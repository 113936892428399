import * as React from 'react';
import {
  Icon,
  IconButton,
  Typography,
  WithStyles,
  withStyles,
  Button,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { acceptedDocuments } from '@/app/utils/helper';

import styles from './formikSingleAttachmentField/styles';
import translate from '@/app/utils/translate';
import { FieldProps } from 'formik';

type OwnProps = {
  attachment: File;
  // attachmentId?: number,
  // attachmentName?: string,
  maxFileSize?: number;
  required?: boolean;
  fullWidth?: boolean;
  shrink?: boolean;

  // onChange: (attachment: File) => void,
  onDownloadAttachment: () => void;
};
type InnerProps = WithStyles<typeof styles> & FieldProps;
type Props = OwnProps & InnerProps;

const AttachmentsField = ({
  classes,
  form,
  field,
  // attachment,
  // attachmentId = -1,
  // attachmentName = '',
  maxFileSize = 10 * 1024 * 1024, // 10Mb
  required = false,
  fullWidth = true,
  shrink = true,
  // onChange,
  onDownloadAttachment,
}: Props) => {
  const attachmentNameFieldName = field.name.replace('Id', 'Name');
  const attachmentFileFieldName = field.name.replace('Id', 'File');
  const attachmentName = form.values[attachmentNameFieldName] || '';

  const handleDrop = (droppedFiles: File[]) => {
    const att = droppedFiles[0];
    form.setFieldValue(attachmentFileFieldName, att);
    form.setFieldValue(field.name, -1);
    form.setFieldValue(attachmentNameFieldName, att.name);
  };

  const handleRemove = () => {
    form.setFieldValue(attachmentFileFieldName, undefined);
    form.setFieldValue(field.name, -1);
    form.setFieldValue(attachmentNameFieldName, '');
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: acceptedDocuments,
    maxSize: maxFileSize,
    onDrop: handleDrop,
  });

  let existingAttachment = undefined;
  if (attachmentName !== '') {
    if (field.value === -1) {
      existingAttachment = (
        <div className={classes.existingAttachmentArea}>
          <div className={classes.existingAttachment}>
            <Typography variant="body1" className={classes.attachmentName}>
              {attachmentName}
            </Typography>
            <IconButton
              title={translate.t('label_remove_attachment')}
              className={classes.removeAttButton}
              onClick={handleRemove}
            >
              <Icon color="error" fontSize="small">
                close
              </Icon>
            </IconButton>
          </div>
        </div>
      );
    } else {
      existingAttachment = (
        <div className={classes.existingAttachmentArea}>
          <div className={classes.existingAttachment}>
            <Button
              className={classes.attButton}
              variant="text"
              title={translate.t('label_open_attachment')}
              onClick={onDownloadAttachment}
            >
              {attachmentName}
            </Button>
            <IconButton
              title={translate.t('label_remove_attachment')}
              className={classes.removeAttButton}
              onClick={handleRemove}
            >
              <Icon color="error" fontSize="small">
                close
              </Icon>
            </IconButton>
          </div>
        </div>
      );
    }
  }

  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel htmlFor="dropzone-input" shrink={shrink}>
        {translate.t('table_col_attachment')}
      </InputLabel>
      {existingAttachment}
      <div className={classes.root} {...getRootProps()}>
        <input {...getInputProps()} required={required} />
        <div className={classes.paragraph}>
          {isDragActive ? (
            <Typography variant="body1">
              {translate.t('text_drop_attachment_here')}
            </Typography>
          ) : (
            <Typography variant="body1">
              {attachmentName === ''
                ? translate.t('text_dragdrop_attachment_here_to_add')
                : translate.t('text_dragdrop_attachment_here_to_replace')}
            </Typography>
          )}
        </div>
      </div>
    </FormControl>
  );
};

export default withStyles(styles, { withTheme: true })(AttachmentsField);

import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const questionsSortDialogStyle: StyleRulesCallback = () => {
  return {
    paperWidthMd: {
      width: '500px',
    },
  };
};

export default questionsSortDialogStyle;

import * as React from 'react';
import { FormControl, TextField } from '@material-ui/core';
import { styles } from '@/app/components/FormFields/formFieldsStyle';

const RenderTextField = (props: any) => {
  const {
    input,
    label,
    required,
    meta: { touched, error },
    className,
    helpText,
    ...custom
  } = props;

  const [helpTextLimit, setHelpTextLimit] = React.useState(`${input.value.length}/${custom.limit}`);
  const inputProps = custom.multiline && custom.limit ? { maxLength: custom.limit } : undefined;
  const { childrenInputs, formControlMargin, limit, ...customProps } = custom;
  const handleLimitUpdate = (change: React.ChangeEvent<HTMLInputElement>) => {
    input.onChange(change);
    if (custom.multiline && custom.limit) {
      setHelpTextLimit(`${change.target.value.length}/${custom.limit}`);
    }
  };

  const hasError = () => {
    if (!touched) {
      return false;
    }

    return !!error;
  };

  const getHelperText = () => {
    const errMessage = hasError() ? error : null;
    if (custom.multiline && custom.limit) {
      return (
        <div>
          <div>{helpTextLimit}</div>
          {errMessage ? <div>{errMessage}</div> : undefined}
        </div>
      );
    }

    return errMessage;
  };
  
  return (
    <FormControl
      style={styles.textField}
      fullWidth
      margin={formControlMargin || 'normal'}
    >
      <TextField
        label={label}
        helperText={getHelperText()}
        error={hasError()}
        required={required}
        inputProps={inputProps}
        FormHelperTextProps={{
          component: 'div'
        }}
        {...input}
        {...customProps}
        onChange={handleLimitUpdate}
      />
      {helpText}
    </FormControl>
  );
};

export default RenderTextField;

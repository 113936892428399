import { Map as iMap, List as iList, Record } from 'immutable';
import { THROW_ERROR } from './error';
import API from '@/app/api/internalAPIs';
import Service from '@/app/utils/service';
import { Dispatch } from 'redux';
import {
  getDeleteUrl,
  getCurrentLanguage,
  getStored,
} from '@/app/utils/helper';
import { OPEN_NOTIFIER } from '@/app/redux/notifier';
import { Translation } from '@/app/components/Enum/EnumTranslateDialog/EnumTranslateDialog';
import { ChildInputType } from '@/app/components/FormFields/FormFields';
import {
  getCompanyCountryNameByCode,
  getCompanyUnitsByCodeArray,
  getCustomDataGroupName,
  getCustomDataParentGroupName,
} from '@/old/utils/helper';
import { OptionType } from '../components/Trainings/types';

export interface DetailTranslation {
  lang: string;
  details: string;
}

export type lockLevelType = 'ALL' | 'VALUE' | 'NONE';

export type Enum = {
  groupName: string;
  code: string;
  description: string;
  name: string;
  details: string;
  seq?: number;
  tags?: string[];
  countries?: ChildInputType[];
  isActive?: boolean;
  inputType?: ChildInputType;
  extCode?: string;
  fCustomDataAttributeEditableByEmp?: boolean;
  orgUnits?: number[];
  editableByEmp?: boolean;
};

export type ValueListsEditableEnum = Enum & {
  orgUnits?: OptionType[];
  includeSubUnits?: boolean;
};

export type AllEnums = {
  [key: string]: Enum[];
};

export type EnumConfig = {
  name: string;
  lockLevel: lockLevelType;
  isOrdered: boolean;
  isComposite: boolean;
  hasInputType?: boolean;
};

export type Enums = {
  allEnums: object;
  childrenEnums: any;
  configs: Array<EnumConfig>;
  isFetching?: boolean;
};

const sortEnumGroup = (
  groupName: string, // Enum group name
  enums: object[], // Enums in the group
  isSortable: boolean = false, // If enum group is sortable
): object[] => {
  let modifiedEnums = [...enums];

  // Fix inactive enum sequences for absence reasons as old ui expects
  // (starting index.js line 339, function jsSortEnums)
  if (['ABSENCE_SHORT_REASON', 'ABSENCE_LONG_REASON'].indexOf(groupName) > -1) {
    modifiedEnums.forEach((item: any) => {
      if (item.isActive === false) {
        item.seq = 10000;
      }
    });
  }

  // Sort by sequence, if enum group is meant to be sortable
  if (isSortable) {
    modifiedEnums = modifiedEnums.sort((a: any, b: any) => a.seq - b.seq);
  } else {
    // Following enum groups have alphabetic sorting requirement
    // (also old code jsSortEnums function)
    if (['CURRENCY'].indexOf(groupName) > -1) {
      modifiedEnums = modifiedEnums.sort((a: any, b: any) =>
        (a.name as string).localeCompare(b.name),
      );
    }

    // EVENT_FREQUENCY has specific sorting requirement
    // (also old code jsSortEnums function)
    if (groupName === 'EVENT_FREQUENCE') {
      const codesInOrder = [
        '0_BLANK',
        'HOURLY',
        'DAILY',
        'WEEKLY',
        'BIWEEKLY',
        'MONTHLY_BIWEEKLY',
        'MONTHLY',
        'QUARTERLY',
        'SEMIANNUALLY',
        'ANNUALLY',
      ];
      modifiedEnums = modifiedEnums.sort(
        (a: any, b: any) =>
          codesInOrder.indexOf(a.code) - codesInOrder.indexOf(b.code),
      );
    }
  }

  return modifiedEnums;
};

export const selectAllEnums = (state: any) => {
  return state.enums.get('allEnums').toJS();
};

export const selectActiveEnumsInGroup = (state: any, group: string): Enum[] => {
  const enums: Enum[] = state.enums.get('allEnums').get(group);

  if (!enums) {
    return [];
  }

  return enums.filter(enumItem => enumItem.isActive);
};

export const selectCustomFieldInputTypes = (state: any): ChildInputType[] => {
  const allEnums = selectAllEnums(state);
  const values: Enum[] = allEnums.CUSTOM_DATA_TYPE;

  if (!values) {
    return [];
  }

  return values.map(v => ({ value: v.code, label: v.name }));
};

export const getEnumName = (group: string, code: string, allEnums: object) => {
  const enumValue = allEnums[group].find((e: any) => e.code === code);
  if (!enumValue) {
    return code;
  }

  return enumValue.name;
};

export const FETCH_ALL_ENUMS = '@@solaforce/enums/FETCH_ALL_ENUMS';
export const DELETE_ENUMS = '@@solaforce/enums/DELETE_ENUMS';
export const DELETE_GROUP = '@@solaforce/enums/DELETE_GROUP';
export const UPDATE_TRANSLATION = '@@solaforce/enums/UPDATE_TRANSLATION';
export const UPDATE_DETAILS = '@@solaforce/enums/UPDATE_DETAILS';
export const ADD_NEW_ENUM = '@@solaforce/enums/ADD_NEW_ENUM';
export const ADD_NEW_GROUP = '@@solaforce/enums/ADD_NEW_GROUP';
export const UPDATE_ENUM = '@@solaforce/enums/UPDATE_ENUM';
export const UPDATE_ORDER = '@@solaforce/enums/UPDATE_ORODER';
export const FETCHING = '@@solaforce/enums/FETCHING';
export const FETCH_CUSTOM_CHILDREN = '@@solaforce/enums/FETCH_CUSTOM_CHILDREN';
export const UPDATE_CUSTOM_CHILDREN_ORDER =
  '@@solaforce/enums/UPDATE_CUSTOM_CHILDREN_ORDER';
export const CLEAR_CUSTOM_CHILDREN = '@@solaforce/enums/CLEAR_CUSTOM_CHILDREN';

const initalState = Record({
  allEnums: iMap<string, any>(),
  childrenEnums: iList(),
  sortedChildrenEnums: [],
  configs: iList(),
  isFetching: false,
});

const reducer = (state = new initalState(), action: any) => {
  switch (action.type) {
    case FETCHING:
      return state.set('isFetching', true);
    case FETCH_ALL_ENUMS: {
      const { allEnums, configs } = action.enums;
      return state
        .set('allEnums', iMap(allEnums))
        .set('configs', iList(configs))
        .set('isFetching', false);
    }
    case DELETE_ENUMS: {
      const { groupName, codes } = action.payload;
      const allEnumsState = state.allEnums;
      const childrenEnums = state.childrenEnums.toJS();
      const groupEnums = <Enum[]> allEnumsState.get(groupName);
      const newEnums = groupEnums.map(grp =>
        codes.indexOf(grp.code) !== -1 ? { ...grp, isActive: false } : grp,
      );
      let newChildrenEnums = [...childrenEnums];
      newChildrenEnums = newChildrenEnums.filter(
        (c: Enum) =>
          (c.groupName === groupName && codes.indexOf(c.code) === -1) ||
          c.groupName !== groupName,
      );

      const allEnums = allEnumsState.set(groupName, newEnums);

      return state
        .set('childrenEnums', iList(newChildrenEnums))
        .merge({ allEnums });
    }
    case DELETE_GROUP: {
      const { name } = action.payload;
      const allEnumsState = state.get('allEnums');
      let configs = state.get('configs').concat();
      const allEnums = allEnumsState.filter((e: Enum) => e.groupName !== name);
      configs = configs.filter((e: EnumConfig) => e.name !== name);

      return state.set('configs', configs).merge({ allEnums });
    }
    case UPDATE_TRANSLATION: {
      const allEnumsState = state.allEnums;
      let childrenEnums = state.childrenEnums;
      const { groupName, code, translations } = action.payload;

      let newTranslation: Translation = null;
      if (translations.length > 0) {
        newTranslation = translations.find(
          (t: Translation) => t.lang === getCurrentLanguage(),
        );
      }

      const groupEnums = <Enum[]> allEnumsState.get(groupName);
      const index = groupEnums.findIndex(grp => grp.code === code);

      const newEnums = groupEnums.map((en, idx) => {
        const newEn = { ...en };

        if (idx === index) {
          newEn.name = newTranslation ? newTranslation.name : newEn.description;
        }

        return newEn;
      });

      if (groupName.indexOf('__CUSTOMDATA__') === 0) {
        const cIndex = childrenEnums.findIndex(
          (c: Enum) => c.groupName === groupName && c.code === code,
        );
        childrenEnums = childrenEnums.map((en: Enum, idx: number) => {
          const newEn = { ...en };

          if (idx === cIndex) {
            newEn.name = newTranslation
              ? newTranslation.name
              : newEn.description;
          }

          return newEn;
        });
      }

      const allEnums = allEnumsState.set(groupName, newEnums);

      return state.set('childrenEnums', childrenEnums).merge({ allEnums });
    }
    case UPDATE_DETAILS: {
      const allEnumsState = state.allEnums;
      let childrenEnums = state.childrenEnums;
      const { groupName, code, details } = action.payload;

      let newDetails: DetailTranslation = null;
      if (details.length > 0) {
        newDetails = details.find(
          (t: DetailTranslation) => t.lang === getCurrentLanguage(),
        );
      }

      const groupEnums = <Enum[]> allEnumsState.get(groupName);
      const index = groupEnums.findIndex(grp => grp.code === code);

      const newEnums = groupEnums.map((en, idx) => {
        const newEn = { ...en };

        if (idx === index) {
          newEn.details = newDetails ? newDetails.details : '';
        }

        return newEn;
      });

      const allEnums = allEnumsState.set(groupName, newEnums);

      return state.set('childrenEnums', childrenEnums).merge({ allEnums });
    }
    case ADD_NEW_ENUM: {
      const { groupName, newEnum } = action.payload;
      const allEnumsState = state.allEnums;
      let configs = state.configs.concat();
      let childrenEnums = state.childrenEnums;
      const groupEnums = <Enum[]> allEnumsState.get(groupName);

      let newEnumCopy = {
        ...newEnum,
        groupName,
        description: newEnum.name,
        isActive: true,
        inputType: newEnum.inputType
          ? {
              value: newEnum.inputType,
              label: getInputTypeName(allEnumsState.toJS(), newEnum.inputType),
            }
          : '',
      };
      const newGroupEnums = [...groupEnums].filter(
        value => value.code !== newEnumCopy.code,
      );
      const seqArray = newGroupEnums.map(nge => nge.seq);
      if (Array.isArray(seqArray) && seqArray.length > 0) {
        newEnumCopy.seq = Math.max(...seqArray) + 1;
      } else {
        newEnumCopy.seq = 1;
      }
      newGroupEnums.push(newEnumCopy);

      // means child enums
      if (groupName.indexOf('__CUSTOMDATA__') === 0) {
        const child = childrenEnums.filter((c: Enum) => c.isActive).last();
        const lastChild = <Enum> child;
        let seq = 0;
        if (lastChild) {
          seq = lastChild.seq + 0.01;
        } else {
          const parentGroupName = getCustomDataParentGroupName(groupName);
          const parentEnumCode = groupName.split('__')[3];
          const parentEnum = allEnumsState
            .get(parentGroupName)
            .find((e: Enum) => e.code === parentEnumCode);
          seq = parentEnum.seq + 0.01;
        }

        childrenEnums = childrenEnums.push({
          ...newEnum,
          groupName,
          description: newEnum.name,
          isActive: true,
          seq,
        });
      }

      let allEnums = allEnumsState.merge({
        [groupName]: newGroupEnums,
      });

      if (newEnum.inputType) {
        const customDataGroupName = getCustomDataGroupName(
          groupName,
          newEnum.code,
        );
        allEnums = allEnums.merge({
          [customDataGroupName]: [],
        });
        configs = configs.push({
          name: customDataGroupName,
          lockLevel: 'NONE',
          isOrdered: false,
          isComposite: false,
          hasInputType: false,
        });
      }

      return state
        .set('childrenEnums', childrenEnums)
        .set('configs', configs)
        .merge({ allEnums });
    }
    case ADD_NEW_GROUP: {
      const { name } = action.payload;
      const allEnumsState = state.get('allEnums');
      let configs = state.get('configs').concat();

      const allEnums = allEnumsState.merge({
        ['__USER__DEV_' + name.toUpperCase()]: [],
      });

      configs = configs.push({
        name: '__USER__DEV_' + name.toUpperCase(),
        lockLevel: 'NONE',
        isOrdered: true,
        isComposite: false,
        hasInputType: false,
      });

      return state.set('configs', configs).merge({ allEnums });
    }
    case UPDATE_ORDER: {
      const { groupName, enums } = action.payload;
      const allEnumsState = state.allEnums;
      const groupEnums = <Enum[]> allEnumsState.get(`${groupName}`);

      const newEnums = enums.map((en: Enum) => {
        const index = groupEnums.findIndex(grp => grp.code === en.code);
        const countries =
          en.countries && en.countries.length > 0
            ? en.countries.map(
                (c: any): ChildInputType => ({
                  value: c,
                  label: getCompanyCountryNameByCode(c),
                }),
              )
            : null;

        return {
          ...groupEnums[index],
          seq: en.seq,
          countries,
        };
      });

      const allEnums = allEnumsState.set(
        groupName,
        newEnums.sort((a: any, b: any) => a.seq - b.seq),
      );

      return state.set('childrenEnums', iList([])).merge({ allEnums });
    }
    case UPDATE_ENUM: {
      const { groupName, code, data } = action.payload;
      const {
        tags = [],
        countries = [],
        name,
        extCode,
        orgUnits = [],
        inputType = '',
      } = data;

      const allEnumsState = state.allEnums;
      const groupEnums = <Enum[]> allEnumsState.get(groupName);
      const index = groupEnums.findIndex(grp => grp.code === code);
      let childrenEnums = state.childrenEnums.concat();

      const newEnums = groupEnums.map((en, idx) => {
        const newEn = { ...en };
        if (idx === index) {
          newEn.countries = countries.map((c: string) => ({
            value: c,
            label: getCompanyCountryNameByCode(c),
          }));
          newEn.orgUnits = orgUnits.map((unit: number) =>
            getCompanyUnitsByCodeArray(unit),
          );
          newEn.editableByEmp = data.editableByEmp;
          newEn.tags = tags;
          newEn.description = name;
          newEn.extCode = extCode;
          newEn.inputType = {
            value: inputType,
            label: getInputTypeName(allEnumsState.toJS(), inputType),
          };
        }
        return newEn;
      });

      if (groupName.indexOf('__CUSTOMDATA__') === 0) {
        const cIndex = childrenEnums.findIndex(
          (c: Enum) => c.groupName === groupName && c.code === code,
        );
        childrenEnums = childrenEnums.map((en: Enum, idx: number) => {
          const newEn = { ...en };

          if (idx === cIndex) {
            newEn.countries = countries.map((c: string) => ({
              value: c,
              label: getCompanyCountryNameByCode(c),
            }));
            newEn.tags = tags;
            newEn.description = name;
            newEn.extCode = extCode;
          }

          return newEn;
        });
      }

      const allEnums = allEnumsState.set(groupName, newEnums);

      return state.set('childrenEnums', childrenEnums).merge({ allEnums });
    }
    case FETCH_CUSTOM_CHILDREN: {
      const { groupName, code } = action.payload;
      const allEnumsState = state.allEnums;
      const sortedChildrenEnums = state.sortedChildrenEnums;
      const groupEnums = <Enum[]> allEnumsState.get(groupName);

      if (code) {
        let childKey = getCustomDataGroupName(groupName, code);
        const childrenData = <Enum[]> allEnumsState.get(childKey);

        if (childrenData) {
          const parentData = groupEnums.find(grp => grp.code === code);
          const checkForRecentlySorted =
            sortedChildrenEnums.length > 0 &&
            childrenData[0].groupName === sortedChildrenEnums[0].groupName;
          const updatedChildrenData = (checkForRecentlySorted
            ? sortedChildrenEnums
            : childrenData
          ).map((c: Enum, index: number) => ({
            ...c,
            seq: c.isActive ? (parentData.seq * 100 + index + 1) / 100 : -1,
            groupName: childKey,
          }));

          return state.set('childrenEnums', iList(updatedChildrenData));
        }
      }

      return state;
    }

    case UPDATE_CUSTOM_CHILDREN_ORDER: {
      const { groupName, code, enums } = action.payload;
      const allEnumsState = state.allEnums;
      const groupEnums = <Enum[]> allEnumsState.get(groupName);

      if (code) {
        let childKey = getCustomDataGroupName(groupName, code);
        const childrenData = <Enum[]> allEnumsState.get(childKey);

        if (childrenData) {
          const parentData = groupEnums.find(grp => grp.code === code);
          const updatedChildrenData = enums
            .sort((a: any, b: any) => a.seq - b.seq)
            .map((en: any) => {
              const childData = childrenData.find(ch => ch.code === en.code);

              return {
                ...childData,
                seq: childData.isActive
                  ? (parentData.seq * 100 + en.seq) / 100
                  : -1,
              };
            });

          return state
            .set('childrenEnums', iList(updatedChildrenData))
            .set('sortedChildrenEnums', updatedChildrenData)
            .merge({
              allEnums: allEnumsState.set(
                getCustomDataGroupName(groupName, code),
                updatedChildrenData,
              ),
            });
        }
      }

      return state;
    }

    case CLEAR_CUSTOM_CHILDREN:
      return state.set('childrenEnums', iList([]));
    default:
      return state;
  }
};

const getInputTypeName = (enums: any, inputType: string) => {
  if (!enums.CUSTOM_DATA_TYPE) {
    return '';
  }

  const result = enums.CUSTOM_DATA_TYPE.find((d: any) => d.code === inputType);
  if (!result) {
    return '';
  }

  return result.name;
};

export const fetchAllEnums = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: FETCHING });
    const enums: Enums = {
      allEnums: {},
      childrenEnums: [],
      configs: [],
    };
    let storedVersion = getStored('version/enums') || -1;
    let currentVersion = 0;

    await Service.get(
      API.enum.currentVersion,
      (data: any) => {
        currentVersion = data.versionNumber;
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
    const cachedEnums = getStored('state/enums');
    const cachedLang = getStored('cachedLang');
    // if the cache is updated, load the cache
    if (
      storedVersion === currentVersion &&
      cachedEnums &&
      cachedLang === getCurrentLanguage()
    ) {
      dispatch({ type: FETCH_ALL_ENUMS, enums: cachedEnums });
    } else {
      await Service.get(
        API.enum.allWithConfig,
        (data: any) => {
          // TODO, currently hasInputType is decided by name, should read from API
          enums.configs = data.map((d: any) =>
            Object.assign({}, d, {
              hasInputType: d.name.indexOf('CUSTOM_ENTITY') === 0,
            }),
          );
        },
        (error: any) => dispatch({ type: THROW_ERROR, error }),
      );
      const sortableGroups = enums.configs
        .filter(group => group.isOrdered)
        .map(group => group.name);

      // we needs to make extra calls for custom enums
      const customFields: string[] = [
        'BILLABLE_HOURS_PROPOSAL',
        'COST_CENTER',
        'EMPLOYEE',
        'EMPLOYEE_POS',
        'DECISION_REGISTRY',
        'EMPLOYMENT',
        'JOB_APPLICATION',
        'TIMESHEET',
        'UNIT_POSITION',
        'EMPLOYMENT_DETAILS',
      ];
      let customFieldsData: {
        group: string;
        code: string;
        inputType: string;
        countries: string[];
      }[] = [];

      for await (let field of customFields) {
        await Service.get(
          API.enum.customFieldAttribute(field),
          (data: any) => {
            if (Array.isArray(data) && data.length > 0) {
              customFieldsData = customFieldsData.concat(
                data.map((d: any) => ({
                  group: 'CUSTOM_ENTITY_' + d.fCustomDataEntity,
                  code: d.fCustomDataAttributeName,
                  inputType: d.fCustomDataAttributeDataType,
                  countries: d.fCustomDataAttributeCountry,
                })),
              );
            }
          },
          (error: any) => dispatch({ type: THROW_ERROR, error }),
        );
      }

      await Service.get(
        API.enum.all(),
        (data: any) => {
          enums.allEnums = Object.assign({}, data, {
            EMPLOYEE_POSITION: data.EMPLOYEE_POSITION.slice(),
          });
          const keys = Object.keys(enums.allEnums);

          keys.forEach(key => {
            enums.allEnums[key] = sortEnumGroup(
              key,
              enums.allEnums[key],
              sortableGroups.indexOf(key) > -1,
            ).map((e: any) => {
              let mappedEnum: Enum = Object.assign({}, e, {
                groupName: key,
                isActive: e.hasOwnProperty('isActive') ? e.isActive : true,
              });

              const customEnum = customFieldsData.find(
                cf => cf.group === key && cf.code === e.code,
              );
              const customInputType = customEnum
                ? getInputTypeName(enums.allEnums, customEnum.inputType)
                : null;
              const currentEnum = customEnum || e;
              const countries =
                currentEnum.countries && currentEnum.countries.length > 0
                  ? currentEnum.countries
                      // Filter added to handle when in db the countries list has an empty string | Validation must be added to BE
                      .filter((c: string) => !!c)
                      .map((c: string) => ({
                        value: c,
                        label: getCompanyCountryNameByCode(c),
                      }))
                  : [];
              const inputType = e.inputType
                ? {
                    value: e.inputType,
                    label: getInputTypeName(enums.allEnums, e.inputType),
                  }
                : null;

              if (countries.length) {
                mappedEnum = Object.assign({}, mappedEnum, {
                  countries: countries.slice(),
                });
              }

              if (inputType || customInputType) {
                mappedEnum = Object.assign({}, mappedEnum, {
                  inputType: inputType || {
                    value: customEnum.inputType,
                    label: customInputType,
                  },
                });
              }

              return mappedEnum;
            });
          });
        },
        // Console left on purpose for easier error catching
        (error: any) => {
          console.error(error);
          dispatch({ type: THROW_ERROR, error });
        },
      );
      dispatch({ type: FETCH_ALL_ENUMS, enums, currentVersion });
    }
  };
};

const deleteEnumsService = (groupName: string, codes: Array<string>) => {
  return (dispatch: Dispatch) => {
    Service.delete(
      getDeleteUrl(API.enum.group(groupName), codes),
      null,
      (data: any, headers: any) => {
        if (data > 0) {
          dispatch({ type: OPEN_NOTIFIER, payload: {} });
        }
        const payload = { groupName, codes };
        dispatch({
          type: DELETE_ENUMS,
          payload,
          currentVersion: parseInt(headers['x-solaforce-enum-version'], 10),
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

const deleteCustomEnumsService = (groupName: string, codes: Array<string>) => {
  return async (dispatch: Dispatch) => {
    let reslult = 0;
    let resultHeaders = {};
    for await (let code of codes) {
      await Service.deleteWithoutContentType(
        API.enum.deleteCustomFieldAttribute(
          groupName.replace('CUSTOM_ENTITY_', ''),
          code,
        ),
        null,
        (data: any, headers: any) => {
          reslult = data;
          resultHeaders = headers;
        },
        (error: any) => dispatch({ type: THROW_ERROR, error }),
      );
    }
    if (reslult > 0) {
      dispatch({ type: OPEN_NOTIFIER, payload: {} });
    }
    const payload = { groupName, codes };
    dispatch({
      type: DELETE_ENUMS,
      payload,
      currentVersion: parseInt(resultHeaders['x-solaforce-enum-version'], 10),
    });
  };
};

export const deleteEnums = (groupName: string, codes: Array<string>) => {
  // if there is inputType, use custom field API service
  if (groupName.indexOf('CUSTOM_ENTITY_') === 0) {
    return deleteCustomEnumsService(groupName, codes);
  }

  return deleteEnumsService(groupName, codes);
};

export const deleteGroup = (name: string) => {
  return (dispatch: Dispatch) => {
    return Service.delete(
      API.group.delete('dev', name),
      null,
      (data: any, headers: any) => {
        if (data > 0) {
          dispatch({ type: OPEN_NOTIFIER, payload: {} });
        }
        const payload = { name };
        dispatch({
          type: DELETE_GROUP,
          payload,
          currentVersion: parseInt(headers['x-solaforce-enum-version'], 10),
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

const addNewEnumService = (value: Enum, groupName: string) => {
  const payload = {
    code: value.code || '_',
    name: value.name,
    tags: value.tags ? value.tags.map((t: any) => t.value) : [],
    countries: value.countries ? value.countries.map((c: any) => c.value) : [],
    extCode: value.extCode || '',
  };

  return (dispatch: Dispatch) => {
    Service.post(
      API.enum.add(groupName),
      payload,
      (data: any, headers: any) => {
        if (data.name) {
          dispatch({ type: OPEN_NOTIFIER, payload: {} });
          const newData = Object.assign({ isActive: true }, data);
          if (newData.countries) {
            newData.countries = newData.countries.map((c: string) => ({
              value: c,
              label: getCompanyCountryNameByCode(c),
            }));
          }
          dispatch({
            type: ADD_NEW_ENUM,
            payload: {
              newEnum: newData,
              groupName,
            },
            currentVersion: parseInt(headers['x-solaforce-enum-version'], 10),
          });
        }
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

const addNewCustomEnumService = (value: Enum, groupName: string) => {
  const payload = {
    fCustomDataAttributeName: value.code || '_',
    fCustomDataAttributeLabel: value.name,
    fCustomDataAttributeCountry: value.countries
      ? value.countries.map((c: any) => c.value)
      : [],
    fCustomDataAttributeDataType: value.inputType.value,
  };

  return (dispatch: Dispatch) => {
    Service.post(
      API.enum.customFieldAttribute(groupName.replace('CUSTOM_ENTITY_', '')),
      payload,
      (data: any, headers: any) => {
        if (data.fCustomDataEntity) {
          dispatch({ type: OPEN_NOTIFIER, payload: {} });
          const newData = Object.assign(
            { isActive: true },
            {
              code: data.fCustomDataAttributeName,
              name: data.fCustomDataAttributeLabel,
              countries: data.fCustomDataAttributeCountry,
              inputType: data.fCustomDataAttributeDataType,
            },
          );
          if (newData.countries) {
            newData.countries = newData.countries.map((c: string) => ({
              value: c,
              label: getCompanyCountryNameByCode(c),
            }));
          }

          dispatch({
            type: ADD_NEW_ENUM,
            payload: {
              newEnum: newData,
              groupName,
            },
            currentVersion: parseInt(headers['x-solaforce-enum-version'], 10),
          });
        }
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const addNewEnum = (value: Enum, groupName: string) => {
  // if there is inputType, use custom field API service
  if (value.inputType) {
    return addNewCustomEnumService(value, groupName);
  }

  return addNewEnumService(value, groupName);
};

const addNewEnumWithTranslationService = (
  value: Enum,
  languages: Array<Translation>,
  groupName: string,
) => {
  const payload = {
    code: value.code || '_',
    name: value.name,
    tags: value.tags ? value.tags.map((t: any) => t.value) : [],
    countries: value.countries ? value.countries.map((c: any) => c.value) : [],
    extCode: value.extCode || '',
    orgUnits: value.orgUnits
      ? value.orgUnits.map((unit: any) => unit.value)
      : [],
  };

  return (dispatch: Dispatch) => {
    Service.post(
      API.enum.add(groupName),
      payload,
      (dataEnum: any, headers: any) => {
        if (dataEnum.name) {
          const newEnum = Object.assign({}, dataEnum);
          if (newEnum.countries) {
            newEnum.countries = newEnum.countries.map((c: string) => ({
              value: c,
              label: getCompanyCountryNameByCode(c),
            }));
          }
          const code = dataEnum.code;

          if (newEnum.orgUnits) {
            newEnum.orgUnits = newEnum.orgUnits.map((unit: string) => {
              return getCompanyUnitsByCodeArray(unit);
            });
          }
          dispatch({
            type: ADD_NEW_ENUM,
            payload: {
              newEnum,
              groupName,
            },
            currentVersion: parseInt(headers['x-solaforce-enum-version'], 10),
          });
          dispatch({ type: OPEN_NOTIFIER, payload: {} });

          Service.put(
            API.enum.translation(groupName, code),
            languages,
            (data: any, _headers: any) => {
              if (data instanceof Array) {
                const payloadTrans = { groupName, code, translations: data };

                dispatch({ type: OPEN_NOTIFIER, payload: {} });
                dispatch({
                  type: UPDATE_TRANSLATION,
                  payload: payloadTrans,
                  currentVersion: parseInt(
                    _headers['x-solaforce-enum-version'],
                    10,
                  ),
                });
              }
            },
            (error: any) => dispatch({ type: THROW_ERROR, error }),
          );
        }
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

const addNewEnumWithTranslationAndDetailsService = (
  value: Enum,
  languages: Array<Translation>,
  details: Array<DetailTranslation>,
  groupName: string,
) => {
  const payload = {
    code: value.code || '_',
    name: value.name,
    tags: value.tags ? value.tags.map((t: any) => t.value) : [],
    countries: value.countries ? value.countries.map((c: any) => c.value) : [],
    extCode: value.extCode || '',
  };

  return (dispatch: Dispatch) => {
    Service.post(
      API.enum.add(groupName),
      payload,
      (dataEnum: any, headers: any) => {
        if (dataEnum.name) {
          const newEnum = Object.assign({}, dataEnum);
          if (newEnum.countries) {
            newEnum.countries = newEnum.countries.map((c: string) => ({
              value: c,
              label: getCompanyCountryNameByCode(c),
            }));
          }
          const code = dataEnum.code;

          dispatch({
            type: ADD_NEW_ENUM,
            payload: {
              newEnum,
              groupName,
            },
            currentVersion: parseInt(headers['x-solaforce-enum-version'], 10),
          });
          // dispatch({ type: OPEN_NOTIFIER, payload: {} });

          Service.put(
            API.enum.translation(groupName, code),
            languages,
            (data: any, _headers: any) => {
              if (data instanceof Array) {
                const payloadTrans = { groupName, code, translations: data };

                // dispatch({ type: OPEN_NOTIFIER, payload: {} });
                dispatch({
                  type: UPDATE_TRANSLATION,
                  payload: payloadTrans,
                  currentVersion: parseInt(
                    _headers['x-solaforce-enum-version'],
                    10,
                  ),
                });
              }

              Service.put(
                API.enum.details(groupName, code),
                details,
                (dataDet: any, _headersDet: any) => {
                  const payloadDetails = { groupName, code, details: dataDet };

                  dispatch({ type: OPEN_NOTIFIER, payload: {} });
                  dispatch({
                    type: UPDATE_DETAILS,
                    payload: payloadDetails,
                    currentVersion: parseInt(
                      _headersDet['x-solaforce-enum-version'],
                      10,
                    ),
                  });
                },
                (error: any) => dispatch({ type: THROW_ERROR, error }),
              );
            },
            (error: any) => dispatch({ type: THROW_ERROR, error }),
          );
        }
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

const addNewCustomEnumWithTranslationService = (
  value: Enum,
  languages: Array<Translation>,
  groupName: string,
) => {
  const payload = {
    fCustomDataAttributeName: value.code || '_',
    fCustomDataAttributeLabel: value.name,
    fCustomDataAttributeCountry: value.countries
      ? value.countries.map((c: any) => c.value)
      : [],
    fCustomDataAttributeDataType: value.inputType.value,
    fCustomDataAttributeEditableByEmp: value.fCustomDataAttributeEditableByEmp,
  };

  return (dispatch: Dispatch) => {
    Service.post(
      API.enum.customFieldAttribute(groupName.replace('CUSTOM_ENTITY_', '')),
      payload,
      (dataEnum: any, headers: any) => {
        if (dataEnum.fCustomDataEntity) {
          const newEnum = Object.assign(
            { isActive: true },
            {
              code: dataEnum.fCustomDataAttributeName,
              name: dataEnum.fCustomDataAttributeLabel,
              countries: dataEnum.fCustomDataAttributeCountry,
              inputType: dataEnum.fCustomDataAttributeDataType,
              editableByEmp: dataEnum.fCustomDataAttributeEditableByEmp,
            },
          );
          if (
            newEnum.countries &&
            newEnum.countries.length > 0 &&
            newEnum.countries[0]
          ) {
            newEnum.countries = newEnum.countries.map((c: string) => ({
              value: c,
              label: getCompanyCountryNameByCode(c),
            }));
          }
          const code = newEnum.code;

          dispatch({
            type: ADD_NEW_ENUM,
            payload: {
              newEnum,
              groupName,
            },
            currentVersion: parseInt(headers['x-solaforce-enum-version'], 10),
          });
          dispatch({ type: OPEN_NOTIFIER, payload: {} });

          Service.put(
            API.enum.translation(groupName, code),
            languages,
            (data: any, _headers: any) => {
              if (data instanceof Array) {
                const payloadTrans = { groupName, code, translations: data };

                dispatch({ type: OPEN_NOTIFIER, payload: {} });
                dispatch({
                  type: UPDATE_TRANSLATION,
                  payload: payloadTrans,
                  currentVersion: parseInt(
                    _headers['x-solaforce-enum-version'],
                    10,
                  ),
                });
              }
            },
            (error: any) => dispatch({ type: THROW_ERROR, error }),
          );
        }
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const addNewEnumWithTranslation = (
  value: Enum,
  languages: Array<Translation>,
  groupName: string,
  details?: Array<DetailTranslation>,
) => {
  // if there is inputType, use custom field API service

  if (value.inputType) {
    return addNewCustomEnumWithTranslationService(value, languages, groupName);
  }

  if (!details || details.length === 0) {
    return addNewEnumWithTranslationService(value, languages, groupName);
  } else {
    return addNewEnumWithTranslationAndDetailsService(
      value,
      languages,
      details,
      groupName,
    );
  }
};

export const addNewGroup = (value: Enum) => {
  return (dispatch: Dispatch) => {
    return Service.post(
      API.group.post('dev', value.name),
      [],
      (_: any, headers: any) => {
        dispatch({ type: OPEN_NOTIFIER, payload: {} });
        dispatch({
          type: ADD_NEW_GROUP,
          payload: { name: value },
          currentVersion: parseInt(headers['x-solaforce-enum-version'], 10),
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const updateEnumOrder = (groupName: string, list: any[]) => {
  return (dispatch: Dispatch) => {
    Service.put(
      API.enum.order(groupName),
      list,
      (data: any, headers: any) => {
        if (data) {
          const payload = {
            groupName,
            enums: data,
          };

          dispatch({ type: OPEN_NOTIFIER, payload: {} });
          dispatch({
            type: UPDATE_ORDER,
            payload,
            currentVersion: parseInt(headers['x-solaforce-enum-version'], 10),
          });
        }
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const updateTranslation = (
  languages: Array<Translation>,
  groupName: string,
  code: string,
) => {
  return (dispatch: Dispatch) => {
    Service.put(
      API.enum.translation(groupName, code),
      languages,
      (data: any, headers: any) => {
        if (data instanceof Array) {
          dispatch({ type: OPEN_NOTIFIER, payload: {} });
          const payload = { groupName, code, translations: data };
          dispatch({
            type: UPDATE_TRANSLATION,
            payload,
            currentVersion: parseInt(headers['x-solaforce-enum-version'], 10),
          });
        }
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const updateDetails = (
  details: Array<DetailTranslation>,
  groupName: string,
  code: string,
) => {
  return (dispatch: Dispatch) => {
    Service.put(
      API.enum.details(groupName, code),
      details,
      (data: any, headers: any) => {
        if (data instanceof Array) {
          dispatch({ type: OPEN_NOTIFIER, payload: {} });
          const payload = { groupName, code, details: data };
          dispatch({
            type: UPDATE_DETAILS,
            payload,
            currentVersion: parseInt(headers['x-solaforce-enum-version'], 10),
          });
        }
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const updateEnumService = (
  body: any,
  groupName: string,
  code: string,
) => {
  return (dispatch: Dispatch) => {
    Service.put(
      API.enum.groupCode(groupName, code),
      body,
      (data: any, headers: any) => {
        dispatch({ type: OPEN_NOTIFIER, payload: {} });
        const payload = { groupName, code, data };
        dispatch({
          type: UPDATE_ENUM,
          payload,
          currentVersion: parseInt(headers['x-solaforce-enum-version'], 10),
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const updateCustomEnumService = (
  body: any,
  groupName: string,
  code: string,
) => {
  const copyBody = {
    fCustomDataAttributeName: code,
    fCustomDataAttributeLabel: body.name,
    fCustomDataAttributeDataType: body.inputType,
    fCustomDataAttributeCountry: body.countries,
    fCustomDataAttributeEditableByEmp: body.fCustomDataAttributeEditableByEmp,
  };
  return (dispatch: Dispatch) => {
    Service.put(
      API.enum.updateCustomFieldAttribute(
        groupName.replace('CUSTOM_ENTITY_', ''),
        code,
      ),
      copyBody,
      (data: any, headers: any) => {
        dispatch({ type: OPEN_NOTIFIER, payload: {} });
        const payload = {
          groupName,
          code,
          data: {
            code: data.fCustomDataAttributeName,
            name: data.fCustomDataAttributeLabel,
            countries: data.fCustomDataAttributeCountry,
            editableByEmp: data.fCustomDataAttributeEditableByEmp,
            inputType: data.fCustomDataAttributeDataType,
          },
        };
        dispatch({
          type: UPDATE_ENUM,
          payload,
          currentVersion: parseInt(headers['x-solaforce-enum-version'], 10),
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const updateEnum = (body: any, groupName: string, code: string) => {
  // if there is inputType, use custom field API service
  if (groupName.indexOf('CUSTOM_ENTITY_') === 0) {
    return updateCustomEnumService(body, groupName, code);
  }

  return updateEnumService(body, groupName, code);
};

export const fetchCustomChildren = (groupName: string, code: string) => {
  return { type: FETCH_CUSTOM_CHILDREN, payload: { groupName, code } };
};

export const updateCustomChildrenOrder = (
  groupName: string,
  code: string,
  list: any[],
) => {
  return (dispatch: Dispatch) => {
    Service.put(
      API.enum.order(getCustomDataGroupName(groupName, code)),
      list,
      (data: any, headers: any) => {
        if (data) {
          dispatch({
            type: UPDATE_CUSTOM_CHILDREN_ORDER,
            payload: { groupName, code, enums: data },
            currentVersion: parseInt(headers['x-solaforce-enum-version'], 10),
          });

          dispatch({
            type: OPEN_NOTIFIER,
            payload: {},
          });
        }
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const clearCustomChildren = () => {
  return { type: CLEAR_CUSTOM_CHILDREN };
};

export default reducer;

import { WithStyles, withStyles } from '@material-ui/core';
import React from 'react';
import Select from 'react-select';

import Control from '@/app/components/Formik2/components/formikSelectField/Control';
import { OptionType } from '@/app/components/Trainings/types';
import { Enum } from '@/app/redux/enums'; 
import translate from '@/app/utils/translate';
import addNewTemplateStyle from '../templateHeaderStyle';

const mapTags = (tagOptions: Enum[]) => {
  if (!tagOptions) {
    return [];
  }
  
  return tagOptions.map(tt => ({
    value: tt.code,
    label: tt.name,
  }));
};

type Props = {
  value: OptionType[];
  tagOptions: Enum[];
  onChange: (e: any) => void;
} & WithStyles<typeof addNewTemplateStyle>;

const TemplateTags = ({
  classes,
  tagOptions,
  value,
  onChange,
}: Props) => {
  return (
    <Select
      classes={classes}
      textFieldProps={{
        label: translate.t('laTags'),
        InputLabelProps: {
          shrink: true,
        },
      }}
      options={mapTags(tagOptions)}
      components={{ Control }}
      value={value}
      onChange={onChange}
      placeholder={translate.t('laSelectTagsPlaceholder')}
      isMulti
    />
  );
};

export default withStyles(addNewTemplateStyle)(TemplateTags);

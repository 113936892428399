import { Dispatch } from 'react';
import Service from '@/app/utils/service';
import API from '@/app/api/internalAPIs';

import { HoursSettingsActionTypes } from '../data';
import { CountryHoursSettings } from '../hoursSettingsReducer';

export const updateCustomHours = (
    countryCode: string,
    hours: Omit<CountryHoursSettings, 'countryCode'>,
    dispatch: Dispatch<any>,
): void => {
    dispatch({type: HoursSettingsActionTypes.PUT_COUNTRY_CUSTOM_HOURS_PENDING});
    Service.put(
        API.workTimeSettings.updateCountryHoursSettings(countryCode),
        hours,
        (response: any) => {
            dispatch(
                {
                    type: HoursSettingsActionTypes.PUT_COUNTRY_CUSTOM_HOURS_FULFILLED,
                    payload: response
                }
            );
        },
        (error: any) => {
            dispatch(
                {
                    type: HoursSettingsActionTypes.PUT_COUNTRY_CUSTOM_HOURS_REJECTED,
                    error
                }
            );
        }
    );

};

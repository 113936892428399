import * as React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip, Icon, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DuplicateIcon from '@material-ui/icons/FileCopy';
import TranslateIcon from '@material-ui/icons/Translate';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BlockIcon from '@material-ui/icons/Block';
import { WithStyles } from '@material-ui/core';
import topMenuToolBarStyle from './topMenuToolBarStyle';
import ModalWrapper from './ModalWrapper';
import { LanguagesType } from '@/app/redux/languages';
import translate from '@/app/utils/translate';
import { Status } from '@/app/components/JobEvaluation/enums';

type PropsType = OwnProps & WithStyles<typeof topMenuToolBarStyle>;

type OwnProps = {
  partialShow: boolean;
  fullMenu: boolean;
  languages: LanguagesType[];
  addJobEvaluationTemplate: any;
  changeTemplateStatus: any;
  deleteTemplate: (selectedTemplateIds: string[]) => void;
  duplicateTemplate: (id: string) => void;
  selectedTemplateIds: string[];
  handleEditTemplate: (selectedTemplateIds: string[]) => void;
};

class TopMenuToolBar extends React.PureComponent<PropsType> {
  state = {
    modalOpen: false,
    openEditModal: false,
    readyToEditTemplate: [{}],
  };

  handleAddTemplate = () => {
    this.setState({ modalOpen: true });
  };

  handleTemplateStatusChange = (
    selectedTemplateIds: string[],
    newStatus: string,
  ) => {
    this.props.changeTemplateStatus(selectedTemplateIds, newStatus);
  };

  handleTemplateDeletion = (selectedTemplateIds: string[]) => {
    this.props.deleteTemplate(selectedTemplateIds);
  };

  handleTemplateDuplication = (selectedTemplateIds: string[]) => {
    const [templateId] = selectedTemplateIds;
    this.props.duplicateTemplate(templateId);
    // // TODO: Auto editing disabled. Changes required after BE wiring
    // setTimeout(function () {handleTemplateEdit([newId]); }, 750);
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false, openEditModal: false });
  };

  render() {
    const {
      classes,
      partialShow,
      fullMenu,
      languages,
      addJobEvaluationTemplate,
      selectedTemplateIds,
      handleEditTemplate,
    } = this.props;
    const { modalOpen } = this.state;

    return (
      <>
        <span className={classes.root}>
          <IconButton onClick={() => this.handleAddTemplate()}>
            <Tooltip title={translate.t('laAdd')}>
              <Icon color="primary">add_circle</Icon>
            </Tooltip>
          </IconButton>
          {fullMenu && (
            <>
              <IconButton
                onClick={() => handleEditTemplate(selectedTemplateIds)}
              >
                <Tooltip title={translate.t('laEdit')}>
                  <EditIcon color="primary" />
                </Tooltip>
              </IconButton>
              <IconButton
                onClick={() =>
                  this.handleTemplateDuplication(selectedTemplateIds)
                }
              >
                <Tooltip title={translate.t('label_duplicate')}>
                  <DuplicateIcon color="primary" />
                </Tooltip>
              </IconButton>
              <IconButton>
                <Tooltip title={translate.t('title_translate')}>
                  <TranslateIcon color="disabled" />
                </Tooltip>
              </IconButton>
            </>
          )}
          {(partialShow || fullMenu) && (
            <>
              <IconButton
                onClick={() =>
                  this.handleTemplateStatusChange(
                    selectedTemplateIds,
                    Status.ACTIVE,
                  )
                }
              >
                <Tooltip title={translate.t('laActivate')}>
                  <CheckCircleIcon color="primary" />
                </Tooltip>
              </IconButton>
              <IconButton
                onClick={() =>
                  this.handleTemplateStatusChange(
                    selectedTemplateIds,
                    Status.INACTIVE,
                  )
                }
              >
                <Tooltip title={translate.t('label_deactivate')}>
                  <BlockIcon color="primary" />
                </Tooltip>
              </IconButton>
              <IconButton
                onClick={() => this.handleTemplateDeletion(selectedTemplateIds)}
              >
                <Tooltip title={translate.t('laDelete')}>
                  <DeleteIcon color="primary" />
                </Tooltip>
              </IconButton>
            </>
          )}
        </span>
        <ModalWrapper
          languages={languages}
          modalOpen={modalOpen}
          addJobEvaluationTemplate={addJobEvaluationTemplate}
          handleModalClose={this.handleModalClose}
          handleEditTemplate={handleEditTemplate}
        />
      </>
    );
  }
}

const enhance = compose<PropsType, OwnProps>(withStyles(topMenuToolBarStyle));

export default enhance(TopMenuToolBar);

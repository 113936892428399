import * as React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import * as moment from 'moment';
import { selectAllEnums, getEnumName } from '@/app/redux/enums';
import translate from '@/app/utils/translate';
import ReportView from '../components/ReportView';
import KendoProfileImageCell from '../components/KendoProfileImageCell';
import KendoGenericUrlCell from '../components/KendoGenericUrlCell';
import { IReportConfig } from '../ReportConfig';
import { IReportSettings } from '../ReportSettings';

class GoalSettingsAndReviewsReport extends React.Component<any, any> {
  private config: IReportConfig = {
    id: 'GOAL_SETTINGS_AND_REVIEWS',
    report: 'goalSettingsAndReviews',
    titleLabel: 'laGoalSettingReviewDetails',
    access: 'standard',
    columns: [
      { field: 'Picture', title: translate.t('laPicture'), width: '80px', cell: kendoProps => {
        return (
          <KendoProfileImageCell
            {...kendoProps}
          />
        );
      }},
      { field: 'Name', title: translate.t('laName'), width: '200px', cell: (props) => {
        return (
          <KendoGenericUrlCell
            page={'page-people'}
            empId={props.dataItem.EmpId}
            text={props.dataItem.Name}
          />
        );
      }},
      { field: 'EmpId', title: '' },
      { field: 'EmpNumber', title: translate.t('laEmpNumber'), width: '150px' },
      { field: 'Position', title: translate.t('laPosition'), width: '150px' },
      { field: 'Goal', title: translate.t('laGoal'), width: '200px', cell: (props) => {
        return (
          <KendoGenericUrlCell
            page={'page-performance'}
            empId={props.dataItem.EmpId}
            text={props.dataItem.Goal}
          />
        );
      }},
      { field: 'SuccessMeasures', title: translate.t('laSuccessMeasures'), width: '200px' },
      { field: 'StartDate', title: translate.t('laStartDate'), width: '100px' },
      { field: 'EndDate', title: translate.t('laEndDate'), width: '100px' },
      { field: 'Target', title: translate.t('laTarget'), width: '200px' },
      { field: 'Actual', title: translate.t('laActual'), width: '200px' },
      { field: 'Result', title: translate.t('laResult'), width: '200px' },
      { field: 'Weight', title: '%', width: '100px' },
      { field: 'Total', title: translate.t('laTotal'), width: '100px' },
      { field: 'Info', title: translate.t('laInfo'), width: '300px' },
      { field: 'HasGoals', title: '' },
      { field: 'HasReviews', title: '' }
    ],
    excelExportColumns: [
      { field: 'EmpId', title: 'Emp ID' },
      { field: 'Name', title: 'Name' },
      { field: 'EmpNumber', title: 'Emp Number' },
      { field: 'Position', title: 'Position' },
      { field: 'Goal', title: 'Goal' },
      { field: 'SuccessMeasures', title: 'Success Measures' },
      { field: 'StartDate', title: 'Start Date' },
      { field: 'EndDate', title: 'End Date' },
      { field: 'Target', title: 'Target' },
      { field: 'Actual', title: 'Actual' },
      { field: 'Result', title: 'Result' },
      { field: 'Weight', title: '%' },
      { field: 'Total', title: 'Total' },
      { field: 'Info', title: 'Info' }
    ],
    filters: {
      scopeFilter: {
        enabled: true,
        accessRights: {
          company: ['HR_ADMIN', 'MANAGER'],
          dManagerAll: ['DEPUTY_MANAGER'],
          dManagerDirect: ['DEPUTY_MANAGER'],
          managerAll: ['MANAGER'],
          managerDirect: ['MANAGER'],
          unitAll: ['MANAGER', 'DEPUTY_MANAGER', 'HR_STAFF', 'HR_ADMIN'],
          unitDirect: ['MANAGER', 'DEPUTY_MANAGER', 'HR_STAFF', 'HR_ADMIN'],
        },
      },
      customFilters: [
        {
          type: 'CHECKBOX',
          params: {
            selectedSettingName: 'laNoPlans',
            fieldName: 'HasGoals',
            operator: 'neq',
            buttonLabel: 'laNoPlans',
            value: false,
          },
        },
        {
          type: 'CHECKBOX',
          params: {
            selectedSettingName: 'laNoReviews',
            fieldName: 'HasReviews',
            operator: 'neq',
            buttonLabel: 'laNoReviews',
            value: false,
          },
        }
      ],
      dateFilter: {
        enabled: false,
      },
    },
  };

  private defaultSettings: IReportSettings = {
    report: 'pdd/performance',
    scope: {
      type: '',
      range: {
        startDate: moment().add(1, 'day').subtract(6, 'month'),
        endDate: moment().add(1, 'day'),
      }
    },
    custom: {
      laNoPlans: false,
      laNoReviews: false
    },
  };

  mapData = (origData: any): any => {
    const allEnums = this.props.allEnums;
    const getEnumValue = (enumCode: string, enumGroupKey: string) => {
      if (!enumCode || !enumGroupKey) {
        return '';
      }

      if (!allEnums[enumGroupKey]) {
        return enumCode;
      }

      return getEnumName(enumGroupKey, enumCode, allEnums);
    };

    const expandCallback = (acc: any[], or: any) => {
      const {
        fEmpGoalReviewsCommon,
        fEmpGoalReviewsPersonal,
        fEmpGoalSettingsCommon,
        fEmpGoalSettingsPersonal,
        ...expDataFragment
      } = or;

      const empGoalCallback = (currentGoal: any) => Object.assign({}, expDataFragment, {
        ...currentGoal,
        hasReviews: !!fEmpGoalReviewsCommon.length || !!fEmpGoalReviewsPersonal.length ? true : null,
        hasGoals: !!fEmpGoalSettingsCommon.length || !!fEmpGoalSettingsPersonal.length ? true : null,
      });

      const mappedGoalReviewsCommon = fEmpGoalReviewsCommon.map(empGoalCallback);
      const mappedReviewsPersonal = fEmpGoalReviewsPersonal.map(empGoalCallback);
      const mappedSettingsCommon = fEmpGoalSettingsCommon.map(empGoalCallback);
      const mappedSettingsPersonal = fEmpGoalSettingsPersonal.map(empGoalCallback);

      const result = acc
        .concat(mappedGoalReviewsCommon)
        .concat(mappedReviewsPersonal)
        .concat(mappedSettingsCommon)
        .concat(mappedSettingsPersonal);

      if (
        !mappedGoalReviewsCommon.length &&
        !mappedReviewsPersonal.length &&
        !mappedSettingsCommon.length &&
        !mappedSettingsPersonal.length
      ) {
        return result.concat([expDataFragment]);
      }

      return result;
    };

    const expandedData = origData.reduce(expandCallback, []);

    return expandedData.map((employee: any) => {
      return {
        Picture: employee.fEmpId,
        Name: employee.fEmpLastName + ', ' + employee.fEmpFirstName,
        EmpId: employee.fEmpId,
        EmpNumber: employee.fEmpPersonNumber,
        Position: getEnumValue(employee.fEmpPosition, 'EMPLOYEE_POSITION'),
        Goal: getEnumValue(employee.fPerfGoal, 'PERF_GOAL'),
        SuccessMeasures: getEnumValue(employee.fPerfSuccessMeasure, `PERF_GOAL_SUBGRP_${employee.fPerfGoal}`),
        StartDate: employee.fPerfStartDate ? moment(employee.fPerfStartDate, 'YYYY-MM-DD').format('DD.MM.YYYY') : '',
        EndDate: employee.fPerfEndDate ? moment(employee.fPerfEndDate, 'YYYY-MM-DD').format('DD.MM.YYYY') : '',
        Target: employee.fPerfTarget || '',
        Actual: employee.fPerfActual || '',
        Result: employee.fPerfResult || '',
        Weight: employee.fPerfPercent || '',
        Total: employee.fPerfTotal || '',
        HasGoals: employee.hasGoals,
        HasReviews: employee.hasReviews,
        Info: !employee.fPerfInfo ? '' : employee.fPerfInfo,
        HasAccount: employee.fEmpHasAccount 
      };
    });
  }

  render() {
    return (
      <ReportView
        moduleId="performance"
        reportId="GOAL_SETTINGS_AND_REVIEWS"
        config={this.config}
        defaultSettings={this.defaultSettings}
        kendoGrouping={[]}
        onDataMapping={this.mapData}
        onGetCustomFilters={() => this.config.filters.customFilters}
        sort={[{ field: 'Name', dir: 'asc' }]}
        exportFileName={translate.t('laGoalSettingReviewDetails')}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  allEnums: selectAllEnums(state),
});

const enhance = compose<any, any>(
  connect(mapStateToProps),
);

export default enhance(GoalSettingsAndReviewsReport);

import * as React from 'react';
import { Menu, MenuItem, Divider } from '@material-ui/core';
import translate from '@/app/utils/translate';
import { BonusRule } from '../../../BonusCalculationRules/BonusCalculationRules';

let openMenuFn: Function;

interface IState {
  anchorEl: any;
  onClick: (newRule: any, evt: React.MouseEvent) => void;
  rules: BonusRule[];
}

class BonusCalcRuleMenu extends React.PureComponent {
  state: IState = {
    anchorEl: null,
    rules: [],
    onClick: null,
  };

  componentDidMount() {
    openMenuFn = this.openMenu;
  }

  openMenu = (
    anchorEl: any,
    rules: BonusRule[],
    onClick: React.MouseEventHandler
  ) => {
    this.setState({
      anchorEl,
      rules,
      onClick,
    });
  }

  handleClick = (selectedRule: any, evt: React.MouseEvent) => {
    const { onClick } = this.state;
    this.handleClose();
    onClick(selectedRule, evt);
  }

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  }

  render() {
    const { anchorEl, rules } = this.state;
    return (
      <Menu
        id="rule-menu"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={this.handleClose}
      >
        {rules.map((rule: BonusRule) => (
          <MenuItem
            key={rule.id}
            onClick={(evt: React.MouseEvent) => this.handleClick(rule, evt)}
          >
            {rule.fRuleName}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem onClick={(evt: React.MouseEvent) => this.handleClick({ id: null, fRuleName: null }, evt)}>
          ({translate.t('option_not_included')})
        </MenuItem>
      </Menu>
    );
  }
}

export const openMenu = (
  anchorEl: any,
  rules: BonusRule[],
  onClick: (newRule: any, evt: React.MouseEvent) => void,
) => {
  openMenuFn(anchorEl, rules, onClick);
};

export default BonusCalcRuleMenu;

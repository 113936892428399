import { StyleRulesCallback } from '@material-ui/core';

const styles: StyleRulesCallback = () => ({
  content: {
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: 360,
  },
  flex: {
    display: 'flex',
  },
  grow: {
    flex: 1,
  },
});

export default styles;

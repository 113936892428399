import * as React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const ResizableIconButton = ({title, classes, size, ...props}: any) => {
  return (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={title}
    >
      <IconButton className={classes[size]} {...props} />
    </Tooltip>
  );
};

const styles = {
  small: {
    padding: 0,
    '& svg': {
      fontSize: 16
    },
    '& span': {
      marginRight: 0,

    },
    width: 30,
    height: 30,
  },
  medium: {
    '& svg': {
      fontSize: 24
    },
    '& span': {
      marginRight: 0
    },
  },
  large: {
    '& svg': {
      fontSize: 32
    },
    '& span': {
      marginRight: 0
    },
    width: 60,
    height: 60,
  }
};

export default withStyles(styles)(ResizableIconButton);

import React, {
  BaseSyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Checkbox,
  Icon,
  Menu,
  MenuItem,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import {
  Grid,
  GridColumn,
  GridDataStateChangeEvent,
  GridToolbar,
} from '@progress/kendo-react-grid';
import {
  ExcelExport,
  ExcelExportColumn,
  ExcelExportColumnGroup,
} from '@progress/kendo-react-excel-export';
import { process } from '@progress/kendo-data-query';
import compose from 'recompose/compose';

import ColumnMenu from '@/app/components/Kendo/ColumnMenu';
import Service from '@/app/utils/service';
import API, { Relation, Scope } from '@/app/api/internalAPIs';
import { ExportFilename } from '@/app/utils/fileValidation';
import {
  getLoggedUserId,
  getMyEmployeeUnitId,
  isAltManager,
  isDManager,
  isEmployee,
  isHR,
  isHRAdminLimited,
  isHRSalary,
  isHRPeople,
  isHRPeopleDev,
  isManager,
  isOnlyAltMgr,
  isRecruiter,
} from '@/old/utils/helper';
import translate from '@/app/utils/translate';

import { gridColumns, gridColumnsVacBalance } from './Columns/GridColumns';
import SelectFilter from './KendoFilters/SelectFilter';
import { Unit, VacationBalanceReportResponseItem } from './types';
import { mapReportForlatestYear, mapReportForYearlyBalance } from './service';
import styles from './styles';

type InnerProps = WithStyles<typeof styles>;
type OwnProps = {};

type Props = OwnProps & InnerProps;

const VacationBalancesReport = (props: Props) => {
  const GLOBAL: any = window;
  const allUnitsOption = {
    fTreeUnitId: '0',
    fTreeUnitName: translate.t('label_all'),
    fTreeUnitNumber: 'all-1',
  };

  const { classes } = props;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>(undefined);

  const [hrUnits, setHRUnits] = useState<Unit[]>([]);
  const [managerUnits, setManagerUnits] = useState<Unit[]>([]);
  const [altManagerUnits, setAltManagerUnits] = useState<Unit[]>([]);
  const [employeeUnits, setEmployeeUnits] = useState<Unit[]>([]);
  const [selectedUnit, setSelectedUnit] = useState<Unit>(allUnitsOption);

  const [relation, setRelation] = useState<Relation | undefined>(
    (isHR() || isHRSalary() || isHRPeople() || isHRPeopleDev() || isHRAdminLimited()) && !isManager() && !isAltManager()
      ? Relation.ALL
      : Relation.DIRECT,
  );

  const [entries, setEntries] = useState<any>([]);

  const gridCol = !!GLOBAL.bnEnableVacationBank
    ? gridColumnsVacBalance()
    : gridColumns();
  const [columns, setColumns] = useState(gridCol);

  const [dataState = { skip: 0, take: 9 }, setDataState] = useState();
  const [dropdownMenu, setDropdownMenu] = useState();

  const myUserId = useMemo(() => getLoggedUserId(), []);

  useEffect(() => {
    Service.get(
      API.orgTree.primaryUnits(),
      (allUnits: Unit[]) => {
        if (isHR() || isHRSalary() || isHRPeople() || isHRPeopleDev() || isHRAdminLimited()) {
          Service.get(
            API.orgTree.myHrUnits(myUserId),
            (unitIds: number[]) => {
              const myHRUnits = allUnits.filter((unit: Unit) =>
                unitIds.includes(+unit.fTreeUnitId),
              );
              setHRUnits(myHRUnits);
              setLoading(false);
              setError(undefined);
            },
            (err: any) => {
              setLoading(false);
              setError(err);
            },
          );
        }

        if (isManager() && !isAltManager()) {
          Service.get(
            API.orgTree.myManagedUnits(myUserId),
            (unitIds: number[]) => {
              const myManagerUnits = allUnits.filter((unit: Unit) =>
                unitIds.includes(+unit.fTreeUnitId),
              );
              setManagerUnits(myManagerUnits);
              setLoading(false);
              setError(undefined);
            },
            (err: any) => {
              setLoading(false);
              setError(err);
            },
          );
        }

        if (isAltManager()) {
          Service.get(
            API.orgTree.myAltManagerUnits(myUserId),
            (unitIds: number[]) => {
              const myAltManagerUnits = allUnits.filter((unit: Unit) =>
                unitIds.includes(+unit.fTreeUnitId),
              );
              setAltManagerUnits(myAltManagerUnits);
              setSelectedUnit(myAltManagerUnits[0]);
              setLoading(false);
              setError(undefined);
            },
            (err: any) => {
              setLoading(false);
              setError(err);
            },
          );
        }

        if (isEmployee() || isRecruiter() || isDManager()) {
          const myEmployeeUnit = allUnits.find(
            (unit: Unit) =>
              +unit.fTreeUnitId === +getMyEmployeeUnitId(myUserId),
          );
          setEmployeeUnits([myEmployeeUnit]);
          setSelectedUnit(myEmployeeUnit);
        }
      },
      (err: any) => {
        setLoading(false);
        setError(err);
      },
    );
  }, []);

  useEffect(() => {
    if (relation) {
      const resolveScope = (
        chosenUnit: any,
        chosenRelation: Relation,
      ): Scope => {
        // Unit Scope
        const isActualUnitSelected =
          chosenUnit && chosenUnit.fTreeUnitId !== allUnitsOption.fTreeUnitId;
        const empRoleCanViewUnitScopes =
          !isEmployee() && !isRecruiter() && !isDManager();

        if (isActualUnitSelected && empRoleCanViewUnitScopes) {
          return Scope.UNIT;
        }

        /**
         * Special condition:
         * Use Manager Scope for HR person managing Unit(s), when Relation is set to DIRECT
         *
         * @NOTE from Juhana:
         * This feels like some old quickfix case, and should probably be handled differently
         * Suggest for multi-role user to have option to toggle between HR & Manager scopes as needed.
         */
        if (
          (isHR() || isHRSalary() || isHRPeople() || isHRPeopleDev() || isHRAdminLimited()) &&
          isManager() &&
          !isAltManager() &&
          managerUnits.length > 0 &&
          chosenRelation === Relation.DIRECT
        ) {
          return Scope.MANAGER;
        }

        // Role scopes
        if (isHR() || isHRSalary() || isHRPeople() || isHRPeopleDev() || isHRAdminLimited()) {
          return Scope.HR;
        }

        if (isManager() && !isOnlyAltMgr()) {
          return Scope.MANAGER;
        }

        if (isOnlyAltMgr()) {
          return Scope.ALT_MANAGER;
        }

        return Scope.EMPLOYEE;
      };

      const scope = resolveScope(selectedUnit, relation);
      const scopeId =
        scope === Scope.UNIT ? selectedUnit.fTreeUnitId : myUserId;

      Service.get(
        API.vacationBalancesReport.getReport(scope, scopeId, relation),
        (res: VacationBalanceReportResponseItem[]) => {
          !!GLOBAL.bnEnableVacationBank
            ? setColumns(Array.from(new Set([...gridColumnsVacBalance()])))
            : setColumns(Array.from(new Set([...gridColumns()])));
          !!GLOBAL.bnEnableYearlyVacationBalance
            ? setEntries(mapReportForYearlyBalance(res)) //
            : setEntries(mapReportForlatestYear(res));
          setLoading(false);
          setError(undefined);
        },
        (err: any) => {
          setEntries([]);
          setLoading(false);
          setError(err);
        },
      );
    } else {
      setEntries([]);
    }
  }, [selectedUnit, relation]);

  const unitOptions = useMemo(() => {
    const uniqueUnits = Array.from(
      new Set([
        ...hrUnits,
        ...managerUnits,
        ...altManagerUnits,
        ...employeeUnits,
      ]),
    );

    // Include All Units option
    const userRoleAllowsAllUnitsOption = !(isEmployee() || isAltManager());
    if (userRoleAllowsAllUnitsOption && uniqueUnits.length > 0) {
      return [allUnitsOption, ...uniqueUnits];
    }

    return uniqueUnits;
  }, [hrUnits, managerUnits, altManagerUnits, employeeUnits]);

  if (loading) {
    return <div>{loading}</div>;
  }
  if (error) {
    return <div>{'error'}</div>;
  }
  if (!entries) {
    return <div>{'no data'}</div>;
  }

  const unitFilterCell = SelectFilter(
    unitOptions
      .filter(u => u.fTreeUnitId !== allUnitsOption.fTreeUnitId)
      .map(u => u.fTreeUnitName),
    translate.t('laSelectUnit'),
  );

  const onColumnsSubmit = (columnsState: any) => {
    setColumns(columnsState);
  };

  const createDataState = (dataStateProp: any) => {
    return {
      dataState: setDataState(dataStateProp),
    };
  };

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    return createDataState(event.data);
  };

  let _export: any;
  const saveClosedWorkflowsReport = () => {
    _export.save(
      process(entries, {
        ...dataState, // Export all the records
        skip: 0,
        take: undefined,
      }),
    );
  };

  const handleTeamCheckboxChange = (
    _: BaseSyntheticEvent,
    checked: boolean,
  ) => {
    setRelation(checked ? Relation.DIRECT : Relation.ALL);
  };

  const handleSubunitsCheckboxChange = (
    _: BaseSyntheticEvent,
    checked: boolean,
  ) => {
    setRelation(checked ? Relation.ALL : Relation.DIRECT);
  };

  const selectUnit = (unit: Unit) => () => {
    setSelectedUnit(unit);
    setDropdownMenu(null);
  };

  const hasUnits = unitOptions.length > 0;
  const ToolbarUnitSelect = (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={(event: any) => setDropdownMenu(event.currentTarget)}
      >
        {translate.t('laSelectUnit')}{' '}
        <Icon fontSize="small">arrow_drop_down</Icon>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={dropdownMenu}
        keepMounted={false}
        open={Boolean(dropdownMenu)}
        onClose={() => setDropdownMenu(null)}
      >
        {unitOptions.map((unit: Unit) => (
          <MenuItem
            key={unit.fTreeUnitId}
            onClick={selectUnit(unit)}
            selected={selectedUnit.fTreeUnitId === unit.fTreeUnitId}
            disabled={
              (isRecruiter() || isDManager()) && unit.fTreeUnitName === 'All'
            }
          >
            {unit.fTreeUnitName}
          </MenuItem>
        ))}
        {!hasUnits && (
          <MenuItem key={'noUnits'} selected={false} disabled={true}>
            {translate.t('laNoOptionsAvailable')}
          </MenuItem>
        )}
      </Menu>
      {hasUnits && selectedUnit && selectedUnit.fTreeUnitName}
    </>
  );

  const ToolbarManagerAndHr = (
    <div className={classes.actionBar}>
      {ToolbarUnitSelect}

      {isManager() || isHRSalary() || isHRPeople() || isHRPeopleDev() && (
        <span>
          <Checkbox
            checked={relation === Relation.DIRECT}
            onChange={handleTeamCheckboxChange}
            value="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          {translate.t('laTeam')}
        </span>
      )}

      {!isEmployee() && !isRecruiter() && !isDManager() && (
        <span>
          <Checkbox
            checked={relation === Relation.ALL}
            onChange={handleSubunitsCheckboxChange}
            value="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          {translate.t('laIncludeSubunits')}
        </span>
      )}
      <Button
        onClick={saveClosedWorkflowsReport}
        className={classes.downloadButton}
      >
        {translate.t('laDownload')} &nbsp;{' '}
        <Icon fontSize="small">cloud_download</Icon>
      </Button>
    </div>
  );

  if (!!GLOBAL.bnEnableYearlyVacationBalance) {
    columns.map((c: any) => {
      c.field && c.field === 'vacationYear' && (c.show = true);
    });
  } else {
    columns.map((c: any) => {
      c.field && c.field === 'vacationYear' && (c.show = false);
    });
  }
  const renderColumns = (columnsData: any) => {
    const unitFields = ['unitName', 'subMgrUnitName'];
    return columnsData.map(
      (column: any, idx: number) =>
        column.show && (
          <GridColumn
            {...column}
            key={idx}
            columnMenu={(otherProps: any) => (
              <ColumnMenu
                {...otherProps}
                columns={columnsData}
                onColumnsSubmit={onColumnsSubmit}
                filterUI={
                  unitFields.includes(column.field) ? unitFilterCell : undefined
                }
              />
            )}
          >
            {column.columns &&
              column.columns.length > 0 &&
              renderColumns(column.columns)}
          </GridColumn>
        ),
    );
  };

  const renderExcelExportColumns = (columnsData: any) => {
    return columnsData.map((column: any, idx: number) => {
      if (column.columns) {
        return (
          <ExcelExportColumnGroup key={idx} title={column.title}>
            {renderExcelExportColumns(column.columns)}
          </ExcelExportColumnGroup>
        );
      }

      const isPictureColumn = column.title === translate.t('laPicture');

      return (
        <ExcelExportColumn
          key={idx}
          field={column.field}
          title={column.title}
          hidden={isPictureColumn}
        />
      );
    });
  };

  return (
    <>
      <ExcelExport
        data={process(entries, dataState).data}
        fileName={new ExportFilename(
          translate.t('laVacationBalancesReport'),
        ).XLSX()}
        ref={excelExport => (_export = excelExport)}
      >
        {renderExcelExportColumns(columns)}
      </ExcelExport>
      <Grid
        data={process(entries, dataState)}
        onDataStateChange={dataStateChange}
        {...dataState}
        sortable={true}
        resizable={true}
        pageable={true}
        groupable={true}
      >
        <GridToolbar>{ToolbarManagerAndHr}</GridToolbar>
        {renderColumns(columns)}
      </Grid>
    </>
  );
};

const enhance = compose<Props, OwnProps>(withStyles(styles));

export default enhance(VacationBalancesReport);

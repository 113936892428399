import * as React from 'react';
import { compose } from 'recompose';
import {
  withStyles,
  WithStyles,
  Paper,
  Button,
  TextField,
  MenuItem,
  IconButton,
  Typography,
} from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import translate from '@/app/utils/translate';
import { getCurrentLanguage } from '@/app/utils/helper';
import { getCompanyLanguages } from '@/old/utils/helper';
import Loading from '@/app/components/Loading/Loading';
import translationDialogStyle from './translationDialogStyle';

type IncomingProps = {
  title: string;
  disabledSave: boolean;
  loadLocalizedData: (lang: string) => Promise<void>;
  localizedDataForm: React.ReactNode;
  onSubmit: (lang: string) => void;
  onCancel: () => void;
};

type PropsType = IncomingProps & WithStyles<typeof translationDialogStyle>;

type StateType = {
  language: string;
  loadingLocalizedData: boolean;
  failedToLoadLocalizedData: boolean;
};

class TranslationDialog extends React.PureComponent<PropsType> {
  state: StateType = {
    language: getCurrentLanguage(),
    loadingLocalizedData: false,
    failedToLoadLocalizedData: false,
  };

  componentDidMount() {
    this.loadLocalizedData();
  }

  componentDidUpdate(_prevProps: PropsType, prevState: StateType) {
    if (prevState.language !== this.state.language) {
      this.loadLocalizedData();
    }
  }

  loadLocalizedData = () => {
    this.setState({
      loadingLocalizedData: true,
      failedToLoadLocalizedData: false,
    });

    this.props
      .loadLocalizedData(this.state.language)
      .then(() => {
        this.setState({
          loadingLocalizedData: false,
          failedToLoadLocalizedData: false,
        });
      })
      .catch(() => {
        this.setState({
          loadingLocalizedData: false,
          failedToLoadLocalizedData: true,
        });
      });
  };

  handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ language: e.target.value });
  };

  handleSubmit = (e: any) => {
    e.preventDefault();
    this.props.onSubmit(this.state.language);
  };

  render() {
    const {
      classes,
      title,
      disabledSave,
      localizedDataForm,
      onCancel,
    } = this.props;
    const {
      language,
      loadingLocalizedData,
      failedToLoadLocalizedData,
    } = this.state;

    const disableSubmit =
      disabledSave || loadingLocalizedData || failedToLoadLocalizedData;

    return (
      <Paper className={classes.root}>
        <form onSubmit={this.handleSubmit}>
          <h3>{title}</h3>

          <TextField
            select
            name="language"
            value={language}
            onChange={this.handleLanguageChange}
            fullWidth
          >
            {getCompanyLanguages().map(
              ({ fComLanguageCode, fComLanguageName }: any) => {
                return (
                  <MenuItem key={fComLanguageCode} value={fComLanguageCode}>
                    {fComLanguageName}
                  </MenuItem>
                );
              },
            )}
          </TextField>

          <div className={classes.localizedContent}>
            {failedToLoadLocalizedData ? (
              <div className={classes.loadErrorContainer}>
                <Typography color="error">
                  {translate.t('failed_to_load_localized_data')}
                </Typography>

                <IconButton onClick={this.loadLocalizedData}>
                  <ReplayIcon color="primary" />
                </IconButton>
              </div>
            ) : loadingLocalizedData ? (
              <Loading size="small" />
            ) : (
              localizedDataForm
            )}
          </div>

          <div className={classes.buttonsWrapper}>
            <Button color="primary" variant="text" onClick={onCancel}>
              {translate.t('laCancel')}
            </Button>

            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={disableSubmit}
            >
              {translate.t('laSave')}
            </Button>
          </div>
        </form>
      </Paper>
    );
  }
}

const enhance = compose<any, any>(withStyles(translationDialogStyle));

export default enhance(TranslationDialog);

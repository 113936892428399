import { Record } from 'immutable';
import { Dispatch } from 'redux';
import * as moment from 'moment';
import API from '@/app/api/internalAPIs';
import Service from '@/app/utils/service';
import { getEmployeeName, getSelectedEmpId } from '@/old/utils/helper';
import translate from '@/app/utils/translate';
import { THROW_ERROR } from './error';
import { OPEN_NOTIFIER } from './notifier';
import { getDefault } from '@/app/components/TemplateComponents/helpers';
import { AppraisalFormTemplate } from '@/app/components/TemplateComponents/types';

enum FileType {
  FILE = 'FILE',
  DELETE_FILE = 'DELETE_FILE',
}

type Stage = {
  default: boolean;
  id: number;
  index: number;
  isDeleted: boolean;
  label: string;
};

const getUniqueStages = function(stages: Stage[]) {
  if (!stages || !stages.length) {
    return stages;
  }

  return stages.reduce((acc, stage) => {
    const isIdDuplicated = acc.find(
      existingStage => existingStage.id === stage.id,
    );
    const isNameDuplicated = acc.find(
      existingStage => existingStage.label === stage.label,
    );
    if (stage.isDeleted || isIdDuplicated || isNameDuplicated) {
      return acc;
    }

    return acc.concat(stage);
  }, []);
};

const initialState = Record({
  allAppraisalTemplates: [],
  activeTemplate: {} as any,
  appraisalEvaluation: { responses: [] } as any,
  archivedEvaluations: [],
});

const FETCH_ALL_APPRAISAL_TEMPLATES =
  '@@solaforce/appraisalEvaluation/FETCH_ALL_APPRAISAL_TEMPLATES';
const FETCH_ARCHIVED_APPRAISALS =
  '@@solaforce/appraisalEvaluation/FETCH_ARCHIVED_APPRAISALS';
const CLEAR_ARCHIVED_APPRAISALS =
  '@@solaforce/appraisalEvaluation/CLEAR_ARCHIVED_APPRAISALS';
const SET_INITIAL_TEMPLATE =
  '@@solaforce/appraisalEvaluation/SET_INITIAL_TEMPLATE';
const SET_ACTIVE_TEMPLATE =
  '@@solaforce/appraisalEvaluation/SET_ACTIVE_TEMPLATE';
const SAVE_EMPLOYEE_EVALUATION =
  '@@solaforce/appraisalEvaluation/SAVE_EMPLOYEE_EVALUATION';
const CHANGE_TEMPLATE_APPROVEMENT = '@@solaforce/appraisalEvaluation/APPROVE';
const CHANGE_EVALUATION_STAGE = '@@solaforce/appraisalEvaluation/STAGE_CHANGE';
const UPDATE_ACTIVE_TEMPLATE =
  '@@solaforce/appraisalEvaluation/UPDATE_ACTIVE_TEMPLATE';
const ARCHIVE_APPRAISAL_EVALUATION =
  '@@solaforce/appraisalEvaluation/ARCHIVE_APPRAISAL_EVALUATION';
const UPDATE_APPRAISAL_EVALUATION =
  '@@solaforce/appraisalEvaluation/UPDATE_APPRAISAL_EVALUATION';
const LOAD_APPRAISAL_EVALUATION =
  '@@solaforce/appraisalEvaluation/LOAD_APPRAISAL_EVALUATION';
const CLEAR_EVALUATION = '@@solaforce/appraisalEvaluation/CLEAR_EVALUATION';

// Helper functions to format strings for appraisals approval
const formatedListOnRemoval = (
  initialList: string,
  approvingUserName: string,
) => {
  if (initialList.indexOf(', ' + approvingUserName) !== -1) {
    return initialList.replace(', ' + approvingUserName, '');
  } else if (initialList.indexOf(approvingUserName + ', ') !== -1) {
    return initialList.replace(approvingUserName + ', ', '');
  } else {
    return initialList.replace(approvingUserName, '');
  }
};

const formatedAddition = (initialList: string, approvingUserName: string) => {
  if (initialList.length > 0) {
    return initialList + ', ' + approvingUserName;
  } else {
    return approvingUserName;
  }
};

const updateLastModifiedBy = (template: any) => {
  if (!template.updatedBy || !template.updatedBy.employeeId) {
    return template.lastModifiedBy || '';
  }

  return getEmployeeName(template.updatedBy.employeeId, true);
};

const mapEmpIdsToNames = (empIds: number[]): string[] => {
  if (!Array.isArray(empIds)) {
    return [];
  }

  return empIds.map(empId => getEmployeeName(empId, true));
};

// Helper function to update components
const updateResponse = (listOfResponses: object[], answer: any) => {
  let newListOfResponses = Object.assign([], listOfResponses);
  let found = false;
  for (var i = 0; i < newListOfResponses.length; i++) {
    if (newListOfResponses[i].componentId === answer.componentId) {
      found = true;
      // Updates text components
      if (
        answer.type === 'TEXT' ||
        answer.type === 'DATE' ||
        answer.type === 'SCALE'
      ) {
        newListOfResponses[i] = answer;
      }
      // Updates checkbox components
      if (answer.type === 'CHECKBOXES') {
        if (newListOfResponses[i].response.includes(answer.response[0])) {
          newListOfResponses[i].response = newListOfResponses[
            i
          ].response.filter((value: number) => value !== answer.response[0]);
        } else {
          newListOfResponses[i].response.push(answer.response[0]);
        }
      }
      // Updates evaluations components
      if (['EVALUATIONS', 'COMPETENCY_LEVEL'].includes(answer.type)) {
        newListOfResponses[i].response.map(
          (evalQuestion: any, index: number) => {
            if (evalQuestion.optionId === answer.response[0].optionId) {
              newListOfResponses[i].response[index] = {
                ...evalQuestion,
                ...answer.response[0],
              };
            } else if (
              newListOfResponses[i].response.find(
                (o: any) => o.optionId === answer.response[0].optionId,
              ) === undefined
            ) {
              newListOfResponses[i].response.push(answer.response[0]);
            }
          },
        );
      }
      // Updates dropdown components
      if (
        answer.type === 'DROPDOWN' ||
        answer.type === 'MULTISELECT_DROPDOWN'
      ) {
        newListOfResponses[i].response = answer.response;
      }
      if (answer.type === FileType.FILE) {
        newListOfResponses.splice(i, 1);
        if (answer.file !== FileType.DELETE_FILE) {
          newListOfResponses.push(answer);
        }
      }
    }
  }

  if (!found) {
    // Pushes a new component to the list of answers
    newListOfResponses.push(answer);
  }
  return newListOfResponses;
};

const reducer = (state = new initialState(), action: any) => {
  switch (action.type) {
    case FETCH_ALL_APPRAISAL_TEMPLATES:
      const { allAppraisalTemplates } = action.payload;
      const processedAppraisalTemplates = allAppraisalTemplates.map(
        (appraisalTemplate: any) => {
          return {
            ...appraisalTemplate,
            stages: getUniqueStages(appraisalTemplate.stages),
          };
        },
      );

      return state.set('allAppraisalTemplates', processedAppraisalTemplates);

    case FETCH_ARCHIVED_APPRAISALS:
      const { archivedEvaluations } = action.payload;
      return state.set('archivedEvaluations', archivedEvaluations);

    case CLEAR_ARCHIVED_APPRAISALS:
      return state.set('archivedEvaluations', []);

    case SET_INITIAL_TEMPLATE:
      const { activeEval } = action.payload;
      return state.set('activeTemplate', activeEval);

    case SET_ACTIVE_TEMPLATE:
      const { templateSelected } = action.payload;
      return state.set('activeTemplate', templateSelected);

    case SAVE_EMPLOYEE_EVALUATION:
      // const { fullResponse } = action.payload; // Just for checks
      return state;

    case UPDATE_ACTIVE_TEMPLATE:
      const { approvingUser } = action.payload;
      const activeTemp = state.get('activeTemplate');

      const updatedActiveTemplate = {
        ...activeTemp,
        approvedBy: activeTemp.approvedBy.includes(approvingUser)
          ? formatedListOnRemoval(activeTemp.approvedBy, approvingUser)
          : formatedAddition(activeTemp.approvedBy, approvingUser),
        lastEdited: moment().format('YYYY-MM-DD'),
        lastModifiedBy: approvingUser,
      };

      return state.set('activeTemplate', updatedActiveTemplate);

    case LOAD_APPRAISAL_EVALUATION: {
      const { response } = action;
      const tmpID = response.templateId;
      const allAnswers = response.responses;
      return state.set('appraisalEvaluation', { tmpID, responses: allAnswers });
    }

    case UPDATE_APPRAISAL_EVALUATION:
      const { templateId, componentAnswer } = action.payload;
      const appraisalEvaluationAnswers = state.get('appraisalEvaluation')
        .responses;
      const updatedAnswers = updateResponse(
        appraisalEvaluationAnswers,
        componentAnswer,
      );

      return state.set('appraisalEvaluation', {
        templateId,
        responses: updatedAnswers,
      });

    case CLEAR_EVALUATION:
      return state.set('appraisalEvaluation', { responses: [] });

    case ARCHIVE_APPRAISAL_EVALUATION:
      const { templateToArhive } = action.payload;
      const allAppraisalsUpdate = state
        .get('allAppraisalTemplates')
        .map((item: any) => {
          if (item.id === templateToArhive) {
            return {
              ...item,
              archived: true,
            };
          } else {
            return item;
          }
        });
      return state.set('allAppraisalTemplates', allAppraisalsUpdate);

    case CHANGE_TEMPLATE_APPROVEMENT:
      const { evaluationToUpdate, newApproval } = action.payload;

      const updatedApprovedStatus = state
        .get('allAppraisalTemplates')
        .map((item: any) => {
          if (item.id === evaluationToUpdate) {
            if (item.approvedBy.includes(newApproval)) {
              return {
                ...item,
                approvedBy: formatedListOnRemoval(item.approvedBy, newApproval),
                lastEdited: moment().format('YYYY-MM-DD'),
                lastModifiedBy: newApproval,
              };
            } else {
              return {
                ...item,
                approvedBy: formatedAddition(item.approvedBy, newApproval),
                lastEdited: moment().format('YYYY-MM-DD'),
                lastModifiedBy: newApproval,
              };
            }
          }
          return item;
        });
      return state.set('allAppraisalTemplates', updatedApprovedStatus);
    default:
      return state;

    case CHANGE_EVALUATION_STAGE:
      const { setStage, userMakingTheChange } = action.payload;
      const activeTmp = state.get('activeTemplate');
      const updatedWStateChange = {
        ...activeTmp,
        setStage: setStage,
        lastEdited: moment().format('YYYY-MM-DD'),
        lastModifiedBy: userMakingTheChange,
      };
      return state.set('activeTemplate', updatedWStateChange);
  }
};

export const getEvaluationInformation = (
  template: AppraisalFormTemplate,
  employeeId: number,
) => {
  return (dispatch: Dispatch) => {
    return Service.get(
      API.appraisalsEvaluations.getEvaluationDetails(template.id, employeeId),
      (response: any) => {
        let payload = {
          activeEval: {
            id: response.templateId,
            currentEvaluationId: response.id,
            subject: template.subject,
            deadline: template.deadline,
            sections: template.sections,
            stages: template.stages,
            isTeam: template.isTeam,
            updatedBy: response.updatedBy,
            setStage: response.stageId
              ? template.stages.filter(
                  (stage: any) => stage.id === response.stageId,
                )[0]
              : getDefault(template.stages),
            lastEdited: moment(response.updatedTime).format('YYYY-MM-DD'),
            lastModifiedBy: response.updatedBy
              ? formatedAddition('', getEmployeeName(response.updatedBy, true))
              : updateLastModifiedBy(template),
            completionStatus: response.status,
            approvedBy: response.approvedBy
              .map((x: any) => getEmployeeName(x, true))
              .reduce(formatedAddition, ''),
            responses: response.responses,
          },
        };
        dispatch({ type: SET_INITIAL_TEMPLATE, payload });
        dispatch({ type: LOAD_APPRAISAL_EVALUATION, response });

        return response;
      },
      (error: any) => {
        if (error.data.error.type === 'NOT FOUND') {
          const payload = {
            activeEval: {
              id: template.id,
              subject: template.subject,
              completionStatus: 'IN_PROGRESS',
              deadline: template.deadline,
              approvedBy: '',
              sections: template.sections,
              stages: template.stages,
              isTeam: template.isTeam,
              setStage: getDefault(template.stages),
              lastEdited: moment(template.updatedTime).format('YYYY-MM-DD'),
              lastModifiedBy: updateLastModifiedBy(template),
            },
          };
          dispatch({ type: SET_INITIAL_TEMPLATE, payload });
          dispatch({ type: CLEAR_EVALUATION });
        } else {
          dispatch({ type: THROW_ERROR, error });
        }
      },
    );
  };
};

export const fetchAllAppraisals = () => {
  return (dispatch: Dispatch) => {
    return Service.get(
      API.appraisalsEvaluations.getTemplateList(getSelectedEmpId()),
      (response: any) => {
        const payload = {
          allAppraisalTemplates: response.data,
        };
        dispatch({ type: FETCH_ALL_APPRAISAL_TEMPLATES, payload });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const fetchArchivedAppraisals = (employeeId: number) => {
  return (dispatch: Dispatch) => {
    Service.get(
      API.appraisalsEvaluations.getArchivedEvaluations(employeeId),
      (response: any) => {
        const payload = { archivedEvaluations: response.data };
        dispatch({ type: FETCH_ARCHIVED_APPRAISALS, payload });
      },
      (_error: any) => dispatch({ type: CLEAR_ARCHIVED_APPRAISALS }),
    );
  };
};

export const setActiveTemplate = (
  templateSelected: AppraisalFormTemplate,
  employeeId: number,
  fromArchive: boolean = false,
) => {
  return (dispatch: Dispatch) => {
    const url = fromArchive
      ? API.appraisalsEvaluations.getArchivedEvaluationDetails(
          templateSelected.id,
        )
      : API.appraisalsEvaluations.getEvaluationDetails(
          templateSelected.id,
          employeeId,
        );

    return Service.get(
      url,
      (response: any) => {
        let payload = {
          templateSelected: {
            id: response.templateId,
            currentEvaluationId: response.id,
            subject: templateSelected.subject,
            deadline: templateSelected.deadline,
            sections: templateSelected.sections,
            stages: templateSelected.stages,
            isTeam: templateSelected.isTeam,
            updatedBy: response.updatedBy,
            setStage: response.stageId
              ? templateSelected.stages.filter(
                  (stage: any) => stage.id === response.stageId,
                )[0]
              : getDefault(templateSelected.stages),
            lastEdited: moment(response.updatedTime).format('YYYY-MM-DD'),
            lastModifiedBy: response.updatedBy
              ? formatedAddition('', getEmployeeName(response.updatedBy, true))
              : updateLastModifiedBy(templateSelected),
            completionStatus: response.status,
            approvedBy: mapEmpIdsToNames(response.approvedBy).join(', '),
            archived: fromArchive,
            responses: response.responses,
          },
        };

        dispatch({ type: SET_ACTIVE_TEMPLATE, payload });
        dispatch({ type: LOAD_APPRAISAL_EVALUATION, response });
      },
      (error: any) => {
        // TODO this happens when active template is being reloaded after changes are canceled
        if (error && error.data.error.type === 'NOT FOUND') {
          const payload = {
            templateSelected: {
              id: templateSelected.id,
              subject: templateSelected.subject,
              completionStatus: 'IN_PROGRESS',
              deadline: templateSelected.deadline,
              approvedBy: '',
              sections: templateSelected.sections,
              stages: templateSelected.stages,
              isTeam: templateSelected.isTeam,
              setStage: getDefault(templateSelected.stages),
              lastEdited: moment(templateSelected.updatedTime).format(
                'YYYY-MM-DD',
              ),
              lastModifiedBy: updateLastModifiedBy(templateSelected),
            },
          };
          dispatch({ type: SET_ACTIVE_TEMPLATE, payload });
          dispatch({ type: CLEAR_EVALUATION });
        } else {
          dispatch({ type: THROW_ERROR, error });
        }
      },
    );
  };
};

export const updateEvaluation = (templateId: string, componentAnswer: {}) => {
  return (dispatch: Dispatch) => {
    const payload = {
      templateId: templateId,
      componentAnswer: componentAnswer,
    };
    dispatch({ type: UPDATE_APPRAISAL_EVALUATION, payload });
  };
};

export const clearEvaluation = (_templateId: string) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: CLEAR_EVALUATION });
  };
};

const saveFileForEvaluation = async (
  responseFile: any,
  evaluationIds: Array<string>,
  dispatch: Dispatch,
) => {
  const formData = new FormData();
  formData.append('file', responseFile.file);
  formData.append('componentId', responseFile.componentId);
  evaluationIds.forEach((evaluationId: string) => {
    formData.append('evaluationIds[]', evaluationId);
  });

  return Service.postFormData(
    API.appraisalsEvaluations.uploadFile(),
    formData,
    (response: any) => response,
    (error: any) => dispatch({ type: THROW_ERROR, error }),
  );
};

export const saveEmployeeEvaluations = (
  templateId: string,
  fullResponse: any = {},
) => {
  const responseFiles = fullResponse.responses
    .filter((element: any) => element.file !== undefined)
    .map((element: any) => {
      return { componentId: element.componentId, file: element.file };
    });

  const responsesWithoutFiles = fullResponse.responses.map(
    ({ file, ...returnValue }: any) => {
      return returnValue;
    },
  );
  fullResponse.responses = responsesWithoutFiles;
  return (dispatch: Dispatch) => {
    return Service.post(
      API.appraisalsEvaluations.saveEvaluation(templateId),
      fullResponse,
      async (response: any) => {
        const evaluationIds = response.map((element: any) => {
          return element.id;
        });

        for (const file of responseFiles) {
          await saveFileForEvaluation(file, evaluationIds, dispatch);
        }
        const payload = {
          fullResponse: fullResponse,
        };
        dispatch({ type: SAVE_EMPLOYEE_EVALUATION, payload });
        dispatch({
          type: OPEN_NOTIFIER,
          payload: {
            message: translate.t('change_saved'),
          },
        });
        return response;
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const archiveEvaluation = (appraisalEvaluationId: string) => {
  return (dispatch: Dispatch) => {
    return Service.post(
      API.appraisalsEvaluations.archiveEvaluation(appraisalEvaluationId),
      {},
      (response: any) => {
        const payload = {
          templateToArhive: appraisalEvaluationId,
        };
        dispatch({ type: ARCHIVE_APPRAISAL_EVALUATION, payload });
        dispatch({
          type: OPEN_NOTIFIER,
          payload: {
            message: translate.t('evaluation_archived'),
          },
        });
        return response;
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const changeEvaluationApproval = (
  evaluationId: string,
  newApproval: string,
) => {
  return (dispatch: Dispatch) => {
    Service.post(
      API.appraisalsEvaluations.approveEvaluation(evaluationId),
      { newApproval: newApproval },
      (_response: any) => {
        const payload = {
          evaluationToUpdate: evaluationId,
          newApproval: newApproval,
          approvingUser: newApproval,
        };
        dispatch({ type: CHANGE_TEMPLATE_APPROVEMENT, payload });
        dispatch({ type: UPDATE_ACTIVE_TEMPLATE, payload });
        dispatch({
          type: OPEN_NOTIFIER,
          payload: { message: translate.t('change_saved') },
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const removeEvaluationApproval = (
  evaluationId: string,
  newApproval: string,
) => {
  return (dispatch: Dispatch) => {
    Service.post(
      API.appraisalsEvaluations.unapproveEvaluation(evaluationId),
      { newApproval: newApproval },
      (_response: any) => {
        const payload = {
          evaluationToUpdate: evaluationId,
          newApproval: newApproval,
          approvingUser: newApproval,
        };
        dispatch({ type: CHANGE_TEMPLATE_APPROVEMENT, payload });
        dispatch({ type: UPDATE_ACTIVE_TEMPLATE, payload });
        dispatch({
          type: OPEN_NOTIFIER,
          payload: { message: translate.t('change_saved') },
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const changeEvaluationStage = (
  setStage: any,
  userMakingTheChange: string,
) => {
  return (dispatch: Dispatch) => {
    const payload = {
      setStage: setStage,
      userMakingTheChange: userMakingTheChange,
    };
    dispatch({ type: CHANGE_EVALUATION_STAGE, payload });
  };
};

export default reducer;

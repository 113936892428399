import {
  Button,
  Checkbox,
  Paper,
  WithStyles,
  withStyles,
} from '@material-ui/core';

import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Dispatch } from 'redux';
import * as moment from 'moment';

import API, { Relation } from '@/app/api/internalAPIs';
import { Enum, selectAllEnums } from '@/app/redux/enums';
import { fetchAllTemplates } from '@/app/redux/goals';
import { ReducerState } from '@/app/redux/store';
import Service from '@/app/utils/service';
import translate from '@/app/utils/translate';
import {
  getEmployeeInfoById,
  getEmployeeName,
  getLoggedUserId,
  isHR,
  isManager,
} from '@/old/utils/helper';
import { useUnits } from '@/app/hooks/useUnits';
import KendoProfileImageCell from '../components/KendoProfileImageCell';
import KendoGenericUrlCell from '../components/KendoGenericUrlCell';
import { IReportConfig } from '../ReportConfig';

import EmpGoals from './components/EmpGoals/EmpGoals';
import TemplateGrid from './components/TemplateGrid/TemplateGrid';
import UnitGrid from './components/UnitGrid/UnitGrid';
import {
  filterEvaluationData,
  getEnumValue,
  getEvaluationStatus,
  getTemplateSubjectById,
  getUnitEvaluationCombinedStatusDistribution,
  getUserRolePerspective,
  searchMatchingTemplates,
  templateStatusTranslation,
} from './helpers';
import { EmpUnitEval, ITemplateFull, IUnitGrid, IUnitGridData, TemplateStatus } from './types';
import styles from './styles';
import { SnackbarProvider } from 'notistack';
import { ReportSettingsTypes, ReportSettingsUtils } from '../../ReportSettingsUtils';

type MapStateToProps = {
  allGoalTemplates: ITemplateFull[];
  allEnums: Enum[];
};

type MapDispatchToProps = {
  fetchGoalTemplates: () => Promise<ITemplateFull[]>;
};

type OwnProps = {};
type InnerProps = WithStyles<typeof styles>;
type Props = MapStateToProps & MapDispatchToProps & OwnProps & InnerProps;

enum ScopesValues {
  CURRENT_EMPLOYEES = 'CURRENT_EMPLOYEES',
  ENDED_EMPLOYEES = 'ENDED_EMPLOYEES',
  ALL_EMPLOYEES = 'ALL_EMPLOYEES'
}

export type GoalsReportConfig = {
  templates: {
    selected: string[],
    scope: ScopesValues,
    searchQuery: string,
  },
  units: {
    selected: string,
    tableStatus: any,
    columnsState: any,
    teamChecked: Relation,
    subUnitsChecked: boolean,
  },
  reviews: {
    columnsOrder: string[],
    tableStatus: any
  }
};

export enum GoalsReportConfigFields {
  templates = 'templates',
  units = 'units',
  reviews = 'reviews'
}

const getTotal = (result?: number, weight?: number) => {
  if (!result || !weight) {
    return '';
  }

  const total = (result * (weight / 100)).toFixed(3);
  return Math.trunc(parseFloat(total) * 1000) / 1000;
};

const config: IReportConfig = {
  id: '',
  report: '',
  titleLabel: 'laGoalSettingReviewDetails',
  access: 'standard',
  columns: [
    {
      field: 'Picture',
      title: translate.t('laPicture'),
      width: '80px',
      cell: kendoProp => {
        return <KendoProfileImageCell {...kendoProp} />;
      },
    },
    {
      field: 'Name',
      title: translate.t('laName'),
      width: '200px',
      cell: kendoProp => {
        return (
          <KendoGenericUrlCell
            page={'page-people'}
            empId={kendoProp.dataItem.EmpId}
            text={kendoProp.dataItem.Name}
          />
        );
      },
    },
    { field: 'EmpId', title: '' },
    { field: 'EmpNumber', title: translate.t('laEmpNumber'), width: '150px' },
    { field: 'Position', title: translate.t('laPosition'), width: '150px' },
    { field: 'Subject', title: translate.t('laSubject'), width: '200px' },
    { field: 'Status', title: translate.t('laStatus'), width: '150px' },
    {
      field: 'archived',
      title: translate.t('laArchived'),
      width: '150px',
      cell: kendoProp => {
        return (
          <td>{kendoProp.dataItem.Archived ? translate.t('laArchived') : ' '}</td>
        );
      },
    },
    {
      field: 'Goal',
      title: translate.t('laGoal'),
      width: '200px',
      cell: kendoProp => {
        return (
          <KendoGenericUrlCell
            page={'page-performance'}
            empId={kendoProp.dataItem.EmpId}
            text={kendoProp.dataItem.Goal}
          />
        );
      },
    },
    {
      field: 'SuccessMeasures',
      title: translate.t('laSuccessMeasures'),
      width: '200px',
    },
    { field: 'StartDate', title: translate.t('laStartDate'), width: '100px' },
    { field: 'EndDate', title: translate.t('laEndDate'), width: '100px' },
    { field: 'Target', title: translate.t('laTarget'), width: '200px' },
    { field: 'Actual', title: translate.t('laActual'), width: '200px' },
    { field: 'Result', title: translate.t('laResult'), width: '200px' },
    { field: 'Weight', title: '%', width: '100px' },
    { field: 'Total', title: translate.t('laTotal'), width: '100px' },
    { field: 'Unit', title: translate.t('laUnit'), width: '150px' },
    {
      field: 'CreatedTime',
      title: translate.t('laCreatedTime'),
      width: '150px',
    },
    {
      field: 'UpdatedTime',
      title: translate.t('laUpdatedTime'),
      width: '150px',
    },
    { field: 'UpdatedBy', title: translate.t('laUpdatedBy'), width: '150px' },
    {
      field: 'ApprovedBy',
      title: translate.t('laApprovedBy'),
      width: '150px',
    },
    {
      field: 'LegalEntity',
      title: translate.t('laLegalEntity'),
      width: '150px',
    },
    {
      field: 'OfficeCountry',
      title: translate.t('laOfficeCountry'),
      width: '150px',
    },
    { field: 'PG1', title: translate.t('fPersonnelGroup1'), width: '300px' },
    { field: 'PG2', title: translate.t('fPersonnelGroup2'), width: '300px' },
    { field: 'PG3', title: translate.t('fPersonnelGroup3'), width: '300px' },
    { field: 'PG4', title: translate.t('fPersonnelGroup4'), width: '300px' },
    { field: 'PG5', title: translate.t('fPersonnelGroup5'), width: '300px' },
    { field: 'Info', title: translate.t('laInfo'), width: '300px' },
    {
      field: 'Latest',
      title: translate.t('laLatest'),
      width: '300px',
      cell: kendoProp => {
        return (
          <td>{kendoProp.dataItem.Latest ? translate.t('laLatest') : ' '}</td>
        );
      },
    },
    { field: 'HasGoals', title: '' },
    { field: 'HasReviews', title: '' },
    { field: 'TemplateIds', title: '', cell: () => <></> },
  ],
  excelExportColumns: [
    { field: 'EmpId', title: translate.t('laEmpId') },
    { field: 'Name', title: translate.t('laName') },
    { field: 'EmpNumber', title: translate.t('laEmpNumber') },
    { field: 'Position', title: translate.t('laPosition') },
    { field: 'Subject', title: translate.t('laSubject') },
    { field: 'Status', title: translate.t('laStatus') },
    { field: 'Archived', title: translate.t('laArchived') },
    { field: 'Goal', title: translate.t('laGoal') },
    { field: 'SuccessMeasures', title: translate.t('laSuccessMeasures') },
    { field: 'StartDate', title: translate.t('laStartDate') },
    { field: 'EndDate', title: translate.t('laEndDate') },
    { field: 'Target', title: translate.t('laTarget') },
    { field: 'Actual', title: translate.t('laActual') },
    { field: 'Result', title: translate.t('laResult') },
    { field: 'Weight', title: '%' },
    { field: 'Total', title: translate.t('laTotal') },
    { field: 'CreatedTime', title: translate.t('laCreatedTime') },
    { field: 'UpdatedTime', title: translate.t('laUpdatedTime') },
    { field: 'UpdatedBy', title: translate.t('laUpdatedBy') },
    { field: 'ApprovedBy', title: translate.t('laApprovedBy') },
    { field: 'Unit', title: translate.t('laUnit') },
    { field: 'LegalEntity', title: translate.t('laLegalEntity') },
    { field: 'OfficeCountry', title: translate.t('laOfficeCountry') },
    { field: 'PG1', title: translate.t('fPersonnelGroup1') },
    { field: 'PG2', title: translate.t('fPersonnelGroup2') },
    { field: 'PG3', title: translate.t('fPersonnelGroup3') },
    { field: 'PG4', title: translate.t('fPersonnelGroup4') },
    { field: 'PG5', title: translate.t('fPersonnelGroup5') },
    { field: 'Info', title: translate.t('laInfo') },
    { field: 'Latest', title: translate.t('laLatest') },
  ],
};

const GoalsReport = (props: Props) => {
  const userId = getLoggedUserId();
  const defaultRelation = isHR() && !isManager()
    ? Relation.ALL : Relation.DIRECT;

  const { allEnums, allGoalTemplates, classes, fetchGoalTemplates } = props;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [relation, setRelation] = useState(defaultRelation);
  const [goalsData, setGoalsData] = useState<EmpUnitEval[]>([]);
  const [empData, setEmpData] = useState([]);
  const [selectedTemplateIds, setSelectedTemplateIds] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [scope, setScope] = useState(ScopesValues.CURRENT_EMPLOYEES);
  const [updateTableConfig, setUpdateTableConfig] = useState(false);
  const {
    allUnits,
    includeSubunits,
    selectedUnitId,
    getIncludedUnits,
    setSelectedUnitId,
    setIncludeSubUnits,
  } = useUnits();
  const goalsReportConfigInit: GoalsReportConfig = {
    templates: { selected: selectedTemplateIds, scope, searchQuery },
    units: {
      selected: selectedUnitId,
      tableStatus: {
        filter: undefined,
        sort: [],
        skip: 0,
        group: undefined,
        take: 7,
      },
      columnsState: [],
      teamChecked: defaultRelation,
      subUnitsChecked: includeSubunits,
    },
    reviews: {
      columnsOrder: [],
      tableStatus: {
        filter: undefined,
        sort: [],
        skip: 0,
        group: undefined,
      },
    }
  };
  const [goalsReportConfig, setGoalsReportConfig] = useState<GoalsReportConfig>(goalsReportConfigInit);

  const getActiveColumnsSettings = () => {
    return goalsReportConfig;
  };

  const handleGoalsConfigChange = (name: string, data: Record<string, unknown>) => {
    setGoalsReportConfig({ ...goalsReportConfig, [name]: { ...goalsReportConfig[name], ...data } });
  };

  const handleChangeEmpScope = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value as ScopesValues;
    setScope(value);
    handleGoalsConfigChange(GoalsReportConfigFields.templates, { scope: value });
  };

  const unitGridData: IUnitGridData[] = useMemo(() => allUnits.map(currentUnit => {
    const statusCount = getUnitEvaluationCombinedStatusDistribution(
      goalsData,
      allGoalTemplates,
      currentUnit,
      selectedTemplateIds,
    );

    return {
      unitId: parseInt(currentUnit.fTreeUnitId, 10),
      unitName: currentUnit.fTreeUnitName,
      completed: statusCount.completed,
      inProgress: statusCount.inProgress,
      notStarted: statusCount.notStarted,
    };
  }), [allUnits, allGoalTemplates, goalsData, selectedTemplateIds]);

  const getTemplateSubject = useCallback((templateId: string) => getTemplateSubjectById(
    templateId,
    allGoalTemplates,
  ), [allGoalTemplates]);

  const mapEmpGoalEvalData = (emp: EmpUnitEval) => {
    if (!emp.employeeDetails || !emp.employeeDetails.empId) {
      return [];
    }

    const empInfo = getEmployeeInfoById(emp.employeeDetails.empId);
    const pg1EnumName = emp.employeeDetails.personnelGroups[0];
    const pg2EnumName = emp.employeeDetails.personnelGroups[1];
    const pg3EnumName = emp.employeeDetails.personnelGroups[2];
    const pg4EnumName = emp.employeeDetails.personnelGroups[3];
    const pg5EnumName = emp.employeeDetails.personnelGroups[4];

    const empPersonalInfo = {
      Picture: empInfo.fEmpId,
      Name: empInfo.fEmpLastName + ', ' + empInfo.fEmpFirstName,
      EmpId: empInfo.fEmpId,
      EmpNumber: empInfo.fEmpPersonNumber,
      Position: empInfo.fEmpPosition,
      Subject: '',
      Status: translate.t('laNotStarted'),
      Goal: '',
      SuccessMeasures: '',
      StartDate: '',
      EndDate: '',
      Target: '',
      Actual: '',
      Result: '',
      Weight: '',
      Total: '',
      Latest: '',
      HasGoals: false,
      HasReviews: false,
      Info: '',
      TemplateIds: '',
      HasAccount: emp.employeeDetails.userDetails.hasAccount,
      Unit: emp.employeeDetails.orgUnitDetails.name,
      UnitId: `${emp.employeeDetails.orgUnitDetails.id}`,
      OfficeCountry: getEnumValue(
        emp.employeeDetails.officeCountry,
        'COUNTRY',
        allEnums,
      ),
      PG1: getEnumValue(pg1EnumName, 'PERSONNEL_GROUP_1', allEnums),
      PG2: getEnumValue(pg2EnumName, 'PERSONNEL_GROUP_2', allEnums),
      PG3: getEnumValue(pg3EnumName, 'PERSONNEL_GROUP_3', allEnums),
      PG4: getEnumValue(pg4EnumName, 'PERSONNEL_GROUP_4', allEnums),
      PG5: getEnumValue(pg5EnumName, 'PERSONNEL_GROUP_5', allEnums),
      LegalEntity: emp.employeeDetails.legalEntityDetails.name,
    };

    const notStartedEvals = selectedTemplateIds.filter((tplId) => {
      return !emp.evaluations.find(evalItem => evalItem.templateId === tplId);
    }).map((tplId) => ({
      ...empPersonalInfo,
      Subject: getTemplateSubject(tplId),
      TemplateIds: [tplId],
    }));

    return emp.evaluations.reduce((goalsAcc, evalItem) => {
      if (!evalItem.goals) {
        return goalsAcc;
      }
      const whoUpdated = getEmployeeName(evalItem.updatedBy);
      const whoApproved = evalItem.approvedBy
        .map(e => getEmployeeName(e))
        .join('; ');
      const createdAt = moment(evalItem.createdTime).format(
        'YYYY-MM-DD h:mm:ss',
      );
      const updatedAt = moment(evalItem.updatedTime).format(
        'YYYY-MM-DD h:mm:ss',
      );

      const mappedEmpGoals = evalItem.goals.map(goalItem => {
        const goalEnumName = goalItem.goal.replace('PERF_GOAL_V2_SUBGRP_', '');

        return {
          ...empPersonalInfo,
          Subject: getTemplateSubject(evalItem.templateId),
          Status: getEvaluationStatus(emp.employeeDetails, unitGridData),
          Goal: getEnumValue(goalEnumName, 'PERF_GOAL_V2', allEnums),
          SuccessMeasures: getEnumValue(
            goalItem.successMeasure,
            goalItem.goal,
            allEnums,
          ),
          StartDate: goalItem.startDate
            ? moment(goalItem.startDate, 'YYYY-MM-DD').format('DD.MM.YYYY')
            : '',
          EndDate: goalItem.endDate
            ? moment(goalItem.endDate, 'YYYY-MM-DD').format('DD.MM.YYYY')
            : '',
          Target: goalItem.target || '',
          Actual: goalItem.actual || '',
          Result: goalItem.result || '',
          Weight: goalItem.weight || '',
          Total: getTotal(goalItem.result, goalItem.weight),
          HasGoals: true,
          HasReviews: true,
          Info: !goalItem.info ? '' : goalItem.info,
          Latest: evalItem.isLatest ? translate.t('laLatest') : '',
          TemplateIds: emp.evaluations.map(e => e.templateId),
          CreatedTime: createdAt,
          UpdatedTime: updatedAt,
          UpdatedBy: whoUpdated,
          ApprovedBy: whoApproved,
          Archived: evalItem.isArchived,
        };
      });

      return goalsAcc.concat(mappedEmpGoals);
    }, []).concat(notStartedEvals);
  };

  const mapData = (empUnitEvals: EmpUnitEval[]) =>
    empUnitEvals.reduce((list, emp) => {
      return list.concat(mapEmpGoalEvalData(emp));
    }, []);

  useEffect(() => {
    if (goalsData.length && selectedUnitId) {
      const filteredGoalData = filterEvaluationData(
        goalsData,
        selectedTemplateIds,
        getIncludedUnits(selectedUnitId, includeSubunits),
        unitGridData,
      );

      setEmpData(mapData(filteredGoalData));
    }
  }, [goalsData, selectedUnitId]);

  useEffect(() => {
    fetchGoalTemplates();
  }, [fetchGoalTemplates]);

  useEffect(() => {
    Service.get(
      API.GoalsReport.getGoalsReport(
        getUserRolePerspective(relation),
        userId,
        relation,
        scope,
      ),
      (response: EmpUnitEval[]) => {
        setGoalsData(response);
        setLoading(false);
        setError('');
      },
      (err: any) => {
        setGoalsData([]);
        setLoading(false);
        setError(err);
      },
    );
  }, [
    scope,
    userId,
    relation,
    setGoalsData,
    setEmpData,
    setLoading,
    setError,
  ]);

  const handleTemplateClick = (id: string) => {
    const selectedIds = selectedTemplateIds.includes(id)
      ? selectedTemplateIds.filter(tplId => tplId !== id)
      : selectedTemplateIds.concat([id]);

    setSelectedTemplateIds(selectedIds);
    setSelectedUnitId(undefined);
    setEmpData([]);
    handleGoalsConfigChange(GoalsReportConfigFields.templates, { selected: selectedIds });
  };

  const handleTemplateBulkSelection = (templateIds: string[]) => {
    setSelectedTemplateIds(templateIds);
  };

  const onSearch = (query: string) => {
    setSearchQuery(query);
    handleTemplateBulkSelection([]);
    handleGoalsConfigChange(GoalsReportConfigFields.templates, { searchQuery: query });
  };

  const onDeselectAll = () => {
    handleTemplateBulkSelection([]);
    handleGoalsConfigChange(GoalsReportConfigFields.templates, { selected: [] });
  };
  const onSelectAll = () => {
    const templateIdList = searchMatchingTemplates(allGoalTemplates, searchQuery).map(template => `${template.id}`);
    handleTemplateBulkSelection(templateIdList);
    handleGoalsConfigChange(GoalsReportConfigFields.templates, { selected: templateIdList });
  };

  const handleUnitClick = (id: string) => {
    const newSelectedUnitId = id !== selectedUnitId ? id : undefined;
    setSelectedUnitId(newSelectedUnitId);

    if (!newSelectedUnitId) {
      return setEmpData([]);
    }

    handleGoalsConfigChange(GoalsReportConfigFields.units, { selected: newSelectedUnitId });
  };

  const handleSubUnitInclusion = () => {
    setIncludeSubUnits(!includeSubunits);

    const filteredGoalData = filterEvaluationData(
      goalsData,
      selectedTemplateIds,
      getIncludedUnits(selectedUnitId, !includeSubunits),
      unitGridData,
    );

    setEmpData(mapData(filteredGoalData));
    handleGoalsConfigChange(GoalsReportConfigFields.units, { subUnitsChecked: !includeSubunits });
  };

  const getUnitGridElements = (): IUnitGrid[] =>
    unitGridData.map((unitGridItem: IUnitGridData) => ({
      ...unitGridItem,
      completed: unitGridItem.completed.length,
      inProgress: unitGridItem.inProgress.length,
      notStarted: unitGridItem.notStarted.length,
      subjectButton: (
        <Button
          variant="outlined"
          onClick={() => handleUnitClick(`${unitGridItem.unitId}`)}
          className={classNames({
            [classes.selectedButtonBackground]:
              `${unitGridItem.unitId}` === selectedUnitId,
          })}
        >
          {unitGridItem.unitName}
        </Button>
      ),
    }));

  const handleTeamCheckboxClick = () => {
    const newStatus = relation === Relation.DIRECT ? Relation.ALL : Relation.DIRECT;
    setRelation(newStatus);
    handleGoalsConfigChange(GoalsReportConfigFields.units, { teamChecked: newStatus });
  };

  const templateGridData = useMemo(() => searchMatchingTemplates(
    allGoalTemplates,
    searchQuery,
  ).map((template) => ({
    ...template,
    subjectButton: (
      <Button
        variant="outlined"
        onClick={() => handleTemplateClick(`${template.id}`)}
        className={classNames({
          [classes.selectedButtonBackground]:
            selectedTemplateIds.includes(`${template.id}`),
        })}
      >
        {template.subject}
      </Button>
    ),
  })), [allGoalTemplates, selectedTemplateIds, searchQuery]);

  const applyGoalReportSettings = (settings: GoalsReportConfig) => {
    if (settings) {
      const { scope: empScope, searchQuery: search, selected } = settings.templates;
      const { teamChecked, subUnitsChecked, selected: selectedUnit } = settings.units;
      
      setScope(empScope as ScopesValues);
      setSearchQuery(search);
      setSelectedTemplateIds(selected);

      setRelation(teamChecked);
      setIncludeSubUnits(subUnitsChecked);
      setSelectedUnitId(selectedUnit);

      setGoalsReportConfig(settings);
      setUpdateTableConfig(!updateTableConfig);
    } else {
      setGoalsReportConfig(goalsReportConfigInit);
    }
  };

  if (error) {
    return <div>{'error'}</div>;
  }

  return (
    <Paper elevation={0} className={classes.root}>
      <div className={classes.clearance}>
        <SnackbarProvider maxSnack={3}>
          <ReportSettingsUtils
            reportType={ReportSettingsTypes.GOALSV2}
            initialReportSettings={JSON.stringify(goalsReportConfigInit)}
            getCurrentReportSettings={getActiveColumnsSettings}
            applyReportSettings={applyGoalReportSettings}
          />
        </SnackbarProvider>
      </div>
      <TemplateGrid
        title={translate.t('laGoalTemplates')}
        showSelect={true}
        handleChangeEmpScope={handleChangeEmpScope}
        scope={scope}
        templateGridData={templateGridData}
        showSearch={true}
        searchQuery={searchQuery}
        onSearch={onSearch}
        onSelectAll={onSelectAll}
        onDeselectAll={onDeselectAll}
      />
      {!!selectedTemplateIds.length && (
        <>
          {isManager() && (
            <span>
              <Checkbox
                checked={relation === Relation.DIRECT}
                onChange={handleTeamCheckboxClick}
              />
              {translate.t('laTeam')}
            </span>
          )}

          <span>
            <Checkbox
              checked={includeSubunits}
              onChange={handleSubUnitInclusion}
            />
            {translate.t('laIncludeSubunits')}
          </span>
          <UnitGrid
            completedAltTitle={translate.t('laApproved')}
            showNotStarted={true}
            unitGrid={getUnitGridElements()}
            unitGridTitle={translate.t('laUnits')}
          />
        </>
      )}
      {!!selectedTemplateIds.length && selectedUnitId && empData.length ? (
        <div className={classes.gridMarginVertical}>
          <EmpGoals
            exportFileName={translate.t('laGoalsV2Report')}
            getActiveColumnsSettings={getActiveColumnsSettings}
            applyGoalReportSettings={applyGoalReportSettings}
            updateReportConfig={handleGoalsConfigChange}
            reportConfig={goalsReportConfig}
            initialReportSettings={JSON.stringify(goalsReportConfigInit)}
            updateConfigFromSettings={updateTableConfig}
            config={config}
            data={empData}
            isLoading={loading}
          />
        </div>
      ) : (
        undefined
      )}
    </Paper>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchGoalTemplates: () => dispatch<any>(fetchAllTemplates()),
});

const mapStateToProps = (state: ReducerState) => {
  const { allGoalTemplates } = state.goals;

  return {
    allEnums: selectAllEnums(state),
    allGoalTemplates: allGoalTemplates.map((goalTpl: ITemplateFull) => ({
      ...goalTpl,
      status: templateStatusTranslation(goalTpl.status as TemplateStatus),
      deadline: moment(goalTpl.deadline, 'YYYY-MM-DD').toDate(),
    })),
  };
};

const enhance = compose<Props, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
);

export default enhance(GoalsReport);

import * as React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import * as moment from 'moment';

import ReportView from '../components/ReportView';
import { ICustomFilter, IReportConfig } from '../ReportConfig';
import { selectAllEnums, getEnumName } from '@/app/redux/enums';
import { IReportSettings } from '../ReportSettings';
import translate from '@/app/utils/translate';
import { getEmployee } from '@/app/components/Trainings/helpers';

enum CompetencyTypesEnum {
  CORE = 'CORE_COMPETENCY',
  POS = 'DEV_COMPETENCY_POS_RELATED',
  PRO = 'DEV_COMPETENCY_PROFESSIONAL',
  OTHER = 'OTHER',
}

type StateType = {
  competencies: any;
};

class CompetenciesSearchReport extends React.Component<any, StateType> {
  state: StateType = {
    competencies: {
      [CompetencyTypesEnum.CORE]: {},
      [CompetencyTypesEnum.POS]: {},
      [CompetencyTypesEnum.PRO]: {},
      [CompetencyTypesEnum.OTHER]: {},
    },
  };

  private config: IReportConfig = {
    id: 'COMPETENCY_SEARCH',
    report: 'competency',
    titleLabel: 'laReportCompSearch',
    access: 'standard',
    columns: [
      { field: 'EmployeeNumber', title: translate.t('fEmployeeNumberInput'), width: '200px' },
      { field: 'CompetencyType', title: translate.t('laType'), width: '200px' },
      { field: 'Competency', title: translate.t('laCompetency'), width: '200px' },
      { field: 'SkillLevel', title: translate.t('laSkillLevel'), width: '70px' },
      { field: 'CompetencyTarget', title: translate.t('laTarget'), width: '70px' },
      { field: 'CompetencyDelta', title: translate.t('laDelta'), width: '70px' },
      { field: 'Name', title: translate.t('laName'), width: '200px' },
      { field: 'Unit', title: translate.t('laOrgUnit') },
      { field: 'Info', title: translate.t('laInfo') },
      { field: 'ReviewDate', title: translate.t('label_review_date'), width: '100px' },
    ],
    excelExportColumns: [
      { field: 'EmployeeNumber', title: 'Emp No.' },
      { field: 'CompetencyType', title: 'Competency type'},
      { field: 'Competency', title: 'Competency' },
      { field: 'SkillLevel', title: 'Skill Level' },
      { field: 'CompetencyTarget', title: 'Target'},
      { field: 'CompetencyDelta', title: 'Delta'},
      { field: 'Name', title: 'Name' },
      { field: 'FirstName', title: 'First name' },
      { field: 'LastName', title: 'Last name' },
      { field: 'Unit', title: 'Unit' },
      { field: 'Info', title: 'Info' },
      { field: 'ReviewDate', title: 'Evaluated' },
    ],
    filters: {
      scopeFilter: {
        enabled: true,
        accessRights: {
          company: ['HR_ADMIN', 'MANAGER'],
          dManagerAll: ['DEPUTY_MANAGER'],
          dManagerDirect: ['DEPUTY_MANAGER'],
          managerAll: ['MANAGER'],
          managerDirect: ['MANAGER'],
          unitAll: ['MANAGER', 'DEPUTY_MANAGER', 'HR_STAFF', 'HR_ADMIN'],
          unitDirect: ['MANAGER', 'DEPUTY_MANAGER', 'HR_STAFF', 'HR_ADMIN'],
        },
      },
      dateFilter: {
        enabled: false,
      },
    },
  };

  private defaultSettings: IReportSettings = {
    report: 'competency',
    scope: {
      type: '',
    },
    allowCompanyScopeForAll: true,
    custom: {
      competencyType: '',
      competency: '',
      skillLevel: '1',
    },
  };

  mapData = (origData: any): any => {
    if (!origData || origData.length === 0) { return []; }
    const newCompetencies = Object.assign({}, this.state.competencies);

    const allEnums = this.props.allEnums;
    const getEnumText = (type: string, competencyType: string) => {
      if (type === CompetencyTypesEnum.OTHER) { return competencyType; }
      return getEnumName(type, competencyType, allEnums);
    };

    const getCompentencyTypeName = (type: string): string => {
      switch (type) {
        case 'CORE_COMPETENCY': return translate.t('laCoreCompetency');
        case 'DEV_COMPETENCY_PROFESSIONAL': return translate.t('laProfessionalCompetency');
        case 'DEV_COMPETENCY_POS_RELATED': return translate.t('laPosCompetency');
        default: return translate.t('laOther');
      }
    };

    const getEmployeeNumber = (employeeId: number): string => {
      return getEmployee(employeeId).code;
    };

    const createRow = (employee: any, type: string, competency: any) => {
      const enumText = getEnumText(type, competency.fEmpCompetencyType);
      const newRow = {
        EmpId: employee.fEmpId,
        EmployeeNumber: getEmployeeNumber(employee.fEmpId),
        Name: employee.fEmpLastName + ', ' + employee.fEmpFirstName,
        FirstName: employee.fEmpFirstName,
        LastName: employee.fEmpLastName,
        Type: type,
        CompetencyType: getCompentencyTypeName(type),
        Competency: enumText,
        SkillLevel: competency.fEmpCompetencySkillLevel,
        Info: competency.fEmpCompetencyInfo,
        Unit: employee.fEmpUnit,
        ReviewDate: moment(competency.fEmpCompetencyReviewDate, 'YYYY-MM-DD').format('DD.MM.YYYY'),
        CompetencyTarget: competency.fEmpCompetencyTarget,
        CompetencyDelta: competency.fEmpCompetencyDelta,
      };
      if (!newCompetencies[type][competency.fEmpCompetencyType]) {
        newCompetencies[type][competency.fEmpCompetencyType] = enumText;
      }
      return newRow;
    };

    const rows: Array<object> = [];
    origData.forEach((employee: any) => {
      employee.fEmpCompetencyCore.forEach((comp: any) => {
        rows.push(createRow(employee, CompetencyTypesEnum.CORE, comp));
      });
      employee.fEmpCompetencyPos.forEach((comp: any) => {
        rows.push(createRow(employee, CompetencyTypesEnum.POS, comp));
      });
      employee.fEmpCompetencyPro.forEach((comp: any) => {
        rows.push(createRow(employee, CompetencyTypesEnum.PRO, comp));
      });
      employee.fEmpCompetencyOther.forEach((comp: any) => {
        rows.push(createRow(employee, CompetencyTypesEnum.OTHER, comp));
      });
    });

    // Save competencies into local state for use in filter
    this.setState({ competencies: newCompetencies });

    // Return mapped data
    return rows;
  }

  getCustomFilters = (): ICustomFilter[] => {
    const { competencies } = this.state;
    const compValues = [{ label: translate.t('label_all'), value: '' }];
    Object.keys(competencies).forEach((typeKey: string) => {
      const type = competencies[typeKey];
      Object.keys(type).forEach((compKey: string) => {
        compValues.push({ label: type[compKey], value: type[compKey] });
      });
    });

    return [
      {
        type: 'DROPDOWN_MENU',
        params: {
          selectedSettingName: 'competencyType',
          fieldName: 'Type',
          operator: 'eq',
          buttonLabel: 'laType',
          values: [
            { label: translate.t('label_all'), value: '' },
            { label: translate.t('laCoreCompetencies'), value: CompetencyTypesEnum.CORE },
            { label: translate.t('laPositionCompetencies'), value: CompetencyTypesEnum.POS },
            { label: translate.t('laProfessionalCompetencies'), value: CompetencyTypesEnum.PRO },
            { label: translate.t('laOtherCompetencies'), value: CompetencyTypesEnum.OTHER },
          ],
        },
      },
      {
        type: 'DROPDOWN_MENU',
        params: {
          selectedSettingName: 'competency',
          fieldName: 'Competency',
          operator: 'eq',
          buttonLabel: 'laCompetency',
          values: compValues,
        },
      },
      {
        type: 'DROPDOWN_MENU',
        params: {
          selectedSettingName: 'skillLevel',
          fieldName: 'SkillLevel',
          operator: 'gte',
          buttonLabel: 'laSkillLevel',
          values: [
            { label: translate.t('label_any_1to5'), value: '1' },
            { label: translate.t('label_n_or_higher', {n: '2'}), value: '2' },
            { label: translate.t('label_n_or_higher', {n: '3'}), value: '3' },
            { label: translate.t('label_n_or_higher', {n: '4'}), value: '4' },
            { label: '5', value: '5' },
          ],
        },
      },
    ];
  }

  render() {
    return (
      <ReportView
        moduleId="development"
        reportId="COMPETENCY_SEARCH"
        config={this.config}
        defaultSettings={this.defaultSettings}
        onDataMapping={this.mapData}
        onGetCustomFilters={this.getCustomFilters}
        exportFileName={translate.t('laReportCompSearch')}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  allEnums: selectAllEnums(state),
});

const enhance = compose<any, any>(
  connect(mapStateToProps),
);

export default enhance(CompetenciesSearchReport);
import { Typography, withStyles } from '@material-ui/core';
import * as React from 'react';
import { compose } from 'recompose';
import subtitleComponentStyles from '@/app/components/TemplateComponents/form/InputComponents/SubtitleComponent/subtitleComponentStyles';
import {
  TemplateBaseComponentType,
  TemplateComponentTypeKey,
  TemplateStaticComponentProps,
} from '@/app/components/TemplateComponents/form/InputComponents';

type SubtitleComponentProps = TemplateStaticComponentProps & {
  component: TemplateBaseComponentType & {
    type: TemplateComponentTypeKey.SUBTITLE;
  };
};

const SubtitleComponent = (props: SubtitleComponentProps) => {
  const { component, classes } = props;
  return (
    component.type === TemplateComponentTypeKey.SUBTITLE && (
      <Typography variant="subtitle2" className={classes.typography}>
        {component.name}
      </Typography>
    )
  );
};

const enhance = compose<any, any>(withStyles(subtitleComponentStyles));

export default enhance(SubtitleComponent);

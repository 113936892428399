import * as React from 'react';
import { compose } from 'recompose';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as yup from 'yup';

import translate from '@/app/utils/translate';
import { IWorklist } from '../types';

type OwnProps = {
  open: boolean;
  onSave: (values: IWorklist) => void;
  onClose: () => void;

  initialValues: IWorklist | Pick<IWorklist, Exclude<keyof IWorklist, 'id' | 'active'>>;
};
type InnerProps = {};
type Props = OwnProps & InnerProps;

const WorklistEditDialog = (props: Props) => {
  const { initialValues, open, onSave, onClose } = props;
  if (!open || !initialValues) {
    return <></>;
  }

  const validationSchema = yup.object<IWorklist>().shape({
    title: yup.string()
      .required(translate.t('laErrorFieldRequir'))
      .max(80, translate.t('laErrorStringMaxChar')),
    description: yup.string()
      .max(3000, translate.t('laErrorTextMaxChar')),
  });

  const handleSave = (values: IWorklist, actions: FormikProps<IWorklist>) => {
    onSave(values);
    actions.resetForm();
    onClose();
  };

  const renderDialog = ({
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    touched,
    values
  }: FormikProps<IWorklist>) => {
    const handleCancel = () => {
      resetForm();
      onClose();
    };

    const handleSubmitClick = () => {
      handleSubmit();
    };

    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        aria-labelledby="worklist-edit-dialog-title"
      >
        <DialogTitle id="worklist-edit-dialog-title">
          {(!values.id) ? translate.t('title_add_worklist') : translate.t('title_edit_worklist')}
        </DialogTitle>
        <DialogContent>
          <Form>
            <Field
              id="title"
              autoFocus
              component={TextField}
              error={Boolean(errors.title && touched.title)}
              fullWidth
              helperText={(errors.title && touched.title) && errors.title}
              label={translate.t('laTitle')}
              margin="normal"
              name="title"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.title}
            />
            <Field
              id="description"
              component={TextField}
              error={Boolean(errors.description && touched.description)}
              fullWidth
              helperText={(errors.description && touched.description) && errors.description}
              label={translate.t('laDescription')}
              margin="normal"
              name="description"
              rows={2}
              rowsMax={5}
              multiline={true}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.description}
            />
          </Form>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleCancel}
          >
            {translate.t('laCancel')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmitClick}
          >
            {translate.t('laSave')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSave}
      validationSchema={validationSchema}
    >
      {renderDialog}
    </Formik>
  );
};

const enhance = compose<Props, OwnProps>();

export default enhance(WorklistEditDialog);

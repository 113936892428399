import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const InstructionsStyle: StyleRulesCallback = (theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: '#05A4D8',
    fontSize: 14,
    padding: '3px 4px',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  titleText: {
    color: '#05A4D8',
    paddingLeft: 4,
  },
  titleDiv: {
    border: 'none',
    borderTop: '3px solid #05A4D8',
    borderBottom: '2px solid #f2f2f5',
    padding: '12px 10px',
    background: '#fff',
    color: '#05A4D8',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialog: {
    boxShadow: 'none',
  },
  dialogContent: {
    width: 800,
    height: 500,
    marginTop: 10,
  },
});

export default InstructionsStyle;

import * as React from 'react';
import moment from 'moment';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { ReducerState } from '@/app/redux/store';
import { Typography, WithStyles } from '@material-ui/core';
import { LanguagesType } from '@/app/redux/languages';
import {
  fetchAllAppraisals,
  fetchArchivedAppraisals,
  getEvaluationInformation,
  changeEvaluationApproval,
  removeEvaluationApproval,
  setActiveTemplate,
  updateEvaluation,
  clearEvaluation,
  saveEmployeeEvaluations,
  archiveEvaluation,
  changeEvaluationStage,
} from '@/app/redux/appraisalsEvaluations';
import Loading from '@/app/components/Loading/Loading';
import TemplateSelection from './TemplateSelection/TemplateSelection';
import {
  openConfirmDialog,
  ConfirmDialogType,
} from '@/app/redux/confirmDialog';
import translate from '@/app/utils/translate';
import appraisalEvaluationsStyles from './appraisalEvaluationsStyles';
import { AppraisalFormTemplate } from '@/app/components/TemplateComponents/types';

type incomingProps = {
  employeeId: number;
};

type MapStateToProps = {
  allAppraisalTemplates: AppraisalFormTemplate[];
  archivedEvaluations: [];
  activeTemplate: any;
  languages: LanguagesType[];
};

type MapDispatchToProps = {
  fetchAllAppraisals: () => any;
  fetchArchivedAppraisals: (employeeId: number) => void;
  getEvaluationInformation: (
    template: AppraisalFormTemplate,
    employeeId: number,
  ) => any;
  setActiveTemplate: (
    template: AppraisalFormTemplate,
    employeeId: number,
    fromArchive?: boolean,
  ) => any;
  updateEvaluation: (templateId: string, responses: any) => any;
  saveEmployeeEvaluations: (tempateId: string, component: any) => any;
  openConfirmDialog: (payload: ConfirmDialogType) => void;
  clearEvaluation: (templateId: string) => any;
  changeEvaluationApproval: () => any;
  removeEvaluationApproval: () => any;
  archiveEvaluation: (evaluationId: string) => any;
  changeEvaluationStage: (setStage: any, userMakingTheChange: string) => any;
};

type PropsType = incomingProps &
  MapStateToProps &
  MapDispatchToProps &
  WithStyles<typeof appraisalEvaluationsStyles>;

type StateType = {
  isLoading: boolean;
};

class AppraisalEvaluationsContainer extends React.PureComponent<
  PropsType,
  StateType
> {
  state: StateType = {
    isLoading: false,
  };

  pdfRef = React.createRef<HTMLDivElement>();

  updateAppraisalTemplate = () =>
    this.props.fetchAllAppraisals().then(() => {
      let firstActiveTemplate = this.props.allAppraisalTemplates.find(
        (template: any) =>
          template.status === 'ACTIVE' &&
          moment(template.deadline)
            .endOf('day')
            .isAfter(new Date()),
      );

      // If all templates have passed deadlines set the first active template a locked template
      if (firstActiveTemplate === undefined) {
        firstActiveTemplate = this.props.allAppraisalTemplates.find(
          (template: any) => template.status === 'ACTIVE',
        );
      }
      if (firstActiveTemplate) {
        this.props.getEvaluationInformation(
          firstActiveTemplate,
          this.props.employeeId,
        );
      }

      this.setState({ isLoading: false });
    });

  componentDidMount() {
    this.setState({ isLoading: true });
    this.props.fetchArchivedAppraisals(this.props.employeeId);
    this.updateAppraisalTemplate();
  }

  componentDidUpdate(prevProps: PropsType) {
    if (this.props.employeeId !== prevProps.employeeId) {
      this.updateAppraisalTemplate();
    }
  }

  getTemplateDetails = (
    templateId: string,
    templates: AppraisalFormTemplate[],
  ): any => {
    return templates.find((template: any) => template.id === templateId);
  };

  handleTemplateChange = (selectedTemplateId: any) => {
    const newActiveTemplate = this.getTemplateDetails(
      selectedTemplateId,
      this.props.allAppraisalTemplates,
    );
    const archivedEvaluation = this.getTemplateDetails(
      selectedTemplateId,
      this.props.archivedEvaluations,
    );

    if (newActiveTemplate) {
      return this.props.setActiveTemplate(
        newActiveTemplate,
        this.props.employeeId,
      );
    } else if (archivedEvaluation) {
      const activeTemplate = this.getTemplateDetails(
        archivedEvaluation.templateId,
        this.props.allAppraisalTemplates,
      );
      const archivedTemplate = {
        ...activeTemplate,
        id: archivedEvaluation.id,
      };

      return this.props.setActiveTemplate(
        archivedTemplate,
        this.props.employeeId,
        true,
      );
    } else {
      return this.props.setActiveTemplate(
        newActiveTemplate,
        this.props.employeeId,
      );
    }
  };

  archiveEvaluation = (evaluationId: string) => {
    return this.props.archiveEvaluation(evaluationId).then(() => {
      this.props.fetchArchivedAppraisals(this.props.employeeId);
    });
  };

  handleEvaluationsSave = (evaluationId: string, component: any) => {
    return this.props
      .saveEmployeeEvaluations(evaluationId, component)
      .then(() => {
        return this.props.getEvaluationInformation(
          this.props.activeTemplate,
          this.props.employeeId,
        );
      });
  };

  render() {
    const {
      languages,
      allAppraisalTemplates,
      activeTemplate,
      archivedEvaluations,
    } = this.props;
    const { isLoading } = this.state;
    const notStartedStatus = activeTemplate.currentEvaluationId === undefined;

    return (
      <>
        {isLoading && <Loading key="loading" />}
        {!isLoading &&
          allAppraisalTemplates.length > 0 &&
          activeTemplate &&
          activeTemplate.id && (
            <div ref={this.pdfRef}>
              <TemplateSelection
                languages={languages}
                templates={allAppraisalTemplates}
                archivedEvaluations={archivedEvaluations}
                handleTemplateChange={this.handleTemplateChange}
                archiveEvaluation={this.archiveEvaluation}
                changeEvaluationApproval={this.props.changeEvaluationApproval}
                removeEvaluationApproval={this.props.removeEvaluationApproval}
                activeTemplate={activeTemplate}
                handleEvaluationsSave={this.handleEvaluationsSave}
                updateEvaluation={this.props.updateEvaluation}
                openConfirmDialog={this.props.openConfirmDialog}
                clearEvaluation={this.props.clearEvaluation}
                setActiveTemplate={this.props.setActiveTemplate}
                employeeId={this.props.employeeId}
                notStartedStatus={notStartedStatus}
                changeEvaluationStage={this.props.changeEvaluationStage}
              />
            </div>
          )}
        {!isLoading && !allAppraisalTemplates.length && !activeTemplate.id && (
          <Typography variant="h6">{translate.t('laNoResults')}</Typography>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: ReducerState) => {
  const {
    allAppraisalTemplates,
    activeTemplate,
    archivedEvaluations,
  } = state.appraisalsEvaluations;
  const { languages } = state;

  return {
    allAppraisalTemplates,
    activeTemplate,
    archivedEvaluations,
    languages: languages.get('allLanguages'),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAllAppraisals: () => dispatch<any>(fetchAllAppraisals()),
  fetchArchivedAppraisals: (employeeId: number) =>
    dispatch<any>(fetchArchivedAppraisals(employeeId)),
  getEvaluationInformation: (
    template: AppraisalFormTemplate,
    employeeId: number,
  ) => dispatch<any>(getEvaluationInformation(template, employeeId)),
  changeEvaluationApproval: (evaluationId: string, newApprovedBy: string) =>
    dispatch<any>(changeEvaluationApproval(evaluationId, newApprovedBy)),
  removeEvaluationApproval: (evaluationId: string, newApprovedBy: string) =>
    dispatch<any>(removeEvaluationApproval(evaluationId, newApprovedBy)),
  setActiveTemplate: (
    template: AppraisalFormTemplate,
    employeeId: number,
    fromArchive: boolean = false,
  ) => dispatch<any>(setActiveTemplate(template, employeeId, fromArchive)),
  archiveEvaluation: (evaluationId: string) =>
    dispatch<any>(archiveEvaluation(evaluationId)),
  updateEvaluation: (templateId: string, responses: any) =>
    dispatch<any>(updateEvaluation(templateId, responses)),
  openConfirmDialog: (payload: ConfirmDialogType) =>
    dispatch(openConfirmDialog(payload)),
  clearEvaluation: (templateId: string) =>
    dispatch<any>(clearEvaluation(templateId)),
  saveEmployeeEvaluations: (templateId: string, component: any) =>
    dispatch<any>(saveEmployeeEvaluations(templateId, component)),
  changeEvaluationStage: (setStage: any, userMakingTheChange: string) => {
    dispatch<any>(changeEvaluationStage(setStage, userMakingTheChange));
  },
});

const enhance = compose<any, any>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(appraisalEvaluationsStyles),
);

export default enhance(AppraisalEvaluationsContainer);

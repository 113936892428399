const GLOBAL: any = window;

export default () => {
  if (!GLOBAL.jsonCompanyData || !GLOBAL.jsonCompanyData.languages) {
    return [];
  }

  return GLOBAL.jsonCompanyData.languages.map((item: any) => ({
    code: item.fComLanguageCode,
    name: item.fComLanguageName,
    isDefault: item.fComLanguageIsDefault || false,
  }));
};

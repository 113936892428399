import * as React from 'react';
import { ResponsiveBar, BarDatum, BarExtendedDatum } from '@nivo/bar';
import {
  Grid,
  WithStyles,
  withStyles,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';
import { compose } from 'recompose';
import barChartStyle from './barChartStyle';

export type BarData = {
  label: string;
  value: number;
  color: string;
};

type OwnProps = {
  data: BarData[];
  title: string;
};

type PropsType = OwnProps & WithStyles<typeof barChartStyle>;

const BarChart: React.FunctionComponent<PropsType> = (props) => {
  const { data, classes, title } = props;

  const getSectionColor = (e: BarDatum) => e.data[`color`];

  const ColorBox = ({ label, color }: { label: string; color: string }) => {
    const colorBoxStyle = {
      background: `${color}`,
      height: '18px',
      marginRight: '8px',
      minWidth: '18px',
      width: '18px',
    };

    return (
      <Grid
        item
        style={{ display: 'flex', alignItems: 'center', fontSize: '11px' }}
      >
        <span style={colorBoxStyle} /> {label}
      </Grid>
    );
  };

  const getCustomTooltip = (
    d: BarExtendedDatum,
    _e: React.MouseEvent<HTMLCanvasElement>,
  ) => <ColorBox label={`${d.data.label}: ${d.value}`} color={d.color} />;

  const reversedData = [...data].reverse();

  return (
    <Card className={classes.barChartCard}>
      <CardHeader
        className={classes.barChartHeader}
        title={title}
        classes={{
          title: classes.barChartCardTitle,
        }}
      />
      <CardContent className={classes.barChartCardContent}>
        <Grid container className={classes.barChartContainer}>
          <ResponsiveBar
            data={reversedData}
            keys={['value']}
            indexBy="label"
            margin={{
              top: 0,
              right: 150,
              bottom: 0,
              left: 0,
            }}
            reverse={true}
            padding={0.05}
            innerPadding={2}
            groupMode="grouped"
            layout="horizontal"
            colors={(e) => getSectionColor(e)}
            axisTop={null}
            axisRight={{
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendOffset: 10,
            }}
            axisBottom={null}
            axisLeft={null}
            enableGridY={false}
            labelTextColor="#000"
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            tooltip={(d, e) => getCustomTooltip(d, e)}
            legends={[]}
          />
        </Grid>
      </CardContent>
    </Card>
  );
};

const enhance = compose<PropsType, OwnProps>(withStyles(barChartStyle));

export default enhance(BarChart);

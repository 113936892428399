import {
  Checkbox,
  IconButton,
  TextField,
  Typography,
  InputLabel,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import * as React from 'react';
import * as moment from 'moment';

import IntegrationReactSelect from '@/app/components/IntegrationReactSelect/IntegrationReactSelect';
import LocalizedDatePicker from '@/app/components/Pickers/LocalizedDatePicker';

import { CustomFieldInnerProps } from './customFieldModels';
import { useCustomField } from './useCustomField';
import { CustomFieldType } from './types';
import { DATE_FORMAT } from '@/app/utils/helper';
import classNames from 'classnames';
import { useEffect } from 'react';

export const CustomField: React.FC<CustomFieldInnerProps> = props => {
  const {
    errors,
    validationRules,
    register,
    setValue,
    idEditMode,
    fieldDetails,
    classes,
  } = props;
  const {
    formattedValue,
    options,
    inputValue,
    selectedOption,
    handleChange,
    handleClearValue,
  } = useCustomField(props);

  if (idEditMode) {
    useEffect(() => {
      const type = props.fieldDetails.inputType.value;
      if (type === CustomFieldType.DATE) {
        const dateVal = moment(props.fieldDetails.value, 'YYYY-MM-DD').isValid()
          ? moment(moment(props.fieldDetails.value, 'YYYY-MM-DD')).format(
            DATE_FORMAT,
          )
          : '';
        setValue(props.fieldDetails.code, dateVal);
      } else if (
        type === CustomFieldType.ENUM ||
        type === CustomFieldType.MULTISELECT
      ) {
        setValue(props.fieldDetails.code, props.fieldDetails.value);
      }
    }, []);
    switch (fieldDetails.inputType.value) {
      case CustomFieldType.BOOLEAN: {
        return (
          <div className={classes.marginTopOther}>
            <InputLabel>{fieldDetails.label}</InputLabel>
            <Checkbox
              disabled={props.isSaving}
              name={fieldDetails.code}
              inputRef={register}
              defaultChecked={
                fieldDetails.value === 'checked' ||
                  fieldDetails.value === 'true'
                  ? true
                  : false
              }
              onChange={handleChange}
            />
          </div>
        );
      }
      case CustomFieldType.STRING: {
        const rules = validationRules[CustomFieldType.STRING]
          ? validationRules[CustomFieldType.STRING]
          : {};
        return (
          <div className={classes.marginTopText}>
            <TextField
              type="text"
              margin="dense"
              name={fieldDetails.code}
              disabled={props.isSaving}
              className={classes.textInput}
              onChange={handleChange}
              label={fieldDetails.label}
              defaultValue={`${fieldDetails.value}`}
              fullWidth
              inputRef={register({
                ...rules,
              })}
              error={errors[fieldDetails.code]}
              helperText={
                errors[fieldDetails.code]
                  ? errors[fieldDetails.code].message
                  : ' '
              }
            />
          </div>
        );
      }
      case CustomFieldType.NUMBER: {
        const rules = validationRules[CustomFieldType.NUMBER]
          ? validationRules[CustomFieldType.NUMBER]
          : {};
        return (
          <div className={classes.marginTopText}>
            <TextField
              type="number"
              margin="dense"
              inputProps={{ step: '0.01' }}
              name={fieldDetails.code}
              disabled={props.isSaving}
              className={classes.textInput}
              inputRef={register({
                ...rules,
              })}
              error={errors[fieldDetails.code]}
              helperText={
                errors[fieldDetails.code]
                  ? errors[fieldDetails.code].message
                  : ' '
              }
              onChange={handleChange}
              label={fieldDetails.label}
              defaultValue={`${fieldDetails.value}`}
              fullWidth
            />
          </div>
        );
      }
      case CustomFieldType.TEXT: {
        const rules = validationRules[CustomFieldType.STRING]
          ? validationRules[CustomFieldType.STRING]
          : {};
        return (
          <div className={classes.marginTopText}>
            <InputLabel>{fieldDetails.label}</InputLabel>
            <textarea
              maxLength={3000}
              disabled={props.isSaving}
              name={fieldDetails.code}
              className={`${classes.textarea} ${classes.fullWidth} ${classNames(
                {
                  [classes.error]: errors[fieldDetails.code],
                },
              )}`}
              ref={register({ ...rules })}
              defaultValue={`${fieldDetails.value}`}
              onChange={handleChange}
            />
            {errors[fieldDetails.code] ? (
              <Typography
                className={classNames({
                  [classes.exceededLengthLimit]: errors[fieldDetails.code],
                })}
              >
                {errors[fieldDetails.code].message}
              </Typography>
            ) : (
              ' '
            )}
          </div>
        );
      }
      case CustomFieldType.DATE: {
        return (
          <div
            className={`${classes.clearableInputContainer} ${classes.marginTopOther}`}
          >
            <InputLabel style={{ marginRight: 12 }}>
              {fieldDetails.label}
            </InputLabel>
            <LocalizedDatePicker
              margin="dense"
              variant="outlined"
              value={inputValue || ''}
              inputRef={register}
              onChange={handleChange}
              name={fieldDetails.code}
              className={classes.textInput}
              invalidDateMessage=""
              invalidLabel=""
              disabled={props.isSaving}
              fullWidth={true}
            />
            <IconButton
              className={classes.clearButton}
              onClick={handleClearValue}
            >
              <Clear />
            </IconButton>
          </div>
        );
      }
      case CustomFieldType.ENUM: {
        return (
          <div className={classes.marginTopOther}>
            <InputLabel>{fieldDetails.label}</InputLabel>
            <IntegrationReactSelect
              isMulti={false}
              isClearable={true}
              isSearchable={true}
              isDisabled={props.isSaving}
              name={fieldDetails.code}
              label=""
              required={false}
              error=""
              onChange={handleChange}
              defaultValue={selectedOption || ''}
              options={options}
              className={classes.fullWidth}
              noWrapValue={true}
            />
          </div>
        );
      }
      case CustomFieldType.MULTISELECT: {
        return (
          <div className={classes.marginTopOther}>
            <InputLabel>{fieldDetails.label}</InputLabel>
            <IntegrationReactSelect
              isMulti={true}
              isClearable={true}
              isSearchable={true}
              isDisabled={props.isSaving}
              name={fieldDetails.code}
              label=""
              required={false}
              defaultValue={selectedOption || []}
              error=""
              onChange={handleChange}
              options={options}
              className={classes.fullWidth}
              noWrapValue={true}
            />
          </div>
        );
      }
      default: {
        return null;
      }
    }
  }

  return <Typography>{formattedValue}</Typography>;
};

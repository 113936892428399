import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { TransactionsList as LocalTransactionsList } from './TransactionsList';
import { TransactionsListInnerProps, TransactionsListOuterProps } from './transactionsListModels';
import { mapStateToProps } from './transactionsListRedux';
import { transactionsListStyles } from './transactionsListStyles';

export const TransactionsList = compose<TransactionsListInnerProps, TransactionsListOuterProps>(
    connect(mapStateToProps),
    withStyles(transactionsListStyles),
)(LocalTransactionsList);

import * as React from 'react';
import PropsMenu from '@/app/components/PropsMenu/PropsMenu';
import { ChildInputType } from '../../FormFields/FormFields';
import { Icon, Typography, StyleRulesCallback, Theme, WithStyles, withStyles } from '@material-ui/core';

const styles: StyleRulesCallback = (theme: Theme) => ({
  root: {
    marginLeft: theme.spacing.unit,
  },
  prompt: {
  },
  value: {
    textTransform: 'none',
  }
});

type PropsType = {
  label: string;
  selectedItem: string;
  data: Array<ChildInputType>;
  onSelect: (item: string) => void;
};

const ToolbarFilterDropdownButton: React.FunctionComponent<PropsType> = (props: PropsType & WithStyles<typeof styles>) => {
  const { classes, label, selectedItem, data, onSelect } = props;
  const selectedItemData = data.find((item: ChildInputType) => item.value === selectedItem);

  if (!selectedItemData) {
    return null;
  }

  const selectedItemText = selectedItemData.label;

  return (
    <PropsMenu
      data={data}
      onSelect={(item: ChildInputType) => onSelect(item.value)}
      useButton={true}
    >
      <div>
        <Typography className={classes.prompt}>{label}</Typography>
        <Typography variant="caption" className={classes.value}>{selectedItemText}</Typography>
      </div>
      <Icon>arrow_drop_down</Icon>
    </PropsMenu>
  );
};

export default withStyles(styles, {withTheme: true})(ToolbarFilterDropdownButton);
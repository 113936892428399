import * as React from 'react';
import Cookies from 'universal-cookie';

import {
  Toolbar,
  Avatar,
  IconButton,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  withStyles,
  WithStyles,
  Menu,
  MenuItem,
} from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';

import { EmployeeListData } from '../../SolaIntelConfig';
import styles from './solaIntelEmployeeListWidgetStyle';
import EmployeeImage from '@/app/components/EmployeeImage/EmployeeImage';
import { showEmployee } from '@/old/utils/helper';
import translate from '@/app/utils/translate';

interface Props {
  title: string;
  data: EmployeeListData[];
  onClick?: Function;
  onClose?: Function;
  onSetClickAwayClosing?: Function;
}

interface State {
  anchorEl: any;
  employeeId: number;
  targetPages: string[];
  selectedIndex: number;
}

class SolaIntelEmployeeListWidget extends React.Component<
  Props & WithStyles<typeof styles>,
  State
> {
  state = {
    anchorEl: null as any,
    employeeId: null as number,
    targetPages: [] as string[],
    selectedIndex: 1,
  };

  handleClickListItem = (
    event: any,
    targetPage: string | string[],
    employeeId: number,
  ) => {
    const { onSetClickAwayClosing } = this.props;
    const { anchorEl } = this.state;

    if (anchorEl) {
      this.setState({ anchorEl: null });
      onSetClickAwayClosing(true);
    } else if (Array.isArray(targetPage)) {
      this.setState({
        anchorEl: event.currentTarget,
        employeeId: employeeId,
        targetPages: targetPage.slice(),
      });

      onSetClickAwayClosing(false);
    }
  };

  handleClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  handleShowTargetPage = (
    targetPage: string | string[],
    employeeId: number,
    event: any,
  ) => {
    if (targetPage !== '' && !Array.isArray(targetPage)) {
      const cookies = new Cookies();
      cookies.set('sola-current-page', targetPage);
      showEmployee(employeeId);
      this.setState({
        anchorEl: null,
        employeeId: null,
        targetPages: [],
      });

      return;
    }

    this.handleClickListItem(event, targetPage, employeeId);
  };

  compareStrings = (a: string, b: string): number => {
    if (a > b) {
      return 1;
    }
    if (a < b) {
      return -1;
    }
    return 0;
  };

  getItemDescription = (item: EmployeeListData): string => {
    if (!item.description) {
      return null;
    }

    const params = item.description.params;
    if (item.description.term && params && params.date) {
      return translate.t(
        item.description.term,
        Object.assign({}, params, {
          date: new Date(params.date).toLocaleDateString('fi-FI'),
        }),
      );
    } else if (item.description.term && params) {
      return translate.t(item.description.term, params);
    }

    if (item.description.trans) {
      return item.description.trans;
    }

    return null;
  };

  getPageTranslation = (page: string) => {
    const pageName = page.charAt(0).toUpperCase() + page.slice(1);
    return translate.t(`la${pageName.replace('.html', '')}`);
  };

  render() {
    const { classes, title, data } = this.props;
    const { anchorEl, employeeId, targetPages } = this.state;

    return (
      <Paper className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label={translate.t('label_close_list')}
            onClick={this.handleClose}
          >
            <BackIcon fontSize="small" />
          </IconButton>
          <Typography variant="body1" color="inherit" noWrap>
            {title}
          </Typography>
        </Toolbar>
        <List>
          {data
            .sort((a: EmployeeListData, b: EmployeeListData) =>
              this.compareStrings(a.name, b.name),
            )
            .map((item: EmployeeListData, ndx: number) => (
              <ListItem
                button
                key={ndx}
                onClick={(event) =>
                  this.handleShowTargetPage(
                    item.targetPage,
                    item.employeeId,
                    event,
                  )
                }
              >
                <Avatar>
                  <EmployeeImage employeeId={item.employeeId} size="sm" />
                </Avatar>
                <ListItemText
                  primary={item.name}
                  secondary={this.getItemDescription(item)}
                />
              </ListItem>
            ))}
        </List>
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => this.handleClickListItem(null, null, null)}
        >
          {targetPages.map((page) => (
            <MenuItem
              key={page}
              onClick={(event) =>
                this.handleShowTargetPage(page, employeeId, event)
              }
            >
              {this.getPageTranslation(page)}
            </MenuItem>
          ))}
        </Menu>
      </Paper>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  SolaIntelEmployeeListWidget,
);

import * as React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SortIcon from '@material-ui/icons/Sort';
import translate from '@/app/utils/translate';
import AddComponentPicker from '../AddComponent/AddComponentPicker/AddComponentPicker';

type PropsType = {
  section: any;
  selectedComponentIds: any[];
  sortComponents: any;
  addComponent: any;
  editComponent: any;
  deleteComponent: any;
};

class SectionTopToolbar extends React.PureComponent<PropsType> {
  render() {
    const {
      section,
      selectedComponentIds,
      sortComponents,
      addComponent,
      editComponent,
      deleteComponent,
    } = this.props;

    return (
      <>
        {section.components.length > 1 && (
          <Tooltip title={translate.t('laSort')}>
            <IconButton onClick={sortComponents}>
              <SortIcon color="primary" />
            </IconButton>
          </Tooltip>
        )}

        <AddComponentPicker onComponentAdd={addComponent} />

        {selectedComponentIds.length === 1 && (
          <Tooltip title={translate.t('laEdit')}>
            <IconButton onClick={() => editComponent(selectedComponentIds)}>
              <EditIcon color="primary" />
            </IconButton>
          </Tooltip>
        )}

        {selectedComponentIds.length > 0 && (
          <Tooltip title={translate.t('laDelete')}>
            <IconButton onClick={deleteComponent}>
              <DeleteIcon color="primary" />
            </IconButton>
          </Tooltip>
        )}
      </>
    );
  }
}

export default SectionTopToolbar;

import { Dispatch } from 'redux';
import { change, autofill, touch } from 'redux-form';

export const FORM_CHANGE = '@@redux-form/CHANGE';
export const FORM_SET_SUBMIT_SUCCEEDED = '@@redux-form/SET_SUBMIT_SUCCEEDED';
export const FORM_DESTROY = '@@redux-form/DESTROY';
export const FORM_INITIALIZE = '@@redux-form/INITIALIZE';
export const FORM_RESET = '@@redux-form/RESET';

export interface FormDataType {
  form: string;
  field: string;
  value: any;
}

export const loadFormData = (form: string, data: any) => {
  return async (dispatch: Dispatch) => {
    if (data instanceof Object) {
      const keys = Object.keys(data);
      keys.forEach(key => {
        dispatch(autofill(form, key, data[key]));
      });
      dispatch(touch(form, ...keys));
    }
  };
};

export const resetFormData = (form: string) => ({ type: FORM_RESET, meta: { form } });
export const changeFormData = (dispatch: Dispatch, payload: FormDataType) => {
  dispatch(change(payload.form, payload.field, payload.value));
};

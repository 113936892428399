import { WithStyles } from '@material-ui/core';
import { FormContextValues } from 'react-hook-form';

import { TimeBlock } from '../../../../data';
import { DailySummaryFormValues } from '../../dailySummaryModels';

import { TransactionsListStyleKeys } from './transactionsListStyles';
import { AutoDeductionRule } from '@/app/components/AutoDeduction/types';

export enum CustomFieldType {
  BOOLEAN = 'BOOLEAN',
  STRING = 'STRING',
  DATE = 'DATE',
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
  ENUM = 'ENUM',
  MULTISELECT = 'MULTISELECT',
}

export interface CustomField {
  groupName: string;
  code: string;
  description: string;
  name: string;
  inputType: {
    label: string;
    value: CustomFieldType;
  };
  countries?: Array<{
    label: string;
    value: string;
  }>;
}

export interface StateProps {
  customFields: CustomField[];
}

export interface TransactionsListOuterProps {
  currentDate: string;
  date: string;
  isSaving: boolean;
  inEditMode: boolean;
  timeBlocks?: TimeBlock[];
  canManageEverything: boolean;
  formErrors: FormContextValues<DailySummaryFormValues>['errors'];
  watchFormValues: FormContextValues<DailySummaryFormValues>['watch'];
  getFormValues: FormContextValues<DailySummaryFormValues>['getValues'];
  setFormValue: FormContextValues<DailySummaryFormValues>['setValue'];
  onTransactionRowDelete: (timeBlockId: string) => void;
  autoDeductionRule?: AutoDeductionRule;
}

export interface TransactionsListInnerProps
  extends TransactionsListOuterProps,
    StateProps,
    WithStyles<TransactionsListStyleKeys> {}

import * as React from 'react';
import { Timeline, TimelineEvent } from 'react-event-timeline';
import { ListItem, Avatar, ListItemText } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import SendIcon from '@material-ui/icons/Send';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import EmployeeImage from '@/app/components/EmployeeImage/EmployeeImage';
import translate from '@/app/utils/translate';
import { getEmployeeName } from '@/old/utils/helper';
import * as moment from 'moment';
import { DATE_TIME_FORMAT } from '@/app/utils/helper';
import Service from '@/app/utils/service';
import API from '@/app/api/internalAPIs';
import { connect } from 'react-redux';
import { throwError } from '@/app/redux/error';
import compose from 'recompose/compose';

type StateType = {
  history: Array<any>
};

type PropsType = {
  type: 'benefit' | 'allowance' | 'salary' | 'bonus',
  employeeId: number,
  employmentId: number,
  uid: string,
  throwError: Function
};

class ProposalWorkflow extends React.Component<PropsType> {
  state: StateType = {
    history: []
  };

  componentWillMount () {
    let url = '';
    if (this.props.type === 'benefit') {
      url = API.workflow.benefitproposal(this.props.employeeId, this.props.employmentId, this.props.uid);
    } else if (this.props.type === 'allowance') {
      url = API.workflow.allowanceproposal(this.props.employeeId, this.props.employmentId, this.props.uid);
    } else if (this.props.type === 'salary') {
      url = API.workflow.salaryproposal(this.props.employeeId, this.props.employmentId, this.props.uid);
    } else if (this.props.type === 'bonus') {
      url = API.workflow.bonusproposal(this.props.employeeId, this.props.employmentId, this.props.uid);
    } else if (this.props.type === 'billableHours') {
      url = API.workflow.billableHoursProposal(this.props.employeeId, this.props.employmentId, this.props.uid);
    }

    // const url = API.workflow.benefitproposal(779, 10779, '1CBDVwPRuF');
    Service.get(
      url,
      (data: any) => {
        const filtedData = data.filter((d: any) => {
          const keys = Object.keys(d);
          const statusKey = keys.find((key: string) => key.indexOf('ProposalStatus') > -1);
          d.status = d[statusKey];
          return d.status !== 'CLOSED' && d.status !== 'FAILED' && d.fEmpSubEnteredEmpId;
        });
        this.setState({
          history: filtedData.reverse()
        });
      },
      (error: any) => this.props.throwError(error)
    );
  }

  getTitle = (status: string): string => {
    if (status === 'PROPOSED') {
      return translate.t('laProposed');
    } else if (status.indexOf('APPROVED') > -1 || status === 'COMPLETED') {
      return translate.t('laApproved');
    } else if (status.indexOf('REJECTED') > -1) {
      return translate.t('laRejected');
    } else { // not sure if there is any other status
      return '';
    }
  };

  getTimelineEventAttrs = (status: string) => {
    if (status === 'PROPOSED') {
      return {
        icon: <SendIcon style={{fontSize: '20px'}} />,
        iconStyle: { color: '#2196f3' },
        bubbleStyle: { borderColor: '#2196f3' }
      };
    } else if (status.indexOf('APPROVED') > -1 || status === 'COMPLETED') {
      return {
        icon: <DoneIcon />,
        iconStyle: { color: '#4caf50' },
        bubbleStyle: { borderColor: '#4caf50' }
      };
    } else if (status.indexOf('REJECTED') > -1) {
      return {
        icon: <HighlightOffIcon />,
        iconStyle: { color: '#f50057' },
        bubbleStyle: { borderColor: '#f50057' }
      };
    } else { // not sure if there is any other status
      return {
        icon: <QueryBuilderIcon />,
        iconStyle: { color: '#2196f3' },
        bubbleStyle: { borderColor: '#2196f3' }
      };
    }
  };

  render () {
    const { history } = this.state;
    if (history.length === 0) {
      return null;
    }
    return (
      <Timeline>
        {history.map((data: any) => {
          return (
            <TimelineEvent
              title=""
              key={data.fEmplSubEnteredTime}
              createdAt={moment(data.fEmplSubEnteredTime).format(DATE_TIME_FORMAT)}
              {...this.getTimelineEventAttrs(data.status)}
              style={{width: '250px'}}
            >
                <ListItem style={{padding: '5px 10px'}}>
                  <Avatar>
                    <EmployeeImage employeeId={data.fEmpSubEnteredEmpId} />
                  </Avatar>
                  <ListItemText primary={this.getTitle(data.status)} secondary={getEmployeeName(data.fEmpSubEnteredEmpId)} />
                </ListItem>
            </TimelineEvent>
          );
        })}
      </Timeline>
    );
  }
}

const enhance = compose<any, any>(
  connect(null, { throwError }),
);

export default enhance(ProposalWorkflow);

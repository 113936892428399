import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const evaluationTotalsStyles: StyleRulesCallback = () => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px 20px',
  },
  totalInput: {
    width: '100px',

    '&:not(:last-child)': {
      marginRight: '10px',
    },
  },
});

export default evaluationTotalsStyles;

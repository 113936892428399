import * as React from 'react';
import {
  GridDataStateChangeEvent,
  Grid,
  GridColumn,
  GridToolbar,
} from '@progress/kendo-react-grid';
import ColumnMenu from '@/app/components/Kendo/ColumnMenu';
import { process } from '@progress/kendo-data-query';
import { Typography } from '@material-ui/core';
import translate from '@/app/utils/translate';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { KendoButtonCell } from '../../../components/KendoButtonCell';
import KendoReportDownloadButton from '../../../components/KendoReportDownloadButton';

const UnitGridColumns = (
  showNotStarted?: boolean,
  completedAltTitle?: string,
) =>
  [
    {
      cell: KendoButtonCell,
      field: 'unitName',
      show: true,
      title: translate.t('laUnit'),
    },
    showNotStarted
      ? {
          field: 'notStarted',
          show: true,
          title: translate.t('laNotStarted'),
          filter: 'numeric',
        }
      : undefined,
    {
      field: 'inProgress',
      show: true,
      title: translate.t('laInProgress'),
      filter: 'numeric',
    },
    {
      field: 'completed',
      show: true,
      title: completedAltTitle ? completedAltTitle : translate.t('laCompleted'),
      filter: 'numeric',
    },
  ].filter(col => !!col);

type Props = {
  unitGrid: any;
  unitGridTitle?: string;
  showNotStarted?: boolean;
  completedAltTitle?: string;
};

const UnitGrid = (props: Props) => {
  const {
    completedAltTitle,
    showNotStarted,
    unitGrid,
    unitGridTitle
  } = props;

  const [dataState = { skip: 0, take: 7 }, setDataState] = React.useState();
  const [
    columns = UnitGridColumns(showNotStarted, completedAltTitle),
    setColumns,
  ] = React.useState();

  const onColumnsSubmit = (columnsState: any) => {
    setColumns(columnsState);
  };
  const createDataState = (dataStateProp: any) => {
    return {
      dataState: setDataState(dataStateProp),
    };
  };
  const dataStateChange = (e: GridDataStateChangeEvent) => {
    createDataState(e.data);
  };

  let _export: any;

  const saveGridData = () => {
    _export.save();
  };

  return (
    <div>
      <ExcelExport
        data={
          process(unitGrid, {
            ...dataState, // Export all the records
            skip: 0,
            take: undefined,
          }).data
        }
        fileName={translate.t('laOrgUnits')}
        ref={excelExport => (_export = excelExport)}
      >
        <Grid
          data={process(unitGrid, dataState)}
          onDataStateChange={dataStateChange}
          {...dataState}
          sortable={true}
          resizable={true}
          pageable={true}
          groupable={true}
          filterable={false}
        >
          <GridToolbar>
            <Typography variant="h6" gutterBottom color="primary">
              {unitGridTitle}
            </Typography>
            <KendoReportDownloadButton onClick={saveGridData} />
          </GridToolbar>
          {columns.map(
            (column: any, idx: number) =>
              column.show && (
                <GridColumn
                  {...column}
                  key={idx}
                  columnMenu={(otherProps: any) => (
                    <ColumnMenu
                      {...otherProps}
                      columns={columns}
                      onColumnsSubmit={onColumnsSubmit}
                    />
                  )}
                />
              ),
          )}
        </Grid>
      </ExcelExport>
    </div>
  );
};

export default UnitGrid;

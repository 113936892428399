import * as React from 'react';
import moment from 'moment';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { Typography } from '@material-ui/core';

import { ReducerState } from '@/app/redux/store';
import Loading from '@/app/components/Loading/Loading';
import translate from '@/app/utils/translate';
import {
  ConfirmDialogType,
  openConfirmDialog,
} from '@/app/redux/confirmDialog';
import TemplateSelection from './TemplateSelection/TemplateSelection';
import {
  clearEvaluation,
  fetchAllJobRequirements,
  fetchArchivedJobRequirements,
  archiveEvaluation,
  changeEvaluationApprovement,
  getEvaluationInformation,
  setActiveTemplate,
  updateEvaluation,
  saveEmployeeEvaluations,
  changeEvaluationStage,
  getEnumScoreGroups,
  clearActiveTemplate,
} from '@/app/redux/jobRequirementEvaluations';
import {
  ActiveTemplate,
  ComponentItem,
  EvaluationStage,
  EvaluationTemplate,
  JobReqEvaluationResponse,
  ScoreGroup,
} from '../types';
import { Status } from '../enums';

type IncomingProps = {
  employeeId: number;
};

type MapStateToProps = {
  allJobRequirementTemplates: EvaluationTemplate[];
  archivedEvaluations: [];
  activeTemplate: ActiveTemplate;
  scoreGroups: ScoreGroup[];
};

type MapDispatchToProps = {
  fetchAllJobRequirements: () => Promise<void>;
  fetchArchivedJobRequirements: (employeeId: number) => Promise<void>;
  getEvaluationInformation: (
    template: any,
    employeeId: number,
  ) => Promise<void>;
  setActiveTemplate: (
    template: any,
    employeeId: number,
    fromArchive?: boolean,
  ) => Promise<void>;
  updateEvaluation: (
    templateId: string,
    responses: JobReqEvaluationResponse,
  ) => Promise<void>;
  saveEmployeeEvaluations: (
    tempateId: string,
    component: ComponentItem,
  ) => Promise<void>;
  openConfirmDialog: (payload: ConfirmDialogType) => Promise<void>;
  clearEvaluation: () => Promise<void>;
  changeEvaluationApprovement: (
    evaluationId: string,
    newApprovedBy: string,
  ) => Promise<void>;
  archiveEvaluation: (evaluationId: string) => Promise<void>;
  changeEvaluationStage: (
    setStage: EvaluationStage,
    userMakingTheChange: string,
  ) => Promise<void>;
  getEnumScoreGroups: () => Promise<void>;
  clearActiveTemplate: () => Promise<void>;
};

type PropsType = IncomingProps & MapStateToProps & MapDispatchToProps;

type StateType = {
  isLoading: boolean;
};

class JobRequirementEvaluationCointainer extends React.PureComponent<
  PropsType,
  StateType
> {
  state: StateType = {
    isLoading: false,
  };

  componentDidMount() {
    this.setState({ isLoading: true });
    this.props.fetchArchivedJobRequirements(this.props.employeeId);
    this.props.fetchAllJobRequirements().then(() => {
      let firstActiveTemplate = this.props.allJobRequirementTemplates.find(
        (template: any) =>
          template.status === Status.ACTIVE &&
          moment(template.deadline)
            .endOf('day')
            .isAfter(new Date()),
      );

      // If all templates have passed deadlines set the first active template a locked template
      if (firstActiveTemplate === undefined) {
        firstActiveTemplate = this.props.allJobRequirementTemplates.find(
          (template: any) => template.status === Status.ACTIVE,
        );
      }

      if (firstActiveTemplate === undefined) {
        this.props.clearActiveTemplate();
      }

      if (firstActiveTemplate) {
        this.props.getEvaluationInformation(
          firstActiveTemplate,
          this.props.employeeId,
        );
      }
      this.props.getEnumScoreGroups();
      this.setState({ isLoading: false });
    });
  }

  getTemplateDetails = (templateId: string, templates: any) => {
    return templates.find((template: any) => template.id === templateId);
  };

  handleTemplateChange = (selectedTemplateId: string) => {
    const newActiveTemplate = this.getTemplateDetails(
      selectedTemplateId,
      this.props.allJobRequirementTemplates,
    );
    const archivedEvaluation = this.getTemplateDetails(
      selectedTemplateId,
      this.props.archivedEvaluations,
    );

    if (newActiveTemplate) {
      return this.props.setActiveTemplate(
        newActiveTemplate,
        this.props.employeeId,
      );
    } else if (archivedEvaluation) {
      const activeTemplate = this.getTemplateDetails(
        archivedEvaluation.templateId,
        this.props.allJobRequirementTemplates,
      );
      const archivedTemplate = {
        ...activeTemplate,
        id: archivedEvaluation.id,
      };

      return this.props.setActiveTemplate(
        archivedTemplate,
        this.props.employeeId,
        true,
      );
    }
    return this.props.setActiveTemplate(
      newActiveTemplate,
      this.props.employeeId,
    );
  };

  archiveEvaluation = (evaluationId: string) => {
    return this.props.archiveEvaluation(evaluationId).then(() => {
      this.props.fetchArchivedJobRequirements(this.props.employeeId);
    });
  };

  handleEvaluationsSave = (evaluationId: string, component: ComponentItem) => {
    return this.props
      .saveEmployeeEvaluations(evaluationId, component)
      .then(() => {
        return this.props.getEvaluationInformation(
          this.props.activeTemplate,
          this.props.employeeId,
        );
      });
  };

  render() {
    const {
      allJobRequirementTemplates,
      activeTemplate,
      archivedEvaluations,
    } = this.props;
    const { isLoading } = this.state;
    const notStartedStatus = activeTemplate.currentEvaluationId === undefined;
    return (
      <>
        {isLoading && <Loading key="loading" />}
        {!isLoading &&
          allJobRequirementTemplates.length > 0 &&
          activeTemplate &&
          activeTemplate.id && (
            <div>
              <TemplateSelection
                templates={allJobRequirementTemplates}
                archivedEvaluations={archivedEvaluations}
                handleTemplateChange={this.handleTemplateChange}
                archiveEvaluation={this.archiveEvaluation}
                changeEvaluationApprovement={
                  this.props.changeEvaluationApprovement
                }
                activeTemplate={activeTemplate}
                handleEvaluationsSave={this.handleEvaluationsSave}
                updateEvaluation={this.props.updateEvaluation}
                openConfirmDialog={this.props.openConfirmDialog}
                clearEvaluation={this.props.clearEvaluation}
                setActiveTemplate={this.props.setActiveTemplate}
                employeeId={this.props.employeeId}
                notStartedStatus={notStartedStatus}
                changeEvaluationStage={this.props.changeEvaluationStage}
              />
            </div>
          )}
        {!isLoading &&
          (!allJobRequirementTemplates.length || !activeTemplate.id) && (
            <Typography variant="h6">{translate.t('laNoResults')}</Typography>
          )}
      </>
    );
  }
}

const mapStateToProps = (state: ReducerState) => {
  const {
    allJobRequirementTemplates,
    activeTemplate,
    archivedEvaluations,
    scoreGroups,
  } = state.jobRequirementEvaluations;

  return {
    allJobRequirementTemplates,
    activeTemplate,
    archivedEvaluations,
    scoreGroups,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAllJobRequirements: () => dispatch<any>(fetchAllJobRequirements()),
  fetchArchivedJobRequirements: (employeeId: number) =>
    dispatch<any>(fetchArchivedJobRequirements(employeeId)),
  getEvaluationInformation: (template: any, employeeId: number) =>
    dispatch<any>(getEvaluationInformation(template, employeeId)),
  changeEvaluationApprovement: (evaluationId: string, newApprovedBy: string) =>
    dispatch<any>(changeEvaluationApprovement(evaluationId, newApprovedBy)),
  setActiveTemplate: (
    template: any,
    employeeId: number,
    fromArchive: boolean = false,
  ) => dispatch<any>(setActiveTemplate(template, employeeId, fromArchive)),
  archiveEvaluation: (evaluationId: string) =>
    dispatch<any>(archiveEvaluation(evaluationId)),
  updateEvaluation: (templateId: string, responses: any) =>
    dispatch<any>(updateEvaluation(templateId, responses)),
  openConfirmDialog: (payload: ConfirmDialogType) =>
    dispatch(openConfirmDialog(payload)),
  clearEvaluation: () => dispatch<any>(clearEvaluation()),
  saveEmployeeEvaluations: (templateId: string, component: any) =>
    dispatch<any>(saveEmployeeEvaluations(templateId, component)),
  changeEvaluationStage: (setStage: any, userMakingTheChange: string) => {
    dispatch<any>(changeEvaluationStage(setStage, userMakingTheChange));
  },
  getEnumScoreGroups: () => dispatch<any>(getEnumScoreGroups()),
  clearActiveTemplate: () => dispatch<any>(clearActiveTemplate()),
});

const enhance = compose<PropsType, IncomingProps>(
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(JobRequirementEvaluationCointainer);

import { IconButton, WithTheme, withTheme } from '@material-ui/core';
import * as React from 'react';
import { compose } from 'recompose';

type Props = {} & WithTheme;

const WorkspaceLink = ({ theme }: Props) => (
  <IconButton
    component="a"
    href="/v2/my-workspace"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill={theme.palette.primary.main}
    >
      <g><path d="M0,0h24v24H0V0z" fill="none"/></g>
      <g>
        <g>
          <path d="M3,11h8V3H3V11z M5,5h4v4H5V5z"/>
          <path d="M13,3v8h8V3H13z M19,9h-4V5h4V9z"/>
          <path d="M3,21h8v-8H3V21z M5,15h4v4H5V15z"/>
          <polygon points="18,13 16,13 16,16 13,16 13,18 16,18 16,21 18,21 18,18 21,18 21,16 18,16"/>
        </g>
      </g>
    </svg>
  </IconButton>
);

const enhance = compose<Props, {}>(
  withTheme(),
);

export default enhance(WorkspaceLink);

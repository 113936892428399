import * as React from 'react';
import { ChangeEvent, useState } from 'react';
import compose from 'recompose/compose';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import translate from '@/app/utils/translate';
import {
  changeData,
  getFormDialog,
} from '@/app/components/FormDialog/FormDialog';
import autoDeductionRulesDialogStyle from './AutoDeductionRuleFormDialogStyle';
import {
  AutoDeductionRule,
  SelectOptionProps,
} from '@/app/components/AutoDeduction/types';
import { useAutoDeductionOptions } from '@/app/components/AutoDeduction/useAutoDeductionOptions';
import moment from 'moment';

interface MapStateToProps {
  currentValues: AutoDeductionRule;
}

interface MapDispatchToProps {
  throwError: any;
}

interface OwnProps {
  title: string;
  open: boolean;
  data?: AutoDeductionRule;
  allRules: AutoDeductionRule[];
  onClose: () => void;
  onSubmit: Function;
  resetForm?: (formName: string) => void;
}

type PropsType = OwnProps &
  MapDispatchToProps &
  WithStyles<typeof autoDeductionRulesDialogStyle> &
  MapStateToProps;

const validate = (values: AutoDeductionRule) => {
  let errors: { [k: string]: any } = {};

  const requiredFields = [
    'countryCode',
    'correctionCode',
    'duration',
    'threshold',
    'nonWorkingCode',
  ];

  const durationFields = ['duration', 'threshold'];

  durationFields.forEach(field => {
    if (!moment(values[field], 'H:mm', true).isValid()) {
      errors[field] = translate.t('laErrorDurationFormat');
    }
  });

  requiredFields.forEach(field => {
    if (!values[field] || values[field] === '<p>&nbsp;</p>') {
      errors[field] = translate.t('laThisRequired');
    }
  });

  return errors;
};

const emptyFormData: AutoDeductionRule = {
  id: 0,
  isActive: false,
  countryCode: '',
  unitId: undefined,
  correctionCode: '',
  nonWorkingCode: '',
  duration: '0:30',
  threshold: '6:00',
};

const getFormData = (existingData?: AutoDeductionRule): AutoDeductionRule => {
  if (existingData && existingData.id) {
    return existingData;
  }

  return emptyFormData;
};

const FORM_NAME = 'autoDeductionRuleForm';
const FormDialog = getFormDialog(FORM_NAME, validate);
const AutoDeductionRuleFormDialog: React.FC<PropsType> = props => {
  const {
    allRules,
    title,
    data,
    classes,
    resetForm,
    onClose,
    onSubmit,
    open,
  } = props;
  const {
    unitOptions,
    countryOptions,
    correctionTypeOptions,
    nonWorkTypeOptions,
  } = useAutoDeductionOptions();
  const [formData, setFormData] = useState<AutoDeductionRule>(
    getFormData(data),
  );
  const [formErrors, setFormErrors] = useState({});
  const [availableUnitOptions, setAvailableUnitOptions] = useState(unitOptions);
  React.useEffect(() => {
    setAvailableUnitOptions(unitOptions);
  }, [unitOptions]);

  React.useEffect(() => {
    setFormData(getFormData(data));

    if (data) {
      updateAvailableUnits(data.countryCode);
    }
  }, [data]);

  const setFormValues = () => {
    Object.keys(formData).forEach(field => {
      changeData({ form: FORM_NAME, field, value: formData[field] });
    });
  };

  const handleFormReset = () => {
    setFormData(getFormData());
    resetForm(FORM_NAME);
    setFormValues();
    setFormErrors({});
    setAvailableUnitOptions(unitOptions);
  };

  const handleClose = () => {
    onClose();
    handleFormReset();
  };

  const handleSubmit = (values: AutoDeductionRule) => {
    const errors = validate(values);
    if (Object.keys(errors).length) {
      setFormErrors(errors);
      return;
    }

    onSubmit(values);
    handleFormReset();
  };

  const updateAvailableUnits = (countryCode: string) => {
    const activeRuleUnitIdString =
      data && data.unitId && data.unitId.toString();
    const unitIdsConfiguredForCountry = allRules
      .filter(rule => rule.countryCode === countryCode)
      .map(rule => (rule.unitId ? `${rule.unitId}` : ''));
    const availableUnits = unitOptions.filter(
      unitOption =>
        !unitIdsConfiguredForCountry.includes(unitOption.value) ||
        activeRuleUnitIdString === unitOption.value,
    );

    setAvailableUnitOptions(availableUnits);
  };

  const updateFieldValue = (fieldName: string, value: any) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleCountryChange = (fieldName: string) => (
    event: ChangeEvent<HTMLSelectElement>,
  ) => {
    updateAvailableUnits(event.target.value);
    updateFieldValue(fieldName, event.target.value);
  };

  const handleUnitChange = (fieldName: string) => (
    event: ChangeEvent<HTMLSelectElement>,
  ) => updateFieldValue(fieldName, event.target.value);

  const handleSelectChange = (fieldName: string) => (
    event: ChangeEvent<HTMLSelectElement>,
  ) => updateFieldValue(fieldName, event.target.value);

  const handleDurationFieldUpdate = (fieldName: string) => (
    event?: ChangeEvent<HTMLInputElement>,
  ) => updateFieldValue(fieldName, event.target.value);

  const handleSwitchChange = (fieldName: string) => (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    updateFieldValue(fieldName, event.target.checked);
  };

  const renderSelectField = (selectProps: {
    fieldName: string;
    label: string;
    options: SelectOptionProps[];
    onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
    helperText?: string;
  }) => {
    const { fieldName, label, onChange, helperText, options } = selectProps;
    return (
      <FormControl className={classes.formControl} fullWidth margin="normal">
        <InputLabel htmlFor={fieldName}>{label}</InputLabel>
        <Select
          error={formErrors[fieldName]}
          className={classes.select}
          id={fieldName}
          value={formData[fieldName] || ''}
          onChange={onChange}
        >
          {options.map(option => (
            <MenuItem value={option.value} key={`${fieldName}-${option.value}`}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  };

  const renderDurationField = (durationProps: {
    fieldName: string;
    label: string;
  }) => {
    return (
      <FormControl className={classes.formControl} fullWidth margin="normal">
        <TextField
          error={!!formErrors[durationProps.fieldName]}
          placeholder="HH:mm"
          className={classes.textField}
          type={'text'}
          id={durationProps.fieldName}
          value={formData[durationProps.fieldName]}
          onChange={handleDurationFieldUpdate(durationProps.fieldName)}
          label={durationProps.label}
        />
      </FormControl>
    );
  };

  const renderSwitch = (switchProps: { fieldName: string; label: string }) => {
    return (
      <FormControlLabel
        control={
          <Switch
            id={switchProps.fieldName}
            checked={formData[switchProps.fieldName]}
            onChange={handleSwitchChange(switchProps.fieldName)}
          />
        }
        label={switchProps.label}
      />
    );
  };

  return (
    <FormDialog
      title={title}
      open={open}
      onClose={handleClose}
      formData={formData}
      paperWidthMd={classes.paperWidthMd}
      onSubmit={handleSubmit}
    >
      <Grid container>
        <Grid item xs={12} sm={12} md={4} className={classes.gridLeft}>
          {renderSelectField({
            fieldName: 'countryCode',
            label: translate.t('laCountry'),
            options: countryOptions,
            onChange: handleCountryChange('countryCode'),
          })}
          {renderSelectField({
            fieldName: 'unitId',
            label: translate.t('laUnit'),
            options: availableUnitOptions,
            onChange: handleUnitChange('unitId'),
          })}
          {renderSwitch({
            fieldName: 'isActive',
            label: translate.t('laActive'),
          })}
        </Grid>
        <Grid item xs={12} sm={12} md={4} className={classes.gridRight}>
          {renderSelectField({
            fieldName: 'correctionCode',
            label: translate.t('laAutoDeductionCorrectionType'),
            options: correctionTypeOptions,
            onChange: handleSelectChange('correctionCode'),
          })}
          {renderSelectField({
            fieldName: 'nonWorkingCode',
            label: translate.t('laAutoDeductionMatchingNonWorkType'),
            helperText: translate.t(
              'laAutoDeductionMatchingNonWorkTypeInstruction',
            ),
            options: nonWorkTypeOptions,
            onChange: handleSelectChange('nonWorkingCode'),
          })}
        </Grid>
        <Grid item xs={12} sm={12} md={4} className={classes.gridRight}>
          {renderDurationField({
            fieldName: 'duration',
            label: translate.t('laWorktimeDeductionAmount'),
          })}
          {renderDurationField({
            fieldName: 'threshold',
            label: translate.t('laWorktimeDeductionThreshold'),
          })}
        </Grid>
      </Grid>
    </FormDialog>
  );
};

const enhance = compose<any, OwnProps>(
  withStyles(autoDeductionRulesDialogStyle),
);

export default enhance(AutoDeductionRuleFormDialog);

const GLOBAL: any = window;

export default (empId: number) => {
  try {
    const employee = GLOBAL.jsonAllEmp[empId];

    return {
      id: employee.fEmpId,
      code: employee.fEmpPersonNumber,
      firstName: employee.fEmpFirstName,
      lastName: employee.fEmpLastName,
      position: employee.fEmpPosition,
      unitId: employee.fEmpUnit,
      phone: employee.fEmpPhoneWork,
      mobile: employee.fEmpPhoneMob,
      email: employee.fEmpEmailWork,
    };
  } catch (e) {
    return undefined;
  }
};

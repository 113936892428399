import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const styles: StyleRulesCallback = (theme: Theme) => ({
  root: {
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
  },
  toolbar: {
    paddingLeft: theme.spacing.unit,
  },
});

export default styles;

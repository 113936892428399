import * as React from 'react';
import translate from '@/app/utils/translate';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { WithStyles } from '@material-ui/core';
import GenericDialog from '../../GenericDialog/GenericDialog';
import questionsSortDialogStyle from './questionsSortDialogStyle';
import SortableComponent from '@/app/components/SortableComponent/SortableComponent';
import { SurveyQuestion } from '@/app/redux/surveys';

type OwnProps = {
  title: string;
  open: boolean;
  questionList: SurveyQuestion[];
  onClose: () => void;
  onSave: (list: SurveyQuestion[]) => void;
};

type PropsType = OwnProps & WithStyles<typeof questionsSortDialogStyle>;

type StateType = {
  list: any[];
  isDisabledSave: boolean;
};

class QuestionsSortDialog extends React.Component<PropsType> {
  state: StateType = {
    list: [],
    isDisabledSave: true,
  };

  componentDidUpdate(prevProps: PropsType) {
    if (this.props.open && (this.props.open !== prevProps.open)) {
      this.setState({
        list: this.enumList(),
        isDisabledSave: true,
      });
    }
  }

  enumList() {
    const { questionList } = this.props;

    if (questionList) {
      const sortedQuestions = questionList
        .map(({ text, order, ...attr }, index) => ({
          text,
          name: text,
          order: order ? order : index + 1,
          ...attr,
        }))
        .sort((a: any, b: any) => a.order - b.order);

      return sortedQuestions;
    }

    return [];
  }

  handleSave = () => {
    const { list: oldList } = this.state;
    const list = oldList.map((item: SurveyQuestion, index: number) => {
      return Object.assign(
        {},
        item.hasOwnProperty('yesLabel') ? { yesLabel: item.yesLabel } : {},
        item.hasOwnProperty('noLabel') ? { noLabel: item.noLabel } : {},
        item.hasOwnProperty('options') ? { options: item.options } : {},
        {
          order: index + 1,
          text: item.text,
          id: item.id,
          type: item.type,
          typeLabel: item.typeLabel,
        },
      );
    });
    this.props.onSave(list);
  }

  handleUpdateList = (list: any[]) => {
    this.setState({
      list,
      isDisabledSave: false
    });
  }

  render() {
    const { title, open, classes, onClose } = this.props;
    const { list, isDisabledSave } = this.state;

    return (
      <GenericDialog
        open={open}
        title={title}
        onClose={onClose}
        onSave={this.handleSave}
        paperWidthMd={classes.paperWidthMd}
        description={translate.t('laSortIns')}
        isDisabledSave={isDisabledSave}
      >
        <SortableComponent
          showDragHandle
          list={list}
          updatedList={this.handleUpdateList}
        />
      </GenericDialog>
    );
  }
}

const enhance = compose<any, OwnProps>(
  withStyles(questionsSortDialogStyle, {withTheme: true}),
);

export default enhance(QuestionsSortDialog);

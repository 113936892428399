import React from 'react';

import { FieldProps } from 'formik';

import { DatePickerProps } from 'material-ui-pickers/DatePicker';
import moment from 'moment';

import LocalizedDatePicker from '@/app/components/Pickers/LocalizedDatePicker';
import translate from '@/app/utils/translate';
import { FormikFieldError } from './FormikFieldError';
import { hasError } from '../utils';

type OwnProps = {};
type FormikDateFieldProps = OwnProps & FieldProps & DatePickerProps;

export function FormikDateField({
  field,
  form,
  ...others
}: FormikDateFieldProps) {
  const defaultProps = {
    margin: 'dense' as 'dense',
    autoOk: true,
    clearable: false,
    okLabel: translate.t('laOk'),
    cancelLabel: translate.t('laCancel'),
    clearLabel: translate.t('laClear'),
  };

  const handleClose = () => {
    form.setFieldTouched(field.name, true);
  };

  const handleChange = (value: moment.Moment) => {
    form.setFieldTouched(field.name, true);
    form.setFieldValue(field.name, !value ? null : value);
  };

  const error = hasError(field.name, form, null);

  return (
    <>
      <LocalizedDatePicker
        {...defaultProps}
        {...field}
        {...others}
        InputLabelProps={{ error }}
        InputProps={{ error }}
        onClose={handleClose}
        onChange={handleChange}
        minDate={others.initialFocusedDate}
      />
      <FormikFieldError name={field.name} />
    </>
  );
}

import * as React from 'react';
import CreatableSelect from 'react-select/lib/Creatable';
import translate from '@/app/utils/translate';

export type ComboSelectOptionValueType = {
  value: string;
  label: string;
};

interface Props {
  options: ComboSelectOptionValueType[];
  selectedValue: ComboSelectOptionValueType;
  customStyles?: any;
  onChange(newValue: any): void;
}

const comboSelect: React.FunctionComponent<Props> = (props) => {
  const { selectedValue, options, onChange, customStyles } = props;
  return (
    <CreatableSelect
      createOptionPosition={'first'}
      defaultValue={selectedValue}
      formatCreateLabel={(value: string) => `${translate.t('laUse')}: ${value}`}
      isClearable
      onChange={onChange}
      options={options}
      placeholder={translate.t('laSelectOrType')}
      styles={customStyles}
    />
  );
};

export default comboSelect;

import * as React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { compose, withState } from 'recompose';
import { ChildInputType } from '@/app/components/FormFields/FormFields';
import { Button } from '@material-ui/core';

interface OutterProps {
  data: Array<ChildInputType>;
  onSelect: (d: ChildInputType) => void;
  onClose?: () => void;
  useButton?: boolean;
}

interface Props extends OutterProps {
  anchorEl: any;
  updateAnchorEl: (el: any) => void;
}

const PropsMenu: React.FunctionComponent<Props> = ({ anchorEl, updateAnchorEl, children, data, onSelect, onClose, useButton = false}) => {
  const open = Boolean(anchorEl);
  const handleSelect = (d: ChildInputType) => {
    onSelect(d);
    handleClose();
  };

  const handleClose = () => {
    if (typeof(onClose) !== 'undefined') { onClose(); }
    updateAnchorEl(null);
  };

  const anchorInstance = useButton ? (
    <Button
      onClick={(event: any) => {
        updateAnchorEl(event.currentTarget);
      }}
    >
      {children}
    </Button>
  ) : (
    <span
      onClick={(event: any) => {
        updateAnchorEl(event.currentTarget);
      }}
    >
      {children}
    </span>
  );

  return (
    <>
      {anchorInstance}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {data.map((d, i) => <MenuItem key={`${d.value}-${i}`} onClick={() => handleSelect(d)}>{d.label}</MenuItem>)}
      </Menu>
    </>
  );
};

const enhance = compose<Props, OutterProps>(
  withState('anchorEl', 'updateAnchorEl', null)
);

export default enhance(PropsMenu);

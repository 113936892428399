import translate from '@/app/utils/translate';
import { Menu, MenuItem, WithStyles } from '@material-ui/core';
import * as React from 'react';

import { ReportSettings, ReportSettingsPrivacyType } from '../../models';

import { StyleKeys } from './styles';
import ConfirmDialog from '@/app/components/ConfirmDialog/ConfirmDialog';
import { eventTargetAsHtmlElement } from '@/old/utils/helper';

interface OpenReportSettingsMenuOuterProps {
    reportSettings: ReportSettings[];
    openedReportSettings: ReportSettings;
    initialReportSettings: string;
    onChange: (reportSettings?: ReportSettings) => void;
    getCurrentReportSettings: () => object;
    disabled: boolean;
}

export interface OpenReportSettingsMenuInnerProps extends OpenReportSettingsMenuOuterProps, WithStyles<StyleKeys> {}

const groupTranslationKeys = {
    [ReportSettingsPrivacyType.PERSONAL]: 'laPersonalReportSettings',
    [ReportSettingsPrivacyType.COMMON]: 'laCommonReportSettings',
};

export const OpenReportSettingsMenu: React.FC<OpenReportSettingsMenuInnerProps> = ({
    classes,
    reportSettings,
    openedReportSettings,
    initialReportSettings,
    onChange,
    getCurrentReportSettings,
    disabled,
}) => {
    const [menuAnchorEl, setMenuAnchorEl] = React.useState<HTMLElement|undefined>();
    const [showConfirmChangeDialog, setShowConfirmChangeDialog] = React.useState(false);
    const [requestedNextOpenedReportSettings, setRequestedNextOpenedReportSettings] = React.useState<ReportSettings|undefined>();

    const handleOpenMenu = React.useCallback(
        (event: React.SyntheticEvent) => {
            setMenuAnchorEl(eventTargetAsHtmlElement(event.currentTarget));
        },
        [setMenuAnchorEl]
    );

    const handleCloseMenu = React.useCallback(
        () => { setMenuAnchorEl(undefined); },
        [setMenuAnchorEl]
    );

    const handleOpenReportSettings = React.useCallback(
        (event: React.SyntheticEvent) => {
            const dataIdAttribute = event.currentTarget.attributes['data-id'];
            const nextOpenedReportSettingsId = dataIdAttribute && dataIdAttribute.value;
            const nextOpenedReportSettings: ReportSettings | undefined = nextOpenedReportSettingsId
                ? reportSettings.find((rs) => {
                    return rs.id === nextOpenedReportSettingsId;
                })
                : undefined;

            if (openedReportSettings !== nextOpenedReportSettings) {
                const openedReportSettingsInitialData = openedReportSettings && openedReportSettings.activeColumns || initialReportSettings;
                const hasUnsavedSettings = openedReportSettingsInitialData !== JSON.stringify(getCurrentReportSettings());

                if (hasUnsavedSettings) {
                    setRequestedNextOpenedReportSettings(nextOpenedReportSettings);
                    setShowConfirmChangeDialog(true);
                } else {
                    onChange(nextOpenedReportSettings);
                }
            }

            handleCloseMenu();
        },
        [
            handleCloseMenu,
            setRequestedNextOpenedReportSettings,
            setShowConfirmChangeDialog,
            reportSettings,
            openedReportSettings,
            initialReportSettings,
            getCurrentReportSettings,
            onChange,
        ]
    );

    const handleCloseConfirmChangeDialog = React.useCallback(
        () => {
            setRequestedNextOpenedReportSettings(undefined);
            setShowConfirmChangeDialog(false);
        },
        [setRequestedNextOpenedReportSettings, setShowConfirmChangeDialog]
    );

    const handleChangeOpenedReportSettings = React.useCallback(
        () => {
            onChange(requestedNextOpenedReportSettings);
            handleCloseConfirmChangeDialog();
        },
        [
            onChange,
            requestedNextOpenedReportSettings,
            handleCloseConfirmChangeDialog
        ]
    );

    const groupedReportSettings = React.useMemo(
        () => {
            return reportSettings.reduce<{ [key: string]: ReportSettings[] }>(
                (accumulator, rs) => {
                    (accumulator[rs.privacyType] = accumulator[rs.privacyType] || []).push(rs);
                    return accumulator;
                },
                {}
            );
        },
        [reportSettings]
    );

    return (
        <>
            <button
                className={`k-button ${classes.menuButton}`}
                aria-owns={menuAnchorEl && 'open-report-settings-menu'}
                aria-haspopup={true}
                onClick={handleOpenMenu}
                disabled={disabled}
            >
                {translate.t('laOpenReportSettings')}
            </button>

            <Menu
                id="open-report-settings-menu"
                anchorEl={menuAnchorEl}
                open={!!menuAnchorEl}
                onClose={handleCloseMenu}
            >
                <MenuItem key="default-item" onClick={handleOpenReportSettings}>
                    {translate.t('laDefaultReportSettings')}
                </MenuItem>

                {Object.keys(groupedReportSettings).reduce(
                    (accumulator, gr) => {
                        accumulator.push(
                            <MenuItem key={`${gr}-GROUP`} className={classes.groupItem} disabled={true}>
                                {translate.t(groupTranslationKeys[gr])}
                            </MenuItem>
                        );

                        accumulator.push(
                            ...groupedReportSettings[gr].map((rs) => {
                                return (
                                    <MenuItem
                                        key={rs.id}
                                        className={classes.listItemInGroup}
                                        onClick={handleOpenReportSettings}
                                        data-id={rs.id}
                                    >
                                        {rs.name}
                                    </MenuItem>
                                );
                            })
                        );

                        return accumulator;
                    },
                    []
                )}
            </Menu>

            <ConfirmDialog
                open={showConfirmChangeDialog}
                text={translate.t('laConfirmChangeReportSettings')}
                onOk={handleChangeOpenedReportSettings}
                onClose={handleCloseConfirmChangeDialog}
            />
        </>
    );
};

import API from '../../../app/api/internalAPIs';
import Service from '../../../app/utils/service';
import translate from '../../../app/utils/translate';
import {
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import Loading from '../Loading/Loading';
import style from './dataRetentionStyle';
import { DataRetentionSetting } from './types';
import { useSnackbar } from 'notistack';

type OwnProps = {};
type InnerProps = WithStyles<typeof style>;
type Props = OwnProps & InnerProps;

const DataRetentionHRAdmin = ({ classes }: Props) => {
  const [dataRetentionSettings, setDataRetentionSettings] = useState([]);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const fetchData = () => {
    Service.get(
      API.dataRetentionFull(),
      (resp: DataRetentionSetting[]) => {
        setDataRetentionSettings(resp);
        setLoading(false);
      },
      (_err: any) => {
        enqueueSnackbar(translate.t('laGeneralError'), {
          variant: 'error',
        });
      },
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const moduleOptions = {
    PEOPLE_AND_REWARDING: `${translate.t('laPeople')} & ${translate.t(
      'laRewarding',
    )}`,
    PERFORMANCE: translate.t('laPerformance'),
    DEVELOPMENT: translate.t('laDevelopment'),
    RECRUITING: translate.t('laRecruiting'),
    TRAINING_CALENDAR: translate.t('training_calendar'),
  };

  const handleRunRetention = (module: string) => {
    Service.delete(
      API.deleteDataRetention(module),
      undefined,
      (_data: any) => {
        fetchData();
        enqueueSnackbar(translate.t('message_success_approval'), {
          variant: 'success',
        });
      },
      (_err: any) => {
        enqueueSnackbar(translate.t('laGeneralError'), {
          variant: 'error',
        });
      },
    );
  };

  return (
    <Paper className={classes.paper}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.title}>
          <Typography variant="h6">{translate.t('laDataRetention')}</Typography>
        </div>
      </Toolbar>
      {loading ? (
        <Loading />
      ) : (
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell align="left">
                <Typography className={classes.bold}>
                  {translate.t('laModule')}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.bold}>
                  {translate.t('laAutoDataRetentionSelect')}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.bold}>
                  {translate.t('laDataRetentionTimeline')}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.bold}>
                  {translate.t('laNumOfItems')}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.bold}>
                  {translate.t('laLastTimeOfRetention')}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.bold}>
                  {translate.t('laClickToRunDataRetention')}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRetentionSettings.map(module => (
              <TableRow key={moduleOptions[module.fComDataRetModule]}>
                <TableCell align="left">
                  <Typography>
                    {moduleOptions[module.fComDataRetModule]}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  {module.fComDataRetIsAutomatic ? (
                    <Checkbox checked disabled />
                  ) : (
                    <Checkbox disabled />
                  )}
                </TableCell>
                <TableCell align="center">
                  <Typography>
                    {module.fComDataRetTimeline ? (
                      <>
                        {module.fComDataRetTimeline >= 12
                          ? `${module.fComDataRetTimeline / 12} years`
                          : `${module.fComDataRetTimeline} months`}
                      </>
                    ) : (
                      '-'
                    )}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>{module.fComDataRetAmount}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>{module.fComDataRetLastRun}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Button
                    variant="contained"
                    id="runRetentionBtnId"
                    className={classes.runRetentionBtn}
                    onClick={() => handleRunRetention(module.fComDataRetModule)}
                  >
                    {translate.t('runManualRetentionNow')}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Paper>
  );
};

const enhance = compose<Props, OwnProps>(
  withStyles(style, { withTheme: true }),
);

export default enhance(DataRetentionHRAdmin);

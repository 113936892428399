import { textField, icon } from '@/app/jss/common';
import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

export const styles = {
  textFieldInline: {
    marginRight: '10px',
  },
  textField: {
    ...textField,
    width: '100%',
  },
  inputFile: {
    display: 'none',
  },
  icon: {
    ...icon,
  },
  uploadField: {
    fontSize: '16px',
    height: '30px',
  },
};

const formFieldsStyle: StyleRulesCallback = () => ({
  styles,
  hiddenField: {
    display: 'none',
    height: 0,
    visibility: 'hidden',
  },
});

export default formFieldsStyle;

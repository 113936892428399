import React, { SyntheticEvent, useContext, useState } from 'react';
import {
  TableCell,
  Typography,
  Table,
  TableRow,
  TableHead,
  TableBody,
  WithStyles,
  withStyles,
  Menu,
  Icon,
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
  TablePagination,
  Checkbox,
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import translate from '@/app/utils/translate';

import styles from './historyTable/styles';
import {
  formatTrainingDates,
  sortByDateDesc,
  getTrainingGroupShortName,
} from '../../helpers';
import Loading from '@/app/components/Loading/Loading';
import { CompanyTraining, EmployeeTraining, TrainingType } from '../../types';
import EmployeeTrainingUpdateDialog from './EmployeeTrainingUpdateDialog';
import ConfirmDialog from '@/app/components/ConfirmDialog/ConfirmDialog';
import Context from '../../context/trainingsContext';
import moment from 'moment';
import { sanitize } from 'dompurify';
import Service from '@/app/utils/service';
import API from '@/app/api/internalAPIs';
import { useSnackbar } from 'notistack';
import { getCurrentLanguage } from '@/app/utils/helper';

type OwnProps = {
  // trainingGroup: 'INTERNAL_TRAINING_TYPE' | 'EXTERNAL_TRAINING_TYPE' | 'OTHER_TRAINING_TYPE';
  isLoggedUserData?: boolean;
};
type InnerProps = WithStyles<typeof styles>;

type Props = OwnProps & InnerProps;

const HistoryTable = ({
  classes,
  isLoggedUserData,
}: // trainingGroup,
Props) => {
  const {
    state: {
      employeeTrainings: {
        history: { data: trainings, loading, error },
      },
    },
    deleteEmployeeTraining,
    downloadEmployeeTrainingAttachment,
  } = useContext(Context);
  // const trainings = data.filter((t: EmployeeTraining) => t.trainingGroup.value === trainingGroup);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedTraining, setSelectedTraining] = React.useState<
    EmployeeTraining
  >();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<
    undefined | HTMLElement
  >(undefined);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [confirmDialogText, setConfirmDialogText] = React.useState('');
  const [openDetails, setOpenDetails] = React.useState(false);
  const [description, setDescription] = React.useState<string>(undefined);
  const { enqueueSnackbar } = useSnackbar();

  const handlePageChange = (_e: any, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (e: any) => {
    setRowsPerPage(e.target.value);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(undefined);
  };

  const handleEditTraining = () => {
    setOpenEditDialog(true);
    handleCloseMenu();
  };
  const handleDeleteTraining = () => {
    setConfirmDialogText(translate.t('text_confirm_employee_training_delete'));
    handleCloseMenu();
  };

  const handleDeleteTrainingExecute = () => {
    deleteEmployeeTraining(selectedTraining);
    handleCloseConfirmDialog();
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedTraining(undefined);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogText(undefined);
    setSelectedTraining(undefined);
  };

  const handleDescriptionDialogClose = () => {
    setSelectedTraining(undefined);
    setDescription(undefined);
    setOpenDetails(false);
  };

  const getTrainingDescription = () => {
    if (selectedTraining.companyTrainingFK) {
      setOpenDetails(true);
      Service.get(
        API.training.companyTraining(selectedTraining.companyTrainingFK),
        (response: CompanyTraining) => {
          setDescription(response.description);
        },
        () => {
          enqueueSnackbar(`${translate.t('laFailedToLoad')}`, {
            variant: 'error',
          });
        },
      );
    }
  };

  const rowMenu =
    !selectedTraining || !menuAnchorEl ? null : (
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem
          onClick={handleEditTraining}
          data-testid="btn-training-history-edit"
        >
          <ListItemIcon>
            <Icon>edit</Icon>
          </ListItemIcon>
          <ListItemText>{translate.t('laEdit')}</ListItemText>
        </MenuItem>
        {selectedTraining.companyTrainingFK && (
          <MenuItem onClick={getTrainingDescription}>
            <ListItemIcon>
              <Icon>feed</Icon>
            </ListItemIcon>
            <ListItemText>{translate.t('laDetails')}</ListItemText>
          </MenuItem>
        )}
        <MenuItem
          onClick={handleDeleteTraining}
          data-testid="btn-training-history-delete"
        >
          <ListItemIcon>
            <Icon>delete</Icon>
          </ListItemIcon>
          <ListItemText>{translate.t('laDelete')}</ListItemText>
        </MenuItem>
      </Menu>
    );

  const editDialog =
    !openEditDialog || !selectedTraining ? null : (
      <EmployeeTrainingUpdateDialog
        open={openEditDialog}
        initialValues={selectedTraining}
        onClose={handleCloseEditDialog}
        isLoggedUserData={isLoggedUserData}
      />
    );

  const confirmDialog =
    !confirmDialogText || !selectedTraining ? null : (
      <ConfirmDialog
        open={true}
        text={confirmDialogText}
        customOk={translate.t('laDelete')}
        onOk={handleDeleteTrainingExecute}
        onClose={handleCloseConfirmDialog}
      />
    );

  const detailsDialog = !selectedTraining ? null : (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={handleDescriptionDialogClose}
      open={openDetails}
    >
      <DialogTitle>{translate.t('laDetails')}</DialogTitle>
      <DialogContent className={classes.detailsDialog}>
        {!description ? (
          <Loading />
        ) : (
          <Typography
            gutterBottom
            dangerouslySetInnerHTML={{ __html: sanitize(description) }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleDescriptionDialogClose}>
          {translate.t('laClose')}
        </Button>
      </DialogActions>
    </Dialog>
  );

  let rows;
  if (!!error && error !== '') {
    const errorRow = (
      <TableRow key="error">
        <TableCell colSpan={7}>
          <Typography key="error" color="error">{`ERROR: ${error}`}</Typography>
        </TableCell>
      </TableRow>
    );
    rows = [errorRow];
  } else if (loading) {
    const loadingRow = (
      <TableRow key="loading">
        <TableCell colSpan={7}>
          <Loading size="small" />
        </TableCell>
      </TableRow>
    );
    rows = [loadingRow];
  } else {
    rows = trainings
      .sort((a: EmployeeTraining, b: EmployeeTraining) =>
        sortByDateDesc(a.startDate, b.startDate),
      )
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((t: EmployeeTraining, index: number) => {
        const handleOpenMenu = (e: SyntheticEvent) => {
          setMenuAnchorEl(e.currentTarget as HTMLElement);
          setSelectedTraining(t);
        };

        // User can't edit own internal trainings
        const disableEdit =
          isLoggedUserData &&
          t.trainingGroup.value === TrainingType.INTERNAL;

        return (
          <TableRow key={`${t.trainingGroup.value}${t.id}`}>
            <TableCell
              className={getCurrentLanguage() === 'fi' ? classes.tableCell : ''}
            >
              <Typography variant="body2">{t.name.label}</Typography>
              <Typography variant="caption">
                {!t.trainingClass ? '' : t.trainingClass.label}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">{t.provider}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">
                {getTrainingGroupShortName(t.trainingGroup.value as string)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">
                {formatTrainingDates(t.startDate, t.endDate)}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">{t.hours}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" className={classes.textApproved}>
                {'' /*t.status*/}
              </Typography>
              <Typography variant="body2">{t.info}</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="body2">
                {t.attachmentId === -1 ? (
                  undefined
                ) : (
                  <IconButton
                    title={`${translate.t('laDownload')}: ${t.attachmentName}`}
                    onClick={() =>
                      downloadEmployeeTrainingAttachment(
                        t.employeeId,
                        t.attachmentId,
                      )
                    }
                  >
                    <Icon>attachment_file</Icon>
                  </IconButton>
                )}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                color={
                  !t.expires || t.expires.isAfter(moment())
                    ? 'inherit'
                    : 'error'
                }
                variant="body2"
              >
                {formatTrainingDates(t.expires, null)}
              </Typography>
            </TableCell>
            <TableCell>
              <Checkbox
                color="primary"
                checked={!!t.includeInTrainingCompensation}
                disabled
              />
            </TableCell>
            <TableCell align="right">
              {rowMenu}
              <IconButton
                onClick={handleOpenMenu}
                data-testid={`btn-training-options-${index}`}
                disabled={disableEdit}
              >
                <Icon>more_vert</Icon>
              </IconButton>
            </TableCell>
          </TableRow>
        );
      });
  }

  return (
    <>
      {editDialog}
      {confirmDialog}
      {detailsDialog}
      <Table padding="dense" data-testid="table-training-history">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="body2" color="textSecondary">
                {translate.t('laTraining')}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {translate.t('laClass')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="textSecondary">
                {translate.t('laProvider')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="textSecondary">
                {translate.t('laType')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="textSecondary">
                {translate.t('laDate')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="textSecondary">
                {translate.t('laHours')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="textSecondary">
                {translate.t('laStatusOrInfo')}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="body2" color="textSecondary">
                {translate.t('laAttachment')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="textSecondary">
                {translate.t('laExpires')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="textSecondary">
                {translate.t('label_include_in_training_compensation')}
              </Typography>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {trainings.length > 0 || loading || error ? null : (
            <TableRow>
              <TableCell colSpan={7}>{translate.t('laNoData')}</TableCell>
            </TableRow>
          )}
          {rows}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={trainings.length}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 25, 50, 100]}
        backIconButtonProps={{
          'aria-label': translate.t('label_previous_page'),
        }}
        nextIconButtonProps={{
          'aria-label': translate.t('label_next_page'),
        }}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
      />
    </>
  );
};

export default withStyles(styles)(HistoryTable);

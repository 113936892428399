import * as React from 'react';
import { compose } from 'recompose';
import {
  withStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  FormControlLabel,
  IconButton,
  WithStyles,
  Tooltip,
  Modal,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import translate from '@/app/utils/translate';
import sectionListingStyle from './sectionListingStyle';
import AddSection from '@/app/components/TemplateComponents/admin/EditTemplateComponents/AddSection/AddSection';
import ComponentListing from '@/app/components/TemplateComponents/admin/EditTemplateComponents/ComponentListing/ComponentListing';
import {
  FormTemplate,
  FormTemplateComponentDeleteCall, FormTemplateSectionDeleteCall,
  FormTemplateSection, FormTemplateSectionUpdateCall, FormTemplateComponentUpdateCall, FormTemplateComponentOrderCall
} from '@/app/components/TemplateComponents/types';

type State = {
  expanded: string;
  sectionEditModalOpen: boolean;
};

type Props = {
  customStyle: boolean;
  addComponentToSection: FormTemplateComponentUpdateCall;
  updateSection: FormTemplateSectionUpdateCall;
  updateSectionComponent: FormTemplateComponentUpdateCall;
  readyToEditTemplate: FormTemplate;
  section: FormTemplateSection;
  deleteSection: FormTemplateSectionDeleteCall;
  orderComponents: FormTemplateComponentOrderCall;
  deleteComponent: FormTemplateComponentDeleteCall;
  newSection: boolean;
  readonly?: boolean;
  onItemSelection?: (sectionId: string, selectedItemIds: string[]) => void;
  templateType: string;
} & WithStyles<typeof sectionListingStyle>;

class SectionListing extends React.Component<Props> {
  state: State = {
    expanded: null,
    sectionEditModalOpen: false,
  };

  handleChange = (panel: string) => (_event: object, expanded: boolean) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  openSectionEditModal = () => {
    this.setState({ sectionEditModalOpen: true });
  };

  closeSectionEditModal = () => {
    this.setState({ sectionEditModalOpen: false });
  };

  handleSectionDeletion = (templateId: string, sectionId: string) => {
    this.props.deleteSection(templateId, sectionId);
  };

  render() {
    const { expanded, sectionEditModalOpen } = this.state;
    const {
      classes,
      customStyle,
      addComponentToSection,
      updateSection,
      updateSectionComponent,
      readyToEditTemplate,
      section,
      orderComponents,
      deleteComponent,
      newSection,
      readonly,
      onItemSelection,
      templateType,
    } = this.props;
    const sections = readyToEditTemplate.sections;
    const lastSectionList = sections[sections.length - 1].id;

    return (
      <div style={customStyle ? { padding: '20px' } : {}}>
        {!!section && (
          <ExpansionPanel
            key={section.id}
            defaultExpanded={lastSectionList === section.id || expanded === section.id}
            onChange={this.handleChange(section.id)}
          >
            <ExpansionPanelSummary>
              <Typography
                variant="h6"
                style={{ cursor: expanded ? 'pointer' : 'auto' }}
              >
                {expanded ? (
                  <IconButton className={classes.iconButton}>
                    <ExpandMoreIcon className={classes.icon} fontSize="small" />
                  </IconButton>
                ) : (
                  <IconButton className={classes.iconButton}>
                    <ExpandMoreIcon fontSize="small" />
                  </IconButton>
                )}
                <label>{section.name}</label>
                {!readonly && (
                  <>
                    <FormControlLabel
                      aria-label="Acknowledge"
                      onClick={event => event.stopPropagation()}
                      onFocus={event => event.stopPropagation()}
                      control={
                        <IconButton onClick={() => this.openSectionEditModal()}>
                          <Tooltip title={translate.t('laEdit')}>
                            <EditIcon fontSize="small" />
                          </Tooltip>
                        </IconButton>
                      }
                      label=""
                      className={classes.noMargin}
                    />
                    <FormControlLabel
                      aria-label="ForDel"
                      onClick={event => event.stopPropagation()}
                      onFocus={event => event.stopPropagation()}
                      control={
                        <IconButton
                          onClick={() =>
                            this.handleSectionDeletion(
                              readyToEditTemplate.id,
                              section.id,
                            )
                          }
                        >
                          <Tooltip title={translate.t('laDelete')}>
                            <DeleteIcon fontSize="small" />
                          </Tooltip>
                        </IconButton>
                      }
                      label=""
                      className={classes.noMargin}
                    />
                  </>
                )}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <ComponentListing
                readyToEditTemplate={readyToEditTemplate}
                section={section}
                components={section.components}
                expanded={expanded || newSection || lastSectionList}
                addComponentToSection={addComponentToSection}
                updateSectionComponent={updateSectionComponent}
                orderComponents={orderComponents}
                deleteComponent={deleteComponent}
                readonly={readonly}
                onItemSelection={onItemSelection}
                templateType={templateType}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}
        <Modal
          open={sectionEditModalOpen && !!readyToEditTemplate}
          onClose={() => this.closeSectionEditModal()}
        >
          <AddSection
            editMode={true}
            readyToEditTemplate={readyToEditTemplate}
            section={section}
            updateSection={updateSection}
            handleModalClose={this.closeSectionEditModal}
          />
        </Modal>
      </div>
    );
  }
}

const enhance = compose<any, any>(withStyles(sectionListingStyle));

export default enhance(SectionListing);

import { bindActionCreators, Dispatch } from 'redux';

import { fetchTaskCommentsAction, clearTaskComments } from '../../../../data';
import { RawTaskComment } from '../../../../collaborationModels';

import { DispatchProps, StateProps, CommentsListOuterProps } from './CommentsList';

export const mapStateToProps = (state: any, props: CommentsListOuterProps): StateProps => {
    const taskState = state.collaboration.tasks[props.taskId];
    const taskCommentsState = taskState && taskState.comments;

    return {
        comments: Object.values<RawTaskComment>(taskCommentsState && taskCommentsState.data || {}).reverse(),
        loadingComments: taskCommentsState && taskCommentsState.loading || false,
        failedToLoadComments: taskCommentsState && taskCommentsState.failedToLoad || false,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => bindActionCreators(
    {
        fetchTaskComments: fetchTaskCommentsAction,
        clearTaskComments: clearTaskComments,
    },
    dispatch
);

import {
  WithStyles,
  Paper,
  TextField,
  Select,
  Tooltip,
  IconButton,
  Button,
  Typography,
  withStyles,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from '@material-ui/icons/Print';
import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Dispatch } from 'redux';
import { v4 as uuid4 } from 'uuid';

import { printElements } from '@/app/utils/helper';
import translate from '@/app/utils/translate';
import { ReducerState } from '@/app/redux/store';
import {
  ConfirmDialogType,
  openConfirmDialog,
} from '@/app/redux/confirmDialog';
import {
  changeEvaluationApproval,
  removeEvaluationApproval,
  archiveEvaluation,
  saveEvaluation,
} from '@/app/redux/goalEvaluations';
import { LanguagesType } from '@/app/redux/languages';
import {
  getEmployeeName,
  getLoggedUserId,
  isEmployee,
  isHR,
} from '@/old/utils/helper';

import EvaluationTotals from './EditTemplate/EvaluationTotals/EvaluationTotals';
import SectionListing from './EditTemplate/SectionListing/SectionListing';
import templateSelectionStyles from './templateSelectionStyles';
import { validateEvaluation } from './validateEvaluation';

type IncomingProps = {
  employeeId: number;
  templates: any[];
  evaluations: any[];
};

type MapStateToProps = {
  languages: LanguagesType[];
};

type MapDispatchToProps = {
  changeEvaluationApproval: (
    evaluationId: string,
    newApprovedBy: string,
  ) => void;
  removeEvaluationApproval: (
    evaluationId: string,
    newApprovedBy: string,
  ) => void;
  archiveEvaluation: (evaluationId: string) => void;
  saveEvaluation: (templateId: string, evaluation: {}) => void;
  openConfirmDialog: (payload: ConfirmDialogType) => void;
};

type PropsType = IncomingProps &
  MapStateToProps &
  MapDispatchToProps &
  WithStyles<typeof templateSelectionStyles>;

type StateType = {
  templatesWithArchivedEvaluations: any[];
  inEditMode: boolean;
  selection?: {
    template: any;
    evaluation?: any;
    formData: any;
    submitErrors?: any;
    isFromArchive: boolean;
    isEditable: boolean;
  };
};

class TemplateSelection extends React.PureComponent<PropsType, StateType> {
  state: StateType = {
    templatesWithArchivedEvaluations: [],
    inEditMode: false,
  };

  componentDidMount() {
    this.setDefaultTemplate();
    this.updateTemplatesArchivedEvaluations();
  }

  componentDidUpdate(prevProps: Readonly<PropsType>) {
    if (this.props.evaluations !== prevProps.evaluations) {
      this.updateTemplatesArchivedEvaluations();

      if (this.state.selection && !this.state.inEditMode) {
        this.handleTemplateChange(
          this.state.selection.template.id,
          this.state.selection.isFromArchive,
        );
      }
    }
  }

  setDefaultTemplate = () => {
    let selectedTemplate = this.props.templates.find(template => {
      return (
        template.status === 'ACTIVE' &&
        moment(template.deadline)
          .endOf('day')
          .isAfter(new Date())
      );
    });

    // If all templates have passed deadlines set the first active template a locked one
    if (!selectedTemplate) {
      selectedTemplate = this.props.templates.find(
        template => template.status === 'ACTIVE',
      );
    }

    if (selectedTemplate) {
      this.handleTemplateChange(selectedTemplate.id, false);
    }
  };

  handleTemplateChange = (
    selectedTemplateId: string,
    isFromArchive: boolean,
  ) => {
    const selectedTemplate = this.props.templates.find(template => {
      return template.id === selectedTemplateId;
    });

    if (!selectedTemplate) {
      return;
    }

    const actualEvaluation = this.props.evaluations.find(
      evaluation =>
        evaluation.templateId === selectedTemplateId &&
        (!isFromArchive || evaluation.isArchived),
    );

    this.setState({
      selection: {
        template: selectedTemplate,
        evaluation: actualEvaluation,
        formData: this.getFreshFormData(selectedTemplate, actualEvaluation),
        submitErrors: undefined,
        isFromArchive,
        isEditable:
          !isFromArchive &&
          selectedTemplate.sections.length > 0 &&
          moment(selectedTemplate.deadline)
            .endOf('day')
            .isAfter(new Date()),
      },
    });
  };

  getFreshFormData = (template: any, evaluation?: any) => {
    return template.sections.reduce((formData: any, section: any) => {
      return {
        ...formData,
        [section.id]: {
          commonGoals: [
            ...section.components.map((component: any) => {
              const commonGoal =
                evaluation &&
                evaluation.commonGoals.find(
                  (cg: any) =>
                    cg.sectionId === section.id &&
                    cg.componentId === component.id,
                );

              if (commonGoal) {
                const { id, evaluationId, ...commonGoalFD } = commonGoal;

                return {
                  goalId: uuid4(),
                  ...commonGoalFD,
                  goal: component.enum.groupFK,
                  successMeasure: component.enum.code,
                  result:
                    typeof commonGoalFD.result === 'number'
                      ? commonGoalFD.result.toFixed(2)
                      : commonGoalFD.result,
                  weight:
                    typeof commonGoalFD.weight === 'number'
                      ? commonGoalFD.weight.toFixed(2)
                      : commonGoalFD.weight,
                  index: component.index,
                  type: 'GOAL',
                };
              }

              return {
                template: true,
                goalId: uuid4(),
                sectionId: section.id,
                componentId: component.id,
                status: '',
                goal: component.enum.groupFK,
                successMeasure: component.enum.code,
                startDate: '',
                endDate: '',
                weight: '',
                rangeMin: '',
                rangeMax: '',
                target: '',
                actual: '',
                result: '',
                info: '',
                isDate: false,
                index: component.index,
                type: 'GOAL',
              };
            }),
            ...(evaluation
              ? evaluation.commonGoals
                  .filter(
                    (commonGoal: any) =>
                      commonGoal.sectionId === section.id &&
                      !commonGoal.componentId,
                  )
                  .map(({ id, evaluationId, ...commonGoal }: any) => ({
                    goalId: uuid4(),
                    ...commonGoal,
                    type: 'GOAL',
                  }))
              : []),
          ],
          personalGoals: evaluation
            ? evaluation.personalGoals
                .filter(
                  (personalGoal: any) => personalGoal.sectionId === section.id,
                )
                .map(({ id, evaluationId, ...personalGoal }: any) => ({
                  goalId: uuid4(),
                  ...personalGoal,
                }))
            : [],
          otherComponents: section.appraisalComponents,
        },
      };
    }, {});
  };

  updateTemplatesArchivedEvaluations = () => {
    this.setState({
      templatesWithArchivedEvaluations: this.props.evaluations
        .filter(evaluation => evaluation.isArchived)
        .reduce((templatesWithArchivedEvaluations, evaluation) => {
          if (
            !templatesWithArchivedEvaluations.some(
              ({ id }: any) => evaluation.templateId === id,
            ) &&
            this.props.templates.find(({ id }) => {
              return evaluation.templateId === id;
            })
          ) {
            return [
              ...templatesWithArchivedEvaluations,
              this.props.templates.find(({ id }) => {
                return evaluation.templateId === id;
              }),
            ];
          }

          return templatesWithArchivedEvaluations;
        }, []),
    });
  };

  enterInEditMode = () => {
    if (this.state.selection) {
      this.setState({ inEditMode: true });
    }
  };

  leaveEditMode = () => {
    this.props.openConfirmDialog({
      text: translate.t('text_unsaved_changes'),
      onOk: () => {
        this.setState({
          inEditMode: false,
          selection: this.state.selection
            ? {
                ...this.state.selection,
                formData: this.getFreshFormData(
                  this.state.selection.template,
                  this.state.selection.evaluation,
                ),
                submitErrors: undefined,
              }
            : undefined,
        });
      },
    });
  };

  printEvaluation = () => {
    let node = document.getElementById('evaluationToPrint');
    printElements.print([node]);
  };

  getActiveEvaluationUpdatedDetails = () => {
    const { selection } = this.state;

    if (!selection || !selection.evaluation) {
      return ' ';
    }

    const updatedAt = moment(selection.evaluation.updatedTime).format(
      'YYYY-MM-DD',
    );
    const updatedBy = getEmployeeName(selection.evaluation.updatedBy, true);
    return `${updatedAt}, ${updatedBy}`;
  };

  getActiveEvaluationApprovedDetails = () => {
    const { selection } = this.state;

    if (!selection || !selection.evaluation) {
      return ' ';
    }

    return selection.evaluation
      ? selection.evaluation.approvedBy
          .map((approvedBy: number) => {
            return getEmployeeName(approvedBy, true);
          })
          .join(', ')
      : '';
  };

  handleApproveEvaluation = (currentUserId: any) => {
    const { selection } = this.state;

    if (
      selection &&
      selection.evaluation &&
      selection.evaluation.approvedBy.includes(getLoggedUserId())
    ) {
      this.props.removeEvaluationApproval(
        selection.evaluation.id,
        currentUserId,
      );
    } else {
      this.props.changeEvaluationApproval(
        selection.evaluation.id,
        currentUserId,
      );
    }
  };

  handleArchiveEvaluation = () => {
    const { selection } = this.state;

    if (selection && selection.evaluation) {
      this.props.archiveEvaluation(selection.evaluation.id);
    }
  };

  handleSaveEvaluation = async () => {
    const { selection } = this.state;
    if (!selection) {
      return;
    }

    const submitErrors = await validateEvaluation(selection.formData);

    if (submitErrors) {
      this.setState({
        selection: {
          ...this.state.selection,
          submitErrors,
        },
      });

      return;
    }

    this.props.saveEvaluation(selection.template.id, {
      evaluationId: uuid4(),
      employeeId: this.props.employeeId,
      commonGoals: Object.values(selection.formData).reduce(
        (allCommonGoals: any[], { commonGoals }: any) => {
          return [...allCommonGoals, ...commonGoals];
        },
        [],
      ),
      personalGoals: Object.values(selection.formData).reduce(
        (allPersonalGoals: any[], { personalGoals }: any) => {
          return [...allPersonalGoals, ...personalGoals];
        },
        [],
      ),
    });

    this.setState({ inEditMode: false });
  };

  updateSectionFormData = (sectionId: number, sectionFormData: any) => {
    if (!this.state.selection || !this.state.selection.formData[sectionId]) {
      return;
    }

    this.setState({
      selection: {
        ...this.state.selection,
        formData: {
          ...this.state.selection.formData,
          [sectionId]: sectionFormData,
        },
      },
    });
  };

  render() {
    const { classes, employeeId, templates } = this.props;
    const currentUserId = getLoggedUserId();
    const {
      templatesWithArchivedEvaluations,
      inEditMode,
      selection,
    } = this.state;
    const ownPage = getLoggedUserId() === employeeId;

    return (
      <Paper
        className={classNames(classes.root, {
          cssEditMode: inEditMode,
        })}
        id="evaluationToPrint"
      >
        <form>
          <div>
            <Select
              native
              disabled={inEditMode}
              onChange={(event: any) => {
                const [group, templateId] = event.target.value.split(':');
                this.handleTemplateChange(templateId, group === 'ARCHIVED');
              }}
              className={classNames(classes.select, {
                [classes.selectDisabled]: inEditMode,
              })}
              inputProps={{
                classes: {
                  icon: inEditMode
                    ? classes.selectIconDisabled
                    : classes.selectIcon,
                  root: inEditMode
                    ? classes.selectInputDisabled
                    : classes.selectInput,
                },
              }}
            >
              <optgroup label={translate.t('laActive')}>
                {templates.reduce((options, template: any) => {
                  if (
                    template.status === 'ACTIVE' &&
                    moment(template.deadline)
                      .endOf('day')
                      .isAfter(new Date())
                  ) {
                    return [
                      ...options,
                      <option
                        key={template.id}
                        value={`ACTIVE:${template.id}`}
                        className={classes.highlightedOption}
                      >
                        {template.subject}
                      </option>,
                    ];
                  }

                  return options;
                }, [])}
              </optgroup>

              <optgroup label={translate.t('laLocked')}>
                {templates.reduce((options, template: any) => {
                  if (
                    template.status === 'ACTIVE' &&
                    moment(template.deadline)
                      .endOf('day')
                      .isBefore(new Date())
                  ) {
                    return [
                      ...options,
                      <option
                        key={template.id}
                        value={`LOCKED:${template.id}`}
                        className={classes.highlightedOption}
                      >
                        {template.subject}
                      </option>,
                    ];
                  }

                  return options;
                }, [])}
              </optgroup>

              <optgroup
                label={`${translate.t('laArchived')} (${translate.t(
                  'laEvaluations',
                )})`}
              >
                {templatesWithArchivedEvaluations.map(template => {
                  return (
                    <option key={template.id} value={`ARCHIVED:${template.id}`}>
                      {template.subject}
                    </option>
                  );
                })}
              </optgroup>
            </Select>

            {selection && !inEditMode && (
              <>
                <Tooltip title={translate.t('laEdit')}>
                  <span className={classes.tooltipContainer}>
                    <IconButton
                      className={classes.iconButton}
                      onClick={this.enterInEditMode}
                      disabled={!selection.isEditable}
                    >
                      <EditIcon
                        color={selection.isEditable ? 'primary' : 'disabled'}
                      />
                    </IconButton>
                  </span>
                </Tooltip>

                <Tooltip title={translate.t('laPrint')}>
                  <IconButton
                    className={classes.iconButton}
                    onClick={this.printEvaluation}
                  >
                    <PrintIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </div>

          {selection && (
            <>
              {inEditMode && (
                <span className={classes.buttonWrapper}>
                  <Button
                    color="primary"
                    variant="text"
                    className={classes.button}
                    onClick={this.leaveEditMode}
                  >
                    {translate.t('laCancel')}
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    onClick={this.handleSaveEvaluation}
                  >
                    {translate.t('laSave')}
                  </Button>
                </span>
              )}

              <div className={classes.flexWrapper}>
                <TextField
                  value={selection.template.deadline}
                  label={translate.t('laDeadline')}
                  inputProps={{ readOnly: true, disabled: true }}
                  className={classes.textField}
                />

                <TextField
                  value={this.getActiveEvaluationUpdatedDetails()}
                  label={translate.t('laModifiedTime')}
                  inputProps={{ readOnly: true, disabled: true }}
                  className={classNames(
                    classes.textField,
                    classes.textFieldMinWidth,
                  )}
                />

                <TextField
                  value={this.getActiveEvaluationApprovedDetails()}
                  label={translate.t('laApproved')}
                  inputProps={{ readOnly: true, disabled: true }}
                  className={classNames(
                    classes.textField,
                    classes.textFieldApproved,
                  )}
                  InputLabelProps={{ shrink: true }}
                />

                <div className={classes.evaluationActionsWrapper}>
                  {selection.isFromArchive && (
                    <Typography variant="h3">
                      {translate.t('laArchived')}
                    </Typography>
                  )}
                  {!selection.isFromArchive && !inEditMode && (
                    <>
                      <Button
                        color="primary"
                        variant="text"
                        className={classes.button}
                        disabled={
                          !selection.evaluation || !selection.isEditable
                        }
                        onClick={() =>
                          this.handleApproveEvaluation(currentUserId)
                        }
                      >
                        {selection.evaluation &&
                        selection.evaluation.approvedBy.includes(currentUserId)
                          ? translate.t('laRemoveApproval')
                          : translate.t('laApprove')}
                      </Button>

                      <Button
                        color="primary"
                        variant="text"
                        className={classes.button}
                        disabled={
                          !selection.evaluation || !selection.isEditable
                        }
                        onClick={this.handleArchiveEvaluation}
                      >
                        {translate.t('laArchive')}
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </form>

        {selection && selection.template.sections.length > 0 ? (
          <>
            {selection.template.sections.map((section: any) => {
              return (
                <SectionListing
                  key={section.id}
                  section={section}
                  data={selection.formData[section.id]}
                  submitErrors={
                    selection.submitErrors && selection.submitErrors[section.id]
                  }
                  updateData={this.updateSectionFormData}
                  /* Section is editable by
                      case 1: everyone
                      case 2: everyone but employees(unless HR/Manager are looking at their own profile)
                      case 3: only Employees and HR
                    */
                  inEditMode={
                    inEditMode &&
                    ((!section.onlyEmpAccess && section.empAccess) ||
                      (!section.onlyEmpAccess &&
                        !isEmployee() &&
                        getLoggedUserId() !== employeeId) ||
                      (section.onlyEmpAccess &&
                        (isEmployee() || isHR() || ownPage)))
                  }
                  openConfirmDialog={this.props.openConfirmDialog}
                />
              );
            })}

            <EvaluationTotals formData={selection.formData} />
          </>
        ) : (
          <Typography className={classes.noSections}>
            {translate.t('no_sections')}
          </Typography>
        )}
      </Paper>
    );
  }
}

const mapStateToProps = (state: ReducerState) => {
  return {
    languages: state.languages.get('allLanguages'),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  changeEvaluationApproval: (evaluationId: string, newApprovedBy: string) =>
    dispatch<any>(changeEvaluationApproval(evaluationId, newApprovedBy)),
  removeEvaluationApproval: (evaluationId: string, newApprovedBy: string) =>
    dispatch<any>(removeEvaluationApproval(evaluationId, newApprovedBy)),
  archiveEvaluation: (evaluationId: string) =>
    dispatch<any>(archiveEvaluation(evaluationId)),
  saveEvaluation: (templateId: string, evaluation: any) =>
    dispatch<any>(saveEvaluation(templateId, evaluation)),
  openConfirmDialog: (payload: ConfirmDialogType) =>
    dispatch(openConfirmDialog(payload)),
});

const enhance = compose<any, any>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(templateSelectionStyles),
);

export default enhance(TemplateSelection);

import * as React from 'react';
import {
  Divider,
  Icon,
  ListItemIcon,
  Menu,
  MenuItem,
} from '@material-ui/core';
import translate from '@/app/utils/translate';

type OwnProps = {
  anchorEl: HTMLElement,
  showActivate: boolean,
  showDeactivate: boolean,

  onActivate: (e: React.SyntheticEvent) => void;
  onDeactivate: (e: React.SyntheticEvent) => void;
  onRename: (e: React.SyntheticEvent) => void;
  onDelete: (e: React.SyntheticEvent) => void;
  onClose: (e: React.SyntheticEvent) => void;
};
type Props = OwnProps;

const WorklistMenu = (props: Props) => {
  const {
    anchorEl,
    showActivate,
    showDeactivate,
    onActivate,
    onDeactivate,
    onRename,
    onDelete,
    onClose
  } = props;

  const menuItemActivate = !showActivate ? undefined : (
    <MenuItem onClick={onActivate}>
      <ListItemIcon><Icon>check_circle</Icon></ListItemIcon>
      {translate.t('label_activate')}
    </MenuItem>
  );

  const menuItemDeactivate = !showDeactivate ? undefined : (
    <MenuItem onClick={onDeactivate}>
      <ListItemIcon><Icon>block</Icon></ListItemIcon>
      {translate.t('label_deactivate')}
    </MenuItem>
  );

  const menuItemDivider = !menuItemActivate && !menuItemDeactivate ? undefined : (
    <Divider />
  );

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
      <MenuItem onClick={onRename}>
        <ListItemIcon><Icon>edit</Icon></ListItemIcon>
        {translate.t('label_edit_worklist')}
      </MenuItem>
      <MenuItem onClick={onDelete}>
        <ListItemIcon><Icon>delete</Icon></ListItemIcon>
        {translate.t('label_delete_worklist')}
      </MenuItem>
      {menuItemDivider}
      {menuItemActivate}
      {menuItemDeactivate}
    </Menu>
  );
};

export default WorklistMenu;

import moment from 'moment';
import { useCallback, useMemo } from 'react';

import { WEEK_FORMAT } from '../../timeSheetConstants';

import { weekFormatter } from './weekFormatter';

export const useWeekSelector = (
  week: string,
  onWeekChange: (week: string) => void,
  setCalendarValue: React.Dispatch<React.SetStateAction<moment.Moment>>,
) => {
  const formattedWeek = useMemo(() => weekFormatter(week), [week]);

  const handleWeekChange = useCallback(
    (date: moment.Moment) => {
      onWeekChange(moment(date).format(WEEK_FORMAT));
    },
    [week, onWeekChange],
  );

  const handlePrevWeekChange = useCallback(() => {
    onWeekChange(
      moment(week, WEEK_FORMAT)
        .subtract(1, 'week')
        .format(WEEK_FORMAT),
    );
    setCalendarValue(moment(week, WEEK_FORMAT).subtract(1, 'week'));
  }, [week, onWeekChange]);

  const handleNextWeekChange = useCallback(() => {
    onWeekChange(
      moment(week, WEEK_FORMAT)
        .add(1, 'week')
        .format(WEEK_FORMAT),
    );
    setCalendarValue(moment(week, WEEK_FORMAT).add(1, 'week'));
  }, [week, onWeekChange]);

  return {
    formattedWeek,
    handlePrevWeekChange,
    handleNextWeekChange,
    handleWeekChange,
  };
};

import * as React from 'react';
import { connect } from 'react-redux';
import { v4 as uuid4 } from 'uuid';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import {
  WithStyles,
  Paper,
  Button,
  TextField,
  MenuItem,
} from '@material-ui/core';
import translate from '@/app/utils/translate';
import { ReducerState } from '@/app/redux/store';
import addComponentStyle from './addComponentStyle';

type IncomingProps = {
  template: any;
  section: any;
  componentBeingEdited: any;
  addComponent: any;
  updateComponent: any;
  handleModalClose: any;
  componentType: string;
};

type MapStateToProps = {
  allCommonGoals: any;
};

type PropsType = IncomingProps &
  MapStateToProps &
  WithStyles<typeof addComponentStyle>;

type Option = {
  value: string;
  label: string;
};

type StateType = {
  availableSuccessMeasures: Option[];
  availableCommonGoals: Option[];
  commonGoal: string;
  successMeasure: string;
  showValidationError: boolean;
  disabledSave: boolean;
};

const buildAvailableCommonGoalsAndSuccessMeasures = (
  allCommonGoals: any,
  section: any,
  componentBeingEdited?: any,
) => {
  const availableSuccessMeasures = Object.values(allCommonGoals).reduce(
    (accumulator: any, commonGoal: any) => {
      const successMeasures = Object.values(commonGoal.subgroupValues)
        .filter((successMeasure: any) => {
          if (
            componentBeingEdited &&
            componentBeingEdited.enum &&
            componentBeingEdited.enum.groupFK === commonGoal.subgroupName &&
            componentBeingEdited.enum.code === successMeasure.code
          ) {
            return true;
          }

          return !section.components.some((component: any) => {
            // handle component not of type GOAL
            if (!component.type || component.type !== 'GOAL') {
              return false;
            }
            return (
              component.enum.groupFK === commonGoal.subgroupName &&
              component.enum.code === successMeasure.code
            );
          });
        })
        .map((successMeasure: any) => {
          return { value: successMeasure.code, label: successMeasure.name };
        });

      if (successMeasures.length) {
        return { ...accumulator, [commonGoal.subgroupName]: successMeasures };
      }

      return accumulator;
    },
    {},
  );

  const availableCommonGoals = Object.values(allCommonGoals)
    .filter((commonGoal: any) => {
      return !!availableSuccessMeasures[commonGoal.subgroupName];
    })
    .map((commonGoal: any) => {
      return { value: commonGoal.subgroupName, label: commonGoal.name };
    });

  return { availableSuccessMeasures, availableCommonGoals };
};

class AddComponent extends React.PureComponent<PropsType> {
  state: StateType = {
    availableSuccessMeasures: [],
    availableCommonGoals: [],
    commonGoal: '',
    successMeasure: '',
    showValidationError: false,
    disabledSave: true,
  };

  constructor(props: any) {
    super(props);

    if (props.componentBeingEdited) {
      this.state = {
        availableCommonGoals: [],
        availableSuccessMeasures: [],
        commonGoal: this.props.componentBeingEdited.enum.groupFK,
        successMeasure: this.props.componentBeingEdited.enum.code,
        showValidationError: false,
        disabledSave: true,
      };
    }
  }

  componentDidMount() {
    this.setState({
      ...buildAvailableCommonGoalsAndSuccessMeasures(
        this.props.allCommonGoals,
        this.props.section,
        this.props.componentBeingEdited,
      ),
    });
  }

  changeCommonGoal = (event: any) => {
    const target = event.target as HTMLSelectElement;
    this.setState({
      commonGoal: target.value,
      successMeasure: '',
      disabledSave: false,
    });
  };

  changeSuccessMeasure = (event: any) => {
    const target = event.target as HTMLSelectElement;
    this.setState({ successMeasure: target.value, disabledSave: false });
  };

  handleSaveComponent = (e: any) => {
    e.preventDefault();

    const {
      template,
      section,
      componentBeingEdited,
      addComponent,
      updateComponent,
      handleModalClose,
      componentType,
    } = this.props;
    const { commonGoal, successMeasure } = this.state;

    if (commonGoal === '' || successMeasure === '') {
      this.setState({ showValidationError: true });
      return;
    }

    if (componentBeingEdited) {
      updateComponent(template.id, section.id, {
        componentId: componentBeingEdited.id,
        commonGoal,
        type: componentType,
        successMeasure,
        defaultValue: 'undefined',
        name: 'undefined',
        index: componentBeingEdited.index,
      });
    } else {
      addComponent(template.id, section.id, {
        componentId: uuid4(),
        commonGoal,
        type: componentType,
        successMeasure,
        defaultValue: 'undefined',
        name: 'undefined',
        index: section.components ? section.components.length + 1 : 1,
      });
    }

    handleModalClose();
  };

  render() {
    const { classes, componentBeingEdited, handleModalClose } = this.props;

    const {
      availableCommonGoals,
      availableSuccessMeasures,
      commonGoal,
      successMeasure,
      showValidationError,
      disabledSave,
    } = this.state;

    return (
      <Paper className={classes.root}>
        <form onSubmit={this.handleSaveComponent} className={classes.form}>
          <div>
            <h3 className={classes.topTitle}>
              {componentBeingEdited
                ? translate.t('edit_component')
                : translate.t('add_component')}
            </h3>

            <TextField
              select
              name="commonGoal"
              value={commonGoal}
              onChange={this.changeCommonGoal}
              label={translate.t('laGoal')}
              error={showValidationError && !commonGoal}
              helperText={
                showValidationError && !commonGoal
                  ? translate.t('laThisRequired')
                  : ''
              }
              disabled={!availableCommonGoals.length}
              fullWidth
            >
              {availableCommonGoals.map(option => {
                return (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                );
              })}
            </TextField>

            <TextField
              select
              name="successMeasure"
              value={successMeasure}
              onChange={this.changeSuccessMeasure}
              label={translate.t('laSuccessMeasure')}
              error={showValidationError && !successMeasure}
              helperText={
                showValidationError && !successMeasure
                  ? translate.t('laThisRequired')
                  : ''
              }
              disabled={
                !commonGoal ||
                !(availableSuccessMeasures[commonGoal] || []).length
              }
              fullWidth
            >
              {(availableSuccessMeasures[commonGoal] || []).map(
                (option: Option) => {
                  return (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  );
                },
              )}
            </TextField>
          </div>

          <div className={classes.buttonsWrapper}>
            <Button color="primary" variant="text" onClick={handleModalClose}>
              {translate.t('laCancel')}
            </Button>

            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={disabledSave}
            >
              {translate.t('laSave')}
            </Button>
          </div>
        </form>
      </Paper>
    );
  }
}

const mapStateToProps = (state: ReducerState) => ({
  allCommonGoals: state.goals.allCommonGoals,
});

const enhance = compose<any, any>(
  connect(mapStateToProps),
  withStyles(addComponentStyle),
);

export default enhance(AddComponent);

import { Record } from 'immutable';
import { Dispatch } from 'redux';
import { v4 as uuid4 } from 'uuid';

import API from '@/app/api/internalAPIs';
import { getCurrentLanguage } from '@/app/utils/helper';
import Service from '@/app/utils/service';
import translate from '@/app/utils/translate';

const FETCH_QUICK_LINKS = '@@solaforce/quickLinks/FETCH_QUICK_LINKS';
const CREATE_QUICK_LINK = '@@solaforce/quickLinks/CREATE_QUICK_LINK';
const EDIT_QUICK_LINK = '@@solaforce/quickLinks/EDIT_QUICK_LINK';
const REMOVE_MULTIPLE_QUICK_LINKS =
  '@@solaforce/quickLinks/REMOVE_MULTIPLE_QUICK_LINKS';
const UPDATE_TRANSLATIONS_QUICK_LINKS =
  '@@solaforce/quickLinks/UPDATE_TRANSLATIONS_QUICK_LINKS';
const UPDATE_QUICK_LINKS_ORDER = '@@solaforce/quickLinks/UPDATE_QUICK_LINKS_ORDER';

export type QuickLink = {
  id: string;
  url?: string;
  location: 'menu_links' | 'quick_links_widget';
  navigation?: {
    module: string;
    tab: string;
  },
  roles: string[];
  seq?: number,
  translations: QuickLinkTranslation[];
};

export type QuickLinkInput = Omit<QuickLink, 'translations'> & {
  name: string;
  description: string;
  language: string;
};

export type QuickLinkTranslation = {
  name: string;
  description?: string;
  language: string;
};

export type QuickLinkForm = Omit<QuickLink, 'roles' | 'translations'> & {
  name: string;
  description: string;
  roles: { label: string; value: string }[];
  module?: { label: string; value: string };
  tab?: { label: string; value: string };
};

export type QuickLinkSeq = {
  id: string,
  name: string,
  seq: number,
};

const initialState = Record({
  list: [],
});

const sortSeqItems = (a: QuickLink, b: QuickLink) => {
  return a.seq - b.seq;
};

const reducer = (state = new initialState(), action: any) => {
  switch (action.type) {
    case FETCH_QUICK_LINKS:
      return state.set('list', action.payload.sort(sortSeqItems));
    case CREATE_QUICK_LINK:
      return state.set('list', [
        ...state.list,
        mapQuickLinkInputToQuickLink(action.payload),
      ].sort(sortSeqItems));
    case EDIT_QUICK_LINK:
      return state.set(
        'list',
        state.list.map(link =>
          link.id !== action.payload.id
            ? link
            : mapQuickLinkInputToQuickLink(action.payload, link.translations),
        ).sort(sortSeqItems),
      );
    case REMOVE_MULTIPLE_QUICK_LINKS:
      const updatedList = state.list.reduce(
        (acc: any[], currLink: QuickLink) =>
          !action.payload.some((id: string) => id === currLink.id)
            ? [...acc, currLink]
            : acc,
        [],
      ).sort(sortSeqItems);
      return state.set('list', updatedList);
    case UPDATE_TRANSLATIONS_QUICK_LINKS:
      return state.set(
        'list',
        state.list.map(link =>
          link.id !== action.payload.id ? link : action.payload,
        ).sort(sortSeqItems),
      );
    case UPDATE_QUICK_LINKS_ORDER:
      return state.set(
        'list',
        state.list.map(link => {
          const updatedSeq = action.payload.find((s: QuickLinkSeq) => s.id === link.id);
          if (!updatedSeq) {
            return link;
          }

          return { ...link, seq: updatedSeq.seq};
        }).sort(sortSeqItems),
      );
    default:
      return state;
  }
};

const composeTranslations = (
  existingQuickLinks: QuickLink[],
  existingTranslations: QuickLinkTranslation[],
): string => {
  const currentLanguage = getCurrentLanguage();
  const currentTranslation =
    existingTranslations.find(tr => tr.language === currentLanguage) ||
    existingTranslations[0];
  const finalIncrement = existingQuickLinks.reduce((increment: number) => {
    const potentialName = `${currentTranslation.name} (${translate.t(
      'laCopy',
    )} ${increment})`;
    const found = existingQuickLinks.some(link => {
      const linkTr =
        link.translations.find(tr => tr.language === currentLanguage) ||
        link.translations[0];

      return linkTr.name === potentialName;
    });

    if (found) {
      return increment + 1;
    }

    return increment;
  }, 1);

  return `${currentTranslation.name} (${translate.t(
    'laCopy',
  )} ${finalIncrement})`;
};

export const fetchQuickLinks = () => {
  return (dispatch: Dispatch) => {
    return Service.get(
      API.quickLinks.get(getCurrentLanguage()),
      (response: { data: QuickLink[] }) => {
        dispatch({
          type: FETCH_QUICK_LINKS,
          payload: response.data.map(r => ({ roles: [], ...r })),
        });
      },
      (err: any) => {
        throw err;
      },
    );
  };
};

export const createQuickLink = (newQuickLink: QuickLink) => (
  dispatch: Dispatch,
) => {
  return Service.post(
    API.quickLinks.add(getCurrentLanguage()),
    JSON.stringify(newQuickLink),
    (_response: { id: string }) => {
      dispatch({ type: CREATE_QUICK_LINK, payload: newQuickLink });
    },
    (err: any) => {
      throw err;
    },
  );
};

export const removeMultipleQuickLinks = (ids: string[]) => (
  dispatch: Dispatch,
) => {
  return Service.post(
    API.quickLinks.bulkDelete(),
    JSON.stringify({ ids }),
    (_response: {}) => {
      dispatch({ type: REMOVE_MULTIPLE_QUICK_LINKS, payload: ids });
    },
    (err: any) => {
      throw err;
    },
  );
};

export const duplicateQuickLink = (existingQuickLink: QuickLink) => (
  dispatch: Dispatch,
  getState: Function,
) => {
  const { list } = getState().quickLinks;
  const newQuickLink: QuickLinkInput = {
    ...mapQuickLinkToQuickLinkInput(existingQuickLink),
    name: composeTranslations(list, existingQuickLink.translations),
    id: uuid4(),
  };

  return Service.post(
    API.quickLinks.add(getCurrentLanguage()),
    JSON.stringify(newQuickLink),
    (_response: { id: string }) => {
      dispatch({ type: CREATE_QUICK_LINK, payload: newQuickLink });
    },
    (err: any) => {
      throw err;
    },
  );
};

export const editQuickLink = (editedQuickLink: QuickLinkInput) => (
  dispatch: Dispatch,
) => {
  return Service.put(
    API.quickLinks.update(editedQuickLink.id),
    JSON.stringify(editedQuickLink),
    () => {
      dispatch({ type: EDIT_QUICK_LINK, payload: editedQuickLink });
    },
    (err: any) => {
      throw err;
    },
  );
};

export const updateQuickLinkTranslations = (updatedQuickLink: QuickLink) => (
  dispatch: Dispatch,
) => {
  return Service.post(
    API.quickLinks.updateTranslations(updatedQuickLink.id),
    JSON.stringify({ translations: updatedQuickLink.translations }),
    () => {
      dispatch({
        type: UPDATE_TRANSLATIONS_QUICK_LINKS,
        payload: updatedQuickLink,
      });
    },
    (err: any) => {
      throw err;
    },
  );
};

export const updateQuickLinkOrdering = (
  updatedSequence: QuickLinkSeq[]
) => (
  dispatch: Dispatch
): Promise<QuickLinkSeq[]> => {
  return Service.post(
    API.quickLinks.updateOrder(),
    JSON.stringify({
      quickLinks: updatedSequence.map(({id, seq}) => ({id, seq}))
    }),
    () => {
      dispatch({
        type: UPDATE_QUICK_LINKS_ORDER,
        payload: updatedSequence
      });

      return updatedSequence;
    },
    (err: any) => {
      throw err;
    },
  );
};

const mapQuickLinkToQuickLinkInput = (
  quickLink: QuickLink,
): QuickLinkInput => ({
  id: quickLink.id,
  location: quickLink.location,
  ...(quickLink.location === 'menu_links' ? {
    navigation: quickLink.navigation,
  } : {
    url: quickLink.url,
  }),
  roles: quickLink.roles,
  name: quickLink.translations[0].name,
  description: quickLink.translations[0].description,
  language: quickLink.translations[0].language,
  ...(quickLink.seq || quickLink.seq === 0 ? {
    seq: quickLink.seq,
  } : {}),
});

const mapQuickLinkInputToQuickLink = (
  quickLinkInput: QuickLinkInput,
  translations: QuickLinkTranslation[] = [],
): QuickLink => {
  const index = translations
    ? translations.findIndex(tr => tr.language === quickLinkInput.language)
    : -1;
  const translationToBeMapped = {
    name: quickLinkInput.name,
    description: quickLinkInput.description,
    language: quickLinkInput.language,
  };
  if (index > -1) {
    translations = translations.map((t, i) => {
      if (i === index) {
        return translationToBeMapped;
      }

      return t;
    });
  } else {
    translations = translations.concat([translationToBeMapped]);
  }

  return {
    id: quickLinkInput.id,
    url: quickLinkInput.url,
    location: quickLinkInput.location,
    ...(quickLinkInput.navigation ? {
      navigation: quickLinkInput.navigation,
    } : {}),
    roles: quickLinkInput.roles,
    ...(quickLinkInput.seq || quickLinkInput.seq === 0 ? {
      seq: quickLinkInput.seq,
    } : {}),
    translations: [...translations],
  };
};

export default reducer;

import * as React from 'react';
import compose from 'recompose/compose';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import APIs from '@/app/api/internalAPIs';
import Service from '@/app/utils/service';
import translate from '@/app/utils/translate';
import withMobileDialog, {
  InjectedProps,
} from '@material-ui/core/withMobileDialog';
import { getCompanyLanguages } from '@/old/utils/helper';
import configureUiLabelDialogStyle from './configureUiLabelDialogStyle';

interface Props extends InjectedProps {
  classes: any;
  languages: any[];
  term: string;
  show: boolean;
  onSave: any;
  onClose: React.MouseEventHandler;
}

interface State {
  languages: any[];
  loading: boolean;
  loadingCount: number;
  saving: boolean;
  exiting: boolean;
}

class ConfigureUiLabelDialog extends React.Component<Props, State> {
  state: State = {
    languages: [],
    loading: false,
    loadingCount: 0,
    saving: false,
    exiting: false,
  };

  getData() {
    const compLangs = getCompanyLanguages();
    this.setState(
      {
        languages: [],
        loading: true,
        loadingCount: 0,
        saving: false,
        exiting: false,
      },
      () => this.fetchLanguageCustomizations(compLangs),
    );
  }

  componentWillMount() {
    const { show, term } = this.props;
    if (show && term !== '') {
      this.getData();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { show, term } = this.props;
    const { loading } = this.state;
    if (((!show && nextProps.show) || term !== nextProps.term) && !loading) {
      this.getData();
    }
  }

  fetchLanguageCustomizations = (compLangs: any[]) => {
    const { term } = this.props;
    compLangs.forEach((lc: any) => {
      this.setState(
        (prevState: Readonly<State>): State => ({
          ...prevState,
          loadingCount: prevState.loadingCount + 1,
          loading: true,
        }),
        () =>
          Service.get(
            APIs.uiLabels.list(),
            (data: any) => {
              const label = data.filter((d: any) => d.name === term);
              let langItem = {
                langCode: lc.fComLanguageCode,
                langName: lc.fComLanguageName,
                langIsDefault: lc.fComLanguageIsDefault,
                term,
                code: '_',
                value: '',
                originalValue: '',
                defaultValue: translate.getLangMessage(
                  lc.fComLanguageCode,
                  term,
                ),
              };
              if (label.length > 0) {
                Service.get(
                  APIs.uiLabels.listByLangCode(lc.fComLanguageCode),
                  (res: any) => {
                    const resItem = res.filter(
                      (r: any) => r.code === label[0].code,
                    );
                    if (resItem.length > 0) {
                      langItem = {
                        ...langItem,
                        code: label[0].code,
                        value: resItem[0].name,
                        originalValue: resItem[0].name,
                        defaultValue: translate.getLangMessage(
                          lc.fComLanguageCode,
                          term,
                        ),
                      };
                    }
                    this.setState(
                      (prevState: Readonly<State>): State => {
                        const languages: any[] = prevState.languages;
                        languages.push(langItem);
                        const newCount = prevState.loadingCount - 1;
                        return {
                          ...prevState,
                          loadingCount: newCount,
                          loading: newCount > 0 ? true : false,
                          languages,
                        };
                      },
                    );
                  },
                  (err: any) => {
                    console.error(err);
                  },
                );
              } else {
                this.setState(
                  (prevState: Readonly<State>): State => {
                    const languages: any[] = prevState.languages;
                    languages.push(langItem);
                    const newCount = prevState.loadingCount - 1;
                    return {
                      ...prevState,
                      loadingCount: newCount,
                      loading: newCount > 0 ? true : false,
                      languages,
                    };
                  },
                );
              }
            },
            (err: any) => {
              console.error(err);
            },
          ),
      );
    });
  };

  handleChange(langCode: string, value: string) {
    this.setState((prevState: State) => {
      const newLanguages = prevState.languages.map(l => {
        if (l.langCode === langCode) {
          return {
            ...l,
            value,
          };
        }
        return l;
      });
      return {
        ...prevState,
        languages: newLanguages,
      };
    });
  }

  clearCustomizations = () => {
    this.setState((prevState: State) => {
      const newLanguages = prevState.languages.map(l => ({
        ...l,
        value: '',
      }));
      return {
        ...prevState,
        languages: newLanguages,
      };
    });
  };

  formHasChanges = () => {
    const { languages } = this.state;
    const langsToInsert = languages.filter(
      l => l.value !== '' && l.originalValue === '',
    );
    const langsToUpdate = languages.filter(
      l =>
        l.value !== '' && l.originalValue !== '' && l.value !== l.originalValue,
    );
    const langsToDelete = languages.filter(
      l => l.value === '' && l.originalValue !== '',
    );
    return (
      langsToInsert.length !== 0 ||
      langsToUpdate.length !== 0 ||
      langsToDelete.length !== 0
    );
  };

  formHasCustomizations = (): boolean => {
    const { languages } = this.state;
    return languages.filter(l => l.value !== '').length > 0;
  };

  handleSave = (evt: any) => {
    const { term } = this.props;

    // Do nothing if form has not changed
    if (!this.formHasChanges()) {
      this.props.onClose(evt);
      return;
    }
    this.setState({ saving: true });

    // Get all current entries in UI_LABEL
    Service.get(
      APIs.uiLabels.list(),
      (data: any) => {
        const labels = data;

        // Build set of labels to update (insert new if this label was not customized before)
        if (
          labels.filter((l: any) => l.code.toUpperCase() === term.toUpperCase())
            .length === 0
        ) {
          labels.push({
            code: '_',
            name: term,
          });
        }

        // Handle saving
        this.handleGroupSave(labels)
          .then(() => {
            this.handleLanguagesSave(this.state.languages)
              .then(() => {
                this.props.onSave(
                  this.state.languages.map(l => ({
                    langCode: l.langCode,
                    value: l.value || l.defaultValue,
                  })),
                );
                this.setState({ saving: false, exiting: true });
              })
              .catch((err: any) => {
                console.error('handleLangSave() failed!', err);
                this.setState({ saving: false });
              });
          })
          .catch((err: any) => {
            console.error('handleGroupSave() failed!', err);
            this.setState({ saving: false });
          });
      },
      (err: any) => {
        console.error('Error getting existing label info', err);
        this.setState({ saving: false });
      },
    );
  };

  handleGroupSave = (labels: any[]): Promise<any> => {
    return new Promise((resolve, reject) => {
      // Check if we have new entry and only then do the update
      const newEntry = labels.filter(lbl => lbl.code === '_');
      if (newEntry.length === 0) {
        resolve();
        return;
      }

      Service.put(
        APIs.uiLabels.putLabels(),
        labels,
        (data1: any) => {
          const thisLabel = data1.filter(
            (d: any) => d.name === this.props.term,
          );
          if (thisLabel.length > 0) {
            this.setState(
              (prevState: State) => {
                return {
                  ...prevState,
                  languages: prevState.languages.map(l => {
                    if (l.code === '_') {
                      return {
                        ...l,
                        code: thisLabel[0].code,
                      };
                    }
                    return { ...l };
                  }),
                };
              },
              () => resolve(),
            );
          } else {
            console.error(
              'Error saving group: Saved group was not found in results',
            );
          }
        },
        (err: any) => {
          console.error('handleGroupSave() failed (1. putLabel)', err);
          reject(err);
        },
      );
    });
  };

  handleLanguagesSave = (languages: any[]): Promise<any> => {
    return new Promise((resolve, reject) => {
      const promises: Promise<any>[] = [];
      languages
        .filter(l => l.term === this.props.term)
        .map(l => {
          promises.push(this.handleLanguageSave(l));
        });
      Promise.all(promises)
        .then((_results: any[]) => {
          resolve();
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  };

  handleLanguageSave = (lang: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      Service.put(
        APIs.uiLabels.updateLabel(lang.langCode, lang.code),
        JSON.stringify(lang.value),
        (_data: any) => {
          resolve();
        },
        (err: any) => {
          reject(err);
        },
      );
    });
  };

  render() {
    const { classes, show, onClose } = this.props;
    const { languages, loading, saving, exiting } = this.state;

    const hasChanges = this.formHasChanges();
    let saveButton: React.ReactElement<any> = null;
    if (hasChanges) {
      saveButton = (
        <Button
          variant="contained"
          component="a"
          className={classes.actionButtonRight}
          onClick={this.handleSave}
          color="primary"
        >
          {translate.t('laSave')}
        </Button>
      );
    }

    const dialogContent =
      loading || saving || exiting ? (
        <DialogContent>
          <CircularProgress className={classes.progress} />
        </DialogContent>
      ) : (
        <>
          <DialogTitle>{translate.t('titleCustomizeUiLabel')}</DialogTitle>
          <DialogContent>
            <form className={classes.container} noValidate>
              {languages
                .sort((a: any, b: any) => {
                  if (b.langIsDefault || a.langName > b.langName) {
                    return 1;
                  }
                  if (a.langIsDefault || a.langName < b.langName) {
                    return -1;
                  }
                  return 0;
                })
                .map((lang: any) => (
                  <FormControl
                    key={lang.langCode}
                    fullWidth
                    className={classes.formControl}
                  >
                    <TextField
                      label={lang.langName}
                      className={classes.textField}
                      InputLabelProps={{ shrink: true }}
                      placeholder={lang.defaultValue}
                      onChange={evt =>
                        this.handleChange(lang.langCode, evt.target.value)
                      }
                      value={lang.value || ''}
                    />
                  </FormControl>
                ))}
            </form>
          </DialogContent>
          <DialogActions>
            <Grid container justify="space-between">
              <Grid item>
                <Button
                  variant="text"
                  component="a"
                  className={classes.actionButtonLeft}
                  color="secondary"
                  disabled={!this.formHasCustomizations()}
                  onClick={this.clearCustomizations}
                >
                  {translate.t('laRestoreDefaults')}
                </Button>
              </Grid>
              <Grid>
                <Button
                  variant="text"
                  component="a"
                  className={classes.actionButtonRight}
                  onClick={onClose}
                  color="primary"
                >
                  {hasChanges
                    ? translate.t('laCancel')
                    : translate.t('laClose')}
                </Button>
                {saveButton}
              </Grid>
            </Grid>
          </DialogActions>
        </>
      );
    return (
      <Dialog open={show} onClick={evt => evt.stopPropagation()}>
        {dialogContent}
      </Dialog>
    );
  }
}

const enhance = compose<Props, any>(
  withStyles(configureUiLabelDialogStyle),
  withMobileDialog(),
);

export default enhance(ConfigureUiLabelDialog);

import * as React from 'react';
import { createStyles, withStyles, WithStyles, } from '@material-ui/core';
import { GridCellProps } from '@progress/kendo-react-grid';

const styles = () => createStyles({});

type Props = WithStyles<typeof styles> & GridCellProps;

const ScheduledDateCell = ({
  dataItem,
  rowType,
}: Props) => {

  if (rowType === 'groupHeader' || rowType === 'groupFooter' || rowType !== 'data') {
    return null;
  }

  return (
    <td style={{ verticalAlign: 'top' }}>
      {
        dataItem.scheduledDate
      }
    </td>
  );
};

export default withStyles(styles)(ScheduledDateCell);
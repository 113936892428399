import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const genericDialogStyle: StyleRulesCallback = () => {
  return {
    content: {
      minWidth: '500px',
    },
    dialogActions: {
      margin: '8px 20px',
      padding: '8px',
    },
  };
};

export default genericDialogStyle;

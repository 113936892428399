import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { WithStyles, CircularProgress } from '@material-ui/core';
import loadingStyle from './loadingStyle';

type OwnProps = {
  size?: 'small' | 'normal'
};

type Props = OwnProps & WithStyles<typeof loadingStyle>;

const Loading = (props: Props)  => {
  const { size = 'normal', classes } = props;
  return (
      <div className={classes.root}>
        <CircularProgress className={classes.progress} size={size === 'normal' ? 50 : 40} />
      </div>

  );
};

export default withStyles(loadingStyle, {withTheme: true})(Loading);

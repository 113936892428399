import { DatePicker } from 'material-ui-pickers';
import { DatePickerProps } from 'material-ui-pickers/DatePicker';
import React from 'react';
import { DATE_FORMAT } from '@/app/utils/helper';
import LocalizationProvider from './LocalizationProvider/LocalizationProvider';

const LocalizedDatePicker = (props: DatePickerProps) => {
  return (
    <LocalizationProvider>
      <DatePicker
        format={DATE_FORMAT}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default LocalizedDatePicker;

import * as React from 'react';
import { connect } from 'react-redux';
import { fetchModules } from '@/app/redux/modules';
import { ReducerState } from '@/app/redux/store';

const FetchModulesHOC = (PassedComponent: React.ComponentType) => {
  class FetchModules extends React.Component<any> {
    componentDidMount() {
      this.props.fetchModules();
    }

    render () {
      const { modules } = this.props;
      // no render if modules are not loaded
      if (modules.size === 0) {
        return null;
      }
      return <PassedComponent {...this.props} />;
    }
  }

  const mapStateToProps = (state: ReducerState) => ({
    modules: state.modules.get('allModules'),
  });

  return connect(
    mapStateToProps,
    {
      fetchModules,
    }
  )(FetchModules);
};

export default FetchModulesHOC;

import * as React from 'react';
import WizardDialogActions from '../../Wizard/WizardDialogActions';
import EnumGroupForm from '../EnumGroupForm/EnumGroupForm';
import translate from '@/app/utils/translate';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

const FORM_NAME = 'newGroup';

type OwnProps = {
  open: boolean;
  onClose: Function;
  onSave: Function;
  remoteFormSubmit?: (formName: string) => void;
  resetForm?: (formName: string) => void;
};

type PropsType = OwnProps;

type IState = {
  close: boolean;
};

class EnumAddGroupDialog extends React.Component<PropsType, IState> {
  state: IState = {
    close: false,
  };

  componentDidMount() {
    this.props.resetForm(FORM_NAME);
  }

  componentDidUpdate(prevProps: OwnProps) {
    const { open } = this.props;
    if (!prevProps.open && open) {
      this.setState({ close: false });
    }
  }

  componentWillUnmount() {
    this.props.resetForm(FORM_NAME);
  }

  handleSave = (values: any) => {
    const { onSave } = this.props;

    onSave(values, true);
    this.props.resetForm(FORM_NAME);
  };

  getPageContent = (): React.ReactNode => {
    if (!this.props.open) {
      return <></>;
    }

    return (
      <EnumGroupForm onSubmit={(values: any) => this.handleSave(values)} />
    );
  };

  handleSaveAndClose(formName: string) {
    this.props.remoteFormSubmit(formName);
    this.setState({
      close: true,
    });
  }

  getPageActions = (): React.ReactNode => {
    const { onClose } = this.props;

    return (
      <WizardDialogActions
        onCancel={() => onClose(FORM_NAME)}
        onSaveAndClose={() => this.handleSaveAndClose(FORM_NAME)}
      />
    );
  };

  render() {
    const { open, onClose } = this.props;

    return (
      <Dialog
        disableBackdropClick
        onEscapeKeyDown={() => onClose(FORM_NAME)}
        open={open}
        maxWidth="md"
      >
        <DialogTitle>{`${translate.t('title_add_group')}`}</DialogTitle>
        <DialogContent>{this.getPageContent()}</DialogContent>
        <DialogActions>{this.getPageActions()}</DialogActions>
      </Dialog>
    );
  }
}

export default EnumAddGroupDialog;

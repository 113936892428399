import * as React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  WithStyles,
} from '@material-ui/core';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { LanguagesType } from '@/app/redux/languages';
import { ReducerState } from '@/app/redux/store';
import {
  fetchAllJobEvaluationTemplates,
  addJobEvaluationTemplate,
  updateJobEvaluationTemplate,
  changeTemplateStatus,
  deleteTemplate,
  duplicateTemplate,
  addTemplateSection,
  deleteSection,
  addComponentToSection,
  updateSectionComponent,
  orderComponents,
  deleteComponent,
  updateSection,
  getEnumScoreGroups,
} from '@/app/redux/jobEvaluation';
import {
  openConfirmDialog,
  ConfirmDialogType,
} from '@/app/redux/confirmDialog';
import Loading from '@/app/components/Loading/Loading';
import translate from '@/app/utils/translate';
import ConfigureUiLabelButton from '@/app/components/ConfigurableLabels/ConfigureUiLabelButton/ConfigureUiLabelButton';
import TopMenuToolBar from './TopMenuToolBar/TopMenuToolBar';
import SideRowMenu from './SideMenuToolBar/SideRowMenu';
import { sortArr } from '../../helpers';
import jobEvaluationTemplatesStyle from './jobEvaluationTemplatesStyle';
import { Status } from '../../enums';
import { TemplatesOrder } from '../../types';
import EditTemplate from './EditTemplate/EditTemplate';
import TemplateTags from '@/app/components/TemplateComponents/admin/TemplateHeader/TemplateTags/TemplateTags';
import {
  Enum,
  selectActiveEnumsInGroup,
  fetchAllEnums,
} from '@/app/redux/enums';
import { OptionType } from '@/app/components/Trainings/types';
import { FormTemplate } from '@/app/components/TemplateComponents/types';
import { filterTemplatesByTags } from '@/app/components/TemplateComponents/helpers';

type MapStateToProps = {
  allJobEvaluationTemplates: [];
  languages: LanguagesType[];
  templateTags: Enum[];
};

type MapDispatchToProps = {
  fetchAllJobEvaluationTemplates: () => Promise<any>;
  getEnumScoreGroups: () => Promise<any>;
  addJobEvaluationTemplate: () => void;
  updateJobEvaluationTemplate: () => void;
  changeTemplateStatus: () => void;
  deleteTemplate: (templateIds: []) => void;
  openConfirmDialog: (payload: ConfirmDialogType) => void;
  duplicateTemplate: () => void;
  addTemplateSection: () => void;
  addComponentToSection: () => void;
  updateSectionComponent: () => void;
  updateSection: (section: any) => void;
  deleteSection: (templateId: string, sectionId: string) => void;
  orderComponents: (sectionId: string, components: any) => void;
  deleteComponent: (
    templateId: string,
    sectionId: string,
    componentIds: [],
  ) => any;
  fetchAllEnums: () => void;
};

type PropsType = MapStateToProps &
  MapDispatchToProps &
  WithStyles<typeof jobEvaluationTemplatesStyle>;

type StateType = {
  allCheckboxesSelected: boolean;
  selectedCheckboxes: string[];
  rowCount: number;
  openEditModal: boolean;
  readyToEditTemplate: any[];
  displayedOrder: TemplatesOrder;
  orderBy: string;
  warningMessage: boolean;
  isLoading: boolean;
  tags: OptionType[];
};

const statusTranslation = {
  ACTIVE: 'label_ACTIVE',
  INACTIVE: 'label_inactive',
};

class JobEvaluationTemplates extends React.PureComponent<PropsType> {
  state: StateType = {
    allCheckboxesSelected: false,
    selectedCheckboxes: [],
    rowCount: -1,
    openEditModal: false,
    readyToEditTemplate: [{}],
    displayedOrder: {
      subject: 'desc',
      status: 'desc',
      updatedTime: 'desc',
    },
    orderBy: 'updatedTime',
    warningMessage: false,
    isLoading: false,
    tags: [],
  };

  componentDidMount() {
    this.setState({ isLoading: true });
    Promise.all([
      this.props.fetchAllJobEvaluationTemplates(),
      this.props.getEnumScoreGroups(),
    ]).then(() => this.setState({ isLoading: false }));
  }

  componentDidUpdate(prevProps: PropsType, prevState: StateType) {
    const rowCount = this.props.allJobEvaluationTemplates.length;
    if (prevProps.allJobEvaluationTemplates.length !== rowCount) {
      this.setState({ rowCount: rowCount });
    }
    if (rowCount !== prevState.rowCount && prevState.rowCount !== -1) {
      this.setState({
        selectedCheckboxes: [],
        readyToEditTemplate: [{}],
        allCheckboxesSelected: false,
      });
    }

    const beingEditedTemplate: any = this.props.allJobEvaluationTemplates.filter(
      (item: any) => item.id === this.state.readyToEditTemplate[0].id,
    );
    if (
      beingEditedTemplate[0] !== undefined &&
      this.state.readyToEditTemplate[0] !== undefined &&
      this.state.openEditModal
    ) {
      if (
        this.state.readyToEditTemplate[0].sections !==
        beingEditedTemplate[0].sections
      ) {
        this.handleEditTemplate([this.state.readyToEditTemplate[0].id]);
      }
    }
  }

  handleChange = (id: string) => {
    let selectedRows = this.state.selectedCheckboxes;
    if (selectedRows.includes(id)) {
      selectedRows = selectedRows.filter(row => row !== id);
    } else {
      selectedRows = [...selectedRows, id];
    }

    this.setState({
      selectedCheckboxes: selectedRows,
      allCheckboxesSelected: selectedRows.length === this.state.rowCount,
    });
  };

  onSelectAllClick = (allJobEvaluationTemplates: any[]) => {
    let selectedRows = [];
    selectedRows = allJobEvaluationTemplates.map(
      (template: any) => template.id,
    );
    this.setState({
      allCheckboxesSelected: !this.state.allCheckboxesSelected,
      selectedCheckboxes: !this.state.allCheckboxesSelected ? selectedRows : [],
    });
  };

  handleEditTemplate = (selectedTemplateIds: string[]) => {
    const allJobEvaluationTemplates = this.props.allJobEvaluationTemplates;
    const [selectedTemplate] = selectedTemplateIds;
    const readyToEditTemplate: any = allJobEvaluationTemplates.filter(
      (template: any) => template.id === selectedTemplate,
    );
    const [temp] = readyToEditTemplate;

    this.setState({ warningMessage: temp.status === Status.ACTIVE });

    this.setState({
      openEditModal: true,
      readyToEditTemplate: readyToEditTemplate,
    });
  };

  handleDelete = (templateIds: []) => {
    this.props.openConfirmDialog({
      text: translate.t('template_delete_warning'),
      onOk: () => this.props.deleteTemplate(templateIds),
    });
  };

  changeTags = (e: any) => {
    this.setState({ tags: e });
  };

  handleDeleteSection = (templateId: string, sectionId: string) => {
    this.props.openConfirmDialog({
      text: translate.t('confirm_delete_item_s'),
      onOk: () => this.props.deleteSection(templateId, sectionId),
    });
  };

  handleDeleteComponent = (
    templateId: string,
    sectionId: string,
    componentIds: [],
  ) => {
    this.props.openConfirmDialog({
      text: translate.t('confirm_delete_item_s'),
      onOk: () =>
        this.props.deleteComponent(templateId, sectionId, componentIds),
    });
  };

  handleModalClose = () => {
    this.setState({ openEditModal: false });
  };

  sortHandle = (sortBy: string, order: string) => {
    // TODO - revise for sorting by date after lastEdited date format is received from real DB
    const reversedOrder = order === 'asc' ? 'desc' : 'asc';
    this.setState({
      displayedOrder: { ...this.state.displayedOrder, [sortBy]: reversedOrder },
    });
    this.setState({ orderBy: sortBy });
  };

  getMatchingTemplates = (): FormTemplate[] => {
    const { allJobEvaluationTemplates } = this.props;
    const { displayedOrder, orderBy, tags } = this.state;
    const tagCodes = tags.map(tt => `${tt.value}`);
    const filteredTemplates = filterTemplatesByTags(
      allJobEvaluationTemplates,
      tagCodes,
    );

    return sortArr(filteredTemplates, orderBy, displayedOrder[orderBy]);
  };

  render() {
    const {
      classes,
      languages,
      templateTags,
      orderComponents: handleOrderComponents,
      updateJobEvaluationTemplate: handleUpdateJobEvaluationTemplate,
      addJobEvaluationTemplate: handleAddJobEvaluationTemplate,
      changeTemplateStatus: handleChangeTemplateStatus,
      duplicateTemplate: handleDuplicateTemplate,
      addTemplateSection: handleAddTemplateSection,
      addComponentToSection: handleAddComponentToSection,
      updateSectionComponent: handleUpdateSectionComponent,
      updateSection: handleUpdateSection,
      fetchAllEnums: handleUpdateEnum,
    } = this.props;
    const {
      allCheckboxesSelected,
      selectedCheckboxes,
      openEditModal,
      readyToEditTemplate,
      displayedOrder,
      orderBy,
      warningMessage,
      isLoading,
      tags,
    } = this.state;

    const filteredJobEvalTemplates = this.getMatchingTemplates();

    const tableSortLabelComponent = (
      componentOrderBy: string,
      label: string,
      direction: 'asc' | 'desc',
    ) => (
      <TableSortLabel
        classes={{
          icon:
            orderBy === componentOrderBy
              ? classes.activeSortIcon
              : classes.inactiveSortIcon,
        }}
        active={orderBy === componentOrderBy}
        direction={direction}
        onClick={() => this.sortHandle(componentOrderBy, direction)}
      >
        {label}
      </TableSortLabel>
    );

    return (
      <>
        <Paper className={classes.root}>
          <Typography variant="h6" className={classes.sectionTitle}>
            <ConfigureUiLabelButton
              term={'job_evaluation_templates'}
              fetchAllEnums={handleUpdateEnum}
            />
            {selectedCheckboxes.length > 0 && (
              <span className={classes.subHeader}>
                {translate.t('x_selected', {
                  count: selectedCheckboxes.length,
                })}
              </span>
            )}
            <TopMenuToolBar
              languages={languages}
              fullMenu={selectedCheckboxes.length === 1}
              partialShow={selectedCheckboxes.length > 1}
              selectedTemplateIds={selectedCheckboxes}
              addJobEvaluationTemplate={handleAddJobEvaluationTemplate}
              changeTemplateStatus={handleChangeTemplateStatus}
              deleteTemplate={this.handleDelete}
              duplicateTemplate={handleDuplicateTemplate}
              handleEditTemplate={this.handleEditTemplate}
            />
          </Typography>
          <div>
            <TemplateTags
              value={tags}
              tagOptions={templateTags}
              onChange={this.changeTags}
            />
          </div>
          {isLoading && <Loading key="loading" />}
          {!isLoading && filteredJobEvalTemplates.length === 0 && (
            <Typography variant="h6">
              {translate.t('laNoTemplatesAvailable')}
            </Typography>
          )}
          {!isLoading && filteredJobEvalTemplates.length > 0 && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      indeterminate={
                        !allCheckboxesSelected && selectedCheckboxes.length > 0
                      }
                      checked={allCheckboxesSelected}
                      onChange={() =>
                        this.onSelectAllClick(filteredJobEvalTemplates)
                      }
                      className={classes.topCheckbox}
                    />
                    {tableSortLabelComponent(
                      'subject',
                      translate.t('laSubject'),
                      displayedOrder.subject,
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {tableSortLabelComponent(
                      'status',
                      translate.t('laStatus'),
                      displayedOrder.status,
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {tableSortLabelComponent(
                      'updatedTime',
                      translate.t('laLastEdited'),
                      displayedOrder.updatedTime,
                    )}
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredJobEvalTemplates.map((template: any, index) => (
                  <TableRow hover role="checkbox" key={`${index}-row`}>
                    <TableCell
                      className={classes.tablecell}
                      key={`indexTC-${template.id}`}
                      padding="checkbox"
                    >
                      <Checkbox
                        onClick={() => this.handleChange(template.id)}
                        checked={selectedCheckboxes.includes(template.id)}
                        key={`index-${template.id}`}
                      />
                      {template.subject}
                    </TableCell>
                    <TableCell>
                      {translate
                        .t(statusTranslation[template.status])
                        .toUpperCase()}
                    </TableCell>
                    <TableCell className={classes.capitalize}>
                      {template.updatedTime.substring(0, 10)},
                      {' ' + template.updatedBy.username.replace('.', ' ')}
                    </TableCell>
                    <TableCell align="right">
                      <SideRowMenu
                        active={template.status === Status.ACTIVE}
                        tools={{
                          showActive: true,
                          showTranslate: true,
                          showDuplicate: true,
                        }}
                        templateId={template.id}
                        changeTemplateStatus={handleChangeTemplateStatus}
                        deleteTemplate={this.handleDelete}
                        duplicateTemplate={handleDuplicateTemplate}
                        handleEditTemplate={this.handleEditTemplate}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Paper>
        <EditTemplate
          readyToEditTemplate={readyToEditTemplate}
          openEditModal={openEditModal}
          handleModalClose={this.handleModalClose}
          updateJobEvaluationTemplate={handleUpdateJobEvaluationTemplate}
          addTemplateSection={handleAddTemplateSection}
          deleteSection={this.handleDeleteSection}
          addComponentToSection={handleAddComponentToSection}
          updateSectionComponent={handleUpdateSectionComponent}
          updateSection={handleUpdateSection}
          deleteComponent={this.handleDeleteComponent}
          orderComponents={handleOrderComponents}
          languages={languages}
          warningMessage={warningMessage}
        />
      </>
    );
  }
}

const mapStateToProps = (state: ReducerState) => {
  const { allJobEvaluationTemplates } = state.jobEvaluation;
  const { languages } = state;

  return {
    allJobEvaluationTemplates,
    languages: languages.get('allLanguages'),
    templateTags: selectActiveEnumsInGroup(state, 'JOB_REQ_TEMPLATE_TAGS'),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAllJobEvaluationTemplates: () =>
    dispatch<any>(fetchAllJobEvaluationTemplates()),
  getEnumScoreGroups: () => dispatch<any>(getEnumScoreGroups()),
  addJobEvaluationTemplate: (template: any) =>
    dispatch<any>(addJobEvaluationTemplate(template)),
  changeTemplateStatus: (templateIds: [], newStatus: string) =>
    dispatch<any>(changeTemplateStatus(templateIds, newStatus)),
  deleteTemplate: (templateIds: []) =>
    dispatch<any>(deleteTemplate(templateIds)),
  duplicateTemplate: (templateId: string) =>
    dispatch<any>(duplicateTemplate(templateId)),
  addTemplateSection: (templateId: string, section: []) =>
    dispatch<any>(addTemplateSection(templateId, section)),
  deleteSection: (templateId: string, sectionId: string) =>
    dispatch<any>(deleteSection(templateId, sectionId)),
  updateJobEvaluationTemplate: (templateId: string, template: any) =>
    dispatch<any>(updateJobEvaluationTemplate(templateId, template)),
  addComponentToSection: (
    templateId: string,
    sectionId: string,
    component: {},
  ) => dispatch<any>(addComponentToSection(templateId, sectionId, component)),
  updateSection: (editedTmpId: string, section: any) =>
    dispatch<any>(updateSection(editedTmpId, section)),
  updateSectionComponent: (
    templateId: string,
    sectionId: string,
    component: {},
  ) => dispatch<any>(updateSectionComponent(templateId, sectionId, component)),
  orderComponents: (sectionId: string, components: any) =>
    dispatch<any>(orderComponents(sectionId, components)),
  deleteComponent: (templateId: string, sectionId: string, componentIds: []) =>
    dispatch<any>(deleteComponent(templateId, sectionId, componentIds)),
  openConfirmDialog: (payload: ConfirmDialogType) =>
    dispatch(openConfirmDialog(payload)),
  fetchAllEnums: () => dispatch<any>(fetchAllEnums()),
});

const enhance = compose<any, any>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(jobEvaluationTemplatesStyle),
);

export default enhance(JobEvaluationTemplates);

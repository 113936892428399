import React, { useState } from 'react';
import { Button, Icon, Menu, MenuItem, Typography } from '@material-ui/core';
import { APPROVAL_STATUS } from '../../../types';
import translate from '@/app/utils/translate';
import { FieldProps } from 'formik';
import { getApprovalStatusName, canAccessApprovalStatus } from '../../../helpers';
import { eventTargetAsHtmlElement } from '@/old/utils/helper';

type OwnProps = {
  employeeId?: number;
  requireManagerApproval: boolean;
  readOnly?: boolean;
  className?: string;
  onChange: () => void;
};
type InnerProps = FieldProps;
type Props = OwnProps & InnerProps;

const ApprovalStatusField = ({
  className,
  field,
  form,
  employeeId,
  requireManagerApproval,
  readOnly = false,
  onChange,
}: Props) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement|undefined>();

  const handleSelectApproval = (newApprovalStatus: APPROVAL_STATUS) => {
    setMenuAnchorEl(undefined);
    form.setFieldTouched(field.name, true);
    form.setFieldValue(field.name, {
      value: newApprovalStatus,
      label: getApprovalStatusName(newApprovalStatus),
    });
    onChange();
  };

  const approvalText = field.value.label;  // getApprovalStatusName(field.value);

  if (readOnly || !canAccessApprovalStatus(employeeId)) {
    return <Typography variant="inherit">{approvalText}</Typography>;
  }

  return (
    <>
      <Menu anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={() => setMenuAnchorEl(undefined)}>
        {!requireManagerApproval ? null :
          <MenuItem onClick={() => handleSelectApproval(APPROVAL_STATUS.WAITING)}>{translate.t('laWaitApproval')}</MenuItem>}
        <MenuItem onClick={() => handleSelectApproval(APPROVAL_STATUS.APPROVED)}>{translate.t('laApproved')}</MenuItem>
        {!requireManagerApproval ? null :
          <MenuItem onClick={() => handleSelectApproval(APPROVAL_STATUS.REJECTED)}>{translate.t('laRejected')}</MenuItem>}
        <MenuItem onClick={() => handleSelectApproval(APPROVAL_STATUS.CANCELLED)}>{translate.t('label_cancelled')}</MenuItem>
      </Menu>
      <Button
        className={className}
        size="small"
        variant="contained"
        color="default"
        onClick={(e) => setMenuAnchorEl(eventTargetAsHtmlElement(e.target))}
      >
        {approvalText}
        <Icon>arrow_drop_down</Icon>
      </Button>
    </>
  );
};

export default ApprovalStatusField;

import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  withStyles,
  CircularProgress,
  WithStyles,
} from '@material-ui/core';
import {
  createNewPlan,
  fetchActiveRules,
  fetchPrograms,
  setRuleForCurrentPlanRows,
  bulkSendToProcess,
} from '@/app/redux/bonusCalc';
import BonusCalcPlanningList from './BonusCalcPlanningList/BonusCalcPlanningList';
import BonusCalcSendToProcessDialog from './BonusCalcSendToProcessDialog/BonusCalcSendToProcessDialog';
import PromptNameDialog from '../BonusCalcPlanning/PromptNameDialog/PromptNameDialog';
import { ChildInputType } from '@/app/components/FormFields/FormFields';
import translate from '@/app/utils/translate';
import { formatNumber } from '@/app/utils/helper'; // printElement
import styles from './bonusCalcPlanningStyle';
import {
  openConfirmDialog,
  ConfirmDialogType,
} from '@/app/redux/confirmDialog';
import { BonusRule } from '../BonusCalculationRules/BonusCalculationRules';
import { fetchCompanyWorkflow } from '@/app/redux/currentUser';

interface MapStateToProps {
  currentPlan: any;
  rules: BonusRule[];
  programs: any;
  isFetching: boolean;
  shouldConfirm: boolean;
}

interface MapDispatchToProps {
  createNewPlan: (teamId: any) => void;
  fetchActiveRules: () => void;
  fetchPrograms: () => void;
  setRuleForRows: (rowIds: number[], rule: any) => void;
  openConfirmDialog: (payload: any) => void;
  bulkSendToProcess: (payload: any) => void;
  fetchCompanyWorkflow: () => void;
}

type PropsType = MapStateToProps &
  MapDispatchToProps &
  WithStyles<typeof styles>;

interface IState {
  sendToProcessData: any;
  showSendDialog: boolean;
  showHistoryDialog: boolean;
  selectedTeam: ChildInputType;
  disabled: boolean;
}

class BonusCalcPlanning extends React.Component<PropsType> {
  state: IState = {
    sendToProcessData: {
      paymentDate: null,
      paymentPeriod: '',
      info: '',
    },
    showSendDialog: false,
    showHistoryDialog: false,
    selectedTeam: null,
    disabled: true,
  };

  componentDidMount() {
    this.props.fetchActiveRules();
    this.props.fetchPrograms();
    this.props.fetchCompanyWorkflow();
  }

  getTotals = (items: any[], selectedItems: Array<any>): any[] => {
    const selectedIds = selectedItems.map((s: any) => s.id);
    const newTotals: any[] = [];
    items.forEach((item: any) => {
      if (selectedIds.indexOf(item.id) > -1) {
        if (!newTotals.hasOwnProperty(item.currency)) {
          newTotals[item.currency] = 0;
        }
        newTotals[item.currency] += item.sum;
      }
    });

    return newTotals;
  };

  handleChangeRowRule = (row: any, newRule: any) => {
    this.props.setRuleForRows([row.id], newRule);
  };

  handleChangeProgramRule = (program: any, newRule: any) => {
    const { currentPlan } = this.props;
    const rowIds: number[] = !program
      ? currentPlan.rows.map((row: any) => row.id)
      : currentPlan.rows
          .filter((row: any) => row.programId === program.id)
          .map((row: any) => row.id);
    this.props.setRuleForRows(rowIds, newRule);
  };

  handleOpenSendToProcessDialog = () => {
    this.setState({ showSendDialog: true });
  };

  handleCloseSendToProcessDialog = () => {
    this.setState({
      showSendDialog: false,
      disabled: true,
      sendToProcessData: {
        paymentDate: null,
        paymentPeriod: '',
        info: '',
      },
    });
  };

  handleSendToProcess = () => {
    this.props.openConfirmDialog({
      text: translate.t('confirm_without_print'),
      onOk: () => sendProposals(),
    });

    const sendProposals = () => {
      this.props.bulkSendToProcess({
        employees: this.props.currentPlan.rows,
        programs: this.props.programs,
        payment: this.state.sendToProcessData,
      });

      this.setState({
        showSendDialog: false,
        disabled: true,
        sendToProcessData: {
          paymentDate: null,
          paymentPeriod: '',
          info: '',
        },
        selectedTeam: null,
      });
    };
  };

  handleSendToProcessDialogFieldChange = (field: string, value: any) => {
    this.setState((prevState: IState) => {
      return {
        ...prevState,
        sendToProcessData: {
          ...prevState.sendToProcessData,
          [field]: value,
        },
        disabled:
          (prevState.disabled && !(field === 'paymentDate' && !!value)) ||
          (!prevState.disabled && field === 'paymentDate' && !value),
      };
    });
  };

  renderTotals() {
    const { classes, currentPlan } = this.props;
    if (!currentPlan || currentPlan.totals.rowCount === 0) {
      return null;
    }

    return (
      <Grid container justify="flex-end" className={classes.totalsContainer}>
        <Paper className={classes.totalsPaper}>
          <Typography variant="h6">{translate.t('laTotal')}</Typography>
          <Table>
            <TableBody>
              {Object.keys(currentPlan.totals)
                .filter((key: string) => key !== 'rowCount')
                .map((currency: string) => (
                  <TableRow key={currency}>
                    <TableCell>{currency}</TableCell>
                    <TableCell>
                      {formatNumber(currentPlan.totals[currency].total)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    );
  }

  handleTeamSelected = (d: ChildInputType) => {
    const { shouldConfirm, openConfirmDialog: showConfirmDialog } = this.props;
    if (shouldConfirm) {
      showConfirmDialog({
        text: translate.t('text_unsaved_changes'),
        onOk: () => this.changeTeam(d),
      });
    } else {
      this.changeTeam(d);
    }
  };

  changeTeam = (d: ChildInputType) => {
    this.props.createNewPlan(d.value);
    this.setState({ selectedTeam: d, disabled: true });
  };

  handlePrint = () => {
    const newWindow = window.open('', '_blank');
    // Get the content element
    const content = window.document.getElementById('sendToProcessContent');
    // Get the HTML content of the content element
    const contentHtml = content.outerHTML;
    // Create a new document inside the new window
    const document = newWindow.document;
    // Set the HTML content of the new document
    document.write(`
      <!DOCTYPE html>
      <html>
        <head>
          <title>Solaforce calculation bonus</title>
          <style>
            #sendToProcessContent{
              flex: 1 1 auto;
              padding: 0 24px 24px;
              overflow-y: visible;
            }
            table{
              width: 100%;
              display: table;
              font-family: "Roboto", "Helvetica", "Arial", sans-serif;
              border-spacing: 0;
              border-collapse: collapse;
            }
            thead {
              display: table-header-group;
            }
            .headRow {
              color: inherit;
              height: 56px;
              display: table-row;
              outline: none;
              vertical-align: middle;
            }
            .headRow >  th {
              color: rgba(0, 0, 0, 0.54);
              font-size: 0.75rem;
              font-weight: 500;
              display: table-cell;
              padding: 4px 56px 4px 24px;
              text-align: right;
              border-bottom: 1px solid rgba(224, 224, 224, 1);
              vertical-align: inherit;
            }
            .headRow > th:first-child {
              text-align:left
            }
            tbody {
              display: table-row-group;
            }
            tbody > tr {
              color: inherit;
              height: 30px;
              display: table-row;
              outline: none;
              vertical-align: middle;
            }
            tbody > tr > td {
              color: rgba(0, 0, 0, 0.75);
              font-size: 0.7rem;
              font-weight: 400;
              display: table-cell;
              padding: 4px 30px 4px 24px;
              text-align: right;
              border-bottom: 1px solid rgba(224, 224, 224, 1);
              vertical-align: inherit;
            }
            tbody > tr > td:first-child {
              text-align:left
            }
            .footerRow {
              height: 24px;
              color: inherit;
              height: 48px;
              display: table-row;
              outline: none;
              vertical-align: middle;
            }
            .footerRow > td {
              color: rgba(0, 0, 0, 0.54);
              font-size: 0.75rem;
              border-bottom: 0;
              display: table-cell;
              padding: 4px 56px 4px 24px
              vertical-align: inherit;
            }
            .footerRow > td > h6 {
              color: rgba(0, 0, 0, 0.70);
              font-size: 0.875rem;
              font-family: "Roboto", "Helvetica", "Arial", sans-serif;
              font-weight: 600;
              line-height: 1.57;
              letter-spacing: 0.00714em;
            }
            @media print {
              @page:header{
                display: none;
              }
            }
          </style>
        </head>
        <body>
          ${contentHtml}
        </body>
      </html>
    `);

    const table = document.getElementById('processTable');
    const thead = table.getElementsByTagName('thead')[0];
    const tbody = table.getElementsByTagName('tbody')[0];
    const tfooter = table.getElementsByTagName('tfoot')[0];

    const thElements = thead.getElementsByTagName('th');
    const tdFooterElements = tfooter.getElementsByTagName('td');

    const headRow = document.createElement('tr');
    headRow.classList.add('headRow');
    for (let i = 0; i < thElements.length; i++) {
      const th = thElements[i];
      const td = document.createElement('td');
      td.innerHTML = th.innerHTML;
      headRow.appendChild(td);
    }
    tbody.insertBefore(headRow, tbody.firstChild);
    table.removeChild(thead);

    const footerRow = document.createElement('tr');
    footerRow.classList.add('footerRow');
    for (let i = 0; i < tdFooterElements.length; i++) {
      const th = tdFooterElements[i];
      const td = document.createElement('td');
      td.innerHTML = th.innerHTML;
      footerRow.appendChild(td);
    }
    tbody.append(footerRow);
    table.removeChild(tfooter);

    const tfoot = document.createElement('tfoot');
    const footTr = document.createElement('tr');
    const footTd = document.createElement('td');
    footTr.append(footTd);

    tfoot.append(footTr);
    table.append(tfoot);

    newWindow.print();
    newWindow.close();
  };

  render() {
    const { classes, currentPlan, rules, programs, isFetching } = this.props;
    const {
      sendToProcessData,
      showSendDialog,
      selectedTeam,
      disabled,
    } = this.state;

    if (isFetching || !currentPlan) {
      return (
        <div className={classes.root}>
          <CircularProgress />
        </div>
      );
    }

    const sendToProcessDialog = !showSendDialog ? null : (
      <BonusCalcSendToProcessDialog
        formData={sendToProcessData}
        plan={currentPlan}
        programs={programs}
        sendTitle={translate.t('label_propose')}
        printTitle={translate.t('label_print')}
        onPrint={this.handlePrint}
        show={showSendDialog}
        onFieldChange={this.handleSendToProcessDialogFieldChange}
        onSend={this.handleSendToProcess}
        onClose={this.handleCloseSendToProcessDialog}
        disabled={disabled}
      />
    );

    const disabledSend =
      currentPlan.selectedIds.length === 0 ||
      currentPlan.rows.filter((r: any) => r.sum !== 0).length === 0;

    return (
      <div className={classes.root}>
        <PromptNameDialog />
        {sendToProcessDialog}
        <BonusCalcPlanningList
          data={currentPlan.rows}
          rules={rules}
          programs={programs}
          defaultSelected={currentPlan.selectedIds}
          onRowRuleChange={this.handleChangeRowRule}
          onProgramRuleChange={this.handleChangeProgramRule}
          onTeamSelect={this.handleTeamSelected}
          selectedTeam={selectedTeam}
          isFetching={isFetching}
        />
        {this.renderTotals()}
        <Grid container justify="flex-end" className={classes.actionButtons}>
          <Grid item>
            <Button
              className={classes.actionButton}
              variant="contained"
              color="primary"
              disabled={disabledSend}
              onClick={this.handleOpenSendToProcessDialog}
            >
              {translate.t('button_send_to_process')}
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  const {
    rules,
    currentPlan,
    programs,
    isFetching,
    shouldConfirm,
  } = state.bonusCalc;

  return {
    currentPlan,
    rules,
    programs,
    isFetching,
    shouldConfirm,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createNewPlan: (teamId: any) => dispatch<any>(createNewPlan(teamId)),
  fetchActiveRules: () => dispatch<any>(fetchActiveRules()),
  fetchPrograms: () => dispatch<any>(fetchPrograms()),
  setRuleForRows: (rowIds: number[], rule: any) =>
    dispatch<any>(setRuleForCurrentPlanRows(rowIds, rule)),
  openConfirmDialog: (payload: ConfirmDialogType) =>
    dispatch(openConfirmDialog(payload)),
  bulkSendToProcess: (payload: any) =>
    dispatch<any>(bulkSendToProcess(payload)),
  fetchCompanyWorkflow: () => dispatch<any>(fetchCompanyWorkflow()),
});

const enhance = compose<PropsType, {}>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
);

export default enhance(BonusCalcPlanning);

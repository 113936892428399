import * as React from 'react';
import { Button, withStyles, WithStyles } from '@material-ui/core';
import { compose } from 'recompose';
import wizardDialogActionsStyle from './wizardDialogActionsStyle';
import translate from '@/app/utils/translate';
import { InjectedProps } from '@material-ui/core/withMobileDialog';

interface Props extends InjectedProps {
  classes?: any;
  onCancel?: any;
  onPreviousPage?: any;
  onNextPage?: any;
  onSaveAndClose?: any;
  onSaveAndNew?: any;
  disabledNext?: any;
}

const WizardDialogActions = (props: Props & WithStyles<typeof wizardDialogActionsStyle>): any => {
  const {
    classes,
    onCancel,
    onNextPage,
    onPreviousPage,
    onSaveAndClose,
    onSaveAndNew,
    disabledNext,
  } = props;

  const cancelButton = onCancel ? (
    <Button
      className={classes.button}
      color="primary"
      variant="text"
      component="a"
      onClick={onCancel}
    >
      {translate.t('laCancel')}
    </Button>
  ) : null;

  const previousButton = onPreviousPage ? (
    <Button
      className={classes.button}
      color="primary"
      variant="text"
      component="a"
      onClick={onPreviousPage}
    >
      {translate.t('laBack')}
    </Button>
  ) : null;

  const nextButton = onNextPage ? (
    <Button
      className={classes.button}
      color="primary"
      variant="contained"
      component="a"
      onClick={onNextPage}
      disabled={disabledNext}
    >
      {translate.t('laNext')}
    </Button>
  ) : null;

  const saveAndCloseButton = onSaveAndClose ? (
    <Button
      className={classes.button}
      color="primary"
      variant="text"
      component="a"
      onClick={onSaveAndClose}
    >
      {translate.t('button_save_close')}
    </Button>
  ) : null;

  const saveAndNewButton = onSaveAndNew ? (
    <Button
      className={classes.button}
      color="primary"
      variant="contained"
      component="a"
      onClick={onSaveAndNew}
    >
      {translate.t('button_save_new')}
    </Button>
  ) : null;

  return (
    <>
      {cancelButton}
      {previousButton}
      {nextButton}
      {saveAndCloseButton}
      {saveAndNewButton}
    </>
  );
};

const enhance = compose<any, Props>(
  withStyles(wizardDialogActionsStyle, {withTheme: true})
);

export default enhance(WizardDialogActions);

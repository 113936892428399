import * as React from 'react';
import {
  withStyles,
  WithStyles,
  List,
  Grid,
  ListItem,
  Toolbar,
  Tabs,
  Tab,
  Icon,
  Button,
  Typography,
} from '@material-ui/core';
import ConfigureIcon from '@material-ui/icons/Settings';
import DropdownIcon from '@material-ui/icons/ArrowDropDown';

import styles from './solaIntelDashboardStyle';

import {
  ISolaIntelSettings,
  IModuleSettings,
  IIndicatorSettings,
} from '../SolaIntelConfig';
import SolaIntelEmployeeListWidget from './SolaIntelListWidget/SolaIntelEmployeeListWidget';
import SolaIntelNumberSummaryWidget from './SolaIntelNumberSummaryWidget/SolaIntelNumberSummaryWidget';
import SolaIntelDashboardModuleTitle from './SolaIntelDashboardModuleTitle/SolaIntelDashboardModuleTitle';
import SolaIntelConfigureModuleDialog from '../SolaIntelConfigureModuleDialog/SolaIntelConfigureModuleDialog';
import translate from '@/app/utils/translate';
import PropsMenu from '../../PropsMenu/PropsMenu';
import { getMyTeams } from '@/old/utils/helper';
import { ChildInputType } from '../../FormFields/FormFields';

interface IProps {
  indicators: ISolaIntelSettings;
  showConfig: boolean;
  selectedUnitName: string;
  onIndicatorUpdate: (indicator: any) => void;
  onIndicatorSelect: (indicator: any) => void;
  onUnitChange: (unitId: string, selectedIndicator: string) => void;
  onSetExternalToolbar: (toolbar: React.ReactElement<typeof Toolbar>) => void;
  onSetClickAwayClosing?: (allowCloseOnClickAway: boolean) => void;
}

interface IState {
  selectedModule: string;
  selectedIndicator: string;
  moduleToConfigure: IModuleSettings;
}

class SolaIntelDashboard extends React.Component<
  IProps & WithStyles<typeof styles>,
  IState
> {
  state: IState = {
    selectedModule: '',
    selectedIndicator: '',
    moduleToConfigure: null,
  };

  anyIndicatorsActive(): boolean {
    const { selectedModule } = this.state;
    const { indicators } = this.props;

    if (!indicators || !Object.keys(indicators).length) {
      return false;
    }

    if (selectedModule && indicators[selectedModule]) {
      return Object.keys(indicators[selectedModule].indicators).some((ind) => {
        return indicators[selectedModule].indicators[ind].active === true;
      });
    }

    const activeReduce = (acc: boolean[], mod: string) => {
      const anyActive = Object.keys(indicators[mod].indicators).some((ind) => {
        return indicators[mod].indicators[ind].active === true;
      });

      return acc.concat([anyActive]);
    };

    const activeArr: boolean[] = Object.keys(indicators).reduce(
      activeReduce,
      [],
    );
    return activeArr.some((active) => active);
  }

  getIndicatorSettings = (
    moduleName: string,
    indicatorId: string,
  ): IIndicatorSettings => {
    const { indicators } = this.props;
    if (
      !indicators[moduleName] ||
      !indicators[moduleName].indicators[indicatorId]
    ) {
      return null;
    }

    return indicators[moduleName].indicators[indicatorId];
  };

  getModuleSettings = (module: string): IModuleSettings => {
    const { indicators } = this.props;
    if (!indicators[module]) {
      return null;
    }
    return indicators[module];
  };

  handleModuleChange = (selectedModule: string) => {
    const { indicators } = this.props;
    this.setState(
      {
        selectedModule,
        selectedIndicator: '',
      },
      () => this.props.onSetExternalToolbar(this.renderToolbar(indicators)),
    );
  };

  handleConfigureModule = (mdle: IModuleSettings) => {
    this.setState({ moduleToConfigure: mdle }, () =>
      this.props.onSetClickAwayClosing(false),
    );
  };

  handleSaveModuleConfiguration = (newMdle: IModuleSettings) => {
    this.handleCloseModuleConfiguration();
    this.props.onIndicatorUpdate(newMdle);
  };

  handleCloseModuleConfiguration = () => {
    this.setState({ moduleToConfigure: null }, () =>
      this.props.onSetClickAwayClosing(true),
    );
  };

  handleOpenTargetGroupSelect = () => {
    this.props.onSetClickAwayClosing(false);
  };

  handleTargetGroupSelected = (d: ChildInputType) => {
    const { indicators } = this.props;
    const { selectedIndicator, selectedModule } = this.state;
    this.props.onSetClickAwayClosing(true);

    if (selectedIndicator && selectedModule && indicators[selectedModule]) {
      const selectedIndicatorData =
        indicators[selectedModule].indicators[selectedIndicator];
      this.props.onUnitChange(d.value, selectedIndicatorData.uid);
    }

    this.props.onUnitChange(d.value, null);
  };

  handleTargetGroupClosed = () => {
    this.props.onSetClickAwayClosing(true);
  };

  handleIndicatorClick = (
    selectedModule: string,
    selectedIndicator: string,
  ) => {
    const { indicators } = this.props;
    this.props.onIndicatorSelect(
      indicators[selectedModule].indicators[selectedIndicator],
    );
    this.setState({ selectedModule, selectedIndicator }, () =>
      this.props.onSetExternalToolbar(this.renderToolbar(indicators)),
    );
  };

  translateLabel = (indicator: any): string => {
    if (!indicator) {
      return '';
    }

    if (!indicator.titleTransLabel) {
      return indicator.title;
    }

    if (!indicator.config || !indicator.config.value) {
      return translate.t(indicator.titleTransLabel);
    }

    return translate.t(indicator.titleTransLabel, {
      value: indicator.config.value,
    });
  };

  renderIndicator = (): React.ReactNode => {
    const { classes, indicators, onSetClickAwayClosing } = this.props;
    const { selectedModule, selectedIndicator } = this.state;
    const indicator: IIndicatorSettings = this.getIndicatorSettings(
      selectedModule,
      selectedIndicator,
    );
    let mdle: IModuleSettings;

    if (
      !indicators ||
      !selectedModule ||
      !indicators[selectedModule] ||
      !indicator
    ) {
      return null;
    } else if (indicators[selectedModule]) {
      mdle = indicators[selectedModule];
    }

    return (
      <div className={classes.listWidgetRoot}>
        <List className={classes.listWidgetHeaderList}>
          <SolaIntelDashboardModuleTitle
            title={this.translateLabel(mdle)}
            iconName={mdle.iconName}
            onConfigure={() => this.handleConfigureModule(mdle)}
          />
        </List>
        <SolaIntelEmployeeListWidget
          title={this.translateLabel(indicator)}
          data={indicator.data}
          onClose={() => this.setState({ selectedIndicator: '' })}
          onSetClickAwayClosing={onSetClickAwayClosing}
        />
      </div>
    );
  };

  renderSummaryList = (): React.ReactNode => {
    const { classes, showConfig } = this.props;
    const { selectedModule } = this.state;
    const allModuleSettings = this.props.indicators;

    // Reduce setting list for rendering if module selected
    let settings: ISolaIntelSettings;
    if (selectedModule === '') {
      settings = allModuleSettings;
    } else {
      settings = { [selectedModule]: allModuleSettings[selectedModule] };
    }

    if (selectedModule && !allModuleSettings[selectedModule]) {
      return null;
    }

    // Collect summary nodes by module
    const moduleNodes: React.ReactNode[] = [];
    Object.keys(settings).forEach((moduleId: string) => {
      const mdle: IModuleSettings = settings[moduleId];

      const indicatorNodes: React.ReactNode[] = [];
      Object.keys(mdle.indicators)
        .sort()
        .forEach((indicatorId: string) => {
          const indicator: IIndicatorSettings = mdle.indicators[indicatorId];
          if (indicator.active) {
            indicatorNodes.push(
              <Grid item xs={6} key={indicator.id}>
                <SolaIntelNumberSummaryWidget
                  value={indicator.count}
                  title={this.translateLabel(indicator)}
                  onClick={() =>
                    this.handleIndicatorClick(moduleId, indicator.id)
                  }
                />
              </Grid>,
            );
          }
        });

      // Add module only if there are active indicators
      if (indicatorNodes.length > 0) {
        moduleNodes.push(
          <React.Fragment key={mdle.type}>
            <SolaIntelDashboardModuleTitle
              title={this.translateLabel(mdle)}
              iconName={mdle.iconName}
              onConfigure={() => this.handleConfigureModule(mdle)}
            />
            <ListItem className={classes.summaryListItem}>
              <Grid
                container
                justify="space-between"
                alignItems="stretch"
                spacing={16}
              >
                {indicatorNodes.map(
                  (indicatorNode: React.ReactNode) => indicatorNode,
                )}
              </Grid>
            </ListItem>
          </React.Fragment>,
        );
      }
    });

    let launchConfiguration: React.ReactElement<typeof ListItem> = null;
    if (selectedModule !== '' && showConfig && selectedModule !== 'CUSTOM') {
      launchConfiguration = (
        <ListItem className={classes.configureListItem}>
          <Button
            className={classes.configureButton}
            size="small"
            variant="text"
            onClick={() => this.handleConfigureModule(settings[selectedModule])}
          >
            <ConfigureIcon className={classes.configureIcon} fontSize="small" />
            {translate.t('label_configure')}
          </Button>
        </ListItem>
      );
    }

    const summaryList: React.ReactNode = (
      <List className={classes.summaryListRoot}>
        {moduleNodes.map((moduleNode: React.ReactNode) => moduleNode)}
        {launchConfiguration}
      </List>
    );
    return summaryList;
  };

  renderToolbar(
    settings: ISolaIntelSettings,
  ): React.ReactElement<typeof Toolbar> {
    const { classes } = this.props;
    const { selectedModule } = this.state;

    if (selectedModule && !settings[selectedModule]) {
      return null;
    }

    return (
      <Toolbar className={classes.actionBar}>
        <Grid container justify="space-between">
          <Grid item>
            <Tabs
              classes={{
                root: classes.actionTabs,
                flexContainer: classes.actionTabs,
              }}
              // variant="fullWidth"
              textColor="primary"
              indicatorColor="secondary"
              value={selectedModule}
              onChange={(_evt, value) => this.handleModuleChange(value)}
            >
              <Tab
                className={classes.actionTab}
                value=""
                label={translate.t('label_all')}
              />
              {Object.keys(settings).map((moduleType: string) => {
                const thisMdle: IModuleSettings = settings[moduleType];
                return (
                  <Tab
                    key={thisMdle.type}
                    className={classes.actionTab}
                    value={thisMdle.type}
                    icon={<Icon>{thisMdle.iconName}</Icon>}
                  />
                );
              })}
            </Tabs>
          </Grid>
        </Grid>
      </Toolbar>
    );
  }

  componentDidMount() {
    const { indicators } = this.props;
    this.props.onSetExternalToolbar(this.renderToolbar(indicators));
  }

  componentDidUpdate(prevProps: IProps) {
    const { indicators } = this.props;
    if (
      Object.keys(indicators).length !==
      Object.keys(prevProps.indicators).length
    ) {
      this.props.onSetExternalToolbar(
        this.renderToolbar(this.props.indicators),
      );
    }
  }

  render() {
    const { classes, indicators, selectedUnitName } = this.props;
    const { selectedIndicator, moduleToConfigure } = this.state;
    if (!indicators) {
      return null;
    }
    const confDialog = !moduleToConfigure ? null : (
      <SolaIntelConfigureModuleDialog
        moduleSettings={moduleToConfigure}
        show={true}
        onClose={this.handleCloseModuleConfiguration}
        onSave={this.handleSaveModuleConfiguration}
      />
    );
    return (
      <>
        <Typography
          className={classes.currentUnit}
          component="p"
          variant="subtitle2"
          align="center"
        >
          <PropsMenu
            onSelect={this.handleTargetGroupSelected}
            onClose={this.handleTargetGroupClosed}
            data={getMyTeams()}
          >
            <Button
              variant="text"
              size="small"
              color="primary"
              onClick={this.handleOpenTargetGroupSelect}
            >
              {selectedUnitName}
              <DropdownIcon fontSize="small" />
            </Button>
          </PropsMenu>
        </Typography>
        {confDialog}
        {!this.anyIndicatorsActive() && (
          <Grid
            container
            justify="center"
            alignItems="stretch"
            alignContent="stretch"
            spacing={0}
          >
            <p>
              <Typography component="span">
                {translate.t('laNoActiveIndicators')}
              </Typography>
            </p>
          </Grid>
        )}
        {selectedIndicator === ''
          ? this.renderSummaryList()
          : this.renderIndicator()}
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(SolaIntelDashboard);

import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      border: 'solid 1px #D6D6D6',
      padding: theme.spacing.unit * 2,
    },
    listRoot: {
      borderTop: 'solid 1px #D6D6D6',
      paddingTop: theme.spacing.unit * 2,
    },
    bulletIcon: {
      color: '#05a4d8 !important',
      marginRight: theme.spacing.unit,
    },
    header: {
      fontWeight: 'bold',
    },
    alignCenter: {
      alignItems: 'center',
    },
    dateSelect: {
      marginRight: theme.spacing.unit * 2,
      width: 110,
    },
    dataDisplay: {
      marginBottom: theme.spacing.unit * 2,
    },
    dateSelectContainer: {
      marginBottom: theme.spacing.unit * 4,
    },
    totalWE: {
      marginRight: theme.spacing.unit * 2,
    },
    defaultMargin: {
      marginBottom: theme.spacing.unit * 2,
    },
    convertMargin: {
      marginRight: theme.spacing.unit * 2,
    },
  });

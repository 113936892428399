import moment from 'moment';

export default (a: moment.Moment, b: moment.Moment) => {
  if (a.isBefore(b)) {
    return -1;
  } else if (b.isBefore(a)) {
    return 1;
  } else {
    return 0;
  }
};

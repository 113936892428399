import {
  WorkflowReportPayloadEntry,
  WorkflowReportTypes,
  WorkflowsReportDataRow,
} from '@/app/components/WorkflowsReport/ReportTypes';
import {
  mapIntPositionWorkflowsForUI,
} from '@/app/components/WorkflowsReport/ReportTypes/InternalPosition';
import {
  mapEmpDetailsWorkflowsForUI,
} from '@/app/components/WorkflowsReport/ReportTypes/EmploymentDetails';
import { mapClosedWorkflowsForUI } from '@/app/components/WorkflowsReport/ReportTypes/Rewarding/dataMapping';

export const getGridData = (
  reportType: WorkflowReportTypes,
  data: WorkflowReportPayloadEntry[],
): WorkflowsReportDataRow[] => {
  switch (reportType) {
    case WorkflowReportTypes.EmployeeDetailsClosed:
    case WorkflowReportTypes.EmployeeDetailsOpen:
      return mapEmpDetailsWorkflowsForUI(data);
    case WorkflowReportTypes.InternalPositionClosed:
    case WorkflowReportTypes.InternalPositionOpen:
      return mapIntPositionWorkflowsForUI(data);
    case WorkflowReportTypes.RewardingClosed:
      return mapClosedWorkflowsForUI(data);
    case WorkflowReportTypes.RewardingOpen:
      return [];
    default:
      return [];
  }
};

import commonAPIs from '@/app/api/commonAPIs';
import { getCurrentLanguage } from '@/app/utils/helper';

const APIs = {
  ...commonAPIs,
  companyDescription: (currentLanguage = getCurrentLanguage()) =>
    `/d/json/company/introduction/${currentLanguage}`,
  jobs: '/d/json/external/jobs',
  job: (jobId: number) => `/d/json/external/job/${jobId}`,
  jobPolicy: (jobId: number) => `/d/json/external/job/${jobId}/privacy`,
  documentLink: (documentId: number) => `/d/bin/external/file/${documentId}`,
  jobApply: (jobId: number) => `/d/form/external/job/${jobId}/app`,
  publicTrainings: '/d/json/dev/edu/company/public/trainings',
  publicTraining: (trainingId: string) => `/d/json/dev/edu/company/public/trainings/${trainingId}`,
  publicTrainingRegister: (trainingId: string) => `/d/json/dev/edu/company/public/trainings/${trainingId}/register`,
  userActivityLogging: `/api/iam/user/activity/events`,
};

export default APIs;

import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const enumScoreStyle: StyleRulesCallback = (theme: Theme) => ({
  dialogContainer: {
    minWidth: 500,
  },
  hidden: {
    visibility: 'hidden',
  },
  addButton: {
    maxHeight: 48,
    marginRight: 0,
    marginLeft: 'auto',
  },
  addMoreButton: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
  form: {
    minWidth: 300,
  },
  spacing: {
    flexGrow: 1,
    marginRight: theme.spacing.unit * 2,
  },
  title: {
    alignSelf: 'center',
  },
  titleContainer: {
    display: 'flex',
  },
  headerSelect: {
    alignItems: 'center',
    display: 'flex',
    minWidth: 181,
    width: 181,
  },
  chainSelect: {
    minWidth: 181,
    width: 181,
  },
  scoreInputContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: 120,
  },
  scoreTitleContainer: {
    minWidth: 120,
  },
});

export default enumScoreStyle;

import { FormControl, Typography, withStyles } from '@material-ui/core';
import * as React from 'react';
import { compose } from 'recompose';
import {
  TemplateBaseComponentOptionType,
  TemplateBaseComponentType,
  TemplateComponentTypeKey,
  TemplateInputComponentPropsType,
} from '@/app/components/TemplateComponents/form/InputComponents';
import { resolveComponentResponse } from '@/app/components/TemplateComponents/form/InputComponents/data';
import multiSelectDropdownComponentStyles from './multiSelectDropdownComponentStyles';
import Multiselect from 'multiselect-react-dropdown';

export type MultiSelectDropdownComponentProps = TemplateInputComponentPropsType & {
  component: TemplateBaseComponentType & {
    type: TemplateComponentTypeKey.MULTISELECT_DROPDOWN;
    options: TemplateBaseComponentOptionType[];
  };
};

const MultiSelectDropdownComponent = (
  props: MultiSelectDropdownComponentProps,
) => {
  const {
    templateId,
    component,
    responses,
    editMode,
    onUpdate,
    classes,
  } = props;

  const existingResponses = resolveComponentResponse(
    component,
    responses,
    [],
  ) as number[];

  const handleChange = (selectedList: any) => {
    if (!!onUpdate) {
      onUpdate(templateId, {
        componentId: component.id,
        response: selectedList.map((item: any) => item.value),
        type: component.type,
      });
    }
  };

  return (
    component.type === TemplateComponentTypeKey.MULTISELECT_DROPDOWN && (
      <div key={component.id + '_parent' + '_' + editMode}>
        <Typography variant="subtitle2" className={classes.typography}>
          {component.name}
        </Typography>
        <FormControl className={classes.formControl}>
          <Multiselect
            displayValue="label"
            options={component.options.map((option: any) => ({
              label: option.label,
              value: option.id,
            }))}
            selectedValues={existingResponses.map(value => ({
              label: component.options.find(
                (option: any) => option.id === value,
              )
                ? component.options.find((option: any) => option.id === value)
                    .label
                : '',
              value: value,
            }))}
            onSelect={handleChange}
            onRemove={handleChange}
            disable={editMode ? false : true}
          />
        </FormControl>
      </div>
    )
  );
};

const enhance = compose<any, any>(
  withStyles(multiSelectDropdownComponentStyles),
);

export default enhance(MultiSelectDropdownComponent);

import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const saveChangesStyles: StyleRulesCallback = () => ({
  root: {
    textAlign: 'center',
    padding: '25px 25px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) !important',
    display: 'flex',
    minWidth: '350px',
  },
  topTitle: {
    float: 'left',
  },
  select: {
    minWidth: '300px',
  },
  spacing: {
    flexGrow: 1,
    marginRight: 20,
  },
  button: {
    minHeight: '35px',
  },
  buttonsWrapper: {
    float: 'right',
  },
  form: {
    minWidth: '300px',
  },
  checkboxText: {
    textAlign: 'left',
    marginLeft: -15,
  },
  checkboxLabelActive: {
    color: 'black',
  },
  checkboxLabelInactive: {
    color: 'gray',
  },
  stageLabel: {
    textAlign: 'left',
    paddingTop: '20px',
  },
});

export default saveChangesStyles;

import '@/app/utils/polyfills';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import EnumSelect from '@/app/components/EnumSelect/EnumSelect';
import UiLabel, {
  getConfiguredText,
} from '@/app/components/ConfigurableLabels/UiLabel/UiLabel';
import ProposalWorkflow from '@/app/components/ProposalWorkflow/ProposalWorkflow';
import TrainingList, {
  TRAINING_TYPE,
} from '@/app/components/Training/TrainingList/TrainingList';
import store from '@/old/store';
import TrainingCalendar from '@/app/components/Training/TrainingCalendar/TrainingCalendar';
import Wrapper from './Wrapper';
import CustomizeUiLabelButton from '@/app/components/ConfigurableLabels/ConfigureUiLabelButton/ConfigureUiLabelButton';
import EnumGroupList from '@/app/components/Enum/EnumGroupList/EnumGroupList';
import { hasRole } from '@/app/utils/security';
import BonusCalcPlanning from '@/app/components/BonusCalc/BonusCalcPlanning/BonusCalcPlanning';
import BonusCalculationRules from '@/app/components/BonusCalc/BonusCalculationRules/BonusCalculationRules';
import SolaIntelPopoverLaunchButton from '@/app/components/SolaIntel/SolaIntelPopoverLaunchButton';
import SolaIntelGroupConfig from '@/app/components/SolaIntel/SolaIntelGroupConfig/solaIntelGroupConfig';
import SendToApprovalFormDialog from '@/app/components/DocumentApproval/SendToApprovalFormDialog/SendToApprovalFormDialog';
import SendToApprovalFormDialogList from '@/app/components/DocumentApproval/SendToApprovalFormDialog/SendToApprovalFormDialogList';
import ManageSurveyList from '@/app/components/Surveys/ManageSurveyList/ManageSurveyList';
import ManageQuickLinks from '@/app/components/QuickLinks/ManageQuickLinks/ManageQuickLinks';
import ManageUI2MenuLinks from '@/app/components/QuickLinks/ManageUI2MenuLinks/ManageUI2MenuLinks';
import AnswerSurveyBox from '@/app/components/Surveys/AnswerSurveyBox/AnswerSurveyBox';
import CompetenciesSearchReport from '@/app/components/Reporting/Development/CompetenciesSearchReport';
import GoalSettingsAndReviewsReport from '@/app/components/Reporting/Performance/GoalSettingsAndReviewsReport';
import DevelopmentPlansReport from '@/app/components/Reporting/Development/DevelopmentPlansReport';
import AllTrainingsReport from '@/app/components/Reporting/Development/AllTrainingsReport';
import HeadCountReport from '@/app/components/Reporting/People/HeadCountReport';
import {
  ReportSettingsTypes,
  ReportSettingsUtils,
} from '@/app/components/ReportSettingsUtils';
import TabsConfigurator from '@/app/components/TabsConfigurator/TabsConfigurator';
import Worklists from '@/app/components/Worklist/Worklists';
import GoalTemplates from '@/app/components/GoalTemplates/admin/GoalContainer/GoalTemplates';
import GoalEvaluationsContainer from '@/app/components/GoalTemplates/users/GoalEvaluationsContainer/GoalEvaluationsContainer';
import AppraisalTemplates from '@/app/components/Appraisal/AppraisalTemplates/admin/AppraisalContainer/AppraisalTemaplates';
import AppraisalEvaluationsContainer from '@/app/components/Appraisal/AppraisalTemplates/users/AppraisalEvaluationsContainer/AppraisalEvaluationsContainer';
import JobRequirementEvaluationCointainer from '@/app/components/JobEvaluation/users/JobRequirementEvaluationContainer';
import ProcessTemplates from '@/app/components/ProcessTemplates/admin/ProcessTempContainer/ProcessTemplates';
import ProcessEvaluationsContainer from '@/app/components/ProcessTemplates/users/ProcessEvaluationsContainer/ProcessEvaluationsContainer';
import CreateTasksFromWorklistButton from '@/app/components/Worklist/CreateTasksFromWorklistButton';
import EnumValueDetailsButton from '@/app/components/Enum/EnumValueDetailsButton/EnumValueDetailsButton';
import ClosedWorkflowsReport from '@/app/components/ClosedWorkflowsReport/ClosedWorkflowsReport';
import NotifierForOld from '@/app/components/NotifierForOld/NotifierForOld';
import {
  AdminTrainingCalendarView,
  TrainingsProvider,
  TrainingsView,
} from '@/app/components/Trainings';
import { TasksList } from '@/app/components/Collaboration';
import { HoursSettings } from '@/app/components/HoursSettings';
import { TimeSheet } from '@/app/components/TimeReporting';
import WorktimeReport from '@/app/components/WorktimeReport/WorktimeReport';
import { AbsenceCustomLabels } from '@/app/components/AbsenceCustomLabels';
import Notifier, { NotifierTYpe } from '@/app/components/Notifier/Notifier';
import * as docxtemplater from '@/app/utils/docxtemplater';
import DecisionRegistryContainer from '@/app/components/DecisionRegistry/DecisionRegistryContainer';
import GenericTranslateDialog, {
  GenericTranslateDialogOwnProps,
} from '@/app/components/GenericTranslateDialog/GenericTranslateDialog';
import DecisionRegistryReport from '@/app/components/Reporting/People/DecisionRegistryReport';
import WorkingHoursSummaryReport from '@/app/components/WorkingHoursSummaryReport/WorkingHoursSummaryReport';
import JobEvaluationTemplates from '@/app/components/JobEvaluation/admin/JobEvaluationContainer/JobEvaluationTemplates';
import AppraisalReport from '@/app/components/Reporting/Performance/AppraisalReport';
import GoalsReport from '@/app/components/Reporting/Performance/GoalsReport';
import ProcessReport from '@/app/components/Reporting/Performance/ProcessReport';
import ScoreGroups from '@/app/components/JobEvaluation/admin/JobEvaluationContainer/ScoreGroups/ScoreGroups';
import UnitPositionReport from '@/app/components/UnitPositionReport/UnitPositionReport';
import DescriptionTranslateDialog, {
  DescriptionTranslateProps,
} from '@/app/components/DescriptionTranslateDialog/DescriptionTranslateDialog';
import WorkExperience from '@/app/components/WorkExperience/WorkExperience';
import moment from 'moment';
import { getCurrentLocaleForDateUtils } from '@/app/utils/helper';
import { AutoDeductionRules } from '@/app/components/AutoDeduction/AutoDeduction';
import WorkspaceLink from '@/app/components/WorkspaceLink/WorkspaceLink';
import SubstituteManagerReport from '@/app/components/SubstituteManagerReport/SubstituteManagerReport';
import VacationBalancesReport from '@/app/components/VacationBalancesReport/VacationBalancesReport';
import DataRetention from '@/app/components/DataRetention/DataRetention';
import DataRetentionHRAdmin from '@/app/components/DataRetention/DataRetentionHRAdmin';
import {
  renderAccountManagementMenu,
  renderLeftSideMenu,
  renderScheduleChangesDialog,
  renderUserActivityLogDialogComponent,
  renderWorkflowsEmployeeProposalsDialog,
  renderPortalTopBar,
  renderOrganizationUnitPopup,
} from '@solaforce/sf-ui2';
import {
  dispatchTabChangeEvent,
  initLogger,
} from '@/app/components/UserActivityLogging/UserActivityLogging';
import JobEvaluationReport from '@/app/components/Reporting/Development/JobEvaluationReport';
import WorkflowsReport from '@/app/components/WorkflowsReport/WorkflowsReport';
import { WorkflowReportTypes } from '@/app/components/WorkflowsReport/ReportTypes';

// (window as any).$.noConflict(true);
// (window as any)._.noConflict();

// pass the render function to global
(window as any).reactRender = (component: any, container?: any) => {
  moment.locale(getCurrentLocaleForDateUtils());
  /**
   * There are situations when we render react components after
   * some data is loaded. Because until the data is loaded you
   * can already move to another page, it will happen to try
   * to render some components nowhere.
   */
  if (!container) {
    return;
  }

  ReactDOM.render(
    <Provider store={store}>
      <Wrapper>{component}</Wrapper>
    </Provider>,
    container,
  );
};
(window as any).reactUnmountComponentAtNode = ReactDOM.unmountComponentAtNode;

(window as any).reactComponents = {
  decisionRegistryContainer: (employeeId: number) => (
    <SnackbarProvider maxSnack={3}>
      <DecisionRegistryContainer employeeId={employeeId} />
    </SnackbarProvider>
  ),
  customizeUiLabelButton: (term: string, fetchAllEnums: () => void) => (
    <CustomizeUiLabelButton term={term} fetchAllEnums={fetchAllEnums} />
  ),
  enum: {
    detailsButton: (title: string, details?: string) => (
      <EnumValueDetailsButton title={title} details={details} />
    ),
  },
  enumSelect: (enumName: string, code: string, name: string, onChange: any) => (
    <EnumSelect
      enumName={enumName}
      code={code}
      name={name}
      onChange={onChange}
    />
  ),
  uiLabel: (term: string) => <UiLabel term={term} />,
  proposalWorkflow: (
    type: 'benefit' | 'allowance' | 'salary' | 'bonus',
    employeeId: number,
    employmentId: number,
    uid: string,
  ) => (
    <ProposalWorkflow
      employeeId={employeeId}
      employmentId={employmentId}
      uid={uid}
      type={type}
    />
  ),
  trainingList: () => <TrainingList />,
  trainingCalendar: (groupType: TRAINING_TYPE) => (
    <TrainingCalendar groupType={groupType} />
  ),
  enumGroupList: (
    displayArea: string,
    canAddUser: boolean,
    enumGroupNames: string[],
  ) => (
    <EnumGroupList
      displayArea={displayArea}
      canAddUser={canAddUser}
      enumGroupNames={enumGroupNames}
    />
  ),
  unitNotifier: (message: string) => <NotifierForOld message={message} />,
  adminTabsInUseConfigurator: (area: string, disabledTabs?: string[]) => (
    <TabsConfigurator area={area} disabledTabs={disabledTabs} />
  ),
  goalEvaluationsContainer: (employeeId: number) => (
    <GoalEvaluationsContainer employeeId={employeeId} />
  ),
  appraisalEvaluationsContainer: (employeeId: number) => (
    <AppraisalEvaluationsContainer employeeId={employeeId} />
  ),
  jobRequirementEvaluationContainer: (employeeId: number) => (
    <JobRequirementEvaluationCointainer employeeId={employeeId} />
  ),
  processEvaluationsContainer: (employeeId: number) => (
    <ProcessEvaluationsContainer employeeId={employeeId} />
  ),
  goalTemplates: () => <GoalTemplates />,
  appraisalTemplates: () => <AppraisalTemplates />,
  jobEvaluationTemplates: () => <JobEvaluationTemplates />,
  jobScoreGroups: () => <ScoreGroups />,
  processTemplates: () => <ProcessTemplates />,
  bonusCalcPlanning: () => <BonusCalcPlanning />,
  bonusCalculationRules: () => <BonusCalculationRules />,
  solaIntelButton: () => <SolaIntelPopoverLaunchButton />,
  solaIntelGroupConfig: () => <SolaIntelGroupConfig />,
  workspaceLink: () => <WorkspaceLink />,
  sendToApprovalFormDialog: (data: any) => (
    <SendToApprovalFormDialog data={data} />
  ),
  sendToApprovalFormDialogsList: (data: any) => (
    <SendToApprovalFormDialogList data={data} />
  ),
  manageQuickLinks: () => <ManageQuickLinks />,
  manageUI2MenuLinks: () => <ManageUI2MenuLinks />,
  manageSurveyList: () => <ManageSurveyList />,
  surveysBox: () => <AnswerSurveyBox />,
  worklists: () => <Worklists />,
  createTasksFromWorklistButton: (successCallback: () => void) => (
    <CreateTasksFromWorklistButton onSuccess={successCallback} />
  ),
  genericTranslateDialog: (props: GenericTranslateDialogOwnProps) => (
    <GenericTranslateDialog {...props} />
  ),
  descriptionTranslateDialog: (props: DescriptionTranslateProps) => (
    <SnackbarProvider maxSnack={3}>
      <DescriptionTranslateDialog {...props} />
    </SnackbarProvider>
  ),
  dummyComponent: () => <></>,
  reporting: {
    development: {
      competenciesSearchReport: () => <CompetenciesSearchReport />,
      developmentPlansReport: () => <DevelopmentPlansReport />,
      allTrainingsReport: () => (
        <TrainingsProvider>
          <AllTrainingsReport />
        </TrainingsProvider>
      ),
      JobEvaluationReport: () => <JobEvaluationReport isStatusReport={false} />,
      JobEvaluationStatus: () => <JobEvaluationReport isStatusReport={true} />,
    },
    people: {
      headCountReport: () => <HeadCountReport />,
      intPositionClosedWorkflowsReport: () => (
        <WorkflowsReport
          reportType={WorkflowReportTypes.InternalPositionClosed}
        />
      ),
      intPositionOpenWorkflowsReport: () => (
        <WorkflowsReport
          reportType={WorkflowReportTypes.InternalPositionOpen}
        />
      ),
      empDetailsClosedWorkflowsReport: () => (
        <WorkflowsReport
          reportType={WorkflowReportTypes.EmployeeDetailsClosed}
        />
      ),
      empDetailsOpenWorkflowsReport: () => (
        <WorkflowsReport reportType={WorkflowReportTypes.EmployeeDetailsOpen} />
      ),
      decisionRegistryReport: () => <DecisionRegistryReport />,
      unitPositionsReport: () => <UnitPositionReport />,
      substituteManagerReports: () => <SubstituteManagerReport />,
      VacationBalancesReport: () => <VacationBalancesReport />,
    },
    rewarding: {
      closedWorkflowsReport: () => (
        <ClosedWorkflowsReport closedWorkflowType="rewardingClosedWorkflows" />
      ),
      worktimeReport: () => <WorktimeReport worktimeReport={true} />,
      worktimeDimensions: () => <WorktimeReport worktimeReport={false} />,
      workingHoursSummaryReport: () => <WorkingHoursSummaryReport />,
    },
    performance: {
      goalSettingsAndReviewsReport: () => <GoalSettingsAndReviewsReport />,
      GoalsReport: () => <GoalsReport />,
      AppraisalReport: () => <AppraisalReport isStatusReport={false} />,
      AppraisalStatus: () => <AppraisalReport isStatusReport={true} />,
      ProcessReport: () => <ProcessReport />,
    },
  },
  trainings: {
    adminTrainingCalendar: () => (
      <SnackbarProvider maxSnack={3}>
        <TrainingsProvider>
          <AdminTrainingCalendarView />
        </TrainingsProvider>
      </SnackbarProvider>
    ),
    trainingsView: () => (
      <SnackbarProvider maxSnack={3}>
        <TrainingsProvider>
          <TrainingsView />
        </TrainingsProvider>
      </SnackbarProvider>
    ),
  },
  reportSettingsUtils: (
    reportType: ReportSettingsTypes,
    initialReportSettings: string,
    getCurrentReportSettings: () => object,
    applyReportSettings: (settings: object) => void,
  ) => (
    <SnackbarProvider maxSnack={3}>
      <ReportSettingsUtils
        reportType={reportType}
        initialReportSettings={initialReportSettings}
        getCurrentReportSettings={getCurrentReportSettings}
        applyReportSettings={applyReportSettings}
      />
    </SnackbarProvider>
  ),
  collaboration: {
    tasksList: (openTaskDialog: (task: any) => void) => (
      <TasksList openTaskDialog={openTaskDialog} />
    ),
  },
  workingTimeDefaults: () => (
    <>
      <HoursSettings />
      <AutoDeductionRules />
    </>
  ),
  timeReporting: {
    timeSheet: (
      canManageBasicData: boolean,
      canManageEverything: boolean,
      calcHoursBalance: boolean,
    ) => (
      <SnackbarProvider maxSnack={3}>
        <TimeSheet
          canManageBasicData={canManageBasicData}
          canManageEverything={canManageEverything}
          calcHoursBalance={calcHoursBalance}
        />
      </SnackbarProvider>
    ),
  },
  absenceCustomLabels: () => <AbsenceCustomLabels />,
  snackbar: (message: string, type?: NotifierTYpe, onClose?: Function) => (
    <Notifier open={true} type={type} message={message} onClose={onClose} />
  ),
  workExperience: () => (
    <SnackbarProvider maxSnack={3}>
      <WorkExperience />
    </SnackbarProvider>
  ),
  dataRetention: () => (
    <SnackbarProvider maxSnack={3}>
      <DataRetention />
    </SnackbarProvider>
  ),
  dataRetentionHRAdmin: () => (
    <SnackbarProvider maxSnack={3}>
      <DataRetentionHRAdmin />
    </SnackbarProvider>
  ),
};

(window as any).ui2ReactComponents = {
  renderLeftSideMenu,
  renderScheduleChangesDialog,
  renderAccountManagementMenu,
  renderUserActivityLogDialogComponent,
  renderWorkflowsEmployeeProposalsDialog,
  renderPortalTopBar,
  renderOrganizationUnitPopup,
};

(window as any).reactHelpers = {
  getConfiguredUiLabelText: (term: string) => getConfiguredText(term),
  hasRole: (role: string) => hasRole(role),
};

(window as any).utils = { docxtemplater, dispatchTabChangeEvent, initLogger };

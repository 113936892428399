import { ChangeEvent, KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';

import { commentTextCharactersLimit } from './sendCommentConstants';

export const useSendComment = (
    taskId: number,
    creatingComment: boolean,
    failedToCreateComment: boolean,
    createTaskComment: (taskId: number, commentText: string) => void,
) => {
    const [commentText, setCommentText] = useState('');
    const textareaRef = useRef<HTMLTextAreaElement>();
    const prevCreatingCommentRef = useRef(creatingComment);
    const textareaIsDisabled = creatingComment;

    const handleCommentTextChange = useCallback(
        (e: ChangeEvent<HTMLTextAreaElement>) => {
            setCommentText(e.currentTarget.value.replace(/  +/g, ' '));
        },
        [setCommentText]
    );

    const commentTextLength = commentText.trim().length;
    const exceededCommentTextLengthLimit = commentTextLength > commentTextCharactersLimit;

    const handleTextareaKeyDown = useCallback(
        (e: KeyboardEvent<HTMLTextAreaElement>) => {
            if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();

                if (!exceededCommentTextLengthLimit) {
                    const trimmedCommentText = commentText.trim();

                    if (trimmedCommentText) {
                        createTaskComment(taskId, trimmedCommentText);
                    }
                }
            }
        },
        [taskId, exceededCommentTextLengthLimit, commentText, createTaskComment]
    );

    useEffect(
        () => {
            if (
                prevCreatingCommentRef.current &&
                !creatingComment &&
                !failedToCreateComment
            ) {
                setCommentText('');
            }

            prevCreatingCommentRef.current = creatingComment;
        },
        [
            prevCreatingCommentRef,
            creatingComment,
            failedToCreateComment,
            setCommentText,
        ]
    );

    useEffect(
        () => {
            if (!textareaIsDisabled && textareaRef.current) {
                textareaRef.current.focus();
            }
        },
        [textareaRef, textareaIsDisabled]
    );

    return {
        textareaRef,
        commentText,
        textareaIsDisabled,
        handleCommentTextChange,
        handleTextareaKeyDown,
        commentTextLength,
        exceededCommentTextLengthLimit,
    };
};
import {
  getAllEmployees,
  getEmployeeInfoById,
  getEmployeeName,
  getLoggedUserId,
  isHR,
  isManager,
} from '@/old/utils/helper';
import API, { Relation } from '@/app/api/internalAPIs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  EvaluationStatus,
  GenericEmployeeEvaluation,
  ITemplateFull,
  IUnitGridData,
  TemplateFieldSelection,
  TemplateStatus,
} from '../../JobEvaluation/types';
import {
  JobEvaluationReportData,
  JobEvaluationReportUnit,
  fetchAllJobEvaluationTemplates,
} from '@/app/redux/jobEvaluation';
import { useUnits } from '@/app/hooks/useUnits';
import {
  filterEvaluationData,
  getTemplateSubjectById,
  getUnitEvaluationCombinedStatusDistribution,
  getUserRolePerspective,
  templateStatusTranslation,
  translateCompletionStatus,
} from './helpers';
import Service from '@/app/utils/service';
import translate from '@/app/utils/translate';
import moment from 'moment';
import React from 'react';
import { Dispatch } from 'redux';
import { ReducerState } from '@/app/redux/store';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import TemplateResponseReport from './components/TemplateResponseReport/TemplateResponseReport';
import {
  fetchAllJobRequirements as dispatchFetchAllJobRequirements,
  getEnumScoreGroups as dispatchGetEnumScoreGroups,
} from '@/app/redux/jobRequirementEvaluations';

type MapStateToProps = {
  allJobEvaluationTemplates: ITemplateFull[];
};

type MapDispatchToProps = {
  fetchJobEvaluations: () => Promise<ITemplateFull[]>;
  fetchAllJobRequirements: () => Promise<any>;
  getEnumScoreGroups: () => Promise<any>;
};

type OwnProps = {
  isStatusReport: boolean;
};

type Props = OwnProps & MapStateToProps & MapDispatchToProps;

const JobEvaluationReport = (props: Props) => {
  const {
    allJobEvaluationTemplates,
    fetchJobEvaluations,
    fetchAllJobRequirements,
    getEnumScoreGroups,
    isStatusReport,
  } = props;
  const userId = getLoggedUserId();
  const defaultRelation =
    isHR() && !isManager() ? Relation.ALL : Relation.DIRECT;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [jobEvaluationData, setJobEvaluationData] = useState<
    JobEvaluationReportUnit[]
  >([]);
  const [empData, setEmpData] = useState([]);
  const [selectedTemplateIds, setSelectedTemplateIds] = useState<string[]>([]);
  const [relation, setRelation] = useState(defaultRelation);
  const [selectedTemplateFields, setSelectedTemplateFields] = useState<
    TemplateFieldSelection
  >();

  const {
    allUnits,
    includeSubunits,
    selectedUnitId,
    getUnitDataById,
    getIncludedUnits,
    setSelectedUnitId,
    setIncludeSubUnits,
  } = useUnits();

  const handleFetchError = useCallback(
    (err: any) => {
      setJobEvaluationData([]);
      setLoading(false);
      setError(err);
    },
    [setJobEvaluationData, setError, setLoading],
  );

  const handleFetchSuccess = useCallback(() => {
    setEmpData([]);
    setLoading(false);
    setError('');
  }, [setError, setEmpData, setLoading]);

  const allEmps = useMemo(() => getAllEmployees(), []);
  const allAvailableEmps = useMemo(
    () =>
      Object.keys(allEmps)
        .filter(empId => allEmps[empId].fEmpStatus === 'ACTIVE')
        .map(empId => allEmps[empId]),
    [allEmps],
  );

  const getTemplateSubject = useCallback(
    (templateId: string) =>
      getTemplateSubjectById(templateId, allJobEvaluationTemplates),
    [allJobEvaluationTemplates],
  );

  const mapEmpJobEvaluationStatusData = useCallback(
    (emp: GenericEmployeeEvaluation) => {
      if (!emp.employeeDetails || !emp.employeeDetails.empId) {
        return [];
      }

      const empInfo = getEmployeeInfoById(emp.employeeDetails.empId);
      const empPersonalInfo = {
        unitId: `${emp.employeeDetails.orgUnitDetails.id}`,
        unitName: emp.employeeDetails.orgUnitDetails.name,
        empId: empInfo.fEmpId,
        empNumber: empInfo.fEmpPersonNumber,
        firstName: empInfo.fEmpFirstName,
        lastName: empInfo.fEmpLastName,
        updatedTime: '',
        approvedBy: '',
        updatedBy: '',
        statusCode: EvaluationStatus.NOT_STARTED,
        status: translate.t('laNotStarted'),
        archived: '',
        templateId: '',
        subject: '',
        hasAccount: emp.employeeDetails.userDetails.hasAccount,
      };

      const notStartedEvals = selectedTemplateIds
        .filter(tplId => {
          return !emp.evaluations.find(
            evalItem => evalItem.templateId === tplId,
          );
        })
        .map(tplId => ({
          ...empPersonalInfo,
          subject: getTemplateSubject(tplId),
          templateId: tplId,
        }));

      return emp.evaluations
        .reduce((goalsAcc, evalItem) => {
          const translatedStatus = evalItem.status
            ? translateCompletionStatus(evalItem.status)
            : translate.t('laNotStarted');
          const whoUpdated = getEmployeeName(evalItem.updatedBy);
          const whoApproved = evalItem.approvedBy
            .map(e => getEmployeeName(e))
            .join('; ');
          const updatedAt = moment(evalItem.updatedTime).format(
            'YYYY-MM-DD h:mm:ss',
          );

          return goalsAcc.concat([
            {
              ...empPersonalInfo,
              subject: getTemplateSubject(evalItem.templateId),
              statusCode: evalItem.status || EvaluationStatus.NOT_STARTED,
              status: translatedStatus,
              updatedTime: updatedAt,
              approvedBy: whoApproved,
              updatedBy: whoUpdated,
              archived: evalItem.archived ? translate.t('laArchived') : '',
            },
          ]);
        }, [])
        .concat(notStartedEvals);
    },
    [selectedTemplateIds, getTemplateSubject],
  );

  const mapEmpJobEvaluationReportData = useCallback(
    (emp: GenericEmployeeEvaluation, tplFields: TemplateFieldSelection) => {
      if (!emp.employeeDetails || !emp.employeeDetails.empId) {
        return [];
      }

      const empInfo = getEmployeeInfoById(emp.employeeDetails.empId);
      const foundTemplate = allJobEvaluationTemplates.find(
        templateItem =>
          selectedTemplateIds[0] &&
          `${templateItem.id}` === selectedTemplateIds[0],
      );

      const empPersonalInfo = {
        unitId: `${emp.employeeDetails.orgUnitDetails.id}`,
        unitName: emp.employeeDetails.orgUnitDetails.name,
        empId: empInfo.fEmpId,
        empNumber: empInfo.fEmpPersonNumber,
        evalItems: {},
        name: `${empInfo.fEmpFirstName} ${empInfo.fEmpLastName}`,
        position: empInfo.fEmpPosition,
        selectedTemplateFields: tplFields,
        statusCode: EvaluationStatus.NOT_STARTED,
        templateId: selectedTemplateIds[0],
        template: foundTemplate,
        hasAccount: emp.employeeDetails.userDetails.hasAccount,
      };

      const notStartedEvals = selectedTemplateIds
        .filter(tplId => {
          return !emp.evaluations.find(
            evalItem => evalItem.templateId === tplId,
          );
        })
        .map(tplId => ({
          ...empPersonalInfo,
          subject: getTemplateSubject(tplId),
          templateId: tplId,
        }));

      return emp.evaluations
        .reduce(
          (goalsAcc, evalItem) =>
            goalsAcc.concat([
              {
                ...empPersonalInfo,
                evalItems: evalItem,
                selectedTemplateFields: tplFields,
                statusCode: evalItem.status || EvaluationStatus.NOT_STARTED,
                template: foundTemplate,
              },
            ]),
          [],
        )
        .concat(notStartedEvals);
    },
    [allJobEvaluationTemplates, selectedTemplateIds, getTemplateSubject],
  );

  const mapData = useCallback(
    (
      empUnitEvals: GenericEmployeeEvaluation[],
      tplFields: TemplateFieldSelection,
    ) =>
      empUnitEvals.reduce((list, emp) => {
        return isStatusReport
          ? list.concat(mapEmpJobEvaluationStatusData(emp))
          : list.concat(mapEmpJobEvaluationReportData(emp, tplFields));
      }, []),
    [
      isStatusReport,
      mapEmpJobEvaluationStatusData,
      mapEmpJobEvaluationReportData,
    ],
  );

  useEffect(() => {
    fetchJobEvaluations()
      .then(handleFetchSuccess)
      .catch(handleFetchError);
    fetchAllJobRequirements()
      .then(handleFetchSuccess)
      .catch(handleFetchError);
    getEnumScoreGroups()
      .then(handleFetchSuccess)
      .catch(handleFetchError);
  }, [fetchJobEvaluations, fetchAllJobRequirements, getEnumScoreGroups]);

  useEffect(() => {
    Service.get(
      API.JobEvaluationReport.getJobEvaluationReportCustom(
        getUserRolePerspective(relation),
        userId,
        relation,
        selectedTemplateIds,
      ),
      (response: JobEvaluationReportUnit[]) => {
        setJobEvaluationData(response);
        handleFetchSuccess();
      },
      handleFetchError,
    );
  }, [userId, relation, selectedTemplateIds, setJobEvaluationData]);

  const employeeJobEvaluations = useMemo(
    () =>
      jobEvaluationData
        .reduce((acc: JobEvaluationReportData[], jobEvaluationDataItem) => {
          return acc.concat(jobEvaluationDataItem.employees);
        }, [])
        .map(empJobEvaluationData => {
          const empInfo = getEmployeeInfoById(empJobEvaluationData.employee.id);
          const managerData = getEmployeeInfoById(empInfo.fEmpManagerId);
          const unitData = getUnitDataById(empInfo.fEmpUnit);
          const unitFields = unitData
            ? {
                name: unitData.fTreeUnitName,
                number: unitData.fTreeUnitNumber,
              }
            : {};
          const managerFields = managerData
            ? {
                managerEmpNumber: managerData.fEmpPersonNumber,
                managerFirstName: managerData.fEmpFirstName,
                managerLastName: managerData.fEmpLastName,
              }
            : {};

          return {
            employeeDetails: {
              empId: empInfo.fEmpId,
              firstName: empInfo.fEmpFirstName,
              lastName: empInfo.fEmpLastName,
              empNumber: empInfo.fEmpPersonNumber,
              orgUnitDetails: {
                id: empInfo.fEmpUnit,
                ...unitFields,
              },
              managerId: empInfo.fEmpManagerId,
              ...managerFields,
              employmentId: empInfo.fEmpEmplId,
              startDate: new Date(empInfo.fEmplStartDate).getTime(),
              userDetails: {
                roles: empJobEvaluationData.employee.roles,
                hasAccount: empInfo.fEmpHasAccount,
              },
            },
            evaluations: empJobEvaluationData.evaluations || [],
          };
        }),
    [allAvailableEmps, jobEvaluationData],
  );

  const unitGridData: IUnitGridData[] = useMemo(
    () =>
      allUnits.map(currentUnit => {
        const statusCount = getUnitEvaluationCombinedStatusDistribution(
          employeeJobEvaluations,
          allJobEvaluationTemplates,
          currentUnit,
          selectedTemplateIds,
        );

        return {
          unitId: parseInt(currentUnit.fTreeUnitId, 10),
          unitName: currentUnit.fTreeUnitName,
          completed: statusCount.completed,
          inProgress: statusCount.inProgress,
          notStarted: statusCount.notStarted,
        };
      }),
    [
      allJobEvaluationTemplates,
      allUnits,
      selectedTemplateIds,
      employeeJobEvaluations,
    ],
  );

  const handleTemplateClick = (id: string) => {
    setSelectedTemplateIds(prevSelectedIds => {
      if (!isStatusReport) {
        return id !== prevSelectedIds[0] ? [id] : [];
      }

      return prevSelectedIds.indexOf(id) > -1
        ? prevSelectedIds.filter(tplId => tplId !== id)
        : prevSelectedIds.concat([id]);
    });

    setSelectedUnitId(undefined);
    setSelectedTemplateFields(undefined);
    setEmpData([]);
  };

  const handleTemplateBulkSelection = (templateIds: string[]) => {
    setSelectedTemplateIds([...templateIds]);
    setSelectedUnitId(undefined);
    setSelectedTemplateFields(undefined);
    setEmpData([]);
  };

  const updateSelectedEmps = (
    unitId: string,
    tplFields: TemplateFieldSelection,
    subUnitsIncluded: boolean,
  ) => {
    if (!selectedTemplateIds.length) {
      return;
    }

    const filteredAppraisalData = filterEvaluationData(
      employeeJobEvaluations,
      selectedTemplateIds,
      getIncludedUnits(unitId, subUnitsIncluded),
      unitGridData,
    );

    const additionalFiltering = isStatusReport
      ? filteredAppraisalData
      : filteredAppraisalData.filter(
          appraisalDataItem => appraisalDataItem.evaluations.length,
        );

    setEmpData(mapData(additionalFiltering, tplFields));
  };

  const handleUnitClick = (id: string | number) => {
    const newSelectedUnitId = `${id}` !== selectedUnitId ? `${id}` : undefined;
    setSelectedUnitId(newSelectedUnitId);

    if (!selectedTemplateIds.length) {
      return;
    }

    updateSelectedEmps(
      newSelectedUnitId,
      selectedTemplateFields,
      includeSubunits,
    );
  };

  const handleIncludeSubunitsCheckboxClick = () => {
    setIncludeSubUnits(!includeSubunits);
    updateSelectedEmps(
      selectedUnitId,
      selectedTemplateFields,
      !includeSubunits,
    );
  };

  const handleTeamCheckboxClick = () => {
    const updatedRelation =
      relation === Relation.DIRECT ? Relation.ALL : Relation.DIRECT;
    setRelation(updatedRelation);
    setSelectedUnitId(undefined);
    setSelectedTemplateFields(undefined);
  };

  const handleTemplateFieldSelection = (
    sectionId: string,
    selection: string[],
  ) => {
    const newTemplateFields = {
      ...selectedTemplateFields,
      [sectionId]: { selectedFields: selection },
    };

    const updatedSelectedTemplateFields:
      | TemplateFieldSelection
      | undefined = Object.keys(newTemplateFields).reduce(
      (acc: TemplateFieldSelection | undefined, sectionKey: string) => {
        if (!newTemplateFields[sectionKey].selectedFields.length) {
          return acc;
        }

        return {
          ...acc,
          [sectionKey]: {
            selectedFields: newTemplateFields[sectionKey].selectedFields,
          },
        };
      },
      undefined,
    );

    setSelectedTemplateFields(updatedSelectedTemplateFields);
    updateSelectedEmps(
      selectedUnitId,
      updatedSelectedTemplateFields,
      includeSubunits,
    );
  };

  if (loading) {
    return <div>{loading}</div>;
  }

  if (error) {
    return <div>{'error'}</div>;
  }

  return (
    <TemplateResponseReport
      exportFileName={
        isStatusReport
          ? translate.t('laJobReqEvalStatus')
          : translate.t('laJobReqEvalReport')
      }
      templates={allJobEvaluationTemplates}
      templatesTitle={translate.t('job_evaluation_templates')}
      selectedTemplateFields={selectedTemplateFields}
      onTemplateClick={handleTemplateClick}
      onBulkTemplateSelection={handleTemplateBulkSelection}
      showSearch={true}
      selectedTemplateIds={selectedTemplateIds}
      units={unitGridData}
      selectedUnitId={selectedUnitId}
      onUnitClick={handleUnitClick}
      includeSubunits={includeSubunits}
      onIncludeSubunitsClick={handleIncludeSubunitsCheckboxClick}
      onTeamRelationClick={isManager() ? handleTeamCheckboxClick : undefined}
      teamRelation={relation}
      employeeJobEvaluationData={empData}
      onTemplateFieldSelection={handleTemplateFieldSelection}
      isStatusReport={isStatusReport}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchJobEvaluations: () => dispatch<any>(fetchAllJobEvaluationTemplates()),
  fetchAllJobRequirements: () =>
    dispatch<any>(dispatchFetchAllJobRequirements()),
  getEnumScoreGroups: () => dispatch<any>(dispatchGetEnumScoreGroups()),
});

const mapStateToProps = (state: ReducerState) => {
  const { allJobEvaluationTemplates } = state.jobEvaluation;

  return {
    allJobEvaluationTemplates: allJobEvaluationTemplates.map(
      (jobEvaluationTpl: ITemplateFull) => ({
        ...jobEvaluationTpl,
        status: templateStatusTranslation(
          jobEvaluationTpl.status as TemplateStatus,
        ),
        deadline: moment(jobEvaluationTpl.deadline, 'YYYY-MM-DD').toDate(),
      }),
    ),
  };
};

const enhance = compose<Props, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(JobEvaluationReport);

import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
  WithStyles,
  Grid,
  Typography,
  Card,
  CardContent,
  FormHelperText,
  Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import translate from '@/app/utils/translate';
import { INPUT_TYPE } from '@/app/utils/helper';
import FormFields from '@/app/components/FormFields/FormFields';
import bonusCalculationRuleFormDialogStyle from './bonusCalculationRuleFormDialogStyle';
import { throwError } from '@/app/redux/error';
import {
  getFormDialog,
  changeData,
} from '@/app/components/FormDialog/FormDialog';
import { getFormValues } from 'redux-form';
import { BonusRule } from '../BonusCalculationRules/BonusCalculationRules';
import { genCalcFormula, resetFormula } from '@/app/redux/bonusCalc';

const validate = (values: BonusRule) => {
  let errors: { [k: string]: any } = {};
  const requiredFields = [
    'fRuleName',
    'fRuleFormula',
    'fBonusValue',
    'fAnnualSalary',
    'fScore',
  ];

  requiredFields.forEach((field) => {
    if (!values[field] || values[field] === '<p>&nbsp;</p>') {
      errors[field] = translate.t('laThisRequired');
    }
  });

  if (values.fRuleName && values.fRuleName.length > 80) {
    errors.fRuleName = `${translate.t('laErrorMaxTextLen1')} 80`;
  }

  return errors;
};

const FORM_NAME = 'bonusCalculationRuleForm';

interface MapStateToProps {
  currentValues: BonusRule;
  testResult: string;
  formula: string;
  currency: string;
}

interface MapDispatchToProps {
  throwError: any;
  genCalcFormula: (rule: BonusRule) => void;
  resetFormula: () => void;
}

interface OwnProps {
  title: string;
  open: boolean;
  data?: BonusRule;
  onClose: () => void;
  onSubmit: Function;
  resetForm?: (formName: string) => void;
}

type PropsType = OwnProps &
  WithStyles<typeof bonusCalculationRuleFormDialogStyle> &
  MapDispatchToProps &
  MapStateToProps;

const emptyForm = () => {
  return {
    id: 0,
    active: false,
    fRuleId: 0,
    fRuleName: '',
    fRuleFormula: '',
    fRuleActive: false,
    fBonusValue: '0.15',
    fMonthlySalary: '3000',
    fScore: '0.75',
    fTargetEarning: '3000',
    fAnnualSalary: '36000',
  };
};

type StateType = {
  formData: BonusRule;
};

class BonusCalculationRuleFormDialog extends React.Component<PropsType> {
  state: StateType = {
    formData: emptyForm(),
  };
  FormDialog: any;
  AVAILABLE_SYSTEM_VALUES = [
    {
      text: translate.t('text_bonus_value'),
      value: '{BONUS_VALUE}',
    },
    {
      text: translate.t('text_monthly_salary'),
      value: '{MONTHLY_SALARY}',
    },
    {
      text: translate.t('text_perf_score'),
      value: '{SCORE}',
    },
    {
      text: translate.t('text_target_earning'),
      value: '{TARGET_EARNING}',
    },
    {
      text: translate.t('text_annual_salary'),
      value: '{ANNUAL_SALARY}',
    },
  ];

  loadRule = (rule: BonusRule) => {
    const formData = {
      id: rule.id,
      active: rule.active,
      fRuleId: rule.fRuleId,
      fRuleName: rule.fRuleName,
      fRuleFormula: rule.fRuleFormula,
      fRuleActive: rule.fRuleActive,
      fBonusValue: rule.fBonusValue,
      fMonthlySalary: rule.fMonthlySalary,
      fScore: rule.fScore,
      fTargetEarning: rule.fTargetEarning,
      fAnnualSalary: rule.fAnnualSalary,
    };
    this.setState({ formData });
  };

  constructor(props: PropsType) {
    super(props);
    this.FormDialog = getFormDialog(
      FORM_NAME,
      validate,
      this.onFormValuesChange,
    );
  }

  componentDidMount() {
    this.setFormValues();
  }

  componentDidUpdate(prevProps: PropsType) {
    const { open, data } = this.props;

    if (open && prevProps.data !== data) {
      this.loadRule(data);
      this.props.genCalcFormula(data);
    }
  }

  setFormValues = () => {
    const { formData } = this.state;
    Object.keys(formData).forEach((field) => {
      changeData({ form: FORM_NAME, field, value: formData[field] });
    });
  };

  setStateTestResults = (results: string) => {
    this.setState({
      testResults: results,
    });
  };

  calculateTestResult = (_event: any) => {
    this.props.genCalcFormula(this.props.currentValues);
  };

  onFormValuesChange = (values: BonusRule) => {
    if (values.hasOwnProperty('fRuleFormula') && values.fRuleFormula) {
      this.props.genCalcFormula(values);
    }
  };

  setEmptyState = () => {
    this.setState({
      formData: emptyForm(),
      testResults: '',
    });
  };

  handleClose = () => {
    const { onClose, resetForm } = this.props;

    this.setEmptyState();
    onClose();
    resetForm(FORM_NAME);
    this.props.resetFormula();
    this.setFormValues();
  };

  handleSubmit = (values: BonusRule) => {
    const { onSubmit, resetForm } = this.props;
    this.setEmptyState();
    onSubmit(values);
    resetForm(FORM_NAME);
    this.props.resetFormula();
    this.setFormValues();
  };

  updateFormula = (val: string) => {
    const value = `${this.props.currentValues.fRuleFormula || ''} ${val}`;
    changeData({ form: FORM_NAME, field: 'fRuleFormula', value });
  };

  render() {
    const { title, open, classes, testResult, formula, currency } = this.props;
    const { formData } = this.state;
    const { FormDialog, AVAILABLE_SYSTEM_VALUES } = this;

    const testResults = `${formula} = ${testResult} ${currency}`;
    const leftInputs = [
      {
        type: INPUT_TYPE.HIDDEN,
        code: 'id',
        name: 'id',
        label: translate.t('label_id'),
        order: 0,
        required: false,
      },
      {
        type: INPUT_TYPE.TEXT,
        code: 'fRuleName',
        name: 'fRuleName',
        label: translate.t('label_rule_name'),
        order: 1,
        required: true,
      },
      {
        type: INPUT_TYPE.TEXTAREA,
        code: 'fRuleFormula',
        name: 'fRuleFormula',
        label: translate.t('label_formula'),
        order: 2,
        required: true,
        onBlur: this.calculateTestResult,
        helpText: (
          <>
            <FormHelperText>
              <Button
                className={classes.mathjsDocsButton}
                variant="contained"
                color="default"
                href="http://mathjs.org/docs/reference/functions.html"
                target="mathjsdocs"
                size="small"
              >
                {translate.t('label_math_js_doc')}
              </Button>
              {translate.t('text_math_js_notes')}
            </FormHelperText>
            <FormHelperText>
              {translate.t('text_variables_format')}
            </FormHelperText>
            {AVAILABLE_SYSTEM_VALUES.map((sv: any) => (
              <FormHelperText key={`variable_link_${sv.value}`}>
                <a
                  href="javascript:void(0)"
                  className={classes.link}
                  onClick={() => this.updateFormula(sv.value)}
                >
                  {sv.value}
                </a>{' '}
                = {sv.text}
              </FormHelperText>
            ))}
          </>
        ),
      },
      {
        type: INPUT_TYPE.SWITCH,
        code: 'fRuleActive',
        name: 'fRuleActive',
        label: translate.t('laActive'),
        order: 3,
        required: false,
      },
    ];

    const rightInputs = (
      <Grid container>
        <Grid item xs={12} sm={12} md={6} className={classes.gridLeft}>
          <FormFields
            inputs={[
              {
                type: INPUT_TYPE.NUMBER,
                code: 'fBonusValue',
                name: 'fBonusValue',
                label: translate.t('label_bonus_value'),
                order: 1,
                required: true,
                onBlur: this.calculateTestResult,
              },
              {
                type: INPUT_TYPE.NUMBER,
                code: 'fMonthlySalary',
                name: 'fMonthlySalary',
                label: `${translate.t('label_monthly_salary')} (${
                  this.props.currency
                })`,
                order: 2,
                required: false,
                onBlur: this.calculateTestResult,
              },
              {
                type: INPUT_TYPE.NUMBER,
                code: 'fScore',
                name: 'fScore',
                label: translate.t('label_latest_perf_review_score'),
                order: 3,
                required: true,
                onBlur: this.calculateTestResult,
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormFields
            inputs={[
              {
                type: INPUT_TYPE.NUMBER,
                code: 'fTargetEarning',
                name: 'fTargetEarning',
                label: `${translate.t('label_target_earning')} (${
                  this.props.currency
                })`,
                order: 1.5,
                required: false,
                onBlur: this.calculateTestResult,
              },
              {
                type: INPUT_TYPE.NUMBER,
                code: 'fAnnualSalary',
                name: 'fAnnualSalary',
                label: `${translate.t('label_annual_salary')} (${
                  this.props.currency
                })`,
                order: 2.5,
                required: true,
                onBlur: this.calculateTestResult,
              },
            ]}
          />
        </Grid>
      </Grid>
    );

    return (
      <FormDialog
        title={title}
        open={open}
        onClose={this.handleClose}
        formData={formData}
        paperWidthMd={classes.paperWidthMd}
        onSubmit={this.handleSubmit}
      >
        <Grid container>
          <Grid item xs={12} sm={12} md={5} className={classes.gridLeft}>
            <FormFields inputs={leftInputs} />
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={7}
            className={classes.gridRight}
          >
            <Card className={classes.gridRightContainer}>
              <CardContent>
                <Typography variant="h6" style={{ width: '100%' }}>
                  {translate.t('label_test_values')}
                </Typography>
                {rightInputs}
                <>
                  <Typography variant="h6" style={{ width: '100%' }}>
                    {translate.t('label_test_results')}
                  </Typography>
                  {testResults}
                </>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </FormDialog>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { testResult, formula, currency } = state.bonusCalc;

  return {
    currentValues: getFormValues(FORM_NAME)(state),
    testResult,
    formula,
    currency,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  throwError: (err: any) => dispatch(throwError(err)),
  genCalcFormula: (rule: BonusRule) => dispatch<any>(genCalcFormula(rule)),
  resetFormula: () => dispatch(resetFormula()),
});

const enhance = compose<any, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(bonusCalculationRuleFormDialogStyle, { withTheme: true }),
);

export default enhance(BonusCalculationRuleFormDialog);

import React, { useContext, useEffect, useCallback, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  Icon,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { Formik, FormikProps, FieldArray } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import moment from 'moment';

import Loading from '@/app/components/Loading/Loading';
import translate from '@/app/utils/translate';

import Context from '../context/trainingsContext';
import { formatTrainingDates } from '../helpers';
import {
  CompanyTraining,
  CompanyTrainingParticipantRegistration,
  ApprovalAction,
  HoursAction,
  CompletionAction,
  ExpiresAction,
  UpdateTrainingHistoryAction,
} from '../types';
import { ParticipantsExportDialog } from './';

import {
  ParticipantList,
  SendEmailToParticipants,
  styles,
} from './participantsDialog/';
import AddToDrDialog from './AddToDrDialog';
import ParticipantsImportDialog from './ParticipantsImportDialog';

export type FormData = {
  participants: (CompanyTrainingParticipantRegistration & {
    addToDr?: boolean;
  })[];
};

type OwnProps = {
  open: boolean;
  training: CompanyTraining;
  onSave: (newParticipants: CompanyTrainingParticipantRegistration[]) => void;
  onClose: () => void;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const ParticipantsDialog = ({
  classes,
  open,
  training,
  onSave,
  onClose,
}: Props) => {
  const {
    state: {
      participants: { data: participants, loading, error },
    },
    getParticipants,
    saveParticipants,
    reorderParticipants,
    changeApprovalStatuses,
    changeHours,
    changeCompletionStatuses,
    changeExpirationDates,
    changeUpdateTrainingHistoryMarks,
    toggleParticipantRegistration,
    toggleAllParticipantRegistrations,
    changeUpdateTrainingHistoryMarkForOneUser,
  } = useContext(Context);
  const { enqueueSnackbar } = useSnackbar();
  const [openExportDialog, setOpenExportDialog] = useState(false);
  const [addToDRDialogOpen, setAddToDROpen] = useState(false);
  const [addToDRParticipants, setAddToDRParticipants] = useState([]);
  const [valuesToBeSaved, setValuesToBeSaved] = useState(null as FormData);
  const [openImportDialog, setOpenImportDialog] = useState(false);

  useEffect(() => {
    setOpenImportDialog(false);
  }, [participants]);

  useEffect(() => {
    getParticipants(training);
  }, []);

  const handleReorder = useCallback((newParticipants) => {
    reorderParticipants(training, newParticipants);
  }, []);

  const validationSchema = yup.object<FormData>().shape({
    participants: yup.array().of(
      yup.object<CompanyTrainingParticipantRegistration>().shape({
        trainingId: yup.number().required(),
        participant: yup.object().required(),
      }),
    ),
  });

  const handleCancel = () => {
    onClose();
  };

  const handleSave = (values: FormData, _actions: FormikProps<FormData>) => {
    saveParticipants(training, values.participants);
    onSave(values.participants);
    enqueueSnackbar(translate.t('label_participant_list_saved'), {
      variant: 'success',
    });
    onClose();
  };

  if (!open || loading || !participants) {
    return null;
  }

  const renderContent = (formikBag: FormikProps<FormData>) => {
    let content: React.ReactNode;
    if (error) {
      content = <Typography>ERROR: {error}</Typography>;
    } else if (loading || !participants) {
      content = <Loading />;
    } else {
      content = (
        <FieldArray
          name="participants"
          render={arrHelpers => (
            <ParticipantList
              training={training}
              form={formikBag}
              onReorder={() => handleReorder(formikBag.values.participants)}
              onChangeApprovalStatuses={(action: ApprovalAction) =>
                changeApprovalStatuses(training, formikBag.values.participants, action)
              }
              onChangeHours={(action: HoursAction, hours?: number) =>
                changeHours(training, formikBag.values.participants, action, hours)
              }
              onChangeCompletionStatuses={(action: CompletionAction) =>
                changeCompletionStatuses(training, formikBag.values.participants, action)
              }
              onChangeExpirationDates={(
                action: ExpiresAction,
                expires?: moment.Moment,
              ) =>
                changeExpirationDates(training, formikBag.values.participants, action, expires)
              }
              onChangeUpdateTrainingHistoryMarks={(
                action: UpdateTrainingHistoryAction,
              ) =>
                changeUpdateTrainingHistoryMarks(training, formikBag.values.participants, action)
              }
              arrayHelpers={arrHelpers}
              onChangeAddToDR={(addToDr, reg) => {
                if (addToDr) {
                  setAddToDRParticipants([...addToDRParticipants, reg]);
                } else {
                  setAddToDRParticipants(
                    addToDRParticipants.filter(participantRegistration => {
                      return participantRegistration.id !== reg.id;
                    }),
                  );
                }
              }}
              onToggleParticipantRegistration={toggleParticipantRegistration}
              onToggleAllParticipantRegistration={
                toggleAllParticipantRegistrations
              }
              toggleParticipantUpdateTrainingHistory={(
                actualParticipantId: number,
                checkBoxValue: boolean,
              ) =>
                changeUpdateTrainingHistoryMarkForOneUser(
                  training,
                  formikBag.values.participants,
                  actualParticipantId,
                  checkBoxValue,
                )
              }
            />
          )}
        />
      );
    }

    return content;
  };

  const exportDialog = !openExportDialog ? null : (
    <ParticipantsExportDialog
      open={openExportDialog}
      training={training}
      participants={participants}
      onClose={() => setOpenExportDialog(false)}
    />
  );

  const importDialog =
    !openImportDialog ? null : (
      <ParticipantsImportDialog
        open={openImportDialog}
        training={training}
        onClose={() => setOpenImportDialog(false)}
      />
    );

  const handleCloseParticipantsDialog = () => {
    setAddToDROpen(false);
  };

  const handleAddToRegistry = () => {
    handleSave(valuesToBeSaved, undefined);
  };

  const addToDRDialog = !addToDRDialogOpen ? null : (
    <AddToDrDialog
      open={addToDRDialogOpen}
      participants={addToDRParticipants.map((p) => p.participant)}
      title={`${translate.t('label_participants')}: ${
        training.name.label
      } ${formatTrainingDates(training.startDate, training.endDate)}`}
      onSave={handleAddToRegistry}
      onClose={handleCloseParticipantsDialog}
    />
  );

  const onSaveClick = (formikBag: FormikProps<{ participants: any }>) => {
    if (addToDRParticipants.length !== 0) {
      setValuesToBeSaved(formikBag.values);
      setAddToDROpen(true);
    } else {
      handleSave(formikBag.values, undefined);
    }
  };

  return (
    <>
      {exportDialog}
      {addToDRDialog}
      {importDialog}
      <Formik
        initialValues={{
          participants: participants.map((p: any) => {
            const isAddedToDr = addToDRParticipants.length
              ? addToDRParticipants.some((participantRegistration: any) => {
                  return (
                    participantRegistration &&
                    participantRegistration.id === p.id
                  );
                })
              : false;

            return { ...p, addToDr: isAddedToDr };
          }),
        }}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={handleSave}
      >
        {(formikBag) => (
          <Dialog
            open={open}
            disableEscapeKeyDown={true}
            fullWidth={true}
            maxWidth="xl"
          >
            <DialogTitle>
              <div className={classes.flex}>
                <Typography className={classes.grow} variant="h6">
                  {translate.t('label_participants')}:&nbsp;
                  {training.name.label}{' '}
                  {formatTrainingDates(training.startDate, training.endDate)}
                </Typography>
              </div>
            </DialogTitle>
            <DialogContent className={classes.content}>
              {renderContent(formikBag)}
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="text"
                disabled={!participants || participants.length === 0}
                onClick={() => setOpenExportDialog(true)}
              >
                <Icon className={classes.titleButtonIcon}>cloud_download</Icon>
                {translate.t('laExport')}
              </Button>
              <Button
                color="primary"
                variant="text"
                onClick={() => setOpenImportDialog(!importDialog)}
              >
                <Icon className={classes.titleButtonIcon}>cloud_upload</Icon>
                {translate.t('laImport')}
              </Button>
              <SendEmailToParticipants
                training={training}
                participants={participants}
              />
              <div className={classes.grow} />
              <Button
                data-testid="btn-training-participants-dialog-cancel"
                color="primary"
                onClick={handleCancel}
              >
                {translate.t('laCancel')}
              </Button>
              <Button
                data-testid="btn-training-participants-dialog-save"
                color="primary"
                variant="contained"
                onClick={() => onSaveClick(formikBag)}
              >
                {translate.t('laSave')}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </>
  );
};

export default withStyles(styles)(ParticipantsDialog);

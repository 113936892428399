import {
  Grid,
  Icon,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import React from 'react';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import { styles } from './styles';
import { compose } from 'recompose';
import {
  Column,
  employment,
  longTermAbsences,
  MappedEmployment,
  MappedLongTermAbsence,
  MappedOtherExperience,
  MappedPreviousCompany,
  MappedPreviousLongTermAbsence,
  MappedWorkExprience,
  otherExperience,
  previousAbsences,
  previousCompany,
} from './data';
import translate from '@/app/utils/translate';
import WorkExperienceLineDataDisplay from './WorkExperienceLineDataDisplay';
import WorkExperienceTotal from './WorkExperienceTotal';

type MappedArrays =
  | MappedEmployment[]
  | MappedLongTermAbsence[]
  | MappedPreviousCompany[]
  | MappedPreviousLongTermAbsence[]
  | MappedOtherExperience[];

type OwnProps = {
  title: string;
  data: MappedArrays | MappedWorkExprience;
};

type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const getHeaders = (title: string) => {
  switch (title) {
    case 'laEmployment':
      return employment();
    case 'laLongTermAbsences':
      return longTermAbsences();
    case 'laPreviousCompany':
      return previousCompany();
    case 'laPreviousAbsences':
      return previousAbsences();
    case 'laOtherExperience':
      return otherExperience();
    default:
      return '';
  }
};
const WorkExperienceLine = ({ title, classes, data }: Props) => {
  return (
    <Grid item container direction="row" className={classes.dataDisplay}>
      <Grid item container className={classes.alignCenter}>
        <Icon
          className={classes.bulletIcon}
          id="WE-bullet-icon"
          color="primary"
          fontSize="small"
        >
          <FormatListBulletedIcon fontSize="small" />
        </Icon>
        <Typography className={classes.header} color="primary" variant="h6">
          {translate.t(title)}
        </Typography>
      </Grid>
      {title !== 'workExperience' && (
        <WorkExperienceLineDataDisplay
          dataHeaders={getHeaders(title) as Column[]}
          data={data as MappedArrays}
        />
      )}
      {title === 'workExperience' && data && (
        <WorkExperienceTotal data={data as MappedWorkExprience} />
      )}
    </Grid>
  );
};

const enhance = compose<Props, OwnProps>(withStyles(styles));

export default enhance(WorkExperienceLine);

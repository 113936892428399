import moment from 'moment';

import { ReportingGroupType, TimeBlock, TimeCard } from '../data';

export const getIntervalDuration = (
  from: string,
  to: string,
): moment.Duration => {
  const numberOfMinutes = moment(to, 'HH:mm:ss').diff(
    moment(from, 'HH:mm:ss'),
    'minutes',
  );

  return moment.duration(numberOfMinutes, 'minutes');
};

export const getTimeBlocksTotalHours = (
  intervals: Array<TimeBlock>,
): moment.Duration => {
  return intervals.reduce(
    (accumulator, { hours }) => accumulator.add(moment.duration(hours)),
    moment.duration(),
  );
};

/**
 *  The daily balance is being calculated from 2 parts (DWT + DCT):
 *  - DWT (Daily working time) is the sum of working transactions without
 *    daily working hours (how much you were supposed to work);
 *  - DCT (Daily correction time) is the sum of correction transactions;
 *
 *  Daily working time will be 0 when no non-correction transactions
 *  were reported.
 */
export const getTimeCardDailyBalance = (
  timeCard?: TimeCard,
): moment.Duration => {
  if (!timeCard || !timeCard.timeBlocks.length) {
    return moment.duration();
  }

  const nonCorrectionTypeTimeBlocks = timeCard.timeBlocks.filter(
    ({ reportingGroupType }) =>
      reportingGroupType !== ReportingGroupType.TIMESHEET_CORRECTION_TYPE,
  );

  const dailyCorrectionBalance = getTimeBlocksTotalHours(
    timeCard.timeBlocks.filter(
      ({ reportingGroupType }) =>
        reportingGroupType === ReportingGroupType.TIMESHEET_CORRECTION_TYPE,
    ),
  );

  if (!nonCorrectionTypeTimeBlocks.length) {
    return dailyCorrectionBalance;
  }

  const loggedWorkingHours = getTimeBlocksTotalHours(
    timeCard.timeBlocks.filter(
      ({ reportingGroupType }) =>
        reportingGroupType === ReportingGroupType.TIMESHEET_WORK_TYPE,
    ),
  );

  const dailyWorkingBalance = loggedWorkingHours.subtract(
    moment.duration(timeCard.requiredWorkingHours),
  );

  return dailyWorkingBalance.add(dailyCorrectionBalance);
};

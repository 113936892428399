import {
  SEAT_STATUS,
  APPROVAL_STATUS,
  REGISTRATION_STATUS,
} from '../types';

export default function(
  approvalStatus?: APPROVAL_STATUS,
  seatStatus?: SEAT_STATUS,
): REGISTRATION_STATUS {
  if (!approvalStatus) {
    return REGISTRATION_STATUS.NOT_REGISTERED;
  }

  // Registration statuses purely dependant on approval status
  if (approvalStatus === APPROVAL_STATUS.WAITING) {
    return REGISTRATION_STATUS.WAITING_FOR_APPROVAL;
  }
  if (approvalStatus === APPROVAL_STATUS.CANCELLED) {
    return REGISTRATION_STATUS.CANCELLED;
  }
  if (approvalStatus === APPROVAL_STATUS.REJECTED) {
    return REGISTRATION_STATUS.REJECTED;
  }

  // Otherwise registration status is dependant on seat status
  switch (seatStatus) {
    case SEAT_STATUS.HAS_SEAT:
      return REGISTRATION_STATUS.ACCEPTED;
    case SEAT_STATUS.WAITING_SEAT:
      return REGISTRATION_STATUS.WAITING_FOR_SEAT;
    default:
      return REGISTRATION_STATUS.NO_SEAT;
  }
}

import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  Tooltip,
  TextField,
  Typography,
  WithStyles,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import {
  AddCircle as AddCircleIcon,
  RemoveCircle as RemoveCircleIcon,
} from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import enumScoreStyle from './enumScoreStyle';
import Select from './Select';
import { ReducerState } from '@/app/redux/store';
import {
  createEnumScoreGroup,
  updateEnumScoreGroup,
} from '@/app/redux/jobEvaluation';
import translate from '@/app/utils/translate';
import {
  ScoreElement,
  HeaderElement,
  ChainGroup,
  ScoreGroup,
} from '../../../types';
import ENUM_GROUP_CONFIG from '@/app/components/Enum/EnumGroupList/enumGroupConfig';
import { EnumConfig } from '@/app/redux/enums';

type OwnProps = {
  handleModalClose: () => void;
  open: boolean;
  selectedScoreGroupId?: string;
  usedScoreGroups: string[][];
};

type PropsType = OwnProps & WithStyles<typeof enumScoreStyle>;

const EnumScore = (props: PropsType) => {
  const { classes, open, handleModalClose, selectedScoreGroupId } = props;
  const dispatch = useDispatch();

  const scoreGroups = useSelector((state: ReducerState) =>
    state.jobEvaluation.get('scoreGroups'),
  );

  const { allEnums, enumConfigs } = useSelector((state: ReducerState) => ({
    allEnums: state.enums.get('allEnums').toJS(),
    enumConfigs: state.enums.get('configs').toJS(),
  }));

  const enumGroups = enumConfigs.filter((eg: any) =>
    eg.name.startsWith('__USER__DEV_'),
  );

  const emptyChain = { group: '', enum: '' };
  const emptyItem = {
    name: '',
    headers: [{ group: '', seq: 1 }],
    scores: [{ score: 0, chain: [emptyChain] }],
  } as ScoreGroup;

  const [formValues, setFormValues] = useState(emptyItem);

  useEffect(() => {
    if (scoreGroups.length && !!selectedScoreGroupId) {
      const foundScoreGroup = scoreGroups.find(
        (sc: ScoreGroup) => sc.id === selectedScoreGroupId,
      );
      setFormValues(foundScoreGroup);
      scoresAreValid(foundScoreGroup.scores);
    }
  }, [scoreGroups]);

  const [showHeadersError, setShowHeadersError] = useState([false]);
  const [showNameError, setShowNameError] = useState(false);
  const [showScoreError, setShowScoreError] = useState([false]);
  const [showScoreChainsError, setShowScoreChainsError] = useState([[false]]);

  const getEmptyChain = (hdr: HeaderElement) => ({
    enum: '',
    group: hdr.group,
  });

  const getEmptyGroup = (seq: number) => ({
    seq: seq + 1,
    group: '',
  });

  const getEmptyScoreChain = (headers: HeaderElement[]) => ({
    score: 0,
    chain: headers.map(h => getEmptyChain(h)),
  });

  const getEmptyChainErrors = (scores: ScoreElement[]) =>
    scores.map(({ chain }) => chain.map(() => false));

  const handleAddGroup = (seq: number) => {
    const headers = [...formValues.headers, getEmptyGroup(seq)];

    const scores = formValues.scores.map(score => ({
      ...score,
      chain: headers.map((head, ihd) => {
        if (score.chain.length - 1 >= ihd) {
          return score.chain[ihd];
        }

        return getEmptyChain(head);
      }),
    }));

    setFormValues({ ...formValues, headers, scores });
    setShowHeadersError([...showHeadersError, false]);
    setShowScoreChainsError(getEmptyChainErrors(scores));
  };

  const addScoreItem = () => {
    const { scores: oldScores, headers } = formValues;
    const emptyScoreChain = getEmptyScoreChain(headers);
    const scores = [...oldScores, emptyScoreChain];

    setFormValues({ ...formValues, scores });
    setShowScoreError([...showScoreError, false]);
    setShowScoreChainsError([
      ...showScoreChainsError,
      emptyScoreChain.chain.map(() => false),
    ]);
  };

  type AddIconButtonProps = {
    hidden?: boolean;
    onClick?: () => void;
  };
  const AddIconButton = (prop: AddIconButtonProps) => (
    <Tooltip title={translate.t('laAdd')}>
      <IconButton
        color="primary"
        component="span"
        className={prop.hidden ? classes.hidden : classes.addButton}
        onClick={() => (prop.onClick ? prop.onClick() : undefined)}
      >
        <AddCircleIcon />
      </IconButton>
    </Tooltip>
  );

  const onRemoveGroup = (indexToRemove: number) => {
    const { scores: oldScores, headers: oldHeaders } = formValues;

    const headers = [...oldHeaders].filter((_h, i) => i !== indexToRemove);

    const scores = oldScores.map(score => ({
      ...score,
      chain: [...score.chain].filter((_s, i) => i !== indexToRemove),
    }));

    setFormValues({ ...formValues, headers, scores });
    setShowHeadersError(
      [...showHeadersError].filter((_e, i) => i !== indexToRemove),
    );
  };

  const onRemoveChain = (indexToRemove: number) => {
    const { scores: oldScores } = formValues;
    const scores = [...oldScores].filter((_s, i) => i !== indexToRemove);

    setFormValues({ ...formValues, scores });
    setShowScoreChainsError(
      [...showScoreChainsError].filter((_e, i) => i !== indexToRemove),
    );
  };

  const nameIsValid = (name: string) => {
    const isInvalid = name === '';
    setShowNameError(isInvalid);

    return !isInvalid;
  };

  const headersAreValid = (headers: HeaderElement[]) => {
    const areInvalid = headers.map(header => header.group === '');

    setShowHeadersError(areInvalid);

    return areInvalid.every(val => val === false);
  };

  const scoresAreValid = (scores: ScoreElement[]) => {
    let chainsAreInvalid: boolean[][] = [];
    const scoresAreInvalid = scores.map(({ score, chain }) => {
      chainsAreInvalid.push(chain.map(ch => ch.enum === ''));

      return (score !== 0 && !score) || score < 0;
    });

    setShowScoreError(scoresAreInvalid);
    setShowScoreChainsError(chainsAreInvalid);

    return (
      scoresAreInvalid.every(val => val === false) &&
      chainsAreInvalid.every(val => val.every(v => v === false))
    );
  };

  const handleSubmit = () => {
    const validNames = nameIsValid(formValues.name);
    const validHeaders = headersAreValid(formValues.headers);
    const validScores = scoresAreValid(formValues.scores);
    const allFieldsAreValid = validNames && validHeaders && validScores;

    if (!allFieldsAreValid) {
      return;
    }

    if (formValues.id) {
      dispatch(updateEnumScoreGroup(formValues));
    } else {
      dispatch(createEnumScoreGroup(formValues));
    }

    handleModalClose();
  };

  const changeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: name } = event.target;
    setFormValues({ ...formValues, name });

    if (name !== '') {
      setShowNameError(false);
    }
  };

  const changeScore = useCallback(
    (
      event: React.ChangeEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >,
      index: number,
    ) => {
      const { value } = event.target;
      if (/^\d*(\.\d*)?$/.test(value) || value === '') {
        const scores = formValues.scores.map((score, i) => {
          if (i === index) {
            score.score = value !== '' ? parseFloat(value) : null;
          }
          return score;
        });

        setFormValues({ ...formValues, scores });

        if (value !== '') {
          setShowScoreError(
            showScoreError.map((err, i) => (i === index ? false : err)),
          );
        }
      }
    },
    [formValues.scores],
  );

  const handleHeaderChange = (
    field: HeaderElement,
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { headers: allHeaders, scores: allScores } = formValues;

    const foundIndex = allHeaders.findIndex(h => h.seq === field.seq);

    const headers = allHeaders.map((header, index) => ({
      ...header,
      group:
        field.seq === header.seq && foundIndex === index
          ? event.target.value
          : header.group,
    }));

    const scores = allScores.map(score => ({
      ...score,
      chain: headers.map((h, i) =>
        score.chain[h.seq - 1]
          ? {
              group: h.group,
              enum: foundIndex === h.seq - 1 ? '' : score.chain[i].enum,
            }
          : score.chain[i],
      ),
    }));

    setFormValues({ ...formValues, headers, scores });

    if (field.group !== '') {
      setShowHeadersError(
        showHeadersError.map((err, i) => (i === foundIndex ? false : err)),
      );
    }
  };

  const handleChainChange = (
    field: ChainGroup,
    event: React.ChangeEvent<HTMLSelectElement>,
    index: number,
    childIndex: number,
  ) => {
    const { scores: allScores } = formValues;
    const { value } = event.target;

    const scores = allScores.map((score, isc) => ({
      ...score,
      chain:
        index === isc
          ? score.chain.map((ch, ich) => ({
              ...ch,
              enum:
                ch.group === field.group && ich === childIndex
                  ? value
                  : ch.enum,
            }))
          : score.chain,
    }));

    setFormValues({ ...formValues, scores });

    if (value !== '') {
      const errors = showScoreChainsError.map((errChain, i) =>
        i !== index
          ? errChain
          : errChain.map((err, idx) => (idx === childIndex ? false : err)),
      );

      setShowScoreChainsError(errors);
    }
  };

  const gridSize = (size: number) => {
    const sizes = {
      1: 4,
      2: 3,
      3: 2,
    };

    return size <= 3 ? sizes[size] : 2;
  };

  const emptySelectOption = { code: '', name: '' };

  const getEnumValueOptions = useCallback(
    (group: string) =>
      [emptySelectOption, ...(allEnums[group] ? allEnums[group] : [])].map(
        (option: any) =>
          option.isActive ? (
            <MenuItem key={option.code} value={option.code}>
              {option.name}
            </MenuItem>
          ) : null,
      ),
    [allEnums],
  );

  const getEnumGroupValueOptions = useCallback(
    () =>
      Object.keys(allEnums)
        .filter(
          (key: string) =>
            !!enumGroups.find((eg: EnumConfig) => eg.name === key),
        )
        .map((key: any) => {
          const name = key.replace('__USER__DEV_', '');

          return (
            <MenuItem key={key} value={key}>
              {translate.t(
                !ENUM_GROUP_CONFIG[name]
                  ? name
                  : ENUM_GROUP_CONFIG[name].transPluralKey,
              )}
            </MenuItem>
          );
        }),
    [allEnums],
  );

  return (
    <Dialog
      classes={{ paper: classes.dialogContainer }}
      disableBackdropClick
      onEscapeKeyDown={handleModalClose}
      open={open}
      maxWidth="xl"
    >
      <DialogTitle>
        {formValues.id
          ? translate.t('title_edit_score_group')
          : translate.t('title_add_score_group')}
      </DialogTitle>
      <DialogContent>
        <form className={classes.form} noValidate autoComplete="off">
          <Grid
            container
            spacing={8}
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={4}>
              <TextField
                label={translate.t('laName')}
                name="name"
                onChange={changeName}
                value={formValues.name}
                className={classes.spacing}
                required
                error={!!showNameError}
                helperText={showNameError ? translate.t('laThisRequired') : ' '}
              />
            </Grid>
          </Grid>
          <div className={classes.titleContainer}>
            <Typography variant="h6" className={classes.title}>
              {translate.t('laHeaders')}:
            </Typography>
            <AddIconButton
              onClick={() => handleAddGroup(formValues.headers.length)}
            />
          </div>
          <Grid container spacing={8} justify="space-between" wrap="nowrap">
            {formValues.headers.map(
              (header: HeaderElement, headerIndex: number) => (
                <Grid
                  item
                  className={classes.headerSelect}
                  xs={12}
                  sm={gridSize(formValues.headers.length)}
                  key={`${header.group}-${headerIndex}`}
                >
                  <Select
                    label={translate.t('laEnumGroup')}
                    name={`enumGroup[${headerIndex}]`}
                    onChange={ev => handleHeaderChange(header, ev)}
                    value={header.group}
                    required
                    error={showHeadersError[headerIndex]}
                    helperText={
                      showHeadersError[headerIndex]
                        ? translate.t('laThisRequired')
                        : ' '
                    }
                  >
                    {getEnumGroupValueOptions()}
                  </Select>
                  {formValues.headers.length > 1 && (
                    <Tooltip title={translate.t('laRemove')}>
                      <IconButton
                        color="primary"
                        component="span"
                        className={classes.removeButton}
                        onClick={() => onRemoveGroup(headerIndex)}
                      >
                        <RemoveCircleIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
              ),
            )}
            <Grid item xs={2} className={classes.scoreTitleContainer}>
              <Typography variant="h6">Scores</Typography>
            </Grid>
          </Grid>

          <Grid container justify="space-between">
            <Grid item xs={12}>
              <div className={classes.titleContainer}>
                <Typography variant="h6" className={classes.title}>
                  {translate.t('laScoreChains')}:
                </Typography>
                <AddIconButton onClick={addScoreItem} />
              </div>
            </Grid>
          </Grid>
          {formValues.scores.map(
            (score: ScoreElement, scoreElIndex: number) => (
              <Grid
                container
                spacing={8}
                justify="space-between"
                wrap="nowrap"
                key={scoreElIndex}
              >
                {score.chain.map(
                  (chainGroup: ChainGroup, chainIndex: number) => (
                    <Grid
                      item
                      xs={12}
                      sm={gridSize(formValues.headers.length)}
                      key={`${chainGroup.group}-${chainIndex}`}
                      className={classes.chainSelect}
                    >
                      <Select
                        label={translate.t('laEnum')}
                        name={`enum[${scoreElIndex}][${chainIndex}]`}
                        onChange={ev =>
                          handleChainChange(
                            chainGroup,
                            ev,
                            scoreElIndex,
                            chainIndex,
                          )
                        }
                        value={chainGroup.enum}
                        required
                        error={showScoreChainsError[scoreElIndex][chainIndex]}
                        helperText={
                          showScoreChainsError[scoreElIndex][chainIndex]
                            ? translate.t('laThisRequired')
                            : ' '
                        }
                      >
                        {getEnumValueOptions(chainGroup.group)}
                      </Select>
                    </Grid>
                  ),
                )}
                <Grid item xs={2} className={classes.scoreInputContainer}>
                  <TextField
                    label={translate.t('laScore')}
                    onChange={ev => changeScore(ev, scoreElIndex)}
                    value={score.score}
                    className={classes.spacing}
                    required
                    type="number"
                    name={`scoreInput[${scoreElIndex}]`}
                    error={!!showScoreError[scoreElIndex]}
                    helperText={
                      showScoreError[scoreElIndex]
                        ? translate.t('laThisRequired')
                        : ' '
                    }
                  />
                  {formValues.scores.length > 1 && (
                    <Tooltip title={translate.t('laRemove')}>
                      <IconButton
                        color="primary"
                        component="span"
                        className={classes.removeButton}
                        onClick={() => onRemoveChain(scoreElIndex)}
                      >
                        <RemoveCircleIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            ),
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleModalClose}>
          {translate.t('laCancel')}
        </Button>
        <Button
          type="button"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
        >
          {formValues.id ? translate.t('laSave') : translate.t('laAdd')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(enumScoreStyle)(EnumScore);

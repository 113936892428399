import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const sortableInputsComponentStyle: StyleRulesCallback = (theme: Theme) => {

  return ({
    root: {
      ...theme.mixins.gutters(),
      marginTop: theme.spacing.unit,
      cursor: 'grab',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      '& :active': {
        cursor: 'grabbing',
      },
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    sortableListContainer: {
      display: 'flex',
    },
    sortableItems: {
      flexGrow: 1,
    },
    deleteButtonsContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    sortableItemContainer: {
      display: 'flex',
      alignItems: 'baseline',
    },
    sortableItem: {
      flex: '1 1 0%',
      marginRight: theme.spacing.unit,
      maxHeight: '48px',
      zIndex: 50000,
    },
    deleteButton: {
      height: '48px',
      marginTop: theme.spacing.unit,
      width: '48px',
    },
    textField: {
      marginTop: `${theme.spacing.unit / 2}px`,
    },
  });
};

export default sortableInputsComponentStyle;

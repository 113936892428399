import { Add, Delete } from '@material-ui/icons';
import {
  withStyles,
  WithStyles,
  IconButton,
  Button,
} from '@material-ui/core';
import classNames from 'classnames';
import * as React from 'react';
import compose from 'recompose/compose';
import { Field } from 'redux-form';

import {
  RenderTextField,
} from '@/app/components/FormFieldBuilders';
import { InputType } from '@/app/components/FormFields/FormFields';
import translate from '@/app/utils/translate';
import textFormFieldDynamicListStyle from './textFormFieldDynamicListStyle';

type OwnProps = {
  fields: InputType[];
  onAddField: () => void;
  onRemoveField: (fieldCode: string) => void;
};

type Props = OwnProps & WithStyles<typeof textFormFieldDynamicListStyle>;

const TextFormFieldDynamicList = (props: Props) => {
  const { classes, fields, onAddField, onRemoveField } = props;
  return (
    <>
      {fields.map((sInput, index) => {
        const { code, name, required, helpText, label, onChange, ...inputProps } = sInput;

        return (
          <div
            className={classNames(classes.listItem, classes.fullWidth)}
            key={code}
          >
            <Field
              component={RenderTextField}
              name={name}
              required={required}
              label={label}
              onChange={onChange ? onChange : null}
              helpText={helpText}
              margin="dense"
              formControlMargin="none"
              style={{ width: '100%' }}
              {...inputProps}
            />
            {index === 0 ? null : (
              <IconButton
                disableRipple
                className={classes.iconBtn}
                aria-label={translate.t('laDelete')}
                onClick={() => { onRemoveField(sInput.code); }}
              >
                <Delete fontSize="small" color="error" />
              </IconButton>
            )}
          </div>
        );
      })}
      <Button
        className={classes.btn}
        size="small"
        variant="contained"
        color="primary"
        onClick={onAddField}
      >
        <Add fontSize="small" />
        {translate.t('laAdd')}
      </Button>
    </>
  );
};

const enhance = compose<Props, OwnProps>(
  withStyles(textFormFieldDynamicListStyle),
);

export default enhance(TextFormFieldDynamicList);

import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, InjectedFormProps, getFormValues } from 'redux-form';
import compose from 'recompose/compose';
import { loadFormData, changeFormData } from '@/app/redux/formData';

/**
 * form: redux form name
 * field: target field to change
 * value: field data
 */
type FormDataType = {
  form: string, field: string, value: any
};

let resetDataFn: Function;
let changeDataFn: Function;
let pushDataFn: Function;

/**
 * Method for reseting the form data
 */
export const resetData = () => {
  resetDataFn();
};

/**
 * Method for changing the form data
 */
export const changeData = (data: FormDataType) => {
  changeDataFn(data);
};

/**
 * Method for pushing a value to a form data
 */
export const pushData = (data: FormDataType) => {
  pushDataFn(data);
};

/**
 * Component for redux-form dialog
 * @param {string} formName - name of the form, used by redux-form for id
 * @param {function} validate - form validation
 *
 */
export const getForm = (formName: string, validate: any) => {

  interface MapDispatchToProps {
    loadFormData(form: string, e: any): void;
    changeFormData: (data: FormDataType) => void;
  }

  interface MapStateToProps {
    currentValues: Array<string>;
  }

  interface OwnProps  {
    formData: object;
  }

  type PropsType = OwnProps & InjectedFormProps & MapStateToProps & MapDispatchToProps;

  const FORM_NAME = formName;

  class Form extends React.Component<PropsType> {

    resetData = () => {
      this.props.loadFormData(FORM_NAME, {});
    }

    componentDidMount () {
      resetDataFn = this.resetData;
      changeDataFn = this.changeData;
      pushDataFn = this.pushData;
      if (this.props.formData) {
        this.props.loadFormData(FORM_NAME, this.props.formData);
      }
    }

    changeData = (data: FormDataType) => {
      this.props.changeFormData(data);
    }

    pushData = (data: FormDataType) => {
      let currentValue = this.props.currentValues[data.field];
      currentValue.push(data.value);
      this.props.changeFormData({
        form: data.form,
        field: data.field,
        value: currentValue
      });
    }

    render () {
      // const { title, open, invalid, pristine, submitting, handleSubmit, paperWidthMd, classes } = this.props;

      return this.props.children;
    }
  }

  const mapStateToProps = (state: any) => ({
    initialValues: {},
    currentValues: getFormValues(FORM_NAME)(state)
  });

  const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadFormData: (form: string, e: any) => dispatch<any>(loadFormData(form, e)),
    changeFormData: (e: FormDataType) => changeFormData(dispatch, e)
  });

  const enhance = compose<any, OwnProps>(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
      form: FORM_NAME,
      validate,
      updateUnregisteredFields: true,
      enableReinitialize : true,
    }),
  );

  return enhance(Form);
};

import * as React from 'react';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import { selectAllEnums } from '@/app/redux/enums';

const UpateGlobalValuesHOC = (PassedComponent: React.ComponentType) => {
  class UpateGlobalValues extends React.Component<any> {
    static remapEnums = (enumItem: any) => {
      if (enumItem.countries) {
        return {
          ...enumItem,
          countries: enumItem.countries.map((country: any) => country.value),
        };
      }
      return enumItem;
    };

    componentDidMount() {
      const { allEnums = {}, configs = [] } = this.props;
      (window as any).jsonEnumConf = update(configs, { $set: configs }).toJS();
      const newEnums = update(allEnums, { $set: allEnums });
      Object.keys(newEnums).forEach((groupKey: string) => {
        (window as any).jsonEnumALL[groupKey] = newEnums[groupKey].map(
          UpateGlobalValues.remapEnums,
        );
      });
    }

    componentDidUpdate(prevProps: any) {
      const { allEnums = {}, configs = [] } = this.props;
      if (JSON.stringify(prevProps.allEnums) !== JSON.stringify(allEnums)) {
        const newEnums = update(allEnums, { $set: allEnums });
        Object.keys(newEnums).forEach((groupKey: string) => {
          (window as any).jsonEnumALL[groupKey] = newEnums[groupKey].map(
            UpateGlobalValues.remapEnums,
          );
        });
      }

      if (JSON.stringify(prevProps.configs) !== JSON.stringify(configs)) {
        (window as any).jsonEnumConf = update(configs, {
          $set: configs,
        }).toJS();
      }
    }

    render() {
      return <PassedComponent {...this.props} />;
    }
  }

  const mapStateToProps = (state: any) => ({
    allEnums: selectAllEnums(state),
    configs: state.enums.get('configs'),
  });

  return connect(mapStateToProps, null)(UpateGlobalValues);
};

export default UpateGlobalValuesHOC;

import React, { useState, useContext } from 'react';
import { Grid, Typography, IconButton, Icon, Tooltip } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import moment from 'moment';

import { getSelectedEmpId, getLoggedUserId } from '@/old/utils/helper';
import translate from '@/app/utils/translate';

import Context from '../../context/trainingsContext';
import { canAccessParticipantList } from '../../helpers';
import {
  CompanyTraining,
  CompanyTrainingParticipantRegistration,
  APPROVAL_STATUS,
} from '../../types';
import {
  ParticipantsButton,
  ParticipantsDialog,
  ParticipantsExportDialog,
  RegistrationStatus,
} from '../';

import ViewFieldText from './trainingInfoView/ViewFieldText';
import ViewFieldElement from './trainingInfoView/ViewFieldElement';

type OwnProps = {
  training: CompanyTraining;
};
type InnerProps = {};
type Props = OwnProps & InnerProps;

const TrainingInfoView = ({ training }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { getOncomingEmployeeTrainings, saveParticipant } = useContext(Context);
  const [openParticipantsList, setOpenParticipantsList] = useState(false);
  const [openExportDialog, setOpenExportDialog] = useState(false);

  const handleChangeApprovalStatus = async (
    updatedParticipant: CompanyTrainingParticipantRegistration,
  ) => {
    saveParticipant(training, updatedParticipant)
      .then(() => {
        const message =
          updatedParticipant.approvalStatus.status.value ===
          APPROVAL_STATUS.APPROVED
            ? translate.t('text_registration_was_approved')
            : translate.t('text_registration_was_rejected');
        getOncomingEmployeeTrainings(getSelectedEmpId() || getLoggedUserId());
        enqueueSnackbar(message, { variant: 'success' });
      })
      .catch((err: string) => {
        enqueueSnackbar(
          translate.t('text_error_saving_approval_status_change', {
            error: err,
          }),
          { variant: 'error' },
        );
      });
  };

  const participantListDialog = !openParticipantsList ? null : (
    <ParticipantsDialog
      open={openParticipantsList}
      training={training}
      onSave={() => {
        /** TODO: implement Handle participants list save here! */
      }}
      onClose={() => setOpenParticipantsList(false)}
    />
  );

  const exportDialog = !openExportDialog ? null : (
    <ParticipantsExportDialog
      open={openExportDialog}
      training={training}
      onClose={() => setOpenExportDialog(false)}
    />
  );

  return (
    <>
      {participantListDialog}
      {exportDialog}
      <Grid container>
        <Grid item xs={12} md={3}>
          <Grid container>
            <Grid item xs={12}>
              <ViewFieldText
                prompt={translate.t('laType')}
                value={training.trainingGroup.label}
              />
            </Grid>
            <Grid item xs={12}>
              <ViewFieldText
                prompt={translate.t('training_class')}
                value={
                  !training.trainingClass ? '' : training.trainingClass.label
                }
              />
            </Grid>
            <Grid item xs={12}>
              <ViewFieldText
                prompt={translate.t('laProvider')}
                value={training.provider}
              />
            </Grid>
            <Grid item xs={12}>
              <ViewFieldText
                prompt={translate.t('laHours')}
                value={training.hours}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={2}>
          <Grid item xs={12} md={6}>
            <ViewFieldText
              prompt={translate.t('laFee')}
              value={`${training.fee ? training.fee : ''} ${
                !training.currency ? '' : training.currency.label
              }`}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ViewFieldText
              prompt={translate.t('laLastCancellationDate')}
              value={
                training.lastDateOfCancellation
                  ? moment(training.lastDateOfCancellation).format('YYYY-MM-DD')
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ViewFieldText
              prompt={translate.t('lastDateForRegistration')}
              value={
                training.lastDateForRegistration
                  ? moment(training.lastDateForRegistration).format(
                      'YYYY-MM-DD',
                    )
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ViewFieldText
              prompt={translate.t('laWorkContractLocation')}
              value={training.city ? training.city : ''}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ViewFieldText
              prompt={`${translate.t('fEmpOfficeRoom')}/${translate.t(
                'laVenue',
              )}`}
              value={training.room ? training.room : ''}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12} md={6}>
            <ViewFieldText
              prompt={translate.t('laContactPerson')}
              value={training.contactPerson ? training.contactPerson : ''}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ViewFieldText
              prompt={translate.t('laTeacherName')}
              value={training.teacherName ? training.teacherName : ''}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <ViewFieldText
              prompt={translate.t('laStartTime')}
              value={
                training.startTime
                  ? moment(training.startTime).format('HH:mm')
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12}>
            <ViewFieldText
              prompt={translate.t('laEndTime')}
              value={
                training.endTime ? moment(training.endTime).format('HH:mm') : ''
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid container>
            {!canAccessParticipantList(training) ? null : (
              <Grid item xs={12}>
                <ViewFieldElement
                  prompt={translate.t('label_participants')}
                  value={
                    <>
                      <ParticipantsButton
                        trainingId={training.id}
                        onOpenParticipants={() => setOpenParticipantsList(true)}
                      />
                      <Tooltip title={translate.t('label_export_participants')}>
                        <IconButton onClick={() => setOpenExportDialog(true)}>
                          <Icon fontSize="small">cloud_download</Icon>
                        </IconButton>
                      </Tooltip>
                    </>
                  }
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <ViewFieldElement
                prompt={translate.t('label_registration')}
                value={
                  <RegistrationStatus
                    training={training}
                    onChangeApprovalStatus={handleChangeApprovalStatus}
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            gutterBottom
            dangerouslySetInnerHTML={{ __html: training.description }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default TrainingInfoView;

import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import Service from '@/app/utils/service';
import API from '@/app/api/internalAPIs';
import { getFormDialog } from '@/app/components/FormDialog/FormDialog';
import FormFields, { InputType } from '@/app/components/FormFields/FormFields';
import { ReducerState } from '@/app/redux/store';
import { INPUT_TYPE } from '@/app/utils/helper';
import { LanguagesType } from '@/app/redux/languages';
import { withStyles } from '@material-ui/core/styles';
import { WithStyles } from '@material-ui/core';
import enumDetailsDialogStyle from './enumDetailsDialogStyle';
import translate from '@/app/utils/translate';
import { throwError } from '@/app/redux/error';
import { updateDetails, DetailTranslation } from '@/app/redux/enums';
import { IEnumGroup } from '@/app/components/Enum/EnumGroupList/enumGroup';

const supportedLanguags = require('@/assets/languages.json');

interface OwnProps {
  group: IEnumGroup;
  code: string;
  title: string;
  open: boolean;
  onClose: () => void;
}

interface MapStateToProps {
  languages: Array<LanguagesType>;
}

interface MapDispatchToProps {
  throwError: any;
  updateDetails: (details: Array<DetailTranslation>, groupName: string, code: string) => void;
}

type PropsType = OwnProps & MapStateToProps & MapDispatchToProps & WithStyles<typeof enumDetailsDialogStyle>;

type StateType = {
  formData: object; // data for editing
};

const FORM_NAME = 'translateForm';
class EnumDetailsDialog extends React.Component<PropsType> {
  state: StateType = {
    formData: null,
  };

  loadDetails = () => {
    const { group, code } = this.props;
    let formData: object = {};
    this.setState({ formData: null }); // reset the form first
    Service.get(
      API.enum.details(group.name, code),
      (data: any) => {
        if (data instanceof Array) {
          data.forEach((d: any) => {
            formData[d.lang] = d.details;
          });
          this.setState({ formData });
        }
      },
      (error: any) => this.props.throwError(error)
    );
  }

  getTranslateLabel = (code: string) => {
    const result = supportedLanguags.find((l: LanguagesType) => l.code === code);
    if (!result) {
      return '';
    }
    return result.name;
  }

  componentDidUpdate (prevProps: PropsType) {
    if (this.props.open && this.props.open !== prevProps.open) {
      this.loadDetails();
    }
  }

  handleSubmit = (values: any) => {
    const { group, code, onClose } = this.props;
    if (values) {
      const keys: Array<string> = Object.keys(values);
      const details = keys.filter(key => values[key].length > 0).map(key => ({
        lang: key,
        details: values[key]
      }));

      this.props.updateDetails(details, group.name, code);
    }

    onClose();
  }

  validate = (values: any) => {
    const errors: {[k: string]: any} = {};
    const keys = Object.keys(values);
    if (keys) {
      keys.forEach(key => {
        if (values[key] && values[key].length > 3000) {
          errors[key] = `${translate.t('laErrorMaxTextLen1')} 3000`;
        }
      });
    }

    return errors;
  }

  FormDialog = getFormDialog(FORM_NAME, this.validate);

  render() {
    const { title, open, languages, onClose, classes } = this.props;
    const { formData } = this.state;
    const { FormDialog } = this;

    const inputs: InputType[] = languages.map((language: LanguagesType) => (
      {
        type: INPUT_TYPE.TEXT,
        code: language.code,
        name: language.code,
        label: this.getTranslateLabel(language.code),
        multiline: true,
        rows: 1,
        rowsMax: 5,
        order: 1,
        required: false
      }
    ));

    return (
      <FormDialog
        title={title}
        open={open}
        onClose={onClose}
        formData={formData}
        paperWidthMd={classes.paperWidthMd}
        onSubmit={this.handleSubmit}
        keepSubmitEnabled={true}
      >
        <FormFields inputs={inputs} />
      </FormDialog>
    );
  }
}

const mapStateToProps = (state: ReducerState) => {
  const { languages } = state;

  return {
    languages: languages.get('allLanguages')
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  throwError: (err: any) => dispatch(throwError(err)),
  updateDetails: (details: Array<DetailTranslation>, groupName: string, code: string) =>
    dispatch<any>(updateDetails(details, groupName, code)),
});

const enhance = compose<any, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(enumDetailsDialogStyle, {withTheme: true}),
);

export default enhance(EnumDetailsDialog);

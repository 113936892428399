import { process } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { Typography, WithStyles, Menu, MenuItem, Button, IconButton } from '@material-ui/core';
import { ArrowDropDown, CloudDownload } from '@material-ui/icons';
import * as React from 'react';

import Loading from '@/app/components/Loading/Loading';
import ColumnMenu from '@/app/components/Kendo/ColumnMenu';
import translate from '@/app/utils/translate';

import { RawCollaborationTask, RawTaskComment } from '../../collaborationModels';

import { CommentsDialog } from './components/CommentsDialog';
import { CommentsList } from './components/CommentsList';
import { FilterName } from './components/FilterName';
import { SendComment } from './components/SendComment';
import { ReportType } from './tasksListModels';
import { taskListStyles } from './tasksListStyles';
import { useTasksList } from './useTasksList';

export interface StateProps {
    tasks: { [key: number]: RawCollaborationTask };
    loadingTasks: boolean;
    failedToLoadTasks: boolean;
}

export interface DispatchProps {
    fetchMyTasks: (empId: number) => void;
    syncTasks: (tasks: RawCollaborationTask[]) => void;
    syncDeletedTasks: (taskIds: number[]) => void;
    syncCreatedTaskComment: (comment: RawTaskComment) => void;
    syncDeletedTaskComment: (comment: RawTaskComment) => void;
}

export interface TasksListOuterProps {
    openTaskDialog: (task: RawCollaborationTask) => void;
}

export interface TasksListInnerProps extends
    TasksListOuterProps,
    WithStyles<typeof taskListStyles>,
    StateProps,
    DispatchProps {}

export const TasksList: React.FC<TasksListInnerProps> = ({
    classes,
    tasks,
    loadingTasks,
    failedToLoadTasks,
    fetchMyTasks,
    openTaskDialog,
    syncTasks,
    syncDeletedTasks,
    syncCreatedTaskComment,
    syncDeletedTaskComment,
}) => {
    const {
        data,
        menuAnchorEl,
        handleOpenQuickFilterMenu,
        handleCloseQuickFilterMenu,
        excelExportRef,
        handleExportExcel,
        reportType,
        handleChangeReportTypeSettings,
        gridDataState,
        handleGridDataStateChange,
        columns,
        handleColumnsSubmit,
        handleRowClick,
        showCommentsFor,
        handleCloseCommentsDialog,
    } = useTasksList(
        tasks,
        fetchMyTasks,
        openTaskDialog,
        syncTasks,
        syncDeletedTasks,
        syncCreatedTaskComment,
        syncDeletedTaskComment
    );

    if (loadingTasks) {
        return <Loading />;
    }

    if (failedToLoadTasks) {
        return (
            <div className={classes.errorContainer}>
                <Typography variant="h6" color="error">
                    {translate.t('laFailedToLoad')}
                </Typography>
            </div>
        );
    }

    return (
        <>
            <ExcelExport ref={excelExportRef}>
                <Grid
                    className={classes.grid}
                    data={process(data, gridDataState)}
                    onDataStateChange={handleGridDataStateChange}
                    onRowClick={handleRowClick}
                    {...gridDataState}
                    sortable={true}
                    resizable={true}
                    pageable={true}
                    groupable={true}
                >
                    <GridToolbar>
                        <div className={classes.gridToolbarContent}>
                            <div>
                                <Menu
                                    anchorEl={menuAnchorEl}
                                    open={Boolean(menuAnchorEl)}
                                    onClose={handleCloseQuickFilterMenu}
                                >
                                    <MenuItem
                                        onClick={handleChangeReportTypeSettings}
                                        data-report-type={ReportType.BY_ASSIGNEE}
                                    >
                                        <FilterName reportType={ReportType.BY_ASSIGNEE} />
                                    </MenuItem>
                                    <MenuItem
                                        onClick={handleChangeReportTypeSettings}
                                        data-report-type={ReportType.BY_ASSIGNOR}
                                    >
                                        <FilterName reportType={ReportType.BY_ASSIGNOR} />
                                    </MenuItem>
                                    <MenuItem
                                        onClick={handleChangeReportTypeSettings}
                                        data-report-type={ReportType.BY_PERTAINS}
                                    >
                                        <FilterName reportType={ReportType.BY_PERTAINS} />
                                    </MenuItem>
                                </Menu>

                                <Button color="primary" onClick={handleOpenQuickFilterMenu}>
                                    {translate.t('laApplyQuickFilter')}
                                    <ArrowDropDown fontSize="small" />
                                </Button>

                                <Typography inline={true}>
                                    <FilterName reportType={reportType} />
                                </Typography>
                            </div>

                            <div>
                                <IconButton onClick={handleExportExcel}>
                                    <CloudDownload fontSize="small" />
                                </IconButton>
                            </div>
                        </div>
                    </GridToolbar>

                    {
                        columns.map((column) => {
                            return column.show && (
                                <GridColumn
                                    key={column.field}
                                    {...column}
                                    columnMenu={
                                        (columnMenuProps) => (
                                            <ColumnMenu
                                                {...columnMenuProps}
                                                columns={columns}
                                                onColumnsSubmit={handleColumnsSubmit}
                                            />
                                        )
                                    }
                                />
                            );
                        })
                    }
                </Grid>
            </ExcelExport>

            <CommentsDialog
                open={!!showCommentsFor}
                onClose={handleCloseCommentsDialog}
                taskTitle={tasks[showCommentsFor] && tasks[showCommentsFor].taskCore.fTaskTitle}
                content={tasks[showCommentsFor] && <CommentsList taskId={tasks[showCommentsFor].fTaskId} />}
                footer={tasks[showCommentsFor] && <SendComment taskId={tasks[showCommentsFor].fTaskId} />}
            />
        </>
    );
};

import axios, { AxiosInstance } from 'axios';
import { getCurrentLanguage } from '@/app/utils/helper';
import Cookies from 'universal-cookie';

const CONTENT_JSON = 'application/json';
const CONTENT_FORM_DATA = 'multipart/form-data';

class Service {
  service: AxiosInstance;

  constructor() {
    let service: AxiosInstance = axios.create();
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }

  getHeaders(contentType: string = CONTENT_JSON, extraHeaders?: any) {
    let header = {
      Language: getCurrentLanguage(),
    };
    if (contentType) {
      header['Content-Type'] = contentType;
    }
    const cookies = new Cookies();
    const token = cookies.get('SOLAFORCE1');

    // CSRF token
    if (token) {
      header['X-Solaforce-Token'] = token; // https://github.com/Solaforce/ui/wiki/Cross-Site-Request-Forgery
    }

    if (extraHeaders && extraHeaders.Authorization) {
      const { Authorization } = extraHeaders;
      header = Object.assign({}, header, { Authorization });
    }

    return header;
  }

  handleSuccess(response: any) {
    return response;
  }

  handleError = (error: any) => {
    return Promise.reject(error);
  };

  redirectTo = (path: any) => {
    document.location = path;
  };

  get(path: string, callback: any, errorHandler: any, extraHeaders?: any) {
    const responseType =
      extraHeaders && extraHeaders.responseType
        ? extraHeaders.responseType
        : 'json';

    return this.service
      .request({
        headers: this.getHeaders(CONTENT_JSON, extraHeaders),
        method: 'GET',
        url: path,
        responseType,
        // quick links is part of new api and uses lang instead of language
        params:
          !path.includes('langIso=') && !path.includes('lang=')
            ? {
                langIso: getCurrentLanguage(),
              }
            : {},
      })
      .then(({ data, headers }) => callback(data, headers))
      .catch(({ response }) => errorHandler(response));
  }

  patch(path: string, payload: any, callback: any, errorHandler: any) {
    return this.service
      .request({
        headers: this.getHeaders(),
        method: 'PATCH',
        url: path,
        responseType: 'json',
        data: payload,
      })
      .then(({ data, headers }) => callback(data, headers))
      .catch(({ response }) => errorHandler(response));
  }

  post(
    path: string,
    payload: any,
    callback?: any,
    errorHandler?: any,
    extraHeaders?: any,
  ) {
    return this.service
      .request({
        headers: this.getHeaders(CONTENT_JSON, extraHeaders),
        method: 'POST',
        url: path,
        responseType: 'json',
        data: payload,
      })
      .then(({ data, headers }) => {
        if (callback) {
          return callback(data, headers);
        }
      })
      .catch(({ response }) => {
        if (errorHandler) {
          errorHandler(response);
        }
      });
  }

  postFormData(
    path: string,
    payload: FormData,
    callback: any,
    errorHandler: any,
  ) {
    return this.service
      .request({
        headers: this.getHeaders(CONTENT_FORM_DATA),
        method: 'POST',
        url: path,
        responseType: 'json',
        data: payload,
      })
      .then(({ data, headers }) => callback(data, headers))
      .catch(({ response }) => errorHandler(response));
  }

  put(path: string, payload: any, callback: any, errorHandler?: any) {
    return this.service
      .request({
        headers: this.getHeaders(),
        method: 'PUT',
        url: path,
        responseType: 'json',
        data: payload,
      })
      .then(({ data, headers }) => callback(data, headers))
      .catch(({ response }) => errorHandler && errorHandler(response));
  }

  putFormData(
    path: string,
    payload: FormData,
    callback: any,
    errorHandler: any,
  ) {
    return this.service
      .request({
        headers: this.getHeaders(CONTENT_FORM_DATA),
        method: 'PUT',
        url: path,
        responseType: 'json',
        data: payload,
      })
      .then(({ data, headers }) => callback(data, headers))
      .catch(({ response }) => errorHandler(response));
  }

  delete(path: string, payload: any, callback: any, errorHandler: any) {
    return this.service
      .request({
        headers: this.getHeaders(),
        method: 'DELETE',
        url: path,
        responseType: 'json',
        data: payload,
      })
      .then(({ data, headers }) => callback(data, headers))
      .catch(({ response }) => errorHandler(response));
  }

  deleteWithoutContentType(
    path: string,
    payload: any,
    callback: any,
    errorHandler: any,
  ) {
    return this.service
      .request({
        headers: this.getHeaders(null),
        method: 'DELETE',
        url: path,
        responseType: 'json',
        data: payload,
      })
      .then(({ data, headers }) => callback(data, headers))
      .catch(({ response }) => errorHandler(response));
  }
}

export default new Service();

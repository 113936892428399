import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const componentListingStyles: StyleRulesCallback = () => ({
  topCheckbox: {
    marginLeft: '-12px',
  },
  multiline: {
    whiteSpace: 'pre-wrap',
  },
});

export default componentListingStyles;
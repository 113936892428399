import * as React from 'react';
import genericDialogStyle from './genericDialogStyle';
import {
  WithStyles, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, withStyles,
} from '@material-ui/core';
import translate from '@/app/utils/translate';
import { compose } from 'recompose';

interface OwnProps {
  title: string;
  description?: string;
  open: boolean;
  onClose: (event: React.MouseEvent) => void;
  paperWidthMd?: string;
  onSave: (event: React.MouseEvent) => void;
  saveBtnType?: 'button' | 'submit';
  isDisabledSave: boolean;
}

type PropsType = OwnProps & WithStyles<typeof genericDialogStyle>;

const handleEntered = (event: any) => {
  event.removeAttribute('tabindex');
};

const GenericDialog: React.FunctionComponent<PropsType> = (props) => {
  const {
    title,
    description,
    open,
    onClose,
    classes,
    paperWidthMd,
    onSave,
    saveBtnType = 'button',
    isDisabledSave,
    children
  } = props;

  return (
    <Dialog
      open={open}
      onEntered={handleEntered}
      disableBackdropClick
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      className={classes.content}
      maxWidth="md"
      classes={{ paperWidthMd }}
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {description ? <DialogContentText>{description}</DialogContentText> : null}
        {children}
      </DialogContent>
      <DialogActions
        classes={{
          root: classes.dialogActions
        }}
      >
        <Button
          variant="text"
          color="primary"
          component="a"
          onClick={onClose}
        >
          {translate.t('laCancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          component="a"
          type={saveBtnType}
          onClick={onSave}
          disabled={isDisabledSave}
        >
          {translate.t('laSave')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const enhance = compose<any, OwnProps>(
  withStyles(genericDialogStyle, {withTheme: true}),
);

export default enhance(GenericDialog);

import * as qs from 'qs';
import * as moment from 'moment';
import axios from 'axios';
import { getEnums } from '@/old/utils/helper';
import { ParsedQs } from 'qs';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const LANGUAGE_KEY = 'language';
const COMPANY_DEFAULT_LANGUAGE_KEY = 'company_language';
export const DEFAULT_LANG = 'en';

// THIS should be used when a type is optional, not null
// usage -> let user: Optional<User>;
// should be declared in 'global.d.ts' so that there is no import in files when used
export type Optional<T> = T | undefined;

export const getCurrentLanguage = () => {
  return getStored(LANGUAGE_KEY) || DEFAULT_LANG;
};

export const getCurrentLocaleForDateUtils = (locale?: string) => {
  const l = !!locale ? locale : getCurrentLanguage();
  if (l === 'fm') {
    return 'fi';
  }

  return l;
};

export const getCompanyDefaultLanguage = () => {
  return getStored(COMPANY_DEFAULT_LANGUAGE_KEY);
};

export const setCompanyDefaultLanguage = (payload: any) => {
  setStored(COMPANY_DEFAULT_LANGUAGE_KEY, payload);
};

export const getStoredLanguage = () => {
  return getStored(LANGUAGE_KEY);
};

export const setLanguage = (payload: any) => {
  setStored(LANGUAGE_KEY, payload);
};

export const getStored = (key: string) => {
  try {
    const result = localStorage.getItem(key);

    if (!result) {
      return '';
    }

    return JSON.parse(result);
  } catch (e) {
    console.error(e);

    return '';
  }
};

export const setStored = (key: string, payload: any) => {
  localStorage.setItem(key, JSON.stringify(payload));
};

// get date format depends on language selection, currently only one format
export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_TIME_FORMAT = `${DATE_FORMAT}, HH:mm`;
export const DTH_FORMAT = `${DATE_FORMAT} HH:mm`;
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';

const formatDateCommon = (s: string, format: string) => {
  if (!s) {
    return '';
  }
  return moment(s).format(format);
};

// convert date string to formated date string
export const formatDate = (s: string) => formatDateCommon(s, DATE_FORMAT);

// convert date string to formated date string
export const formatDatetime = (s: string) =>
  formatDateCommon(s, DATE_TIME_FORMAT);

const parseDateCommon = (s: string) => {
  if (!s) {
    return null;
  }
  return moment(s).toDate();
};
// convert formated date string into js date
export const parseStrToDate = (s: string) => parseDateCommon(s);

export const parseStrToDatetime = (s: string) => parseDateCommon(s);

export enum INPUT_TYPE {
  TEXT = 'STRING',
  DATE = 'DATE',
  CHECKBOX = 'BOOLEAN',
  NUMBER = 'NUMBER',
  INTEGER = 'INTEGER',
  TEXTAREA = 'TEXT',
  SELECT = 'ENUM',
  MULTISELECT = 'MULTISELECT',
  EMAIL = 'EMAIL',
  PASSWORD = 'PASSWORD',
  TEL = 'TEL',
  RICHTEXT = 'RICHTEXT',
  SWITCH = 'SWITCH',
  HIDDEN = 'HIDDEN',
  RADIO_GROUP = 'RADIO_GROUP', // TODO. document approval + survey questions
}

export const shortTextFromHtml = (html: string, maxLength: number = 100) => {
  let text = html.replace(/<[^>]*>/g, '');
  if (text.length <= maxLength) {
    return text;
  }
  return text.substr(0, maxLength) + '...';
};

export type UrlParam = string | string[] | ParsedQs | ParsedQs[];
export const getUrlParam = (props: any, key: string): UrlParam => {
  return qs.parse(props.location.search, { ignoreQueryPrefix: true })[key];
};

export const HOMEPAGE = '/app';

export const CUSTOM_PREFIX = 'custom__';

/**
 * Build redux form data as json, custom fields comes in extra
 * @param values redux form submit values
 * @returns { formData, customFields }
 */
export const getJsonData = (
  values: any,
): { jsonData: Object; customFields: Object } => {
  const payload = {};
  let customFields = {};
  Object.keys(values).forEach(key => {
    let data = values[key];
    if (data && (data instanceof Date || data._isAMomentObject)) {
      data = moment(data).format('YYYY-MM-DD');
    }
    // react-select will pass ChildInputType object, have to convert the value for select and multiselect
    if (data instanceof Object && !!data.value) {
      data = data.value;
    }
    if (data instanceof Array) {
      let newData: Array<string> = [];
      data.forEach((d: any) => {
        if (d instanceof Object && !!d.value) {
          newData.push(d.value);
        }
      });
      data = newData;
    }
    if (key.indexOf(CUSTOM_PREFIX) === 0) {
      const oldKey = key.substring(CUSTOM_PREFIX.length);
      // backend expect boolean as string
      customFields[oldKey] = typeof data === 'boolean' ? data + '' : data;
    } else {
      payload[key] = data;
    }
  });
  return {
    jsonData: payload,
    customFields,
  };
};

export const trimFileNameToAMaxLength = (
  fileName: string,
  maxLength: number = 80,
) => {
  const extension = getStringAfterChar(fileName, '.');
  return fileName.length > maxLength
    ? `${fileName.substring(0, 72)}.${extension}`
    : fileName;
};

/**
 * Build redux form data, usually with file upload, custom fields comes in extra
 * @param values redux form submit values
 * @returns { formData, customFields }
 */
export const getFormData = (
  values: any,
): { formData: FormData; customFields: Object } => {
  const payload = new FormData();
  let customFields = {};

  Object.keys(values).forEach(key => {
    let data = values[key];
    if (data instanceof Date || (!!data && data._isAMomentObject)) {
      data = moment(data).format('YYYY-MM-DD');
    }

    // react-select will pass ChildInputType object, have to convert the value for select and multiselect
    if (data instanceof Object && !!data && !!data.value) {
      data = data.value;
    }

    // https://github.com/redux-form/redux-form/issues/3169
    // Because of the issues of redux-form when using FileList
    // on Firefox, added the support of Files array in order
    // to support the upload field adaptor fix.
    if (data instanceof Array && data.every(v => v instanceof File)) {
      data.forEach((file: File) => {
        payload.append(key, file, trimFileNameToAMaxLength(file.name));
      });

      return;
    }

    if (data instanceof Array) {
      let newData: Array<string> = [];
      data.forEach((d: any) => {
        if (d instanceof Object && !!d.value) {
          newData.push(d.value);
        }
      });
      data = newData;
    }

    if (data instanceof FileList) {
      for (var i = 0; i < data.length; i++) {
        payload.append(key, data[i], data[i]['uploadName']); // tslint:disable-line
      }
    } else if (key.indexOf(CUSTOM_PREFIX) === 0) {
      const oldKey = key.substring(CUSTOM_PREFIX.length);
      // backend expect boolean as string
      customFields[oldKey] = typeof data === 'boolean' ? data + '' : data;
    } else {
      payload.append(key, data);
    }
  });

  return {
    formData: payload,
    customFields,
  };
};

const supportedLanguags = require('@/assets/languages.json');
export const getLanguageLabel = (langCode: string): string => {
  if (langCode) {
    const result = supportedLanguags.find(
      (s: { code: string; name: string }) => s.code === langCode,
    );
    if (result) {
      return result.name;
    }
  }
  return '';
};

const companyDefaultLanguageLabel = (): string => {
  const companyDefaultLanguage = getCompanyDefaultLanguage();
  return getLanguageLabel(companyDefaultLanguage);
};

export const getCurrentLanguageLabel = (): string => {
  const langCode = getCurrentLanguage();
  return getLanguageLabel(langCode);
};

export const COMPANY_DEFAULT_LANGUAGE_LABEL = companyDefaultLanguageLabel();

export const getDeleteUrl = (api: string, params: Array<string | number>) => {
  const idsParam = params.map((s: string | number) => `ids=${s}`).join('&');
  return `${api}?${idsParam}`;
};

export const formatStartAndEndDate = (start: string, end: string) => {
  return `${formatDate(start)}${end ? ' - ' + formatDate(end) : ''}`;
};

/**
 * Coverts a string to a camelCase string and returns it
 *
 *   > camelize('background-color')
 *   < "backgroundColor"
 *
 * @param {string} str
 * @return {string}
 */

export const camelize = (str: string) => {
  if (!str) {
    return '';
  }

  const camelCaseString = str
    .toLowerCase()
    .replace(/[-_\s]+(.)?/g, (_match, chr) => {
      return chr ? chr.toUpperCase() : '';
    });

  return camelCaseString;
};

/**
 * Coverts a string/number to FI number format (ex: 1 234,56) and returns it
 *
 *   > formatNumber('4200.24')
 *   < "4 200,24"
 *
 * @param {any} vNumber
 * @return {string}
 */
export const formatNumber = (vNumber: any) => {
  if (typeof vNumber === 'undefined' || vNumber === 'NaN') {
    return '';
  }

  return parseFloat(vNumber)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    .replace('.', ',');
};

/**
 * Generates an unique 10 characters length string and returns it
 *
 *   > generateUniqueId()
 *   < "xaxiswkvlz"
 *
 * @return {string}
 */
export const generateUniqueId = () =>
  `${Math.random()
    .toString(36)
    .substr(2, 12)}`;

export const generateTempNumId = () =>
  parseInt(`${Math.random() * 1000000}`, 10);

/**
 * Returns the text which is after a specified character
 *
 *   > getStringAfterChar('https://github.com/string-to-be-returned.pdf', '/');
 *   < "string-to-be-returned.pdf"
 *
 * @param {string} str
 * @param {string} char
 * @return {string}
 */
export const getStringAfterChar = (str: string, char: string) =>
  str.split(char).pop();

/**
 * Returns the text which is before a specified character(first instance of the character)
 *
 *   > getStringBeforeChar('https://github.com/string-to-be-returned.pdf', '/');
 *   < "https:"
 *
 * @param {string} str
 * @param {string} char
 * @return {string}
 */
export const getStringBeforeChar = (str: string, char: string) =>
  str.substring(0, str.indexOf(char));

/**
 * Returns the Capitalized text
 *
 *   > capitalizeFirstLetter('some text');
 *   < "Some text"
 *
 * @param {string} str
 * @return {string}
 */
export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
};

/**
 * Returns true if the difference between the given time/date and current date is lower than the buffer(minutes)
 *
 *   > isExpired('2000-02-21', 100);
 *   < true
 *
 * @param {string} expireTime
 * @param {number} buffer
 * @return {boolean}
 */
export const isExpired = (expireTime: string, buffer: number = 15) =>
  moment(expireTime).diff(moment(), 'minutes') < buffer;

/**
 * Returns the percentage in string format given a value and an amount from which that value is
 *
 *   > getPercentage(20, 100);
 *   < '20%'
 *
 * @param {number} expireTime
 * @param {number} buffer
 * @return {string}
 */
export const getPercentage = (value: number, outOf: number) =>
  `${(Math.round((value * 100) / outOf) || 0).toString()}%`;

/**
 * Returns the given array with unique values inside (removes duplicates)
 *
 *   > getUniqValues([[1, 2, 3], [2, 3, 4], [1, 2, 3]]);
 *   < [[1, 2, 3], [2, 3, 4]]
 *
 * @param {array} arr
 * @return {array}
 */
export const getUniqValues = (arr: any[]) => {
  const seen = {};

  return arr.filter(item => {
    const k = JSON.stringify(item);

    return seen.hasOwnProperty(k) ? false : (seen[k] = true);
  });
};

/**
 * Returns the difference between 2 given arrays
 *
 *   > arrayDifference([1, 2, 3], [2, 3, 4, 5]);
 *   < [1, 4, 5]
 *
 * @param {array} bigStack
 * @param {array} smallStack
 * @return {array}
 */
export const arraysDifference = (
  bigStack: any[],
  smallStack: any[],
): Array<any> => bigStack.filter(i => smallStack.indexOf(i) < 0);

export const acceptedDocuments = [
  'application/epub+zip',
  'application/json',
  'application/ld+json',
  'application/msword',
  'application/pdf',
  'application/rtf',
  'application/zip',
  'application/vnd.amazon.ebook',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.mozilla.xul+xml',
  'application/vnd.ms-fontobject',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  'application/vnd.visio',
  'application/x-7z-compressed',
  'application/x-abiword',
  'application/x-bzip',
  'application/x-bzip2',
  'application/x-freearc',
  'application/x-rar-compressed',
  'application/x-tar',
  'application/xhtml+xml',
  'application/xml',
  'audio/*',
  'font/*',
  'image/*',
  'text/*',
  'video/*',
];

export const formatDurationToHMM = (
  value: moment.Duration | string,
): string => {
  const duration = moment.duration(value);

  const sign = duration.asMilliseconds() < 0 ? '-' : '';
  const formattedHours = Math.floor(duration.abs().asHours());
  const formattedMinutes = String(duration.abs().minutes()).padStart(2, '0');

  return `${sign}${formattedHours}:${formattedMinutes}`;
};

export const parseHMMToDuration = (
  value: string,
): moment.Duration | undefined => {
  if (/^(-?)(0+)?[0-9]+:([0-9]|[0-5][0-9])$/.test(value)) {
    const [hours, minutes] = value.replace('-', '').split(':');
    return moment.duration(
      (value[0] === '-' ? -1 : 1) * (60 * +hours + +minutes),
      'minutes',
    );
  }

  if (/^(-?)(0+)?[0-9]+$/.test(value)) {
    return moment.duration(+value, 'hours');
  }

  return undefined;
};

export const parseHMMToDurationISOString = (
  value: string,
): string | undefined => {
  const duration = parseHMMToDuration(value);
  return duration && duration.toISOString();
};

export const printElements = (function() {
  'use strict';

  let hideFromPrintClass = 'pe-no-print';
  let preservePrintClass = 'pe-preserve-print';
  let preserveAncestorClass = 'pe-preserve-ancestor';
  let bodyElementName = 'BODY';

  let _hide = function(element: Element) {
    if (!element.classList.contains(preservePrintClass)) {
      element.classList.add(hideFromPrintClass);
    }
  };

  let _preserve = function(element: Element, isStartingElement: boolean) {
    element.classList.remove(hideFromPrintClass);
    element.classList.add(preservePrintClass);
    if (!isStartingElement) {
      element.classList.add(preserveAncestorClass);
    }
  };

  let _clean = function(element: Element) {
    element.classList.remove(hideFromPrintClass);
    element.classList.remove(preservePrintClass);
    element.classList.remove(preserveAncestorClass);
  };

  let _walkSiblings = function(
    element: Element,
    callback: (e: Element | null) => void,
  ) {
    let sibling = element.previousElementSibling;
    while (sibling) {
      callback(sibling);
      sibling = sibling.previousElementSibling;
    }
    sibling = element.nextElementSibling;
    while (sibling) {
      callback(sibling);
      sibling = sibling.nextElementSibling;
    }
  };

  let _attachPrintClasses = function(
    element: Element,
    isStartingElement: boolean,
  ) {
    _preserve(element, isStartingElement);
    _walkSiblings(element, _hide);
  };

  let _cleanup = function(element: Element, _isStartingElement: boolean) {
    _clean(element);
    _walkSiblings(element, _clean);
  };

  let _walkTree = function(
    element: Element,
    callback: (e: Element | null, b: boolean) => void,
  ) {
    let currentElement = element;
    callback(currentElement, true);
    currentElement = currentElement.parentElement;
    while (currentElement && currentElement.nodeName !== bodyElementName) {
      callback(currentElement, false);
      currentElement = currentElement.parentElement;
    }
  };

  let textareaElementsInitialSizes: Array<{
    element: HTMLTextAreaElement;
    height?: string;
  }> = [];
  let _updateTextareaElementsHeightToFitContent = function(element: Element) {
    let textareaElements = element.querySelectorAll('textarea');
    for (let i = 0; i < textareaElements.length; i++) {
      textareaElementsInitialSizes.push({
        element: textareaElements[i],
        height: textareaElements[i].style.height,
      });

      textareaElements[i].style.height = '';
      textareaElements[i].style.height =
        textareaElements[i].scrollHeight + 'px';
    }
  };

  let _cleanupTextareaElementsHeight = function() {
    for (let i = 0; i < textareaElementsInitialSizes.length; i++) {
      textareaElementsInitialSizes[i].element.style.height =
        textareaElementsInitialSizes[i].height;
    }

    textareaElementsInitialSizes = [];
  };

  let listOfUnexpandedElements: HTMLElement[] = [];
  let _updateUnexpandedElementsHeight = function(element: Element) {
    const unexpandedElements = element.querySelectorAll<HTMLElement>(
      '[role="button"][aria-expanded="false"]',
    );
    for (let i = 0; i < unexpandedElements.length; i++) {
      listOfUnexpandedElements.push(unexpandedElements[i]);
      (unexpandedElements[i].nextElementSibling as HTMLElement).style.height =
        'auto';
    }
  };

  let _cleanupUnexpandedElementsHeight = function() {
    for (let i = 0; i < listOfUnexpandedElements.length; i++) {
      (listOfUnexpandedElements[i]
        .nextElementSibling as HTMLElement).style.height = '0';
    }

    listOfUnexpandedElements = [];
  };

  let _print = function(elements: Element[]) {
    for (let i = 0; i < elements.length; i++) {
      _updateTextareaElementsHeightToFitContent(elements[i]);
      _updateUnexpandedElementsHeight(elements[i]);
      _walkTree(elements[i], _attachPrintClasses);
    }

    window.print();

    for (let i = 0; i < elements.length; i++) {
      _walkTree(elements[i], _cleanup);
    }

    _cleanupTextareaElementsHeight();
    _cleanupUnexpandedElementsHeight();
  };

  return {
    print: _print,
  };
})();

export const saveElementsAsPDF = (function() {
  'use strict';

  let hideFromPrintClass = 'pe-no-print';
  let preservePrintClass = 'pe-preserve-print';
  let preserveAncestorClass = 'pe-preserve-ancestor';
  let bodyElementName = 'BODY';

  let _hide = function(element: Element) {
    if (!element.classList.contains(preservePrintClass)) {
      element.classList.add(hideFromPrintClass);
    }
  };

  let _preserve = function(element: Element, isStartingElement: boolean) {
    element.classList.remove(hideFromPrintClass);
    element.classList.add(preservePrintClass);
    if (!isStartingElement) {
      element.classList.add(preserveAncestorClass);
    }
  };

  let _clean = function(element: Element) {
    element.classList.remove(hideFromPrintClass);
    element.classList.remove(preservePrintClass);
    element.classList.remove(preserveAncestorClass);
  };

  let _walkSiblings = function(
    element: Element,
    callback: (e: Element | null) => void,
  ) {
    let sibling = element.previousElementSibling;
    while (sibling) {
      callback(sibling);
      sibling = sibling.previousElementSibling;
    }
    sibling = element.nextElementSibling;
    while (sibling) {
      callback(sibling);
      sibling = sibling.nextElementSibling;
    }
  };

  let _attachPrintClasses = function(
    element: Element,
    isStartingElement: boolean,
  ) {
    _preserve(element, isStartingElement);
    _walkSiblings(element, _hide);
  };

  let _cleanup = function(element: Element, _isStartingElement: boolean) {
    _clean(element);
    _walkSiblings(element, _clean);
  };

  let _walkTree = function(
    element: Element,
    callback: (e: Element | null, b: boolean) => void,
  ) {
    let currentElement = element;
    callback(currentElement, true);
    currentElement = currentElement.parentElement;
    while (currentElement && currentElement.nodeName !== bodyElementName) {
      callback(currentElement, false);
      currentElement = currentElement.parentElement;
    }
  };

  let textareaElementsInitialSizes: Array<{
    element: HTMLTextAreaElement;
    height?: string;
  }> = [];
  let _updateTextareaElementsHeightToFitContent = function(element: Element) {
    let textareaElements = element.querySelectorAll('textarea');
    for (let i = 0; i < textareaElements.length; i++) {
      textareaElementsInitialSizes.push({
        element: textareaElements[i],
        height: textareaElements[i].style.height,
      });

      textareaElements[i].style.height = '';
      textareaElements[i].style.height =
        textareaElements[i].scrollHeight + 'px';
    }
  };

  let _cleanupTextareaElementsHeight = function() {
    for (let i = 0; i < textareaElementsInitialSizes.length; i++) {
      textareaElementsInitialSizes[i].element.style.height =
        textareaElementsInitialSizes[i].height;
    }

    textareaElementsInitialSizes = [];
  };

  let listOfUnexpandedElements: HTMLElement[] = [];
  let _updateUnexpandedElementsHeight = function(element: Element) {
    const unexpandedElements = element.querySelectorAll<HTMLElement>(
      '[role="button"][aria-expanded="false"]',
    );
    for (let i = 0; i < unexpandedElements.length; i++) {
      listOfUnexpandedElements.push(unexpandedElements[i]);
      (unexpandedElements[i].nextElementSibling as HTMLElement).style.height =
        'auto';
    }
  };

  let _cleanupUnexpandedElementsHeight = function() {
    for (let i = 0; i < listOfUnexpandedElements.length; i++) {
      (listOfUnexpandedElements[i]
        .nextElementSibling as HTMLElement).style.height = '0';
    }

    listOfUnexpandedElements = [];
  };

  let _saveAsPDF = async function(elements: HTMLElement[], docName: string) {
    const pdf = new jsPDF('p', 'mm');

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];

      _updateTextareaElementsHeightToFitContent(element);
      _updateUnexpandedElementsHeight(element);
      _walkTree(element, _attachPrintClasses);

      const canvas = await html2canvas(element, {
        onclone: (clonedDocument) => {
          Array.from(clonedDocument.querySelectorAll('div > textarea:last-of-type')).forEach((textArea: HTMLTextAreaElement) => {
                const div = clonedDocument.createElement('div');
                div.innerText = textArea.value;
                div.className = textArea.className;
                div.style.overflowWrap = 'anywhere';
                div.style.height = textArea.style.height;
                div.style.transitionProperty = textArea.style.transitionProperty;
                div.style.marginBottom = '10px';
                const firstParent = textArea.parentElement;
                const secondParent = firstParent.parentElement;
                secondParent.style.height = textArea.style.height;
                textArea.replaceWith(div);
          });
        },
      });

      var imgData = canvas.toDataURL('image/png');
      var imgWidth = 210; // A4 page width
      var pageHeight = 295; // A4 page height
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var position = 10; // Give some top padding to the first page

      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight + 10; // Apply top padding for subsequent pages
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      _walkTree(element, _cleanup);
    }

    pdf.save(`${docName}.pdf`);

    _cleanupTextareaElementsHeight();
    _cleanupUnexpandedElementsHeight();
  };

  return {
    saveAsPDF: _saveAsPDF,
  };
})();

export const isNumber = (s: string): boolean => {
  return /^\s*(\+|-)?((\d+(\.\d+)?)|(\.\d+))\s*$/.test(s);
};

export const isNumberWithBothCommaAndDot = (s: string): boolean => {
  return /^\s*(\+|-)?((\d+((\.|,)\d+)?)|((\.|,)\d+))\s*$/.test(s);
};

export const sortArr = (
  arr: any[],
  key: string,
  order: 'asc' | 'desc' = 'asc',
) => {
  return [...arr].sort((a, b) => {
    if (order === 'desc') {
      return a[key] > b[key] ? -1 : a[key] < b[key] ? 1 : 0;
    }

    return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
  });
};

export const basicFileDownload = (
  urlForApiCall: string,
  attachmentName: string,
) => {
  axios({
    url: urlForApiCall,
    responseType: 'blob',
  }).then(response => {
    // IE support
    if (window.navigator.msSaveOrOpenBlob) {
      const blob: object = new Blob([response.data], {
        type: 'text/calendar;charset=utf-8',
      });
      window.navigator.msSaveOrOpenBlob(blob, attachmentName);
      return;
    }
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', attachmentName);
    link.click();
  });
};

export const validText = (text: string) => {
  {
    if (text === '' || text.length < 1) {
      return false;
    } else {
      return true;
    }
  }
};

export const isValidEmail = (email: string) => {
  const regEx = /^(?:[a-z0-9!#$%&amp;‘*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;‘*+/=?^_`{|}~-]+)*|“(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*“)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

  return !(
    // tests length of domain name included in the email address
    (
      (email &&
        email
          .split('@')
          .slice(-1)[0]
          .split('.')[0].length < 2) ||
      !regEx.test(email)
    )
  );
};

export const getActiveEnumNameByCode = (
  group: string,
  code: string,
): string => {
  const item = getEnums(group).find(v => {
    return v.isActive && v.code === code;
  });

  return (item && item.name) || '';
};

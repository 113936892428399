import * as React from 'react';
import { Avatar, Button } from '@material-ui/core';
import { GridCellProps } from '@progress/kendo-react-grid';
import { getEmpPicId, showEmployee } from '@/old/utils/helper';
import { withStyles, WithStyles } from '@material-ui/core';
import API from '@/app/api/internalAPIs';
import styles from '../styles';
import translate from '@/app/utils/translate';

type Props = WithStyles<typeof styles> & GridCellProps;

const AvatarCell = ({ dataItem, rowType, classes, field }: Props) => {
  if (
    rowType === 'groupHeader' ||
    rowType === 'groupFooter' ||
    rowType !== 'data'
  ) {
    return null;
  }

  const getEmployeeImageUrl = (employeeId: number): string => {
    return API.employeeImageUrl(getEmpPicId(employeeId));
  };

  const visitEmpProfile = (id: number) => {
    showEmployee(id);
  };

  const isUndefined = (value: any) => typeof value === 'undefined';
  const isDefinedFalse = (value: any) => !isUndefined(value) && !value;

  return (
    <td style={{ verticalAlign: 'top' }}>
      {isDefinedFalse(dataItem.fEmpHasAccount) && (
        <span className={classes.externalSign}>{translate.t('ext')}</span>
      )}
      <Button>
        <Avatar
          src={getEmployeeImageUrl(dataItem[field])}
          onClick={() => visitEmpProfile(dataItem[field])}
        />
      </Button>
    </td>
  );
};

export default withStyles(styles)(AvatarCell);

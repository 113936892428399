import * as React from 'react';
import { Checkbox, FormControl, FormControlLabel } from '@material-ui/core';

const RenderCheckboxField = (props: any) => {
  const {
    input,
    label,
    helpText,
    ...custom
  } = props;

  return (
    <FormControl
      fullWidth
      margin="normal"
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={input.value ? true : false}
            onChange={input.onChange}
            {...custom}
          />
        }
        label={label}
      />
      {helpText}
    </FormControl>
  );
};

export default RenderCheckboxField;

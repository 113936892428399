import translate from '@/app/utils/translate';

export const gridColumns = () => [
  {
    field: 'unit',
    show: true,
    title: translate.t('laUnit'),
  },
  {
    field: 'positionId',
    show: true,
    title: translate.t('laPositionID'),
  },
  {
    field: 'positionName',
    show: true,
    title: translate.t('laPositionName'),
  },
  {
    field: 'startDate',
    show: true,
    title: translate.t('laStartDate'),
  },
  {
    field: 'endDate',
    show: true,
    title: translate.t('laEndDate'),
  },
  {
    field: 'positionPricing',
    show: true,
    title: translate.t('laPositionPricingID'),
  },
  {
    field: 'assignee',
    show: true,
    title: translate.t('laAssignee'),
  },
  {
    field: 'empNumber',
    show: true,
    title: translate.t('laEmpNumber'),
  },
  {
    field: 'assigneePercentage',
    show: true,
    title: '%',
  },
];

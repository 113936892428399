import { Link, Typography, withStyles } from '@material-ui/core';
import * as React from 'react';
import { compose } from 'recompose';
import {
  TemplateBaseComponentType,
  TemplateComponentTypeKey,
  TemplateStaticComponentProps,
} from '@/app/components/TemplateComponents/form/InputComponents';
import linkComponentStyles from '@/app/components/TemplateComponents/form/InputComponents/LinkComponent/linkComponentStyles';

export type LinkComponentProps = TemplateStaticComponentProps & {
  component: TemplateBaseComponentType & {
    type: TemplateComponentTypeKey.LINK;
    defaultValue: string;
  };
};

const LinkComponent = (props: LinkComponentProps) => {
  const { component, classes } = props;
  return (
    component.type === TemplateComponentTypeKey.LINK && (
      <Typography variant="subtitle2" className={classes.typography}>
        <Link href={component.defaultValue} target="_blank" rel="noopener">
          {component.name}
        </Link>
      </Typography>
    )
  );
};

const enhance = compose<any, any>(withStyles(linkComponentStyles));

export default enhance(LinkComponent);

import {
  CompanyTraining,
  CompanyTrainingParticipantRegistration,
  CompanyTrainingParticipantType,
  COMPLETION_STATUS,
  EmployeeTraining,
  OptionType,
} from '../../types';
import moment from 'moment';
import {
  getTrainingName,
  getTrainingGroupName,
  getTrainingClassName,
  getEmployee,
  getUnitName,
  getRoleName,
  getCountryName,
  getLanguageName,
  getApprovalStatusName,
  getCompletionStatusName,
} from '../../helpers';

/**
 * Company trainings
 */

export const mapCompanyTrainingForUI = (item: any): CompanyTraining => {
  const { classification, details } = item;
  return {
    id: item.id,
    language: { value: item.language, label: getLanguageName(item.language) },
    trainingGroup: !classification.group
      ? null
      : {
          value: classification.group,
          label: getTrainingGroupName(classification.group),
        },
    trainingClass: !classification.class
      ? null
      : {
          value: classification.class,
          label: getTrainingClassName(classification.class),
        },
    // NOTICE! name in UI structure is trainingType in BE
    name: {
      value: classification.type,
      label: getTrainingName(classification.type, classification.group),
    },
    startDate: moment(item.startDate),
    endDate: !item.endDate ? null : moment(item.endDate),
    startTime: !item.trainingTime.startTime
      ? null
      : moment(
          `${moment(item.startDate).format('YYYY-MM-DD')} ${
            item.trainingTime.startTime
          }`,
          'YYYY-MM-DD HH:mm',
        ),
    endTime:
      !item.trainingTime.endTime || !item.endDate
        ? null
        : moment(
            `${moment(item.endDate).format('YYYY-MM-DD')} ${
              item.trainingTime.endTime
            }`,
            'YYYY-MM-DD HH:mm',
          ),
    timeZone: !item.trainingTime.timezone
      ? null
      : {
          value: item.trainingTime.timezone,
          label: `${item.trainingTime.timezone} (GMT ${moment
            .tz(item.trainingTime.timezone)
            .format('Z')})`,
        },
    hours: item.hours,
    provider: item.provider,
    description: item.description,
    fee: details.fee,
    currency: !details.currency
      ? null
      : {
          value: details.currency,
          label: details.currency,
        },
    city: details.city,
    room: details.room,
    contactPerson: details.contactPerson,
    teacherName: details.teacherName,
    lastDateOfCancellation: !details.lastDateOfCancellation
      ? null
      : moment(details.lastDateOfCancellation),
    lastDateForRegistration: !details.lastDateForRegistration
      ? null
      : moment(details.lastDateForRegistration),
    responsiblePersons: (item.responsiblePersons || []).reduce(
      (acc: OptionType[], empId: number) => {
        const emp = getEmployee(empId);

        if (!emp) {
          return acc;
        }

        return [
          ...acc,
          {
            value: empId,
            label: `${emp.firstName} ${emp.lastName} (${emp.position}) - ${emp.code}`,
          },
        ];
      },
      [],
    ),
    unitAudience:
      !item.audience.unitAudience || item.audience.unitAudience.length === 0
        ? null
        : item.audience.unitAudience.map((unitId: number) => ({
            value: unitId,
            label: getUnitName(unitId),
          })),
    roleAudience:
      !item.audience.roleAudience || item.audience.roleAudience.length === 0
        ? null
        : item.audience.roleAudience.map((role: string) => ({
            value: role,
            label: getRoleName(role),
          })),
    countryAudience:
      !item.audience.countryAudience ||
      item.audience.countryAudience.length === 0
        ? null
        : item.audience.countryAudience.map((countryCode: string) => ({
            value: countryCode,
            label: getCountryName(countryCode),
          })),
    seats: isNaN(item.seats) ? null : item.seats,
    allowWaitingSeats: item.allowWaitingSeats || false,
    expires: !item.expires ? null : moment(item.expires),
    useOnlineRegistration: item.useOnlineRegistration || false,
    requireManagerApproval: item.requireManagerApproval || false,
    isPublic: item.isPublic || false,
    includeInTrainingCompensation: item.includeInTrainingCompensation || false,
    active: item.active || false,
    summary: {
      ...item.summary,
      ...item.participantsSummaries,
      seats: isNaN(item.seats) ? null : item.seats,
      allowWaitingSeats: item.allowWaitingSeats || false,
    },
  };
};

export const mapCompanyTrainingForPublicUI = (item: any): CompanyTraining => {
  const { classification, details } = item;

  return {
    ...item,
    trainingGroup: classification.group,
    trainingClass: classification.class,
    trainingType: classification.type,
    fee: details.fee,
    currency: !details.currency
    ? null
    : details.currency,
    city: details.city,
    room: details.room,
    contactPerson: details.contactPerson,
    teacherName: details.teacherName,
    lastDateOfCancellation: !details.lastDateOfCancellation ? null : moment(details.lastDateOfCancellation).format('YYYY-MM-DD'),
    lastDateForRegistration: !details.lastDateForRegistration ? null : moment(details.lastDateForRegistration).format('YYYY-MM-DD'),
  };
};

export const mapCompanyTrainingForBE = (
  item: CompanyTraining,
  _isAdd: boolean = false,
): any => {
  return {
    id: !item.id ? null : item.id,
    language: item.language.value,
    classification: {
      group: item.trainingGroup.value,
      class: !item.trainingClass ? null : item.trainingClass.value,
      // NOTICE! name in UI structure is trainingType in BE
      type: item.name.value,
    },
    startDate: item.startDate.format('YYYY-MM-DD'),
    endDate:
      !item.endDate || !item.endDate.isValid()
        ? null
        : item.endDate.format('YYYY-MM-DD'),
    trainingTime: {
      startTime: !item.startTime
        ? null
        : moment(item.startTime).format('HH:mm'),
      endTime: !item.endTime ? null : moment(item.endTime).format('HH:mm'),
      timezone: !item.timeZone ? null : item.timeZone.value,
    },
    hours: item.hours,
    provider: item.provider,
    description: item.description,
    responsiblePersons: !item.responsiblePersons || item.responsiblePersons.length === 0
      ? null
      : item.responsiblePersons.map((p: any) => p.value),
    audience: {
      unitAudience: !item.unitAudience || item.unitAudience.length === 0
        ? null
        : item.unitAudience.map((u: any) => u.value),
      roleAudience: !item.roleAudience || item.roleAudience.length === 0
        ? null
        : item.roleAudience.map((r: any) => r.value),
      countryAudience: !item.countryAudience || item.countryAudience.length === 0
        ? null
        : item.countryAudience.map((c: any) => c.value),
    },
    seats: !item.seats || item.seats === 0 ? null : item.seats,
    allowWaitingSeats: item.allowWaitingSeats || false,
    expires:
      !item.expires || !item.expires.isValid()
        ? null
        : item.expires.format('YYYY-MM-DD'),
    useOnlineRegistration: item.useOnlineRegistration || false,
    requireManagerApproval: item.requireManagerApproval || false,
    isPublic: item.isPublic || false,
    includeInTrainingCompensation: item.includeInTrainingCompensation || false,
    active: item.active || false,
    details: {
      fee: item.fee,
      currency: !item.currency ? null : item.currency.value,
      city: item.city,
      room: item.room,
      contactPerson: item.contactPerson,
      teacherName: item.teacherName,
      lastDateOfCancellation: !item.lastDateOfCancellation ? null : item.lastDateOfCancellation.format('YYYY-MM-DD'),
      lastDateForRegistration: !item.lastDateForRegistration ? null : item.lastDateForRegistration.format('YYYY-MM-DD'),
    }
  };
};

/**
 * Participants
 */

export const mapParticipantRegistrationForUI = (
  item: any,
): CompanyTrainingParticipantRegistration => {
  return {
    id: item.id,
    trainingId: item.trainingId,
    participant:
      item.participant.type === CompanyTrainingParticipantType.INTERNAL
        ? {
            id: item.participant.id,
            employeeId: item.participant.employeeId,
            updateTrainingHistory:
              item.participant.updateTrainingHistory || false,
            includeInTrainingCompensation:
              item.participant.includeInTrainingCompensation || false,
            employeeTrainingId: item.participant.employeeTrainingId,
            lastEmailSubject: item.participant.lastEmailSubject || '',
            type: CompanyTrainingParticipantType.INTERNAL,
            costCenter: item.participant.costCenter,
          }
        : {
            id: item.participant.id,
            email: item.participant.email,
            firstName: item.participant.firstName,
            lastName: item.participant.lastName,
            givenNames: item.participant.givenNames,
            phone: item.participant.phone,
            lastEmailSubject: item.participant.lastEmailSubject || '',
            type: CompanyTrainingParticipantType.EXTERNAL,
          },
    seated: item.seated || false,
    seatStatus: item.seatStatus,
    position: item.position,
    approvalStatus: !item.approvalStatus
      ? null
      : {
          status: {
            value: item.approvalStatus,
            label: getApprovalStatusName(item.approvalStatus),
          },
          statusBy: null,
          statusOn: null,
        },
    hours: item.hours || null,
    completionStatus: !item.completionStatus
      ? {
          value: COMPLETION_STATUS.DID_NOT_COMPLETE,
          label: getCompletionStatusName(COMPLETION_STATUS.DID_NOT_COMPLETE),
        }
      : {
          value: item.completionStatus,
          label: getCompletionStatusName(item.completionStatus),
        },
    expires: !item.expires ? null : moment(item.expires),
    message: item.message,
    registeredTime: moment(item.registeredTime),
  };
};

export const mapParticipantRegistrationForBE = (
  item: CompanyTrainingParticipantRegistration,
): any => {
  return {
    id: item.id,
    trainingId: item.trainingId,
    participant: item.participant,
    seated: item.seated,
    seatStatus: item.seatStatus,
    position: item.position,
    approvalStatus: item.approvalStatus.status.value,
    hours: item.hours || null,
    completionStatus: item.completionStatus.value,
    expires: !item.expires ? null : item.expires.format('YYYY-MM-DD'),
    message: item.message,
  };
};

const mapEmployeeInternalTrainingHistoryForUI = (
  item: any,
): EmployeeTraining => {
  return {
    id: item.fEduInternalTrainingId,
    trainingGroup: {
      value: 'INTERNAL_TRAINING_TYPE',
      label: getTrainingGroupName('INTERNAL_TRAINING_TYPE'),
    },
    employeeId: item.fEduInternalTrainingEmpId,
    name: {
      value: item.fEduInternalTrainingType,
      label: getTrainingName(
        item.fEduInternalTrainingType,
        'INTERNAL_TRAINING_TYPE',
      ),
    },
    provider: item.fEduInternalTrainingProvider,
    trainingClass: !item.fEduInternalTrainingClassFK
      ? null
      : {
          value: item.fEduInternalTrainingClassFK,
          label: getTrainingClassName(item.fEduInternalTrainingClassFK),
        },
    startDate: moment(item.fEduInternalTrainingStartDate, 'YYYY-MM-DD'),
    endDate: !item.fEduInternalTrainingEndDate
      ? null
      : moment(item.fEduInternalTrainingEndDate, 'YYYY-MM-DD'),
    hours: item.fEduInternalTrainingHours,
    info: item.fEduInternalTrainingInfo,
    attachmentId: item.fEduInternalTrainingAttachmentId,
    attachmentName: item.fEduInternalTrainingAttachmentName,
    expires: !item.fEduInternalTrainingExpirationDate
      ? null
      : moment(item.fEduInternalTrainingExpirationDate, 'YYYY-MM-DD'),
    includeInTrainingCompensation: !!item.fEduInternalTrainingIncludeInTrainingCompensation,
    companyTrainingFK: !item.fEduCompanyTrainingFK ? null : item.fEduCompanyTrainingFK,
  };
};

const mapEmployeeExternalTrainingHistoryForUI = (
  item: any,
): EmployeeTraining => {
  return {
    id: item.fEduExternalTrainingId,
    trainingGroup: {
      value: 'EXTERNAL_TRAINING_TYPE',
      label: getTrainingGroupName('EXTERNAL_TRAINING_TYPE'),
    },
    employeeId: item.fEduExternalTrainingEmpId,
    name: {
      value: item.fEduExternalTrainingType,
      label: getTrainingName(
        item.fEduExternalTrainingType,
        'EXTERNAL_TRAINING_TYPE',
      ),
    },
    provider: item.fEduExternalTrainingProvider,
    trainingClass: !item.fEduExternalTrainingClassFK
      ? null
      : {
          value: item.fEduExternalTrainingClassFK,
          label: getTrainingClassName(item.fEduExternalTrainingClassFK),
        },
    startDate: moment(item.fEduExternalTrainingStartDate, 'YYYY-MM-DD'),
    endDate: !item.fEduExternalTrainingEndDate
      ? null
      : moment(item.fEduExternalTrainingEndDate, 'YYYY-MM-DD'),
    hours: item.fEduExternalTrainingHours,
    info: item.fEduExternalTrainingInfo,
    attachmentId: item.fEduExternalTrainingAttachmentId,
    attachmentName: item.fEduExternalTrainingAttachmentName,
    expires: !item.fEduExternalTrainingExpirationDate
      ? null
      : moment(item.fEduExternalTrainingExpirationDate, 'YYYY-MM-DD'),
    includeInTrainingCompensation: !!item.fEduExternalTrainingIncludeInTrainingCompensation,
    companyTrainingFK: !item.fEduCompanyTrainingFK ? null : item.fEduCompanyTrainingFK,
  };
};

const mapEmployeeOtherTrainingHistoryForUI = (item: any): EmployeeTraining => {
  return {
    id: item.fEduOtherTrainingId,
    trainingGroup: {
      value: 'OTHER_TRAINING_TYPE',
      label: getTrainingGroupName('OTHER_TRAINING_TYPE'),
    },
    employeeId: item.fEduOtherTrainingEmpId,
    name: {
      value: item.fEduOtherTrainingType,
      label: item.fEduOtherTrainingType,
    },
    provider: item.fEduOtherTrainingProvider,
    trainingClass: !item.fEduOtherTrainingClassFK
      ? null
      : {
          value: item.fEduOtherTrainingClassFK,
          label: getTrainingClassName(item.fEduOtherTrainingClassFK),
        },
    startDate: moment(item.fEduOtherTrainingStartDate, 'YYYY-MM-DD'),
    endDate: !item.fEduOtherTrainingEndDate
      ? null
      : moment(item.fEduOtherTrainingEndDate, 'YYYY-MM-DD'),
    hours: item.fEduOtherTrainingHours,
    info: item.fEduOtherTrainingInfo,
    attachmentId: item.fEduOtherTrainingAttachmentId,
    attachmentName: item.fEduOtherTrainingAttachmentName,
    expires: !item.fEduOtherTrainingExpirationDate
      ? null
      : moment(item.fEduOtherTrainingExpirationDate, 'YYYY-MM-DD'),
    includeInTrainingCompensation: !!item.fEduOtherTrainingIncludeInTrainingCompensation,
    companyTrainingFK: !item.fEduCompanyTrainingFK ? null : item.fEduCompanyTrainingFK,
  };
};

export const mapEmployeeTrainingHistoryForUI = (
  item: any,
): EmployeeTraining => {
  if (item.hasOwnProperty('fEduInternalTrainingType')) {
    return mapEmployeeInternalTrainingHistoryForUI(item);
  }
  if (item.hasOwnProperty('fEduExternalTrainingType')) {
    return mapEmployeeExternalTrainingHistoryForUI(item);
  }
  if (item.hasOwnProperty('fEduOtherTrainingType')) {
    return mapEmployeeOtherTrainingHistoryForUI(item);
  }
  return undefined;
};

const removeNullFields = (data: object): object => {
  let item = Object.assign({}, data); // Create copy
  Object.entries(item).forEach((o) => (o[1] === null ? delete item[o[0]] : 0));
  return item;
};

const withUploadFilename = (file: File) => {
  if (!file) {
    return file;
  }
  return Object.assign(file, { uploadName: file.name });
};

const mapEmployeeInternalTrainingHistoryForBE = (item: EmployeeTraining): any =>
  removeNullFields({
    fEduInternalTrainingId: item.id || null,
    fEduInternalTrainingEmpId: item.employeeId,
    fEduInternalTrainingType: item.name.value,
    fEduInternalTrainingProvider: item.provider,
    fEduInternalTrainingClassFK: !item.trainingClass
      ? null
      : item.trainingClass.value,
    fEduInternalTrainingStartDate: item.startDate.format('YYYY-MM-DD'),
    fEduInternalTrainingEndDate: !item.endDate
      ? null
      : item.endDate.format('YYYY-MM-DD'),
    fEduInternalTrainingHours: !item.hours ? null : item.hours,
    fEduInternalTrainingInfo: item.info || '', // Seems to be mandatory field in BE
    fEduInternalTrainingExpirationDate: !item.expires
      ? null
      : item.expires.format('YYYY-MM-DD'),
    fEduInternalTrainingAttachmentId: item.attachmentId || -1,
    fEduInternalTrainingAttachmentName: item.attachmentName,
    fEduInternalTrainingIncludeInTrainingCompensation:
      item.includeInTrainingCompensation,
    file: withUploadFilename(item.attachmentFile),
  });

const mapEmployeeExternalTrainingHistoryForBE = (item: EmployeeTraining): any =>
  removeNullFields({
    fEduExternalTrainingId: item.id || null,
    fEduExternalTrainingEmpId: item.employeeId,
    fEduExternalTrainingType: item.name.value,
    fEduExternalTrainingProvider: item.provider,
    fEduExternalTrainingClassFK: !item.trainingClass
      ? null
      : item.trainingClass.value,
    fEduExternalTrainingStartDate: item.startDate.format('YYYY-MM-DD'),
    fEduExternalTrainingEndDate: !item.endDate
      ? null
      : item.endDate.format('YYYY-MM-DD'),
    fEduExternalTrainingHours: !item.hours ? null : item.hours,
    fEduExternalTrainingInfo: item.info || '',
    fEduExternalTrainingExpirationDate: !item.expires
      ? null
      : item.expires.format('YYYY-MM-DD'),
    fEduExternalTrainingAttachmentId: item.attachmentId || -1,
    fEduExternalTrainingAttachmentName: item.attachmentName,
    fEduExternalTrainingIncludeInTrainingCompensation:
      item.includeInTrainingCompensation,
    file: withUploadFilename(item.attachmentFile),
  });

const mapEmployeeOtherTrainingHistoryForBE = (item: EmployeeTraining): any =>
  removeNullFields({
    fEduOtherTrainingId: item.id || null,
    fEduOtherTrainingEmpId: item.employeeId,
    fEduOtherTrainingType: item.name.value,
    fEduOtherTrainingProvider: item.provider,
    fEduOtherTrainingClassFK: !item.trainingClass
      ? null
      : item.trainingClass.value,
    fEduOtherTrainingStartDate: item.startDate.format('YYYY-MM-DD'),
    fEduOtherTrainingEndDate: !item.endDate
      ? null
      : item.endDate.format('YYYY-MM-DD'),
    fEduOtherTrainingHours: !item.hours ? null : item.hours,
    fEduOtherTrainingInfo: item.info || '',
    fEduOtherTrainingExpirationDate: !item.expires
      ? null
      : item.expires.format('YYYY-MM-DD'),
    fEduOtherTrainingAttachmentId: item.attachmentId || -1,
    fEduOtherTrainingAttachmentName: item.attachmentName,
    fEduOtherTrainingIncludeInTrainingCompensation:
      item.includeInTrainingCompensation,
    file: withUploadFilename(item.attachmentFile),
  });

export const mapEmployeeTrainingHistoryForBE = (item: EmployeeTraining) => {
  if (item.trainingGroup.value === 'INTERNAL_TRAINING_TYPE') {
    return mapEmployeeInternalTrainingHistoryForBE(item);
  }
  if (item.trainingGroup.value === 'EXTERNAL_TRAINING_TYPE') {
    return mapEmployeeExternalTrainingHistoryForBE(item);
  }
  if (item.trainingGroup.value === 'OTHER_TRAINING_TYPE') {
    return mapEmployeeOtherTrainingHistoryForBE(item);
  }
  return undefined;
};

import translate from '@/app/utils/translate';

export default (status: string) => {
  switch (status) {
    case 'APPROVED': return translate.t('laApproved');
    case 'REJECTED': return translate.t('laRejected');
    case 'WAITING': return translate.t('laWaitApproval');
    case 'CANCELLED': return translate.t('label_cancelled');
    default: return translate.t('laUnknown');
  }
};

import * as React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles, WithStyles, Switch } from '@material-ui/core';
import ScheduleIcon from '@material-ui/icons/Schedule';
import PlayIcon from '@material-ui/icons/PlayArrowRounded';
import StopIcon from '@material-ui/icons/StopRounded';
import ChartIcon from '@material-ui/icons/BarChart';
import PublishIcon from '@material-ui/icons/CloudUpload';
import UnpublishIcon from '@material-ui/icons/CloudOff';
import TranslateIcon from '@material-ui/icons/Translate';
import ChangeCloseDateIcon from '@material-ui/icons/Restore';
import AddAudienceIcon from '@material-ui/icons/PersonAdd';
import ChangeReviewersIcon from '@material-ui/icons/People';
import manageSurveyListStyle from './manageSurveyListStyle';
import { Dispatch } from 'redux';
import EnhancedTable from '../../EnhancedTable/EnhancedTable';
import { Tools } from '../../EnhancedTable/EnhancedTableToolbar/EnhancedTableToolbar';
import { HeadData } from '../../EnhancedTable/EnhancedTableHead/EnhancedTableHead';
import { openConfirmDialog, ConfirmDialogType } from '@/app/redux/confirmDialog';
import {
  fetchSurveys,
  createSurvey,
  updateSurvey,
  deleteSurveys,
  closeSurvey,
  startSurvey,
  scheduleSurvey,
  SURVEY_STATUS,
  PartialSurvey,
  getAndOpenResultsDialog,
  ServerSurveyTranslation,
  saveTranslation,
  publishSurvey,
  NewSurvey,
  duplicateSurvey,
  unpublishSurvey,
  ListSurvey,
  resetAllTranslations,
  UnitType,
  fetchEditSurvey,
  emptyEdit,
  SurveyCloseDate,
  changeCloseDate,
  SurveyAddAudience,
  SurveyChangeReviewers,
  addAudience,
  changeReviewers,
} from '@/app/redux/surveys';
import { ReducerState } from '@/app/redux/store';
import SurveyFormDialog from '../SurveyFormDialog/SurveyFormDialog';
import translate from '@/app/utils/translate';
import ScheduleSurveyFormDialog from '../ScheduleSurveyFormDialog/ScheduleSurveyFormDialog';
import TranslateSurveyDialog from '../TranslateSurveyDialog/TranslateSurveyDialog';
import { getLoggedUserId, getEmployeeInfoById } from '@/old/utils/helper';
import { CustomRowButton } from '../../EnhancedTable/RowMenu/RowMenu';
import ChangeCloseDateFormDialog from '../ChangeCloseDateFormDialog/ChangeCloseDateFormDialog';
import AddAudienceFormDialog from '../AddAudienceFormDialog/AddAudienceFormDialog';
import ChangeReviewersFormDialog from '../ChangeReviewersFormDialog/ChangeReviewersFormDialog';

const TOOLS: Tools = {
  showAdd: true,
  showEdit: true,
  showDelete: true,
  showTranslate: true,
  showDuplicate: true,
};

type MapStateToProps = {
  surveys: ListSurvey[];
  unitList: UnitType[];
};

type MapDispatchToProps = {
  fetchSurveys: (all?: boolean) => void;
  fetchEditSurvey: (surveyId: string) => void;
  openConfirmDialog: (payload: ConfirmDialogType) => void;
  createSurvey: (survey: NewSurvey) => void;
  editSurvey: (survey: NewSurvey) => void;
  deleteSurveys: (ids: string[]) => void;
  scheduleSurvey: (partSurvey: PartialSurvey) => void;
  startSurvey: (surveyId: string) => void;
  closeSurvey: (surveyId: string) => void;
  openResultsDialog: (surveyId: string) => void;
  saveTranslation: (surveyId: string, translation: ServerSurveyTranslation) => void;
  publishSurvey: (surveyId: string) => void;
  unpublishSurvey: (surveyId: string) => void;
  duplicateSurvey: (surveyId: string) => void;
  resetAllTranslations: () => void;
  emptyEdit: () => void;
  changeCloseDate: (surveyData: SurveyCloseDate) => void;
  addAudience: (surveyData: SurveyAddAudience) => void;
  changeReviewers: (surveyData: SurveyChangeReviewers) => void;
};

type State = {
  formDialogOpen: boolean;
  formDialogTitle: string;
  survey: ListSurvey;
  selectedRows: ListSurvey[];
  hideDelete: boolean;
  hideTranslate: boolean;
  scheduleSurveyOpen: boolean;
  scheduleSurveyTitle: string;
  translateSurveyOpen: boolean;
  onlyMySurveys: boolean;
  edit: boolean;
  changeCloseDateOpen: boolean;
  addAudienceOpen: boolean;
  changeReviewersOpen: boolean;
};

type PropsType = MapStateToProps & MapDispatchToProps & WithStyles<typeof manageSurveyListStyle>;

class ManageSurveyList extends React.PureComponent<PropsType> {
  state: State = {
    formDialogOpen: false,
    formDialogTitle: '',
    survey: null,
    selectedRows: [],
    hideDelete: false,
    hideTranslate: false,
    scheduleSurveyOpen: false,
    scheduleSurveyTitle: '',
    translateSurveyOpen: false,
    onlyMySurveys: true,
    edit: false,
    changeCloseDateOpen: false,
    addAudienceOpen: false,
    changeReviewersOpen: false,
  };

  logUsrEmpId: number;
  logUsrUnitId: number;

  componentDidMount() {
    this.props.fetchSurveys(this.state.onlyMySurveys);
    this.logUsrEmpId = getLoggedUserId();
    this.logUsrUnitId = getEmployeeInfoById(this.logUsrEmpId).fEmpUnit;
  }

  handleAdd = () => {
    this.formDialogOpen(translate.t('title_add_survey'));
  };

  formDialogOpen = (title: string, surveyId?: string) => {
    if (surveyId) {
      this.props.fetchEditSurvey(surveyId);
    }
    this.setState({ formDialogOpen: true, formDialogTitle: title });
  };

  handleEdit = (selectedItems: ListSurvey[]) => () => {
    this.setState({ edit: true });

    this.formDialogOpen(translate.t('title_edit_survey'), selectedItems[0].id);
  };

  handleFormDialogClose = () => {
    if (this.state.edit) {
      this.props.emptyEdit();
    }
    this.setState({
      formDialogOpen: false,
      edit: false,
    });
  };

  handleSchedule = (selectedItems: ListSurvey[]) => {
    const survey = selectedItems[0];

    this.setState({
      scheduleSurveyOpen: true,
      scheduleSurveyTitle: `${translate.t('label_schedule_survey')} - ${survey.name}`,
      survey,
    });
  };

  handleScheduleSurveyFormDialogClose = () => {
    this.setState({
      scheduleSurveyOpen: false,
      scheduleSurveyTitle: '',
      survey: null,
    });
  };

  handleScheduleSurveySubmit = (survey: PartialSurvey) => {
    this.props.scheduleSurvey(survey);
    this.handleScheduleSurveyFormDialogClose();
  };

  handleStart = (selectedItems: ListSurvey[]) => {
    this.props.openConfirmDialog({
      text: translate.t('confirm_start_survey'),
      onOk: () => this.props.startSurvey(selectedItems[0].id)
    });
  };

  handleClose = (selectedItems: ListSurvey[]) => {
    this.props.openConfirmDialog({
      text: translate.t('confirm_close_survey'),
      onOk: () => this.props.closeSurvey(selectedItems[0].id)
    });
  };

  handleViewResults = (selectedItems: ListSurvey[]) => {
    this.props.openResultsDialog(selectedItems[0].id);
  };

  handlePublishResults = (selectedItems: ListSurvey[]) => {
    this.props.openConfirmDialog({
      text: translate.t('confirm_publish_survey'),
      onOk: () => this.props.publishSurvey(selectedItems[0].id)
    });
  };

  handleUnpublishResults = (selectedItems: ListSurvey[]) => {
    this.props.openConfirmDialog({
      text: translate.t('confirm_unpublish_survey'),
      onOk: () => this.props.unpublishSurvey(selectedItems[0].id)
    });
  };

  handleTranslate = (selectedItems: ListSurvey[]): any => {
    const survey = selectedItems[0];
    this.setState({
      survey,
      translateSurveyOpen: true,
    });

    return null;
  };

  handleTranslateSave = (surveyId: string, translatedSurvey: ServerSurveyTranslation) => {
    this.setState({
      survey: null,
      translateSurveyOpen: false,
    });

    this.props.saveTranslation(surveyId, translatedSurvey);
  };

  handleTranslateClose = () => {
    this.setState({
      survey: null,
      translateSurveyOpen: false,
    });

    this.props.resetAllTranslations();
  };

  handleDuplicate = (selectedItems: ListSurvey[]) => () => {
    this.props.duplicateSurvey(selectedItems[0].id);
  };

  handleDelete = (selectedItems: ListSurvey[]) => () => {
    const ids = selectedItems.map(item => item.id);
    this.props.openConfirmDialog({
      text: translate.t('confirm_delete_survey'),
      onOk: () => this.props.deleteSurveys(ids)
    });
  };

  handleSubmit = (values: NewSurvey) => {
    if (this.state.edit) {
      this.props.editSurvey(values);
    } else {
      this.props.createSurvey(values);
    }

    this.handleFormDialogClose();
  };

  onSelectedChange = (selectedRows: ListSurvey[]) => {
    this.setState({
      selectedRows,
      hideDelete: this.hideDelete(selectedRows),
      hideTranslate: this.hideTranslate(selectedRows),
    });
  };

  hideDelete = (selectedRows: ListSurvey[]) => (
    selectedRows.length >= 1 &&
    selectedRows.some(row => row.status === SURVEY_STATUS.ACTIVE)
  );

  hideTranslate = (selectedRows: ListSurvey[]) => (
    selectedRows.length >= 1 &&
    selectedRows.some(row => (
      row.status !== SURVEY_STATUS.ACTIVE &&
      row.status !== SURVEY_STATUS.DRAFT &&
      row.status !== SURVEY_STATUS.SCHEDULED
    ))
  );

  handleChangeCloseDate = (selectedRows: ListSurvey[]) => {
    this.setState({
      changeCloseDateOpen: true,
      survey: selectedRows[0],
    });
  };

  handleAddAudience = (selectedRows: ListSurvey[]) => {
    this.setState({
      addAudienceOpen: true,
      survey: selectedRows[0],
    });
  };

  handleChangeReviewers = (selectedRows: ListSurvey[]) => {
    this.setState({
      changeReviewersOpen: true,
      survey: selectedRows[0],
    });
  };

  getCustomButtons = () => {
    const { selectedRows } = this.state;
    if (selectedRows.length === 0 || selectedRows.length > 1) {
      return undefined;
    }

    const { unitList } = this.props;
    const { logUsrEmpId, logUsrUnitId } = this;
    const { status, owner, reviewers, audience } = selectedRows[0];

    const isOwner = owner.employeeId === logUsrEmpId;
    const isReviewer = reviewers.some((rev: UnitType) => (
      parseInt(rev.value, 10) === logUsrUnitId
    ));
    const showResults = status !== SURVEY_STATUS.DRAFT && isOwner ||
      status === SURVEY_STATUS.PUBLISHED && isReviewer;
    const showChangeCloseDate = (status === SURVEY_STATUS.ACTIVE || status === SURVEY_STATUS.CLOSED) && isOwner;
    const showAddAudience = status === SURVEY_STATUS.ACTIVE && audience && audience.length < unitList.length;

    const customButtons = [{
      tooltip: translate.t('label_schedule_survey'),
      iconButton: (<ScheduleIcon />),
      onClick: this.handleSchedule,
      hidden: status !== SURVEY_STATUS.DRAFT,
     }, {
      tooltip: translate.t('label_start_survey'),
      iconButton: (<PlayIcon />),
      onClick: this.handleStart,
      hidden: status !== SURVEY_STATUS.SCHEDULED,
    }, {
      tooltip: translate.t('label_close_survey'),
      iconButton: (<StopIcon />),
      onClick: this.handleClose,
      hidden: status !== SURVEY_STATUS.ACTIVE,
    }, {
      label: translate.t('label_change_close_date'),
      iconButton: (<ChangeCloseDateIcon />),
      onClick: this.handleChangeCloseDate,
      hidden: !showChangeCloseDate,
    }, {
      label: translate.t('label_add_audience'),
      iconButton: (<AddAudienceIcon />),
      onClick: this.handleAddAudience,
      hidden: !showAddAudience,
    }, {
      label: translate.t('label_change_reviewers'),
      iconButton: (<ChangeReviewersIcon />),
      onClick: this.handleChangeReviewers,
      hidden: status === SURVEY_STATUS.DRAFT,
    }, {
      tooltip: translate.t('label_view_results'),
      iconButton: (<ChartIcon />),
      onClick: this.handleViewResults,
      hidden: !showResults,
    }, {
      label: translate.t('label_publish_results'),
      iconButton: (<PublishIcon />),
      onClick: this.handlePublishResults,
      hidden: !(status === SURVEY_STATUS.CLOSED && isOwner),
    }, {
      label: translate.t('label_unpublish_results'),
      iconButton: (<UnpublishIcon />),
      onClick: this.handleUnpublishResults,
      hidden: !(status === SURVEY_STATUS.PUBLISHED && isOwner),
    }];

    return customButtons.filter(btn => !btn.hidden);
  };

  getCustomRowButtons = (row: any) => {
    const { logUsrEmpId, logUsrUnitId } = this;
    const { status, owner, reviewers, audience } = row;
    const { unitList } = this.props;

    const isOwner = owner.employeeId === logUsrEmpId;
    const isReviewer = reviewers.some((rev: UnitType) => (
      parseInt(rev.value, 10) === logUsrUnitId
    ));
    const showResults = status !== SURVEY_STATUS.DRAFT && isOwner ||
      status === SURVEY_STATUS.PUBLISHED && isReviewer;
    const showChangeCloseDate = (status === SURVEY_STATUS.ACTIVE || status === SURVEY_STATUS.CLOSED) && isOwner;
    const showAddAudience = status === SURVEY_STATUS.ACTIVE && audience && audience.length < unitList.length;

    const customRowButtons: CustomRowButton[] = [{
      label: translate.t('label_schedule_survey'),
      iconButton: (<ScheduleIcon />),
      onClick: (selectedItems: ListSurvey[]) => this.handleSchedule(selectedItems),
      hidden: status !== SURVEY_STATUS.DRAFT,
     }, {
      label: translate.t('label_start_survey'),
      iconButton: (<PlayIcon />),
      onClick: this.handleStart,
      hidden: status !== SURVEY_STATUS.SCHEDULED || !isOwner,
    }, {
      label: translate.t('label_close_survey'),
      iconButton: (<StopIcon />),
      onClick: this.handleClose,
      hidden: status !== SURVEY_STATUS.ACTIVE,
    }, {
      label: translate.t('label_change_close_date'),
      iconButton: (<ChangeCloseDateIcon />),
      onClick: this.handleChangeCloseDate,
      hidden: !showChangeCloseDate,
    }, {
      label: translate.t('label_add_audience'),
      iconButton: (<AddAudienceIcon />),
      onClick: this.handleAddAudience,
      hidden: !showAddAudience,
    }, {
      label: translate.t('label_change_reviewers'),
      iconButton: (<ChangeReviewersIcon />),
      onClick: this.handleChangeReviewers,
      hidden: status === SURVEY_STATUS.DRAFT,
    }, {
      label: translate.t('label_view_results'),
      iconButton: (<ChartIcon />),
      onClick: this.handleViewResults,
      hidden: !showResults,
    }, {
      label: translate.t('label_publish_results'),
      iconButton: (<PublishIcon />),
      onClick: this.handlePublishResults,
      hidden: !(status === SURVEY_STATUS.CLOSED && isOwner),
    }, {
      label: translate.t('label_unpublish_results'),
      iconButton: (<UnpublishIcon />),
      onClick: this.handleUnpublishResults,
      hidden: !(status === SURVEY_STATUS.PUBLISHED && isOwner),
    }, {
      label: translate.t('button_translate'),
      iconButton: (<TranslateIcon />),
      onClick: (selectedItems: ListSurvey[]) => this.handleTranslate(selectedItems),
      hidden: row.status !== SURVEY_STATUS.ACTIVE &&
        row.status !== SURVEY_STATUS.DRAFT &&
        row.status !== SURVEY_STATUS.SCHEDULED,
    }];

    return customRowButtons.filter((btn: CustomRowButton) => !btn.hidden);
  };

  getCustomTool = () => (
    <>
      {translate.t('label_only_my_surveys')}
      <Switch
        checked={this.state.onlyMySurveys}
        onChange={this.changeShownSurveys()}
        value={this.state.onlyMySurveys}
        color="primary"
      />
    </>
  );

  changeShownSurveys = () => (event: any) => {
    const { checked: onlyMySurveys } = event.target;

    this.setState({ onlyMySurveys }, () => this.props.fetchSurveys(onlyMySurveys));
  };

  handleCloseDateFormDialogClose = () => {
    this.setState({
      changeCloseDateOpen: false,
      survey: null,
    });
  };

  handleCloseDateSubmit = (values: SurveyCloseDate) => {
    this.props.changeCloseDate(values);
    this.handleCloseDateFormDialogClose();
  };

  handleAddAudienceFormDialogClose = () => {
    this.setState({
      addAudienceOpen: false,
      survey: null,
    });
  };

  handleAddAudienceSubmit = (values: SurveyAddAudience) => {
    this.props.addAudience(values);
    this.handleAddAudienceFormDialogClose();
  };

  handleChangeReviewersFormDialogClose = () => {
    this.setState({
      changeReviewersOpen: false,
      survey: null,
    });
  };

  handleChangeReviewersSubmit = (values: SurveyChangeReviewers) => {
    this.props.changeReviewers(values);
    this.handleChangeReviewersFormDialogClose();
  };

  render() {
    const headData: Array<HeadData> = [
      { id: 'name', type: 'string', disablePadding: false, label: translate.t('table_col_survey_name') },
      { id: 'surveyAudience', type: 'string', disablePadding: false, label: translate.t('table_col_audience') },
      { id: 'surveyReviewers', type: 'string', disablePadding: false, label: translate.t('table_col_reviewers') },
      { id: 'surveyAnswers', type: 'string', disablePadding: false, label: translate.t('table_col_answers') },
      { id: 'surveyStatus', type: 'string', disablePadding: false, label: translate.t('table_col_status') },
    ];

    const { surveys } = this.props;
    const {
      formDialogOpen,
      formDialogTitle,
      hideDelete,
      hideTranslate,
      survey,
      scheduleSurveyTitle,
      scheduleSurveyOpen,
      translateSurveyOpen,
      edit,
      changeCloseDateOpen,
      addAudienceOpen,
      changeReviewersOpen,
    } = this.state;

    return (
      <>
        <SurveyFormDialog
          open={formDialogOpen}
          onClose={this.handleFormDialogClose}
          onSubmit={this.handleSubmit}
          title={formDialogTitle}
          edit={edit}
        />
        <ScheduleSurveyFormDialog
          open={scheduleSurveyOpen}
          onClose={this.handleScheduleSurveyFormDialogClose}
          onSubmit={this.handleScheduleSurveySubmit}
          title={scheduleSurveyTitle}
          id={survey && survey.id ? survey.id : ''}
        />
        <TranslateSurveyDialog
          open={translateSurveyOpen}
          surveyId={survey && survey ? survey.id : ''}
          onSave={this.handleTranslateSave}
          onClose={this.handleTranslateClose}
        />
        <ChangeCloseDateFormDialog
          open={changeCloseDateOpen}
          onClose={this.handleCloseDateFormDialogClose}
          onSubmit={this.handleCloseDateSubmit}
          title={translate.t('title_change_close_date')}
          survey={survey}
        />
        <AddAudienceFormDialog
          open={addAudienceOpen}
          onClose={this.handleAddAudienceFormDialogClose}
          onSubmit={this.handleAddAudienceSubmit}
          title={translate.t('title_add_audience')}
          survey={survey}
        />
        <ChangeReviewersFormDialog
          open={changeReviewersOpen}
          onClose={this.handleChangeReviewersFormDialogClose}
          onSubmit={this.handleChangeReviewersSubmit}
          title={translate.t('title_change_reviewers')}
          survey={survey}
        />
        <EnhancedTable
          customTools={this.getCustomTool()}
          title={translate.t('title_manage_surveys')}
          hideTools={{
            hideDelete,
            hideTranslate,
          }}
          tools={TOOLS}
          customButtons={this.getCustomButtons()}
          customRowButtons={this.getCustomRowButtons}
          addHandler={this.handleAdd}
          editHandler={this.handleEdit}
          deleteHandler={this.handleDelete}
          duplicateHandler={this.handleDuplicate}
          translateHandler={(selectedSurveys: ListSurvey[]) => () => this.handleTranslate(selectedSurveys)}
          hideRowTranslate={true}
          headData={headData}
          data={surveys}
          order={'asc'}
          orderBy={'name'}
          onSelectedChange={this.onSelectedChange}
        />
      </>
    );
  }
}

const mapStateToProps = (state: ReducerState) => {
  const { allSurveys: surveys, unitList } = state.surveys;

  return {
    surveys,
    unitList,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchSurveys: (all?: boolean) => dispatch<any>(fetchSurveys(all)),
  fetchEditSurvey: (surveyId: string) => dispatch<any>(fetchEditSurvey(surveyId)),
  openConfirmDialog: (payload: ConfirmDialogType) => dispatch(openConfirmDialog(payload)),
  createSurvey: (survey: NewSurvey) => dispatch<any>(createSurvey(survey)),
  editSurvey: (survey: NewSurvey) => dispatch<any>(updateSurvey(survey)),
  deleteSurveys: (ids: string[]) => dispatch<any>(deleteSurveys(ids)),
  scheduleSurvey: (partSurvey: PartialSurvey) => dispatch<any>(scheduleSurvey(partSurvey)),
  startSurvey: (surveyId: string) => dispatch<any>(startSurvey(surveyId)),
  closeSurvey: (surveyId: string) => dispatch<any>(closeSurvey(surveyId)),
  openResultsDialog: (surveyId: string) => dispatch<any>(getAndOpenResultsDialog(surveyId)),
  saveTranslation: (surveyId: string, translation: ServerSurveyTranslation) => (
    dispatch<any>(saveTranslation(surveyId, translation))
  ),
  resetAllTranslations: () => dispatch(resetAllTranslations()),
  publishSurvey: (surveyId: string) => dispatch<any>(publishSurvey(surveyId)),
  unpublishSurvey: (surveyId: string) => dispatch<any>(unpublishSurvey(surveyId)),
  duplicateSurvey: (surveyId: string) => dispatch<any>(duplicateSurvey(surveyId)),
  emptyEdit: () => dispatch(emptyEdit()),
  changeCloseDate: (surveyData: SurveyCloseDate) => dispatch<any>(changeCloseDate(surveyData)),
  addAudience: (surveyData: SurveyAddAudience) => dispatch<any>(addAudience(surveyData)),
  changeReviewers: (surveyData: SurveyChangeReviewers) => dispatch<any>(changeReviewers(surveyData)),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(manageSurveyListStyle),
);

export default enhance(ManageSurveyList);

import translate from '@/app/utils/translate';

export type Column = {
  field: string;
  title: string;
};

export const employment = () => [
  {
    field: 'position',
    title: translate.t('laPosition'),
  },
  {
    field: 'startDate',
    title: translate.t('laStartDate'),
  },
  {
    field: 'endDate',
    title: translate.t('laEndDate'),
  },
];

export const longTermAbsences = () => [
  {
    field: 'absenceReason',
    title: translate.t('laAbsenceReason'),
  },
  {
    field: 'startDate',
    title: translate.t('laStartDate'),
  },
  {
    field: 'endDate',
    title: translate.t('laEndDate'),
  },
  {
    field: 'info',
    title: translate.t('laInfo'),
  },
];

export const previousCompany = () => [
  {
    field: 'previousCompany',
    title: translate.t('laPreviousCompany'),
  },
  {
    field: 'position',
    title: translate.t('laPosition'),
  },
  {
    field: 'startDate',
    title: translate.t('laStartDate'),
  },
  {
    field: 'endDate',
    title: translate.t('laEndDate'),
  },
  {
    field: 'expInDays',
    title: translate.t('laExperienceInDays'),
  },
  {
    field: 'info',
    title: translate.t('laInfo'),
  },
  {
    field: 'experience',
    title: translate.t('laExperience'),
  },
];

export const previousAbsences = () => [
  {
    field: 'absenceReason',
    title: translate.t('laAbsenceReason'),
  },
  {
    field: 'startDate',
    title: translate.t('laStartDate'),
  },
  {
    field: 'endDate',
    title: translate.t('laEndDate'),
  },
  {
    field: 'days',
    title: translate.t('laDays'),
  },
  {
    field: 'info',
    title: translate.t('laInfo'),
  },
];

export const otherExperience = () => [
  {
    field: 'position',
    title: translate.t('laPosition'),
  },
  {
    field: 'positionSubtitle',
    title: translate.t('laPositionSubtitle'),
  },
  {
    field: 'startDate',
    title: translate.t('laStartDate'),
  },
  {
    field: 'endDate',
    title: translate.t('laEndDate'),
  },
  {
    field: 'info',
    title: translate.t('laInfo'),
  },
];

import { createStyles } from '@material-ui/core';

const styles = () => createStyles({
  toolbar: {
    display: 'grid',
    gridTemplateAreas: `"history scope download"`,
    gridTemplateColumns: 'auto 1fr auto',
    alignItems: 'center',
  },
  toolbarHistory: {
    gridArea: 'history',
  },
  toolbarScope: {
    gridArea: 'scope',
    borderLeft: 'solid 1px rgba(0, 0, 0, 0.08)',
    padding: '0 8px',
},
  toolbarDownload: {
    gridArea: 'download',
    justifySelf: 'end',
    borderLeft: 'solid 1px rgba(0, 0, 0, 0.08)',
    paddingLeft: '8px',
  },
});

export default styles;
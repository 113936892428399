import translate from '@/app/utils/translate';
import { Grid, Typography, WithStyles, withStyles } from '@material-ui/core';
import React from 'react';
import { compose } from 'recompose';
import { MappedWorkExprience } from './data';
import { styles } from './styles';
type OwnProps = {
  data: MappedWorkExprience;
};

type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const WorkExperienceTotal = ({ data, classes }: Props) => {
  return (
    <Grid item container direction="row">
      <Typography className={classes.totalWE}>{`${translate.t(
        'totalWorkExperience',
      )} ${data.totalWorkExperience} ${translate.t('laDays')}`}</Typography>

      <Typography className={classes.convertMargin}>{`${translate.t(
        'convertedTo',
      )}: ${data.convertYears} ${translate.t('laYears')} ${
        data.convertMonths
      } ${translate.t('laMonths')}`}</Typography>
      <Typography>{`${data.convertYears} ${translate.t('laYears')} ${
        data.convertDays
      } ${translate.t('laDays')}`}</Typography>
    </Grid>
  );
};

const enhance = compose<Props, OwnProps>(withStyles(styles));

export default enhance(WorkExperienceTotal);

import { Grid, Typography, withStyles, WithStyles } from '@material-ui/core';
import React from 'react';
import { styles } from './styles';
import { compose } from 'recompose';
import { Column } from './data';

type OwnProps = {
  dataHeaders: Column[];
  data: any;
};

type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const WorkExperienceLineDataDisplay = ({
  data,
  dataHeaders,
  classes,
}: Props) => {
  return (
    <Grid item container direction="row">
      <Grid item container direction="row">
        {dataHeaders.map(header => (
          <Grid key={header.title} item xs={2}>
            <Typography variant="subtitle1" className={classes.header}>
              {header.title}
            </Typography>
          </Grid>
        ))}
      </Grid>
      {data &&
        data.map((item: any, index: any) => (
          <Grid key={index} item container direction="row">
            {Object.keys(item).map((key, index2) => (
              <Grid key={index2} item xs={2}>
                <Typography>{item[key]}</Typography>
              </Grid>
            ))}
          </Grid>
        ))}
    </Grid>
  );
};

const enhance = compose<Props, OwnProps>(withStyles(styles));

export default enhance(WorkExperienceLineDataDisplay);

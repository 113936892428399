import React from 'react';
import {
  Popover,
  Paper,
  ListItem,
  ListItemText,
  Button,
  Typography,
  StyleRulesCallback,
  Theme,
  ListItemAvatar,
  WithStyles,
  withStyles,
  List
} from '@material-ui/core';
import { CompanyTrainingExternalParticipant } from '../../../types';
import ExternalParticipantAvatar from './ExternalParticipantAvatar';
import translate from '@/app/utils/translate';

const styles: StyleRulesCallback = (theme: Theme) => ({
  paper: {
    padding: theme.spacing.unit,
    paddingTop: 0,
    minWidth: 280,
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  textButton: {
    textTransform: 'none',
  },
});

type OwnProps = {
  participant: CompanyTrainingExternalParticipant;
  anchorEl: any;
  onClose: () => void;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const ExternalParticipantDetailsPopover = ({
  classes,
  participant,
  anchorEl,
  onClose,
}: Props) => {
  if (!anchorEl) {
    return null;
  }

  return (
    <Popover
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      <Paper className={classes.paper}>
        <List>
          <ListItem>
            <ListItemAvatar>
              <ExternalParticipantAvatar participant={participant} />
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="h6">
                <strong>{`${participant.firstName} ${participant.lastName}`}</strong>
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Button
              className={classes.textButton}
              color="primary"
              variant="text"
              href={`mailto:${participant.email}`}
            >
              {participant.email}
            </Button>
          </ListItem>
          <ListItem className={classes.listItem}>
            {translate.t('laPhone')}
            <Button
              className={classes.textButton}
              color="primary"
              variant="text"
              href={`tel:${participant.phone}`}
            >
              {participant.phone}
            </Button>
          </ListItem>
        </List>
      </Paper>
    </Popover>
  );
};

export default withStyles(styles)(ExternalParticipantDetailsPopover);

import * as React from 'react';
import {
  Avatar,
  Button,
  Chip,
  IconButton,
  Paper,
  Tooltip,
  withStyles,
  CircularProgress,
} from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/Group';
import InfoIcon from '@material-ui/icons/Info';
import DropdownIcon from '@material-ui/icons/ArrowDropDown';
import EnhancedTable from '@/app/components/EnhancedTable/EnhancedTable';
import { Tools } from '@/app/components/EnhancedTable/EnhancedTableToolbar/EnhancedTableToolbar';
import { HeadData } from '@/app/components/EnhancedTable/EnhancedTableHead/EnhancedTableHead';
import ApplyRuleColumnHeader from './BonusCalcApplyRuleColumnHeader/BonusCalcApplyRuleColumnHeader';
import BonusCalcRuleMenu, {
  openMenu as openRuleMenu,
} from './BonusCalcRuleMenu/BonusCalcRuleMenu';
import PropsMenu from '@/app/components/PropsMenu/PropsMenu';
import { getMyTeams } from '@/old/utils/helper';
import translate from '@/app/utils/translate';
import { formatNumber } from '@/app/utils/helper';
import { BonusRule } from '../../BonusCalculationRules/BonusCalculationRules';

const filterProgramsByOrgUnit = (programs: any[], selectedTeam?: any) => {
  if (!selectedTeam || !selectedTeam.value) {
    return programs;
  }
  const { value: orgUnit } = selectedTeam;

  return programs
    .map(program => {
      if (!program.orgUnits || program.orgUnits.length === 0) {
        return program;
      }
      if (
        program.orgUnits.some(
          (or: { value: number; label: string }) =>
            or.value.toString() === orgUnit.toString(),
        )
      ) {
        return program;
      }
      return null;
    })
    .filter(el => el);
};

const TableCellButton = withStyles({
  label: {
    textTransform: 'none',
    fontWeight: 'normal',
  },
})(Button);

const WarningMessage = withStyles({
  root: {
    padding: '10px',
    marginTop: '10px',
    width: 'auto',
    backgroundColor: '#f4be10',
  },
})(Paper);

const BonusCalcPlanningList: React.FunctionComponent<any> = props => {
  const handleApplyRules = (
    program: any,
    rule: BonusRule,
    _evt: React.MouseEvent,
  ) => {
    props.onProgramRuleChange(program, rule);
  };

  const renderRuleHeaderCell = (head: HeadData): React.ReactNode => {
    return (
      <ApplyRuleColumnHeader
        label={head.label}
        rules={props.rules}
        programs={filterProgramsByOrgUnit(props.programs, props.selectedTeam)}
        onClick={handleApplyRules}
      />
    );
  };

  const handleRuleDataClick = (evt: React.MouseEvent, item: any) => {
    openRuleMenu(evt.currentTarget, props.rules, selectedRule =>
      props.onRowRuleChange(item, selectedRule),
    );
    evt.preventDefault();
    evt.stopPropagation();
  };

  const renderRuleDataCell = (item: any, _head: HeadData): React.ReactNode => {
    const tooltipTitle =
      item.rawFormula && item.valuesFormula
        ? `${item.rawFormula} = ${item.valuesFormula}`
        : 'Formula info here!';

    return (
      <>
        <Tooltip disableFocusListener disableTouchListener title={tooltipTitle}>
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
        <TableCellButton
          variant="text"
          color="inherit"
          onClick={(evt: React.MouseEvent) => handleRuleDataClick(evt, item)}
        >
          {item.ruleName || `(${translate.t('option_not_included')})`}
          <DropdownIcon />
        </TableCellButton>
      </>
    );
  };

  const renderSumDataCell = (item: any): React.ReactNode => {
    return !item.sum || item.sum === 0
      ? null
      : formatNumber(item.sum) + ` ${item.currency}`;
  };

  const headData = (): HeadData[] => {
    return [
      {
        id: 'employee',
        type: 'string',
        disablePadding: false,
        label: translate.t('laEmployee'),
      },
      {
        id: 'position',
        type: 'string',
        disablePadding: false,
        label: translate.t('fEmpPosition'),
      },
      {
        id: 'programName',
        type: 'string',
        disablePadding: false,
        label: translate.t('laIncentive'),
      },
      {
        id: 'ruleName',
        type: 'string',
        renderCustomHeaderCell: renderRuleHeaderCell,
        renderCustomDataCell: renderRuleDataCell,
        disablePadding: false,
        label: translate.t('apply_rule'),
      },
      {
        id: 'sum',
        type: 'number',
        renderCustomDataCell: renderSumDataCell,
        disablePadding: false,
        label: translate.t('sum_to_paid'),
      },
    ];
  };

  const tableTools = (): Tools => {
    return {
      showAdd: false,
      showEdit: false,
      showDelete: false,
      showTranslate: false,
      showSort: false,
      showActive: false,
    };
  };

  const getTeamList = () => {
    return getMyTeams();
  };

  const tableCustomTools = (): React.ReactNode => {
    const { onTeamSelect, selectedTeam } = props;
    return (
      <div>
        <PropsMenu onSelect={onTeamSelect} data={getTeamList()}>
          {selectedTeam ? (
            <Chip
              avatar={
                <Avatar>
                  <PeopleIcon />
                </Avatar>
              }
              label={selectedTeam.label}
              clickable
              color="primary"
              onDelete={null}
              variant="outlined"
            />
          ) : (
            <IconButton color="primary">
              <PeopleIcon />
            </IconButton>
          )}
        </PropsMenu>
      </div>
    );
  };

  const {
    data,
    onSelectedChange,
    defaultSelected,
    selectedTeam: team,
    isFetching,
  } = props;

  return (
    <>
      <BonusCalcRuleMenu />
      {isFetching ? (
        <CircularProgress />
      ) : (
        <>
          <EnhancedTable
            data={data}
            headData={headData()}
            order="asc"
            orderBy="name"
            pagination={false}
            hasRowMenuColumn={false}
            hasSelectionAvailable={false}
            title={translate.t('title_bonus_calc_planning')}
            tools={tableTools()}
            customTools={tableCustomTools()}
            onSelectedChange={onSelectedChange}
            defaultSelected={defaultSelected}
          />
          {data && data.length === 0 && team ? (
            <WarningMessage>
              {translate.t('text_no_rights_to_propose')}
            </WarningMessage>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default BonusCalcPlanningList;

import moment from 'moment';
import { DATE_FORMAT } from '@/app/utils/helper';

export default (startDate: moment.Moment, endDate?: moment.Moment) => {
  if (!startDate) {
    return '';
  }

  const endDateStr =
    !endDate || startDate.format(DATE_FORMAT) === endDate.format(DATE_FORMAT)
      ? ''
      : ` - ${endDate.format(DATE_FORMAT)}`;

  return `${startDate.format(DATE_FORMAT)}${endDateStr}`;
};

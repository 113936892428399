import * as React from 'react';
import {
  Paper,
  withStyles,
  WithStyles,
  Popper,
  Fade,
  ClickAwayListener,
  StyleRulesCallback,
  Theme,
} from '@material-ui/core';
import { PopperPlacementType } from '@material-ui/core/Popper';

const styles: StyleRulesCallback = (theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.modal,
    transform: 'translate3d(0, 100%, 0)'
  },
  rootPaper: {},
  content: {},
});

type PropsType = {
  anchorEl: any;
  allowClickAwayClose?: boolean;
  onClose: Function;
  placement?: PopperPlacementType;
};

class GenericPopup extends React.Component<PropsType & WithStyles<typeof styles>, any> {
  render() {
    const { children, classes, anchorEl, allowClickAwayClose = true, placement = 'bottom-start', onClose } = this.props;
    if (!anchorEl) { return null; }
    return (
      <Popper
        className={classes.root}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement={placement}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <ClickAwayListener
              onClickAway={() => onClose()}
              mouseEvent={allowClickAwayClose ? 'onMouseUp' : false}
              touchEvent={allowClickAwayClose ? 'onTouchEnd' : false}
            >
              <Paper className={classes.rootPaper}>
                <Paper className={classes.content}>
                  {children}
                </Paper>
              </Paper>
            </ClickAwayListener>
          </Fade>
        )}
      </Popper>
    );
  }
}

export default withStyles(styles, {withTheme: true})(GenericPopup);
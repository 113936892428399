import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const configureUiLabelDialogStyle: StyleRulesCallback = (theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  actionButtonLeft: {
    marginRight: theme.spacing.unit,
  },
  actionButtonRight: {
    marginLeft: theme.spacing.unit,
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  progress: {
    margin: theme.spacing.unit * 3,
  },
});

export default configureUiLabelDialogStyle;
import * as React from 'react';

import { getEmployeeName, getLoggedUserId, getSelectedEmpId } from '@/old/utils/helper';
import translate from '@/app/utils/translate';

import { ReportType } from '../../tasksListModels';

export interface FilterNameProps {
    reportType: ReportType;
}

export const FilterName: React.FC<FilterNameProps> = ({ reportType }) => {
    const employeeName = getEmployeeName(getSelectedEmpId(), true);
    const viewingOwnPage = getSelectedEmpId() === getLoggedUserId();

    switch (reportType) {
        case ReportType.BY_ASSIGNEE: {
            return (
                viewingOwnPage
                    ? <>{translate.t('laTasksAssignedToMe')}</>
                    : <>{translate.t('laTasksAssignedTo')} {employeeName}</>
            );
        }

        case ReportType.BY_ASSIGNOR: {
            return (
                viewingOwnPage
                    ? <>{translate.t('laTasksCreatedByMe')}</>
                    : <>{translate.t('laTasksCreatedBy')} {employeeName}</>
            );
        }

        case ReportType.BY_PERTAINS: {
            return (
                viewingOwnPage
                    ? <>{translate.t('laTasksRelatedToMe')}</>
                    : <>{translate.t('laTasksRelatedTo')} {employeeName}</>
            );
        }

        default: throw Error('Invalid report type.');
    }
};

import moment from 'moment';

import { TimeCard } from '../../data';

export const timerJustStarted = (date: string, timeCardData?: TimeCard): boolean => {
    if (timeCardData && moment(date).isSame(new Date(), 'day')) {
        const now = moment().format('HH:mm');
        return timeCardData.timeBlocks.some((timeBlock) => {
            return timeBlock.from && !timeBlock.to && moment(timeBlock.from, 'HH:mm:ss').format('HH:mm') === now;
        });
    }

    return false;
};
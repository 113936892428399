import * as React from 'react';
import { Grid as MuiGrid, Button, Icon } from '@material-ui/core';
import ToolbarFilterButton from './ToolbarFilterButton';
import ToolbarFilterDropdownButton from './ToolbarFilterDropdownMenu';
import ToolbarFilterCheckbox from './ToolbarFilterCheckbox';
import ToolbarFilterButtonGroup from './ToolbarFilterButtonGroup';
import GenericPopup from './GenericPopup';
import ScopeSelector from './ScopeSelector';
import {
  ICustomFilterDropdownMenu,
  IReportConfig,
  ICustomFilter,
} from '../ReportConfig';
import { IReportSettings, ScopeType } from '../ReportSettings';
import translate from '@/app/utils/translate';
import * as moment from 'moment';
import { DATE_FORMAT } from '@/app/utils/helper';
import { hasRole } from '@/app/utils/security';

type PropsType = {
  config: IReportConfig;
  settings: IReportSettings;
  hasTeam: boolean;
  myHrUnits: Array<object>;
  myManagedUnits: Array<object>;
  onGetCustomFilters: () => ICustomFilter[];
  onSettingsChanged: (newSettings: IReportSettings) => void;
  onExportExcel: () => void;
};

type StateType = {
  scopeSelectorAnchorEl: any;
};

class ReportTools extends React.Component<PropsType, StateType> {
  state: StateType = {
    scopeSelectorAnchorEl: null,
  };

  private isLocked: boolean = false;
  private refScopeSelectorButton = React.createRef<React.ReactNode>();

  private getUnitName = (unitId: string): string => {
    const { myManagedUnits, myHrUnits } = this.props;
    let unit: any = myManagedUnits.find(
      (item: any) => item.fTreeUnitId === unitId,
    );
    if (!unit) {
      unit = myHrUnits.find((item: any) => item.fTreeUnitId === unitId);
      if (!unit) {
        return '';
      }
    }
    return unit.fTreeUnitName;
  };

  handleUnitSelectorClose = () => {
    if (this.isLocked) {
      return;
    }

    this.setState({ scopeSelectorAnchorEl: null });
  };

  handleApplyScope = (newScope: ScopeType) => {
    const newSettings = Object.assign({}, this.props.settings);
    newSettings.scope = newScope;
    this.props.onSettingsChanged(newSettings);
    this.handleUnitSelectorClose();
  };

  handleCustomFilterChange = (settingName: string, value: any): void => {
    const { settings } = this.props;
    const newSettings = Object.assign({}, settings);
    newSettings.custom = {
      ...settings.custom,
      [settingName]: value,
    };
    this.props.onSettingsChanged(newSettings);
  };

  render() {
    const {
      config,
      settings,
      hasTeam,
      myHrUnits,
      myManagedUnits,
      onGetCustomFilters = null,
    } = this.props;

    const { scopeSelectorAnchorEl } = this.state;

    const handleDateRangeSelectorClick = () => {
      // TODO: Open date range selector here!
    };

    const getScopeSelectorDescription = (): string => {
      if (!settings.scope || settings.scope.type === '') {
        return translate.t('label_none');
      }

      switch (settings.scope.type) {
        case 'manager':
          return translate.t('label_myteam');
        case 'unit':
          if (!settings.scope.rln) {
            return '';
          }
          if (settings.scope.rln === 'all') {
            return translate.t('label_unit_with_subunits', {
              unitName: this.getUnitName(settings.scope.id),
            });
          } else {
            return this.getUnitName(settings.scope.id);
          }
        default:
          return translate.t('label_whole_company');
      }
    };

    const getScopeDateRangeDescription = (): string => {
      if (!settings.scope) {
        return null;
      }
      if (settings.scope.range) {
        if (settings.scope.range.startDate && settings.scope.range.endDate) {
          return `${moment(settings.scope.range.startDate).format(
            DATE_FORMAT,
          )} - ${moment(settings.scope.range.endDate).format(DATE_FORMAT)}`;
        } else {
          if (!settings.scope.range.endDate) {
            return `${moment(settings.scope.range.startDate).format(
              DATE_FORMAT,
            )} -`;
          }
          if (!settings.scope.range.startDate) {
            return `- ${moment(settings.scope.range.endDate).format(
              DATE_FORMAT,
            )}`;
          }
        }
      }
      return null;
    };

    const scopeSelector = !config.filters.scopeFilter.enabled ? null : (
      <>
        <GenericPopup
          anchorEl={scopeSelectorAnchorEl}
          onClose={this.handleUnitSelectorClose}
          placement="top-start"
        >
          <ScopeSelector
            scope={settings.scope}
            allowCompanyScope={
              settings.allowCompanyScopeForAll === true || hasRole('HR_ADMIN')
            }
            hasTeam={hasTeam}
            myHrUnits={myHrUnits}
            myManagedUnits={myManagedUnits}
            onApply={this.handleApplyScope}
            onCancel={this.handleUnitSelectorClose}
            onPopupLock={(shouldLock: boolean) => {
              this.isLocked = shouldLock;
            }}
          />
        </GenericPopup>
        <ToolbarFilterButton
          buttonRef={this.refScopeSelectorButton}
          prompt={translate.t('label_scope')}
          value={getScopeSelectorDescription()}
          value2={getScopeDateRangeDescription()}
          onClick={() =>
            this.setState({
              scopeSelectorAnchorEl: this.refScopeSelectorButton.current,
            })
          }
        />
      </>
    );

    const dateRangeSelector = !config.filters.dateFilter.enabled ? null : (
      <ToolbarFilterButton
        prompt="Date range"
        value="1.1.2019 - 18.2.2019"
        onClick={handleDateRangeSelectorClick}
      />
    );

    const customFilterConfig = !onGetCustomFilters
      ? null
      : onGetCustomFilters();
    const customFilters =
      !customFilterConfig || customFilterConfig.length === 0 || !settings.custom
        ? null
        : customFilterConfig.map((filter: ICustomFilter, ndx: number) => {
            if (filter.type === 'DROPDOWN_MENU') {
              const params = filter.params as ICustomFilterDropdownMenu;
              return (
                <ToolbarFilterDropdownButton
                  key={ndx}
                  label={translate.t(filter.params.buttonLabel)}
                  selectedItem={
                    settings.custom[filter.params.selectedSettingName]
                  }
                  data={params.values}
                  onSelect={(value: any) =>
                    this.handleCustomFilterChange(
                      filter.params.selectedSettingName,
                      value,
                    )
                  }
                />
              );
            } else if (filter.type === 'CHECKBOX') {
              return (
                <ToolbarFilterCheckbox
                  key={ndx}
                  label={translate.t(filter.params.buttonLabel)}
                  isChecked={settings.custom[filter.params.selectedSettingName]}
                  onCheck={(value: any) =>
                    this.handleCustomFilterChange(
                      filter.params.selectedSettingName,
                      value,
                    )
                  }
                />
              );
            } else if (filter.type === 'BUTTONGROUP') {
              return (
                <ToolbarFilterButtonGroup
                  key={ndx}
                  buttons={
                    settings.custom[filter.params.selectedSettingName].buttons
                  }
                  onSelect={(value: any) =>
                    this.handleCustomFilterChange(
                      filter.params.selectedSettingName,
                      value,
                    )
                  }
                />
              );
            } else if (filter.type === 'NONE') {
              return '';
            }

            return null;
          });

    return (
      <MuiGrid container justify="space-between" alignItems="center">
        <MuiGrid item>
          {scopeSelector}
          {dateRangeSelector}
          {customFilters}
        </MuiGrid>
        <MuiGrid item>
          <Button onClick={this.props.onExportExcel}>
            <Icon>share</Icon> Excel
          </Button>
        </MuiGrid>
      </MuiGrid>
    );
  }
}

export default ReportTools;

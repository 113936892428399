import { StyleRulesCallback, Theme } from '@material-ui/core';

export type StyleKeys = 'menuButton' | 'groupItem' | 'listItemInGroup';

export const styles: StyleRulesCallback<StyleKeys> = (theme: Theme) => ({
    menuButton: {
        marginRight: 0.5 * theme.spacing.unit,
    },
    groupItem: {
        fontWeight: theme.typography.fontWeightMedium,
        opacity: 1,
        paddingBottom: 0,
    },
    listItemInGroup: {
        paddingLeft: 3 * theme.spacing.unit,
    }
});

import { useCallback, useMemo } from 'react';

import { getLoggedUserCountry } from '@/old/utils/helper';

import { TimeBlockFormValues } from '../../dailySummaryModels';

import { TransactionsListInnerProps } from './transactionsListModels';
import { useAutoDeduction } from '@/app/components/TimeReporting/components/TimeSheet/components/DailySummary/components/TransactionsList/useAutoDeduction';

export const useTransactionsList = (props: TransactionsListInnerProps) => {
  const { getFormValues, setFormValue, watchFormValues, customFields } = props;
  const { hasAutoDeductionLock, isAutoDeductionRow } = useAutoDeduction(props);

  const updateTimeBlock = useCallback(
    (
      timeBlockId: string,
      overriddenTimeBlockData: Partial<TimeBlockFormValues>,
    ) => {
      const timeBlocks = getFormValues('timeBlocks');
      setFormValue([
        {
          timeBlocks: {
            ...timeBlocks,
            [timeBlockId]: {
              ...timeBlocks[timeBlockId],
              ...overriddenTimeBlockData,
            },
          },
        },
      ]);
    },
    [getFormValues, setFormValue],
  );

  const empCustomFields = useMemo(() => {
    return customFields.filter(customField => {
      return (
        !customField.countries ||
        !customField.countries.length ||
        customField.countries.some(({ value }) => {
          return value === getLoggedUserCountry();
        })
      );
    });
  }, [customFields]);

  return {
    inputTimeBlocks: watchFormValues('timeBlocks'),
    updateTimeBlock,
    empCustomFields,
    hasAutoDeductionLock,
    isAutoDeductionRow,
  };
};

import * as React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { WithStyles, TextField } from '@material-ui/core';
import translate from '@/app/utils/translate';
import scaleCompCommentsStyles from './scaleCompCommentsStyles';

type incomingProps = {
  generateTextFieldKey: any;
  evaluationCommentComponentChange: any;
  initialResponse: any;
  component: any;
  option: any;
  optionIndex: number;
  editMode: boolean;
};

type PropsType = incomingProps & WithStyles<typeof scaleCompCommentsStyles>;

class ScaleCompCommnets extends React.PureComponent<PropsType> {
  state = {
    showValidationError: false,
  };

  validateLength = (fieldValue: string) => {
    if (fieldValue.length === 254) {
      this.setState({ showValidationError: true });
    } else if (this.state.showValidationError) {
      this.setState({ showValidationError: false });
    }
  };

  render() {
    const {
      generateTextFieldKey,
      initialResponse,
      component,
      option,
      optionIndex,
      evaluationCommentComponentChange,
      editMode,
      classes,
    } = this.props;
    const { showValidationError } = this.state;

    return (
      <TextField
        key={generateTextFieldKey(
          `${component.id}`,
          initialResponse ? initialResponse.comment : '',
        )}
        id={`${component.id}-${optionIndex}`}
        classes={{ root: classes.root }}
        style={optionIndex === 0 ? { marginTop: '16px' } : {}}
        defaultValue={initialResponse ? initialResponse.comment : ''}
        onBlur={e =>
          evaluationCommentComponentChange(
            option.id,
            component.id,
            e.target.value,
          )
        }
        onChange={e => this.validateLength(e.target.value)}
        disabled={!editMode}
        margin="none"
        multiline={true}
        rows={1}
        rowsMax={5}
        inputProps={{ maxLength: 254 }}
        error={showValidationError ? true : false}
        helperText={
          showValidationError ? translate.t('laErrorMaxTextLen1') + ' 254' : ' '
        }
      />
    );
  }
}

const enhance = compose<any, any>(withStyles(scaleCompCommentsStyles));

export default enhance(ScaleCompCommnets);

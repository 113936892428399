import { Button } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import * as React from 'react';

import { CompanyTraining, CompanyTrainingParticipantRegistration } from '../../types';
import SendEmailPopover from './SendEmailPopover';
import { eventTargetAsHtmlElement } from '@/old/utils/helper';

type Props = {
  training: CompanyTraining;
  participants: CompanyTrainingParticipantRegistration[];
};

const SendEmailToParticipants: React.FC<Props> = ({ training, participants }) => {
  const [sendEmailPopupAnchorEl, setSendEmailPopupAnchorEl] = React.useState<HTMLElement|undefined>();

  const noneRecipientsSelected = React.useMemo(
    () => !participants.some(({ selected }) => selected),
    [participants],
  );

  const registrationIds = React.useMemo(
    () => participants.filter(({ selected }) => selected).map(({ id }) => id),
    [participants],
  );

  return (
    <>
      {sendEmailPopupAnchorEl && (
        <SendEmailPopover
          trainingId={training.id}
          registrationIds={registrationIds}
          anchorEl={sendEmailPopupAnchorEl}
          onClose={() => setSendEmailPopupAnchorEl(undefined)}
        />
      )}

      <Button
        color="primary"
        variant="text"
        disabled={noneRecipientsSelected}
        onClick={e => setSendEmailPopupAnchorEl(eventTargetAsHtmlElement(e.target))}
      >
        <EmailIcon />
      </Button>
    </>
  );
};

export default SendEmailToParticipants;

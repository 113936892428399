import React from 'react';
import { ErrorMessage } from 'formik';
import { FormHelperText } from '@material-ui/core';

type OwnProps = {
  name: string;
};
type Props = OwnProps;

export function FormikFieldError({
  name,
}: Props) {
  return (
    <ErrorMessage
      name={name}
      render={(errorMsg: string) => (
        <FormHelperText
          error={true}
        >
          {errorMsg}
        </FormHelperText>
      )}
    />
  );
}

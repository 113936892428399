import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { FieldErrors } from 'react-hook-form';

import translate from '@/app/utils/translate';

import { ReportingGroupType } from '../../../../data';
import { TimeBlockFormValues } from '../../dailySummaryModels';

import { TransactionRow } from './components/TransactionRow';
import { TransactionsListInnerProps } from './transactionsListModels';
import { useTransactionsList } from './useTransactionsList';

export const TransactionsList: React.FC<TransactionsListInnerProps> = (props) => {
    const {
        inputTimeBlocks,
        updateTimeBlock,
        empCustomFields,
        hasAutoDeductionLock,
        isAutoDeductionRow,
    } = useTransactionsList(props);

    return (
        <div className={props.classes.root}>
            <Grid container={true} spacing={8} wrap="nowrap">
                <Grid item={true} xs={2}>
                    <Typography variant="subtitle1">{translate.t('laType')}</Typography>
                </Grid>
                <Grid item={true} xs={1}>
                    <Typography variant="subtitle1">{translate.t('laStarted')}</Typography>
                </Grid>
                <Grid item={true} xs={1}>
                    <Typography variant="subtitle1">{translate.t('laEnd')}</Typography>
                </Grid>
                <Grid item={true} xs={1}>
                    <Typography variant="subtitle1">{translate.t('laTime')}</Typography>
                </Grid>

                {
                    empCustomFields.map((empCustomField) => {
                        return (
                            <Grid key={empCustomField.code} item={true} xs={2}>
                                <Typography variant="subtitle1" noWrap={true}>
                                    {empCustomField.name}
                                </Typography>
                            </Grid>
                        );
                    })
                }
            </Grid>

            {
                (
                    props.inEditMode &&
                    Object.keys(inputTimeBlocks).map((timeBlockId) => {
                        const [actualReportingGroupType] = (inputTimeBlocks[timeBlockId].reportingType || '').split('-');

                        return (
                            <TransactionRow
                                key={timeBlockId}
                                id={timeBlockId}
                                reportingType={inputTimeBlocks[timeBlockId].reportingType}
                                start={
                                    inputTimeBlocks[timeBlockId].start &&
                                    moment(inputTimeBlocks[timeBlockId].start).format('HH:mm:ss')
                                }
                                end={
                                    inputTimeBlocks[timeBlockId].end &&
                                    moment(inputTimeBlocks[timeBlockId].end).format('HH:mm:ss')
                                }
                                hours={inputTimeBlocks[timeBlockId].hours}
                                customData={inputTimeBlocks[timeBlockId].customData}
                                currentDate={props.currentDate}
                                date={props.date}
                                isSaving={props.isSaving}
                                inEditMode={
                                    /**
                                     * Make sure to disable edit mode for correction type
                                     * transactions if you dont have permissions.
                                     */
                                    props.canManageEverything ||
                                    actualReportingGroupType !== ReportingGroupType.TIMESHEET_CORRECTION_TYPE
                                }
                                inAutoDeductionEditMode={isAutoDeductionRow(timeBlockId)}
                                canManageEverything={props.canManageEverything}
                                formErrors={
                                    props.formErrors.timeBlocks &&
                                    props.formErrors.timeBlocks[timeBlockId] as FieldErrors<TimeBlockFormValues>
                                }
                                formValues={inputTimeBlocks[timeBlockId]}
                                getFormValues={props.getFormValues}
                                updateFormValues={updateTimeBlock}
                                customFields={empCustomFields}
                                onTransactionRowDelete={props.onTransactionRowDelete}
                                autoDeductionLock={hasAutoDeductionLock(timeBlockId)}
                            />
                        );
                    })
                ) || (
                    props.timeBlocks && !!props.timeBlocks.length &&
                    props.timeBlocks.map((timeBlock) => {
                        const reportingType = timeBlock.reportingGroupType && timeBlock.reportingType &&
                            `${timeBlock.reportingGroupType}-${timeBlock.reportingType}`;

                        return (
                            <TransactionRow
                                key={timeBlock.id}
                                id={timeBlock.id.toString()}
                                reportingType={reportingType}
                                start={timeBlock.from}
                                end={timeBlock.to}
                                hours={timeBlock.hours}
                                customData={timeBlock.customData}
                                currentDate={props.currentDate}
                                date={props.date}
                                isSaving={props.isSaving}
                                inEditMode={false}
                                canManageEverything={props.canManageEverything}
                                getFormValues={props.getFormValues}
                                updateFormValues={updateTimeBlock}
                                customFields={empCustomFields}
                                onTransactionRowDelete={props.onTransactionRowDelete}
                            />
                        );
                    }) || (
                        <Typography color="textSecondary">
                            {translate.t('laNoTransactionsEntered')}
                        </Typography>
                    )
                )
            }
        </div>
    );
};

import {
  Button,
  Grid,
  IconButton,
  Modal,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Loading from '@/app/components/Loading/Loading';
import {
  getDefault,
  getLatestArchiveOfEvaluations,
  orderElementsByIndex,
  translateCompletionStatus,
} from '@/app/components/TemplateComponents/helpers';
import { ReducerState } from '@/app/redux/store';
import translate from '@/app/utils/translate';
import { getEmployeeName, getLoggedUserId } from '@/old/utils/helper';

import SaveChanges from './EditTemplate/SectionListing/ComponentListing/SaveChanges';
import SectionListing from './EditTemplate/SectionListing/SectionListing';
import templateSelectionStyles from './templateSelectionStyles';
import {
  ActiveTemplate,
  ComponentItem,
  EvaluationStage,
  EvaluationTemplate,
  JobReqEvalSection,
  JobReqEvaluationResponse,
} from '../../types';
import { ConfirmDialogType } from '@/app/redux/confirmDialog';
import { JobEvaluationStatus, Status } from '../../enums';

type IncomingProps = {
  templates: EvaluationTemplate[];
  archivedEvaluations: [];
  activeTemplate: ActiveTemplate;
  updateEvaluation: (
    templateId: string,
    responses: JobReqEvaluationResponse,
  ) => Promise<void>;
  openConfirmDialog: (payload: ConfirmDialogType) => Promise<void>;
  clearEvaluation: () => Promise<void>;
  handleEvaluationsSave: (
    evaluationId: string,
    component: ComponentItem,
  ) => Promise<void>;
  handleTemplateChange: (selectedTemplateId: string) => Promise<void>;
  changeEvaluationApprovement: (
    evaluationId: string,
    newApprovedBy: string,
  ) => Promise<void>;
  archiveEvaluation: (evaluationId: string) => Promise<void>;
  setActiveTemplate: (
    template: any,
    employeeId: number,
    fromArchive?: boolean,
  ) => Promise<void>;
  employeeId: number;
  notStartedStatus: boolean;
  changeEvaluationStage: (
    setStage: EvaluationStage,
    userMakingTheChange: string,
  ) => Promise<void>;
};

type MapStateToProps = { totalScore: number };

type PropsType = IncomingProps &
  MapStateToProps &
  WithStyles<typeof templateSelectionStyles>;
const GLOBAL: any = window;
class TemplateSelection extends React.PureComponent<PropsType> {
  state = {
    editMode: false,
    saveModalOpen: false,
    isLoading: false,
    open: false,
  };

  toggleEditMode = () => {
    this.setState({ editMode: !this.state.editMode });
  };

  cancelEvaluation = () => {
    this.props.openConfirmDialog({
      text: translate.t('text_unsaved_changes'),
      onOk: () => {
        this.props.clearEvaluation();
        this.props.setActiveTemplate(
          this.props.activeTemplate,
          this.props.employeeId,
        );
        this.setState({ editMode: !this.state.editMode });
      },
    });
  };

  isEditDisabled = () => {
    const { activeTemplate } = this.props;
    const sectionsHasNoComponents = !activeTemplate.sections.some(
      (section: JobReqEvalSection) => section.components.length,
    );
    const afterDeadline = moment(activeTemplate.deadline)
      .endOf('day')
      .isBefore(new Date());

    if (sectionsHasNoComponents || afterDeadline || activeTemplate.archived) {
      return true;
    }

    if (!activeTemplate.isTeam) {
      return false;
    }

    if (
      !(GLOBAL.iHR || GLOBAL.iManager || GLOBAL.iDManager) ||
      (activeTemplate.responses &&
        getLoggedUserId() !== activeTemplate.updatedBy)
    ) {
      return true;
    }

    return false;
  };

  handleSaveModalClose = () => {
    this.setState({ saveModalOpen: false });
  };

  openSaveChangesModal = () => {
    this.setState({ saveModalOpen: true });
  };

  onTemplateChange = (evalId: string) => {
    this.setState({ isLoading: true });
    this.props.handleTemplateChange(evalId).then(() => {
      this.setState({ isLoading: false });
    });
  };

  render() {
    const {
      classes,
      templates,
      archivedEvaluations,
      activeTemplate,
      changeEvaluationApprovement,
      archiveEvaluation,
      updateEvaluation,
      employeeId,
      notStartedStatus,
      handleEvaluationsSave,
      changeEvaluationStage,
      totalScore,
    } = this.props;

    const { editMode, saveModalOpen, isLoading } = this.state;
    const editIsDisabled = this.isEditDisabled();
    const orgUnitId = GLOBAL.jsonEmpData;
    const currentUserName = getEmployeeName(getLoggedUserId(), true);
    const orderedSections = orderElementsByIndex(activeTemplate.sections);
    const latestArchives = getLatestArchiveOfEvaluations(archivedEvaluations);
    const activeStage = activeTemplate.setStage
      ? activeTemplate.stages.filter(
          (stage: any) => stage.id === activeTemplate.setStage.id,
        )[0]
      : getDefault(activeTemplate.stages);

    const everyone = (section: JobReqEvalSection) => {
      return editMode && !section.onlyEmpAccess && section.empAccess;
    };

    const everyoneButEmpExceptHRorManagOwnProf = (
      section: JobReqEvalSection,
    ) => {
      return (
        editMode &&
        !section.onlyEmpAccess &&
        (!GLOBAL.iEmployee || GLOBAL.aMyManUnits.indexOf(orgUnitId) > -1) &&
        getLoggedUserId() !== employeeId
      );
    };

    const onlyEmpAndHR = (section: JobReqEvalSection) => {
      return (
        editMode &&
        section.onlyEmpAccess &&
        ((GLOBAL.iEmployee && !GLOBAL.iITAdmin) ||
          GLOBAL.iHR ||
          getLoggedUserId() === employeeId)
      );
    };

    const teamTemplate = () => {
      return editMode && activeTemplate.isTeam;
    };

    return (
      <Paper
        className={
          editMode ? [classes.root, 'cssEditMode'].join(' ') : classes.root
        }
        id="evaluationToPrint"
      >
        <form>
          <div>
            <Select
              native
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                this.onTemplateChange(event.target.value)
              }
              className={`${classes.select} ${
                editMode ? classes.selectDisabled : classes.selectEnabled
              }`}
              inputProps={{
                classes: {
                  icon: editMode ? classes.iconDisabled : classes.icon,
                  root: editMode ? classes.inputDisabled : classes.inputColor,
                },
              }}
              disabled={editMode}
            >
              <optgroup label={translate.t('laActive')}>
                {templates.map(
                  (templateOption: EvaluationTemplate) =>
                    templateOption.status === Status.ACTIVE &&
                    moment(templateOption.deadline)
                      .endOf('day')
                      .isAfter(new Date()) && (
                      <option
                        className={classes.option}
                        key={templateOption.id}
                        value={templateOption.id}
                      >
                        {templateOption.subject}
                      </option>
                    ),
                )}
              </optgroup>
              <optgroup label={translate.t('laLocked')}>
                {templates.map(
                  (templateOption: EvaluationTemplate) =>
                    templateOption.status === Status.ACTIVE &&
                    moment(templateOption.deadline)
                      .endOf('day')
                      .isBefore(new Date()) && (
                      <option
                        className={classes.option}
                        key={templateOption.id}
                        value={templateOption.id}
                      >
                        {templateOption.subject}
                      </option>
                    ),
                )}
              </optgroup>
              <optgroup
                label={`${translate.t('laArchived')} (${translate.t(
                  'laEvaluations',
                )})`}
              >
                {latestArchives.map((templateOption: any) => (
                  <option key={templateOption.id} value={templateOption.id}>
                    {templateOption.templateSubject}
                  </option>
                ))}
              </optgroup>
            </Select>
            {!editMode && (
              <>
                <IconButton
                  disabled={editIsDisabled}
                  className={classes.iconButton}
                  onClick={() => this.toggleEditMode()}
                >
                  <Tooltip title={translate.t('laEdit')}>
                    <EditIcon color={editIsDisabled ? 'disabled' : 'primary'} />
                  </Tooltip>
                </IconButton>
              </>
            )}
          </div>
          {editMode && (
            <span className={classes.buttonWrapper}>
              <Button
                color="primary"
                variant="text"
                className={classes.button}
                onClick={() => this.cancelEvaluation()}
              >
                {translate.t('laCancel')}
              </Button>
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={() => this.openSaveChangesModal()}
              >
                {translate.t('laSave')}
              </Button>
            </span>
          )}
          <div className={classes.flexWrapper}>
            <TextField
              label={translate.t('laApprovalStatus')}
              inputProps={{ readOnly: true, disabled: true }}
              value={
                notStartedStatus
                  ? translate.t('label_notStarted')
                  : translateCompletionStatus(activeTemplate.completionStatus)
              }
              className={classes.spacing}
            />
            {activeStage && (
              <TextField
                label={translate.t('laStage')}
                inputProps={{
                  readOnly: true,
                  disabled: true,
                }}
                value={`${activeStage ? activeStage.label : ''}`}
                className={`${classes.spacing} ${classes.textField}`}
              />
            )}
            <TextField
              label={translate.t('laDeadline')}
              inputProps={{ readOnly: true, disabled: true }}
              value={activeTemplate.deadline}
              className={classes.spacing}
            />
            <TextField
              label={translate.t('laModifiedTime')}
              inputProps={{ readOnly: true, disabled: true }}
              value={`${activeTemplate.lastEdited}, ${activeTemplate.lastModifiedBy}`}
              className={`${classes.spacing} ${classes.textField}`}
            />
            <TextField
              label={translate.t('laApproved')}
              inputProps={{ readOnly: true, disabled: true }}
              InputLabelProps={{ shrink: true }}
              value={activeTemplate.approvedBy}
              className={`${classes.spacing} ${classes.approved}`}
            />
            <div className={classes.removeApprovalWrapper}>
              {activeTemplate.archived && (
                <Typography variant="h3">
                  {translate.t('laArchived')}
                </Typography>
              )}
              {!activeTemplate.archived && !editMode && (
                <>
                  <Tooltip title={translate.t('laEvaluationTooltipApprove')}>
                    <span>
                      <Button
                        className={classes.button}
                        color="primary"
                        variant="text"
                        onClick={() =>
                          changeEvaluationApprovement(
                            activeTemplate.currentEvaluationId,
                            currentUserName,
                          )
                        }
                        disabled={
                          activeTemplate.completionStatus ===
                            JobEvaluationStatus.IN_PROGRESS ||
                          !activeTemplate.currentEvaluationId ||
                          editIsDisabled
                        }
                      >
                        {activeTemplate.approvedBy.includes(currentUserName)
                          ? translate.t('laRemoveApproval')
                          : translate.t('laApprove')}
                      </Button>
                    </span>
                  </Tooltip>
                  <Tooltip title={translate.t('laEvaluationTooltipArchive')}>
                    <span>
                      <Button
                        className={classes.button}
                        color="primary"
                        variant="text"
                        onClick={() => {
                          archiveEvaluation(activeTemplate.currentEvaluationId);
                        }}
                        disabled={
                          activeTemplate.archived ||
                          activeTemplate.completionStatus ===
                            JobEvaluationStatus.IN_PROGRESS ||
                          !activeTemplate.currentEvaluationId ||
                          editIsDisabled
                        }
                      >
                        {translate.t('laArchive')}
                      </Button>
                    </span>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
        </form>
        {isLoading && <Loading key="loading" />}
        {!isLoading && orderedSections && orderedSections.length > 0 ? (
          <Grid wrap="nowrap" container direction="column">
            <Grid item>
              {orderedSections.map((section: JobReqEvalSection) => (
                <SectionListing
                  key={section.id}
                  template={activeTemplate}
                  section={section}
                  list={[
                    {
                      key: section.id,
                      label: section.name,
                      componentsList: section.components || [],
                    },
                  ]}
                  editMode={
                    everyone(section) ||
                    everyoneButEmpExceptHRorManagOwnProf(section) ||
                    onlyEmpAndHR(section) ||
                    teamTemplate()
                  }
                  updateEvaluation={updateEvaluation}
                />
              ))}
            </Grid>
            <Grid container item>
              <Grid item xs={7} />
              <Grid item xs={5}>
                <Typography variant="h6">
                  <strong>
                    {translate.t('laEvalScoreTotal')} {totalScore}
                  </strong>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          !isLoading && (
            <Typography className={classes.noSections}>
              {translate.t('no_sections')}
            </Typography>
          )
        )}
        <Modal open={saveModalOpen} onClose={() => this.handleSaveModalClose()}>
          <SaveChanges
            employeeId={employeeId}
            completionStatus={activeTemplate.completionStatus}
            activeTemplate={activeTemplate}
            handleModalClose={this.handleSaveModalClose}
            handleEvaluationsSave={handleEvaluationsSave}
            toggleEditMode={this.toggleEditMode}
            archiveEvaluation={archiveEvaluation}
            changeEvaluationStage={changeEvaluationStage}
          />
        </Modal>
      </Paper>
    );
  }
}

const mapStateToProps = (state: ReducerState) => ({
  totalScore: state.jobRequirementEvaluations.get('totalScore'),
});

const enhance = compose<PropsType, IncomingProps>(
  connect(mapStateToProps),
  withStyles(templateSelectionStyles),
);

export default enhance(TemplateSelection);

import { WorkflowReportTypes } from '@/app/components/WorkflowsReport/ReportTypes';
import { intPositionGridColumns } from '@/app/components/WorkflowsReport/ReportTypes/InternalPosition';
import { empDetailsGridColumns } from '@/app/components/WorkflowsReport/ReportTypes/EmploymentDetails';
import { rewardingGridColumns } from '@/app/components/WorkflowsReport/ReportTypes/Rewarding';
import { ColumnConfig } from '@/old/utils/helper';

export const getGridColumns = (
  reportType: WorkflowReportTypes,
): ColumnConfig[] => {
  switch (reportType) {
    case WorkflowReportTypes.EmployeeDetailsClosed:
    case WorkflowReportTypes.EmployeeDetailsOpen:
      return empDetailsGridColumns();
    case WorkflowReportTypes.InternalPositionClosed:
    case WorkflowReportTypes.InternalPositionOpen:
      return intPositionGridColumns();
    case WorkflowReportTypes.RewardingClosed:
    case WorkflowReportTypes.RewardingOpen:
      return rewardingGridColumns();
    default:
      return [];
  }
};

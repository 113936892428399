import { StyleRulesCallback } from '@material-ui/core';

export const hoursSettingsStyles: StyleRulesCallback = () => ({
  root: {
    padding: 24,
  },
  selectContainer: {
    width: 200,
    marginBottom: 16,
  },
  flex: {
    display: 'flex',
  },
  item: {
    marginRight: 16,
    width: 215,
  },
});

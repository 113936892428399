const GLOBAL: any = window;

export default () => {
  if (!GLOBAL.jsonEnumALL || !GLOBAL.jsonEnumALL.TRAINING_CLASS) {
    return [];
  }

  return GLOBAL.jsonEnumALL.TRAINING_CLASS.filter(
    (item: any) => item.isActive !== false,
  ).map((item: any) => ({
    code: item.code,
    name: item.name,
  }));
};

import {
  Checkbox,
  Grid,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import DateSelectElement from './DateSelectElement';
import { styles } from './styles';
import WorkExperienceLine from './WorkExperienceLine';

import API from '@/app/api/internalAPIs';
import Service from '@/app/utils/service';
import {
  getSelectedEmpId,
  getUserEmploymentStartDate,
  getUserOriginalStartDate,
  toEUDate,
  toISODate,
} from '@/old/utils/helper';
import {
  MappedData,
  workExperienceDataMapper,
  WorkExperienceType,
} from './data';
import translate from '@/app/utils/translate';
import { useSnackbar } from 'notistack';
import Loading from '../Loading/Loading';

type OwnProps = {};

type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const WorkExperience = ({ classes }: Props) => {
  const [startDate, setStartDate] = useState<string | undefined>();
  const [endDate, setEndDate] = useState<string | undefined>();
  const [originalStartDateCheck, setOriginalStartDateCheck] = useState<boolean>(
    false,
  );
  const [data, setData] = useState<MappedData>();
  const [loading, setLoading] = useState(false);

  const originalStartDate = getUserOriginalStartDate()
    ? toEUDate(getUserOriginalStartDate())
    : undefined;
  const employmentStartDate = toEUDate(getUserEmploymentStartDate());
  const selectedEmpId = getSelectedEmpId();

  const { enqueueSnackbar } = useSnackbar();

  const createInterval = () => {
    if (!originalStartDateCheck && startDate && endDate) {
      const endDateString = `&endDate=${toISODate(endDate)}`;
      const startDateString = `startDate=${toISODate(startDate)}`;
      return `${startDateString}${endDateString}`;
    }

    if (originalStartDateCheck) {
      return `startDate=${toISODate(originalStartDate)}`;
    }

    return `startDate=${toISODate(employmentStartDate)}`;
  };

  useEffect(() => {
    const weInterval = createInterval();
    setLoading(true);
    Service.get(
      API.workExperience(weInterval, selectedEmpId),
      (resp: WorkExperienceType[]) => {
        setData(workExperienceDataMapper(resp[0]));
        setLoading(false);
      },
      () => {
        setLoading(false);
        enqueueSnackbar(translate.t('laGeneralError'), {
          variant: 'error',
        });
      },
    );
  }, [startDate, endDate, originalStartDateCheck]);

  const handleStartDateChange = (date: string | undefined) => {
    setStartDate(date ? date : undefined);
  };

  const handleEndDateChange = (date: string | undefined) => {
    setEndDate(date ? date : undefined);
  };

  const dataElement = (date: string, label: string) => (
    <Grid item container direction="row" className={classes.alignCenter}>
      <Typography>
        {label}: {date}
      </Typography>
      <Checkbox
        checked={originalStartDateCheck}
        disabled={startDate !== undefined || endDate !== undefined}
        onChange={() => {
          setOriginalStartDateCheck(!originalStartDateCheck);
        }}
      />
    </Grid>
  );

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item className={!originalStartDate ? classes.defaultMargin : ''}>
        <Typography>{`${translate.t(
          'defaultStartDate',
        )}: ${employmentStartDate} (${translate.t(
          'fEmplDateStart',
        )})`}</Typography>
      </Grid>
      {originalStartDate &&
        dataElement(originalStartDate, translate.t('laEmpOrigStartDate'))}
      <DateSelectElement
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        originalStartDateCheck={originalStartDateCheck}
      />
      <div className={classes.listRoot}>
        {loading || !data ? (
          <Loading />
        ) : (
          <>
            <WorkExperienceLine title="laEmployment" data={data.employment} />
            <WorkExperienceLine
              title="laLongTermAbsences"
              data={data.longTermAbsences}
            />
            <WorkExperienceLine
              title="laPreviousCompany"
              data={data.previousCompany}
            />
            <WorkExperienceLine
              title="laPreviousAbsences"
              data={data.previousAbsences}
            />
            <WorkExperienceLine
              title="laOtherExperience"
              data={data.otherExperience}
            />
            <WorkExperienceLine
              title="workExperience"
              data={data.workExperience}
            />
          </>
        )}
      </div>
    </Grid>
  );
};

const enhance = compose<Props, OwnProps>(withStyles(styles));

export default enhance(WorkExperience);

import { Checkbox, FormControl, FormControlLabel, FormGroup, Paper, Typography, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { compose } from 'recompose';

import translate from '@/app/utils/translate';
import { getEmployeeName, getLoggedUserId } from '@/old/utils/helper';

import evaluationVisibilityStyles from './evaluationVisibilityStyles';

type IncomingProps = {
  activeTemplate: any;
  changeRoleVisibility: any;
  editMode: boolean;
  employeeId: string;
};

type PropsType = IncomingProps & WithStyles<typeof evaluationVisibilityStyles>;

const GLOBAL: any = window;

function EvaluationVisibility(props: PropsType) {
  const { classes, editMode, activeTemplate, changeRoleVisibility, employeeId } = props;

  const handleRoleVisibilityFlagChange = (p: string) => (event: any) => {
    const currentUserName = getEmployeeName(getLoggedUserId(), true);
    changeRoleVisibility(p, event.target.checked, currentUserName);
  };

  React.useEffect(
    () => {
      // Check by default when HR or Manager only in edit mode
      if (editMode && employeeId !== getLoggedUserId() && !GLOBAL.iHRAdmin) {
        if (GLOBAL.iHR && !activeTemplate.responses) {
          changeRoleVisibility('hrAccess', true, getEmployeeName(getLoggedUserId(), true));
        }

        if (GLOBAL.iManager && !activeTemplate.responses) {
          changeRoleVisibility('mgrAccess', true, getEmployeeName(getLoggedUserId(), true));
        }
      }
    },
    [editMode]
  );

  return (
    <Paper className={classes.container}>
      <FormControl className={classes.wrapper} disabled={!editMode}>
        <Typography
          className={classes.typography}
          variant="subtitle2"
        >
          {translate.t('laVisibleBy') + ':'}
        </Typography>

        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={activeTemplate.mgrAccess}
                onChange={handleRoleVisibilityFlagChange('mgrAccess')}
                disabled={!editMode}
                color="primary"
              />
            }
            label={translate.t('laManager')}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={activeTemplate.hrAccess}
                onChange={handleRoleVisibilityFlagChange('hrAccess')}
                disabled={!editMode}
                color="primary"
              />
            }
            label={translate.t('laHR')}
          />
        </FormGroup>
      </FormControl>
    </Paper>
  );
}

const enhance = compose<any, any>(withStyles(evaluationVisibilityStyles));

export default enhance(EvaluationVisibility);

import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Popover,
  Select,
  TextField,
  Tooltip,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import CKEditor from 'react-ckeditor-component';
import { ErrorMessage, useForm } from 'react-hook-form';
import * as yup from 'yup';

import APIs from '@/app/api/internalAPIs';
import { useYupValidationResolver } from '@/app/hooks/useYupValidationResolver';
import { DEFAULT_LANG, getCurrentLanguage } from '@/app/utils/helper';
import translate from '@/app/utils/translate';
import Service from '@/app/utils/service';
import { useState } from 'react';
import styles from './styles';

type EmailTemplate = {
  fTrainingMailKey: string;
  fTrainingMailName: string;
  fTrainingMailSubject: string;
  fTrainingMailBody: string;
};

type OwnProps = {
  trainingId: number;
  registrationIds: string[];
  anchorEl: any;
  onClose: () => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

type EmailData = {
  subject: string;
  body: string;
};

const SendEmailPopover: React.FC<Props> = ({
  classes,
  trainingId,
  registrationIds,
  anchorEl,
  onClose,
}) => {
  const [selectValue, setSelectValue] = useState('');
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const emailValidationResolver = React.useMemo(
    () =>
      yup.object().shape<EmailData>({
        subject: yup.string().required(translate.t('laThisRequired')),
        body: yup.string().required(translate.t('laThisRequired')),
      }),
    [],
  );

  const { register, watch, handleSubmit, setValue, errors } = useForm<
    EmailData
  >({
    validationResolver: useYupValidationResolver(emailValidationResolver),
  });

  React.useEffect(() => {
    Service.get(
      APIs.training.getEmailTemplates(),
      (response: EmailTemplate[]) => {
        setEmailTemplates(response);
      },
      () => {
        enqueueSnackbar(translate.t('laFailedToLoad'), {
          variant: 'error',
        });
      },
    );
  }, []);

  React.useEffect(() => {
    register('body');
  }, [register]);

  if (!anchorEl) {
    return null;
  }

  const selectOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectValue(event.target.value);
    const selectedTemplate = emailTemplates.find(
      template => template.fTrainingMailKey === event.target.value,
    );
    setValue('subject', selectedTemplate.fTrainingMailSubject);
    setValue('body', selectedTemplate.fTrainingMailBody);
  };

  const clearTemplate = () => {
    setSelectValue('');
    setValue('subject', '');
    setValue('body', '');
  };

  const onFormSubmit = (emailData: EmailData) => {
    const formData = new FormData();
    registrationIds.forEach(registrationId => {
      formData.append('registrationIds[]', registrationId);
    });
    selectedFiles.forEach(selectedFile => {
      formData.append('files[]', selectedFile);
    });
    formData.append('body', emailData.body);
    formData.append('subject', emailData.subject);
    Service.postFormData(
      APIs.training.sendEmail(trainingId),
      formData,
      () => {
        enqueueSnackbar(translate.t('laTrainingEmailsSent'), {
          variant: 'success',
        });
      },
      () => {
        enqueueSnackbar(translate.t('laFailedToSendTrainingEmails'), {
          variant: 'error',
        });
      },
    );

    onClose();
  };

  const handleFileDelete = () => {
    setSelectedFiles([]);
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFiles(Array.from(event.target.files));
  };

  const listOfFileNames = () => (
    <div className={classes.tooltip}>
      {selectedFiles.map(file => (
        <span>{file.name}</span>
      ))}
    </div>
  );

  const multipleFiles = () => (
    <Tooltip title={listOfFileNames()}>
      <span>{`${selectedFiles.length} ${translate.t('laAttachedFile')}`}</span>
    </Tooltip>
  );

  const displayFiles = () => {
    if (selectedFiles.length > 0) {
      return selectedFiles.length > 1 ? multipleFiles() : selectedFiles[0].name;
    }
    return translate.t('laNoFile');
  };

  return (
    <Popover
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Paper className={classes.paper}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <div className={classes.body}>
            <InputLabel htmlFor="email-subject">
              {translate.t('laSubject')}
            </InputLabel>
            <TextField
              inputRef={register}
              name="subject"
              margin="dense"
              inputProps={{
                id: 'email-subject',
              }}
              error={!!errors.subject}
              helperText={
                errors.subject && (
                  <ErrorMessage errors={errors} name="subject" />
                )
              }
              fullWidth
            />

            <FormControl margin="dense" fullWidth>
              <CKEditor
                activeClass="p10"
                content={watch('body')}
                events={{
                  blur: (event: any) =>
                    setValue('body', event.editor.getData()),
                  change: (event: any) =>
                    setValue('body', event.editor.getData()),
                }}
                config={{
                  toolbar: [
                    ['Format', 'FontSize', 'RemoveFormat'],
                    ['Cut', 'Copy', 'Paste'],
                    ['Bold', 'Italic', 'Underline'],
                    ['TextColor', 'BGColor'],
                    ['NumberedList', 'BulletedList', 'Outdent', 'Indent'],
                    ['Link', 'Unlink'],
                    ['Image', 'Table', 'HorizontalRule'],
                  ],
                  defaultLanguage: DEFAULT_LANG,
                  language: getCurrentLanguage(),
                  baseFloatZIndex: 10401,
                }}
              />

              {errors.body && (
                <FormHelperText error>
                  <ErrorMessage errors={errors} name="body" />
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={8} container direction="row" alignItems="center">
              <FormControl className={classes.templateSelect}>
                <InputLabel htmlFor="email-template-select">
                  {translate.t('laEmailTemplates')}
                </InputLabel>
                <Select
                  inputProps={{
                    id: 'email-template-select',
                  }}
                  value={selectValue}
                  onChange={selectOnChange}
                >
                  {emailTemplates &&
                    emailTemplates.map(template => (
                      <MenuItem
                        key={template.fTrainingMailKey}
                        value={template.fTrainingMailKey}
                      >
                        {template.fTrainingMailName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {selectValue && (
                <IconButton
                  disableRipple
                  onClick={clearTemplate}
                  className={classes.clearButton}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              )}
            </Grid>
            <Grid
              container
              xs={4}
              item
              justify="flex-end"
              className={classes.actions}
            >
              <Button color="primary" onClick={onClose}>
                {translate.t('laCancel')}
              </Button>
              <Button type="submit" color="primary" variant="contained">
                <SendIcon className={classes.iconButton} />
                {translate.t('laSend')}
              </Button>
            </Grid>
          </Grid>
          <div className={classes.download}>
            <span>{displayFiles()}</span>
            <input
              accept="*"
              className={classes.inputFileUpload}
              id={'attachment-upload'}
              multiple
              type="file"
              onChange={handleFileUpload}
            />
            <label htmlFor={'attachment-upload'}>
              <Button
                className={classes.rightButton}
                variant="contained"
                component="span"
              >
                {selectedFiles.length > 0
                  ? translate.t('laChangeFile')
                  : translate.t('laChooseFile')}
              </Button>
            </label>
            {selectedFiles.length > 0 && (
              <IconButton
                className={classes.deleteButton}
                onClick={handleFileDelete}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        </form>
      </Paper>
    </Popover>
  );
};

export default withStyles(styles)(SendEmailPopover);

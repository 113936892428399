import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const trainingFormDialogStyle: StyleRulesCallback = () => {
  return {
    content: {
      minWidth: '500px',
    },
    dialogActions: {
      margin: '8px 20px',
    },
    dialogTitle: {
      wordBreak: 'break-word',
    },
  };
};

export default trainingFormDialogStyle;

import { Paper, IconButton, Button, Typography } from '@material-ui/core';
import { Add, Save, Close, ExpandMore } from '@material-ui/icons';
import classNames from 'classnames';
import * as React from 'react';
import { ErrorMessage } from 'react-hook-form';

import translate from '@/app/utils/translate';

import { DailySummaryRow } from './components/DailySummaryRow';
import { TransactionsList } from './components/TransactionsList';
import { DailySummaryInnerProps } from './dailySummaryModels';
import { useDailySummary } from './useDailySummary';

export const DailySummary: React.FC<DailySummaryInnerProps> = props => {
  const {
    timeCardIsArchived,
    timeCardData,
    isLoadingAdditionalData,
    isApprovedVacation,
    isExpanded,
    handlePanelToggle,
    inEditMode,
    handleEditModeEnter,
    handleEditModeLeave,
    timerStartIsConfigured,
    handleStartTimer,
    handleStopTimer,
    formErrors,
    watchFormValues,
    getFormValues,
    setFormValue,
    handleDeleteTransactionRow,
    handleAddNewTransactionRow,
    handleSaveDailySummary,
    autoDeductionRule,
  } = useDailySummary(props);

  const {
    canManageBasicData,
    canManageEverything,
    classes,
    currentDate,
    date,
    isPublicHoliday,
    isSaving,
    placeholder,
    showDailyCumulativeBalance,
  } = props;

  const timeCardIsEditable = !placeholder && !timeCardIsArchived;

  return (
    <Paper
      className={classNames(classes.root, {
        [classes.expanded]: isExpanded,
        [classes.publicHoliday]: isPublicHoliday,
        [classes.approvedVacation]: isApprovedVacation,
      })}
    >
      <form onSubmit={handleSaveDailySummary}>
        <div className={classes.header}>
          <IconButton
            disabled={inEditMode || placeholder}
            onClick={handlePanelToggle}
            className={classes.toggle}
          >
            <ExpandMore />
          </IconButton>

          <DailySummaryRow
            currentDate={currentDate}
            date={date}
            timeCard={timeCardData}
            isSaving={isSaving}
            isLoadingAdditionalData={isLoadingAdditionalData}
            inEditMode={inEditMode}
            allowEditMode={
              timeCardIsEditable && (canManageEverything || canManageBasicData)
            }
            canManageEverything={timeCardIsEditable && canManageEverything}
            showDailyCumulativeBalance={showDailyCumulativeBalance}
            formErrors={formErrors}
            watchFormValues={watchFormValues}
            setFormValue={setFormValue}
            onEditModeEnter={handleEditModeEnter}
            timerStartIsConfigured={timerStartIsConfigured}
            onStartTimer={handleStartTimer}
            onStopTimer={handleStopTimer}
          />
        </div>

        <div className={classes.body}>
          <div className={classes.content}>
            <TransactionsList
              currentDate={currentDate}
              date={date}
              isSaving={isSaving}
              inEditMode={inEditMode}
              timeBlocks={timeCardData && timeCardData.timeBlocks}
              canManageEverything={canManageEverything}
              formErrors={formErrors}
              watchFormValues={watchFormValues}
              getFormValues={getFormValues}
              setFormValue={setFormValue}
              onTransactionRowDelete={handleDeleteTransactionRow}
              autoDeductionRule={autoDeductionRule}
            />

            {formErrors.form && (
              <Typography className={classes.formErrors} color="error">
                <ErrorMessage errors={formErrors} name="form" />
              </Typography>
            )}

            {inEditMode && (
              <div className={classes.actions}>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleAddNewTransactionRow}
                  disabled={isSaving}
                >
                  <Add className={classes.buttonIcon} />
                  {translate.t('laAdd')}
                </Button>

                <Button
                  variant="outlined"
                  size="small"
                  type="submit"
                  className={classes.button}
                  disabled={isSaving}
                >
                  <Save className={classes.buttonIcon} />
                  {translate.t('laSave')}
                </Button>

                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleEditModeLeave}
                  disabled={isSaving}
                >
                  <Close className={classes.buttonIcon} />
                  {translate.t('laCancel')}
                </Button>
              </div>
            )}
          </div>
        </div>
      </form>
    </Paper>
  );
};

import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    actionBar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    downloadButton: {
      position: 'absolute',
      right: '0px',
    },
    datePicker: {
      width: 100,
      margin: '0px 0px 0px 16px',
    },
    externalSign: {
      fontSize: '10px',
      fontWeight: 'bold',
      color: '#05A4D8 !important',
      float: 'left',
    },
  });

export default styles;

import React from 'react';
import { Typography } from '@material-ui/core';

import { getRegistrationStatusName } from '../../helpers';
import { REGISTRATION_STATUS } from '../../types';

import RegistrationStatusIcon from './readOnlyRegistrationStatus/RegistrationStatusIcon';

type OwnProps = {
  registrationStatus: REGISTRATION_STATUS,
};
type InnerProps = {};
type Props = OwnProps & InnerProps;

const ReadOnlyRegistrationStatus = ({
  registrationStatus,
}: Props) => {
  return (
    <>
      <RegistrationStatusIcon registrationStatus={registrationStatus} />
      <Typography inline={true} variant="body2">
        {getRegistrationStatusName(registrationStatus)}
      </Typography>
    </>
  );
};

export default ReadOnlyRegistrationStatus;

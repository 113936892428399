import { Popover, Paper, Typography, StyleRulesCallback, Theme, WithStyles, withStyles } from '@material-ui/core';
import React from 'react';

const styles: StyleRulesCallback = (theme: Theme) => ({
  paper: {
    padding: theme.spacing.unit * 2,
    minWidth: 280,
  },
  message: {
    whiteSpace: 'pre',
  },
});

type OwnProps = {
  message: string;
  anchorEl: any;
  onClose: () => void;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const ExternalParticipantDetailsPopover = ({
  classes,
  message,
  anchorEl,
  onClose,
}: Props) => {
  if (!anchorEl) {
    return null;
  }

  return (
    <Popover
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      <Paper className={classes.paper}>
        <Typography className={classes.message}>
          {message}
        </Typography>
      </Paper>
    </Popover>
  );
};

export default withStyles(styles)(ExternalParticipantDetailsPopover);

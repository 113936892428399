import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const topMenuToolBarStyle: StyleRulesCallback = () => ({
  root: {
    float: 'right',
    marginRight: '25px',
  },
});

export default topMenuToolBarStyle;

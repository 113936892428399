import {
  Grid,
  GridColumn,
  GridColumnProps,
  GridToolbar,
  GridNoRecords,
  GridDataStateChangeEvent,
} from '@progress/kendo-react-grid';
import {
  process,
  SortDescriptor,
  State as GridState,
} from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {
  Button,
  Icon,
  Grid as MuiGrid,
  Typography,
  StyleRulesCallback,
  Theme,
  withStyles,
  CircularProgress,
  WithStyles,
} from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { compose } from 'recompose';

import translate from '@/app/utils/translate';
import { IReportConfig } from '../../../ReportConfig';
import ColumnMenu from '../../../components/ColumnMenu';
import { GoalsReportConfigFields, GoalsReportConfig } from '../../GoalsReport';

const styles: StyleRulesCallback = (theme: Theme) => ({
  title: {
    marginBottom: theme.spacing.unit,
  },
});

const PAGE_SIZE = 20;
type OwnProps = {
  config: IReportConfig;
  getActiveColumnsSettings: () => Record<string, unknown>;
  applyGoalReportSettings: (settings: object) => void;
  updateReportConfig?: (field: string, value: any) => void;
  updateConfigFromSettings?: boolean
  reportConfig: GoalsReportConfig;
  initialReportSettings: string;
  data: unknown[];
  exportFileName: string;
  isLoading: boolean;
  sort?: SortDescriptor[];
};

type Props = OwnProps & WithStyles<typeof styles>;
const EmpGoals = (props: Props) => {
  const {
    config,
    classes,
    data,
    exportFileName,
    updateReportConfig,
    updateConfigFromSettings,
    reportConfig,
    isLoading,
  } = props;
  const exportRef: React.LegacyRef<ExcelExport> = useRef();
  // TODO: create pagination hook
  const initialDataState: GridState = {
    take: PAGE_SIZE,
    skip: 0,
    sort: [],
  };
  const [dataState, setDataState] = useState<GridState>(initialDataState);

  useEffect(() => {
    setDataState(reportConfig.reviews.tableStatus);
  }, [updateConfigFromSettings, reportConfig]);

  const saveGridData = () => {
    exportRef.current.save(
      process(data, {
        ...dataState, // Export all the records
        skip: 0,
        take: undefined,
      }),
      config.excelExportColumns,
    );
  };

  const getNoRecordContent = useCallback(() => {
    if (isLoading) {
      return (
        <CircularProgress color="secondary" title={translate.t('laLoading')} />
      );
    }

    return !data.length ? translate.t('laNoData') : '';
  }, [isLoading, data.length]);

  return (
    <>
      <ExcelExport fileName={exportFileName} ref={exportRef} />
      <Grid
        data={process(data, dataState)}
        {...dataState}
        pageable={true}
        total={data.length}
        sortable={true}
        reorderable={true}
        onDataStateChange={(e: GridDataStateChangeEvent) => {
          updateReportConfig(GoalsReportConfigFields.reviews, { tableStatus: e.data});
          setDataState(e.data);
        }}
        style={{ height: '600px' }}
      >
        <GridNoRecords>{getNoRecordContent()}</GridNoRecords>
        <GridToolbar>
          <MuiGrid container justify="space-between" alignItems="center">
            <MuiGrid item>
              {config.titleLabel ? (
                <Typography className={classes.title} variant="h6">
                  {translate.t(config.titleLabel)}
                </Typography>
              ) : (
                undefined
              )}
            </MuiGrid>
            <MuiGrid item>
              <Button onClick={saveGridData}>
                <Icon>share</Icon> Excel
              </Button>
            </MuiGrid>
          </MuiGrid>
        </GridToolbar>
        {config.columns.map((col: GridColumnProps, ndx: number) => (
          <GridColumn key={ndx} {...col} columnMenu={ColumnMenu} />
        ))}
      </Grid>
    </>
  );
};

const enhance = compose<Props, OwnProps>(
  withStyles(styles, { withTheme: true }),
);

export default enhance(EmpGoals);

import { Record } from 'immutable';
import { THROW_ERROR } from './error';
import { Dispatch } from 'redux';
import Service from '@/app/utils/service';
import API from '@/app/api/internalAPIs';
import { OPEN_NOTIFIER } from './notifier';

import { AutoDeductionRule } from '../components/AutoDeduction/types';
import moment from 'moment';

const FETCH_RULES = '@@solaforce/autoDeduction/FETCH_RULES';
const DELETE_RULE = '@@solaforce/autoDeduction/DELETE_RULE';
const EDIT_RULE = '@@solaforce/autoDeduction/EDIT_RULE';
const ADD_RULE = '@@solaforce/autoDeduction/ADD_RULE';
const FETCHING = '@@solaforce/autoDeduction/FETCHING';
const STOP_FETCHING = '@@solaforce/autoDeduction/STOP_FETCHING';

// TODO: change [] with iList
const initialState = Record({
  rules: [],
  isFetching: false,
  supportedCurrencies: [],
  testResult: '0',
  formula: '',
  shouldConfirm: false,
});

const mapIsoDataToDuration = (value: string, defaultValue?: string) => {
  const duration = !value ? moment.duration(defaultValue) : moment.duration(value);

  return moment.utc(Math.abs(duration.asMilliseconds())).format('HH:mm');
};

const createRule = (rule: AutoDeductionRule): AutoDeductionRule => {
  return {
    id: rule.id,
    isActive: false,
    countryCode: '',
    unitId: undefined,
    correctionCode: '',
    nonWorkingCode: '',
    ...rule,
    duration: mapIsoDataToDuration(rule.duration, 'PT30M'),
    threshold: mapIsoDataToDuration(rule.threshold, 'PT1H'),
    active: rule.isActive,
  };
};

const reducer = (state = new initialState(), action: any) => {
  switch (action.type) {
    case FETCHING:
      return state.set('isFetching', true);
    case STOP_FETCHING:
      return state.set('isFetching', false);
    case FETCH_RULES: {
      const rules = action.payload.map((rule: AutoDeductionRule) => createRule(rule));

      return state.set('rules', rules);
    }
    case ADD_RULE: {
      const { rules: oldRules } = state;
      const rules = [createRule(action.rule), ...oldRules];

      return state.set('rules', rules);
    }
    case EDIT_RULE: {
      const { rules: oldRules } = state;

      const rule = createRule(action.rule);
      const rules = oldRules.map((r: AutoDeductionRule) =>
        r.id === rule.id ? rule : r,
      );

      return state.set('rules', rules);
    }
    case DELETE_RULE: {
      const { rules } = state;
      const { rule } = action;
      const updatedRules: AutoDeductionRule[] = rules.filter(oldRule => oldRule.id === rule.id);

      return state.set('rules', updatedRules);
    }
    default:
      return state;
  }
};

export const fetchRules = () => {
  return (dispatch: Dispatch) => {
    Service.get(
      API.workTimeAutoDeduction.getRules(),
      (data: any) => {
        dispatch({ type: FETCH_RULES, payload: data });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const addRule = (rule: AutoDeductionRule) => {
  return (dispatch: Dispatch) => {
    const { id, ...ruleData } = rule;

    return Service.post(
      API.workTimeAutoDeduction.createRule(),
      ruleData,
      (data: AutoDeductionRule) => {
        dispatch({ type: ADD_RULE, rule: data });
        dispatch({ type: OPEN_NOTIFIER, payload: {} });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const editRule = (rule: AutoDeductionRule) => {
  return (dispatch: Dispatch) => {
    return Service.put(
      API.workTimeAutoDeduction.editRule(rule.id),
      rule,
      (data: AutoDeductionRule) => {
        dispatch({ type: EDIT_RULE, rule: data });
        dispatch({ type: OPEN_NOTIFIER, payload: {} });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const deleteRule = (rule: AutoDeductionRule) => {
  return (dispatch: Dispatch) => {
    return Service.delete(
      API.workTimeAutoDeduction.deleteRule(rule.id),
      rule,
      (_response: number) => {
        dispatch({ type: DELETE_RULE, rule });
        dispatch({ type: OPEN_NOTIFIER, payload: {} });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export default reducer;

import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const addSectionStyle: StyleRulesCallback = () => ({
  root: {
    textAlign: 'center',
    padding: '25px 25px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) !important',
    display: 'flex',
  },
  topTitle: {
    textAlign: 'left',
  },
  title: {
    flexGrow: 1,
    marginRight: 20,
    minWidth: 220,
  },
  checkboxes: {
    textAlign: 'left',
  },
  buttonsWrapper: {
    float: 'right',
  },
});

export default addSectionStyle;

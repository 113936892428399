import moment from 'moment';
import { useEffect, useState } from 'react';

export const useCurrentDate = (dateFormat = 'YYYY-MM-DD'): string => {
  const [currentDate, setCurrentDate] = useState(moment().format(dateFormat));

  useEffect(
    () => {
      setCurrentDate(moment().format(dateFormat));

      const untilMidnight = moment()
        .add(1, 'day')
        .startOf('day')
        .diff(moment());

      const timeout = setTimeout(
        () => {
          setCurrentDate(moment().format(dateFormat));
        },
        untilMidnight
      );

      return () => {
        clearTimeout(timeout);
      };
    },
    [dateFormat, setCurrentDate]
  );

  return currentDate;
};
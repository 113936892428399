import { Reducer } from 'redux';

import { RawCollaborationTask } from './collaborationModels';
import { CollaborationActionKeys, CollaborationActionTypes } from './data';

export interface CollaborationModuleStoreState {
    tasks: { [key: number]: RawCollaborationTask };
    loadingTasks: boolean;
    failedToLoadTasks: boolean;
}

const initialCollaborationModuleStoreState: CollaborationModuleStoreState = {
    tasks: {},
    loadingTasks: false,
    failedToLoadTasks: false,
};

export const collaborationModuleReducer: Reducer<CollaborationModuleStoreState, CollaborationActionTypes> = (
    state = initialCollaborationModuleStoreState,
    action,
) => {
    switch (action.type) {
        case CollaborationActionKeys.FETCH_MY_TASKS_PENDING: {
            return {
                ...state,
                loadingTasks: true,
            };
        }

        case CollaborationActionKeys.FETCH_MY_TASKS_FULFILLED: {
            return {
                ...state,
                tasks: action.payload.reduce(
                    (accumulator, task) => ({
                        ...accumulator,
                        [task.fTaskId]: task,
                    }),
                    {}
                ),
                loadingTasks: false,
                failedToLoadTasks: false,
            };
        }

        case CollaborationActionKeys.FETCH_MY_TASKS_REJECTED: {
            return {
                ...state,
                loadingTasks: false,
                failedToLoadTasks: true,
            };
        }

        case CollaborationActionKeys.CREATE_TASK_COMMENT_FULFILLED: {
            if (!state.tasks[action.meta]) {
                return state;
            }

            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    [action.meta]: {
                        ...state.tasks[action.meta],
                        numberOfComments: state.tasks[action.meta].numberOfComments + 1,
                    },
                },
            };
        }

        case CollaborationActionKeys.SYNC_TASKS: {
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    ...action.payload.reduce(
                        (accumulator, task) => ({
                            ...accumulator,
                            [task.fTaskId]: task,
                        }),
                        {}
                    ),
                },
            };
        }

        case CollaborationActionKeys.SYNC_DELETED_TASKS: {
            const nextTasks = { ...state.tasks };

            action.payload.forEach((taskId) => {
                delete nextTasks[taskId];
            });

            return { ...state, tasks: nextTasks };
        }

        case CollaborationActionKeys.SYNC_CREATED_TASK_COMMENT: {
            if (!state.tasks[action.payload.fTaskCommentTaskId]) {
                return state;
            }

            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    [action.payload.fTaskCommentTaskId]: {
                        ...state.tasks[action.payload.fTaskCommentTaskId],
                        numberOfComments: state.tasks[action.payload.fTaskCommentTaskId].numberOfComments + 1,
                    },
                },
            };
        }

        case CollaborationActionKeys.SYNC_DELETED_TASK_COMMENT: {
            if (!state.tasks[action.payload.fTaskCommentTaskId]) {
                return state;
            }

            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    [action.payload.fTaskCommentTaskId]: {
                        ...state.tasks[action.payload.fTaskCommentTaskId],
                        numberOfComments: state.tasks[action.payload.fTaskCommentTaskId].numberOfComments - 1,
                    },
                },
            };
        }

        default: {
            return state;
        }
    }
};
import ConfirmDialog from '@/app/components/ConfirmDialog/ConfirmDialog';
import translate from '@/app/utils/translate';
import { Typography, WithStyles } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { WithSnackbarProps } from 'notistack';
import * as React from 'react';

import { ReportSettings } from '../../models';
import { canManageReportSettings } from '../../services';

import { StyleKeys } from './styles';

export interface OpenedReportControlsOuterProps {
    openedReportSettings?: ReportSettings;
    onDelete: (reportSettingsId: string) => void;
    deleting: boolean;
    failedToDelete: boolean;
}

export interface OpenedReportControlsInnerProps extends OpenedReportControlsOuterProps, WithSnackbarProps, WithStyles<StyleKeys> {}

export const OpenedReportSettingsControls: React.FC<OpenedReportControlsInnerProps> = ({
    enqueueSnackbar,
    classes,
    openedReportSettings,
    onDelete,
    deleting,
    failedToDelete
}) => {
    const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);

    const handleDeleteClick = React.useCallback(
        () => { setShowConfirmDialog(true); },
        [setShowConfirmDialog]
    );

    const handleCloseConfirmDialog = React.useCallback(
        () => { setShowConfirmDialog(false); },
        [setShowConfirmDialog]
    );

    const handleDeleteOpenedReportSettings = React.useCallback(
        () => {
            if (openedReportSettings) {
                onDelete(openedReportSettings.id);
            }
        },
        [openedReportSettings, onDelete]
    );

    React.useEffect(
        () => {
            if (!deleting && failedToDelete) {
                enqueueSnackbar(
                    translate.t('laFailedToDeleteReportSettings'),
                    { variant: 'error' }
                );
            }
        },
        [enqueueSnackbar, deleting, failedToDelete]
    );

    const openedReportSettingsLabel = openedReportSettings && openedReportSettings.name || translate.t('laDefaultReportSettings');
    const canNotDeleteOpenedReportSettings = !openedReportSettings || !canManageReportSettings(openedReportSettings) || deleting;
    const confirmDialogContent = `${translate.t('laConfirmDelete')} ${translate.t('laReportSettings')}?`;

    return (
        <div className={classes.root}>
            <Typography component="label" inline={true}>
                {translate.t('laCurrentReportSettings')}:
            </Typography>
            <Typography component="label" inline={true} className={classes.openedReportLabel}>
                {openedReportSettingsLabel}
            </Typography>

            <button
                className={`k-button k-button-icontext ${classes.deleteButton}`}
                onClick={handleDeleteClick}
                disabled={canNotDeleteOpenedReportSettings}
            >
                <Delete />
            </button>

            <ConfirmDialog
                open={showConfirmDialog}
                text={confirmDialogContent}
                onOk={handleDeleteOpenedReportSettings}
                onClose={handleCloseConfirmDialog}
            />
        </div>
    );
};

import React from 'react';
import {
  Popover,
  Paper,
  ListItem,
  ListItemText,
  Button,
  Typography,
  ListItemAvatar,
  WithStyles,
  withStyles,
  List
} from '@material-ui/core';
import { getEmployeeInfoById } from '@/old/utils/helper';
import { EmployeeAvatar } from '../../';
import translate from '@/app/utils/translate';
import { styles } from './employeeDetailsPopover/';

type OwnProps = {
  employeeId: number,
  anchorEl: any,
  onClose: () => void;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const EmployeeDetailsPopover = ({
  classes,
  employeeId,
  anchorEl,
  onClose,
}: Props) => {
  if (!anchorEl) {
    return null;
  }

  const {
    fEmpFirstName: firstName,
    fEmpLastName: lastName,
    fEmpPosition: position = '',
    fEmpPhoneWork: phone = '',
    fEmpPhoneMob: mobile = '',
    fEmpEmailWork: email = '',
  } = getEmployeeInfoById(employeeId);

  return (
    <Popover
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      <Paper className={classes.paper}>
        <List>
          <ListItem>
            <ListItemAvatar>
              <EmployeeAvatar employeeId={employeeId} />
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="h6"><strong>{`${firstName} ${lastName}`}</strong></Typography>
              <Typography variant="body1">{position}</Typography>
            </ListItemText>
          </ListItem>
          <ListItem className={classes.listItem}>
            <Button className={classes.textButton} color="primary" variant="text" href={`mailto:${email}`}>{email}</Button>
          </ListItem>
          <ListItem className={classes.listItem}>
            {translate.t('laWorkMobile')}
            <Button className={classes.textButton} color="primary" variant="text" href={`tel:${mobile}`}>{mobile}</Button>
          </ListItem>
          <ListItem className={classes.listItem}>
            {translate.t('laWorkPhone')} <Button className={classes.textButton} color="primary" variant="text" href={`tel:${phone}`}>{phone}</Button>
          </ListItem>
        </List>
      </Paper>
    </Popover>
  );
};

export default withStyles(styles)(EmployeeDetailsPopover);

const GLOBAL: any = window;

export default () => {
  if (!GLOBAL.jsonEnumALL || !GLOBAL.jsonEnumALL.EXTERNAL_TRAINING_TYPE) {
    return [];
  }

  return GLOBAL.jsonEnumALL.EXTERNAL_TRAINING_TYPE.filter((training: any) => training.isActive)
    .map((item: any) => ({
      code: item.code,
      name: item.name,
    }));
};

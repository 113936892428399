import {
  Button,
  FormHelperText,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import * as React from 'react';
import { compose } from 'recompose';
import { v4 as uuid4 } from 'uuid';

import { sortArr } from '@/app/utils/helper';
import translate from '@/app/utils/translate';

import { ComponentType, componentTypeTranslationKeys } from '../constants';

import addComponentFormStyle from './addComponentFormStyle';

type Option = {
  id?: number;
  index: number;
  label: string;
};

type IncomingProps = {
  component?: any;
  componentType: ComponentType;
  onCancel: () => void;
  onComponentAdd: (component: any) => void;
  onComponentUpdate: (component: any) => void;
};

type PropsType = IncomingProps & WithStyles<typeof addComponentFormStyle>;

const AddComponentForm: React.FC<PropsType> = ({
  classes,
  component,
  componentType,
  onCancel,
  onComponentAdd,
  onComponentUpdate,
}) => {
  const [labelValue, setLabelValue] = React.useState('');
  const [labelError, setLabelError] = React.useState('');

  const [options, setOptions] = React.useState<Option[]>([]);
  const [optionErrors, setOptionErrors] = React.useState<{
    [index: number]: string;
  }>();
  const [optionsError, setOptionsError] = React.useState('');

  const [defaultValue, setDefaultValue] = React.useState('');
  const [defaultValueError, setDefaultValueError] = React.useState('');

  const [showValidationError, setShowValidationError] = React.useState(false);
  const [disabledSave, setDisabledSave] = React.useState(true);

  const hasOptions = [
    ComponentType.CHECKBOXES,
    ComponentType.EVALUATIONS,
    ComponentType.COMPETENCY_LEVEL,
    ComponentType.DROPDOWN,
    ComponentType.MULTISELECT_DROPDOWN,
  ].includes(componentType);

  React.useEffect(() => {
    if (component) {
      setLabelValue(component.name);
      setOptions(
        (component.options && sortArr(component.options, 'index')) || [],
      );
      setDefaultValue(component.defaultValue || '');
    } else {
      setOptions([{ label: '', index: 0 }]);
    }
  }, []);

  const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLabelValue(e.target.value);
    setDisabledSave(!e.target.value);
    setShowValidationError(false);
  };

  const handleOptionAdd = () => {
    setOptions(actualOptions => {
      const maxIndex = Math.max(...actualOptions.map(({ index }) => index));
      return [...actualOptions, { label: '', index: maxIndex + 1 }];
    });

    setDisabledSave(true);
    setShowValidationError(false);
  };

  const handleOptionChange = (o: Option) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const label = e.target.value;
      setOptions(actualOptions => {
        return actualOptions.map(actualOption => {
          if (o.index === actualOption.index) {
            return { ...actualOption, label };
          }

          return actualOption;
        });
      });

      setDisabledSave(!e.target.value);
      setShowValidationError(false);
    };
  };

  const handleOptionDelete = (o: Option) => {
    return () => {
      setOptions(actualOptions => {
        return actualOptions.filter(({ index }) => {
          return o.index !== index;
        });
      });

      setDisabledSave(false);
      setShowValidationError(false);
    };
  };

  const handleDefaultValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDefaultValue(e.target.value);
    setDisabledSave(!e.target.value);
    setShowValidationError(false);
  };

  const handleSave = () => {
    let hasErrors = false;

    if (labelValue) {
      setLabelError('');
    } else {
      setLabelError(translate.t('laThisRequired'));
      hasErrors = true;
    }

    if (hasOptions) {
      let hasEmptyOptions = false;

      setOptionErrors(
        options.reduce((errors, option) => {
          if (!option.label) {
            hasErrors = true;
            hasEmptyOptions = true;
            return { ...errors, [option.index]: translate.t('laThisRequired') };
          }

          return errors;
        }, {}),
      );

      if (!hasEmptyOptions) {
        const optionLabels = options.map(({ label }) => label);
        if (new Set(optionLabels).size < optionLabels.length) {
          setOptionsError(translate.t('laNameDuplicateError'));
          hasErrors = true;
        } else {
          setOptionsError('');
        }
      }
    }

    if (componentType === ComponentType.LINK) {
      if (defaultValue) {
        setDefaultValueError('');
      } else {
        setDefaultValueError(translate.t('laThisRequired'));
        hasErrors = true;
      }
    }

    if (hasErrors) {
      setShowValidationError(true);
      return;
    }

    if (component) {
      onComponentUpdate({
        componentId: component.id,
        type: componentType,
        name: labelValue,
        index: component.index,
        defaultValue:
          componentType === ComponentType.LINK ? defaultValue : undefined,
        options: hasOptions
          ? options.map((option, index) => ({ ...option, index }))
          : undefined,
      });
    } else {
      onComponentAdd({
        componentId: uuid4(),
        type: componentType,
        name: labelValue,
        defaultValue:
          componentType === ComponentType.LINK ? defaultValue : undefined,
        options: hasOptions
          ? options.map((option, index) => ({ ...option, index }))
          : undefined,
      });
    }
  };

  return (
    <Paper className={classes.root}>
      <form className={classes.form}>
        <Typography variant="h6" className={classes.topTitle}>
          {translate.t(componentTypeTranslationKeys[componentType])}

          {hasOptions && (
            <IconButton onClick={handleOptionAdd}>
              <Tooltip title={translate.t('laAdd')}>
                <AddCircleIcon color="primary" />
              </Tooltip>
            </IconButton>
          )}
        </Typography>

        <TextField
          label={translate.t('laLabel')}
          value={labelValue}
          onChange={handleLabelChange}
          error={showValidationError && !!labelError}
          helperText={(showValidationError && labelError) || ''}
          fullWidth={true}
          margin="dense"
          multiline={true}
          rows={1}
          rowsMax={5}
        />

        {hasOptions && (
          <>
            {options.map(option => {
              const getPlaceholder = (compType: ComponentType) => {
                if (compType === ComponentType.EVALUATIONS) {
                  return `${translate.t('add_evaluation_question')}*`;
                }

                if (compType === ComponentType.COMPETENCY_LEVEL) {
                  return `${translate.t('add_evaluation_question')}*`;
                }

                return `${translate.t('checkbox_text')}*`;
              };

              return (
                <div key={option.index}>
                  <TextField
                    placeholder={getPlaceholder(componentType)}
                    value={option.label}
                    onChange={handleOptionChange(option)}
                    error={
                      showValidationError &&
                      optionErrors &&
                      !!optionErrors[option.index]
                    }
                    helperText={
                      (showValidationError &&
                        optionErrors &&
                        optionErrors[option.index]) ||
                      ''
                    }
                    fullWidth={true}
                    multiline={true}
                    rows={1}
                    rowsMax={5}
                    InputProps={{
                      endAdornment: options.length > 1 && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleOptionDelete(option)}
                            className={classes.adornmentButton}
                            disableRipple
                          >
                            <HighlightOffIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              );
            })}

            {showValidationError && optionsError && (
              <FormHelperText error={true}>{optionsError}</FormHelperText>
            )}
          </>
        )}

        {componentType === ComponentType.LINK && (
          <TextField
            label={translate.t('laUrl')}
            value={defaultValue}
            onChange={handleDefaultValueChange}
            error={showValidationError && !!defaultValueError}
            helperText={(showValidationError && defaultValueError) || ''}
            fullWidth={true}
            margin="dense"
          />
        )}

        <div className={classes.buttonsWrapper}>
          <Button color="primary" variant="text" onClick={onCancel}>
            {translate.t('laCancel')}
          </Button>

          <Button
            color="primary"
            variant="contained"
            disabled={disabledSave}
            onClick={handleSave}
          >
            {translate.t('laSave')}
          </Button>
        </div>
      </form>
    </Paper>
  );
};

export default compose<any, any>(withStyles(addComponentFormStyle))(
  AddComponentForm,
);

import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const sendToApprovalFormDialogStyle: StyleRulesCallback = (theme: Theme) => {
  return ({
    paperWidthMd: {
      width: '570px',
    },
    gridRight: {
      alignItems: 'flex-end',
      // TODO: temporary?
      marginBottom: '10px',
    },
    sendButton: {
      marginLeft: '10px',
    },
    table: {
      width: '100%',
    },
    tableCell: {
      padding: '4px 10px',

      '&:last-child': {
        paddingRight: 0,
      },
    },
    tableHead: {
      backgroundColor: '#ededed',
      height: '37px',
    },
    tableHeadCell: {
      color: 'black',
      fontWeight: 'bold',
      padding: '4px 10px',
      fontSize: '12px',
    },
    listTitle: {
      fontSize: '16px',
      fontWeight: 500,
      paddingBottom: theme.spacing.unit,
      paddingTop: `${theme.spacing.unit * 2}px`,
    }
  });
};

export default sendToApprovalFormDialogStyle;

import * as React from 'react';

import {
  Button,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';

import styles from './styles';

type OwnProps = {
  prompt?: string;
  value?: string;
  url?: string;
  hideIfEmpty?: boolean;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const ViewFieldText: any = (props: Props) => {
  const { classes, hideIfEmpty = true, prompt = '', value = '', url = '' } = props;

  if (!value && hideIfEmpty) {
    return <></>;
  }

  const promptElement = !prompt ? '' : (
    <Typography inline={true} variant="body1" color="textSecondary">
      {prompt}:&nbsp;
    </Typography>
  );

  const valueElement = !url ? (
    <Typography inline={true} variant="body1">
      {value}
    </Typography>
  ) : (
      <Button href={url} size="small" color="primary" className={classes.textLinkButton}>
        {value}
      </Button>
    );

  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      {promptElement}{valueElement}
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(ViewFieldText);

import { StyleRulesCallback } from '@material-ui/core';

const styles: StyleRulesCallback = () => ({
  grow: {
    flex: 1,
  },
  colorSpan: {
    color: 'red',
  },
  input: {
    display: 'flex',
    height: 'auto',
    padding: 0,
  },
  valueContainer: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    overflow: 'hidden',
    paddingLeft: 0,
  },
});

export default styles;

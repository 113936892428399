import {
  ComponentType,
  TemplateType,
  componentTypeExclusionsPerTemplateType,
} from '@/app/components/TemplateComponents/constants';

export const useTemplateComponentTypes = (
  templateType: string,
  defaultTemplateType?: TemplateType,
): ComponentType[] => {
  const findTemplateType = (tt: string): TemplateType | undefined =>
    Object.values(TemplateType).find(t => t.toString() === tt);

  const templateTypeFound = !!findTemplateType(templateType);
  const exclusions: ComponentType[] | undefined = (templateTypeFound || !!defaultTemplateType) ?
    componentTypeExclusionsPerTemplateType[
      templateTypeFound
        ? templateType
        : defaultTemplateType
    ] : undefined;

  return Object.values(ComponentType).filter(
    c => !(!!exclusions && exclusions.includes(c)),
  );
};

import translate from '@/app/utils/translate';
import { COMPLETION_STATUS } from '../types';

export default (status: COMPLETION_STATUS) => {
  switch (status) {
    case 'COMPLETED': return translate.t('laCompleted');
    case 'PARTICIPATED_PARTLY': return translate.t('label_completed_partly');
    case 'DID_NOT_SHOW_UP': return translate.t('label_did_not_show_up');
    case 'DID_NOT_COMPLETE': return translate.t('label_did_not_complete');
    case 'PENDING': return translate.t('laPending');
    default: return translate.t('label_not_set');
  }
};

import { RootRef, Tooltip, withStyles, WithStyles } from '@material-ui/core';
import React, { useEffect } from 'react';

import styles from '@/app/components/TooltippedTruncatedText/tooltipStyle';

export interface Props extends WithStyles<typeof styles> {
  children: NonNullable<React.ReactNode>;
}

const TooltippedTruncatedText: React.FC<Props> = ({
  children,
  classes,
}) => {
  const ref = React.useRef<HTMLSpanElement>(null);
  const [isTruncated, setIsTruncated] = React.useState(false);
  const scrollWidth = ref.current && ref.current.scrollWidth;
  const offsetWidth = ref.current && ref.current.offsetWidth;

  useEffect(
    () => {
      if (ref.current) {
        setIsTruncated(ref.current.scrollWidth > ref.current.offsetWidth);
      }
    },
    [scrollWidth, offsetWidth]
  );

  useEffect(
    () => {
      if (ref.current) {
        setIsTruncated(ref.current.scrollWidth > ref.current.offsetWidth);
      }
    },
    []
  );

  if (isTruncated) {
    return (
      <RootRef rootRef={ref}>
        <Tooltip title={children}>
          <span className={classes.text}>
            {children}
          </span>
        </Tooltip>
      </RootRef>
    );
  }

  return (
    <RootRef rootRef={ref}>
      <span className={classes.text}>
        {children}
      </span>
    </RootRef>
  );
};

export default withStyles(styles)(TooltippedTruncatedText);
import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const tabsConfiguratorStyle: StyleRulesCallback = () => ({
  root: {
    textAlign: 'center',
    width: '50%',
    marginTop: '40px',
    marginLeft: '15px',
  },
  tableCells: {
    width: 40,
  },
});

export default tabsConfiguratorStyle;

import { CompanyTraining, ParticipantSummary, CompanyTrainingParticipantRegistration, APPROVAL_STATUS, COMPLETION_STATUS } from '../types';

export default (
  training: CompanyTraining,
  participants: CompanyTrainingParticipantRegistration[]
): ParticipantSummary => {
  let waiting = 0, approved = 0, rejected = 0, cancelled = 0;
  let guaranteedSeat = 0, seat = 0, waitingSeat = 0, noSeat = 0;
  let completed = 0, completedPartly = 0, didNotComplete = 0, didNotShowUp = 0, pending = 0;

  participants.forEach(p => {
    switch (p.approvalStatus.status.value) {
      case APPROVAL_STATUS.WAITING:
        waiting += 1;
        break;
      case APPROVAL_STATUS.APPROVED:
        approved += 1;
        break;
      case APPROVAL_STATUS.REJECTED:
        rejected += 1;
        break;
      case APPROVAL_STATUS.CANCELLED:
        cancelled += 1;
        break;
      default: // do nothing
    }

    if (p.approvalStatus.status.value === APPROVAL_STATUS.WAITING || p.approvalStatus.status.value === APPROVAL_STATUS.APPROVED) {
      // Waiting approval or already approved

      if (training.seats && training.seats > 0) {
        // Training seats are numbered

        if (p.position > training.seats) {
          // Position outside of training seats

          if (training.allowWaitingSeats) {
            waitingSeat += 1;
          } else {
            noSeat += 1;
          }

        } else {
          // Position within training seats
          if (p.seated) {
            guaranteedSeat += 1;
          } else {
            seat += 1;
          }
        }
      } else {
        // Training seats are not numbered
        seat += 1;
      }
    } else {
      // Rejected approval or cancelled
      noSeat += 1;
    }

    switch (p.completionStatus.value) {
      case COMPLETION_STATUS.COMPLETED:
        completed += 1;
        break;
      case COMPLETION_STATUS.COMPLETED_PARTLY:
        completedPartly += 1;
        break;
      case COMPLETION_STATUS.DID_NOT_COMPLETE:
        didNotComplete += 1;
        break;
      case COMPLETION_STATUS.DID_NOT_SHOW_UP:
        didNotShowUp += 1;
        break;
      case COMPLETION_STATUS.PENDING:
        pending += 1;
        break;
      default: // do nothing
    }
  });

  return {
    trainingId: training.id,
    totalRegistrants: participants.length,
    approvals: {
      waiting,
      approved,
      rejected,
      cancelled,
    },
    seatings: {
      guaranteedSeat,
      seat,
      waitingSeat,
      noSeat,
    },
    completions: {
      completed,
      completedPartly,
      didNotComplete,
      didNotShowUp,
      pending,
    },
  };
};

import { TextField, Typography, withStyles } from '@material-ui/core';
import * as React from 'react';
import { compose } from 'recompose';

import {
  TemplateBaseComponentType,
  TemplateComponentTypeKey,
  TemplateInputComponentPropsType,
} from '@/app/components/TemplateComponents/form/InputComponents';
import textComponentStyles from '@/app/components/TemplateComponents/form/InputComponents/TextComponent/textComponentStyles';
import translate from '@/app/utils/translate';
import {
  generateTextFieldKey,
  resolveComponentResponse,
} from '@/app/components/TemplateComponents/form/InputComponents/data';

type TextComponentProps = TemplateInputComponentPropsType & {
  component: TemplateBaseComponentType & {
    type: TemplateComponentTypeKey.TEXT;
  };
};

const TextComponent = (props: TextComponentProps) => {
  const {
    templateId,
    component,
    responses,
    editMode,
    onUpdate,
    classes,
  } = props;
  const existingResponse = resolveComponentResponse(component, responses, '');

  const handleChange = (event: any) => {
    if (!!onUpdate) {
      onUpdate(templateId, {
        componentId: component.id,
        response: event.target.value,
        type: component.type,
      });
    }
  };

  return (
    component.type === TemplateComponentTypeKey.TEXT && (
      <div key={component.type + '-' + component.id + '-' + editMode}>
        <Typography variant="subtitle2" className={classes.typography}>
          {component.name}
        </Typography>
        <TextField
          id={component.id}
          key={generateTextFieldKey(`${component.id}`, existingResponse)}
          classes={{ root: classes.root }}
          placeholder={translate.t('label_TEXT')}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          disabled={!editMode}
          onBlur={handleChange}
          defaultValue={existingResponse}
          multiline={true}
          rows={1}
          rowsMax={5}
        />
      </div>
    )
  );
};

const enhance = compose<any, any>(withStyles(textComponentStyles));

export default enhance(TextComponent);

import { FormLabel, Typography, withStyles } from '@material-ui/core';
import * as React from 'react';
import { compose } from 'recompose';
import {
  TemplateBaseComponentOptionType,
  TemplateBaseComponentType,
  TemplateComponentTypeKey,
  TemplateInputComponentPropsType,
} from '@/app/components/TemplateComponents/form/InputComponents';
import translate from '@/app/utils/translate';
import {
  generateTextFieldKey,
  resolveComponentResponse,
} from '@/app/components/TemplateComponents/form/InputComponents/data';
import EvaluationOptions, {
  EvaluationOptionsChange,
} from '@/app/components/TemplateComponents/form/InputComponents/EvaluationsComponent/components/EvaluationOptions/EvaluationOptions';
import evaluationsComponentStyles from '@/app/components/TemplateComponents/form/InputComponents/EvaluationsComponent/evaluationsComponentStyles';
import ScaleCompCommnets from '@/app/components/TemplateComponents/form/InputComponents/EvaluationsComponent/components/ScaleCompComments/ScaleCompCommnets';

type EvaluationsComponentType = TemplateBaseComponentType & {
  type: TemplateComponentTypeKey.EVALUATIONS;
  options: TemplateBaseComponentOptionType[];
};

type CompetencyLevelComponentType = TemplateBaseComponentType & {
  type: TemplateComponentTypeKey.COMPETENCY_LEVEL;
  options: TemplateBaseComponentOptionType[];
};

export type EvaluationsComponentProps = TemplateInputComponentPropsType & {
  component: EvaluationsComponentType | CompetencyLevelComponentType;
};

const EvaluationsComponent = (props: EvaluationsComponentProps) => {
  const {
    templateId,
    component,
    responses,
    editMode,
    onUpdate,
    classes,
  } = props;

  const handleOptionChange = (update: EvaluationOptionsChange) => {
    if (!!onUpdate) {
      onUpdate(templateId, { ...update });
    }
  };

  const handleCommentChange = (
    optionId: number,
    componentId: string,
    value: string,
  ) => {
    onUpdate(templateId, {
      componentId: componentId,
      response: [
        {
          optionId,
          comment: value,
        },
      ],
      type: component.type,
    });
  };

  const existingResponse = resolveComponentResponse(component, responses, []);

  const evaluationTypeConfig =
    component.type === TemplateComponentTypeKey.COMPETENCY_LEVEL
      ? {
          eval1: {
            label: 'laSkillLevel',
            variableName: 'competencyLevel',
          },
          eval2: {
            label: 'laTargetLevel',
            variableName: 'targetLevel',
          },
        }
      : {
          eval1: {
            label: 'laSelfEvaluation',
            variableName: 'selfEvaluation',
          },
          eval2: {
            label: 'laMgrEvaluation',
            variableName: 'managerEvaluation',
          },
        };

  return (
    [
      TemplateComponentTypeKey.EVALUATIONS,
      TemplateComponentTypeKey.COMPETENCY_LEVEL,
    ].includes(component.type) && (
      <div key={`typeEvaluations-${component.id}`}>
        <Typography
          variant="subtitle2"
          className={classes.typography}
          style={{ paddingBottom: '25px' }}
        >
          {component.name}
        </Typography>
        <div className={classes.sectionContainer}>
          {component.options.map((option: any, optionIndex: number) => {
            const initialResponse = existingResponse.find(
              (existingRespData: any) =>
                existingRespData.optionId === option.id,
            );

            return (
              <div
                key={`compOption-${optionIndex}`}
                className={classes.componentListContainer}
              >
                <div
                  className={`${classes.controlContainer} ${classes.controlContainerMd}`}
                >
                  <Typography
                    key={option.label + '-' + option.index}
                    style={
                      optionIndex === 0
                        ? { marginTop: '25px' }
                        : { marginTop: '15px' }
                    }
                    className={classes.multiline}
                  >
                    {option.label}
                  </Typography>
                </div>
                {Object.values(evaluationTypeConfig).map(evalConf => {
                  return (
                    <div
                      key={`evaluation-${evalConf.variableName}`}
                      className={`${classes.controlContainer} ${classes.controlContainerLg}`}
                    >
                      <EvaluationOptions
                        label={translate.t(evalConf.label)}
                        evaluationVariable={evalConf.variableName}
                        componentId={component.id}
                        evaluationType={component.type}
                        showLabel={optionIndex === 0}
                        editMode={editMode}
                        optionId={option.id}
                        evaluationComponentChange={handleOptionChange}
                        existingResponse={existingResponse}
                      />
                    </div>
                  );
                })}
                <div className={`${classes.controlContainer}`}>
                  <>
                    {optionIndex === 0 && (
                      <FormLabel className={classes.formLabel}>
                        {translate.t('laComment')}
                      </FormLabel>
                    )}
                    <ScaleCompCommnets
                      generateTextFieldKey={generateTextFieldKey}
                      evaluationCommentComponentChange={handleCommentChange}
                      initialResponse={initialResponse}
                      component={component}
                      option={option}
                      optionIndex={optionIndex}
                      editMode={editMode}
                    />
                  </>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    )
  );
};

const enhance = compose<any, any>(withStyles(evaluationsComponentStyles));

export default enhance(EvaluationsComponent);

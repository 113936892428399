import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    gridMarginVertical: {
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 4,
    },
    root: {
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 6,
    },
    selectedButtonBackground: {
      backgroundColor: theme.palette.action.selected,
    },
    clearance: {
      paddingTop: 10,
      paddingBottom: 10,
    },
  });

export default styles;

import * as React from 'react';
import {
  ListItem,
  ListItemIcon,
  Icon,
  ListItemText,
  withStyles,
  WithStyles
} from '@material-ui/core';

import styles from './solaIntelDashboardModuleTitleStyle';

interface IProps {
  iconName: string;
  title: string;
  onConfigure: any;
}

const SolaIntelDashboardModuleTitle: React.FunctionComponent<any> = (props: IProps & WithStyles<typeof styles>) => {
  const { classes, iconName, title } = props;
  return (
    <ListItem className={classes.root}>
      <ListItemIcon className={classes.icon}>
        <Icon fontSize="small">{iconName}</Icon>
      </ListItemIcon>
      <ListItemText className={classes.title} primary={title} />
    </ListItem>
  );
};

export default withStyles(styles, {withTheme: true})(SolaIntelDashboardModuleTitle);
import { Record } from 'immutable';
import { Dispatch } from 'redux';
import { THROW_ERROR } from './error';
import Service from '@/app/utils/service';
import {
  IReportSettings,
  resolveUrl,
} from '../components/Reporting/ReportSettings';
import { currentUserHasTeam } from '@/old/utils/helper';

const APPLY_SETTINGS = '@@solaforce/reporting/APPLY_SETTINGS';
const FETCHING = '@@solaforce/reporting/FETCHING';
const FETCH_REPORT_DATA = '@@solaforce/reporting/FETCH_REPORT_DATA';
const FETCH_DEFAULT_SETTINGS = '@@solaforce/reporting/FETCH_DEFAULT_SETTINGS';
const NOT_FETCHING = '@@solaforce/reporting/NOT_FETCHING';

const initialState = Record({
  isFetching: false,
  settings: {},
  data: [],
  initialLoadDone: false,
});

const reducer = (state = new initialState(), action: any) => {
  switch (action.type) {
    case APPLY_SETTINGS:
      return state.set('settings', action.settings);
    case FETCHING:
      return state.set('isFetching', true).set('data', []);
    case FETCH_DEFAULT_SETTINGS:
      return state.set('settings', action.defaultSettings).set('data', []).set('initialLoadDone', false);
    case FETCH_REPORT_DATA:
      return state.set('data', action.data).set('initialLoadDone', true);
    case NOT_FETCHING:
      return state.set('isFetching', false);
    default:
      return state;
  }
};

const hasFetchSettingsChanged = (oldSettings: IReportSettings, newSettings: IReportSettings): boolean => {
  let isDateChanged = false;
  if (oldSettings.scope && oldSettings.scope.range && newSettings.scope.range) {
    isDateChanged = (oldSettings.scope.range.startDate !== newSettings.scope.range.startDate) ||
      (oldSettings.scope.range.endDate !== newSettings.scope.range.endDate);
  }

  return (
    isDateChanged ||
    oldSettings.scope.type !== newSettings.scope.type ||
    oldSettings.scope.id !== newSettings.scope.id ||
    oldSettings.scope.rln !== newSettings.scope.rln
  );
};

export const applySettings = (
  settings: IReportSettings,
  mapDataFn: (origData: any) => any,
) => {
  return (dispatch: any, getState: Function) => {
    const oldSettings = getState().reporting.get('settings');
    const initialLoadDone = getState().reporting.get('initialLoadDone');
    dispatch({ type: APPLY_SETTINGS, settings });

    // /* Fetch data, if settings that affect API call have changed */
    const shouldLoadInitially = !initialLoadDone && settings.scope.type === 'manager';
    if (settings.scope && (shouldLoadInitially || hasFetchSettingsChanged(oldSettings, settings))) {
      dispatch(fetchReport(resolveUrl(settings), mapDataFn));
    }
  };
};

export const applyDefaultSettings = (defaultSettings: IReportSettings) => {
  return (dispatch: Dispatch) => {
    if (defaultSettings.scope.type === '') {
      defaultSettings.scope.type = currentUserHasTeam() ? 'manager' : '';
      if (defaultSettings.scope.type === 'manager') { defaultSettings.scope.rln = 'direct'; }
    }
    dispatch({ type: FETCH_DEFAULT_SETTINGS, defaultSettings });
  };
};

const fetchReport = (url: string, mapDataFn: (origData: any) => any) => {
  return async (dispatch: Dispatch) => {
    await dispatch({ type: FETCHING });

    Service.get(
      url,
      (data: any) => {
        if (!mapDataFn) {
          dispatch({ type: FETCH_REPORT_DATA, data });
        } else {
          dispatch({ type: FETCH_REPORT_DATA, data: mapDataFn(data) });
        }
        dispatch({ type: NOT_FETCHING });
      },
      (error: any) => {
        dispatch({ type: THROW_ERROR, error });
        dispatch({ type: NOT_FETCHING });
      }
    );
  };
};

export default reducer;
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Dispatch } from 'redux';
import moment from 'moment';

import API, { Relation } from '@/app/api/internalAPIs';
import { ReducerState } from '@/app/redux/store';
import { fetchAllProcessTemplates } from '@/app/redux/processTemplates';
import { useUnits } from '@/app/hooks/useUnits';
import Service from '@/app/utils/service';
import translate from '@/app/utils/translate';
import {
  getLoggedUserId,
  isHR,
  isManager,
} from '@/old/utils/helper';

import TemplateResponseReport from './components/TemplateResponseReport/TemplateResponseReport';
import {
  getUnitGridData,
  getUserRolePerspective,
  mapEvaluationData,
  mapEvaluationDataForUI,
  templateStatusTranslation
} from './helpers';
import { ReportEvaluationType, TemplateFieldSelection } from './types';

type MapStateToProps = {
  allProcessTemplates: [];
};

type MapDispatchToProps = {
  fetchProcessTemplates: () => any;
};

type Props = MapStateToProps & MapDispatchToProps;

const ProcessReport = (props: Props) => {
  const { allProcessTemplates, fetchProcessTemplates } = props;
  const userId = getLoggedUserId();
  const defaultRelation =
    isHR() && !isManager() ? Relation.ALL : Relation.DIRECT;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [processData, setProcessData] = useState<ReportEvaluationType[]>([]);
  const [empData, setEmpData] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [relation, setRelation] = useState(defaultRelation);
  const [selectedTemplateFields, setSelectedTemplateFields] = useState<
    TemplateFieldSelection
  >();

  const {
    myUnits,
    includeSubunits,
    selectedUnitId,
    getIncludedUnits,
    setSelectedUnitId,
    setIncludeSubUnits
  } = useUnits();

  useEffect(() => {
    fetchProcessTemplates().then(() => {
      setEmpData([]);
      setLoading(false);
      setError('');
    }).catch((err: any) => {
      setProcessData([]);
      setLoading(false);
      setError(err);
    });
  }, [
    setEmpData,
    setProcessData,
    setLoading,
    setError
  ]);

  useEffect(() => {
    Service.get(
      API.ProcessReport.getProcessFullReport(
        getUserRolePerspective(relation),
        userId,
        relation,
      ),
      (response: any) => {
        setProcessData(mapEvaluationDataForUI(response));
        setEmpData([]);
        setLoading(false);
        setError('');
      },
      (err: any) => {
        setProcessData([]);
        setLoading(false);
        setError(err);
      },
    );
  }, [
    userId,
    relation,
    setProcessData,
    setEmpData,
    setLoading,
    setError,
  ]);

  if (loading) {
    return <div>{loading}</div>;
  }

  if (error) {
    return <div>{'error'}</div>;
  }

  const processedEvaluationData = mapEvaluationData(
    processData,
    myUnits,
    [selectedTemplateId],
    selectedTemplateFields,
    allProcessTemplates,
  );

  const unitGridData = getUnitGridData(
    processedEvaluationData,
    [selectedTemplateId],
  );

  const handleTemplateClick = (id: string) => {
    const newSelectedTemplateId = id !== selectedTemplateId ? id : undefined;
    setSelectedTemplateId(newSelectedTemplateId);
    setSelectedTemplateFields(undefined);
    setSelectedUnitId(undefined);
    setEmpData([]);
  };

  const handleBulkTemplateSelection = () => {
    handleTemplateClick(selectedTemplateId);
  };

  const updateSelectedEmps = (
    unitId: string,
    tplFields: TemplateFieldSelection,
    subUnitsIncluded: boolean,
  ) => {
    if (!selectedTemplateId) {
      return;
    }

    const includedUnits = getIncludedUnits(unitId, subUnitsIncluded);
    const relevantEmpData = mapEvaluationData(
      processData,
      myUnits,
      [selectedTemplateId],
      tplFields,
      allProcessTemplates,
    ).filter(
      processedEvalItem => {
        if (!includedUnits.includes(`${processedEvalItem.unitId}`)) {
          return false;
        }

        return (
          selectedTemplateId === undefined ||
          processedEvalItem.templateId === selectedTemplateId
        );
      },
    );

    setEmpData(relevantEmpData);
  };

  const handleUnitClick = (id: string) => {
    const newSelectedUnitId = id !== selectedUnitId ? id : undefined;
    setSelectedUnitId(newSelectedUnitId);
    setSelectedTemplateFields(undefined);

    if (!selectedTemplateId) {
      return;
    }

    updateSelectedEmps(
      newSelectedUnitId,
      selectedTemplateFields,
      includeSubunits,
    );
  };

  const handleIncludeSubunitsCheckboxClick = () => {
    setIncludeSubUnits(!includeSubunits);
    updateSelectedEmps(
      selectedUnitId,
      selectedTemplateFields,
      !includeSubunits,
    );
  };

  const handleTeamCheckboxClick = () => {
    const updatedRelation =
      relation === Relation.DIRECT ? Relation.ALL : Relation.DIRECT;
    setRelation(updatedRelation);
    setSelectedUnitId(undefined);
    setSelectedTemplateFields(undefined);
  };

  const handleTemplateFieldSelection = (
    sectionId: string,
    selection: string[],
  ) => {
    const newTemplateFields = {
      ...selectedTemplateFields,
      [sectionId]: { selectedFields: selection },
    };

    const updatedSelectedTemplateFields:
      | TemplateFieldSelection
      | undefined = Object.keys(newTemplateFields).reduce(
      (acc: TemplateFieldSelection | undefined, sectionKey: string) => {
        if (!newTemplateFields[sectionKey].selectedFields.length) {
          return acc;
        }

        return {
          ...acc,
          [sectionKey]: {
            selectedFields: newTemplateFields[sectionKey].selectedFields,
          },
        };
      },
      undefined,
    );

    setSelectedTemplateFields(updatedSelectedTemplateFields);
    updateSelectedEmps(
      selectedUnitId,
      updatedSelectedTemplateFields,
      includeSubunits,
    );
  };

  return (
    <TemplateResponseReport
      exportFileName={translate.t('laProcessReport')}
      templates={allProcessTemplates}
      templatesTitle={translate.t('laProcessTemplates')}
      selectedTemplateFields={selectedTemplateFields}
      onTemplateClick={handleTemplateClick}
      onBulkTemplateSelection={handleBulkTemplateSelection}
      showSearch={true}
      selectedTemplateIds={[selectedTemplateId]}
      units={unitGridData}
      selectedUnitId={selectedUnitId}
      onUnitClick={handleUnitClick}
      includeSubunits={includeSubunits}
      onIncludeSubunitsClick={handleIncludeSubunitsCheckboxClick}
      onTeamRelationClick={isManager() ? handleTeamCheckboxClick : undefined}
      teamRelation={relation}
      employeeAppraisalData={empData}
      onTemplateFieldSelection={handleTemplateFieldSelection}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchProcessTemplates: () => dispatch<any>(fetchAllProcessTemplates()),
});

const mapStateToProps = (state: ReducerState) => {
  const { allProcessTemplates } = state.processTemplates;

  return { 
    allProcessTemplates: allProcessTemplates.map((processTpl: any) => ({
      ...processTpl,
      status: templateStatusTranslation(processTpl.status),
      deadline: moment(processTpl.deadline, 'YYYY-MM-DD').toDate(),
    }))
  };
};

const enhance = compose<Props, {}>(
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(ProcessReport);

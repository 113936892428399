import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  WithStyles,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { getCompanyCountries } from '@/old/utils/helper';
import { withStyles } from '@material-ui/core/styles';
import { formatDurationToHMM } from '@/app/utils/helper';
import translate from '@/app/utils/translate';

import * as React from 'react';
import { compose } from 'recompose';

import { hoursSettingsStyles } from './hoursSettingsStyles';
import { useHoursSettings } from './useHoursSettings';
import { EditWorkTimeSettingsDialog } from './components/EditWorkTimeSettingsDialog';

export interface HoursSettings extends WithStyles<typeof hoursSettingsStyles> {}

const HoursSettingsComponent: React.FC<HoursSettings> = ({ classes }) => {
  const companyCountries = React.useMemo(() => getCompanyCountries(), [
    getCompanyCountries,
  ]);

  const {
    selectedCountryCode,
    handleCountryChange,
    dialogsVisibilityProps,
    changeEditHoursDialogVisibility,
    companyDefaultHours,
    handleSaveDefaultHours,
    selectedCountryCustomHours,
    handleSaveCustomHours,
  } = useHoursSettings();

  return (
    <Paper classes={{ root: classes.root }}>
      {companyDefaultHours && (
        <div className={classes.flex}>
          <div className={classes.item}>
            <Typography variant="caption">
              {translate.t('defaultLengthOfTheWorkDay')}
            </Typography>
            <Typography variant="body2">
              {formatDurationToHMM(companyDefaultHours.workDayHours)}
            </Typography>
          </div>

          <div className={classes.item}>
            <Typography variant="caption">
              {translate.t('defaultMaxCumulativeHours')}
            </Typography>
            <Typography variant="body2">
              {formatDurationToHMM(companyDefaultHours.maxCumulativeHours)}
            </Typography>
          </div>

          <div className={classes.item}>
            <Typography variant="caption">
              {translate.t('defaultMinCumulativeHours')}
            </Typography>
            <Typography variant="body2">
              {formatDurationToHMM(companyDefaultHours.minCumulativeHours)}
            </Typography>
          </div>

          <div className={classes.item}>
            <Typography variant="caption" noWrap={true}>
              {translate.t('defaultBlockTheHourEditingAfterDays')}
            </Typography>
            <Typography variant="body2">
              {companyDefaultHours.blockAfterDays}
            </Typography>
          </div>

          <IconButton
            component="span"
            onClick={changeEditHoursDialogVisibility('defaultHours', true)}
          >
            <EditIcon fontSize="small" />
          </IconButton>

          {dialogsVisibilityProps.defaultHours && (
            <EditWorkTimeSettingsDialog
              open={dialogsVisibilityProps.defaultHours}
              onClose={changeEditHoursDialogVisibility('defaultHours', false)}
              onSave={handleSaveDefaultHours}
              hours={companyDefaultHours}
            />
          )}
        </div>
      )}

      <FormControl className={classes.selectContainer}>
        <InputLabel>{translate.t('laCountry')}</InputLabel>
        <Select
          value={selectedCountryCode || ''}
          onChange={handleCountryChange}
        >
          // @ts-ignore
          {companyCountries.map(country => (
            <MenuItem key={country.code} value={country.code}>
              {country.description}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {selectedCountryCustomHours && selectedCountryCode && (
        <div className={classes.flex}>
          <div className={classes.item}>
            <Typography variant="caption">
              {translate.t('lengthOfTheWorkDay')}
            </Typography>
            <Typography variant="body2">
              {formatDurationToHMM(selectedCountryCustomHours.workDayHours)}
            </Typography>
          </div>

          <div className={classes.item}>
            <Typography variant="caption">
              {translate.t('maxCumulativeHours')}
            </Typography>
            <Typography variant="body2">
              {formatDurationToHMM(
                selectedCountryCustomHours.maxCumulativeHours,
              )}
            </Typography>
          </div>

          <div className={classes.item}>
            <Typography variant="caption">
              {translate.t('minCumulativeHours')}
            </Typography>
            <Typography variant="body2">
              {formatDurationToHMM(
                selectedCountryCustomHours.minCumulativeHours,
              )}
            </Typography>
          </div>

          <div className={classes.item}>
            <Typography variant="caption">
              {translate.t('blockTheHourEditingAfterDays')}
            </Typography>
            <Typography variant="body2">
              {selectedCountryCustomHours.blockAfterDays}
            </Typography>
          </div>

          <IconButton
            component="span"
            onClick={changeEditHoursDialogVisibility('customHours', true)}
          >
            <EditIcon fontSize="small" />
          </IconButton>

          {dialogsVisibilityProps.customHours && (
            <EditWorkTimeSettingsDialog
              open={dialogsVisibilityProps.customHours}
              onClose={changeEditHoursDialogVisibility('customHours', false)}
              onSave={handleSaveCustomHours}
              hours={selectedCountryCustomHours}
            />
          )}
        </div>
      )}
    </Paper>
  );
};

export const EnhancedHoursSettings = compose<any, any>(
  withStyles(hoursSettingsStyles),
)(HoursSettingsComponent);

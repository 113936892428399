import { Grid, withStyles, WithStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { compose } from 'recompose';
import { Moment } from 'moment';

import LocalizedDatePicker from '@/app/components/Pickers/LocalizedDatePicker';
import { DATE_FORMAT } from '@/app/utils/helper';
import translate from '@/app/utils/translate';
import { styles } from './styles';

type OwnProps = {
  handleStartDateChange: (date: string | undefined) => void;
  handleEndDateChange: (date: string | undefined) => void;
  originalStartDateCheck: boolean;
};

type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const DateSelectElement: React.FC<Props> = ({
  classes,
  handleStartDateChange,
  handleEndDateChange,
  originalStartDateCheck,
}) => {
  const [selectStartDate, setSelectStartDate] = useState<Moment>(null);
  const [selectEndDate, setSelectEndDate] = useState<Moment>(null);

  const defaultProps = {
    autoOk: true,
    clearable: true,
    okLabel: translate.t('laOk'),
    cancelLabel: translate.t('laCancel'),
    clearLabel: translate.t('laClear'),
    className: classes.dateSelect,
  };

  const handleSelectStartDateChange = (value: Moment) => {
    if (value === null) {
      handleStartDateChange(undefined);
    } else {
      handleStartDateChange(value.format(DATE_FORMAT).toString());
    }
    setSelectStartDate(value);
  };

  const handleSelectEndDateChange = (value: Moment) => {
    if (value === null) {
      handleEndDateChange(undefined);
    } else {
      handleEndDateChange(value.format(DATE_FORMAT).toString());
    }
    setSelectEndDate(value);
  };

  return (
    <Grid
      item
      container
      direction="row"
      className={classes.dateSelectContainer}
    >
      <LocalizedDatePicker
        {...defaultProps}
        variant="outlined"
        label={translate.t('laStartDate')}
        maxDate={selectEndDate ? selectEndDate : undefined}
        disabled={originalStartDateCheck}
        value={selectStartDate}
        onChange={handleSelectStartDateChange}
      />

      <LocalizedDatePicker
        {...defaultProps}
        variant="outlined"
        label={translate.t('laEndDate')}
        minDate={selectStartDate ? selectStartDate : undefined}
        disabled={originalStartDateCheck}
        value={selectEndDate}
        onChange={handleSelectEndDateChange}
      />
    </Grid>
  );
};

const enhance = compose<Props, OwnProps>(withStyles(styles));

export default enhance(DateSelectElement);

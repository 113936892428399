import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { compose } from 'recompose';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  withStyles,
  WithStyles,
  Typography,
  Grid,
} from '@material-ui/core';
import DropdownIcon from '@material-ui/icons/ArrowDropDown';
import {
  closeResultsDialog,
  RESULTS_SUMMARY,
  SurveySummaryData,
  QuestionType,
  SurveyResultsData,
  getFilteredResults,
  UnitType,
  ServerQuestion,
} from '@/app/redux/surveys';
import translate from '@/app/utils/translate';
import surveyResultsDialogStyle from './surveyResultsDialogStyle';
import DonutChart from '../../Charts/DonutChart/DonutChart';
import BarChart from '../../Charts/BarChart/BarChart';
import LikertChart from '../../Charts/LikertChart/LikertChart';
import { ChildInputType } from '../../FormFields/FormFields';
import { getMyTeams } from '@/old/utils/helper';
import PropsMenu from '../../PropsMenu/PropsMenu';
import { ReducerState } from '@/app/redux/store';
import { exportSurveyResultsToExcel } from './exportSurveyResultsToExcel';

type MapStateToProps = {
  resultsRawObj: any;
  resultsObj: any;
  open: boolean;
  reviewers: UnitType[],
  questions: ServerQuestion[],
};

type MapDispatchToProps = {
  closeResultsDialog: () => void;
  filterResults: (surveyId: string, unitId: number) => void;
};

type PropsType = WithStyles<typeof surveyResultsDialogStyle> & MapDispatchToProps & MapStateToProps;

type State = {
  selectedUnit: string;
};
class SurveyResultsDialog extends React.Component<PropsType> {
  state: State = {
    selectedUnit: translate.t('label_all_units'),
  };

  onExport = () => {
    window.print();
  };

  onExportToExcel = () => {
    exportSurveyResultsToExcel(this.props.questions, this.props.resultsRawObj);
  };

  onShare = () => {
    window.print();
  };

  handleClose = () => {
    this.props.closeResultsDialog();
  };

  renderComments = (comObj: SurveyResultsData) => (
    <Grid
      item
      sm={12}
      md={6}
      key={`${comObj.type}-${comObj.id}`}
      className={this.props.classes.avoidBreak}
    >
      <Typography variant="h6">{comObj.title}</Typography>
      {comObj.answers.map((c: string, index: number) => (
        <Typography key={index} variant="body2">{index + 1}. {c}</Typography>
      ))}
    </Grid>
  );

  renderChart = (data: any) => {
    if (data.type === QuestionType.YES_NO) {
      return (
        <Grid
          item
          className={this.props.classes.avoidBreak}
          key={`${data.type}-${data.id}`}
        >
          <DonutChart
            data={data.answers}
            title={data.title}
            total={data.total}
            percentageLabel
          />
        </Grid>
      );
    }

    if (data.type === QuestionType.SCALE) {
      return (
        <Grid
          item
          className={this.props.classes.avoidBreak}
          key={`${data.type}-${data.id}`}
        >
          <LikertChart
            data={data.answers}
            legendData={data.legend}
            title={data.title}
            total={data.total}
          />
        </Grid>
      );
    }

    if (data.type === QuestionType.MULTIPLE_CHOICE || data.type === QuestionType.SINGLE_CHOICE) {
      return (
        <Grid
          item
          key={`${data.type}-${data.id}`}
          className={this.props.classes.avoidBreak}
        >
          <BarChart
            data={data.answers}
            title={data.title}
          />
        </Grid>
      );
    }

    if (data.type === QuestionType.FREE_TEXT) {
      return this.renderComments(data);
    }

    return null;
  };

  handleFilterSelect = (d: ChildInputType) => {
    this.setState({
      selectedUnit: d.label,
    });
    const { id: surveyId } = this.props.resultsObj;
    const unitId = !!d.value ? parseInt(d.value, 10) : undefined;

    this.props.filterResults(surveyId, unitId);
  };

  renderFilterSelect = () => {
    const { reviewers } = this.props;

    const unitList = getMyTeams().filter(team => (
      !!reviewers ?
      reviewers.some((r: UnitType) => r.id === parseInt(team.value, 10)) :
      true
    ));

    const allLabel = translate.t('label_all_units');
    const filterList = unitList && unitList.length > 1 ?
      [{label: allLabel, value: undefined}, ...unitList] :
      [{label: allLabel, value: undefined}];

    return filterList.length === 1 ? (
      <Typography variant="subtitle1">{allLabel}</Typography>
    ) : (
      <PropsMenu
        onSelect={this.handleFilterSelect}
        data={filterList}
      >
        <Button
          variant="text"
          color="primary"
        >
          {this.state.selectedUnit}
          <DropdownIcon fontSize="default" />
        </Button>
      </PropsMenu>
    );
  };

  render() {
    const { selectedUnit } = this.state;
    const { open, classes, resultsObj } = this.props;
    const { summary, results, name: surveyName, showResults } = resultsObj;

    return (
      <Dialog
        open={open}
        disableBackdropClick
        onEscapeKeyDown={this.handleClose}
        fullScreen
        hideBackdrop
        color="primary"
        scroll="body"
        classes={{
          root: classes.dialogRoot,
          container: classes.dialogContainer,
          paperFullScreen: classes.dialogPaperFullScreen
        }}
      >
        <DialogTitle>
          <div className={classes.headerContainer}>
            <Typography variant="h5" className={classes.title}>{surveyName}</Typography>
            <div className={classes.headerActions}>
              {this.renderFilterSelect()}
              <Button variant="contained" onClick={this.onExport}>
                {translate.t('laExport')}
              </Button>
              <Button variant="contained" onClick={this.onExportToExcel}>
                {translate.t('laExportToExcel')}
              </Button>
              <Button variant="contained" onClick={this.onShare}>
                {translate.t('label_share')}
              </Button>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container wrap="wrap" spacing={16}>
            {showResults ? (
              <>
                <Grid
                  container
                  item
                  direction="row"
                  wrap="wrap"
                  spacing={16}
                  justify="flex-start"
                  className={this.props.classes.avoidBreakContainer}
                >
                  <Typography variant="h6">{translate.t('title_summary')}</Typography>
                  <Grid
                    container
                    item
                    direction="row"
                    wrap="wrap"
                    justify="flex-start"
                    spacing={16}
                    className={this.props.classes.avoidBreakContainer}
                  >
                    {summary ?
                      summary.map((s: SurveySummaryData, index: number) => (
                        <Grid 
                          item
                          key={`${s.title}-${index}`}
                          className={this.props.classes.avoidBreak}
                        >
                          <DonutChart
                            data={s.data}
                            title={s.title}
                            percentageLabel={s.type !== RESULTS_SUMMARY.AGE}
                            total={s.total}
                          />
                        </Grid>
                      )) : null
                    }
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  direction="row"
                  wrap="wrap"
                  spacing={16}
                  justify="flex-start"
                  className={this.props.classes.avoidBreakContainer}
                >
                  <Typography variant="h6">{translate.t('title_results_by_question')}</Typography>
                  <Grid
                    container
                    item
                    direction="row"
                    wrap="wrap"
                    justify="flex-start"
                    spacing={16}
                    className={this.props.classes.avoidBreakContainer}
                  >
                    {results ?
                      results.map((r: SurveyResultsData) => (
                        this.renderChart(r)
                      )) : null
                    }
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid container item direction="row" wrap="wrap" spacing={16} justify="flex-start">
                <Typography variant="h6">
                  {selectedUnit === translate.t('label_all_units') ?
                    translate.t('text_no_survey_responses') :
                    translate.t('text_no_survey_responses_from_unit')
                  }
                </Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="primary" onClick={this.handleClose}>
            {translate.t('laClose')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state: ReducerState) => {
  const {
    surveyResultsOpen: open,
    surveyRawResults: resultsRawObj,
    surveyResults: resultsObj,
    surveyReviewers: reviewers,
    surveyQuestions: questions,
  } = state.surveys;

  return {
    open,
    resultsRawObj,
    resultsObj,
    reviewers,
    questions,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  closeResultsDialog: () => dispatch(closeResultsDialog()),
  filterResults: (surveyId: string, unitId: number) => dispatch<any>(getFilteredResults(surveyId, unitId)),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(surveyResultsDialogStyle, {withTheme: true}),
);

export default enhance(SurveyResultsDialog);

import Service from '@/app/utils/service';
import API from '@/app/api/internalAPIs';
import { Dispatch } from 'react';

import { HoursSettingsActionTypes } from '../data';
import { CountryHoursSettings } from '@/app/components/HoursSettings/hoursSettingsReducer';
import { getCompanyData, updateCompanyData } from '@/old/utils/helper';

export const updateDefaultHours = (
    hours: Omit<CountryHoursSettings, 'countryCode'>,
    dispatch: Dispatch<any>,
): void => {
    dispatch({type: HoursSettingsActionTypes.PUT_COUNTRY_DEFAULT_HOURS_PENDING});
    updateCompanyData(hours);
    Service.put(
        API.workTimeSettings.defaultHoursSettings(),
        {
            ...getCompanyData().config,
            fComTmWorkdayLengthHours: hours.workDayHours,
            fComTmMaxCumulativeHours: hours.maxCumulativeHours,
            fComTmMinCumulativeHours: hours.minCumulativeHours,
            fComTmPossibleEditingDays: hours.blockAfterDays,
        },
        (response: any) => {
            dispatch(
                {
                    type: HoursSettingsActionTypes.PUT_COUNTRY_DEFAULT_HOURS_FULFILLED,
                    payload: response,
                }
            );
        },
        (error: any) => {
            dispatch(
                {
                    type: HoursSettingsActionTypes.PUT_COUNTRY_DEFAULT_HOURS_REJECTED,
                    error
                }
            );
        }
    );

};

import moment from 'moment';
import { Reducer } from 'redux';

import {
  HoursSettings,
  TimeCard,
  TimeSheet,
  TimeSheetActionKeys,
  TimeSheetActionTypes,
} from './data';
import { WEEK_FORMAT } from './timeSheetConstants';
import { getTimeCardDailyBalance } from './services';
import { AutoDeductionRule } from '@/app/components/AutoDeduction/types';

export interface TimeSheetState {
  data?: TimeSheet;
  loadingTimeSheet: boolean;
  loadedTimeSheet: boolean;
  failedToLoadTimeSheet: boolean;
}

export interface TimeCardsState {
  // The map is ordered desc
  data: { [date: string]: TimeCard };
  weeksWeAreLoading: string[];
  weeksWeLoaded: string[];
  weeksFailedToLoad: string[];
  cardsWeAreSaving: string[];
  cardsFailedToSave: string[];
  cardsWeAreDeleting: string[];
  cardsFailedToDelete: string[];
}

export interface HoursSettingsState {
  data?: HoursSettings;
  loadingHoursSettings: boolean;
  loadedHoursSettings: boolean;
  failedToLoadHoursSettings: boolean;
}

export interface AutoDeductionRuleState {
  data?: AutoDeductionRule;
  loadingAutoDeductionRule: boolean;
  loadedAutoDeductionRule: boolean;
  failedToLoadAutoDeductionRule: boolean;
}

export interface PublicHolidaysState {
  data: string[];
  yearsWeAreLoading: string[];
  yearsLoaded: string[];
  yearsFailedToLoad: string[];
}

export interface TimeSheetStoreState {
  activeWeek: string;
  activeYears: string[];
  timeSheet: TimeSheetState;
  timeCards: TimeCardsState;
  hoursSettings: HoursSettingsState;
  publicHolidays: PublicHolidaysState;
  autoDeductionRule: AutoDeductionRuleState;
}

const WINDOW = window as any;

const getWeekYears = (week: string) => {
  const weekYears = [
    moment(week, WEEK_FORMAT)
      .startOf('isoWeek')
      .format('YYYY'),
  ];
  const endYear = moment(week, WEEK_FORMAT)
    .endOf('isoWeek')
    .format('YYYY');

  if (!weekYears.includes(endYear)) {
    weekYears.push(endYear);
  }

  return weekYears;
};

const initialActiveWeek = moment().format(WEEK_FORMAT);

/**
 * Because this reducer is included in external app as well,
 * we should not use window._ for generating default state.
 */
const defaultTimeSheetStoreState: TimeSheetStoreState = {
  activeWeek: initialActiveWeek,
  activeYears: getWeekYears(initialActiveWeek),
  timeSheet: {
    loadingTimeSheet: false,
    loadedTimeSheet: false,
    failedToLoadTimeSheet: false,
  },
  timeCards: {
    data: {},
    weeksWeAreLoading: [],
    weeksWeLoaded: [],
    weeksFailedToLoad: [],
    cardsWeAreSaving: [],
    cardsFailedToSave: [],
    cardsWeAreDeleting: [],
    cardsFailedToDelete: [],
  },
  hoursSettings: {
    loadingHoursSettings: false,
    loadedHoursSettings: false,
    failedToLoadHoursSettings: false,
  },
  publicHolidays: {
    data: [],
    yearsWeAreLoading: [],
    yearsLoaded: [],
    yearsFailedToLoad: [],
  },
  autoDeductionRule: {
    data: null,
    loadingAutoDeductionRule: false,
    loadedAutoDeductionRule: false,
    failedToLoadAutoDeductionRule: false,
  },
};

export type TimeSheetReducer = Reducer<
  TimeSheetStoreState,
  TimeSheetActionTypes
>;

type UnorderedTimeCards = {
  [date: string]: TimeCard;
};

const sortTimeCardsDesc = (
  unorderedTimeCards: UnorderedTimeCards,
): UnorderedTimeCards =>
  Object.keys(unorderedTimeCards)
    .sort()
    .reverse()
    .map(date => unorderedTimeCards[date])
    .reduce(
      (accumulator, timeCard) => ({
        ...accumulator,
        [timeCard.date]: timeCard,
      }),
      {},
    );

const resolveNewTotalBalance = (
  balanceISOString: string,
  balanceShift: moment.Duration,
) =>
  moment
    .duration(balanceISOString)
    .add(balanceShift)
    .toISOString();

const updateTimeCardTotalBalance = (
  timeCard: TimeCard,
  balanceShift: moment.Duration,
): TimeCard => ({
  ...timeCard,
  totalBalanceOnDate: resolveNewTotalBalance(
    timeCard.totalBalanceOnDate,
    balanceShift,
  ),
});

const resolveTimeCardsDataWithNewTotalBalance = (
  timeCardsData: UnorderedTimeCards,
  payload: TimeCard,
  payloadDate: string,
  balanceShift: moment.Duration,
): UnorderedTimeCards => {
  const payloadTimeCardData =
    payload !== null
      ? {
          [payloadDate]: updateTimeCardTotalBalance(payload, balanceShift),
        }
      : {};

  let newTimeCardsData = {
    ...timeCardsData,
    ...payloadTimeCardData,
  };

  // Update total balance on TimeCards dated after payload
  Object.entries(timeCardsData).forEach(([date, timeCard]) => {
    if (moment(date).isAfter(payloadDate, 'day')) {
      newTimeCardsData[date] = updateTimeCardTotalBalance(
        timeCard,
        balanceShift,
      );
    }
  });

  return newTimeCardsData;
};

export const timeSheetReducer: TimeSheetReducer = (
  state = defaultTimeSheetStoreState,
  action,
) => {
  switch (action.type) {
    case TimeSheetActionKeys.CHANGE_WEEK: {
      const nextActiveYears = getWeekYears(action.payload);

      return {
        ...state,
        activeWeek: action.payload,
        // Avoid changing reference when active years were not changed
        activeYears: WINDOW._.isEqual(state.activeYears, nextActiveYears)
          ? state.activeYears
          : nextActiveYears,
      };
    }
    case TimeSheetActionKeys.GET_TIME_SHEET_PENDING: {
      return {
        ...state,
        timeSheet: {
          ...state.timeSheet,
          loadingTimeSheet: true,
        },
      };
    }
    case TimeSheetActionKeys.GET_TIME_SHEET_FULFILLED: {
      return {
        ...state,
        timeSheet: {
          ...state.timeSheet,
          data: action.payload,
          loadingTimeSheet: false,
          loadedTimeSheet: true,
          failedToLoadTimeSheet: false,
        },
      };
    }
    case TimeSheetActionKeys.GET_TIME_SHEET_REJECTED: {
      return {
        ...state,
        timeSheet: {
          ...state.timeSheet,
          loadingTimeSheet: false,
          failedToLoadTimeSheet: true,
        },
      };
    }
    case TimeSheetActionKeys.GET_TIME_CARDS_PENDING: {
      return {
        ...state,
        timeCards: {
          ...state.timeCards,
          weeksWeAreLoading: WINDOW._.union(state.timeCards.weeksWeAreLoading, [
            action.meta,
          ]),
        },
      };
    }
    case TimeSheetActionKeys.GET_TIME_CARDS_FULFILLED: {
      return {
        ...state,
        timeCards: {
          ...state.timeCards,
          data: sortTimeCardsDesc({
            ...state.timeCards.data,
            ...action.payload.reduce(
              (accumulator, timeCard) => ({
                ...accumulator,
                [timeCard.date]: timeCard,
              }),
              {},
            ),
          }),
          weeksWeAreLoading: WINDOW._.difference(
            state.timeCards.weeksWeAreLoading,
            [action.meta],
          ),
          weeksWeLoaded: WINDOW._.union(state.timeCards.weeksWeLoaded, [
            action.meta,
          ]),
          weeksFailedToLoad: WINDOW._.difference(
            state.timeCards.weeksFailedToLoad,
            [action.meta],
          ),
        },
      };
    }
    case TimeSheetActionKeys.GET_TIME_CARDS_REJECTED: {
      return {
        ...state,
        timeCards: {
          ...state.timeCards,
          weeksWeAreLoading: WINDOW._.difference(
            state.timeCards.weeksWeAreLoading,
            [action.meta],
          ),
          weeksFailedToLoad: WINDOW._.union(state.timeCards.weeksFailedToLoad, [
            action.meta,
          ]),
        },
      };
    }
    case TimeSheetActionKeys.UPDATE_TIME_CARD_PENDING: {
      return {
        ...state,
        timeCards: {
          ...state.timeCards,
          cardsWeAreSaving: WINDOW._.union(state.timeCards.cardsWeAreSaving, [
            action.meta,
          ]),
        },
      };
    }
    case TimeSheetActionKeys.UPDATE_TIME_CARD_FULFILLED: {
      const balanceShift = getTimeCardDailyBalance(action.payload).subtract(
        getTimeCardDailyBalance(state.timeCards.data[action.meta]),
      );

      return {
        ...state,
        timeSheet: {
          ...state.timeSheet,
          data: {
            extraHoursBalance: resolveNewTotalBalance(
              state.timeSheet.data && state.timeSheet.data.extraHoursBalance,
              balanceShift,
            ),
          },
        },
        timeCards: {
          ...state.timeCards,
          data: sortTimeCardsDesc(
            resolveTimeCardsDataWithNewTotalBalance(
              state.timeCards.data,
              action.payload,
              action.meta,
              balanceShift,
            ),
          ),
          cardsWeAreSaving: WINDOW._.difference(
            state.timeCards.cardsWeAreSaving,
            [action.meta],
          ),
          cardsFailedToSave: WINDOW._.difference(
            state.timeCards.cardsFailedToSave,
            [action.meta],
          ),
        },
      };
    }
    case TimeSheetActionKeys.UPDATE_TIME_CARD_REJECTED: {
      return {
        ...state,
        timeCards: {
          ...state.timeCards,
          cardsWeAreSaving: WINDOW._.difference(
            state.timeCards.cardsWeAreSaving,
            [action.meta],
          ),
          cardsFailedToSave: WINDOW._.union(state.timeCards.cardsFailedToSave, [
            action.meta,
          ]),
        },
      };
    }
    case TimeSheetActionKeys.DELETE_TIME_CARD_PENDING: {
      return {
        ...state,
        timeCards: {
          ...state.timeCards,
          cardsWeAreDeleting: WINDOW._.union(
            state.timeCards.cardsWeAreDeleting,
            [action.meta],
          ),
        },
      };
    }
    case TimeSheetActionKeys.DELETE_TIME_CARD_FULFILLED: {
      const balanceShift = moment
        .duration(0)
        .subtract(getTimeCardDailyBalance(state.timeCards.data[action.meta]));

      return {
        ...state,
        timeSheet: {
          ...state.timeSheet,
          data: {
            extraHoursBalance: resolveNewTotalBalance(
              state.timeSheet.data && state.timeSheet.data.extraHoursBalance,
              balanceShift,
            ),
          },
        },
        timeCards: {
          ...state.timeCards,
          data: sortTimeCardsDesc(
            resolveTimeCardsDataWithNewTotalBalance(
              WINDOW._.omit(state.timeCards.data, action.meta),
              null,
              action.meta,
              balanceShift,
            ),
          ),
          cardsWeAreDeleting: WINDOW._.difference(
            state.timeCards.cardsWeAreDeleting,
            [action.meta],
          ),
          cardsFailedToDelete: WINDOW._.difference(
            state.timeCards.cardsFailedToDelete,
            [action.meta],
          ),
        },
      };
    }
    case TimeSheetActionKeys.DELETE_TIME_CARD_REJECTED: {
      return {
        ...state,
        timeCards: {
          ...state.timeCards,
          cardsWeAreDeleting: WINDOW._.difference(
            state.timeCards.cardsWeAreDeleting,
            [action.meta],
          ),
          cardsFailedToDelete: WINDOW._.union(
            state.timeCards.cardsFailedToDelete,
            [action.meta],
          ),
        },
      };
    }
    case TimeSheetActionKeys.GET_HOURS_SETTINGS_PENDING: {
      return {
        ...state,
        hoursSettings: {
          ...state.hoursSettings,
          loadingHoursSettings: true,
        },
      };
    }
    case TimeSheetActionKeys.GET_HOURS_SETTINGS_FULFILLED: {
      return {
        ...state,
        hoursSettings: {
          ...state.hoursSettings,
          data: action.payload,
          loadingHoursSettings: false,
          loadedHoursSettings: true,
          failedToLoadHoursSettings: false,
        },
      };
    }
    case TimeSheetActionKeys.GET_HOURS_SETTINGS_REJECTED: {
      return {
        ...state,
        hoursSettings: {
          ...state.hoursSettings,
          loadingHoursSettings: false,
          failedToLoadHoursSettings: true,
        },
      };
    }
    case TimeSheetActionKeys.GET_AUTO_DEDUCTION_RULE_PENDING: {
      return {
        ...state,
        autoDeductionRule: {
          ...state.autoDeductionRule,
          loadingAutoDeductionRule: true,
        },
      };
    }
    case TimeSheetActionKeys.GET_AUTO_DEDUCTION_RULE_FULFILLED: {
      return {
        ...state,
        autoDeductionRule: {
          ...state.autoDeductionRule,
          data: action.payload,
          loadingAutoDeductionRule: false,
          loadedAutoDeductionRule: true,
          failedToLoadAutoDeductionRule: false,
        },
      };
    }
    case TimeSheetActionKeys.GET_AUTO_DEDUCTION_RULE_REJECTED: {
      return {
        ...state,
        autoDeductionRule: {
          ...state.autoDeductionRule,
          loadingAutoDeductionRule: false,
          failedToLoadAutoDeductionRule: true,
        },
      };
    }
    case TimeSheetActionKeys.GET_PUBLIC_HOLIDAYS_PENDING: {
      return {
        ...state,
        publicHolidays: {
          ...state.publicHolidays,
          yearsWeAreLoading: WINDOW._.union(
            state.publicHolidays.yearsWeAreLoading,
            action.meta,
          ),
        },
      };
    }
    case TimeSheetActionKeys.GET_PUBLIC_HOLIDAYS_FULFILLED: {
      return {
        ...state,
        publicHolidays: {
          ...state.publicHolidays,
          data: WINDOW._.union(state.publicHolidays.data, action.payload),
          yearsWeAreLoading: WINDOW._.difference(
            state.publicHolidays.yearsWeAreLoading,
            action.meta,
          ),
          yearsLoaded: WINDOW._.union(
            state.publicHolidays.yearsLoaded,
            action.meta,
          ),
          yearsFailedToLoad: WINDOW._.difference(
            state.publicHolidays.yearsFailedToLoad,
            action.meta,
          ),
        },
      };
    }
    case TimeSheetActionKeys.GET_PUBLIC_HOLIDAYS_REJECTED: {
      return {
        ...state,
        publicHolidays: {
          ...state.publicHolidays,
          yearsWeAreLoading: WINDOW._.difference(
            state.publicHolidays.yearsWeAreLoading,
            action.meta,
          ),
          yearsFailedToLoad: WINDOW._.union(
            state.publicHolidays.yearsFailedToLoad,
            action.meta,
          ),
        },
      };
    }
    case TimeSheetActionKeys.CLEAR_STATE: {
      return defaultTimeSheetStoreState;
    }
    default: {
      return state;
    }
  }
};

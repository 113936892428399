import * as React from 'react';
import {
  Icon,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  WithStyles,
  withStyles,
  Button,
} from '@material-ui/core';
import { IWorklistTask, EmployeeAutomation } from '../types';
import translate from '@/app/utils/translate';

import styles from './taskTable/styles';
import TaskEditDialog from './TaskEditDialog';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import { getEnums, downloadAttachment } from '@/old/utils/helper';
import {
  getEmployeeAutomationTransLabel,
  getDueDateAutomationText,
} from '../service';
import API from '@/app/api/internalAPIs';

type OwnProps = {
  tasks: IWorklistTask[];
  onDelete: (id: number) => void;
  onUpdate: (values: IWorklistTask) => void;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const sortByName = (a: any, b: any) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

const getTaskTypes = () => {
  return getEnums('COLLAB_TASK_TYPE').filter((item: any) => item.isActive);
};

const TaskTable = (props: Props) => {
  const { classes, tasks, onDelete, onUpdate } = props;
  const [rowMenuAnchorEl, setRowMenuAnchorEl] = React.useState<any>();
  const [taskEditDialogOpen, setTaskEditDialogOpen] = React.useState(false);
  const [taskDeleteConfirmOpen, setTaskDeleteConfirmOpen] = React.useState(
    false,
  );
  const [selectedTask, setSelectedTask] = React.useState<any>();
  const taskTypes = getTaskTypes();

  const getAutomation = (task: IWorklistTask): React.ReactNode => {
    const dueDateString =
      task.dueDateAutomationType === 'NONE'
        ? ''
        : `${translate.t('fTaskDueDate')}: ${getDueDateAutomationText(
            task.dueDateAutomationType,
            task.dueDateAutomationDays,
          )}`;

    const getEmployeeAutomation = (
      key: EmployeeAutomation,
      automationType: string,
    ): string => {
      if (key === 'NONE') {
        return '';
      } else {
        return `${automationType} ${translate.t(
          getEmployeeAutomationTransLabel(key),
        )}`;
      }
    };

    const assigneeString = getEmployeeAutomation(
      task.assigneeAutomation,
      `${translate.t('laAssignee')}:`,
    );
    const pertainingToString = getEmployeeAutomation(
      task.pertainingToAutomation,
      `${translate.t('laPertaining')}:`,
    );

    return (
      <>
        <div>{dueDateString}</div>
        <div>{assigneeString}</div>
        <div>{pertainingToString}</div>
      </>
    );
  };

  const getTaskTypeTranslation = (type: string) => {
    const taskType = taskTypes.find((item: any) => item.code === type);
    if (!taskType) {
      return '';
    }
    return taskType.name;
  };

  const handleDelete = React.useCallback(() => {
    onDelete(selectedTask.id);
  }, [selectedTask]);

  const handleUpdate = React.useCallback(async (values: IWorklistTask) => {
    await onUpdate(values);
  }, []);

  const renderTasks = () => {
    const sortByTitle = (a: IWorklistTask, b: IWorklistTask) => {
      return a.title.localeCompare(b.title);
    };

    return tasks.sort(sortByTitle).map((task: IWorklistTask) => {
      const handleRowMenuOpen = (e: React.SyntheticEvent) => {
        setRowMenuAnchorEl(e.target);
        setSelectedTask(task);
      };

      const handleStartEdit = (_e: React.SyntheticEvent) => {
        setTaskEditDialogOpen(true);
        setRowMenuAnchorEl(undefined);
      };

      const handleStartDelete = () => {
        setTaskDeleteConfirmOpen(true);
        setRowMenuAnchorEl(undefined);
      };

      const rowMenu = !rowMenuAnchorEl ? (
        undefined
      ) : (
        <Menu
          open={Boolean(rowMenuAnchorEl)}
          anchorEl={rowMenuAnchorEl}
          onClose={() => setRowMenuAnchorEl(undefined)}
        >
          <MenuItem onClick={handleStartEdit}>
            <ListItemIcon>
              <Icon>edit</Icon>
            </ListItemIcon>
            {translate.t('laEdit')}
          </MenuItem>
          <MenuItem onClick={handleStartDelete}>
            <ListItemIcon>
              <Icon>delete</Icon>
            </ListItemIcon>
            {translate.t('laDelete')}
          </MenuItem>
        </Menu>
      );

      const downloadAttButton =
        task.attachmentId > -1 && task.attachmentName !== '' ? (
          <Button
            className={classes.attButton}
            variant="text"
            onClick={() =>
              downloadAttachment(
                API.worklists.taskAttachment(task.id, task.attachmentId),
              )
            }
          >
            <Icon fontSize="small">attach_file</Icon>
            {task.attachmentName}
          </Button>
        ) : (
          undefined
        );

      return (
        <React.Fragment key={`task${task.id}`}>
          <TableRow>
            {rowMenu}
            <TableCell>{task.title}</TableCell>
            <TableCell>{getTaskTypeTranslation(task.type)}</TableCell>
            <TableCell>{getAutomation(task)}</TableCell>
            <TableCell>{downloadAttButton}</TableCell>
            <TableCell align="right">
              <IconButton onClick={handleRowMenuOpen}>
                <Icon fontSize="small">more_vert</Icon>
              </IconButton>
            </TableCell>
          </TableRow>
        </React.Fragment>
      );
    });
  };

  if (!tasks || tasks.length === 0) {
    return (
      <div className={classes.noData}>
        {translate.t('text_no_tasks_on_worklist')}
      </div>
    );
  }

  const taskDialogs = !selectedTask ? (
    undefined
  ) : (
    <>
      <TaskEditDialog
        open={taskEditDialogOpen}
        initialValues={selectedTask}
        taskTypes={taskTypes.sort(sortByName)}
        onSave={handleUpdate}
        onClose={() => setTaskEditDialogOpen(false)}
      />
      <ConfirmDialog
        open={taskDeleteConfirmOpen}
        text={translate.t('text_confirm_worklist_task_delete', {
          title: selectedTask.title,
        })}
        customOk={translate.t('laDelete')}
        onOk={handleDelete}
        onClose={() => setTaskDeleteConfirmOpen(false)}
      />
    </>
  );

  return (
    <>
      {taskDialogs}
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>{translate.t('table_col_title')}</TableCell>
            <TableCell>{translate.t('table_col_type')}</TableCell>
            <TableCell>{translate.t('table_col_automation')}</TableCell>
            <TableCell>{translate.t('table_col_attachment')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>{renderTasks()}</TableBody>
      </Table>
    </>
  );
};

export default withStyles(styles, { withTheme: true })(TaskTable);

import { TemplateComponentTypeKey } from '@/app/components/TemplateComponents/form/InputComponents/index';

export const generateTextFieldKey = (prefix: string, comment: string) => {
  if (comment && comment.length) {
    return `${prefix}-${comment.length}`;
  }

  return `${prefix}-0`;
};

export const resolveComponentResponse = (
  component: any,
  evaluationResponses: any,
  defaultValue: any,
) => {
  if (!evaluationResponses) {
    return defaultValue;
  }

  const componentResponse = evaluationResponses.find(
    (o: any) => o.componentId === component.id,
  );

  if (componentResponse && componentResponse.response) {
    return componentResponse.response;
  }

  if (component.type === TemplateComponentTypeKey.FILE) {
    // File attachment data seems to be provided in componentResponse.response
    // This may be obsolete case handling, included here just in case during refactoring
    if (componentResponse && componentResponse.file) {
      return componentResponse.file;
    }
  }

  return defaultValue;
};

export const preloadLatestEvalResp = (
  component: any,
  evaluationResponses: any,
) => {
  const componentResponse = evaluationResponses.find(
    (o: any) => o.componentId === component.id,
  );
  if (componentResponse && componentResponse.response) {
    return componentResponse.response;
  }

  if (component.type === TemplateComponentTypeKey.TEXT) {
    return '';
  }

  if (
    component.type === TemplateComponentTypeKey.DATE ||
    component.type === TemplateComponentTypeKey.SCALE
  ) {
    return null;
  }

  return [];
};

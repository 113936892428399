import { StyleRulesCallback, Theme } from '@material-ui/core';

const styles: StyleRulesCallback = (theme: Theme) => ({
  automationTitle: {
    fontWeight: theme.typography.fontWeightMedium,
    paddingTop: theme.spacing.unit * 3,
    textTransform: 'uppercase',
  },
  dueDateAutomationType: {
    minWidth: 180,
    paddingRight: theme.spacing.unit,
  },
  dropzoneArea: {
    backgroundColor: theme.palette.background.default,
    border: '1px dashed #c8c8c8',
    borderRadius: 5,
    marginTop: theme.spacing.unit * 2,
    textAlign: 'center',
    width: '100%',
  },
  dropzoneParagraph: {
    padding: theme.spacing.unit,
  },
  existingAttachments: {
    marginTop: 16,
  },
  existingAttachment: {
    display: 'table',
  },
  attachmentButton: {
    display: 'table-cell',
    verticalAlign: 'middle',
    marginLeft: theme.spacing.unit / 2,
    padding: theme.spacing.unit / 2,
  },
  attachmentName: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
});

export default styles;

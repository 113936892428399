import { StyleRulesCallback } from '@material-ui/core';

export type WeekSelectorStyleKeys = 'root' | 'label' | 'calendar' | 'button' | 'day' | 'nonCurrentMonthDay' | 'highlightNonCurrentMonthDay' | 'highlight' | 'firstHighlight' | 'endHighlight';
export const weekSelectorStyles: StyleRulesCallback<WeekSelectorStyleKeys> = (theme) => ({
    root: {
        color: theme.palette.primary.main,
        marginLeft: 4 * theme.spacing.unit,
    },
    label: {
        marginLeft: 4 * theme.spacing.unit,
        display: 'inline-block',
    },
    calendar: {
        width: 16 * theme.spacing.unit,
        textColor: theme.palette.primary.main,
    },
    button: {
        color: theme.palette.primary.main,
        marginLeft: 4 * theme.spacing.unit,
        marginTop: theme.spacing.unit,
        textTransform: 'none',
    },
    day: {
      width: 36,
      height: 36,
      fontSize: theme.typography.caption.fontSize,
      margin: '0 2px',
      color: 'inherit',
    },
    nonCurrentMonthDay: {
      color: theme.palette.text.disabled,
    },
    highlightNonCurrentMonthDay: {
      color: '#676767',
    },
    highlight: {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    firstHighlight: {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
    },
    endHighlight: {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
    },

});

import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const textFieldStyle: StyleRulesCallback = () => ({
  field: {
    '& label': {
      width: '100%',
      display: 'inline-flex',
    },
  },
});

export default textFieldStyle;

import * as React from 'react';
import { compose } from 'recompose';
import { withStyles, Tooltip, IconButton, WithStyles } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import DuplicateIcon from '@material-ui/icons/FileCopy';
import TranslateIcon from '@material-ui/icons/Translate';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BlockIcon from '@material-ui/icons/Block';
import topMenuToolBarStyle from './topMenuToolBarStyle';
import { LanguagesType } from '@/app/redux/languages';
import translate from '@/app/utils/translate';

type PropsType = WithStyles<typeof topMenuToolBarStyle>;

type ToolbarTypes = {
  selectedTemplateIds: any[];
  addTemplate: any;
  changeTemplateStatus: any;
  deleteTemplate: any;
  duplicateTemplate: any;
  translateTemplate: any;
  handleEditTemplate: any;
  languages: LanguagesType;
};

class TopMenuToolBar extends React.PureComponent<PropsType & ToolbarTypes> {
  handleTemplateAdd = () => {
    this.props.addTemplate();
  };

  handleTemplateStatusChange = (newStatus: string) => {
    this.props.changeTemplateStatus(this.props.selectedTemplateIds, newStatus);
  };

  handleTemplateDeletion = () => {
    this.props.deleteTemplate(this.props.selectedTemplateIds);
  };

  handleTemplateDuplication = () => {
    const [templateId] = this.props.selectedTemplateIds;
    this.props.duplicateTemplate(templateId);
  };

  handleTemplateTranslate = () => {
    const [templateId] = this.props.selectedTemplateIds;
    this.props.translateTemplate(templateId);
  };

  handleTemplateEdit = () => {
    this.props.handleEditTemplate(this.props.selectedTemplateIds);
  };

  render() {
    const { classes, selectedTemplateIds } = this.props;

    return (
      <>
        <span className={classes.root}>
          <Tooltip title={translate.t('laAdd')}>
            <IconButton onClick={this.handleTemplateAdd}>
              <AddCircleIcon color="primary" />
            </IconButton>
          </Tooltip>

          {selectedTemplateIds.length === 1 &&
            <>
              <Tooltip title={translate.t('laEdit')}>
                <IconButton onClick={this.handleTemplateEdit}>
                  <EditIcon color="primary"/>
                </IconButton>
              </Tooltip>

              <Tooltip title={translate.t('label_duplicate')}>
                <IconButton onClick={this.handleTemplateDuplication}>
                  <DuplicateIcon color="primary"/>
                </IconButton>
              </Tooltip>

              <Tooltip title={translate.t('title_translate')}>
                <IconButton onClick={this.handleTemplateTranslate}>
                  <TranslateIcon color="primary"/>
                </IconButton>
              </Tooltip>
            </>
          }

          {selectedTemplateIds.length > 0 &&
            <>
              <Tooltip title={translate.t('laActivate')}>
                <IconButton onClick={() => this.handleTemplateStatusChange('ACTIVE')}>
                    <CheckCircleIcon color="primary"/>
                </IconButton>
              </Tooltip>

              <Tooltip title={translate.t('label_deactivate')}>
                <IconButton onClick={() => this.handleTemplateStatusChange('INACTIVE')}>
                  <BlockIcon color="primary"/>
                </IconButton>
              </Tooltip>

              <Tooltip title={translate.t('laDelete')}>
                <IconButton onClick={this.handleTemplateDeletion}>
                  <DeleteIcon color="primary"/>
                </IconButton>
              </Tooltip>
            </>
          }
        </span>
      </>
    );
  }
}

const enhance = compose<any, any>(withStyles(topMenuToolBarStyle));

export default enhance(TopMenuToolBar);
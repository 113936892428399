import React, { SyntheticEvent, useState } from 'react';
import {
  TableRow,
  TableCell,
  IconButton,
  Icon,
  WithStyles,
  withStyles,
  StyleRulesCallback,
  Typography,
  CircularProgress,
  Checkbox,
} from '@material-ui/core';
import { CompanyTraining } from '../../types';
import {
  formatTrainingDates,
  getResponsiblePersonsNames
} from '../../helpers';
import AudienceDetailsPopover from './trainingTableRow/AudienceDetailsPopover';
import { ParticipantsButton } from '../';
import { eventTargetAsHtmlElement } from '@/old/utils/helper';

const styles: StyleRulesCallback = () => ({
  cellButton: {
    textTransform: 'none',
  },
  inactiveRow: {
    backgroundColor: '#dddddd',
  },
});

type OwnProps = {
  training: CompanyTraining;
  loading?: boolean;
  onOpenRowMenu: (e: SyntheticEvent) => void;
  onOpenParticipants: (training: CompanyTraining) => void;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const TrainingTableRow = ({
  classes,
  training,
  loading = false,
  onOpenRowMenu,
  onOpenParticipants,
}: Props) => {
  const [audiencePopoverAnchorEl, setAudiencePopoverAnchorEl] = useState<HTMLElement|undefined>(undefined);

  const audienceCellContent =
    (!!training.unitAudience && training.unitAudience.length > 0) ||
    (!!training.roleAudience && training.roleAudience.length > 0) ||
    (!!training.countryAudience && training.countryAudience.length > 0) ? (
    <>
      <AudienceDetailsPopover
        training={training}
        anchorEl={audiencePopoverAnchorEl}
        onClose={() => setAudiencePopoverAnchorEl(undefined)}
      />
      <IconButton onClick={(e) => setAudiencePopoverAnchorEl(eventTargetAsHtmlElement(e.target))}>
        <Icon>supervised_user_circle</Icon>
      </IconButton>
    </>
  ) : null;

  const moreMenu = !loading ? (
    <IconButton onClick={onOpenRowMenu} data-testid="btn-training-table-row-options">
      <Icon>more_vert</Icon>
    </IconButton>
  ) : (
    <CircularProgress size={32} />
  );

  return (
    <TableRow className={training.active === false ? classes.inactiveRow : null}>
      <TableCell>
        <Typography variant="body2">
          {training.name.label}
        </Typography>
        <Typography variant="caption">{!training.trainingClass ? null : training.trainingClass.label}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{training.provider}</Typography>
        <Typography variant="caption">
          {getResponsiblePersonsNames(training.responsiblePersons.map(rp => rp.value as number))}
        </Typography>
      </TableCell>
      <TableCell>
        {training.trainingGroup.label}
      </TableCell>
      <TableCell>
        {training.language.label}
      </TableCell>
      <TableCell>
        {formatTrainingDates(training.startDate, training.endDate)}
      </TableCell>
      <TableCell>
        {audienceCellContent}
      </TableCell>
      <TableCell>
        <ParticipantsButton
          className={classes.cellButton}
          disabled={loading}
          trainingId={training.id}
          onOpenParticipants={() => onOpenParticipants(training)}
        />
      </TableCell>
      <TableCell>
        <Checkbox color="primary" checked={!!training.includeInTrainingCompensation} disabled />
      </TableCell>
      <TableCell>
        {moreMenu}
      </TableCell>
    </TableRow>
  );
};

export default withStyles(styles)(TrainingTableRow);

import * as React from 'react';
import {
  ResponsiveLine,   // TODO! @nivo/line has missing "onClick" prop that has been fixed in 'nivo-responsive-line-fix.d.ts' file!!
  LineDatum,
  LineSerieData,
  DataFormatter,
  LineTooltipProps,
} from '@nivo/line';
import translate from '@/app/utils/translate';

import LineTooltip from './empCountLineChart/LineTooltip';

const getScale = (min: number, max: number): number => {
  if (max - min > 100000) {
    return 50000;
  } else if (max - min > 10000) {
    return 5000;
  } else if (max - min > 1000) {
    return 500;
  } else if (max - min > 100) {
    return 50;
  }
  return 5;
};

type OwnProps = {
  data: LineSerieData[],
  tooltipMenuItems?: React.ReactNodeArray,
  onPointClick?: (
    datum: LineDatum,
    event: React.MouseEvent<SVGElement, MouseEvent>
  ) => void;
  onFormatX?: DataFormatter;
};
type Props = OwnProps;

const EmpCountLineChart = ({
  data,
  tooltipMenuItems,
  onFormatX,
  onPointClick,
}: Props) => {
  let min = 99999999;
  let max = -99999999;
  data.forEach((serie) => {
    serie.data.forEach((item) => {
      if (item.y < min) {
        min = item.y as number;
      }
      if (item.y > max) {
        max = item.y as number;
      }
    });
  });

  const scale = getScale(min, max);
  const chartMin = min - scale < 0 ? 0 : min - scale;
  const chartMax = max + scale;

  return (
    <ResponsiveLine
      data={data}
      colors={['#05A4D8']}
      isInteractive={true}
      margin={{
        top: 50,
        right: 100,
        bottom: 50,
        left: 100,
      }}
      lineWidth={3}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      legends={[]}
      axisBottom={{
        legend: translate.t('laObservationDates'),
        legendPosition: 'middle',
        legendOffset: 40,
      }}
      enableArea={true}
      areaBaselineValue={chartMin}
      axisLeft={{
        legend: translate.t('laNumberOfEmp'),
        legendPosition: 'middle',
        legendOffset: -60,
      }}
      xFormat={!onFormatX ? undefined : onFormatX}
      xScale={{
        type: 'point',
      }}
      yScale={{
        type: 'linear',
        min: chartMin,
        max: chartMax,
      }}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      useMesh={true}
      tooltip={(tt: LineTooltipProps) => <LineTooltip tooltipProps={tt} menuItems={tooltipMenuItems} />}
      onClick={onPointClick}
      theme={{
        background: 'white',
        axis: {
          legend: {
            text: {
              letterSpacing: 1.1,
              fontWeight: 'bold',
            },
          },
        },
      }}
    />
  );
};

export default EmpCountLineChart;

import * as React from 'react';
import { SortableContainer, SortableElement, arrayMove, SortableHandle } from 'react-sortable-hoc';
import { withStyles } from '@material-ui/core/styles';
import { WithStyles, Paper, Typography } from '@material-ui/core';
import sortableComponentStyle from './sortableComponentStyle';
import DragHandleIcon from '@material-ui/icons/DragHandle';

interface SortableItem {
  value: any;
}

interface OwnProps {
  list?: any[];
  updatedList: (list: any[]) => void;
  showDragHandle?: boolean;
}

type SortStateType = {
  items: any;
};

type Props = OwnProps & WithStyles<typeof sortableComponentStyle>;

class SortableComponent extends React.Component<Props> {
  state: SortStateType = {
    items: [],
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      items: props.list,
    };
  }

  DragHandle = SortableHandle(() => <DragHandleIcon />);

  SortableItem = SortableElement(({ value }: { value: SortableItem } ) => {
    const { classes, showDragHandle } = this.props;
    return (
      <Paper className={classes.root} style={{zIndex: 50000}} elevation={1}>
        <Typography variant="subtitle1">{value}</Typography>
        {showDragHandle ? <this.DragHandle /> : null}
      </Paper>
    );
  });

  SortableList = SortableContainer(({ items }: { items: any }) => {
    return (
      <div>
        {items.map((value: any, index: number) => (
          <this.SortableItem key={`item-${index}`} index={index} value={value.name} />
        ))}
      </div>
    );
  });

  onSortEnd = ({oldIndex, newIndex}: {oldIndex: number, newIndex: number}) => {
    this.setState({
      items: arrayMove(this.state.items, oldIndex, newIndex),
    });

    this.props.updatedList(this.state.items);
  };

  render() {
    return <this.SortableList items={this.state.items} onSortEnd={this.onSortEnd} />;
  }
}

export default withStyles(sortableComponentStyle, {withTheme: true})(SortableComponent);

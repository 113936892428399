import {
  Button,
  Checkbox,
  FormControlLabel,
  Icon,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import compose from 'recompose/compose';
import * as React from 'react';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import translate from '@/app/utils/translate';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { SnackbarProvider } from 'notistack';
import { ReportSettingsUtils } from '@/app/components/ReportSettingsUtils';
import ColumnMenu from '@/app/components/Kendo/ColumnMenu';

import { useCallback } from 'react';

import styles from './styles';
import { WorkflowReportTypes } from './ReportTypes';
import { useWorkflowsReport } from './useWorkflowsReport';
import SelectFilter from './components/KendoFilters/SelectFilter';
import { ToolbarManagerAndHr } from './components/ToolbarManagerAndHr';

type InnerProps = WithStyles<typeof styles>;
type OwnProps = { reportType: WorkflowReportTypes };

type Props = OwnProps & InnerProps;

const WorkflowsReport = (props: Props) => {
  const { reportType, classes } = props;

  const {
    gridData,
    loading,
    onColumnsSubmit,
    onDataStateChange,
    filterCellOptions: { unitOptions, workflowTypeOptions },
    error,
    excelData,
    toolbarSettings,
    reportSettings,
    onChangeShowDetails,
    onApplyReportSettings,
  } = useWorkflowsReport({ reportType });
  const { columns, dataState, showDetails } = reportSettings.current;

  const columnSelectFilter = useCallback(
    fieldId => {
      switch (fieldId) {
        case 'unitNo':
          return SelectFilter(unitOptions, 'Select unit');
        case 'type':
          return SelectFilter(workflowTypeOptions, 'Select category');
        default:
          return undefined;
      }
    },
    [workflowTypeOptions, unitOptions],
  );

  if (loading) {
    return <div>{loading}</div>;
  }
  if (error) {
    return <div>{'error'}</div>;
  }
  if (!gridData) {
    return <div>{'no data'}</div>;
  }

  let _export: any;

  const downloadWorkflowsReportExcelFile = () => {
    const filteredDataState = {
      ...dataState,
      // Export all the records
      skip: 0,
      take: undefined as number,
    };
    !showDetails.history
      ? _export.save(process(excelData.current, filteredDataState))
      : _export.save(process(excelData.history, filteredDataState));
  };

  const DownloadButton = () => {
    return (
      <Button onClick={downloadWorkflowsReportExcelFile}>
        {translate.t('laDownload')} &nbsp;{' '}
        <Icon fontSize="small">cloud_download</Icon>
      </Button>
    );
  };

  return (
    <ExcelExport
      fileName={excelData.fileName}
      ref={excelExport => (_export = excelExport)}
    >
      <Grid
        data={process(gridData, dataState)}
        onDataStateChange={onDataStateChange}
        {...dataState}
        sortable={true}
        resizable={true}
        pageable={true}
        groupable={true}
      >
        <GridToolbar>
          <SnackbarProvider maxSnack={3}>
            <ReportSettingsUtils
              reportType={reportSettings.settingsType}
              initialReportSettings={JSON.stringify(reportSettings.initial)}
              getCurrentReportSettings={() => reportSettings.current}
              applyReportSettings={onApplyReportSettings}
            />
          </SnackbarProvider>
        </GridToolbar>
        <GridToolbar>
          <div className={classes.toolbar}>
            <div className={classes.toolbarHistory}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showDetails.changes}
                    onChange={onChangeShowDetails('changes')}
                    value="checkedA"
                  />
                }
                label={translate.t('laShowChanges')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showDetails.history}
                    onChange={onChangeShowDetails('history')}
                    value="checkedA"
                  />
                }
                label={translate.t('laShowHistory')}
              />
            </div>
            <div className={classes.toolbarScope}>
              <ToolbarManagerAndHr {...toolbarSettings} />
            </div>
            <div className={classes.toolbarDownload}>
              <DownloadButton />
            </div>
          </div>
        </GridToolbar>
        {columns.map(
          (column: any, idx: number) =>
            column.show && (
              <GridColumn
                {...column}
                key={idx}
                columnMenu={(otherProps: any) => (
                  <ColumnMenu
                    {...otherProps}
                    columns={columns}
                    onColumnsSubmit={onColumnsSubmit}
                    filterUI={columnSelectFilter(column.field)}
                  />
                )}
              />
            ),
        )}
      </Grid>
    </ExcelExport>
  );
};

const enhance = compose<Props, OwnProps>(withStyles(styles));

export default enhance(WorkflowsReport);

import React, { useState } from 'react';
import { Button, Icon, Menu, MenuItem, Typography } from '@material-ui/core';
import translate from '@/app/utils/translate';
import { ApprovalAction, APPROVAL_STATUS } from '../../../../types';
import { getApprovalStatusName } from '../../../../helpers';

type OwnProps = {
  className?: string;
  readOnly?: boolean;
  onChangeApproval: (action: ApprovalAction) => void;
};
type InnerProps = {};
type Props = OwnProps & InnerProps;

const ApprovalStatusHeadButton = ({
  className,
  readOnly = false,
  onChangeApproval,
}: Props) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(undefined);

  const handleChangeApproval = (action: ApprovalAction) => {
    setMenuAnchorEl(undefined);
    onChangeApproval(action);
  };

  if (readOnly) {
    return <Typography variant="inherit">{translate.t('label_approval_status')}</Typography>;
  }

  return (
    <>
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(undefined)}
      >
        <MenuItem onClick={() => handleChangeApproval('CHANGE_ALL_SEATERS_AS_APPROVED')}>
          {translate.t('label_set_all_seaters_as_status', { status: getApprovalStatusName(APPROVAL_STATUS.APPROVED).toLocaleUpperCase() })}
        </MenuItem>
        <MenuItem onClick={() => handleChangeApproval('CHANGE_ALL_AS_APPROVED')}>
          {translate.t('label_set_all_as_status', { status: getApprovalStatusName(APPROVAL_STATUS.APPROVED).toLocaleUpperCase() })}
        </MenuItem>
      </Menu>
      <Button className={className} color="inherit" onClick={e => setMenuAnchorEl(e.target)}>
        {translate.t('label_approval_status')}
        <Icon>arrow_drop_down</Icon>
      </Button>
    </>
  );
};

export default ApprovalStatusHeadButton;

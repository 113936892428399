import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const dropdownComponentStyles: StyleRulesCallback = () => ({
  typography: {
    whiteSpace: 'pre-wrap',
    paddingTop: '20px',
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontSize: '0.875em',
    fontWeight: 'bold',
  },
  formControl: {
    minWidth: '100px',
  },
});

export default dropdownComponentStyles;

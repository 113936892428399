import { AvatarCell, EmployeeNameCell } from '../KendoCells';
import translate from '@/app/utils/translate';
import { GridCellProps } from '@progress/kendo-react-grid/dist/npm/interfaces/GridCellProps';

export const gridColumns = () => [
  {
    title: translate.t('fOrgUnitManager'),
    show: true,
    columns: [
      {
        cell: AvatarCell,
        field: 'mgrId',
        show: true,
        title: translate.t('laPicture'),
        groupable: false,
      },
      {
        cell: (props: GridCellProps) =>
          EmployeeNameCell({ ...props, empIdField: 'mgrId' }),
        field: 'mgrFirstName',
        show: true,
        title: translate.t('laFirstName'),
      },
      {
        cell: (props: GridCellProps) =>
          EmployeeNameCell({ ...props, empIdField: 'mgrId' }),
        field: 'mgrLastName',
        show: true,
        title: translate.t('laLastName'),
      },
      {
        field: 'mgrNumber',
        show: true,
        title: translate.t('laEmpNumber'),
      },
      {
        field: 'unitName',
        show: true,
        title: translate.t('laOrgUnit'),
      },
      {
        field: 'unitNumber',
        show: true,
        title: translate.t('laUnitNumber'),
      },
    ],
  },
  {
    title: translate.t('fOrgUnitSubstituteManager'),
    show: true,
    columns: [
      {
        cell: AvatarCell,
        field: 'subMgrId',
        show: true,
        title: translate.t('laPicture'),
        groupable: false,
      },
      {
        cell: (props: GridCellProps) =>
          EmployeeNameCell({ ...props, empIdField: 'subMgrId' }),
        field: 'subMgrFirstName',
        show: true,
        title: translate.t('laFirstName'),
      },
      {
        cell: (props: GridCellProps) =>
          EmployeeNameCell({ ...props, empIdField: 'subMgrId' }),
        field: 'subMgrLastName',
        show: true,
        title: translate.t('laLastName'),
      },
      {
        field: 'subMgrNumber',
        show: true,
        title: translate.t('laEmpNumber'),
      },
      {
        field: 'subMgrUnitName',
        show: true,
        title: translate.t('laOrgUnit'),
      },
      {
        field: 'subMgrUnitCode',
        show: true,
        title: translate.t('laUnitNumber'),
      },
      {
        field: 'subStartDate',
        show: true,
        title: translate.t('laStartDate'),
      },
      {
        field: 'subEndDate',
        show: true,
        title: translate.t('laEndDate'),
      },
    ],
  },
];

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Dispatch } from 'redux';
import moment from 'moment';

import API, { Relation } from '@/app/api/internalAPIs';
import { ReducerState } from '@/app/redux/store';
import { AppraisalReportData, AppraisalReportUnit, fetchAllAppraisals } from '@/app/redux/appraisals';
import TemplateResponseReport from './components/TemplateResponseReport/TemplateResponseReport';

import Service from '@/app/utils/service';
import translate from '@/app/utils/translate';
import {
  getAllEmployees,
  getEmployeeInfoById,
  getEmployeeName,
  getLoggedUserId,
  isHR,
  isManager,
} from '@/old/utils/helper';
import { useUnits } from '@/app/hooks/useUnits';
import {
  EvaluationStatus,
  GenericEmployeeEvaluation,
  ITemplateFull,
  IUnitGridData,
  TemplateFieldSelection,
  TemplateStatus
} from './types';
import {
  filterEvaluationData,
  getTemplateSubjectById,
  getUnitEvaluationCombinedStatusDistribution,
  getUserRolePerspective,
  templateStatusTranslation,
  translateCompletionStatus
} from './helpers';

type MapStateToProps = {
  allAppraisalTemplates: ITemplateFull[];
};

type MapDispatchToProps = {
  fetchAppraisals: () => Promise<ITemplateFull[]>;
};

type OwnProps = {
  isStatusReport: boolean;
};

type Props = OwnProps & MapStateToProps & MapDispatchToProps;

const AppraisalReport = (props: Props) => {
  const { allAppraisalTemplates, fetchAppraisals, isStatusReport } = props;
  const userId = getLoggedUserId();
  const defaultRelation =
    isHR() && !isManager() ? Relation.ALL : Relation.DIRECT;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [appraisalData, setAppraisalData] = useState<AppraisalReportUnit[]>([]);
  const [empData, setEmpData] = useState([]);
  const [selectedTemplateIds, setSelectedTemplateIds] = useState<string[]>([]);
  const [relation, setRelation] = useState(defaultRelation);
  const [selectedTemplateFields, setSelectedTemplateFields] = useState<
    TemplateFieldSelection
  >();

  const {
    allUnits,
    includeSubunits,
    selectedUnitId,
    getUnitDataById,
    getIncludedUnits,
    setSelectedUnitId,
    setIncludeSubUnits
  } = useUnits();

  const handleFetchError = useCallback((err: any) => {
    setAppraisalData([]);
    setLoading(false);
    setError(err);
  }, [setAppraisalData, setError, setLoading]);

  const handleFetchSuccess = useCallback(() => {
    setEmpData([]);
    setLoading(false);
    setError('');
  }, [setError, setEmpData, setLoading]);

  const allEmps = useMemo(() => getAllEmployees(), []);
  const allAvailableEmps = useMemo(() => Object.keys(allEmps).filter(
    (empId) => allEmps[empId].fEmpStatus === 'ACTIVE'
  ).map(empId => allEmps[empId]), [allEmps]);

  const getTemplateSubject = useCallback((templateId: string) => getTemplateSubjectById(
    templateId,
    allAppraisalTemplates,
  ), [allAppraisalTemplates]);

  const mapEmpAppraisalStatusData = useCallback((emp: GenericEmployeeEvaluation) => {
    if (!emp.employeeDetails || !emp.employeeDetails.empId) {
      return [];
    }
  
    const empInfo = getEmployeeInfoById(emp.employeeDetails.empId);
    const empPersonalInfo = {
      unitId: `${emp.employeeDetails.orgUnitDetails.id}`,
      unitName: emp.employeeDetails.orgUnitDetails.name,
      empId: empInfo.fEmpId,
      empNumber: empInfo.fEmpPersonNumber,
      firstName: empInfo.fEmpFirstName,
      lastName: empInfo.fEmpLastName,
      updatedTime: '',
      approvedBy: '',
      updatedBy: '',
      statusCode: EvaluationStatus.NOT_STARTED,
      status: translate.t('laNotStarted'),
      archived: '',
      templateId: '',
      subject: '',
      hasAccount: emp.employeeDetails.userDetails.hasAccount,
    };
  
    const notStartedEvals = selectedTemplateIds.filter((tplId) => {
      return !emp.evaluations.find(evalItem => evalItem.templateId === tplId);
    }).map((tplId) => ({
      ...empPersonalInfo,
      subject: getTemplateSubject(tplId),
      templateId: tplId
    }));
  
    return emp.evaluations.reduce((goalsAcc, evalItem) => {
      const translatedStatus = evalItem.status
        ? translateCompletionStatus(evalItem.status)
        : translate.t('laNotStarted');
      const whoUpdated = getEmployeeName(evalItem.updatedBy);
      const whoApproved = evalItem.approvedBy
        .map(e => getEmployeeName(e))
        .join('; ');
      const updatedAt = moment(evalItem.updatedTime).format(
        'YYYY-MM-DD h:mm:ss',
      );

      return goalsAcc.concat([{
        ...empPersonalInfo,
        subject: getTemplateSubject(evalItem.templateId),
        statusCode: evalItem.status || EvaluationStatus.NOT_STARTED,
        status: translatedStatus,
        updatedTime: updatedAt,
        approvedBy: whoApproved,
        updatedBy: whoUpdated,
        archived: evalItem.archived ? translate.t('laArchived') : '',
      }]);
    }, []).concat(notStartedEvals);
  }, [selectedTemplateIds, getTemplateSubject]);

  const mapEmpAppraisalReportData = useCallback((
    emp: GenericEmployeeEvaluation,
    tplFields: TemplateFieldSelection,
  ) => {
    if (!emp.employeeDetails || !emp.employeeDetails.empId) {
      return [];
    }

    const empInfo = getEmployeeInfoById(emp.employeeDetails.empId);
    const foundTemplate = allAppraisalTemplates.find(
      (templateItem) => selectedTemplateIds[0] && `${templateItem.id}` === selectedTemplateIds[0],
    );

    const empPersonalInfo = {
      unitId: `${emp.employeeDetails.orgUnitDetails.id}`,
      unitName: emp.employeeDetails.orgUnitDetails.name,
      empId: empInfo.fEmpId,
      empNumber: empInfo.fEmpPersonNumber,
      evalItems: {},
      name: `${empInfo.fEmpFirstName} ${empInfo.fEmpLastName}`,
      position: empInfo.fEmpPosition,
      selectedTemplateFields: tplFields,
      statusCode: EvaluationStatus.NOT_STARTED,
      templateId: selectedTemplateIds[0],
      template: foundTemplate,
      hasAccount: emp.employeeDetails.userDetails.hasAccount,
    };
  
    const notStartedEvals = selectedTemplateIds.filter((tplId) => {
      return !emp.evaluations.find(evalItem => evalItem.templateId === tplId);
    }).map((tplId) => ({
      ...empPersonalInfo,
      subject: getTemplateSubject(tplId),
      templateId: tplId
    }));
  
    return emp.evaluations.reduce((goalsAcc, evalItem) => goalsAcc.concat([{
      ...empPersonalInfo,
      evalItems: evalItem,
      selectedTemplateFields: tplFields,
      statusCode: evalItem.status || EvaluationStatus.NOT_STARTED,
      template: foundTemplate,
    }]), []).concat(notStartedEvals);
  }, [allAppraisalTemplates, selectedTemplateIds, getTemplateSubject]);

  const mapData = useCallback((
    empUnitEvals: GenericEmployeeEvaluation[],
    tplFields: TemplateFieldSelection
  ) => empUnitEvals.reduce((list, emp) => {
    return isStatusReport
      ? list.concat(mapEmpAppraisalStatusData(emp))
      : list.concat(mapEmpAppraisalReportData(emp, tplFields));
  }, []), [isStatusReport, mapEmpAppraisalStatusData, mapEmpAppraisalReportData]);

  useEffect(() => {
    fetchAppraisals().then(handleFetchSuccess).catch(handleFetchError);
  }, [fetchAppraisals]);

  useEffect(() => {
    Service.get(
      API.AppraisalReport.getAppraisalReportCustom(
        getUserRolePerspective(relation),
        userId,
        relation,
        selectedTemplateIds,
      ),
      (response: AppraisalReportUnit[]) => {
        setAppraisalData(response);
        handleFetchSuccess();
      },
      handleFetchError
    );
  }, [
    userId,
    relation,
    selectedTemplateIds,
    setAppraisalData,
  ]);

  const employeeAppraisals = useMemo(() => appraisalData.reduce((
    acc: AppraisalReportData[],
    appraisalDataItem,
  ) => {
    return acc.concat(appraisalDataItem.employees);
  }, []).map((empAppraisalData) => {
    const empInfo = getEmployeeInfoById(empAppraisalData.employee.id);
    const managerData = getEmployeeInfoById(empInfo.fEmpManagerId);
    const unitData = getUnitDataById(empInfo.fEmpUnit);
    const unitFields = unitData ? {
      name: unitData.fTreeUnitName,
      number: unitData.fTreeUnitNumber,
    } : {};
    const managerFields = managerData ? {
      managerEmpNumber: managerData.fEmpPersonNumber,
      managerFirstName: managerData.fEmpFirstName,
      managerLastName: managerData.fEmpLastName,
    } : {};

    return {
      employeeDetails: {
        empId: empInfo.fEmpId,
        firstName: empInfo.fEmpFirstName,
        lastName: empInfo.fEmpLastName,
        empNumber: empInfo.fEmpPersonNumber,
        orgUnitDetails: {
          id: empInfo.fEmpUnit,
          ...unitFields,
        },
        managerId: empInfo.fEmpManagerId,
        ...managerFields,
        employmentId: empInfo.fEmpEmplId,
        startDate: new Date(empInfo.fEmplStartDate).getTime(),
        userDetails: {
          roles: empAppraisalData.employee.roles,
          hasAccount: empInfo.fEmpHasAccount,
        }
      },
      evaluations: empAppraisalData.evaluations || [],
    };
  }), [allAvailableEmps, appraisalData]);

  const unitGridData: IUnitGridData[] = useMemo(() => allUnits.map(currentUnit => {
    const statusCount = getUnitEvaluationCombinedStatusDistribution(
      employeeAppraisals,
      allAppraisalTemplates,
      currentUnit,
      selectedTemplateIds,
    );

    return {
      unitId: parseInt(currentUnit.fTreeUnitId, 10),
      unitName: currentUnit.fTreeUnitName,
      completed: statusCount.completed,
      inProgress: statusCount.inProgress,
      notStarted: statusCount.notStarted,
    };
  }), [allAppraisalTemplates, allUnits, selectedTemplateIds, employeeAppraisals]);

  const handleTemplateClick = (id: string) => {
    setSelectedTemplateIds((prevSelectedIds) => {
      if (!isStatusReport) {
        return id !== prevSelectedIds[0] ? [id] : [];
      }

      return prevSelectedIds.indexOf(id) > -1
        ? prevSelectedIds.filter(tplId => tplId !== id)
        : prevSelectedIds.concat([id]);
    });

    setSelectedUnitId(undefined);
    setSelectedTemplateFields(undefined);
    setEmpData([]);
  };

  const handleTemplateBulkSelection = (templateIds: string[]) => {
    setSelectedTemplateIds([...templateIds]);
    setSelectedUnitId(undefined);
    setSelectedTemplateFields(undefined);
    setEmpData([]);
  };

  const updateSelectedEmps = (
    unitId: string,
    tplFields: TemplateFieldSelection,
    subUnitsIncluded: boolean,
  ) => {
    if (!selectedTemplateIds.length) {
      return;
    }

    const filteredAppraisalData = filterEvaluationData(
      employeeAppraisals,
      selectedTemplateIds,
      getIncludedUnits(unitId, subUnitsIncluded),
      unitGridData,
    );

    const additionalFiltering = isStatusReport
      ? filteredAppraisalData
      : filteredAppraisalData.filter(
        appraisalDataItem => appraisalDataItem.evaluations.length,
      );

    setEmpData(mapData(additionalFiltering, tplFields));
  };

  const handleUnitClick = (id: string | number) => {
    const newSelectedUnitId = `${id}` !== selectedUnitId ? `${id}` : undefined;
    setSelectedUnitId(newSelectedUnitId);

    if (!selectedTemplateIds.length) {
      return;
    }

    updateSelectedEmps(
      newSelectedUnitId,
      selectedTemplateFields,
      includeSubunits,
    );
  };

  const handleIncludeSubunitsCheckboxClick = () => {
    setIncludeSubUnits(!includeSubunits);
    updateSelectedEmps(
      selectedUnitId,
      selectedTemplateFields,
      !includeSubunits,
    );
  };

  const handleTeamCheckboxClick = () => {
    const updatedRelation =
      relation === Relation.DIRECT ? Relation.ALL : Relation.DIRECT;
    setRelation(updatedRelation);
    setSelectedUnitId(undefined);
    setSelectedTemplateFields(undefined);
  };

  const handleTemplateFieldSelection = (
    sectionId: string,
    selection: string[],
  ) => {
    const newTemplateFields = {
      ...selectedTemplateFields,
      [sectionId]: { selectedFields: selection },
    };

    const updatedSelectedTemplateFields:
      | TemplateFieldSelection
      | undefined = Object.keys(newTemplateFields).reduce(
      (acc: TemplateFieldSelection | undefined, sectionKey: string) => {
        if (!newTemplateFields[sectionKey].selectedFields.length) {
          return acc;
        }

        return {
          ...acc,
          [sectionKey]: {
            selectedFields: newTemplateFields[sectionKey].selectedFields,
          },
        };
      },
      undefined,
    );

    setSelectedTemplateFields(updatedSelectedTemplateFields);
    updateSelectedEmps(
      selectedUnitId,
      updatedSelectedTemplateFields,
      includeSubunits,
    );
  };

  if (loading) {
    return <div>{loading}</div>;
  }

  if (error) {
    return <div>{'error'}</div>;
  }

  return (
    <TemplateResponseReport
      exportFileName={isStatusReport ? translate.t('laAppraisalStatus') : translate.t('laAppraisalReport')}
      templates={allAppraisalTemplates}
      templatesTitle={translate.t('appraisal_templates')}
      selectedTemplateFields={selectedTemplateFields}
      onTemplateClick={handleTemplateClick}
      onBulkTemplateSelection={handleTemplateBulkSelection}
      showSearch={true}
      selectedTemplateIds={selectedTemplateIds}
      units={unitGridData}
      selectedUnitId={selectedUnitId}
      onUnitClick={handleUnitClick}
      includeSubunits={includeSubunits}
      onIncludeSubunitsClick={handleIncludeSubunitsCheckboxClick}
      onTeamRelationClick={isManager() ? handleTeamCheckboxClick : undefined}
      teamRelation={relation}
      employeeAppraisalData={empData}
      onTemplateFieldSelection={handleTemplateFieldSelection}
      isStatusReport={isStatusReport}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAppraisals: () => dispatch<any>(fetchAllAppraisals()),
});

const mapStateToProps = (state: ReducerState) => {
  const { allAppraisalTemplates } = state.appraisals;

  return { 
    allAppraisalTemplates: allAppraisalTemplates.map((appraisalTpl: ITemplateFull) => ({
      ...appraisalTpl,
      status: templateStatusTranslation(appraisalTpl.status as TemplateStatus),
      deadline: moment(appraisalTpl.deadline, 'YYYY-MM-DD').toDate(),
    }))
  };
};

const enhance = compose<Props, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(AppraisalReport);

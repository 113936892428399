import { Record } from 'immutable';
import moment from 'moment';
import { Dispatch } from 'redux';
import Service from '@/app/utils/service';
import API from '@/app/api/internalAPIs';
import { THROW_ERROR } from './error';
import { OPEN_NOTIFIER } from './notifier';
import translate from '../utils/translate';
import { getEmployeeName } from '@/old/utils/helper';
import { sortArr } from '@/app/utils/helper';
import {
  ActiveTemplate,
  EvaluationTemplate,
  JobReqEvaluationResponse,
  ScoreGroup,
} from '../components/JobEvaluation/types';
import { getDefault } from '../components/JobEvaluation/helpers';
import { SCORE, TEXT } from '../components/JobEvaluation/constants';

type Stage = {
  default: boolean;
  id: number;
  index: number;
  isDeleted: boolean;
  label: string;
};

const getUniqueStages = function(stages: Stage[]) {
  if (!stages || !stages.length) {
    return stages;
  }

  return stages.reduce((acc, stage) => {
    const isIdDuplicated = acc.some(
      existingStage => existingStage.id === stage.id,
    );
    const isNameDuplicated = acc.some(
      existingStage => existingStage.label === stage.label,
    );
    if (stage.isDeleted || isIdDuplicated || isNameDuplicated) {
      return acc;
    }

    return acc.concat(stage);
  }, []);
};

const initialState = Record({
  allJobRequirementTemplates: [] as EvaluationTemplate[],
  archivedEvaluations: [],
  activeTemplate: {} as ActiveTemplate,
  jobRequirementEvaluation: { responses: [] } as any,
  scoreGroups: [] as ScoreGroup[],
  totalScore: 0,
});

const FETCH_ALL_JOB_REQUIREMENT_TEMPLATES =
  '@@solaforce/jobRequirementEvaluation/FETCH_ALL_JOB_REQUIREMENT_TEMPLATES';
const FETCH_ARCHIVED_JOB_REQUIREMENTS =
  '@@solaforce/jobRequirementEvaluation/FETCH_ARCHIVED_JOB_REQUIREMENTS';
const CLEAR_ARCHIVED_JOB_REQUIREMENTS =
  '@@solaforce/jobRequirementEvaluation/CLEAR_ARCHIVED_JOB_REQUIREMENTS';
const SET_INITIAL_TEMPLATE =
  '@@solaforce/jobRequirementEvaluation/SET_INITIAL_TEMPLATE';
const SET_ACTIVE_TEMPLATE =
  '@@solaforce/jobRequirementEvaluation/SET_ACTIVE_TEMPLATE';
const CHANGE_TEMPLATE_APPROVEMENT =
  '@@solaforce/jobRequirementEvaluation/APPROVE';
const UPDATE_ACTIVE_TEMPLATE =
  '@@solaforce/jobRequirementEvaluation/UPDATE_ACTIVE_TEMPLATE';
const ARCHIVE_JOB_REQUIREMENT_EVALUATION =
  '@@solaforce/jobRequirementEvaluation/ARCHIVE_JOB_REQUIREMENT_EVALUATION';
const UPDATE_JOB_REQUIREMENT_EVALUATION =
  '@@solaforce/jobRequirementEvaluation/UPDATE_JOB_REQUIREMENT_EVALUATION';
const LOAD_JOB_REQUIREMENT_EVALUATION =
  '@@solaforce/jobRequirementEvaluation/LOAD_JOB_REQUIREMENT_EVALUATION';
const CLEAR_EVALUATION =
  '@@solaforce/jobRequirementEvaluation/CLEAR_EVALUATION';
const CHANGE_EVALUATION_STAGE =
  '@@solaforce/jobRequirementEvaluation/STAGE_CHANGE';
const FETCH_ALL_SCOREGROUPS =
  '@@solaforce/jobRequirementEvaluation/FETCH_ALL_SCOREGROUPS';
const CLEAR_ACTIVE_TEMPLATE =
  '@@solaforce/jobRequirementEvaluation/CLEAR_ACTIVE_TEMPLATE';

const formatedListOnRemoval = (
  initialList: string,
  approvingUserName: string,
) => {
  return initialList.substring(
    0,
    initialList.indexOf(
      initialList.indexOf(', ' + approvingUserName) !== -1
        ? ', ' + approvingUserName
        : approvingUserName + ',',
    ),
  );
};

const formatedAddition = (initialList: string, approvingUserName: string) => {
  if (initialList.length > 0) {
    return `${initialList} , ${approvingUserName}`;
  }
  return approvingUserName;
};

const updateLastModifiedBy = (template: any) => {
  if (!template.updatedBy || !template.updatedBy.employeeId) {
    return template.lastModifiedBy || '';
  }

  return getEmployeeName(template.updatedBy.employeeId, true);
};

const updateResponse = (
  listOfResponses: JobReqEvaluationResponse[],
  answer: JobReqEvaluationResponse,
): JobReqEvaluationResponse[] => {
  let found = false;
  let newListOfResponses: JobReqEvaluationResponse[] = listOfResponses.map(
    (response: JobReqEvaluationResponse) => {
      if (response.componentId === answer.componentId) {
        found = true;
        if (answer.type === SCORE) {
          return answer;
        }
        if (answer.type === TEXT) {
          return answer;
        }
      }
      return response;
    },
  );

  if (!found) {
    // Pushes a new component to the list of answers
    newListOfResponses.push(answer);
  }
  return newListOfResponses;
};

const getTotalScore = (
  jobRequirementEvaluation: JobReqEvaluationResponse[],
) => {
  return jobRequirementEvaluation.reduce(
    (sum, reqEval) => sum + reqEval.response.score,
    0,
  );
};

const reducer = (state = new initialState(), action: any) => {
  switch (action.type) {
    case FETCH_ALL_JOB_REQUIREMENT_TEMPLATES:
      const { allJobRequirementTemplates } = action.payload;
      const processedJobRequirementTemplates = allJobRequirementTemplates.map(
        (jobRequirementTemplate: EvaluationTemplate) => {
          const sections = sortArr(
            jobRequirementTemplate.sections.map((section: any) => {
              const allComponents = section.components.concat(
                section.appraisalComponents,
              );
              return {
                ...section,
                components: sortArr(allComponents, 'index'),
              };
            }),
            'index',
          );
          return {
            ...jobRequirementTemplate,
            sections,
            stages: getUniqueStages(jobRequirementTemplate.stages),
          };
        },
      );
      return state.set(
        'allJobRequirementTemplates',
        processedJobRequirementTemplates,
      );

    case FETCH_ARCHIVED_JOB_REQUIREMENTS:
      const { archivedEvaluations } = action.payload;
      return state.set('archivedEvaluations', archivedEvaluations);

    case CLEAR_ARCHIVED_JOB_REQUIREMENTS:
      return state.set('archivedEvaluations', []);

    case SET_INITIAL_TEMPLATE:
      const { activeEval } = action.payload;
      return state.set('activeTemplate', activeEval);

    case SET_ACTIVE_TEMPLATE:
      const { templateSelected } = action.payload;
      return state.set('activeTemplate', templateSelected);

    case UPDATE_ACTIVE_TEMPLATE:
      const { approvingUser } = action.payload;
      const activeTemp = state.get('activeTemplate');
      const updatedActiveTemplate = {
        ...activeTemp,
        approvedBy: activeTemp.approvedBy.includes(approvingUser)
          ? formatedListOnRemoval(activeTemp.approvedBy, approvingUser)
          : formatedAddition(activeTemp.approvedBy, approvingUser),
        lastEdited: moment().format('YYYY-MM-DD'),
        lastModifiedBy: approvingUser,
      };
      return state.set('activeTemplate', updatedActiveTemplate);

    case LOAD_JOB_REQUIREMENT_EVALUATION: {
      const { response } = action;
      const tmpID = response.templateId;
      const allAnswers = response.responses;

      return state
        .set('totalScore', getTotalScore(allAnswers))
        .set('jobRequirementEvaluation', {
          tmpID,
          responses: allAnswers,
        });
    }

    case UPDATE_JOB_REQUIREMENT_EVALUATION:
      const { templateId, componentAnswer } = action.payload;
      const jobRequirementEvaluationAnswers = state.get(
        'jobRequirementEvaluation',
      ).responses;
      const updatedAnswers = updateResponse(
        jobRequirementEvaluationAnswers,
        componentAnswer,
      );

      return state
        .set('totalScore', getTotalScore(updatedAnswers))
        .set('jobRequirementEvaluation', {
          templateId,
          responses: updatedAnswers,
        });

    case CLEAR_EVALUATION:
      return state
        .set('totalScore', getTotalScore([]))
        .set('jobRequirementEvaluation', { responses: [] });

    case ARCHIVE_JOB_REQUIREMENT_EVALUATION:
      const { templateToArhive } = action.payload;
      const allJobRequirementsUpdate = state
        .get('allJobRequirementTemplates')
        .map((item: any) => {
          if (item.id === templateToArhive) {
            return {
              ...item,
              archived: true,
            };
          }
          return item;
        });
      return state.set('allJobRequirementTemplates', allJobRequirementsUpdate);

    case CHANGE_TEMPLATE_APPROVEMENT:
      const { evaluationToUpdate, newApproval } = action.payload;

      const updatedApprovedStatus = state
        .get('allJobRequirementTemplates')
        .map((item: any) => {
          if (item.id === evaluationToUpdate) {
            if (item.approvedBy.includes(newApproval)) {
              return {
                ...item,
                approvedBy: formatedListOnRemoval(item.approvedBy, newApproval),
                lastEdited: moment().format('YYYY-MM-DD'),
                lastModifiedBy: newApproval,
              };
            }
            return {
              ...item,
              approvedBy: formatedAddition(item.approvedBy, newApproval),
              lastEdited: moment().format('YYYY-MM-DD'),
              lastModifiedBy: newApproval,
            };
          }
          return item;
        });
      return state.set('allJobRequirementTemplates', updatedApprovedStatus);

    case CHANGE_EVALUATION_STAGE:
      const { setStage, userMakingTheChange } = action.payload;
      const activeTmp = state.get('activeTemplate');
      const updatedWStateChange = {
        ...activeTmp,
        setStage: setStage,
        lastEdited: moment().format('YYYY-MM-DD'),
        lastModifiedBy: userMakingTheChange,
      };
      return state.set('activeTemplate', updatedWStateChange);

    case FETCH_ALL_SCOREGROUPS:
      const { scoreGroups } = action.payload;
      return state.set('scoreGroups', scoreGroups);

    case CLEAR_ACTIVE_TEMPLATE:
      return state.set('activeTemplate', {} as ActiveTemplate);

    default:
      return state;
  }
};

export const getEvaluationInformation = (template: any, employeeId: number) => {
  return (dispatch: Dispatch) => {
    return Service.get(
      API.jobRequirementEvaluations.getEvaluationDetails(
        template.id,
        employeeId,
      ),
      (response: any) => {
        const payload = {
          activeEval: {
            id: response.templateId,
            currentEvaluationId: response.id,
            subject: template.subject,
            deadline: template.deadline,
            sections: template.sections,
            stages: template.stages,
            isTeam: template.isTeam,
            updatedBy: response.updatedBy,
            setStage: response.stageId
              ? template.stages.filter(
                  (stage: any) => stage.id === response.stageId,
                )[0]
              : getDefault(template.stages),
            lastEdited: moment(response.updatedTime).format('YYYY-MM-DD'),
            lastModifiedBy: response.updatedBy
              ? formatedAddition('', getEmployeeName(response.updatedBy, true))
              : updateLastModifiedBy(template),
            completionStatus: response.status,
            approvedBy: formatedAddition(
              '',
              getEmployeeName(response.approvedBy, true),
            ),
            responses: response.responses,
          },
        };
        dispatch({ type: SET_INITIAL_TEMPLATE, payload });
        dispatch({ type: LOAD_JOB_REQUIREMENT_EVALUATION, response });

        return response;
      },
      (error: any) => {
        if (error.data.error.type === 'NOT FOUND') {
          const payload = {
            activeEval: {
              id: template.id,
              subject: template.subject,
              completionStatus: 'IN_PROGRESS',
              deadline: template.deadline,
              approvedBy: '',
              sections: template.sections,
              stages: template.stages,
              isTeam: template.isTeam,
              setStage: getDefault(template.stages),
              lastEdited: moment(template.updatedTime).format('YYYY-MM-DD'),
              lastModifiedBy: updateLastModifiedBy(template),
            },
          };
          dispatch({ type: SET_INITIAL_TEMPLATE, payload });
          dispatch({ type: CLEAR_EVALUATION });
        } else {
          dispatch({ type: THROW_ERROR, error });
        }
      },
    );
  };
};

export const fetchAllJobRequirements = () => {
  return (dispatch: Dispatch) => {
    return Service.get(
      API.jobRequirementEvaluations.getTemplateList(),
      (response: any) => {
        const payload = {
          allJobRequirementTemplates: response.data,
        };
        dispatch({ type: FETCH_ALL_JOB_REQUIREMENT_TEMPLATES, payload });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const fetchArchivedJobRequirements = (employeeId: number) => {
  return (dispatch: Dispatch) => {
    return Service.get(
      API.jobRequirementEvaluations.getArchivedEvaluations(employeeId),
      (response: any) => {
        const payload = { archivedEvaluations: response.data };
        dispatch({ type: FETCH_ARCHIVED_JOB_REQUIREMENTS, payload });
      },
      (_error: any) => dispatch({ type: CLEAR_ARCHIVED_JOB_REQUIREMENTS }),
    );
  };
};

export const setActiveTemplate = (
  templateSelected: EvaluationTemplate,
  employeeId: number,
  fromArchive: boolean = false,
) => {
  return (dispatch: Dispatch) => {
    const url = fromArchive
      ? API.jobRequirementEvaluations.getArchivedEvaluationDetails(
          templateSelected.id,
        )
      : API.jobRequirementEvaluations.getEvaluationDetails(
          templateSelected.id,
          employeeId,
        );

    return Service.get(
      url,
      (response: any) => {
        const payload = {
          templateSelected: {
            id: response.templateId,
            currentEvaluationId: response.id,
            subject: templateSelected.subject,
            deadline: templateSelected.deadline,
            sections: templateSelected.sections,
            stages: templateSelected.stages,
            isTeam: templateSelected.isTeam,
            updatedBy: response.updatedBy,
            setStage: response.stageId
              ? templateSelected.stages.filter(
                  (stage: any) => stage.id === response.stageId,
                )[0]
              : getDefault(templateSelected.stages),
            lastEdited: moment(response.updatedTime).format('YYYY-MM-DD'),
            lastModifiedBy: response.updatedBy
              ? formatedAddition('', getEmployeeName(response.updatedBy, true))
              : updateLastModifiedBy(templateSelected),
            completionStatus: response.status,
            approvedBy: formatedAddition(
              '',
              getEmployeeName(response.approvedBy, true),
            ),
            archived: fromArchive,
            responses: response.responses,
          },
        };
        dispatch({ type: SET_ACTIVE_TEMPLATE, payload });
        dispatch({ type: LOAD_JOB_REQUIREMENT_EVALUATION, response });
      },
      (error: any) => {
        // TODO: this happens when active template is being reloaded after changes are canceled
        if (error && error.data.error.type === 'NOT FOUND') {
          const payload = {
            templateSelected: {
              id: templateSelected.id,
              subject: templateSelected.subject,
              completionStatus: 'IN_PROGRESS',
              deadline: templateSelected.deadline,
              approvedBy: '',
              sections: templateSelected.sections,
              stages: templateSelected.stages,
              isTeam: templateSelected.isTeam,
              setStage: getDefault(templateSelected.stages),
              lastEdited: moment(templateSelected.updatedTime).format(
                'YYYY-MM-DD',
              ),
              lastModifiedBy: updateLastModifiedBy(templateSelected),
            },
          };
          dispatch({ type: SET_ACTIVE_TEMPLATE, payload });
          dispatch({ type: CLEAR_EVALUATION });
        } else {
          dispatch({ type: THROW_ERROR, error });
        }
      },
    );
  };
};

export const updateEvaluation = (templateId: string, componentAnswer: {}) => {
  return (dispatch: Dispatch) => {
    const payload = {
      templateId: templateId,
      componentAnswer: componentAnswer,
    };
    dispatch({ type: UPDATE_JOB_REQUIREMENT_EVALUATION, payload });
  };
};

export const clearEvaluation = () => ({ type: CLEAR_EVALUATION });

export const saveEmployeeEvaluations = (
  templateId: string,
  fullResponse: {},
) => {
  return (dispatch: Dispatch) => {
    return Service.post(
      API.jobRequirementEvaluations.saveEvaluation(templateId),
      fullResponse,
      (response: any) => {
        dispatch({
          type: OPEN_NOTIFIER,
          payload: {
            message: translate.t('change_saved'),
          },
        });
        return response;
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const archiveEvaluation = (evaluationId: string) => {
  return (dispatch: Dispatch) => {
    return Service.post(
      API.jobRequirementEvaluations.archiveEvaluation(evaluationId),
      {},
      (response: any) => {
        const payload = {
          templateToArhive: evaluationId,
        };
        dispatch({ type: ARCHIVE_JOB_REQUIREMENT_EVALUATION, payload });
        dispatch({
          type: OPEN_NOTIFIER,
          payload: {
            message: translate.t('evaluation_archived'),
          },
        });
        return response;
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const changeEvaluationApprovement = (
  evaluationId: string,
  newApproval: string,
) => {
  return (dispatch: Dispatch) => {
    Service.post(
      API.jobRequirementEvaluations.approveEvaluation(evaluationId),
      { newApproval: newApproval },
      (_response: any) => {
        const payload = {
          evaluationToUpdate: evaluationId,
          newApproval: newApproval,
          approvingUser: newApproval,
        };
        dispatch({ type: CHANGE_TEMPLATE_APPROVEMENT, payload });
        dispatch({ type: UPDATE_ACTIVE_TEMPLATE, payload });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const changeEvaluationStage = (
  setStage: any,
  userMakingTheChange: string,
) => {
  return (dispatch: Dispatch) => {
    const payload = {
      setStage: setStage,
      userMakingTheChange: userMakingTheChange,
    };
    dispatch({ type: CHANGE_EVALUATION_STAGE, payload });
  };
};

export const getEnumScoreGroups = () => (dispatch: Dispatch) => {
  return Service.get(
    API.jobRequirementEvaluations.getScoreGroups(),
    (scoreGroups: ScoreGroup[]) => {
      dispatch({ type: FETCH_ALL_SCOREGROUPS, payload: { scoreGroups } });
    },
    (error: any) => dispatch({ type: THROW_ERROR, error }),
  );
};

export const clearActiveTemplate = () => ({ type: CLEAR_ACTIVE_TEMPLATE });

export default reducer;

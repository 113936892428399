import moment from 'moment';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { useWhenFlagBecomesTrue } from '@/app/hooks/useWhenFlagBecomesTrue';
import translate from '@/app/utils/translate';
import { getSelectedEmpId } from '@/old/utils/helper';

import { ReportingGroupType } from '../../../../data';
import { getTimeBlocksTotalHours, getTimeCardDailyBalance } from '../../../../services';
import { momentIsFilled as checkIfMomentIsFilled } from '../../momentIsFilled';
import { timerIsRunning as checkIfTimerIsRunning } from '../../timerIsRunning';
import { timerJustStarted as checkIfTimerJustStarted } from '../../timerJustStarted';

import { DailySummaryRowInnerProps } from './dailySummaryRowModels';

export const useDailySummaryRow = ({
    currentDate,
    date,
    timeCard,
    watchFormValues,
    setFormValue,
    deleteTimeCard,
    openConfirmDialog,
    enqueueSnackbar,
    isDeleting,
    failedToDelete,
}: DailySummaryRowInnerProps) => {
    const startDate = useMemo(
        () => {
            const values = timeCard
                ? timeCard.timeBlocks
                    .filter(({ from }) => !!from)
                    .map(({ from }) => moment(from, 'HH:mm:ss'))
                : [];

            if (!values.length) {
                return undefined;
            }

            return moment.min(values).format('LT');
        },
        [timeCard],
    );

    const endDate = useMemo(
        () => {
            const values = timeCard
                ? timeCard.timeBlocks
                    .filter(({ to }) => !!to)
                    .map(({ to }) => moment(to, 'HH:mm:ss'))
                : [];

            if (!values.length) {
                return undefined;
            }

            return moment.max(values).format('LT');
        },
        [timeCard],
    );

    const workingHours = useMemo(
        () => {
            if (!timeCard || !timeCard.timeBlocks.length) {
                return undefined;
            }

            return getTimeBlocksTotalHours(
                timeCard.timeBlocks.filter(({ reportingGroupType }) => (
                    reportingGroupType === ReportingGroupType.TIMESHEET_WORK_TYPE
                )),
            );
        },
        [timeCard],
    );

    const nonWorkingHours = useMemo(
        () => {
            if (!timeCard || !timeCard.timeBlocks.length) {
                return undefined;
            }

            return getTimeBlocksTotalHours(
                timeCard.timeBlocks.filter(({ reportingGroupType }) => (
                    reportingGroupType === ReportingGroupType.TIMESHEET_NON_WORK_TYPE
                )),
            );
        },
        [timeCard],
    );

    const dailyBalance = useMemo(
        () => {
            if (!timeCard || !timeCard.timeBlocks.length) {
              return undefined;
            }

            return getTimeCardDailyBalance(timeCard);
        },
        [timeCard]
    );

    const handleDefaultWorkingHoursChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setFormValue('defaultWorkingHours', event.target.value);
        },
        [setFormValue],
    );

    const handleCommentChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setFormValue('comment', event.target.value);
        },
        [setFormValue],
    );

    const handleDeleteTimeCard = useCallback(
        () => {
            openConfirmDialog({
                text: translate.t('laConfirmDeleteTimeCard'),
                onOk: () => {
                    deleteTimeCard(getSelectedEmpId(), date);
                },
            });
        },
        [openConfirmDialog, deleteTimeCard, date],
    );

    useWhenFlagBecomesTrue(
        !isDeleting && failedToDelete,
        useCallback(
            () => {
                enqueueSnackbar(
                    translate.t('laFailedToDeleteTimeCard'),
                    { variant: 'error' }
                );
            },
            [enqueueSnackbar]
        ),
    );

    const [momentIsFilled, setMomentIsFilled] = useState(false);
    const [timerIsRunning, setTimerIsRunning] = useState(false);
    const [timerJustStarted, setTimerJustStarted] = useState(false);

    const updateTimerStartStopIndicators = useCallback(
        () => {
            const actualTimerIsRunning = checkIfTimerIsRunning(date, timeCard);
            const actualMomentIsFilled = !actualTimerIsRunning && checkIfMomentIsFilled(date, timeCard);
            const actualTimerJustStarted = actualTimerIsRunning && checkIfTimerJustStarted(date, timeCard);

            setMomentIsFilled(actualMomentIsFilled);
            setTimerIsRunning(actualTimerIsRunning);
            setTimerJustStarted(actualTimerJustStarted);
        },
        [
            date,
            timeCard,
            setMomentIsFilled,
            setTimerIsRunning,
            setTimerJustStarted,
        ],
    );

    const isToday = moment(date).isSame(currentDate, 'day');

    // Recalculate momentIsFilled & timerIsRunning every second if today.
    // This way we make sure we can show right buttons with right status.
    useEffect(
        () => {
            if (!isToday) {
                return undefined;
            }

            updateTimerStartStopIndicators();

            const timeout = setInterval(
                () => updateTimerStartStopIndicators(),
                1000
            );

            return () => {
                clearInterval(timeout);
            };
        },
        [
            isToday,
            updateTimerStartStopIndicators,
        ],
    );

    return {
        isToday,
        startDate,
        endDate,
        workingHours,
        nonWorkingHours,
        dailyBalance,
        momentIsFilled,
        timerIsRunning,
        timerJustStarted,
        inputDefaultWorkingHours: watchFormValues('defaultWorkingHours'),
        inputComment: watchFormValues('comment'),
        handleDefaultWorkingHoursChange,
        handleCommentChange,
        handleDeleteTimeCard,
    };
};
import { Record } from 'immutable';
import { Dispatch } from 'redux';
import { THROW_ERROR } from './error';
import API from '@/app/api/internalAPIs';
import Service from '@/app/utils/service';
import { OPEN_NOTIFIER } from '@/app/redux/notifier';
import { ENABLED_TABS_PEOPLE, ENABLED_TABS_PERFORMANCE, ENABLED_TABS_DEVELOPMENT, ENABLED_TABS_REWARDING, ENABLED_TABS_PLANNING } from '../utils/constants';

const FETCH_ALL_TABS_OPTIONS = '@@solaforce/tabs/FETCH_ALL_TABS_OPTIONS';
const FETCH_ACTIVE_PEOPLE_TABS = '@@solaforce/tabs/FETCH_ACTIVE_PEOPLE_TABS';
const SET_ACTIVE_PEOPLE_TABS = '@@solaforce/tabs/SET_ACTIVE_PEOPLE_TABS';
const FETCH_ACTIVE_PERFORMANCE_TABS = '@@solaforce/tabs/FETCH_ACTIVE_PERFORMANCE_TABS';
const SET_ACTIVE_PERFORMANCE_TABS = '@@solaforce/tabs/SET_ACTIVE_PERFORMANCE_TABS';
const FETCH_ACTIVE_DEVELOPMENT_TABS = '@@solaforce/tabs/FETCH_ACTIVE_DEVELOPMENT_TABS';
const SET_ACTIVE_DEVELOPMENT_TABS = '@@solaforce/tabs/SET_ACTIVE_DEVELOPMENT_TABS';
const FETCH_ACTIVE_PLANNING_TABS = '@@solaforce/tabs/FETCH_ACTIVE_PLANNING_TABS';
const SET_ACTIVE_PLANNING_TABS = '@@solaforce/tabs/SET_ACTIVE_PLANNING_TABS';
const FETCH_ACTIVE_REWARDING_TABS = '@@solaforce/tabs/FETCH_ACTIVE_REWARDING_TABS';
const SET_ACTIVE_REWARDING_TABS = '@@solaforce/tabs/SET_ACTIVE_REWARDING_TABS';

const initialState = Record({
  AllTabsOptions: [],
  activePeopleTabs: [],
  activePerformanceTabs: [],
  activeDevelopmentTabs: [],
  activePlanningTabs: [],
  activeRewardingTabs: []
});

const reducer = (state = new initialState(), action: any) => {
  switch (action.type) {
    case FETCH_ALL_TABS_OPTIONS:
      const { allTabs } = action.payload;
      return state.set('AllTabsOptions', allTabs);
    case FETCH_ACTIVE_PEOPLE_TABS:
      const { activePeopleTabs } = action.payload;
      return state.set('activePeopleTabs', activePeopleTabs);
    case SET_ACTIVE_PEOPLE_TABS:
      const { newActivePeopleTabs } = action.payload;
      return state.set('activePeopleTabs', newActivePeopleTabs);
    case FETCH_ACTIVE_PERFORMANCE_TABS:
      const { activePerfTabs } = action.payload;
      return state.set('activePerformanceTabs', activePerfTabs);
    case SET_ACTIVE_PERFORMANCE_TABS:
      const { newActivePerformanceTabs } = action.payload;
      return state.set('activePerformanceTabs', newActivePerformanceTabs);
    case FETCH_ACTIVE_DEVELOPMENT_TABS:
      const { activeDevTabs } = action.payload;
      return state.set('activeDevelopmentTabs', activeDevTabs);
    case SET_ACTIVE_DEVELOPMENT_TABS:
      const { newActiveDevelopmentTabs } = action.payload;
      return state.set('activeDevelopmentTabs', newActiveDevelopmentTabs);
    case FETCH_ACTIVE_PLANNING_TABS:
      const { activePlanTabs } = action.payload;
      return state.set('activePlanningTabs', activePlanTabs);
    case SET_ACTIVE_PLANNING_TABS:
      const { newActivePlanningTabs } = action.payload;
      return state.set('activePlanningTabs', newActivePlanningTabs);
    case FETCH_ACTIVE_REWARDING_TABS:
      const { activeRewTabs } = action.payload;
      return state.set('activeRewardingTabs', activeRewTabs);
    case SET_ACTIVE_REWARDING_TABS:
      const { newActiveRewardingTabs } = action.payload;
      return state.set('activeRewardingTabs', newActiveRewardingTabs);
    default:
      return state;
  }
};

export const fetchAllTabsOptions = () => {
  return (dispatch: Dispatch) => {
    Service.get(
      API.adminSection.getAllTabs(),
      (response: any) => {
        // Process Templates was removed from performance tab
        const filteredTabOptions = response.filter((tabOption: string) => tabOption !== 'PERF_PROCESS_TEMPLATES');
        const payload = {
          allTabs: filteredTabOptions,
        };

        dispatch({ type: FETCH_ALL_TABS_OPTIONS, payload });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error })
    );
  };
};

export const getActiveTabs = (module: string) => {
  return (dispatch: Dispatch) => {
    Service.get(
      API.adminSection.getActiveTabs(module),
      (response: any) => {
        if (module === ENABLED_TABS_PEOPLE) {
          const payload = {
            activePeopleTabs: response,
          };
          dispatch({ type: FETCH_ACTIVE_PEOPLE_TABS, payload});
        }
        if (module === ENABLED_TABS_PERFORMANCE) {
          const payload = {
            activePerfTabs: response,
          };
          dispatch({ type: FETCH_ACTIVE_PERFORMANCE_TABS, payload});
        }
        if (module === ENABLED_TABS_DEVELOPMENT) {
          const payload = {
            activeDevTabs: response,
          };
          dispatch({ type: FETCH_ACTIVE_DEVELOPMENT_TABS, payload});
        }
        if (module === ENABLED_TABS_PLANNING) {
          const payload = {
            activePlanTabs: response,
          };
          dispatch({ type: FETCH_ACTIVE_PLANNING_TABS, payload});
        }
        if (module === ENABLED_TABS_REWARDING) {
          const payload = {
            activeRewTabs: response,
          };
          dispatch({ type: FETCH_ACTIVE_REWARDING_TABS, payload});
        }
      },
      (error: any) => dispatch({ type: THROW_ERROR, error})
    );
  };
};

export const setActiveTabs = (module: string, activeTabs: string[]) => {
  return (dispatch: Dispatch) => {
    Service.put(
      API.adminSection.setActiveTabs(module),
      {fTabs: activeTabs},
      () => {
        if (module === ENABLED_TABS_PEOPLE) {
          const payload = {
            newActivePeopleTabs: activeTabs,
          };
          dispatch({ type: SET_ACTIVE_PEOPLE_TABS, payload});
        }
        if (module === ENABLED_TABS_PERFORMANCE) {
          const payload = {
            newActivePerformanceTabs: activeTabs,
          };
          dispatch({ type: SET_ACTIVE_PERFORMANCE_TABS, payload});
        }
        if (module === ENABLED_TABS_DEVELOPMENT) {
          const payload = {
            newActiveDevelopmentTabs: activeTabs,
          };
          dispatch({ type: SET_ACTIVE_DEVELOPMENT_TABS, payload});
        }
        if (module === ENABLED_TABS_PLANNING) {
          const payload = {
            newActivePlanningTabs: activeTabs,
          };
          dispatch({ type: SET_ACTIVE_PLANNING_TABS, payload});
        }
        if (module === ENABLED_TABS_REWARDING) {
          const payload = {
            newActiveRewardingTabs: activeTabs,
          };
          dispatch({ type: SET_ACTIVE_REWARDING_TABS, payload});
        }
        dispatch({ type: OPEN_NOTIFIER, payload: {} });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error })
    );
  };
};

export default reducer;
import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const answerSurveyFormDialogStyle: StyleRulesCallback = (theme: Theme) => {
  return ({
    paperWidthMd: {
      width: '580px',
    },
    dialogActions: {
      margin: '8px 20px',
    },
    formControl: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    formControlLabel: {
      color: 'black',
    },
    group: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
    },
    inlineGroup: {
      flexGrow: 0,
    },
    radio: {
      marginLeft: theme.spacing.unit,
    },
    checkbox: {
      marginLeft: theme.spacing.unit,
    },
    stretch: {
      flexGrow: 1,
    },
    question: {
      minWidth: '100%',
      marginTop: theme.spacing.unit,
    },
    scale: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-evenly',
      flexWrap: 'wrap',
    },
  });
};

export default answerSurveyFormDialogStyle;

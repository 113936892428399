import React, { useContext } from 'react';
import moment from 'moment';
import {
  TableCell,
  Typography,
  Table,
  TableRow,
  TableHead,
  TableBody,
  WithStyles,
  withStyles,
  Tooltip,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import translate from '@/app/utils/translate';
import styles from './registrationTable/styles';
import {
  formatTrainingDates,
  getResponsiblePersonsNames,
  getTrainingGroupShortName,
} from '../../helpers';
import { OptionType, CompanyTrainingParticipantRegistration, APPROVAL_STATUS } from '../../types';
import Context from '../../context/trainingsContext';
import { RegistrationStatus } from '../';
import ICalButton from './registrationTable/ICalButton';
import { getLoggedUserId, getSelectedEmpId } from '@/old/utils/helper';
import 'moment-timezone';
import Loading from '@/app/components/Loading/Loading';

type OwnProps = {};
type InnerProps = WithStyles<typeof styles>;

type Props = OwnProps & InnerProps;

const RegistrationsTable = ({ classes }: Props) => {
  const {
    state: {
      employeeTrainings: {
        oncoming: {
          data: trainings,
          loading,
          error,
        },
      },
    },
    getOncomingEmployeeTrainings,
    saveParticipant,
  } = useContext(Context);
  const { enqueueSnackbar } = useSnackbar();

  if (loading) {
    return <Loading size="small" />;
  }

  if (error) {
    return (
      <Typography component="h2" color="error">
        {error}
      </Typography>
    );
  }
  
  return (
    <Table padding="dense" data-testid="table-training-registration">
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography variant="body2" color="textSecondary">{translate.t('laTraining')}</Typography>
            <Typography variant="caption" color="textSecondary">{translate.t('laClass')}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" color="textSecondary">{translate.t('laProvider')}</Typography>
            <Typography variant="caption" color="textSecondary">{translate.t('label_responsible_persons')}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" color="textSecondary">{translate.t('laContactPerson')}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" color="textSecondary">{translate.t('laType')}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" color="textSecondary">{translate.t('laLanguage')}</Typography>
          </TableCell>
          <TableCell>
          <Typography variant="body2" color="textSecondary">{translate.t('laDate')}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" color="textSecondary">{translate.t('laHours')}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" color="textSecondary">{translate.t('label_registration')}</Typography>
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {trainings.length > 0 ? null : (
          <TableRow>
            <TableCell colSpan={7}>{translate.t('laNoData')}</TableCell>
          </TableRow>
        )}
        {trainings.map((t: any) => {
          const handleChangeApprovalStatus = async (updatedParticipant: CompanyTrainingParticipantRegistration) => {
            saveParticipant(t, updatedParticipant).then(() => {
              const message = updatedParticipant.approvalStatus.status.value === APPROVAL_STATUS.APPROVED
                ? translate.t('text_registration_was_approved')
                : translate.t('text_registration_was_rejected');
              getOncomingEmployeeTrainings(getSelectedEmpId() || getLoggedUserId());
              enqueueSnackbar(message, { variant: 'success' });
            }).catch((err: string) => {
              enqueueSnackbar(translate.t('text_error_saving_approval_status_change', { error: err }), { variant: 'error'});
            });
          };

          const event = {
            title: translate.t('title_ical_event', { title: t.name.label }),
            description: translate.t('text_imported_from_solaforce_training_calendar'),
            startTime: t.startTime ?
              moment.utc(moment.tz(t.startTime.format('YYYY-MM-DD HH:mm'), t.timeZone.value)).format('YYYYMMDDTHHmmss') + 'Z' :
              t.startDate.format('YYYYMMDD'),
            endTime: t.endTime ?
              moment.utc(moment.tz(t.endTime.format('YYYY-MM-DD HH:mm'), t.timeZone.value)).format('YYYYMMDDTHHmmss') + 'Z' :
              moment(t.endDate || t.startDate).add(1, 'day').format('YYYYMMDD'),
            location: '',
          };

          return (
            <TableRow key={t.id}>
              <TableCell>
                <Typography variant="body2">{t.name.label}</Typography>
                <Typography variant="caption">{!t.trainingClass ? '' : t.trainingClass.label}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{t.provider}</Typography>
                <Typography variant="caption">{getResponsiblePersonsNames(t.responsiblePersons.map((p: OptionType) => p.value))}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{t.contactPerson}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{getTrainingGroupShortName(t.trainingGroup.value)}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{t.language.label}</Typography>
              </TableCell>
              <TableCell>
                <Tooltip title={translate.t('label_add_to_calendar')}>
                  <ICalButton
                    className={`${classes.iCalButton} autoAdjustButton`}
                    label={formatTrainingDates(t.startDate, t.endDate)}
                    event={event}
                    iconName="event"
                    iconPosition="left"
                    iconProps={{
                      color: 'inherit',
                      fontSize: 'small',
                    }}
                  />
                </Tooltip>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{t.hours}</Typography>
              </TableCell>
              <TableCell>
                <RegistrationStatus
                  training={t}
                  onChangeApprovalStatus={handleChangeApprovalStatus}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table >
  );
};

export default withStyles(styles)(RegistrationsTable);

export const SCORE = 'SCORE';
export const TEXT = 'TEXT';

export const componentTypes = {
  SCORE: 'Score',
  LINK: 'Link',
  SUBTITLE: 'Subtitle',
  TEXT: 'Text',
};

export enum ComponentType {
  SCORE = 'SCORE',
  LINK = 'LINK',
  SUBTITLE = 'SUBTITLE',
  TEXT = 'TEXT',
}

export const componentTypeTranslationKeys = {
  [ComponentType.SCORE]: 'label_SCORE',
  [ComponentType.LINK]: 'label_LINK',
  [ComponentType.SUBTITLE]: 'label_SUBTITLE',
  [ComponentType.TEXT]: 'label_TEXT',
};

import * as React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { showEmployee } from '@/old/utils/helper';
import { Button } from '@material-ui/core';

type Props = GridCellProps & {
  empIdField?: string;
};

const EmployeeNameCell = ({ dataItem, rowType, field, empIdField }: Props) => {
  if (
    rowType === 'groupHeader' ||
    rowType === 'groupFooter' ||
    rowType !== 'data'
  ) {
    return null;
  }

  const visitEmpProfile = (id: number) => {
    showEmployee(id);
  };

  return (
    <td>
      <Button
        onClick={() =>
          visitEmpProfile(dataItem[!!empIdField ? empIdField : 'empId'])
        }
      >
        <span>{dataItem[field]}</span>
      </Button>
    </td>
  );
};

export default EmployeeNameCell;

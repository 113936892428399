import * as React from 'react';
import { useCallback, useEffect, useReducer, useState } from 'react';

import { getCompanyDefaultHours } from '@/old/utils/helper';

import { createCustomHours } from './services/createCustomHours';
import { CountryHoursSettings, hoursSettingsReducer } from './hoursSettingsReducer';
import { getCustomHours } from './services/getCustomHours';
import { updateCustomHours } from './services/updateCustomHours';
import { updateDefaultHours } from './services/updateDefaultHours';

export interface UseHoursSettingsOutput {
    selectedCountryCode: string;
    handleCountryChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    dialogsVisibilityProps: {
        defaultHours: boolean,
        customHours: boolean,
    };
    changeEditHoursDialogVisibility: (hoursType: 'defaultHours' | 'customHours', visibility: boolean) => () => void;
    companyDefaultHours?: Omit<CountryHoursSettings, 'countryCode'>;
    handleSaveDefaultHours: (data: Omit<CountryHoursSettings, 'countryCode'>) => void;
    selectedCountryCustomHours?: CountryHoursSettings;
    handleSaveCustomHours: (data: Omit<CountryHoursSettings, 'countryCode'>) => void;
}

export const useHoursSettings = (): UseHoursSettingsOutput => {
    const companyDefaultHours = React.useMemo(() => getCompanyDefaultHours(), [getCompanyDefaultHours]);
    const [selectedCountryCode, setSelectedCountryCode] = useState<string | undefined>(undefined);
    const [dialogsVisibilityProps, setDialogsVisibilityProps] = useState({'defaultHours': false, 'customHours': false});
    const [state, dispatch] = useReducer(hoursSettingsReducer, {
        loading: false,
        error: false,
        hasCustomHours: false,
        companyDefaultHours,
        selectedCountryCustomHours: undefined,
    });

    useEffect(
        () => {
            if (selectedCountryCode) {
                getCustomHours(selectedCountryCode, dispatch);
            }
        },
        [selectedCountryCode, dispatch]
    );

    const handleCountryChange = React.useCallback(
        (event: React.ChangeEvent<HTMLSelectElement>) => {
            setSelectedCountryCode(event.target.value);
        },
        [setSelectedCountryCode]
    );

    const changeEditHoursDialogVisibility = useCallback(
        (hoursType: 'defaultHours' | 'customHours', visibility: boolean) => () => {
            setDialogsVisibilityProps({...dialogsVisibilityProps, [hoursType]: visibility});
        },
        [setDialogsVisibilityProps]
    );

    const handleSaveDefaultHours = useCallback(
        (hours: Omit<CountryHoursSettings, 'countryCode'>) => {
            updateDefaultHours(hours, dispatch);

            setDialogsVisibilityProps({...dialogsVisibilityProps, 'defaultHours': false});
        },
        [selectedCountryCode, setDialogsVisibilityProps]
    );

    const handleSaveCustomHours = useCallback(
        (customHours: Omit<CountryHoursSettings, 'countryCode'>) => {
            if (!state.hasCustomHours) {
                createCustomHours(selectedCountryCode, customHours, dispatch);
            } else {
                updateCustomHours(selectedCountryCode, customHours, dispatch);
            }

            setDialogsVisibilityProps({...dialogsVisibilityProps, 'customHours': false});
        },
        [state.hasCustomHours, selectedCountryCode]
    );

    return {
        selectedCountryCode,
        handleCountryChange,
        dialogsVisibilityProps,
        changeEditHoursDialogVisibility,
        companyDefaultHours: state.companyDefaultHours,
        handleSaveDefaultHours,
        selectedCountryCustomHours: state.selectedCountryCustomHours,
        handleSaveCustomHours,
    };
};

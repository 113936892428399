import * as React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { showEmployee } from '@/old/utils/helper';
import { Button } from '@material-ui/core';

type Props = GridCellProps;

const FirstNameCell = ({
  dataItem,
  rowType,
}: Props) => {

  if (rowType === 'groupHeader' || rowType === 'groupFooter' || rowType !== 'data') {
    return null;
  }

  const visitEmpProfile = (id: number) => {
    showEmployee(id);
  };

  return (
    <td>
      <Button onClick={() => visitEmpProfile(dataItem.empId)}>
        <span>{dataItem.empFirstName}</span>
      </Button>
    </td>
  );
};

export default FirstNameCell;

import * as React from 'react';
import {
  Paper,
  Typography,
  withStyles,
  WithStyles,
  ButtonBase,
} from '@material-ui/core';

import styles from './solaIntelNumberSummaryWidgetStyle';

interface Props {
  title: string;
  value: number;
  onClick?: () => void;
}

const SolaIntelNumberSummaryWidget: React.FunctionComponent<Props &
  WithStyles<typeof styles>> = ({ classes, title, value, onClick }) => {
  const clickHandler = (_evt: React.MouseEvent) => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <ButtonBase focusRipple onClick={clickHandler} className={classes.button}>
      <Paper className={classes.root}>
        <Typography variant="h2" className={classes.value}>
          {value}
        </Typography>
        <Typography variant="body2" className={classes.title}>
          {title}
        </Typography>
      </Paper>
    </ButtonBase>
  );
};

export default withStyles(styles, { withTheme: true })(
  SolaIntelNumberSummaryWidget,
);

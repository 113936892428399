import React from 'react';

import { DropDownList } from '@progress/kendo-react-dropdowns';

export default function SelectFilter(data, fieldLabel) {
  return class extends React.Component {
    render() {
      const { firstFilterProps } = this.props;
      const value = firstFilterProps.value;

      return (
        <div className="k-filtercell">
          <div>
            {fieldLabel}
            <DropDownList
              data={data}
              onChange={this.onChange}
              value={value}
              style={{ width: '200px' }}
            />
          </div>
        </div>
      );
    }

    hasValue = value => Boolean(value);

    onChange = event => {
      const hasValue = this.hasValue(event.target.value);
      this.props.firstFilterProps.onChange({
        value: hasValue ? event.target.value : '',
        operator: hasValue ? 'eq' : '',
        syntheticEvent: event.syntheticEvent,
      });
    };

    onClearButtonClick = event => {
      event.preventDefault();
      this.props.onChange({
        value: '',
        operator: '',
        syntheticEvent: event,
      });
    };
  };
}

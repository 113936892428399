import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import Service from '@/app/utils/service';
import { Formik, FormikProps, FieldArray } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';

import Loading from '@/app/components/Loading/Loading';
import translate from '@/app/utils/translate';

import {
  CompanyTrainingInternalParticipant,
} from '../types';

import { AddToDRList, styles } from './addToDRDialog/';
import { getEmployeeName, getLoggedUserId } from '@/old/utils/helper';

type OwnProps = {
  open: boolean;
  participants: CompanyTrainingInternalParticipant[];
  title: string;
  onSave: () => void;
  onClose: () => void;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const AddToDrDialog = ({
  classes,
  open,
  participants,
  title,
  onSave,
  onClose,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [addToDRParticipants, setAddToDRParticipants] = useState([]);
  const loggedInUserId = getLoggedUserId();

  const validationSchema = yup.object<{ participants: any[] }>().shape({
    participants: yup.array().of(yup.object<any>().shape({
      employeeId: yup.number().required(),
    })),
  });

  const handleCancel = () => {
    onClose();
  };

  const handleSave = () => {
    onSave();
    enqueueSnackbar(translate.t('label_add_to_dr_list_saved'), { variant: 'success' });
    onClose();
  };

  if (!open || !participants) {
    return null;
  }

  const renderContent = (formikBag: FormikProps<{ participants: any[]; }>) => {
    if (addToDRParticipants.length === 0) {
      setAddToDRParticipants(formikBag.values.participants);
    }

    if (!participants) {
      return <Loading />;

    }

    return (
      <FieldArray
        name="participants"
        render={() => (
          <AddToDRList
            participants={addToDRParticipants}
            handleParticipantChange={( participant: any ) => {
              const newParticipants = addToDRParticipants.map((p) => {
                if ( p.employeeId === participant.employeeId ) {
                  return participant;
                }
                return p;
              });
              setAddToDRParticipants([...newParticipants]);
            }}
          />
        )}
      />
    );
  };

  const onSaveClick = () => {
    addToDRParticipants.forEach((participant) => {
      const formData = new FormData();

      formData.append('type', participant.type);
      formData.append('title', participant.title);
      formData.append('maker', getEmployeeName(loggedInUserId, false, true));
      formData.append('info', participant.decisionInfo);
      if (participant.reminderDate !== null) {
        formData.append('reminderDate', participant.reminderDate);
      }
      formData.append('isSystemGenerated', 'true');
      formData.append('file', participant.file);

      const addURL = 'd/form/employee/' + participant.employeeId + '/decisionregistry/add';

      Service.post(addURL, formData);
    });
    handleSave();
  };

  const initialValues = participants.map((p) => {
    return {
      employeeId: p.employeeId,
      title: title,
      type: 'Training',
      decisionInfo: '',
      file: null,
      reminderDate: null,
     };
  });

  return (
    <Formik
      initialValues={{ participants: initialValues }}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={handleSave}
    >
      {formikBag => (
        <Dialog
          open={open}
          disableEscapeKeyDown={true}
          fullWidth={true}
          maxWidth="xl"
        >
          <DialogTitle>
            <div className={classes.flex}>
              <Typography
                className={classes.grow}
                variant="h6"
              >
                {translate.t('laAddToDR')}
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent className={classes.content}>
            {renderContent(formikBag)}
          </DialogContent>
          <DialogActions>
            <Button
              data-testid="btn-training-participants-add-to-dr-dialog-cancel"
              color="primary"
              onClick={handleCancel}
            >
              {translate.t('laCancel')}
            </Button>
            <Button
              data-testid="btn-training-participants-add-to-dr-dialog-save"
              color="primary"
              variant="contained"
              onClick={onSaveClick}
            >
              {translate.t('laSave')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

export default withStyles(styles)(AddToDrDialog);

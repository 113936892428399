import * as React from 'react';
import compose from 'recompose/compose';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  MenuItem,
  Select,
} from '@material-ui/core';
import translate from '@/app/utils/translate';
import { LanguagesType } from '@/app/redux/languages';
import { INPUT_TYPE } from '@/app/utils/helper';
import { withStyles } from '@material-ui/core/styles';
import { WithStyles } from '@material-ui/core';
import {
  getFormDialog,
  resetForm,
} from '@/app/components/FormDialog/FormDialog';
import FormFields, {
  InputType,
  ChildInputType,
} from '@/app/components/FormFields/FormFields';
import TranslateTermsDialogStyle from './TranslateTermsDialogStyle';

interface OwnProps {
  title: string;
  open: boolean;
  languages: LanguagesType[];
  references: LanguagesType[];
  referenceData: any;
  translationData: any;
  selectedRefLang: string;
  selectedTransLang: string;
  onClose: () => void;
  onSave: (saveData: any) => void;
  onRefChange: (change: any) => void;
  onTrChange: (change: any) => void;
  untouch?: (field: string) => void;
}

type PropsType = OwnProps & WithStyles<typeof TranslateTermsDialogStyle>;
type StateType = {
  alertOpen: boolean;
  list: any[];
  isDisabledSave: boolean;
  formData: any;
  pendingOption: string;
  pendingDropdown: string;
};

const FORM_NAME = 'translateTermsForm';
class TranslateTermsDialog extends React.Component<PropsType> {
  groups: React.ReactElement[];
  inputGroups: React.ReactElement[];
  state: StateType = {
    alertOpen: false,
    list: [],
    isDisabledSave: true,
    formData: {},
    pendingOption: '',
    pendingDropdown: '',
  };

  validate = (values: any) => {
    const { formData } = this.state;
    const errors: { [k: string]: any } = {};
    const keys = Object.keys(formData);
    if (!keys) {
      return errors;
    }

    keys.forEach((key) => {
      if (key.indexOf('ref-') > -1) {
        return;
      }

      if (!values[key] || values[key] === '<p>&nbsp;</p>') {
        errors[key] = translate.t('laThisRequired');
      } else if (values[key].length > 200) {
        errors[key] = `${translate.t('laErrorMaxTextLen1')} 200`;
      }
    });

    return errors;
  };

  generateFieldId(parentKey: string, currentKey: string = ''): string {
    const parentStr = parentKey
      .trim()
      .replace(/\s+/g, '-')
      .toLowerCase();
    const childStr = currentKey
      .trim()
      .replace(/\s+/g, '-')
      .toLowerCase();

    return currentKey === '' ? `${parentStr}` : `${parentStr}-${childStr}`;
  }

  getFormData(group: object, parentKey: string = ''): object {
    const reduceCallback = (accumulator: object, groupKey: string) => {
      if (typeof group[groupKey] !== 'string') {
        const nextKeyName = parentKey + ' ' + groupKey;
        return Object.assign(
          {},
          accumulator,
          this.getFormData(group[groupKey], nextKeyName),
        );
      }

      return Object.assign({}, accumulator, {
        [this.generateFieldId(parentKey, groupKey)]: group[groupKey],
      });
    };

    return Object.keys(group).reduce(reduceCallback, {});
  }

  getFormInputs(
    termData: object,
    groupPrefix: string,
    disabled: boolean,
  ): React.ReactElement[] {
    return Object.keys(termData).map((termKey, index) => {
      const referenceKeys = this.getGroupKeys(termData[termKey], termKey);
      const fields: InputType[] = this.getGroupValues(termData[termKey]).map(
        (_field, childIndex) => {
          return {
            type: INPUT_TYPE.TEXT,
            code: `${groupPrefix}${index}${childIndex}`,
            name: this.generateFieldId(groupPrefix, referenceKeys[childIndex]),
            label: null,
            disabled,
            order: 1,
            required: groupPrefix === 'tra',
          };
        },
      );

      return (
        <div key={`${groupPrefix}${index}`}>
          <h5>
            {termKey}
            {groupPrefix === 'tra' ? '*' : ''}
          </h5>
          <FormFields inputs={fields} />
        </div>
      );
    });
  }

  getGroupValues(group: object): string[] {
    const reduceCallback = (accumulator: string[], groupKey: string) => {
      if (typeof group[groupKey] !== 'string') {
        return accumulator.concat(this.getGroupValues(group[groupKey]));
      }

      return accumulator.concat([group[groupKey]]);
    };

    return Object.keys(group).reduce(reduceCallback, []);
  }

  getGroupKeys(group: Object, parentKey: string = ''): string[] {
    const reduceCallback = (accumulator: string[], groupKey: string) => {
      if (typeof group[groupKey] !== 'string') {
        const nextKeyName = parentKey + ' ' + groupKey;

        return accumulator.concat(
          this.getGroupKeys(group[groupKey], nextKeyName),
        );
      }

      return accumulator.concat([parentKey + ' ' + groupKey]);
    };

    return Object.keys(group).reduce(reduceCallback, []);
  }

  mapFormToStructure(formData: any, structure: Object, parentKey: string = '') {
    const formMappingCallback = (
      accumulator: Object,
      refKey: string,
    ): Object => {
      if (typeof structure[refKey] !== 'string') {
        const nextKeyName = parentKey + ' ' + refKey;

        return Object.assign({}, accumulator, {
          [refKey]: this.mapFormToStructure(
            formData,
            structure[refKey],
            nextKeyName,
          ),
        });
      }

      const fieldId = this.generateFieldId(parentKey, refKey);

      return Object.assign({}, accumulator, { [refKey]: formData[fieldId] });
    };

    return Object.keys(structure).reduce(formMappingCallback, {});
  }

  processFormSubmit(formData: any, referenceData: any, langCode: string) {
    this.props.onSave({
      language: langCode,
      terms: this.mapFormToStructure(formData, referenceData.terms, 'tra'),
    });
  }

  shouldUpdate = (prevProps: PropsType): boolean => {
    const {
      translationData: prevTrData,
      referenceData: prevRefData,
    } = prevProps;
    const { open, translationData, referenceData } = this.props;

    if (!open) {
      return false;
    }

    if (
      open !== prevProps.open ||
      (open &&
        JSON.stringify(translationData) !== JSON.stringify(prevTrData)) ||
      (open && prevRefData.language !== referenceData.language)
    ) {
      return true;
    }

    if (
      !prevTrData ||
      !translationData ||
      prevTrData.language === translationData.language
    ) {
      return false;
    }

    return true;
  };

  componentDidMount() {
    const translationFormData = this.getFormData(
      this.props.translationData.terms,
      'tra',
    );
    const referenceFormData = this.getFormData(
      this.props.referenceData.terms,
      'ref',
    );
    this.setState({
      formData: Object.assign({}, referenceFormData, translationFormData),
    });
  }

  componentDidUpdate(prevProps: PropsType) {
    const { translationData } = this.props;
    if (!this.shouldUpdate(prevProps)) {
      return;
    }

    resetForm(FORM_NAME);
    const translationFormData = this.getFormData(
      this.props.translationData.terms,
      'tra',
    );
    const referenceFormData = this.getFormData(
      this.props.referenceData.terms,
      'ref',
    );
    this.groups = this.getFormInputs(
      this.props.referenceData.terms,
      'ref',
      true,
    );
    this.inputGroups = this.getFormInputs(translationData.terms, 'tra', false);
    this.setState(
      {
        formData: open
          ? Object.assign({}, referenceFormData, translationFormData)
          : {},
      },
      () => this.cleanErrors(),
    );
  }

  cleanErrors = () => {
    Object.keys(
      this.getFormData(this.props.translationData.terms, 'tra'),
    ).forEach((field) => {
      this.props.untouch(field);
    });
  };

  handleOnClose = () => {
    this.props.onClose();
    this.setState({
      alertOpen: false,
      list: [],
      isDisabledSave: true,
      formData: {},
      pendingOption: '',
      pendingDropdown: '',
    });
  };

  handleAlertCancel = () => {
    this.setState({
      alertOpen: false,
      pendingOption: '',
      pendingDropdown: '',
    });
  };

  handleAlertOpen = (currentChange: string, currentDropdown: string) => {
    this.setState({
      alertOpen: true,
      pendingOption: currentChange,
      pendingDropdown: currentDropdown,
    });
  };

  handleAlertConfirm = () => {
    const { onTrChange, onRefChange } = this.props;
    const { pendingOption, pendingDropdown } = this.state;
    this.setState({ formData: {} });

    if (pendingDropdown === 'translation') {
      onTrChange(pendingOption);
    } else {
      onRefChange(pendingOption);
    }
  };

  FormDialog = getFormDialog(FORM_NAME, this.validate);

  render() {
    const {
      title,
      open,
      classes,
      referenceData,
      selectedRefLang,
      selectedTransLang,
      references,
      languages,
    } = this.props;
    const { FormDialog, groups, inputGroups } = this;
    const { alertOpen, formData } = this.state;
    const refs: ChildInputType[] = references.map((ref) => ({
      label: ref.name,
      value: ref.code,
    }));
    const langs: ChildInputType[] = languages.map((ref) => ({
      label: ref.name,
      value: ref.code,
    }));
    const selectedRefOption = refs.find((ref) => ref.value === selectedRefLang);
    const selectedTransOption = langs.find(
      (lang) => lang.value === selectedTransLang,
    );

    return (
      <>
        <FormDialog
          title={title}
          open={open}
          onClose={this.handleOnClose}
          formData={formData}
          paperWidthMd={classes.paperWidthMd}
          onSubmit={(values: ChildInputType) => {
            this.processFormSubmit(values, referenceData, selectedTransLang);
          }}
        >
          <div className={classes.sectionWrapper}>
            <div className={classes.section}>
              <Select
                className={classes.select}
                value={selectedRefOption.value}
                onChange={(event) =>
                  this.handleAlertOpen(event.target.value, 'reference')
                }
                inputProps={{
                  name: 'reference',
                  id: 'reference',
                }}
              >
                {refs.map((ref) => (
                  <MenuItem key={`tra-${ref.value}`} value={ref.value}>
                    <em>{ref.label}</em>
                  </MenuItem>
                ))}
              </Select>
              {groups}
            </div>
            <div className={classes.section}>
              <Select
                className={classes.select}
                value={selectedTransOption.value}
                onChange={(event) =>
                  this.handleAlertOpen(event.target.value, 'translation')
                }
                inputProps={{
                  name: 'translation',
                  id: 'translation',
                }}
              >
                {langs.map((lang) => (
                  <MenuItem key={`tra-${lang.value}`} value={lang.value}>
                    <em>{lang.label}</em>
                  </MenuItem>
                ))}
              </Select>
              {inputGroups}
            </div>
          </div>
        </FormDialog>
        <Dialog
          disableBackdropClick
          open={alertOpen}
          onClose={this.handleAlertCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {translate.t('text_unsaved_changes')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleAlertCancel}>
              {translate.t('laNo')}
            </Button>
            <Button
              autoFocus
              color="primary"
              onClick={() => {
                this.handleAlertConfirm();
                this.handleAlertCancel();
              }}
            >
              {translate.t('laYes')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const enhance = compose<any, OwnProps>(
  withStyles(TranslateTermsDialogStyle, { withTheme: true }),
);

export default enhance(TranslateTermsDialog);

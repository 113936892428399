import React from 'react';
import { Paper, InputBase, WithStyles, withStyles, StyleRulesCallback, Theme, Typography } from '@material-ui/core';
import { APPROVAL_STATUS } from '../../../types';
import translate from '@/app/utils/translate';
import { FieldProps } from 'formik';

const styles: StyleRulesCallback = (theme: Theme) => ({
  input: {
    flex: 1,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    minWidth: theme.spacing.unit * 6,
    width: theme.spacing.unit * 6,
  },
  inputPaper: {
    backgroundColor: theme.palette.grey[300],
    display: 'flex',
  },
});

type OwnProps = {
  approvalStatus: APPROVAL_STATUS;
  readOnly?: boolean;
};
type InnerProps = WithStyles<typeof styles> & FieldProps;
type Props = OwnProps & InnerProps;

const HoursField = ({
  classes,
  field,
  form,
  readOnly = false,
  approvalStatus,
  ...others
}: Props) => {
  if (approvalStatus === APPROVAL_STATUS.REJECTED || approvalStatus === APPROVAL_STATUS.CANCELLED) {
    return null;
  }

  if (readOnly) {
    return <Typography variant="inherit">{field.value}</Typography>;
  }

  return (
    <Paper className={classes.inputPaper} elevation={1}>
      <InputBase
        {...field}
        className={classes.input}
        placeholder={translate.t('laHours')}
        type="number"
        {...others}
      />
    </Paper>
  );
};

export default withStyles(styles)(HoursField);

import { Reducer } from 'react';

import { ReportSettingsUtilsActionKeys, ReportSettingsUtilsActionTypes } from './actions';
import { ReportSettings } from './models';

export interface ReportSettingsUtilsState {
    reportSettings?: ReportSettings[];
    openedReportSettings?: ReportSettings;
    deletingOpenedReportSettings?: boolean;
    failedToDeleteOpenedReportSettings?: boolean;
    savingReportSettings?: boolean;
    failedToSaveReportSettings?: boolean;
}

export type ReportSettingsUtilsReducer = Reducer<ReportSettingsUtilsState, ReportSettingsUtilsActionTypes>;

export const reducer: ReportSettingsUtilsReducer = (state, action): ReportSettingsUtilsState => {
    switch (action.type) {
        case ReportSettingsUtilsActionKeys.INITIALIZE_REPORT_SETTINGS:
            return {
                ...state,
                reportSettings: action.payload.reportSettings,
                openedReportSettings: action.payload.openedReportSettings,
            };

        case ReportSettingsUtilsActionKeys.DELETE_REPORT_SETTINGS_PENDING:
            return {
                ...state,
                deletingOpenedReportSettings: true,
            };

        case ReportSettingsUtilsActionKeys.DELETE_REPORT_SETTINGS_FULFILLED: {
            return {
                ...state,
                reportSettings: state.reportSettings && state.reportSettings.filter((rs) => rs.id !== action.meta),
                openedReportSettings: undefined,
                deletingOpenedReportSettings: false,
                failedToDeleteOpenedReportSettings: false,
            };
        }

        case ReportSettingsUtilsActionKeys.DELETE_REPORT_SETTINGS_REJECTED:
            return {
                ...state,
                deletingOpenedReportSettings: false,
                failedToDeleteOpenedReportSettings: true,
            };

        case ReportSettingsUtilsActionKeys.CHANGE_OPENED_REPORT_SETTINGS:
            return {
                ...state,
                openedReportSettings: action.payload,
            };

        case ReportSettingsUtilsActionKeys.CREATE_REPORT_SETTINGS_PENDING:
        case ReportSettingsUtilsActionKeys.FETCH_REPORT_SETTINGS_PENDING:
        case ReportSettingsUtilsActionKeys.UPDATE_REPORT_SETTINGS_PENDING:
            return {
                ...state,
                savingReportSettings: true,
            };

        case ReportSettingsUtilsActionKeys.CREATE_REPORT_SETTINGS_FULFILLED:
            return {
                ...state,
                reportSettings: [...state.reportSettings, action.payload],
                openedReportSettings: action.payload,
                savingReportSettings: false,
                failedToSaveReportSettings: false,
            };
        
        case ReportSettingsUtilsActionKeys.FETCH_REPORT_SETTINGS_FULFILLED:
            return {
                ...state,
                reportSettings: action.payload.reportSettings,
                openedReportSettings: action.payload.openedReportSettings,
                savingReportSettings: false,
            };

        case ReportSettingsUtilsActionKeys.UPDATE_REPORT_SETTINGS_FULFILLED:
            return {
                ...state,
                reportSettings: state.reportSettings && state.reportSettings.map((rs) => {
                    if (rs.id === action.payload.id) {
                        return action.payload;
                    }

                    return rs;
                }),
                openedReportSettings: action.payload,
                savingReportSettings: false,
                failedToSaveReportSettings: false,
            };

        case ReportSettingsUtilsActionKeys.CREATE_REPORT_SETTINGS_REJECTED:
        case ReportSettingsUtilsActionKeys.FETCH_REPORT_SETTINGS_REJECTED:
        case ReportSettingsUtilsActionKeys.UPDATE_REPORT_SETTINGS_REJECTED:
            return {
                ...state,
                savingReportSettings: false,
                failedToSaveReportSettings: true,
            };

        default:
            return state;
    }
};

import {
  getGridColumns,
  WorkflowReportTypes,
} from '@/app/components/WorkflowsReport/ReportTypes';
import { useCallback, useMemo, useState } from 'react';
import {
  CommonWorkflowReportUnitData,
  CommonWorkflowsReportPresentationSettings,
} from '@/app/components/WorkflowsReport/ReportTypes/Common';
import { ReportSettingsTypes } from '@/app/components/ReportSettingsUtils';
import { ColumnConfig, OrganizationData } from '@/old/utils/helper';

const GLOBAL: any = window;

type PropsType = {
  reportType: WorkflowReportTypes;
};

export type WorkflowsReportDataState = {
  skip: number;
  take: number;
};

export type WorkflowsReportSettingsConfigShowDetails = {
  history: boolean;
  changes: boolean;
};

export type WorkflowsReportSettingsConfig = {
  columns: ColumnConfig[];
  dataState: WorkflowsReportDataState;
  showDetails: CommonWorkflowsReportPresentationSettings;
  teamCheck: boolean;
  subUnitCheck: boolean;
  selectedUnitData?: CommonWorkflowReportUnitData;
};

export type SavedWorkflowsReportSettingsConfig = WorkflowsReportSettingsConfig & {
  [key: string]: any;
};

export type WorkflowsReportSettings = {
  settingsType: ReportSettingsTypes;
  initial: WorkflowsReportSettingsConfig;
  current: WorkflowsReportSettingsConfig;
};

export type WorkflowsReportColumnsConfig = any;

export type WorkflowsReportSettingsSetStates = {
  setTeamCheck: (value: boolean) => void;
  setSubUnitCheck: (value: boolean) => void;
  setSelectedUnit: (unitData: CommonWorkflowReportUnitData | undefined) => void;
  setColumns: (columns: WorkflowsReportColumnsConfig) => void;
  setDataState: (dataState: any) => void;
  setShowDetails: (value: CommonWorkflowsReportPresentationSettings) => void;
};

type UseWorkflowsReportSettingsOutput = {
  reportSettings: WorkflowsReportSettings;
  setStates: WorkflowsReportSettingsSetStates;
  applySavedSettings: (
    settings: SavedWorkflowsReportSettingsConfig,
  ) => WorkflowsReportSettingsConfig;
};

export const useWorkflowsReportSettings = (
  props: PropsType,
): UseWorkflowsReportSettingsOutput => {
  const { reportType } = props;

  const reportGridColumns: ColumnConfig[] = useMemo(
    () => getGridColumns(reportType),
    [reportType],
  );

  const reportSettingConfigInit: WorkflowsReportSettingsConfig = {
    columns: reportGridColumns,
    dataState: { skip: 0, take: 9 },
    showDetails: {
      history: false,
      changes: true,
    },
    teamCheck: true,
    subUnitCheck: false,
    selectedUnitData: undefined,
  };

  const [columns, setColumns] = useState<ColumnConfig[]>(reportGridColumns);
  const [dataState, setDataState] = useState(reportSettingConfigInit.dataState);
  const [showDetails, setShowDetails] = useState(
    reportSettingConfigInit.showDetails,
  );

  // Toolbar settings
  const [teamCheck, setTeamCheck] = useState<boolean>(
    reportSettingConfigInit.teamCheck,
  );
  const [subUnitCheck, setSubUnitCheck] = useState<boolean>(
    reportSettingConfigInit.subUnitCheck,
  );
  const [toolbarSelectedUnit, setToolbarSelectedUnit] = useState<
    CommonWorkflowReportUnitData | undefined
  >(reportSettingConfigInit.selectedUnitData);

  const reportSettingConfig: WorkflowsReportSettingsConfig = {
    columns,
    dataState,
    showDetails,
    teamCheck,
    subUnitCheck,
    selectedUnitData: toolbarSelectedUnit,
  };

  const setSavedReportSettings = useCallback(
    (
      settings: WorkflowsReportSettingsConfig,
    ): WorkflowsReportSettingsConfig => {
      setColumns(settings.columns);
      setDataState(settings.dataState);
      setShowDetails(settings.showDetails);
      setTeamCheck(settings.teamCheck);
      setSubUnitCheck(settings.subUnitCheck);
      setToolbarSelectedUnit(settings.selectedUnitData);

      return settings;
    },
    [
      setColumns,
      setDataState,
      setShowDetails,
      setTeamCheck,
      setSubUnitCheck,
      setToolbarSelectedUnit,
    ],
  );

  const applySavedReportSettings = useCallback(
    (
      settings: SavedWorkflowsReportSettingsConfig,
    ): WorkflowsReportSettingsConfig => {
      if (!settings) {
        // Reset to default
        return setSavedReportSettings(reportSettingConfigInit);
      } else {
        // Override default column configs with selected saved properties
        const resolvedColumns = reportGridColumns.map(col => {
          const foundSavedSetting = settings.columns.find(
            c => c.field === col.field,
          );

          return !!foundSavedSetting
            ? {
                ...col,
                ...foundSavedSetting,
              }
            : col;
        });

        // Data processing utils
        const getValueOrFallback = <
          TKey extends keyof WorkflowsReportSettingsConfig
        >(
          propKey: TKey,
          fallback: WorkflowsReportSettingsConfig[TKey],
        ): WorkflowsReportSettingsConfig[TKey] => {
          return settings.hasOwnProperty(propKey)
            ? settings[propKey]
            : fallback;
        };

        const getValuesOrDefaults = <
          TKey extends keyof WorkflowsReportSettingsConfig
        >(
          propKeys: TKey[],
        ): Pick<WorkflowsReportSettingsConfig, TKey> => {
          return Object.assign(
            {},
            ...propKeys.map(k => ({
              [k]: getValueOrFallback(k, reportSettingConfigInit[k]),
            })),
          );
        };

        // Check old properties for resolving fallback value
        // Old object was renamed & extended
        const resolvedShowDetails: CommonWorkflowsReportPresentationSettings = getValueOrFallback(
          'showDetails',
          {
            history: settings.hasOwnProperty('showHistory')
              ? settings.showHistory.checkedA === true
              : reportSettingConfigInit.showDetails.history,
            changes: reportSettingConfigInit.showDetails.changes,
          },
        );
        // Old variable name was corrected
        const resolvedSubUnitCheck = getValueOrFallback(
          'subUnitCheck',
          settings.hasOwnProperty('subunitCheck')
            ? settings.subunitCheck === true
            : reportSettingConfigInit.subUnitCheck,
        );

        // Multiple unit variables was replaced with single selectedUnitData object
        const resolveSelectedUnitDataFallback = ():
          | CommonWorkflowReportUnitData
          | undefined => {
          const unitId = settings.selectedUnitId;

          // No unit selected
          if (typeof unitId === 'undefined') {
            return undefined;
          }
          const foundUnit = (GLOBAL.jsonOrgUnitData as OrganizationData[]).find(
            u => u.fOrgUnitId === unitId,
          );

          // Get unit number
          return !!foundUnit
            ? {
                unit: foundUnit.fOrgUnitName,
                unitId: foundUnit.fOrgUnitId,
                unitNo: foundUnit.fOrgUnitNumber,
              }
            : {
                unit: settings.selectedUnit,
                unitId: settings.selectedUnitId,
                unitNo: '',
              };
        };
        const resolvedSelectedUnitData = getValueOrFallback(
          'selectedUnitData',
          resolveSelectedUnitDataFallback(),
        );

        const resolvedSettings: WorkflowsReportSettingsConfig = {
          ...getValuesOrDefaults(['dataState', 'teamCheck']),
          columns: resolvedColumns,
          showDetails: resolvedShowDetails,
          subUnitCheck: resolvedSubUnitCheck,
          selectedUnitData: resolvedSelectedUnitData,
        };

        return setSavedReportSettings(resolvedSettings);
      }
    },
    [
      reportSettingConfigInit,
      setColumns,
      setDataState,
      setShowDetails,
      setTeamCheck,
      setSubUnitCheck,
      setToolbarSelectedUnit,
    ],
  );

  const reportSettingsType = useMemo(() => {
    const typeMap: { [key in WorkflowReportTypes]: ReportSettingsTypes } = {
      [WorkflowReportTypes.EmployeeDetailsClosed]:
        ReportSettingsTypes.CLOSED_WORKFLOW_EMPLDET,
      [WorkflowReportTypes.EmployeeDetailsOpen]:
        ReportSettingsTypes.OPEN_WORKFLOW_EMPLDET,
      [WorkflowReportTypes.InternalPositionClosed]:
        ReportSettingsTypes.CLOSED_WORKFLOW_INTPOS,
      [WorkflowReportTypes.InternalPositionOpen]:
        ReportSettingsTypes.OPEN_WORKFLOW_INTPOS,
      [WorkflowReportTypes.RewardingClosed]:
        ReportSettingsTypes.CLOSED_WORKFLOW_REWARDING,
      [WorkflowReportTypes.RewardingOpen]:
        ReportSettingsTypes.OPEN_WORKFLOW_REWARDING,
    };

    return typeMap[reportType];
  }, [reportType]);

  return {
    reportSettings: {
      settingsType: reportSettingsType,
      initial: reportSettingConfigInit,
      current: reportSettingConfig,
    },
    setStates: {
      setTeamCheck,
      setSubUnitCheck,
      setSelectedUnit: setToolbarSelectedUnit,
      setColumns,
      setDataState,
      setShowDetails,
    },
    applySavedSettings: applySavedReportSettings,
  };
};

import React, { useMemo, useState } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import GenericDialog from '@/app/components/GenericDialog/GenericDialog';
import SortableComponent from '@/app/components/SortableComponent/SortableComponent';
import { QuickLink, QuickLinkSeq, updateQuickLinkOrdering } from '@/app/redux/quickLinks';
import { getCurrentLanguage } from '@/app/utils/helper';
import translate from '@/app/utils/translate';
import { getTranslation } from '../utils';

type MapDispatchToProps = {
  saveSorting: (newSeq: QuickLinkSeq[]) => Promise<QuickLinkSeq[]>;
};

type OwnProps = {
  open: boolean;
  quickLinks: QuickLink[];
  onClose: () => void;
};

type Props = OwnProps & MapDispatchToProps;

const SortQuickLinksDialog = ({
  open,
  quickLinks,
  onClose,
  saveSorting,
}: Props) => {
  const [sorting, setSorting] = useState<QuickLinkSeq[]>([]);
  const onSave = () => {
    if (!sorting.length) {
      return onClose();
    }

    const ordered = sorting.map((item, seq) => ({ ...item, seq }));
    saveSorting(ordered).then(() => {
      onClose();
    });
  };

  const sortableItems = useMemo(
    () => quickLinks.map((ql) => {
      const currentTr = getTranslation(
        getCurrentLanguage(),
        ql.translations,
        true,
      );
      
      return {
        name: currentTr.name,
        seq: ql.seq,
        id: ql.id,
      };
    }),
    [quickLinks]
  );

  return (
    <GenericDialog
      open={open}
      title={translate.t('laSort')}
      onClose={onClose}
      onSave={onSave}
      description={translate.t('laSortIns')}
      isDisabledSave={false}
    >
      <SortableComponent
        list={sortableItems}
        updatedList={setSorting}
      />
    </GenericDialog>
  );
};

const mapDispatchToProps = {
  saveSorting: updateQuickLinkOrdering,
};

const enhance = compose<any, OwnProps>(
  connect(null, mapDispatchToProps),
);

export default enhance(SortQuickLinksDialog);

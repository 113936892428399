import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  IconButton,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as React from 'react';
import { compose } from 'recompose';

import ComponentListing from './ComponentListing/ComponentListing';
import sectionListingStyles from './sectionListingStyles';

type IncomingProps = {
  section: any;
  data: { commonGoals: any[]; personalGoals: any[] };
  submitErrors?: any;
  updateData: (sectionId: number, data: any) => void;
  inEditMode: boolean;
};

type PropsType = IncomingProps & WithStyles<typeof sectionListingStyles>;

type StateType = {
  expanded: boolean;
};

class SectionListing extends React.PureComponent<PropsType, StateType> {
  state: StateType = {
    expanded: false,
  };

  componentDidUpdate(prevProps: Readonly<PropsType>) {
    if (this.props.submitErrors !== prevProps.submitErrors) {
      if (
        this.props.submitErrors &&
        this.props.submitErrors[this.props.section.id]
      ) {
        this.setState({ expanded: true });
      }
    }

    if (this.props.inEditMode && !prevProps.inEditMode) {
      this.setState({ expanded: true });
    }
  }

  toggleExpansionPanel = (_event: object, expanded: boolean) => {
    this.setState({ expanded });
  };

  render() {
    const { expanded } = this.state;
    const {
      classes,
      section,
      data,
      submitErrors,
      updateData,
      inEditMode,
    } = this.props;

    return (
      <div className={classes.container}>
        <ExpansionPanel
          expanded={this.state.expanded}
          onChange={this.toggleExpansionPanel}
        >
          <ExpansionPanelSummary
            classes={{
              root: classes.expansionPanel,
              content: classes.expansionPanelContent,
              expanded: classes.expanded,
            }}
          >
            <Typography variant="h6">
              <IconButton className={classes.iconButton}>
                {(expanded && <ExpandLessIcon fontSize="small" />) || (
                  <ExpandMoreIcon fontSize="small" />
                )}
              </IconButton>

              {section.name}
            </Typography>
          </ExpansionPanelSummary>

          <ExpansionPanelDetails
            classes={{ root: classes.expansionPanelDetails }}
          >
            <ComponentListing
              section={section}
              data={data}
              submitErrors={submitErrors}
              updateData={updateData}
              inEditMode={inEditMode}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

const enhance = compose<any, any>(withStyles(sectionListingStyles));

export default enhance(SectionListing);

import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const componentListingStyles: StyleRulesCallback = () => ({
  topCheckbox: {
    marginLeft: '-12px',
  },
  multiline: {
    whiteSpace: 'pre-wrap',
  },
  longText: {
    maxWidth: 430,
  }
});

export default componentListingStyles;
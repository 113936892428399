import {
  Popover,
  Paper,
  Button,
  TextField,
  StyleRulesCallback,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import * as React from 'react';
import { ErrorMessage, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useYupValidationResolver } from '@/app/hooks/useYupValidationResolver';
import translate from '@/app/utils/translate';

const styles: StyleRulesCallback = (theme) => ({
  paper: {
    padding: theme.spacing.unit * 2,
    minWidth: 280,
    maxWidth: 350,
  },
  body: {
    marginBottom: theme.spacing.unit,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& > *': {
      marginLeft: theme.spacing.unit / 2,
      marginRight: theme.spacing.unit / 2,
    },
  },
});

type OwnProps = {
  anchorEl: any;
  onClose: () => void;
  onAdd: (participantDetails: any) => void;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

type ParticipantDetails = {
  email: string;
  firstName: string;
  lastName: string;
  givenNames: string;
  phone: string;
};

const RegisterExternalParticipantPopover = ({
  classes,
  anchorEl,
  onClose,
  onAdd,
}: Props) => {
  const dailySummaryValidationResolver = React.useMemo(
    () => yup.object().shape<ParticipantDetails>({
      firstName: yup.string().required(translate.t('laThisRequired')),
      givenNames: yup.string().required(translate.t('laThisRequired')),
      lastName: yup.string().required(translate.t('laThisRequired')),
      email: yup.string().email(translate.t('laCheckEmailAddress')).required(translate.t('laThisRequired')),
      phone: yup.string().required(translate.t('laThisRequired')),
    }),
    [],
  );

  const { register, handleSubmit, errors } = useForm<ParticipantDetails>({
    validationResolver: useYupValidationResolver(dailySummaryValidationResolver),
  });

  if (!anchorEl) {
    return null;
  }

  return (
    <Popover
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
    >
      <Paper className={classes.paper}>
        <form
          onSubmit={handleSubmit((participantDetails) => {
            onAdd(participantDetails);
            onClose();
          })}
        >
          <div className={classes.body}>
            <TextField
              inputRef={register}
              name="firstName"
              margin="dense"
              label={translate.t('laFirstName')}
              error={!!errors.firstName}
              helperText={errors.firstName && (
                <ErrorMessage errors={errors} name="firstName" />
              )}
              fullWidth
            />

            <TextField
              inputRef={register}
              name="givenNames"
              margin="dense"
              label={translate.t('laGivenNames')}
              error={!!errors.givenNames}
              helperText={errors.givenNames && (
                <ErrorMessage errors={errors} name="givenNames" />
              )}
              fullWidth
            />

            <TextField
              inputRef={register}
              name="lastName"
              margin="dense"
              label={translate.t('laLastName')}
              error={!!errors.lastName}
              helperText={errors.lastName && (
                <ErrorMessage errors={errors} name="lastName" />
              )}
              fullWidth
            />

            <TextField
              inputRef={register}
              name="email"
              margin="dense"
              label={translate.t('laEmail')}
              error={!!errors.email}
              helperText={errors.email && (
                <ErrorMessage errors={errors} name="email" />
              )}
              fullWidth
            />

            <TextField
              inputRef={register}
              name="phone"
              type="tel"
              margin="dense"
              label={translate.t('laPhone')}
              error={!!errors.phone}
              helperText={errors.phone && (
                <ErrorMessage errors={errors} name="phone" />
              )}
              fullWidth
            />
          </div>

          <div className={classes.actions}>
            <Button color="primary" onClick={onClose}>{translate.t('laCancel')}</Button>
            <Button type="submit" color="primary" variant="contained">{translate.t('laAdd')}</Button>
          </div>
        </form>
      </Paper>
    </Popover>
  );
};

export default withStyles(styles)(RegisterExternalParticipantPopover);

import React from 'react';
import {
  Popover,
  Paper,
  ListItem,
  ListItemIcon,
  ListItemText,
  Icon,
  Typography,
  StyleRulesCallback,
  Theme,
  WithStyles,
  withStyles,
  List,
} from '@material-ui/core';
import { CompanyTraining } from '../../../types';
import { formatTrainingDates } from '../../../helpers';
import translate from '@/app/utils/translate';

const styles: StyleRulesCallback = (theme: Theme) => ({
  paper: {
    padding: theme.spacing.unit * 2,
    minWidth: 280,
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

type OwnProps = {
  training: CompanyTraining;
  anchorEl: any;
  onClose: () => void;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const AudienceDetailsPopover = ({
  classes,
  training: {
    name,
    startDate,
    endDate,
    unitAudience,
    roleAudience,
    countryAudience,
  },
  anchorEl,
  onClose,
}: Props) => {
  if (!anchorEl) {
    return null;
  }

  const getAudienceListItem = (
    title: string,
    audience: string[],
    icon: string,
  ) => (
    <ListItem>
      <ListItemIcon>
        <Icon color="primary">{icon}</Icon>
      </ListItemIcon>
      <ListItemText>
        <Typography variant="caption">{title}</Typography>
        <Typography variant="body1">
          {audience.map((item, ndx) => (
            <span key={item}>
              {item}
              {ndx === audience.length - 1 ? '' : ', '}
            </span>
          ))}
        </Typography>
      </ListItemText>
    </ListItem>
  );

  const audienceUnitsItem =
    !unitAudience || unitAudience.length === 0
      ? null
      : getAudienceListItem(
          translate.t('label_audience_for_units'),
          unitAudience.map((item) => item.label),
          'business',
        );
  const audienceRolesItem =
    !roleAudience || roleAudience.length === 0
      ? null
      : getAudienceListItem(
          translate.t('label_audience_for_roles'),
          roleAudience.map((item) => item.label),
          'people_alt',
        );
  const audienceCountriesItem =
    !countryAudience || countryAudience.length === 0
      ? null
      : getAudienceListItem(
          translate.t('label_audience_for_countries'),
          countryAudience.map((item) => item.label),
          'flag',
        );

  return (
    <Popover anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
      <Paper className={classes.paper}>
        <Typography variant="h6">
          {translate.t('label_training_audience')}: {name.label}{' '}
          {formatTrainingDates(startDate, endDate)}
        </Typography>
        <List>
          {audienceUnitsItem}
          {audienceRolesItem}
          {audienceCountriesItem}
        </List>
      </Paper>
    </Popover>
  );
};

export default withStyles(styles)(AudienceDetailsPopover);

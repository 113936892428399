import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';

const toolbarHeight: number = 34;

const styles: StyleRulesCallback = (theme: Theme) => {
  return ({
    root: {},
    grow: {
      flexGrow: 1,
    },
    actionBar: {
      backgroundColor: theme.palette.background.paper,
      padding: 0,
      minHeight: toolbarHeight,
      height: toolbarHeight,
    },
    actionTabs: {
      maxHeight: toolbarHeight,
      minHeight: toolbarHeight,
    },
    actionTab: {
      minHeight: toolbarHeight,
      maxHeight: toolbarHeight,
      minWidth: toolbarHeight,
    },
    summaryListRoot: {
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2,
      color: 'inherit',
    },
    summaryListItem: {
      paddingTop: theme.spacing.unit,
      paddingLeft: 0,
      paddingRight: 0,
      width: 'auto',
    },
    summaryListSubheader: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    summaryListSubheaderIcon: {
      marginRight: theme.spacing.unit,
    },
    summaryListSubheaderText: {
      paddingLeft: 0,
    },
    listWidgetRoot: {
      marginTop: 0,
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2,
    },
    listWidgetHeaderList: {},
    configureListItem: {
      padding: 0,
      justifyContent: 'center',
    },
    configureButton: {
      color: grey[600],
    },
    configureIcon: {
      marginRight: theme.spacing.unit / 2,
    },
    currentUnit: {
      marginTop: 10,
    }
  });
};

export default styles;

import { AvatarCell, FirstNameCell, LastNameCell } from '../KendoCells';
import translate from '@/app/utils/translate';

export const gridColumns = () => [
  {
    cell: AvatarCell,
    field: 'empId',
    show: true,
    title: translate.t('laPicture'),
    groupable: false,
  },
  {
    cell: FirstNameCell,
    field: 'empFirstName',
    show: true,
    title: translate.t('laFirstName'),
  },
  {
    cell: LastNameCell,
    field: 'empLastName',
    show: true,
    title: translate.t('laLastName'),
  },
  {
    field: 'empNumber',
    show: true,
    title: translate.t('laEmpId'),
  },
  {
    field: 'empUnitName',
    show: true,
    title: translate.t('laOrgUnit'),
  },
  {
    field: 'empUnitNumber',
    show: true,
    title: translate.t('laUnitNumber'),
  },
  {
    field: 'country',
    show: true,
    title: translate.t('laCountry'),
  },
  {
    field: 'legalEntityCode',
    show: true,
    title: translate.t('laLegalEntityCode'),
  },
  {
    field: 'legalEntityName',
    show: true,
    title: translate.t('laLegalEntityName'),
  },
  {
    field: 'empPersonnelGroup1',
    show: true,
    title: translate.t('fPersonnelGroup1'),
  },
  {
    field: 'empPersonnelGroup2',
    show: true,
    title: translate.t('fPersonnelGroup2'),
  },
  {
    field: 'empPersonnelGroup3',
    show: true,
    title: translate.t('fPersonnelGroup3'),
  },
  {
    field: 'empPersonnelGroup4',
    show: true,
    title: translate.t('fPersonnelGroup4'),
  },
  {
    field: 'empPersonnelGroup5',
    show: true,
    title: translate.t('fPersonnelGroup5'),
  },
  {
    field: 'date',
    show: true,
    title: translate.t('laDate'),
  },
  {
    field: 'defaultHours',
    show: true,
    title: translate.t('laExpectedWorktime'),
  },
  {
    field: 'workingTypeHours',
    show: true,
    title: translate.t('laWorkingTypeHours'),
  },
  {
    field: 'nonWorkingTypeHours',
    show: true,
    title: translate.t('laNonWorkingTypeHours'),
  },
  {
    field: 'correctionTypeHours',
    show: true,
    title: translate.t('laCorrectionTypeHours'),
  },
  {
    field: 'dailyBalance',
    show: true,
    title: translate.t('laHourBalanceChange'),
  },
  {
    field: 'dailyCumulativeBalance',
    show: true,
    title: translate.t('laHourBalanceInTotal'),
  },
];

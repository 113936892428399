import * as React from 'react';
import moment from 'moment';

import { FieldProps } from 'formik';

import LocalizedDatePicker from '@/app/components/Pickers/LocalizedDatePicker';
import translate from '@/app/utils/translate';

type OwnProps = {
  clearable?: boolean;
  disabled?: boolean;
  required?: boolean;
  label: string;
  onChange?: (value: Date) => void;
};
type Props = OwnProps;

const FieldDate = ({
  clearable = false,
  disabled = false,
  required = false,
  field,
  form,
  label,
  onChange,
}: FieldProps & Props) => {
  const handleBlur = (e: React.FocusEvent) => {
    form.setFieldTouched(field.name, true);
    form.handleBlur(e);
  };

  const handleChange = (value: moment.Moment) => {
    form.setFieldValue(field.name, !value ? null : value.toDate());
    form.setFieldTouched(field.name, true);
    if (onChange) {
      onChange(!value ? null : value.toDate());
    }
  };

  const handleClose = () => {
    form.setFieldTouched(field.name, true);
    if (!field.value && required) {
      form.setFieldError(field.name, translate.t('laThisRequired'));
    }
  };

  const errorText = (form.errors[field.name] && form.touched[field.name]) && form.errors[field.name];
  const renderError = () => !(form.errors[field.name] && form.touched[field.name]) ? undefined : (
    <div style={{ color: 'red' }} /*className={classes.colorSpan}*/ >
      {errorText}
    </div>
  );

  return (
    <>
      <LocalizedDatePicker
        autoOk={true}
        clearable={clearable}
        disabled={disabled}
        required={required}
        okLabel={translate.t('laOk')}
        cancelLabel={translate.t('laCancel')}
        clearLabel={translate.t('laClear')}
        label={label}
        value={field.value}
        onBlur={handleBlur}
        onChange={handleChange}
        onClose={handleClose}
      />
      {renderError()}
    </>
  );
};

export default FieldDate;

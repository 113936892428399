import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const enhancedTableToolbarStyle: StyleRulesCallback = (theme: Theme) => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight: {
    color: theme.palette.primary.main,
    backgroundColor: '#FFF',
  },
  actions: {
    flex: '0 0 auto',
    textAlign: 'right'
  },
  title: {
    flex: '1 1 100%',
  },
  subHeader: {
    fontSize: '80%',
    fontWeight: 'normal',
    color: theme.palette.primary.main,
    marginLeft: 20,
  }
});

export default enhancedTableToolbarStyle;

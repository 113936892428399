import {
  ITask,
  IWorklist,
  IWorklistTask,
  DueDaysAutomation,
  DueDateAutomationSelection,
  EmployeeAutomation,
  EmployeeAutomationSelection
} from './types';
import * as moment from 'moment';

import translate from '@/app/utils/translate';
import {
  getEmployeeName,
  getEmployeeManagerId,
  getEmployeeHRId,
  getLoggedUserId,
} from '@/old/utils/helper';

const NO_DUE_DATE_AUTOMATION_VALUE = -999999;

const hasDueDateAutomation = (days: number): boolean => {
  return days > NO_DUE_DATE_AUTOMATION_VALUE;
};

const getDueDateAutomationDays = (days: number): number | undefined => {
  if (!hasDueDateAutomation(days) || days === 0) { return 0; }
  return days < 0 ? Math.abs(days) : days;
};

const getDueDateAutomationType = (days: number): DueDaysAutomation => {
  if (!hasDueDateAutomation(days)) { return 'NONE'; }
  if (days === 0) { return 'ON'; }
  return days < 0 ? 'BEFORE' : 'AFTER';
};

export const resolveInitialDueDate = (completionDate: Date, type: DueDaysAutomation, days: number): Date => {
  switch (type) {
    case 'ON':
      return completionDate;
    case 'AFTER':
      return moment(completionDate).add(days, 'days').toDate();
    case 'BEFORE':
      return moment(completionDate).add(-1 * days, 'days').toDate();
    default:
      return null;
  }
};

export const resolveInitialEmployeeId = (
  type: EmployeeAutomation,
  loggedInUserId: number,
  selectedEmpId: number
): number => {
  switch (type) {
    case 'LOGGED_IN_USER':
      return loggedInUserId;
    case 'LOGGED_IN_USER_MANAGER':
      return getEmployeeManagerId(loggedInUserId);
    case 'LOGGED_IN_USER_HR_RESP':
      return getEmployeeHRId(loggedInUserId);
    case 'SELECTED_EMP':
      return selectedEmpId;
    case 'SELECTED_EMP_MANAGER':
      return getEmployeeManagerId(selectedEmpId);
    case 'SELECTED_EMP_HR_RESP':
      return getEmployeeHRId(selectedEmpId);
    default:
      return null;
  }
};

export const resolveDueDate = (
  automationSelection: DueDateAutomationSelection,
  type: DueDaysAutomation,
  days: number,
  completionDate: Date,
  currentDueDate: Date,
): Date | undefined => {
  switch (automationSelection) {
    case 'AUTO': return resolveInitialDueDate(completionDate, type, days);
    case 'COMPL_DATE': return completionDate;
    case 'MANUAL': return currentDueDate;
    default: return undefined;
  }
};

export const resolveAssignee = (automationRule: string): string => {
  return `resolved: ${automationRule}`;
};

export const resolvePertainingTo = (automationRule: string): string => {
  return `resolved: ${automationRule}`;
};

export const getDueDateAutomationText = (type: DueDaysAutomation, days: number) => {
  switch (type) {
    case 'ON':
      return `${translate.t('label_on_completion_date')}`;
    case 'BEFORE':
      return `${translate.t('label_days_before_completion_date', {
        days,
      })}`;
    case 'AFTER':
      return `${translate.t('label_days_after_completion_date', {
        days,
      })}`;
    default:
      return '';
  }
};

export const getEmployeeAutomationText = (
  type: EmployeeAutomation,
  loggedInEmpId: number,
  selectedEmpId: number
) => {
  if (type === 'NONE') { return ''; }

  const getManagerEmpId = (empId: number) => {
    return getEmployeeManagerId(empId);
  };

  const getHrEmpId = (empId: number) => {
    return getEmployeeHRId(empId);
  };

  let name;
  let transLabel;
  switch (type) {

    case 'LOGGED_IN_USER':
      name = getEmployeeName(loggedInEmpId);
      transLabel = 'label_logged_in_user';
      break;
    case 'LOGGED_IN_USER_MANAGER':
      name = getEmployeeName(getManagerEmpId(loggedInEmpId));
      transLabel = 'label_logged_in_user_manager';
      break;
    case 'LOGGED_IN_USER_HR_RESP':
      name = getEmployeeName(getHrEmpId(loggedInEmpId));
      transLabel = 'label_logged_in_user_hr_resp';
      break;

    case 'SELECTED_EMP':
      name = getEmployeeName(selectedEmpId);
      transLabel = 'label_selected_emp';
      break;
    case 'SELECTED_EMP_MANAGER':
      name = getEmployeeName(getManagerEmpId(selectedEmpId));
      transLabel = 'label_selected_emp_manager';
      break;
    case 'SELECTED_EMP_HR_RESP':
      name = getEmployeeName(getHrEmpId(selectedEmpId));
      transLabel = 'label_selected_emp_hr_resp';
      break;

    default:
      name = '';
      transLabel = '';
  }

  return `${translate.t(transLabel, { name })}`;
};

export const getInitialDueDateAutomationSelection = (type: DueDaysAutomation): DueDateAutomationSelection => {
  if (type === 'NONE') {
    return 'NONE';
  }
  return 'AUTO';
};

export const getInitialEmployeeAutomationSelection = (type: EmployeeAutomation): EmployeeAutomationSelection => {
  if (type === 'NONE') {
    return 'NONE';
  }
  return 'AUTO';
};

const getDueDateAutomationForBE = (type: DueDaysAutomation, days: number): number => {
  switch (type) {
    case 'ON': return 0;
    case 'BEFORE': return -1 * days;
    case 'AFTER': return days;
    default: return NO_DUE_DATE_AUTOMATION_VALUE;
  }
};

export const mapWorklistForUI = (data: any): IWorklist => {
  const tasks = !data.fWorklistTasks
    ? undefined
    : data.fWorklistTasks.map((task: any) => mapWorklistTaskForUI(task));
  return {
    id: data.fWorklistId,
    title: data.fWorklistTitle,
    active: data.fWorklistIsActive,
    description: data.fWorklistDescription,
    tasks,
  };
};

export const mapWorklistForBE = (data: IWorklist): any => {
  return {
    fWorklistId: data.id,
    fWorklistTitle: data.title,
    fWorklistIsActive: data.active,
    fWorklistDescription: data.description,
  };
};

export const mapWorklistsForUI = (data: any[]): IWorklist[] => {
  return data.map(item => mapWorklistForUI(item));
};

export const mapWorklistTaskForUI = (data: any): IWorklistTask => {
  return {
    id: data.fWorklistTaskId,
    worklistId: data.fWorklistTaskTemplateFK,
    title: data.fWorklistTaskTitle,
    description: data.fWorklistTaskDescription,
    type: data.fWorklistTaskTypeFK,
    dueDateAutomationDays: getDueDateAutomationDays(data.fWorklistTaskAutomnDueDay),
    dueDateAutomationType: getDueDateAutomationType(data.fWorklistTaskAutomnDueDay),
    assigneeAutomation: data.fWorklistTaskAutomnAssignee,
    pertainingToAutomation: data.fWorklistTaskAutomnPertainingTo,
    visibleToPertainingTo: data.fWorklistTaskVisibleToPertTo,
    attachmentId: data.fWorklistTaskAttachmentId,
    attachmentName: data.fWorklistTaskAttachmentName,
  };
};

const withUploadFilename = (file: File) => {
  if (!file) { return file; }
  return Object.assign(file, { uploadName: file.name });
};

export const mapWorklistTaskForBE = (data: IWorklistTask): any => {
  return {
    fWorklistTaskId: data.id,
    fWorklistTaskTemplateFK: data.worklistId,
    fWorklistTaskTitle: data.title,
    fWorklistTaskDescription: data.description,
    fWorklistTaskTypeFK: data.type,
    fWorklistTaskAutomnDueDay: getDueDateAutomationForBE(data.dueDateAutomationType, data.dueDateAutomationDays),
    fWorklistTaskAutomnAssignee: data.assigneeAutomation,
    fWorklistTaskAutomnPertainingTo: data.pertainingToAutomation,
    fWorklistTaskVisibleToPertTo: data.visibleToPertainingTo,
    fWorklistTaskAttachmentId: data.attachmentId || -1,
    fWorklistTaskAttachmentName: data.attachmentName || '',
    file: withUploadFilename(data.newAttachment),
  };
};

export const mapNewTaskForBE = (data: ITask): any => {
  const loggedInUserId = getLoggedUserId();

  // Make sure this satisfies business rule (if other roles than pertaining to, task must be visible to employee)
  let visibleToPertainingTo;
  if (!data.pertainingToId) {
    visibleToPertainingTo = data.visibleToPertainingTo;
  } else {
    if (data.pertainingToId === loggedInUserId || data.pertainingToId === data.assigneeId) {
      visibleToPertainingTo = true;
    } else {
      visibleToPertainingTo = data.visibleToPertainingTo;
    }
  }

  return {
    fTaskAttachmentId: -1,
    fTaskAttachmentName: '',
    fTaskId: '',
    taskAudit: {
      fTaskCreatedTime: '',
      fTaskUpdatedEmpId: '',
      fTaskUpdatedTime: '',
    },
    taskCore: {
      fTaskAssigneeId: data.assigneeId,
      fTaskAssignorId: loggedInUserId,
      fTaskDescription: data.description,
      fTaskIsVisibleToPertainingTo: visibleToPertainingTo,
      fTaskPertainingToId: data.pertainingToId,
      fTaskTitle: data.title,
      fTaskType: data.type,
    },
    taskState: {
      fTaskDueDate: !data.dueDate ? '' : moment(data.dueDate).format('YYYY-MM-DD'),
      fTaskStartDate: moment().format('YYYY-MM-DD'),
      fTaskStatus: data.status,
    },
    worklistTaskId: data.automationSelection.copyAttachment ? data.id : null,
    fOriginalWorklistTaskId: data.id,
  };
};

export const getEmployeeAutomationTransLabel = (empAutomation: EmployeeAutomation) => {
  switch (empAutomation) {
    case 'SELECTED_EMP': return 'label_automation_sel_emp';
    case 'SELECTED_EMP_MANAGER': return 'label_automation_sel_emp_manager';
    case 'SELECTED_EMP_HR_RESP': return 'label_automation_sel_emp_hr_resp';
    case 'LOGGED_IN_USER': return 'label_automation_curr_user';
    case 'LOGGED_IN_USER_MANAGER': return 'label_automation_curr_user_manager';
    case 'LOGGED_IN_USER_HR_RESP': return 'label_automation_curr_user_hr_resp';
    default: return 'label_automation_none';
  }
};

import { Avatar, ListItem, ListItemAvatar, ListItemText, WithStyles } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';

import API from '@/app/api/internalAPIs';
import { NewLineToBr } from '@/app/components/NewLineToBr';
import { getEmpPicId, getEmployeeName } from '@/old/utils/helper';

import { RawTaskComment } from '../../../../../../collaborationModels';

import { commentsListItemStyles } from './commentsListItemStyles';

export interface CommentsListItemOuterProps {
    comment: RawTaskComment;
}

export interface CommentsListItemInnerProps extends
    CommentsListItemOuterProps,
    WithStyles<typeof commentsListItemStyles> {}

export const CommentsListItem: React.FC<CommentsListItemInnerProps> = ({
    classes,
    comment,
}) => {
    const avatarSrc = API.employeeImageUrl(
        getEmpPicId(comment.fTaskCommentUpdatedEmpId)
    );

    return (
        <ListItem disableGutters={true}>
            <ListItemAvatar>
                <Avatar src={avatarSrc} />
            </ListItemAvatar>
            <ListItemText
                primary={<NewLineToBr value={comment.fTaskCommentText} />}
                primaryTypographyProps={{ className: classes.commentText }}
                secondary={
                    <>
                        <span className={classes.date}>{moment(comment.fTaskCommentUpdatedTime).calendar()}</span>
                        <span>{getEmployeeName(comment.fTaskCommentUpdatedEmpId)}</span>
                    </>
                }
            />
        </ListItem>
    );
};
import * as React from 'react';
import { v4 as uuid4 } from 'uuid';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { WithStyles, Grid, IconButton, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import translate from '@/app/utils/translate';
import {
  INPUT_TYPE,
  getLanguageLabel,
  getCurrentLanguage,
} from '@/app/utils/helper';
import FormFields from '@/app/components/FormFields/FormFields';
import surveyFormDialogStyle from './surveyFormDialogStyle';
import {
  getFormDialog,
  changeData,
} from '@/app/components/FormDialog/FormDialog';
import { getFormValues, reset, untouch } from 'redux-form';
import {
  SurveyQuestion,
  SURVEY_STATUS,
  QuestionType,
  NewSurvey,
  ServerSurvey,
} from '@/app/redux/surveys';
import EnhancedTable from '../../EnhancedTable/EnhancedTable';
import { HeadData } from '../../EnhancedTable/EnhancedTableHead/EnhancedTableHead';
import QuestionsSortDialog from '../QuestionsSortDialog/QuestionsSortDialog';
import PropsMenu from '../../PropsMenu/PropsMenu';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import QuestionFormDialog from '../QuestionFormDialog/QuestionFormDialog';
import { ReducerState } from '@/app/redux/store';
import { LanguagesType } from '@/app/redux/languages';
import { getLoggedUserId } from '@/old/utils/helper';

const FORM_NAME = 'addEditSurveyForm';

type MapStateToProps = {
  currentValues: NewSurvey;
  languages: LanguagesType[];
  survey: ServerSurvey;
};

type MapDispatchToProps = {
  resetForm: () => void;
  untouch: (field: string) => void;
};

type OwnProps = {
  title: string;
  open: boolean;
  edit: boolean;
  onClose: () => void;
  onSubmit: (survey: NewSurvey) => void;
};

type PropsType = OwnProps &
  WithStyles<typeof surveyFormDialogStyle> &
  MapDispatchToProps &
  MapStateToProps;

const emptyForm = () => {
  const language = getCurrentLanguage();

  return {
    id: uuid4(),
    name: '',
    language,
    lang: {
      value: language,
      label: getLanguageLabel(language),
    },
    isPrivate: false,
    questions: [] as SurveyQuestion[],
    status: SURVEY_STATUS.DRAFT,
  };
};

type StateType = {
  formData: NewSurvey;
  list: SurveyQuestion[];
  sortOpen: boolean;
  questionOpen: boolean;
  questionType: QuestionType;
  questionLabel: string;
  question: SurveyQuestion;
  editQuestion: boolean;
};

class SurveyFormDialog extends React.Component<PropsType> {
  state: StateType = {
    formData: emptyForm(),
    list: [],
    sortOpen: false,
    questionOpen: false,
    questionType: null,
    questionLabel: '',
    question: null,
    editQuestion: false,
  };

  validate = (values: NewSurvey) => {
    let errors: { [k: string]: any } = {};
    const requiredFields = ['name', 'questions'];

    requiredFields.forEach(field => {
      if (
        !values[field] ||
        values[field] === '<p>&nbsp;</p>' ||
        (values[field] && values[field].length === 0)
      ) {
        errors[field] = translate.t('laThisRequired');
      }
    });

    if (values.name && values.name.length > 70) {
      errors.name = `${translate.t('laErrorMaxTextLen1')} 70`;
    }

    if (values.questions && values.questions.length === 0) {
      errors.questions = translate.t('laThisRequired');
    }

    return errors;
  };

  componentDidUpdate(prevProps: PropsType) {
    const { open, survey } = this.props;

    if (open && JSON.stringify(prevProps.survey) !== JSON.stringify(survey)) {
      this.loadSurvey(survey);
    } else if (open && open !== prevProps.open) {
      this.setFormValues();
    }
  }

  loadSurvey = (survey: ServerSurvey) => {
    const formData = {
      id: survey.id,
      name: survey.name,
      language: survey.language,
      lang: {
        value: survey.language,
        label: getLanguageLabel(survey.language),
      },
      isPrivate: survey.isPrivate,
      questions: survey.questions,
      status: survey.status,
    };

    this.setState(
      {
        formData,
        list: survey.questions,
      },
      () => this.setFormValues(),
    );
  };

  setFormValues = (skip?: boolean) => {
    const { formData } = this.state;
    Object.keys(formData).forEach(field => {
      changeData({ form: FORM_NAME, field, value: formData[field] });
    });

    if (!skip) {
      this.props.untouch('name');
    }
  };

  setEmptyState = () => {
    this.setState({
      formData: emptyForm(),
      list: [],
    });
  };

  handleClose = () => {
    const { onClose, resetForm } = this.props;

    this.setEmptyState();
    onClose();
    resetForm();
  };

  handleSubmit = (values: NewSurvey) => {
    const { onSubmit, resetForm } = this.props;
    this.setEmptyState();
    onSubmit(values);
    resetForm();
  };

  handleAddQuestion = (questionData: any) => {
    this.setState({
      questionOpen: true,
      questionType: questionData.value,
      questionLabel: questionData.label,
    });
  };

  setEmptyQuestion = () => {
    this.setState({
      questionOpen: false,
      questionType: null,
      questionLabel: '',
      question: null,
      editQuestion: false,
    });
  };

  handleQuestionClose = () => {
    this.setEmptyQuestion();
  };

  setQuestions = (list: SurveyQuestion[]) => {
    this.setState({ list });
    changeData({ form: FORM_NAME, field: 'questions', value: list });
  };

  handleQuestionSubmit = (question: SurveyQuestion) => {
    const { list: oldList, editQuestion } = this.state;
    let list = [] as SurveyQuestion[];

    if (editQuestion) {
      const index = oldList.findIndex(
        (s: SurveyQuestion) => s.id === question.id,
      );
      list = [...oldList];
      list[index] = { ...question };
    } else {
      const maxOrder = Math.max.apply(
        Math,
        oldList.map((el: any) => el.order),
      );
      const order = maxOrder > 0 ? maxOrder + 1 : 1;
      const value = { ...question, order, id: `temp_${order}`, index: order };
      list = [...oldList, value];
    }

    this.setEmptyQuestion();
    this.setQuestions(list);
  };

  handleEditQuestion = (selectedQuestion: SurveyQuestion[]) => (
    _event: React.MouseEvent,
  ) => {
    const question = selectedQuestion[0];
    this.setState({
      questionOpen: true,
      question,
      editQuestion: true,
      questionType: question.type,
      questionLabel: translate.t('laEdit'),
    });
  };

  handleDeleteQuestions = (selectedItems: SurveyQuestion[]) => (
    _event: React.MouseEvent,
  ) => {
    const { list: oldList } = this.state;
    const list = [...oldList];

    selectedItems.forEach((q: SurveyQuestion) => {
      const index = oldList.findIndex((c: SurveyQuestion) => c.id === q.id);
      list.splice(index, 1);
    });

    this.setQuestions(list);
  };

  handleSortQuestions = () => {
    this.setState({ sortOpen: true });
  };

  handleSortDialogClose = () => {
    this.setState({ sortOpen: false });
  };

  handleSortDialogSave = (list: SurveyQuestion[]) => {
    const { currentValues } = this.props;

    this.setState(
      {
        sortOpen: false,
        list,
        formData: {
          ...currentValues,
          questions: [...list],
        },
      },
      () => this.setFormValues(true),
    );
  };

  getQuestionList = () => [
    {
      label: translate.t(`label_${QuestionType.YES_NO}`),
      value: QuestionType.YES_NO,
    },
    {
      label: translate.t(`label_${QuestionType.SCALE}`),
      value: QuestionType.SCALE,
    },
    {
      label: translate.t(`label_${QuestionType.MULTIPLE_CHOICE}`),
      value: QuestionType.MULTIPLE_CHOICE,
    },
    {
      label: translate.t(`label_${QuestionType.FREE_TEXT}`),
      value: QuestionType.FREE_TEXT,
    },
  ];

  tableCustomTools = (formData: NewSurvey): React.ReactNode =>
    formData.status === SURVEY_STATUS.DRAFT ? (
      <PropsMenu
        onSelect={this.handleAddQuestion}
        data={this.getQuestionList()}
      >
        <Tooltip title={translate.t('laAdd')}>
          <IconButton color="primary">
            <AddCircleIcon />
          </IconButton>
        </Tooltip>
      </PropsMenu>
    ) : null;

  renderTopInputs = () => {
    const { languages, classes, edit: disabledLanguage, survey } = this.props;
    const disabledPrivate =
      survey && survey.owner.employeeId !== getLoggedUserId();

    const topInputs = [
      {
        type: INPUT_TYPE.TEXT,
        code: 'name',
        name: 'name',
        label: translate.t('label_survey_name'),
        order: 1,
        required: true,
      },
      {
        type: INPUT_TYPE.SELECT,
        code: 'lang',
        name: 'lang',
        label: translate.t('laLanguage'),
        childrenInputs: languages.map((l: LanguagesType) => ({
          label: l.name,
          value: l.code,
        })),
        order: 2,
        required: false,
        configs: {
          isSearchable: true,
          isDisabled: disabledLanguage,
          isCreatable: false,
        },
      },
      {
        type: INPUT_TYPE.CHECKBOX,
        code: 'isPrivate',
        name: 'isPrivate',
        label: translate.t('label_private'),
        order: 3,
        required: false,
        disabled: disabledPrivate,
      },
      {
        type: INPUT_TYPE.HIDDEN,
        code: 'questions',
        name: 'questions',
        label: translate.t('label_questions'),
        order: 4,
        required: true,
      },
    ];

    return topInputs.map(inpt => (
      <Grid
        item
        xs={12}
        sm={inpt.order === 1 ? 5 : inpt.order === 3 ? 2 : 3}
        key={inpt.order}
        className={`${classes.field} ${
          inpt.type === 'HIDDEN' ? classes.hidden : ''
        }`}
      >
        <FormFields inputs={[inpt]} />
      </Grid>
    ));
  };

  FormDialog = getFormDialog(FORM_NAME, this.validate);

  render() {
    const { title, open, classes } = this.props;
    const {
      formData,
      sortOpen,
      list,
      questionOpen,
      questionType,
      questionLabel,
      question,
    } = this.state;
    const { FormDialog } = this;
    const { status } = formData;

    const TOOLS = {
      showSort: true,
      showDelete:
        status === SURVEY_STATUS.DRAFT || status === SURVEY_STATUS.SCHEDULED,
      showEdit:
        status === SURVEY_STATUS.DRAFT || status === SURVEY_STATUS.SCHEDULED,
    };

    const headData: Array<HeadData> = [
      {
        id: 'text',
        type: 'string',
        disablePadding: false,
        label: translate.t('table_col_question'),
      },
      {
        id: 'typeLabel',
        type: 'string',
        disablePadding: false,
        label: translate.t('table_col_type'),
      },
    ];

    return (
      <FormDialog
        title={title}
        open={open}
        onClose={this.handleClose}
        formData={formData}
        paperWidthMd={classes.paperWidthMd}
        onSubmit={this.handleSubmit}
      >
        <Grid container className={classes.root} justify="space-between">
          {this.renderTopInputs()}

          <Grid container item xs={12}>
            <QuestionFormDialog
              title={translate.t('title_type_question', {
                type: questionLabel,
              })}
              open={questionOpen}
              data={question}
              questionType={questionType}
              onClose={this.handleQuestionClose}
              onSubmit={this.handleQuestionSubmit}
            />
            <QuestionsSortDialog
              title={`${translate.t('laSort')}`}
              questionList={list}
              open={sortOpen}
              onClose={this.handleSortDialogClose}
              onSave={this.handleSortDialogSave}
            />
            <EnhancedTable
              title={translate.t('title_questions')}
              tools={TOOLS}
              customTools={this.tableCustomTools(formData)}
              addHandler={this.handleAddQuestion}
              editHandler={this.handleEditQuestion}
              deleteHandler={this.handleDeleteQuestions}
              sortHandler={this.handleSortQuestions}
              headData={headData}
              data={list}
              order={'asc'}
              orderBy={'order'}
              pagination={false}
            />
          </Grid>
        </Grid>
      </FormDialog>
    );
  }
}

const mapStateToProps = (state: ReducerState) => {
  const { surveys, languages } = state;

  return {
    languages: languages.get('allLanguages'),
    currentValues: getFormValues(FORM_NAME)(state),
    survey: surveys.editSurvey,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetForm: () => dispatch(reset(FORM_NAME)),
  untouch: (field: string) => dispatch(untouch(FORM_NAME, field)),
});

const enhance = compose<any, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(surveyFormDialogStyle, { withTheme: true }),
);

export default enhance(SurveyFormDialog);

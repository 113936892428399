import * as React from 'react';
import { FormControl, withStyles } from '@material-ui/core';
import formFieldsStyle, { styles }  from '@/app/components/FormFields/formFieldsStyle';
import IntegrationReactSelect from '@/app/components/IntegrationReactSelect/IntegrationReactSelect';

const RenderHiddenField = (props: any) => {
  const {
    input,
    label,
    required,
    meta: { touched, dirty, error },
    children,
    helpText,
    classes,
    ...custom
  } = props;

  const hasError = (touched || dirty) && !!error;

  return (
    <FormControl
      style={styles.textField}
      className={classes.hiddenField}
      fullWidth
      margin="normal"
    >
      <IntegrationReactSelect
        {...input}
        error={hasError ? error : null}
        onChange={input.onChange}
        onBlur={() => input.onBlur(input.value)}
        children={children}
        required={required}
        fullWidth
        {...custom}
      />
      {helpText}
    </FormControl>
  );
};

export default withStyles(formFieldsStyle, {withTheme: true})(RenderHiddenField);

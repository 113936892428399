import * as React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  withStyles,
  Checkbox,
  Paper,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  WithStyles,
  Modal,
} from '@material-ui/core';
import { LanguagesType } from '@/app/redux/languages';
import { ReducerState } from '@/app/redux/store';
import {
  fetchAllCommonGoals,
  fetchAllTemplates,
  addTemplate,
  updateTemplate,
  deleteTemplate,
  changeTemplatesStatus,
  duplicateTemplate,
  addTemplateSection,
  updateTemplateSection,
  updateTemplateSections,
  deleteTemplateSection,
  addSectionComponent,
  updateSectionComponent,
  deleteSectionComponent,
  orderSectionComponents,
} from '@/app/redux/goals';
import Loading from '@/app/components/Loading/Loading';
import translate from '@/app/utils/translate';
import {
  openConfirmDialog,
  ConfirmDialogType,
} from '@/app/redux/confirmDialog';
import ConfigureUiLabelButton from '@/app/components/ConfigurableLabels/ConfigureUiLabelButton/ConfigureUiLabelButton';
import { sortArr } from '@/app/utils/helper';
import TopMenuToolBar from './TopMenuToolBar/TopMenuToolBar';
import SideRowMenu from './SideMenuToolBar/SideRowMenu';
import EditTemplate from './EditTemplate/EditTemplate';
import TranslateTemplate from './TranslateTemplate/TranslateTemplate';
import goalTemplatesStyle from './goalTemplatesStyle';
import TemplateHeader from '@/app/components/TemplateComponents/admin/TemplateHeader/TemplateHeader';
import TemplateTags from '@/app/components/TemplateComponents/admin/TemplateHeader/TemplateTags/TemplateTags';
import {
  Enum,
  fetchAllEnums,
  selectActiveEnumsInGroup,
} from '@/app/redux/enums';
import { OptionType } from '@/app/components/Trainings/types';
import { FormTemplate } from '@/app/components/TemplateComponents/types';
import { filterTemplatesByTags } from '@/app/components/TemplateComponents/helpers';

type MapStateToProps = {
  allGoalTemplates: [];
  languages: LanguagesType[];
  templateTags: Enum[];
};

type MapDispatchToProps = {
  fetchAllCommonGoals: () => any;
  fetchAllTemplates: () => any;
  addTemplate: () => any;
  updateTemplate: (templateId: string, template: any) => any;
  deleteTemplate: (templateIds: any[]) => any;
  changeTemplatesStatus: (templateIds: any[], newStatus: string) => any;
  duplicateTemplate: (templateId: string) => any;
  addTemplateSection: (templateId: string, section: any) => any;
  updateTemplateSection: (templateId: string, section: any) => any;
  updateTemplateSections: (templateId: string, sections: any) => any;
  deleteTemplateSection: (templateId: string, sectionId: string) => any;
  addSectionComponent: (
    templateId: string,
    sectionId: string,
    component: {},
  ) => any;
  updateSectionComponent: (
    templateId: string,
    sectionId: string,
    component: any,
  ) => any;
  deleteSectionComponent: (
    templateId: string,
    sectionId: string,
    componentIds: any[],
  ) => any;
  orderSectionComponents: (
    templateId: string,
    sectionId: string,
    components: any,
  ) => any;
  openConfirmDialog: (payload: ConfirmDialogType) => void;
  fetchAllEnums: () => void;
};

type PropsType = MapStateToProps &
  MapDispatchToProps &
  WithStyles<typeof goalTemplatesStyle>;

type StateType = {
  allCheckboxesSelected: boolean;
  selectedCheckboxes: any[];
  rowCount: number;
  displayedOrder: any;
  orderBy: string;
  isLoading: boolean;
  openAddModal: boolean;
  openEditModal: boolean;
  templateBeingEdited: any;
  openTranslateModal: boolean;
  templateBeingTranslated?: string;
  warningMessage: boolean;
  tags: OptionType[];
};

const statusTranslation = {
  ACTIVE: 'label_ACTIVE',
  INACTIVE: 'label_inactive',
};

class GoalTemplates extends React.PureComponent<PropsType> {
  state: StateType = {
    allCheckboxesSelected: false,
    selectedCheckboxes: [],
    rowCount: -1,
    displayedOrder: {
      subject: 'desc',
      status: 'desc',
      updatedTime: 'desc',
    },
    orderBy: 'updatedTime',
    isLoading: false,
    openAddModal: false,
    openEditModal: false,
    templateBeingEdited: {},
    openTranslateModal: false,
    templateBeingTranslated: undefined,
    warningMessage: false,
    tags: [],
  };

  componentDidMount() {
    this.setState({ isLoading: true });

    // Update rows count
    this.setState({ rowCount: this.props.allGoalTemplates.length });

    Promise.all([
      this.props.fetchAllCommonGoals(),
      this.props.fetchAllTemplates(),
    ]).then(() => {
      this.setState({ isLoading: false });
    });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // Update rows count
    if (
      prevProps.allGoalTemplates.length !== this.props.allGoalTemplates.length
    ) {
      this.setState({ rowCount: this.props.allGoalTemplates.length });
    }

    // Reset selection on template delete/create
    if (
      this.props.allGoalTemplates.length !== prevState.rowCount &&
      prevState.rowCount !== -1
    ) {
      this.setState({
        selectedCheckboxes: [],
        templateBeingEdited: {},
        allCheckboxesSelected: false,
      });
    }

    // Make sure to update templateBeingEdited when the store updates
    if (
      prevProps.allGoalTemplates !== this.props.allGoalTemplates &&
      this.state.openEditModal &&
      this.state.templateBeingEdited.id
    ) {
      const templateBeingEdited = this.props.allGoalTemplates.find(
        (template: any) => {
          return this.state.templateBeingEdited.id === template.id;
        },
      );

      if (
        templateBeingEdited &&
        templateBeingEdited !== this.state.templateBeingEdited
      ) {
        this.setState({ templateBeingEdited });
      }
    }
  }

  onSelectTemplateClick = (id: string) => {
    const nextSelectedRows = this.state.selectedCheckboxes.includes(id)
      ? this.state.selectedCheckboxes.filter(row => row !== id)
      : [...this.state.selectedCheckboxes, id];

    this.setState({
      selectedCheckboxes: nextSelectedRows,
      allCheckboxesSelected: nextSelectedRows.length === this.state.rowCount,
    });
  };

  onSelectAllTemplatesClick = () => {
    this.setState({
      allCheckboxesSelected: !this.state.allCheckboxesSelected,
      selectedCheckboxes: !this.state.allCheckboxesSelected
        ? this.props.allGoalTemplates.map((template: any) => template.id)
        : [],
    });
  };

  changeTags = (e: any) => {
    this.setState({ tags: e });
  };

  sortTemplatesBy = (orderBy: string) => {
    const reversedOrder =
      this.state.displayedOrder[orderBy] === 'asc' ? 'desc' : 'asc';

    this.setState({
      displayedOrder: {
        ...this.state.displayedOrder,
        [orderBy]: reversedOrder,
      },
      orderBy,
    });
  };

  handleAddModalShow = () => {
    this.setState({ openAddModal: true });
  };

  handleAddModalClose = () => {
    this.setState({ openAddModal: false });
  };

  handleEditTemplate = (selectedTemplateIds?: any[]) => {
    const [firstSelectedTemplateId] =
      selectedTemplateIds || this.state.selectedCheckboxes;
    const templateBeingEdited: any = this.props.allGoalTemplates.find(
      (template: any) => {
        return template.id === firstSelectedTemplateId;
      },
    );

    this.setState({
      warningMessage: templateBeingEdited.status === 'ACTIVE',
      templateBeingEdited: templateBeingEdited,
      openEditModal: true,
    });
  };

  handleEditModalClose = () => {
    this.setState({ templateBeingEdited: {}, openEditModal: false });
  };

  handleTranslateTemplate = (templateId: string) => {
    this.setState({
      openTranslateModal: true,
      templateBeingTranslated: templateId,
    });
  };

  handleTranslateModalClose = () => {
    this.setState({
      openTranslateModal: false,
      templateBeingTranslated: undefined,
    });
  };

  deleteTemplate = (templateIds: []) => {
    this.props.openConfirmDialog({
      text: translate.t('template_delete_warning'),
      onOk: () => this.props.deleteTemplate(templateIds),
    });
  };

  changeTemplateStatus = (templateIds: [], newStatus: string) => {
    this.props.changeTemplatesStatus(templateIds, newStatus);
  };

  duplicateTemplate = (templateId: string) => {
    this.props.duplicateTemplate(templateId);
  };

  deleteTemplateSection = (templateId: string, sectionId: string) => {
    this.props.openConfirmDialog({
      text: translate.t('confirm_delete_item_s'),
      onOk: () => this.props.deleteTemplateSection(templateId, sectionId),
    });
  };

  deleteSectionComponent = (
    templateId: string,
    sectionId: string,
    componentIds: string[],
  ) => {
    this.props.openConfirmDialog({
      text: translate.t('confirm_delete_item_s'),
      onOk: () =>
        this.props.deleteSectionComponent(templateId, sectionId, componentIds),
    });
  };

  getMatchingTemplates = (): FormTemplate[] => {
    const { allGoalTemplates } = this.props;
    const { displayedOrder, orderBy, tags } = this.state;
    const tagCodes = tags.map(tt => `${tt.value}`);
    const filteredTemplates = filterTemplatesByTags(allGoalTemplates, tagCodes);

    return sortArr(filteredTemplates, orderBy, displayedOrder[orderBy]);
  };

  render() {
    const {
      classes,
      languages,
      templateTags,
      fetchAllEnums: handleUpdateEnum,
    } = this.props;

    const {
      allCheckboxesSelected,
      selectedCheckboxes,
      displayedOrder,
      orderBy,
      isLoading,
      templateBeingEdited,
      openAddModal,
      openEditModal,
      openTranslateModal,
      templateBeingTranslated,
      warningMessage,
      tags,
    } = this.state;

    const filteredGoalTemplates = this.getMatchingTemplates();

    return (
      <>
        <Paper className={classes.root}>
          <Typography variant="h6" className={classes.sectionTitle}>
            <ConfigureUiLabelButton
              term={'laGoalEvaluation'}
              fetchAllEnums={handleUpdateEnum}
            />

            {selectedCheckboxes.length > 0 && (
              <span className={classes.subHeader}>
                {translate.t('x_selected', {
                  count: selectedCheckboxes.length,
                })}
              </span>
            )}

            <TopMenuToolBar
              selectedTemplateIds={selectedCheckboxes}
              addTemplate={this.handleAddModalShow}
              changeTemplateStatus={this.changeTemplateStatus}
              deleteTemplate={this.deleteTemplate}
              duplicateTemplate={this.duplicateTemplate}
              translateTemplate={this.handleTranslateTemplate}
              handleEditTemplate={this.handleEditTemplate}
              languages={languages}
            />
          </Typography>
          <div>
            <TemplateTags
              value={tags}
              tagOptions={templateTags}
              onChange={this.changeTags}
            />
          </div>
          {!isLoading && filteredGoalTemplates.length > 0 ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      indeterminate={
                        !allCheckboxesSelected && selectedCheckboxes.length > 0
                      }
                      checked={allCheckboxesSelected}
                      onChange={this.onSelectAllTemplatesClick}
                      className={classes.topCheckbox}
                    />

                    <TableSortLabel
                      classes={{
                        icon:
                          orderBy === 'subject'
                            ? classes.activeSortIcon
                            : classes.inactiveSortIcon,
                      }}
                      active={orderBy === 'subject'}
                      direction={displayedOrder.subject}
                      onClick={() => this.sortTemplatesBy('subject')}
                    >
                      {translate.t('laSubject')}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell align="left">
                    <TableSortLabel
                      classes={{
                        icon:
                          orderBy === 'status'
                            ? classes.activeSortIcon
                            : classes.inactiveSortIcon,
                      }}
                      active={orderBy === 'status'}
                      direction={displayedOrder.status}
                      onClick={() => this.sortTemplatesBy('status')}
                    >
                      {translate.t('laStatus')}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell align="left">
                    <TableSortLabel
                      classes={{
                        icon:
                          orderBy === 'updatedTime'
                            ? classes.activeSortIcon
                            : classes.inactiveSortIcon,
                      }}
                      active={orderBy === 'updatedTime'}
                      direction={displayedOrder.updatedTime}
                      onClick={() => this.sortTemplatesBy('updatedTime')}
                    >
                      {translate.t('laLastEdited')}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredGoalTemplates.map((template: any) => {
                  return (
                    <TableRow key={template.id} hover role="checkbox">
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={() =>
                            this.onSelectTemplateClick(template.id)
                          }
                          checked={selectedCheckboxes.includes(template.id)}
                        />

                        {template.subject}
                      </TableCell>

                      <TableCell>
                        {translate
                          .t(statusTranslation[template.status])
                          .toUpperCase()}
                      </TableCell>

                      <TableCell className={classes.capitalize}>
                        {template.updatedTime.substring(0, 10)}
                        {', '}
                        {template.updatedBy.username.replace('.', ' ')}
                      </TableCell>

                      <TableCell align="right">
                        <SideRowMenu
                          template={template}
                          tools={{
                            showActive: true,
                            showTranslate: true,
                            showDuplicate: true,
                          }}
                          changeTemplateStatus={this.changeTemplateStatus}
                          deleteTemplate={this.deleteTemplate}
                          duplicateTemplate={this.duplicateTemplate}
                          translateTemplate={this.handleTranslateTemplate}
                          handleEditTemplate={this.handleEditTemplate}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) : isLoading ? (
            <Loading />
          ) : (
            <Typography variant="h6">
              {translate.t('laNoTemplatesAvailable')}
            </Typography>
          )}
        </Paper>

        <Modal open={openAddModal} onClose={this.handleAddModalClose}>
          <TemplateHeader
            languages={languages}
            handleModalClose={this.handleAddModalClose}
            addTemplate={this.props.addTemplate}
            showTags={true}
            templateTags={templateTags}
          />
        </Modal>

        <EditTemplate
          template={templateBeingEdited}
          openEditModal={openEditModal}
          handleModalClose={this.handleEditModalClose}
          updateTemplate={this.props.updateTemplate}
          addTemplateSection={this.props.addTemplateSection}
          updateTemplateSection={this.props.updateTemplateSection}
          updateTemplateSections={this.props.updateTemplateSections}
          deleteTemplateSection={this.deleteTemplateSection}
          addSectionComponent={this.props.addSectionComponent}
          updateSectionComponent={this.props.updateSectionComponent}
          deleteSectionComponent={this.deleteSectionComponent}
          orderSectionComponents={this.props.orderSectionComponents}
          selectedCheckboxes={selectedCheckboxes}
          warningMessage={warningMessage}
          languages={languages}
        />

        <Modal
          open={openTranslateModal}
          onClose={this.handleTranslateModalClose}
        >
          <TranslateTemplate
            templateId={templateBeingTranslated}
            handleModalClose={this.handleTranslateModalClose}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: ReducerState) => {
  return {
    allGoalTemplates: state.goals.allGoalTemplates,
    languages: state.languages.get('allLanguages'),
    templateTags: selectActiveEnumsInGroup(state, 'GOALS_TEMPLATE_TAGS'),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAllCommonGoals: () => dispatch<any>(fetchAllCommonGoals()),
  fetchAllTemplates: () => dispatch<any>(fetchAllTemplates()),
  addTemplate: (template: any) => dispatch<any>(addTemplate(template)),
  updateTemplate: (templateId: string, template: any) =>
    dispatch<any>(updateTemplate(templateId, template)),
  deleteTemplate: (templateIds: []) =>
    dispatch<any>(deleteTemplate(templateIds)),
  changeTemplatesStatus: (templateIds: [], newStatus: string) =>
    dispatch<any>(changeTemplatesStatus(templateIds, newStatus)),
  duplicateTemplate: (templateId: string) =>
    dispatch<any>(duplicateTemplate(templateId)),
  addTemplateSection: (templateId: string, section: any) =>
    dispatch<any>(addTemplateSection(templateId, section)),
  updateTemplateSection: (templateId: string, section: any) =>
    dispatch<any>(updateTemplateSection(templateId, section)),
  updateTemplateSections: (templateId: string, sections: any) =>
    dispatch<any>(updateTemplateSections(templateId, sections)),
  deleteTemplateSection: (templateId: string, sectionId: string) =>
    dispatch<any>(deleteTemplateSection(templateId, sectionId)),
  addSectionComponent: (templateId: string, sectionId: string, component: {}) =>
    dispatch<any>(addSectionComponent(templateId, sectionId, component)),
  updateSectionComponent: (
    templateId: string,
    sectionId: string,
    component: any,
  ) => dispatch<any>(updateSectionComponent(templateId, sectionId, component)),
  deleteSectionComponent: (
    templateId: string,
    sectionId: string,
    componentIds: [],
  ) =>
    dispatch<any>(deleteSectionComponent(templateId, sectionId, componentIds)),
  orderSectionComponents: (
    templateId: string,
    sectionId: string,
    components: any,
  ) => dispatch<any>(orderSectionComponents(templateId, sectionId, components)),
  openConfirmDialog: (payload: ConfirmDialogType) =>
    dispatch(openConfirmDialog(payload)),
  fetchAllEnums: () => dispatch<any>(fetchAllEnums()),
});

const enhance = compose<any, any>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(goalTemplatesStyle),
);

export default enhance(GoalTemplates);

import {
  AmountCell,
  AvatarCell,
  EmpNoCell,
  FirstNameCell,
  LastNameCell,
  OrgNoCell,
  OrgUnitCell,
  ScheduledDateCell,
  StatusApprovedCell,
  TypeCell,
  UpdatedDateCell,
  WorkflowInfoCell,
  WorkflowStatusCell,
} from '@/app/components/WorkflowsReport/components/KendoCells';
import translate from '@/app/utils/translate';
import { ColumnConfig } from '@/old/utils/helper';

export const rewardingGridColumns = (): ColumnConfig[] => [
  {
    cell: AvatarCell,
    field: 'empId',
    show: true,
    title: translate.t('laPicture'),
    groupable: false,
  },
  {
    cell: FirstNameCell,
    field: 'firstName',
    show: true,
    title: translate.t('laFirstName'),
  },
  {
    cell: LastNameCell,
    field: 'lastName',
    show: true,
    title: translate.t('laLastName'),
  },
  {
    cell: EmpNoCell,
    field: 'empNo',
    show: true,
    title: translate.t('laEmpId'),
  },
  {
    cell: OrgUnitCell,
    field: 'unitId',
    show: true,
    title: translate.t('laOrgUnit'),
  },
  {
    field: 'unit',
    show: true,
    title: translate.t('laUnit'),
  },
  {
    field: 'managerId',
    show: true,
    title: translate.t('laManager'),
  },
  {
    cell: OrgNoCell,
    field: 'unitNo',
    show: true,
    title: translate.t('laUnitNumber'),
  },
  {
    cell: TypeCell,
    field: 'type',
    show: true,
    title: translate.t('laType'),
  },
  {
    cell: AmountCell,
    field: 'amount',
    show: true,
    filter: 'numeric',
    title: translate.t('laAmount'),
  },
  {
    cell: WorkflowStatusCell,
    field: 'status',
    show: true,
    title: translate.t('laStatus'),
  },
  {
    cell: StatusApprovedCell,
    field: 'approvedBy',
    show: true,
    title: translate.t('laApproved'),
  },
  {
    cell: UpdatedDateCell,
    field: 'updatedDate',
    show: true,
    title: translate.t('laUpdated'),
  },
  {
    cell: ScheduledDateCell,
    field: 'scheduledDate',
    show: true,
    title: translate.t('laScheduledDate'),
  },
  {
    cell: WorkflowInfoCell,
    field: 'info',
    show: true,
    title: translate.t('laInfo'),
    groupable: false,
  },
];

export type DueDaysAutomation = 'NONE' | 'ON' | 'BEFORE' | 'AFTER';
export type EmployeeAutomation =
  'NONE' |
  'SELECTED_EMP' |
  'SELECTED_EMP_MANAGER' |
  'SELECTED_EMP_HR_RESP' |
  'LOGGED_IN_USER' |
  'LOGGED_IN_USER_MANAGER' |
  'LOGGED_IN_USER_HR_RESP'
;
export const getEmployeeAutomationKeys = () => {
  return [
    'NONE',
    'SELECTED_EMP',
    'SELECTED_EMP_MANAGER',
    'SELECTED_EMP_HR_RESP',
    'LOGGED_IN_USER',
    'LOGGED_IN_USER_MANAGER',
    'LOGGED_IN_USER_HR_RESP'
  ];
};

export type DueDateAutomationSelection =
  'NONE' |
  'AUTO' |
  'COMPL_DATE' |
  'MANUAL'
;
export type EmployeeAutomationSelection =
  'NONE' |
  'AUTO' |
  'LOGGED_IN_USER' |
  'LOGGED_IN_USER_MANAGER' |
  'LOGGED_IN_USER_HR_RESP' |
  'SELECTED_EMP' |
  'SELECTED_EMP_MANAGER' |
  'SELECTED_EMP_HR_RESP'
;
export interface IAutomationSelection {
  dueDate: DueDateAutomationSelection;
  dueDateAutomationType: DueDaysAutomation;
  dueDateAutomationDays: number;
  assignee: EmployeeAutomationSelection;
  assigneeAutomation: EmployeeAutomation;
  pertainingTo: EmployeeAutomationSelection;
  pertainingToAutomation: EmployeeAutomation;
  copyAttachment: boolean;
}

export interface IWorklistTask {
  id: number;
  worklistId: number;
  title: string;
  description: string;
  type: string;
  dueDateAutomationDays?: number;
  dueDateAutomationType?: DueDaysAutomation;
  assigneeAutomation?: EmployeeAutomation;
  pertainingToAutomation?: EmployeeAutomation;
  visibleToPertainingTo: boolean;
  attachmentId?: number;
  attachmentName?: string;
  newAttachment?: File;
}

export interface IWorklist {
  id: number;
  title: string;
  description?: string;
  active: boolean;
  tasks?: IWorklistTask[];
}

export interface ICreateTasksFromWorklist {
  worklistId: number;
  title: string;
  completionDate: Date;
  tasks: ITask[];
}

export interface ITask {
  id: number;
  title: string;
  description?: string;
  type: string;
  status: string;
  startDate?: Date;
  dueDate?: Date;
  assigneeId?: number;
  pertainingToId?: number;
  visibleToPertainingTo: boolean;
  attachmentId: number;
  attachmentName: string;
  automationSelection?: IAutomationSelection;
}

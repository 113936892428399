import APIs from '@/app/api/externalAPIs';
import { getStored, setStored } from '@/app/utils/helper';
import { isNewDeploymentType } from '@/app/utils/isNewDeployment';
import Service from '@/app/utils/service';
import { getLoggedUserData, getLoggedUserId } from '@/old/utils/helper';
import translate from '@/app/utils/translate';
import { v4 as uuid4 } from 'uuid';

enum UserActivityTarget {
  EMPLOYEE = 'employee',
  UNIT = 'unit',
  COMPANY = 'company',
  OTHER = 'other',
}

enum UserActivityAction {
  READ = 'read',
  WRITE = 'write',
}

enum ActivityEvents {
  ROUTE_CHANGE = 'ROUTE_CHANGE',
  TAB_CHANGE = 'TAB_CHANGE',
}

type UserActivityLogMetadata = {
  [key: string]: string | number | boolean | undefined; 
};

type UserActivityPersonalMetadata = {
  userId: string;
  language: string;
};

type UserActivityLogData = {
  type: UserActivityAction;
  target: UserActivityTarget;
  targetId: string;
  resourceName: string;
} & UserActivityLogMetadata;

type UserActivityLogItem = {
  data: UserActivityLogData;
  timestamp: number;
  order: number;
};

type ActivityTrackingBatch = {
  requestId: string;
  events: UserActivityLogItem[];
};

export const ActivityLogging = ((isNewDeployment) => {
  const STORAGE_KEY = '__activity';
  const UPDATE_INTERVAL = 30000;
  // QUICKFIX: 300 events is too large of a payload to API endpoint, using 250 as ACTIVITY_STORAGE_COUNT_LIMIT
  // TODO: Change submitting events to be handled in series of smaller chunks the API endpoint can handle,
  //  and increase ACTIVITY_STORAGE_COUNT_LIMIT back to 5000 so events wont be forgotten so easily
  const ACTIVITY_STORAGE_COUNT_LIMIT = 250;
  let userActivities: ActivityTrackingBatch = {
    requestId: uuid4(),
    events: [],
  };

  const filterEmptyFields = (obj: Object) => ({
    ...Object.keys(obj).filter(k => !!obj[k]).reduce((acc, k) => ({
      ...acc,
      [k]: obj[k]
    }), {}),
  });

  // MONKEY PATCH history.pushState in order to hook into navigation events
  // and dispatch custom event for logging user activities
  const patchPushState = () => {
    const originalPushState = history.pushState;
    history.pushState = function() {
      window.dispatchEvent(new Event(ActivityEvents.ROUTE_CHANGE));
      originalPushState.apply(history, arguments);
    };
  };

  const dispatchTabChangeEvent = () => {
    window.dispatchEvent(new Event(ActivityEvents.TAB_CHANGE));
  };

  const trackUserActivityEvents = (userMetadata: UserActivityPersonalMetadata) => {
    const appendUserActivityItem = function() {
      pushNewUserAction(createPageReadActivityItem(userMetadata));
    };

    window.addEventListener(
      ActivityEvents.ROUTE_CHANGE,
      appendUserActivityItem,
    false);

    window.addEventListener(
      ActivityEvents.TAB_CHANGE,
      appendUserActivityItem,
      false
    );

    window.addEventListener('popstate', appendUserActivityItem, false);
    window.addEventListener('pushstate', appendUserActivityItem, false);
    window.addEventListener('hashchange', appendUserActivityItem, false);

    window.addEventListener('beforeunload', function () {
      cacheCurrentActivities();
      window.removeEventListener(
        ActivityEvents.ROUTE_CHANGE,
        appendUserActivityItem
      );
      window.removeEventListener(
        ActivityEvents.TAB_CHANGE,
        appendUserActivityItem
      );

      window.removeEventListener('popstate', appendUserActivityItem);
      window.removeEventListener('pushstate', appendUserActivityItem);
      window.removeEventListener('hashchange', appendUserActivityItem);
    });
  };

  const pushNewUserAction = (newActivity: UserActivityLogItem) => {
    if (userActivities.events.length >= ACTIVITY_STORAGE_COUNT_LIMIT) {
      // Forget all events over the count limit, retain newer & forget older
      userActivities.events.splice(0, userActivities.events.length - ACTIVITY_STORAGE_COUNT_LIMIT);
    }

    userActivities.events.push(newActivity);
  };

  const resetUserActions = () => {
    userActivities = {
      requestId: uuid4(),
      events: [],
    };
  };

  const getPageFromUrlPath = (urlPath: string) => {
    return urlPath.slice(0, urlPath.indexOf('.')).replace('/page-', '');
  };

  const cacheCurrentActivities = () => {
    setStored(STORAGE_KEY, userActivities);
  };

  const getTargetByUrlPath = (urlPath: string) => {
    const page = getPageFromUrlPath(urlPath);

    switch (page) {
      case 'people': return UserActivityTarget.EMPLOYEE;
      case 'personalEmp': return UserActivityTarget.EMPLOYEE;
      case 'rewarding': return UserActivityTarget.EMPLOYEE;
      case 'performance': return UserActivityTarget.EMPLOYEE;
      case 'development': return UserActivityTarget.EMPLOYEE;
      case 'analytics': return UserActivityTarget.COMPANY;
      case 'collaboration': return UserActivityTarget.COMPANY;
      case 'organization': return UserActivityTarget.UNIT;
      case 'recruiting': return UserActivityTarget.COMPANY;
      case 'planning': return UserActivityTarget.COMPANY;
      case 'mgrworkspace': return UserActivityTarget.COMPANY;
      case 'tutorial': return UserActivityTarget.OTHER;
      case 'newEmp': return UserActivityTarget.COMPANY;
      case 'newEmpFormTemplate': return UserActivityTarget.COMPANY;
      case 'admin': return UserActivityTarget.COMPANY;
      case 'earlyInvolvement': return UserActivityTarget.EMPLOYEE;
      case 'notification': return UserActivityTarget.COMPANY;
      case 'helpCenter': return UserActivityTarget.OTHER;
      case 'userSetting': return UserActivityTarget.OTHER;
      default: return UserActivityTarget.OTHER;
    }
  };

  const getTargetIdByUrl = (urlPath: string, urlParams: string) => {
    const page = getPageFromUrlPath(urlPath);
    const params = new URLSearchParams(urlParams);
    const { oTreeNode } = window as any;

    switch (page) {
      case 'people': return params.get('emp');
      case 'rewarding': return params.get('emp');
      case 'performance': return params.get('emp');
      case 'development': return params.get('emp');
      case 'analytics': return '';
      case 'collaboration': return '';
      case 'organization': return oTreeNode.id;
      case 'recruiting': return '';
      case 'planning': return '';
      case 'mgrworkspace': return '';
      case 'tutorial': return '';
      case 'newEmp': return params.get('pendingEmp');
      case 'newEmpFormTemplate': return params.get('pendingEmp');
      case 'admin': return '';
      case 'earlyInvolvement': return params.get('emp');
      case 'notification': return '';
      case 'helpCenter': return '';
      case 'userSetting': return '';
      default: return params.get('emp');
    }
  };

  const getResourceNameByTabId = () => {
    const activeTab = document
      .querySelector('#idContent .sfTabs .ui-tabs-nav > li.ui-tabs-active em');

    if (!activeTab) {
      return '';
    }

    const translationKey = Object.values(activeTab.classList)
      .find((classItem) => classItem.indexOf('la') === 0);

    return translationKey ? translate.getLangMessage('en', translationKey) : '';
  };

  const createActivityItem = (
    interval: number,
  ) => (type: UserActivityAction) => (
    target: UserActivityTarget,
    targetId: string,
    resourceName: string,
    order: number,
    metadata: UserActivityLogMetadata,
  ) => ({
    data: {
      type,
      target,
      targetId,
      resourceName,
      interval,
      ...filterEmptyFields(metadata),
    },
    timestamp: (new Date()).getTime(),
    order,
  });

  const createReadActivityItemWithMetadata = createActivityItem(
    UPDATE_INTERVAL,
  )(UserActivityAction.READ);

  const createPageReadActivityItem = (
    userMetadata: UserActivityPersonalMetadata
  ) => {
    const target = getTargetByUrlPath(window.location.pathname);
    const resourceName = getResourceNameByTabId();
    const targetId = getTargetIdByUrl(
      window.location.pathname,
      window.location.search
    );

    return createReadActivityItemWithMetadata(
      target,
      targetId || '',
      resourceName,
      0,
      {
        url: window.location.href,
        userAgent: window.navigator ? window.navigator.userAgent : '',
        browserLanguage: window.navigator ? window.navigator.language : '',
        browserWindowWidth: window.screen ? window.screen.width : '',
        browserWindowHeight: window.screen ? window.screen.height : '',
        ...userMetadata,
      }
    );
  };

  const postUserActivities = (currentActivities: ActivityTrackingBatch) => {
    const userActivitiesWithOrder = {
      ...currentActivities,
      events: currentActivities.events.map((currentActivity, index) => ({
        ...currentActivity,
        order: index + 1,
      })),
    };

    Service.post(
      APIs.userActivityLogging,
      userActivitiesWithOrder,
      () => { resetUserActions(); },
      () => { cacheCurrentActivities(); },
    );
  };

  const initLogger = (isLoggingEnabled?: boolean) => {
    if (!isNewDeployment || !isLoggingEnabled) {
      return;
    }

    const loginUserData = getLoggedUserData();
    const userMetadata = {
      userId: `${loginUserData.fLoginEmpId}`,
      language: loginUserData.fLoginLanguage,
    }; 
    patchPushState();
    pushNewUserAction(createPageReadActivityItem(userMetadata));

    const prevActivities = getStored(STORAGE_KEY);
    if (prevActivities) {
      userActivities = prevActivities;
      localStorage.removeItem(STORAGE_KEY);
    }

    const interval: ReturnType<typeof setInterval> = setInterval(() => {
      if (!getLoggedUserId()) {
        return clearInterval(interval);
      }

      pushNewUserAction(createPageReadActivityItem(userMetadata));
      postUserActivities(userActivities);
    }, UPDATE_INTERVAL);

    trackUserActivityEvents(userMetadata);
  };

  return {
    initLogger,
    dispatchTabChangeEvent,
  };
})(isNewDeploymentType());

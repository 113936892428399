import { StyleRulesCallback } from '@material-ui/core';

export type CustomFieldStyleKeys =
  | 'textInput'
  | 'textarea'
  | 'clearableInputContainer'
  | 'clearButton'
  | 'fullWidth'
  | 'marginTopText'
  | 'marginTopOther'
  | 'error'
  | 'exceededLengthLimit';

export const customFieldStyles: StyleRulesCallback<CustomFieldStyleKeys> = theme => ({
  textInput: {
    '& input': {
      padding: theme.spacing.unit,
    },
  },
  textarea: {
    overflow: 'auto',
    resize: 'vertical',
    minHeight: 32,
    maxHeight: 100,
  },
  clearableInputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  clearButton: {
    marginLeft: theme.spacing.unit,

    '& svg': {
      fontSize: 20,
    },
  },
  fullWidth: {
    width: '100%',
  },
  marginTopText: {
    marginTop: 24,
  },
  marginTopOther: {
    marginTop: 32,
  },
  error: {
    border: `1px solid ${theme.palette.error.main}`,

    '&:focus': {
      outline: 'none',
      boxShadow: `0 0 4px ${theme.palette.error.main}`,
    },
  },
  exceededLengthLimit: {
    color: theme.palette.error.main,
  },
});

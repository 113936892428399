import React from 'react';
import {
  Paper,
  Icon,
  WithStyles,
  StyleRulesCallback,
  withStyles,
  Theme,
} from '@material-ui/core';
import translate from '@/app/utils/translate';
import { Async as SelectAsync } from 'react-select';
import { OptionType } from '@/app/components/Trainings/types';

const styles: StyleRulesCallback = (theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.grey[300],
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    paddingLeft: theme.spacing.unit / 2,
  },
  input: {
    flex: 1,
    paddingLeft: theme.spacing.unit,
  },
});

type OwnProps = {
  inputValue: string;
  value: OptionType;
  optionsData: [];
  onInputChange: (newInputValue: string) => void;
  onChange: (optionId: number) => void;
  filterFunction: Function;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const SearchField = ({
  classes,
  inputValue,
  value,
  optionsData,
  onInputChange,
  onChange,
  filterFunction,
}: Props) => {
  const handleChange = (option: OptionType) => {
    if (!!option) {
      onChange(option.value as number);
    } else {
      onChange(null);
    }
  };

  return (
    <Paper
      className={classes.root}
      elevation={1}
      data-testid="autocomplete-add-training-participant"
    >
      <Icon className={classes.icon}>search</Icon>
      <SelectAsync
        className={classes.input}
        styles={{
          control: provided => ({
            ...provided,
            backgroundColor: 'inherit',
            border: 'none',
            '&:hover': { border: 'none', boxShadow: 'none' },
          }),
        }}
        isClearable={true}
        placeholder={translate.t('laSearch')}
        loadOptions={(searchTerm: string): Promise<any> => {
          if (searchTerm.length < 3) {
            return Promise.resolve([]);
          }
          const searchTermLC = searchTerm.toLocaleLowerCase();
          return Promise.resolve(
            filterFunction(optionsData, searchTermLC, ['ACTIVE']),
          );
        }}
        options={[]}
        noOptionsMessage={() =>
          translate.t('label_type_at_least_n_chars', { count: 3 })
        }
        inputValue={inputValue}
        value={value}
        onInputChange={onInputChange}
        onChange={handleChange}
      />
    </Paper>
  );
};

export default withStyles(styles)(SearchField);

import { Map as iMap } from 'immutable';

export const OPEN_DIALOG = '@@solaforce/confirmDialog/OPEN_DIALOG';
export const CLOSE_DIALOG = '@@solaforce/confirmDialog/CLOSE_DIALOG';
export const SET_OPEN_DIALOG = '@@solaforce/confirmDialog/SET_OPEN_DIALOG';

export type ConfirmDialogType = {
  text: string,
  onOk: Function,
  onClose?: Function,
  customOk?: string,
  customClose?: string,
};

const initialState = iMap({
  shouldOpen: false,
  open: false,
  text: '',
  onOk: Function,
  onClose: Function,
  customOk: '',
  customClose: '',
});

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_OPEN_DIALOG:
      return state.set('shouldOpen', action.payload);
    case OPEN_DIALOG: {
      const {
        text, onOk, onClose = new Function, customOk, customClose,
      } = action.payload;

      return state
        .set('text', text)
        .set('open', true)
        .set('onOk', onOk)
        .set('onClose', onClose)
        .set('customOk', customOk)
        .set('customClose', customClose);
      }
    case CLOSE_DIALOG:
      return state
        .set('open', false);
    default:
      return state;
  }
};

export const openConfirmDialog = (payload: ConfirmDialogType) => ({ type: OPEN_DIALOG, payload });
export const closeConfirmDialog = () => ({ type: CLOSE_DIALOG });
export const setOpenConfirmDialog = (payload: boolean = true) => ({ type: SET_OPEN_DIALOG, payload });

export default reducer;

import { FormData } from './DataRetention';
import { DataRetentionSetting } from './types';

export const mapDataRetention = (data: DataRetentionSetting[]) =>
  data.reduce(
    (o, dataRetention) =>
      Object.assign(o, {
        [`${dataRetention.fComDataRetModule}_checkbox`]: dataRetention.fComDataRetIsAutomatic,
        [`${dataRetention.fComDataRetModule}_select`]:
          dataRetention.fComDataRetTimeline || -1,
      }),
    {},
  );

export const mapSubmitData = (
  formData: FormData,
  defaultData: DataRetentionSetting[],
) =>
  defaultData.map(dataRetention => ({
    fComDataRetModule: dataRetention.fComDataRetModule,
    fComDataRetIsAutomatic:
      formData[`${dataRetention.fComDataRetModule}_checkbox`],
    ...(formData[`${dataRetention.fComDataRetModule}_select`] !== -1
      ? {
          fComDataRetTimeline:
            formData[`${dataRetention.fComDataRetModule}_select`],
        }
      : {}),
    fComDataRetSeq: dataRetention.fComDataRetSeq,
  }));

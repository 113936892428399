import Service from '@/app/utils/service';
import API from '@/app/api/internalAPIs';

import { Dispatch } from 'react';

import { HoursSettingsActionTypes } from '../data';
import { CountryHoursSettings } from '../hoursSettingsReducer';

export const createCustomHours = (
    countryCode: string,
    customHours: Omit<CountryHoursSettings, 'countryCode'>,
    dispatch: Dispatch<any>,
): void => {
    dispatch({type: HoursSettingsActionTypes.POST_COUNTRY_HOURS_CUSTOM_HOURS_PENDING});
    Service.post(
        API.workTimeSettings.createCountryHoursSettings(),
        {
            countryCode: countryCode,
            ...customHours
        },
        (response: any) => {
            dispatch(
                {
                    type: HoursSettingsActionTypes.POST_COUNTRY_HOURS_CUSTOM_HOURS_FULFILLED,
                    payload: response
                }
            );
        },
        (error: any) => {
            dispatch(
                {
                    type: HoursSettingsActionTypes.POST_COUNTRY_HOURS_CUSTOM_HOURS_REJECTED,
                    error
                }
            );
        }
    );
};

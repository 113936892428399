export enum JobEvaluationStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  EMPLOYEE_APPROVED = 'EMPLOYEE_APPROVED',
}

export enum Status {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum TargetType {
  EMPLOYEE = 'EMPLOYEE',
  UNIT = 'UNIT',
}

import * as React from 'react';
import { compose } from 'recompose';
import { LanguagesType } from '@/app/redux/languages';
import { withStyles } from '@material-ui/core/styles';
import { WithStyles, Paper, Icon, Button, Typography } from '@material-ui/core';
import { orderElementsByIndex } from '@/app/components/TemplateComponents/helpers';
import editTemplateStyle from './editTemplateStyle';
import SectionListing from '@/app/components/TemplateComponents/admin/EditTemplateComponents/SectionListing/SectionListing';
import Modal from '@material-ui/core/Modal';
import AddSection from '@/app/components/TemplateComponents/admin/EditTemplateComponents/AddSection/AddSection';
import translate from '@/app/utils/translate';
import SortableHOC from '@/app/components/TemplateComponents/admin/EditTemplateComponents/SortableHOC/SortableHOC';
import {
  FormTemplate,
  FormTemplateComponentDeleteCall,
  FormTemplateComponentOrderCall,
  FormTemplateComponentUpdateCall,
  FormTemplateSection,
  FormTemplateSectionDeleteCall,
  FormTemplateSectionUpdateCall,
} from '@/app/components/TemplateComponents/types';
import TemplateHeader from '@/app/components/TemplateComponents/admin/TemplateHeader/TemplateHeader';

type incomingProps = {
  languages: LanguagesType[];
  handleModalClose: any;
  updateProcessTemplate: any;
  addTemplateSection: FormTemplateSectionUpdateCall;
  deleteSection: FormTemplateSectionDeleteCall;
  addComponentToSection: FormTemplateComponentUpdateCall;
  updateSection: FormTemplateSectionUpdateCall;
  updateSectionComponent: FormTemplateComponentUpdateCall;
  deleteComponent: FormTemplateComponentDeleteCall;
  orderComponents: FormTemplateComponentOrderCall;
  openEditModal: any;
  readyToEditTemplate: FormTemplate | undefined;
  warningMessage: boolean;
};

type PropsType = incomingProps & WithStyles<typeof editTemplateStyle>;

class EditTemplate extends React.PureComponent<PropsType> {
  state = {
    modalOpen: false,
    loadCompleted: false,
    newSection: false,
    openSectionSort: false,
  };

  handleAddSection = () => {
    this.setState({ modalOpen: true });
  };

  newSectionAdded = () => {
    this.setState({ newSection: true });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };

  componentDidUpdate = (prevProps: any) => {
    if (
      !this.state.loadCompleted &&
      prevProps.readyToEditTemplate !== this.props.readyToEditTemplate
    ) {
      this.setState({ loadCompleted: true });
    }
  };

  saveNewOrder = (newOrder: []) => {
    const { readyToEditTemplate, updateSection } = this.props;
    let updatedSections = newOrder.map((section: any, index) => {
      section.index = index + 1;
      return section;
    });
    for (let i = 0; i < updatedSections.length; i++) {
      updateSection(readyToEditTemplate.id, updatedSections[i]);
    }
    this.closeSortingModal();
  };

  sectionSortOpen = () => {
    this.setState({ openSectionSort: true });
  };

  closeSortingModal = () => {
    this.setState({ openSectionSort: false });
  };

  render() {
    const {
      classes,
      languages,
      addTemplateSection,
      addComponentToSection,
      updateSectionComponent,
      deleteSection,
      deleteComponent,
      orderComponents,
      readyToEditTemplate,
      updateProcessTemplate,
      openEditModal,
      handleModalClose,
      warningMessage,
      updateSection,
    } = this.props;
    const {
      modalOpen,
      loadCompleted,
      newSection,
      openSectionSort,
    } = this.state;

    const hasSections = !!readyToEditTemplate && readyToEditTemplate.sections;
    const orderedSections: FormTemplateSection[] = hasSections
      ? orderElementsByIndex<FormTemplateSection>(readyToEditTemplate.sections)
      : [];
    const templateBeingEdited: FormTemplate = hasSections
      ? { ...readyToEditTemplate, sections: orderedSections }
      : readyToEditTemplate;

    return (
      <Modal open={openEditModal}>
        <Paper className={classes.root}>
          <SortableHOC
            open={openSectionSort}
            itemsToSort={orderedSections}
            saveNewOrder={this.saveNewOrder}
            cancelNewOrder={this.closeSortingModal}
          />
          <TemplateHeader
            templateBeingEdited={templateBeingEdited}
            languages={languages}
            handleModalClose={handleModalClose}
            editStyle={true}
            updateTemplate={updateProcessTemplate}
            accessFilter
          />
          {warningMessage ? (
            <Typography className={classes.warning} color="error">
              {translate.t('warningTemplateEdit')}
            </Typography>
          ) : (
            ''
          )}
          {loadCompleted &&
          templateBeingEdited &&
          templateBeingEdited.sections &&
          templateBeingEdited.sections.length > 0 ? (
            orderedSections.map((section: FormTemplateSection) => (
              <SectionListing
                customStyle={true}
                key={section.id}
                section={section}
                addComponentToSection={addComponentToSection}
                updateSectionComponent={updateSectionComponent}
                deleteSection={deleteSection}
                readyToEditTemplate={readyToEditTemplate}
                orderComponents={orderComponents}
                deleteComponent={deleteComponent}
                newSection={newSection}
                updateSection={updateSection}
                templateType={'process'}
              />
            ))
          ) : (
            <h2 className={classes.noSections}>{translate.t('no_sections')}</h2>
          )}
          <div className={classes.buttonWrapper}>
            <Button
              onClick={() => this.handleAddSection()}
              variant="contained"
              color="primary"
              className={classes.addSectionButton}
            >
              <Icon
                onClick={() => this.handleAddSection()}
                className={classes.addIcon}
              >
                add_circle
              </Icon>
              {translate.t('add_section')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.sortButton}
              onClick={this.sectionSortOpen}
            >
              <Icon onClick={() => this.sectionSortOpen}>sort</Icon>
              {translate.t('laSort')}
            </Button>
          </div>
          <Modal
            open={modalOpen && !!readyToEditTemplate}
            onClose={() => this.handleModalClose()}
          >
            <AddSection
              readyToEditTemplate={readyToEditTemplate}
              addTemplateSection={addTemplateSection}
              handleModalClose={this.handleModalClose}
              newSectionAdded={this.newSectionAdded}
            />
          </Modal>
        </Paper>
      </Modal>
    );
  }
}

const enhance = compose<any, any>(withStyles(editTemplateStyle));

export default enhance(EditTemplate);

import * as React from 'react';
import {
  FormControlLabel,
  Checkbox,
  Icon,
  WithStyles,
  withStyles,
  Button,
  MenuItem,
  Menu,
} from '@material-ui/core';
import {
  Grid,
  GridColumn,
  GridDataStateChangeEvent,
  GridToolbar,
} from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import Service from '@/app/utils/service';
import API from '@/app/api/internalAPIs';
import {
  getLoggedUserId,
  getEnums,
  mapBooleanToYesNo,
  getLoginData,
  isOnlyAltMgr,
} from '@/old/utils/helper';
import {
  mapClosedWorkflowsForUI,
  mapIntPositionClosedWorkflowsForUI,
  mapEmpDetailsClosedWorkflowsForUI,
} from './service';
import { process } from '@progress/kendo-data-query';
import ColumnMenu from '@/app/components/Kendo/ColumnMenu';
import styles from './styles';
import {
  gridColumns,
  intPositionGridColumns,
  empDetailsGridColumns,
  gridColMapping,
} from './Columns/GridColumns';
import compose from 'recompose/compose';
import translate from '@/app/utils/translate';
import SelectFilter from './KendoFilters/SelectFilter';
import { SnackbarProvider } from 'notistack';
import { ReportSettingsTypes, ReportSettingsUtils } from '../ReportSettingsUtils';

type InnerProps = WithStyles<typeof styles>;
type OwnProps = { closedWorkflowType: string };

type Props = OwnProps & InnerProps;

const getMapClosedWorkflowsForUIMetod = (closedWorkflowType: string) => {
  switch (closedWorkflowType) {
    case 'peopleIntPositionsClosedWorkflows':
      return mapIntPositionClosedWorkflowsForUI;
    case 'peopleEmpDetailsClosedWorkflows':
      return mapEmpDetailsClosedWorkflowsForUI;
    case 'rewardingClosedWorkflows':
    default:
      return mapClosedWorkflowsForUI;
  }
};

const getGridColumns = (closedWorkflowType: string) => {
  switch (closedWorkflowType) {
    case 'peopleIntPositionsClosedWorkflows':
      return intPositionGridColumns();
    case 'peopleEmpDetailsClosedWorkflows':
      return empDetailsGridColumns();
    case 'rewardingClosedWorkflows':
    default:
      return gridColumns();
  }
};

const selectReportSetting = (closedWorkflowType: string) => {
  switch (closedWorkflowType) {
    case 'peopleIntPositionsClosedWorkflows':
      return ReportSettingsTypes.CLOSED_WORKFLOW_INTPOS;
    case 'peopleEmpDetailsClosedWorkflows':
      return ReportSettingsTypes.CLOSED_WORKFLOW_EMPLDET;
    case 'rewardingClosedWorkflows':
    default:
      return ReportSettingsTypes.CLOSED_WORKFLOW_REWARDING;
  }
};

const ClosedWorkflowsReport = (props: Props) => {
  const { classes, closedWorkflowType } = props;
  const reportGridColumns: any[] = getGridColumns(closedWorkflowType);

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState('');
  const [workflows, setWorkflows] = React.useState([]);
  const [workflowsHr, setWorkflowsHr] = React.useState([]);
  const [workflowsM, setWorkflowsM] = React.useState([]);
  const [columns, setColumns] = React.useState(reportGridColumns);
  const [dataState = { skip: 0, take: 9 }, setDataState] = React.useState();
  const [showHistory, setShowHistory] = React.useState({ checkedA: false });
  const [dropdownMenu, setDropdownMenu] = React.useState();
  const [teamCheck, setTeamCheck] = React.useState(true);
  const [subunitCheck, setSubunitCheck] = React.useState(false);
  const [selectedUnit, setSelectedUnit] = React.useState();
  const [selectedUnitId, setSelectedUnitId] = React.useState();

  const reportSettingConfig = {
    columns,
    dataState,
    showHistory,
    teamCheck,
    subunitCheck,
    selectedUnit,
    selectedUnitId,
  };

  const reportSettingConfigInit = {
    columns: reportGridColumns,
    dataState: { skip: 0, take: 9 },
    showHistory: { checkedA: false },
    teamCheck: true,
    subunitCheck: false,
    selectedUnit: undefined as string,
    selectedUnitId: undefined as number,
  };

  const mapWorkflowsForUI = getMapClosedWorkflowsForUIMetod(closedWorkflowType);

  // get roles
  const getRoles = getLoginData();
  const roles = getRoles.fLoginRoles;
  const managerType = isOnlyAltMgr() ? 'amgr' : 'manager';

  const getEmpRole = (value1: string) => roles.find((r: any) => r === value1);

  const Manager =
    getEmpRole('MANAGER') ||
    getEmpRole('DEPUTY_MANAGER') ||
    getEmpRole('ALT_MANAGER');
  const Hr =
    getEmpRole('HR_ADMIN') ||
    getEmpRole('HR_ADMIN_LIMITED') ||
    getEmpRole('HR_PEOP') ||
    getEmpRole('HR_PEOP_DEV') ||
    getEmpRole('HR_INITIAL') ||
    getEmpRole('HR_STAFF') ||
    getEmpRole('HR_DEV') ||
    getEmpRole('HR_AUDITOR') ||
    getEmpRole('HR_SALARY');

  React.useEffect(() => {
    if (Manager && Hr) {
      Service.get(
        API.closedWorkflowsReport.getByManagerId(
          closedWorkflowType,
          managerType,
          getLoggedUserId(),
        ),
        (res: any) => {
          setWorkflows(mapWorkflowsForUI(res));
          setLoading(false);
          setError('');
        },
        (err: any) => {
          setWorkflows([]);
          setLoading(false);
          setError(err);
        },
      );
    } else if (Manager) {
      Service.get(
        API.closedWorkflowsReport.getByManagerId(
          closedWorkflowType,
          managerType,
          getLoggedUserId(),
        ),
        (res: any) => {
          setWorkflows(mapWorkflowsForUI(res));
          setLoading(false);
          setError('');
        },
        (err: any) => {
          setWorkflows([]);
          setLoading(false);
          setError(err);
        },
      );
    } else if (Hr) {
      setSubunitCheck(true);
      Service.get(
        API.closedWorkflowsReport.getByHrId(
          closedWorkflowType,
          getLoggedUserId(),
        ),
        (res: any) => {
          setWorkflows(mapWorkflowsForUI(res));
          setLoading(false);
          setError('');
        },
        (err: any) => {
          setWorkflows([]);
          setLoading(false);
          setError(err);
        },
      );
    }
  }, []);

  Manager
    ? React.useEffect(() => {
        Service.get(
          API.closedWorkflowsReport.getByManagerAllId(
            closedWorkflowType,
            getLoggedUserId(),
          ),
          (res: any) => {
            setWorkflowsM(mapWorkflowsForUI(res));
            setLoading(false);
            setError('');
          },
          (err: any) => {
            setWorkflowsM([]);
            setLoading(false);
            setError(err);
          },
        );
      }, [])
    : '';

  Hr
    ? React.useEffect(() => {
        Service.get(
          API.closedWorkflowsReport.getByHrId(
            closedWorkflowType,
            getLoggedUserId(),
          ),
          (res: any) => {
            setWorkflowsHr(mapWorkflowsForUI(res));
            setLoading(false);
            setError('');
          },
          (err: any) => {
            setWorkflowsHr([]);
            setLoading(false);
            setError(err);
          },
        );
      }, [])
    : undefined;

  const handleCallUnitsEndpoint = (unit: any) => {
    setDropdownMenu(null);
    setTeamCheck(false);
    setSubunitCheck(false);
    setSelectedUnit(unit.unit);
    setSelectedUnitId(unit.unitId);
    Service.get(
      API.closedWorkflowsReport.getByUnitId(closedWorkflowType, unit.unitId),
      (res: any) => {
        setWorkflows(mapWorkflowsForUI(res));
        setLoading(false);
        setError('');
      },
      (err: any) => {
        setWorkflows([]);
        setLoading(false);
        setError(err);
      },
    );
  };

  const handleCallSubunitsEndpoint = () => {
    selectedUnitId
      ? Service.get(
          API.closedWorkflowsReport.getBySubUnitId(
            closedWorkflowType,
            selectedUnitId,
          ),
          (res: any) => {
            setWorkflows(mapWorkflowsForUI(res));
            setLoading(false);
            setError('');
          },
          (err: any) => {
            setWorkflows([]);
            setLoading(false);
            setError(err);
          },
        )
      : '';
  };

  if (loading) {
    return <div>{loading}</div>;
  }
  if (error) {
    return <div>{'error'}</div>;
  }
  if (!workflows) {
    return <div>{'no data'}</div>;
  }

  // get Enum names for workflow type
  const getWFTypes = (group: any) => {
    return getEnums(group).filter((item: any) => item.isActive);
  };
  const getTypeTranslation = (group: any, type: string) => {
    const workflowTypes = getWFTypes(group);
    const wf = workflowTypes.find((item: any) => item.code === type);
    if (!wf) {
      return '';
    }
    return wf.name;
  };

  // get Enum names for workflow status
  const getStatusTypes = () => {
    return getEnums('WORKFLOW_ITEM_STATUS').filter(
      (item: any) => item.isActive,
    );
  };
  const workflowStatus = getStatusTypes();
  const getStatusTranslation = (type: string) => {
    const wf = workflowStatus.find((item: any) => item.code === type);
    if (!wf) {
      return '';
    }
    return wf.name;
  };

  const getUniqueWorkflows = () => {
    const wf =
      Manager && Hr
        ? workflowsHr.concat(workflowsM)
        : Hr && !Manager
        ? workflowsHr
        : !Hr && Manager
        ? workflowsM
        : workflows;
    const unique = wf
      .map((wflow: any) => wflow.unitId)
      .map(
        (wflow, element, final) => final.indexOf(wflow) === element && element,
      )
      .filter(wflow => wf[wflow])
      .map(wflow => wf[wflow]);
    return unique;
  };

  const unitData = getUniqueWorkflows();

  const getWorkflowData = (
    innitialWorkflows: any[],
    receivedClosedWorkflowType: string,
  ) => {
    const workflowListData: any[] = [];
    switch (receivedClosedWorkflowType) {
      case 'peopleIntPositionsClosedWorkflows':
        innitialWorkflows.forEach((obj: any) => {
          workflowListData.push({
            empId: obj.empId,
            firstName: obj.firstName,
            lastName: obj.lastName,
            empNo: obj.empNo,
            unitId: obj.unitId,
            unit: obj.unit,
            unitNo: obj.unitNo,
            managerId: obj.managerId,
            workflowId: obj.workflowId,
            updatedDate: obj.updatedDate,
            approvedBy: obj.approvedBy,
            info: obj.info,
            scheduledDate: obj.scheduledDate,
            statusCode: obj.status,
            type: obj.type,
            status: getStatusTranslation(obj.status),
            position: obj.position,
            costCenter: obj.costCenter,
            costCenterPct: obj.costCenterPct,
            jobTitle: obj.jobTitle,
            startDate: obj.startDate,
            endDate: obj.endDate,
            legalEntity: obj.legalEntity,
          });
        });
        break;
      case 'peopleEmpDetailsClosedWorkflows':
        workflows.forEach((obj: any) => {
          workflowListData.push({
            empId: obj.empId,
            firstName: obj.firstName,
            lastName: obj.lastName,
            empNo: obj.empNo,
            empStatus: getTypeTranslation('EMPLOYMENT_STATUS', obj.empStatus),
            pg1: getTypeTranslation('PERSONNEL_GROUP_1', obj.pg1),
            pg2: getTypeTranslation('PERSONNEL_GROUP_2', obj.pg2),
            pg3: getTypeTranslation('PERSONNEL_GROUP_3', obj.pg3),
            pg4: getTypeTranslation('PERSONNEL_GROUP_4', obj.pg4),
            pg5: getTypeTranslation('PERSONNEL_GROUP_5', obj.pg5),
            unitId: obj.unitId,
            unit: obj.unit,
            unitNo: obj.unitNo,
            managerId: obj.managerId,
            workflowId: obj.workflowId,
            amount: obj.amount,
            group: obj.group,
            updatedDate: obj.updatedDate,
            approvedBy: obj.approvedBy,
            info: obj.info,
            jobGrade: obj.jobGrade,
            scheduledDate: obj.scheduledDate,
            statusCode: obj.status,
            status: getStatusTranslation(obj.status),
            startDate: obj.startDate,
            endDate: obj.endDate,
            taxNumber: obj.taxNumber,
            vacBalCalcUseSixDays: mapBooleanToYesNo(
              Boolean(obj.vacBalCalcUseSixDays),
            ),
            calcHoursBalance: mapBooleanToYesNo(Boolean(obj.calcHoursBalance)),
            ext: obj.ext,
          });
        });
        break;
      case 'rewardingClosedWorkflows':
      default:
        innitialWorkflows.forEach((obj: any) => {
          workflowListData.push({
            empId: obj.empId,
            firstName: obj.firstName,
            lastName: obj.lastName,
            empNo: obj.empNo,
            unitId: obj.unitId,
            unit: obj.unit,
            unitNo: obj.unitNo,
            managerId: obj.managerId,
            workflowId: obj.workflowId,
            amount: obj.amount,
            group: obj.group,
            updatedDate: obj.updatedDate,
            approvedBy: obj.approvedBy,
            info: obj.info,
            scheduledDate: obj.scheduledDate,
            statusCode: obj.status,
            type: getTypeTranslation(obj.group, obj.type),
            status: getStatusTranslation(obj.status),
            ext: obj.ext,
          });
        });
        break;
    }

    return workflowListData;
  };

  // workflow data with enum translations
  const workflowsData: any[] = getWorkflowData(workflows, closedWorkflowType);

  // closed workflows when history is unchecked (closed workflows EXCEL AND GRID data)
  let closedWFExcelAndGridData = workflowsData.filter(
    wflow => wflow.statusCode === 'CLOSED',
  );

  // wf IDs for CLOSED workflows
  let closedWorkflowIDs = closedWFExcelAndGridData.map(
    wflow => wflow.workflowId,
  );

  // filter workflowsData to get history for closed workflows (closed workflows history EXCEL data)
  let closedWFHistoryExcelData = workflowsData.filter(element => {
    return closedWorkflowIDs.includes(element.workflowId);
  });

  // workflows when history is unchecked  (closed workflows history GRID data)
  var closedWFHistoryGridData: any[] = [];
  var current: any = [];

  closedWFHistoryExcelData.forEach(
    (obj: any, _i: any) => {
      if (!current[obj.workflowId]) {
        current[obj.workflowId] = {
          statusHistory: [],
          updatedDateHistory: [],
          approvedByHistory: [],
          ...obj,
        };
        closedWFHistoryGridData.push(current[obj.workflowId]);
      }
      current[obj.workflowId].statusHistory.push(obj.status);
      current[obj.workflowId].updatedDateHistory.push(obj.updatedDate);
      current[obj.workflowId].approvedByHistory.push(obj.approvedBy);
    },
    // tslint:disable-next-line: align
    Object.create(null),
  );

  const handleChange = (name: string) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setShowHistory({ ...showHistory, [name]: event.target.checked });
  };

  // custom filter for workflow type column
  const workflowTypeOptions = Array.from(
    new Set(workflowsData.map(field => field.type)),
  );
  const WorkflowTypeFilterCell = SelectFilter(
    workflowTypeOptions,
    'Select category',
  );

  // custom filter for workflow unit column
  const unitOptions = Array.from(
    new Set(workflowsData.map(field => field.unitNo)),
  );
  const workflowUnitFilterCell = SelectFilter(unitOptions, 'Select unit');

  const gridData: any[] = [];

  (!showHistory.checkedA
    ? closedWFExcelAndGridData
    : closedWFHistoryGridData
  ).forEach((obj: any) => {
    gridData.push({
      showHistory: showHistory.checkedA,
      statusHistory: obj.statusHistory || '',
      updatedDateHistory: obj.updatedDateHistory || '',
      approvedByHistory: obj.approvedByHistory || '',
      ...obj,
    });
  });

  const onColumnsSubmit = (columnsState: any) => {
    setColumns(columnsState);
  };

  const createDataState = (dataStateProp: any) => {
    return {
      dataState: setDataState(dataStateProp),
    };
  };

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    return createDataState(event.data);
  };

  let _export: any;

  const saveClosedWorkflowsReport = () => {
    const filteredDataState = {
      ...dataState,
      // Export all the records
      skip: 0,
      take: undefined as number,
    };
    !showHistory.checkedA
      ? _export.save(process(closedWFExcelAndGridData, filteredDataState))
      : _export.save(process(closedWFHistoryExcelData, filteredDataState));
  };

  const handleClickTeamTab = () => {
    setSelectedUnit(undefined);
    setSelectedUnitId(undefined);
    Service.get(
      API.closedWorkflowsReport.getByManagerId(
        closedWorkflowType,
        managerType,
        getLoggedUserId(),
      ),
      (res: any) => {
        setWorkflows(mapWorkflowsForUI(res));
        setLoading(false);
        setError('');
      },
      (err: any) => {
        setWorkflows([]);
        setLoading(false);
        setError(err);
      },
    );
  };

  const handleClickUnitTab = () => {
    Service.get(
      API.closedWorkflowsReport.getByHrId(
        closedWorkflowType,
        getLoggedUserId(),
      ),
      (res: any) => {
        setWorkflows(mapWorkflowsForUI(res));
        setLoading(false);
        setError('');
      },
      (err: any) => {
        setWorkflows([]);
        setLoading(false);
        setError(err);
      },
    );
  };

  const handleClickSubunitsManagerAll = () => {
    Service.get(
      API.closedWorkflowsReport.getByManagerAllId(
        closedWorkflowType,
        getLoggedUserId(),
      ),
      (res: any) => {
        setWorkflows(mapWorkflowsForUI(res));
        setLoading(false);
        setError('');
      },
      (err: any) => {
        setWorkflows([]);
        setLoading(false);
        setError(err);
      },
    );
  };
  
  const applySavedReportSettings = (settings: any) => {
    if (!settings) {
      settings = reportSettingConfigInit;
    }
    setColumns(settings.columns.map((col: any) => ({...col, cell: gridColMapping[col.field] })));
    setDataState(settings.dataState);
    setShowHistory(settings.showHistory);
    setTeamCheck(settings.teamCheck);
    setSubunitCheck(settings.subunitCheck);
    setSelectedUnit(settings.selectedUnit);
    setSelectedUnitId(settings.selectedUnitId);
  };

  const ToolbarManagerAndHr = (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={(event: any) => setDropdownMenu(event.currentTarget)}
      >
        {translate.t('laSelectUnit')}{' '}
        <Icon fontSize="small">arrow_drop_down</Icon>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={dropdownMenu}
        keepMounted={false}
        open={Boolean(dropdownMenu)}
        onClose={() => setDropdownMenu(null)}
      >
        {unitData.map((unit: any) => (
          <MenuItem
            selected={false}
            key={unit.unitId}
            onClick={() => handleCallUnitsEndpoint(unit)}
          >
            {unit.unit}
          </MenuItem>
        ))}
      </Menu>
      {selectedUnit || ''}
      {Manager ? (
        <span>
          <Checkbox
            checked={teamCheck}
            onChange={(event: any) => {
              setTeamCheck(event.target.checked);
              setSubunitCheck(false);
              handleClickTeamTab(); // Manager + Hr
            }}
            value="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />{' '}
          {translate.t('laTeam')}
        </span>
      ) : (
        ''
      )}

      {Manager && !Hr ? (
        <span>
          <Checkbox
            checked={subunitCheck}
            onChange={(event: any) => {
              setSubunitCheck(event.target.checked);
              setTeamCheck(false);
              selectedUnitId
                ? handleCallSubunitsEndpoint()
                : handleClickSubunitsManagerAll(); // Manager
            }}
            value="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />{' '}
          {translate.t('laIncludeSubunits')}
        </span>
      ) : Manager || Hr ? (
        <span>
          <Checkbox
            checked={subunitCheck}
            onChange={(event: any) => {
              setSubunitCheck(event.target.checked);
              setTeamCheck(false);
              selectedUnitId
                ? handleCallSubunitsEndpoint()
                : handleClickUnitTab(); // Manager + Hr
            }}
            value="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />{' '}
          {translate.t('laIncludeSubunits')}
        </span>
      ) : (
        ''
      )}
    </div>
  );

  return (
    <>
      <ExcelExport
        fileName={translate.t('laClosedWorkflowsReport')}
        ref={excelExport => (_export = excelExport)}
      >
        <Grid
          data={process(gridData, dataState)}
          onDataStateChange={dataStateChange}
          {...dataState}
          sortable={true}
          resizable={true}
          pageable={true}
          groupable={true}
        >
          <GridToolbar>
            <SnackbarProvider maxSnack={3}>
              <ReportSettingsUtils
                reportType={selectReportSetting(closedWorkflowType)}
                initialReportSettings={JSON.stringify(reportSettingConfigInit)}
                getCurrentReportSettings={() => reportSettingConfig}
                applyReportSettings={applySavedReportSettings}
              />
            </SnackbarProvider>
          </GridToolbar>
          <GridToolbar>{ToolbarManagerAndHr}</GridToolbar>
          <GridToolbar>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showHistory.checkedA}
                  onChange={handleChange('checkedA')}
                  value="checkedA"
                />
              }
              label={translate.t('laShowHistory')}
            />
            <span className={classes.groupButton}>
              <Button onClick={saveClosedWorkflowsReport}>
                {translate.t('laDownload')} &nbsp;{' '}
                <Icon fontSize="small">cloud_download</Icon>
              </Button>
            </span>
          </GridToolbar>
          {columns.map(
            (column: any, idx: number) =>
              column.show && (
                <GridColumn
                  {...column}
                  key={idx}
                  columnMenu={(otherProps: any) => (
                    <ColumnMenu
                      {...otherProps}
                      columns={columns}
                      onColumnsSubmit={onColumnsSubmit}
                      filterUI={
                        column.field === 'unitNo'
                          ? workflowUnitFilterCell
                          : column.field === 'type'
                          ? WorkflowTypeFilterCell
                          : undefined
                      }
                    />
                  )}
                />
              ),
          )}
        </Grid>
      </ExcelExport>
    </>
  );
};

const enhance = compose<Props, OwnProps>(withStyles(styles));

export default enhance(ClosedWorkflowsReport);

import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const templateSelectionStyles: StyleRulesCallback = () => ({
  root: {
    borderRadius: 0,
    border: 'solid 1px #D6D6D6 !important',
    boxShadow: 'none',
    marginLeft: '-16px',
    marginTop: '8px',
    outline: 'none',
  },
  spacing: {
    flexGrow: 1,
    marginRight: 20,
  },
  flexWrapper: {
    marginLeft: '20px',
  },
  select: {
    minWidth: 220,
    margin: '20px 0 20px 20px',
  },
  selectEnabled: {
    '&:before': {
      borderColor: '#05a4d8',
    },
    '&:after': {
      borderColor: '#05a4d8',
    },
  },
  selectDisabled: {
    '&:before': {
      borderColor: '#d3d3d3',
    },
    '&:after': {
      borderColor: '#d3d3d3',
    },
  },
  icon: {
    fill: '#05a4d8',
  },
  iconDisabled: {
    fill: '#d3d3d3',
  },
  inputColor: {
    color: '#05a4d8',
  },
  inputDisabled: {
    color: '#d3d3d3',
  },
  iconButton: {
    minHeight: '50px',
    float: 'right',
  },
  option: {
    color: 'red',
  },
  noSections: {
    padding: '20px 20px',
  },
  button: {
    minHeight: '35px',
    textAlign: 'left',
    fontSize: '0.9em !important',
  },
  buttonWrapper: {
    float: 'right',
    marginTop: '-20px',
    marginRight: '20px',
  },
  removeApprovalWrapper: {
    marginTop: '-20px',
    marginRight: '15px',
    marginLeft: '-20px',
    display: 'inline-block',
    maxWidth: '170px',
    float: 'right',
  },
  textField: {
    minWidth: '175px',
  },
  approved: {
    maxWidth: '145px',
  },
  naText: {
    marginLeft: '20px',
    marginBottom: '20px',
  },
});

export default templateSelectionStyles;

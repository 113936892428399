import * as React from 'react';
import * as moment from 'moment';
import { compose } from 'recompose';
import {
  StyleRulesCallback,
  Theme,
  withStyles,
  Paper,
  Tabs,
  Tab,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Button,
  Grid,
  WithStyles,
  TextField,
  MenuItem,
} from '@material-ui/core';

import { TreeView } from '@progress/kendo-react-treeview';
import { ScopeType, ScopeNameType } from '../ReportSettings';
import LocalizedDatePicker from '@/app/components/Pickers/LocalizedDatePicker';
import translate from '@/app/utils/translate';

const styles: StyleRulesCallback = (theme: Theme) => ({
  root: {
    maxWidth: 500,
  },
  tabContainer: {
    padding: theme.spacing.unit * 2,
  },
  treeContainer: {
    margin: theme.spacing.unit * 2,
    maxHeight: 250,
    overflow: 'auto',
  },
  actionButtons: {
    marginTop: theme.spacing.unit * 2,
    textAlign: 'right',
    width: '100%',
  },
  yearSlider: {
    width: 250,
  },
});

enum ScreenTypeEnum {
  NONE = '',
  MYTEAM = 'myteam',
  UNIT = 'unit',
  COMPANY = 'company',
}

type DateRangeVariant = 'date-range' | 'years-and-end-date';

type OwnProps = {
  scope: ScopeType;
  allowCompanyScope: boolean;
  hasTeam: boolean;
  myManagedUnits: Array<object>;
  myHrUnits: Array<object>;
  dateRangeVariant?: DateRangeVariant;
  onApply: (newScope: ScopeType) => void;
  onCancel: () => void;
  onPopupLock: (shouldLock: boolean) => void;
};

type StateType = {
  scope: ScopeType;
  unitTree: Array<object>;
};

type PropsType = OwnProps & WithStyles<typeof styles>;

class ScopeSelector extends React.Component<PropsType, StateType> {
  state: StateType = {
    scope: null,
    unitTree: [],
  };

  private concatUnits = (
    myManUnits: Array<any>,
    myHrUnits: Array<any>,
  ): Array<any> => {
    const results = myManUnits.slice();
    myHrUnits.forEach((unit: any) => {
      if (myManUnits.indexOf(unit) === -1) {
        results.push(unit);
      }
    });
    return results;
  };

  componentDidMount() {
    const { scope, myManagedUnits, myHrUnits } = this.props;
    let unitTree: Array<object> = [];
    if (
      (myManagedUnits && myManagedUnits.length > 0) ||
      (myHrUnits && myHrUnits.length > 0)
    ) {
      let selectedUnitId: string = null;
      if (scope && scope.type === 'unit' && !!scope.id) {
        selectedUnitId = scope.id;
      }
      const myUnits = this.concatUnits(myManagedUnits, myHrUnits);
      unitTree = this.buildTreeFromList(myUnits, selectedUnitId);
    }
    this.setState({ scope: scope || this.getDefaultScope(), unitTree });
  }

  componentDidUpdate(prevProps: PropsType) {
    const { scope, myManagedUnits, myHrUnits } = this.props;
    if (
      (myManagedUnits && myManagedUnits.length > 0) ||
      (myHrUnits && myHrUnits.length > 0)
    ) {
      if (
        !prevProps.myManagedUnits ||
        prevProps.myManagedUnits.length !== myManagedUnits.length ||
        !prevProps.myHrUnits ||
        prevProps.myHrUnits.length !== myHrUnits.length
      ) {
        let selectedUnitId: string = null;
        if (scope && scope.type === 'unit' && !!scope.id) {
          selectedUnitId = scope.id;
        }
        const myUnits = this.concatUnits(myManagedUnits, myHrUnits);
        this.setState({
          unitTree: this.buildTreeFromList(myUnits, selectedUnitId),
        });
      }
    }
  }

  buildTreeFromList = (
    data: any,
    selectedUnitId: string = null,
  ): Array<object> => {
    const SOURCE_ID_KEY = 'fTreeUnitId';
    const SOURCE_TEXT_KEY = 'fTreeUnitName';
    const SOURCE_PARENT_KEY = 'fTreeParentUnitId';
    const TARGET_ID_KEY = 'id';
    const TARGET_TEXT_KEY = 'text';
    const TARGET_CHILDREN_KEY = 'items';
    const TARGET_SELECTED_KEY = 'selected';
    const TARGET_EXPANDED_KEY = 'expanded';

    const getSelectedUnitParents = (id: string): string[] => {
      const currItem = data.find((item: any) => item[SOURCE_ID_KEY] === id);
      if (!currItem || currItem[SOURCE_PARENT_KEY] === '#') {
        return [];
      } else {
        return getSelectedUnitParents(currItem[SOURCE_PARENT_KEY]).concat(
          currItem[SOURCE_PARENT_KEY],
        );
      }
    };
    const selectedUnitParents: string[] = getSelectedUnitParents(
      selectedUnitId,
    );

    const tree: Array<object> = [];
    let childrenOf: any = {};

    for (let i = 0, length = data.length; i < length; i++) {
      const item = data[i];
      const id = item[SOURCE_ID_KEY];
      const parentId = item[SOURCE_PARENT_KEY] || '#';
      // Every item may have children
      childrenOf[id] = childrenOf[id] || [];
      // Init its children
      item[TARGET_CHILDREN_KEY] = childrenOf[id] || [];
      if (
        parentId !== '#' &&
        data.find((a: any) => a[SOURCE_ID_KEY] === parentId) !== undefined
      ) {
        childrenOf[parentId] = childrenOf[parentId] || [];
        childrenOf[parentId].push({
          [TARGET_ID_KEY]: item[SOURCE_ID_KEY],
          [TARGET_TEXT_KEY]: item[SOURCE_TEXT_KEY],
          [TARGET_CHILDREN_KEY]: item[TARGET_CHILDREN_KEY],
          [TARGET_SELECTED_KEY]: selectedUnitId === item[SOURCE_ID_KEY],
          [TARGET_EXPANDED_KEY]:
            selectedUnitParents.indexOf(item[SOURCE_ID_KEY]) !== -1,
        });
      } else {
        tree.push({
          [TARGET_ID_KEY]: item[SOURCE_ID_KEY],
          [TARGET_TEXT_KEY]: item[SOURCE_TEXT_KEY],
          [TARGET_CHILDREN_KEY]: item[TARGET_CHILDREN_KEY],
          [TARGET_SELECTED_KEY]: selectedUnitId === item[SOURCE_ID_KEY],
          [TARGET_EXPANDED_KEY]:
            selectedUnitParents.indexOf(item[SOURCE_ID_KEY]) !== -1,
        });
      }
    }

    return tree;
  };

  handleTypeChange = (_evt: any, screenType: ScreenTypeEnum) => {
    this.setState((prevState: StateType) => ({
      scope: {
        ...prevState.scope,
        type: this.getScopeTypeFromScreenType(screenType),
      },
    }));
  };

  handleDateRangeChange = (
    startDate: moment.Moment,
    endDate: moment.Moment,
  ) => {
    this.setState((prevState: StateType) => ({
      scope: {
        ...prevState.scope,
        range: {
          ...prevState.scope.range,
          startDate,
          endDate,
        },
      },
    }));
  };

  handleUnitTreeExpandChange = (event: any) => {
    const clickedUnitId = event.item.id;
    const toggleExpandedUnit = (units: any) => {
      return units.map((unit: any) => {
        if (unit.id === clickedUnitId) {
          unit.expanded = !unit.expanded;
        }
        if (unit.items && unit.items.length > 0) {
          return {
            ...unit,
            items: toggleExpandedUnit(unit.items),
          };
        } else {
          return unit;
        }
      });
    };
    this.setState({ unitTree: toggleExpandedUnit(this.state.unitTree) });
  };

  handleUnitTreeClick = (event: any) => {
    const clickedUnitId = event.item.id;
    const switchSelectedUnit = (units: any) => {
      return units.map((unit: any) => {
        if (unit.id === clickedUnitId) {
          unit.selected = true;
        } else {
          unit.selected = false;
        }
        if (unit.items && unit.items.length > 0) {
          return {
            ...unit,
            items: switchSelectedUnit(unit.items),
          };
        } else {
          return unit;
        }
      });
    };
    this.setState((prevState: StateType) => ({
      unitTree: switchSelectedUnit(this.state.unitTree),
      scope: {
        ...prevState.scope,
        type: 'unit',
        id: clickedUnitId,
      },
    }));
  };

  handleApplySelection = () => {
    const newScope = Object.assign({}, this.state.scope);
    if (newScope.type === 'company') {
      newScope.id = null;
      newScope.rln = 'all';
    }
    if (newScope.type === 'manager') {
      newScope.id = null;
    }
    this.props.onApply(newScope);
  };

  handleIncludeSubunitsChanged = (includeSubunits: boolean) => {
    this.setState((prevState: StateType) => ({
      ...prevState,
      scope: {
        ...prevState.scope,
        rln: includeSubunits ? 'all' : 'direct',
      },
    }));
  };

  getScopeTypeFromScreenType = (screenType: ScreenTypeEnum): ScopeNameType => {
    switch (screenType) {
      case ScreenTypeEnum.MYTEAM:
        return 'manager';
      case ScreenTypeEnum.UNIT:
        return 'unit';
      case ScreenTypeEnum.COMPANY:
        return 'company';
      default:
        return '';
    }
  };

  getScreenTypeFromScopeType = (scopeType: ScopeNameType): ScreenTypeEnum => {
    switch (scopeType) {
      case 'manager':
      case 'dmgr':
      case 'amgr':
        // if (hasManagedUnits) {
        return ScreenTypeEnum.MYTEAM;
      // } else {
      // return ScreenTypeEnum.COMPANY;
      // }
      case 'unit':
        return ScreenTypeEnum.UNIT;
      case 'company':
        return ScreenTypeEnum.COMPANY;
      default:
        return ScreenTypeEnum.NONE;
    }
  };

  getDefaultScope = (): ScopeType => {
    const {
      allowCompanyScope,
      hasTeam,
      myManagedUnits,
      myHrUnits,
    } = this.props;
    if (hasTeam) {
      return {
        type: 'manager',
        rln: 'direct',
      };
    }
    if (myManagedUnits.length > 0 || myHrUnits.length > 0) {
      return {
        type: 'unit',
        rln: 'direct',
      };
    }
    if (allowCompanyScope) {
      return {
        type: 'company',
        rln: 'all',
      };
    }
    return {
      type: '',
      rln: 'direct',
    };
  };

  getDateRangeSelector = (): React.ReactElement => {
    const { classes, onPopupLock } = this.props;
    const { scope } = this.state;

    if (!scope.range) {
      return null;
    }

    const minDate =
      scope.range.endDate && scope.range.maxRange
        ? scope.range.endDate
          .clone()
          .subtract(scope.range.maxRange, scope.range.maxRangeUnit)
        : undefined;
    const maxDate =
      scope.range.startDate && scope.range.maxRange
        ? scope.range.startDate
          .clone()
          .add(scope.range.maxRange, scope.range.maxRangeUnit)
        : undefined;

    return (
      <Grid
        container
        className={classes.actionButtons}
        spacing={8}
        justify="flex-start"
      >
        <Grid item>
          <LocalizedDatePicker
            clearable
            label={translate.t('laStartDate')}
            value={scope.range.startDate}
            onOpen={() => onPopupLock(true)}
            onClose={() => onPopupLock(false)}
            onChange={(date: moment.Moment) =>
              this.handleDateRangeChange(date, scope.range.endDate)
            }
            maxDate={scope.range.endDate}
            minDate={minDate}
          />
        </Grid>
        <Grid item>
          <LocalizedDatePicker
            clearable
            label={translate.t('laEndDate')}
            value={scope.range.endDate}
            onOpen={() => onPopupLock(true)}
            onClose={() => onPopupLock(false)}
            onChange={(date: moment.Moment) =>
              this.handleDateRangeChange(scope.range.startDate, date)
            }
            minDate={scope.range.startDate}
            maxDate={maxDate}
          />
        </Grid>
      </Grid>
    );
  };

  getYearsAndEndDateSelector = (): React.ReactElement => {
    const { classes, onPopupLock } = this.props;
    const { scope } = this.state;

    if (!scope.range) {
      return null;
    }

    const years = scope.range.endDate.diff(scope.range.startDate, 'years');
    return (
      <Grid
        container
        className={classes.actionButtons}
        spacing={8}
        justify="flex-start"
      >
        <Grid item>
          <LocalizedDatePicker
            clearable
            label={translate.t('laEndDate')}
            value={scope.range.endDate}
            onOpen={() => onPopupLock(true)}
            onClose={() => onPopupLock(false)}
            onChange={(date: moment.Moment) => {
              const startDate = date.clone().subtract(years, 'years');
              this.handleDateRangeChange(startDate, date);
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            id="select-years"
            select
            label={translate.t('laYears')}
            value={years}
            onChange={(e: any) => {
              const newYears = e.target.value;
              const startDate = scope.range.endDate
                .clone()
                .subtract(newYears, 'years');
              this.handleDateRangeChange(startDate, scope.range.endDate);
            }}
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    );
  };

  render() {
    const {
      classes,
      allowCompanyScope,
      hasTeam,
      dateRangeVariant = 'date-range',
      onCancel,
    } = this.props;
    const { unitTree, scope } = this.state;
    if (!scope) {
      return null;
    }

    let screenType: string = this.getScreenTypeFromScopeType(scope.type);
    const actionButtons = (
      <Grid
        container
        className={classes.actionButtons}
        spacing={8}
        justify="flex-end"
      >
        <Grid item>
          <Button onClick={onCancel}>{translate.t('laCancel')}</Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            disabled={
              screenType === ScreenTypeEnum.NONE ||
              (screenType === ScreenTypeEnum.UNIT && !scope.id)
            }
            onClick={this.handleApplySelection}
          >
            {translate.t('laApply')}
          </Button>
        </Grid>
      </Grid>
    );

    const tabAvailability = {
      [ScreenTypeEnum.MYTEAM]: hasTeam,
      [ScreenTypeEnum.UNIT]: unitTree.length > 0,
      [ScreenTypeEnum.COMPANY]: allowCompanyScope,
    };

    const getFirstAvailableScreenType = () => {
      if (tabAvailability[ScreenTypeEnum.MYTEAM]) {
        return ScreenTypeEnum.MYTEAM;
      }
      if (tabAvailability[ScreenTypeEnum.UNIT]) {
        return ScreenTypeEnum.UNIT;
      }
      if (tabAvailability[ScreenTypeEnum.COMPANY]) {
        return ScreenTypeEnum.COMPANY;
      }
      return ScreenTypeEnum.NONE;
    };
    if (screenType === ScreenTypeEnum.NONE || !tabAvailability[screenType]) {
      screenType = getFirstAvailableScreenType();
    }

    const myTeamTab = !hasTeam ? null : (
      <Tab label={translate.t('laTeam')} value={ScreenTypeEnum.MYTEAM} />
    );
    const unitTab =
      unitTree.length === 0 ? null : (
        <Tab label={translate.t('laUnit')} value={ScreenTypeEnum.UNIT} />
      );
    const companyTab = !allowCompanyScope ? null : (
      <Tab label={translate.t('laCompany')} value={ScreenTypeEnum.COMPANY} />
    );
    if (!myTeamTab && !unitTab && !companyTab) {
      return null;
    }

    const tabs = (
      <Tabs
        variant="fullWidth"
        value={screenType}
        onChange={this.handleTypeChange}
      >
        {myTeamTab}
        {unitTab}
        {companyTab}
      </Tabs>
    );

    if (screenType === ScreenTypeEnum.MYTEAM && !myTeamTab) {
      screenType = ScreenTypeEnum.UNIT;
    }
    if (screenType === ScreenTypeEnum.UNIT && !unitTab) {
      screenType = ScreenTypeEnum.COMPANY;
    }
    if (screenType === ScreenTypeEnum.COMPANY && !companyTab) {
      if (!myTeamTab) {
        if (!unitTab) {
          screenType = ScreenTypeEnum.NONE;
        } else {
          screenType = ScreenTypeEnum.UNIT;
        }
      } else {
        screenType = ScreenTypeEnum.MYTEAM;
      }
    }

    return (
      <Paper className={classes.root}>
        {tabs}
        {screenType === ScreenTypeEnum.MYTEAM && (
          <Paper className={classes.tabContainer}>
            <FormHelperText>
              {translate.t('label_report_scope_myteam_info')}
            </FormHelperText>
            {dateRangeVariant === 'date-range'
              ? this.getDateRangeSelector()
              : this.getYearsAndEndDateSelector()}
            {actionButtons}
          </Paper>
        )}
        {screenType === ScreenTypeEnum.UNIT && (
          <Paper className={classes.tabContainer}>
            <FormHelperText>
              {translate.t('label_report_scope_unit_info')}
            </FormHelperText>
            <Paper elevation={0} className={classes.treeContainer}>
              <TreeView
                data={unitTree}
                onExpandChange={this.handleUnitTreeExpandChange}
                onItemClick={this.handleUnitTreeClick}
              />
            </Paper>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={scope.rln === 'all'}
                  onChange={(
                    _evt: React.ChangeEvent<HTMLInputElement>,
                    checked: boolean,
                  ) => this.handleIncludeSubunitsChanged(checked)}
                />
              }
              label={translate.t('label_include_subunits')}
            />
            {dateRangeVariant === 'date-range'
              ? this.getDateRangeSelector()
              : this.getYearsAndEndDateSelector()}
            {actionButtons}
          </Paper>
        )}
        {screenType === ScreenTypeEnum.COMPANY && (
          <Paper className={classes.tabContainer}>
            <FormHelperText>
              {translate.t('label_report_scope_company_info')}
            </FormHelperText>
            {dateRangeVariant === 'date-range'
              ? this.getDateRangeSelector()
              : this.getYearsAndEndDateSelector()}
            {actionButtons}
          </Paper>
        )}
      </Paper>
    );
  }
}

const enhance = compose<any, OwnProps>(withStyles(styles, { withTheme: true }));

export default enhance(ScopeSelector);

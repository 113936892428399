import { StyleRulesCallback, Theme } from '@material-ui/core';

const styles: StyleRulesCallback = (theme: Theme) => ({
  employeeName: {
    paddingLeft: theme.spacing.unit,
  },
  grow: {
    flex: 1,
  },
  infoButton: {
    padding: theme.spacing.unit,
  },
  inputFileUpload: {
    display: 'none',
  },
  waitingRow: {
    backgroundColor: theme.palette.grey[200],
  },
  rejectedRow: {
    backgroundColor: theme.palette.grey[400],
  },
  error: { color: 'red' },
});

export default styles;

import React from 'react';
import {
  Checkbox,
  WithStyles,
  withStyles,
  StyleRulesCallback,
  Theme,
  Typography,
  Grid,
} from '@material-ui/core';
import {
  APPROVAL_STATUS,
  CompanyTrainingParticipantRegistration,
  COMPLETION_STATUS,
} from '../../../types';
import translate from '@/app/utils/translate';
import { FieldProps } from 'formik';

const styles: StyleRulesCallback = (theme: Theme) => ({
  checkbox: {
    height: theme.spacing.unit * 3,
    width: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit,
  },
  grow: {
    flex: 1,
  },
  historyUpdatedText: {
    marginLeft: theme.spacing.unit,
  },
});

type OwnProps = {
  approvalStatus: APPROVAL_STATUS;
  completionStatus: COMPLETION_STATUS;
  readOnly?: boolean;
  onCheckboxChange: (
    actualParticipantId: number,
    checkBoxValue: boolean,
  ) => void;
  participant: CompanyTrainingParticipantRegistration;
};
type InnerProps = WithStyles<typeof styles> & FieldProps;
type Props = OwnProps & InnerProps;

const UpdateTrainingHistoryField = ({
  classes,
  approvalStatus,
  completionStatus,
  readOnly = false,
  participant,
  onCheckboxChange,
}: Props) => {
  if (
    approvalStatus === APPROVAL_STATUS.REJECTED ||
    approvalStatus === APPROVAL_STATUS.CANCELLED ||
    !(
      completionStatus === COMPLETION_STATUS.COMPLETED ||
      completionStatus === COMPLETION_STATUS.COMPLETED_PARTLY
    ) ||
    !participant.participant.hasOwnProperty('updateTrainingHistory')
  ) {
    return null;
  }
  const participantUpdateTrainingHistory =
    (participant.participant as any).updateTrainingHistory;

  if (readOnly) {
    return (
      <Grid container={true}>
        <Grid item={true}>
          <Typography variant="inherit">
            {!!participantUpdateTrainingHistory
              ? translate.t('laYes')
              : translate.t('laNo')}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container={true} alignItems="center">
      <Grid item={true}>
        <Checkbox
          className={classes.checkbox}
          color="default"
          checked={participantUpdateTrainingHistory}
          value={
            participantUpdateTrainingHistory
              ? participantUpdateTrainingHistory.toString()
              : ''
          }
          onChange={() => {
            onCheckboxChange(
              participant.participant.id,
              !participantUpdateTrainingHistory,
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(UpdateTrainingHistoryField);

import * as React from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  WithStyles,
  withStyles,
  CircularProgress,
} from '@material-ui/core';
import {
  Field,
  Form,
  Formik,
  FormikProps
} from 'formik';
import * as yup from 'yup';

import translate from '@/app/utils/translate';
import { IWorklistTask, EmployeeAutomation, getEmployeeAutomationKeys } from '../types';
import { getEmployeeAutomationTransLabel } from '../service';
import SingleAttachmentField from './taskEditDialog/SingleAttachmentField';
import styles from './taskEditDialog/styles';
import API from '@/app/api/internalAPIs';
import { downloadAttachment } from '@/old/utils/helper';

type OwnProps = {
  open: boolean;
  taskTypes: any[];
  onSave: (values: IWorklistTask) => void;
  onClose: () => void;

  initialValues: IWorklistTask | Pick<IWorklistTask, Exclude<keyof IWorklistTask, 'id'>>
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const TaskEditDialog = (props: Props) => {
  const { classes, initialValues, open, taskTypes, onSave, onClose } = props;

  const validationSchema = yup.object<IWorklistTask>().shape({
    title: yup.string()
      .required(translate.t('laErrorFieldRequir'))
      .max(80, translate.t('laErrorStringMaxChar')),
    description: yup.string()
      .max(3000, translate.t('laErrorTextMaxChar')),
    type: yup.string()
      .required(translate.t('laErrorFieldRequir')),
    dueDateAutomationDays: yup.number()
      .integer(translate.t('text_validate_integer'))
      .required(translate.t('laErrorFieldRequir'))
      .when('dueDateAutomationType', {
        is: val => val === 'BEFORE' || val === 'AFTER',
        then: yup.number().positive(translate.t('text_validate_positive_num_of_days')),
      }),
    dueDateAutomationType: yup.string(),
    assigneeAutomation: yup.string(),
    pertainingToAutomation: yup.string(),
    visibleToPertainingTo: yup.boolean(),
  });

  const handleSave = React.useCallback(
    async (values: IWorklistTask, actions: FormikProps<IWorklistTask>) => {
      await onSave(values);
      actions.resetForm();
      onClose();
    },
    []
  );

  if (!open || !initialValues) {
    return <></>;
  }

  const renderDialog = ({
    errors,
    isSubmitting,
    isValid,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldValue,
    touched,
    values
  }: FormikProps<IWorklistTask>) => {
    const handleCancel = () => {
      resetForm();
      onClose();
    };

    const handleSubmitClick = () => {
      handleSubmit();
    };

    const handleAttachmentChange = (att: File) => {
      setFieldValue('newAttachment', att);
      setFieldValue('attachmentId', -1);
      setFieldValue('attachmentName', !att ? '' : att.name);
    };

    const handleAttachmentDownload = () => {
      downloadAttachment(API.worklists.taskAttachment(values.id, values.attachmentId));
    };

    const submitContent = !isSubmitting ? translate.t('laSave') : (
      <CircularProgress size={22} />
    );

    return (
      <Dialog
        open={open}
        onClose={!isSubmitting ? onClose : undefined}
        maxWidth="sm"
        fullWidth
        aria-labelledby="worklist-edit-dialog-title"
      >
        <DialogTitle id="worklist-edit-dialog-title">
          {(!values.id) ? translate.t('title_add_worklist_task') : translate.t('title_edit_worklist_task')}
        </DialogTitle>
        <DialogContent>
          <Form>
            <Field
              id="title"
              autoFocus
              component={TextField}
              error={Boolean(errors.title && touched.title)}
              fullWidth
              helperText={(errors.title && touched.title) && errors.title}
              label={translate.t('laTitle')}
              margin="normal"
              name="title"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.title}
            />
            <Field
              id="description"
              component={TextField}
              error={Boolean(errors.description && touched.description)}
              fullWidth
              helperText={(errors.description && touched.description) && errors.description}
              label={translate.t('laDescription')}
              margin="normal"
              name="description"
              multiline={true}
              rows={1}
              rowsMax={5}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.description}
            />
            <FormControl
              fullWidth
              margin="normal"
            >
              <InputLabel htmlFor="type-input" shrink={true}>{translate.t('laTaskType')}</InputLabel>
              <Field
                id="type"
                type="select"
                component={Select}
                name="type"
                error={Boolean(errors.type && touched.type)}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.type}
                inputProps={{ name: 'type', id: 'type-input' }}
              >
                {taskTypes.map((taskType: any) => (
                  <MenuItem key={taskType.code} value={taskType.code}>{taskType.name}</MenuItem>
                ))}
              </Field>
            </FormControl>
            <Typography
              className={classes.automationTitle}
              variant="subtitle2"
            >
              {translate.t('title_automation')}
            </Typography>
            <FormControl
              margin="normal"
              className={classes.dueDateAutomationType}
            >
              <InputLabel htmlFor="dueDateAutomationType" shrink={true}>{translate.t('table_col_due_date_automation')}</InputLabel>
              <Field
                id="dueDateAutomationType"
                component={Select}
                name="dueDateAutomationType"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.dueDateAutomationType}
                inputProps={{ name: 'dueDateAutomationType' }}
              >
                <MenuItem value={'NONE'}>{translate.t('label_no_due_date_automation')}</MenuItem>
                <MenuItem value={'ON'}>{translate.t('label_due_date_on_completion_date')}</MenuItem>
                <MenuItem value={'BEFORE'}>{translate.t('label_due_date_before_completition', { days: '' })}</MenuItem>
                <MenuItem value={'AFTER'}>{translate.t('label_due_date_after_completition', { days: '' })}</MenuItem>
              </Field>
            </FormControl>
            <Field
              id="dueDateAutomationDays"
              type="number"
              component={TextField}
              disabled={['NONE', 'ON'].indexOf(values.dueDateAutomationType) > -1}
              error={Boolean(errors.dueDateAutomationDays && touched.dueDateAutomationDays)}
              helperText={(errors.dueDateAutomationDays && touched.dueDateAutomationDays) && errors.dueDateAutomationDays}
              label={translate.t('label_due_date_automation_days')}
              margin="normal"
              name="dueDateAutomationDays"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.dueDateAutomationDays}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="ass-autom-input" shrink={true}>{translate.t('table_col_assignee_automation')}</InputLabel>
              <Field
                id="assigneeAutomation"
                type="select"
                component={Select}
                error={Boolean(errors.assigneeAutomation && touched.assigneeAutomation)}
                name="assigneeAutomation"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.assigneeAutomation}
                inputProps={{ name: 'assigneeAutomation', id: 'ass-autom-input' }}
              >
                {getEmployeeAutomationKeys().map(optKey => {
                  const optLabel = getEmployeeAutomationTransLabel(optKey as EmployeeAutomation);
                  return (
                    <MenuItem key={`pta${optKey}`} value={optKey}>{translate.t(optLabel)}</MenuItem>
                  );
                })}
              </Field>
            </FormControl>
            <FormControl
              fullWidth
              margin="normal"
            >
              <InputLabel htmlFor="pert-autom-input" shrink={true}>{translate.t('table_col_pertaining_to_automation')}</InputLabel>
              <Field
                id="pertainingToAutomation"
                type="select"
                component={Select}
                error={Boolean(errors.pertainingToAutomation && touched.pertainingToAutomation)}
                name="pertainingToAutomation"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.pertainingToAutomation}
                inputProps={{ name: 'pertainingToAutomation', id: 'pert-autom-input' }}
              >
                {getEmployeeAutomationKeys().map(optKey => {
                  const optLabel = getEmployeeAutomationTransLabel(optKey as EmployeeAutomation);
                  return (
                    <MenuItem key={`pta${optKey}`} value={optKey}>{translate.t(optLabel)}</MenuItem>
                  );
                })}
              </Field>
            </FormControl>
            <FormControlLabel
              label={translate.t('fTaskIsVisibleToPertainingTo')}
              control={(
                <Field
                  id="visibleToPertainingTo"
                  type="checkbox"
                  component={Checkbox}
                  name="visibleToPertainingTo"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  checked={values.visibleToPertainingTo ? 'checked' : ''}
                  value={values.visibleToPertainingTo}
                  inputProps={{ name: 'visibleToPertainingTo' }}
                />
              )}
            />
            <FormControl
              fullWidth
              margin="dense"
            >
              <InputLabel htmlFor="dropzone-input" shrink={true}>{translate.t('table_col_attachment')}</InputLabel>
              <Field
                id="attachmentId"
                component={SingleAttachmentField}
                name="attachmentId"
                attachment={values.newAttachment}
                attachmentId={values.attachmentId}
                attachmentName={values.attachmentName}
                onChange={handleAttachmentChange}
                onDownloadAttachment={handleAttachmentDownload}
              />
            </FormControl>
          </Form>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={isSubmitting}
            onClick={handleCancel}
          >
            {translate.t('laCancel')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={!isValid || isSubmitting}
            onClick={handleSubmitClick}
          >
            {submitContent}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSave}
      validationSchema={validationSchema}
    >
      {renderDialog}
    </Formik>
  );
};

export default withStyles(styles, { withTheme: true })(TaskEditDialog);

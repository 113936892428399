import * as React from 'react';
import ComboSelect, { ComboSelectOptionValueType } from '@/app/components/ComboSelect/ComboSelect';

declare var jsonEnumALL: {};

interface IProps {
  enumName: string;
  code: string;
  name: string;
  onChange: any;
}

const enumSelect = (props: IProps) => {
  const OTHER_VALUE = 'OTHER';
  const { enumName, code, name, onChange } = props;

  const customStyles = {
    clearIndicator: (base: any) => ({ ...base, padding: '0px 2px' }),
    control: (base: any) => ({ ...base, minHeight: 20 }),
    dropdownIndicator: (base: any) => ({ ...base, padding: '0px 2px' }),
    input: (base: any) => ({ ...base, paddingTop: '1px', paddingBottom: '1px' }),
    valueContainer: (base: any) => ({ ...base, padding: '0px 5px' }),
  };

  const options = jsonEnumALL[enumName]
    .filter((e: any) => e.isActive !== false && e.code.toUpperCase() !== OTHER_VALUE)
    .map((e: any) => ({
      value: e.code,
      label: e.name,
    }))
    .sort((a: any, b: any) => a.seq - b.seq);

  const selectedValue: ComboSelectOptionValueType = {
    value: code,
    label: name,
  };

  const handleChange = (newValue: any) => {
    if (!newValue || newValue.__isNew__) {
      newValue = {
        label: !newValue ? '' : newValue.label,
        value: OTHER_VALUE,
      };
    }
    onChange(newValue);
  };

  return (
    <ComboSelect
      options={options}
      selectedValue={selectedValue}
      onChange={(newValue) => handleChange(newValue)}
      customStyles={customStyles}
    />
  );
};

export default enumSelect;
import {
  withStyles,
  WithStyles,
  Button,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Link,
  Typography,
} from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Dispatch } from 'redux';
import { v4 as uuid4 } from 'uuid';
import { sortArr } from '@/app/utils/helper';
import {
  ConfirmDialogType,
  openConfirmDialog,
} from '@/app/redux/confirmDialog';
import { ReducerState } from '@/app/redux/store';
import { isNumber } from '@/app/utils/helper';
import translate from '@/app/utils/translate';

import CommonGoal from './CommonGoal';
import PersonalGoal from './PersonalGoal';
import componentListingStyles from './componentListingStyles';

type IncomingProps = {
  section: any;
  data: { commonGoals: any[]; personalGoals: any[]; otherComponents: any[] };
  submitErrors?: any;
  updateData: (sectionId: number, data: any) => void;
  inEditMode: boolean;
};

type MapStateToProps = {
  allCommonGoals: any;
};

type MapDispatchToProps = {
  openConfirmDialog: (payload: ConfirmDialogType) => void;
};

type PropsType = IncomingProps &
  MapStateToProps &
  MapDispatchToProps &
  WithStyles<typeof componentListingStyles>;

class ComponentListing extends React.PureComponent<PropsType> {
  handleAddCommonGoal = () => {
    this.props.updateData(this.props.section.id, {
      ...this.props.data,
      commonGoals: [
        ...this.props.data.commonGoals,
        {
          goalId: uuid4(),
          sectionId: this.props.section.id,
          status: '',
          goal: '',
          successMeasure: '',
          startDate: '',
          endDate: '',
          weight: '',
          rangeMin: '',
          rangeMax: '',
          target: '',
          total: '',
          actual: '',
          result: '',
          info: '',
        },
      ],
    });
  };

  handleChangeCommonGoal = (commonGoal: any) => {
    this.props.updateData(this.props.section.id, {
      ...this.props.data,
      commonGoals: this.props.data.commonGoals.map((cg: any) => {
        if (cg.goalId === commonGoal.goalId) {
          return commonGoal;
        }

        return cg;
      }),
    });
  };

  handleDeleteCommonGoal = (commonGoal: any) => {
    if (commonGoal.componentId) {
      return;
    }

    this.props.openConfirmDialog({
      text: translate.t('laConfirmRowDelete'),
      onOk: () => {
        this.props.updateData(this.props.section.id, {
          ...this.props.data,
          commonGoals: this.props.data.commonGoals.filter((cg: any) => {
            return cg.goalId !== commonGoal.goalId;
          }),
        });
      },
    });
  };

  handleAddPersonalGoal = () => {
    this.props.updateData(this.props.section.id, {
      ...this.props.data,
      personalGoals: [
        ...this.props.data.personalGoals,
        {
          goalId: uuid4(),
          sectionId: this.props.section.id,
          status: '',
          goal: '',
          successMeasure: '',
          startDate: '',
          endDate: '',
          weight: '',
          rangeMin: '',
          rangeMax: '',
          target: '',
          total: '',
          actual: '',
          result: '',
          info: '',
        },
      ],
    });
  };

  handleChangePersonalGoal = (personalGoal: any) => {
    this.props.updateData(this.props.section.id, {
      ...this.props.data,
      personalGoals: this.props.data.personalGoals.map((pg: any) => {
        if (pg.goalId === personalGoal.goalId) {
          return personalGoal;
        }

        return pg;
      }),
    });
  };

  handleDeletePersonalGoal = (personalGoal: any) => {
    this.props.openConfirmDialog({
      text: translate.t('laConfirmRowDelete'),
      onOk: () => {
        this.props.updateData(this.props.section.id, {
          ...this.props.data,
          personalGoals: this.props.data.personalGoals.filter((pg: any) => {
            return pg.goalId !== personalGoal.goalId;
          }),
        });
      },
    });
  };

  getTotalWeight = (type: 'commonGoals' | 'personalGoals'): number =>
    this.props.data[type].reduce(
      (totalWeight, goal) =>
        totalWeight + (isNumber(goal.weight) ? parseFloat(goal.weight) : 0),
      0,
    );

  getTotal = (type: 'commonGoals' | 'personalGoals'): number =>
    this.props.data[type].reduce((total, goal) => {
      const goalTotal =
        isNumber(goal.result) && isNumber(goal.weight)
          ? goal.result * (goal.weight / 100)
          : 0;
      const totalValue =
        parseFloat(total.toFixed(3)) + parseFloat(goalTotal.toFixed(3));
      return Math.trunc(totalValue * 1000) / 1000;
    }, 0);

  render() {
    const {
      classes,
      data,
      submitErrors,
      inEditMode,
      allCommonGoals,
    } = this.props;
    const indexedComponents = sortArr(
      data.commonGoals
        .filter(c => (!isNaN(c.index) ? true : false))
        .concat(data.otherComponents),
      'index',
    );
    const orderedComponents = indexedComponents.concat(
      data.commonGoals.filter(c => (isNaN(c.index) ? true : false)),
    );
    return (
      <>
        {inEditMode && (
          <div className={classes.actionsContainer}>
            <Button
              color="primary"
              variant="text"
              onClick={this.handleAddCommonGoal}
              className={classes.button}
            >
              {translate.t('laAddCommonGoal')}
            </Button>
          </div>
        )}

        <Table className={classes.table}>
          <TableBody>
            {(orderedComponents.length &&
              orderedComponents
                .filter(c => c)
                .map((component: any, index: number) => {
                  if (
                    component.type === 'GOAL' ||
                    (!component.type && component.goalId)
                  ) {
                    return (
                      <CommonGoal
                        key={component.goalId}
                        data={component}
                        submitErrors={
                          submitErrors && submitErrors[component.goalId]
                        }
                        onChange={this.handleChangeCommonGoal}
                        onDelete={this.handleDeleteCommonGoal}
                        inEditMode={inEditMode}
                        allCommonGoals={allCommonGoals}
                      />
                    );
                  } else if (component.type === 'LINK') {
                    return (
                      <div key={component.id}>
                        <Typography
                          variant="subtitle2"
                          className={classes.typography}
                        >
                          <Link
                            href={component.defaultValue}
                            target="_blank"
                            rel="noopener"
                          >
                            {component.name}
                          </Link>
                        </Typography>
                      </div>
                    );
                  } else if (component.type === 'SUBTITLE') {
                    return (
                      <TableRow>
                        <TableCell colSpan={8}>
                          <div key={component.id}>
                            <Typography
                              variant="subtitle2"
                              className={classes.typography}
                            >
                              {component.name}
                            </Typography>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  }

                  return <h2 key={index}>{translate.t('laComponentError')}</h2>;
                })) || (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  {translate.t('no_components')}
                </TableCell>
              </TableRow>
            )}

            <TableRow>
              <TableCell colSpan={6} />
              <TableCell>
                <TextField
                  label={translate.t('laWeight')}
                  value={
                    Math.round(
                      (this.getTotalWeight('commonGoals') + Number.EPSILON) *
                        1000,
                    ) / 1000
                  }
                  disabled={true}
                  fullWidth
                />
              </TableCell>
              <TableCell>
                <TextField
                  label={translate.t('laTotal')}
                  value={this.getTotal('commonGoals')}
                  disabled={true}
                  fullWidth
                />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>

        <div className={classes.delimiter} />

        {inEditMode && (
          <div className={classes.actionsContainer}>
            <Button
              color="primary"
              variant="text"
              onClick={this.handleAddPersonalGoal}
              className={classes.button}
            >
              {translate.t('laAddPersonalGoal')}
            </Button>
          </div>
        )}

        <Table className={classes.table}>
          <TableBody>
            {(data.personalGoals.length &&
              data.personalGoals.map((personalGoal: any) => {
                return (
                  <PersonalGoal
                    key={personalGoal.goalId}
                    data={personalGoal}
                    submitErrors={
                      submitErrors && submitErrors[personalGoal.goalId]
                    }
                    onChange={this.handleChangePersonalGoal}
                    onDelete={this.handleDeletePersonalGoal}
                    inEditMode={inEditMode}
                  />
                );
              })) || (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  {translate.t('no_components')}
                </TableCell>
              </TableRow>
            )}

            <TableRow>
              <TableCell colSpan={6} />
              <TableCell>
                <TextField
                  label={translate.t('laWeight')}
                  value={
                    Math.round(
                      (this.getTotalWeight('personalGoals') + Number.EPSILON) *
                        1000,
                    ) / 1000
                  }
                  disabled={true}
                  fullWidth
                />
              </TableCell>
              <TableCell>
                <TextField
                  label={translate.t('laTotal')}
                  value={this.getTotal('personalGoals')}
                  disabled={true}
                  fullWidth
                />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </>
    );
  }
}

const mapStateToProps = (state: ReducerState) => {
  return {
    allCommonGoals: state.goalEvaluations.get('allCommonGoals'),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openConfirmDialog: (payload: ConfirmDialogType) =>
    dispatch(openConfirmDialog(payload)),
});

const enhance = compose<any, any>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(componentListingStyles),
);

export default enhance(ComponentListing);

import {
  Button,
  Grid,
  IconButton,
  InputBase,
  Paper,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { Clear, Search } from '@material-ui/icons';
import React, { ChangeEvent } from 'react';
import { compose } from 'recompose';

import translate from '@/app/utils/translate';
import styles from './styles';

type OwnProps = {
  value?: string;
  onChange?: (query: string) => void;
  onSelectAll?: () => void;
  onDeselectAll?: () => void;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const SearchTemplates = (props: Props) => {
  const {
    classes,
    onChange,
    onSelectAll,
    onDeselectAll,
    value,
  } = props;

  const handleOnSearch = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(e.target.value);
  };

  const onClearSearch = () => {
    onChange('');
  };

  return (
    <Grid container alignItems="center" justify="space-between" spacing={8}>
      <Grid item className={classes.fullFlex}>
        <Paper className={classes.root} elevation={1}>
          <Search className={classes.iconButton} />
          <InputBase
            value={value}
            onChange={handleOnSearch}
            className={classes.input}
            placeholder={translate.t('laSearch')}
          />
          {!!value && (
            <IconButton
              className={classes.iconButton}
              onClick={onClearSearch}
            >
              <Clear />
            </IconButton>
          )}
        </Paper>
      </Grid>
      {!!onSelectAll && (
        <Grid item>
          <Button onClick={onSelectAll} variant="contained">
            {translate.t('laSelectAll')}
          </Button>
        </Grid>
      )}
      {!!onDeselectAll && (
        <Grid item>
          <Button onClick={onDeselectAll} variant="contained">
            {translate.t('laUnSelectAll')}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

const enhance = compose<Props, OwnProps>(
  withStyles(styles),
);

export default enhance(SearchTemplates);

import React, { useCallback, useContext, ChangeEvent } from 'react';
import { Field, FormikProps, FormikValues } from 'formik';
import { Grid } from '@material-ui/core';
import { EmployeeTraining, OptionType, TrainingType } from '../../types';
import { useSnackbar } from 'notistack';
import translate from '@/app/utils/translate';
import * as yup from 'yup';
import Context from '../../context/trainingsContext';
import { FormikSelectField, FormikTextField, FormikDateField, FormikDialog, FormikCheckboxField } from '@/app/components/Formik2';
import { getTrainingGroupName, getInternalTrainingNames, getExternalTrainingNames, getTrainingClasses } from '../../helpers';
import FormikSingleAttachmentField from '../../../Formik2/components/FormikSingleAttachmentField';

type OwnProps = {
  open: boolean;
  initialValues?: EmployeeTraining;
  onClose: () => void;
  isLoggedUserData?: boolean;
};
type Props = OwnProps;

const EmployeeTrainingUpdateDialog = ({
  open,
  initialValues,
  onClose,
  isLoggedUserData,
}: Props) => {
  const {
    addEmployeeTraining,
    updateEmployeeTraining,
    downloadEmployeeTrainingAttachment,
  } = useContext(Context);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = useCallback(
    (values: EmployeeTraining, actions: FormikProps<EmployeeTraining>) => {
      try {
        if (!values.id) {
          addEmployeeTraining(values);
        } else {
          updateEmployeeTraining(values);
        }
        enqueueSnackbar(translate.t('label_employee_training_saved'), { variant: 'success' });
        actions.resetForm({ values: initialValues });
      } catch (e) {
        enqueueSnackbar(
          `${translate.t('label_error_saving_employee_training', { error: e.message })}`,
          { variant: 'error' },
        );
      }
    },
    []
  );

  const handleTrainingGroupChange = (passedForm: FormikProps<FormikValues>) => {
    if (!!passedForm.values.name) {
      passedForm.setFieldTouched('name', true);
      passedForm.setFieldValue('name', null);
    }
  };

  const validationSchema = yup.object<EmployeeTraining>().shape({
    trainingGroup: yup
      .object<OptionType>()
      .required(translate.t('laThisRequired')),
    name: yup
      .object<OptionType>()
      .nullable()
      .required(translate.t('laThisRequired'))
      .test(
        'name-test',
        translate.t('text_validate_max_length', { max: 80 }),
        value => !value || value.value.length <= 80,
      ),
    provider: yup
      .string()
      .required(translate.t('laThisRequired'))
      .max(80, translate.t('text_validate_max_length', { max: 80 })),
    trainingClass: yup.object<OptionType>().nullable(),
    startDate: yup.date().required(translate.t('laThisRequired')),
    endDate: yup
      .date()
      .nullable()
      .when('startDate', (st: Date, schema: yup.DateSchema<Date>) =>
        schema.min(
          new Date(st.setHours(0, 0, 0, 0)),
          translate.t('laCheckEndDate'),
        ),
      ),
    info: yup
      .string()
      .max(80, translate.t('text_validate_max_length', { max: 80 })),
    hours: yup.number().nullable(),
    expires: yup.date().nullable(),
  });

  const title = !initialValues.id ? translate.t('laAddTraining') : translate.t('edit_training');

  return (
    <FormikDialog
      fullWidth={true}
      initialValues={initialValues}
      maxWidth="md"
      open={open}
      title={title}
      validationSchema={validationSchema}
      onClose={onClose}
      onSubmit={handleSubmit}
    >
      {(form: FormikProps<EmployeeTraining>) => {
        const trainingNames = form.values.trainingGroup.value === 'INTERNAL_TRAINING_TYPE'
          ? getInternalTrainingNames().map((item: any) => ({ value: item.code, label: item.name }))
          : getExternalTrainingNames().map((item: any) => ({ value: item.code, label: item.name }));

        const handleAttachmentDownload = () => {
          downloadEmployeeTrainingAttachment(form.values.employeeId, form.values.attachmentId);
        };

        const handleChangeOtherTrainingName = (e: ChangeEvent<HTMLInputElement>) => {
          const value = e.currentTarget.value
            ? { value: e.currentTarget.value, label: e.currentTarget.value }
            : null;

          form.setFieldTouched('name', true);
          form.setFieldValue('name', value);
        };

        const nameField = form.values.trainingGroup.value === 'OTHER_TRAINING_TYPE' ? (
          <Field
            name="name"
            label={translate.t('laTrainingName')}
            component={FormikTextField}
            fullWidth
            required
            value={!form.values.name ? '' : form.values.name.value}
            onChange={handleChangeOtherTrainingName}
          />
        ) : (
          <Field
            name="name"
            label={translate.t('laTrainingName')}
            component={FormikSelectField}
            required
            options={trainingNames}
          />
        );

        const trainingTypes = isLoggedUserData ? [
          TrainingType.EXTERNAL, TrainingType.OTHER
        ] : [
          TrainingType.INTERNAL, TrainingType.EXTERNAL, TrainingType.OTHER
        ];
        const trainingGroupOptions = trainingTypes.map((tt) => ({ value: tt, label: getTrainingGroupName(tt) }));

        return (
          <Grid container spacing={24}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={8}>
                <Grid item xs={12} data-testid="autocomplete-training-type">
                  <Field
                    name="trainingGroup"
                    label={translate.t('label_training_type')}
                    component={FormikSelectField}
                    disabled={!!form.values.id}
                    required
                    // fullWidth
                    options={trainingGroupOptions}
                    onChange={handleTrainingGroupChange}
                  />
                </Grid>
                <Grid item xs={12} data-testid="autocomplete-training-name">
                  {nameField}
                </Grid>
                <Grid item xs={12} data-testid="autocomplete-training-class">
                  <Field
                    name="trainingClass"
                    label={translate.t('training_class')}
                    component={FormikSelectField}
                    clearable
                    options={getTrainingClasses().map((item: any) => ({ value: item.code, label: item.name }))}
                  />
                </Grid>
                <Grid item xs={12} data-testid="autocomplete-training-provider">
                  <Field
                    name="provider"
                    label={translate.t('laProvider')}
                    component={FormikTextField}
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={8}>
                <Grid item xs={6} data-testid="date-training-start-date">
                  <Field
                    name="startDate"
                    label={translate.t('laStartDate')}
                    component={FormikDateField}
                    required
                  />
                </Grid>
                <Grid item xs={6} data-testid="date-training-end-date">
                  <Field
                    name="endDate"
                    label={translate.t('laEndDate')}
                    component={FormikDateField}
                    initialFocusedDate={form.values.startDate}
                    clearable
                  />
                </Grid>
                <Grid item xs={6} data-testid="number-training-hours">
                  <Field
                    name="hours"
                    type="number"
                    label={translate.t('laHours')}
                    component={FormikTextField}
                  />
                </Grid>
                <Grid item xs={6} data-testid="date-training-expires">
                  <Field
                    name="expires"
                    label={translate.t('laExpires')}
                    component={FormikDateField}
                    initialFocusedDate={form.values.startDate}
                    clearable
                  />
                </Grid>
                <Grid item xs={12} data-testid="text-training-info">
                  <Field
                    name="info"
                    label={translate.t('laInfo')}
                    component={FormikTextField}
                    fullWidth
                    multiline
                    rows={1}
                    rowsMax={5}
                  />
                </Grid>
                <Grid item xs={12} data-testid="text-training-info">
                  <Field
                    name="includeInTrainingCompensation"
                    label={translate.t('label_include_in_training_compensation')}
                    component={FormikCheckboxField}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={8}>
                <Grid item xs={12} data-testid="file-training-attachment">
                  <Field
                    name="attachmentId"
                    component={FormikSingleAttachmentField}
                    onDownloadAttachment={handleAttachmentDownload}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    </FormikDialog>
  );
};

export default EmployeeTrainingUpdateDialog;

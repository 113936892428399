import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import moment from 'moment';
import * as React from 'react';

import { getCustomDataGroupName, getEnums } from '@/old/utils/helper';

import { CustomFieldType } from '../../../../transactionsListModels';

import { CustomFieldInnerProps } from './customFieldModels';

const getEnumOptionValue = (group: string, code: string) => {
    const option = getEnums(group).find((o) => o.code === code);
    return option ? option.name : code;
};

const inputIsEnumerable = (customFieldType: CustomFieldType) => {
    return customFieldType === CustomFieldType.ENUM || customFieldType === CustomFieldType.MULTISELECT;
};

export const useCustomField = ({
    customData,
    formValues,
    fieldDetails,
    updateFormValues,
}: CustomFieldInnerProps) => {
    const value = customData && customData[fieldDetails.code];

    const formattedValue: React.ReactNode = React.useMemo(
        () => {
            if (value === undefined || value === '') {
                return value;
            }

            switch (fieldDetails.inputType.value) {
                case CustomFieldType.BOOLEAN: {
                    return value === 'true' ? <CheckBox /> : <CheckBoxOutlineBlank />;
                }
                case CustomFieldType.DATE: {
                    return moment(value).format('L');
                }
                case CustomFieldType.ENUM: {
                    return getEnumOptionValue(
                        getCustomDataGroupName(
                            fieldDetails.groupName,
                            fieldDetails.code,
                        ),
                        value,
                    );
                }
                case CustomFieldType.MULTISELECT: {
                    if (Array.isArray(value)) {
                        return (value as string[]).map((code) => {
                            return getEnumOptionValue(
                                getCustomDataGroupName(
                                    fieldDetails.groupName,
                                    fieldDetails.code,
                                ),
                                code,
                            );
                        }).join(', ');
                    }

                    return value;
                }
                default: {
                    return value;
                }
            }
        },
        [fieldDetails, value],
    );

    const options = React.useMemo(
        () => {
            if (!inputIsEnumerable(fieldDetails.inputType.value)) {
                return [];
            }

            return getEnums(getCustomDataGroupName(
                    fieldDetails.groupName,
                    fieldDetails.code,
                ))
                .filter(({ isActive }) => isActive !== false)
                .map(({ name, code }) => ({ label: name, value: code }));
        },
        [fieldDetails],
    );

    const inputValue = formValues && formValues[fieldDetails.code];

    const selectedOption = React.useMemo(
        () => {
            if (!inputIsEnumerable(fieldDetails.inputType.value)) {
                return null;
            }

            return Array.isArray(inputValue)
                ? options.filter((o) => inputValue.includes(o.value))
                : options.find((o) => o.value === inputValue);
        },
        [fieldDetails, inputValue, options],
    );

    const handleChange = React.useCallback(
        (event: unknown, ...otherArgs: unknown[]) => {
            switch (fieldDetails.inputType.value) {
                case CustomFieldType.BOOLEAN: {
                    const [checked] = otherArgs as [boolean];

                    updateFormValues({
                        [fieldDetails.code]: checked ? 'true' : undefined,
                    });

                    return;
                }
                case CustomFieldType.DATE: {
                    updateFormValues({
                        [fieldDetails.code]: event && (event as moment.Moment).format() || undefined,
                    });

                    return;
                }
                case CustomFieldType.ENUM: {
                    updateFormValues({
                        [fieldDetails.code]: event && (event as { value: string }).value || undefined,
                    });

                    return;
                }
                case CustomFieldType.MULTISELECT: {
                    updateFormValues({
                        [fieldDetails.code]: event && (event as Array<{ value: string }>)
                            .map((o) => o.value) || [],
                    });

                    return;
                }
                default: {
                    updateFormValues({
                        [fieldDetails.code]: (event as React.ChangeEvent<HTMLInputElement>).target.value,
                    });
                }
            }
        },
        [fieldDetails, updateFormValues],
    );

    const handleClearValue = React.useCallback(
        () => {
            updateFormValues({
                [fieldDetails.code]: undefined,
            });
        },
        [fieldDetails, updateFormValues]
    );

    return {
        formattedValue,
        options,
        inputValue,
        selectedOption,
        handleChange,
        handleClearValue,
    };
};

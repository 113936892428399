import * as React from 'react';
import {
  Button,
  Chip,
  CircularProgress,
  createMuiTheme,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Icon,
  IconButton,
  MuiThemeProvider,
  Paper,
  Snackbar,
  SnackbarContent,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';

import { compose } from 'recompose';

import API from '@/app/api/internalAPIs';
import ConfirmDialog from '@/app/components/ConfirmDialog/ConfirmDialog';
import { getFormData } from '@/app/utils/helper';
import Service from '@/app/utils/service';
import translate from '@/app/utils/translate';

import {
  mapWorklistForBE,
  mapWorklistsForUI,
  mapWorklistForUI,
  mapWorklistTaskForBE,
  mapWorklistTaskForUI,
} from './service';
import {
  DueDaysAutomation,
  IWorklist,
  IWorklistTask,
  EmployeeAutomation,
} from './types';
import styles from './worklists/styles';
import WorklistEditDialog from './worklists/WorklistEditDialog';
import WorklistMenu from './worklists/WorklistMenu';
import TaskTable from './worklists/TaskTable';
import TaskEditDialog from './worklists/TaskEditDialog';

import { getEnums } from '@/old/utils/helper';

const InactiveChip = (props: any) => {
  const inactiveTheme = createMuiTheme({
    palette: {
      primary: {
        main: red[600],
      },
    },
    typography: { useNextVariants: true },
  });

  return (
    <MuiThemeProvider theme={inactiveTheme}>
      <Chip {...props} />
    </MuiThemeProvider>
  );
};

const emptyWorklist = {
  title: '',
  description: '',
};

const emptyWorklistTask = {
  worklistId: 0,
  title: '',
  description: '',
  type: '',
  dueDateAutomationDays: 0,
  dueDateAutomationType: 'NONE' as DueDaysAutomation,
  assigneeAutomation: 'NONE' as EmployeeAutomation,
  pertainingToAutomation: 'NONE' as EmployeeAutomation,
  visibleToPertainingTo: false,
};

const sortByStringProp = (prop: string, a: any, b: any) => {
  return a[prop].localeCompare(b[prop]);
};

const sortByName = (a: any, b: any) => {
  return sortByStringProp('name', a, b);
};

const sortByTitle = (a: IWorklist, b: IWorklist) => {
  return sortByStringProp('title', a, b);
};

const getTaskTypes = () => {
  return getEnums('COLLAB_TASK_TYPE').filter((item: any) => item.isActive);
};

type OwnProps = {};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const Worklists = (props: Props) => {
  const { classes } = props;
  const taskTypes = getTaskTypes();

  const [snackbar, setSnackbar] = React.useState<any>();
  const [worklists, setWorklists] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    Service.get(
      API.worklists.all,
      (res: any) => {
        setWorklists(mapWorklistsForUI(res));
        setLoading(false);
        setError('');
      },
      (err: any) => {
        setWorklists([]);
        setLoading(false);
        setError(err);
      },
    );
  }, []);

  const [expandedWorklistId, setExpandedWorklistId] = React.useState<any>();
  const [worklistDetails, setWorklistDetails] = React.useState<any>();
  const [worklistDetailsLoading, setWorklistDetailsLoading] = React.useState(
    false,
  );
  const [worklistDetailsError, setWorklistDetailsError] = React.useState<any>();
  const loadWorklistDetails = React.useCallback((id: number) => {
    setWorklistDetailsLoading(true);
    setWorklistDetailsError(undefined);
    setExpandedWorklistId(id);
    Service.get(
      API.worklists.get(id),
      (res: any) => {
        setWorklistDetails(mapWorklistForUI(res));
        setWorklistDetailsLoading(false);
      },
      (err: any) => {
        setWorklistDetailsError(err);
        setWorklistDetailsLoading(false);
      },
    );
  }, []);

  const updateTask = React.useCallback(
    async (values: IWorklistTask) => {
      const { formData } = getFormData(mapWorklistTaskForBE(values));
      await Service.putFormData(
        API.worklists.updateTask(values.worklistId, values.id),
        formData,
        (res: any) => {
          const updatedTask = mapWorklistTaskForUI(res);
          setWorklistDetails((prevDetails: any) => {
            const updatedDetails = Object.assign({}, prevDetails);
            updatedDetails.tasks = prevDetails.tasks.map((item: any) => {
              if (item.id === values.id) {
                return updatedTask;
              }
              return item;
            });
            return updatedDetails;
          });
          setSnackbar({
            root: {
              autoHideDuration: 3000,
            },
            content: {
              className: classes.successSnackbar,
              message: translate.t('text_item_updated'),
            },
          });
        },
        (err: any) => {
          console.error('PUT ERROR', err);
        },
      );
    },
    [setWorklistDetails, setSnackbar],
  );
  const addTask = React.useCallback(
    async (values: IWorklistTask) => {
      const { formData } = getFormData(mapWorklistTaskForBE(values));
      await Service.postFormData(
        API.worklists.addTask(values.worklistId),
        formData,
        (res: any) => {
          const addedTask = mapWorklistTaskForUI(res);
          setWorklistDetails((prevDetails: IWorklist) => {
            const updatedDetails = prevDetails;
            updatedDetails.tasks.push(addedTask);
            return updatedDetails;
          });
          setSnackbar({
            root: {
              autoHideDuration: 3000,
            },
            content: {
              className: classes.successSnackbar,
              message: translate.t('text_item_added'),
            },
          });
        },
        (err: any) => {
          console.error('POST ERROR', err);
        },
      );
    },
    [setWorklistDetails, setSnackbar],
  );

  const [worklistToDelete, setWorklistToDelete] = React.useState<any>();
  const [worklistDialogOpen, setWorklistDialogOpen] = React.useState(false);
  const [addTaskWorklistId, setAddTaskWorklistId] = React.useState<any>();

  const [worklistMenuAnchorEl, setWorklistMenuAnchorEl] = React.useState<any>();
  const handleWorklistMenuClose = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setWorklistMenuAnchorEl(undefined);
  };

  const handleStartWorklistAdd = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setExpandedWorklistId(undefined);
    setWorklistDetails(undefined);
    setWorklistDialogOpen(true);
  };

  const handleWorklistDialogClose = () => setWorklistDialogOpen(false);

  const handleWorklistSave = (values: IWorklist) => {
    // Add new, if id was not set
    if (!values.id) {
      Service.post(
        API.worklists.add,
        mapWorklistForBE(values),
        (res: any) => {
          const addedWorklist = mapWorklistsForUI([res])[0];
          setWorklists(prevWorklists => {
            const updatedWorklists = prevWorklists;
            updatedWorklists.push(addedWorklist);
            return updatedWorklists;
          });
          setExpandedWorklistId(addedWorklist.id);
          loadWorklistDetails(addedWorklist.id);
          setSnackbar({
            root: {
              autoHideDuration: 3000,
            },
            content: {
              className: classes.successSnackbar,
              message: translate.t('text_item_added'),
            },
          });
        },
        (err: any) => {
          console.error('POST ERROR', err);
        },
      );

      // Update existing, if id was set
    } else {
      Service.put(
        API.worklists.update(values.id),
        mapWorklistForBE(values),
        (res: any) => {
          setWorklists(prevWorklists => {
            const updatedWorklist = mapWorklistsForUI([res])[0];
            const wlDetails = Object.assign({}, worklistDetails);
            wlDetails.title = updatedWorklist.title;
            wlDetails.description = updatedWorklist.description;
            setWorklistDetails(wlDetails);
            return prevWorklists.map(prevWorklist => {
              if (prevWorklist.id === values.id) {
                return updatedWorklist;
              }
              return prevWorklist;
            });
          });
          setSnackbar({
            root: {
              autoHideDuration: 3000,
            },
            content: {
              className: classes.successSnackbar,
              message: translate.t('text_item_updated'),
            },
          });
        },
        (err: any) => {
          console.error('PUT ERROR', err);
        },
      );
    }
  };

  const renderWorklists = () => {
    if (!worklists || worklists.length === 0) {
      return <Paper elevation={0}>{translate.t('text_no_worklists')}</Paper>;
    }

    return worklists.sort(sortByTitle).map((worklist: IWorklist) => {
      const toggleExpanded = () => {
        if (worklist.id === expandedWorklistId) {
          setExpandedWorklistId(undefined);
        } else {
          loadWorklistDetails(worklist.id);
        }
      };

      const handleStartWorklistEdit = (e: React.SyntheticEvent) => {
        e.stopPropagation();
        setWorklistMenuAnchorEl(undefined);
        setWorklistDialogOpen(true);
      };

      const handleWorklistMenuOpen = (e: React.SyntheticEvent) => {
        e.stopPropagation();
        setWorklistMenuAnchorEl(e.target);
      };

      const handleAddTaskOpen = (e: React.SyntheticEvent) => {
        e.stopPropagation();
        setAddTaskWorklistId(worklist.id);
      };

      const handleWorklistActivate = (e: React.SyntheticEvent) => {
        e.stopPropagation();
        const updatedWorklist = Object.assign({}, worklist);
        updatedWorklist.active = true;
        Service.put(
          API.worklists.update(worklist.id),
          {
            ...mapWorklistForBE(updatedWorklist),
          },
          (res: any) => {
            setWorklists(prevWorklists => {
              return prevWorklists.map(prevWorklist => {
                if (worklist.id === prevWorklist.id) {
                  return mapWorklistForUI(res);
                }
                return prevWorklist;
              });
            });
            setWorklistMenuAnchorEl(undefined);
          },
          (err: any) => {
            console.error('UPDATE ERROR', err);
          },
        );
      };

      const handleWorklistDeactivate = (e: React.SyntheticEvent) => {
        e.stopPropagation();
        const updatedWorklist = Object.assign({}, worklist);
        updatedWorklist.active = false;
        Service.put(
          API.worklists.update(worklist.id),
          {
            ...mapWorklistForBE(updatedWorklist),
          },
          (res: any) => {
            setWorklists(prevWorklists => {
              return prevWorklists.map(prevWorklist => {
                if (worklist.id === prevWorklist.id) {
                  return mapWorklistForUI(res);
                }
                return prevWorklist;
              });
            });
            setWorklistMenuAnchorEl(undefined);
          },
          (err: any) => {
            console.error('UPDATE ERROR', err);
          },
        );
      };

      const handleStartWorklistDelete = (e: React.SyntheticEvent) => {
        e.stopPropagation();
        setWorklistToDelete(worklist);
      };

      const handleCancelWorklistDelete = (e: React.SyntheticEvent) => {
        e.stopPropagation();
        setWorklistMenuAnchorEl(undefined);
        setWorklistToDelete(undefined);
      };

      const handleWorklistDelete = (e: React.SyntheticEvent) => {
        e.stopPropagation();
        Service.delete(
          API.worklists.delete,
          {
            fWorklistIds: [worklist.id],
          },
          (_res: any) => {
            setWorklists(prevWorklists =>
              prevWorklists.filter(a => a.id !== worklist.id),
            );
            setWorklistToDelete(undefined);
            setExpandedWorklistId(undefined);
            setWorklistDetails(undefined);
            setSnackbar({
              root: {
                autoHideDuration: 3000,
              },
              content: {
                className: classes.successSnackbar,
                message: translate.t('text_item_deleted'),
              },
            });
          },
          (err: any) => {
            console.error('DELETE ERROR', err);
          },
        );
      };

      const handleTaskDelete = (id: number) => {
        Service.delete(
          API.worklists.deleteTask(worklist.id),
          { fWorklistIds: [id] },
          (_res: any) => {
            setWorklistDetails((prevWl: IWorklist) => {
              const updatedTasks = prevWl.tasks.filter(
                (task: IWorklistTask) => task.id !== id,
              );
              return {
                ...prevWl,
                tasks: updatedTasks,
              };
            });
            setSnackbar({
              root: {
                autoHideDuration: 3000,
              },
              content: {
                className: classes.successSnackbar,
                message: translate.t('text_item_deleted'),
              },
            });
          },
          (err: any) => {
            console.error('DELETE ERROR', err);
          },
        );
      };

      const handleTaskAdd = async (values: IWorklistTask) =>
        await addTask(values);
      const handleTaskUpdate = async (values: IWorklistTask) =>
        await updateTask(values);

      const deleteWorklistConfirmDialog = !worklistToDelete ? (
        undefined
      ) : (
        <ConfirmDialog
          open={Boolean(worklistToDelete)}
          text={translate.t('text_confirm_worklist_delete', {
            title: worklist.title,
          })}
          onOk={handleWorklistDelete}
          onClose={handleCancelWorklistDelete}
        />
      );

      const addTaskDialog =
        !addTaskWorklistId || worklist.id !== expandedWorklistId ? (
          undefined
        ) : (
          <TaskEditDialog
            open={true}
            initialValues={{
              ...emptyWorklistTask,
              worklistId: worklist.id,
              type: taskTypes[0].code || '',
            }}
            taskTypes={taskTypes.sort(sortByName)}
            onSave={handleTaskAdd}
            onClose={() => setAddTaskWorklistId(undefined)}
          />
        );

      const expandedTools =
        worklist.id === expandedWorklistId ? (
          <>
            {deleteWorklistConfirmDialog}
            <div className={classes.worklistMenuButtonContainer}>
              <WorklistMenu
                anchorEl={worklistMenuAnchorEl}
                showActivate={
                  !worklist.active &&
                  worklistDetails &&
                  worklistDetails.tasks &&
                  worklistDetails.tasks.length > 0
                }
                showDeactivate={!!worklist.active}
                onActivate={handleWorklistActivate}
                onDeactivate={handleWorklistDeactivate}
                onRename={handleStartWorklistEdit}
                onDelete={handleStartWorklistDelete}
                onClose={handleWorklistMenuClose}
              />
              <IconButton color="primary" onClick={handleWorklistMenuOpen}>
                <Icon>more_vert</Icon>
              </IconButton>
            </div>
            <IconButton color="primary" onClick={handleAddTaskOpen}>
              <Icon>add_circle_outlined</Icon>
            </IconButton>
            <div />
          </>
        ) : (
          <div className={classes.noExpandedTools} />
        );

      return (
        <ExpansionPanel
          key={worklist.id}
          expanded={worklist.id === expandedWorklistId}
          onChange={toggleExpanded}
        >
          <ExpansionPanelSummary
            classes={{ content: classes.expansionPanelSummaryContent }}
            expandIcon={<Icon>expand_more</Icon>}
          >
            <Typography component="span" className={classes.worklistHeading}>
              {worklist.title}
            </Typography>
            {!!worklist.active ? (
              undefined
            ) : (
              <InactiveChip
                className={classes.inactiveChip}
                label={translate.t('label_inactive')}
                color="secondary"
              />
            )}
            {expandedTools}
            {/* {expandedDesc} */}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionPanelDetails}>
            {!worklistDetailsLoading ? (
              undefined
            ) : (
              <CircularProgress size={24} className={classes.loading} />
            )}
            {!worklistDetailsError ? (
              undefined
            ) : (
              <div>{`ERROR loading details: ${worklistDetailsError}`}</div>
            )}
            {!worklistDetails ||
            !!worklistDetailsLoading ||
            !!worklistDetailsError ? (
              undefined
            ) : (
              <>
                {addTaskDialog}
                <Paper elevation={0} className={classes.worklistDesc}>
                  <Typography variant="caption">
                    {worklist.description}
                  </Typography>
                </Paper>
                <div className={classes.flexBreak} />
                <TaskTable
                  tasks={worklistDetails.tasks}
                  onDelete={handleTaskDelete}
                  onUpdate={handleTaskUpdate}
                />
              </>
            )}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    });
  };

  if (loading) {
    return <CircularProgress size={24} className={classes.loading} />;
  }

  if (error) {
    return <div color="error">ERROR: {error}</div>;
  }

  const snackbarEl = !snackbar ? (
    undefined
  ) : (
    <Snackbar
      {...snackbar.root}
      open={true}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      onClose={() => setSnackbar(undefined)}
    >
      <SnackbarContent {...snackbar.content} />
    </Snackbar>
  );

  return (
    <div className={classes.root}>
      {snackbarEl}
      <WorklistEditDialog
        open={worklistDialogOpen}
        initialValues={worklistDetails || emptyWorklist}
        onSave={handleWorklistSave}
        onClose={handleWorklistDialogClose}
      />
      <Button color="primary" onClick={handleStartWorklistAdd}>
        <Icon>add_circle_outlined</Icon>&nbsp;
        {translate.t('label_add_worklist')}
      </Button>
      <div className={classes.worklist}>{renderWorklists()}</div>
    </div>
  );
};

const enhance = compose<Props, OwnProps>(
  withStyles(styles, { withTheme: true }),
);

export default enhance(Worklists);

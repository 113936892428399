import * as React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { LineTooltipProps } from '@nivo/line';
import translate from '@/app/utils/translate';

type OwnProps = {
  menuItems?: React.ReactNodeArray;
  tooltipProps: LineTooltipProps;
};
type Props = OwnProps;

const LineTooltip = ({
  tooltipProps,
  menuItems = [],
}: Props) => {
  const { point } = tooltipProps;

  return (
    <Paper style={{ padding: '8px' }}>
      <Typography variant="subtitle2">{point.data.xFormatted}</Typography>
      <div>
        <div
          style={{
            display: 'inline-block',
            float: 'left',
            backgroundColor: point.serieColor,
            width: 16,
            height: 16,
            marginRight: 8
          }}
        />
        <Typography component="span" noWrap={true} variant="body2">
          {translate.t('laNumberOfEmp')} <b>{point.data.yFormatted}</b>
        </Typography>
        {menuItems}
      </div>
    </Paper>
  );
};

export default LineTooltip;

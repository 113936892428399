import { Map as iMap } from 'immutable';

type ACTION = {
  type: string,
  error: any
};

export const THROW_ERROR = '@@solaforce/error/THROW_ERROR';
export const EMPTY_ERROR = '@@solaforce/error/EMPTY_ERROR';

const initialState = iMap({
  error: null,
});

const reducer = (state = initialState, action: ACTION) => {
  switch (action.type) {
    case THROW_ERROR:
      return state.set('error', action.error);
    case EMPTY_ERROR:
      return state.set('error', null);
    default:
      return state;
  }
};

export const throwError = (error: any) => ({ type: THROW_ERROR, error });
export const emptyError = () => ({ type: EMPTY_ERROR });

export default reducer;

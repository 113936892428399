import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, InjectedFormProps, getFormValues } from 'redux-form';
import compose from 'recompose/compose';
import {
  WithStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import translate from '@/app/utils/translate';
import formDialogStyle from './formDialogStyle';
import { withStyles } from '@material-ui/core/styles';
import {
  loadFormData,
  changeFormData,
  resetFormData,
} from '@/app/redux/formData';
import {
  openConfirmDialog,
  ConfirmDialogType,
} from '@/app/redux/confirmDialog';

/**
 * form: redux form name
 * field: target field to change
 * value: field data
 */
type FormDataType = {
  form: string;
  field: string;
  value: any;
};

let changeDataFn: Function;
let pushDataFn: Function;
let formResetFn: Function;

/**
 * Method for changing the form data
 */
export const changeData = (data: FormDataType) => {
  changeDataFn(data);
};

/**
 * Method for pushing a value to a form data
 */
export const pushData = (data: FormDataType) => {
  pushDataFn(data);
};

export const resetForm = (formName: string) => {
  formResetFn(formName);
};

/**
 * Component for redux-form dialog
 * @param {string} formName - name of the form, used by redux-form for id
 * @param {function} validate - form validation
 *
 */
export const getFormDialog = (
  formName: string,
  validate?: any,
  onChange?: Function,
) => {
  interface MapDispatchToProps {
    loadFormData(form: string, e: any): void;
    changeFormData: (data: FormDataType) => void;
    openConfirmDialog: (payload: ConfirmDialogType) => void;
    resetFormData(form: string): void;
  }

  interface MapStateToProps {
    currentValues: Array<string>;
    shouldOpenConfirm: boolean;
  }

  interface OwnProps {
    title: string;
    open: boolean;
    onClose: () => void;
    formData: object;
    paperWidthMd: string;
    onSubmit?: Function;
    customCloseLabel?: string;
    customSubmitLabel?: string;
    noCloseButton?: boolean;
    preventConfirmDialog?: boolean;
    keepSubmitEnabled?: boolean;
    dialogContentStyling?: string;
  }
  type PropsType = OwnProps &
    InjectedFormProps &
    MapStateToProps &
    MapDispatchToProps &
    WithStyles<typeof formDialogStyle>;

  const FORM_NAME = formName;

  class FormDialog extends React.Component<PropsType> {
    /**
     * !Front alert, this is a HACK method, in order to make ckeditor plugin's inputs working
     */
    handleEntered = (event: any) => {
      event.removeAttribute('tabindex');
    };

    handleClose = (_event: React.MouseEvent<Element>) => {
      const {
        openConfirmDialog: openConfirm,
        shouldOpenConfirm,
        preventConfirmDialog,
      } = this.props;

      if (shouldOpenConfirm && !preventConfirmDialog) {
        openConfirm({
          text: translate.t('text_unsaved_changes'),
          onOk: () => this.closeDialog(),
        });
      } else {
        this.closeDialog();
      }
    };

    closeDialog = () => {
      this.props.onClose();
    };

    componentDidMount() {
      changeDataFn = this.changeData;
      pushDataFn = this.pushData;
      formResetFn = this.props.resetFormData;

      /**
       * Make sure data is loaded into form for those cases when you
       * would like to show the form dialog instantly.
       */
      this.props.loadFormData(FORM_NAME, this.props.formData);
    }

    componentDidUpdate(prevProps: PropsType) {
      if (this.props.open === false && prevProps.open === true) {
        this.props.resetFormData(FORM_NAME);
      }
      if (
        JSON.stringify(this.props.formData) !==
        JSON.stringify(prevProps.formData)
      ) {
        this.props.loadFormData(FORM_NAME, this.props.formData);
      }
    }

    changeData = (data: FormDataType) => {
      this.props.changeFormData(data);
    };

    pushData = (data: FormDataType) => {
      const currentValue = this.props.currentValues[data.field];
      currentValue.push(data.value);

      this.props.changeFormData({
        form: data.form,
        field: data.field,
        value: currentValue,
      });
    };

    render() {
      const {
        title,
        open,
        invalid,
        pristine,
        submitting,
        handleSubmit,
        paperWidthMd,
        classes,
        customCloseLabel,
        customSubmitLabel,
        noCloseButton,
        keepSubmitEnabled = false,
        dialogContentStyling,
      } = this.props;

      const disabled = keepSubmitEnabled
        ? invalid || submitting
        : invalid || pristine || submitting;

      return (
        <Dialog
          open={open}
          onEntered={this.handleEntered}
          disableBackdropClick
          onEscapeKeyDown={this.handleClose}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          className={classes.content}
          maxWidth="md"
          classes={{ paperWidthMd }}
        >
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
              {title}
            </DialogTitle>
            <DialogContent classes={{ root: dialogContentStyling }}>
              {this.props.children}
            </DialogContent>
            <DialogActions
              classes={{
                root: classes.dialogActions,
              }}
            >
              {noCloseButton ? null : (
                <Button
                  variant="text"
                  component="a"
                  onClick={this.handleClose}
                  color="primary"
                >
                  {customCloseLabel
                    ? customCloseLabel
                    : translate.t('laCancel')}
                </Button>
              )}
              {typeof this.props.onSubmit === 'function' ? (
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={disabled}
                >
                  {customSubmitLabel
                    ? customSubmitLabel
                    : translate.t('laSave')}
                </Button>
              ) : null}
            </DialogActions>
          </form>
        </Dialog>
      );
    }
  }

  const mapStateToProps = (state: any) => ({
    initialValues: {},
    currentValues: getFormValues(FORM_NAME)(state),
    shouldOpenConfirm: state.confirmDialog.get('shouldOpen'),
  });

  const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadFormData: (form: string, e: any) =>
      dispatch<any>(loadFormData(form, e)),
    changeFormData: (e: FormDataType) => changeFormData(dispatch, e),
    openConfirmDialog: (payload: ConfirmDialogType) =>
      dispatch(openConfirmDialog(payload)),
    resetFormData: (form: string) => dispatch(resetFormData(form)),
  });

  const enhance = compose<any, OwnProps>(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
      form: FORM_NAME,
      validate,
      updateUnregisteredFields: true,
      forceUnregisterOnUnmount: true,
      enableReinitialize: true,
      onChange: (values) => (onChange ? onChange(values) : values),
    }),
    withStyles(formDialogStyle, { withTheme: true }),
  );

  return enhance(FormDialog);
};

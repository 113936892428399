import { StyleRulesCallback } from '@material-ui/core';

export type DailySummaryRowStyleKeys = 'root' | 'textInput' | 'actionButton' | 'actionButtonIcon' | 'actionButtonsContainer' | 'tooltipContainer';

export const dailySummaryRowStyles: StyleRulesCallback<DailySummaryRowStyleKeys> = (theme) => ({
    root: {
        flexGrow: 1,

        '&>div': {
            alignSelf: 'center',
        },
    },
    textInput: {
        margin: 0,

        '& input': {
            padding: theme.spacing.unit,
        },
    },
    actionButton: {
        // Override UI Legacy styles
        fontSize: '0.8125rem !important',
    },
    actionButtonIcon: {
        fontSize: 20,
    },
    actionButtonsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    tooltipContainer: {
        display: 'inline-block',
    }
});

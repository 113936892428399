import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';

import { ExtraHoursBalance as LocalExtraHoursBalance } from './ExtraHoursBalance';
import { ExtraHoursBalanceInnerProps, ExtraHoursBalanceOuterProps } from './extraHoursBalanceModels';
import { extraHoursBalanceStyles } from './extraHoursBalanceStyles';

export const ExtraHoursBalance = compose<ExtraHoursBalanceInnerProps, ExtraHoursBalanceOuterProps>(
    withStyles(extraHoursBalanceStyles),
)(LocalExtraHoursBalance);

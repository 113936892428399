import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const changeReviewersFormDialogStyle: StyleRulesCallback = (theme: Theme) => {
  return ({
    paperWidthMd: {
      width: '620px',
    },
    card: {
      flex: 1,
    },
    multiSelectContainer: {
      overflow: 'auto',
      maxHeight: '220px',
    },
    button: {
      margin: `${theme.spacing.unit}px 0`,
    }
  });
};

export default changeReviewersFormDialogStyle;

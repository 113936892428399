import translate from '@/app/utils/translate';

export const roles = {
  EMPLOYEE: 'laEmployee',
  HR_STAFF: 'laHR',
  HR_ADMIN: 'laHRAdmin',
  HR_DEV: 'laHRDev',
  MANAGER: 'laManager',
  ALT_MANAGER: 'laAltManager',
  DEPUTY_MANAGER: 'laDeputyManager',
  IT_ADMIN: 'laITAdmin',
};

export default () => {
  return (
    Object.keys(roles)
      .map(roleKey => ({
        id: roleKey,
        name: translate.t(roles[roleKey]),
      }))
      .sort((a, b) => a.name.localeCompare(b.name))
  );
};

import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { WithStyles, Grid, Card, CardContent, Typography, Button } from '@material-ui/core';
import DownArrow from '@material-ui/icons/ArrowDownward';
import { withStyles } from '@material-ui/core/styles';
import translate from '@/app/utils/translate';
import { INPUT_TYPE, getUniqValues } from '@/app/utils/helper';
import FormFields from '@/app/components/FormFields/FormFields';
import scheduleSurveyFormDialogStyle from './scheduleSurveyFormDialogStyle';
import { getFormDialog } from '@/app/components/FormDialog/FormDialog';
import { getFormValues, reset, untouch } from 'redux-form';
import { PartialSurvey, setAudienceCount, resetCount, UnitType, updateEmployeesList, getAllActiveEmployeesForSurveys } from '@/app/redux/surveys';
import { changeFormData, FormDataType } from '@/app/redux/formData';
import { ConfirmDialogType, openConfirmDialog } from '@/app/redux/confirmDialog';
import { getUnitMembers, lookForSubUnits } from '@/old/utils/helper';
import * as moment from 'moment';
import { ReducerState } from '@/app/redux/store';

const FORM_NAME = 'scheduleSurveyForm';

type MapStateToProps = {
  currentValues: PartialSurvey;
  unitList: UnitType[];
  allActiveEmployees: [],
  unitsEmployeesList: any;
  audienceCount: number;
};

type MapDispatchToProps = {
  resetForm: () => void;
  changeFormData: (payload: FormDataType) => void;
  getAllActiveEmployeesForSurveys: () => void;
  setAudienceCount: (units: UnitType[]) => void;
  updateEmployeesList: (allActiveEmployees: any[], employeeList: any[]) => void;
  resetCount: () => void;
  openConfirmDialog: (payload: ConfirmDialogType) => void;
  untouch: (field: string) => void;
};

type OwnProps = {
  title: string;
  open: boolean;
  id: string;
  onClose: () => void;
  onSubmit: (survey: PartialSurvey) => void;
};

type PropsType = OwnProps & WithStyles<typeof scheduleSurveyFormDialogStyle> & MapDispatchToProps & MapStateToProps;

const emptyForm = (id?: string) => {
  return {
    id,
    startDate: '',
    closeDate: '',
    audience: undefined as UnitType[],
    reviewers: [] as UnitType[],
    publishOnClose: false,
  };
};

type StateType = {
  formData: PartialSurvey;
  audience: UnitType[];
};

class ScheduleSurveyFormDialog extends React.Component<PropsType> {
  state: StateType = {
    formData: emptyForm(),
    audience: undefined,
  };
  requiredFields = [
    'startDate',
    'closeDate',
    'audience',
  ];

  validate = (values: PartialSurvey) => {
    const errors: {[k: string]: any} = {};

    this.requiredFields.forEach(field => {
      if (!values[field] || values[field] === '<p>&nbsp;</p>' || values[field] && values[field].length === 0) {
        errors[field] = translate.t('laThisRequired');
      }
    });

    const { startDate, closeDate } = values;
    if (startDate && closeDate && moment(closeDate).isSameOrBefore(startDate)) {
      errors.closeDate = translate.t('error_close_date_before_start_date');
    } else if (closeDate && moment(closeDate).diff(moment(), 'days') <= 0 && moment(closeDate).diff(moment(), 'hours') < 0) {
      errors.closeDate = translate.t('error_close_date_from_tomorrow');
    }

    return errors;
  };

  componentDidMount() {
    this.props.getAllActiveEmployeesForSurveys();
  }

  componentDidUpdate(prevProps: PropsType) {
    const { open, id } = this.props; // resetForm
    const { open: prevOpen, id: prevId } = prevProps;

    if (open && open !== prevOpen && id && id !== prevId) {
      this.setFields(id);
    }
  }

  setFields = (id: string) => {
    this.setState(
      { formData: emptyForm(id) },
      () => {
        this.requiredFields.forEach(field => {
          this.props.untouch(field);
        });
      }
    );
  }

  handleChangeAudience = (event: any) => {
    const { currentValues, allActiveEmployees} = this.props;
    const alreadyAddedEmployeesByUnits: any = [];
    let subUnits: number[] = [];

    const audience: UnitType[] = [];
    Object.values(event).forEach((item: UnitType) => {
      if (typeof item === 'object') {
        if (item.type === 'UNIT') {
          alreadyAddedEmployeesByUnits.push(...item.unitEmployees);
        }
        if (item.type === 'EMPLOYEE') {
          alreadyAddedEmployeesByUnits.push(...[item]);
        }
        audience.push(item);
      }
    });

    if (currentValues.audience && currentValues.audience.length > audience.length) {
      this.props.setAudienceCount(audience);
      this.setState({ audience });
    } else {
      subUnits = lookForSubUnits(audience);

      if (subUnits && subUnits.length > 0) {
        this.props.openConfirmDialog({
          text: translate.t('confirm_add_subunits'),
          onOk: () => {
            subUnits.forEach((subUnit: any) => {
              alreadyAddedEmployeesByUnits.push(...getUnitMembers(subUnit, true));
            });
            this.props.updateEmployeesList(allActiveEmployees, alreadyAddedEmployeesByUnits);
            this.setDataField(audience, subUnits);
          },
          onClose: () => this.setFormAudience(audience),
          customOk: translate.t('laYes'),
          customClose: translate.t('laNo'),
        });
      } else {
        this.props.setAudienceCount(audience);
        this.setState({ audience });
      }
    }
    this.props.updateEmployeesList(allActiveEmployees, alreadyAddedEmployeesByUnits);
  }

  handleChangeReviewers = (event: any) => {
    const { currentValues, allActiveEmployees } = this.props;
    const alreadyAddedReviewersByUnits: any = [];
    let subUnits: number[] = [];
    const reviewers: UnitType[] = [];
    Object.values(event).forEach((item: UnitType) => {
      if (typeof item === 'object') {
        if (item.type === 'UNIT' && item.unitEmployees) {
          alreadyAddedReviewersByUnits.push(...item.unitEmployees);
        }
        if (item.type === 'EMPLOYEE') {
          alreadyAddedReviewersByUnits.push(...[item]);
        }
        reviewers.push(item);
      }
    });

    if (!(currentValues.reviewers && currentValues.reviewers.length > reviewers.length)) {
      subUnits = lookForSubUnits(reviewers);

      if (subUnits && subUnits.length > 0) {
        event.preventDefault();
        this.props.openConfirmDialog({
          text: translate.t('confirm_add_subunits'),
          onOk: () => {
            subUnits.forEach((subUnit: any) => {
              alreadyAddedReviewersByUnits.push(...getUnitMembers(subUnit, true));
            });
            this.props.updateEmployeesList(allActiveEmployees, alreadyAddedReviewersByUnits);
            this.setDataField(reviewers, subUnits, true);
          },
          onClose: () => this.setFormReviewers(reviewers),
          customOk: translate.t('laYes'),
          customClose: translate.t('laNo'),
        });
      }
    }
    this.props.updateEmployeesList(allActiveEmployees, alreadyAddedReviewersByUnits);
  };

  getSubUnitsValuesFromIds = (ids: number[]) => {
    const { unitList } = this.props;
    const values = ids.map((id: number) => (
      unitList.find((unit: UnitType) => parseInt(unit.value, 10) === id)
    ));

    return values;
  };

  setDataField = (unitList: UnitType[], subUnits: number[], review = false) => {
    const subUnitValues = this.getSubUnitsValuesFromIds(subUnits);
    const newUnitList = [...unitList].concat(subUnitValues);
    const units: UnitType[] = getUniqValues(newUnitList);

    if (review) {
      this.setFormReviewers(units);
    } else {
      this.setFormAudience(units);
    }
  };

  setFormAudience = (unitList: UnitType[]) => {
    const { currentValues } = this.props;
    const audience = getUniqValues(unitList);
    this.setState({
      formData: { ...currentValues, audience },
    });

    this.props.setAudienceCount(audience);
    this.setState({ audience });
    this.props.changeFormData({ form: FORM_NAME, field: 'audience', value: audience });
  };

  setFormReviewers = (unitList: UnitType[]) => {
    const { currentValues } = this.props;
    const reviewers = getUniqValues(unitList);

    this.setState({
      formData: { ...currentValues, reviewers },
    });

    this.props.changeFormData({ form: FORM_NAME, field: 'reviewers', value: reviewers });
  };

  setEmptyState = () => {
    const { resetForm, resetCount: resetCnt } = this.props;
    this.setState({
      formData: emptyForm(),
      audience: undefined,
    });
    resetCnt();
    resetForm();
  };

  handleClose = () => {
    this.setEmptyState();
    this.props.onClose();
  };

  handleSubmit = (values: PartialSurvey) => {
    this.props.openConfirmDialog({
      text: translate.t('confirm_schedule_survey'),
      onOk: () => submit()
    });

    const submit = () => {
      this.props.onSubmit(values);
      this.setEmptyState();
    };
  };

  copyAudienceToReviewers = () => {
    this.setFormReviewers(this.state.audience);
  };

  renderTopInputs = () => {
    const topInputs = [
      {
        type: INPUT_TYPE.DATE,
        code: 'startDate',
        name: 'startDate',
        label: translate.t('label_start_date'),
        order: 1,
        required: true,
      }, {
        type: INPUT_TYPE.DATE,
        code: 'closeDate',
        name: 'closeDate',
        label: translate.t('label_close_date'),
        order: 2,
        required: true,
      }, {
        type: INPUT_TYPE.CHECKBOX,
        code: 'publishOnClose',
        name: 'publishOnClose',
        label: translate.t('label_publish_on_close_date'),
        order: 3,
        required: false,
      },
    ];

    return topInputs.map(inpt => (
      <Grid item xs={12} sm={inpt.order === 3 ? 5 : 3} key={inpt.order}>
        <FormFields inputs={[inpt]} />
      </Grid>
    ));
  };

  renderAudience = () => (
    <Grid item xs={12}>
      <FormFields
        inputs={[{
          type: INPUT_TYPE.MULTISELECT,
          code: 'audience',
          name: 'audience',
          label: translate.t('label_audience'),
          childrenInputs: this.props.unitList
            .concat(this.props.audienceCount === 0 ? this.props.allActiveEmployees : this.props.unitsEmployeesList),
          order: 4,
          required: true,
          configs: {
            isSearchable: true,
            isDisabled: false,
            isCreatable: false,
          },
          onChange: (value: any) => this.handleChangeAudience(value),
        }]}
      />
    </Grid>
  );

  renderReviewers = () => (
    <Grid item xs={12}>
      <FormFields
        inputs={[{
          type: INPUT_TYPE.MULTISELECT,
          code: 'reviewers',
          name: 'reviewers',
          label: translate.t('label_reviewers'),
          childrenInputs: this.props.unitList
            .concat(this.state.formData.reviewers.length === 0 ? this.props.allActiveEmployees : this.props.unitsEmployeesList),
          order: 5,
          required: false,
          configs: {
            isSearchable: true,
            isDisabled: false,
            isCreatable: false,
          },
          onChange: (value: any) => this.handleChangeReviewers(value),
        }]}
      />
    </Grid>
  );

  FormDialog = getFormDialog(FORM_NAME, this.validate);

  render() {
    const { title, open, classes, audienceCount } = this.props;
    const { formData } = this.state;
    const { FormDialog } = this;

    return (
      <FormDialog
        title={title}
        open={open}
        onClose={this.handleClose}
        formData={formData}
        paperWidthMd={classes.paperWidthMd}
        onSubmit={this.handleSubmit}
        customSubmitLabel={translate.t('laSchedule')}
        customCloseLabel={translate.t('laCancel')}
      >
        <Grid container className={classes.root} justify="space-between">
          {this.renderTopInputs()}

          <Grid container item xs={12} className={classes.multiSelectContainer}>
            {this.renderAudience()}
          </Grid>
          <Grid container item xs={12} justify="space-between" alignItems="center">
            {audienceCount > 0 ? (
              <div className={classes.cardContainer}>
                <Card className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    <Typography variant="body2">
                      <b>{translate.t('text_note')}</b> {translate.t('text_invitations_sent', {count: audienceCount})}
                    </Typography>
                  </CardContent>
                </Card>

                <Button
                  variant="contained"
                  size="small"
                  className={classes.button}
                  onClick={this.copyAudienceToReviewers}
                >
                  <DownArrow />
                  {translate.t('laCopy')}
                </Button>
              </div>
              ) : null
            }
          </Grid>

          <Grid container item xs={12} className={classes.multiSelectContainer}>
            {this.renderReviewers()}
          </Grid>
        </Grid>
      </FormDialog>
    );
  }
}

const mapStateToProps = (state: ReducerState) => {
  const { unitList, unitsEmployeesList, audienceCount, allActiveEmployees } = state.surveys;

  return {
    currentValues: getFormValues(FORM_NAME)(state),
    unitList,
    unitsEmployeesList,
    audienceCount,
    allActiveEmployees,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetForm: () => dispatch(reset(FORM_NAME)),
  changeFormData: (payload: FormDataType) => changeFormData(dispatch, payload),
  getAllActiveEmployeesForSurveys: () => dispatch(getAllActiveEmployeesForSurveys()),
  updateEmployeesList: (allActiveEmployees: any[], employeeList: any[]) => dispatch(updateEmployeesList(allActiveEmployees, employeeList)),
  setAudienceCount: (units: UnitType[]) => dispatch(setAudienceCount(units)),
  resetCount: () => dispatch(resetCount()),
  openConfirmDialog: (payload: ConfirmDialogType) => dispatch(openConfirmDialog(payload)),
  untouch: (field: string) => dispatch(untouch(FORM_NAME, field)),
});

const enhance = compose<any, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(scheduleSurveyFormDialogStyle, {withTheme: true}),
);

export default enhance(ScheduleSurveyFormDialog);
import { Button, IconButton, WithStyles } from '@material-ui/core';
import classNames from 'classnames';
import * as React from 'react';
import moment, { Moment } from 'moment';

import translate from '@/app/utils/translate';
import LocalizedDatePicker from '@/app/components/Pickers/LocalizedDatePicker';

import { useWeekSelector } from './useWeekSelector';
import { WeekSelectorStyleKeys } from './weekSelectorStyles';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';

export interface WeekSelectorOuterProps {
  week: string;
  onWeekChange: (week: string) => void;
}

export interface WeekSelectorInnerProps
  extends WeekSelectorOuterProps,
    WithStyles<WeekSelectorStyleKeys> {}

export const WeekSelector: React.FC<WeekSelectorInnerProps> = ({
  classes,
  week,
  onWeekChange,
}) => {
  const [calendarValue, setCalendarValue] = React.useState<moment.Moment>(
    moment(),
  );
  const {
    formattedWeek,
    handleWeekChange,
    handlePrevWeekChange,
    handleNextWeekChange,
  } = useWeekSelector(week, onWeekChange, setCalendarValue);

  const onChange = React.useCallback(
    (newDate: Moment) => {
      setCalendarValue(newDate);
      handleWeekChange(newDate);
    },
    [setCalendarValue, handleWeekChange],
  );

  const onResetToCurrentWeek = React.useCallback(() => {
    setCalendarValue(moment());
    handleWeekChange(moment());
  }, [setCalendarValue, handleWeekChange]);

  const renderWrappedWeekDay = (
    date: moment.Moment,
    selectedDate: moment.Moment,
    dayInCurrentMonth: boolean,
  ) => {
    let dateClone = date.clone();
    let selectedDateClone = selectedDate.clone();

    const start = moment(selectedDateClone).startOf('week');
    const end = moment(selectedDateClone)
      .endOf('week')
      .startOf('day');

    const dayIsBetween = moment(dateClone).isBetween(
      moment(start),
      moment(end),
    );
    const isFirstDay = moment(dateClone).isSame(moment(start));
    const isLastDay = moment(dateClone).isSame(moment(end));

    const wrapperClassName = classNames({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    });

    const dayClassName = classNames(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {dateClone.format('D')} </span>
        </IconButton>
      </div>
    );
  };
  return (
    <div className={classes.root}>
      <IconButton color="inherit" onClick={handlePrevWeekChange}>
        <ArrowLeft />
      </IconButton>
      <LocalizedDatePicker
        className={classes.calendar}
        label={translate.t('laWeekPicker')}
        value={calendarValue}
        onChange={onChange}
        renderDay={renderWrappedWeekDay}
        labelFunc={() => formattedWeek}
      />
      <IconButton color="inherit" onClick={handleNextWeekChange}>
        <ArrowRight />
      </IconButton>
      <Button
        variant="outlined"
        className={classes.button}
        onClick={onResetToCurrentWeek}
      >
        {translate.t('laCurrentWeek')}
      </Button>
    </div>
  );
};

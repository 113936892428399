import * as React from 'react';
import API from '@/app/api/internalAPIs';
import { getEmpPicId } from '@/old/utils/helper';
import withStyles from '@material-ui/core/styles/withStyles';
import employeeImageStyle from './employeeImageStyle';

type Size = 'sm' | 'md' | 'lg';

type PropsType = {
  employeeId: number
  size?: Size,
  classes?: any,
  isRound?: boolean,
};

const getEmployeeImageUrl = (employeeId: number): string => {
  return API.employeeImageUrl(getEmpPicId(employeeId));
};

const EmployeeImage: React.FunctionComponent<PropsType> = (props) => {
  // default size is icon
  const { size = 'sm', isRound} = props;
  let classes = `${props.classes[size]}`;

  if (isRound) {
    classes = `${classes} ${props.classes.round}`;
  }

  return (
    <img
      className={classes}
      src={getEmployeeImageUrl(props.employeeId)}
    />
  );
};

export default withStyles(employeeImageStyle)(EmployeeImage);

import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { WithStyles, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import translate from '@/app/utils/translate';
import Service from '@/app/utils/service';
import API from '@/app/api/internalAPIs';
import { INPUT_TYPE, parseStrToDate } from '@/app/utils/helper';
import FormFields, {
  ChildInputType,
} from '@/app/components/FormFields/FormFields';
import trainingFormDialogStyle from './trainingFormDialogStyle';
import {
  Training,
  getTrainingGroupLabel,
  TRAINING_TYPE,
} from '../TrainingList/TrainingList';
import { throwError } from '@/app/redux/error';
import {
  getFormDialog,
  changeData,
} from '@/app/components/FormDialog/FormDialog';
import {
  addNewEnumWithTranslation,
  Enum,
  getEnumName,
  selectAllEnums,
} from '@/app/redux/enums';
import { LanguagesType } from '@/app/redux/languages';

const INTEGER_AND_DECIMAL_REGEX = /^\d+([\.|\,]\d{1,2})?$/g;
const normalizeHours = (value: string) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.match(/\d{1,6}[\.|\,]?([0-9]{1,2})?/);
  if (!onlyNums) {
    return '';
  }

  return onlyNums[0];
};

const validate = (values: any) => {
  let errors: { [k: string]: any } = {};
  const requiredFields = [
    'fEduCompanyTrainingGroup',
    'fEduCompanyTrainingType',
    'fEduCompanyTrainingProvider',
    'fEduCompanyTrainingStartDate',
    'fEduCompanyTrainingDesc',
  ];

  requiredFields.forEach((field) => {
    if (!values[field] || values[field] === '<p>&nbsp;</p>') {
      errors[field] = translate.t('laThisRequired');
    }
  });

  if (values.fEduCompanyTrainingHours) {
    const match = `${values.fEduCompanyTrainingHours}`.match(
      INTEGER_AND_DECIMAL_REGEX,
    );
    errors.fEduCompanyTrainingHours = match
      ? null
      : translate.t('laErrorField');
  }

  if (
    values.fEduCompanyTrainingType &&
    values.fEduCompanyTrainingType.label.length > 40
  ) {
    errors.fEduCompanyTrainingType = `${translate.t('laErrorMaxTextLen1')} 40`;
  }

  if (
    values.fEduCompanyTrainingClassFK &&
    values.fEduCompanyTrainingClassFK.length > 80
  ) {
    errors.fEduCompanyTrainingClassFK = `${translate.t(
      'laErrorMaxTextLen1',
    )} 80`;
  }

  if (
    values.fEduCompanyTrainingProvider &&
    values.fEduCompanyTrainingProvider.length > 80
  ) {
    errors.fEduCompanyTrainingProvider = `${translate.t(
      'laErrorMaxTextLen1',
    )} 80`;
  }

  if (
    values.fEduCompanyTrainingDesc &&
    values.fEduCompanyTrainingDesc.length > 20000
  ) {
    errors.fEduCompanyTrainingDesc = `${translate.t(
      'laErrorMaxTextLen1',
    )} 20000`;
  }

  if (
    values.fEduCompanyTrainingStartDate &&
    values.fEduCompanyTrainingEndDate &&
    values.fEduCompanyTrainingEndDate < values.fEduCompanyTrainingStartDate
  ) {
    errors.fEduCompanyTrainingStartDate = translate.t('laCheckEndDate');
    errors.fEduCompanyTrainingEndDate = translate.t('laCheckEndDate');
  }

  return errors;
};

const FORM_NAME = 'trainingForm';
const FormDialog = getFormDialog(FORM_NAME, validate);

interface MapStateToProps {
  allEnums: object;
  allLanguages: Array<LanguagesType>;
}

interface MapDispatchToProps {
  throwError: any;
  addNewEnumWithTranslation: any;
}

interface OwnProps {
  title: string;
  open: boolean;
  onClose: () => void;
  onSubmit: Function;
}

type PropsType = OwnProps &
  WithStyles<typeof trainingFormDialogStyle> &
  MapDispatchToProps &
  MapStateToProps;

type StateType = {
  trainingNames: Array<ChildInputType>;
  trainingClasses: Array<ChildInputType>;
  isDisableTrainingNamesSelect: boolean;
  endMinDate: Date;
  trainingNameEnum: Enum;
  formData: object; // data for editing
};

let loadTrainingFn: Function;

class TrainingFormDialog extends React.Component<PropsType> {
  state: StateType = {
    trainingNames: [],
    trainingClasses: [],
    isDisableTrainingNamesSelect: true,
    endMinDate: new Date(),
    trainingNameEnum: null,
    formData: null,
  };

  groupType: TRAINING_TYPE;

  loadTraining = (training: Training) => {
    if (!training) {
      this.setState({ formData: null });
    } else {
      this.groupType = training.rawData.fEduCompanyTrainingGroup;
      // load training names dropdown
      this.getNamesByType();
      const fEduCompanyTrainingGroup = {
        label: getTrainingGroupLabel(training.rawData.fEduCompanyTrainingGroup),
        value: training.rawData.fEduCompanyTrainingGroup,
      };
      const fEduCompanyTrainingType = {
        label: training.rawData.fEduCompanyTrainingName,
        value: training.rawData.fEduCompanyTrainingType,
      };
      const fEduCompanyTrainingClassFK = {
        label: getEnumName(
          'TRAINING_CLASS',
          training.rawData.fEduCompanyTrainingClassFK,
          this.props.allEnums,
        ),
        value: training.rawData.fEduCompanyTrainingClassFK,
      };
      if (training.rawData.fEduCompanyTrainingStartDate) {
        const startDate = parseStrToDate(
          training.rawData.fEduCompanyTrainingStartDate,
        );
        if (startDate < new Date()) {
          this.setState({
            endMinDate: startDate,
          });
        }
      }
      const formData = {
        fEduCompanyTrainingId: training.rawData.fEduCompanyTrainingId,
        fEduCompanyTrainingGroup,
        fEduCompanyTrainingType,
        fEduCompanyTrainingProvider:
          training.rawData.fEduCompanyTrainingProvider,
        fEduCompanyTrainingClassFK: fEduCompanyTrainingClassFK,
        fEduCompanyTrainingStartDate: parseStrToDate(
          training.rawData.fEduCompanyTrainingStartDate,
        ),
        fEduCompanyTrainingEndDate: parseStrToDate(
          training.rawData.fEduCompanyTrainingEndDate,
        ),
        fEduCompanyTrainingHours: training.rawData.fEduCompanyTrainingHours,
        fEduCompanyTrainingActive: training.rawData.fEduCompanyTrainingActive,
        fEduCompanyTrainingDesc: training.rawData.fEduCompanyTrainingDesc,
      };
      this.setState({ formData });
    }
  };

  componentDidMount() {
    loadTrainingFn = this.loadTraining;
    this.listTrainingClasses();
  }

  componentDidUpdate() {
    const { allEnums } = this.props;
    const { trainingNameEnum } = this.state;

    if (!allEnums) {
      return;
    }

    if (trainingNameEnum && allEnums[this.groupType]) {
      this.handleNewEnumAdd(
        allEnums[this.groupType],
        trainingNameEnum,
        'fEduCompanyTrainingType',
      );
    }
  }

  handleNewEnumAdd(enums: Enum[], newEnum: Enum, field: string) {
    const selectedType = enums.find((d: any) => d.name === newEnum.name);
    if (!selectedType) {
      return;
    }

    if (field === 'fEduCompanyTrainingType') {
      this.setStateTrainingNames(enums);
      this.setState({ trainingNameEnum: null });
    }

    changeData({
      form: FORM_NAME,
      field,
      value: { label: selectedType.name, value: selectedType.code },
    });
  }

  getChildrenInputs = (data: any): Array<ChildInputType> => {
    if (data instanceof Array) {
      let newChildrenInputs: Array<ChildInputType> = [];
      data.forEach((d: any) => {
        newChildrenInputs.push({
          label: d.name,
          value: d.code,
        });
      });
      return newChildrenInputs;
    }
    return [];
  };

  setStateTrainingClasses = (data: any) => {
    this.setState({
      trainingClasses: this.getChildrenInputs(data),
    });
  };

  setStateTrainingNames = (data: any) => {
    this.setState({
      trainingNames: this.getChildrenInputs(data),
      isDisableTrainingNamesSelect: false,
    });
  };

  addTrainingNameHandler = (inputValue: string) => {
    const selectValue = { label: inputValue, value: inputValue };
    const validationResults = validate({
      fEduCompanyTrainingType: selectValue,
    });
    changeData({
      form: FORM_NAME,
      field: 'fEduCompanyTrainingType',
      value: selectValue,
    });

    if (validationResults.fEduCompanyTrainingType) {
      return;
    }

    const { allLanguages } = this.props;
    const trainingNameEnum = { code: '_', name: inputValue };
    const keys: Array<string> = Object.keys(selectValue);
    const filteredKeys = keys.filter((key) =>
      allLanguages.some((la: LanguagesType) => la.code === key),
    );
    const languages = filteredKeys.map((key) => ({
      lang: key,
      name: selectValue[key],
    }));

    this.props.addNewEnumWithTranslation(
      trainingNameEnum,
      languages,
      this.groupType,
    );
    this.setState({ trainingNameEnum });
  };

  addTrainingClassHandler = (inputValue: string) => {
    let payload = this.state.trainingClasses.map((trainingClass) => ({
      code: trainingClass.value,
      name: trainingClass.label,
    }));
    payload.push({ code: '_', name: inputValue });
    Service.put(
      API.enum.group('TRAINING_CLASS'),
      payload,
      (data: any) => {
        if (data instanceof Array) {
          this.setStateTrainingClasses(data);
          const selectedType = data.find((d: any) => d.name === inputValue);
          changeData({
            form: FORM_NAME,
            field: 'fEduCompanyTrainingClassFK',
            value: { label: selectedType.name, value: selectedType.code },
          });
        }
      },
      (error: any) => this.props.throwError(error),
    );
  };

  getNamesByType = () => {
    Service.get(
      API.training.listNamesByType(this.groupType),
      (data: any) => {
        this.setStateTrainingNames(data);
        this.setState({
          isDisableTrainingNamesSelect: false,
        });
      },
      (error: any) => this.props.throwError(error),
    );
  };

  listTrainingClasses = () => {
    Service.get(
      API.enum.listValuesByGroup('TRAINING_CLASS'),
      (data: any) => {
        this.setStateTrainingClasses(data);
      },
      (error: any) => this.props.throwError(error),
    );
  };

  groupChangeHandler = (
    _event: React.FormEvent<HTMLSelectElement>,
    newValue: any,
  ) => {
    if (newValue) {
      this.groupType = newValue.value;
      this.getNamesByType();
      changeData({
        form: FORM_NAME,
        field: 'fEduCompanyTrainingType',
        value: null,
      });
    }
  };

  onClose = () => {
    this.setState({ isDisableTrainingNamesSelect: true });
    this.props.onClose();
  };

  startDateChangeHandler = (_event: any, newValue: Date) => {
    if (newValue) {
      this.setState({ endMinDate: newValue });
    }
  };

  render() {
    const { title, open, classes, onSubmit } = this.props;
    const {
      trainingNames,
      trainingClasses,
      isDisableTrainingNamesSelect,
      endMinDate,
      formData,
    } = this.state;

    const leftInputs = (
      <>
        <FormFields
          inputs={[
            {
              type: INPUT_TYPE.SELECT,
              code: 'fEduCompanyTrainingGroup',
              name: 'fEduCompanyTrainingGroup',
              label: translate.t('laType'),
              childrenInputs: [
                {
                  label: getTrainingGroupLabel('INTERNAL_TRAINING_TYPE'),
                  value: 'INTERNAL_TRAINING_TYPE',
                },
                {
                  label: getTrainingGroupLabel('EXTERNAL_TRAINING_TYPE'),
                  value: 'EXTERNAL_TRAINING_TYPE',
                },
              ],
              order: 1,
              required: true,
              onChange: this.groupChangeHandler,
            },
          ]}
        />
        <FormFields
          inputs={[
            {
              type: INPUT_TYPE.SELECT,
              code: 'fEduCompanyTrainingType',
              name: 'fEduCompanyTrainingType',
              label: translate.t('laTrainingName'),
              childrenInputs: trainingNames,
              order: 2,
              required: true,
              configs: {
                isSearchable: true,
                isDisabled: isDisableTrainingNamesSelect,
                isCreatable: true,
                onCreateOption: this.addTrainingNameHandler, // event for creating new option
              },
            },
          ]}
        />
        <FormFields
          inputs={[
            {
              type: INPUT_TYPE.TEXT,
              code: 'fEduCompanyTrainingProvider',
              name: 'fEduCompanyTrainingProvider',
              label: translate.t('laProvider'),
              order: 3,
              required: true,
            },
            {
              type: INPUT_TYPE.SELECT,
              code: 'fEduCompanyTrainingClassFK',
              name: 'fEduCompanyTrainingClassFK',
              label: translate.t('training_class'),
              childrenInputs: trainingClasses,
              order: 3.5,
              configs: {
                isSearchable: true,
                isCreatable: false,
                onCreateOption: this.addTrainingClassHandler, // event for creating new option
              },
            },
            {
              type: INPUT_TYPE.DATE,
              code: 'fEduCompanyTrainingStartDate',
              name: 'fEduCompanyTrainingStartDate',
              label: translate.t('laStartDate'),
              order: 4,
              required: true,
              onChange: this.startDateChangeHandler,
            },
            {
              type: INPUT_TYPE.DATE,
              code: 'fEduCompanyTrainingEndDate',
              name: 'fEduCompanyTrainingEndDate',
              label: translate.t('laEndDate'),
              order: 5,
              required: false,
              configs: {
                minDate: endMinDate,
              },
            },
            {
              type: INPUT_TYPE.TEXT,
              code: 'fEduCompanyTrainingHours',
              name: 'fEduCompanyTrainingHours',
              label: translate.t('laHours'),
              order: 6,
              required: false,
              configs: {
                normalize: normalizeHours,
              },
            },
            {
              type: INPUT_TYPE.SWITCH,
              code: 'fEduCompanyTrainingActive',
              name: 'fEduCompanyTrainingActive',
              label: translate.t('laActive'),
              order: 7,
              required: false,
            },
          ]}
        />
      </>
    );

    const rightInputs = (
      <FormFields
        inputs={[
          {
            type: INPUT_TYPE.RICHTEXT,
            code: 'fEduCompanyTrainingDesc',
            name: 'fEduCompanyTrainingDesc',
            label: translate.t('laDescription'),
            order: 1,
            required: true,
          },
        ]}
      />
    );

    return (
      <FormDialog
        title={title}
        open={open}
        onClose={this.onClose}
        formData={formData}
        paperWidthMd={classes.paperWidthMd}
        onSubmit={onSubmit}
      >
        <Grid container>
          <Grid item xs={12} sm={12} md={5}>
            {leftInputs}
          </Grid>
          <Grid item xs={12} sm={12} md={7} className={classes.gridRight}>
            {rightInputs}
          </Grid>
        </Grid>
      </FormDialog>
    );
  }
}

const mapStateToProps = (state: any) => ({
  allEnums: selectAllEnums(state),
  allLanguages: state.languages.get('allLanguages'),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ throwError, addNewEnumWithTranslation }, dispatch);

const enhance = compose<any, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(trainingFormDialogStyle, { withTheme: true }),
);

export const loadTraining = (training: Training) => {
  loadTrainingFn(training);
};

export default enhance(TrainingFormDialog);

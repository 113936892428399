import translate from '@/app/utils/translate';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Service from '@/app/utils/service';
import API from '@/app/api/internalAPIs';
import { useSnackbar } from 'notistack';

import { getCompanyLanguages } from '@/old/utils/helper';
import { getCurrentLanguage } from '@/app/utils/helper';

type DataType = {
  language: string;
  title: string;
  description: string;
};

export type DescriptionTranslateProps = {
  open: boolean;
  proposalId: string;
  onClose: () => void;
  classes: any;
};
type Props = DescriptionTranslateProps;

const DescriptionTranslateDialog = ({
  proposalId,
  open,
  onClose,
}: Props) => {
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [language, setLanguage] = useState<string>(getCurrentLanguage());
  const [disabledSave, setDisabledSave] = useState<boolean>(true);
  const [translations, setTranslations] = useState<DataType[]>();
  const { enqueueSnackbar } = useSnackbar();

  const loadData = () => {
    Service.get(
      API.translate.getProposalTranslation(proposalId),
      (fetchedData: DataType[]) => {
        setTranslations(fetchedData);
      },
      (error: Error) => {
        setTranslations([]);
        enqueueSnackbar(
          `${translate.t('laFailedToLoad', {
            error: error.message,
          })}`,
          { variant: 'error' },
        );
      },
    );
  };

  const setData = (translationData: DataType[]) => {
    Service.put(
      API.translate.putProposalTranslation(proposalId),
      translationData,
      (_data: any) => {
        setTranslations(translationData);
        enqueueSnackbar(translate.t('laSave'), {
          variant: 'success',
        });
      },
      (error: Error) => {
        enqueueSnackbar(
          `${translate.t('laFailedToSave', {
            error: error.message,
          })}`,
          { variant: 'error' },
        );
      },
    );
  };

  const findLanguage = (lang: string) =>
    translations.find(translation => translation.language === lang);

  const onSubmit = () => {
    if (!findLanguage(language)) {
      setData([
        ...translations,
        { language: language, description: description, title: title },
      ]);

      return handleModalClose();
    }

    const newTranslations = translations.map(translation => {
      if (translation.language !== language) {
        return translation;
      }
      
      return {
        language: translation.language,
        description: description,
        title: title,
      };
    });

    setData(newTranslations);

    return handleModalClose();
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
    disabledSaveButton();
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
    disabledSaveButton();
  };
  const setFieldsData = (lang: string) => {
    const storedTranslation = findLanguage(lang);
    if (storedTranslation) {
      setTitle(storedTranslation.title);
      setDescription(storedTranslation.description);
    } else {
      setTitle('');
      setDescription('');
    }
  };
  const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLanguage(e.target.value);
    setFieldsData(e.target.value);
  };

  const handleModalClose = () => {
    onClose();
  };

  const disabledSaveButton = () => {
    if (title !== '' && description !== '') {
      setDisabledSave(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [proposalId]);

  useEffect(() => {
    if (translations) {
      setFieldsData(language);
    }
  }, [translations]);

  return (
    <Dialog open={open} onClose={handleModalClose}>
      <DialogTitle id="form-dialog-title">
        {translate.t('laEditJobTranslations')}
      </DialogTitle>
      <DialogContent>
        <TextField
          select
          name="language"
          value={language}
          onChange={handleLanguageChange}
          fullWidth
        >
          {getCompanyLanguages().map(
            ({ fComLanguageCode, fComLanguageName }: any) => {
              return (
                <MenuItem key={fComLanguageCode} value={fComLanguageCode}>
                  {fComLanguageName}
                </MenuItem>
              );
            },
          )}
        </TextField>
        <TextField
          name="title"
          value={title}
          onChange={handleTitleChange}
          label={translate.t('laTitle')}
          fullWidth
        />
        <TextField
          name="description"
          value={description}
          onChange={handleDescriptionChange}
          label={translate.t('laDescription')}
          fullWidth
          multiline={true}
          rows={7}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="text"
          onClick={handleModalClose}
        >
          {translate.t('laCancel')}
        </Button>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          onClick={onSubmit}
          disabled={disabledSave}
        >
          {translate.t('laSave')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DescriptionTranslateDialog;

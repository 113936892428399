import {
  getGridData,
  WorkflowReportPayloadEntry,
  WorkflowReportTypes,
  WorkflowsReportDataRow,
} from '@/app/components/WorkflowsReport/ReportTypes';
import {
  getLoggedUserId,
  getLoginData,
  hasAnyHrRelatedRole,
  hasManagerRole,
  isOnlyAltMgr,
} from '@/old/utils/helper';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Service from '@/app/utils/service';
import API from '@/app/api/internalAPIs';
import { GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import * as React from 'react';

import {
  useWorkflowsReportExcelAndGridData,
  UseWorkflowsReportExcelExportOutput,
} from '@/app/components/WorkflowsReport/useWorkflowsReportExcelAndGridData';
import { RewardingWorkflowReportEntry } from '@/app/components/WorkflowsReport/ReportTypes/Rewarding/types';
import {
  ReportToolbarSettingsChange,
  ReportToolbarSettingsChangeAction,
  WorkflowsReportToolbarSettings,
} from '@/app/components/WorkflowsReport/components/ToolbarManagerAndHr';
import {
  CommonWorkflowReportScopeSettings,
  CommonWorkflowReportUnitData,
} from '@/app/components/WorkflowsReport/ReportTypes/Common';
import { useFormattedWorkflowData } from './useFormattedWorkflowData';
import {
  SavedWorkflowsReportSettingsConfig,
  useWorkflowsReportSettings,
  WorkflowsReportColumnsConfig,
  WorkflowsReportSettings,
  WorkflowsReportSettingsConfigShowDetails,
} from '@/app/components/WorkflowsReport/useWorkflowsReportSettings';

type PropsType = {
  reportType: WorkflowReportTypes;
};

type UseWorkflowsReportOutput = UseWorkflowsReportExcelExportOutput & {
  loading: boolean;
  error: string;

  reportSettings: WorkflowsReportSettings;
  toolbarSettings: WorkflowsReportToolbarSettings;

  onColumnsSubmit: (columnsState: WorkflowsReportColumnsConfig) => void;
  onDataStateChange: (event: GridDataStateChangeEvent) => void;
  onChangeShowDetails: (
    name: keyof WorkflowsReportSettingsConfigShowDetails,
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  filterCellOptions: {
    unitOptions: (string | number)[];
    workflowTypeOptions: string[];
  };
  onApplyReportSettings: (
    savedSettings: SavedWorkflowsReportSettingsConfig,
  ) => void;
};

export const useWorkflowsReport = (
  props: PropsType,
): UseWorkflowsReportOutput => {
  const { reportType } = props;

  const {
    reportSettings,
    setStates,
    applySavedSettings,
  } = useWorkflowsReportSettings({
    reportType,
  });
  const {
    teamCheck,
    subUnitCheck,
    selectedUnitData: toolbarSelectedUnit,
    showDetails,
  } = reportSettings.current;
  const {
    setTeamCheck,
    setSubUnitCheck,
    setSelectedUnit: setToolbarSelectedUnit,
    setDataState,
    setShowDetails,
  } = setStates;

  // States
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [reportDataRows, setReportDataRows] = useState<
    WorkflowsReportDataRow[]
  >([]);

  const [isLoadingToolbarOptions, setIsLoadingToolbarOptions] = useState(true);
  const [toolbarUnitOptions, setUnitOptions] = useState<
    CommonWorkflowReportUnitData[]
  >([]);

  // Roles
  const { managerType, isUserManager, isUserHR } = useMemo(() => {
    const getRoles = getLoginData();
    const loginRoles = getRoles.fLoginRoles;
    const mgrType = isOnlyAltMgr() ? 'amgr' : 'manager';

    return {
      managerType: mgrType,
      isUserManager: hasManagerRole(loginRoles),
      isUserHR: hasAnyHrRelatedRole(loginRoles),
    };
  }, []);

  // API & Request utils
  const ReportAPI = useMemo(() => {
    const userId = getLoggedUserId();
    const wfAPI = API.workflowsReport;

    return {
      getByManagerId: () =>
        wfAPI.getByManagerId(reportType, managerType, userId),
      getByManagerAllId: () => wfAPI.getByManagerAllId(reportType, userId),
      getByHrId: () => wfAPI.getByHrId(reportType, userId),
      getBySubUnitId: (unitId: number) =>
        wfAPI.getBySubUnitId(reportType, unitId),
      getByUnitId: (unitId: number) => wfAPI.getByUnitId(reportType, unitId),
    };
  }, [reportType, managerType]);

  const getReportUrlByConfig = useCallback(
    (updateProps: CommonWorkflowReportScopeSettings): string => {
      const isOnlyManager = isUserManager && !isUserHR;

      if (!!updateProps.selectedUnit) {
        if (updateProps.subUnitCheck) {
          return ReportAPI.getBySubUnitId(updateProps.selectedUnit.unitId);
        } else {
          return ReportAPI.getByUnitId(updateProps.selectedUnit.unitId);
        }
      } else {
        if (updateProps.teamCheck) {
          return ReportAPI.getByManagerId();
        }
        if (updateProps.subUnitCheck) {
          return isOnlyManager
            ? ReportAPI.getByManagerAllId()
            : ReportAPI.getByHrId();
        } else {
          return isOnlyManager
            ? ReportAPI.getByManagerId()
            : ReportAPI.getByHrId();
        }
      }
    },
    [ReportAPI, isUserManager, isUserHR],
  );

  const getValidReportToolbarSettings = useCallback(
    (
      settings: ReportToolbarSettingsChange,
    ): CommonWorkflowReportScopeSettings => {
      const { action, ...selections } = settings;

      switch (action) {
        case ReportToolbarSettingsChangeAction.SelectedUnit:
          return {
            teamCheck: false,
            subUnitCheck: !selections.selectedUnit,
            selectedUnit: !!selections.selectedUnit
              ? selections.selectedUnit
              : undefined,
          };
        case ReportToolbarSettingsChangeAction.TeamCheck:
          return {
            ...selections,
            subUnitCheck: !selections.selectedUnit && !selections.teamCheck,
            selectedUnit: undefined,
          };
        case ReportToolbarSettingsChangeAction.SubUnitCheck:
          return {
            ...selections,
            teamCheck: !selections.selectedUnit && !selections.subUnitCheck,
          };
        case ReportToolbarSettingsChangeAction.LoadSavedSettings:
          if (!!selections.selectedUnit) {
            return {
              ...selections,
              teamCheck: false,
            };
          } else {
            return {
              ...selections,
              subUnitCheck: !selections.teamCheck,
            };
          }
        default:
          return {
            teamCheck: reportSettings.initial.teamCheck,
            subUnitCheck: reportSettings.initial.subUnitCheck,
            selectedUnit: reportSettings.initial.selectedUnitData,
          };
      }
    },
    [],
  );

  // Data mapping & loading
  const mapReportDataForGrid = useCallback(
    (data: WorkflowReportPayloadEntry[]) => getGridData(reportType, data),
    [reportType],
  );

  // Data updating & API utils
  const updateWorkflowsReportData = useCallback(
    (
      requestUrl?: string,
      setData?: (data: WorkflowsReportDataRow[]) => void,
    ) => {
      if (!!requestUrl) {
        Service.get(
          requestUrl,
          (res: WorkflowReportPayloadEntry[]) => {
            setData(mapReportDataForGrid(res));
            setLoading(false);
            setError('');
          },
          (err: any) => {
            setData([]);
            setLoading(false);
            setError(err);
          },
        );
      }
    },
    [mapReportDataForGrid, setLoading, setError],
  );

  const updateReportBySettings = useCallback(
    (settings: CommonWorkflowReportScopeSettings) => {
      setTeamCheck(settings.teamCheck);
      setSubUnitCheck(settings.subUnitCheck);
      setToolbarSelectedUnit(settings.selectedUnit);

      updateWorkflowsReportData(
        getReportUrlByConfig(settings),
        setReportDataRows,
      );
    },
    [
      setTeamCheck,
      setSubUnitCheck,
      setToolbarSelectedUnit,
      updateWorkflowsReportData,
      getReportUrlByConfig,
      setReportDataRows,
    ],
  );

  const getUnitDataFromReportDataRow = useCallback(
    (dataRow: WorkflowsReportDataRow): CommonWorkflowReportUnitData => {
      const { unit, unitId, unitNo } = dataRow;
      return { unit, unitId, unitNo };
    },
    [],
  );

  const filterUniqueUnitData = useCallback(
    (unitData: CommonWorkflowReportUnitData[]) => {
      return unitData.filter(
        (u, i, a) => a.findIndex(v => v.unitId === u.unitId) === i,
      );
    },
    [],
  );

  // Initial data fetching
  useEffect(() => {
    if (isLoadingToolbarOptions) {
      const initial = reportSettings.initial;

      const initRequests = {
        defaultUrl: getReportUrlByConfig({
          teamCheck: initial.teamCheck,
          subUnitCheck: initial.subUnitCheck,
          selectedUnit: initial.selectedUnitData,
        }),
        managerAllUrl: isUserManager
          ? ReportAPI.getByManagerAllId()
          : undefined,
        hrUrl: isUserHR ? ReportAPI.getByHrId() : undefined,
      };

      const requestUrls: (string | undefined)[] = [
        initRequests.defaultUrl,
        initRequests.managerAllUrl,
        initRequests.hrUrl,
      ];

      Promise.all(
        requestUrls.map(
          url =>
            new Promise<WorkflowsReportDataRow[]>(resolve => {
              if (!!url) {
                updateWorkflowsReportData(url, resolve);
              } else {
                resolve([]);
              }
            }),
        ),
      )
        .then((...results): WorkflowsReportDataRow[] => {
          const newWorkflows = results[0][0];
          if (newWorkflows.length) {
            setReportDataRows(newWorkflows);
          }

          return results.flat(2);
        })
        .then(data => {
          const uOps = filterUniqueUnitData(
            data.map(getUnitDataFromReportDataRow),
          );
          setUnitOptions(uOps);
          setIsLoadingToolbarOptions(false);
        });
    }
  }, [
    updateWorkflowsReportData,
    setReportDataRows,
    getUnitDataFromReportDataRow,
    setUnitOptions,
    setIsLoadingToolbarOptions,
    isUserManager,
    isUserHR,
  ]);

  const onReportToolbarSettingsChange = useCallback(
    (settings: ReportToolbarSettingsChange) => {
      updateReportBySettings(getValidReportToolbarSettings(settings));
    },
    [updateReportBySettings, getValidReportToolbarSettings],
  );

  const toolbarSettings: WorkflowsReportToolbarSettings = useMemo(() => {
    return {
      unitOptions: toolbarUnitOptions,
      selectedUnit: toolbarSelectedUnit,
      teamCheck,
      subUnitCheck,
      enabledActions: {
        unitSelector: true,
        teamCheckbox: isUserManager,
        subUnitsCheckbox: isUserManager || isUserHR,
      },
      onReportToolbarSettingsChange,
    };
  }, [
    toolbarUnitOptions,
    toolbarSelectedUnit,
    teamCheck,
    subUnitCheck,
    onReportToolbarSettingsChange,
    isUserManager,
    isUserHR,
  ]);

  const onApplyReportSettings = useCallback(
    (savedSettings: SavedWorkflowsReportSettingsConfig) => {
      const appliedSettings = applySavedSettings(savedSettings);
      onReportToolbarSettingsChange({
        action: ReportToolbarSettingsChangeAction.LoadSavedSettings,
        selectedUnit: appliedSettings.selectedUnitData,
        subUnitCheck: appliedSettings.subUnitCheck,
        teamCheck: appliedSettings.teamCheck,
      });
    },
    [],
  );

  const onChangeShowDetails = (
    name: keyof WorkflowsReportSettingsConfigShowDetails,
  ) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowDetails({ ...showDetails, [name]: event.target.checked });
  };

  const onDataStateChange = useCallback(
    (event: GridDataStateChangeEvent): void => {
      setDataState(event.data);
    },
    [],
  );

  const { getFormattedWorkflowData } = useFormattedWorkflowData();

  const { workflowsData, filterCellOptions } = useMemo(() => {
    // workflow data with enum translations
    const formattedData: WorkflowsReportDataRow[] = getFormattedWorkflowData(
      reportDataRows,
      reportType,
    );

    // custom filter for workflow type column
    const workflowTypeOptions = [
      WorkflowReportTypes.RewardingClosed,
      WorkflowReportTypes.RewardingOpen,
    ].includes(reportType)
      ? Array.from(
          new Set(
            (formattedData as RewardingWorkflowReportEntry[]).map(
              field => field.type,
            ),
          ),
        )
      : [];

    // custom filter for workflow unit column
    const uOps = Array.from(new Set(formattedData.map(field => field.unitNo)));

    return {
      workflowsData: formattedData,
      filterCellOptions: {
        workflowTypeOptions,
        unitOptions: uOps,
      },
    };
  }, [reportDataRows, reportType, getFormattedWorkflowData]);

  const { excelData, gridData } = useWorkflowsReportExcelAndGridData({
    reportType,
    workflowsData,
    showDetails,
  });

  return {
    gridData,
    loading: loading || isLoadingToolbarOptions,
    onColumnsSubmit: setStates.setColumns,
    onDataStateChange,
    onChangeShowDetails,
    filterCellOptions,
    error,
    excelData,
    reportSettings,
    toolbarSettings,
    onApplyReportSettings,
  };
};

import * as React from 'react';
import { InjectedProps } from '@material-ui/core/withMobileDialog';
import FormFields, {
  InputType,
  ChildInputType,
} from '../../FormFields/FormFields';
import { INPUT_TYPE } from '@/app/utils/helper';
import {
  reduxForm,
  InjectedFormProps,
  getFormValues,
  FormProps,
} from 'redux-form';
import { compose } from 'recompose';
import { Dispatch } from 'redux';
import { loadFormData, changeFormData } from '@/app/redux/formData';
import { connect } from 'react-redux';
import translate from '@/app/utils/translate';
import Service from '@/app/utils/service';
import API from '@/app/api/internalAPIs';
import { throwError } from '@/app/redux/error';
import { selectCustomFieldInputTypes } from '@/app/redux/enums';
import { ReducerState } from '@/app/redux/store';

const FORM_NAME = 'newGroup';

type FormDataType = {
  form: string;
  field: string;
  value: any;
};

type MapDispatchToProps = {
  loadFormData(form: string, e: any): void;
  changeFormData: Function;
  throwError: Function;
};

type MapStateToProps = FormProps<any, any> & {
  currentGroups: string[];
  customDataTypes: ChildInputType[];
};

type OwnProps = InjectedProps & {
  onSubmit: (values: any) => void;
};

type PropsType = OwnProps &
  InjectedFormProps &
  MapStateToProps &
  MapDispatchToProps;

class EnumGroupForm extends React.Component<PropsType> {
  mounted: boolean;

  render() {
    const { handleSubmit } = this.props;
    const inputs: Array<InputType> = [
      {
        type: INPUT_TYPE.TEXT,
        code: 'name',
        name: 'name',
        label: translate.t('laTerm'),
        order: 1,
        required: true,
      },
    ];

    return (
      <form onSubmit={handleSubmit}>
        <FormFields inputs={inputs} />
      </form>
    );
  }
}

const mapStateToProps = (state: ReducerState) => ({
  customDataTypes: selectCustomFieldInputTypes(state),
  currentGroups: getFormValues(FORM_NAME)(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadFormData: (form: string, e: any) => dispatch<any>(loadFormData(form, e)),
  changeFormData: (e: FormDataType) => changeFormData(dispatch, e),
  throwError: (e: string) => throwError(e),
});

const validate = (values: any) => {
  const errors: { [k: string]: any } = {};

  if (!values.name) {
    errors.name = translate.t('laThisRequired');
  } else if (values.name.length > 52) {
    errors.name = `${translate.t('laErrorMaxTextLen1')} 52`;
  }

  return errors;
};

const asyncValidate = (values: any, _dispatch: Dispatch, props: PropsType) => {
  const errKey = 'asyncErrors';
  let errors = props[errKey];

  return Service.get(
    API.group.getGroupValues(`__USER__DEV_${values.name.toUpperCase()}`),
    (data: any) => {
      if (data) {
        errors = Object.assign({}, errors, {
          name: translate.t('error_exists_name'),
        });
      }
    },
    (error: any) => props.throwError(error),
  ).then(() => {
    return new Promise((resolve, reject) => {
      if (errors) {
        reject(errors);
      }

      resolve();
    });
  });
};

const enhance = compose<any, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
    asyncValidate,
    asyncBlurFields: ['name'],
  }),
);

export default enhance(EnumGroupForm);

import { AvatarCell, EmployeeNameCell } from '../KendoCells';
import translate from '@/app/utils/translate';
import { GridCellProps } from '@progress/kendo-react-grid/dist/npm/interfaces/GridCellProps';

const commonGridColumns = () => [
  {
    cell: AvatarCell,
    field: 'empId',
    show: true,
    width: 80,
    title: translate.t('laPicture'),
    groupable: false,
  },
  {
    cell: (props: GridCellProps) =>
      EmployeeNameCell({ ...props, empIdField: 'empId' }),
    field: 'firstName',
    show: true,
    width: 100,
    title: translate.t('laFirstName'),
  },
  {
    cell: (props: GridCellProps) =>
      EmployeeNameCell({ ...props, empIdField: 'empId' }),
    field: 'lastName',
    show: true,
    width: 100,
    title: translate.t('laLastName'),
  },
  {
    field: 'empNumber',
    show: true,
    width: 100,
    title: translate.t('laEmpNumber'),
  },
  {
    field: 'unitName',
    show: true,
    width: 100,
    title: translate.t('fOrgUnitName'),
  },
  {
    field: 'unitCode',
    show: true,
    width: 100,
    title: translate.t('fOrgUnitNumber'),
  },
  {
    field: 'costCenterName',
    show: true,
    width: 100,
    title: translate.t('laCostCenter'),
  },
  {
    field: 'costCenterCode',
    show: true,
    width: 100,
    title: translate.t('laCostCenterCode'),
  },
  {
    field: 'legalEntityName',
    show: true,
    width: 100,
    title: translate.t('laLegalEntityName'),
  },
  {
    field: 'legalEntityCode',
    show: true,
    width: 100,
    title: translate.t('laLegalEntityCode'),
  },
  {
    field: 'managerNumber',
    show: true,
    width: 100,
    title: translate.t('laManagerNumber'),
  },
  {
    field: 'manager',
    show: true,
    width: 100,
    title: translate.t('laManager'),
  },
  {
    field: 'vacationYear',
    show: true,
    width: 100,
    title: translate.t('laVacationYear'),
  },
  {
    title: translate.t('laVacBal'),
    show: true,
    columns: [
      {
        field: 'vacationBalance',
        show: true,
        width: 100,
        title: translate.t('laBalance'),
      },
      {
        field: 'vacationBalanceApproved',
        show: true,
        width: 100,
        title: translate.t('laApproved'),
      },
      {
        field: 'vacationBalanceInProgress',
        show: true,
        width: 100,
        title: translate.t('laInProgress'),
      },
      {
        field: 'vacationBalanceRemainingDays',
        show: true,
        width: 100,
        title: translate.t('laRemaining'),
      },
    ],
  },
];

export const gridColumns = () => {
  const commonColumns = commonGridColumns();
  return [
    ...commonColumns,
    {
      title: translate.t('laVacBalExtra'),
      show: true,
      columns: [
        {
          field: 'extraBalance',
          show: true,
          width: 100,
          title: translate.t('laBalance'),
        },
        {
          field: 'extraBalanceApproved',
          show: true,
          width: 100,
          title: translate.t('laApproved'),
        },
        {
          field: 'extraBalanceInProgress',
          show: true,
          width: 100,
          title: translate.t('laInProgress'),
        },
      ],
    },
    {
      title: translate.t('laSupplementalBalance1'),
      show: true,
      columns: [
        {
          field: 'supplement1',
          show: true,
          width: 100,
          title: translate.t('laBalance'),
        },
        {
          field: 'supplement1Spent',
          show: true,
          width: 100,
          title: translate.t('laSpent'),
        },
      ],
    },
    {
      title: translate.t('laSupplementalBalance2'),
      show: true,
      columns: [
        {
          field: 'supplement2',
          show: true,
          width: 100,
          title: translate.t('laBalance'),
        },
        {
          field: 'supplement2Spent',
          show: true,
          width: 100,
          title: translate.t('laSpent'),
        },
      ],
    },
  ];
};

export const gridColumnsVacBalance = () => {
  const commonColumns = commonGridColumns();
  return [
    ...commonColumns,
    {
      title: translate.t('fComVacationBankLeaveTypes1'),
      show: true,
      columns: [
        {
          field: 'vacationBalanceBank1',
          show: true,
          width: 100,
          title: translate.t('laBalance'),
        },
        {
          field: 'vacationBalanceBank1Approved',
          show: true,
          width: 100,
          title: translate.t('laApproved'),
        },
        {
          field: 'vacationBalanceBank1InProgress',
          show: true,
          width: 100,
          title: translate.t('laInProgress'),
        },
      ],
    },
    {
      title: translate.t('fComVacationBankLeaveTypes2'),
      show: true,
      columns: [
        {
          field: 'vacationBalanceBank2',
          show: true,
          width: 100,
          title: translate.t('laBalance'),
        },
        {
          field: 'vacationBalanceBank2Approved',
          show: true,
          width: 100,
          title: translate.t('laApproved'),
        },
        {
          field: 'vacationBalanceBank2InProgress',
          show: true,
          width: 100,
          title: translate.t('laInProgress'),
        },
      ],
    },
    {
      title: translate.t('fComVacationBankLeaveTypes3'),
      show: true,
      columns: [
        {
          field: 'vacationBalanceBank3',
          show: true,
          width: 100,
          title: translate.t('laBalance'),
        },
        {
          field: 'vacationBalanceBank3Approved',
          show: true,
          width: 100,
          title: translate.t('laApproved'),
        },
        {
          field: 'vacationBalanceBank3InProgress',
          show: true,
          width: 100,
          title: translate.t('laInProgress'),
        },
      ],
    },
  ];
};

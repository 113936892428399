import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const bonusCalcPlanningStyle: StyleRulesCallback = (theme: Theme) => {
  return ({
    root: {
      padding: theme.spacing.unit * 3,
    },
    actionButtons: {
      paddingTop: theme.spacing.unit * 2,
    },
    actionButton: {
      marginLeft: theme.spacing.unit,
    },
    totalsContainer: {
      marginTop: theme.spacing.unit * 2,
      width: '100%',
    },
    totalsPaper: {
      padding: theme.spacing.unit * 2,
      backgroundColor: theme.palette.background.default,
    },
  });
};

export default bonusCalcPlanningStyle;

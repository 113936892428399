import React from 'react';
import {
  Paper,
  Icon,
  WithStyles,
  StyleRulesCallback,
  withStyles,
  Theme,
} from '@material-ui/core';
import translate from '@/app/utils/translate';
import { Async as SelectAsync } from 'react-select';
import { getEmployees } from '../../../helpers';
import { OptionType } from '../../../types';

const styles: StyleRulesCallback = (theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.grey[300],
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    paddingLeft: theme.spacing.unit / 2,
  },
  input: {
    flex: 1,
    paddingLeft: theme.spacing.unit,
  },
});

type OwnProps = {
  inputValue: string;
  value: OptionType;
  onInputChange: (newInputValue: string) => void;
  onChange: (employeeId: number) => void;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const SearchEmployeeField = ({
  classes,
  inputValue,
  value,
  onInputChange,
  onChange,
}: Props) => {
  const handleChange = (employee: OptionType) => {
    if (!!employee) {
      onChange(employee.value as number);
    }
  };

  return (
    <Paper className={classes.root} elevation={1} data-testid="autocomplete-add-training-participant">
      <Icon className={classes.icon}>search</Icon>
      <SelectAsync
        className={classes.input}
        styles={{
          control: provided => ({
            ...provided,
            backgroundColor: 'inherit',
            border: 'none',
            '&:hover': { border: 'none', boxShadow: 'none' },
          }),
        }}
        isClearable={true}
        placeholder={translate.t('label_add_internal_participant')}
        loadOptions={(searchTerm: string): Promise<any> => {
          if (searchTerm.length < 3) {
            return Promise.resolve([]);
          }

          return Promise.resolve(getEmployees(searchTerm, ['ACTIVE']).map((item: any) => ({
            value: item.id,
            label: `${item.firstName} ${item.lastName} (${item.position}) - ${item.code}`,
          })));
        }}
        options={[]}
        noOptionsMessage={() => translate.t('label_type_at_least_n_chars', { count: 3 })}
        inputValue={inputValue}
        value={value}
        onInputChange={onInputChange}
        onChange={handleChange}
      />
    </Paper>
  );
};

export default withStyles(styles)(SearchEmployeeField);

import ENUM_GROUP_CONFIG from './enumGroupConfig';
import { lockLevelType, EnumConfig } from '@/app/redux/enums';

export interface  IEnumGroup extends EnumConfig {
  getTransKey(): string;
  getTransPluralKey(): string;
  showInArea(displayArea: string): boolean;
  canAdd(): boolean;
  canEdit(): boolean;
  canEditName: boolean;
  canDelete(): boolean;
  canTranslate(): boolean;
  hasDetails(): boolean;
  hasTags(): boolean;
  isCountryBased(): boolean;
  order(): number;
  hasExtCode: boolean;
  hasInputType: boolean;
  customizableUiLabelName: string;
}

export class EnumGroup implements IEnumGroup {
  readonly name: string;
  readonly lockLevel: lockLevelType;
  readonly isOrdered: boolean;
  readonly isComposite: boolean;
  readonly hasExtCode: boolean = false;
  readonly hasInputType: boolean = false;
  readonly canEditName: boolean = false;
  readonly customizableUiLabelName: string = '';

  constructor(
    name: string,
    lockLevel: lockLevelType,
    isOrdered: boolean,
    isComposite: boolean,
    hasExtCode: boolean,
    hasInputType: boolean,
  ) {
    this.name = name;
    this.lockLevel = lockLevel;
    this.isOrdered = isOrdered;
    this.isComposite = isComposite;
    this.hasExtCode = hasExtCode && !hasInputType;
    this.hasInputType = hasInputType;
    this.canEditName = this.lockLevel === 'NONE' || this.name.indexOf('CUSTOM_ENTITY') === 0;
    this.customizableUiLabelName = !ENUM_GROUP_CONFIG[this.name] ? '' : ENUM_GROUP_CONFIG[this.name].customizableUiLabelName;
  }

  getTransKey = () =>
    !ENUM_GROUP_CONFIG[this.name] ? this.name : ENUM_GROUP_CONFIG[this.name].transKey;

  getTransPluralKey = () =>
    !ENUM_GROUP_CONFIG[this.name] ? this.name : ENUM_GROUP_CONFIG[this.name].transPluralKey;

  showInArea = (displayArea: string) =>
    !ENUM_GROUP_CONFIG[this.name] ?
      false :
      ENUM_GROUP_CONFIG[this.name].displayAreas.indexOf(displayArea.toUpperCase()) > -1;
  // TODO, custom field should allow adding by lockLevel, currently the config is controlled by check the name in UI
  canAdd = () => this.lockLevel === 'NONE' || this.name.indexOf('CUSTOM_ENTITY') === 0;
  canEdit = () => this.lockLevel === 'NONE' ||
    this.name.indexOf('CUSTOM_ENTITY') === 0 ||
    (this.lockLevel === 'VALUE' && (this.hasExtCode || this.hasTags() || this.isCountryBased()));
  canDelete = () => this.lockLevel === 'NONE' || this.name.indexOf('CUSTOM_ENTITY') === 0;
  canTranslate = () => this.lockLevel === 'NONE' || this.lockLevel === 'VALUE';

  hasDetails = () => !ENUM_GROUP_CONFIG[this.name] ? false : ENUM_GROUP_CONFIG[this.name].hasDetails;
  hasTags = () => !ENUM_GROUP_CONFIG[this.name] ? false : ENUM_GROUP_CONFIG[this.name].hasTags;
  isCountryBased = () => !ENUM_GROUP_CONFIG[this.name] ? false : ENUM_GROUP_CONFIG[this.name].isCountryBased;
  order = () => !ENUM_GROUP_CONFIG[this.name] ? 1 : ENUM_GROUP_CONFIG[this.name].order;
}

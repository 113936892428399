import * as React from 'react';
import {
  Icon,
  IconButton,
  Paper,
  Popover,
  StyleRulesCallback,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import translate from '@/app/utils/translate';
import { eventTargetAsHtmlElement } from '@/old/utils/helper';

const styles: StyleRulesCallback = (theme: Theme) => ({
  button: {
    padding: 0,
  },
  popoverPaper: {
    padding: theme.spacing.unit,
  },
});

type OwnProps = {
  details?: string;
  title?: string;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const EnumValueDetailsButton = ({ classes, details = '', title = '' }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement|undefined>(undefined);

  const handleOpenDetails = (e: React.SyntheticEvent) => {
    setAnchorEl(eventTargetAsHtmlElement(e.target));
  };

  const handleCloseDetails = () => {
    setAnchorEl(null);
  };

  // If no details, we don't show the button at all
  if (details === '') {
    return null;
  }

  // Only render popover if it is going to be visible
  const popover = !Boolean(anchorEl) ? undefined : (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'center',
      }}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'center',
      }}
      onClose={handleCloseDetails}
    >
      <Paper className={classes.popoverPaper}>
        <Typography variant="subtitle2">{title}</Typography>
        {details.split('\n').map((det: string, ndx: number) => (
          <Typography key={`${title}${ndx}`} variant="body2">{det}</Typography>
        ))}
      </Paper>
    </Popover>
  );

  return (
    <>
      <IconButton
        className={classes.button}
        title={translate.t('title_show_details')}
        onClick={handleOpenDetails}
      >
        <Icon>information</Icon>
      </IconButton>
      {popover}
    </>
  );
};

export default withStyles(styles)(EnumValueDetailsButton);

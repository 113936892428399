import { withStyles } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { DailySummaryRow as LocalDailySummaryRow } from './DailySummaryRow';
import { DailySummaryRowInnerProps, DailySummaryRowOuterProps } from './dailySummaryRowModels';
import { dailySummaryRowStyles } from './dailySummaryRowStyles';
import { mapDispatchToProps, mapStateToProps } from './dailySummaryRowRedux';

export const DailySummaryRow = compose<DailySummaryRowInnerProps, DailySummaryRowOuterProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withSnackbar,
    withStyles(dailySummaryRowStyles),
)(LocalDailySummaryRow);

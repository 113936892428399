import * as React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';

type Props = GridCellProps;

export const KendoButtonCell = ({ dataItem, rowType }: Props) => {
  if (
    rowType === 'groupHeader' ||
    rowType === 'groupFooter' ||
    rowType !== 'data'
  ) {
    return null;
  }

  return <td>{dataItem.subjectButton}</td>;
};

import * as React from 'react';
import { Field } from 'redux-form';
import {
  Grid,
  Typography,
  TextField,
} from '@material-ui/core';
import translate from '@/app/utils/translate';
import { INPUT_TYPE } from '@/app/utils/helper';
import FormFields from '@/app/components/FormFields/FormFields';
import SolaIntelGroupedIndicatorList from '../SolaIntelGroupedIndicatorList/solaIntelGroupedIndicatorList';

interface IProps {
  indicators: any[];
  disableIndicators?: boolean;
  selectedIndicatorIds?: string[];
  onIndicatorInclude: (uuid: string, checked: boolean) => void;
}

interface IState {}
class SolaIntelGroupedIndicatorDetails extends React.Component<IProps, IState> {
  render() {
    const { indicators, disableIndicators, selectedIndicatorIds, onIndicatorInclude } = this.props;
    const rewardingIndicators = indicators.filter(indicator => indicator.category === 'REWARDING');
    const performanceIndicators = indicators.filter(indicator => indicator.category === 'PERFORMANCE');
    const developmentIndicators = indicators.filter(indicator => indicator.category === 'DEVELOPMENT');

    const nameInput = [{
      type: INPUT_TYPE.TEXT,
      code: 'name',
      name: 'name',
      label: translate.t('laName'),
      order: 1,
      required: true,
    }];

    const groupingSelect = [{
      type: INPUT_TYPE.SELECT,
      code: 'condition',
      name: 'condition',
      label: translate.t('laGrouping'),
      childrenInputs: [
        { label: translate.t('laAND'), value: 'AND' },
        { label: translate.t('laOR'), value: 'OR' },
      ],
      order: 1,
      required: true,
    }];

    const activeInput = [{
      type: INPUT_TYPE.SWITCH,
      code: 'active',
      name: 'active',
      label: translate.t('laActive'),
      order: 3,
      required: false,
    }];

    return (
      <>
        <Grid
          container
          direction={'row'}
          alignItems={'flex-start'}
          justify={'space-between'}
          spacing={8}
        >
          <Grid item xs={12} md={6}>
            <FormFields inputs={nameInput} />
          </Grid>
          <Grid item xs={6} md={3} style={{marginTop: '-6.5px'}}>
            <FormFields inputs={groupingSelect} />
          </Grid>
          <Grid item xs={6} md={3}>
            <FormFields inputs={activeInput} />
          </Grid>
        </Grid>
        <Typography variant={'h5'}>
          {translate.t('laIncludedIndicators')}
        </Typography>
        <Grid
          container
          direction={'column'}
          alignItems={'flex-start'}
        >
          <SolaIntelGroupedIndicatorList
            indicators={rewardingIndicators}
            listTitle={translate.t('laRewarding')}
            disableIndicators={disableIndicators}
            selectedIndicatorIds={selectedIndicatorIds}
            onIndicatorInclude={onIndicatorInclude}
          />
          <SolaIntelGroupedIndicatorList
            indicators={performanceIndicators}
            listTitle={translate.t('laPerformance')}
            disableIndicators={disableIndicators}
            selectedIndicatorIds={selectedIndicatorIds}
            onIndicatorInclude={onIndicatorInclude}
          />
          <SolaIntelGroupedIndicatorList
            indicators={developmentIndicators}
            listTitle={translate.t('laDevelopment')}
            disableIndicators={disableIndicators}
            selectedIndicatorIds={selectedIndicatorIds}
            onIndicatorInclude={onIndicatorInclude}
          />
          <Field
            component={TextField}
            type={'hidden'}
            name={'indicatorIds'}
            label={''}
            color="primary"
          />
        </Grid>
      </>
    );
  }
}

export default SolaIntelGroupedIndicatorDetails;

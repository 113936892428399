export enum FormCompletionStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  EMPLOYEE_APPROVED = 'EMPLOYEE_APPROVED',
}

export enum FormStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum FormTargetType {
  EMPLOYEE = 'EMPLOYEE',
  UNIT = 'UNIT',
}

import React, { useContext } from 'react';
import {
  StyleRulesCallback,
  Theme,
  WithStyles,
  withStyles,
  Typography,
} from '@material-ui/core';

import Context from '../context/trainingsContext';
import {
  CompanyTraining,
  CompanyTrainingParticipantRegistration,
} from '../types';
import { getRegistrationStatus } from '../helpers';

import translate from '@/app/utils/translate';
import ApprovalButton from './registrationStatus/ApprovalButton';
import SelfRegisterButton from './registrationStatus/SelfRegisterButton';

const styles: StyleRulesCallback = (theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
});

type OwnProps = {
  training: CompanyTraining;
  onChangeApprovalStatus?: (
    updatedParticipant: CompanyTrainingParticipantRegistration,
  ) => void;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const RegistrationStatus = ({
  classes,
  training: {
    id: trainingId,
    startDate: trainingStartDate,
    lastDateForRegistration,
    useOnlineRegistration,
  },
  onChangeApprovalStatus,
}: Props) => {
  const {
    state: {
      employeeTrainings: {
        registrations: { data: currentRegistrations },
        selfRegistering: { error: registeringError },
      },
    },
  } = useContext(Context);

  const currentRegistration: CompanyTrainingParticipantRegistration = currentRegistrations.find(
    (r: CompanyTrainingParticipantRegistration) => r.trainingId === trainingId,
  );

  const errorEl = !registeringError ? null : (
    <Typography color="error">{`${translate.t('label_error_self_registering', {
      error: registeringError,
    })}`}</Typography>
  );

  let content;
  if (!currentRegistration) {
    content = (
      <SelfRegisterButton
        lastDateForRegistration={lastDateForRegistration}
        trainingId={trainingId}
        trainingStartDate={trainingStartDate}
        useOnlineRegistration={useOnlineRegistration}
      />
    );
  } else {
    const regStatus = getRegistrationStatus(
      currentRegistration.approvalStatus.status.value,
      currentRegistration.seatStatus,
    );

    content = (
      <ApprovalButton
        registration={currentRegistration}
        registrationStatus={regStatus}
        onChangeApprovalStatus={onChangeApprovalStatus}
      />
    );
  }

  return (
    <div className={classes.root}>
      {content}
      {errorEl}
    </div>
  );
};

export default withStyles(styles)(RegistrationStatus);

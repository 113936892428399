import * as React from 'react';
import translate from '@/app/utils/translate';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { WithStyles } from '@material-ui/core';
import { Enum } from '@/app/redux/enums';
import GenericDialog from '../../GenericDialog/GenericDialog';
import enumSortDialogStyle from './enumSortDialogStyle';
import { IEnumGroup } from '../EnumGroupList/enumGroup';
import SortableComponent from '@/app/components/SortableComponent/SortableComponent';

interface OwnProps {
  group: IEnumGroup;
  title: string;
  open: boolean;
  itemList?: any[];
  onClose: () => void;
  onSave: (groupName: string, list: any[]) => void;
  allEnums: object;
}

type PropsType = OwnProps & WithStyles<typeof enumSortDialogStyle>;

type StateType = {
  list: any[];
  isDisabledSave: boolean;
};

class EnumSortDialog extends React.Component<PropsType> {
  state: StateType = {
    list: [],
    isDisabledSave: true,
  };

  componentDidUpdate(prevProps: PropsType) {
    if (this.props.open && (this.props.open !== prevProps.open)) {
      this.setState({
        list: this.enumList(),
        isDisabledSave: true,
      });
    }
  }

  enumList() {
    const { group, allEnums } = this.props;

    if (group) {
      const values: Array<Enum> = allEnums[group.name];

      const enumValues = values
        .filter(val => val.isActive)
        .map(({code, name, seq }, index) => ({
          name,
          code,
          seq: seq ? seq : index + 1,
        }))
        .sort((a: any, b: any) => a.seq - b.seq);

      return enumValues;
    }

    return [];
  }

  handleSave = () => {
    const { group } = this.props;
    const { list: oldList } = this.state;
    const list = oldList.map((item: any, index: number) => {
      return Object.assign({}, item, {
        seq: index + 1,
      });
    });
    this.props.onSave(group.name, list);
  }

  handleUpdateList = (list: any[]) => {
    this.setState({
      list,
      isDisabledSave: false
    });
  }

  render() {
    const { title, open, classes, onClose } = this.props;
    const { list, isDisabledSave } = this.state;

    return (
      <GenericDialog
        open={open}
        title={title}
        onClose={onClose}
        onSave={this.handleSave}
        paperWidthMd={classes.paperWidthMd}
        description={translate.t('laSortIns')}
        isDisabledSave={isDisabledSave}
      >
          <SortableComponent list={list} updatedList={this.handleUpdateList} />
      </GenericDialog>
    );
  }
}

const enhance = compose<any, OwnProps>(
  withStyles(enumSortDialogStyle, {withTheme: true}),
);

export default enhance(EnumSortDialog);

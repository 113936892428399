import { StyleRulesCallback } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';

export type TransactionRowStyleKeys =
  | 'root'
  | 'stale'
  | 'nonWorkReportingType'
  | 'correctionReportingType'
  | 'textInput'
  | 'deleteButton'
  | 'fullWidth'
  | 'timePicker';

export const transactionRowStyles: StyleRulesCallback<TransactionRowStyleKeys> = theme => ({
  root: {
    '&>div': {
      display: 'flex',
      alignItems: 'center',
    },

    '&:not(:last-child)': {
      marginBottom: theme.spacing.unit / 2,
    },
  },
  stale: {
    '& *': {
      color: theme.palette.error.main,
    },
  },
  nonWorkReportingType: {
    '&>div': {
      backgroundColor: fade('#d3d3d3', 0.4),
    },
  },
  correctionReportingType: {
    '&>div': {
      backgroundColor: fade('#ff4444', 0.2),
    },
  },
  textInput: {
    margin: 0,

    '& input': {
      padding: `10px ${theme.spacing.unit}px`,
    },
  },
  deleteButton: {
    margin: -theme.spacing.unit,

    '& svg': {
      fontSize: 20,
    },
  },
  fullWidth: {
    width: '100%',
  },
  timePicker: {
    '& input': {
      // Resolve conflicts with legacy UI
      margin: '0 !important',
      paddingLeft: '0 !important',

      // Align time picker with select pickers
      padding: '10px 0 10px',
    },

    '& button': {
      margin: -theme.spacing.unit,

      // There are some issues with end adornment positioning in IE11
      // and the button is too far on right side
      // https://github.com/mui-org/material-ui/issues/10342
      '.k-ie11 &': { marginLeft: -36 },

      '& .material-icons': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },

      '& svg': {
        fontSize: 20,
      },
    },
  },
});

import React, { useState } from 'react';
import {
  Button,
  Grid,
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import moment from 'moment';

import LocalizedDatePicker from '@/app/components/Pickers/LocalizedDatePicker';
import translate from '@/app/utils/translate';

import {
  SEAT_STATUS,
} from '../../types';
import { getEmployee } from '../../helpers';

import { EmployeeAvatar } from '..';
import {
  EmployeeDetailsPopover,
  AddToDrTableHead,
  styles,
} from './addToDRList/';

type OwnProps = {
  participants: any[];
  handleParticipantChange: (participant: any) => void;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const AddToDRList = ({
  classes,
  participants,
  handleParticipantChange,
}: Props) => {
  const [selectedEmployeeId, setSelectedEmployeeId] = useState();
  const [empDetailsAnchorEl, setEmpDetailsAnchorEl] = useState(undefined);

  const handleReminderChange = (date: moment.Moment, participant: any) => {
    handleParticipantChange({ ...participant, reminderDate: date.format('YYYY-MM-DD') });
  };

  const handleFileUpload = (e: any, participant: any) => {
    handleParticipantChange({ ...participant, file: e.target.files[0] });
  };

  const handleDecisionInfoChange = (e: any, participant: any) => {
    handleParticipantChange({ ...participant, decisionInfo: e.target.value });
  };

  const employeeDetailsPopover = !selectedEmployeeId || !empDetailsAnchorEl ? null : (
    <EmployeeDetailsPopover
      employeeId={selectedEmployeeId}
      anchorEl={empDetailsAnchorEl}
      onClose={() => {
        setEmpDetailsAnchorEl(undefined);
        setSelectedEmployeeId(undefined);
      }}
    />
  );

  return (
    <>
      {employeeDetailsPopover}
      <Table padding="dense">
        <AddToDrTableHead />
        <TableBody>
          {participants.map((participant, index) => {
            const emp = getEmployee(participant.employeeId);

            let rowClassName = '';
            if (participant.seatStatus !== SEAT_STATUS.HAS_SEAT) {
              rowClassName = participant.seatStatus === SEAT_STATUS.NO_SEAT ? classes.rejectedRow : classes.waitingRow;
            }

            return (
              <React.Fragment key={index}>
                <TableRow className={rowClassName}>
                  <TableCell>
                    <Grid container={true} alignItems="center">
                      <Grid item={true}>
                        <EmployeeAvatar employeeId={participant.employeeId} />
                      </Grid>
                      <Grid item={true} className={classes.grow}>
                        <Typography
                          variant="body1"
                          className={classes.employeeName}
                        >
                          {`${emp.firstName} ${emp.lastName}`}
                          <IconButton
                            className={classes.infoButton}
                            onClick={e => {
                              setEmpDetailsAnchorEl(e.target);
                              setSelectedEmployeeId(participant.employeeId);
                            }}
                          >
                            <Icon>info_outlined</Icon>
                          </IconButton>
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    {participant.type}
                  </TableCell>
                  <TableCell>
                    {participant.title}
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={participant.decisionInfo}
                      onChange={(e) => { handleDecisionInfoChange(e, participant); }}
                    />
                  </TableCell>
                  <TableCell>
                    <div>
                      <input
                        accept="*"
                        className={classes.inputFileUpload}
                        id={`raised-button-file-${participant.employeeId}`}
                        multiple
                        type="file"
                        onChange={(e) => handleFileUpload(e, participant)}
                      />
                      <label htmlFor={`raised-button-file-${participant.employeeId}`}>
                        <Button
                          variant="contained"
                          component="span"
                        >
                          {participant.file ? translate.t('laChangeFile') : translate.t('laChooseFile')}
                        </Button>
                      </label>
                      <span className={classes.fileButton}>{participant.file ? `  ${participant.file.name}` : `  ${translate.t('laNoFile')}`}</span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <LocalizedDatePicker
                      minDate={moment()}
                      value={participant.reminderDate}
                      autoOk={true}
                      onChange={(date: moment.Moment) => handleReminderChange(date, participant)}
                    />
                  </TableCell>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default withStyles(styles)(AddToDRList);

import React from 'react';
import { compose } from 'recompose';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import translate from '@/app/utils/translate';
import notifierForOldStyle from './notifierForOldStyle';

type incomingProps = {
  message: string;
};

type PropsType = incomingProps & WithStyles<typeof notifierForOldStyle>;

class NotifierForOld extends React.Component<PropsType> {
  state = {
    open: true,
  };

  handleClose = (_event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    const message = translate.t('laUnitCreationInfo');

    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.open}
          autoHideDuration={5000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
            className: classes.info,
          }}
          message={
            <span id="message-id">
              <InfoIcon className={classes.infoIcon} />
              <span className={classes.messageSpan}>{message}</span>
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={() => this.handleClose}
            >
              <CloseIcon className={classes.close} />
            </IconButton>,
          ]}
        />
      </div>
    );
  }
}

const enhance = compose<any, any>(withStyles(notifierForOldStyle));
export default enhance(NotifierForOld);

import moment from 'moment';
import {
  Column,
  CustomDataItem,
  UnitPositionReportEntry,
  UnitPositionReportResponseItem,
} from './types';
import { CustomField, CustomFieldType } from './unitPositionReportRedux';

export const mapReportForUI = (
  unitPositionReportResponseItems: UnitPositionReportResponseItem[],
  allEnums: any,
  customEntities: CustomField[],
): UnitPositionReportEntry[] => {
  const unitPositionItemsMapped = unitPositionReportResponseItems.map(
    unitPositionReportResponseItem => {
      const entries = {
        unit: unitPositionReportResponseItem.fOrgUnitName,
        positionId: unitPositionReportResponseItem.fUnitPositionPosId,
        positionName: unitPositionReportResponseItem.fUnitPositionPosName,
        startDate: unitPositionReportResponseItem.fUnitPositionPosStartDate,
        endDate: unitPositionReportResponseItem.fUnitPositionPosEndDate || '',
        positionPricing: unitPositionReportResponseItem.fUnitPositionPosPricing,
        assignee: `${unitPositionReportResponseItem.fEmpFirstName} ${unitPositionReportResponseItem.fEmpLastName}`,
        empNumber: unitPositionReportResponseItem.fEmpNumber,
        assigneePercentage: unitPositionReportResponseItem.fEmpUnitPosPercent,
      };
      unitPositionReportResponseItem.fDataEntriesUnitPos.map(customField => {
        const customFieldEnum = customEntities.find(
          c => c.code === customField.fCustomDataFieldName,
        );
        entries[customField.fCustomDataFieldName] = getCustomFieldValue(
          customField,
          customFieldEnum,
          allEnums,
        );
      });

      return entries;
    },
  );

  return unitPositionItemsMapped;
};

const getCustomFieldValue = (
  customField: CustomDataItem,
  customDataEnum: CustomField,
  allEnums: any,
): string => {
  switch (customDataEnum.inputType.value) {
    case CustomFieldType.DATE: {
      return moment(customField.fCustomDataFieldValue[0]).format('L');
    }

    case CustomFieldType.ENUM:
    case CustomFieldType.MULTISELECT: {
      const customDataSubItemEnum: CustomField[] = allEnums.get(
        `__CUSTOMDATA__UNIT_POSITION__${customField.fCustomDataFieldName}`,
      );

      return customDataSubItemEnum && !!customDataSubItemEnum.length
        ? customField.fCustomDataFieldValue
            .map(c => {
              const customDataSubItem = customDataSubItemEnum.find(
                s => s.code === c,
              );

              if (customDataSubItem) {
                return customDataSubItem.name;
              }

              return c;
            })
            .join()
        : customField.fCustomDataFieldValue.join();
    }

    default: {
      return customField.fCustomDataFieldValue[0];
    }
  }
};

export const getCustomDataColumns = (
  unitPositionReportingTypeEnums: CustomField[],
): Column[] => {
  return unitPositionReportingTypeEnums.map(t => ({
    field: t.code,
    show: true,
    title: t.name,
  }));
};

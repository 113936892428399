import React, { BaseSyntheticEvent } from 'react';
import { useEffect, useMemo, useState } from 'react';
import API, { Relation, Scope } from '@/app/api/internalAPIs';
import { gridColumns } from './Columns/GridColumns';
import {
  Column,
  Unit,
  UnitPositionReportEntry,
  UnitPositionReportResponseItem,
} from './types';
import Service from '@/app/utils/service';
import {
  getLoggedUserId,
  getMyEmployeeUnitId,
  isAltManager,
  isDManager,
  isEmployee,
  isHR,
  isManager,
  isOnlyAltMgr,
  isRecruiter,
} from '@/old/utils/helper';
import SelectFilter from './KendoFilters/SelectFilter';
import {
  Grid,
  GridColumn,
  GridDataStateChangeEvent,
  GridToolbar,
} from '@progress/kendo-react-grid';
import { process, State } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {
  Button,
  Checkbox,
  Icon,
  Menu,
  MenuItem,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import translate from '@/app/utils/translate';
import ColumnMenu from '../Kendo/ColumnMenu';
import styles from './styles';
import Loading from '../Loading/Loading';
import { compose } from 'recompose';
import { getCustomDataColumns, mapReportForUI } from './service';
import { mapStateToProps, StateProps } from './unitPositionReportRedux';
import { connect } from 'react-redux';

const allUnitsOption = {
  fTreeUnitId: '0',
  fTreeUnitName: 'All',
  fTreeUnitNumber: 'all-1',
};

type InnerProps = WithStyles<typeof styles>;
type OwnProps = {};

type Props = OwnProps & InnerProps & StateProps;

const UnitPositionReport = (props: Props) => {
  const { classes } = props;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>(undefined);

  const [hrUnits, setHRUnits] = useState<Unit[]>([]);
  const [managerUnits, setManagerUnits] = useState<Unit[]>([]);
  const [altManagerUnits, setAltManagerUnits] = useState<Unit[]>([]);
  const [employeeUnits, setEmployeeUnits] = useState<Unit[]>([]);
  const [selectedUnit, setSelectedUnit] = useState<Unit>(allUnitsOption);

  const [relation, setRelation] = useState<Relation | undefined>(
    isHR() && !isManager() && !isAltManager() ? Relation.ALL : Relation.DIRECT,
  );

  const [entries, setEntries] = useState<UnitPositionReportEntry[]>([]);
  const [columns, setColumns] = useState<Column[]>(gridColumns());
  const [
    dataState = { skip: 0, take: 9 },
    setDataState,
  ] = useState<State | null>();
  const [dropdownMenu, setDropdownMenu] = useState<
    HTMLInputElement | undefined
  >();

  const myUserId = useMemo(() => getLoggedUserId(), []);

  useEffect(() => {
    Service.get(
      API.orgTree.primaryUnits(),
      (allUnits: Unit[]) => {
        if (isHR()) {
          Service.get(
            API.orgTree.myHrUnits(myUserId),
            (unitIds: number[]) => {
              const myHRUnits = allUnits.filter((unit: Unit) =>
                unitIds.includes(+unit.fTreeUnitId),
              );
              setHRUnits(myHRUnits);
              setLoading(false);
              setError(undefined);
            },
            (err: string) => {
              setLoading(false);
              setError(err);
            },
          );
        }

        if (isManager() && !isAltManager()) {
          Service.get(
            API.orgTree.myManagedUnits(myUserId),
            (unitIds: number[]) => {
              const myManagerUnits = allUnits.filter((unit: Unit) =>
                unitIds.includes(+unit.fTreeUnitId),
              );
              setManagerUnits(myManagerUnits);
              setLoading(false);
              setError(undefined);
            },
            (err: string) => {
              setLoading(false);
              setError(err);
            },
          );
        }

        if (isAltManager()) {
          Service.get(
            API.orgTree.myAltManagerUnits(myUserId),
            (unitIds: number[]) => {
              const myAltManagerUnits = allUnits.filter((unit: Unit) =>
                unitIds.includes(+unit.fTreeUnitId),
              );
              setAltManagerUnits(myAltManagerUnits);
              setLoading(false);
              setError(undefined);
            },
            (err: string) => {
              setLoading(false);
              setError(err);
            },
          );
        }

        if (isEmployee() || isRecruiter() || isDManager()) {
          const myEmployeeUnit = allUnits.find(
            (unit: Unit) =>
              +unit.fTreeUnitId === +getMyEmployeeUnitId(myUserId),
          );
          setEmployeeUnits([myEmployeeUnit]);
          setSelectedUnit(myEmployeeUnit);
        }
      },
      (err: string) => {
        setLoading(false);
        setError(err);
      },
    );
  }, []);

  useEffect(() => {
    if (relation) {
      const unitScope =
        selectedUnit &&
        selectedUnit.fTreeUnitId !== allUnitsOption.fTreeUnitId &&
        !isEmployee() &&
        !isRecruiter() &&
        !isDManager();

      const scope = unitScope
        ? Scope.UNIT
        : (isHR() || isAltManager()) &&
          isManager() &&
          !isAltManager() &&
          relation === Relation.DIRECT
        ? Scope.MANAGER
        : isHR()
        ? Scope.HR
        : isManager() && !isOnlyAltMgr()
        ? Scope.MANAGER
        : isOnlyAltMgr()
        ? Scope.ALT_MANAGER
        : Scope.EMPLOYEE;

      const scopeId = unitScope ? selectedUnit.fTreeUnitId : myUserId;
      Service.get(
        API.unitPositionReport.getReport(scope, scopeId, relation),
        (res: UnitPositionReportResponseItem[]) => {
          setColumns(
            Array.from(
              new Set([
                ...gridColumns(),
                ...getCustomDataColumns(props.customEntities),
              ]),
            ),
          );
          setEntries(mapReportForUI(res, props.allEnums, props.customEntities));
          setLoading(false);
          setError(undefined);
        },
        (err: string) => {
          setEntries([]);
          setLoading(false);
          setError(err);
        },
      );
    } else {
      setEntries([]);
    }
  }, [selectedUnit, relation]);

  const initialOptions = isEmployee() ? [] : [allUnitsOption];

  const unitOptions = useMemo(
    () =>
      Array.from(
        new Set([
          ...initialOptions,
          ...hrUnits,
          ...managerUnits,
          ...altManagerUnits,
          ...employeeUnits,
        ]),
      ),
    [hrUnits, managerUnits, altManagerUnits, employeeUnits],
  );

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <div>{'error'}</div>;
  }
  if (!entries) {
    return <div>{'no data'}</div>;
  }

  const unitFilterCell = SelectFilter(
    unitOptions.map(u => u.fTreeUnitName),
    'Select unit',
  );

  const onColumnsSubmit = (columnsState: Column[]) => {
    setColumns(columnsState);
  };

  const createDataState = (dataStateProp: State) => {
    return {
      dataState: setDataState(dataStateProp),
    };
  };

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    return createDataState(event.data);
  };

  let _export: ExcelExport;
  const saveUnitPositionReport = () => {
    _export.save(
      process(entries, {
        ...dataState, // Export all the records
        skip: 0,
        take: undefined,
      }),
    );
  };
  const handleTeamCheckboxChange = (
    _: BaseSyntheticEvent,
    checked: boolean,
  ) => {
    setRelation(checked ? Relation.DIRECT : undefined);
  };

  const handleSubunitsCheckboxChange = (
    _: BaseSyntheticEvent,
    checked: boolean,
  ) => {
    setRelation(checked ? Relation.ALL : undefined);
  };

  const selectUnit = (unit: Unit) => () => {
    setSelectedUnit(unit);
    setDropdownMenu(null);
  };
  const ToolbarManagerAndHr = (
    <div className={classes.actionBar}>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={(event: React.MouseEvent<HTMLElement>) =>
          setDropdownMenu(event.currentTarget as HTMLInputElement)
        }
      >
        {translate.t('laSelectUnit')}{' '}
        <Icon fontSize="small">arrow_drop_down</Icon>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={dropdownMenu}
        keepMounted={false}
        open={Boolean(dropdownMenu)}
        onClose={() => setDropdownMenu(null)}
      >
        {unitOptions.map((unit: Unit) => (
          <MenuItem
            key={unit.fTreeUnitId}
            onClick={selectUnit(unit)}
            selected={selectedUnit.fTreeUnitId === unit.fTreeUnitId}
            disabled={
              (isRecruiter() || isDManager()) && unit.fTreeUnitName === 'All'
            }
          >
            {unit.fTreeUnitName}
          </MenuItem>
        ))}
      </Menu>
      {selectedUnit && selectedUnit.fTreeUnitName}
      {isManager() && (
        <span>
          <Checkbox
            checked={relation === Relation.DIRECT}
            onChange={handleTeamCheckboxChange}
            value="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          {translate.t('laTeam')}
        </span>
      )}

      {!isEmployee() && !isRecruiter() && !isDManager() && (
        <span>
          <Checkbox
            checked={relation === Relation.ALL}
            onChange={handleSubunitsCheckboxChange}
            value="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          {translate.t('laIncludeSubunits')}
        </span>
      )}

      <Button
        onClick={saveUnitPositionReport}
        className={classes.downloadButton}
      >
        {translate.t('laDownload')} &nbsp;{' '}
        <Icon fontSize="small">cloud_download</Icon>
      </Button>
    </div>
  );

  return (
    <>
      <ExcelExport
        ref={excelExport => (_export = excelExport)}
        fileName={translate.t('laUnitPositionReport')}
      >
        <Grid
          data={process(entries, dataState)}
          onDataStateChange={dataStateChange}
          {...dataState}
          sortable={true}
          resizable={true}
          pageable={true}
          groupable={true}
        >
          <GridToolbar>{ToolbarManagerAndHr}</GridToolbar>
          {columns.map(
            (column: any, idx: number) =>
              column.show && (
                <GridColumn
                  {...column}
                  key={idx}
                  columnMenu={(otherProps: unknown) => (
                    <ColumnMenu
                      {...otherProps}
                      columns={columns}
                      onColumnsSubmit={onColumnsSubmit}
                      filterUI={
                        column.field === 'unitNo' ? unitFilterCell : undefined
                      }
                    />
                  )}
                />
              ),
          )}
        </Grid>
      </ExcelExport>
    </>
  );
};

const enhance = compose<Props, OwnProps>(
  connect(mapStateToProps),
  withStyles(styles),
);

export default enhance(UnitPositionReport);

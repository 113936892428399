import { TransactionsListInnerProps } from '@/app/components/TimeReporting/components/TimeSheet/components/DailySummary/components/TransactionsList/transactionsListModels';
import { ReportingGroupType } from '@/app/components/TimeReporting/components/TimeSheet/data';
import { getReportingOptions } from '@/app/components/AutoDeduction/useAutoDeductionOptions';
import moment from 'moment';
import { useMemo } from 'react';

export const useAutoDeduction = ({
  getFormValues,
  autoDeductionRule,
}: TransactionsListInnerProps) => {
  const formTimeBlocks = getFormValues('timeBlocks');

  const isEmptyObject = (obj: any) => Object.keys(obj).length === 0;

  const getTimeBlocksByTypes = (types: string[]) =>
    !!formTimeBlocks
      ? Object.entries(formTimeBlocks).filter(tb => {
          return !!tb[1].reportingType && types.includes(tb[1].reportingType);
        })
      : [];

  const getTypesByGroup = (group: ReportingGroupType) =>
    getReportingOptions(group).map(o => o.value);
  const reportingTypes = {
    correction: getTypesByGroup(ReportingGroupType.TIMESHEET_CORRECTION_TYPE),
    nonWork: getTypesByGroup(ReportingGroupType.TIMESHEET_NON_WORK_TYPE),
    work: getTypesByGroup(ReportingGroupType.TIMESHEET_WORK_TYPE),
  };

  const ruleChecksDefaults = {
    firstDeductionId: '',
    hasDeduction: false,
    skipDeduction: false,
  };
  const ruleChecks = useMemo(() => {
    const dataRequirements: boolean =
      !!autoDeductionRule && !isEmptyObject(formTimeBlocks);

    if (!dataRequirements) {
      return { ...ruleChecksDefaults };
    }

    const getCompoundType = (group: ReportingGroupType, type: string) =>
      `${group}-${type}`;

    const deductionTBs = getTimeBlocksByTypes([
      getCompoundType(
        ReportingGroupType.TIMESHEET_CORRECTION_TYPE,
        autoDeductionRule.correctionCode,
      ),
    ]);

    const nonWorkMatchFound =
      getTimeBlocksByTypes([
        getCompoundType(
          ReportingGroupType.TIMESHEET_NON_WORK_TYPE,
          autoDeductionRule.nonWorkingCode,
        ),
      ]).length > 0;

    const getMilliseconds = (value: string) =>
      moment.duration(value).asMilliseconds();
    const workTime = getTimeBlocksByTypes(reportingTypes.work)
      .map(tb => getMilliseconds(tb[1].hours))
      .reduce((prev, cur) => prev + cur, 0);
    const thresholdExceeded =
      workTime >= getMilliseconds(autoDeductionRule.threshold);

    return {
      ...ruleChecksDefaults,
      firstDeductionId: deductionTBs.map(tb => tb[0])[0],
      hasDeduction: deductionTBs.length > 0,
      skipDeduction: nonWorkMatchFound || !thresholdExceeded,
    };
  }, [formTimeBlocks, autoDeductionRule]);

  const isAutoDeductionRow = (timeBlockId: string) =>
    ruleChecks.hasDeduction &&
    ruleChecks.firstDeductionId === timeBlockId;

  const hasAutoDeductionLock = (timeBlockId: string) =>
    !ruleChecks.skipDeduction &&
    isAutoDeductionRow(timeBlockId);

  return {
    isAutoDeductionRow,
    hasAutoDeductionLock,
  };
};

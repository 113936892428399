import * as React from 'react';
import WizardDialog from '../../Wizard/WizardDialog';
import WizardDialogActions from '../../Wizard/WizardDialogActions';
import EnumValueForm from '../EnumValueForm/EnumValueForm';
import translate from '@/app/utils/translate';
import { IEnumGroup } from '../EnumGroupList/enumGroup';
import EnumTranslateForm from '../EnumTranslateForm/EnumTranslateForm';
import EnumDetailsForm from '../EnumDetailsForm/EnumDetailsForm';

const FORM_NAME = 'newEnum';

interface OwnProps {
  open: boolean;
  onClose: Function;
  onSave: Function;
  remoteFormSubmit?: (formName: string) => void;
  resetForm?: (formName: string) => void;
  group: IEnumGroup;
  disableNext?: any;
  isNextDisabled?: boolean;
}

type PropsType = OwnProps;

interface IState {
  activePage: number;
  close: boolean;
}

class EnumAddValueDialog extends React.Component<PropsType, IState> {
  state: IState = {
    activePage: 0,
    close: false,
  };

  componentDidMount() {
    this.props.resetForm(FORM_NAME);
  }

  componentDidUpdate(prevProps: OwnProps) {
    const { open } = this.props;
    if (!prevProps.open && open) {
      this.setState({ activePage: 0, close: false });
    }
  }

  componentWillUnmount() {
    this.props.resetForm(FORM_NAME);
  }

  handlePreviousPage = () => {
    this.setState((prevState: IState) => ({
      activePage: prevState.activePage - 1,
    }));
  };

  handleNext = (_value: any) => {
    this.setState((prevState: IState) => ({
      activePage: prevState.activePage + 1,
    }));
  };

  handleSave = (values: any, close: boolean) => {
    const { onSave } = this.props;
    onSave(values, this.props.group.name, close);
    this.resetContent(FORM_NAME);
  };

  resetContent = (formName: string) => {
    this.setState({
      activePage: 0,
    });
    this.props.resetForm(formName);
  };

  getPageContent = (page: number): React.ReactNode => {
    const { group, disableNext } = this.props;
    if (!this.props.open) {
      return <></>;
    }

    switch (page) {
      case 0:
        return (
          <EnumValueForm
            group={group}
            disableNext={disableNext}
            onSubmit={(values: any) => this.handleNext(values)}
          />
        );
      case 1:
        if (this.props.group.hasDetails()) {
          return (
            <EnumTranslateForm
              onSubmit={(values: any) => this.handleNext(values)}
            />
          );
        } else {
          return (
            <EnumTranslateForm
              onSubmit={(values: any) =>
                this.handleSave(values, this.state.close)
              }
            />
          );
        }
      case 2:
        return (
          <EnumDetailsForm
            onSubmit={(values: any) =>
              this.handleSave(values, this.state.close)
            }
          />
        );
      default:
        return <div>Unknown page requested!</div>;
    }
  };

  handleSaveAndClose(formName: string) {
    this.props.remoteFormSubmit(formName);
    this.setState({
      close: true,
    });
  }

  handleSaveAndNew(formName: string) {
    this.props.remoteFormSubmit(formName);
  }

  getPageActions = (page: number): React.ReactNode => {
    let onCancel, onNextPage, onPreviousPage, onSaveAndClose, onSaveAndNew;
    const { onClose, remoteFormSubmit, isNextDisabled } = this.props;

    switch (page) {
      case 0:
        onCancel = () => onClose(FORM_NAME);
        onNextPage = () => remoteFormSubmit(FORM_NAME);
        break;
      case 1:
        if (this.props.group.hasDetails()) {
          onPreviousPage = this.handlePreviousPage;
          onNextPage = () => remoteFormSubmit(FORM_NAME);
        } else {
          onPreviousPage = this.handlePreviousPage;
          onSaveAndNew = () => this.handleSaveAndNew(FORM_NAME);
          onSaveAndClose = () => this.handleSaveAndClose(FORM_NAME);
        }
        break;
      case 2:
        onPreviousPage = this.handlePreviousPage;
        onSaveAndNew = () => this.handleSaveAndNew(FORM_NAME);
        onSaveAndClose = () => this.handleSaveAndClose(FORM_NAME);
        break;
      default:
        return null;
    }

    return (
      <WizardDialogActions
        onCancel={onCancel}
        onNextPage={onNextPage}
        onPreviousPage={onPreviousPage}
        onSaveAndClose={onSaveAndClose}
        onSaveAndNew={onSaveAndNew}
        disabledNext={isNextDisabled}
      />
    );
  };

  render() {
    const { open, onClose } = this.props;
    const { activePage } = this.state;

    if (!this.props.group) {
      return <></>;
    }

    const pageTitles = [
      translate.t('laBasicData'),
      translate.t('title_translations'),
    ];
    if (this.props.group.hasDetails()) {
      pageTitles.push(translate.t('title_details'));
    }

    return (
      <WizardDialog
        activePage={activePage}
        pageTitles={pageTitles}
        title={`${translate.t('title_add_item')}`}
        open={open}
        onRenderPage={this.getPageContent}
        onRenderActions={this.getPageActions}
        onEsc={() => onClose(FORM_NAME)}
      />
    );
  }
}

export default EnumAddValueDialog;

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import {
  Grid,
  GridColumn,
  GridDataStateChangeEvent,
  GridToolbar,
} from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { process } from '@progress/kendo-data-query';
import * as moment from 'moment';
import React, {
  BaseSyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import API, { Relation, Scope } from '@/app/api/internalAPIs';
import ColumnMenu from '@/app/components/Kendo/ColumnMenu';
import {
  ChildInputType,
  Unit,
  WorktimeReportEntry,
  WorktimeReportResponseItem,
} from '@/app/components/WorktimeReport/types';
import { getStored, setStored } from '@/app/utils/helper';
import Service from '@/app/utils/service';
import translate from '@/app/utils/translate';
import {
  getLoggedUserId,
  getMyEmployeeUnitId,
  isOnlyAltMgr,
  isAltManager,
  isEmployee,
  isHR,
  isManager,
  isRecruiter,
  isDManager,
} from '@/old/utils/helper';

import IntegrationReactSelect from '../IntegrationReactSelect/IntegrationReactSelect';
import LocalizedDatePicker from '@/app/components/Pickers/LocalizedDatePicker';

import { gridColumns, gridColumnsDimensions } from './Columns/GridColumns';
import { reportingTypesOptions } from './Dimensions/ReportingType';
import { customEntityOptions } from './Dimensions/CustomEntities';
import DimensionsSumTable from './DimensionsSumTable';
import SelectFilter from './KendoFilters/SelectFilter';
import {
  getCustomDataColumns,
  mapReportForUI,
  mapReportForUIDimensions,
} from './service';
import styles from './styles';
import { StateProps, mapStateToProps } from './worktimeReportRedux';

const allUnitsOption = {
  value: '0',
  label: 'All',
};

type InnerProps = WithStyles<typeof styles>;
type OwnProps = {
  worktimeReport: boolean;
};

type Props = OwnProps & InnerProps & StateProps;

const WorktimeReport = (props: Props) => {
  const { classes, worktimeReport } = props;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>(undefined);

  const [hrUnits, setHRUnits] = useState<ChildInputType[]>([]);
  const [managerUnits, setManagerUnits] = useState<ChildInputType[]>([]);
  const [altManagerUnits, setAltManagerUnits] = useState<ChildInputType[]>([]);
  const [employeeUnits, setEmployeeUnits] = useState<ChildInputType[]>([]);
  const [selectedUnit, setSelectedUnit] = useState<ChildInputType>(
    allUnitsOption,
  );
  const [dimensions, setDimensions] = useState({
    date: null,
    reportingType: null,
    customEntities: [],
  });
  const [relation, setRelation] = useState<Relation | undefined>(
    isHR() && !isManager() && !isAltManager() ? Relation.ALL : Relation.DIRECT,
  );
  const [startDate, setStartDate] = useState(
    moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)),
  );
  const [endDate, setEndDate] = useState(moment(new Date().getTime()));

  const [entries, setEntries] = useState<WorktimeReportEntry[]>([]);

  const getColumns = useMemo(() => {
    const storedValue = worktimeReport
      ? getStored('WorkTimeReportSettings')
      : getStored('WorkTimeReportDimensionsSettings');
    const defaultColumns = worktimeReport
      ? gridColumns()
      : gridColumnsDimensions(dimensions);

    const storedValueWithCell = storedValue
      ? storedValue.columns.map((column: any) => {
        const findColumn = defaultColumns.find(
          (c: any) => c.field === column.field,
        );
        const columnCell =
          findColumn && findColumn.cell ? findColumn.cell : undefined;
        return {
          ...column,
          ...(columnCell ? { cell: columnCell } : {}),
        };
      })
      : [];

    return storedValue ? storedValueWithCell : defaultColumns;
  }, []);

  const getDataState = useMemo(() => {
    const storedValue = worktimeReport
      ? getStored('WorkTimeReportSettings')
      : getStored('WorkTimeReportDimensionsSettings');
    return storedValue ? storedValue.dataState : { skip: 0, take: 9 };
  }, []);

  const [columns, setColumns] = useState(getColumns);
  const [dataState, setDataState] = useState(getDataState);

  const [reportDimensionsDialogOpen, setReportDimensionsDialogOpen] = useState(
    false,
  );

  const [crudeRes, setCrudeRes] = useState([]);
  const myUserId = useMemo(() => getLoggedUserId(), []);

  const mapToChildInput = (data: Unit[]) =>
    data.map(unit => ({ value: unit.fTreeUnitId, label: unit.fTreeUnitName }));

  const checkIfNotCustom = (column: any) =>
    !getCustomDataColumns(props.customEntities, dimensions).find(
      customColumn => customColumn.field === column.field,
    );

  const columnsWithoutCustom = (activeColumns: any) =>
    activeColumns.filter((activeColumn: any) => checkIfNotCustom(activeColumn));

  useEffect(() => {
    if (crudeRes && crudeRes.length) {
      setEntries(
        mapReportForUIDimensions(
          crudeRes,
          props.allEnums,
          props.customEntities,
          props.timesheetReportingTypes,
          dimensions,
        ).filter(el => Object.keys(el).length !== 0),
      );
    }
  }, [crudeRes, dimensions]);

  useEffect(() => {
    setColumns(
      Array.from(
        new Set([
          ...columnsWithoutCustom(columns),
          ...getCustomDataColumns(props.customEntities, dimensions),
        ]),
      ),
    );
  }, [dimensions]);

  const translateTitles = (translateColumns: any) =>
    translateColumns.map((column: any) =>
      checkIfNotCustom(column)
        ? {
          ...column,
          title: translate.t(column.title),
        }
        : column,
    );

  const handleStartDateChange = useCallback(
    (date: moment.Moment | null) => {
      setStartDate(date);
    },
    [setStartDate],
  );

  const handleEndDateChange = useCallback(
    (date: moment.Moment | null) => {
      setEndDate(date);
    },
    [setEndDate],
  );

  const saveTableSettings = () => {
    const storageName = worktimeReport
      ? 'WorkTimeReportSettings'
      : 'WorkTimeReportDimensionsSettings';
    const saveData = {
      columns: columnsWithoutCustom(columns),
      dataState,
    };
    setStored(storageName, saveData);
  };

  useEffect(() => {
    Service.get(
      API.orgTree.primaryUnits(),
      (allUnits: Unit[]) => {
        if (isHR()) {
          Service.get(
            API.orgTree.myHrUnits(myUserId),
            (unitIds: number[]) => {
              const myHRUnits = allUnits.filter((unit: Unit) =>
                unitIds.includes(+unit.fTreeUnitId),
              );
              setHRUnits(mapToChildInput(myHRUnits));
              setLoading(false);
              setError(undefined);
            },
            (err: any) => {
              setLoading(false);
              setError(err);
            },
          );
        }

        if (isManager() && !isAltManager()) {
          Service.get(
            API.orgTree.myManagedUnits(myUserId),
            (unitIds: number[]) => {
              const myManagerUnits = allUnits.filter((unit: Unit) =>
                unitIds.includes(+unit.fTreeUnitId),
              );
              setManagerUnits(mapToChildInput(myManagerUnits));
              setLoading(false);
              setError(undefined);
            },
            (err: any) => {
              setLoading(false);
              setError(err);
            },
          );
        }

        if (isAltManager()) {
          Service.get(
            API.orgTree.myAltManagerUnits(myUserId),
            (unitIds: number[]) => {
              const myAltManagerUnits = allUnits.filter((unit: Unit) =>
                unitIds.includes(+unit.fTreeUnitId),
              );
              setAltManagerUnits(mapToChildInput(myAltManagerUnits));
              setLoading(false);
              setError(undefined);
            },
            (err: any) => {
              setLoading(false);
              setError(err);
            },
          );
        }

        if (isEmployee() || isRecruiter() || isDManager()) {
          const myEmployeeUnit = allUnits.find(
            (unit: Unit) =>
              +unit.fTreeUnitId === +getMyEmployeeUnitId(myUserId),
          );
          setEmployeeUnits(mapToChildInput([myEmployeeUnit]));
          setSelectedUnit({
            value: myEmployeeUnit.fTreeUnitId,
            label: myEmployeeUnit.fTreeUnitName,
          });
        }
      },
      (err: any) => {
        setLoading(false);
        setError(err);
      },
    );
  }, []);

  useEffect(() => {
    if (relation) {
      const unitScope =
        selectedUnit &&
        selectedUnit.value !== allUnitsOption.value &&
        !isEmployee() &&
        !isRecruiter() &&
        !isDManager();

      const scope = unitScope
        ? Scope.UNIT
        : (isHR() || isAltManager()) &&
          isManager() &&
          !isAltManager() &&
          relation === Relation.DIRECT
          ? Scope.MANAGER
          : isHR()
            ? Scope.HR
            : isManager() && !isOnlyAltMgr()
              ? Scope.MANAGER
              : isOnlyAltMgr()
                ? Scope.ALT_MANAGER
                : Scope.EMPLOYEE;

      const scopeId = unitScope ? selectedUnit.value : myUserId;

      Service.get(
        API.worktimeReport.getReport(
          scope,
          scopeId,
          relation,
          moment(startDate).format('YYYY-MM-DD'),
          moment(endDate).format('YYYY-MM-DD'),
        ),
        (res: WorktimeReportResponseItem[]) => {
          if (worktimeReport) {
            setEntries(
              mapReportForUI(
                res,
                props.allEnums,
                props.customEntities,
                props.timesheetReportingTypes,
                props.employeePositions,
              ),
            );
          } else {
            setCrudeRes(res);
          }
          setColumns(
            Array.from(
              new Set([
                ...columnsWithoutCustom(columns),
                ...getCustomDataColumns(props.customEntities, dimensions),
              ]),
            ),
          );
          setLoading(false);
          setError(undefined);
        },
        (err: any) => {
          setEntries([]);
          setLoading(false);
          setError(err);
        },
      );
    } else {
      setEntries([]);
    }
  }, [selectedUnit, relation, startDate, endDate]);

  const initialOptions =
    isEmployee() || isRecruiter() || isDManager() ? [] : [allUnitsOption];

  const sortedUnits = [
    ...hrUnits,
    ...managerUnits,
    ...altManagerUnits,
    ...employeeUnits,
  ].sort((a, b) => (a.label < b.label ? -1 : b.label > a.label ? 1 : 0));

  const unitOptions = useMemo(
    () => Array.from(new Set([...initialOptions, ...sortedUnits])),
    [hrUnits, managerUnits, altManagerUnits, employeeUnits],
  );

  if (loading) {
    return <div>{loading}</div>;
  }
  if (error) {
    return <div>{'error'}</div>;
  }
  if (!entries) {
    return <div>{'no data'}</div>;
  }

  const unitFilterCell = SelectFilter(
    unitOptions.map(u => u.label),
    'Select unit',
  );

  const onColumnsSubmit = (columnsState: any) => {
    setColumns(
      columnsState.map((column: any) => ({
        ...column,
        title: columns.find((c: any) => c.field === column.field).title,
      })),
    );
  };

  const createDataState = (dataStateProp: any) => {
    return {
      dataState: setDataState(dataStateProp),
    };
  };

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    return createDataState(event.data);
  };

  let _export: any;
  const saveClosedWorkflowsReport = () => {
    _export.save(
      process(entries, {
        ...dataState, // Export all the records
        skip: 0,
        take: undefined,
      }),
    );
  };

  const handleTeamCheckboxChange = (
    _: BaseSyntheticEvent,
    checked: boolean,
  ) => {
    setRelation(checked ? Relation.DIRECT : undefined);
  };

  const handleSubunitsCheckboxChange = (
    _: BaseSyntheticEvent,
    checked: boolean,
  ) => {
    setRelation(checked ? Relation.ALL : undefined);
  };

  const ToolbarManagerAndHr = (
    <div className={classes.actionBar}>
      <IntegrationReactSelect
        isMulti={false}
        isSearchable={true}
        label={translate.t('laSelectUnit')}
        placeholder={translate.t('laSelectUnit')}
        required={false}
        error=""
        value={selectedUnit ? selectedUnit : null}
        className={classes.unitSelect}
        onChange={(selectedValue: ChildInputType) =>
          setSelectedUnit(selectedValue)
        }
        options={unitOptions}
      />
      {isManager() && (
        <span>
          <Checkbox
            checked={relation === Relation.DIRECT}
            onChange={handleTeamCheckboxChange}
            value="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          {translate.t('laTeam')}
        </span>
      )}

      {!isEmployee() && !isRecruiter() && !isDManager() && (
        <span>
          <Checkbox
            checked={relation === Relation.ALL}
            onChange={handleSubunitsCheckboxChange}
            value="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          {translate.t('laIncludeSubunits')}
        </span>
      )}
      <LocalizedDatePicker
        name="startDate"
        format="L"
        margin="normal"
        label={translate.t('laStartDate')}
        value={startDate}
        onChange={handleStartDateChange}
        fullWidth={true}
        maxDate={endDate}
        className={classes.datePicker}
      />

      <LocalizedDatePicker
        name="endDate"
        format="L"
        margin="normal"
        label={translate.t('laEndDate')}
        value={endDate}
        onChange={handleEndDateChange}
        fullWidth={true}
        minDate={startDate}
        className={classes.datePicker}
      />
      {!worktimeReport && (
        <Button
          onClick={() => setReportDimensionsDialogOpen(true)}
          className={
            !dimensions.date &&
              !dimensions.reportingType &&
              !dimensions.customEntities.length
              ? classes.setReportDimensions
              : undefined
          }
        >
          {translate.t('setReportDimensions')}
        </Button>
      )}
      <Button
        style={{ marginLeft: '10px' }}
        onClick={() => saveTableSettings()}
      >
        {translate.t('laSaveTableSettings')}
      </Button>
      <div className={classes.rightComponent}>
        <Button onClick={saveClosedWorkflowsReport}>
          {translate.t('laDownload')} &nbsp;{' '}
          <Icon fontSize="small">cloud_download</Icon>
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <ExcelExport
        fileName={translate.t(
          worktimeReport ? 'laWorktimeReport' : 'laWorktimeReportDimensions',
        )}
        ref={excelExport => (_export = excelExport)}
      >
        <Grid
          data={process(entries, dataState)}
          onDataStateChange={dataStateChange}
          {...dataState}
          sortable={true}
          resizable={true}
          pageable={true}
          groupable={true}
        >
          <GridToolbar>{ToolbarManagerAndHr}</GridToolbar>
          {translateTitles(columns).map(
            (column: any, idx: number) =>
              column.show && (
                <GridColumn
                  {...column}
                  key={idx}
                  columnMenu={(otherProps: any) => (
                    <ColumnMenu
                      {...otherProps}
                      columns={translateTitles(columns)}
                      onColumnsSubmit={onColumnsSubmit}
                      filterUI={
                        column.field === 'unitNo' ? unitFilterCell : undefined
                      }
                    />
                  )}
                />
              ),
          )}
        </Grid>
      </ExcelExport>
      {!worktimeReport && (
        <DimensionsSumTable
          columns={translateTitles(columnsWithoutCustom(columns))}
          entries={
            process(entries, {
              ...dataState,
              skip: 0,
              take: undefined,
            }).data
          }
        />
      )}
      <Dialog
        open={reportDimensionsDialogOpen}
        fullWidth
        onClose={() => setReportDimensionsDialogOpen(false)}
      >
        <DialogTitle>{translate.t('setReportDimensions')}</DialogTitle>
        <DialogContent>
          <LocalizedDatePicker
            name="entryDate"
            format="L"
            margin="normal"
            label={translate.t('laDate')}
            value={dimensions.date}
            onChange={selectedDate =>
              setDimensions({ ...dimensions, date: selectedDate })
            }
            fullWidth={true}
            minDate={startDate}
            maxDate={endDate}
            className={classes.datePickerDimension}
            clearable
          />
          <IntegrationReactSelect
            isClearable={true}
            isMulti={false}
            isSearchable={true}
            label={translate.t('laReportingType')}
            placeholder={translate.t('laReportingType')}
            required={false}
            error=""
            onChange={(selectedValue: ChildInputType) =>
              setDimensions({ ...dimensions, reportingType: selectedValue })
            }
            options={reportingTypesOptions()}
            value={dimensions.reportingType}
            className={classes.dimensionSelect}
            noWrapValue={true}
          />
          <IntegrationReactSelect
            isClearable={true}
            isMulti={true}
            isSearchable={true}
            label={translate.t('laCustomFields')}
            placeholder={translate.t('laCustomFields')}
            required={false}
            error=""
            onChange={(selectedValues: ChildInputType[]) =>
              setDimensions({ ...dimensions, customEntities: selectedValues })
            }
            options={customEntityOptions(props.customEntities)}
            value={dimensions.customEntities}
            noWrapValue={true}
          />
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={() => setReportDimensionsDialogOpen(false)}
          >
            {translate.t('laSave')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const enhance = compose<Props, OwnProps>(
  connect(mapStateToProps),
  withStyles(styles),
);

export default enhance(WorktimeReport);

import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import * as React from 'react';

export const NullableTooltip: React.FC<TooltipProps> = (props) => {
    if (!props.title) {
        return props.children;
    }

    return <Tooltip {...props} />;
};

import * as React from 'react';
import { connect } from 'react-redux';
import { fetchAllEnums, selectAllEnums } from '@/app/redux/enums';

const FetchAllEnumsHOC = (PassedComponent: React.ComponentType) => {
  class FetchAllEnums extends React.Component<any> {
    componentDidMount() {
      if (!Object.keys(this.props.allEnums).length && !this.props.fetching) {
        this.props.fetchAllEnums();
      }
    }

    render () {
      if (!Object.keys(this.props.allEnums).length) {
        return null;
      }

      return <PassedComponent {...this.props} />;
    }
  }

  const mapStateToProps = (state: any) => ({
    fetching: state.enums.get('isFetching'),
    allEnums: selectAllEnums(state),
  });

  return connect(
    mapStateToProps,
    {
      fetchAllEnums,
    }
  )(FetchAllEnums);
};

export default FetchAllEnumsHOC;

import translate from '@/app/utils/translate';
import {
  StyleRulesCallback,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import {
  ParticipantDetailsPopover,
  RegistrationMessagePopover,
} from '../../../Trainings/components/participantsDialog/participantList/';
import { CompanyTraining, SEAT_STATUS } from '../../../Trainings/types';
import ParticipantsRow from './ParticipantsRow';
import SearchField from './SearchField';

const styles: StyleRulesCallback = (theme: Theme) => ({
  selectionCell: {
    width: 0,
  },
  cellButton: {
    textTransform: 'none',
    boxShadow: theme.shadows[1],
    lineHeight: 1,
    fontWeight: 'normal',
  },
  employeeName: {
    paddingLeft: theme.spacing.unit,
  },
  grow: {
    flex: 1,
  },
  actionButton: {
    padding: theme.spacing.unit,
    marginLeft: theme.spacing.unit / 2,
  },
  headerActions: {
    display: 'flex',
  },
  searchEmployeeContainer: {
    flexGrow: 1,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 2,
    minWidth: 150,
    maxWidth: 450,
  },
  waitingRow: {
    backgroundColor: theme.palette.grey[200],
  },
  rejectedRow: {
    backgroundColor: theme.palette.grey[400],
  },
});

type OwnProps = {
  training: CompanyTraining;
  participants: any;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const GLOBAL: any = window;

const ParticipantList = ({ classes, training, participants }: Props) => {
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [participantDetailsAnchorEl, setParticipantDetailsAnchorEl] = useState(
    undefined,
  );
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [
    registrationMessageAnchorEl,
    setRegistrationMessageAnchorEl,
  ] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchValue, setSearchValue] = useState(null);

  const participantDetailsPopover =
    !selectedParticipant || !participantDetailsAnchorEl ? null : (
      <ParticipantDetailsPopover
        participant={selectedParticipant}
        anchorEl={participantDetailsAnchorEl}
        onClose={() => {
          setParticipantDetailsAnchorEl(undefined);
          setSelectedParticipant(undefined);
        }}
      />
    );

  const registrationMessagePopover =
    !selectedMessage || !registrationMessageAnchorEl ? null : (
      <RegistrationMessagePopover
        message={selectedMessage}
        anchorEl={registrationMessageAnchorEl}
        onClose={() => {
          setRegistrationMessageAnchorEl(undefined);
          setSelectedMessage(undefined);
        }}
      />
    );

  const filterFunc = (options: any, searchTermLC: any, statuses: any) =>
    options
      .filter((item: any) => {
        const employee = GLOBAL.jsonAllEmp[item.participant.employeeId];
        return (
          (statuses.length === 0 ||
            statuses.indexOf(employee.fEmpStatus.toLocaleUpperCase()) > -1) &&
          (searchTerm === '' ||
            employee.fEmpFirstName
              .toLocaleLowerCase()
              .toLowerCase()
              .includes(searchTermLC) ||
            employee.fEmpLastName.toLocaleLowerCase().includes(searchTermLC) ||
            employee.fEmpPosition.toLocaleLowerCase().includes(searchTermLC) ||
            employee.fEmpPersonNumber
              .toLocaleLowerCase()
              .includes(searchTermLC))
        );
      })
      .map((item: any) => {
        const employee = GLOBAL.jsonAllEmp[item.participant.employeeId];
        return {
          value: item.participant.employeeId,
          label: `${employee.fEmpFirstName} ${employee.fEmpLastName} (${item.position})`,
        };
      });

  return (
    <>
      {participantDetailsPopover}
      {registrationMessagePopover}

      <div className={classes.headerActions}>
        <div className={classes.searchEmployeeContainer}>
          <SearchField
            inputValue={searchTerm}
            value={searchValue}
            onInputChange={(value: string) => setSearchTerm(value)}
            optionsData={participants}
            onChange={id => {
              if (id) {
                const employee = GLOBAL.jsonAllEmp[id];
                setSearchValue({
                  value: id,
                  label: `${employee.fEmpFirstName} ${employee.fEmpLastName}`,
                });
              } else {
                setSearchValue(null);
              }
            }}
            filterFunction={filterFunc}
          />
        </div>
      </div>

      <Table padding="dense">
        <TableHead>
          <TableRow>
            <TableCell>{translate.t('laEmployee')}</TableCell>
            <TableCell>{translate.t('laUnit')}</TableCell>
            <TableCell>{translate.t('label_approval_status')}</TableCell>
            <TableCell>{translate.t('laCC')}</TableCell>
            <TableCell>{translate.t('label_completion')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {searchValue
            ? participants
                .filter(
                  (registration: any) =>
                    registration.participant.employeeId === searchValue.value,
                )
                .map((registration: any, index: any) => {
                  const rowClassName =
                    registration.seatStatus !== SEAT_STATUS.HAS_SEAT
                      ? registration.seatStatus === SEAT_STATUS.NO_SEAT
                        ? classes.rejectedRow
                        : classes.waitingRow
                      : '';
                  const employee =
                    GLOBAL.jsonAllEmp[registration.participant.employeeId];
                  return (
                    <ParticipantsRow
                      key={index}
                      training={training}
                      rowClassName={rowClassName}
                      employee={employee}
                      registration={registration}
                      setSelectedParticipant={setSelectedParticipant}
                      setParticipantDetailsAnchorEl={
                        setParticipantDetailsAnchorEl
                      }
                      setSelectedMessage={setSelectedMessage}
                      setRegistrationMessageAnchorEl={
                        setRegistrationMessageAnchorEl
                      }
                    />
                  );
                })
            : participants.map((registration: any, index: any) => {
                const rowClassName =
                  registration.seatStatus !== SEAT_STATUS.HAS_SEAT
                    ? registration.seatStatus === SEAT_STATUS.NO_SEAT
                      ? classes.rejectedRow
                      : classes.waitingRow
                    : '';
                const employee =
                  GLOBAL.jsonAllEmp[registration.participant.employeeId];
                return (
                  <ParticipantsRow
                    training={training}
                    rowClassName={rowClassName}
                    employee={employee}
                    registration={registration}
                    setSelectedParticipant={setSelectedParticipant}
                    setParticipantDetailsAnchorEl={
                      setParticipantDetailsAnchorEl
                    }
                    key={index}
                    setSelectedMessage={setSelectedMessage}
                    setRegistrationMessageAnchorEl={
                      setRegistrationMessageAnchorEl
                    }
                  />
                );
              })}
        </TableBody>
      </Table>
    </>
  );
};

export default withStyles(styles)(ParticipantList);

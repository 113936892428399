import { GridCellProps } from '@progress/kendo-react-grid';
import { IconButton } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import * as React from 'react';

export const CommentsCellFactory = (
    onViewComments: (taskId: number) => void,
): React.FC<GridCellProps> => ({
    rowType,
    dataItem,
}) => {
    const handleViewComments = React.useCallback(
        () => { onViewComments(dataItem.id); },
        [dataItem],
    );

    if (rowType !== 'data') {
        return null;
    }

    return (
        <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
            {dataItem.numberOfComments}

            <IconButton onClick={handleViewComments}>
                <Visibility fontSize="small" />
            </IconButton>
        </td>
    );
};
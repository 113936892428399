import * as React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import {
  WithStyles,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { getEmployeeName, getLoggedUserId } from '@/old/utils/helper';
import stageSelectStyle from './stageSelectStyle';
import { getDefault } from '@/app/components/JobEvaluation/helpers';
import {
  ActiveTemplate,
  EvaluationStage,
} from '@/app/components/JobEvaluation/types';

type IncomingProps = {
  activeTemplate: ActiveTemplate;
  changeEvaluationStage: (
    setStage: EvaluationStage,
    userMakingTheChange: string,
  ) => Promise<void>;
};

type PropsType = IncomingProps & WithStyles;

type StateType = {
  setStage: { id: number; label: string; default: boolean };
};

class StageSelect extends React.PureComponent<PropsType> {
  state: StateType = {
    setStage: getDefault(this.props.activeTemplate.stages),
  };

  constructor(props: PropsType) {
    super(props);
    const { stages, setStage } = props.activeTemplate;
    const removeDeletedStaged = stages.filter(
      (stage: EvaluationStage) => !stage.isDeleted,
    );
    const defStageFromTemplate = getDefault(removeDeletedStaged);
    this.state = {
      // Stage exists - was not soft deleted - id of set stage > template default
      setStage:
        setStage &&
        !setStage.isDeleted &&
        setStage.id > parseInt((defStageFromTemplate || { id: '' }).id, 10)
          ? setStage
          : getDefault(stages),
    };
  }

  handleChange = (event: React.ChangeEvent<HTMLOptionElement>) => {
    const { stages } = this.props.activeTemplate;
    const currentUserName = getEmployeeName(getLoggedUserId(), true);
    let newStage;
    stages.forEach((stage: EvaluationStage) => {
      if (stage.id === Number(event.target.value)) {
        newStage = stage;
      }
    });
    this.setState({ setStage: newStage });
    this.props.changeEvaluationStage(newStage, currentUserName);
  };

  render() {
    const { stages } = this.props.activeTemplate;
    const { setStage } = this.state;
    const activeUniqueStages =
      stages && stages.length
        ? stages.reduce(
            (acc: Array<EvaluationStage>, stage: EvaluationStage) => {
              const isFound = acc.find(
                existingStage => existingStage.id === stage.id,
              );
              if (stage.isDeleted || isFound) {
                return acc;
              }

              return acc.concat(stage);
            },
            [],
          )
        : [];

    return (
      <FormControl className={this.props.classes.root}>
        <RadioGroup
          aria-label="position"
          name="position"
          value={this.state.setStage.label}
          onChange={this.handleChange}
          row
        >
          {activeUniqueStages.map((stage: EvaluationStage) => {
            return (
              <FormControlLabel
                key={stage.id}
                value={stage.id.toString()}
                control={
                  <Radio color="primary" checked={stage.id === setStage.id} />
                }
                label={stage.label}
                labelPlacement="top"
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  }
}

const enhance = compose<PropsType, IncomingProps>(withStyles(stageSelectStyle));

export default enhance(StageSelect);

import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const addComponentFormStyle: StyleRulesCallback = () => ({
  root: {
    textAlign: 'center',
    padding: '25px 25px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) !important',
    width: '400px',
    maxHeight: '400px',
    overflow: 'auto',
    overflowX: 'hidden',
  },
  form: {
    width: '100%',
  },
  topTitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  formControl: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonsWrapper: {
    textAlign: 'right',
    paddingTop: '20px',
  },
});

export default addComponentFormStyle;

import * as React from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button
} from '@material-ui/core';
import translate from '@/app/utils/translate';

interface Props {
  open: boolean;
  onOk: Function;
  text: string;
  onClose: any;
  customOk?: string;
  customClose?: string;
}

const ConfirmDialog: React.FunctionComponent<Props> = ({ open, onOk, text, onClose, customOk = '', customClose = '' }) => {
  const handleOk = (e: React.SyntheticEvent) => {
    onClose(e);
    onOk(e);
  };

  const handleClose = (e: React.SyntheticEvent) => {
    onClose(e);
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="confirmation-dialog-title"
      disableBackdropClick
      onEscapeKeyDown={onClose}
      maxWidth="xs"
    >
      <DialogTitle id="confirmation-dialog-title">{translate.t('laConfirm')}</DialogTitle>
      <DialogContent>{text}</DialogContent>
      <DialogActions style={{margin: '8px 20px'}}>
        <Button
          variant="text"
          component="a"
          onClick={handleClose}
          color="primary"
          data-testid="btn-confirm-dialog-close"
        >
          {customClose ? customClose : translate.t('laCancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOk}
          data-testid="btn-confirm-dialog-confirm"
        >
          {customOk === '' ? translate.t('laOk') : customOk}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;

import React, { useState } from 'react';
import { Button, Icon, Menu, MenuItem, Typography } from '@material-ui/core';
import translate from '@/app/utils/translate';
import { CompletionAction, COMPLETION_STATUS } from '../../../../types';
import { getCompletionStatusName } from '../../../../helpers';

type OwnProps = {
  className?: string;
  readOnly?: boolean;
  onChangeCompletionStatus: (action: CompletionAction) => void;
};
type InnerProps = {};
type Props = OwnProps & InnerProps;

const CompletionStatusHeadButton = ({
  className,
  readOnly = false,
  onChangeCompletionStatus,
}: Props) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(undefined);

  const handleChangeCompletionStatus = (action: CompletionAction) => {
    setMenuAnchorEl(undefined);
    onChangeCompletionStatus(action);
  };

  if (readOnly) {
    return <Typography variant="inherit">{translate.t('label_completion')}</Typography>;
  }

  return (
    <>
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(undefined)}
      >
        <MenuItem onClick={() => handleChangeCompletionStatus('CHANGE_ALL_SEATERS_TO_COMPLETED')}>
          {translate.t('label_set_all_seaters_as_status', { status: getCompletionStatusName(COMPLETION_STATUS.COMPLETED).toLocaleUpperCase() })}
        </MenuItem>
        <MenuItem onClick={() => handleChangeCompletionStatus('CHANGE_ALL_TO_DID_NOT_COMPLETE')}>
          {translate.t('label_set_all_as_status', { status: getCompletionStatusName(COMPLETION_STATUS.DID_NOT_COMPLETE).toLocaleUpperCase() })}
        </MenuItem>
      </Menu>
      <Button className={className} color="inherit" onClick={e => setMenuAnchorEl(e.target)}>
        {translate.t('label_completion')}
        <Icon>arrow_drop_down</Icon>
      </Button>
    </>
  );
};

export default CompletionStatusHeadButton;

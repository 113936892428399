import React from 'react';
import {
  Grid,
  Icon,
  IconButton,
  StyleRulesCallback,
  TableCell,
  TableRow,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';

import { CompanyTraining } from '../../../Trainings/types';
import { getParticipantName, getUnitName } from '../../../Trainings/helpers';

import { ParticipantAvatar } from '../../../Trainings/components/participantsDialog/participantList/';
import { getCostCenter } from '@/old/utils/helper';
import moment from 'moment';

const styles: StyleRulesCallback = (theme: Theme) => ({
  selectionCell: {
    width: 0,
  },
  cellButton: {
    textTransform: 'none',
    boxShadow: theme.shadows[1],
    lineHeight: 1,
    fontWeight: 'normal',
  },
  employeeName: {
    paddingLeft: theme.spacing.unit,
  },
  grow: {
    flex: 1,
  },
  actionButton: {
    padding: theme.spacing.unit,
    marginLeft: theme.spacing.unit / 2,
  },
  headerActions: {
    display: 'flex',
  },
  searchEmployeeContainer: {
    flexGrow: 1,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 2,
    minWidth: 150,
    maxWidth: 450,
  },
  waitingRow: {
    backgroundColor: theme.palette.grey[200],
  },
  rejectedRow: {
    backgroundColor: theme.palette.grey[400],
  },
});

type OwnProps = {
  training: CompanyTraining;
  registration: any;
  setParticipantDetailsAnchorEl: Function;
  setSelectedParticipant: Function;
  setRegistrationMessageAnchorEl: Function;
  setSelectedMessage: Function;
  rowClassName: string;
  employee: any;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const ParticipantRow = ({
  classes,
  registration,
  setSelectedParticipant,
  setParticipantDetailsAnchorEl,
  setSelectedMessage,
  setRegistrationMessageAnchorEl,
  rowClassName,
  employee,
  training,
}: Props) => {
  const CC = registration.participant.costCenter
    ? getCostCenter(registration.participant.costCenter)
    : undefined;
  const checkIfTrainingEnded = () =>
    training.endDate ? moment(new Date()).isBefore(training.endDate) : true;

  const renderUnitName = () => {
    if (employee && employee.fEmpUnit) {
      return getUnitName(employee.fEmpUnit);
    }
    return '';
  };

  return (
    <TableRow className={rowClassName}>
      <TableCell>
        <Grid container={true} alignItems="center" wrap="nowrap">
          <Grid item={true}>
            <ParticipantAvatar participant={registration.participant} />
          </Grid>

          <Grid item={true} className={classes.grow}>
            <Typography variant="body1" className={classes.employeeName} noWrap>
              {getParticipantName(registration.participant)}

              <IconButton
                className={classes.actionButton}
                onClick={e => {
                  setParticipantDetailsAnchorEl(e.target);
                  setSelectedParticipant(registration.participant);
                }}
              >
                <Icon>info_outlined</Icon>
              </IconButton>

              {registration.message && checkIfTrainingEnded() && (
                <IconButton
                  className={classes.actionButton}
                  onClick={e => {
                    setRegistrationMessageAnchorEl(e.target);
                    setSelectedMessage(registration.message);
                  }}
                >
                  <Icon>message_outlined</Icon>
                </IconButton>
              )}
            </Typography>
          </Grid>
        </Grid>
      </TableCell>

      <TableCell>{renderUnitName()}</TableCell>

      <TableCell>{registration.approvalStatus.status.label}</TableCell>

      <TableCell>{CC ? CC.fCostCenterName : ''}</TableCell>

      <TableCell>{registration.completionStatus.label}</TableCell>
    </TableRow>
  );
};

export default withStyles(styles)(ParticipantRow);

import { Paper } from '@material-ui/core';
import translate from '@/app/utils/translate';

import React, { useState, useEffect, ReactNode } from 'react';
import { compose } from 'recompose';

import EnhancedTable from '../EnhancedTable/EnhancedTable';
import { Tools } from '../EnhancedTable/EnhancedTableToolbar/EnhancedTableToolbar';
import { HeadData } from '../EnhancedTable/EnhancedTableHead/EnhancedTableHead';
import AutoDeductionRuleFormDialog from './AutoDeductionRuleFormDialog';
import { throwError } from '@/app/redux/error';
import {
  fetchRules,
  deleteRule,
  editRule,
  addRule,
} from '@/app/redux/autoDeduction';
import { reset } from 'redux-form';
import { resetForm } from '../FormDialog/FormDialog';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  ConfirmDialogType,
  openConfirmDialog,
} from '@/app/redux/confirmDialog';
import {
  AutoDeductionRule,
  SelectOptionProps,
} from '@/app/components/AutoDeduction/types';
import { useAutoDeductionOptions } from '@/app/components/AutoDeduction/useAutoDeductionOptions';
import moment from 'moment';

interface MapStateToProps {
  allRules: AutoDeductionRule[];
}

interface MapDispatchToProps {
  throwError: (err: any) => void;
  resetForm: (formName: string) => void;
  fetchRules: () => void;
  deleteRule: (rule: AutoDeductionRule) => Promise<any>;
  editRule: (rule: AutoDeductionRule) => Promise<AutoDeductionRule>;
  addRule: (rule: AutoDeductionRule) => Promise<AutoDeductionRule>;
  openConfirmDialog: (payload: ConfirmDialogType) => void;
}

type PropsType = MapStateToProps & MapDispatchToProps;

const convertToPositiveIsoDuration = (durationMMss: string): string => {
  const positiveDurationMs = Math.abs(moment.duration(durationMMss).asMilliseconds());

  return `${moment.duration(positiveDurationMs)}`;
};

const AutoDeductionRulesComponent: React.FC<PropsType> = props => {
  const { allRules, fetchRules: fetchAutoDeductionRules } = props;

  const {
    countryOptions,
    unitOptions,
    correctionTypeOptions,
    nonWorkTypeOptions,
  } = useAutoDeductionOptions();

  const [isFormDialogOpen, setIsFormDialogOpen] = useState<boolean>(false);
  const [formDialogTitle, setFormDialogTitle] = useState<string>(
    translate.t('laAutoDeductionRules'),
  );
  const [rule, setRule] = useState<AutoDeductionRule>(null);
  const [edit, setEdit] = useState<boolean>(false);

  useEffect(() => {
    fetchAutoDeductionRules();
  }, []);

  const handleAdd = (_event: React.MouseEvent) => {
    setEdit(false);
    setIsFormDialogOpen(true);
  };

  const handleEdit = (selectedItems: Array<any>) => (
    _event: React.MouseEvent,
  ) => {
    if (!selectedItems || !selectedItems.length) {
      return;
    }

    const index = allRules.findIndex((r: any) => r.id === selectedItems[0].id);
    setRule(allRules[index]);
    setEdit(true);
    setIsFormDialogOpen(true);
    setFormDialogTitle(translate.t('title_edit_auto_deduction_rule'));
  };

  const handleDelete = (selectedItems: Array<any>) => (
    _event: React.MouseEvent,
  ) => {
    if (!selectedItems || !selectedItems.length) {
      return;
    }

    props.openConfirmDialog({
      text: translate.t('confirm_delete_item_s'),
      onOk: () => {
        const deletionPromises = selectedItems.map(selectedItem => props.deleteRule(selectedItem));
        Promise.all(deletionPromises).finally(() => {
          props.fetchRules();
        });
      }
    });
  };

  const handleActivation = (active: boolean) => (selectedItems: AutoDeductionRule[]) => (
    _event: React.MouseEvent,
  ) => {
    if (!selectedItems || !selectedItems.length) {
      return;
    }

    const activationPromises = selectedItems.map(selectedItem => props.editRule({
      ...selectedItem,
      duration: convertToPositiveIsoDuration(selectedItem.duration),
      threshold: convertToPositiveIsoDuration(selectedItem.threshold),
      isActive: active
    }));

    Promise.all(activationPromises).finally(() => {
      props.fetchRules();
    });
  };

  const handleFormDialogClose = () => {
    setRule(null);
    setIsFormDialogOpen(false);
  };

  const handleSubmit = (data: AutoDeductionRule) => {
    if (!data) {
      return;
    }

    const payloadData: AutoDeductionRule = {
      ...data,
      unitId: parseInt(`${data.unitId}`, 10),
      duration: convertToPositiveIsoDuration(data.duration),
      threshold: convertToPositiveIsoDuration(data.threshold),
    };

    if (edit) {
      props.editRule(payloadData);
    } else {
      props.addRule(payloadData).then(() => {
        props.fetchRules();
      });
    }

    handleFormDialogClose();
    setEdit(false);
  };

  const TOOLS: Tools = {
    showAdd: true,
    showEdit: true,
    showActive: true,
    showDelete: true,
  };

  const renderOptionCell = (
    options: SelectOptionProps[],
    fieldValue: any,
  ): ReactNode => {
    const opt = options.find(o => o.value === fieldValue);
    return !!opt && opt.label ? opt.label : fieldValue;
  };

  const renderActiveCell = (fieldValue: any) =>
    translate.t(!!fieldValue ? 'label_ACTIVE' : 'label_inactive').toUpperCase();

  const headData: Array<HeadData> = [
    {
      id: 'countryCode',
      type: 'string',
      disablePadding: false,
      label: translate.t('laCountry'),
      renderCustomDataCell: dataItem =>
        renderOptionCell(countryOptions, dataItem.countryCode),
    },
    {
      id: 'unitId',
      type: 'string',
      disablePadding: false,
      label: translate.t('laUnit'),
      renderCustomDataCell: dataItem =>
        renderOptionCell(unitOptions, dataItem.unitId ? dataItem.unitId.toString() : ''),
    },
    {
      id: 'correctionCode',
      type: 'string',
      disablePadding: false,
      label: translate.t('laCorrectionType'),
      renderCustomDataCell: dataItem =>
        renderOptionCell(correctionTypeOptions, dataItem.correctionCode),
    },
    {
      id: 'nonWorkingCode',
      type: 'string',
      disablePadding: false,
      label: translate.t('laNonWorkType'),
      renderCustomDataCell: dataItem =>
        renderOptionCell(nonWorkTypeOptions, dataItem.nonWorkingCode),
    },
    {
      id: 'duration',
      type: 'number',
      disablePadding: false,
      label: translate.t('laWorktimeDeductionAmount'),
    },
    {
      id: 'threshold',
      type: 'number',
      disablePadding: false,
      label: translate.t('laWorktimeDeductionThreshold'),
    },
    {
      id: 'isActive',
      type: 'string',
      disablePadding: false,
      label: translate.t('laActive'),
      renderCustomDataCell: dataItem => renderActiveCell(dataItem.isActive),
    },
  ];

  return (
    <Paper>
      <AutoDeductionRuleFormDialog
        open={isFormDialogOpen}
        onClose={handleFormDialogClose}
        onSubmit={handleSubmit}
        title={formDialogTitle}
        data={rule}
        allRules={allRules}
        resetForm={resetForm}
      />
      <EnhancedTable
        expanded={true}
        title={translate.t('laAutoDeductionRules')}
        tools={TOOLS}
        addHandler={handleAdd}
        editHandler={handleEdit}
        deleteHandler={handleDelete}
        deactivateHandler={handleActivation(false)}
        activateHandler={handleActivation(true)}
        headData={headData}
        data={allRules}
        order={'asc'}
        orderBy={'countryCode'}
      />
    </Paper>
  );
};

const mapStateToProps = (state: any) => {
  const { rules } = state.autoDeduction;

  return { allRules: rules };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  throwError: (err: any) => dispatch(throwError(err)),
  resetForm: (formName: string) => dispatch(reset(formName)),
  fetchRules: () => dispatch<any>(fetchRules()),
  deleteRule: (rule: AutoDeductionRule) => dispatch<any>(deleteRule(rule)),
  editRule: (rule: AutoDeductionRule) => dispatch<any>(editRule(rule)),
  addRule: (rule: AutoDeductionRule) => dispatch<any>(addRule(rule)),
  openConfirmDialog: (payload: ConfirmDialogType) =>
    dispatch(openConfirmDialog(payload)),
});

export const AutoDeductionRules = compose<any, any>(
  connect(mapStateToProps, mapDispatchToProps),
)(AutoDeductionRulesComponent);

import * as React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import * as moment from 'moment';
import { selectAllEnums } from '@/app/redux/enums';
import translate from '@/app/utils/translate';
import ReportView from '../components/ReportView';
import KendoGenericUrlCell from '../components/KendoGenericUrlCell';
import { IReportConfig } from '../ReportConfig';
import { IReportSettings } from '../ReportSettings';
class DecisionRegistryReport extends React.Component<any, any> {
  private config: IReportConfig = {
    id: 'DECISION_REGISTRY_REPORT',
    report: 'decisionRegistryReport',
    titleLabel: 'laDecisionRegistryReport',
    access: 'standard',
    columns: [
      { field: 'Name', title: translate.t('laName'), width: '200px', cell: (props) => {
        return (
          <KendoGenericUrlCell
            page={'page-people'}
            empId={props.dataItem.EmployeeId}
            text={props.dataItem.Name}
            tab={'idEmpTabDecisionRegistry'}
          />
        );
      }},
      { field: 'Type', title: translate.t('laType'), width: '150px' },
      { field: 'Title', title: translate.t('laTitle'), width: '150px' },
      { field: 'InventoryNumber', title: translate.t('laInventoryNumber'), width: '150px' },
      { field: 'DecisionDate', title: translate.t('laDecisionDate'), width: '150px' },
      { field: 'DecisionMaker', title: translate.t('laDecisionMaker'), width: '200px' },
      { field: 'DecisionInfo', title: translate.t('laInfo'), width: '200px' },
      { field: 'ReminderDate', title: translate.t('laReminderDate'), width: '150px' },
    ],
    excelExportColumns: [
      { field: 'Name', title: 'Name' },
      { field: 'Type', title: 'Type' },
      { field: 'Title', title: 'Title' },
      { field: 'InventoryNumber', title: 'Inventory Number' },
      { field: 'DecisionDate', title: 'Decision Date' },
      { field: 'DecisionMaker', title: 'Decision Maker' },
      { field: 'DecisionInfo', title: 'Information' },
      { field: 'ReminderDate', title: 'Reminder Date' },
    ],
    filters: {
      scopeFilter: {
        enabled: true,
        accessRights: {
          company: ['HR_ADMIN', 'MANAGER'],
          dManagerAll: ['DEPUTY_MANAGER'],
          dManagerDirect: ['DEPUTY_MANAGER'],
          managerAll: ['MANAGER'],
          managerDirect: ['MANAGER'],
          unitAll: ['MANAGER', 'DEPUTY_MANAGER', 'HR_STAFF', 'HR_ADMIN'],
          unitDirect: ['MANAGER', 'DEPUTY_MANAGER', 'HR_STAFF', 'HR_ADMIN'],
        },
      },
      customFilters: [
        {
          type: 'NONE',
          params: {
            selectedSettingName: 'laNoPlans',
            fieldName: 'HasGoals',
            operator: 'neq',
            buttonLabel: 'laNoPlans',
            value: false,
          },
        }
      ],
      dateFilter: {
        enabled: false,
      },
    },
  };

  private defaultSettings: IReportSettings = {
    report: 'decisionregistry',
    scope: {
      type: '',
      range: {
        startDate: moment().add(1, 'day').subtract(6, 'month'),
        endDate: moment().add(1, 'day'),
      }
    },
    custom: {
      laNoPlans: false,
      laNoReviews: false
    },
  };

  mapData = (origData: any): any => {
    const extendedData = [];
    for (let i = 0; i < origData.length; i ++) {
      for (let j = 0; j < origData[i].decisions.length; j++) {
        extendedData.push({
          employeeId: origData[i].employee.id,
          firstName: origData[i].employee.firstName,
          lastName: origData[i].employee.lastName,
          decisionId: origData[i].decisions[j].id,
          type : origData[i].decisions[j].type,
          title : origData[i].decisions[j].title,
          inventoryNumber: origData[i].decisions[j].inventoryNumber,
          decisionDate: origData[i].decisions[j].date,
          decisionMaker: origData[i].decisions[j].maker,
          decisionInfo: origData[i].decisions[j].info,
          reminderDate: origData[i].decisions[j].reminderDate,
        });
      }
    }
    return extendedData.map((employee: any) => {
      return {
        EmployeeId: employee.employeeId,
        Name: employee.firstName + ' ' + employee.lastName,
        Title: employee.title,
        Type: employee.type,
        InventoryNumber: employee.inventoryNumber,
        DecisionDate: employee.decisionDate,
        DecisionMaker: employee.decisionMaker,
        DecisionInfo: employee.decisionInfo,
        ReminderDate: employee.reminderDate,
      };
    });
  }

  render() {
    return (
      <ReportView
        moduleId="people"
        reportId="DECISION_REGISTRY_REPORT"
        config={this.config}
        defaultSettings={this.defaultSettings}
        kendoGrouping={[]}
        onDataMapping={this.mapData}
        onGetCustomFilters={() => this.config.filters.customFilters}
        sort={[{ field: 'Name', dir: 'asc' }]}
        exportFileName={translate.t('laDecisionRegistryReport')}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  allEnums: selectAllEnums(state),
});

const enhance = compose<any, any>(
  connect(mapStateToProps),
);

export default enhance(DecisionRegistryReport);
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useYupValidationResolver } from '@/app/hooks/useYupValidationResolver';
import { formatDurationToHMM, parseHMMToDuration, parseHMMToDurationISOString } from '@/app/utils/helper';
import translate from '@/app/utils/translate';

import { CountryHoursSettings } from '../../hoursSettingsReducer';

export interface WorkTimeSettingsFormValues {
    workDayHours: string;
    maxCumulativeHours: string;
    minCumulativeHours: string;
    blockAfterDays: string;
}

export const useEditWorkTimeSettingsDialog = (
    onSave: (data: Omit<CountryHoursSettings, 'countryCode'>) => void,
    hours: Omit<CountryHoursSettings, 'countryCode'>
) => {
    const defaultFormValues = useMemo(
        () => {
            return {
                workDayHours: formatDurationToHMM(hours.workDayHours),
                maxCumulativeHours: formatDurationToHMM(hours.maxCumulativeHours),
                minCumulativeHours: formatDurationToHMM(hours.minCumulativeHours),
                blockAfterDays: hours.blockAfterDays.toString(),
            };
        },
        [],
    );

    const validationSchema = useMemo(
        () => {
            return yup.object().shape<WorkTimeSettingsFormValues>({
                workDayHours: yup.string()
                    .required(translate.t('laThisRequired'))
                    .test(
                        'duration',
                        translate.t('laErrorDefaultWorkingHoursDurationFormat'),
                        (value: string) => {
                            if (!value) {
                                return true;
                            }

                            const duration = parseHMMToDuration(value);

                            if (!duration) {
                                return false;
                            }

                            const numberOfHours = duration.asHours();
                            return numberOfHours >= 0 && numberOfHours <= 24;
                        }
                    ),
                maxCumulativeHours: yup.string()
                    .required(translate.t('laThisRequired'))
                    .test(
                        'duration',
                        translate.t('laErrorDurationFormat'),
                        (value: string) => !value ||
                            !!parseHMMToDuration(value) &&
                            parseHMMToDuration(value).asMilliseconds() > 0
                    ),
                minCumulativeHours: yup.string()
                .required(translate.t('laThisRequired'))
                .test(
                    'duration',
                    translate.t('laErrorDurationFormat'),
                    (value: string) => !value ||
                        !!parseHMMToDuration(value)
                ),
                blockAfterDays: yup.string().required(translate.t('laThisRequired')),
            });
        },
        [],
    );

    const validationResolver = useYupValidationResolver(validationSchema);

    const { register, handleSubmit, errors } = useForm<WorkTimeSettingsFormValues>({
        validationResolver,
    });

    const handleSave = useMemo(
        () => {
            return handleSubmit((values) => {
                onSave({
                    workDayHours: parseHMMToDurationISOString(values.workDayHours),
                    maxCumulativeHours: parseHMMToDurationISOString(values.maxCumulativeHours),
                    minCumulativeHours: parseHMMToDurationISOString(values.minCumulativeHours),
                    blockAfterDays: +values.blockAfterDays
                });
            });
        },
        [handleSubmit, onSave]
    );

    return {
        defaultFormValues,
        register,
        handleSave,
        errors,
    };
};

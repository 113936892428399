import { StyleRulesCallback, Theme } from '@material-ui/core';

const styles: StyleRulesCallback = (theme: Theme) => ({
  checkbox: {
    height: theme.spacing.unit * 3,
    width: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit,
  },
  grow: {
    flex: 1,
  },
});

export default styles;

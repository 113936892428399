import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const tablePaginationActionsWrappedStyle: StyleRulesCallback = (theme: Theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
    fontSize: '0.9rem'
  }
});

export default tablePaginationActionsWrappedStyle;
import * as React from 'react';
import {
  Icon,
  IconButton,
  Typography,
  WithStyles,
  withStyles,
  Button,
} from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { acceptedDocuments } from '@/app/utils/helper';

import styles from './singleAttachmentField/styles';
import translate from '@/app/utils/translate';

type OwnProps = {
  attachment: File,
  attachmentId?: number,
  attachmentName?: string,
  maxFileSize?: number,
  required?: boolean,

  onChange: (attachment: File) => void,
  onDownloadAttachment: () => void,
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const AttachmentsField = (props: Props) => {
  const {
    classes,
    attachment,
    attachmentId = -1,
    attachmentName = '',
    maxFileSize = 10 * 1024 * 1024,   // 10Mb
    required = false,
    onChange,
    onDownloadAttachment,
  } = props;

  const handleDrop = React.useCallback(
    (droppedFiles) => {
      onChange(droppedFiles[0]);
    },
    [attachment]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    accept: acceptedDocuments,
    maxSize: maxFileSize,
    onDrop: handleDrop,
  });

  const handleRemove = React.useCallback(
    () => {
      onChange(undefined);
    },
    []
  );

  let existingAttachment = undefined;
  if (attachmentName !== '') {
    if (attachmentId === -1) {
      existingAttachment = (
        <div className={classes.existingAttachmentArea}>
          <div className={classes.existingAttachment}>
            <Typography variant="body1" className={classes.attachmentName}>{attachmentName}</Typography>
            <IconButton title={translate.t('label_remove_attachment')} className={classes.removeAttButton} onClick={handleRemove}>
              <Icon color="error" fontSize="small">close</Icon>
            </IconButton>
          </div>
        </div>
      );
    } else {
      existingAttachment = (
        <div className={classes.existingAttachmentArea}>
          <div className={classes.existingAttachment}>
            <Button
              className={classes.attButton}
              variant="text"
              title={translate.t('label_open_attachment')}
              onClick={onDownloadAttachment}
            >
              {attachmentName}
            </Button>
            <IconButton title={translate.t('label_remove_attachment')} className={classes.removeAttButton} onClick={handleRemove}>
              <Icon color="error" fontSize="small">close</Icon>
            </IconButton>
          </div>
        </div>
      );
    }
  }

  return (
    <>
      {existingAttachment}
      <div className={classes.root} {...getRootProps()}>
        <input {...getInputProps()} required={required} />
        <div className={classes.paragraph}>
          {
            isDragActive
              ? <Typography variant="body1">{translate.t('text_drop_attachment_here')}</Typography>
              : (
                <Typography variant="body1">
                  {
                    attachmentName === ''
                      ? translate.t('text_dragdrop_attachment_here_to_add')
                      : translate.t('text_dragdrop_attachment_here_to_replace')
                  }
                </Typography>
              )
          }
        </div>
      </div>
    </>
  );
};

export default withStyles(styles, { withTheme: true })(AttachmentsField);

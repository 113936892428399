import * as React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  WithStyles, withStyles, Tooltip, Typography, Grid, Button, Popover, IconButton, Badge, Paper,
} from '@material-ui/core';
import CommentIcon from '@material-ui/icons/Comment';
import answerSurveyBoxStyle from './answerSurveyBoxStyle';
import translate from '@/app/utils/translate';
import {
  SURVEY_STATUS,
  SurveyNotification,
  getAndOpenResultsDialog,
  getAndOpenAnswerDialog,
  getNotifications,
} from '@/app/redux/surveys';
import SurveyResultsDialog from '../SurveyResultsDialog/SurveyResultsDialog';
import AnswerSurveyFormDialog from '../AnswerSurveyFormDialog/AnswerSurveyFormDialog';
import Cookies from 'universal-cookie';
import Service from '@/app/utils/service';
import APIs from '@/app/api/internalAPIs';

type MapStateToProps = {
  notifications: SurveyNotification[];
};

type MapDispatchToProps = {
  getNotifications: () => void;
  openResultsDialog: (surveyId: string) => void;
  openAnswerDialog: (surveyId: string) => void;
};

type PropsType = MapStateToProps & MapDispatchToProps & WithStyles<typeof answerSurveyBoxStyle>;

type StateType = {
  anchorEl: any;
};

class AnswerSurveyBox extends React.Component<PropsType> {
  state: StateType = {
    anchorEl: undefined,
  };

  componentDidMount() {
    this.props.getNotifications();
  }

  handleClick = (event: any) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: undefined,
    });
  };

  handleAnswer = (surveyId: string) => {
    this.props.openAnswerDialog(surveyId);
  };

  handleResults = (surveyId: string) => {
    this.props.openResultsDialog(surveyId);
    this.handleClose();
  };

  renderNotifications = (notificationList: SurveyNotification[]) => {
    if (!notificationList || notificationList.length === 0) {
      return (
        <Typography variant="body2">
          {translate.t('text_no_available_surveys')}
        </Typography>
      );
    }

    return (notificationList || []).map(notif => (
      notif.status === SURVEY_STATUS.PUBLISHED || notif.status === SURVEY_STATUS.ACTIVE ? (
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          justify="space-between"
          key={notif.id}
          className={this.props.classes.listItem}
        >
          <Typography variant="body2" className={this.props.classes.listItemTitle}>
            {notif.name} {
              notif.status === SURVEY_STATUS.PUBLISHED ?
              `(${translate.t(`label_${SURVEY_STATUS.PUBLISHED}`)})` :
              ''
            }
          </Typography>
          {notif.status === SURVEY_STATUS.PUBLISHED ? (
            <Button
              variant="contained"
              onClick={() => this.handleResults(notif.id)}
            >
              {translate.t('label_results')}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.handleAnswer(notif.id)}
            >
              {translate.t('label_answer')}
            </Button>
          )}
        </Grid>
      ) : null
    ));
  };

  getBadgeCount = (notifs: SurveyNotification[]) => (
    (notifs || []).filter(notif => notif.status === SURVEY_STATUS.ACTIVE).length
  );

  handleAnswerPulse = (value: number) => {
    Service.post(
      APIs.survey.answer(),
      JSON.stringify(value.toString()),
      () => {
        let expDate = new Date();
        expDate.setTime(expDate.getTime() + (12 * 60 * 60 * 1000));

        let cookies = new Cookies();
        cookies.set('Survey1Answered', '1', {path: '/', expires: expDate});

        this.forceUpdate();
      },
      (err: any) => {
        console.error(err);
      }
    );
  }

  render() {
    const { notifications, classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    const cookies = new Cookies();
    const pulseAnswered = cookies.get('Survey1Answered');

    return (
      <>
        <Tooltip title={translate.t('label_available_surveys')}>
          <IconButton
            aria-owns={open ? 'simple-popper' : undefined}
            aria-haspopup="true"
            onClick={this.handleClick}
            aria-label={translate.t('label_available_surveys')}
            component="span"
            className={classes.iconButton}
          >
            <Badge badgeContent={this.getBadgeCount(notifications)} color="error">
              <CommentIcon />
            </Badge>
          </IconButton>
        </Tooltip>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <>
            <Typography variant="h6" className={classes.contentTitle}>
              {translate.t('laCompFeelSurveyTitle')}
            </Typography>
            <Paper className={classes.pulsePaper}>
              {pulseAnswered ? (
                <Typography className={classes.pulseAnswered} variant="body1">{translate.t('laThanksForAnsweringPulse')}</Typography>
              ) : (
                <>
                  <IconButton className={classes.pulseButton} onClick={() => this.handleAnswerPulse(1)}>
                    <i className="fa fa-frown-o fa-2x" />
                  </IconButton>
                  <IconButton className={classes.pulseButton} onClick={() => this.handleAnswerPulse(2)}>
                    <i className="fa fa-meh-o fa-2x" />
                  </IconButton>
                  <IconButton className={classes.pulseButton} onClick={() => this.handleAnswerPulse(3)}>
                    <i className="fa fa-smile-o fa-2x" />
                  </IconButton>
                </>
              )}
            </Paper>
            <Typography variant="h6" className={classes.contentTitle}>
              {translate.t('title_surveys')}
            </Typography>
            <Grid container direction="row" className={classes.content}>
              {this.renderNotifications(notifications)}
            </Grid>
          </>
        </Popover>
        <AnswerSurveyFormDialog />
        <SurveyResultsDialog />
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { notifications } = state.surveys;

  return {
    notifications,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getNotifications: () => dispatch<any>(getNotifications()),
  openResultsDialog: (surveyId: string) => dispatch<any>(getAndOpenResultsDialog(surveyId)),
  openAnswerDialog: (surveyId: string) => dispatch<any>(getAndOpenAnswerDialog(surveyId)),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(answerSurveyBoxStyle, {withTheme: true}),
);

export default enhance(AnswerSurveyBox);

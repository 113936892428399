import * as React from 'react';
import translate from '@/app/utils/translate';
import EnumTranslateDialog, {
  Translation,
} from '@/app/components/Enum/EnumTranslateDialog/EnumTranslateDialog';
import { EnumGroup, IEnumGroup } from './enumGroup';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { IconButton, Tooltip, withStyles, WithStyles } from '@material-ui/core';
import enumGroupListStyle from '@/app/components/Enum/EnumGroupList/enumGroupListStyle';
import EnumGroupValueList from '@/app/components/Enum/EnumGroupValueList/EnumGroupValueList';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {
  Enums,
  Enum,
  addNewEnumWithTranslation,
  updateEnumOrder,
  EnumConfig,
  DetailTranslation,
  addNewGroup,
  fetchAllEnums,
  ValueListsEditableEnum,
} from '@/app/redux/enums';
import EnumAddValueDialog from '@/app/components/Enum/EnumAddValueDialog/EnumAddValueDialog';
import { Dispatch } from 'redux';
import {
  submit,
  reset,
  FormProps,
  isDirty,
  isInvalid,
  isPristine,
  getFormAsyncErrors,
} from 'redux-form';
import { LanguagesType } from '@/app/redux/languages';
import EnumUpdateValueDialog from '@/app/components/Enum/EnumUpdateValueDialog/EnumUpdateValueDialog';
import EnumSortDialog from '../EnumSortDialog/EnumSortDialog';
import {
  ConfirmDialogType,
  openConfirmDialog,
} from '@/app/redux/confirmDialog';
import { getCustomDataGroupName } from '@/old/utils/helper';
import EnumDetailsDialog from '../EnumDetailsDialog/EnumDetailsDialog';
import EnumAddGroupDialog from '../EnumAddGroupDialog/EnumAddGroupDialog';
import { ReducerState } from '@/app/redux/store';

type OwnProps = {
  displayArea: string;
  enumGroupNames: string[];
  canAddUser: boolean;
};

type MapStateToProps = FormProps<any, any> & {
  // TODO: create a type for Configs from company
  companyConfigs: any;
  allEnums: Enums[];
  enumConfigs: any;
  languages: Array<LanguagesType>;
  dirty: Function;
  invalid: Function;
  pristine: Function;
  formAsyncErrors: Function;
  shouldOpenConfirm: boolean;
};

type MapDispatchToProps = {
  remoteFormSubmit: (formName: string) => void;
  resetForm: (formName: string) => void;
  addNewEnumWithTranslation: (
    value: Enum,
    languages: Translation[],
    groupName: string,
    details?: DetailTranslation[],
  ) => void;
  addNewGroup: (value: Enum) => void;
  updateEnumOrder: (groupName: string, list: any[]) => void;
  openConfirmDialog: (payload: ConfirmDialogType) => void;
};

type PropsType = OwnProps & MapStateToProps & MapDispatchToProps;

type State = {
  expanded: string;
  editOpen: boolean;
  editDetailsOpen: boolean;
  translateOpen: boolean;
  code: string;
  translateTitle: string;
  addOpen: boolean;
  selectedGroup: IEnumGroup;
  selectedEnum: ValueListsEditableEnum;
  sortOpen: boolean;
  disabledNext: boolean;
  addGroup: boolean;
};

class EnumGroupList extends React.PureComponent<
  PropsType & WithStyles<typeof enumGroupListStyle>,
  State
> {
  state: State = {
    expanded: null,
    editOpen: false,
    editDetailsOpen: false,
    translateOpen: false,
    code: null,
    translateTitle: null,
    addOpen: false,
    selectedGroup: null,
    selectedEnum: null,
    sortOpen: false,
    disabledNext: true,
    addGroup: false,
  };

  listGroups = () => {
    const { canAddUser, companyConfigs, displayArea, enumConfigs } = this.props;

    let enumGroups: any[] = [];

    const hasExtCode =
      companyConfigs.extConfig.fComEnableExtReceiveCompensation ||
      companyConfigs.extConfig.fComEnableExtSendCompensation;

    if (displayArea) {
      enumGroups = enumConfigs
        .map(
          ({
            name,
            lockLevel,
            isOrdered,
            isComposite,
            hasInputType,
          }: EnumConfig) =>
            new EnumGroup(
              name,
              lockLevel,
              isOrdered,
              isComposite,
              hasExtCode,
              hasInputType,
            ),
        )
        .filter((eg: any) =>
          canAddUser
            ? eg.name.startsWith('__USER__DEV_')
            : eg.showInArea(displayArea),
        );
    }

    return enumGroups;
  };

  handleTitleClick = (group: string) => () => {
    const expanded = group !== this.state.expanded ? group : null;
    this.setState({ expanded });
  };

  handleTranslateClick = (groupName: string, code: string) => {
    this.setState({
      translateOpen: true,
      selectedGroup: this.getEnumGroupByName(groupName),
      code,
      translateTitle: translate.t('button_translate'),
    });
  };

  handleTranslateClose = () => {
    this.setState({
      translateOpen: false,
      selectedGroup: null,
    });
  };

  handleAddGroupClose = (formName: string) => {
    const { openConfirmDialog: openConfirm, shouldOpenConfirm } = this.props;

    if (shouldOpenConfirm) {
      openConfirm({
        text: translate.t('text_unsaved_changes'),
        onOk: () => this.onAddGroupClose(formName),
      });
    } else {
      this.onAddGroupClose(formName);
    }
  };

  onAddGroupClose = (formName: string) => {
    this.props.resetForm(formName);
    this.setState({
      addGroup: false,
    });
  };

  handleAddClose = (formName: string) => {
    const { openConfirmDialog: openConfirm, shouldOpenConfirm } = this.props;

    if (shouldOpenConfirm) {
      openConfirm({
        text: translate.t('text_unsaved_changes'),
        onOk: () => this.onAddClose(formName),
      });
    } else {
      this.onAddClose(formName);
    }
  };

  onAddClose = (formName: string) => {
    this.props.resetForm(formName);
    this.setState({
      addOpen: false,
      selectedGroup: null,
    });
  };

  handleAddOpen = (groupName: string) => {
    this.setState({
      addOpen: true,
      selectedGroup: this.getEnumGroupByName(groupName),
    });
  };

  handleAddChildOpen = (e: Enum) => {
    const groupName = getCustomDataGroupName(e.groupName, e.code);
    this.setState({
      addOpen: true,
      selectedGroup: this.getEnumGroupByName(groupName),
    });
  };

  handleAddGroupSave = (values: any, close: boolean) => {
    this.props.addNewGroup(values);
    if (close) {
      this.setState({
        addGroup: false,
      });
    }
  };

  handleAddSave = (values: any, groupName: string, close: boolean) => {
    const {
      addNewEnumWithTranslation: addEnumTranslation,
      languages: langs,
    } = this.props;
    const keys: Array<string> = Object.keys(values);

    const filteredKeys = keys.filter(key =>
      langs.some((la: LanguagesType) => la.code === key),
    );

    const languages = filteredKeys.map(key => ({
      lang: key,
      name: values[key],
    }));

    const filteredDetKeys = keys.filter(key =>
      langs.some((la: LanguagesType) => la.code + '_det' === key),
    );
    const details = filteredDetKeys.map(key => ({
      lang: key.substr(0, 2),
      details: values[key],
    }));

    addEnumTranslation(values, languages, groupName, details);
    if (close) {
      this.setState({
        addOpen: false,
      });
    }
  };

  sortEnumGroups = () => {
    const enumGroups = this.listGroups();
    const sortedEnumGroups = enumGroups
      .map((eg: any) => ({
        ...eg,
        title: translate.t(eg.getTransPluralKey()),
      }))
      .sort((a: any, b: any) => {
        if (a.order() < b.order()) {
          return -1;
        }
        if (a.order() > b.order()) {
          return 1;
        }

        return 0;
      });

    return sortedEnumGroups;
  };

  getEnumGroupByName = (groupName: string): IEnumGroup => {
    const { companyConfigs, enumConfigs } = this.props;

    const hasExtCode =
      companyConfigs.extConfig.fComEnableExtReceiveCompensation ||
      companyConfigs.extConfig.fComEnableExtSendCompensation;

    const {
      name,
      lockLevel,
      isOrdered,
      isComposite,
      hasInputType,
    } = enumConfigs.find((c: any) => c.name === groupName);

    return new EnumGroup(
      name,
      lockLevel,
      isOrdered,
      isComposite,
      hasExtCode,
      hasInputType,
    );
  };

  handleEditClick = (e: ValueListsEditableEnum) => {
    this.setState({
      editOpen: true,
      selectedGroup: this.getEnumGroupByName(e.groupName),
      selectedEnum: { ...e },
    });
  };

  handleEditDetailsClick = (groupName: string, code: string) => {
    this.setState({
      editDetailsOpen: true,
      selectedGroup: this.getEnumGroupByName(groupName),
      code,
      translateTitle: translate.t('title_edit_details'),
    });
  };

  handleUpdateDialogClose = () => {
    this.setState({
      editOpen: false,
      selectedGroup: null,
      selectedEnum: null,
    });
  };

  handleEditDetailsClose = () => {
    this.setState({
      editDetailsOpen: false,
      selectedGroup: null,
    });
  };

  handleSortClick = (group: IEnumGroup) => {
    this.setState({
      sortOpen: true,
      selectedGroup: group,
    });
  };

  handleSortDialogClose = () => {
    this.setState({
      sortOpen: false,
    });
  };

  handleSortDialogSave = (groupName: string, list: any[]) => {
    this.setState({
      sortOpen: false,
    });
    this.props.updateEnumOrder(groupName, list);
  };

  handleDisableNext = (formName: string, errors: any = null) => {
    const { dirty, pristine, invalid, formAsyncErrors } = this.props;

    this.setState({
      disabledNext:
        !!errors ||
        !!formAsyncErrors(formName) ||
        (invalid(formName) && dirty(formName)) ||
        pristine(formName),
    });
  };

  render() {
    const {
      classes,
      resetForm,
      remoteFormSubmit,
      allEnums,
      enumGroupNames,
      canAddUser,
    } = this.props;

    const {
      translateOpen,
      code,
      translateTitle,
      expanded,
      addOpen,
      selectedGroup,
      editOpen,
      editDetailsOpen,
      selectedEnum,
      sortOpen,
      disabledNext,
      addGroup,
    } = this.state;

    const sortedEnumGroups = enumGroupNames
      ? this.sortEnumGroups().filter(
          (eg: any) => enumGroupNames.indexOf(eg.name) > -1,
        )
      : this.sortEnumGroups();

    return (
      <div className={classes.root}>
        {canAddUser && (
          <Tooltip title={translate.t('laAdd')}>
            <IconButton
              color="primary"
              component="span"
              onClick={() => {
                this.setState({
                  addGroup: true,
                });
              }}
            >
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
        )}
        <EnumTranslateDialog
          open={translateOpen}
          group={selectedGroup}
          code={code}
          title={translateTitle}
          onClose={this.handleTranslateClose}
        />
        <EnumAddValueDialog
          open={addOpen}
          group={selectedGroup}
          onClose={this.handleAddClose}
          onSave={this.handleAddSave}
          resetForm={resetForm}
          remoteFormSubmit={remoteFormSubmit}
          isNextDisabled={disabledNext}
          disableNext={this.handleDisableNext}
        />
        <EnumAddGroupDialog
          open={addGroup}
          onClose={this.handleAddGroupClose}
          onSave={this.handleAddGroupSave}
          resetForm={resetForm}
          remoteFormSubmit={remoteFormSubmit}
        />
        <EnumUpdateValueDialog
          group={selectedGroup}
          enum={selectedEnum}
          title={`${translate.t('laEdit')}`}
          open={editOpen}
          onClose={this.handleUpdateDialogClose}
        />
        <EnumDetailsDialog
          open={editDetailsOpen}
          group={selectedGroup}
          code={code}
          title={`${translate.t('title_edit_details')}`}
          onClose={this.handleEditDetailsClose}
        />
        <EnumSortDialog
          title={`${translate.t('laSort')}`}
          group={selectedGroup}
          allEnums={allEnums}
          open={sortOpen}
          onClose={this.handleSortDialogClose}
          onSave={this.handleSortDialogSave}
        />
        {sortedEnumGroups.map((eg: any) => (
          <EnumGroupValueList
            key={eg.name}
            title={eg.title && eg.title.replace('__USER__DEV_', '')}
            group={eg}
            expanded={expanded === eg.name}
            onAddClick={this.handleAddOpen}
            onAddChildClick={this.handleAddChildOpen}
            onTitleClick={this.handleTitleClick(eg.name)}
            onTranslateClick={this.handleTranslateClick}
            onEditClick={this.handleEditClick}
            onEditDetailsClick={this.handleEditDetailsClick}
            onSortClick={this.handleSortClick}
            showDeleteIcon={eg.name.startsWith('__USER__DEV_')}
          />
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state: ReducerState) => {
  const { enums, languages, companyInfo, confirmDialog } = state;

  return {
    enumConfigs: enums.get('configs').toJS(),
    allEnums: enums.get('allEnums').toJS(),
    languages: languages.get('allLanguages'),
    companyConfigs: companyInfo.get('configs'),
    dirty: (formName: string) => isDirty(formName)(state),
    invalid: (formName: string) => isInvalid(formName)(state),
    pristine: (formName: string) => isPristine(formName)(state),
    formAsyncErrors: (formName: string) => getFormAsyncErrors(formName)(state),
    shouldOpenConfirm: confirmDialog.get('shouldOpen'),
  };
};

// TODO: find a better solution for this dispatch<any>...
const mapDispatchToProps = (dispatch: Dispatch) => ({
  remoteFormSubmit: (formName: string) => dispatch(submit(formName)),
  resetForm: (formName: string) => dispatch(reset(formName)),
  addNewEnumWithTranslation: (
    value: Enum,
    languages: Translation[],
    groupName: string,
    details?: DetailTranslation[],
  ) =>
    dispatch<any>(
      addNewEnumWithTranslation(value, languages, groupName, details),
    ),
  addNewGroup: (value: Enum) =>
    dispatch<any>(addNewGroup(value)).then(() =>
      dispatch<any>(fetchAllEnums()),
    ),
  updateEnumOrder: (groupName: string, list: any[]) =>
    dispatch<any>(updateEnumOrder(groupName, list)),
  openConfirmDialog: (payload: ConfirmDialogType) =>
    dispatch(openConfirmDialog(payload)),
});

const enhance = compose<PropsType, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(enumGroupListStyle),
);

export default enhance(EnumGroupList);

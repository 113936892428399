import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { TimeSheet as LocalTimeSheet } from './TimeSheet';
import { TimeSheetInnerProps, TimeSheetOuterProps } from './timeSheetModels';
import { timeSheetStyles } from './timeSheetStyles';
import { mapDispatchToProps, mapStateToProps } from './timeSheetRedux';

export * from './timeSheetReducer';

export const TimeSheet = compose<TimeSheetInnerProps, TimeSheetOuterProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(timeSheetStyles),
)(LocalTimeSheet);

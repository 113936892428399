import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const scaleCompCommentsStyles: StyleRulesCallback = () => ({
  root: {
    '& textarea': {
      // needed because the old css overwrites textarea height in some sections
      minHeight: 20,
      fontFamily: 'Arial, Helvetica, sans-serif',
      fontSize: '0.875em',
    },
  },
});

export default scaleCompCommentsStyles;

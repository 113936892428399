import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const sectionListingStyles: StyleRulesCallback = () => ({
  container: {
    padding: '10px 20px',
  },
  iconButton: {
    // needed because the old css overwrites textarea height in some sections
    height: '45px !important',
    marginLeft: -12,
  },
  iconTransform: {
    transform: 'rotate(180deg)',
  },
  fullWidth: {
    width: '100%',
  },
  expansionPanel: {
    '&$expanded': {
      margin: 0,
      minHeight: 48,
    },
  },
  expansionPanelContent: {
    '&$expanded': {
      margin: 0,
    },

    margin: 0,
  },
  expansionPanelDetails: {
    paddingTop: 0,
  },
  expanded: {},
  root: {
    padding: '10px 20px',
  },
});

export default sectionListingStyles;

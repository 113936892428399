import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';

import 'moment/locale/cs';
import 'moment/locale/da';
import 'moment/locale/de';
import 'moment/locale/et';
import 'moment/locale/en-gb';
import 'moment/locale/fi';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/lt';
import 'moment/locale/lv';
import 'moment/locale/nl';
import 'moment/locale/nb';
import 'moment/locale/pl';
import 'moment/locale/ru';
import 'moment/locale/sv';
import 'moment/locale/uk';
import 'moment/locale/zh-cn';

import moment from 'moment';
import * as React from 'react';

import { getStored } from '@/app/utils/helper';

const getCurrentLanguage = (fallbackLanguage?: string): string => {
  return getStored('language') || fallbackLanguage || 'en';
};

// Dates localization
const getAdapterLocale = (): string => {
  const language = getCurrentLanguage('en-gb');

  const localeConversions = [
    {
      languages: ['en', 'en-us'],
      adapterLocale: 'en-gb',
    },
    {
      languages: ['fm'],
      adapterLocale: 'fi',
    },
  ];

  return (
    localeConversions.find((lc) => lc.languages.includes(language))
      ? localeConversions.find((lc) => lc.languages.includes(language)).adapterLocale : language
  );
};

export const getMomentDateLibInstance = () => {
  moment.locale(getAdapterLocale());
  return moment;
};

export const LocalizationProvider: React.FC = ({children}) => {
  return (
    <MuiPickersUtilsProvider
      libInstance={getMomentDateLibInstance()}
      utils={MomentUtils}
      locale={getAdapterLocale()}
    >
      {children}
    </MuiPickersUtilsProvider>
  );
};

export default LocalizationProvider;

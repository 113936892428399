import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const approveDocumentFormDialogStyle: StyleRulesCallback = (theme: Theme) => {
  return ({
    paperWidthMd: {
      width: '370px',
    },
    root: {
      display: 'flex',
    },
    formControl: {
      margin: `${theme.spacing.unit * 2}px 0 0`,
      width: '100%',
    },
    formControlLabel: {
      transform: 'translate(0, 1.5px) scale(0.75)',
      transformOrigin: 'top left',
    },
    group: {
      margin: `${theme.spacing.unit}px 0 0`,
    },
    radio: {
      padding: '0 12px',
    },
    input: {
      marginLeft: '34px',

      '& > div': {
        marginTop: 0,
      },
    },
    downloadContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    downloadLink: {
      fontWeight: 'bold',
      color: 'black',
      textDecoration: 'none',
    },
  });
};

export default approveDocumentFormDialogStyle;

import * as React from 'react';
import Cookies from 'universal-cookie';

class KendoGenericUrlCell extends React.Component<any, any> {
  render() {
    const { page, empId, text, tab } = this.props;
    const handleOnCLick = () => {
      const cookies = new Cookies();
      cookies.set('sola-current-page', `${page}`);
      if (tab === 'idTabDevPlan') {
        cookies.set('Development-Page-Id', 'idTabDevPlan');
      }
    };

    return (
      <td>
        <a
          style={{ textDecoration: 'underline' }}
          href={`/${page}.html?emp=${empId}`}
          onClick={handleOnCLick}
        >
          {text}
        </a>
      </td>
    );
  }
}

export default KendoGenericUrlCell;

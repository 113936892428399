import * as React from 'react';
import { Dispatch } from 'redux';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
  WithStyles,
  Button,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { getFormDialog, changeData } from '@/app/components/FormDialog/FormDialog';
import {
  closeADDialog,
  DocumentApprovalConfig,
  isAdvancedApprovalMethod,
  sendResponse,
} from '@/app/redux/documentApproval';
import { throwError } from '@/app/redux/error';
import { INPUT_TYPE } from '@/app/utils/helper';
import translate from '@/app/utils/translate';

import approveDocumentFormDialogStyle from './approveDocumentFormDialogStyle';
import FormFields from '../../FormFields/FormFields';

const validate = (values: ApprovalForm) => {
  let errors: {[k: string]: any} = {};
  const requiredFields = ['fApprovalStatus', 'fInfo'];

  requiredFields.forEach(field => {
    if (!values[field] || values[field] === '<p>&nbsp;</p>') {
      errors[field] = translate.t('laThisRequired');
    }
  });

  if (values.fInfo && values.fInfo.length > 80) {
    errors.fInfo = `${translate.t('laErrorMaxTextLen1')} 80`;
  }

  return errors;
};

const FORM_NAME = 'approveDocumentForm';

interface MapStateToProps {
  config: DocumentApprovalConfig | null;
  currentValues: ApprovalForm;
  open: boolean;
  entityId: number;
  documentURL: string;
  documentName: string;
  empId: number;
  accessToken: string;
}

interface MapDispatchToProps {
  throwError: Function;
  closeADDialog: Function;
  sendResponse: Function;
}

type PropsType = WithStyles<typeof approveDocumentFormDialogStyle> & MapDispatchToProps & MapStateToProps;

const emptyForm = () => {
  return {
    fApprovalStatus: '',
    fInfo: '',
  };
};

type ApprovalForm = {
  fApprovalStatus: string;
  fInfo: string;
};

type StateType = {
  formData: ApprovalForm;
  value: string;
};

class ApproveDocumentFormDialog extends React.Component<PropsType> {
  state: StateType = {
    formData: emptyForm(),
    value: '',
  };
  FormDialog: any;

  constructor(props: PropsType) {
    super(props);
    this.FormDialog = getFormDialog(FORM_NAME, validate);
  }

  componentDidUpdate(prevProps: PropsType) {
    const { open } = this.props;

    if (open && prevProps.open !== this.props.open) {
      this.setFormValues();
    }
  }

  setFormValues = () => {
    const { formData } = this.state;
    Object.keys(formData).forEach(field => {
      changeData({form: FORM_NAME, field, value: formData[field]});
    });
  };

  closeDialog = () => {
    this.props.closeADDialog();
    this.setEmptyState();
  };

  setEmptyState = () => {
    this.setState({
      formData: emptyForm(),
      value: '',
    });
  };

  handleDownload = () => {
    const { documentURL, documentName } = this.props;
    const linkOptions = {
      href: `${window.location.origin}${documentURL}`,
      download: documentName,
      target: '_blank',
      type: 'hidden',
    };
    const link = Object.assign(document.createElement('a'), linkOptions);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  handleSend = () => {
    const { currentValues: cv, entityId, accessToken } = this.props;
    if (cv.fApprovalStatus === 'approve') {
      changeData({form: FORM_NAME, field: 'fInfo', value: ''});
    }

    const response = {
      entityId,
      fInfo: cv.fInfo,
      approved: cv.fApprovalStatus === 'approve',
      fAccessToken: accessToken,
    };

    this.props.sendResponse(response).then(() => {
      window.jsReadEmpDocumentLatest();      
    });

    this.closeDialog();
  }

  handleChange = (event: any) => {
    const { value } = event.target;
    this.setState({ value });
    changeData({form: FORM_NAME, field: 'fApprovalStatus', value});
  };

  render() {
    const { classes, config, open, documentName, documentURL } = this.props;
    const { formData, value } = this.state;
    const { FormDialog } = this;
    const documentLink = `${window.location.origin}${documentURL}`;

    return (
      <FormDialog
        title={translate.t('text_approve_document')}
        open={open}
        onClose={this.closeDialog}
        onSubmit={this.handleSend}
        formData={formData}
        paperWidthMd={classes.paperWidthMd}
        customSubmitLabel={translate.t('label_send_response')}
        preventConfirmDialog
      >
        <Typography component="h6">
          {translate.t('text_document_tb_approved')}
        </Typography>
        <div className={classes.downloadContainer}>
          <a
            href={documentLink}
            target="_blank"
            className={classes.downloadLink}
          >
            {documentName}
          </a>
          <Button
            variant="text"
            color="primary"
            size="small"
            onClick={this.handleDownload}
          >
            {translate.t('label_open')}
          </Button>
        </div>
        <FormControl className={classes.formControl} fullWidth>
          <FormLabel className={classes.formControlLabel}>
            {translate.t('label_approval_action')}
          </FormLabel>
          <RadioGroup
            aria-label={translate.t('text_approve_document')}
            name="fApprovalStatus"
            className={classes.group}
            value={value}
            onChange={this.handleChange}
          >
            <FormControlLabel
              disabled={config && isAdvancedApprovalMethod(config.approval.defaultMethod)}
              value="approve"
              control={<Radio color="primary" className={classes.radio} />}
              label={translate.t('laApprove')}
            />
            <FormControlLabel
              value="reject"
              control={<Radio color="primary" className={classes.radio} />}
              label={translate.t('label_reject_request_changes')}
            />
          </RadioGroup>
        </FormControl>
        {value === 'reject' ? (
          <div className={classes.input}>
            <FormFields
              inputs={[{
                type: INPUT_TYPE.TEXTAREA,
                code: 'fInfo',
                name: 'fInfo',
                label: translate.t('label_changes_reason'),
                required: true,
                order: 2,
              }]}
            />
          </div>
          ) : null
        }
      </FormDialog>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { documentApproval: docApp } = state;

  return {
    config: docApp.get('config'),
    currentValues: getFormValues(FORM_NAME)(state),
    open: docApp.get('openAD'),
    entityId: docApp.get('entityId'),
    documentURL: docApp.get('documentURL'),
    documentName: docApp.get('documentName'),
    empId: docApp.get('empId'),
    accessToken: docApp.get('accessToken'),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  throwError: (err: any) => dispatch(throwError(err)),
  closeADDialog: () => dispatch(closeADDialog()),
  sendResponse: (data: any) => dispatch<any>(sendResponse(data)),
});

const enhance = compose<any, any>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(approveDocumentFormDialogStyle, {withTheme: true}),
);

export default enhance(ApproveDocumentFormDialog);

import * as React from 'react';

import { WithStyles, withStyles, createStyles } from '@material-ui/core';
import { GridCellProps } from '@progress/kendo-react-grid';
import classNames from 'classnames';

const styles = () =>
  createStyles({
    historyStatus: {
      paddingBottom: '1px',
    },
    previousValue: {},
    newValue: {
      color: '#05A4D8 !important',
    },
    arrow: {
      color: '#05A4D8 !important',
      margin: '0 8px',
    },
  });

type Props = WithStyles<typeof styles> & GridCellProps;

const ProposalValueCell = ({ classes, dataItem, rowType, field }: Props) => {
  if (
    rowType === 'groupHeader' ||
    rowType === 'groupFooter' ||
    rowType !== 'data'
  ) {
    return null;
  }
  const { showChanges, referenceValues } = dataItem;
  const value = dataItem[field];
  const hasReferenceValue =
    referenceValues && referenceValues.hasOwnProperty(field);
  const showReference = showChanges && hasReferenceValue;

  return (
    <td style={{ verticalAlign: 'top' }}>
      {showReference ? (
        <>
          <span className={classes.previousValue}>{referenceValues[field]}</span>
          <i
            className={classNames('fa fa-long-arrow-right', classes.arrow)}
          />
          <span className={classes.newValue}>{value}</span>
        </>
      ) : (
        value
      )}
    </td>
  );
};

export default withStyles(styles)(ProposalValueCell);

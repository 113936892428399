import { default as React } from 'react';

import { TextField } from '@material-ui/core';
// import { ControlProps } from 'react-select/src/components/Control';

import InputComponent from './InputComponent';

// type OptionType = {
//   label: string;
//   value: string;
// };

const Control = (props: any) => {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: {
      classes,
      TextFieldProps,
    },
  } = props;

  const inputProps = {
    inputComponent: InputComponent,
    inputProps: {
      children,
      className: classes.input,
      ref: innerRef,
      ...innerProps,
    },
  };

  return (
    <TextField
      fullWidth={true}
      InputLabelProps={{ shrink: true }}
      InputProps={inputProps}
      {...TextFieldProps}
    />
  );
};

export default Control;

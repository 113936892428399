import translate from '@/app/utils/translate';
import { EvaluationStatus,
  EvaluationStatusDistribution,
  GenericEmployeeEvaluation,
  GenericEvaluationItem,
  ITemplate,
  ITemplateFull,
  IUnitGridData,
  SummaryEmpData,
  TemplateStatus } from '../../JobEvaluation/types';
import { Relation } from '@/app/api/internalAPIs';
import { isHR } from '@/old/utils/helper';
import { OrgUnit } from '@/app/hooks/useUnits';

const emptyDistribution: EvaluationStatusDistribution = Object.freeze({
  inProgress: [],
  completed: [],
  notStarted: [],
});

export const getTemplateSubjectById = (templateId: string, templates: ITemplateFull[]) => {
  const found = templates.find(
    (t: ITemplateFull) => t.id.toString() === templateId,
  );

  return found ? found.subject : '';
};

export const translateCompletionStatus = function(status: EvaluationStatus) {
  switch (status) {
    case EvaluationStatus.IN_PROGRESS: return translate.t('laInProgress');
    case EvaluationStatus.COMPLETED: return translate.t('laCompleted');
    case EvaluationStatus.NOT_STARTED: return translate.t('laNotStarted');
    default : return status;
  }
};

export const getUserRolePerspective = (relation: Relation) => {
  if (relation !== Relation.ALL) {
    return 'manager';
  }

  return isHR() ? 'hr' : 'manager';
};

export const getCombinedAudienceUnion = (
  selectedTemplateIds: string[],
  goalTemplates: ITemplateFull[],
) => {
  const fullAudience: {
    unitAudience: number[];
    roleAudience: string[];
  } = { unitAudience: [], roleAudience: [] };

  if (!selectedTemplateIds.length) {
    return fullAudience;
  }

  if (selectedTemplateIds.length === 1) {
    return goalTemplates.find(
      (tpl: any) => tpl.id === selectedTemplateIds[0]
    ).audience;
  }

  const foundFullAudience = selectedTemplateIds.find((tplId) => {
    const foundTemplateData: ITemplateFull | undefined = goalTemplates.find(
      (tpl: any) => tpl.id === tplId
    );

    if (!foundTemplateData || !foundTemplateData.audience) {
      return false;
    }

    return foundTemplateData.audience.roleAudience.length === 0
      || foundTemplateData.audience.unitAudience.length === 0;
  });

  if (foundFullAudience) {
    return fullAudience;
  }

  return selectedTemplateIds.reduce((acc, tplId) => {
    const foundTemplateData: ITemplateFull | undefined = goalTemplates.find(
      (tpl: any) => tpl.id === tplId
    );

    if (!foundTemplateData || !foundTemplateData.audience) {
      return acc;
    }

    return {
      unitAudience: acc.unitAudience.concat(foundTemplateData.audience.unitAudience),
      roleAudience: acc.roleAudience.concat(foundTemplateData.audience.roleAudience),
    };
  }, fullAudience);
};

export const isEmpTargetedByTplAudience = (
  unitAudience: number[],
  roleAudience: string[],
  employeeDetails: SummaryEmpData,
) => {
  const hasUnitAudience = unitAudience && unitAudience.length;
  const hasRoleAudience = roleAudience && roleAudience.length;

  if (!hasUnitAudience && !hasRoleAudience) {
    return true;
  }

  if (!employeeDetails
      || !employeeDetails.orgUnitDetails
      || !employeeDetails.userDetails
  ) {
    return false;
  }

  const isTargetedFromUnit = hasUnitAudience
    && unitAudience.includes(employeeDetails.orgUnitDetails.id);
  const isTargetedFromRole = hasRoleAudience
    && roleAudience.some(role => employeeDetails.userDetails.roles.includes(role));

  return isTargetedFromUnit || isTargetedFromRole;
};

const isEmpInCombinedAudience = (
  selectedTemplateIds: string[],
  templates: ITemplateFull[],
  employeeDetails: SummaryEmpData,
) => {
  const combinedAudience = getCombinedAudienceUnion(
    selectedTemplateIds,
    templates,
  );

  return isEmpTargetedByTplAudience(
    combinedAudience.unitAudience,
    combinedAudience.roleAudience,
    employeeDetails,
  );
};

const isTplEvaluationStarted = (
  templateId: string | number,
  evaluations: GenericEvaluationItem[],
) => {
  return evaluations.some(evalItem => evalItem.templateId === `${templateId}`);
};

const getEvaluationsStatusDistribution = (
  empEvaluations: GenericEvaluationItem[],
  employeeDetails: SummaryEmpData,
  selectedTemplateIds: string[],
) => {
  return selectedTemplateIds.reduce((acc, selectedTemplateId) => {
    if (!isTplEvaluationStarted(selectedTemplateId, empEvaluations)) {
      return {
        ...acc,
        notStarted: [ ...acc.notStarted, employeeDetails.empId ],
      };
    }

    const currentEvalStatusDistribution = empEvaluations.reduce((empEvalAcc, empEval) => {
      if (!isTplEvaluationStarted(selectedTemplateId, [empEval])) {
        return empEvalAcc;
      }

      if (empEval.status) {
        return {
          ...empEvalAcc,
          inProgress: empEval.status === EvaluationStatus.IN_PROGRESS
            ? [ ...empEvalAcc.inProgress, employeeDetails.empId ]
            : [ ...empEvalAcc.inProgress ],
          completed: empEval.status === EvaluationStatus.COMPLETED
            ? [ ...empEvalAcc.completed, employeeDetails.empId ]
            : [ ...empEvalAcc.completed ],
        };
      }

      if (
        !empEval.approvedBy ||
        !empEval.approvedBy.length
      ) {
        return {
          ...empEvalAcc,
          inProgress: [ ...empEvalAcc.inProgress, employeeDetails.empId ],
        };
      }

      return {
        ...empEvalAcc,
        completed: [ ...empEvalAcc.completed, employeeDetails.empId ],
      };
    }, emptyDistribution);

    return {
      ...acc,
      inProgress: [...acc.inProgress, ...currentEvalStatusDistribution.inProgress],
      completed: [...acc.completed, ...currentEvalStatusDistribution.completed],
    };
  }, emptyDistribution);
};

export const getUnitEvaluationCombinedStatusDistribution = (
  empEvaluations: GenericEmployeeEvaluation[],
  templates: ITemplateFull[],
  currentUnit: OrgUnit,
  selectedTemplateIds: string[],
): EvaluationStatusDistribution => {
  return empEvaluations.reduce(
    (accCombinedDistribution, empEvalDataItem: GenericEmployeeEvaluation) => {
      if (
        !isEmpInCombinedAudience(selectedTemplateIds, templates, empEvalDataItem.employeeDetails)
        || currentUnit.fTreeUnitId !== `${empEvalDataItem.employeeDetails.orgUnitDetails.id}`
      ) {
        return accCombinedDistribution;
      }

      const empGoalEvalStatuses = getEvaluationsStatusDistribution(
        empEvalDataItem.evaluations,
        empEvalDataItem.employeeDetails,
        selectedTemplateIds,
      );

      return {
        ...accCombinedDistribution,
        notStarted: accCombinedDistribution.notStarted.concat(empGoalEvalStatuses.notStarted),
        inProgress: accCombinedDistribution.inProgress.concat(empGoalEvalStatuses.inProgress),
        completed: accCombinedDistribution.completed.concat(empGoalEvalStatuses.completed),
      };
    }, emptyDistribution);
};

export function filterEvaluationData<EmpEvaluation extends GenericEmployeeEvaluation>(
  evaluationData: EmpEvaluation[],
  tplIds: string[],
  includedUnitIds: string[],
  unitGridData: IUnitGridData[],
): EmpEvaluation[] {
  const audienceIds = includedUnitIds.reduce((acc, inclUnitId) => {
    const currentUnitGridData: IUnitGridData = unitGridData.find(ug => `${ug.unitId}` === inclUnitId);
    if (!currentUnitGridData) {
      return acc;
    }

    return acc.concat([
      ...currentUnitGridData.notStarted,
      ...currentUnitGridData.inProgress,
      ...currentUnitGridData.completed,
    ]);
  }, []);

  return evaluationData.filter((evaluationDataItem) => (
    audienceIds.includes(evaluationDataItem.employeeDetails.empId)
    && includedUnitIds.includes(
      `${evaluationDataItem.employeeDetails.orgUnitDetails.id}`,
    )
  )).map((evaluationDataItem) => ({
    ...evaluationDataItem,
    evaluations: evaluationDataItem.evaluations.filter(
      evalItem => tplIds.includes(evalItem.templateId),
    ),
  }));
}

export const templateStatusTranslation = (status: TemplateStatus) => {
  switch (status) {
    case TemplateStatus.ACTIVE: return translate.t('laActive');
    case TemplateStatus.INACTIVE: return translate.t('laInactive');
    default : return status;
  }
};

export const searchMatchingTemplates = (templates: ITemplate[], searchQuery: string): ITemplate[] => {
  return templates.filter((template: ITemplate) => {
    return `${template.subject}`.toLocaleLowerCase().indexOf(
      `${searchQuery}`.toLocaleLowerCase()
    ) > -1;
  });
};
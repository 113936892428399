import * as actionType from '@/app/redux/enums';
import { setStored, getCurrentLanguage } from '@/app/utils/helper';

export default (store: any) => (next: any) => (action: any) => {
  next(action);

  switch (action.type) {
    case actionType.FETCH_ALL_ENUMS:
    case actionType.DELETE_ENUMS:
    case actionType.DELETE_GROUP:
    case actionType.UPDATE_TRANSLATION:
    case actionType.ADD_NEW_ENUM:
    case actionType.UPDATE_ENUM:
    case actionType.UPDATE_ORDER:
    case actionType.UPDATE_CUSTOM_CHILDREN_ORDER:
    case actionType.ADD_NEW_GROUP: {
      if (action.currentVersion) {
        setStored('version/enums', action.currentVersion);
      }

      setStored('cachedLang', getCurrentLanguage());

      const { allEnums, configs } = store.getState().enums.toJS();
      setStored('state/enums', { allEnums, configs });
      break;
    }
    default:
      break;
  }
};

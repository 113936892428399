import React, { useState } from 'react';
import { Button, Icon, Menu, MenuItem, Typography } from '@material-ui/core';
import EditHoursDialog from './hoursHeadButton/EditHoursDialog';
import translate from '@/app/utils/translate';
import { HoursAction } from '../../../../types';

type OwnProps = {
  className?: string;
  readOnly?: boolean;
  onChangeHours: (action: HoursAction, hours?: number) => void;
};
type InnerProps = {};
type Props = OwnProps & InnerProps;

const HoursHeadButton = ({
  className,
  readOnly = false,
  onChangeHours,
}: Props) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(undefined);
  const [editHoursDialogOpen, setEditHoursDialogOpen] = useState(false);

  const handleChangeToDefault = (action: HoursAction) => {
    setMenuAnchorEl(undefined);
    onChangeHours(action);
  };

  const handleChangeToCustom = () => {
    setMenuAnchorEl(undefined);
    setEditHoursDialogOpen(true);
  };

  const handleSubmitCustomHours = (newHours: number) => {
    setEditHoursDialogOpen(false);
    onChangeHours('CHANGE_ALL_HOURS_TO', newHours);
  };

  if (readOnly) {
    return <Typography variant="inherit">{translate.t('laHours')}</Typography>;
  }

  return (
    <>
      <EditHoursDialog
        open={editHoursDialogOpen}
        onClose={() => setEditHoursDialogOpen(false)}
        onSubmit={handleSubmitCustomHours}
      />
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(undefined)}
      >
        <MenuItem onClick={() => handleChangeToDefault('CHANGE_ALL_HOURS_TO_DEFAULT')}>
          {translate.t('label_set_all_hours_to_training_default')}
        </MenuItem>
        <MenuItem onClick={handleChangeToCustom}>
          {translate.t('label_set_all_hours_to')}
        </MenuItem>
      </Menu>
      <Button className={className} color="inherit" onClick={e => setMenuAnchorEl(e.target)}>
        {translate.t('laHours')}
        <Icon>arrow_drop_down</Icon>
      </Button>
    </>
  );
};

export default HoursHeadButton;

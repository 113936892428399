import { createStyles } from '@material-ui/core';

const styles = () => createStyles({
  groupButton: {
    position: 'absolute',
    right: '0px',
    top: '17px',
  },
  actionBar: {
    marginBottom: '20px', marginTop: '5px'
  },
  actionBarButton: {
    position: 'absolute', right: '0px'
  },
  closeButton: {
    marginLeft: '4px'
  },
  tab: {
    minWidth: '220px'
  },
  card: {
    paddingLeft: '44px',
    paddingRight: '44px',
    paddingTop: '20px',
  },
  externalSign: {
    fontSize: '10px',
    fontWeight: 'bold',
    color: '#05A4D8 !important' ,
    float: 'left',
  }
});

export default styles;
import * as React from 'react';
import compose from 'recompose/compose';
import withRoot from '@/app/layouts/withRoot';
import ErrorHandlerHOC from '@/app/hocs/ErrorHandlerHOC';
import FetchLanguagesHOC from '@/app/hocs/FetchLanguagesHOC';
import NotifierHOC from '@/app/hocs/NotifierHOC';
import FetchCompanyInfoHOC from '@/app/hocs/FetchCompanyInfoHOC';
import FetchAllEnumsHOC from '@/app/hocs/FetchAllEnumsHOC';
import UpateGlobalValuesHOC from '@/app/hocs/UpateGlobalValuesHOC';
import ConfirmHOC from '@/app/hocs/ConfirmHOC';

const Wrapper = (props: any) => {
  return (
    <>
      {props.children}
    </>
  );
};

const enhance = compose(
  withRoot,
  ConfirmHOC,
  NotifierHOC,
  ErrorHandlerHOC,
  FetchLanguagesHOC,
  FetchCompanyInfoHOC,
  FetchAllEnumsHOC,
  UpateGlobalValuesHOC,
);

export default enhance(Wrapper);

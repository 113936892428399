import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Dispatch } from 'redux';
import { withStyles, WithStyles, TextField, Typography } from '@material-ui/core';
import API from '@/app/api/internalAPIs';
import Service from '@/app/utils/service';
import translate from '@/app/utils/translate';
import { translateTemplate } from '@/app/redux/goals';
import translateTemplateStyle from './translateTemplateStyle';
import TranslationDialog from '../TranslationDialog/TranslationDialog';

type IncomingProps = {
  templateId: string;
  handleModalClose: () => void;
};

type MapDispatchToProps = {
  translateTemplate: (language: string, templateId: string, translation: any) => any;
};

type PropsType = IncomingProps & MapDispatchToProps & WithStyles<typeof translateTemplateStyle>;

type StateType = {
  localizedTemplate: any;
  subject: string;
  stages: { [key: number]: string; };
  showValidationError: boolean;
  disabledSave: boolean;
};

class TranslateTemplate extends React.PureComponent<PropsType> {
  state: StateType = {
    localizedTemplate: undefined,
    subject: '',
    stages: {},
    showValidationError: false,
    disabledSave: true,
  };

  loadLocalizedData = (lang: string) => {
    return new Promise((resolve, reject) => {
      Service.get(
        API.goalTemplates.getFullTemplate(this.props.templateId, lang),
        (localizedTemplate: any) => {
          this.setState({
            localizedTemplate,
            subject: localizedTemplate.subject,
            stages: localizedTemplate.stages.reduce(
              (accumulator: any, stage: any) => {
                if (!stage.isDeleted) {
                  return {
                    ...accumulator,
                    [stage.id]: stage.label,
                  };
                }

                return accumulator;
              },
              {},
            ),
          });

          resolve(localizedTemplate);
        },
        reject,
      );
    });
  };

  handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      subject: e.target.value,
      ...(
        e.target.value !== ''
          ? {
            showValidationError: false,
            disabledSave: false
          }
          : {}
      ),
    });
  };

  handleStageChange = (stageId: number) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const nextStages = { ...this.state.stages, [stageId]: e.target.value };

      this.setState({
        stages: nextStages,
        disabledSave: Object.values(nextStages).some((label) => !label),
        showValidationError: false,
      });
    };
  };

  handleSubmit = (lang: string) => {
    const { localizedTemplate, subject, stages } = this.state;

    if (!localizedTemplate) {
      return;
    }

    if (!subject || Object.values(stages).some((label) => !label)) {
      this.setState({ showValidationError: true });
      return;
    }

    this.props.translateTemplate(lang, localizedTemplate.id, {
      subject,
      stages: localizedTemplate.stages.filter((stage: any) => !stage.isDeleted).map((stage: any) => {
        return { id: stage.id, label: stages[stage.id] };
      }),
    });

    this.props.handleModalClose();
  };

  render() {
    const { localizedTemplate, subject, stages, showValidationError } = this.state;

    return (
      <TranslationDialog
        title={translate.t('translate_template')}
        disabledSave={this.state.disabledSave}
        loadLocalizedData={this.loadLocalizedData}
        localizedDataForm={
          localizedTemplate && (
            <>
              <TextField
                name="subject"
                value={subject}
                onChange={this.handleSubjectChange}
                label={translate.t('laSubject')}
                error={showValidationError && !subject}
                helperText={
                  showValidationError && !subject
                    ? translate.t('laThisRequired')
                    : ''
                }
                fullWidth
              />

              {localizedTemplate.stages.length > 0 && (
                <div className={this.props.classes.stagesContainer}>
                  <Typography>{translate.t('laStages')}</Typography>

                  {localizedTemplate.stages.filter((stage: any) => {
                    return !stage.isDeleted;
                  }).map((stage: any) => {
                    return (
                      <TextField
                        key={stage.id}
                        name={`stage[${stage.id}]`}
                        value={stages[stage.id]}
                        onChange={this.handleStageChange(stage.id)}
                        error={showValidationError && !stages[stage.id]}
                        helperText={
                          showValidationError && !stages[stage.id]
                            ? translate.t('laThisRequired')
                            : ''
                        }
                        fullWidth
                      />
                    );
                  })}
                </div>
              )}
            </>
          )
        }
        onSubmit={this.handleSubmit}
        onCancel={this.props.handleModalClose}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  translateTemplate: (language: string, templateId: string, translation: any) =>
    dispatch<any>(translateTemplate(language, templateId, translation)),
});

const enhance = compose<any, any>(
  connect(undefined, mapDispatchToProps),
  withStyles(translateTemplateStyle),
);

export default enhance(TranslateTemplate);
import moment from 'moment';
import { Dispatch } from 'redux';

import API from '@/app/api/internalAPIs';
import Service from '@/app/utils/service';
import { getCompanyDefaultHours } from '@/old/utils/helper';

import { WEEK_FORMAT } from '../timeSheetConstants';
import { AutoDeductionRule } from '@/app/components/AutoDeduction/types';

export enum TimeSheetActionKeys {
  CHANGE_WEEK = 'components/TimeReporting/TimeSheet/CHANGE_WEEK',
  GET_TIME_SHEET_PENDING = 'components/TimeReporting/TimeSheet/GET_TIME_SHEET_PENDING',
  GET_TIME_SHEET_FULFILLED = 'components/TimeReporting/TimeSheet/GET_TIME_SHEET_FULFILLED',
  GET_TIME_SHEET_REJECTED = 'components/TimeReporting/TimeSheet/GET_TIME_SHEET_REJECTED',
  GET_TIME_CARDS_PENDING = 'components/TimeReporting/TimeSheet/GET_TIME_CARDS_PENDING',
  GET_TIME_CARDS_FULFILLED = 'components/TimeReporting/TimeSheet/GET_TIME_CARDS_FULFILLED',
  GET_TIME_CARDS_REJECTED = 'components/TimeReporting/TimeSheet/GET_TIME_CARDS_REJECTED',
  UPDATE_TIME_CARD_PENDING = 'components/TimeReporting/TimeSheet/UPDATE_TIME_CARD_PENDING',
  UPDATE_TIME_CARD_FULFILLED = 'components/TimeReporting/TimeSheet/UPDATE_TIME_CARD_FULFILLED',
  UPDATE_TIME_CARD_REJECTED = 'components/TimeReporting/TimeSheet/UPDATE_TIME_CARD_REJECTED',
  DELETE_TIME_CARD_PENDING = 'components/TimeReporting/TimeSheet/DELETE_TIME_CARD_PENDING',
  DELETE_TIME_CARD_FULFILLED = 'components/TimeReporting/TimeSheet/DELETE_TIME_CARD_FULFILLED',
  DELETE_TIME_CARD_REJECTED = 'components/TimeReporting/TimeSheet/DELETE_TIME_CARD_REJECTED',
  GET_HOURS_SETTINGS_PENDING = 'components/TimeReporting/TimeSheet/GET_HOURS_SETTINGS_PENDING',
  GET_HOURS_SETTINGS_FULFILLED = 'components/TimeReporting/TimeSheet/GET_HOURS_SETTINGS_FULFILLED',
  GET_HOURS_SETTINGS_REJECTED = 'components/TimeReporting/TimeSheet/GET_HOURS_SETTINGS_REJECTED',
  GET_PUBLIC_HOLIDAYS_PENDING = 'components/TimeReporting/TimeSheet/GET_PUBLIC_HOLIDAYS_PENDING',
  GET_PUBLIC_HOLIDAYS_FULFILLED = 'components/TimeReporting/TimeSheet/GET_PUBLIC_HOLIDAYS_FULFILLED',
  GET_PUBLIC_HOLIDAYS_REJECTED = 'components/TimeReporting/TimeSheet/GET_PUBLIC_HOLIDAYS_REJECTED',
  GET_AUTO_DEDUCTION_RULE_PENDING = 'components/TimeReporting/TimeSheet/GET_AUTO_DEDUCTION_RULE_PENDING',
  GET_AUTO_DEDUCTION_RULE_FULFILLED = 'components/TimeReporting/TimeSheet/GET_AUTO_DEDUCTION_RULE_FULFILLED',
  GET_AUTO_DEDUCTION_RULE_REJECTED = 'components/TimeReporting/TimeSheet/GET_AUTO_DEDUCTION_RULE_REJECTED',
  CLEAR_STATE = 'components/TimeReporting/TimeSheet/CLEAR_STATE',
}

export interface TimeSheet {
  extraHoursBalance: string;
}

export enum ReportingGroupType {
  TIMESHEET_WORK_TYPE = 'TIMESHEET_WORK_TYPE',
  TIMESHEET_NON_WORK_TYPE = 'TIMESHEET_NON_WORK_TYPE',
  TIMESHEET_CORRECTION_TYPE = 'TIMESHEET_CORRECTION_TYPE',
}

export interface TimeBlock {
  id?: number;
  reportingType: string;
  reportingGroupType: ReportingGroupType;
  from?: string;
  to?: string;
  hours: string;
  customData: object;
}

export interface TimeCard<TB = TimeBlock> {
  date: string;
  requiredWorkingHours: string;
  totalBalanceOnDate?: string;
  comment: string;
  timeBlocks: TB[];
}

export type TimeCardUpdate = TimeCard<
  Omit<TimeBlock, 'hours'> & { hours?: string }
>;

export type OrderedTimeCardsData = {
  [p: string]: TimeCard;
};

export type BalanceOnDate = {
  date: string;
  balance: moment.Duration;
};

export interface HoursSettings {
  workDayHours: string;
  maxCumulativeHours: string;
  minCumulativeHours: string;
  blockAfterDays: number;
}

type ChangeWeekActionType = {
  type: TimeSheetActionKeys.CHANGE_WEEK;
  payload: string;
};

type GetTimeSheetPendingActionType = {
  type: TimeSheetActionKeys.GET_TIME_SHEET_PENDING;
};

type GetTimeSheetFulfilledActionType = {
  type: TimeSheetActionKeys.GET_TIME_SHEET_FULFILLED;
  payload?: TimeSheet;
};

type GetTimeSheetRejectedActionType = {
  type: TimeSheetActionKeys.GET_TIME_SHEET_REJECTED;
};

type GetTimeCardsPendingActionType = {
  type: TimeSheetActionKeys.GET_TIME_CARDS_PENDING;
  meta: string;
};

type GetTimeCardsFulfilledActionType = {
  type: TimeSheetActionKeys.GET_TIME_CARDS_FULFILLED;
  payload: TimeCard[];
  meta: string;
};

type GetTimeCardsRejectedActionType = {
  type: TimeSheetActionKeys.GET_TIME_CARDS_REJECTED;
  meta: string;
};

type UpdateTimeCardPendingActionType = {
  type: TimeSheetActionKeys.UPDATE_TIME_CARD_PENDING;
  meta: string;
};

type UpdateTimeCardFulfilledActionType = {
  type: TimeSheetActionKeys.UPDATE_TIME_CARD_FULFILLED;
  payload: TimeCard;
  meta: string;
};

type UpdateTimeCardRejectedActionType = {
  type: TimeSheetActionKeys.UPDATE_TIME_CARD_REJECTED;
  meta: string;
};

type DeleteTimeCardPendingActionType = {
  type: TimeSheetActionKeys.DELETE_TIME_CARD_PENDING;
  meta: string;
};

type DeleteTimeCardFulfilledActionType = {
  type: TimeSheetActionKeys.DELETE_TIME_CARD_FULFILLED;
  meta: string;
};

type DeleteTimeCardRejectedActionType = {
  type: TimeSheetActionKeys.DELETE_TIME_CARD_REJECTED;
  meta: string;
};

type GetHoursSettingsPendingActionType = {
  type: TimeSheetActionKeys.GET_HOURS_SETTINGS_PENDING;
};

type GetHoursSettingsFulfilledActionType = {
  type: TimeSheetActionKeys.GET_HOURS_SETTINGS_FULFILLED;
  payload: HoursSettings;
};

type GetHoursSettingsRejectedActionType = {
  type: TimeSheetActionKeys.GET_HOURS_SETTINGS_REJECTED;
};

type GetAutoDeductionRulePendingActionType = {
  type: TimeSheetActionKeys.GET_AUTO_DEDUCTION_RULE_PENDING;
};

type GetAutoDeductionRuleFulfilledActionType = {
  type: TimeSheetActionKeys.GET_AUTO_DEDUCTION_RULE_FULFILLED;
  payload: AutoDeductionRule | null;
};

type GetAutoDeductionRuleRejectedActionType = {
  type: TimeSheetActionKeys.GET_AUTO_DEDUCTION_RULE_REJECTED;
};

type GetPublicHolidaysPendingActionType = {
  type: TimeSheetActionKeys.GET_PUBLIC_HOLIDAYS_PENDING;
  meta: string[];
};

type GetPublicHolidaysFulfilledActionType = {
  type: TimeSheetActionKeys.GET_PUBLIC_HOLIDAYS_FULFILLED;
  payload: string[];
  meta: string[];
};

type GetPublicHolidaysRejectedActionType = {
  type: TimeSheetActionKeys.GET_PUBLIC_HOLIDAYS_REJECTED;
  meta: string[];
};

type ClearStateActionType = {
  type: TimeSheetActionKeys.CLEAR_STATE;
};

export type TimeSheetActionTypes =
  | ChangeWeekActionType
  | GetTimeSheetPendingActionType
  | GetTimeSheetFulfilledActionType
  | GetTimeSheetRejectedActionType
  | GetTimeCardsPendingActionType
  | GetTimeCardsFulfilledActionType
  | GetTimeCardsRejectedActionType
  | UpdateTimeCardPendingActionType
  | UpdateTimeCardFulfilledActionType
  | UpdateTimeCardRejectedActionType
  | DeleteTimeCardPendingActionType
  | DeleteTimeCardFulfilledActionType
  | DeleteTimeCardRejectedActionType
  | GetHoursSettingsPendingActionType
  | GetHoursSettingsFulfilledActionType
  | GetHoursSettingsRejectedActionType
  | GetPublicHolidaysPendingActionType
  | GetPublicHolidaysFulfilledActionType
  | GetPublicHolidaysRejectedActionType
  | GetAutoDeductionRulePendingActionType
  | GetAutoDeductionRuleFulfilledActionType
  | GetAutoDeductionRuleRejectedActionType
  | ClearStateActionType;

export const changeWeekAction = (week: string): ChangeWeekActionType => {
  return { type: TimeSheetActionKeys.CHANGE_WEEK, payload: week };
};

export const getTimeSheetAction = (employeeId: string) => {
  return (dispatch: Dispatch<TimeSheetActionTypes>) => {
    dispatch({ type: TimeSheetActionKeys.GET_TIME_SHEET_PENDING });

    Service.get(
      API.workTimeSettings.getTimeSheet(employeeId),
      (data: TimeSheet) => {
        dispatch({
          type: TimeSheetActionKeys.GET_TIME_SHEET_FULFILLED,
          payload: data,
        });
      },
      () => {
        dispatch({ type: TimeSheetActionKeys.GET_TIME_SHEET_REJECTED });
      },
    );
  };
};

export const getTimeCardsAction = (employeeId: string, week: string) => {
  return (dispatch: Dispatch<TimeSheetActionTypes>) => {
    dispatch({ type: TimeSheetActionKeys.GET_TIME_CARDS_PENDING, meta: week });

    const startDate = moment(week, WEEK_FORMAT)
      .startOf('isoWeek')
      .format('YYYY-MM-DD');
    const endDate = moment(week, WEEK_FORMAT)
      .endOf('isoWeek')
      .format('YYYY-MM-DD');

    Service.get(
      API.workTimeSettings.getTimeCards(employeeId, startDate, endDate),
      ({ data }: { data: TimeCard[] }) => {
        dispatch({
          type: TimeSheetActionKeys.GET_TIME_CARDS_FULFILLED,
          payload: data,
          meta: week,
        });
      },
      () => {
        dispatch({
          type: TimeSheetActionKeys.GET_TIME_CARDS_REJECTED,
          meta: week,
        });
      },
    );
  };
};

export const updateTimeCardAction = (
  employeeId: string,
  timeCard: TimeCardUpdate,
) => {
  return (dispatch: Dispatch<TimeSheetActionTypes>) => {
    dispatch({
      type: TimeSheetActionKeys.UPDATE_TIME_CARD_PENDING,
      meta: timeCard.date,
    });

    Service.post(
      API.workTimeSettings.updateTimeCard(employeeId),
      timeCard,
      (data: TimeCard) => {
        dispatch({
          type: TimeSheetActionKeys.UPDATE_TIME_CARD_FULFILLED,
          payload: data,
          meta: timeCard.date,
        });
      },
      () => {
        dispatch({
          type: TimeSheetActionKeys.UPDATE_TIME_CARD_REJECTED,
          meta: timeCard.date,
        });
      },
    );
  };
};

export const deleteTimeCardAction = (employeeId: string, date: string) => {
  return (dispatch: Dispatch<TimeSheetActionTypes>) => {
    dispatch({
      type: TimeSheetActionKeys.DELETE_TIME_CARD_PENDING,
      meta: date,
    });

    Service.delete(
      API.workTimeSettings.deleteTimeCard(employeeId, date),
      undefined,
      () => {
        dispatch({
          type: TimeSheetActionKeys.DELETE_TIME_CARD_FULFILLED,
          meta: date,
        });
      },
      () => {
        dispatch({
          type: TimeSheetActionKeys.DELETE_TIME_CARD_REJECTED,
          meta: date,
        });
      },
    );
  };
};

export const getHoursSettingsAction = (countryCode: string) => {
  return (dispatch: Dispatch<TimeSheetActionTypes>) => {
    dispatch({ type: TimeSheetActionKeys.GET_HOURS_SETTINGS_PENDING });

    Service.get(
      API.workTimeSettings.getCountryHoursSettings(countryCode),
      (response: HoursSettings) => {
        dispatch({
          type: TimeSheetActionKeys.GET_HOURS_SETTINGS_FULFILLED,
          payload: response,
        });
      },
      (error: { status: number }) => {
        if (error.status === 404) {
          dispatch({
            type: TimeSheetActionKeys.GET_HOURS_SETTINGS_FULFILLED,
            payload: getCompanyDefaultHours(),
          });
        } else {
          dispatch({ type: TimeSheetActionKeys.GET_HOURS_SETTINGS_REJECTED });
        }
      },
    );
  };
};

export const getAutoDeductionRuleAction = (employeeId: string) => {
  return (dispatch: Dispatch<TimeSheetActionTypes>) => {
    dispatch({ type: TimeSheetActionKeys.GET_AUTO_DEDUCTION_RULE_PENDING });

    Service.get(
      API.workTimeAutoDeduction.getRuleForEmployee(employeeId),
      (response: AutoDeductionRule) => {
        dispatch({
          type: TimeSheetActionKeys.GET_AUTO_DEDUCTION_RULE_FULFILLED,
          payload: response,
        });
      },
      (error: { status: number }) => {
        if (error.status === 404) {
          dispatch({
            type: TimeSheetActionKeys.GET_AUTO_DEDUCTION_RULE_FULFILLED,
            payload: null,
          });
        } else {
          dispatch({
            type: TimeSheetActionKeys.GET_AUTO_DEDUCTION_RULE_REJECTED,
          });
        }
      },
    );
  };
};

export const getPublicHolidaysAction = (
  userLegalEntityId: string,
  years: string[],
) => {
  return (dispatch: Dispatch<TimeSheetActionTypes>) => {
    if (!years.length) {
      return;
    }

    dispatch({
      type: TimeSheetActionKeys.GET_PUBLIC_HOLIDAYS_PENDING,
      meta: years,
    });

    Promise.all(
      years.map(year => {
        return Service.get(
          `d/json/legalentity/${userLegalEntityId}/holiday/${year}`,
          (response: Array<{ fLegEntHolCalDateDate: string }>) => {
            return response.map(res => res.fLegEntHolCalDateDate);
          },
          (error: unknown) => {
            throw error;
          },
        );
      }),
    )
      .then(response => {
        dispatch({
          type: TimeSheetActionKeys.GET_PUBLIC_HOLIDAYS_FULFILLED,
          payload: response.reduce(
            (accumulator, dates) => [...accumulator, ...dates],
            [],
          ),
          meta: years,
        });
      })
      .catch(() => {
        dispatch({
          type: TimeSheetActionKeys.GET_PUBLIC_HOLIDAYS_REJECTED,
          meta: years,
        });
      });
  };
};

export const clearStateAction = (): ClearStateActionType => {
  return { type: TimeSheetActionKeys.CLEAR_STATE };
};

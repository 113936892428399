import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';

import { TransactionRow as LocalTransactionRow } from './TransactionRow';
import { TransactionRowInnerProps, TransactionRowOuterProps } from './transactionRowModels';
import { transactionRowStyles } from './transactionRowStyles';

export const TransactionRow = compose<TransactionRowInnerProps, TransactionRowOuterProps>(
    withStyles(transactionRowStyles),
)(LocalTransactionRow);

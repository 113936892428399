import { Grid, Typography, withStyles } from '@material-ui/core';
import * as React from 'react';
import { compose } from 'recompose';
import {
  TemplateBaseComponentType,
  TemplateComponentTypeKey,
  TemplateInputComponentPropsType,
} from '@/app/components/TemplateComponents/form/InputComponents';
import translate from '@/app/utils/translate';
import scaleComponentStyles from '@/app/components/TemplateComponents/form/InputComponents/ScaleComponent/scaleComponentStyles';
import { resolveComponentResponse } from '@/app/components/TemplateComponents/form/InputComponents/data';
import Scale1To5 from '@/app/components/TemplateComponents/form/InputComponents/common/Scale1To5/Scale1To5';

export type SubtitleComponentProps = TemplateInputComponentPropsType & {
  component: TemplateBaseComponentType & {
    type: TemplateComponentTypeKey.SCALE;
  };
};

const ScaleComponent = (props: SubtitleComponentProps) => {
  const {
    templateId,
    component,
    responses,
    editMode,
    onUpdate,
    classes,
  } = props;

  const handleChange = (value: number) => {
    if (!!onUpdate) {
      onUpdate(templateId, {
        componentId: component.id,
        response: value,
        type: component.type,
      });
    }
  };

  const potentialResponse = parseInt(
    resolveComponentResponse(component, responses, 0),
    10,
  );
  const existingResponse = !isNaN(potentialResponse) ? potentialResponse : 0;

  return (
    component.type === TemplateComponentTypeKey.SCALE && (
      <div key={`typeEvaluations-${component.id}`}>
        <Typography
          variant="subtitle2"
          className={classes.typography}
          style={{ paddingBottom: '25px' }}
        >
          {component.name}
        </Typography>
        <Grid container={true} alignItems="center">
          <Grid item={true}>
            <Typography color={editMode ? 'textPrimary' : 'textSecondary'}>
              {translate.t('label_disagree')}
            </Typography>
          </Grid>
          <Grid item={true}>
            <div
              className={`${classes.controlContainer} ${classes.controlContainerLg}`}
            >
              <Scale1To5
                editMode={editMode}
                value={existingResponse}
                onChange={handleChange}
              />
            </div>
          </Grid>
          <Grid item={true}>
            <Typography color={editMode ? 'textPrimary' : 'textSecondary'}>
              {translate.t('label_agree')}
            </Typography>
          </Grid>
        </Grid>
      </div>
    )
  );
};

const enhance = compose<any, any>(withStyles(scaleComponentStyles));

export default enhance(ScaleComponent);

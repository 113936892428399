import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const addStageStyle: StyleRulesCallback = () => ({
  root: {
    textAlign: 'center',
    padding: '25px 25px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) !important',
    display: 'flex',
    width: '400px',
    maxHeight: '400px',
    overflow: 'auto',
    overflowX: 'hidden'
  },
  form: {
    width: '100%'
  },
  topTitle: {
    textAlign: 'left',
  },
  topRightCtrButtons: {
    float: 'right',
    marginTop: '-20px'
  },
  input: {
    '& textarea:last-child': {
      width: '100%',
    },

    flexGrow: 1,
    width: '100%'
  },
  button: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  validationError: {
    fontSize: 10
  },
  buttonsWrapper: {
    textAlign: 'right',
    paddingTop: '20px'
  },
});

export default addStageStyle;

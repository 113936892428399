import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const moodPickerStyles: StyleRulesCallback = () => ({
  button: {
    height: 'auto !important',
  },
  happy: {
    color: '#92D050',
  },
  satisfied: {
    color: '#FFD200',
  },
  unhappy: {
    color: '#FF3300',
  },
  default: {
    color: '#CCCCCC',
  },
  popover: {
    padding: '10px',
  },
});

export default moodPickerStyles;

import {
  CompanyTrainingParticipantRegistration,
  CompanyTrainingParticipantType,
  ExportedParticipant,
} from '../types';
import { getEmployee, getUnitName } from '../helpers';
import translate from '@/app/utils/translate';
import { DATE_FORMAT } from '@/app/utils/helper';

export default function (registration: CompanyTrainingParticipantRegistration): ExportedParticipant {
  const guaranteedSeatText = registration.seated ? translate.t('laYes') : translate.t('laNo');
  const expiresText = !registration.expires ? '' : registration.expires.format(DATE_FORMAT);

  if (registration.participant.type === CompanyTrainingParticipantType.INTERNAL) {
    const emp = getEmployee(registration.participant.employeeId);
    const unit = getUnitName(emp.unitId);

    return {
      ...registration,
      employeeId: emp.id,
      firstName: emp.firstName,
      lastName: emp.lastName,
      email: emp.email,
      mobile: emp.mobile,
      phone: emp.phone,
      employeePosition: emp.position,
      unit,
      guaranteedSeatText,
      expiresText,
    };
  }

  return {
    ...registration,
    firstName: registration.participant.firstName,
    lastName: registration.participant.lastName,
    email: registration.participant.email,
    phone: registration.participant.phone,
    guaranteedSeatText,
    expiresText,
  };
}

import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const surveyFormDialogStyle: StyleRulesCallback = () => {
  return {
    root: {
      flexGrow: 1,
    },
    paperWidthMd: {
      width: '720px',
    },
    field: {
      '& > div > div > label + div': {
        height: '36px',
      },
    },
    hidden: {
      visibility: 'hidden',
      height: 0,
    },
  };
};

export default surveyFormDialogStyle;

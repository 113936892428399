import React, { useState, SyntheticEvent, useEffect, useContext } from 'react';
import {
  WithStyles,
  withStyles,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Icon,
  Paper,
  TablePagination,
  Toolbar,
  Link,
  InputAdornment,
  OutlinedInput,
} from '@material-ui/core';

import ConfirmDialog from '@/app/components/ConfirmDialog/ConfirmDialog';
import Loading from '@/app/components/Loading/Loading';
import translate from '@/app/utils/translate';

import Context from '../context/trainingsContext';
import {
  CompanyTraining,
  CompanyTrainingParticipantRegistration,
  ParticipantSummary,
} from '../types';
import { ParticipantsDialog, ParticipantsExportDialog } from './';

import {
  RowMenu,
  styles,
  TrainingTableRow,
  TrainingUpdateDialog,
} from './adminTrainingCalendarView/';
import { Search } from '@material-ui/icons';
import ParticipantsImportDialog from './ParticipantsImportDialog';

type OwnProps = {};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const AdminTrainingCalendarView = ({ classes }: Props) => {
  const {
    state: {
      companyTrainings: { data, loading, loadingId, error },
      participantSummaries: { data: participantSummaries },
    },
    getCompanyTrainings,
    deleteCompanyTraining,
    publishCompanyTraining,
    unpublishCompanyTraining,
  } = useContext(Context);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedTraining, setSelectedTraining] = useState<
    CompanyTraining | undefined
  >();
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
  const [participantsDialogOpen, setParticipantsDialogOpen] = useState(false);
  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [rowMenuAnchorEl, setRowMenuAnchorEl] = useState<any>();
  const [confirmText, setConfirmText] = useState<string | undefined>();
  const [updateDialog, setUpdateDialog] = useState(null);
  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    getCompanyTrainings();
  }, []);

  useEffect(() => {
    if (!updateDialogOpen && !duplicateDialogOpen) {
      setUpdateDialog(null);
      return;
    }

    const initialValues = !!updateDialogOpen
      ? selectedTraining
      : {
          ...selectedTraining,
          active: true, // Trick to get duplicate form dirty initially (because set inactive by the form)
        };

    setUpdateDialog(
      <TrainingUpdateDialog
        open={updateDialogOpen || duplicateDialogOpen}
        initialValues={!selectedTraining ? undefined : initialValues}
        toDuplicate={duplicateDialogOpen}
        onClose={handleCloseUpdateDialog}
      />,
    );
  }, [
    updateDialogOpen,
    duplicateDialogOpen,
    selectedTraining,
    setUpdateDialog,
  ]);

  const handlePageChange = (_e: any, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (e: any) => {
    setRowsPerPage(e.target.value);
  };

  const handleAddTraining = () => {
    setSelectedTraining(undefined);
    setUpdateDialogOpen(true);
  };

  const handleCloseUpdateDialog = () => {
    setUpdateDialogOpen(false);
    setDuplicateDialogOpen(false);
    setSelectedTraining(undefined);
  };

  const handleOpenParticipantsDialog = (training: CompanyTraining) => {
    setSelectedTraining(training);
    setParticipantsDialogOpen(true);
  };

  const handleOpenParticipantsExportDialog = (training: CompanyTraining) => {
    setSelectedTraining(training);
    setExportDialogOpen(true);
  };

  const handleOpenParticipantsImportDialog = (training: CompanyTraining) => {
    setSelectedTraining(training);
    setImportDialogOpen(true);
  };

  const handleCloseParticipantsDialog = () => {
    setParticipantsDialogOpen(false);
    setSelectedTraining(undefined);
  };

  const handleCloseExportDialog = () => {
    setExportDialogOpen(false);
    setSelectedTraining(undefined);
  };

  const handleCloseImportDialog = () => {
    setImportDialogOpen(false);
    setSelectedTraining(undefined);
  };

  const handleCloseRowMenu = () => {
    setRowMenuAnchorEl(undefined);
  };

  const handleEditTraining = () => {
    setUpdateDialogOpen(true);
  };

  const handlePublishTraining = () => {
    publishCompanyTraining(selectedTraining.id);
    setSelectedTraining(undefined);
  };

  const handleUnpublishTraining = () => {
    unpublishCompanyTraining(selectedTraining.id);
    setSelectedTraining(undefined);
  };

  const handleDuplicateTraining = () => {
    setDuplicateDialogOpen(true);
  };

  const handleExecuteDeleteTraining = () => {
    deleteCompanyTraining(selectedTraining.id);
    setSelectedTraining(undefined);
    setConfirmText(undefined);
  };

  const isDeletable = () => {
    return (
      participantSummaries.findIndex(
        (s: ParticipantSummary) =>
          s.trainingId === selectedTraining.id && s.totalRegistrants === 0,
      ) === -1
    );
  };

  const handleDeleteTraining = () => {
    setConfirmText(translate.t('label_confirm_delete_company_training'));
  };

  const handleSaveParticipants = (
    _newParticipants: CompanyTrainingParticipantRegistration[],
  ) => {
    // TODO: Do something with new participants, if needed
  };

  const participantsDialog =
    !participantsDialogOpen || !selectedTraining ? null : (
      <ParticipantsDialog
        open={participantsDialogOpen}
        training={selectedTraining}
        onSave={handleSaveParticipants}
        onClose={handleCloseParticipantsDialog}
      />
    );

  const exportDialog =
    !exportDialogOpen || !selectedTraining ? null : (
      <ParticipantsExportDialog
        open={exportDialogOpen}
        training={selectedTraining}
        onClose={handleCloseExportDialog}
      />
    );

  const importDialog =
    !importDialogOpen || !selectedTraining ? null : (
      <ParticipantsImportDialog
        open={importDialogOpen}
        training={selectedTraining}
        onClose={handleCloseImportDialog}
      />
    );

  const searchFilter = (training: CompanyTraining) => {
    const smallSearchValue = searchValue.toLowerCase();
    if (
      training.name.label.toLowerCase().includes(smallSearchValue) ||
      training.language.label.toLowerCase().includes(smallSearchValue) ||
      training.trainingGroup.label.toLowerCase().includes(smallSearchValue) ||
      training.provider.toLowerCase().includes(smallSearchValue)
    ) {
      return true;
    }
    return false;
  };

  const rowMenu = !rowMenuAnchorEl ? null : (
    <RowMenu
      training={selectedTraining}
      anchorEl={rowMenuAnchorEl}
      onClose={handleCloseRowMenu}
      onEdit={handleEditTraining}
      onPublish={handlePublishTraining}
      onUnpublish={handleUnpublishTraining}
      onDelete={isDeletable() ? undefined : handleDeleteTraining}
      onDuplicate={handleDuplicateTraining}
      onOpenParticipants={() => handleOpenParticipantsDialog(selectedTraining)}
      onExportParticipants={() =>
        handleOpenParticipantsExportDialog(selectedTraining)
      }
      onImportParticipants={() =>
        handleOpenParticipantsImportDialog(selectedTraining)
      }
    />
  );

  let content;
  if (error) {
    content = <Typography>ERROR: {error}</Typography>;
  } else if (loading || !data) {
    content = <Loading />;
  } else {
    content = (
      <>
        <OutlinedInput
          className={classes.searchField}
          value={searchValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchValue(e.target.value);
            setPage(0);
          }}
          margin="dense"
          labelWidth={0}
          placeholder={translate.t('laSearch')}
          startAdornment={
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          }
        />
        {updateDialog}
        {participantsDialog}
        {exportDialog}
        {importDialog}
        {rowMenu}
        <Table padding="dense" data-testid="table-admin-training-calendar">
          <TableHead>
            <TableRow>
              <TableCell>
                {translate.t('laTraining')} / {translate.t('training_class')}
              </TableCell>
              <TableCell>
                {translate.t('laProvider')} /{' '}
                {translate.t('label_responsible_persons')}
              </TableCell>
              <TableCell>{translate.t('label_training_type')}</TableCell>
              <TableCell>{translate.t('laLanguage')}</TableCell>
              <TableCell>{translate.t('label_dates')}</TableCell>
              <TableCell>{translate.t('label_training_audience')}</TableCell>
              <TableCell>
                {translate.t('label_training_participants')}
              </TableCell>
              <TableCell>
                {translate.t('label_include_in_training_compensation')}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .filter((training: CompanyTraining) => searchFilter(training))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((training: CompanyTraining) => {
                const handleOpenRowMenu = (e: SyntheticEvent) => {
                  setSelectedTraining(training);
                  setRowMenuAnchorEl(e.target);
                };

                return (
                  <TrainingTableRow
                    key={training.id}
                    training={training}
                    loading={loadingId === training.id}
                    onOpenRowMenu={handleOpenRowMenu}
                    onOpenParticipants={handleOpenParticipantsDialog}
                  />
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={data.length}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          backIconButtonProps={{
            'aria-label': translate.t('label_previous_page'),
          }}
          nextIconButtonProps={{
            'aria-label': translate.t('label_next_page'),
          }}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
        />
      </>
    );
  }

  const confirmDialog = !confirmText ? null : (
    <ConfirmDialog
      open={Boolean(confirmText)}
      text={confirmText}
      onOk={handleExecuteDeleteTraining}
      onClose={() => setConfirmText(undefined)}
      customOk={translate.t('laYes')}
      customClose={translate.t('laNo')}
    />
  );

  return (
    <Paper square={true} className={classes.root}>
      {confirmDialog}
      <Toolbar>
        <Typography className={classes.grow} variant="h6">
          {translate.t('training_calendar')}
        </Typography>
        <Link href="/app/external/trainings" className={classes.publicLink}>
          {translate.t('show_public_trainings')}
        </Link>
        <IconButton
          onClick={handleAddTraining}
          data-testid="btn-add-new-training"
        >
          <Icon color="primary">add_circle_outline</Icon>
        </IconButton>
      </Toolbar>
      {content}
    </Paper>
  );
};

export default withStyles(styles)(AdminTrainingCalendarView);

import * as React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  WithStyles,
} from '@material-ui/core';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { ReducerState } from '@/app/redux/store';
import {
  deleteScoreGroup,
  orderComponents,
  getEnumScoreGroups,
  deleteScoreGroups,
} from '@/app/redux/jobEvaluation';
import {
  openConfirmDialog,
  ConfirmDialogType,
} from '@/app/redux/confirmDialog';
import Loading from '@/app/components/Loading/Loading';
import translate from '@/app/utils/translate';
import ConfigureUiLabelButton from '@/app/components/ConfigurableLabels/ConfigureUiLabelButton/ConfigureUiLabelButton';
import scoreGroupsStyle from './scoreGroupsStyle';
import { HeaderElement, ScoreGroup } from '../../../types';
import TopMenuToolBar from './TopMenuToolBar/TopMenuToolBar';
import SideRowMenu from './SideMenuToolBar/SideRowMenu';
import { sortArr } from '../../../helpers';
import ModalWrapper from './TopMenuToolBar/ModalWrapper';
import { fetchAllEnums } from '@/app/redux/enums';

type MapStateToProps = {
  scoreGroups: ScoreGroup[];
};

type MapDispatchToProps = {
  getEnumScoreGroups: () => Promise<any>;
  deleteScoreGroup: (id: string) => void;
  deleteScoreGroups: (ids: string[]) => void;
  openConfirmDialog: (payload: ConfirmDialogType) => void;
  fetchAllEnums: () => void;
};

type PropsType = MapStateToProps &
  MapDispatchToProps &
  WithStyles<typeof scoreGroupsStyle>;

type StateType = {
  allCheckboxesSelected: boolean;
  selectedCheckboxes: any[];
  rowCount: number;
  openEditModal: boolean;
  selectedScoreGroupId: string;
  displayedOrder: { name: 'asc' | 'desc' };
  orderBy: string;
  isLoading: boolean;
};

class ScoreGroups extends React.PureComponent<PropsType> {
  state: StateType = {
    allCheckboxesSelected: false,
    selectedCheckboxes: [],
    rowCount: -1,
    openEditModal: false,
    selectedScoreGroupId: undefined,
    displayedOrder: {
      name: 'desc',
    },
    orderBy: 'name',
    isLoading: false,
  };

  componentDidMount() {
    this.setState({ isLoading: true });
    this.props
      .getEnumScoreGroups()
      .then(() => this.setState({ isLoading: false }));
  }

  componentDidUpdate(prevProps: PropsType, prevState: StateType) {
    const rowCount = this.props.scoreGroups.length;
    if (prevProps.scoreGroups.length !== rowCount) {
      this.setState({ rowCount: rowCount });
    }
    if (rowCount !== prevState.rowCount && prevState.rowCount !== -1) {
      this.setState({
        selectedCheckboxes: [],
        selectedScoreGroupId: undefined,
        allCheckboxesSelected: false,
      });
    }

    const beingEditedTemplate: any = this.props.scoreGroups.filter(
      (item: any) => item.id === this.state.selectedScoreGroupId,
    );
    if (
      beingEditedTemplate !== undefined &&
      this.state.selectedScoreGroupId !== undefined &&
      this.state.openEditModal
    ) {
      this.handleEditScoreGroup(this.state.selectedScoreGroupId);
    }
  }

  handleChange = (id: string) => {
    let selectedRows = this.state.selectedCheckboxes;
    if (selectedRows.includes(id)) {
      selectedRows = selectedRows.filter(row => row !== id);
    } else {
      selectedRows = [...selectedRows, id];
    }

    this.setState({
      selectedCheckboxes: selectedRows,
      allCheckboxesSelected: selectedRows.length === this.state.rowCount,
    });
  };

  onSelectAllClick = (scoreGroups: ScoreGroup[]) => {
    const selectedRows = scoreGroups.map((sc: any) => sc.id);
    this.setState({
      allCheckboxesSelected: !this.state.allCheckboxesSelected,
      selectedCheckboxes: !this.state.allCheckboxesSelected ? selectedRows : [],
    });
  };

  handleEditScoreGroup = (selectedScoreGroupId: string) => {
    this.setState({
      openEditModal: true,
      selectedScoreGroupId,
    });
  };

  handleDelete = (scoreGroupIds: string[]) => {
    this.props.openConfirmDialog({
      text: translate.t('confirm_delete_item_s'),
      onOk: () => this.props.deleteScoreGroups(scoreGroupIds),
    });
  };

  handleDeleteScoreGroup = (id: string) => {
    this.props.openConfirmDialog({
      text: translate.t('confirm_delete_item_s'),
      onOk: () => this.props.deleteScoreGroup(id),
    });
  };

  handleModalClose = () => {
    this.setState({ openEditModal: false, selectedScoreGroupId: '' });
  };

  sortHandle = (scoreGroups: ScoreGroup[], sortBy: string, order: string) => {
    const reversedOrder = order === 'asc' ? 'desc' : 'asc';
    sortArr(scoreGroups, sortBy, reversedOrder);
    this.setState({
      displayedOrder: { ...this.state.displayedOrder, [sortBy]: reversedOrder },
    });
    this.setState({ orderBy: sortBy });
  };

  render() {
    const {
      classes,
      scoreGroups,
      fetchAllEnums: handleUpdateEnum,
    } = this.props;
    const {
      allCheckboxesSelected,
      selectedCheckboxes,
      openEditModal,
      selectedScoreGroupId,
      displayedOrder,
      orderBy,
      isLoading,
    } = this.state;

    const tableSortLabelComponent = (
      componentOrderBy: string,
      label: string,
      direction: 'asc' | 'desc',
    ) => (
      <TableSortLabel
        classes={{
          icon:
            orderBy === componentOrderBy
              ? classes.activeSortIcon
              : classes.inactiveSortIcon,
        }}
        active={orderBy === componentOrderBy}
        direction={direction}
        onClick={() => this.sortHandle(scoreGroups, 'name', direction)}
      >
        {label}
      </TableSortLabel>
    );

    const usedScoreGroups = scoreGroups
      .filter((sc: ScoreGroup) => sc.id !== selectedScoreGroupId)
      .map((sc: ScoreGroup) => sc.headers.map((h: HeaderElement) => h.group));

    return (
      <>
        <Paper className={classes.root}>
          <Typography variant="h6" className={classes.sectionTitle}>
            <ConfigureUiLabelButton
              term={'score_groups'}
              fetchAllEnums={handleUpdateEnum}
            />
            {selectedCheckboxes.length > 0 && (
              <span className={classes.subHeader}>
                {translate.t('x_selected', {
                  count: selectedCheckboxes.length,
                })}
              </span>
            )}
            <TopMenuToolBar
              fullMenu={selectedCheckboxes.length === 1}
              partialShow={selectedCheckboxes.length > 1}
              selectedScoreGroupIds={selectedCheckboxes}
              deleteScoreGroup={this.handleDelete}
              handleEditScoreGroup={this.handleEditScoreGroup}
              selectedScoreGroupId={selectedScoreGroupId}
              usedScoreGroups={usedScoreGroups}
            />
          </Typography>
          {isLoading && <Loading key="loading" />}
          {!isLoading && scoreGroups.length === 0 && (
            <Typography variant="h6">
              {translate.t('laNoDataAvailable')}
            </Typography>
          )}
          {!isLoading && scoreGroups.length > 0 && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      indeterminate={
                        !allCheckboxesSelected && selectedCheckboxes.length > 0
                      }
                      checked={allCheckboxesSelected}
                      onChange={() => this.onSelectAllClick(scoreGroups)}
                      className={classes.topCheckbox}
                    />
                    {tableSortLabelComponent(
                      'name',
                      translate.t('laName'),
                      displayedOrder.name,
                    )}
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {scoreGroups.map((sc: ScoreGroup) => (
                  <TableRow hover role="checkbox" key={`${sc.id}-row`}>
                    <TableCell className={classes.tablecell} padding="checkbox">
                      <Checkbox
                        onClick={() => this.handleChange(sc.id)}
                        checked={selectedCheckboxes.includes(sc.id)}
                      />
                      {sc.name}
                    </TableCell>
                    <TableCell align="right">
                      <SideRowMenu
                        tools={{
                          showActive: true,
                          showTranslate: true,
                          showDuplicate: true,
                        }}
                        id={sc.id}
                        deleteScoreGroup={this.handleDeleteScoreGroup}
                        handleEditScoreGroup={this.handleEditScoreGroup}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Paper>

        <ModalWrapper
          modalOpen={openEditModal}
          handleModalClose={this.handleModalClose}
          selectedScoreGroupId={selectedScoreGroupId}
          usedScoreGroups={usedScoreGroups}
        />
      </>
    );
  }
}

const mapStateToProps = (state: ReducerState) => {
  const { scoreGroups } = state.jobEvaluation;

  return {
    scoreGroups,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getEnumScoreGroups: () => dispatch<any>(getEnumScoreGroups()),
  deleteScoreGroup: (id: string) => dispatch<any>(deleteScoreGroup(id)),
  deleteScoreGroups: (scoreGroupIds: []) =>
    dispatch<any>(deleteScoreGroups(scoreGroupIds)),
  orderComponents: (sectionId: string, components: any) =>
    dispatch<any>(orderComponents(sectionId, components)),
  openConfirmDialog: (payload: ConfirmDialogType) =>
    dispatch(openConfirmDialog(payload)),
  fetchAllEnums: () => dispatch<any>(fetchAllEnums()),
});

const enhance = compose<PropsType, {}>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(scoreGroupsStyle),
);

export default enhance(ScoreGroups);

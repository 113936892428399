import * as React from 'react';
import { Snackbar, IconButton, Theme } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { sanitize } from 'dompurify';
import translate from '@/app/utils/translate';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles = (theme: Theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

function MySnackbarContent(props: any) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={<span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          <span dangerouslySetInnerHTML={{__html: sanitize(message)}} />
        </span>}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          style={{height: 'auto'}} // needed because the old css overwrites button height in some sections
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

const MySnackbarContentWrapper = withStyles(styles)(MySnackbarContent);

export type NotifierTYpe = 'warning' | 'info' | 'success' | 'error';
interface Props {
  type: NotifierTYpe;
  open: boolean;
  message: string;
  onClose: any;
}

/**
 * Alert message component, default type is 'sucess', message is 'Changes saved'
 * @param props
 */
const Notifier: React.FunctionComponent<Props> = ({
  message = translate.t('change_saved'),
  type = 'success',
  open = false,
  onClose
}) => (
  <Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    open={open}
    autoHideDuration={5000}
    onClose={onClose}
  >
    <MySnackbarContentWrapper
      onClose={onClose}
      variant={type}
      message={message}
    />
  </Snackbar>
);

export default Notifier;

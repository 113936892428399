import { withStyles, WithStyles, TextField } from '@material-ui/core';
import * as React from 'react';
import { compose } from 'recompose';

import { isNumber } from '@/app/utils/helper';
import translate from '@/app/utils/translate';

import evaluationTotalsStyles from './evaluationTotalsStyles';

type IncomingProps = {
  formData: any;
};

type PropsType = IncomingProps & WithStyles<typeof evaluationTotalsStyles>;

class EvaluationTotals extends React.PureComponent<PropsType> {
  getTotalWeight = (): number => {
    return Object.values(this.props.formData).reduce<number>(
      (totalWeight, { commonGoals, personalGoals }) => {
        return (
          totalWeight +
          [...commonGoals, ...personalGoals].reduce<number>(
            (sectionTotalWeight, goal) =>
              sectionTotalWeight +
              (isNumber(goal.weight) ? parseFloat(goal.weight) : 0),
            0,
          )
        );
      },
      0,
    );
  };

  getTotal = () => {
    return Object.values(this.props.formData).reduce<number>(
      (total, { commonGoals, personalGoals }) => {
        return (
          total +
          [...commonGoals, ...personalGoals].reduce<number>(
            (sectionTotal, goal) => {
              const goalTotal =
                isNumber(goal.result) && isNumber(goal.weight)
                  ? goal.result * (goal.weight / 100)
                  : 0;
              const totalValue =
                parseFloat(sectionTotal.toFixed(3)) +
                parseFloat(goalTotal.toFixed(3));
              return Math.trunc(totalValue * 1000) / 1000;
            },
            0,
          )
        );
      },
      0,
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <TextField
          value={
            Math.round((this.getTotalWeight() + Number.EPSILON) * 1000) / 1000
          }
          label={`${translate.t('laWeight')}, %`}
          inputProps={{ readOnly: true, disabled: true }}
          className={classes.totalInput}
        />

        <TextField
          value={this.getTotal()}
          label={translate.t('laTotal')}
          inputProps={{ readOnly: true, disabled: true }}
          className={classes.totalInput}
        />
      </div>
    );
  }
}

const enhance = compose<any, any>(withStyles(evaluationTotalsStyles));

export default enhance(EvaluationTotals);

import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const style: StyleRulesCallback = (theme: Theme) => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  actions: {
    flex: '0 0 auto',
    textAlign: 'right'
  },
  title: {
    flex: '1 1 100%',
  },
  paperWidthMd: {
    width: '500px'
  },
});

export default style;
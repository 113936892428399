import {
  Button,
  Checkbox,
  Icon,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import translate from '@/app/utils/translate';
import * as React from 'react';
import { useState } from 'react';
import { Clear } from '@material-ui/icons';
import {
  ReportToolbarSettingsChangeAction,
  WorkflowsReportToolbarSettings,
} from './types';

export const ToolbarManagerAndHr = (props: WorkflowsReportToolbarSettings) => {
  const {
    unitOptions,
    teamCheck,
    subUnitCheck,
    selectedUnit,
    onReportToolbarSettingsChange,
    enabledActions,
  } = props;

  const [dropdownMenu, setDropdownMenu] = useState();

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={(event: any) => setDropdownMenu(event.currentTarget)}
      >
        {translate.t('laSelectUnit')}{' '}
        <Icon fontSize="small">arrow_drop_down</Icon>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={dropdownMenu}
        keepMounted={false}
        open={Boolean(dropdownMenu)}
        onClose={() => setDropdownMenu(null)}
      >
        {unitOptions.length < 1 && (
          <MenuItem selected={false} key={'no-options'} disabled={true}>
            {translate.t('laNoOptionsAvailable')}
          </MenuItem>
        )}
        {unitOptions.map(unit => (
          <MenuItem
            selected={false}
            key={unit.unitId}
            onClick={() => {
              onReportToolbarSettingsChange({
                selectedUnit: unit,
                teamCheck,
                subUnitCheck,
                action: ReportToolbarSettingsChangeAction.SelectedUnit,
              });
              setDropdownMenu(null);
            }}
          >
            {unit.unit}
          </MenuItem>
        ))}
      </Menu>

      {!!selectedUnit && (
        <>
          {selectedUnit.unit}
          <IconButton
            onClick={() => {
              onReportToolbarSettingsChange({
                selectedUnit: undefined,
                teamCheck,
                subUnitCheck: true,
                action: ReportToolbarSettingsChangeAction.SelectedUnit,
              });
            }}
          >
            <Clear />
          </IconButton>
        </>
      )}

      {enabledActions.teamCheckbox && (
        <span>
          <Checkbox
            checked={teamCheck}
            onChange={(event: any) => {
              onReportToolbarSettingsChange({
                selectedUnit: undefined,
                teamCheck: event.target.checked,
                subUnitCheck: !selectedUnit && !event.target.checked,
                action: ReportToolbarSettingsChangeAction.TeamCheck,
              });
            }}
            value="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />{' '}
          {translate.t('laTeam')}
        </span>
      )}

      {enabledActions.subUnitsCheckbox && (
        <span>
          <Checkbox
            checked={subUnitCheck}
            onChange={(event: any) => {
              onReportToolbarSettingsChange({
                selectedUnit,
                teamCheck: !selectedUnit && !event.target.checked,
                subUnitCheck: event.target.checked,
                action: ReportToolbarSettingsChangeAction.SubUnitCheck,
              });
            }}
            value="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />{' '}
          {translate.t('laIncludeSubunits')}
        </span>
      )}
    </div>
  );
};

import translate from '@/app/utils/translate';
import { roles } from './getRoles';

export default (roleKey: string) => {
  if (roles[roleKey]) {
    return translate.t(roles[roleKey]);
  } else {
    return translate.t('laUnknown');
  }
};

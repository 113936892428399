import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es6/string';
import 'core-js/es6/array';
import 'core-js/es6/weak-map';
import 'core-js/es6/object';
import 'core-js/es6/function';
import 'core-js/es6/number';
import 'core-js/es6/typed';
import 'core-js/es7/array';
import 'core-js/es7/object';
import 'raf/polyfill';

if (!global.Intl) {
  global.Intl = require('intl');
}

require('es6-promise').polyfill();

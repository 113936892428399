import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const employeeImageStyle: StyleRulesCallback = () => ({
  sm: {
    height: '40px',
    width: '40px',
    borderRadius: '4px',
  },
  md: {},
  lg: {},
  round: {
    borderRadius: '50%',
    verticalAlign: 'middle',
  },
});

export default employeeImageStyle;

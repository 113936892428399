import { withStyles } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { compose } from 'recompose';

import {
    SaveReportSettings as SaveReportSettingsComponent,
    SaveReportInnerProps,
    SaveReportOuterProps
} from './component';
import { styles } from './styles';

export const SaveReportSettings = compose<SaveReportInnerProps, SaveReportOuterProps>(
    withSnackbar,
    withStyles(styles)
)(SaveReportSettingsComponent);

import { IconButton, TableRow, TableCell, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import * as moment from 'moment';
import * as React from 'react';

import { ErrorTooltip } from '@/app/components/ErrorTooltip';
import LocalizedDatePicker from '@/app/components/Pickers/LocalizedDatePicker';
import { isNumber } from '@/app/utils/helper';
import translate from '@/app/utils/translate';

import MoodPicker from './MoodPicker';

type PropsType = {
  data: any;
  submitErrors?: any;
  onChange: (data: any) => void;
  onDelete: (data: any) => void;
  inEditMode: boolean;
};

type StateType = {
  errors: any;
};

class PersonalGoal extends React.PureComponent<PropsType, StateType> {
  state: StateType = {
    errors: {},
  };

  componentDidMount() {
    this.setState({ errors: this.props.submitErrors || {} });
  }

  componentDidUpdate(prevProps: Readonly<PropsType>) {
    if (this.props.submitErrors !== prevProps.submitErrors) {
      this.setState({ errors: this.props.submitErrors || {} });
    }
  }

  handleStatusChange = (status: string) => {
    this.props.onChange({ ...this.props.data, status });
  };

  handleChangeText = (property: string) => {
    return (event: any) => {
      this.props.onChange({
        ...this.props.data,
        [property]: event.target.value,
      });

      this.setState({
        errors: {
          ...this.state.errors,
          [property]: undefined,
        },
      });
    };
  };

  handleChangeDate = (property: string) => {
    return (date: moment.Moment) => {
      this.props.onChange({
        ...this.props.data,
        [property]: date.format('YYYY-MM-DD'),
      });
    };
  };

  handleDelete = () => {
    this.props.onDelete(this.props.data);
  };

  getTotal = () => {
    const { data } = this.props;
    const total = (data.result * (data.weight / 100)).toFixed(3);
    return isNumber(data.result) && isNumber(data.weight)
      ? Math.trunc(parseFloat(total) * 1000) / 1000
      : '';
  };

  render() {
    const { data, inEditMode } = this.props;
    const { errors } = this.state;

    return (
      <>
        <TableRow>
          <TableCell>
            <MoodPicker
              value={data.status}
              onChange={this.handleStatusChange}
              disabled={!inEditMode}
            />
          </TableCell>
          <TableCell colSpan={2}>
            <ErrorTooltip placement="top" title={errors.goal}>
              <TextField
                label={translate.t('laPersonalGoals')}
                value={data.goal || ''}
                onChange={this.handleChangeText('goal')}
                error={!!errors.goal}
                disabled={!inEditMode}
                inputProps={{ maxLength: 64 }}
                fullWidth
              />
            </ErrorTooltip>
          </TableCell>
          <TableCell colSpan={2}>
            <ErrorTooltip placement="top" title={errors.successMeasure}>
              <TextField
                label={translate.t('laSuccessMeasure')}
                value={data.successMeasure || ''}
                onChange={this.handleChangeText('successMeasure')}
                error={!!errors.successMeasure}
                disabled={!inEditMode}
                inputProps={{ maxLength: 64 }}
                fullWidth
              />
            </ErrorTooltip>
          </TableCell>
          <TableCell>
            <LocalizedDatePicker
              label={translate.t('laStarted')}
              value={data.startDate || null}
              onChange={this.handleChangeDate('startDate')}
              disabled={!inEditMode}
              autoOk={true}
            />
          </TableCell>
          <TableCell>
            <LocalizedDatePicker
              label={translate.t('laEnd')}
              value={data.endDate || null}
              onChange={this.handleChangeDate('endDate')}
              disabled={!inEditMode}
              autoOk={true}
            />
          </TableCell>
          <TableCell>
            {inEditMode && (
              <IconButton
                style={{ height: 'auto' }}
                onClick={this.handleDelete}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell />
          <TableCell>
            <ErrorTooltip placement="top" title={errors.target}>
              <TextField
                label={translate.t('laTarget')}
                value={data.target || ''}
                onChange={this.handleChangeText('target')}
                error={!!errors.target}
                disabled={!inEditMode}
                fullWidth
              />
            </ErrorTooltip>
          </TableCell>
          <TableCell>
            <ErrorTooltip placement="top" title={errors.rangeMin}>
              <TextField
                label={translate.t('laRangeMin')}
                value={data.rangeMin || ''}
                onChange={this.handleChangeText('rangeMin')}
                error={!!errors.rangeMin}
                disabled={!inEditMode}
                fullWidth
              />
            </ErrorTooltip>
          </TableCell>
          <TableCell>
            <ErrorTooltip placement="top" title={errors.rangeMax}>
              <TextField
                label={translate.t('laRangeMax')}
                value={data.rangeMax || ''}
                onChange={this.handleChangeText('rangeMax')}
                error={!!errors.rangeMax}
                disabled={!inEditMode}
                fullWidth
              />
            </ErrorTooltip>
          </TableCell>
          <TableCell>
            <ErrorTooltip placement="top" title={errors.actual}>
              <TextField
                label={translate.t('laActual')}
                value={data.actual || ''}
                onChange={this.handleChangeText('actual')}
                error={!!errors.actual}
                disabled={!inEditMode}
                fullWidth
              />
            </ErrorTooltip>
          </TableCell>
          <TableCell>
            <ErrorTooltip placement="top" title={errors.result}>
              <TextField
                label={translate.t('laResult')}
                value={data.result || ''}
                onChange={this.handleChangeText('result')}
                error={!!errors.result}
                disabled={!inEditMode}
                fullWidth
              />
            </ErrorTooltip>
          </TableCell>
          <TableCell>
            <ErrorTooltip placement="top" title={errors.weight}>
              <TextField
                label={translate.t('laWeight')}
                value={data.weight || ''}
                onChange={this.handleChangeText('weight')}
                error={!!errors.weight}
                disabled={!inEditMode}
                fullWidth
              />
            </ErrorTooltip>
          </TableCell>
          <TableCell>
            <TextField
              label={translate.t('laTotal')}
              value={this.getTotal()}
              disabled={true}
              fullWidth
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell />
          <TableCell colSpan={7}>
            <TextField
              multiline
              label={translate.t('laInfo')}
              variant="outlined"
              value={data.info || ''}
              onChange={this.handleChangeText('info')}
              disabled={!inEditMode}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </TableCell>
        </TableRow>
      </>
    );
  }
}

export default PersonalGoal;

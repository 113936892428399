import { StyleRulesCallback, Theme } from '@material-ui/core';

const styles: StyleRulesCallback = (theme: Theme) => ({
  grow: {
    flex: 1,
  },
  tableToolbar: {
    marginTop: theme.spacing.unit * 3,
  },
  toolbarIcon: {
    marginRight: theme.spacing.unit,
  },
});

export default styles;

import { StyleRulesCallback, Theme } from '@material-ui/core';

const styles: StyleRulesCallback = (theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    border: '1px dashed #c8c8c8',
    borderRadius: 5,
    marginTop: theme.spacing.unit * 2,
    textAlign: 'center',
    width: '100%',
    cursor: 'pointer',
  },
  paragraph: {
    padding: theme.spacing.unit,
  },
  existingAttachmentArea: {
    display: 'table',
  },
  existingAttachment: {
    paddingTop: 16,
    display: 'table-cell',
  },
  removeAttButton: {
    marginLeft: theme.spacing.unit / 2,
    padding: theme.spacing.unit / 2,
  },
  attachmentName: {
    display: 'inline-block',
    padding: theme.spacing.unit / 2,
    paddingLeft: 0,
  },
  attButton: {
    fontSize: 'inherit', // theme.typography.body1.fontSize,
    fontWeight: 'inherit',
    padding: theme.spacing.unit / 2,
    paddingLeft: 0,
    textAlign: 'left',
    textTransform: 'none',
  },
});

export default styles;

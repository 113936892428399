import translate from '@/app/utils/translate';
import getEmployee from './getEmployee';

export default (empIds: number[]): string => {
  if (empIds.length === 0) {
    return '';
  }

  let moreNote = '';
  if (empIds.length > 1) {
    moreNote = ` (${translate.t('label_plus_n_more', { count: empIds.length - 1})})`;
  }
  const emp = getEmployee(empIds[0]);

  return `${emp.firstName} ${emp.lastName}${moreNote}`;
};

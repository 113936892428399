import * as React from 'react';

import { FieldProps } from 'formik';
import {
  Button,
  Divider,
  Icon,
  ListItemIcon,
  Menu,
  MenuItem,
  WithStyles,
  withStyles,
} from '@material-ui/core';

import { EmployeeAutomationSelection, EmployeeAutomation } from '../types';
import styles from './fieldEmployeeAutomation/styles';
import translate from '@/app/utils/translate';
import {
  getEmployeeName,
  getEmployeeManagerId,
  getEmployeeHRId,
} from '@/old/utils/helper';

type OwnProps = {
  displayValue?: string;
  disabled?: boolean;
  automationText: string;
  automationType: EmployeeAutomation;
  loggedInEmpId: number;
  selectedEmpId: number;
  onChange: (value: EmployeeAutomationSelection, manualEmpId: number) => void;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const FieldEmployeeAutomation = ({
  classes,
  field,
  form,
  displayValue,
  automationText,
  automationType,
  loggedInEmpId,
  selectedEmpId,
  onChange,
  disabled = false,
}: FieldProps & Props) => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<any>();
  // const [manualEmpId, setManualEmpId] = React.useState(displayValue);

  const handleMenuItemClick = (
    selectedValue: EmployeeAutomationSelection,
    selectedManualEmpId?: number,
  ) => {
    form.setFieldValue(field.name, selectedValue);
    onChange(selectedValue, selectedManualEmpId);
    setMenuAnchorEl(undefined);
  };

  const menuItemAuto =
    automationType === 'NONE' ? (
      undefined
    ) : (
      <MenuItem value="AUTO" onClick={() => handleMenuItemClick('AUTO')}>
        <ListItemIcon>
          {field.value === 'AUTO' ? (
            <Icon fontSize="small">check</Icon>
          ) : (
            <Icon fontSize="small" />
          )}
        </ListItemIcon>
        {translate.t('label_use_automation', { automationText })}
      </MenuItem>
    );

  const menuItemsSelectedEmp = () => {
    if (loggedInEmpId === selectedEmpId) {
      return null;
    }

    const divider = <Divider key="sel_emp_dvdr" />;
    const selEmp = (
      <MenuItem
        key="sel_emp"
        value="SELECTED_EMP"
        onClick={() => handleMenuItemClick('SELECTED_EMP')}
      >
        <ListItemIcon>
          {field.value === 'SELECTED_EMP' ? (
            <Icon fontSize="small">check</Icon>
          ) : (
            <Icon fontSize="small" />
          )}
        </ListItemIcon>
        {translate.t('label_selected_emp', {
          name: getEmployeeName(selectedEmpId),
        })}
      </MenuItem>
    );

    const selEmpMan = (
      <MenuItem
        key="sel_emp_man"
        value="SELECTED_EMP_MANAGER"
        onClick={() => handleMenuItemClick('SELECTED_EMP_MANAGER')}
      >
        <ListItemIcon>
          {field.value === 'SELECTED_EMP_MANAGER' ? (
            <Icon fontSize="small">check</Icon>
          ) : (
            <Icon fontSize="small" />
          )}
        </ListItemIcon>
        {translate.t('label_selected_emp_manager', {
          name: getEmployeeName(getEmployeeManagerId(selectedEmpId)),
        })}
      </MenuItem>
    );

    const selEmpHr = (
      <MenuItem
        key="sel_emp_hr"
        value="SELECTED_EMP_HR_RESP"
        onClick={() => handleMenuItemClick('SELECTED_EMP_HR_RESP')}
      >
        <ListItemIcon>
          {field.value === 'SELECTED_EMP_HR_RESP' ? (
            <Icon fontSize="small">check</Icon>
          ) : (
            <Icon fontSize="small" />
          )}
        </ListItemIcon>
        {translate.t('label_selected_emp_hr_resp', {
          name: getEmployeeName(getEmployeeHRId(selectedEmpId)),
        })}
      </MenuItem>
    );

    return [divider, selEmp, selEmpMan, selEmpHr];
  };

  return (
    <>
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(undefined)}
      >
        <MenuItem value="NONE" onClick={() => handleMenuItemClick('NONE')}>
          <ListItemIcon>
            {field.value === 'NONE' ? (
              <Icon fontSize="small">check</Icon>
            ) : (
              <Icon fontSize="small" />
            )}
          </ListItemIcon>
          {translate.t('label_automation_none')}
        </MenuItem>
        {menuItemAuto}
        {menuItemsSelectedEmp()}
        <Divider />
        <MenuItem
          value="LOGGED_IN_USER"
          onClick={() => handleMenuItemClick('LOGGED_IN_USER')}
        >
          <ListItemIcon>
            {field.value === 'LOGGED_IN_USER' ? (
              <Icon fontSize="small">check</Icon>
            ) : (
              <Icon fontSize="small" />
            )}
          </ListItemIcon>
          {translate.t('label_logged_in_user', {
            name: getEmployeeName(loggedInEmpId),
          })}
        </MenuItem>
        <MenuItem
          value="LOGGED_IN_USER_MANAGER"
          onClick={() => handleMenuItemClick('LOGGED_IN_USER_MANAGER')}
        >
          <ListItemIcon>
            {field.value === 'LOGGED_IN_USER_MANAGER' ? (
              <Icon fontSize="small">check</Icon>
            ) : (
              <Icon fontSize="small" />
            )}
          </ListItemIcon>
          {translate.t('label_logged_in_user_manager', {
            name: getEmployeeName(getEmployeeManagerId(loggedInEmpId)),
          })}
        </MenuItem>
        <MenuItem
          value="LOGGED_IN_USER_HR_RESP"
          onClick={() => handleMenuItemClick('LOGGED_IN_USER_HR_RESP')}
        >
          <ListItemIcon>
            {field.value === 'LOGGED_IN_USER_HR_RESP' ? (
              <Icon fontSize="small">check</Icon>
            ) : (
              <Icon fontSize="small" />
            )}
          </ListItemIcon>
          {translate.t('label_logged_in_user_hr_resp', {
            name: getEmployeeName(getEmployeeHRId(loggedInEmpId)),
          })}
        </MenuItem>
      </Menu>
      <Button
        className={classes.button}
        disabled={disabled}
        size="small"
        onClick={(e: React.SyntheticEvent) => setMenuAnchorEl(e.target)}
      >
        {!displayValue ? translate.t('label_automation_none') : displayValue}
        <Icon>arrow_drop_down</Icon>
      </Button>
    </>
  );
};

export default withStyles(styles)(FieldEmployeeAutomation);

import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import API from '@/app/api/internalAPIs';
import Service from '@/app/utils/service';
import {
  getCompanyData,
  getSelectedEmpId,
  userHasApprovedAbsence,
} from '@/old/utils/helper';
import {
  formatDurationToHMM,
  parseHMMToDurationISOString,
} from '@/app/utils/helper';
import translate from '@/app/utils/translate';

import { ReportingGroupType } from '../../data';

import {
  DailySummaryFormValues,
  DailySummaryInnerProps,
} from './dailySummaryModels';
import { dailySummaryValidationResolverFactory } from './dailySummaryValidationResolver';
import { filterNonEmptyTimeBlocks } from './filterNonEmptyTimeBlocks';
import { momentIsFilled } from './momentIsFilled';
import { timerIsRunning } from './timerIsRunning';
import { timerJustStarted } from './timerJustStarted';

const WINDOW = window as any;

export const useDailySummary = ({
  date,
  orderedTimeCardsData,
  updateTimeCard,
  isSaving,
  failedToSave,
  defaultWorkingHours,
  blockAfterDays,
  enqueueSnackbar,
  autoDeductionRule,
}: DailySummaryInnerProps) => {
  const prevSavingPropsRef = useRef<
    Pick<DailySummaryInnerProps, 'isSaving' | 'failedToSave'>
  >();
  const [isExpanded, setIsExpanded] = useState(false);
  const [inEditMode, setInEditMode] = useState(false);
  const [isLoadingAdditionalData, setIsLoadingAdditionalData] = useState('');

  const timeCardIsArchived = useMemo(() => {
    if (!blockAfterDays) {
      return false;
    }

    const lastDayWhenAllowedToEdit = moment(date, 'YYYY-MM-DD').add(
      blockAfterDays,
      'days',
    );
    return lastDayWhenAllowedToEdit < moment().startOf('day');
  }, [date, blockAfterDays]);

  const isApprovedVacation = useMemo(() => userHasApprovedAbsence(date), [
    date,
  ]);

  const dailySummaryValidationResolver = useMemo(
    () => dailySummaryValidationResolverFactory(date),
    [date],
  );

  const {
    register,
    setValue,
    getValues,
    watch,
    handleSubmit,
    errors,
    setError,
    clearError,
  } = useForm<DailySummaryFormValues>({
    validationResolver: dailySummaryValidationResolver,
    defaultValues: { timeBlocks: {} },
  });

  const timeCardData = orderedTimeCardsData[date];

  const timeCardIsInitialized = !!timeCardData;

  // This is only calculated if the time card is not existing.
  const prevRegisteredDayWorkingHours = useMemo(() => {
    if (timeCardIsInitialized) {
      return undefined;
    }

    const loadedDates = Object.keys(orderedTimeCardsData);
    /**
     * Time cards data is already sorted desc by date.
     * This allows us to simply get the first date we
     * find that is before our date.
     */
    const prevDate = loadedDates.find(d => d < date);
    return prevDate && orderedTimeCardsData[prevDate].requiredWorkingHours;
  }, [timeCardIsInitialized, orderedTimeCardsData, date]);

  const setInitialFormData = useCallback(
    (actualDefaultWorkingHours: string) => {
      setValue([
        {
          defaultWorkingHours:
            (timeCardData &&
              formatDurationToHMM(timeCardData.requiredWorkingHours)) ||
            formatDurationToHMM(actualDefaultWorkingHours),
        },
        { comment: timeCardData && timeCardData.comment },
        {
          timeBlocks: {
            ...(timeCardData &&
              timeCardData.timeBlocks.reduce((accumulator, timeBlock) => {
                return {
                  ...accumulator,
                  [`remote-${timeBlock.id}`]: {
                    reportingType: `${timeBlock.reportingGroupType}-${timeBlock.reportingType}`,
                    start:
                      timeBlock.from &&
                      moment(timeBlock.from, 'HH:mm:ss').toDate(),
                    end:
                      timeBlock.to && moment(timeBlock.to, 'HH:mm:ss').toDate(),
                    hours: formatDurationToHMM(timeBlock.hours),
                    customData: timeBlock.customData,
                  },
                };
              }, {})),
            [WINDOW._.uniqueId('local-')]: {},
          },
        },
      ]);
    },
    [timeCardData, setValue],
  );

  const clearFormData = useCallback(() => {
    setValue([
      { defaultWorkingHours: undefined },
      { comment: undefined },
      { timeBlocks: {} },
    ]);

    clearError();
  }, [setValue, clearError]);

  useEffect(() => {
    register({ name: 'defaultWorkingHours' });
    register({ name: 'comment' });
    register({ name: 'timeBlocks' });
  }, [register]);

  const handlePanelToggle = useCallback(() => {
    setIsExpanded(currentIsExpanded => !currentIsExpanded);
  }, [setIsExpanded]);

  const getActualPrevRegisteredDayWorkingHours = useCallback(
    async (loadingDataFor: string) => {
      let actualPrevRegisteredDayWorkingHours = prevRegisteredDayWorkingHours;

      /**
       * We make sure to request working hours for previous registered day
       * if is a new time card and were not able to determine based on data we have.
       */
      if (!timeCardIsInitialized && !actualPrevRegisteredDayWorkingHours) {
        setIsLoadingAdditionalData(loadingDataFor);

        try {
          await Service.get(
            API.workTimeSettings.getPrevRegisteredTimeCardWorkingHours(
              getSelectedEmpId(),
              date,
            ),
            ({ hours }: { hours: string }) => {
              actualPrevRegisteredDayWorkingHours = hours;
              setIsLoadingAdditionalData('');
            },
            (error: { status: number }) => {
              setIsLoadingAdditionalData('');

              if (error.status !== 404) {
                throw error;
              }
            },
          );
        } catch {
          enqueueSnackbar(translate.t('laFailedToLoadTimeCardData'), {
            variant: 'error',
          });

          throw new Error('Failed to load time card data!');
        }
      }

      return actualPrevRegisteredDayWorkingHours;
    },
    [
      date,
      timeCardIsInitialized,
      setIsLoadingAdditionalData,
      prevRegisteredDayWorkingHours,
      enqueueSnackbar,
    ],
  );

  const handleEditModeEnter = useCallback(async () => {
    try {
      setInitialFormData(
        (await getActualPrevRegisteredDayWorkingHours('EDIT')) ||
          defaultWorkingHours,
      );

      setInEditMode(true);
      setIsExpanded(true);
    } catch (e) {
      // Snackbar was already shown if fails to retrieve
      // PrevRegisteredTimeCardWorkingHours
    }
  }, [
    setInitialFormData,
    getActualPrevRegisteredDayWorkingHours,
    defaultWorkingHours,
    setInEditMode,
    setIsExpanded,
  ]);

  const handleEditModeLeave = useCallback(() => {
    setInEditMode(false);
    clearFormData();
  }, [setInEditMode, clearFormData]);

  const timerStartIsConfigured = !!getCompanyData().config
    .fComDefaultWorkReportingType;

  const handleStartTimer = useCallback(async () => {
    if (
      (timeCardData &&
        (timerIsRunning(date, timeCardData) ||
          momentIsFilled(date, timeCardData))) ||
      !timerStartIsConfigured
    ) {
      return;
    }

    try {
      updateTimeCard(getSelectedEmpId(), {
        date,
        requiredWorkingHours:
          (timeCardData && timeCardData.requiredWorkingHours) ||
          (await getActualPrevRegisteredDayWorkingHours('TIMER')) ||
          defaultWorkingHours,
        comment: (timeCardData && timeCardData.comment) || '',
        timeBlocks: [
          ...((timeCardData && timeCardData.timeBlocks) || []),
          {
            reportingType: getCompanyData().config.fComDefaultWorkReportingType,
            reportingGroupType: ReportingGroupType.TIMESHEET_WORK_TYPE,
            from: moment().format('HH:mm:ss'),
            customData: {},
          },
        ],
      });
    } catch (e) {
      // Snackbar was already shown if fails to retrieve
      // PrevRegisteredTimeCardWorkingHours
    }
  }, [
    date,
    timeCardData,
    timerStartIsConfigured,
    getActualPrevRegisteredDayWorkingHours,
    updateTimeCard,
    defaultWorkingHours,
  ]);

  const handleStopTimer = useCallback(() => {
    if (
      !timeCardData ||
      !timerIsRunning(date, timeCardData) ||
      timerJustStarted(date, timeCardData)
    ) {
      return;
    }

    updateTimeCard(getSelectedEmpId(), {
      ...timeCardData,
      timeBlocks: timeCardData.timeBlocks.map(timeBlock => {
        if (timeBlock.from && !timeBlock.to) {
          return {
            ...timeBlock,
            to: moment().format('HH:mm:ss'),
          };
        }

        return timeBlock;
      }),
    });
  }, [date, timeCardData, updateTimeCard]);

  const handleAddNewTransactionRow = useCallback(() => {
    setValue([
      {
        timeBlocks: {
          ...getValues('timeBlocks'),
          [WINDOW._.uniqueId('local-')]: {},
        },
      },
    ]);
  }, [getValues, setValue]);

  const handleDeleteTransactionRow = useCallback(
    (timeBlockId: string) => {
      const remainingTimeBocks = WINDOW._.omit(
        getValues('timeBlocks'),
        timeBlockId,
      );

      setValue([
        {
          timeBlocks: WINDOW._.isEmpty(remainingTimeBocks)
            ? { [WINDOW._.uniqueId('local-')]: {} }
            : remainingTimeBocks,
        },
      ]);
    },
    [getValues, setValue],
  );

  const handleSaveDailySummary = useMemo(() => {
    return handleSubmit(values => {
      updateTimeCard(getSelectedEmpId(), {
        date,
        requiredWorkingHours: parseHMMToDurationISOString(
          values.defaultWorkingHours,
        ),
        comment: values.comment || '',
        timeBlocks: filterNonEmptyTimeBlocks(values.timeBlocks).map(
          timeBlockId => {
            const [
              actualReportingGroupType,
              actualReportingType,
            ] = values.timeBlocks[timeBlockId].reportingType.split('-');

            return {
              reportingType: actualReportingType,
              reportingGroupType: actualReportingGroupType as ReportingGroupType,
              from:
                values.timeBlocks[timeBlockId].start &&
                moment(values.timeBlocks[timeBlockId].start).format('HH:mm'),
              to:
                values.timeBlocks[timeBlockId].end &&
                moment(values.timeBlocks[timeBlockId].end).format('HH:mm'),
              hours:
                values.timeBlocks[timeBlockId].hours &&
                parseHMMToDurationISOString(
                  values.timeBlocks[timeBlockId].hours,
                ),
              customData: values.timeBlocks[timeBlockId].customData || {},
            };
          },
        ),
      });
    });
  }, [handleSubmit, date, updateTimeCard]);

  useEffect(() => {
    if (
      prevSavingPropsRef.current &&
      prevSavingPropsRef.current.isSaving &&
      !isSaving
    ) {
      if (failedToSave) {
        if (inEditMode) {
          setError(
            'form',
            'validation',
            translate.t('laFailedToSaveDailySummary'),
          );
        } else {
          enqueueSnackbar(translate.t('laFailedToSaveDailySummary'), {
            variant: 'error',
          });
        }
      } else {
        handleEditModeLeave();
      }
    }

    prevSavingPropsRef.current = { isSaving, failedToSave };
  }, [
    prevSavingPropsRef,
    isSaving,
    failedToSave,
    handleEditModeLeave,
    setError,
    inEditMode,
    enqueueSnackbar,
  ]);

  return {
    timeCardIsArchived,
    timeCardData,
    isLoadingAdditionalData,
    isApprovedVacation,
    isExpanded,
    handlePanelToggle,
    inEditMode,
    handleEditModeEnter,
    handleEditModeLeave,
    timerStartIsConfigured,
    handleStartTimer,
    handleStopTimer,
    formErrors: errors,
    watchFormValues: watch,
    getFormValues: getValues,
    setFormValue: setValue,
    handleDeleteTransactionRow,
    handleAddNewTransactionRow,
    handleSaveDailySummary,
    autoDeductionRule,
  };
};

import * as React from 'react';
import { Button, StyleRulesCallback, WithStyles, withStyles, Typography } from '@material-ui/core';
import { ChildInputType } from '@/app/components/FormFields/FormFields';

const styles: StyleRulesCallback = () => ({
  root: {
    display: 'inline-block',
    '& :first-child': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    '& :last-child': {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    }
  },
  button: {
    boxShadow: 'none',
    borderRadius: 0,
  },
});

type OwnProps = {
  className?: string;
  buttons: ChildInputType[];
  selectedButton?: string;
  onSelect: (item: string) => void;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const ToolbarFilterButtonGroup = ({
  className,
  classes,
  buttons,
  selectedButton,
  onSelect,
}: Props) => {
  if (!buttons || buttons.length === 0) {
    return null;
  }

  return (
    <div className={[classes.root, className].join(' ')}>
      {buttons.map(button => (
        <Button
          className={classes.button}
          color="default"
          key={button.value}
          variant={selectedButton === button.value ? 'contained' : 'outlined'}
          onClick={() => onSelect(button.value)}
        >
          <Typography>
            {button.label}
          </Typography>
        </Button>
      ))}
    </div>
  );
};

export default withStyles(styles)(ToolbarFilterButtonGroup);

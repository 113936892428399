import * as React from 'react';
import {
  WithStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Grid,
  DialogTitle,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import translate from '@/app/utils/translate';
import { Training } from '../TrainingList/TrainingList';
import { sanitize } from 'dompurify';
import { formatStartAndEndDate } from '@/app/utils/helper';
import { withStyles } from '@material-ui/core/styles';
import trainingCalendarDialogStyle from './trainingCalendarDialogStyle';

interface PropsType
  extends WithStyles<typeof trainingCalendarDialogStyle, true> {
  open: boolean;
  onClose: React.MouseEventHandler;
  trainings: Array<Training>;
}

interface StateType {
  expandedId: number;
}

class TrainingCalendarDialog extends React.Component<PropsType, StateType> {
  state: StateType = {
    expandedId: null,
  };

  handleChange = (trainingId: number) => (
    _event: React.ChangeEvent<{}>,
    expanded: boolean,
  ) => {
    this.setState({
      expandedId: expanded ? trainingId : null,
    });
  };

  render() {
    const { open, onClose, trainings, classes } = this.props;
    const { expandedId } = this.state;

    return (
      <Dialog
        open={open}
        aria-labelledby="confirmation-dialog-title"
        maxWidth="md"
      >
        <DialogTitle id="confirmation-dialog-title">
          {translate.t('training_calendar')}
        </DialogTitle>
        <DialogContent className={classes.root}>
          {trainings.map((training) => (
            <ExpansionPanel
              key={training.id}
              expanded={expandedId === training.id}
              onChange={this.handleChange(training.id)}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography
                  noWrap
                  variant="subtitle1"
                  className={classes.trainingName}
                >
                  {training.name}
                </Typography>
                <Typography>
                  {formatStartAndEndDate(training.start, training.end)}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6}>
                    {translate.t('laProvider')}: {training.provider}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    {translate.t('training_class')}: {training.trainingClass}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    {translate.t('laHours')}:{' '}
                    {training.hours ? training.hours : '-'}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: sanitize(training.description),
                      }}
                    />
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            variant="text"
            component="a"
            onClick={onClose}
            color="primary"
          >
            {translate.t('laClose')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(trainingCalendarDialogStyle, { withTheme: true })(
  TrainingCalendarDialog,
);

import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

export const decisionRegistryFormStyles: StyleRulesCallback = (theme) => ({
  inputFileUpload: {
    display: 'none'
  },
  fileButton: {
    paddingLeft: theme.spacing.unit
  },
  reminderButton: {
    marginLeft: theme.spacing.unit,
    marginBottom: -theme.spacing.unit * 4
  }
});

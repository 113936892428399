import React, { useState } from 'react';
import { Button, Icon, Menu, MenuItem, Typography } from '@material-ui/core';
import translate from '@/app/utils/translate';
import { UpdateTrainingHistoryAction, COMPLETION_STATUS } from '../../../../types';
import { getCompletionStatusName } from '../../../../helpers';

type OwnProps = {
  className?: string;
  readOnly?: boolean;
  onChangeUpdateHistory: (action: UpdateTrainingHistoryAction) => void;
};
type InnerProps = {};
type Props = OwnProps & InnerProps;

const UpdateHistoryHeadButton = ({
  className,
  readOnly = false,
  onChangeUpdateHistory,
}: Props) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(undefined);

  const handleChangeUpdateHistory = (action: UpdateTrainingHistoryAction) => {
    setMenuAnchorEl(undefined);
    onChangeUpdateHistory(action);
  };

  if (readOnly) {
    return <Typography variant="inherit">{translate.t('label_update_training_history')}</Typography>;
  }

  return (
    <>
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(undefined)}
      >
        <MenuItem onClick={() => handleChangeUpdateHistory('MARK_ALL_COMPLETED_TO_UPDATE_HISTORY')}>
          {translate.t(
            'label_mark_all_statuses_to_update_history',
            { status: getCompletionStatusName(COMPLETION_STATUS.COMPLETED).toLocaleUpperCase() }
          )}
        </MenuItem>
        <MenuItem onClick={() => handleChangeUpdateHistory('UNMARK_ALL_COMPLETED_FROM_UPDATING_HISTORY')}>
          {translate.t(
            'label_unmark_all_statuses_from_updating_history',
            { status: getCompletionStatusName(COMPLETION_STATUS.COMPLETED).toLocaleUpperCase() }
          )}
        </MenuItem>
      </Menu>
      <Button className={className} color="inherit" onClick={e => setMenuAnchorEl(e.target)}>
        {translate.t('label_update_training_history')}
        <Icon>arrow_drop_down</Icon>
      </Button>
    </>
  );
};

export default UpdateHistoryHeadButton;

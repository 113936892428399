import translate from '@/app/utils/translate';

export default (code: string): string => {
  switch (code) {
    case 'INTERNAL_TRAINING_TYPE':
      return translate.t('label_internal');
    case 'EXTERNAL_TRAINING_TYPE':
      return translate.t('label_external');
    case 'OTHER_TRAINING_TYPE':
      return translate.t('label_other');
    default:
      return '';
  }
};

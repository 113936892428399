import { getEmployeeName } from '@/old/utils/helper';
import moment from 'moment/moment';
import { RewardingWorkflowReportEntry } from '@/app/components/WorkflowsReport/ReportTypes/Rewarding/types';

export const mapClosedWorkflowsForUI = (
  data: any[],
): RewardingWorkflowReportEntry[] => {
  return data.map(proposal => ({
    empId: proposal.fEmpId,
    firstName: proposal.fEmpFirstName,
    lastName: proposal.fEmpLastName,
    empNo: proposal.fEmpEmployeeNumber,
    unitId: proposal.fEmpUnitId,
    unit: proposal.fEmpUnit,
    unitNo: proposal.fEmpUnitNumber,
    managerId: getEmployeeName(proposal.fEmpManagerId),
    workflowId: proposal.fOpenWorkflowUid,
    status: proposal.fOpenWorkflowStatus,
    amount: proposal.fOpenWorkflowAmount,
    group: proposal.fOpenWorkflowGroup,
    type: proposal.fOpenWorkflowType,
    scheduledDate: moment(
      proposal.fOpenWorkflowScheduledDate,
      'YYYY-MM-DD',
    ).format('DD.MM.YYYY'),
    updatedDate: moment(proposal.fOpenWorkflowLastUpdated, 'YYYY-MM-DD').format(
      'DD.MM.YYYY',
    ),
    approvedBy: getEmployeeName(proposal.fOpenWorkflowUpdatedByEmpId),
    info: proposal.fOpenWorkflowInfo,
    ext: proposal.fEmpHasAccount,
  }));
};

import React, { useState, ReactNode } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from '@material-ui/core';
import {
  Form,
  Formik,
  FormikProps,
} from 'formik';
import * as yup from 'yup';
import translate from '@/app/utils/translate';
import DialogTitle from './formikDialog/DialogTitle';

type OwnProps<T> = {
  children?: ((props: FormikProps<T>) => ReactNode) | ReactNode;
  initialValues: T;
  open: boolean;

  fullWidth?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false,
  cancelLabel?: string;
  saveLabel?: string;
  scroll?: 'paper' | 'body';
  title?: string;
  validationSchema?: yup.ObjectSchema;
  disableEnforceFocus?: boolean;
  needsToBeValidatedBeforeSave?: boolean;

  onClose: () => void;
  onSubmit: (values: T, actions?: FormikProps<T>) => void;
};
type InnerProps = {};
type Props<T> = OwnProps<T> & InnerProps;

const FormikDialog = <T extends object>({
  children,
  scroll,
  initialValues,
  title = '',
  fullWidth = false,
  maxWidth = 'sm',
  cancelLabel = translate.t('laCancel'),
  saveLabel = translate.t('laSave'),
  open,
  validationSchema,
  disableEnforceFocus = false,
  needsToBeValidatedBeforeSave = true,
  onClose,
  onSubmit,
}: Props<T>) => {
  const [values] = useState(initialValues);

  const handleSubmit = async (submittedValues: T, actions: FormikProps<T>) => {
    actions.setSubmitting(true);
    onSubmit(submittedValues, actions);
    actions.setSubmitting(false);
    onClose();
  };

  const renderDialog = (formikBag: FormikProps<T>) => {
    const handleSubmitClick = () => {
      formikBag.handleSubmit();
    };

    return (
      <Dialog open={open} scroll={scroll} fullWidth={fullWidth} maxWidth={maxWidth} disableEnforceFocus={disableEnforceFocus}>
        <DialogTitle title={title} onClose={onClose} />
        <DialogContent>
          <Form>
            {(typeof children === 'function') ? children(formikBag) : children}
          </Form>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={onClose}
            data-testid="form-dialog-cancel"
          >
            {cancelLabel}
          </Button>
          <Button
            color="primary"
            disabled={formikBag.isSubmitting || (needsToBeValidatedBeforeSave && !formikBag.isValid) || !formikBag.dirty}
            onClick={handleSubmitClick}
            variant="contained"
            data-testid="form-dialog-submit"
          >
            {!formikBag.isSubmitting ? saveLabel : <CircularProgress color="inherit" size={24} />}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Formik
      initialValues={values}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {renderDialog}
    </Formik>
  );
};

export default FormikDialog;

import { StyleRulesCallback, Theme } from '@material-ui/core';

const styles: StyleRulesCallback = (theme: Theme) => ({
  textApproved: {
    color: 'green',
  },
  rowMenuButton: {
    height: '24px !important',
    width: '24px !important',
  },
  detailsDialog: {
    height: 400,
    overflowWrap: 'break-word',
    overflowX: 'scroll',
  },
  tableCell: {
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
  },
});

export default styles;

import * as React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { getFormDialog } from '@/app/components/FormDialog/FormDialog';
import FormFields, { InputType } from '@/app/components/FormFields/FormFields';
import { ReducerState } from '@/app/redux/store';
import { LanguagesType } from '@/app/redux/languages';
import {
  EntityTranslation,
  EntityFieldTranslation,
  EntityFieldTranslationSubmitData,
  fetchEntityFieldTranslations,
  resetEntityFieldTranslations,
  updateEntityFieldTranslations,
  TranslatableEntity,
  TranslatableEntityProperty
} from '@/app/redux/entityTranslations';
import { INPUT_TYPE } from '@/app/utils/helper';
import translate from '@/app/utils/translate';
import { withStyles, WithStyles } from '@material-ui/core';
import styles from './styles';

const supportedLanguags: LanguagesType[] = require('@/assets/languages.json');
const validate = (values: any) => {
  const errors: {[k: string]: any} = {};
  const keys = Object.keys(values);
  if (keys) {
    keys.forEach(key => {
      if (values[key] && values[key].length > 200) {
        errors[key] = `${translate.t('laErrorMaxTextLen1')} 200`;
      }
    });
  }

  return errors;
};

const getDialogTitle = (entityName: string, entityCode?: string) => {
  if (!entityCode) {
    return `${entityName}`;
  }

  return `${entityName} (${entityCode})`;
};

type TranslateDialogFormData = {
  [key: string]: string;
};

type MapStateToProps = {
  entityTranslations: EntityFieldTranslation;
  languages: LanguagesType[];
};

type MapDispatchToProps = {
  fetchEntityTranslations: (
    entity: TranslatableEntity,
    id: string,
    property: TranslatableEntityProperty
  ) => void;
  resetEntityTranslations: () => void;
  updateEntityTranslations: (
    entity: TranslatableEntity,
    id: string,
    property: TranslatableEntityProperty,
    translationSubmitData: EntityFieldTranslationSubmitData
  ) => Promise<void>;
};

type InnerProps = WithStyles<typeof styles> & {
  languages: LanguagesType[];
} & MapStateToProps & MapDispatchToProps;

export type GenericTranslateDialogOwnProps = {
  entityName: string;
  entityId: string;
  entityType: TranslatableEntity;
  entityProperty: TranslatableEntityProperty;
  open: boolean;
  entityCode?: string;
  onClose: () => void;
};

type Props = GenericTranslateDialogOwnProps & InnerProps;

const FORM_NAME = 'genericTranslateForm';
const FormDialog = getFormDialog(FORM_NAME, validate);
const GenericTranslateDialog = ({
  classes,
  entityCode,
  entityId,
  entityName,
  entityProperty,
  entityTranslations,
  entityType,
  fetchEntityTranslations,
  languages,
  open,
  onClose,
  resetEntityTranslations,
  updateEntityTranslations
}: Props) => {
  const [defaultFormData, setDefaultFormData] = React.useState<TranslateDialogFormData>();
  React.useEffect(
    () => {
      fetchEntityTranslations(entityType, entityId, entityProperty);
    },
    []
  );

  React.useEffect(
    () => {
      if (!entityTranslations.translations || !Object.keys(entityTranslations.translations).length) {
        return;
      }

      const formData = Object.keys(entityTranslations.translations).reduce(
        (acc, translateKey) => {
          return {
            ...acc,
            [translateKey]: entityTranslations.translations[translateKey].translation
          };
        },
        {}
      );

      return setDefaultFormData(formData);
    },
    [entityTranslations]
  );

  const handleOnClose = () => {
    resetEntityTranslations();
    onClose();
  };

  const handleSubmit = (translateData: TranslateDialogFormData) => {
    const translationSubmitData: EntityTranslation[] = Object.keys(translateData).filter((translateKey) => {
      return !!translateData[translateKey];
    }).map((translateKey) => ({
      locale: translateKey,
      translation: translateData[translateKey]
    }));

    updateEntityTranslations(entityType, entityId, entityProperty, {
      translations: translationSubmitData
    }).then(() => {
      handleOnClose();
    });

  };

  const getTranslateLabel = (code: string) => {
    const result = supportedLanguags.find((l: LanguagesType) => l.code === code);
    if (!result) {
      return '';
    }

    return result.name;
  };

  const inputs: InputType[] = languages.map((language: LanguagesType) => ({
    type: INPUT_TYPE.TEXT,
    code: language.code,
    name: language.code,
    label: getTranslateLabel(language.code),
    order: 1,
    required: false
  }));

  return (
    <FormDialog
      title={getDialogTitle(entityName, entityCode)}
      open={open}
      onClose={handleOnClose}
      formData={defaultFormData}
      paperWidthMd={classes.paperWidthMd}
      onSubmit={handleSubmit}
      keepSubmitEnabled={true}
    >
      <FormFields inputs={inputs} />
    </FormDialog>
  );
};

const mapStateToProps = (state: ReducerState) => {
  const { entityTranslations, languages } = state;

  return {
    entityTranslations: entityTranslations.get('entityFieldTranslations'),
    languages: languages.get('allLanguages')
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchEntityTranslations: (
    entity: TranslatableEntity,
    id: string,
    property: TranslatableEntityProperty
  ) => dispatch<any>(fetchEntityFieldTranslations(entity, id, property)),
  resetEntityTranslations: () => dispatch<any>(resetEntityFieldTranslations()),
  updateEntityTranslations: (
    entity: TranslatableEntity,
    id: string,
    property: TranslatableEntityProperty,
    translationSubmitData: EntityFieldTranslationSubmitData
  ) => dispatch<any>(updateEntityFieldTranslations(entity, id, property, translationSubmitData)),
});

const enhance = compose<Props, GenericTranslateDialogOwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles, { withTheme: true }),
);

export default enhance(GenericTranslateDialog);

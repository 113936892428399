import * as React from 'react';
import { connect } from 'react-redux';
import { fetchPrimaryUnits } from '@/app/redux/orgTree';
import { ReducerState } from '@/app/redux/store';

const FetchPrimaryUnitsHOC = (PassedComponent: React.ComponentType) => {
  class FetchPrimaryUnits extends React.Component<any> {
    componentDidMount() {
      this.props.fetchPrimaryUnits();
    }

    render () {
      const { primaryUnits } = this.props;
      // no render if primary units are not loaded
      if (primaryUnits.length === 0) {
        return null;
      }
      return <PassedComponent {...this.props} />;
    }
  }

  const mapStateToProps = (state: ReducerState) => ({
    primaryUnits: state.orgTree.get('primaryUnits'),
  });

  return connect(
    mapStateToProps,
    {
      fetchPrimaryUnits,
    }
  )(FetchPrimaryUnits);
};

export default FetchPrimaryUnitsHOC;

import { Enum } from '@/app/redux/enums';
import translate from '@/app/utils/translate';
import { TemplateTag } from '../../types';

const GLOBAL: any = window;

export const roles = {
  EMPLOYEE: 'laEmployee',
  HR_STAFF: 'laHR',
  HR_ADMIN: 'laHRAdmin',
  HR_ADMIN_LIMITED: 'laHRAdminLimited',
  HR_DEV: 'laHRDev',
  HR_SALARY: 'laHRSalary',
  HR_PEOP: 'laHRPeople',
  HR_PEOP_DEV: 'laHRPeopleDev',
  HR_AUDITOR: 'laHRAuditor',
  RECRUITER: 'laRecruiter',
  MANAGER: 'laManager',
  ALT_MANAGER: 'laAltManager',
  DEPUTY_MANAGER: 'laDeputyManager',
  IT_ADMIN: 'laITAdmin',
};

export const reIndexAndResetDefault = function(arrayOfObjects: any) {
  return arrayOfObjects.map((obj: any, index: any) => {
    return { ...obj, index, default: index === 0 };
  });
};

export const nameDuplicationCheck = function(arrayOfObjects: any) {
  const labels = arrayOfObjects.map((item: any) => item.label);

  const valuesSoFar = {};
  for (let i = 0; i < labels.length; i++) {
    if (labels[i] in valuesSoFar) {
      return true;
    }

    valuesSoFar[labels[i]] = true;
  }

  return false;
};

export const getUnits = () => {
  if (!GLOBAL.jsonOrgUnitData) {
    return [];
  }

  return GLOBAL.jsonOrgUnitData.map((item: any) => ({
    id: item.fOrgUnitId,
    code: item.fOrgUnitNumber,
    name: item.fOrgUnitName,
  }));
};

export const getRoles = () => {
  return Object.keys(roles)
    .map(roleKey => ({
      id: roleKey,
      name: translate.t(roles[roleKey]),
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
};

export const getCompanyCountries = () => {
  if (
    !GLOBAL.jsonCompanyCountries ||
    !GLOBAL.jsonEnumALL ||
    !GLOBAL.jsonEnumALL.COUNTRY
  ) {
    return [];
  }

  return GLOBAL.jsonCompanyCountries
    .map((countryCode: string) => {
      const country = GLOBAL.jsonEnumALL.COUNTRY.find(
        (c: any) => c.code === countryCode,
      );
      return {
        code: countryCode,
        name: country.name || countryCode,
      };
    })
    .sort((a: any, b: any) => a.name.localeCompare(b.name));
};

export const getUnitName = (unitId: number) => {
  if (!GLOBAL.jsonOrgUnitData) {
    return '';
  }

  const unit = GLOBAL.jsonOrgUnitData.find(
    (item: any) => item.fOrgUnitId === unitId,
  );
  if (!unit) {
    return undefined;
  }

  return unit.fOrgUnitName;
};

export const getCountryName = (code: string): string => {
  if (!GLOBAL.jsonCountries) {
    return '';
  }

  const country = GLOBAL.jsonCountries.find((item: any) => item.code === code);
  if (!country) {
    return undefined;
  }

  return country.name;
};

export const getRoleName = (roleKey: string) => {
  if (roles[roleKey]) {
    return translate.t(roles[roleKey]);
  } else {
    return translate.t('laUnknown');
  }
};

export const mapTemplateTags = (
  currentTags: TemplateTag[],
  enumTags: Enum[],
) => {
  if (!currentTags || !currentTags.length || !enumTags || !enumTags.length) {
    return [];
  }

  return currentTags.map((tag: TemplateTag) => {
    const enumTag = enumTags.find((tt: Enum) => tt.code === tag.tag);

    return {
      label: enumTag ? enumTag.name : tag.tag,
      value: enumTag ? enumTag.code : tag.tag,
    };
  });
};

import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import translate from '@/app/utils/translate';
import {
  Grid,
  Icon,
  IconButton,
  Button,
  Checkbox,
  StyleRulesCallback,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { FormikProps, Field, ArrayHelpers } from 'formik';
import moment from 'moment';
import { v4 as uuid4 } from 'uuid';

import {
  CompanyTraining,
  APPROVAL_STATUS,
  COMPLETION_STATUS,
  SEAT_STATUS,
  ApprovalAction,
  HoursAction,
  CompletionAction,
  ExpiresAction,
  UpdateTrainingHistoryAction,
  CompanyTrainingParticipantRegistration,
  CompanyTrainingParticipantType,
} from '../../types';
import {
  getApprovalStatusName,
  getCompletionStatusName,
  getParticipantName,
} from '../../helpers';

import { FormData } from '../ParticipantsDialog';
import {
  AddToDRField,
  ApprovalStatusField,
  CompletionStatusField,
  ExpiresDateField,
  GuaranteedSeatField,
  HoursField,
  ParticipantAvatar,
  ParticipantDetailsPopover,
  ParticipantTableHead,
  RegisterExternalParticipantPopover,
  RegistrationMessagePopover,
  SearchEmployeeField,
  UpdateTrainingHistoryField,
} from './participantList/';

const styles: StyleRulesCallback = (theme: Theme) => ({
  selectionCell: {
    width: 0,
  },
  cellButton: {
    textTransform: 'none',
    boxShadow: theme.shadows[1],
    lineHeight: 1,
    fontWeight: 'normal',
  },
  employeeName: {
    paddingLeft: theme.spacing.unit,
  },
  grow: {
    flex: 1,
  },
  actionButton: {
    padding: theme.spacing.unit,
    marginLeft: theme.spacing.unit / 2,
  },
  headerActions: {
    display: 'flex',
  },
  searchEmployeeContainer: {
    flexGrow: 1,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 2,
    minWidth: 150,
    maxWidth: 450,
  },
  waitingRow: {
    backgroundColor: theme.palette.grey[200],
  },
  rejectedRow: {
    backgroundColor: theme.palette.grey[400],
  },
});

type OwnProps = {
  training: CompanyTraining;
  form: FormikProps<FormData>;
  arrayHelpers: ArrayHelpers;
  onReorder: () => void;
  onChangeApprovalStatuses: (action: ApprovalAction) => void;
  onChangeHours: (action: HoursAction, hours?: number) => void;
  onChangeCompletionStatuses: (action: CompletionAction) => void;
  onChangeExpirationDates: (
    action: ExpiresAction,
    expires?: moment.Moment,
  ) => void;
  onChangeUpdateTrainingHistoryMarks: (
    action: UpdateTrainingHistoryAction,
  ) => void;
  onChangeAddToDR: (
    addToDR: boolean,
    registration: CompanyTrainingParticipantRegistration,
  ) => void;
  onToggleParticipantRegistration: (registrationId: string) => void;
  onToggleAllParticipantRegistration: () => void;
  toggleParticipantUpdateTrainingHistory: (
    actualParticipantId: number,
    checkBoxValue: boolean,
  ) => void;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const GLOBAL: any = window;

const ParticipantList = ({
  classes,
  training,
  form: { values },
  arrayHelpers: { push, remove },
  onReorder,
  onChangeApprovalStatuses,
  onChangeHours,
  onChangeCompletionStatuses,
  onChangeExpirationDates,
  onChangeUpdateTrainingHistoryMarks,
  onChangeAddToDR,
  onToggleParticipantRegistration,
  onToggleAllParticipantRegistration,
  toggleParticipantUpdateTrainingHistory,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [participantDetailsAnchorEl, setParticipantDetailsAnchorEl] = useState(
    undefined,
  );
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [
    registrationMessageAnchorEl,
    setRegistrationMessageAnchorEl,
  ] = useState(undefined);
  const [
    registerExternalParticipantAnchorEl,
    setRegisterExternalParticipantAnchorEl,
  ] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchValue, setSearchValue] = useState(null);
  const [reorderNeeded, setReorderNeeded] = useState(false);
  const checkIfTrainingEnded = () =>
    training.endDate ? moment(new Date()).isBefore(training.endDate) : true;
  useEffect(() => {
    if (reorderNeeded) {
      onReorder();
      setReorderNeeded(false);
    }
  }, [reorderNeeded]);

  const handleAddParticipant = (
    participant: any,
    alreadyRegistered: boolean,
  ) => {
    if (alreadyRegistered) {
      enqueueSnackbar(translate.t('label_participant_already_on_list'), {
        variant: 'error',
      });
      return;
    }

    // Determine default approval status based on whether we require manager approval or not
    const defaultApprovalStatus = !training.requireManagerApproval
      ? {
          value: APPROVAL_STATUS.APPROVED,
          label: getApprovalStatusName(APPROVAL_STATUS.APPROVED),
        }
      : {
          value: APPROVAL_STATUS.WAITING,
          label: getApprovalStatusName(APPROVAL_STATUS.WAITING),
        };

    push({
      id: uuid4(),
      trainingId: training.id,
      participant,
      seated: false,
      position: 0,
      approvalStatus: {
        status: defaultApprovalStatus,
        statusBy: null,
        statusOn: null,
      },
      hours: training.hours,
      completionStatus: {
        value: COMPLETION_STATUS.PENDING,
        label: getCompletionStatusName(COMPLETION_STATUS.PENDING),
      },
      expires: training.expires,
      registeredTime: moment(),
    });

    enqueueSnackbar(translate.t('label_participant_added'), {
      variant: 'success',
    });
  };

  const handleAddInternalParticipant = (employeeId: number) => {
    handleAddParticipant(
      {
        employeeId,
        updateTrainingHistory: false,
        includeInTrainingCompensation: training.includeInTrainingCompensation,
        type: CompanyTrainingParticipantType.INTERNAL,
      },
      values.participants.some(
        registration =>
          registration.participant.type ===
            CompanyTrainingParticipantType.INTERNAL &&
          registration.participant.employeeId === employeeId,
      ),
    );

    setSearchTerm('');
    setSearchValue(null);
    setReorderNeeded(true);
  };

  const handleAddExternalParticipant = (participantDetails: any) => {
    handleAddParticipant(
      {
        ...participantDetails,
        type: CompanyTrainingParticipantType.EXTERNAL,
      },
      values.participants.some(
        registration =>
          registration.participant.type ===
            CompanyTrainingParticipantType.EXTERNAL &&
          registration.participant.email === participantDetails.email,
      ),
    );
  };

  const handleRemoveParticipant = (index: number) => {
    remove(index);
    setReorderNeeded(true);
    enqueueSnackbar(translate.t('label_participant_removed'), {
      variant: 'success',
    });
  };

  const participantDetailsPopover =
    !selectedParticipant || !participantDetailsAnchorEl ? null : (
      <ParticipantDetailsPopover
        participant={selectedParticipant}
        anchorEl={participantDetailsAnchorEl}
        onClose={() => {
          setParticipantDetailsAnchorEl(undefined);
          setSelectedParticipant(undefined);
        }}
      />
    );

  const registrationMessagePopover =
    !selectedMessage || !registrationMessageAnchorEl ? null : (
      <RegistrationMessagePopover
        message={selectedMessage}
        anchorEl={registrationMessageAnchorEl}
        onClose={() => {
          setRegistrationMessageAnchorEl(undefined);
          setSelectedMessage(undefined);
        }}
      />
    );

  const registerExternalParticipantPopover = !registerExternalParticipantAnchorEl ? null : (
    <RegisterExternalParticipantPopover
      anchorEl={registerExternalParticipantAnchorEl}
      onClose={() => {
        setRegisterExternalParticipantAnchorEl(undefined);
      }}
      onAdd={handleAddExternalParticipant}
    />
  );

  return (
    <>
      {participantDetailsPopover}
      {registrationMessagePopover}
      {registerExternalParticipantPopover}

      <div className={classes.headerActions}>
        <div className={classes.searchEmployeeContainer}>
          <SearchEmployeeField
            inputValue={searchTerm}
            value={searchValue}
            onInputChange={(value: string) => setSearchTerm(value)}
            onChange={handleAddInternalParticipant}
          />
        </div>

        {training.isPublic && (
          <Button
            className={classes.actionButton}
            onClick={e => {
              setRegisterExternalParticipantAnchorEl(e.target);
            }}
          >
            {translate.t('label_add_external_participant')}
          </Button>
        )}
      </div>

      <Table padding="dense">
        <ParticipantTableHead
          training={training}
          participants={values.participants}
          onChangeApproval={onChangeApprovalStatuses}
          onChangeHours={onChangeHours}
          onChangeCompletionStatus={onChangeCompletionStatuses}
          onChangeExpires={onChangeExpirationDates}
          onChangeUpdateHistory={onChangeUpdateTrainingHistoryMarks}
          onToggleAllParticipantRegistration={
            onToggleAllParticipantRegistration
          }
        />
        <TableBody>
          {values.participants.map((registration, index) => {
            const rowClassName =
              registration.seatStatus !== SEAT_STATUS.HAS_SEAT
                ? registration.seatStatus === SEAT_STATUS.NO_SEAT
                  ? classes.rejectedRow
                  : classes.waitingRow
                : '';

            return (
              <TableRow key={index} className={rowClassName}>
                <TableCell className={classes.selectionCell}>
                  {registration.participant.id && (
                    <Checkbox
                      checked={!!registration.selected}
                      onChange={() =>
                        onToggleParticipantRegistration(registration.id)
                      }
                      color="primary"
                    />
                  )}
                </TableCell>

                <TableCell>
                  <Grid container={true} alignItems="center" wrap="nowrap">
                    <Grid item={true}>
                      <ParticipantAvatar
                        participant={registration.participant}
                      />
                    </Grid>

                    <Grid item={true} className={classes.grow}>
                      <Typography
                        variant="body1"
                        className={classes.employeeName}
                        noWrap
                      >
                        {getParticipantName(registration.participant)}

                        <IconButton
                          className={classes.actionButton}
                          onClick={e => {
                            setParticipantDetailsAnchorEl(e.target);
                            setSelectedParticipant(registration.participant);
                          }}
                        >
                          <Icon>info_outlined</Icon>
                        </IconButton>

                        {registration.message && checkIfTrainingEnded() && (
                          <IconButton
                            className={classes.actionButton}
                            onClick={e => {
                              setRegistrationMessageAnchorEl(e.target);
                              setSelectedMessage(registration.message);
                            }}
                          >
                            <Icon>message_outlined</Icon>
                          </IconButton>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>

                <TableCell>
                  <Field
                    name={`participants.${index}.seated`}
                    component={GuaranteedSeatField}
                    approvalStatus={registration.approvalStatus.status.value}
                    position={registration.position}
                    trainingSeats={training.seats}
                    allowWaitingSeats={training.allowWaitingSeats}
                    onChange={() => setReorderNeeded(true)}
                  />
                </TableCell>

                <TableCell>
                  <Field
                    name={`participants.${index}.approvalStatus.status`}
                    component={ApprovalStatusField}
                    className={`${classes.cellButton} autoAdjustText`}
                    requireManagerApproval={training.requireManagerApproval}
                    employeeId={
                      registration.participant.type ===
                        CompanyTrainingParticipantType.INTERNAL &&
                      registration.participant.employeeId
                    }
                    onChange={() => setReorderNeeded(true)}
                  />
                </TableCell>

                <TableCell>
                  <Field
                    name={`participants.${index}.hours`}
                    component={HoursField}
                    approvalStatus={registration.approvalStatus.status.value}
                  />
                </TableCell>

                <TableCell>
                  <Field
                    name={`participants.${index}.completionStatus`}
                    component={CompletionStatusField}
                    className={`${classes.cellButton} autoAdjustText`}
                    approvalStatus={registration.approvalStatus.status.value}
                  />
                </TableCell>

                <TableCell>
                  <Field
                    name={`participants.${index}.expires`}
                    component={ExpiresDateField}
                    className={`${classes.cellButton} autoAdjustText`}
                    approvalStatus={registration.approvalStatus.status.value}
                    completionStatus={registration.completionStatus.value}
                    clearable={true}
                  />
                </TableCell>

                <TableCell>
                  <Field
                    name={`participants.${index}.updateTrainingHistory`}
                    component={UpdateTrainingHistoryField}
                    approvalStatus={registration.approvalStatus.status.value}
                    completionStatus={registration.completionStatus.value}
                    onCheckboxChange={toggleParticipantUpdateTrainingHistory}
                    participant={registration}
                  />
                </TableCell>

                {GLOBAL.bnEnableDecisionRegistry && (
                  <TableCell>
                    {registration.participant.type ===
                      CompanyTrainingParticipantType.INTERNAL && (
                      <Field
                        name={`participants.${index}.addToDr`}
                        component={AddToDRField}
                        registration={registration}
                        checked={registration.addToDr}
                        onChange={onChangeAddToDR}
                      />
                    )}
                  </TableCell>
                )}

                {training.includeInTrainingCompensation && (
                  <TableCell>
                    {registration.participant.type ===
                      CompanyTrainingParticipantType.INTERNAL && (
                      <Checkbox
                        checked={
                          !!registration.participant
                            .includeInTrainingCompensation
                        }
                        color="primary"
                        disabled
                      />
                    )}
                  </TableCell>
                )}

                <TableCell>
                  <Typography>
                    {registration.participant.lastEmailSubject}
                  </Typography>
                </TableCell>

                <TableCell>
                  <IconButton
                    data-testid={`btn-training-participant-remove-${index}`}
                    onClick={() => handleRemoveParticipant(index)}
                  >
                    <Icon>delete</Icon>
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default withStyles(styles)(ParticipantList);

import translate from '@/app/utils/translate';
import {
  WorkflowInfoCell,
  ProposalValueCell,
} from '@/app/components/WorkflowsReport/components/KendoCells';
import {
  commonEmployeeDetailColumnConfig,
  commonWorkflowDetailColumnConfig,
} from '@/app/components/WorkflowsReport/ReportTypes/Common';
import { ColumnConfig } from '@/old/utils/helper';

export const empDetailsGridColumns = (): ColumnConfig[] => {
  const columnConfigs = [
    {
      field: 'empStatus',
      show: true,
      title: translate.t('laEmploymentStatus'),
      width: 120,
    },
    {
      field: 'pg1',
      show: true,
      title: translate.t('fPersonnelGroup1'),
      width: 150,
    },
    {
      field: 'pg2',
      show: true,
      title: translate.t('fPersonnelGroup2'),
      width: 150,
    },
    {
      field: 'pg3',
      show: true,
      title: translate.t('fPersonnelGroup3'),
      width: 150,
    },
    {
      field: 'pg4',
      show: true,
      title: translate.t('fPersonnelGroup4'),
      width: 150,
    },
    {
      field: 'pg5',
      show: true,
      title: translate.t('fPersonnelGroup5'),
      width: 150,
    },
    {
      field: 'startDate',
      show: false,
      title: translate.t('laStartDate'),
      width: 120,
    },
    {
      field: 'endDate',
      show: false,
      title: translate.t('laEndDate'),
      width: 120,
    },
    {
      cell: WorkflowInfoCell,
      field: 'info',
      show: false,
      title: translate.t('laInfo'),
      groupable: false,
      width: 150,
    },
    {
      field: 'jobGrade',
      show: false,
      title: translate.t('laJobGrade'),
      groupable: false,
      width: 120,
    },
    {
      field: 'taxNumber',
      show: false,
      title: translate.t('laTaxNumber'),
      groupable: false,
      width: 150,
    },
    {
      field: 'vacBalCalcUseSixDays',
      show: false,
      title: translate.t('laVacBalCal'),
      groupable: false,
      width: 100,
    },
    {
      field: 'calcHoursBalance',
      show: false,
      title: translate.t('laCalcHoursBalance'),
      groupable: false,
      width: 100,
    },
  ].map(cc =>
    cc.hasOwnProperty('cell')
      ? cc
      : {
          cell: ProposalValueCell,
          ...cc,
        },
  );

  return [
    ...commonEmployeeDetailColumnConfig(),
    ...columnConfigs,
    ...commonWorkflowDetailColumnConfig(),
  ];
};

import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const textFormFieldDynamicListStyle: StyleRulesCallback = (theme: Theme) => ({
  btn: {
    marginTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit * 2
  },
  fullWidth: {
    width: '100%'
  },
  iconBtn: {
    position: 'absolute',
    top: theme.spacing.unit * 3,
    right: theme.spacing.unit * -4,
    padding: 4,
  },
  listItem: {
    position: 'relative',
  }
});

export default textFormFieldDynamicListStyle;

import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const selectStyle: StyleRulesCallback = (theme: Theme) => ({
  selectField: {
    color: theme.palette.primary.main,
    flexGrow: 1,
    minWidth: 125,
    textAlign: 'left',
  },
});

export default selectStyle;

import * as React from 'react';
import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BlockIcon from '@material-ui/icons/Block';
import EditIcon from '@material-ui/icons/Edit';
import DuplicateIcon from '@material-ui/icons/FileCopy';
import translate from '@/app/utils/translate';
import { Tools } from '@/app/components/EnhancedTable/EnhancedTableToolbar/EnhancedTableToolbar';
import TranslateIcon from '@material-ui/icons/Translate';
import { compose, withState } from 'recompose';

export type CustomRowButton = {
  label: string;
  iconButton: React.ReactNode;
  onClick: Function;
  hidden?: boolean;
};

interface PropsType {
  template: any;
  tools: Tools;
  changeTemplateStatus: any;
  deleteTemplate: any;
  duplicateTemplate: any;
  translateTemplate: any;
  customButtons?: CustomRowButton[] | undefined;
  hideTranslate?: boolean;
  handleEditTemplate: any;
}

interface InnerProps extends PropsType {
  anchorEl: HTMLElement;
  setAnchorEl: (target: EventTarget) => void;
}

const SideRowMenu: React.FunctionComponent<InnerProps> = (props) => {
  const handleOpenMenu = (event: React.MouseEvent) => {
    // Stop trigger the row checkbox selection
    event.stopPropagation();
    event.preventDefault();

    props.setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (event: React.MouseEvent) => {
    // Stop trigger the row checkbox selection
    event.stopPropagation();
    event.preventDefault();

    props.setAnchorEl(null);
  };

  const handleTemplateStatusChange = (event: any, newStatus: string) => {
    props.changeTemplateStatus([template.id], newStatus);
    handleCloseMenu(event);
  };

  const handleTemplateDeletion = (event: any) => {
    props.deleteTemplate([template.id]);
    handleCloseMenu(event);
  };

  const handleTemplateDuplication = (event: any) => {
    props.duplicateTemplate(template.id);
    handleCloseMenu(event);
  };

  const translateHandler = (event: any) => {
    props.translateTemplate(template.id);
    handleCloseMenu(event);
  };

  const { template, tools, anchorEl, customButtons, hideTranslate } = props;

  const {
    showEdit = true,
    showDelete = true,
    showActive = false,
    showTranslate = false,
    showDuplicate = false,
  } = tools;

  const openMenu = Boolean(anchorEl);

  const activateButtons = showActive
    ? (
      template.status === 'ACTIVE' ? (
        <MenuItem onClick={(event) => handleTemplateStatusChange(event, 'INACTIVE')}>
          <ListItemIcon>
              <BlockIcon color="primary" />
          </ListItemIcon>

          <ListItemText inset primary={translate.t('button_deactivate')} />
        </MenuItem>
      ) : (
        <MenuItem onClick={(event) => handleTemplateStatusChange(event, 'ACTIVE')}>
          <ListItemIcon>
              <CheckCircleIcon color="primary" />
          </ListItemIcon>

          <ListItemText inset primary={translate.t('laActivate')} />
        </MenuItem>
      )
    ) : null;

  const editButton = showEdit ? (
    <MenuItem
      onClick={(event) => {
         props.handleEditTemplate([template.id]);
         handleCloseMenu(event);
      }}
    >
      <ListItemIcon>
        <EditIcon color="primary" />
      </ListItemIcon>

      <ListItemText inset primary={translate.t('laEdit')} />
    </MenuItem>
  ) : null;

  const translateButton = showTranslate && !hideTranslate ? (
    <MenuItem onClick={translateHandler}>
      <ListItemIcon>
        <TranslateIcon color="primary" />
      </ListItemIcon>

      <ListItemText inset primary={translate.t('button_translate')} />
    </MenuItem>
  ) : null;

  const deleteButton = showDelete ? (
    <MenuItem onClick={(event) => handleTemplateDeletion(event)}>
      <ListItemIcon>
        <DeleteIcon color="primary" />
      </ListItemIcon>

      <ListItemText inset primary={translate.t('laDelete')} />
    </MenuItem>
  ) : null;

  const duplicateButton = showDuplicate ? (
    <MenuItem onClick={(event) => handleTemplateDuplication(event)}>
      <ListItemIcon>
        <DuplicateIcon color="primary" />
      </ListItemIcon>

      <ListItemText inset primary={translate.t('label_duplicate')} />
    </MenuItem>
  ) : null;

  if (
    !activateButtons &&
    !editButton &&
    !translateButton &&
    !deleteButton &&
    !duplicateButton &&
    (
        !customButtons ||
        !customButtons.length
    )
  ) {
    return null;
  }

  return (
    <>
      <IconButton
        aria-label={translate.t('laMore')}
        aria-owns={openMenu ? 'long-menu' : null}
        aria-haspopup="true"
        onClick={handleOpenMenu}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
      >
        {editButton}
        {deleteButton}
        {translateButton}
        {activateButtons}
        {duplicateButton}
      </Menu>
    </>
  );
};

const enhance = compose<InnerProps, PropsType>(
  withState('anchorEl', 'setAnchorEl', null)
);

export default enhance(SideRowMenu);

import * as React from 'react';
import { compose } from 'recompose';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import {
  FormLabel,
  FormControlLabel,
  Radio,
  withStyles,
} from '@material-ui/core';
import scale1To5Styles from './scale1To5Styles';

type PropsType = {
  label?: string;
  showLabel?: boolean;
  responseName: string;
  editMode: boolean;
  onChange: (props: number) => void;
  classes: any;
  value: number;
};

function Scale1To5(props: PropsType) {
  const { classes, label, showLabel, editMode, value, onChange } = props;
  const [selectedValue, setSelectedValue] = React.useState<number>(value || 0);

  function handleChange(event: any) {
    const v = parseInt(event.target.value, 10);
    setSelectedValue(v);
    onChange(v);
  }

  React.useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const renderFormRadioOption = (index: number) => {
    const getClassName = (radioIndex: number) => {
      switch (radioIndex) {
        case 1:
          return classes.formControlLabelFirst;
        case 5:
          return classes.formControlLabelLast;
        default:
          return classes.formControlLabel;
      }
    };

    return (
      <FormControlLabel
        key={`eval-opt-${index}`}
        value={index.toString()}
        control={
          <Radio
            checked={selectedValue === index}
            onChange={handleChange}
            value={index}
            name={`radio-button-demo-${index}`}
            color="primary"
            inputProps={{ 'aria-label': index.toString() }}
            icon={<RadioButtonUncheckedIcon fontSize="small" />}
            checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
            disabled={!editMode}
          />
        }
        label={showLabel ? index : ''}
        labelPlacement="top"
        className={getClassName(index)}
      />
    );
  };

  return (
    <div className={classes.evaluationOptions}>
      <FormLabel className={classes.evaluationLabel}>
        {showLabel ? label : ''}
      </FormLabel>

      {[1, 2, 3, 4, 5].map(i => renderFormRadioOption(i))}
    </div>
  );
}

const enhance = compose<any, any>(withStyles(scale1To5Styles));

export default enhance(Scale1To5);

import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
  WithStyles,
  Grid,
  Typography,
  FormHelperText,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import translate from '@/app/utils/translate';
import { INPUT_TYPE } from '@/app/utils/helper';
import FormFields, { InputType } from '@/app/components/FormFields/FormFields';
import questionFormDialogStyle from './questionFormDialogStyle';
import { throwError } from '@/app/redux/error';
import { getFormValues, isInvalid, isPristine, isSubmitting, reset, untouch } from 'redux-form';
import { SurveyQuestion, QuestionType } from '@/app/redux/surveys';
import { getForm } from '@/app/components/Form/Form';
import SortableInputsComponent, { SortableInputItem } from '../../SortableInputsComponent/SortableInputsComponent';
import { changeFormData, FormDataType } from '@/app/redux/formData';

const FORM_NAME = 'addEditSurveyQuestionForm';

type MapStateToProps = {
  currentValues: SurveyQuestion;
  invalid: (formName: string) => boolean;
  pristine: (formName: string) => boolean;
  submitting: (formName: string) => boolean;
};

type MapDispatchToProps = {
  throwError: (err: any) => void;
  resetForm: () => void;
  changeFormData: (payload: FormDataType) => void;
  untouch: (field: string) => void;
};

type OwnProps = {
  title: string;
  open: boolean;
  data?: SurveyQuestion;
  questionType?: QuestionType;
  onClose: () => void;
  onSubmit: (question: SurveyQuestion) => void;
};

type PropsType = OwnProps & WithStyles<typeof questionFormDialogStyle> & MapDispatchToProps & MapStateToProps;

const emptyForm = (questionType?: QuestionType) => {
  const typeLabel = questionType ? translate.t(`label_${questionType}`) : '';

  switch (questionType) {
    case QuestionType.YES_NO:
      return {
        text: '',
        type: questionType,
        order: 1,
        index: 1,
        yesLabel: '',
        noLabel: '',
        typeLabel,
      };
    case QuestionType.SINGLE_CHOICE:
    case QuestionType.MULTIPLE_CHOICE:
      return {
        text: '',
        type: questionType,
        order: 1,
        index: 1,
        options: [] as SortableInputItem[],
        typeLabel,
      };
    case QuestionType.FREE_TEXT:
    case QuestionType.SCALE:
    default:
      return {
        text: '',
        type: questionType,
        order: 1,
        index: 1,
        typeLabel,
      };
  }
};

type StateType = {
  formData: SurveyQuestion;
  list: SortableInputItem[];
  sortOpen: boolean;
  value: string;
};

class QuestionFormDialog extends React.Component<PropsType> {
  state: StateType = {
    formData: emptyForm(),
    list: [],
    sortOpen: false,
    value: '',
  };

  validate = (values: SurveyQuestion) => {
    let errors: {[k: string]: any} = {};
    const requiredFields = [
      'text',
      'yesLabel',
      'noLabel'
    ];

    requiredFields.forEach(field => {
      if (!values[field] || values[field] === '<p>&nbsp;</p>') {
        errors[field] = translate.t('laThisRequired');
      }
    });

    if (values.text && values.text.length > 180) {
      errors.text = `${translate.t('laErrorMaxTextLen1')} 180`;
    }

    return errors;
  };

  loadQuestion = (q: SurveyQuestion) => {
    const { id, text, order, type, yesLabel, noLabel, options } = q;
    const formData = Object.assign(
      type === QuestionType.MULTIPLE_CHOICE || type === QuestionType.SINGLE_CHOICE ?
      { options } : {},
      type === QuestionType.YES_NO ?
      {
        yesLabel,
        noLabel,
      } : {},
      id ? { id } : {},
      {
        text,
        order,
        type,
      }
    );

    this.setState(
      {
        formData,
        list: q.options,
        value: this.getRadioValue(q.type),
      },
      () => this.setFormValues()
    );
  };

  componentDidUpdate(prevProps: PropsType) {
    const { open, data, questionType } = this.props;

    if (open && prevProps.questionType !== questionType && !data) {
      this.setNewEmptyForm(questionType);
    }

    if (open && prevProps.data !== data) {
      this.loadQuestion(data);
    }
  }

  getRadioValue = (qType: QuestionType): string => (
    qType === QuestionType.SINGLE_CHOICE || qType === QuestionType.MULTIPLE_CHOICE ?
      qType : ''
  );

  setNewEmptyForm = (questionType: QuestionType) => {
    this.setState(
      {
        formData: emptyForm(questionType),
        value: this.getRadioValue(questionType),
      },
      () => {
        this.props.untouch('text');
        if (questionType === QuestionType.YES_NO) {
          this.props.untouch('yesLabel');
          this.props.untouch('noLabel');
        }
      }
    );
  };

  setFormValues = () => {
    const { formData } = this.state;

    Object.keys(formData).forEach(field => {
      this.props.changeFormData({form: FORM_NAME, field, value: formData[field]});
    });
  };

  setEmptyState = () => {
    this.setState({
      formData: emptyForm(),
      list: [],
      value: '',
    });
  };

  handleClose = () => {
    const { onClose, resetForm } = this.props;

    onClose();
    this.setEmptyState();
    resetForm();
  };

  trimEmptyInputs = (list: SortableInputItem[]) => (
    list && list.length > 0 ?
      list.filter((item: SortableInputItem) => item.value && item.value !==  '') :
      null
  );

  handleSubmit = () => {
    const { currentValues, onSubmit, resetForm } = this.props;
    const typeLabel = currentValues.typeLabel ?
      currentValues.typeLabel :
      translate.t(`label_${currentValues.type}`);

    const question = {...currentValues, typeLabel};
    const options = this.trimEmptyInputs(currentValues.options);

    if (options) {
      question.options = options;
    }

    onSubmit(question);
    this.setEmptyState();
    resetForm();
  };

  handleListUpdate = (list: SortableInputItem[]) => {
    const { currentValues } = this.props;

    this.setState(
      {
        list,
        isDisabledSave: false,
        formData: {
          ...currentValues,
          options: [...list],
        },
      },
      () => this.setFormValues()
    );
  };

  renderSortableOptions = () => (
    <SortableInputsComponent
      showDragHandle
      list={this.state.list}
      updatedList={this.handleListUpdate}
      title={translate.t('title_choices')}
      label={translate.t('label_choice')}
    />
  );

  getHelpText = (qType: QuestionType) => {
    if (qType === QuestionType.SCALE) {
      return (
        <FormHelperText>
          <Typography variant="caption" gutterBottom>
            {translate.t('text_scale_question')}
          </Typography>
          <Typography variant="caption" gutterBottom>
            {translate.t('text_scale_question_options')}
          </Typography>
        </FormHelperText>
      );
    }

    if (qType === QuestionType.FREE_TEXT) {
      return (
        <FormHelperText>
          <Typography variant="caption" gutterBottom>
            {translate.t('text_free_text_question')}
          </Typography>
        </FormHelperText>
      );
    }

    return <></>;
  };

  renderInputs = (questionType: QuestionType) => {
    const inputs: InputType[] = [{
      type: INPUT_TYPE.TEXT,
      code: 'text',
      name: 'text',
      label: translate.t('label_question'),
      order: 1,
      required: true,
      helpText: this.getHelpText(questionType),
    }];

    if (questionType === QuestionType.YES_NO) {
      inputs.push({
        type: INPUT_TYPE.TEXT,
        code: 'yesLabel',
        name: 'yesLabel',
        label: translate.t('label_yes_label'),
        order: 2,
        required: true,
      });
      inputs.push({
        type: INPUT_TYPE.TEXT,
        code: 'noLabel',
        name: 'noLabel',
        label: translate.t('label_no_label'),
        order: 3,
        required: true,
      });
    }

    return inputs.map(inpt => (
      <Grid item xs={12} sm={inpt.order === 1 ? 12 : 5} key={inpt.order}>
        <FormFields inputs={[inpt]} />
      </Grid>
    ));
  };

  handleChange = (event: any) => {
    const { value } = event.target;
    this.setState({ value });
    this.props.changeFormData({form: FORM_NAME, field: 'type', value });
  };

  Form = getForm(FORM_NAME, this.validate);

  render() {
    const {
      title, open, classes, questionType, submitting, pristine, invalid, data,
    } = this.props;
    const { formData, value } = this.state;
    const { type, options } = formData;
    const { Form } = this;

    const disabled = invalid(FORM_NAME) || pristine(FORM_NAME) || submitting(FORM_NAME) ||
      ((type === QuestionType.SINGLE_CHOICE || type === QuestionType.MULTIPLE_CHOICE) &&
        !!(options.length === 0 || options.length === 1 && options[0] && !options[0].value ||
        options.length > 2 && options.every(o => o.value === '') || options.length < 2)
      );

    return (
      <Dialog
        open={open}
        aria-labelledby="question-form-dialog"
        disableBackdropClick
        onEscapeKeyDown={this.handleClose}
        color="primary"
        maxWidth="md"
        classes={{ paperWidthMd: classes.paperWidthMd }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Form formData={formData}>
            <Grid container className={classes.root} justify="space-between">
              {this.renderInputs(questionType)}

              {formData.type === QuestionType.SINGLE_CHOICE || formData.type === QuestionType.MULTIPLE_CHOICE ? (
                <>
                  <FormControl className={classes.formControl} fullWidth>
                    <FormLabel className={classes.formControlLabel}>
                      {translate.t('label_allow_user_select')}
                    </FormLabel>
                    <RadioGroup
                      aria-label={'allow user to select'}
                      name="type"
                      className={classes.group}
                      value={value}
                      onChange={this.handleChange}
                    >
                      <FormControlLabel
                        value={QuestionType.SINGLE_CHOICE}
                        control={<Radio color="primary" className={classes.radio} />}
                        label={translate.t('label_SINGLE_CHOICE')}
                      />
                      <FormControlLabel
                        value={QuestionType.MULTIPLE_CHOICE}
                        control={<Radio color="primary" className={classes.radio} />}
                        label={translate.t('label_MULTIPLE_CHOICE')}
                      />
                    </RadioGroup>
                  </FormControl>

                  <Grid container item xs={12} direction="column">
                    {this.renderSortableOptions()}
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Form>
        </DialogContent>
        <DialogActions>
          <Button variant="text" component="a" color="primary" onClick={this.handleClose}>
            {translate.t('laCancel')}
          </Button>
          <Button variant="contained" color="primary" disabled={disabled} onClick={this.handleSubmit}>
            {!!data ? translate.t('laSave') : translate.t('laAdd')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    currentValues: getFormValues(FORM_NAME)(state),
    invalid: (formName: string) => isInvalid(formName)(state),
    pristine: (formName: string) => isPristine(formName)(state),
    submitting: (formName: string) => isSubmitting(formName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  throwError: (err: any) => dispatch(throwError(err)),
  resetForm: () => dispatch(reset(FORM_NAME)),
  changeFormData: (payload: FormDataType) => changeFormData(dispatch, payload),
  untouch: (field: string) => dispatch(untouch(FORM_NAME, field)),
});

const enhance = compose<any, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(questionFormDialogStyle, {withTheme: true}),
);

export default enhance(QuestionFormDialog);

import * as React from 'react';

import { Ripple } from '@progress/kendo-react-ripple';
import { process } from '@progress/kendo-data-query';

type DataState = {
  skip?: number;
  take?: number;
  pageSize?: number;
  filter?: any;
  group?: any[];
  sort?: any[];
};

type StateType = {
  dataState: DataState;
  result: any;
};

export const withDataState = (WrappedGrid: any) => {
  return class StatefulGrid extends React.Component<any, StateType> {
    constructor (props: any) {
      super(props);
      const origState: any = {};
      if (!!props.pageable) {
        origState.skip = 0;
        origState.take = 20;
        origState.pageSize = 20;
      }
      if (!!props.filter) {
        origState.filter = props.filter;
      }

      if (!!props.group) {
        origState.group = props.group.slice();
      }

      if (!!props.sort) {
        origState.sort = props.sort.slice();
      }

      this.state = this.createAppState(origState);
    }

    onExpand = (event: any) => {
      event.dataItem[event.target.props.expandField] = event.value;
      this.setState({
        result: Object.assign({}, this.state.result),
        dataState: this.state.dataState
      });
    }

    createAppState(dataState: DataState) {
      return {
        result: process(this.props.data, dataState),
        dataState: dataState
      };
    }

    componentDidUpdate(prevProps: any) {
      if (prevProps.filter !== this.props.filter) {
        const dataState = Object.assign({}, this.state.dataState, {
          filter: this.props.filter
        });

        this.setState(this.createAppState(dataState));
      }
    }

    render() {
      return (
        <Ripple>
          <WrappedGrid
            filterable={false}
            sortable={true}
            pageable={{ pageSizes: true }}

            {...this.props}
            {...this.state.dataState}

            data={this.state.result}

            onDataStateChange={(e: any) => { this.setState(this.createAppState(e.data)); }}
            onExpandChange={this.onExpand}
          />
        </Ripple>
      );
    }
  };
};
import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const bonusCalculationRulesStyle: StyleRulesCallback = (theme: Theme) => {
  return ({
    root: {
      padding: theme.spacing.unit * 2,
    },
    progressContainer: {
      textAlign: 'center',
    },
  });
};

export default bonusCalculationRulesStyle;

import * as React from 'react';
import { ResponsiveBar, BarDatum, BarExtendedDatum } from '@nivo/bar';
import {
  Grid,
  WithStyles,
  withStyles,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';
import { compose } from 'recompose';
import likertChartStyle from './likertChartStyle';

export type LikertData = {
  question: string;
  c1: number;
  c1Color: string;
  c2: number;
  c2Color: string;
  c3: number;
  c3Color: string;
  c4: number;
  c4Color: string;
  c5: number;
  c5Color: string;
};

export type LikertLegendData = {
  color: string;
  label: string;
};

type OwnProps = {
  data: LikertData[];
  legendData: LikertLegendData[];
  borderColor?: string;
  title: string;
  total?: number;
};

type PropsType = OwnProps & WithStyles<typeof likertChartStyle>;

const LikertChart: React.FunctionComponent<PropsType> = (props) => {
  const { data, classes, title, legendData, total } = props;

  const getSectionColor = (e: BarDatum) => e.data[`${e.id}Color`];

  const getLabelFormat = (v: any) =>
    `${((v * 100) / total).toFixed(1).toString()}%`;

  const ColorBox = ({ label, color }: { label: string; color: string }) => {
    const colorBoxStyle = {
      background: `${color}`,
      height: '18px',
      marginRight: '8px',
      minWidth: '18px',
      width: '18px',
    };

    return (
      <Grid
        item
        style={{ display: 'flex', alignItems: 'center', fontSize: '11px' }}
      >
        <span style={colorBoxStyle} /> {label}
      </Grid>
    );
  };

  const getCustomTooltip = (
    d: BarExtendedDatum,
    _e: React.MouseEvent<HTMLCanvasElement>,
  ) => (
    <ColorBox label={`${d.data[`${d.id}Label`]}: ${d.value}`} color={d.color} />
  );

  const renderLegend = (lData: LikertLegendData[]) =>
    lData.map((d) => (
      <ColorBox label={d.label} color={d.color} key={d.color} />
    ));

  return (
    <Card className={classes.likertChartCard}>
      <CardHeader
        className={classes.likertChartHeader}
        title={title}
        classes={{
          title: classes.likertChartCardTitle,
        }}
      />
      <CardContent className={classes.likertChartCardContent}>
        <Grid container className={classes.likertChartContainer}>
          <ResponsiveBar
            data={data}
            keys={['c1', 'c2', 'c3', 'c4', 'c5']}
            indexBy="question"
            margin={{
              top: 0,
              right: 10,
              bottom: 60,
              left: 10,
            }}
            padding={0.5}
            innerPadding={1}
            layout="horizontal"
            colors={(e) => getSectionColor(e)}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            enableGridY={false}
            tooltip={(d, e) => getCustomTooltip(d, e)}
            labelSkipWidth={15}
            labelSkipHeight={22}
            labelTextColor="#000"
            labelFormat={(value: any) => getLabelFormat(value)}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            legends={[]}
          />
          <Grid
            container
            item
            className={classes.likertLegend}
            direction="row"
            justify="space-between"
            wrap="wrap"
            spacing={8}
          >
            {renderLegend(legendData)}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const enhance = compose<PropsType, OwnProps>(withStyles(likertChartStyle));

export default enhance(LikertChart);

import { Record } from 'immutable';
import { Dispatch } from 'redux';
import { THROW_ERROR } from './error';
import { OPEN_NOTIFIER } from '@/app/redux/notifier';
import translate from '@/app/utils/translate';
import API from '@/app/api/internalAPIs';
import { getCurrentLanguage, sortArr } from '@/app/utils/helper';
import Service from '@/app/utils/service';

const initialState = Record({
    allCommonGoals: {},
    allGoalTemplates: [],
});

const FETCH_ALL_COMMON_GOALS = '@@solaforce/goal/FETCH_ALL_COMMON_GOALS';
const FETCH_ALL_TEMPLATES = '@@solaforce/goal/FETCH_ALL_TEMPLATES';
const ADD_TEMPLATE = '@@solaforce/goal/ADD_TEMPLATE';
const UPDATE_TEMPLATE = '@@solaforce/goal/UPDATE_TEMPLATE';
const DELETE_TEMPLATES = '@@solaforce/goal/DELETE_TEMPLATES';
const CHANGE_TEMPLATES_STATUS = '@@solaforce/goal/CHANGE_TEMPLATES_STATUS';
const DUPLICATE_TEMPLATE = '@@solaforce/goal/DUPLICATE_TEMPLATE';
const ADD_TEMPLATE_SECTION = '@@solaforce/goal/ADD_TEMPLATE_SECTION';
const UPDATE_TEMPLATE_SECTION = '@@solaforce/goal/UPDATE_TEMPLATE_SECTION';
const UPDATE_TEMPLATE_SECTIONS = '@@solaforce/goal/UPDATE_TEMPLATE_SECTIONS';
const DELETE_TEMPLATE_SECTION = '@@solaforce/goal/DELETE_TEMPLATE_SECTION';
const ADD_SECTION_COMPONENT = '@@solaforce/goal/ADD_SECTION_COMPONENT';
const UPDATE_SECTION_COMPONENT = '@@solaforce/goal/UPDATE_SECTION_COMPONENT';
const DELETE_SECTION_COMPONENT = '@@solaforce/goal/DELETE_SECTION_COMPONENT';
const ORDER_SECTION_COMPONENTS = '@@solaforce/goal/ORDER_SECTION_COMPONENTS';

const reducer = (state = new initialState(), action: any) => {
    switch (action.type) {
        case FETCH_ALL_COMMON_GOALS: {
            const { allCommonGoals} = action.payload;
            return state.set('allCommonGoals', allCommonGoals.reduce(
                (commonGoalsHash: {}, commonGoal: any) => {
                    return {
                        ...commonGoalsHash,
                        [commonGoal.subgroupName]: {
                            ...commonGoal,
                            subgroupValues: commonGoal.subgroupValues.reduce(
                                (successMeasuresHash: {}, successMeasure: any) => {
                                    return {
                                        ...successMeasuresHash,
                                        [successMeasure.code]: successMeasure,
                                    };
                                },
                                {}
                            ),
                        },
                    };
                },
                {},
            ));
        }

        case FETCH_ALL_TEMPLATES: {
            const { allGoalTemplates} = action.payload;
            return state.set('allGoalTemplates', allGoalTemplates.map((template: any) => {
                return {
                    ...template,
                    sections: sortArr(
                        template.sections.map((section: any) => {
                            const allComponents = section.components.concat(section.appraisalComponents);
                            return { ...section, components: sortArr(allComponents, 'index') };
                        }),
                        'index',
                    ),
                };
            }));
        }

        case ADD_TEMPLATE: {
            const { template } = action.payload;
            return state.set('allGoalTemplates', [
                ...state.allGoalTemplates,
                { ...template, sections: [] }
            ]);
        }

        case UPDATE_TEMPLATE: {
            const { updatedTemplate } = action.payload;

            return state.set('allGoalTemplates', state.get('allGoalTemplates').map((template: any) => {
                if (updatedTemplate.id === template.id) {
                    return {
                        ...template,
                        ...updatedTemplate,
                    };
                }

                return template;
            }));
        }

        case DELETE_TEMPLATES: {
            const { templatesToDelete } = action.payload;

            return state.set('allGoalTemplates', state.get('allGoalTemplates').filter((template: any) => {
                return !templatesToDelete.includes(template.id);
            }));
        }

        case CHANGE_TEMPLATES_STATUS: {
            const { updatedTemplates } = action.payload;

            return state.set('allGoalTemplates', state.get('allGoalTemplates').map((template: any) => {
                const updatedTemplate = updatedTemplates.find(({ id }: any) => {
                    return template.id === id;
                });

                if (updatedTemplate) {
                    return {
                        ...template,
                        ...updatedTemplate,
                    };
                }

                return template;
            }));
        }

        case DUPLICATE_TEMPLATE: {
            const { template } = action.payload;
            return state.set('allGoalTemplates', [
                ...state.allGoalTemplates,
                template,
            ]);
        }

        case ADD_TEMPLATE_SECTION: {
            const { templateId, section } = action.payload;

            return state.set('allGoalTemplates', state.get('allGoalTemplates').map((template: any) => {
                if (templateId === template.id) {
                    return {
                        ...template,
                        sections: [
                            ...template.sections,
                            { ...section, components: [] },
                        ],
                    };
                }

                return template;
            }));
        }

        case UPDATE_TEMPLATE_SECTION: {
            const { templateId, updatedSection } = action.payload;

            return state.set('allGoalTemplates', state.get('allGoalTemplates').map((template: any) => {
                if (templateId === template.id) {
                    return {
                        ...template,
                        sections: sortArr(
                            template.sections.map((section: any) => {
                                if (updatedSection.id === section.id) {
                                    return {
                                        ...section,
                                        ...updatedSection,
                                    };
                                }

                                return section;
                            }),
                            'index',
                        ),
                    };
                }

                return template;
            }));
        }

        case UPDATE_TEMPLATE_SECTIONS: {
            const { templateId, updatedSections } = action.payload;

            return state.set('allGoalTemplates', state.get('allGoalTemplates').map((template: any) => {
                if (templateId === template.id) {
                    return {
                        ...template,
                        sections: sortArr(
                            template.sections.map((section: any) => {
                                const updatedSection = updatedSections.find(({ id }: any) => {
                                    return section.id === id;
                                });

                                if (updatedSection) {
                                    return {
                                        ...section,
                                        ...updatedSection,
                                    };
                                }

                                return section;
                            }),
                            'index',
                        ),
                    };
                }

                return template;
            }));
        }

        case DELETE_TEMPLATE_SECTION: {
            const { templateId, sectionToDelete } = action.payload;

            return state.set('allGoalTemplates', state.get('allGoalTemplates').map((template: any) => {
                if (templateId === template.id) {
                    return {
                        ...template,
                        sections: template.sections.filter((section: any) => {
                            return sectionToDelete !== section.id;
                        }),
                    };
                }

                return template;
            }));
        }

        case ADD_SECTION_COMPONENT: {
            const { templateId, sectionId, component } = action.payload;

            return state.set('allGoalTemplates', state.get('allGoalTemplates').map((template: any) => {
                if (templateId === template.id) {
                    return {
                        ...template,
                        sections: template.sections.map((section: any) => {
                            if (sectionId === section.id) {
                                return {
                                    ...section,
                                    components: [
                                        ...section.components,
                                        component,
                                    ],
                                };
                            }

                            return section;
                        }),
                    };
                }

                return template;
            }));
        }

        case UPDATE_SECTION_COMPONENT: {
            const { templateId, sectionId, updatedComponent } = action.payload;

            return state.set('allGoalTemplates', state.get('allGoalTemplates').map((template: any) => {
                if (templateId === template.id) {
                    return {
                        ...template,
                        sections: template.sections.map((section: any) => {
                            if (sectionId === section.id) {
                                return {
                                    ...section,
                                    components: sortArr(
                                        section.components.map((component: any) => {
                                            if (updatedComponent.id === component.id) {
                                                return {
                                                    ...component,
                                                    ...updatedComponent,
                                                };
                                            }

                                            return component;
                                        }),
                                        'index',
                                    )
                                };
                            }

                            return section;
                        }),
                    };
                }

                return template;
            }));
        }

        case DELETE_SECTION_COMPONENT: {
            const { templateId, sectionId, componentsToDelete } = action.payload;

            return state.set('allGoalTemplates', state.get('allGoalTemplates').map((template: any) => {
                if (templateId === template.id) {
                    return {
                        ...template,
                        sections: template.sections.map((section: any) => {
                            if (sectionId === section.id) {
                                return {
                                    ...section,
                                    components: section.components.filter((component: any) => {
                                        return !componentsToDelete.includes(component.id);
                                    }),
                                };
                            }

                            return section;
                        }),
                    };
                }

                return template;
            }));
        }

        case ORDER_SECTION_COMPONENTS: {
            const { templateId, sectionId, orderedComponents } = action.payload;

            return state.set('allGoalTemplates', state.get('allGoalTemplates').map((template: any) => {
                if (templateId === template.id) {
                    return {
                        ...template,
                        sections: template.sections.map((section: any) => {
                            if (sectionId === section.id) {
                                return {
                                    ...section,
                                    components: sortArr(
                                        section.components.map((component: any) => {
                                            const updatedComponent = orderedComponents.find(({ id }: any) => {
                                                return component.id === id;
                                            });

                                            if (updatedComponent) {
                                                return {
                                                    ...component,
                                                    ...updatedComponent,
                                                };
                                            }

                                            return component;
                                        }),
                                        'index',
                                    )
                                };
                            }

                            return section;
                        }),
                    };
                }

                return template;
            }));
        }

        default:
            return state;
    }
};

// >>>>>>>>>>>>>>>>>>>>>>> COMMON GOALS  <<<<<<<<<<<<<<<<<<<<<<<<
export const fetchAllCommonGoals = () => {
    return (dispatch: Dispatch) => {
        return Service.get(
            API.goalTemplates.getAllCommonGoals(),
            (response: any) => {
                dispatch({ type: FETCH_ALL_COMMON_GOALS, payload: {
                    allCommonGoals: response,
                }});
            },
            (error: any) => dispatch({ type: THROW_ERROR, error })
        );
    };
};

// >>>>>>>>>>>>>>>>>>>>>>>>> TEMPLATES  <<<<<<<<<<<<<<<<<<<<<<<<<
export const fetchAllTemplates = () => {
    return (dispatch: Dispatch) => {
        return Service.get(
            API.goalTemplates.getAllTemplates(),
            (response: any) => {
                dispatch({ type: FETCH_ALL_TEMPLATES, payload: {
                    allGoalTemplates: response.data,
                }});
            },
            (error: any) => dispatch({ type: THROW_ERROR, error })
        );
    };
};

export const fetchAllTemplatesForUser = (userId: string) => {
  return (dispatch: Dispatch) => {
      return Service.get(
          API.goalTemplates.getAllFilteredTemplates(userId),
          (response: any) => {
              dispatch({ type: FETCH_ALL_TEMPLATES, payload: {
                  allGoalTemplates: response.data,
              }});
          },
          (error: any) => dispatch({ type: THROW_ERROR, error })
      );
  };
};

export const addTemplate = (template: any[]) => {
    return (dispatch: Dispatch) => {
        return Service.post(
            API.goalTemplates.addTemplate(),
            template,
            (response: any) => {
                dispatch({ type: ADD_TEMPLATE, payload: {
                    template: response,
                }});

                dispatch({ type: OPEN_NOTIFIER, payload: { message: translate.t('new_template_added')}});
            },
            (error: any) => dispatch({ type: THROW_ERROR, error })
        );
    };
};

export const updateTemplate = (templateId: string, template: {}) => {
    return (dispatch: Dispatch) => {
        return Service.post(
            API.goalTemplates.updateTemplate(templateId),
            template,
            (response: any) => {
                dispatch({ type: UPDATE_TEMPLATE, payload: {
                    updatedTemplate: response,
                }});

                dispatch({ type: OPEN_NOTIFIER, payload: {}});
            },
            (error: any) => dispatch({ type: THROW_ERROR, error })
        );
    };
};

export const deleteTemplate = (templateIds: []) => {
    return (dispatch: Dispatch) => {
        return Promise.all(
            templateIds.map((templateId) => {
                return new Promise((resolve, reject) => {
                    Service.delete(
                        API.goalTemplates.removeTemplates(templateId),
                        undefined,
                        resolve,
                        reject,
                    );
                });
            })
        ).then(() => {
            dispatch({ type: DELETE_TEMPLATES, payload: {
                templatesToDelete: templateIds,
            }});

            dispatch({ type: OPEN_NOTIFIER, payload: { message: translate.t('template_deleted') }});
        }).catch((error: any) => {
            dispatch({ type: THROW_ERROR, error });
        });
    };
};

export const changeTemplatesStatus = (templateIds: [], newStatus: string) => {
    return (dispatch: Dispatch) => {
        return Service.post(
            newStatus === 'ACTIVE' ?
                API.goalTemplates.activateTemplates() :
                API.goalTemplates.deactivateTemplates(),
            { ids: templateIds },
            (response: any) => {
                dispatch({ type: CHANGE_TEMPLATES_STATUS, payload: { updatedTemplates: response.data } });
                dispatch({ type: OPEN_NOTIFIER, payload: { message: translate.t('template_status_updated')}});
            },
            (error: any) => dispatch({ type: THROW_ERROR, error })
        );
    };
};

export const translateTemplate = (language: string, templateId: string, translation: any) => {
    return (dispatch: Dispatch) => {
        return Service.post(
            API.goalTemplates.translateTemplate(templateId),
            { language, ...translation },
            (response: any) => {
                if (language === getCurrentLanguage()) {
                    dispatch({ type: UPDATE_TEMPLATE, payload: {
                        updatedTemplate: response,
                    }});
                }

                dispatch({ type: OPEN_NOTIFIER, payload: {}});
            },
            (error: any) => dispatch({ type: THROW_ERROR, error })
        );
    };
};

export const duplicateTemplate = (templateId: string) => {
    return (dispatch: Dispatch) => {
        return Service.post(
            API.goalTemplates.duplicateTemplate(templateId),
            undefined,
            (response: any) => {
                dispatch({ type: DUPLICATE_TEMPLATE, payload: { template: response }});
                dispatch({ type: OPEN_NOTIFIER, payload: { message: translate.t('template_duplicated')}});
            },
            (error: any) => dispatch({ type: THROW_ERROR, error })
        );
    };
};

// >>>>>>>>>>>>>>>>>>>>>>>>> SECTIONS  <<<<<<<<<<<<<<<<<<<<<<<<<
export const addTemplateSection = (templateId: string, section: {}) => {
    return (dispatch: Dispatch) => {
        return Service.post(
            API.goalTemplates.addSection(templateId),
            section,
            (response: any) => {
                dispatch({ type: ADD_TEMPLATE_SECTION, payload: { templateId, section: response }});
                dispatch({ type: OPEN_NOTIFIER, payload: { message: translate.t('new_section_added')}});
            },
            (error: any) => dispatch({ type: THROW_ERROR, error })
        );
    };
};

export const updateTemplateSection = (templateId: any, section: any) => {
    return (dispatch: Dispatch) => {
        return Service.post(
            API.goalTemplates.updateSection(section.id),
            {
                name: section.name,
                empAccess: section.empAccess,
                onlyEmpAccess: section.onlyEmpAccess,
                index: section.index
            },
            (response: any) => {
                dispatch({ type: UPDATE_TEMPLATE_SECTION, payload: { templateId, updatedSection: response }});
                dispatch({ type: OPEN_NOTIFIER, payload: { message: translate.t('sectionUpdated')}});
            },
            (error: any) => dispatch({ type: THROW_ERROR, error })
        );
    };
};

export const updateTemplateSections = (templateId: any, sections: any[]) => {
    return (dispatch: Dispatch) => {
        return Promise.all(
            sections.map((section) => {
                return new Promise((resolve, reject) => {
                    Service.post(
                        API.goalTemplates.updateSection(section.id),
                        {
                            name: section.name,
                            empAccess: section.empAccess,
                            onlyEmpAccess: section.onlyEmpAccess,
                            index: section.index
                        },
                        resolve,
                        reject,
                    );
                });
            })
        ).then((response: any[]) => {
            dispatch({ type: UPDATE_TEMPLATE_SECTIONS, payload: { templateId, updatedSections: response }});
            dispatch({ type: OPEN_NOTIFIER, payload: { message: translate.t('sectionUpdated')}});
        }).catch((error: any) => {
            dispatch({ type: THROW_ERROR, error });
        });
    };
};

export const deleteTemplateSection = (templateId: string, sectionId: string ) => {
    return (dispatch: Dispatch) => {
        return Service.delete(
            API.goalTemplates.deleteSection(sectionId),
            undefined,
            () => {
                dispatch({ type: DELETE_TEMPLATE_SECTION, payload: { templateId, sectionToDelete: sectionId } });
                dispatch({ type: OPEN_NOTIFIER, payload: { message: translate.t('section_deleted')}});
            },
            (error: any) => dispatch({ type: THROW_ERROR, error })
        );
    };
};

export const translateSection = (language: string, templateId: string, sectionId: string, translation: any) => {
    return (dispatch: Dispatch) => {
        return Service.post(
            API.goalTemplates.translateSection(sectionId),
            { language, ...translation },
            (response: any) => {
                if (language === getCurrentLanguage()) {
                    dispatch({ type: UPDATE_TEMPLATE_SECTION, payload: { templateId, updatedSection: response }});
                }

                dispatch({ type: OPEN_NOTIFIER, payload: { message: translate.t('sectionUpdated')}});
            },
            (error: any) => dispatch({ type: THROW_ERROR, error })
        );
    };
};

// >>>>>>>>>>>>>>>>>>>>>>>>> Components  <<<<<<<<<<<<<<<<<<<<<<<<<
export const addSectionComponent = (templateId: string, sectionId: string, component: {}) => {
    return (dispatch: Dispatch) => {
        return Service.post(
            API.goalTemplates.addComponent(sectionId),
            component,
            (response: any) => {
                dispatch({ type: ADD_SECTION_COMPONENT, payload: { templateId, sectionId, component: response }});
                dispatch({ type: OPEN_NOTIFIER, payload: { message: translate.t('new_component_added')}});
            },
            (error: any) => dispatch({ type: THROW_ERROR, error })
        );
    };
};

export const updateSectionComponent = (templateId: string, sectionId: string, component: any) => {
    return (dispatch: Dispatch) => {
        return Service.post(
            API.goalTemplates.updateComponent(component.componentId),
            component,
            (response: any) => {
                dispatch({ type: UPDATE_SECTION_COMPONENT, payload: { templateId, sectionId, updatedComponent: response } });
                dispatch({ type: OPEN_NOTIFIER, payload: { message: translate.t('componentUpdated')}});
            },
            (error: any) => dispatch({ type: THROW_ERROR, error })
        );
    };
};

export const deleteSectionComponent = (templateId: string, sectionId: string, componentIds: []) => {
    return (dispatch: Dispatch) => {
        return Promise.all(
            componentIds.map((componentId) => {
                return new Promise((resolve, reject) => {
                    Service.delete(
                        API.goalTemplates.deleteComponent(componentId),
                        undefined,
                        resolve,
                        reject,
                    );
                });
            })
        ).then(() => {
            dispatch({ type: DELETE_SECTION_COMPONENT, payload: {
                templateId,
                sectionId,
                componentsToDelete: componentIds,
            }});

            dispatch({ type: OPEN_NOTIFIER, payload: { message: translate.t('component_deleted') }});
        }).catch((error: any) => {
            dispatch({ type: THROW_ERROR, error });
        });
    };
};

export const orderSectionComponents = (templateId: string, sectionId: string, components: any) => {
    return (dispatch: Dispatch) => {
        return Service.post(
            API.goalTemplates.orderComponents(sectionId),
            {
                components: components.map((component: any) => {
                    return {
                        componentId: component.id,
                        index: component.index,
                    };
                }),
            },
            () => {
                dispatch({ type: ORDER_SECTION_COMPONENTS, payload: { templateId, sectionId, orderedComponents: components } });
                dispatch({ type: OPEN_NOTIFIER, payload: { message: translate.t('componentUpdated')}});
            },
            (error: any) => dispatch({ type: THROW_ERROR, error })
        );
    };
};

export default reducer;

import moment from 'moment';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';

import { ChildInputType } from '@/app/components/FormFields/FormFields';
import { formatDurationToHMM, getUniqValues } from '@/app/utils/helper';
import translate from '@/app/utils/translate';
import { getEnums } from '@/old/utils/helper';

import { ReportingGroupType } from '../../../../../../data';
import { getIntervalDuration } from '../../../../../../services';

import { TransactionRowInnerProps } from './transactionRowModels';
import { TimeBlockFormValues } from '@/app/components/TimeReporting/components/TimeSheet/components/DailySummary/dailySummaryModels';

const reportingGroupTypeLabels: { [key in ReportingGroupType]: string } = {
  [ReportingGroupType.TIMESHEET_WORK_TYPE]: 'laWorkReportingTypes',
  [ReportingGroupType.TIMESHEET_NON_WORK_TYPE]: 'laNonWorkReportingTypes',
  [ReportingGroupType.TIMESHEET_CORRECTION_TYPE]: 'laCorrectionReportingTypes',
};

enum TimeBlockFieldId {
    StartTime = 'start',
    EndTime = 'end',
    Hours = 'hours'
}

export const useTransactionRow = ({
  id,
  reportingType,
  canManageEverything,
  formValues: {
    reportingType: inputReportingType,
    start: inputStart,
    end: inputEnd,
    hours: inputHours,
  } = {},
  getFormValues,
  updateFormValues,
  onTransactionRowDelete,
}: TransactionRowInnerProps) => {
  const reportingTypesOptions = useMemo(() => {
    return [
      ReportingGroupType.TIMESHEET_WORK_TYPE,
      ReportingGroupType.TIMESHEET_NON_WORK_TYPE,
      ...(canManageEverything
        ? [ReportingGroupType.TIMESHEET_CORRECTION_TYPE]
        : []),
    ].reduce(
      (accumulator, group) => {
        const options = getEnums(group).filter(
          ({ isActive }) => isActive !== false,
        );

        if (!options.length) {
          return accumulator;
        }

        return [
          ...accumulator,
          {
            label: translate.t(reportingGroupTypeLabels[group]),
            options: options.map(option => ({
              label: option.name,
              value: `${group}-${option.code}`,
            })),
          },
        ];
      },
      [] as Array<{
        label: string;
        options: ChildInputType[];
      }>,
    );
  }, [canManageEverything]);

  const selectedReportingType = useMemo(() => {
    if (!inputReportingType) {
      return null;
    }

    return reportingTypesOptions
      .reduce((accumulator, { options }) => [...accumulator, ...options], [])
      .find(({ value }) => value === inputReportingType);
  }, [reportingTypesOptions, inputReportingType]);

  const formattedWorkingType = useMemo(() => {
    if (!reportingType) {
      return undefined;
    }

    const [actualReportingGroupType, actualReportingType] = reportingType.split(
      '-',
    );

    const reportingTypeOption = getEnums(actualReportingGroupType).find(
      ({ code }) => {
        return code === actualReportingType;
      },
    );

    return reportingTypeOption && reportingTypeOption.name;
  }, [reportingType]);

  const getCalculatedHours = (start: Date, end: Date): string =>
    (!start || !end) ? undefined : formatDurationToHMM(
      getIntervalDuration(
        moment(start)
          .seconds(0)
          .format('HH:mm:ss'),
        moment(end)
          .seconds(0)
          .format('HH:mm:ss'),
      ),
    );

  const getCalculatedStart = (end: Date, hours: string): Date =>
    (!end || !hours) ? undefined : moment(end)
        .subtract(moment.duration(hours)).toDate();

  const getCalculatedEnd = (start: Date, hours: string): Date =>
    (!start || !hours) ? undefined : moment(start)
        .add(moment.duration(hours)).toDate();

  const handleWorkingTypeChange = useCallback(
    (event: { value: string }) => {
      const timeBlock = getFormValues('timeBlocks')[id];

      const [actualReportingGroupType] = (timeBlock.reportingType || '').split(
        '-',
      );
      const [nextReportingGroupType] = event.value.split('-');

      /**
       * Make sure to clear start, end and hours when changing
       * from or to correction type.
       */
      if (
        actualReportingGroupType !== nextReportingGroupType &&
        [actualReportingGroupType, nextReportingGroupType].includes(
          ReportingGroupType.TIMESHEET_CORRECTION_TYPE,
        )
      ) {
        updateFormValues(id, {
          reportingType: event.value,
          start: undefined,
          end: undefined,
          hours: undefined,
        });

        return;
      }

      updateFormValues(id, { reportingType: event.value });
    },
    [id, updateFormValues],
  );

  const [timeBlockUpdateHistory, setTimeBlockUpdateHistory] = useState([]);

  const getCalculationFieldId = (
      history: TimeBlockFieldId[],
      filled: TimeBlockFieldId[],
      updatedField: TimeBlockFieldId
  ): TimeBlockFieldId => {
    const fieldCount = Object.keys(TimeBlockFieldId).length;
    const getMissingFieldId = (fields: TimeBlockFieldId[]) => Object.values(TimeBlockFieldId)
        .filter((f) => !fields.includes(f))[0];

    if (history.length === (fieldCount - 1)) {
        return getMissingFieldId(history);
    }
    if (filled.length === fieldCount) {
        return updatedField === TimeBlockFieldId.Hours ? TimeBlockFieldId.EndTime : TimeBlockFieldId.Hours;
    }
    if (filled.length === (fieldCount - 1)) {
        return getMissingFieldId(filled);
    }

    return undefined;
  };

  const convertHToHMM = (hours: string): string => {
    const reduceMultiples = (value: number, multiple: number) =>
        (value - multiple * Math.floor(value / multiple));

    if (hours.match(/^(?:\d|[01]\d|2[0-3]):[0-5]\d$/g)) {
        return hours;
    }
    if (hours.match(/\d{1,2}:\d{2}/g)) {
        const time = hours.split(':').map((v) => parseInt(v, 10));
        return `${reduceMultiples(time[0], 24)}:${reduceMultiples(time[1], 60)}`;
    }
    const h = parseInt(hours, 10);
    if (!isNaN(h)) {
        return `${reduceMultiples(h, 24)}:00`;
    }

    return undefined;
  };

  const getUpdatedTimeBlock = (update: TimeBlockFormValues) => {
      const updateFieldId: TimeBlockFieldId = Object.values(TimeBlockFieldId)
          .filter((f) => Object.keys(update).includes(f.toString()))[0];
      let userFieldUpdateHistory: TimeBlockFieldId[] = getUniqValues([updateFieldId, ...timeBlockUpdateHistory]);
      let timeBlock: TimeBlockFormValues = {
          start: inputStart,
          end: inputEnd,
          hours: inputHours,
      };
      timeBlock[updateFieldId.toString()] = update[updateFieldId.toString()];

      const fieldHasValue = (field: TimeBlockFieldId) => {
          const value = timeBlock[field.toString()];
          return !(typeof value === 'undefined' || value === '');
      };
      const filledFields: TimeBlockFieldId[] = Object.values(TimeBlockFieldId)
          .filter((f) => fieldHasValue(f));

      if (!fieldHasValue(updateFieldId)) {
          userFieldUpdateHistory = userFieldUpdateHistory.filter((f) => f !== updateFieldId);
      } else {
          switch (getCalculationFieldId(userFieldUpdateHistory, filledFields, updateFieldId)) {
              case TimeBlockFieldId.StartTime:
                  timeBlock.start =  getCalculatedStart(timeBlock.end, convertHToHMM(timeBlock.hours));
                  break;
              case TimeBlockFieldId.EndTime:
                  timeBlock.end =  getCalculatedEnd(timeBlock.start, convertHToHMM(timeBlock.hours));
                  break;
              case TimeBlockFieldId.Hours:
                  timeBlock.hours =  getCalculatedHours(timeBlock.start, timeBlock.end);
                  break;
              default:
                  break;
          }
      }
      setTimeBlockUpdateHistory(userFieldUpdateHistory);

      return timeBlock;
  };

  const handleStartTimeChange = useCallback(
    (date?: moment.Moment) => {
      const start = date && date.isValid() ? date.toDate() : undefined;
      updateFormValues(id, getUpdatedTimeBlock({start: start}));
    },
    [id, inputEnd, inputHours, updateFormValues],
  );

  const handleEndTimeChange = useCallback(
    (date: moment.Moment) => {
      const end = date && date.isValid() ? date.toDate() : undefined;
      updateFormValues(id, getUpdatedTimeBlock({end: end}));
    },
    [id, inputStart, inputHours, updateFormValues],
  );

  const handleHoursChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const hours = event.target.value;
      updateFormValues(id, getUpdatedTimeBlock({hours: hours}));
    },
    [id, inputStart, inputEnd, updateFormValues],
  );

  const handleDeleteRow = useCallback(() => {
    onTransactionRowDelete(id);
  }, [onTransactionRowDelete, id]);

  const updateCustomData = useCallback(
    (overriddenCustomData: object) => {
      const timeBlock = getFormValues('timeBlocks')[id];

      updateFormValues(id, {
        customData: {
          ...timeBlock.customData,
          ...overriddenCustomData,
        },
      });
    },
    [id, getFormValues, updateFormValues],
  );

  return {
    reportingTypesOptions,
    selectedReportingType,
    formattedWorkingType,
    handleWorkingTypeChange,
    handleStartTimeChange,
    handleEndTimeChange,
    handleHoursChange,
    handleDeleteRow,
    updateCustomData,
  };
};

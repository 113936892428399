import translate from '@/app/utils/translate';
import { REGISTRATION_STATUS } from '../types';

export default function(registrationStatus: REGISTRATION_STATUS) {
  switch (registrationStatus) {
    case REGISTRATION_STATUS.ACCEPTED:
      return translate.t('laAccepted');
    case REGISTRATION_STATUS.CANCELLED:
      return translate.t('label_cancelled');
    case REGISTRATION_STATUS.NO_SEAT:
      return translate.t('label_no_seat');
    case REGISTRATION_STATUS.REJECTED:
      return translate.t('laRejected');
    case REGISTRATION_STATUS.WAITING_FOR_APPROVAL:
      return translate.t('laWaitApproval');
    case REGISTRATION_STATUS.WAITING_FOR_SEAT:
      return translate.t('label_waiting_for_seat');
    default:
      return translate.t('label_not_registered');
  }
}

import { createStyles, Theme } from '@material-ui/core';

export const sendCommentStyles = (theme: Theme) => createStyles({
    root: {
        width: '100%',
    },
    input: {
        width: '100%',
        height: 50,
        resize: 'none',
    },
    error: {
        border: `1px solid ${theme.palette.error.main}`,

        '&:focus': {
            outline: 'none',
            boxShadow: `0 0 4px ${theme.palette.error.main}`,
        },
    },
    exceededLengthLimit: {
        color: theme.palette.error.main,
    },
});
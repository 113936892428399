import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { decisionRegistryContainerStyles } from './decisionRegistryContainerStyles';
import { compose } from 'recompose';
import axios from 'axios';
import API from '@/app/api/internalAPIs';
import Service from '@/app/utils/service';
import { basicFileDownload } from '../../utils/helper';
import TablePagination from '@material-ui/core/TablePagination';
import EditIcon from '@material-ui/icons/Edit';
import DecisionRegistryForm from './components/DecisionRegistryForm';
import translate from '@/app/utils/translate';
import Loading from '@/app/components/Loading/Loading';
import { isEmployee } from '@/old/utils/helper';
import { jsEditCustomFieldData } from '../CustomField/editCustomData';
import {
  AnswerPreProcess,
  CustomFieldType,
  CustomField,
} from '../CustomField/types';
import { CustomField as CustomFieldComponent } from '../CustomField';
import { useSnackbar } from 'notistack';

export interface Decision {
  id: number;
  type: string;
  title: string;
  maker: string;
  decisionInfo: string;
  reminderDate: string;
  isSystemGenerated: boolean;
  attachmentId: number;
  attachmentName: string;
  inventoryNumber: number;
  info: string;
  file: File;
}

type OwnProps = {
  employeeId: number;
};
type InnerProps = WithStyles<typeof decisionRegistryContainerStyles>;
type Props = OwnProps & InnerProps;

const GLOBAL: any = window;
const DecisionRegistryContainer: React.FC<Props> = props => {
  const { employeeId, classes } = props;
  const DECISION_REGISTRY_GET = `d/json/employee/${employeeId}/decisionregistry`;

  const [data, setData] = useState({
    decisionRegistryData: [],
    editDrAllCustomFields: {},
    fieldLang: [],
    addDrEmptyCustomFields: [],
    isFetching: true,
  });
  const [dialogOpen, setOpenDialog] = useState(false);
  const [forEditDecision, setForEditDecision] = useState<Decision>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [customSelectFields, setCustomSelectFields] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const empStatus = GLOBAL.jsonEmpData.empBasicData
    ? GLOBAL.jsonEmpData.empBasicData.fEmpStatus
    : '';
  const empPositionData =
    (GLOBAL.jsIsEmpStatus('NEW') || GLOBAL.jsIsEmpStatus('PENDING')) &&
    GLOBAL.jsonEmpData.empPositionPending
      ? GLOBAL.jsonEmpData.empPositionPending
      : GLOBAL.jsonEmpData.empPosition;

  const empPositionPendingLegalEntity = GLOBAL.jsonEmpData.empPositionPending
    ? GLOBAL.jsonEmpData.empPositionPending.fEmpLegalEntity
    : undefined;

  const { setValue, getValues, register, errors, handleSubmit } = useForm<
    AnswerPreProcess
  >();

  const updateCustomFields = useCallback(
    (customData: any) => {
      const fieldVal: any = Object.keys(customData)[0];
      setValue(fieldVal[0], customData[fieldVal]);
    },
    [getValues, setValue],
  );

  const handleClose = () => {
    setOpenDialog(false);
    setForEditDecision(undefined);
    data.addDrEmptyCustomFields.forEach((field: CustomField) =>
      setValue(field.code, ''),
    );
  };

  const handleEdit = (decision: Decision) => {
    setForEditDecision(decision);
    if (data.editDrAllCustomFields[decision.id]) {
      customSelectFields.forEach((code: string) => {
        const field = data.editDrAllCustomFields[decision.id].find(
          (fieldToFind: any) => fieldToFind.code === code,
        );
        setValue(code, field.value);
      });
    }
    setOpenDialog(true);
  };

  const handleFileDownload = (attachmentId: number, attachmentName: string) => {
    basicFileDownload(
      `/d/bin/employee/${employeeId}/file/${attachmentId}`,
      attachmentName,
    );
  };

  const failedToLoad = (error: any) =>
    enqueueSnackbar(
      `${translate.t('laFailedToLoad', {
        error: error.message,
      })}`,
      { variant: 'error' },
    );

  const fetchDecisionRegistryData = async () => {
    try {
      const response = await axios.get(DECISION_REGISTRY_GET);
      const fieldAttr = await Service.get(
        API.enum.customFieldAttribute('DECISION_REGISTRY'),
        (dataCustomFieldAttr: any) =>
          GLOBAL._.indexBy(dataCustomFieldAttr, 'fCustomDataAttributeName'),
        (err: any) => failedToLoad(err),
      );
      const fieldLang = await Service.get(
        API.enum.groupCodes('CUSTOM_ENTITY_DECISION_REGISTRY'),
        (dataFieldLang: any) =>
          dataFieldLang.sort((a: any, b: any) => a.seq - b.seq),
        (err: any) => failedToLoad(err),
      );
      const fieldValues = await Service.get(
        API.enum.customFields('DECISION_REGISTRY', `${employeeId}`),
        (dataFieldValues: any) => dataFieldValues,
        (err: any) => failedToLoad(err),
      );

      let editDrAllCustomFields = {};
      Promise.all(
        Object.keys(fieldValues).map(async (decisionRegistryId: any) => {
          const drFieldValues = fieldValues[decisionRegistryId].reduce(
            (acc: any, tuple: any) => {
              const fieldPair = tuple.split(',');
              const key = fieldPair[0].substr(1);
              const value = fieldPair[1].slice(0, -1);
              const fieldType = fieldAttr[key].fCustomDataAttributeDataType;
              if (acc[key]) {
                acc[key] = [...acc[key], value];
              } else {
                acc[key] =
                  fieldType === CustomFieldType.MULTISELECT ? [value] : value;
              }
              return acc;
            },
            {},
          );
          const editCustomField = await jsEditCustomFieldData(
            empStatus,
            empPositionData,
            fieldAttr,
            fieldLang,
            drFieldValues ? drFieldValues : {},
            empPositionPendingLegalEntity,
          );
          editDrAllCustomFields[decisionRegistryId] = editCustomField;
          return true;
        }),
      ).then(async () => {
        let addDrEmptyCustomFields: any = [];
        if (Object.keys(fieldAttr).length && fieldLang.length) {
          addDrEmptyCustomFields = await jsEditCustomFieldData(
            empStatus,
            empPositionData,
            fieldAttr,
            fieldLang,
            {},
            empPositionPendingLegalEntity,
          );
          const filteredSelectFieldsCodes = Object.keys(fieldAttr).filter(
            (code: string) => {
              if (
                fieldAttr[code].fCustomDataAttributeDataType ===
                  CustomFieldType.ENUM ||
                fieldAttr[code].fCustomDataAttributeDataType ===
                  CustomFieldType.MULTISELECT
              ) {
                return true;
              } else {
                return false;
              }
            },
          );
          setCustomSelectFields(filteredSelectFieldsCodes);
          // Manually add enums, multiselects to form
          customSelectFields.forEach((code: string) => {
            register(code);
          });
        }
        setData({
          decisionRegistryData: response.data,
          editDrAllCustomFields,
          fieldLang,
          addDrEmptyCustomFields,
          isFetching: false,
        });
      });
    } catch (e) {
      setData({
        decisionRegistryData: data.decisionRegistryData,
        editDrAllCustomFields: {},
        fieldLang: [],
        addDrEmptyCustomFields: [],
        isFetching: false,
      });
    }
  };

  useEffect(() => {
    fetchDecisionRegistryData();
  }, [data.isFetching]);

  if (data.isFetching) {
    return <Loading />;
  }

  const renderAddButton = () =>
    !isEmployee() ? (
      <IconButton onClick={() => setOpenDialog(true)}>
        <Tooltip title={translate.t('laAdd')}>
          <Icon color="primary">add_circle</Icon>
        </Tooltip>
      </IconButton>
    ) : (
      <></>
    );

  const renderEditButton = (decision: Decision) =>
    !isEmployee() ? (
      <IconButton onClick={() => handleEdit(decision)}>
        <Tooltip
          title={
            decision.isSystemGenerated
              ? translate.t('laSystemEntry')
              : translate.t('laManualEntry')
          }
        >
          <EditIcon
            color={decision.isSystemGenerated ? 'primary' : 'secondary'}
          />
        </Tooltip>
      </IconButton>
    ) : (
      <></>
    );
  return (
    <div>
      {!!data.decisionRegistryData.length ? (
        <Paper style={{ overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{renderAddButton()}</TableCell>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                {data.fieldLang.map((field: any) => (
                  <TableCell key={field.code} />
                ))}
                <TableCell />
              </TableRow>
              <TableRow>
                <TableCell />
                <TableCell>{translate.t('laType')}</TableCell>
                <TableCell>{translate.t('laTitle')}</TableCell>
                <TableCell>{translate.t('laEquipmentNumber')}</TableCell>
                <TableCell>{translate.t('laDecisionDate')}</TableCell>
                <TableCell>{translate.t('laDecisionMaker')}</TableCell>
                <TableCell>{translate.t('laDecisionInfo')}</TableCell>
                {data.fieldLang.map((field: any) => (
                  <TableCell key={field.code}>{field.name}</TableCell>
                ))}
                <TableCell className={classes.textCenter}>
                  {translate.t('laAttachment')}
                </TableCell>
                <TableCell>{translate.t('laReminder')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data.decisionRegistryData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(decision => (
                  <TableRow key={'row-' + decision.inventoryNumber}>
                    <TableCell colSpan={1}>
                      {renderEditButton(decision)}
                    </TableCell>
                    <TableCell>
                      <span>{decision.type}</span>
                    </TableCell>
                    <TableCell>{decision.title}</TableCell>
                    <TableCell>{decision.inventoryNumber}</TableCell>
                    <TableCell>{decision.date}</TableCell>
                    <TableCell>{decision.maker}</TableCell>
                    <TableCell>{decision.info}</TableCell>
                    {data.editDrAllCustomFields[decision.id] ? (
                      data.editDrAllCustomFields[decision.id].map(
                        (field: CustomField) => (
                          <TableCell key={field.code}>
                            <CustomFieldComponent
                              fieldDetails={field}
                              isSaving={false}
                              idEditMode={false}
                            />
                          </TableCell>
                        ),
                      )
                    ) : data.addDrEmptyCustomFields.length ? (
                      data.addDrEmptyCustomFields.map((field: CustomField) => (
                        <TableCell key={field.code}>
                          <CustomFieldComponent
                            fieldDetails={field}
                            isSaving={false}
                            idEditMode={false}
                          />
                        </TableCell>
                      ))
                    ) : (
                      <></>
                    )}
                    <TableCell className={classes.textCenter}>
                      {decision.attachmentName && (
                        <Button
                          onClick={() =>
                            handleFileDownload(
                              decision.attachmentId,
                              decision.attachmentName,
                            )
                          }
                        >
                          {decision.attachmentName}
                        </Button>
                      )}
                    </TableCell>
                    <TableCell>{decision.reminderDate}</TableCell>
                    <TableCell />
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      ) : (
        <>
          {translate.t('laNoDecisionsInRegistry')}
          {renderAddButton()}
        </>
      )}
      <DecisionRegistryForm
        decision={forEditDecision}
        employeeId={employeeId}
        dialogOpen={dialogOpen}
        handleClose={handleClose}
        editDrAllCustomFields={data.editDrAllCustomFields}
        addDrEmptyCustomFields={data.addDrEmptyCustomFields}
        updateCustomFields={updateCustomFields}
        register={register}
        getValues={getValues}
        setValue={setValue}
        errors={errors}
        handleSubmit={handleSubmit}
        fetchDecisionRegistryData={fetchDecisionRegistryData}
      />

      {!!data.decisionRegistryData.length ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.decisionRegistryData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={(_e, p) => setPage(p)}
          onChangeRowsPerPage={e =>
            setRowsPerPage(parseInt(e.target.value, 10))
          }
        />
      ) : (
        ''
      )}
    </div>
  );
};

const enhance = compose<Props, OwnProps>(
  withStyles(decisionRegistryContainerStyles),
);

export default enhance(DecisionRegistryContainer);

import translate from '@/app/utils/translate';
import {
  Checkbox,
  Icon,
  IconButton,
  StyleRulesCallback,
  withStyles,
} from '@material-ui/core';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { ParticipantsButton } from '../../Trainings/components';
import AudienceDetailsPopover from '../../Trainings/components/adminTrainingCalendarView/trainingTableRow/AudienceDetailsPopover';
import Context from '../../Trainings/context/trainingsContext';
import { mapCompanyTrainingForUI } from '../../Trainings/context/trainingsContext/dataMappers';
import {
  formatTrainingDates,
  getResponsiblePersonsNames,
} from '../../Trainings/helpers';
import { CompanyTraining } from '../../Trainings/types';
import ReportView from '../components/ReportView';
import { IReportConfig } from '../ReportConfig';
import { IReportSettings } from '../ReportSettings';
import ParticipantsDialog from './components/ParticipantsListDialog';

const styles: StyleRulesCallback = () => ({
  cellButton: {
    textTransform: 'none',
  },
});
const GLOBAL: any = window;
const AllTrainingsReport = () => {
  const { getCompanyTrainings, state } = useContext(Context);

  const [selectedTraining, setSelectedTraining] = useState<
    CompanyTraining | undefined
  >();
  const [participantsDialogOpen, setParticipantsDialogOpen] = useState(false);

  const { participantSummaries } = state;

  const handleOpenParticipantsDialog = (training: CompanyTraining) => {
    setSelectedTraining(training);
    setParticipantsDialogOpen(true);
  };

  const getPendingParticipants = (dataItem: any) => {
    const pendingParticipants = dataItem.mappedTraining.summary.pending;

    if (!!pendingParticipants) {
      return `${pendingParticipants}`;
    }

    return translate.t('laNoPendingParticipants');
  };

  const config: IReportConfig = {
    id: 'ALL_TRAININGS',
    report: 'allTrainings',
    titleLabel: 'laAllTrainingsReport',
    access: 'standard',
    columns: [
      {
        field: 'Training',
        title: `${translate.t('laTraining')} / ${translate.t(
          'training_class',
        )}`,
      },
      {
        field: 'ProviderAndResponsiblePersons',
        title: `${translate.t('laProvider')} /
      ${translate.t('label_responsible_persons')}`,
      },
      {
        field: 'Type',
        title: translate.t('label_training_type'),
      },
      { field: 'Language', title: translate.t('laLanguage') },
      { field: 'StartDate', title: translate.t('label_start_date') },
      { field: 'EndDate', title: translate.t('laEndDate') },
      {
        field: 'Audience',
        title: translate.t('label_training_audience'),
        cell: props => {
          const [
            audiencePopoverAnchorEl,
            setAudiencePopoverAnchorEl,
          ] = useState(null);
          const training = props.dataItem.mappedTraining;
          const audienceCellContent =
            training &&
            ((!!training.unitAudience && training.unitAudience.length > 0) ||
              (!!training.roleAudience && training.roleAudience.length > 0) ||
              (!!training.countryAudience &&
                training.countryAudience.length > 0)) ? (
              <>
                <AudienceDetailsPopover
                  training={training}
                  anchorEl={audiencePopoverAnchorEl}
                  onClose={() => setAudiencePopoverAnchorEl(null)}
                />
                <IconButton onClick={e => setAudiencePopoverAnchorEl(e.target)}>
                  <Icon>supervised_user_circle</Icon>
                </IconButton>
              </>
            ) : null;
          return <td>{audienceCellContent}</td>;
        },
      },
      {
        field: 'Participants',
        title: translate.t('label_training_participants'),
        cell: props => {
          return props.dataItem ? (
            <td>
              <ParticipantsButton
                className={''}
                trainingId={props.dataItem.mappedTraining.id}
                onOpenParticipants={() =>
                  handleOpenParticipantsDialog(props.dataItem.mappedTraining)
                }
                participantsSummary={
                  !GLOBAL.iHR
                    ? props.dataItem.mappedTraining.summary
                    : undefined
                }
              />
            </td>
          ) : (
            <td />
          );
        },
      },
      {
        field: 'NotHandledParticipants',
        title: translate.t('label_training_not_handled_participants'),
        cell: props => {
          const { dataItem } = props;
          return <td>{getPendingParticipants(dataItem)}</td>;
        },
      },
      {
        field: 'IncludedInCompensation',
        title: translate.t('label_include_in_training_compensation'),
        cell: props => {
          const training = props.dataItem;
          return props.dataItem ? (
            <td>
              <Checkbox
                color="primary"
                checked={!!training.IncludedInCompensation}
                disabled
              />
            </td>
          ) : (
            <td />
          );
        },
      },
      {
        field: 'IsPublic',
        title: translate.t('laPublicTrainings'),
        cell: props => {
          const training = props.dataItem;
          return props.dataItem ? (
            <td>
              <Checkbox color="primary" checked={training.IsPublic} disabled />
            </td>
          ) : (
            <td />
          );
        },
      },
    ],
    excelExportColumns: [
      {
        field: 'Training',
        title: `${translate.t('laTraining')} / ${translate.t(
          'training_class',
        )}`,
      },
      {
        field: 'ProviderAndResponsiblePersons',
        title: `${translate.t('laProvider')} /
        ${translate.t('label_responsible_persons')}`,
      },
      { field: 'Type', title: translate.t('label_training_type') },
      { field: 'Language', title: translate.t('laLanguage') },
      { field: 'StartDate', title: translate.t('label_start_date') },
      { field: 'EndDate', title: translate.t('laEndDate') },
      { field: 'Audience', title: translate.t('label_training_audience') },
      {
        field: 'Participants',
        title: translate.t('label_training_participants'),
      },
      {
        field: 'IncludedInCompensation',
        title: translate.t('label_include_in_training_compensation'),
      },
      { field: 'IsPublic', title: translate.t('laPublicTrainings') },
    ],
    filters: {
      scopeFilter: {
        enabled: true,
        accessRights: {
          company: ['HR_ADMIN', 'MANAGER'],
          dManagerAll: ['DEPUTY_MANAGER'],
          dManagerDirect: ['DEPUTY_MANAGER'],
          managerAll: ['MANAGER'],
          managerDirect: ['MANAGER'],
          unitAll: ['MANAGER', 'DEPUTY_MANAGER', 'HR_STAFF', 'HR_ADMIN'],
          unitDirect: ['MANAGER', 'DEPUTY_MANAGER', 'HR_STAFF', 'HR_ADMIN'],
        },
      },
      customFilters: [
        {
          type: 'NONE',
          params: {
            selectedSettingName: 'showAllFields',
            fieldName: 'showAllFields',
            operator: 'eq',
            buttonLabel: 'showAllFields',
            value: true,
          },
        },
      ],
      dateFilter: {
        enabled: false,
      },
    },
  };

  useEffect(() => {
    getCompanyTrainings();
  }, []);

  const defaultSettings: IReportSettings = {
    report: 'trainingcalendar',
    scope: {
      type: '',
      range: {
        startDate: moment()
          .add(1, 'day')
          .subtract(6, 'month'),
        endDate: moment().add(1, 'day'),
      },
    },
    custom: {
      showAllFields: true,
    },
  };

  const participantsDialog =
    !participantsDialogOpen || !selectedTraining || !GLOBAL.iHR ? null : (
      <ParticipantsDialog
        open={participantsDialogOpen}
        training={selectedTraining}
        onClose={() => setParticipantsDialogOpen(false)}
      />
    );

  const mapData = (origData: any) => {
    const { data: participantSummariesData } = participantSummaries;
    return origData.map((training: any) => {
      const updatedTraining = {
        ...training,
        participantsSummaries: participantSummariesData.find(
          (summary: any) =>
            summary.trainingId.toString() === training.id.toString(),
        ).completions,
      };
      const mappedTraining = mapCompanyTrainingForUI(updatedTraining);

      return {
        Training: mappedTraining.name.label,
        ProviderAndResponsiblePersons: !mappedTraining.trainingClass
          ? null
          : mappedTraining.trainingClass.label,
        Provider: mappedTraining.provider,
        ResponsiblePersons: getResponsiblePersonsNames(
          mappedTraining.responsiblePersons.map(
            (rp: any) => rp.value as number,
          ),
        ),
        Type: mappedTraining.trainingGroup.label,
        Language: mappedTraining.language.label,
        StartDate: formatTrainingDates(mappedTraining.startDate),
        EndDate: formatTrainingDates(mappedTraining.endDate),
        Audience: '',
        Participants: '',
        IncludedInCompensation: !!mappedTraining.includeInTrainingCompensation,
        IsPublic: mappedTraining.isPublic,
        showAllFields: true,
        mappedTraining,
      };
    });
  };

  return (
    <>
      {participantsDialog}
      <ReportView
        moduleId="development"
        reportId="ALL_TRAININGS"
        config={config}
        defaultSettings={defaultSettings}
        kendoGrouping={[]}
        onDataMapping={mapData}
        onGetCustomFilters={() => config.filters.customFilters}
        sort={[{ field: 'Training', dir: 'asc' }]}
        exportFileName={translate.t('laAllTrainingsReport')}
      />
    </>
  );
};

export default withStyles(styles)(AllTrainingsReport);

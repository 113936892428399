import * as React from 'react';
import {
  WithStyles,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  TablePagination,
  Collapse,
  Chip,
} from '@material-ui/core';
import EnhancedTableHead, {
  HeadData,
} from './EnhancedTableHead/EnhancedTableHead';
import EnhancedTableToolbar, {
  Tools,
  CustomToolbarButton,
  HideTools,
} from '@/app/components/EnhancedTable/EnhancedTableToolbar/EnhancedTableToolbar';
import { withStyles } from '@material-ui/core/styles';
import enhancedTableStyle from './enhancedTableStyle';
import TablePaginationActionsWrapped from '@/app/components/EnhancedTable/TablePaginationActionWrapped/TablePaginationActionWrapped';
import translate from '@/app/utils/translate';
import { formatDate, formatDatetime } from '@/app/utils/helper';
import RowMenu, { CustomRowButton } from './RowMenu/RowMenu';

type Order = 'asc' | 'desc';
export type Id = string | number;

/**
 * All the data for the table must extend this base interface
 */
export interface BaseDataType {
  id: any;
  type: string;
  active?: boolean;
}

interface PropsType extends WithStyles<typeof enhancedTableStyle, true> {
  title?: string;
  customTitle?: React.ReactNode;
  tools: Tools;
  customTools?: React.ReactNode;
  customButtons?: CustomToolbarButton[];
  customRowButtons?: (row: any) => CustomRowButton[];
  hideTools?: HideTools;
  hideRowTranslate?: boolean;
  headData: Array<HeadData>;
  data: Array<any>;
  order: Order;
  orderBy: Id;
  rowsPerPage?: number;
  foldable?: boolean; // allow table content foldable
  expanded?: boolean;
  pagination?: boolean; // By default will be true
  hasRowMenuColumn?: boolean; // By default will be true
  hasSelectionAvailable?: boolean; // Be default will be true
  titleClickHandler?: React.MouseEventHandler;
  addHandler?: React.MouseEventHandler;
  defaultSelected?: Array<Id>;
  onSelectedChange?: (selected: any[]) => void;
  editHandler?: (selected: any[]) => React.MouseEventHandler;
  deleteHandler?: (selected: any[]) => React.MouseEventHandler;
  deleteCurrentItemHandler?: (selected: any) => React.MouseEventHandler;
  activateHandler?: (selected: any[]) => React.MouseEventHandler;
  deactivateHandler?: (selected: any[]) => React.MouseEventHandler;
  translateHandler?: (selected: any[]) => React.MouseEventHandler;
  detailsHandler?: (selected: any[]) => React.MouseEventHandler;
  duplicateHandler?: (selected: any[]) => React.MouseEventHandler;
  sortHandler?: () => void;
}

type StateType = {
  order: Order;
  orderBy: Id;
  selected: Array<any>;
  page: number;
  rowsPerPage: number;
};

let resetSelectionFn: Function;
let currentSelectionFn: Function;

class EnhancedTable extends React.Component<PropsType, StateType> {
  state: StateType = {
    order: this.props.order || 'asc',
    orderBy: this.props.orderBy,
    selected: [],
    page: 0,
    rowsPerPage: this.props.rowsPerPage || 10,
  };

  componentDidMount() {
    resetSelectionFn = this.resetSelection;
    currentSelectionFn = this.currentSelection;
  }

  currentSelection = () => {
    return this.state.selected;
  };

  componentDidUpdate(prevProps: PropsType) {
    // when data changed, we need to check if the selected still available
    if (
      this.state.selected.length > 0 &&
      this.props.data.length !== prevProps.data.length
    ) {
      this.setState(
        {
          selected: this.props.data.filter(
            d => this.state.selected.map((s: any) => s.id).indexOf(d.id) > -1,
          ),
        },
        () => this.handleSelectedChange(),
      );
    }
  }

  UNSAFE_componentWillReceiveProps(prevProps: PropsType) {
    if (
      !!prevProps.defaultSelected &&
      prevProps.defaultSelected !== this.props.defaultSelected
    ) {
      this.setState({ selected: prevProps.defaultSelected });
    }
  }

  handleSelectedChange = () => {
    if (this.props.onSelectedChange) {
      this.props.onSelectedChange(this.state.selected);
    }
  };

  resetSelection = () => {
    this.setState({ selected: [] }, () => this.handleSelectedChange());
  };

  handleSort = (id: Id) => {
    const orderBy = id;
    let order: Order = 'desc';

    if (this.state.orderBy === id && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    data: any,
  ) => {
    if (event.target.checked) {
      this.setState(
        (prevSate: StateType) => {
          const selected = [...data];
          prevSate.selected.forEach((i: any) => {
            const index = selected.findIndex((m: any) => m.id === i.id);
            if (index === -1) {
              selected.push(i);
            }
          });

          return { selected };
        },
        () => this.handleSelectedChange(),
      );
    } else {
      this.setState(
        (prevSate: StateType) => {
          return {
            selected: prevSate.selected.filter(
              s => data.findIndex((d: any) => s.id === d.id) === -1,
            ),
          };
        },
        () => this.handleSelectedChange(),
      );
    }
  };

  handleClick = (item: any) => () => {
    const { selected } = this.state;
    const selectedIndex = selected.findIndex((i: any) => i.id === item.id);

    let newSelected: Array<any> = [];
    if (selectedIndex > -1) {
      newSelected = [...selected];
      newSelected.splice(selectedIndex, 1);
    } else if (selectedIndex === -1) {
      newSelected = selected.concat(item);
    }

    this.setState({ selected: newSelected }, () => this.handleSelectedChange());
  };

  handleChangePage = (_event: React.MouseEvent, page: number) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
  };

  isSelected = (item: any) =>
    this.state.selected.findIndex((i: any) => i.id === item.id) !== -1;

  getSorting = (order: Order, orderBy: Id) => {
    const { headData, tools } = this.props;
    const { showSort = false } = tools;
    // is manual sort is used, only sort by seq
    if (showSort) {
      orderBy = 'seq';
    }
    const targetHead = headData.filter(head => head.id === orderBy)[0];

    if (showSort || (targetHead && targetHead.type === 'number')) {
      return order === 'desc'
        ? (a: any, b: any) => b[orderBy] - a[orderBy]
        : (a: any, b: any) => a[orderBy] - b[orderBy];
    }

    return order === 'desc'
      ? (a: any, b: any) =>
          b[orderBy] ? b[orderBy].localeCompare(a[orderBy]) : -1
      : (a: any, b: any) =>
          a[orderBy] ? a[orderBy].localeCompare(b[orderBy]) : 1;
  };

  labelDisplayedRows = ({
    from,
    to,
    count,
  }: {
    from: number;
    to: number;
    count: number;
  }): string => translate.t('from_to_items', { from, to, count });

  deleteSelected = (data: Array<any>) => (_event: React.MouseEvent) => {
    this.props.deleteHandler(data)(_event);
    this.setState({ selected: [] }, () => this.handleSelectedChange());
  };

  render() {
    const {
      classes,
      data = [],
      headData,
      title = '',
      customTitle = null,
      tools,
      customTools = null,
      customButtons = undefined,
      foldable = false,
      expanded = true,
      pagination = true,
      hasRowMenuColumn = true,
      hasSelectionAvailable = true,
      titleClickHandler = (_event: React.MouseEvent) => '',
      addHandler = (_event: React.MouseEvent) => '',
      editHandler = new Function(),
      deleteCurrentItemHandler = new Function(),
      deactivateHandler = new Function(),
      activateHandler = new Function(),
      translateHandler = new Function(),
      detailsHandler = new Function(),
      hideRowTranslate = false,
      duplicateHandler = new Function(),
      sortHandler = () => '',
      hideTools = {} as HideTools,
      customRowButtons = new Function(),
    } = this.props;

    const { order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = !pagination
      ? 0
      : rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    const currentPageData = !pagination
      ? data.slice().sort(this.getSorting(order, orderBy))
      : data
          .slice() // do not mess the orign data
          .sort(this.getSorting(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    let showTable = true;
    if (foldable && !expanded) {
      showTable = false;
    }

    return (
      <Paper className={classes.root}>
        <EnhancedTableToolbar
          title={title}
          customTitle={customTitle}
          tools={tools}
          customTools={customTools}
          customButtons={customButtons}
          numSelected={selected.length}
          foldable={foldable}
          expanded={expanded}
          titleClickHandler={titleClickHandler}
          addHandler={addHandler}
          editHandler={editHandler(selected)}
          deleteHandler={this.deleteSelected(selected)}
          deactivateHandler={deactivateHandler(selected)}
          activateHandler={activateHandler(selected)}
          translateHandler={translateHandler(selected)}
          detailsHandler={detailsHandler(selected)}
          duplicateHandler={duplicateHandler(selected)}
          sortHandler={sortHandler}
          hideTools={hideTools}
          data={selected}
          deleteCurrentItemHandler={deleteCurrentItemHandler(selected)}
        />
        <div className={classes.tableWrapper}>
          <Collapse in={showTable}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead
                selectedData={selected}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={(
                  event: React.ChangeEvent<HTMLInputElement>,
                ) => this.handleSelectAllClick(event, currentPageData)}
                onSort={this.handleSort}
                currentPageData={currentPageData}
                data={headData}
                checkable={hasSelectionAvailable}
                hasRowMenuColumn={hasRowMenuColumn}
                tools={tools}
              />
              <TableBody>
                {currentPageData.map(n => {
                  const isSelected = this.isSelected(n);
                  const { active = true } = n;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={`tr-${n.groupName}-${n.id}`}
                      selected={isSelected}
                      className={!active ? classes.inactive : null}
                    >
                      {!hasSelectionAvailable ? null : (
                        <TableCell
                          onClick={
                            hasSelectionAvailable ? this.handleClick(n) : null
                          }
                          className={classes.tablecell}
                          key={`${n.id}-checkbox`}
                          padding="checkbox"
                          style={{
                            width: 40,
                          }}
                        >
                          <Checkbox checked={isSelected} />
                        </TableCell>
                      )}
                      {headData.map(head => {
                        let text = null;
                        if (head.renderCustomDataCell) {
                          text = head.renderCustomDataCell(n, head);
                        } else {
                          if (n[head.id]) {
                            text = n[head.id];
                          }
                          if (text && head.type === 'date') {
                            text = formatDate(text);
                          }
                          if (text && head.type === 'datetime') {
                            text = formatDatetime(text);
                          }
                          if (
                            text &&
                            text instanceof Array &&
                            head.type === 'array'
                          ) {
                            text = text.map(t => <Chip key={t} label={t} />);
                          }
                          if (
                            text &&
                            text instanceof Array &&
                            head.type === 'ChildInputTypeArray'
                          ) {
                            text = text
                              .filter(t => t.value)
                              .map(t => (
                                <Chip
                                  className={
                                    head.isScrollable
                                      ? classes.srollableArrayCell
                                      : ''
                                  }
                                  key={t.value}
                                  label={t.label}
                                />
                              ));
                          }
                          if (text && head.type === 'ChildInputType') {
                            text = text.label;
                          }
                          if (head.type === 'boolean') {
                            text = text ? (
                              <i
                                className="fa fa-check"
                                style={{ fontSize: '22px' }}
                              />
                            ) : (
                              <i style={{ fontSize: '22px' }}>-</i>
                            );
                          }
                          if (head.type === 'icon' && head.renderCustomDataCell) {
                            text = head.renderCustomDataCell(n, head);
                          }
                        }

                        let postfix = null;
                        if (head.renderDataPostfix) {
                          postfix = head.renderDataPostfix(n);
                        }

                        return (
                          <TableCell
                            key={`${n.id}-${head.id}`}
                            align={head.type === 'number' ? 'right' : 'left'}
                            padding={head.disablePadding ? 'none' : 'default'}
                            className={`${classes.tablecell} ${
                              head.isScrollable ? classes.scrollableCell : ''
                            }`}
                            style={{
                              color: !active ? '#aaaaaa' : 'inherit',
                            }}
                          >
                            {text}
                            {postfix}
                          </TableCell>
                        );
                      })}
                      {hasRowMenuColumn ? (
                        <TableCell
                          className={classes.tablecell}
                          key={`${n.id}-menu`}
                          padding="none"
                          style={{
                            width: 50,
                          }}
                        >
                          <RowMenu
                            active={active}
                            tools={tools}
                            data={[n]}
                            customButtons={customRowButtons(n)}
                            hideTranslate={hideRowTranslate}
                            editHandler={editHandler([n])}
                            translateHandler={translateHandler([n])}
                            detailsHandler={detailsHandler([n])}
                            deleteHandler={this.deleteSelected([n])}
                            deactivateHandler={deactivateHandler([n])}
                            activateHandler={activateHandler([n])}
                            duplicateHandler={duplicateHandler([n])}
                          />
                        </TableCell>
                      ) : null}
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={data.length + 1} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {!pagination ? null : (
              <TablePagination
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActionsWrapped}
                labelDisplayedRows={this.labelDisplayedRows}
                labelRowsPerPage={translate.t('items_per_page')}
                classes={{
                  caption: classes.paginationCaption,
                  select: classes.paginationSelect,
                  selectIcon: classes.paginationSelectIcon,
                }}
                SelectProps={{}}
              />
            )}
          </Collapse>
        </div>
      </Paper>
    );
  }
}

export const resetSelection = () => {
  resetSelectionFn();
};

export const currentSelection = () => {
  return currentSelectionFn();
};

export default withStyles(enhancedTableStyle, { withTheme: true })(
  EnhancedTable,
);

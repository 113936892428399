import { StyleRulesCallback, Theme } from '@material-ui/core';

export type StyleKeys = 'root' | 'openedReportLabel' | 'deleteButton';

export const styles: StyleRulesCallback<StyleKeys> = (theme: Theme) => ({
    root: {
        display: 'inline-block',
        marginRight: 2 * theme.spacing.unit,
    },
    openedReportLabel: {
        fontWeight: theme.typography.fontWeightMedium,
        marginLeft: theme.spacing.unit,
    },
    deleteButton: {
        marginLeft: `${theme.spacing.unit}px !important`,

        '& svg': {
            fontSize: 20,
        },
    }
});

import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const sectionListingStyle: StyleRulesCallback = () => ({
  icon: {
    transform: 'rotate(180deg)',
  },
  iconButton: {
    marginLeft: -12,
  },
  noMargin: {
    marginLeft: 0,
    marginRight: 0,
  },
});

export default sectionListingStyle;

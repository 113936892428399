import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const trainingCalendarDialogStyle: StyleRulesCallback = (theme: Theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      width: '800px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
  trainingName: {
    flexBasis: '50%',
    flexShrink: 0,
  },
  dialogActions: {
    margin: '8px 20px'
  }
});

export default trainingCalendarDialogStyle;
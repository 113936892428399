import { Dispatch } from 'redux';
import { Map as iMap } from 'immutable';
import API from '@/app/api/internalAPIs';
import Service from '@/app/utils/service';
import translate from '@/app/utils/translate';
import {
  EmployeeListData,
  IGroupedIndicatorCreate,
  ISolaIntelSettings,
} from '@/app/components/SolaIntel/SolaIntelConfig';

export const FETCH_INDICATORS = '@@solaforce/solaIntel/FETCH_INDICATORS';
export const FETCH_INDICATORS_SUCCESS =
  '@@solaforce/solaIntel/FETCH_INDICATORS_SUCCESS';
export const FETCH_INDICATORS_ERROR =
  '@@solaforce/solaIntel/FETCH_INDICATORS_ERROR';
export const FETCH_INDICATORS_SUMMARY =
  '@@solaforce/solaIntel/FETCH_INDICATORS_SUMMARY';
export const FETCH_INDICATORS_SUMMARY_SUCCESS =
  '@@solaforce/solaIntel/FETCH_INDICATORS_SUMMARY_SUCCESS';
export const FETCH_INDICATORS_SUMMARY_ERROR =
  '@@solaforce/solaIntel/FETCH_INDICATORS_SUMMARY_ERROR';
export const FETCH_INDICATOR_REPORT =
  '@@solaforce/solaIntel/FETCH_INDICATOR_REPORT';
export const FETCH_INDICATOR_REPORT_SUCCESS =
  '@@solaforce/solaIntel/FETCH_INDICATOR_REPORT_SUCCESS';
export const FETCH_INDICATOR_REPORT_ERROR =
  '@@solaforce/solaIntel/FETCH_INDICATOR_REPORT_ERROR';
export const UPDATE_INDICATOR = '@@solaforce/solaIntel/UPDATE_INDICATOR';
export const UPDATE_INDICATOR_SUCCESS =
  '@@solaforce/solaIntel/UPDATE_INDICATOR_SUCCESS';
export const UPDATE_INDICATOR_ERROR =
  '@@solaforce/solaIntel/UPDATE_INDICATOR_ERROR';
export const CREATE_GROUPED_INDICATOR =
  '@@solaforce/solaIntel/CREATE_GROUPED_INDICATOR';
export const CREATE_GROUPED_INDICATOR_SUCCESS =
  '@@solaforce/solaIntel/CREATE_GROUPED_INDICATOR_SUCCESS';
export const CREATE_GROUPED_INDICATOR_ERROR =
  '@@solaforce/solaIntel/CREATE_GROUPED_INDICATOR_ERROR';
export const UPDATE_GROUPED_INDICATOR =
  '@@solaforce/solaIntel/UPDATE_GROUPED_INDICATOR';
export const UPDATE_GROUPED_INDICATOR_SUCCESS =
  '@@solaforce/solaIntel/UPDATE_GROUPED_INDICATOR_SUCCESS';
export const UPDATE_GROUPED_INDICATOR_ERROR =
  '@@solaforce/solaIntel/UPDATE_GROUPED_INDICATOR_ERROR';
export const REMOVE_GROUPED_INDICATOR =
  '@@solaforce/solaIntel/REMOVE_GROUPED_INDICATOR';
export const REMOVE_GROUPED_INDICATOR_SUCCESS =
  '@@solaforce/solaIntel/REMOVE_GROUPED_INDICATOR_SUCCESS';
export const REMOVE_GROUPED_INDICATOR_ERROR =
  '@@solaforce/solaIntel/REMOVE_GROUPED_INDICATOR_ERROR';

const initialState = iMap({
  currentUnitId: null,
  isFetching: false,
  indicators: [],
  uiMappedIndicators: [],
  allIndicatorReportResults: [],
  uiMappedIndicatorResults: {},
  error: null,
});

const INDICATOR_UI_RES = {
  PEOPLE: {
    titleTransLabel: 'laPeople',
    iconName: 'person',
  },
  REWARDING: {
    titleTransLabel: 'laRewarding',
    iconName: 'attach_money',
  },
  PERFORMANCE: {
    titleTransLabel: 'laPerformance',
    iconName: 'playlist_add_check',
  },
  DEVELOPMENT: {
    titleTransLabel: 'laDevelopment',
    iconName: 'trending_up',
  },
  CUSTOM: {
    titleTransLabel: 'laCustom',
    iconName: 'group_work',
  },
  NO_SALARY_INCREASE: {
    configParam: 'coveredMonths',
    dataDescriptionTerm: 'label_last_salary_increase',
    dataDescriptionParam: 'date',
    dataValueKey: 'lastSalaryIncreaseDate',
    dataTargetPage: 'rewarding.html',
    dataTargetTab: 'idTabGeneral',
    type: 'SINGLE_NUMBER',
    titleTransLabel: 'title_no_salary_increase_in_months',
    unitTransLabel: 'laMonths',
  },
  NO_INCENTIVES_PAID: {
    configParam: 'coveredMonths',
    dataDescriptionTerm: 'label_last_incentive_paid',
    dataDescriptionParam: 'date',
    dataValueKey: 'lastIncentivePaidDate',
    dataTargetPage: 'rewarding.html',
    dataTargetTab: 'idTabIncentives',
    type: 'SINGLE_NUMBER',
    titleTransLabel: 'title_no_incentives_paid_in_months',
    unitTransLabel: 'laMonths',
  },
  PERFORMANCE_SCORE_UNDER: {
    configParam: 'performanceScore',
    dataDescriptionTerm: 'label_last_performance_score',
    dataDescriptionParam: 'score',
    dataValueKey: 'lastPerformanceScore',
    dataTargetPage: 'performance.html',
    dataTargetTab: 'idTabGoalSetting',
    type: 'SINGLE_NUMBER',
    titleTransLabel: 'title_performance_score_under',
    unitTransLabel: '',
  },
  NO_TRAININGS_STARTED: {
    configParam: 'coveredMonths',
    dataDescriptionTerm: 'label_last_training_started',
    dataDescriptionParam: 'date',
    dataValueKey: 'lastTrainingStartedDate',
    dataTargetPage: 'development.html',
    dataTargetTab: 'idTabEducation',
    type: 'SINGLE_NUMBER',
    titleTransLabel: 'title_no_training_started_in_months',
    unitTransLabel: 'laMonths',
  },
  PERFORMANCE_SCORE_OVER: {
    configParam: 'performanceScore',
    dataDescriptionTerm: 'label_performance_score_over',
    dataDescriptionParam: 'score',
    dataValueKey: 'lastPerformanceScore',
    dataTargetPage: 'performance.html',
    dataTargetTab: 'idTabPerformanceEvaluation',
    type: 'SINGLE_NUMBER',
    titleTransLabel: 'title_performance_score_over',
    unitTransLabel: '',
  },
  STATUS_DISCUSSION_EXPIRES: {
    configParam: 'coveredDays',
    dataDescriptionTerm: 'label_status_discussion_expires',
    dataDescriptionParam: 'date',
    dataValueKey: 'statusDiscussionExpirationDate',
    dataTargetPage: 'performance.html',
    dataTargetTab: 'idTabStatusDiscussion',
    type: 'SINGLE_NUMBER',
    titleTransLabel: 'title_status_discussion_expires_in',
    unitTransLabel: 'laDays',
  },
  NO_GOALSETTING_DONE: {
    configParam: 'coveredMonths',
    dataDescriptionTerm: 'title_no_goal_setting',
    dataDescriptionParam: 'date',
    dataValueKey: 'lastGoalsetDate',
    dataTargetPage: 'performance.html',
    dataTargetTab: 'idTabGoalSetting',
    type: 'SINGLE_NUMBER',
    titleTransLabel: 'title_no_goal_setting_in_months',
    unitTransLabel: 'laMonths',
  },
  NO_POSITION_CHANGE: {
    configParam: 'coveredMonths',
    dataDescriptionTerm: 'label_no_position_change',
    dataDescriptionParam: 'date',
    dataValueKey: 'lastPositionChangeDate',
    dataTargetPage: 'development.html',
    dataTargetTab: 'idTabDevPlan',
    type: 'SINGLE_NUMBER',
    titleTransLabel: 'title_no_position_change_in_months',
    unitTransLabel: 'laMonths',
  },
  VACATION_BALANCE_OVER: {
    configParam: 'coveredDays',
    dataDescriptionTerm: 'label_vacation_balance_over',
    dataDescriptionParam: 'value',
    dataValueKey: 'vacationBalanceDays',
    dataTargetPage: 'people.html',
    dataTargetTab: 'idEmpTab5',
    type: 'SINGLE_NUMBER',
    titleTransLabel: 'title_vacation_balance_over',
    unitTransLabel: 'laDays',
  },
};

const getGroupedIndicatorDescription = (
  keys: string[],
  dataFragment: any,
  indicatorCondition: string,
): string => {
  const translations = keys.map((key) => {
    const term = INDICATOR_UI_RES[key].dataDescriptionTerm;
    const param = INDICATOR_UI_RES[key].dataDescriptionParam;
    const dataValue = dataFragment[INDICATOR_UI_RES[key].dataValueKey];

    if (param === 'date') {
      return translate.t(term, {
        [param]: new Date(dataValue).toLocaleDateString('fi-FI'),
      });
    }

    return translate.t(term, { [param]: dataValue });
  });

  return translations.join(` ${translate.t(`la${indicatorCondition}`)} `);
};

const uiRemapDataForGroupedIndicators = (
  data: any[],
  indicator: any,
): EmployeeListData[] => {
  const findIndicatorKey = (key: string) => {
    return Object.keys(INDICATOR_UI_RES).find((indicatorKey) => {
      return INDICATOR_UI_RES[indicatorKey].dataValueKey === key;
    });
  };

  const mapField = (indicators: any[], field: string) => {
    return indicators
      .map((ind: any) => INDICATOR_UI_RES[ind.indicatorType][field])
      .filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
  };

  return data.map((dataFragment: any) => {
    const { employee, ...field } = dataFragment;
    const keys = Object.keys(field);
    const foundKeys = keys.map((key) => findIndicatorKey(key));

    return {
      employeeId: employee.id,
      name: `${employee.lastName}, ${employee.firstName}`,
      description: {
        term: null,
        trans: getGroupedIndicatorDescription(
          foundKeys,
          dataFragment,
          indicator.group.condition,
        ),
      },
      targetPage: mapField(indicator.group.indicators, 'dataTargetPage'),
      targetTab: mapField(indicator.group.indicators, 'dataTargetTab'),
    };
  });
};

const uiRemapDataForIndicators = (
  data: any[],
  indicatorType: string,
): EmployeeListData[] =>
  data.map((dataFragment) => {
    const { employee, ...field } = dataFragment;
    const keys = Object.keys(field);
    const paramValue = keys ? dataFragment[keys[0]] : null;
    return {
      employeeId: employee.id,
      name: `${employee.lastName}, ${employee.firstName}`,
      description: {
        term: INDICATOR_UI_RES[indicatorType].dataDescriptionTerm,
        params: {
          [INDICATOR_UI_RES[indicatorType].dataDescriptionParam]: paramValue,
        },
      },
      targetPage: INDICATOR_UI_RES[indicatorType].dataTargetPage,
      targetTab: INDICATOR_UI_RES[indicatorType].unitTransLabel,
    };
  });

const uiRemapIndicatorsWithData = (indicatorReportResults: any[]) => {
  const indRepReduce = (acc: any, indicatorRes: any) => {
    const { indicator, data, count } = indicatorRes;
    if (indicator.group) {
      return Object.assign({}, acc, {
        [indicator.id]: {
          uid: indicator.id,
          id: indicator.id,
          title: indicator.name,
          active: indicator.active,
          type: 'SINGLE_NUMBER',
          config: {},
          data: uiRemapDataForGroupedIndicators(data, indicator),
          count: count ? count : data.length,
        },
      });
    }

    return Object.assign({}, acc, {
      [indicator.indicatorType]: {
        uid: indicator.id,
        id: indicator.indicatorType,
        titleTransLabel:
          INDICATOR_UI_RES[indicator.indicatorType].titleTransLabel,
        active: indicator.active,
        type: INDICATOR_UI_RES[indicator.indicatorType].type,
        config: {
          value:
            indicator.config[
              INDICATOR_UI_RES[indicator.indicatorType].configParam
            ],
          unitTransLabel:
            INDICATOR_UI_RES[indicator.indicatorType].unitTransLabel,
        },
        data: uiRemapDataForIndicators(data, indicator.indicatorType),
        count: count ? count : data.length,
      },
    });
  };

  return indicatorReportResults.reduce(indRepReduce, {});
};

const orderUiRemap = (
  remappedIndicatorResults: ISolaIntelSettings,
): ISolaIntelSettings => {
  let orderedUiIndicatorResults = {};
  if (remappedIndicatorResults.PEOPLE) {
    orderedUiIndicatorResults = Object.assign({}, orderedUiIndicatorResults, {
      PEOPLE: remappedIndicatorResults.PEOPLE,
    });
  }

  if (remappedIndicatorResults.REWARDING) {
    orderedUiIndicatorResults = Object.assign({}, orderedUiIndicatorResults, {
      REWARDING: remappedIndicatorResults.REWARDING,
    });
  }

  if (remappedIndicatorResults.PERFORMANCE) {
    orderedUiIndicatorResults = Object.assign({}, orderedUiIndicatorResults, {
      PERFORMANCE: remappedIndicatorResults.PERFORMANCE,
    });
  }

  if (remappedIndicatorResults.DEVELOPMENT) {
    orderedUiIndicatorResults = Object.assign({}, orderedUiIndicatorResults, {
      DEVELOPMENT: remappedIndicatorResults.DEVELOPMENT,
    });
  }

  if (remappedIndicatorResults.CUSTOM) {
    orderedUiIndicatorResults = Object.assign({}, orderedUiIndicatorResults, {
      CUSTOM: remappedIndicatorResults.CUSTOM,
    });
  }

  return orderedUiIndicatorResults;
};

const uiSimpleIndicatorsRemap = (indicators: any[]) => {
  return indicators.map((indicator: any) => {
    if (indicator.group) {
      return indicator;
    }

    return Object.assign({}, indicator, {
      titleTransLabel:
        INDICATOR_UI_RES[indicator.indicatorType].titleTransLabel,
      config: {
        value:
          indicator.config[
            INDICATOR_UI_RES[indicator.indicatorType].configParam
          ],
        unitTransLabel:
          INDICATOR_UI_RES[indicator.indicatorType].unitTransLabel,
      },
    });
  });
};

const uiSummaryRemap = (indicatorSummaries: any): ISolaIntelSettings => {
  const summaryRemapReduce = (
    acc: ISolaIntelSettings,
    indicatorCategory: string,
  ) => {
    const indicators = indicatorSummaries[indicatorCategory].map(
      (indicatorWrap: any) => {
        return Object.assign({}, indicatorWrap, {
          data: [],
        });
      },
    );

    return Object.assign({}, acc, {
      [indicatorCategory]: {
        type: indicatorCategory,
        titleTransLabel: INDICATOR_UI_RES[indicatorCategory].titleTransLabel,
        iconName: INDICATOR_UI_RES[indicatorCategory].iconName,
        activeIndicatorCount: indicatorSummaries[indicatorCategory].length,
        indicators: uiRemapIndicatorsWithData(indicators),
      },
    });
  };

  return Object.keys(indicatorSummaries).reduce(summaryRemapReduce, {});
};

const mergeIndicators = (
  indicatorList: any[],
  indicatorSummaries: any,
): any => {
  const mergeIndicatorsReduce = (acc: any, indicatorCategory: string) => {
    const inactiveIndicators = indicatorList
      .filter((ind: any) => {
        return !ind.active && ind.category === indicatorCategory;
      })
      .map((indicator: any) => {
        return {
          indicator,
          count: 0,
        };
      });

    const currentIndicators = indicatorSummaries[indicatorCategory]
      ? indicatorSummaries[indicatorCategory]
      : [];
    return Object.assign({}, acc, {
      [indicatorCategory]: currentIndicators.concat(inactiveIndicators),
    });
  };

  const categories = indicatorList
    .map((indicator: any) => {
      return indicator.category;
    })
    .filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

  return categories.reduce(mergeIndicatorsReduce, {});
};

const addReportData = (
  currentIndicators: ISolaIntelSettings,
  indicatorReportData: any,
): ISolaIntelSettings => {
  const addReportDataReduce = (
    acc: ISolaIntelSettings,
    indicatorCategory: string,
  ) => {
    if (indicatorReportData.indicator.category !== indicatorCategory) {
      return Object.assign({}, acc, {
        [indicatorCategory]: currentIndicators[indicatorCategory],
      });
    }

    const indicators = currentIndicators[indicatorCategory].indicators;
    const currentIndicatorType = indicatorReportData.indicator.indicatorType;
    const data = indicatorReportData.indicator.group
      ? uiRemapDataForGroupedIndicators(
          indicatorReportData.data,
          indicatorReportData.indicator,
        )
      : uiRemapDataForIndicators(
          indicatorReportData.data,
          currentIndicatorType,
        );
    const currentIndicatorKey = indicatorReportData.indicator.group
      ? indicatorReportData.indicator.id
      : currentIndicatorType;

    return Object.assign({}, acc, {
      [indicatorCategory]: Object.assign(
        {},
        currentIndicators[indicatorCategory],
        {
          indicators: Object.assign({}, indicators, {
            [currentIndicatorKey]: Object.assign(
              {},
              indicators[currentIndicatorKey],
              {
                data,
              },
            ),
          }),
        },
      ),
    });
  };

  return Object.keys(currentIndicators).reduce(addReportDataReduce, {});
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_INDICATORS_SUMMARY:
    case FETCH_INDICATORS:
      return state
        .set('isFetching', true)
        .set('currentUnitId', action.payload)
        .set('error', null)
        .set('allIndicatorReportResults', []);
    case FETCH_INDICATORS_SUCCESS:
      return state
        .set('isFetching', false)
        .set('indicators', action.payload)
        .set('uiMappedIndicators', uiSimpleIndicatorsRemap(action.payload));
    case FETCH_INDICATORS_ERROR:
    case FETCH_INDICATOR_REPORT_ERROR:
    case FETCH_INDICATORS_SUMMARY_ERROR:
    case UPDATE_INDICATOR_ERROR:
    case REMOVE_GROUPED_INDICATOR_ERROR:
    case CREATE_GROUPED_INDICATOR_ERROR:
      return state.set('isFetching', false).set('error', action.payload);
    case FETCH_INDICATOR_REPORT:
    case UPDATE_INDICATOR:
    case REMOVE_GROUPED_INDICATOR:
      return state.set('isFetching', true).set('error', null);
    case FETCH_INDICATOR_REPORT_SUCCESS:
      const oldReportResults = state.get('uiMappedIndicatorResults');
      const newReportResults = addReportData(oldReportResults, action.payload);
      return state
        .set('isFetching', false)
        .set('uiMappedIndicatorResults', orderUiRemap(newReportResults));
    case FETCH_INDICATORS_SUMMARY_SUCCESS:
      const indicatorList = state.get('indicators') as any[];
      const mergedIndicators = mergeIndicators(indicatorList, action.payload);
      const indicatorSummaries = uiSummaryRemap(mergedIndicators);
      return state
        .set('isFetching', false)
        .set('uiMappedIndicatorResults', orderUiRemap(indicatorSummaries));
    case UPDATE_INDICATOR_SUCCESS:
    case REMOVE_GROUPED_INDICATOR_SUCCESS:
      return state.set('isFetching', false);
    case CREATE_GROUPED_INDICATOR_SUCCESS:
      const oldIndicators = state.get('indicators') as any[];
      const newIndicators = oldIndicators.concat([action.payload]);
      return state
        .set('isFetching', false)
        .set('indicators', newIndicators)
        .set('uiMappedIndicators', uiSimpleIndicatorsRemap(newIndicators));
    case UPDATE_GROUPED_INDICATOR_SUCCESS:
      const indicators = state.get('indicators') as any[];
      const updatedIndicators = indicators.map((indicator) => {
        if (indicator.id === action.payload.id) {
          return action.payload;
        }

        return indicator;
      });

      return state
        .set('isFetching', false)
        .set('indicators', updatedIndicators)
        .set('uiMappedIndicators', uiSimpleIndicatorsRemap(updatedIndicators));
    default:
      return state;
  }
};

export const fetchIndicatorReportResults = (
  indicatorId: string,
  unitId: string,
): any => {
  return (dispatch: Dispatch) => {
    dispatch({ type: FETCH_INDICATOR_REPORT });
    Service.get(
      API.solaIntel.indicatorReportResults(indicatorId, unitId),
      (indicatorReportResults: any) =>
        dispatch({
          type: FETCH_INDICATOR_REPORT_SUCCESS,
          payload: indicatorReportResults,
        }),
      (error: any) =>
        dispatch({ type: FETCH_INDICATOR_REPORT_ERROR, payload: error }),
    );
  };
};

export const fetchIndicatorList = (
  unitId: string,
  callback?: Function,
): any => {
  return (dispatch: Dispatch) => {
    dispatch({ type: FETCH_INDICATORS, payload: unitId });
    Service.get(
      API.solaIntel.allIndicators,
      (indicators: any[]) => {
        dispatch({ type: FETCH_INDICATORS_SUCCESS, payload: indicators });
        if (callback) {
          dispatch(fetchIndicatorsSummary(unitId, callback));
        } else {
          dispatch(fetchIndicatorsSummary(unitId));
        }
      },
      (error: any) =>
        dispatch({ type: FETCH_INDICATORS_ERROR, payload: error }),
    );
  };
};

export const fetchIndicatorsSummary = (
  unitId: string,
  callback?: Function,
): any => {
  return (dispatch: Dispatch) => {
    dispatch({ type: FETCH_INDICATORS_SUMMARY, payload: unitId });
    Service.get(
      API.solaIntel.allIndicatorsSummary(unitId),
      (indicators: any) => {
        dispatch({
          type: FETCH_INDICATORS_SUMMARY_SUCCESS,
          payload: indicators,
        });
        if (callback) {
          callback();
        }
      },
      (error: any) =>
        dispatch({ type: FETCH_INDICATORS_SUMMARY_ERROR, payload: error }),
    );
  };
};

export const fetchIndicators = (): any => {
  return (dispatch: Dispatch) => {
    dispatch({ type: FETCH_INDICATOR_REPORT });
    Service.get(
      API.solaIntel.allIndicators,
      (indicators: any) =>
        dispatch({ type: FETCH_INDICATORS_SUCCESS, payload: indicators }),
      (error: any) =>
        dispatch({ type: FETCH_INDICATORS_ERROR, payload: error }),
    );
  };
};

export const updateIndicator = (
  indicatorCategory: any,
  currentUnitId: string,
) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: UPDATE_INDICATOR });
    const keys = Object.keys(indicatorCategory.indicators);
    const promises = keys.map((key) => {
      const indicator = indicatorCategory.indicators[key];
      if (!indicator.id || !INDICATOR_UI_RES[indicator.id].configParam) {
        return null;
      }

      return new Promise((resolve, reject) => {
        Service.post(
          API.solaIntel.updateIndicator(indicator.uid),
          {
            config: {
              [INDICATOR_UI_RES[indicator.id].configParam]:
                indicator.config.value,
            },
            active: indicator.active,
          },
          (resp: any, _headers: any) => {
            dispatch({ type: UPDATE_INDICATOR_SUCCESS, payload: resp });
            resolve(resp);
          },
          (error: any) => {
            dispatch({ type: UPDATE_INDICATOR_ERROR, payload: error });
            reject(error);
          },
        );
      });
    });

    Promise.all(promises).then(() =>
      dispatch(fetchIndicatorList(currentUnitId)),
    );
  };
};

export const createGroupedIndicator = (
  groupedIndicator: IGroupedIndicatorCreate,
) => {
  return (dispatch: Dispatch, getState: Function) => {
    const currentUnitId = getState().solaIntel.get('currentUnitId');
    dispatch({ type: CREATE_GROUPED_INDICATOR });
    Service.post(
      API.solaIntel.createGroupedIndicator,
      groupedIndicator,
      (resp: any, _headers: any) => {
        dispatch({ type: CREATE_GROUPED_INDICATOR_SUCCESS, payload: resp });
        dispatch(fetchIndicatorList(currentUnitId));
      },
      (error: any) => {
        dispatch({ type: CREATE_GROUPED_INDICATOR_ERROR, payload: error });
      },
    );
  };
};

export const updateGroupedIndicator = (
  id: string,
  groupedIndicator: IGroupedIndicatorCreate,
) => {
  return (dispatch: Dispatch, getState: Function) => {
    const currentUnitId = getState().solaIntel.get('currentUnitId');
    dispatch({ type: UPDATE_GROUPED_INDICATOR });
    Service.post(
      API.solaIntel.updateGroupedIndicator(id),
      groupedIndicator,
      (resp: any, _headers: any) => {
        dispatch({ type: UPDATE_GROUPED_INDICATOR_SUCCESS, payload: resp });
        dispatch(fetchIndicatorList(currentUnitId));
      },
      (error: any) => {
        dispatch({ type: UPDATE_GROUPED_INDICATOR_ERROR, payload: error });
      },
    );
  };
};

export const removeGroupedIndicator = (id: string) => {
  return (dispatch: Dispatch, getState: Function) => {
    const currentUnitId = getState().solaIntel.get('currentUnitId');
    dispatch({ type: REMOVE_GROUPED_INDICATOR });
    Service.delete(
      API.solaIntel.deleteGroupedIndicator(id),
      {},
      (resp: any, _headers: any) => {
        dispatch({ type: REMOVE_GROUPED_INDICATOR_SUCCESS, payload: resp });
        dispatch(fetchIndicatorList(currentUnitId));
      },
      (error: any) => {
        dispatch({ type: REMOVE_GROUPED_INDICATOR_ERROR, payload: error });
      },
    );
  };
};

export default reducer;

import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const surveyResultsDialogStyle: StyleRulesCallback = (theme: Theme) => {
  return ({
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    title: {
      flex: '1 1 0',
    },
    headerActions: {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'nowrap',

      '& > *:not(:last-child)': {
        marginRight: theme.spacing.unit,
      },
    },
    ['@media print']: {
      '@global': {
        'html, body': {
          overflow: 'initial !important'
        },
        header: {
          display: 'none'
        }
      },
      avoidBreak: {
        pageBreakInside: 'avoid',
        display: 'inline-block',
        verticalAlign: 'top'
      },
      avoidBreakContainer: {
        display: 'block'
      },
      dialogRoot: {
        position: 'initial',
      },
      dialogContainer: {
        height: 'auto',
      },
      dialogPaperFullScreen: {
        minHeight: '100vh'
      }
    },
  });
};

export default surveyResultsDialogStyle;

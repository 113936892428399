import * as React from 'react';
import { compose } from 'recompose';
import {
  withStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  FormControlLabel,
  IconButton,
  WithStyles,
  Tooltip,
  Modal,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ComponentListing from '../ComponentListing/ComponentListing';
import translate from '@/app/utils/translate';
import sectionListingStyle from './sectionListingStyle';
import AddSection from '../AddSection/AddSection';
import { ActiveTemplate } from '@/app/components/JobEvaluation/types';

type State = {
  expanded: string;
  sectionEditModalOpen: boolean;
};

type DataType = {
  key: string;
  label: string;
  componentsList: any;
};

type IncomingProps = {
  list: DataType[];
  customStyle: boolean;
  addComponentToSection: (
    templateId: string,
    sectionId: string,
    list: Object[],
  ) => void;
  updateSection: (id: String, template: Object) => void;
  updateSectionComponent: (
    templateId: string,
    sectionId: string,
    object: Object,
  ) => void;
  readyToEditTemplate: ActiveTemplate[];
  section: any;
  deleteSection: (templateId: string, sectionId: string) => void;
  orderComponents: (sectionId: string, object: Object[]) => void;
  deleteComponent: (
    templateId: string,
    sectionId: string,
    componentIds: [],
  ) => void;
  newSection: boolean;
  readonly?: boolean;
  onItemSelection?: (sectionId: string, selectedItemIds: string[]) => void;
  addTemplateSection: (id: String, template: Object) => void;
  newSectionAdded: () => void;
};

type PropsType = IncomingProps & WithStyles<typeof sectionListingStyle>;

class SectionListing extends React.Component<PropsType> {
  state: State = {
    expanded: null,
    sectionEditModalOpen: false,
  };

  handleChange = (panel: string) => (_event: object, expanded: boolean) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  openSectionEditModal = () => {
    this.setState({ sectionEditModalOpen: true });
  };

  closeSectionEditModal = () => {
    this.setState({ sectionEditModalOpen: false });
  };

  render() {
    const { expanded, sectionEditModalOpen } = this.state;
    const {
      classes,
      list,
      customStyle,
      addComponentToSection,
      updateSection,
      updateSectionComponent,
      readyToEditTemplate,
      section,
      orderComponents,
      deleteComponent,
      newSection,
      readonly,
      onItemSelection,
      addTemplateSection,
      newSectionAdded,
      deleteSection,
    } = this.props;
    const sections = readyToEditTemplate[0].sections;
    const lastSectionList = sections[sections.length - 1].id;

    return (
      <div style={customStyle ? { padding: '20px' } : {}}>
        {list.map((l: DataType) => (
          <ExpansionPanel
            key={l.key}
            defaultExpanded={lastSectionList === l.key || expanded === l.key}
            onChange={this.handleChange(l.key)}
          >
            <ExpansionPanelSummary>
              <Typography
                variant="h6"
                style={{ cursor: expanded ? 'pointer' : 'auto' }}
              >
                {expanded ? (
                  <IconButton className={classes.iconButton}>
                    <ExpandMoreIcon className={classes.icon} fontSize="small" />
                  </IconButton>
                ) : (
                  <IconButton className={classes.iconButton}>
                    <ExpandMoreIcon fontSize="small" />
                  </IconButton>
                )}
                <label>{l.label}</label>
                {!readonly && (
                  <>
                    <FormControlLabel
                      aria-label="Acknowledge"
                      onClick={(event) => event.stopPropagation()}
                      onFocus={(event) => event.stopPropagation()}
                      control={
                        <IconButton onClick={() => this.openSectionEditModal()}>
                          <Tooltip title={translate.t('laEdit')}>
                            <EditIcon fontSize="small" />
                          </Tooltip>
                        </IconButton>
                      }
                      label=""
                      className={classes.noMargin}
                    />
                    <FormControlLabel
                      aria-label="ForDel"
                      onClick={(event) => event.stopPropagation()}
                      onFocus={(event) => event.stopPropagation()}
                      control={
                        <IconButton
                          onClick={() =>
                            deleteSection(readyToEditTemplate[0].id, section.id)
                          }
                        >
                          <Tooltip title={translate.t('laDelete')}>
                            <DeleteIcon fontSize="small" />
                          </Tooltip>
                        </IconButton>
                      }
                      label=""
                      className={classes.noMargin}
                    />
                  </>
                )}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <ComponentListing
                readyToEditTemplate={readyToEditTemplate}
                section={section}
                components={l.componentsList}
                expanded={!!expanded || !!newSection || !!lastSectionList}
                addComponentToSection={addComponentToSection}
                updateSectionComponent={updateSectionComponent}
                orderComponents={orderComponents}
                deleteComponent={deleteComponent}
                readonly={readonly}
                onItemSelection={onItemSelection}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
        <Modal
          open={sectionEditModalOpen}
          onClose={() => this.closeSectionEditModal()}
        >
          <AddSection
            editMode={true}
            readyToEditTemplate={readyToEditTemplate}
            section={section}
            updateSection={updateSection}
            handleModalClose={this.closeSectionEditModal}
            addTemplateSection={addTemplateSection}
            newSectionAdded={newSectionAdded}
          />
        </Modal>
      </div>
    );
  }
}

const enhance = compose<PropsType, IncomingProps>(
  withStyles(sectionListingStyle),
);

export default enhance(SectionListing);

import * as React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import * as moment from 'moment';
import { selectAllEnums, getEnumName } from '@/app/redux/enums';
import translate from '@/app/utils/translate';
import ReportView from '../components/ReportView';
import KendoProfileImageCell from '../components/KendoProfileImageCell';
import KendoGenericUrlCell from '../components/KendoGenericUrlCell';
import { IReportConfig } from '../ReportConfig';
import { IReportSettings } from '../ReportSettings';

class DevelopmentPlansReport extends React.Component<any, any> {
  private config: IReportConfig = {
    id: 'DEVELOPMENT_PLANS',
    report: 'developmentPlans',
    titleLabel: 'laNewDevPlans',
    access: 'standard',
    columns: [
      { field: 'Picture', title: translate.t('laPicture'), width: '80px', cell: kendoProps => {
        return (
          <KendoProfileImageCell
            {...kendoProps}
          />
        );
      }},
      { field: 'Name', title: translate.t('laName'), width: '200px', cell: (props) => {
        return (
          <KendoGenericUrlCell
            page={'page-people'}
            empId={props.dataItem.EmpId}
            text={props.dataItem.Name}
          />
        );
      }},
      { field: 'EmpId', title: '' },
      { field: 'EmpNumber', title: translate.t('laEmpNumber'), width: '150px' },
      { field: 'Position', title: translate.t('laPosition'), width: '150px' },
      { field: 'DevFocusShortTerm', title: translate.t('laDevFocusAreaInTheShortTerm'), width: '300px', cell: (props) => {
        return (
          <KendoGenericUrlCell
            page={'page-development'}
            empId={props.dataItem.EmpId}
            text={props.dataItem.DevFocusShortTerm}
            tab={'idTabDevPlan'}
          />
        );
      }},
      { field: 'DevArea', title: translate.t('laDevArea'), width: '200px' },
      { field: 'DevAction', title: translate.t('laDevAction'), width: '200px' },
      { field: 'DevMethod', title: translate.t('laMethod'), width: '100px' },
      { field: 'DevGoal', title: translate.t('laGoal'), width: '200px' },
      { field: 'EndDate', title: translate.t('laEndDate'), width: '100px' },
      { field: 'Info', title: translate.t('laInfo'), width: '300px' },
      { field: 'HasGoals', title: '' },
      { field: 'HasReviews', title: '' },
      { field: 'CareerAspiration', title: translate.t('laCareerAspirationInTheLongTerm') , width: '300px', cell: (props) => {
        return (
          <KendoGenericUrlCell
            page={'page-development'}
            empId={props.dataItem.EmpId}
            text={props.dataItem.CareerAspiration}
            tab={'idTabDevPlan'}
          />
        );
      }},
      { field: 'AddDevPlan', title: translate.t('laAdditionalDevPlan') , width: '300px', cell: (props) => {
        return (
          <KendoGenericUrlCell
            page={'page-development'}
            empId={props.dataItem.EmpId}
            text={props.dataItem.AddDevPlan}
            tab={'idTabDevPlan'}
          />
        );
      }}
    ],
    excelExportColumns: [
      { field: 'EmpId', title: 'Emp ID' },
      { field: 'Name', title: 'Name' },
      { field: 'EmpNumber', title: 'Emp Number' },
      { field: 'Position', title: 'Position' },
      { field: 'DevFocusShortTerm', title: '' },
      { field: 'DevArea', title: 'Development area' },
      { field: 'DevAction', title: 'Development action' },
      { field: 'DevMethod', title: 'Method' },
      { field: 'DevGoal', title: 'Development Goal' },
      { field: 'EndDate', title: 'End Date' },
      { field: 'Info', title: 'Info' },
      { field: 'CareerAspiration', title: 'Career Aspiration' },
      { field: 'AddDevPlan', title: 'Additional development plan' },
    ],
    filters: {
      scopeFilter: {
        enabled: true,
        accessRights: {
          company: ['HR_ADMIN', 'MANAGER'],
          dManagerAll: ['DEPUTY_MANAGER'],
          dManagerDirect: ['DEPUTY_MANAGER'],
          managerAll: ['MANAGER'],
          managerDirect: ['MANAGER'],
          unitAll: ['MANAGER', 'DEPUTY_MANAGER', 'HR_STAFF', 'HR_ADMIN'],
          unitDirect: ['MANAGER', 'DEPUTY_MANAGER', 'HR_STAFF', 'HR_ADMIN'],
        },
      },
      customFilters: [
        {
          type: 'CHECKBOX',
          params: {
            selectedSettingName: 'laNoPlans',
            fieldName: 'HasGoals',
            operator: 'neq',
            buttonLabel: 'laNoPlans',
            value: false,
          },
        },
        {
          type: 'CHECKBOX',
          params: {
            selectedSettingName: 'laNoReviews',
            fieldName: 'HasReviews',
            operator: 'neq',
            buttonLabel: 'laNoReviews',
            value: false,
          },
        }
      ],
      dateFilter: {
        enabled: false,
      },
    },
  };

  private defaultSettings: IReportSettings = {
    report: 'pdd/development',
    scope: {
      type: '',
      range: {
        startDate: moment().add(1, 'day').subtract(6, 'month'),
        endDate: moment().add(1, 'day'),
      }
    },
    custom: {
      laNoPlans: false,
      laNoReviews: false
    },
  };

  mapData = (origData: any): any => {
    const { allEnums } = this.props;
    const getEnumValue = (enumCode: string, enumGroupKey: string) => {
      if (!enumCode || !enumGroupKey) {
        return '';
      }

      if (!allEnums[enumGroupKey]) {
        return enumCode;
      }

      return getEnumName(enumGroupKey, enumCode, allEnums);
    };

    const expandCallback = (acc: any[], or: any) => {
      const {
        fEmpGoalSettings,
        fEmpGoalReviews,
        ...expDataFragment
      } = or;

      const empGoalCallback = (accumulator: any[], currentGoal: any) => {
        return accumulator.concat(currentGoal.devPlanActivities.map((currentActivity: any) => ({
          ...expDataFragment,
          ...currentActivity,
          ...currentGoal.devPlanDescription,
          ...currentGoal.transactionInfo,
          hasReviews: !!fEmpGoalSettings.length ? true : null,
          hasGoals: !!fEmpGoalReviews.length ? true : null,
        })));
      };

      const mappedGoals = fEmpGoalSettings.reduce(empGoalCallback, []);
      const mappedReviews = fEmpGoalReviews.reduce(empGoalCallback, []);

      const result = acc
        .concat(mappedGoals)
        .concat(mappedReviews);

      if (!mappedGoals.length && !mappedReviews.length) {
        return result.concat([expDataFragment]);
      }

      return result;
    };

    const expandedData = origData.reduce(expandCallback, []);

    const getAdditionalDevPlans = (e: any) => {
      let addPlans = [];
      if (e.fDevPlanDescHorizontalInterest) {
        addPlans.push(translate.t('laInterestedInInternationalAssignments'));
      }

      if (e.fDevPlanDescInternationalInterest) {
        addPlans.push(translate.t('laInterestedInHorizontalDevelopment'));
      }

      return addPlans.join(', ');
    };

    return expandedData.map((employee: any) => {
      return {
        Picture: employee.fEmpId,
        Name: employee.fEmpLastName + ', ' + employee.fEmpFirstName,
        EmpId: employee.fEmpId,
        EmpNumber: employee.fEmpPersonNumber,
        Position: getEnumValue(employee.fEmpPosition, 'EMPLOYEE_POSITION'),
        DevFocusShortTerm: employee.fDevPlanDescDevelopmentFocus || '',
        DevArea: employee.fDevPlanActDevArea || '',
        DevAction: employee.fDevPlanActDevAction || '',
        DevMethod: employee.fDevPlanActDevMethod || '',
        DevGoal: getEnumValue(employee.fDevPlanActDevGoal, 'DEV_ACTIVITIES_METHOD'),
        EndDate: employee.fDevPlanActEndDate ? moment(employee.fDevPlanActEndDate, 'YYYY-MM-DD').format('DD.MM.YYYY') : '',
        HasGoals: employee.hasGoals,
        HasReviews: employee.hasReviews,
        Info: employee.fDevPlanActInfo || '',
        CareerAspiration: employee.fDevPlanDescCareerAspirations,
        AddDevPlan: getAdditionalDevPlans(employee),
        HasAccount: employee.fEmpHasAccount
      };
    });
  }

  render() {
    return (
      <ReportView
        moduleId="development"
        reportId="DEVELOPMENT_PLANS"
        config={this.config}
        defaultSettings={this.defaultSettings}
        kendoGrouping={[]}
        onDataMapping={this.mapData}
        onGetCustomFilters={() => this.config.filters.customFilters}
        sort={[{ field: 'Name', dir: 'asc' }]}
        exportFileName={translate.t('laNewDevPlans')}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  allEnums: selectAllEnums(state),
});

const enhance = compose<any, any>(
  connect(mapStateToProps),
);

export default enhance(DevelopmentPlansReport);

import { StyleRulesCallback, Theme } from '@material-ui/core';

const styles: StyleRulesCallback = (theme: Theme) => ({
  button: {
    fontWeight: 'normal',
    marginLeft: -1 * theme.spacing.unit,
    textAlign: 'left',
    textTransform: 'none',
  },
});

export default styles;

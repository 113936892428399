import {
  FormControl,
  MenuItem,
  Select,
  Typography,
  withStyles,
} from '@material-ui/core';
import * as React from 'react';
import { compose } from 'recompose';
import {
  TemplateBaseComponentOptionType,
  TemplateBaseComponentType,
  TemplateComponentTypeKey,
  TemplateInputComponentPropsType,
} from '@/app/components/TemplateComponents/form/InputComponents';
import { resolveComponentResponse } from '@/app/components/TemplateComponents/form/InputComponents/data';
import dropdownComponentStyles from './dropdownComponentStyles';

export type DropdownComponentProps = TemplateInputComponentPropsType & {
  component: TemplateBaseComponentType & {
    type: TemplateComponentTypeKey.DROPDOWN;
    options: TemplateBaseComponentOptionType[];
  };
};

const DropdownComponent = (props: DropdownComponentProps) => {
  const {
    templateId,
    component,
    responses,
    editMode,
    onUpdate,
    classes,
  } = props;

  const existingResponse = resolveComponentResponse(component, responses, '');

  const handleChange = (event: any) => {
    if (!!onUpdate) {
      onUpdate(templateId, {
        componentId: component.id,
        response: [parseInt(event.target.value, 10)],
        type: component.type,
      });
    }
  };

  return (
    component.type === TemplateComponentTypeKey.DROPDOWN && (
      <div key={component.id + '_parent' + '_' + editMode}>
        <Typography variant="subtitle2" className={classes.typography}>
          {component.name}
        </Typography>
        <FormControl className={classes.formControl}>
          <Select
            value={existingResponse[0] || ''}
            onChange={handleChange}
            disabled={!editMode}
          >
            {component.options.map((option: any) => (
              <MenuItem key={option.id} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    )
  );
};

const enhance = compose<any, any>(withStyles(dropdownComponentStyles));

export default enhance(DropdownComponent);

import * as React from 'react';
import { Field, EventWithDataHandler } from 'redux-form';
import {
  RenderTextField,
  RenderRichTextField,
  RenderSelectField,
  RenderDateField,
  RenderCheckboxField,
  RenderSwitchField,
  RenderHiddenField,
} from '@/app/components/FormFieldBuilders';
import { INPUT_TYPE } from '@/app/utils/helper';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import styles from '@/app/components/FormFields/formFieldsStyle';
import textFieldStyles from '@/app/components/FormFields/textFieldStyle';
import {
  normalizePhone,
  normalizeNumber,
  normalizeInteger,
} from '@/app/utils/normalize';
import TooltippedTruncatedText from '../TooltippedTruncatedText/TooltippedTruncatedText';

/**
 * input model
 * @type INPUT_TYPE
 * @code input identifier
 * @name post param name
 * @label input label
 * @childrenInputs children element
 * @order sort order
 * @required if it is required
 * @onChange custom onchange event
 * @configs extra props passed to input component
 */
export type InputType = {
  type: INPUT_TYPE;
  code: string; // this is the identifier
  name: string;
  label: string;
  childrenInputs?: ChildInputType[];
  order: number;
  required?: boolean;
  onChange?: EventWithDataHandler<React.FormEvent<HTMLSelectElement>>;
  onBlur?: EventWithDataHandler<React.FormEvent<HTMLSelectElement>>;
  configs?: Object;
  value?: any;
  multiple?: boolean;
  disabled?: boolean;
  helpText?: any;
  limit?: number;
};

export interface ChildInputType {
  label: string;
  value: string;
  seq?: number;
}

interface Props extends WithStyles<typeof styles> {
  inputs: Array<InputType>;
}

interface TextFieldProps extends WithStyles<typeof textFieldStyles> {
  input: InputType;
  type: string;
  multiline: boolean;
}

const getTextFieldNormalizer = (type: string, configs: any = null) => {
  if (configs && configs.normalize instanceof Function) {
    return configs.normalize;
  } else if (type === 'number') {
    return normalizeNumber;
  } else if (type === 'integer') {
    return normalizeInteger;
  } else if (type === 'tel') {
    return normalizePhone;
  }

  return null;
};

const TextFieldComponent: React.FunctionComponent<TextFieldProps> = (props) => {
  const { input, multiline, classes } = props;
  let { type } = props;
  const { field } = classes;
  const {
    required = false,
    code,
    label,
    name,
    onChange,
    onBlur,
    helpText,
    configs,
    ...fieldProps
  } = input;
  let normalize = getTextFieldNormalizer(type, configs);
  if (type === 'number') {
    type = 'text';
  } else if (type === 'integer') {
    type = 'number';
  }

  return (
    <div className={field}>
      <Field
        key={code}
        component={RenderTextField}
        required={required}
        type={type}
        label={<TooltippedTruncatedText>{label}</TooltippedTruncatedText>}
        name={name}
        rows={multiline ? 4 : null}
        normalize={normalize}
        multiline={multiline}
        rowsMax={multiline ? 4 : null}
        fullWidth
        onChange={onChange ? onChange : null}
        onBlur={onBlur ? onBlur : null}
        helpText={helpText}
        {...fieldProps}
      />
    </div>
  );
};

const getRichTextField = (input: InputType) => {
  const { name, required = false, code, label, onChange, helpText } = input;

  return (
    <Field
      key={code}
      component={RenderRichTextField}
      name={name}
      required={required}
      label={label}
      onChange={onChange ? onChange : null}
      helpText={helpText}
    />
  );
};

const getSelectBox = (input: InputType, multiple: boolean = false) => {
  const {
    name,
    required = false,
    code,
    label,
    onChange,
    childrenInputs,
    helpText,
  } = input;

  return (
    <Field
      key={code}
      required={required}
      component={RenderSelectField}
      name={name}
      type={multiple ? 'select-multiple' : 'select'}
      isMulti={multiple}
      onChange={onChange ? onChange : null}
      options={childrenInputs}
      placeholder={label}
      closeMenuOnSelect={!multiple}
      helpText={helpText}
      {...input.configs}
    />
  );
};

const getDateField = (input: InputType) => {
  const {
    name,
    required = false,
    code,
    label,
    onChange,
    helpText,
    disabled,
  } = input;

  return (
    <Field
      key={code}
      required={required}
      component={RenderDateField}
      name={name}
      label={label}
      onChange={onChange ? onChange : null}
      helpText={helpText}
      disabled={disabled}
      {...input.configs}
    />
  );
};

const getCheckboxField = (input: InputType) => {
  const { name, code, label, helpText, disabled, onChange } = input;

  return (
    <Field
      key={code}
      component={RenderCheckboxField}
      name={name}
      label={label}
      color="primary"
      helpText={helpText}
      disabled={disabled}
      onChange={onChange}
    />
  );
};

const getSwitchField = (input: InputType) => {
  const { name, code, label, helpText } = input;

  return (
    <Field
      key={code}
      component={RenderSwitchField}
      name={name}
      label={label}
      color="primary"
      helpText={helpText}
    />
  );
};

const getHiddenField = (input: InputType) => {
  const {
    name,
    required = false,
    code,
    label,
    onChange,
    childrenInputs = [],
    helpText,
    multiple = true,
  } = input;

  return (
    <Field
      key={code}
      required={required}
      component={RenderHiddenField}
      name={name}
      type={'select-multiple'}
      isMulti={false}
      onChange={onChange ? onChange : null}
      options={childrenInputs}
      placeholder={label}
      closeMenuOnSelect={!multiple}
      helpText={helpText}
      {...{
        isSearchable: false,
        isDisabled: true,
        isCreatable: false,
      }}
    />
  );
};

const TextField = withStyles(textFieldStyles)(TextFieldComponent);

const getInputField = (input: InputType) => {
  switch (input.type) {
    case INPUT_TYPE.TEXT:
      return <TextField input={input} type={'text'} multiline={false} />;
    case INPUT_TYPE.DATE:
      return getDateField(input);
    case INPUT_TYPE.EMAIL:
      return <TextField input={input} type={'email'} multiline={false} />;
    case INPUT_TYPE.TEL:
      return <TextField input={input} type={'tel'} multiline={false} />;
    case INPUT_TYPE.NUMBER:
      return <TextField input={input} type={'number'} multiline={false} />;
    case INPUT_TYPE.INTEGER:
      return <TextField input={input} type={'integer'} multiline={false} />;
    case INPUT_TYPE.CHECKBOX:
      return getCheckboxField(input);
    case INPUT_TYPE.TEXTAREA:
      return <TextField input={input} type={'text'} multiline={true} />;
    case INPUT_TYPE.SELECT:
      return getSelectBox(input);
    case INPUT_TYPE.MULTISELECT:
      return getSelectBox(input, true);
    case INPUT_TYPE.RICHTEXT:
      return getRichTextField(input);
    case INPUT_TYPE.SWITCH:
      return getSwitchField(input);
    case INPUT_TYPE.HIDDEN:
      return getHiddenField(input);
    default:
      return null;
  }
};

/**
 * Based on the inputs props, build mui inputs
 */
const FormFields: React.FunctionComponent<Props> = (props) => {
  return (
    <>
      {props.inputs.map((input: InputType, index: number) => {
        return (
          <React.Fragment key={index}>{getInputField(input)}</React.Fragment>
        );
      })}
    </>
  );
};

export default withStyles(styles)(FormFields);

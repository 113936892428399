import * as React from 'react';
import Cookies from 'universal-cookie';
import { showEmployee } from '@/old/utils/helper';
import EmployeeImage from '@/app/components/EmployeeImage/EmployeeImage';
import { createStyles, withStyles } from '@material-ui/core';
import translate from '@/app/utils/translate';

const styles = () =>
  createStyles({
    externalSign: {
      fontSize: '10px',
      fontWeight: 'bold',
      color: '#05A4D8 !important',
      float: 'left',
    },
  });

class KendoProfileImageCell extends React.Component<any, any> {
  render() {
    if (this.props.rowType !== 'data') {
      return null;
    }

    const handleOnCLick = () => {
      const cookies = new Cookies();
      cookies.set('sola-current-page', 'people-page');
      showEmployee(this.props.dataItem.EmpId);
    };

    return (
      <td
        style={{ textAlign: 'center', cursor: 'pointer' }}
        onClick={handleOnCLick}
      >
        {!this.props.dataItem.HasAccount && (
          <span className={this.props.classes.externalSign}>
            {translate.t('ext')}
          </span>
        )}
        <EmployeeImage isRound={true} employeeId={this.props.dataItem.EmpId} />
      </td>
    );
  }
}

export default withStyles(styles)(KendoProfileImageCell);

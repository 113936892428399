import { List as iList, Map as iMap } from 'immutable';
import { THROW_ERROR } from './error';
import API from '@/app/api/externalAPIs';
import Service from '@/app/utils/service';

const FETCH_LANGUAGES_PENDING = '@@solaforce/languages/FETCH_LANGUAGES_PENDING';
const FETCH_LANGUAGES_FULFILLED = '@@solaforce/languages/FETCH_LANGUAGES_FULFILLED';
const FETCH_LANGUAGES_REJECTED = '@@solaforce/languages/FETCH_LANGUAGES_REJECTED';

export type LanguagesType = {
  code: string,
  name: string,
  isDefault: boolean
};

const initialState = iMap({
                            isFetching: false,
  allLanguages: iList<LanguagesType>(),
});

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_LANGUAGES_PENDING:
      return state.set('isFetching', true);
    case FETCH_LANGUAGES_FULFILLED:
      return state
          .set('allLanguages', action.languages)
          .set('isFetching', false);
    case FETCH_LANGUAGES_REJECTED:
      return state.set('isFetching', false);
    default:
      return state;
  }
};

export const fetchLanguages = () => {
  return (dispatch: any) => {
    dispatch({ type: FETCH_LANGUAGES_PENDING });

    Service.get(
      API.companyLanguages,
      (data: any) => {
        let languages: LanguagesType[] = [];
        data.forEach((d: any) => {
          const language: LanguagesType = {
            code: d.fComLanguageCode,
            name: d.fComLanguageName,
            isDefault: d.fComLanguageIsDefault
          };
          languages.push(language);
        });
        dispatch({ type: FETCH_LANGUAGES_FULFILLED, languages });
      },
      (error: any) => {
        dispatch({ type: FETCH_LANGUAGES_REJECTED });
        dispatch({ type: THROW_ERROR, error });
      },
    );
  };
};

export default reducer;

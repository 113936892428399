export enum CustomFieldType {
  BOOLEAN = 'BOOLEAN',
  STRING = 'STRING',
  DATE = 'DATE',
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
  ENUM = 'ENUM',
  MULTISELECT = 'MULTISELECT',
}

export interface CustomField {
  groupName: string;
  code: string;
  description: string;
  name: string;
  inputType: {
    label: string;
    value: CustomFieldType;
  };
  countries?: Array<{
    label: string;
    value: string;
  }>;
}

export interface StateProps {
  allEnums: any[];
  customEntities: CustomField[];
}

export const mapStateToProps = (state: any): StateProps => {
  const allEnums = state.enums.get('allEnums');

  return {
    allEnums,
    customEntities: allEnums.get('CUSTOM_ENTITY_UNIT_POSITION'),
  };
};

import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const cardHeight = 192;
const cardWidth = 340;

const donutChartStyle: StyleRulesCallback = (theme: Theme) => {
  return ({
    donutChartCard: {
      height: '100%',
      minHeight: `${cardHeight}px`,
      padding: 0,
      width: `${cardWidth}px`,
    },
    donutChartHeader: {
      padding: `${theme.spacing.unit}px ${theme.spacing.unit}px 0`,
    },
    donutChartCardTitle: {
      fontSize: '1.3rem',
    },
    donutChartCardContent: {
      padding: 0,
      '&:last-child': {
        paddingBottom: 0,
      },
      '&:not(:last-child)': {
        paddingBottom: 0,
      },
    },
    donutChartContainer: {
      height: '160px',
      padding: `0 ${theme.spacing.unit}px ${theme.spacing.unit}px`,
      position: 'relative',
      width: '340px',
    },
    total: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      right: '66px',
      top: '43%',
      width: '112px',

      '& *': {
        fontSize: '0.9rem',
      }
    },
  });
};

export default donutChartStyle;

import * as React from 'react';

import {
  Typography,
  WithStyles,
  withStyles,
  StyleRulesCallback,
} from '@material-ui/core';

const styles: StyleRulesCallback = () => ({
  container: {
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  valueContainer: {
    display: 'inline-block',
  },
});

type OwnProps = {
  prompt?: string;
  value?: any;
  hideIfEmpty?: boolean;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const ViewFieldElement: any = ({
  classes,
  hideIfEmpty = true,
  prompt = '',
  value = null,
}: Props) => {
  if (!value && hideIfEmpty) {
    return <></>;
  }

  const promptElement = !prompt ? '' : (
    <Typography inline={true} variant="body1" color="textSecondary">
      {prompt}:&nbsp;
    </Typography>
  );

  const valueElement = (
    <div className={classes.valueContainer}>
      {value}
    </div>
  );

  return (
    <div className={classes.container}>
      {promptElement}{valueElement}
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(ViewFieldElement);

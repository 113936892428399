import { Checkbox, withStyles, StyleRulesCallback, Theme, Grid, WithStyles } from '@material-ui/core';
import React from 'react';

import { CompanyTrainingParticipantRegistration } from '../../../types';

const styles: StyleRulesCallback = (theme: Theme) => ({
  checkbox: {
    height: theme.spacing.unit * 3,
    width: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit,
  },
});

type OwnProps = {
  onChange: (isChecked: boolean, registration: CompanyTrainingParticipantRegistration) => void;
  registration: CompanyTrainingParticipantRegistration;
  checked: boolean;
};

type InnerProps = WithStyles<typeof styles>;
type Props = InnerProps & OwnProps;

const AddToDRField = ({
  classes,
  registration,
  onChange,
  checked,
}: Props) => {

  const handleChange = () => {
    onChange(!checked, registration);
  };

  return (
    <Grid container={true} alignItems="center">
      <Grid item={true}>
        <Checkbox
          className={classes.checkbox}
          color="default"
          checked={checked}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(AddToDRField);

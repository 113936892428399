import { StyleRulesCallback, Theme } from '@material-ui/core';

const styles: StyleRulesCallback = (theme: Theme) => ({
  content: {
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: 360,
  },
  flex: {
    display: 'flex',
  },
  grow: {
    flex: 1,
  },
  titleButtonIcon: {
    marginRight: theme.spacing.unit,
  },
  reorderRow: {
    cursor: 'move',
  },
  waitingRow: {
    backgroundColor: theme.palette.grey[200],
  },
  paper: {
    padding: theme.spacing.unit * 2,
    minWidth: 650,
    maxWidth: 700,
    minHeight: 500,
  },
  body: {
    marginBottom: theme.spacing.unit,
  },
  actions: {
    '& > *': {
      marginLeft: theme.spacing.unit / 2,
      marginRight: theme.spacing.unit / 2,
    },
  },
  iconButton: {
    marginRight: theme.spacing.unit / 2,
  },
  templateSelect: {
    width: 300,
    paddingBottom: 20,
  },
  clearButton: {
    padding: 0,
  },
  rightButton: {
    marginLeft: theme.spacing.unit,
  },
  inputFileUpload: {
    display: 'none',
  },
  deleteButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  download: {
    display: 'flex',
    justifyItems: 'flex-start',
    alignItems: 'center',
  },
  tooltip: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export default styles;

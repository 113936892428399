import React from 'react';

import { FieldProps } from 'formik';

import { TimePickerProps } from 'material-ui-pickers/TimePicker';
import { Event } from '@material-ui/icons';
import moment from 'moment';

import translate from '@/app/utils/translate';
import LocalizedTimePicker from '../../Pickers/LocalizedTimePicker';
import { hasError } from '../utils';
import { FormikFieldError } from './FormikFieldError';

type OwnProps = {};
type FormikTimeFieldProps = OwnProps & FieldProps & TimePickerProps;

export function FormikTimeField({
  field,
  form,
  ...others
}: FormikTimeFieldProps) {
  const defaultProps = {
    margin: 'dense' as 'dense',
    autoOk: true,
    okLabel: translate.t('laOk'),
    cancelLabel: translate.t('laCancel'),
    clearLabel: translate.t('laClear'),
    ampm: false,
    clearable: true,
    keyboard: true,
    keyboardIcon: <Event />,
    placeholder: 'HH:MM',
    format: 'HH:mm',
    mask: [/\d/, /\d/, ':', /\d/, /\d/],
  };

  const handleClose = () => {
    form.setFieldTouched(field.name, true);
  };

  const handleChange = (value: moment.Moment) => {
    form.setFieldTouched(field.name, true);
    form.setFieldValue(field.name, !value ? null : value);
  };

  const error = hasError(field.name, form, null);

  return (
    <>
      <LocalizedTimePicker
        {...defaultProps}
        {...field}
        {...others}
        InputLabelProps={{ error }}
        InputProps={{ error }}
        onClose={handleClose}
        onChange={handleChange}
      />
      <FormikFieldError name={field.name} />
    </>
  );
}

import { isHRAdmin, getLoggedUserId, getMyTeams } from '@/old/utils/helper';
import getEmployee from './getEmployee';

const GLOBAL: any = window;

export default function(empId?: number) {
  // Deny for employee himself
  if (empId === getLoggedUserId()) {
    return false;
  }

  // HR Admin can always access
  if (isHRAdmin()) {
    return true;
  }

  // Grant access for HRs and MGRs for external participants
  if (!empId) {
    return GLOBAL.iHR || GLOBAL.iManager || GLOBAL.iDManager;
  }

  // Check team rights for managers and HR's through employee's unit
  const selectedEmp = getEmployee(empId);
  if (getMyTeams().findIndex(u => u.value === selectedEmp.unitId) > -1) {
    return true;
  }

  // Override Managers / Direct employee Managers
  return GLOBAL.iManager ? GLOBAL.iGlobalHasFullAccess : false;

}

import { StyleRulesCallback, Theme } from '@material-ui/core';
import { green, red, orange } from '@material-ui/core/colors';

const styles: StyleRulesCallback = (theme: Theme) => ({
  textApproved: {
    color: 'green'
  },
  approvalButton: {
    textTransform: 'none',
  },
  approvedIcon: {
    color: green[300],
    marginRight: theme.spacing.unit / 2,
  },
  rejectedIcon: {
    color: red[300],
    marginRight: theme.spacing.unit / 2,
  },
  waitingIcon: {
    color: orange[300],
    marginRight: theme.spacing.unit / 2,
  },
  iCalButton: {
    marginLeft: -1 * theme.spacing.unit,
  },
});

export default styles;
import * as React from 'react';
import { compose } from 'recompose';
import { IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ControlPointDuplicateIcon from '@material-ui/icons/PlaylistAdd';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import SortIcon from '@material-ui/icons/Sort';
import { IEnumGroup } from '@/app/components/Enum/EnumGroupList/enumGroup';
import translate from '@/app/utils/translate';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
  Enum,
  deleteEnums,
  deleteGroup,
  selectAllEnums,
  fetchCustomChildren,
  updateCustomChildrenOrder,
  clearCustomChildren,
  fetchAllEnums,
} from '@/app/redux/enums';
import EnhancedTable from '@/app/components/EnhancedTable/EnhancedTable';
import { HeadData } from '@/app/components/EnhancedTable/EnhancedTableHead/EnhancedTableHead';
import { Tools } from '@/app/components/EnhancedTable/EnhancedTableToolbar/EnhancedTableToolbar';
import ConfigureUiLabelButton from '@/app/components/ConfigurableLabels/ConfigureUiLabelButton/ConfigureUiLabelButton';
import { getCurrentLanguageLabel } from '@/app/utils/helper';
import {
  openConfirmDialog,
  ConfirmDialogType,
} from '@/app/redux/confirmDialog';
import { CustomRowButton } from '../../EnhancedTable/RowMenu/RowMenu';
import EnumSubItemSortDialog from '../EnumGroupValueList/EnumSubItemSortDialog/EnumSubItemSortDialog';
import { getCompanyUnitsByCodeArray } from '@/old/utils/helper';

type MapStateToProps = {
  enumValues(groupName: string): any;
  childrenEnums: any;
};

type MapDispatchToProps = {
  deleteEnums: (groupName: string, codes: Array<string>) => void;
  deleteGroup: (name: string) => void;
  openConfirmDialog: (payload: ConfirmDialogType) => void;
  fetchCustomChildren: (groupName: string, codes: string) => void;
  updateCustomChildrenOrder: (
    groupName: string,
    code: string,
    list: any[],
  ) => void;
  clearCustomChildren: () => void;
  fetchAllEnums: () => void;
};

type OwnProps = {
  group: IEnumGroup;
  title: string;
  expanded: boolean;
  showDeleteIcon: boolean;
  onTitleClick: () => void;
  onTranslateClick: (groupName: string, code: string) => void;
  onAddClick: (grouName: string) => void;
  onAddChildClick: (e: Enum) => void;
  onEditClick: (e: Enum) => void;
  onEditDetailsClick: (groupName: string, code: string) => void;
  onSortClick: (group: IEnumGroup) => void;
};

type PropsType = OwnProps & MapStateToProps & MapDispatchToProps;

type State = {
  translateDialogOpen: boolean;
  enumExpandedCode: string;
  showAddChildButton: boolean;
  openSubItemSort: boolean;
};

class EnumGroupValueList extends React.Component<PropsType, State> {
  state: State = {
    translateDialogOpen: false,
    enumExpandedCode: undefined,
    showAddChildButton: false,
    openSubItemSort: false,
  };
  currentSelectedItems: Enum[];

  handleAdd = (groupName: string) => this.props.onAddClick(groupName);

  handleTranslateClose = () => {
    this.setState({ translateDialogOpen: false });
  };

  handleSelectedChange = (selectedItems: any[]) => {
    this.currentSelectedItems = [...selectedItems];
    if (
      selectedItems.length === 1 &&
      selectedItems[0].inputType &&
      (selectedItems[0].inputType.value === 'ENUM' ||
        selectedItems[0].inputType.value === 'MULTISELECT')
    ) {
      this.setState({ showAddChildButton: true });
    } else {
      this.setState({ showAddChildButton: false });
    }
  };

  handleTitleClick = (_event: React.MouseEvent) => {
    this.props.onTitleClick();
  };

  handleTranslate = (selectedItems: Array<any>) => (
    _event: React.MouseEvent,
  ) => {
    const selectedIds = selectedItems.map((s: any) => s.id);
    this.props.onTranslateClick(selectedItems[0].groupName, selectedIds[0]);
  };

  handleEdit = (selectedItems: Array<any>) => (_event: React.MouseEvent) => {
    this.props.onEditClick(selectedItems[0]);
  };

  handleEditDetails = (selectedItems: Array<any>) => (
    _event: React.MouseEvent,
  ) => {
    const selectedIds = selectedItems.map((s: any) => s.id);
    this.props.onEditDetailsClick(selectedItems[0].groupName, selectedIds[0]);
  };

  delete = (selectedItems: Array<any>) => {
    const groupNames = new Set(selectedItems.map((s: any) => s.groupName));
    groupNames.forEach(g => {
      const selectedIds = selectedItems
        .filter((s: any) => s.groupName === g)
        .map((s: any) => s.id);
      this.props.deleteEnums(g, selectedIds);
    });
  };

  handleDelete = (selectedItems: Array<any>) => (_event: React.MouseEvent) => {
    this.props.openConfirmDialog({
      text: translate.t('confirm_delete_item_s'),
      onOk: () => this.delete(selectedItems),
    });
  };

  handleDeleteGroup = () => () => {
    const group = this.props.group.name;
    this.props.openConfirmDialog({
      text: translate.t('confirm_delete_item_s'),
      onOk: () => this.props.deleteGroup(group),
    });
  };

  handleExpandCodeClick = (_event: React.MouseEvent, id: string) => {
    if (this.state.enumExpandedCode !== id) {
      this.setState({ enumExpandedCode: id });
      this.props.fetchCustomChildren(this.props.group.name, id);
    } else {
      this.setState({ enumExpandedCode: undefined });
      this.props.clearCustomChildren();
    }
  };

  renderExpandActionCell = (e: Enum): React.ReactNode => {
    if (
      e.inputType &&
      (e.inputType.value === 'ENUM' || e.inputType.value === 'MULTISELECT')
    ) {
      const ExpandIcon =
        this.state.enumExpandedCode === e.code
          ? ExpandLessIcon
          : ExpandMoreIcon;
      return (
        <IconButton
          onClick={(event: React.MouseEvent) =>
            this.handleExpandCodeClick(event, e.code)
          }
        >
          <ExpandIcon fontSize="small" />
        </IconButton>
      );
    }
    if (e.groupName.indexOf('__CUSTOMDATA__') === 0) {
      return (
        <IconButton disabled disableRipple>
          <ArrowRightAltIcon fontSize="small" />
        </IconButton>
      );
    }
    return null;
  };

  headData = () => {
    const { group } = this.props;
    const headData: Array<HeadData> = [];

    if (group.hasInputType) {
      headData.push({
        id: 'expandHeader',
        type: 'string',
        renderCustomDataCell: this.renderExpandActionCell,
        disablePadding: true,
        label: '',
      });
    }

    if (group.name !== 'CUSTOM_ENTITY_EMPLOYEE') {
      headData.push({
        id: 'description',
        type: 'string',
        disablePadding: false,
        label: translate.t('laTerm'),
      });
    }

    headData.push({
      id: 'name',
      type: 'string',
      disablePadding: false,
      label: getCurrentLanguageLabel(),
    });

    if (group.hasTags()) {
      headData.push({
        id: 'tags',
        type: 'array',
        disablePadding: false,
        label: translate.t('label_tags'),
      });
    }

    if (group.hasInputType) {
      headData.push({
        id: 'inputType',
        type: 'ChildInputType',
        disablePadding: false,
        label: translate.t('laType'),
      });
    }

    if (group.hasExtCode) {
      headData.push({
        id: 'extCode',
        type: 'string',
        disablePadding: false,
        label: translate.t('laExternalCode'),
      });
    }

    if (group.isCountryBased()) {
      headData.push({
        id: 'countries',
        type: 'ChildInputTypeArray',
        disablePadding: false,
        label: translate.t('laCountries'),
      });
      if (!group.hasInputType) {
        headData.push({
          id: 'orgUnits',
          type: 'ChildInputTypeArray',
          disablePadding: false,
          label: translate.t('laOrgUnit'),
          isScrollable: true,
        });
      }
    }
    if (group.hasInputType && group.name === 'CUSTOM_ENTITY_EMPLOYEE') {
      headData.push({
        id: 'editableByEmp',
        type: 'boolean',
        disablePadding: false,
        label: translate.t('laCustomDataAttributeEditableByEmp'),
      });
    }

    return headData;
  };

  handleSort = () => {
    this.props.onSortClick(this.props.group);
  };

  tableTools = (): Tools => {
    const { group, showDeleteIcon } = this.props;
    return {
      showAdd: group.canAdd(),
      showEdit: group.canEdit(),
      showDelete: group.canDelete(),
      showTranslate: group.canTranslate(),
      showDetails: group.hasDetails(),
      showSort: group.isOrdered,
      showDeleteItemIcon: showDeleteIcon,
    };
  };

  shouldComponentUpdate(nextProps: PropsType, nextState: State) {
    return (
      this.props.expanded !== nextProps.expanded ||
      JSON.stringify(this.props.group) !== JSON.stringify(nextProps.group) ||
      JSON.stringify(this.props.enumValues(this.props.group.name)) !==
        JSON.stringify(nextProps.enumValues(nextProps.group.name)) ||
      this.state.enumExpandedCode !== nextState.enumExpandedCode ||
      this.state.showAddChildButton !== nextState.showAddChildButton ||
      this.state.openSubItemSort !== nextState.openSubItemSort
    );
  }

  componentDidUpdate(prevProp: PropsType) {
    if (this.props.expanded !== prevProp.expanded) {
      this.setState({ enumExpandedCode: undefined });
      this.props.clearCustomChildren();
    }

    if (
      !this.props.childrenEnums.length &&
      prevProp.childrenEnums.length &&
      this.state.enumExpandedCode
    ) {
      this.setState({ enumExpandedCode: undefined });
    }
  }

  handleAddCustomChildButtonClick = () => {
    if (this.state.enumExpandedCode !== this.currentSelectedItems[0].code) {
      this.setState({ enumExpandedCode: this.currentSelectedItems[0].code });
      this.props.fetchCustomChildren(
        this.props.group.name,
        this.currentSelectedItems[0].code,
      );
    }

    this.props.onAddChildClick(this.currentSelectedItems[0]);
  };

  renderAddChildButton = () => {
    const { showAddChildButton } = this.state;
    if (!showAddChildButton) {
      return null;
    }

    return [
      {
        onClick: this.handleAddCustomChildButtonClick,
        tooltip: translate.t('add_sub_item'),
        iconButton: <ControlPointDuplicateIcon />,
      },
    ];
  };

  handleSortSubItemsButtonClick = () => {
    this.setState({ openSubItemSort: true });
  };

  getCustomRowButtons = (row: any) => {
    const { inputType } = row;
    const hidden =
      !inputType ||
      (inputType &&
        !(inputType.value === 'ENUM' || inputType.value === 'MULTISELECT'));

    const customRowButtons: CustomRowButton[] = [
      {
        hidden,
        label: translate.t('add_sub_item'),
        iconButton: <ControlPointDuplicateIcon />,
        onClick: (selectedItems: Array<any>) => {
          if (this.state.enumExpandedCode !== selectedItems[0].code) {
            this.setState({ enumExpandedCode: selectedItems[0].code });
            this.props.fetchCustomChildren(
              this.props.group.name,
              selectedItems[0].code,
            );
          }
          this.props.onAddChildClick(selectedItems[0]);
        },
      },
      {
        hidden,
        label: translate.t('laSort'),
        iconButton: <SortIcon />,
        onClick: (selectedItems: Array<any>) => {
          if (this.state.enumExpandedCode !== selectedItems[0].code) {
            this.setState({ enumExpandedCode: selectedItems[0].code });
            this.props.fetchCustomChildren(
              this.props.group.name,
              selectedItems[0].code,
            );
          }
          this.handleSortSubItemsButtonClick();
        },
      },
    ];

    return customRowButtons.filter((btn: CustomRowButton) => !btn.hidden);
  };

  handleSubItemSortDialogSave = (list: any[]) => {
    this.setState({
      openSubItemSort: false,
    });

    this.props.updateCustomChildrenOrder(
      this.props.group.name,
      this.state.enumExpandedCode,
      list,
    );
  };

  handleSubItemSortDialogClose = () => {
    this.setState({
      openSubItemSort: false,
    });
  };

  render() {
    const {
      group,
      title = translate.t(group.getTransPluralKey()),
      expanded,
      enumValues,
      fetchAllEnums: handleUpdateEnum,
    } = this.props;
    let textTitle = title;
    let customTitle: React.ReactNode | null = null;
    if (!!group && group.customizableUiLabelName !== '') {
      textTitle = '';
      customTitle = (
        <ConfigureUiLabelButton
          term={group.customizableUiLabelName}
          fetchAllEnums={handleUpdateEnum}
        />
      );
    }

    return (
      <>
        <EnhancedTable
          title={textTitle}
          customTitle={customTitle}
          customButtons={this.renderAddChildButton()}
          customRowButtons={this.getCustomRowButtons}
          tools={this.tableTools()}
          foldable={true}
          expanded={expanded}
          titleClickHandler={this.handleTitleClick}
          addHandler={() => this.handleAdd(group.name)}
          editHandler={this.handleEdit}
          detailsHandler={this.handleEditDetails}
          deleteHandler={this.handleDelete}
          deleteCurrentItemHandler={this.handleDeleteGroup}
          translateHandler={this.handleTranslate}
          onSelectedChange={this.handleSelectedChange}
          sortHandler={this.handleSort}
          headData={this.headData()}
          data={enumValues(group.name)}
          order={'asc'}
          orderBy={'name'}
        />
        <EnumSubItemSortDialog
          open={this.state.openSubItemSort}
          itemsToSort={this.props.childrenEnums}
          onCancel={this.handleSubItemSortDialogClose}
          onSave={this.handleSubItemSortDialogSave}
        />
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  const childrenEnums = state.enums
    .get('childrenEnums')
    .toJS()
    .filter((v: any) => v.isActive);

  const enumValues = (group: string) => {
    const allEnums = selectAllEnums(state);
    const values: Array<Enum> = allEnums[group].slice();
    if (!values) {
      return null;
    }

    const eValues = values
      .concat(childrenEnums)
      .filter(v => v.isActive)
      .map(
        ({
          code,
          name,
          groupName,
          description,
          tags = [],
          countries = [],
          seq = 1,
          inputType,
          extCode,
          fCustomDataAttributeEditableByEmp = false,
          editableByEmp,
          orgUnits,
        }) => ({
          id: code,
          code,
          groupName,
          name,
          description,
          tags,
          countries,
          inputType,
          seq,
          extCode,
          fCustomDataAttributeEditableByEmp,
          editableByEmp,
          orgUnits: !!orgUnits
            ? orgUnits.map((ou: any) => {
                if (typeof ou === 'string') {
                  return getCompanyUnitsByCodeArray(ou);
                }
                return ou;
              })
            : [],
        }),
      );

    return eValues;
  };
  return { childrenEnums, enumValues };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  deleteEnums: (groupName: string, codes: string[]) =>
    dispatch<any>(deleteEnums(groupName, codes)),
  deleteGroup: (name: string) =>
    dispatch<any>(deleteGroup(name)).then(() => dispatch<any>(fetchAllEnums())),
  openConfirmDialog: (payload: ConfirmDialogType) =>
    dispatch(openConfirmDialog(payload)),
  fetchCustomChildren: (groupName: string, code: string) =>
    dispatch<any>(fetchCustomChildren(groupName, code)),
  updateCustomChildrenOrder: (groupName: string, code: string, list: any[]) =>
    dispatch<any>(updateCustomChildrenOrder(groupName, code, list)),
  clearCustomChildren: () => dispatch<any>(clearCustomChildren()),
  fetchAllEnums: () => dispatch<any>(fetchAllEnums()),
});

const enhance = compose<any, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(EnumGroupValueList);

import { Reducer } from 'redux';

import { RawTaskComment } from './collaborationModels';
import { CollaborationActionKeys, CollaborationActionTypes } from './data';

export interface CommentsStoreState {
    data: { [commentId: number]: RawTaskComment; };
    loading: boolean;
    failedToLoad: boolean;
    creating: boolean;
    failedToCreate: boolean;
}

const defaultCommentsStoreState: CommentsStoreState = {
    data: {},
    loading: false,
    failedToLoad: false,
    creating: false,
    failedToCreate: false,
};

export interface TasksStoreState {
    [taskId: number]: {
        comments?: CommentsStoreState;
    };
}

const initialTasksStoreState: TasksStoreState = {};

export const tasksReducer: Reducer<TasksStoreState, CollaborationActionTypes> = (
    state = initialTasksStoreState,
    action,
) => {
    switch (action.type) {
        case CollaborationActionKeys.FETCH_TASK_COMMENTS_PENDING: {
            return {
                ...state,
                [action.meta]: {
                    ...state[action.meta],
                    comments: {
                        ...defaultCommentsStoreState,
                        ...(state[action.meta] && state[action.meta].comments),
                        loading: true,
                    },
                },
            };
        }

        case CollaborationActionKeys.FETCH_TASK_COMMENTS_FULFILLED: {
            if (!state[action.meta]) {
                return state;
            }

            return {
                ...state,
                [action.meta]: {
                    ...state[action.meta],
                    comments: {
                        ...state[action.meta].comments,
                        data: action.payload.reduce(
                            (accumulator, comment) => ({
                                ...accumulator,
                                [comment.fTaskCommentId]: comment,
                            }),
                            {}
                        ),
                        loading: false,
                        failedToLoad: false,
                    },
                },
            };
        }

        case CollaborationActionKeys.FETCH_TASK_COMMENTS_REJECTED: {
            if (!state[action.meta]) {
                return state;
            }

            return {
                ...state,
                [action.meta]: {
                    ...state[action.meta],
                    comments: {
                        ...state[action.meta].comments,
                        loading: false,
                        failedToLoad: true,
                    },
                },
            };
        }

        case CollaborationActionKeys.CREATE_TASK_COMMENT_PENDING: {
            if (!state[action.meta]) {
                return state;
            }

            return {
                ...state,
                [action.meta]: {
                    ...state[action.meta],
                    comments: {
                        ...state[action.meta].comments,
                        creating: true,
                    },
                },
            };
        }

        case CollaborationActionKeys.CREATE_TASK_COMMENT_FULFILLED: {
            if (!state[action.meta]) {
                return state;
            }

            return {
                ...state,
                [action.meta]: {
                    ...state[action.meta],
                    comments: {
                        ...state[action.meta].comments,
                        data: {
                            [action.payload.fTaskCommentId]: action.payload,
                            ...state[action.meta].comments.data,
                        },
                        creating: false,
                        failedToCreate: false,
                    },
                },
            };
        }

        case CollaborationActionKeys.CREATE_TASK_COMMENT_REJECTED: {
            if (!state[action.meta]) {
                return state;
            }

            return {
                ...state,
                [action.meta]: {
                    ...state[action.meta],
                    comments: {
                        ...state[action.meta].comments,
                        creating: false,
                        failedToCreate: true,
                    },
                },
            };
        }

        case CollaborationActionKeys.CLEAR_TASK_COMMENTS: {
            if (!state[action.meta]) {
                return state;
            }

            return {
                ...state,
                [action.meta]: {
                    ...state[action.meta],
                    comments: undefined,
                },
            };
        }

        default: {
            return state;
        }
    }
};
import React from 'react';
import { Checkbox, WithStyles, withStyles, Grid } from '@material-ui/core';
import { FieldProps } from 'formik';
import { styles } from './addToDRField/';

type OwnProps = {
  onChange: () => void;
};
type InnerProps = WithStyles<typeof styles> & FieldProps;
type Props = OwnProps & InnerProps;

const AddToDRField = ({
  classes,
  field,
  form,
  onChange,
  ...others
}: Props) => {

  const handleChange = (e: React.ChangeEvent<any>) => {
    field.onChange(e);
    onChange();
  };

  return (
    <Grid container={true} alignItems="center">
      <Grid item={true}>
        <Checkbox
          {...field}
          className={classes.checkbox}
          color="default"
          checked={field.value}
          value={!field.value ? '' : field.value.toString()}
          onChange={handleChange}
          {...others}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(AddToDRField);

import * as React from 'react';
import * as moment from 'moment';
import { v4 as uuid4 } from 'uuid';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import {
  WithStyles,
  Paper,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { LanguagesType } from '@/app/redux/languages';
import translate from '@/app/utils/translate';
import addNewTemplateStyle from './addNewTemplateStyle';
import AddStage from './AddStages/AddStage';
import { orderElementsByIndex } from '../../../helpers';
import { Status } from '@/app/components/JobEvaluation/enums';
import { EvaluationStage } from '@/app/components/JobEvaluation/types';
import LocalizedDatePicker from '@/app/components/Pickers/LocalizedDatePicker';
import TemplateTags from '@/app/components/TemplateComponents/admin/TemplateHeader/TemplateTags/TemplateTags';
import { ReducerState } from '@/app/redux/store';
import { connect } from 'react-redux';
import { Enum, selectActiveEnumsInGroup } from '@/app/redux/enums';
import { OptionType } from '@/app/components/Trainings/types';
import { mapTemplateTags } from '@/app/components/TemplateComponents/admin/TemplateHeader/helper';

type IncomingProps = {
  languages: LanguagesType[];
  handleModalClose: any;
  editStyle?: boolean;
  templateBeingEdited?: any;
  handleEditTemplate?: any;
  addJobEvaluationTemplate?: any;
  updateJobEvaluationTemplate?: any;
};

type MapStateToProps = {
  templateTags: Enum[];
};

type PropsType = IncomingProps & MapStateToProps & WithStyles<typeof addNewTemplateStyle>;

type StateType = {
  subjectName: string;
  status: string;
  stages: [];
  setStage: string;
  deadline: Date;
  language: string;
  isTeam: boolean;
  showValidationError: boolean;
  disabledSave: boolean;
  stagesModal: boolean;
  tags: OptionType[];
};

class AddNewTemplate extends React.PureComponent<PropsType> {
  state: StateType = {
    subjectName: '',
    status: Status.INACTIVE,
    setStage: '',
    stages: [],
    deadline: new Date(),
    language: 'en',
    isTeam: false,
    showValidationError: false,
    disabledSave: true,
    stagesModal: false,
    tags: [],
  };

  constructor(props: any) {
    super(props);
    if (props.templateBeingEdited !== undefined) {
      const templateStages = props.templateBeingEdited.stages || [];
      let setStage = templateStages[0] ? templateStages[0].label : '';
      templateStages.forEach(
        (
          stage: { label: string; index: number; default: boolean },
          index: number,
        ) => {
          if (stage.default) {
            setStage = templateStages[index].label;
          }
        },
      );
      this.state = {
        subjectName: props.templateBeingEdited.subject || '',
        status: props.templateBeingEdited.status || Status.ACTIVE,
        stages: templateStages || [],
        setStage: setStage,
        deadline: props.templateBeingEdited.deadline || new Date(),
        language: props.templateBeingEdited.language || 'en',
        isTeam: props.templateBeingEdited.isTeam || false,
        showValidationError: false,
        disabledSave: true,
        stagesModal: false,
        tags: props.templateBeingEdited.tags
          ? mapTemplateTags(props.templateBeingEdited.tags, props.templateTags)
          : [],
      };
    }
  }

  // To be moved to helper
  titleValidation = (subjectName: string) => {
    if (subjectName === '') {
      this.setState({ showValidationError: true });
      return false;
    } else {
      this.setState({ showValidationError: false });
      return true;
    }
  };

  changeSubject = ({
    target,
  }: React.ChangeEvent<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  >) => {
    this.setState({ subjectName: target.value });
    if (target.value !== '') {
      this.setState({ showValidationError: false, disabledSave: false });
    }
  };

  changeStatus = ({
    target,
  }: React.ChangeEvent<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  >) => {
    this.setState({ status: target.value, disabledSave: false });
  };

  changeStage = ({
    target,
  }: React.ChangeEvent<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  >) => {
    const { stages } = this.state;
    this.setState({ setStage: target.value, disabledSave: false });
    // When a new stage is set... update 'default' in list of stages
    stages.forEach(
      (stage: { label: string; index: number; default: boolean }) => {
        if (stage.label === target.value) {
          stage.default = true;
        } else {
          stage.default = false;
        }
      },
    );
    this.setState({ stages: stages });
  };

  changeStageList = (stageList: EvaluationStage) => {
    this.setState({
      stages: stageList,
      setStage: stageList[0].label,
      disabledSave: false,
    });
  };

  handleDateRangeChange = (date: moment.Moment) => {
    this.setState({ deadline: date, disabledSave: false });
  };

  changeLanguage = ({
    target,
  }: React.ChangeEvent<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  >) => {
    this.setState({ language: target.value, disabledSave: false });
  };

  changeIsTeam = (_: any, checked: boolean) => {
    this.setState({ isTeam: checked, disabledSave: false });
  };

  changeTags = (e: any) => {
    this.setState({ tags: e, disabledSave: false });
  };

  handleSaveTemplate = (handleModalClose: any) => {
    const {
      addJobEvaluationTemplate,
      updateJobEvaluationTemplate,
      templateBeingEdited,
    } = this.props;
    const {
      subjectName,
      status,
      stages,
      deadline,
      language,
      isTeam,
      tags,
    } = this.state;
    if (!this.titleValidation(subjectName)) {
      return;
    }

    const mappedTags = tags.map(tag => ({
      tagGroup: 'JOB_REQ_TEMPLATE_TAGS',
      tag: tag.value
    }));

    if (templateBeingEdited) {
      updateJobEvaluationTemplate(templateBeingEdited.id, {
        subject: subjectName,
        status: status,
        stages: stages,
        deadline: moment(deadline).format('YYYY-MM-DD'),
        isTeam: isTeam,
        tags: mappedTags,
      });
    } else {
      const newUUID = uuid4();
      addJobEvaluationTemplate({
        id: newUUID,
        subject: subjectName,
        status: status,
        stages: stages,
        deadline: moment(deadline).format('YYYY-MM-DD'),
        language: language,
        isTeam: isTeam,
        tags: mappedTags,
      });
      // TODO: Auto editing disabled. Changes required after BE wiring
      // setTimeout(function () {handleEditTemplate([newUUID]); }, 750);
    }
    handleModalClose();
  };

  openStagesModal = () => {
    this.setState({ stagesModal: true });
  };
  closeStagesModal = () => {
    this.setState({ stagesModal: false });
  };

  render() {
    const {
      classes,
      editStyle,
      languages,
      handleModalClose,
      templateBeingEdited,
      templateTags,
    } = this.props;
    const {
      subjectName,
      status,
      deadline,
      language,
      isTeam,
      disabledSave,
      showValidationError,
      setStage,
      stages,
      stagesModal,
      tags,
    } = this.state;

    return (
      <>
        <Paper className={editStyle ? classes.editStyle : classes.root}>
          <form>
            <h3 className={classes.topTitle}>
              {editStyle
                ? translate.t('laEditTemplate')
                : translate.t('laAddNewTemplate')}
              <span className={classes.topTitleRight}>
                <Button
                  color="primary"
                  variant="text"
                  onClick={handleModalClose}
                >
                  {editStyle ? translate.t('laDone') : translate.t('laCancel')}
                </Button>
                {templateBeingEdited !== undefined && stages.length > 0 && (
                  <Button
                    color="primary"
                    variant="text"
                    onClick={() => this.openStagesModal()}
                  >
                    {translate.t('edit_stage_label')}
                  </Button>
                )}
                {templateBeingEdited === undefined && (
                  <Button
                    color="primary"
                    onClick={() => this.openStagesModal()}
                  >
                    {stages.length < 1
                      ? translate.t('add_stage_label')
                      : translate.t('edit_stage_label')}
                  </Button>
                )}
                <Button
                  color="primary"
                  variant="contained"
                  disabled={!!disabledSave}
                  onClick={() => this.handleSaveTemplate(handleModalClose)}
                >
                  {translate.t('button_save_close')}
                </Button>
              </span>
            </h3>
            <div className={classes.flexWrapper}>
              <TextField
                label={translate.t('laSubject')}
                onChange={this.changeSubject}
                value={subjectName}
                className={classes.spacing}
                required
                error={!!showValidationError}
                helperText={
                  showValidationError ? translate.t('laThisRequired') : ' '
                }
              />
              <TextField
                select
                label={translate.t('laStatus')}
                onChange={this.changeStatus}
                value={status}
                name={status}
                className={`${classes.spacing} ${classes.left}`}
                required
              >
                <MenuItem key={Status.ACTIVE} value={Status.ACTIVE}>
                  {translate.t('laActive')}
                </MenuItem>
                <MenuItem key={Status.INACTIVE} value={Status.INACTIVE}>
                  {translate.t('label_inactive')}
                </MenuItem>
              </TextField>
              {stages && stages.length > 0 && (
                <TextField
                  select
                  label={translate.t('laStage')}
                  onChange={this.changeStage}
                  value={setStage}
                  name={setStage}
                  className={`${classes.spacing} ${classes.left}`}
                >
                  {orderElementsByIndex(stages).map(
                    (stage: { label: string }) => {
                      return (
                        <MenuItem
                          key={`${stage.label}-key`}
                          value={stage.label}
                        >
                          {stage.label}
                        </MenuItem>
                      );
                    },
                  )}
                </TextField>
              )}
              <LocalizedDatePicker
                className={`${classes.picker} ${classes.datePicker}`}
                label={translate.t('laDeadline')}
                value={deadline}
                onChange={(date: moment.Moment) =>
                  this.handleDateRangeChange(date)
                }
                minDate={moment().add(-1, 'day')}
                autoOk={true}
                helperText={
                  templateBeingEdited &&
                    moment(deadline)
                      .endOf('day')
                      .isBefore(new Date())
                    ? translate.t('laDeadlineReached')
                    : ''
                }
                required
              />
              <TextField
                disabled={!!templateBeingEdited}
                select
                label={translate.t('laLanguage')}
                onChange={this.changeLanguage}
                value={language}
                name={language}
                className={`${classes.spacing} ${classes.left}`}
                required
              >
                {languages.map((option: LanguagesType) => (
                  <MenuItem key={option.name} value={option.code}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              <FormControlLabel
                classes={{
                  root: classes.checkboxControlLabel,
                  label: classes.checkboxLabel,
                }}
                control={
                  <Checkbox
                    checked={isTeam}
                    onChange={this.changeIsTeam}
                    disabled={!!templateBeingEdited}
                  />
                }
                label={translate.t('laTeamTemplate')}
              />
            </div>
            <div>
              <Grid item xs={12}>
                <TemplateTags
                  value={tags}
                  tagOptions={templateTags}
                  onChange={this.changeTags}
                />
              </Grid>
            </div>
          </form>
        </Paper>
        <AddStage
          editedTemplate={templateBeingEdited}
          openedStageModal={stagesModal}
          closeStagesModal={this.closeStagesModal}
          changeStageList={this.changeStageList}
        />
      </>
    );
  }
}

export const mapStateToProps = (state: ReducerState) => {
  return {
    templateTags: selectActiveEnumsInGroup(state, 'JOB_REQ_TEMPLATE_TAGS'),
  };
};

const enhance = compose<PropsType, IncomingProps>(
  connect(mapStateToProps, null),
  withStyles(addNewTemplateStyle)
);

export default enhance(AddNewTemplate);

import {
  Button,
  createStyles,
  Icon,
  Theme,
  withStyles,
  WithStyles
} from '@material-ui/core';
import React from 'react';
import { compose } from 'recompose';

import translate from '@/app/utils/translate';

const styles = (theme: Theme) => createStyles({
  downloadButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit
  },
});

type OwnProps = {
  onClick: () => void;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const KendoReportDownloadButton = ({ classes, onClick }: Props ) => (
  <span className={classes.downloadButton}>
    <Button onClick={onClick}>
      {translate.t('laDownload')}
      &nbsp;
      <Icon fontSize="small">cloud_download</Icon>
    </Button>
  </span>
);

const enhance = compose<Props, OwnProps>(withStyles(styles));

export default enhance(KendoReportDownloadButton);

import { Typography, withStyles } from '@material-ui/core';
import * as React from 'react';
import { compose } from 'recompose';

import {
  TemplateBaseComponentType,
  TemplateComponentTypeKey,
  TemplateInputComponentPropsType,
} from '@/app/components/TemplateComponents/form/InputComponents';
import { resolveComponentResponse } from '@/app/components/TemplateComponents/form/InputComponents/data';
import fileComponentStyles from '@/app/components/TemplateComponents/form/InputComponents/FileComponent/fileComponentStyles';
import FileFieldComponent from '@/app/components/TemplateComponents/form/InputComponents/FileComponent/components/FileFieldComponent/FileFieldComponent';
import { getLoggedUserId } from '@/old/utils/helper';

type FileComponentProps = TemplateInputComponentPropsType & {
  component: TemplateBaseComponentType & {
    type: TemplateComponentTypeKey.TEXT;
  };
  employeeId?: string;
};

const FileComponent = (props: FileComponentProps) => {
  const {
    templateId,
    component,
    responses,
    editMode,
    onUpdate,
    classes,
    employeeId,
  } = props;
  const existingResponse = resolveComponentResponse(
    component,
    responses,
    undefined,
  );

  const checkExistingResponseForFile = (response: any) => {
    if (response) {
      if (response.attachmentName) {
        return response.attachmentName;
      }
      return response.name;
    }
    return undefined;
  };

  const handleChange = (componentId: string, file: any) => {
    if (!!onUpdate) {
      onUpdate(templateId, {
        componentId: componentId,
        file,
        type: component.type,
      });
    }
  };

  return (
    component.type === TemplateComponentTypeKey.FILE && (
      <div key={`typeEvaluations-${component.id}`}>
        <Typography variant="subtitle2" className={classes.typography}>
          {component.name}
        </Typography>
        <FileFieldComponent
          defaultFile={checkExistingResponseForFile(existingResponse)}
          componentId={component.id}
          onChange={handleChange}
          disabled={!editMode}
          employeeId={employeeId ? employeeId : getLoggedUserId()}
          attachmentId={
            existingResponse
              ? existingResponse.attachmentId || undefined
              : undefined
          }
        />
      </div>
    )
  );
};

const enhance = compose<any, any>(withStyles(fileComponentStyles));

export default enhance(FileComponent);

import { SEAT_STATUS, APPROVAL_STATUS } from '../types';

export default (
  approvalStatus: APPROVAL_STATUS,
  position: number,
  trainingSeats: number = 0,
  allowWaitingSeats: boolean = false,
): SEAT_STATUS => {
  if (
    approvalStatus === APPROVAL_STATUS.REJECTED ||
    approvalStatus === APPROVAL_STATUS.CANCELLED ||
    (trainingSeats > 0 && !allowWaitingSeats && position > trainingSeats)
  ) {
    return SEAT_STATUS.NO_SEAT;
  }

  if (trainingSeats > 0 && position > trainingSeats) {
    return SEAT_STATUS.WAITING_SEAT;
  }

  return SEAT_STATUS.HAS_SEAT;
};

import { Typography } from '@material-ui/core';
import {
  GridDataStateChangeEvent,
  Grid,
  GridColumn,
  GridToolbar,
} from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import React, { useMemo } from 'react';

import translate from '@/app/utils/translate';
import { KendoButtonCell } from '../../../components/KendoButtonCell';
import KendoReportDownloadButton from '../../../components/KendoReportDownloadButton';
import SearchTemplates from './SearchTemplates/SearchTemplates';
import SelectTemplates from './SelectTemplates/SelectTemplates';

type Props = {
  templateGridData: any;
  title?: string;
  showSelect?: boolean;
  showSearch?: boolean;
  searchQuery?: string;
  onSelectAll?: () => void;
  onDeselectAll?: () => void;
  onSearch?: (query: string) => void;
  handleChangeEmpScope?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  scope?: string;
};

const TemplateGrid = (props: Props) => {
  const {
    onSearch,
    onSelectAll,
    onDeselectAll,
    showSelect,
    showSearch,
    searchQuery,
    templateGridData,
    title,
    scope,
    handleChangeEmpScope,
  } = props;

  const templateGridColumns = useMemo(() => [
    {
      cell: KendoButtonCell,
      field: 'subject',
      show: true,
      title: translate.t('laSubject'),
    },
    {
      field: 'status',
      show: true,
      title: translate.t('laStatus'),
    },
    {
      field: 'deadline',
      show: true,
      title: translate.t('laDeadline'),
      type: 'date',
      format: '{0: dd.MM.yyyy}'
    },
  ], [translate]);

  const [datastate = { skip: 0, take: 7 }, setDatastate] = React.useState();
  const createDataState = (dataStateProp: any) => {
    return {
      datastate: setDatastate(dataStateProp),
    };
  };
  const dataStateChange = (event: GridDataStateChangeEvent) => {
    return createDataState(event.data);
  };

  let _export: any;

  const saveGridData = () => {
    _export.save();
  };

  return (
    <div>
      <ExcelExport
        data={
          process(templateGridData, {
            ...datastate, // Export all the records
            skip: 0,
            take: undefined,
          }).data
        }
        fileName={title}
        ref={excelExport => (_export = excelExport)}
      >
        <Grid
          data={process(templateGridData, datastate)}
          onDataStateChange={dataStateChange}
          {...datastate}
          sortable={true}
          resizable={true}
          pageable={true}
          groupable={false}
          filterable={false}
        >
          <GridToolbar>
            <Typography variant="h6" gutterBottom color="primary">
              {title}
            </Typography>
            <KendoReportDownloadButton onClick={saveGridData}/>
          </GridToolbar>
          {!!showSearch && (
            <GridToolbar>
              <SearchTemplates
                value={searchQuery}
                onChange={onSearch}
                onSelectAll={onSelectAll}
                onDeselectAll={onDeselectAll}
              />
            </GridToolbar>
          )}
          {!!showSelect && (
            <GridToolbar>
              <SelectTemplates
                scope={scope}
                handleChangeEmpScope={handleChangeEmpScope}
              />
            </GridToolbar>

          )}
          {templateGridColumns.map(
            (column: any, idx: number) =>
              column.show && (
                <GridColumn
                  {...column}
                  key={idx}
                />
              ),
          )}
        </Grid>
      </ExcelExport>
    </div>
  );
};

export default TemplateGrid;

import { Avatar } from '@material-ui/core';
import * as React from 'react';

import { CompanyTrainingExternalParticipant } from '../../../types';

type Props = {
  participant: CompanyTrainingExternalParticipant;
};

const ExternalParticipantAvatar = ({ participant }: Props) => {
  const firstNameInitial = participant.firstName.substr(0, 1);
  const lastNameInitial = participant.lastName.substr(0, 1);
  return <Avatar>{`${firstNameInitial}${lastNameInitial}`.toUpperCase()}</Avatar>;
};

export default ExternalParticipantAvatar;

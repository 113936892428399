import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const TranslateTermsDialogStyle: StyleRulesCallback = () => {
  return {
    paperWidthMd: {
      width: '900px',
    },
    sectionWrapper: {
      display: 'flex',
      alignItems: 'stretch',
    },
    section: {
      flex: 'auto',
      padding: '10px',
    },
    group: {
      marginTop: '60px',
    },
    select: {
      width: '100%',
    },
  };
};

export default TranslateTermsDialogStyle;

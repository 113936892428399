import { Typography, WithStyles } from '@material-ui/core';
import classNames from 'classnames';
import * as React from 'react';

import { commentTextCharactersLimit } from './sendCommentConstants';
import { sendCommentStyles } from './sendCommentStyles';
import { useSendComment } from './useSendComment';

export interface StateProps {
    creatingComment: boolean;
    failedToCreateComment: boolean;
}

export interface DispatchProps {
    createTaskComment: (taskId: number, commentText: string) => void;
}

export interface SendCommentOuterProps {
    taskId: number;
}

export interface SendCommentInnerPros extends
    SendCommentOuterProps,
    WithStyles<typeof sendCommentStyles>,
    StateProps,
    DispatchProps {}

export const SendComment: React.FC<SendCommentInnerPros> = ({
    classes,
    taskId,
    creatingComment,
    failedToCreateComment,
    createTaskComment,
}) => {
    const {
        textareaRef,
        commentText,
        textareaIsDisabled,
        handleCommentTextChange,
        handleTextareaKeyDown,
        commentTextLength,
        exceededCommentTextLengthLimit,
    } = useSendComment(
        taskId,
        creatingComment,
        failedToCreateComment,
        createTaskComment,
    );

    return (
        <div className={classes.root}>
            <textarea
                ref={textareaRef}
                value={commentText}
                disabled={textareaIsDisabled}
                onChange={handleCommentTextChange}
                onKeyDown={handleTextareaKeyDown}
                className={
                    classNames(classes.input, {
                        [classes.error]: failedToCreateComment,
                    })
                }
            />

            <Typography
                className={
                    classNames({
                        [classes.exceededLengthLimit]: exceededCommentTextLengthLimit,
                    })
                }
            >
                {commentTextLength}/{commentTextCharactersLimit}
            </Typography>
        </div>
    );
};

import * as yup from 'yup';

import translate from '@/app/utils/translate';
import { isNumberWithBothCommaAndDot } from '@/app/utils/helper';

export const validateEvaluation = async (formData: any): Promise<any> => {
  const numericValidation = yup
    .string()
    .test(
      'numeric',
      translate.t('laValueNotValidNumber'),
      (v: string) => !v || isNumberWithBothCommaAndDot(v),
    );

  const componentValidation = (isDate: boolean) =>
    yup.object().shape({
      goal: yup
        .string()
        .required(translate.t('laThisRequired'))
        .max(64, `${translate.t('laErrorMaxTextLen1')} 64`),
      successMeasure: yup
        .string()
        .required(translate.t('laThisRequired'))
        .max(64, `${translate.t('laErrorMaxTextLen1')} 64`),
      weight: numericValidation,
      rangeMin: isDate ? yup.string() : numericValidation,
      rangeMax: isDate ? yup.string() : numericValidation,
      target: isDate ? yup.string() : numericValidation,
      actual: isDate ? yup.string() : numericValidation,
      result: numericValidation,
    });

  let errors = undefined;
  for (let sectionId of Object.keys(formData)) {
    for (let goal of [
      ...formData[sectionId].commonGoals,
      ...formData[sectionId].personalGoals,
    ]) {
      try {
        await componentValidation(goal.isDate).validate(goal, {
          abortEarly: false,
        });
      } catch (error) {
        if (!errors) {
          errors = {};
        }

        if (!errors[sectionId]) {
          errors[sectionId] = {};
        }

        errors[sectionId][goal.goalId] = error.inner.reduce(
          (errs: any, err: any) => {
            return { ...errs, [err.path]: err.message };
          },
          {},
        );
      }
    }
  }

  return errors;
};

import React, { useEffect } from 'react';
import { Button, Grid, withStyles, WithStyles } from '@material-ui/core';
import translate from '@/app/utils/translate';

import { styles } from './fileFieldComponentStyles';
import { basicFileDownload } from '@/app/utils/helper';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

type OwnProps = {
  componentId: string;
  disabled: boolean;
  onChange: any;
  defaultFile: string | undefined;
  employeeId: string;
  attachmentId: string | undefined;
};

type Props = OwnProps & WithStyles<typeof styles>;

const FileUpload = (props: Props) => {
  const {
    componentId,
    disabled,
    onChange,
    defaultFile,
    classes,
    attachmentId,
    employeeId,
  } = props;
  const [selectedFile, setSelectedFile] = React.useState<string>();

  const handleDelete = () => {
    setSelectedFile(undefined);
    onChange(componentId, 'DELETE_FILE');
  };

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(event.target.files[0].name);
    onChange(componentId, event.target.files[0]);
  };

  const handleFileDownload = () => {
    basicFileDownload(
      `/d/bin/employee/${employeeId}/file/${attachmentId}`,
      selectedFile,
    );
  };

  const renderDownloadBtnOrNoFile = () => {
    if (selectedFile) {
      return (
        <Button onClick={() => handleFileDownload()}>{selectedFile}</Button>
      );
    }
    return (
      <span className={classes.noFileSpan}>{translate.t('laNoFile')}</span>
    );
  };

  const renderSelFileOrNoFile = () => {
    if (selectedFile) {
      return selectedFile;
    }
    return translate.t('laNoFile');
  };

  useEffect(() => {
    setSelectedFile(defaultFile);
  }, [defaultFile]);

  const renderUploadedState = () => {
    return (
      <Grid container direction="row" alignItems="center">
        <Grid item>
          {!disabled ? (
            <span>{renderSelFileOrNoFile()}</span>
          ) : (
            renderDownloadBtnOrNoFile()
          )}
        </Grid>
        {!disabled && (
          <>
            <Grid item>
              <input
                accept="*"
                className={classes.inputFileUpload}
                id={componentId}
                multiple
                type="file"
                onChange={e => handleUpload(e)}
              />
              <label htmlFor={componentId}>
                <Button
                  className={classes.rightButton}
                  variant="contained"
                  component="span"
                >
                  {selectedFile
                    ? translate.t('laChangeFile')
                    : translate.t('laChooseFile')}
                </Button>
              </label>
            </Grid>
            {selectedFile && (
              <IconButton
                className={classes.deleteButton}
                onClick={handleDelete}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </>
        )}
      </Grid>
    );
  };

  return <div className={classes.root}>{renderUploadedState()}</div>;
};

export default withStyles(styles, { withTheme: true })(FileUpload);

import * as React from 'react';
import { Dispatch } from 'redux';
import ConfirmDialog from '@/app/components/ConfirmDialog/ConfirmDialog';
import { closeConfirmDialog } from '../redux/confirmDialog';
import { connect } from 'react-redux';

interface Props {
  open: boolean;
  text: string;
  closeDialog: any;
  onOk: Function;
  onClose?: Function;
  customOk?: string;
  customClose?: string;
}

const ConfirmHOC = (PassedComponent: React.ComponentType) => {
  const WithConfirmDialog: React.FunctionComponent<Props> = (
    { open, text, closeDialog, onOk, onClose, customOk, customClose, ...props }
  ) => {
    const handleClose = () => {
      onClose();
      closeDialog();
    };

    return (
      <>
        <ConfirmDialog
          text={text}
          open={open}
          onOk={onOk}
          onClose={handleClose}
          customOk={customOk}
          customClose={customClose}
        />
        <PassedComponent {...props} />
      </>
    );
  };

  const mapStateToProps = (state: any) => {
    const cdState = state.confirmDialog;

    return {
      text: cdState.get('text'),
      open: cdState.get('open'),
      onOk: cdState.get('onOk'),
      onClose: cdState.get('onClose'),
      customOk: cdState.get('customOk'),
      customClose: cdState.get('customClose'),
    };
  };

  const mapDispatchToProps = (dispatch: Dispatch) => ({
    closeDialog: () => dispatch<any>(closeConfirmDialog())
  });

  return connect(mapStateToProps, mapDispatchToProps)(WithConfirmDialog);
};

export default ConfirmHOC;

import * as React from 'react';
import { v4 as uuid4 } from 'uuid';
import { compose } from 'recompose';
import {
  withStyles,
  WithStyles,
  Paper,
  TextField,
  Button,
  Checkbox,
} from '@material-ui/core';
import translate from '@/app/utils/translate';
import addSectionStyle from './addSectionStyle';

type IncomingProps = {
  template: any;
  sectionBeingEdited: any;
  addSection: any;
  updateSection: any;
  handleModalClose: any;
};

type StateType = {
  title: string;
  showValidationError: boolean;
  disabledSave: boolean;
  empAccess: boolean;
  onlyEmpAccess: boolean;
};

type PropsType = IncomingProps & WithStyles<typeof addSectionStyle>;

class AddSection extends React.PureComponent<PropsType> {
  state: StateType = {
    title: '',
    empAccess: true,
    onlyEmpAccess: false,
    showValidationError: false,
    disabledSave: true,
  };

  constructor(props: any) {
    super(props);

    if (props.sectionBeingEdited) {
      this.state = {
        title: this.props.sectionBeingEdited.name,
        empAccess: this.props.sectionBeingEdited.empAccess || false,
        onlyEmpAccess: this.props.sectionBeingEdited.onlyEmpAccess || false,
        showValidationError: false,
        disabledSave: true,
      };
    }
  }

  changeTitle = (event: any) => {
    const target = event.target as HTMLTextAreaElement;
    this.setState({
      title: target.value,
      ...(target.value !== ''
        ? {
            showValidationError: false,
            disabledSave: false,
          }
        : {}),
    });
  };

  handleSaveSection = (e: any) => {
    e.preventDefault();

    const {
      template,
      sectionBeingEdited,
      addSection,
      updateSection,
      handleModalClose,
    } = this.props;
    const { title, empAccess, onlyEmpAccess } = this.state;

    if (title === '') {
      this.setState({ showValidationError: true });
      return;
    }

    if (sectionBeingEdited) {
      updateSection(template.id, {
        ...sectionBeingEdited,
        name: title,
        empAccess,
        onlyEmpAccess,
      });
    } else {
      addSection(template.id, {
        id: uuid4(),
        name: title,
        empAccess,
        onlyEmpAccess,
        index:
          template.sections && template.sections.length
            ? template.sections[template.sections.length - 1].index + 1
            : 1,
      });
    }

    handleModalClose();
  };

  toggleEmpAccess = () => {
    this.setState({
      empAccess: !this.state.empAccess,
      disabledSave: false,
    });
  };

  toggleOnlyEmpAccess = () => {
    this.setState({
      empAccess: !this.state.onlyEmpAccess ? true : this.state.empAccess,
      onlyEmpAccess: !this.state.onlyEmpAccess,
      disabledSave: false,
    });
  };

  render() {
    const { classes, handleModalClose, sectionBeingEdited } = this.props;
    const {
      title,
      empAccess,
      onlyEmpAccess,
      showValidationError,
      disabledSave,
    } = this.state;

    return (
      <Paper className={classes.root}>
        <form onSubmit={this.handleSaveSection}>
          <h3 className={classes.topTitle}>
            {sectionBeingEdited
              ? translate.t('edit_section')
              : translate.t('add_section')}
          </h3>

          <TextField
            value={title}
            onChange={this.changeTitle}
            label={translate.t('laTitle')}
            className={classes.title}
            error={showValidationError}
            helperText={
              showValidationError ? translate.t('laThisRequired') : ' '
            }
          />

          <div className={classes.checkboxes}>
            <Checkbox
              onClick={this.toggleEmpAccess}
              checked={empAccess}
              disabled={onlyEmpAccess}
              color="primary"
            />

            {translate.t('employee_edit')}
          </div>

          <div className={classes.checkboxes}>
            <Checkbox
              onClick={this.toggleOnlyEmpAccess}
              checked={onlyEmpAccess}
              color="primary"
            />

            {translate.t('only_employee_edit')}
          </div>

          <div className={classes.buttonsWrapper}>
            <Button color="primary" variant="text" onClick={handleModalClose}>
              {translate.t('laCancel')}
            </Button>

            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={disabledSave}
            >
              {sectionBeingEdited
                ? translate.t('laSaveChanges')
                : translate.t('laSave')}
            </Button>
          </div>
        </form>
      </Paper>
    );
  }
}

const enhance = compose<any, any>(withStyles(addSectionStyle));

export default enhance(AddSection);

import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const goalTemplatesStyle: StyleRulesCallback = (theme: Theme) => ({
  root: {
    textAlign: 'center',
    width: '97%',
    marginTop: '40px',
    marginLeft: '15px'
  },
  sectionTitle: {
    textAlign: 'left',
    paddingTop: '25px',
    paddingLeft: '25px'
  },
  subHeader: {
    fontSize: '80%',
    fontWeight: 'normal',
    color: theme.palette.primary.main,
    marginLeft: 20,
  },
  topCheckbox: {
    marginLeft: '-12px'
  },
  activeSortIcon: {
    color: '#05A4D8',
  },
  inactiveSortIcon: {
    color: 'black'
  },
  capitalize: {
    textTransform: 'capitalize'
  }
});

export default goalTemplatesStyle;
import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const dateComponentStyles: StyleRulesCallback = (theme: Theme) => ({
  typography: {
    whiteSpace: 'pre-wrap',
    paddingTop: '20px',
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontSize: '0.875em',
    fontWeight: 'bold',
  },
  controlContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
  },
  controlContainerMd: {
    maxWidth: '150px',
  },
  controlContainerLg: {
    maxWidth: '200px',
  },
  constrolContainerSpacing: {
    paddingLeft: theme.spacing.unit * 3,
  },
});

export default dateComponentStyles;

import { useCallback, useMemo } from 'react';
import { getEnums, mapBooleanToYesNo } from '@/old/utils/helper';
import {
  WorkflowReportTypes,
  WorkflowsReportDataRow,
} from '@/app/components/WorkflowsReport/ReportTypes';
import { InternalPositionWorkflowReportEntry } from '@/app/components/WorkflowsReport/ReportTypes/InternalPosition';
import { EmploymentDetailsWorkflowReportEntry } from '@/app/components/WorkflowsReport/ReportTypes/EmploymentDetails';
import { RewardingWorkflowReportEntry } from '@/app/components/WorkflowsReport/ReportTypes/Rewarding';
import translate from '@/app/utils/translate';

type UseFormattedWorkflowDataOutput = {
  getFormattedWorkflowData: (
    unformattedDataRows: WorkflowsReportDataRow[],
    receivedClosedWorkflowType: WorkflowReportTypes,
  ) => WorkflowsReportDataRow[];
};

export const useFormattedWorkflowData = (): UseFormattedWorkflowDataOutput => {
  // get Enum names for workflow type
  const getTypeTranslation = useCallback((group: any, type: string) => {
    const workflowTypes = getEnums(group).filter((item: any) => item.isActive);
    const foundType = workflowTypes.find((item: any) => item.code === type);

    return !!foundType ? foundType.name : '';
  }, []);

  // get Enum names for workflow status
  const workflowStatus = useMemo(() => {
    return getEnums('WORKFLOW_ITEM_STATUS').filter(
      (item: any) => item.hasOwnProperty('isActive') ? item.isActive : true,
    );
  }, []);
  const getStatusTranslation = useCallback(
    (type: string) => {
      const wf = workflowStatus.find((item: any) => item.code === type);
      if (!wf) {
        return '';
      }
      return wf.name;
    },
    [workflowStatus],
  );

  const getFormattedWorkflowData = useCallback(
    (
      unformattedDataRows: WorkflowsReportDataRow[],
      receivedClosedWorkflowType: WorkflowReportTypes,
    ): WorkflowsReportDataRow[] => {
      switch (receivedClosedWorkflowType) {
        case WorkflowReportTypes.InternalPositionClosed:
        case WorkflowReportTypes.InternalPositionOpen:
          return (unformattedDataRows as InternalPositionWorkflowReportEntry[]).map(
            dataRow => {
              const { status, ...restRowData } = dataRow;
              return {
                status: getStatusTranslation(status),
                ...restRowData,
              };
            },
          );
        case WorkflowReportTypes.EmployeeDetailsClosed:
        case WorkflowReportTypes.EmployeeDetailsOpen:
          return (unformattedDataRows as EmploymentDetailsWorkflowReportEntry[]).map(
            dataRow => {
              const {
                status,
                empStatus,
                pg1,
                pg2,
                pg3,
                pg4,
                pg5,
                vacBalCalcUseSixDays,
                calcHoursBalance,
                ...restRowData
              } = dataRow;
              return {
                empStatus: getTypeTranslation('EMPLOYMENT_STATUS', empStatus),
                pg1: getTypeTranslation('PERSONNEL_GROUP_1', pg1),
                pg2: getTypeTranslation('PERSONNEL_GROUP_2', pg2),
                pg3: getTypeTranslation('PERSONNEL_GROUP_3', pg3),
                pg4: getTypeTranslation('PERSONNEL_GROUP_4', pg4),
                pg5: getTypeTranslation('PERSONNEL_GROUP_5', pg5),
                status: getStatusTranslation(dataRow.status),
                vacBalCalcUseSixDays: `${vacBalCalcUseSixDays} ${translate.t(
                  'laDays',
                )}`,
                calcHoursBalance: mapBooleanToYesNo(Boolean(calcHoursBalance)),
                ...restRowData,
              };
            },
          );
        case WorkflowReportTypes.RewardingClosed:
        case WorkflowReportTypes.RewardingOpen:
        default:
          return (unformattedDataRows as RewardingWorkflowReportEntry[]).map(
            dataRow => {
              const { status, group, type, ...restRowData } = dataRow;
              return {
                group,
                type: getTypeTranslation(group, type),
                status: getStatusTranslation(dataRow.status),
                ...restRowData,
              };
            },
          );
      }
    },
    [getStatusTranslation, getTypeTranslation],
  );

  return {
    getFormattedWorkflowData,
  };
};

import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const questionFormDialogStyle: StyleRulesCallback = (theme: Theme) => {
  return ({
    root: {
      flexGrow: 1,
      maxWidth: '500px',
    },
    paperWidthMd: {
      width: '500px',
    },
    formControl: {
      margin: `${theme.spacing.unit * 2}px 0 0`,
      width: '100%',
    },
    formControlLabel: {
      transform: 'translate(0, 1.5px) scale(0.75)',
      transformOrigin: 'top left',
    },
    group: {
      margin: `${theme.spacing.unit}px 0 0`,
    },
    radio: {
      padding: '0 12px',
    },
    input: {
      marginLeft: '34px',

      '& > div': {
        marginTop: 0,
      },
    },
  });
};

export default questionFormDialogStyle;

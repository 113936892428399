import { Typography } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';

import { formatDurationToHMM } from '@/app/utils/helper';
import translate from '@/app/utils/translate';

import { ExtraHoursBalanceInnerProps } from './extraHoursBalanceModels';

export const ExtraHoursBalance: React.FC<ExtraHoursBalanceInnerProps> = props => {
  const extraHoursAsMillis = moment
    .duration(props.extraHoursBalance || 'PT0M')
    .asMilliseconds();
  const maxHoursAsMillis = moment
    .duration(props.maxCumulativeHours || 'PT0M')
    .asMilliseconds();
  const minHoursAsMillis = moment
    .duration(props.minCumulativeHours || 'PT0M')
    .asMilliseconds();
  const renderCumulativeInfo = (classes?: string) => (
    <div className={classes}>
      <Typography inline={true} variant="subtitle1">
        {translate.t('laExtraHoursBalance')}:{' '}
        <b>{formatDurationToHMM(props.extraHoursBalance || 'PT0M')}</b>
      </Typography>
      <Typography variant="subtitle1">
        {`${translate.t('laMinCumulativeHoursAllowed')}: ${formatDurationToHMM(
          props.minCumulativeHours,
        )}`}
      </Typography>
      <Typography variant="subtitle1">
        {`${translate.t('laMaxCumulativeHoursAllowed')}: ${formatDurationToHMM(
          props.maxCumulativeHours,
        )}`}
      </Typography>
    </div>
  );
  const isUpperLimitExceeded =
    extraHoursAsMillis > maxHoursAsMillis ? true : false;
  const isLowerLimitExceeded =
    extraHoursAsMillis < 0 && extraHoursAsMillis < -Math.abs(minHoursAsMillis)
      ? true
      : false;
  const { errorMessage, errorValue } = isUpperLimitExceeded
    ? {
        errorMessage: 'laYourMaxHoursBalanceIsOver',
        errorValue: props.maxCumulativeHours,
      }
    : {
        errorMessage: 'laYourMinHoursBalanceIsOver',
        errorValue: props.minCumulativeHours,
      };
  return isUpperLimitExceeded || isLowerLimitExceeded ? (
    <div className={props.classes.hoursLimitExceededContainer}>
      <div className={props.classes.hoursLimitExceededMessageContainer}>
        <Typography inline={true} variant="subtitle1" color="error">
          {translate.t(errorMessage)}
        </Typography>
        <Typography variant="subtitle1" color="error">
          {`${formatDurationToHMM(errorValue)}, ${translate.t(
            'laPleaseContactManager',
          )}`}
        </Typography>
      </div>
      {renderCumulativeInfo()}
    </div>
  ) : (
    renderCumulativeInfo(props.classes.hoursLimitDeceededContainer)
  );
};

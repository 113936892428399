import translate from '@/app/utils/translate';

import { AttachmentCell } from './components/AttachmentCell';
import { AvatarCellFactory } from './components/AvatarCell';
import { CommentsCellFactory } from './components/CommentsCell';

export const getTasksListColumns = (onViewComments: (taskId: number) => void) => [
    {
        cell: AvatarCellFactory('assigneeId', 'assigneeName'),
        field: 'assigneeName',
        show: true,
        title: translate.t('laAssignee'),
        groupable: true,
    },
    {
        field: 'title',
        show: true,
        title: translate.t('laTitle'),
        groupable: false,
    },
    {
        field: 'type',
        show: true,
        title: translate.t('laType'),
    },
    {
        field: 'status',
        show: true,
        title: translate.t('laStatus'),
    },
    {
        field: 'startDate',
        show: true,
        title: translate.t('laStartDate'),
        type: 'date',
        format: '{0: dd.MM.yyyy}'
    },
    {
        field: 'dueDate',
        show: true,
        title: translate.t('laDueDate'),
        type: 'date',
        format: '{0: dd.MM.yyyy}'
    },
    {
        cell: AvatarCellFactory('assignorId', 'assignorName'),
        field: 'assignorName',
        show: true,
        title: translate.t('laAssignor'),
    },
    {
        cell: AvatarCellFactory('pertainingToId', 'pertainingToName'),
        field: 'pertainingToName',
        show: true,
        title: translate.t('laPertainingTo'),
    },
    {
        cell: CommentsCellFactory(onViewComments),
        field: 'numberOfComments',
        show: true,
        title: translate.t('laComments'),
    },
    {
        cell: AttachmentCell,
        field: 'attachment',
        show: true,
        title: translate.t('laAttachment'),
        groupable: false,
    },
];

import { Button, Checkbox, Icon, Menu, MenuItem, withStyles, WithStyles, } from '@material-ui/core';
import { Grid, GridColumn, GridDataStateChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { process } from '@progress/kendo-data-query';
import moment from 'moment';
import React, { BaseSyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import compose from 'recompose/compose';

import API, { Relation, Scope } from '@/app/api/internalAPIs';
import ColumnMenu from '@/app/components/Kendo/ColumnMenu';
import LocalizedDatePicker from '@/app/components/Pickers/LocalizedDatePicker';
import { Unit, WorkingHoursSummaryReportEntry, WorkingHoursSummaryReportResponseItem } from '@/app/components/WorkingHoursSummaryReport/types';
import Service from '@/app/utils/service';
import translate from '@/app/utils/translate';
import { getLoggedUserId, getMyEmployeeUnitId, isOnlyAltMgr, isAltManager, isEmployee, isHR, isManager, isRecruiter, isDManager } from '@/old/utils/helper';

import { gridColumns } from './Columns/GridColumns';
import SelectFilter from './KendoFilters/SelectFilter';
import { mapReportForUI } from './service';
import styles from './styles';

const allUnitsOption = {
  fTreeUnitId: '0',
  fTreeUnitName: 'All',
  fTreeUnitNumber: 'all-1'
};

type InnerProps = WithStyles<typeof styles>;
type OwnProps = {};

type Props = OwnProps & InnerProps;

const WorkingHoursSummaryReport = (props: Props) => {
  const { classes } = props;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | undefined>(undefined);

  const [hrUnits, setHRUnits] = useState<Unit[]>([]);
  const [managerUnits, setManagerUnits] = useState<Unit[]>([]);
  const [altManagerUnits, setAltManagerUnits] = useState<Unit[]>([]);
  const [employeeUnits, setEmployeeUnits] = useState<Unit[]>([]);
  const [selectedUnit, setSelectedUnit] = useState<Unit>(allUnitsOption);

  const [relation, setRelation] = useState<Relation | undefined>(
    isHR() && !isManager() && !isAltManager() ? Relation.ALL : Relation.DIRECT
  );
  const [startDate, setStartDate] = useState(moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)));
  const [endDate, setEndDate] = useState(moment(new Date().getTime()));

  const [entries, setEntries] = useState<WorkingHoursSummaryReportEntry[]>([]);

  const [columns, setColumns] = useState(gridColumns());
  const [dataState = { skip: 0, take: 9 }, setDataState] = useState();
  const [dropdownMenu, setDropdownMenu] = useState();

  const myUserId = useMemo(() => getLoggedUserId(), []);

  const handleStartDateChange = useCallback(
    (date: moment.Moment | null) => {
      setStartDate(date);
    },
    [setStartDate]);

  const handleEndDateChange = useCallback(
    (date: moment.Moment | null) => {
      setEndDate(date);
    },
    [setEndDate]);

  useEffect(() => {
    Service.get(
      API.orgTree.primaryUnits(),
      (allUnits: Unit[]) => {
        if (isHR()) {
          Service.get(
            API.orgTree.myHrUnits(myUserId),
            (unitIds: number[]) => {
              const myHRUnits = allUnits.filter((unit: Unit) => unitIds.includes(+unit.fTreeUnitId));
              setHRUnits(myHRUnits);
              setLoading(false);
              setError(undefined);
            },
            (err: any) => {
              setLoading(false);
              setError(err);
            }
          );
        }

        if (isManager() && !isAltManager()) {
          Service.get(
            API.orgTree.myManagedUnits(myUserId),
            (unitIds: number[]) => {
              const myManagerUnits = allUnits.filter((unit: Unit) => unitIds.includes(+unit.fTreeUnitId));
              setManagerUnits(myManagerUnits);
              setLoading(false);
              setError(undefined);
            },
            (err: any) => {
              setLoading(false);
              setError(err);
            }
          );
        }

        if (isAltManager()) {
          Service.get(
            API.orgTree.myAltManagerUnits(myUserId),
            (unitIds: number[]) => {
              const myAltManagerUnits = allUnits.filter((unit: Unit) => unitIds.includes(+unit.fTreeUnitId));
              setAltManagerUnits(myAltManagerUnits);
              setLoading(false);
              setError(undefined);
            },
            (err: any) => {
              setLoading(false);
              setError(err);
            }
          );
        }

        if (isEmployee() || isRecruiter() || isDManager()) {
          const myEmployeeUnit = allUnits.find((unit: Unit) => +unit.fTreeUnitId === +getMyEmployeeUnitId(myUserId));
          setEmployeeUnits([myEmployeeUnit]);
          setSelectedUnit(myEmployeeUnit);
        }
      },
      (err: any) => {
        setLoading(false);
        setError(err);
      }
    );
  },
    []
  );

  useEffect(
    () => {
      if (relation) {
        const unitScope =
          selectedUnit &&
          selectedUnit.fTreeUnitId !== allUnitsOption.fTreeUnitId &&
          !isEmployee() &&
          !isRecruiter() &&
          !isDManager();

        const scope = unitScope ? Scope.UNIT :
          (isHR() || isAltManager()) && isManager() && !isAltManager() && relation === Relation.DIRECT ? Scope.MANAGER :
            isHR() ? Scope.HR :
              isManager() && !isOnlyAltMgr() ? Scope.MANAGER :
                isOnlyAltMgr() ? Scope.ALT_MANAGER :
                  Scope.EMPLOYEE;

        const scopeId = unitScope ?
          selectedUnit.fTreeUnitId :
          myUserId;

        Service.get(
          API.workingHoursSummaryReport.getReport(
            scope, scopeId, relation, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD')),
          (res: WorkingHoursSummaryReportResponseItem[]) => {
            setColumns(Array.from(new Set([...gridColumns()])));
            setEntries(mapReportForUI(res));
            setLoading(false);
            setError(undefined);
          },
          (err: any) => {
            setEntries([]);
            setLoading(false);
            setError(err);
          }
        );
      } else {
        setEntries([]);
      }
    },
    [selectedUnit, relation, startDate, endDate]
  );

  const initialOptions = isEmployee() ? [] : [allUnitsOption];

  const unitOptions = useMemo(() =>
    Array.from(new Set([
      ...initialOptions,
      ...hrUnits,
      ...managerUnits,
      ...altManagerUnits,
      ...employeeUnits
    ]
    )
    ),
    [hrUnits, managerUnits, altManagerUnits, employeeUnits]);

  if (loading) {
    return <div>{loading}</div>;
  }
  if (error) {
    return <div>{'error'}</div>;
  }
  if (!entries) {
    return <div>{'no data'}</div>;
  }

  const unitFilterCell = SelectFilter(unitOptions.map(u => u.fTreeUnitName), 'Select unit');

  const onColumnsSubmit = (columnsState: any) => {
    setColumns(columnsState);
  };

  const createDataState = (dataStateProp: any) => {
    return {
      dataState: setDataState(dataStateProp),
    };
  };

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    return createDataState(event.data);
  };

  let _export: any;
  const saveClosedWorkflowsReport = () => {
    _export.save(
      process(entries, {
        ...dataState, // Export all the records
        skip: 0,
        take: undefined,
      }),
    );
  };

  const handleTeamCheckboxChange = (_: BaseSyntheticEvent, checked: boolean) => {
    setRelation(checked ? Relation.DIRECT : undefined);
  };

  const handleSubunitsCheckboxChange = (_: BaseSyntheticEvent, checked: boolean) => {
    setRelation(checked ? Relation.ALL : undefined);
  };

  const selectUnit = (unit: Unit) => () => {
    setSelectedUnit(unit);
    setDropdownMenu(null);
  };

  const ToolbarManagerAndHr = (
    <div className={classes.actionBar}>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={(event: any) => setDropdownMenu(event.currentTarget)}
      >
        {translate.t('laSelectUnit')}{' '}
        <Icon fontSize="small">arrow_drop_down</Icon>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={dropdownMenu}
        keepMounted={false}
        open={Boolean(dropdownMenu)}
        onClose={() => setDropdownMenu(null)}
      >
        {unitOptions.map((unit: Unit) => (
          <MenuItem
            key={unit.fTreeUnitId}
            onClick={selectUnit(unit)}
            selected={selectedUnit.fTreeUnitId === unit.fTreeUnitId}
            disabled={
              (isRecruiter() || isDManager()) && unit.fTreeUnitName === 'All'
            }
          >
            {unit.fTreeUnitName}
          </MenuItem>
        ))}
      </Menu>
      {selectedUnit && selectedUnit.fTreeUnitName}
      {isManager() && (
        <span>
          <Checkbox
            checked={relation === Relation.DIRECT}
            onChange={handleTeamCheckboxChange}
            value="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          {translate.t('laTeam')}
        </span>
      )}

      {(!isEmployee() && !isRecruiter() && !isDManager()) && (
        <span>
          <Checkbox
            checked={relation === Relation.ALL}
            onChange={handleSubunitsCheckboxChange}
            value="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          {translate.t('laIncludeSubunits')}
        </span>
      )}
      <LocalizedDatePicker
        name="startDate"
        format="L"
        margin="normal"
        label={translate.t('laStartDate')}
        value={startDate}
        onChange={handleStartDateChange}
        fullWidth={true}
        maxDate={endDate}
        className={classes.datePicker}
      />
      <LocalizedDatePicker
        name="endDate"
        format="L"
        margin="normal"
        label={translate.t('laEndDate')}
        value={endDate}
        onChange={handleEndDateChange}
        fullWidth={true}
        minDate={startDate}
        className={classes.datePicker}
      />
      <Button
        onClick={saveClosedWorkflowsReport}
        className={classes.downloadButton}
      >
        {translate.t('laDownload')} &nbsp;{' '}
        <Icon fontSize="small">cloud_download</Icon>
      </Button>
    </div>
  );

  return (
    <>
      <ExcelExport
        ref={excelExport => _export = excelExport}
        fileName={translate.t('laWorkingHoursSummaryReport')}
      >

        <Grid
          data={process(entries, dataState)}
          onDataStateChange={dataStateChange}
          {...dataState}
          sortable={true}
          resizable={true}
          pageable={true}
          groupable={true}
        >
          <GridToolbar>
            {ToolbarManagerAndHr}
          </GridToolbar>
          {
            columns.map((column: any, idx: number) =>
              column.show && (
                <GridColumn
                  {...column}
                  key={idx}
                  columnMenu={
                    (otherProps: any) =>
                      <ColumnMenu
                        {...otherProps}
                        columns={columns}
                        onColumnsSubmit={onColumnsSubmit}
                        filterUI={
                          column.field === 'unitNo' ? unitFilterCell :
                            undefined
                        }
                      />
                  }
                />
              )
            )}
        </Grid>
      </ExcelExport>
    </>
  );
};

const enhance = compose<Props, OwnProps>(
  withStyles(styles)
);

export default enhance(WorkingHoursSummaryReport);

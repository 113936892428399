import moment from 'moment';

import { WEEK_FORMAT } from '../../timeSheetConstants';

export const weekFormatter = (week: string) => {
    const startOfWeek = moment(week, WEEK_FORMAT).startOf('isoWeek');
    const endOfWeek = moment(week, WEEK_FORMAT).endOf('isoWeek');

    const formattedStartOfWeek = startOfWeek.format((() => {
        if (startOfWeek.year() !== endOfWeek.year()) {
            return 'YYYY, MMM Do';
        }

        return 'MMM Do';
    })());

    const formattedEndOfWeek = endOfWeek.format((() => {
        if (startOfWeek.year() !== endOfWeek.year()) {
            return 'YYYY, MMM Do';
        }

        if (startOfWeek.month() !== endOfWeek.month()) {
            return 'MMM Do';
        }

        return 'Do';
    })());

    return `${formattedStartOfWeek} - ${formattedEndOfWeek}`;
};
import { bindActionCreators, Dispatch } from 'redux';

import {
    changeWeekAction,
    clearStateAction,
    getTimeCardsAction,
    getTimeSheetAction,
    getHoursSettingsAction,
    getPublicHolidaysAction,
    getAutoDeductionRuleAction,
} from './data';
import { TimeSheetStoreState } from './timeSheetReducer';

import { DispatchProps, StateProps } from './timeSheetModels';

export const mapStateToProps = (state: any): StateProps => {
    const timeSheetStoreState: TimeSheetStoreState = state.timeSheet;

    return {
        activeWeek: timeSheetStoreState.activeWeek,
        activeYears: timeSheetStoreState.activeYears,
        orderedTimeCardsData: timeSheetStoreState.timeCards.data,
        extraHoursBalance: timeSheetStoreState.timeSheet.data && timeSheetStoreState.timeSheet.data.extraHoursBalance,
        maxCumulativeHours: timeSheetStoreState.hoursSettings.data && timeSheetStoreState.hoursSettings.data.maxCumulativeHours,
        minCumulativeHours: timeSheetStoreState.hoursSettings.data && timeSheetStoreState.hoursSettings.data.minCumulativeHours,
        loadingTimeSheet: timeSheetStoreState.timeSheet.loadingTimeSheet,
        loadingTimeCards: timeSheetStoreState.timeCards.weeksWeAreLoading.includes(timeSheetStoreState.activeWeek),
        loadingHoursSettings: timeSheetStoreState.hoursSettings.loadingHoursSettings,
        loadedTimeSheet: timeSheetStoreState.timeSheet.loadedTimeSheet,
        loadedTimeCards: timeSheetStoreState.timeCards.weeksWeLoaded.includes(timeSheetStoreState.activeWeek),
        loadedHoursSettings: timeSheetStoreState.hoursSettings.loadedHoursSettings,
        failedToLoadTimeSheet: timeSheetStoreState.timeSheet.failedToLoadTimeSheet,
        failedToLoadTimeCards: timeSheetStoreState.timeCards.weeksFailedToLoad.includes(timeSheetStoreState.activeWeek),
        failedToLoadHoursSettings: timeSheetStoreState.hoursSettings.failedToLoadHoursSettings,
        loadingPublicHolidaysYears: timeSheetStoreState.publicHolidays.yearsWeAreLoading,
        loadedPublicHolidaysYears: timeSheetStoreState.publicHolidays.yearsLoaded,
        failedToLoadPublicHolidaysYears: timeSheetStoreState.publicHolidays.yearsFailedToLoad,
        loadingAutoDeductionRule: timeSheetStoreState.autoDeductionRule.loadingAutoDeductionRule,
        loadedAutoDeductionRule: timeSheetStoreState.autoDeductionRule.loadedAutoDeductionRule,
        failedToLoadAutoDeductionRule: timeSheetStoreState.autoDeductionRule.failedToLoadAutoDeductionRule,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => bindActionCreators(
    {
        changeWeek: changeWeekAction,
        getTimeSheet: getTimeSheetAction,
        getTimeCards: getTimeCardsAction,
        getHoursSettings: getHoursSettingsAction,
        getPublicHolidays: getPublicHolidaysAction,
        getAutoDeductionRule: getAutoDeductionRuleAction,
        clearState: clearStateAction,
    },
    dispatch
);

import { StyleRulesCallback, Theme } from '@material-ui/core';

const styles: StyleRulesCallback = (theme: Theme) => ({
  content: {
    padding: 0,
  },
  contentPaper: {
    padding: theme.spacing.unit * 3,
    paddingTop: 0,
    paddingBottom: theme.spacing.unit,
  },
  progressPaper: {
    padding: theme.spacing.unit * 2,
    width: '100%',
  },
  progress: {
    height: theme.spacing.unit,
  },
});

export default styles;

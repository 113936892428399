import {
  Button,
  Paper,
  Typography,
  WithStyles,
  withStyles,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Select,
} from '@material-ui/core';
import * as React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { v4 as uuid4 } from 'uuid';

import translate from '@/app/utils/translate';

import addComponentFormStyle from './addComponentFormStyle';
import { ScoreGroup } from '@/app/components/JobEvaluation/types';
import { ReducerState } from '@/app/redux/store';
import {
  componentTypeTranslationKeys,
  ComponentType,
} from '@/app/components/JobEvaluation/constants';

type MapStateToProps = {
  scoreGroups: [];
};

type IncomingProps = {
  component?: any;
  componentType: string;
  section: any;
  onCancel: () => void;
  onComponentAdd: (component: any) => void;
  onComponentUpdate: (component: any) => void;
};

type PropsType = MapStateToProps &
  IncomingProps &
  WithStyles<typeof addComponentFormStyle>;

const AddComponentForm: React.FC<PropsType> = ({
  classes,
  component,
  componentType,
  onCancel,
  section,
  onComponentAdd,
  onComponentUpdate,
  scoreGroups,
}) => {
  const [labelValue, setLabelValue] = React.useState('');
  const [labelError, setLabelError] = React.useState('');

  const [defaultValue, setDefaultValue] = React.useState('');
  const [defaultValueError, setDefaultValueError] = React.useState('');

  const [showValidationError, setShowValidationError] = React.useState(false);
  const [disabledSave, setDisabledSave] = React.useState(true);

  React.useEffect(() => {
    if (component) {
      setLabelValue(component.name);
      setDefaultValue(component.defaultValue);
    }
  }, []);

  const handleDefaultValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDefaultValue(e.target.value);
    setDisabledSave(!e.target.value);
    setShowValidationError(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLabelValue(e.target.value);
    setDisabledSave(!e.target.value);
    setShowValidationError(false);
  };

  const handleSave = () => {
    let hasErrors = false;
    const scoreGroup: ScoreGroup = scoreGroups.find(
      (sc: ScoreGroup) => sc.id === labelValue,
    );

    if (labelValue) {
      setLabelError('');
    } else {
      setLabelError(translate.t('laThisRequired'));
      hasErrors = true;
    }

    if (componentType === ComponentType.LINK) {
      if (defaultValue) {
        setDefaultValueError('');
      } else {
        setDefaultValueError(translate.t('laThisRequired'));
        hasErrors = true;
      }
    }

    if (hasErrors) {
      setShowValidationError(true);
      return;
    }

    if (component) {
      onComponentUpdate({
        componentId: component.id,
        type: componentType,
        enumScoreGroupId:
          componentType === ComponentType.SCORE ? labelValue : undefined, // TO DO BACKEND BAD REQ not valid ID
        name:
          componentType === ComponentType.SCORE ? scoreGroup.name : labelValue,
        index: component.index,
        defaultValue: defaultValue ? defaultValue : undefined,
        options: undefined,
      });
    } else {
      onComponentAdd({
        componentId: uuid4(),
        type: componentType,
        enumScoreGroupId:
          componentType === ComponentType.SCORE ? labelValue : undefined, // TO DO BACKEND BAD REQ not valid ID
        name:
          componentType === ComponentType.SCORE ? scoreGroup.name : labelValue,
        defaultValue: defaultValue ? defaultValue : undefined,
        options: undefined,
        index: section.components ? section.components.length + 1 : 1,
      });
    }
  };

  return (
    <Paper className={classes.root}>
      <form className={classes.form}>
        <Typography variant="h6" className={classes.topTitle}>
          {translate.t(componentTypeTranslationKeys[componentType])}
        </Typography>

        {scoreGroups && componentType === ComponentType.SCORE ? (
          <FormControl className={classes.formControl}>
            <InputLabel>{translate.t('laScore')}</InputLabel>
            <Select
              classes={{ root: classes.select }}
              className={classes.select}
              value={labelValue}
              onChange={handleChange}
            >
              {scoreGroups.map((score: ScoreGroup) => (
                <MenuItem value={score.id}>{score.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <>
            <TextField
              label={translate.t('laLabel')}
              value={labelValue}
              onChange={handleChange}
              error={showValidationError && !!labelError}
              helperText={(showValidationError && labelError) || ''}
              fullWidth={true}
              margin="dense"
              multiline={true}
              rows={1}
              rowsMax={5}
            />

            {componentType === ComponentType.LINK && (
              <TextField
                label={translate.t('laUrl')}
                value={defaultValue}
                onChange={handleDefaultValueChange}
                error={showValidationError && !!defaultValueError}
                helperText={(showValidationError && defaultValueError) || ''}
                fullWidth={true}
                margin="dense"
              />
            )}
          </>
        )}
        <div className={classes.buttonsWrapper}>
          <Button color="primary" variant="text" onClick={onCancel}>
            {translate.t('laCancel')}
          </Button>

          <Button
            color="primary"
            variant="contained"
            disabled={disabledSave}
            onClick={handleSave}
          >
            {translate.t('laSave')}
          </Button>
        </div>
      </form>
    </Paper>
  );
};

const mapStateToProps = (state: ReducerState) => {
  const { scoreGroups } = state.jobEvaluation;

  return {
    scoreGroups,
  };
};

export default compose<PropsType, IncomingProps>(
  connect(mapStateToProps),
  withStyles(addComponentFormStyle),
)(AddComponentForm);

import React from 'react';
import { FormControl, FormLabel, FormGroup } from '@material-ui/core';

import CKEditor from 'react-ckeditor-component';

import { FieldProps } from 'formik';
import { getCurrentLanguage } from '@/app/utils/helper';

type OwnProps = {};
type FormikRichTextFieldProps = OwnProps & FieldProps & any;

export function FormikRichTextField({
  field,
  form,
  label,
  disabled = false,
  required = false,
  fullWidth = false,
  ...others
}: FormikRichTextFieldProps) {
  return (
    <FormControl fullWidth={fullWidth}>
      <FormLabel required={required} disabled={disabled}>
        {label}
      </FormLabel>
      <FormGroup>
        <CKEditor
          content={field.value}
          disabled={disabled}
          // required={required}
          events={{
            blur: (e: any) => form.setFieldValue(field.name, e.editor.getData()),
            change: (e: any) => form.setFieldValue(field.name, e.editor.getData())
          }}
          activeClass="p10"
          {...others}
          config={{
            toolbar: [
              ['Format', 'FontSize', 'RemoveFormat'],
              ['Cut', 'Copy', 'Paste'],
              ['Bold', 'Italic', 'Underline'],
              ['TextColor', 'BGColor'],
              ['NumberedList', 'BulletedList', 'Outdent', 'Indent'],
              ['Link', 'Unlink'],
              ['Image', 'Table', 'HorizontalRule']
            ],
            language: getCurrentLanguage(),
            baseFloatZIndex: 10700,
          }}
        />
      </FormGroup>
    </FormControl>
  );
}

import { getFormDialog } from '@/app/components/FormDialog/FormDialog';
import FormFields, { InputType } from '@/app/components/FormFields/FormFields';
import { INPUT_TYPE } from '@/app/utils/helper';
import translate from '@/app/utils/translate';
import { WithStyles } from '@material-ui/core';
import { WithSnackbarProps } from 'notistack';
import * as React from 'react';

import { ReportSettings, ReportSettingsPrivacyType, UpdatableReportSettingsData } from '../../models';
import { canManagePublicReportSettings, canManageReportSettings } from '../../services';

import { StyleKeys } from './styles';

export interface SaveReportOuterProps {
    reportType: string;
    openedReportSettings: ReportSettings;
    getCurrentReportSettings: () => object;
    onCreate: (data: UpdatableReportSettingsData) => void;
    onUpdate: (reportSettingsId: string, data: UpdatableReportSettingsData) => void;
    saving: boolean;
    failedToSave: boolean;
}

export interface SaveReportInnerProps extends SaveReportOuterProps, WithSnackbarProps, WithStyles<StyleKeys> {}

interface FormData {
    title: string;
    isPublic?: boolean;
    override?: boolean;
}

const FormDialog = getFormDialog('saveReportSettings', (values: Partial<FormData>) => {
    let errors: {[k: string]: any} = {};

    if (values.title && values.title.length > 80) {
        errors.title = `${translate.t('laErrorMaxTextLen1')} 80`;
    }

    return errors;
});

export const SaveReportSettings: React.FC<SaveReportInnerProps> = ({
    enqueueSnackbar,
    classes,
    reportType,
    openedReportSettings,
    getCurrentReportSettings,
    onCreate,
    onUpdate,
    saving,
    failedToSave,
}) => {
    const [saveDialogIsOpened, setSaveDialogIsOpened] = React.useState(false);

    const defaultFormData: FormData = React.useMemo(
        () => {
            return {
                title: openedReportSettings && canManageReportSettings(openedReportSettings) ? openedReportSettings.name : '',
                isPublic: openedReportSettings && openedReportSettings.privacyType === ReportSettingsPrivacyType.COMMON,
                override: openedReportSettings && canManageReportSettings(openedReportSettings),
            };
        },
        [openedReportSettings]
    );

    const formFieldsInputs = React.useMemo(
        () => {
            const inputs: InputType[] = [{
                type: INPUT_TYPE.TEXT,
                code: 'title',
                name: 'title',
                label: translate.t('laTitle'),
                order: 1,
                required: true,
            }];

            if (openedReportSettings && canManageReportSettings(openedReportSettings)) {
                inputs.push({
                    type: INPUT_TYPE.CHECKBOX,
                    code: 'override',
                    name: 'override',
                    label: translate.t('laOverrideReportSettings'),
                    order: 2,
                    required: false,
                });
            }

            if (canManagePublicReportSettings()) {
                inputs.push({
                    type: INPUT_TYPE.CHECKBOX,
                    code: 'isPublic',
                    name: 'isPublic',
                    label: translate.t('laPublicReportSettings'),
                    order: 3,
                    required: false,
                });
            }

            return inputs;
        },
        [openedReportSettings]
    );

    const handleSaveClick = React.useCallback(
        () => { setSaveDialogIsOpened(true); },
        [setSaveDialogIsOpened]
    );

    const handleCloseDialog = React.useCallback(
        () => { setSaveDialogIsOpened(false); },
        [setSaveDialogIsOpened]
    );

    const handleSaveReportSettings = React.useCallback(
        (data: FormData) => {
            const reportSettingsData: UpdatableReportSettingsData = {
                name: data.title,
                reportType,
                privacyType: data.isPublic
                    ? ReportSettingsPrivacyType.COMMON
                    : ReportSettingsPrivacyType.PERSONAL,
                activeColumns: JSON.stringify(getCurrentReportSettings()),
            };

            if (openedReportSettings && canManageReportSettings(openedReportSettings) && data.override) {
                onUpdate(openedReportSettings.id, reportSettingsData);
            } else {
                onCreate(reportSettingsData);
            }

            handleCloseDialog();
        },
        [
            handleCloseDialog,
            reportType,
            openedReportSettings,
            getCurrentReportSettings,
            onCreate,
            onUpdate,
        ]
    );

    React.useEffect(
        () => {
            if (!saving && failedToSave) {
                enqueueSnackbar(
                    translate.t('laFailedToSaveReportSettings'),
                    { variant: 'error' }
                );
            }
        },
        [enqueueSnackbar, saving, failedToSave]
    );

    return (
        <>
            <button disabled={saving} onClick={handleSaveClick} className="k-button">
                {translate.t('laSaveReportSettings')}
            </button>

            {saveDialogIsOpened && (
                <FormDialog
                    open={true}
                    onClose={handleCloseDialog}
                    title={translate.t('laSaveReportSettings')}
                    formData={defaultFormData}
                    onSubmit={handleSaveReportSettings}
                    paperWidthMd={classes.paperWidthMd}
                >
                    <FormFields inputs={formFieldsInputs} />
                </FormDialog>
            )}
        </>
    );
};

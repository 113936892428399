import * as React from 'react';
import moment from 'moment';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Icon,
  ListItemIcon,
  Menu,
  MenuItem,
  TextField,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { FieldProps } from 'formik';

import { DueDateAutomationSelection, DueDaysAutomation } from '../types';

import styles from './fieldDueDateAutomation/styles';
import translate from '@/app/utils/translate';
import { DATE_FORMAT } from '@/app/utils/helper';
import { eventTargetAsHtmlElement } from '@/old/utils/helper';

type OwnProps = {
  displayValue?: Date;
  disabled?: boolean;
  automationText: string;
  automationType: DueDaysAutomation;
  onChange: (value: DueDateAutomationSelection, manualDate: Date) => void;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const FieldDueDateAutomation = ({
  classes,
  field,
  form,
  displayValue,
  automationText,
  automationType,
  onChange,
  disabled = false,
}: FieldProps & Props) => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<HTMLElement|undefined>();
  const [manualDateDialogOpen, setManualDateDialogOpen] = React.useState(false);
  const [manualDate, setManualDate] = React.useState(displayValue);

  const handleMenuItemClick = (selectedValue: DueDateAutomationSelection) => {
    form.setFieldValue(field.name, selectedValue);
    onChange(selectedValue, manualDate);
    setMenuAnchorEl(undefined);
  };

  // const handleManualDateDialogOpen = () => {
  //   setMenuAnchorEl(undefined);
  //   setManualDateDialogOpen(true);
  // };

  const handleManualDateSave = () => {
    form.setFieldValue(field.name, 'MANUAL');
    onChange('MANUAL', manualDate);
    setManualDateDialogOpen(false);
  };

  const handleManualDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setManualDate(moment(e.target.value).toDate());
  };

  const manualDateDialog = !manualDateDialogOpen ? (
    undefined
  ) : (
    <Dialog
      open={manualDateDialogOpen}
      onClose={() => setManualDateDialogOpen(false)}
    >
      <DialogTitle>Set due date manually</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          label={translate.t('fTaskDueDate')}
          InputLabelProps={{ shrink: true }}
          type="date"
          defaultValue={moment(manualDate || new Date()).format('YYYY-MM-DD')}
          onChange={handleManualDateChange}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => setManualDateDialogOpen(false)}>
          {translate.t('laCancel')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleManualDateSave}
        >
          {translate.t('laOk')}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const menuItemAuto =
    automationType === 'NONE' ? (
      undefined
    ) : (
      <MenuItem value="AUTO" onClick={() => handleMenuItemClick('AUTO')}>
        <ListItemIcon>
          {field.value === 'AUTO' ? (
            <Icon fontSize="small">check</Icon>
          ) : (
            <Icon fontSize="small" />
          )}
        </ListItemIcon>
        {translate.t('label_use_automation', { automationText })}
      </MenuItem>
    );

  return (
    <>
      {manualDateDialog}
      <Menu
        id={field.name}
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(undefined)}
      >
        <MenuItem value="NONE" onClick={() => handleMenuItemClick('NONE')}>
          <ListItemIcon>
            {field.value === 'NONE' ? (
              <Icon fontSize="small">check</Icon>
            ) : (
              <Icon fontSize="small" />
            )}
          </ListItemIcon>
          {translate.t('label_automation_none')}
        </MenuItem>
        {menuItemAuto}
        <Divider />
        <MenuItem
          value="COMPL_DATE"
          onClick={() => handleMenuItemClick('COMPL_DATE')}
        >
          <ListItemIcon>
            {field.value === 'COMPL_DATE' ? (
              <Icon fontSize="small">check</Icon>
            ) : (
              <Icon fontSize="small" />
            )}
          </ListItemIcon>
          {translate.t('label_use_completion_date')}
        </MenuItem>
        {/* <MenuItem
          value="MANUAL"
          onClick={() => handleManualDateDialogOpen()}
        >
          <ListItemIcon>
            {field.value === 'MANUAL' ? <Icon fontSize="small">check</Icon> : <Icon fontSize="small" />}
          </ListItemIcon>
          Set due date manually..
        </MenuItem> */}
      </Menu>
      <Button
        className={classes.button}
        size="small"
        disabled={disabled}
        onClick={(e: React.SyntheticEvent) => setMenuAnchorEl(eventTargetAsHtmlElement(e.target))}
      >
        {!displayValue
          ? translate.t('label_automation_none')
          : moment(displayValue).format(DATE_FORMAT)}
        <Icon>arrow_drop_down</Icon>
      </Button>
    </>
  );
};

export default withStyles(styles)(FieldDueDateAutomation);

import * as React from 'react';
import { v4 as uuid4 } from 'uuid';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
  WithStyles,
  Grid,
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  Typography,
  DialogContent,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  TextField,
  FormHelperText,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import translate from '@/app/utils/translate';
import { Optional } from '@/app/utils/helper';
import answerSurveyFormDialogStyle from './answerSurveyFormDialogStyle';
import {
  getFormValues,
  reset,
  isInvalid,
  isPristine,
  isSubmitting,
  getFormSyncErrors,
} from 'redux-form';
import {
  QuestionType,
  sendSurveyResponse,
  closeAnswerDialog,
  ServerSurvey,
  ServerQuestion,
} from '@/app/redux/surveys';
import { getForm } from '../../Form/Form';
import { FormDataType, changeFormData } from '@/app/redux/formData';
import * as moment from 'moment';
import {
  ConfirmDialogType,
  openConfirmDialog,
} from '@/app/redux/confirmDialog';

const FORM_NAME = 'answerSurveyForm';

type MapStateToProps = {
  currentValues: any; // TODO: how make dynamic type?...
  answerSurvey: Optional<ServerSurvey>;
  open: boolean;
  invalid: (formName: string) => boolean;
  pristine: (formName: string) => boolean;
  submitting: (formName: string) => boolean;
  formSyncErrors: (formName: string) => any;
};

type MapDispatchToProps = {
  resetForm: (formName: string) => void;
  changeFormData: (payload: FormDataType) => void;
  openConfirmDialog: (payload: ConfirmDialogType) => void;
  sendSurveyResponse: (surveyId: string, response: any) => void;
  closeAnswerDialog: () => void;
};

type PropsType = WithStyles<typeof answerSurveyFormDialogStyle> &
  MapDispatchToProps &
  MapStateToProps;

type StateType = {
  formData: any; // TODO: make dynamic type...
  title: string;
  subTitle: string;
  disabled: boolean;
  questionAnswers: any;
};

class AnswerSurveyFormDialog extends React.Component<PropsType> {
  state: StateType = {
    formData: {},
    title: '',
    subTitle: '',
    disabled: true,
    questionAnswers: undefined,
  };
  Form: any;

  componentDidUpdate(prevProps: PropsType) {
    const { answerSurvey, open } = this.props;

    if (
      open &&
      answerSurvey &&
      JSON.stringify(prevProps.answerSurvey) !== JSON.stringify(answerSurvey)
    ) {
      this.setAnswerDialog(answerSurvey);
    }
  }

  setAnswerDialog = (survey: ServerSurvey) => {
    const { questions } = survey;

    const validate = (values: any) => {
      let errors: { [k: string]: any } = {};

      const requiredFields = questions
        .filter(f => f.type !== QuestionType.FREE_TEXT)
        .map(q => q.id);
      const fields = questions.map(q => q.id);

      requiredFields.forEach(field => {
        if (!values[field] || values[field] === '<p>&nbsp;</p>') {
          errors[field] = translate.t('laThisRequired');
        }

        if (
          values[field] &&
          questions.find(q => q.id === field).type ===
            QuestionType.MULTIPLE_CHOICE
        ) {
          const multi = Object.keys(values[field]).filter(
            key => values[field][key] === true,
          );
          if (multi && multi.length === 0) {
            errors[field] = translate.t('laThisRequired');
          }
        }
      });

      fields.forEach(field => {
        if (
          values[field] &&
          questions.find(q => q.id === field).type === QuestionType.FREE_TEXT &&
          values[field].length > 3000
        ) {
          errors[field] = `${translate.t('laErrorMaxTextLen1')} 3000`;
        }
      });

      return errors;
    };

    this.Form = getForm(FORM_NAME, validate);

    const formData = {};
    const questionAnswers = {};

    questions.forEach(q => {
      formData[q.id] = null;
      switch (q.type) {
        case QuestionType.MULTIPLE_CHOICE: {
          let value = {};
          q.options.forEach(c => {
            value[c.id] = false;
          });
          questionAnswers[q.id] = { value };
          break;
        }
        case QuestionType.SINGLE_CHOICE:
        case QuestionType.YES_NO:
        case QuestionType.SCALE:
        case QuestionType.FREE_TEXT:
        default:
          questionAnswers[q.id] = { value: '' };
          break;
      }
    });

    this.setState(
      {
        title: survey.name,
        subTitle: translate.t('title_closes', {
          date: moment(survey.closeDate).format('DD.MM.YYYY'),
        }),
        formData,
        questionAnswers,
      },
      () => this.setFormValues(),
    );
  };

  setFormValues = () => {
    const { formData } = this.state;
    Object.keys(formData).forEach(field => {
      this.props.changeFormData({ form: FORM_NAME, field, value: null });
    });
  };

  closeDialog = () => {
    this.setState({
      title: '',
      subTitle: '',
      disabled: true,
      questionAnswers: undefined,
    });

    this.props.closeAnswerDialog();
  };

  handleClose = () => {
    this.props.openConfirmDialog({
      text: translate.t('text_unsaved_changes'),
      onOk: () => this.closeDialog(),
    });
  };

  getResponse = (survey: ServerSurvey, responses: any) => {
    // TODO: make dynamic type...
    const getAnswer = (qs: ServerQuestion, r: any) => {
      // TODO: type
      const { type, yesLabel, noLabel } = qs;
      if (type === QuestionType.FREE_TEXT) {
        return r || '';
      }

      if (type === QuestionType.MULTIPLE_CHOICE) {
        let options: number[] = [];
        Object.keys(r).forEach(k => {
          if (r[k] === true) {
            options.push(parseInt(k, 10));
          }
        });

        return options;
      }

      if (type === QuestionType.YES_NO) {
        return r === yesLabel && r !== noLabel;
      }

      return parseInt(r, 10);
    };

    const answers = survey.questions.map((q: ServerQuestion) => {
      return {
        questionId: q.id,
        questionType: q.type,
        answer: getAnswer(q, responses[q.id]),
      };
    });

    return {
      responseId: uuid4(),
      answers,
    };
  };

  handleSubmit = () => {
    const { currentValues, answerSurvey } = this.props;
    const responseSurvey = this.getResponse(answerSurvey, currentValues);

    this.props.sendSurveyResponse(answerSurvey.id, responseSurvey);
    this.closeDialog();
  };

  handleCheckChange = (qId: number, choiceId: number) => (event: any) => {
    const { questionAnswers } = this.state;
    questionAnswers[qId].value[choiceId] = event.target.checked;

    const opts = Object.keys(questionAnswers[qId].value).filter(
      key => questionAnswers[qId].value[key] === true,
    );
    const valueOrNull =
      opts && opts.length > 0 ? questionAnswers[qId].value : null;

    this.setState({
      questionAnswers,
    });

    this.props.changeFormData({
      form: FORM_NAME,
      field: `${qId}`,
      value: valueOrNull,
    });
  };

  handleChange = (qId: number) => (event: any) => {
    const { questionAnswers } = this.state;
    const { value } = event.target;

    questionAnswers[qId].value = value;
    this.setState({
      questionAnswers,
    });
    this.props.changeFormData({ form: FORM_NAME, field: `${qId}`, value });
  };

  getQuestion = (q: ServerQuestion) => {
    const { classes, formSyncErrors } = this.props;
    const { questionAnswers } = this.state;

    switch (q.type) {
      case QuestionType.MULTIPLE_CHOICE: {
        return (
          <Grid
            container
            item
            xs={12}
            direction="row"
            key={q.id}
            justify="space-between"
          >
            <FormControl className={classes.formControl} fullWidth required>
              <Grid item className={`${classes.question} ${classes.stretch}`}>
                <FormLabel className={classes.formControlLabel}>
                  {q.text}
                </FormLabel>
                <FormHelperText id={`${q.index}-helper-free-text`}>
                  {translate.t('label_select_all_apply')}
                </FormHelperText>
              </Grid>
              <Grid item className={classes.stretch}>
                {q.options.map(choice => (
                  <FormControlLabel
                    key={choice.index}
                    control={
                      <Checkbox
                        color="primary"
                        className={classes.checkbox}
                        checked={questionAnswers[q.id].value[choice.id]}
                        onChange={this.handleCheckChange(q.id, choice.id)}
                        value={choice.id.toString()}
                      />
                    }
                    label={choice.text}
                  />
                ))}
              </Grid>
            </FormControl>
          </Grid>
        );
      }
      case QuestionType.SINGLE_CHOICE: {
        return (
          <Grid
            container
            item
            xs={12}
            direction="row"
            key={q.id}
            justify="space-between"
          >
            <FormControl className={classes.formControl} fullWidth required>
              <Grid item className={`${classes.question} ${classes.stretch}`}>
                <FormLabel className={classes.formControlLabel}>
                  {q.text}
                </FormLabel>
              </Grid>
              <Grid item className={classes.stretch}>
                <RadioGroup
                  aria-label={`${q.type} question`}
                  name={`${q.id}`}
                  className={classes.group}
                  value={questionAnswers[q.id].value}
                  onChange={this.handleChange(q.id)}
                >
                  {q.options.map(choice => (
                    <FormControlLabel
                      key={choice.index}
                      value={choice.id.toString()}
                      control={
                        <Radio color="primary" className={classes.radio} />
                      }
                      label={choice.text}
                    />
                  ))}
                </RadioGroup>
              </Grid>
            </FormControl>
          </Grid>
        );
      }
      case QuestionType.SCALE: {
        const options = Array.from(new Array(5));

        return (
          <Grid
            container
            item
            xs={12}
            direction="row"
            key={q.id}
            justify="space-between"
          >
            <FormControl className={classes.formControl} fullWidth required>
              <Grid item className={`${classes.question} ${classes.stretch}`}>
                <FormLabel className={classes.formControlLabel}>
                  {q.text}
                </FormLabel>
              </Grid>
              <Grid item className={`${classes.stretch} ${classes.scale}`}>
                {translate.t('label_disagree')}
                <RadioGroup
                  aria-label={`${q.type} question`}
                  name={`${q.id}`}
                  className={`${classes.group} ${classes.inlineGroup}`}
                  value={questionAnswers[q.id].value}
                  onChange={this.handleChange(q.id)}
                >
                  {options.map((_c, index) => (
                    <FormControlLabel
                      key={index}
                      value={`${index + 1}`}
                      control={
                        <Radio color="primary" className={classes.radio} />
                      }
                      label={index + 1}
                    />
                  ))}
                </RadioGroup>
                {translate.t('label_agree')}
              </Grid>
            </FormControl>
          </Grid>
        );
      }
      case QuestionType.YES_NO:
        return (
          <Grid
            container
            item
            xs={12}
            direction="column"
            key={q.id}
            justify="space-between"
          >
            <FormControl className={classes.formControl} fullWidth required>
              <Grid item className={`${classes.question} ${classes.stretch}`}>
                <FormLabel className={classes.formControlLabel}>
                  {q.text}
                </FormLabel>
              </Grid>
              <Grid item className={classes.stretch}>
                <RadioGroup
                  aria-label={`${q.type} question`}
                  name={`${q.id}`}
                  className={classes.group}
                  value={questionAnswers[q.id].value}
                  onChange={this.handleChange(q.id)}
                >
                  <FormControlLabel
                    value={q.yesLabel}
                    control={
                      <Radio color="primary" className={classes.radio} />
                    }
                    label={q.yesLabel}
                  />
                  <FormControlLabel
                    value={q.noLabel}
                    control={
                      <Radio color="primary" className={classes.radio} />
                    }
                    label={q.noLabel}
                  />
                </RadioGroup>
              </Grid>
            </FormControl>
          </Grid>
        );
      case QuestionType.FREE_TEXT: {
        const err = formSyncErrors(FORM_NAME);
        const hasError = !!err[q.id];
        const error = err[q.id];

        return (
          <Grid item container xs={12} key={q.index} justify="space-between">
            <FormControl className={classes.formControl} fullWidth>
              <Grid item className={`${classes.question} ${classes.stretch}`}>
                <FormLabel className={classes.formControlLabel}>
                  {q.text}
                  <FormHelperText id={`${q.index}-helper-free-text`}>
                    {translate.t('label_enter_up_to_chars', { count: 3000 })}
                  </FormHelperText>
                </FormLabel>
              </Grid>
              <Grid item className={classes.stretch}>
                <TextField
                  id={`${q.id}-${q.type}-question`}
                  label={''}
                  multiline
                  helperText={hasError ? error : null}
                  error={hasError}
                  rows="5"
                  value={questionAnswers[q.id].value}
                  onChange={this.handleChange(q.id)}
                  className={classes.textField}
                  margin="normal"
                  fullWidth
                />
              </Grid>
            </FormControl>
          </Grid>
        );
      }
      default:
        return <></>;
    }
  };

  renderQuestions = (questions: ServerQuestion[]) => {
    const qs: React.ReactNode[] = [];
    questions.forEach(q => {
      qs.push(this.getQuestion(q));
    });

    return (
      <Grid container direction="row">
        {qs.map(q => q)}
      </Grid>
    );
  };

  render() {
    const {
      open,
      classes,
      invalid,
      pristine,
      submitting,
      answerSurvey,
      formSyncErrors,
    } = this.props;
    const { title, subTitle, formData, questionAnswers } = this.state;

    if (!(open && !!answerSurvey && answerSurvey.id && !!questionAnswers)) {
      return null;
    }

    const { Form } = this;

    const formErr = Object.keys(formSyncErrors(FORM_NAME));
    const disabled =
      invalid(FORM_NAME) ||
      pristine(FORM_NAME) ||
      submitting(FORM_NAME) ||
      formErr.length > 0;

    return (
      <Dialog
        open={open}
        aria-labelledby="answer-form-dialog"
        disableBackdropClick
        onEscapeKeyDown={this.handleClose}
        color="primary"
        maxWidth="md"
        classes={{ paperWidthMd: classes.paperWidthMd }}
      >
        <DialogTitle>
          <>
            {title}
            <Typography component="p" variant="subtitle2" gutterBottom>
              {subTitle}
            </Typography>
          </>
        </DialogTitle>
        <DialogContent>
          <Form formData={formData}>
            {this.renderQuestions(answerSurvey.questions)}
          </Form>
        </DialogContent>
        <DialogActions
          classes={{
            root: classes.dialogActions,
          }}
        >
          <Button
            variant="text"
            component="a"
            color="primary"
            onClick={this.handleClose}
          >
            {translate.t('laCancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={disabled}
            onClick={this.handleSubmit}
          >
            {translate.t('laSend')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { answerSurveyOpen: open, answerSurvey } = state.surveys;

  return {
    currentValues: getFormValues(FORM_NAME)(state),
    answerSurvey,
    open,
    invalid: (formName: string) => isInvalid(formName)(state),
    pristine: (formName: string) => isPristine(formName)(state),
    submitting: (formName: string) => isSubmitting(formName)(state),
    formSyncErrors: (formName: string) => getFormSyncErrors(formName)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetForm: (formName: string) => dispatch(reset(formName)),
  changeFormData: (payload: FormDataType) => changeFormData(dispatch, payload),
  openConfirmDialog: (payload: ConfirmDialogType) =>
    dispatch(openConfirmDialog(payload)),
  sendSurveyResponse: (surveyId: string, response: any) =>
    dispatch<any>(sendSurveyResponse(surveyId, response)),
  closeAnswerDialog: () => dispatch(closeAnswerDialog()),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(answerSurveyFormDialogStyle, { withTheme: true }),
);

export default enhance(AnswerSurveyFormDialog);

import React from 'react';
import translate from '@/app/utils/translate';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

type Props = {
  handleChangeEmpScope?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  scope?: string;
};

const SelectTemplates = (props: Props) => {
  const { scope, handleChangeEmpScope } = props;

  return (
    <>
      <form autoComplete="off">
        <FormControl variant="outlined">
          <Select value={scope} onChange={handleChangeEmpScope}>
            <MenuItem value={'CURRENT_EMPLOYEES'}>
              {translate.t('laCurrentEmployees')}
            </MenuItem>
            <MenuItem value={'ENDED_EMPLOYEES'}>
              {translate.t('laEndedEmployees')}
            </MenuItem>
            <MenuItem value={'ALL_EMPLOYEES'}>
              {translate.t('laAllEmployees')}
            </MenuItem>
          </Select>
        </FormControl>
      </form>
    </>
  );
};

export default SelectTemplates;

import { Country, getCompanyCountries } from '@/old/utils/helper';
import { useUnits } from '@/app/hooks/useUnits';
import { SelectOptionProps } from '@/app/components/AutoDeduction/types';
import { useMemo } from 'react';

type OutputType = {
  countryOptions: SelectOptionProps[];
  unitOptions: SelectOptionProps[];
};

export const useCountryAndUnitOptions = (): OutputType => {
  const { allUnits } = useUnits();
  const countries: Country[] = getCompanyCountries();

  const getCountryOptions = (): SelectOptionProps[] =>
    useMemo(() => {
      return countries.map(country => ({
        label: country.name,
        value: country.code,
      }));
    }, [countries]);

  const getUnitOptions = (): SelectOptionProps[] =>
    useMemo(() => {
      return [{ label: '', value: ''}].concat(allUnits.map(unit => ({
        label: unit.fTreeUnitName,
        value: unit.fTreeUnitId,
      })));
    }, [allUnits]);

  return {
    countryOptions: getCountryOptions(),
    unitOptions: getUnitOptions(),
  };
};

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  TextField,
  DialogActions,
  withStyles,
  WithStyles,
  Grid,
} from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { compose } from 'recompose';

import LocalizedDatePicker from '@/app/components/Pickers/LocalizedDatePicker';
import { validText } from '@/app/utils/helper';
import Service from '@/app/utils/service';
import translate from '@/app/utils/translate';
import { getEmployeeName, getLoggedUserId } from '@/old/utils/helper';

import { CustomField } from '../../CustomField';
import { formatFieldsValue } from '../../CustomField/formatFieldsValue';
import { CustomFieldType } from '../../CustomField/types';
import { Decision } from '../DecisionRegistryContainer';
import { decisionRegistryFormStyles } from './decisionRegistryFormStyles';

type OwnProps = {
  employeeId: number;
  dialogOpen: boolean;
  editDrAllCustomFields: any;
  addDrEmptyCustomFields: any;
  handleClose: () => void;
  fetchDecisionRegistryData: () => void;
  decision: Decision;
  updateCustomFields: (customData: any) => void;
  register: any;
  getValues: any;
  setValue: any;
  errors: any;
  handleSubmit: any;
};

type InnerProps = WithStyles<typeof decisionRegistryFormStyles>;
type Props = OwnProps & InnerProps;

const DecisionRegistryForm: React.FC<Props> = ({
  employeeId,
  dialogOpen,
  handleClose,
  fetchDecisionRegistryData,
  decision,
  classes,
  editDrAllCustomFields,
  addDrEmptyCustomFields,
  updateCustomFields,
  register,
  getValues,
  setValue,
  errors,
  handleSubmit,
}) => {
  const loggedInUserId = getLoggedUserId();
  const inEditMode = !!decision;

  const [type, setType] = useState('');
  const [title, setTitle] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [decisionInfo, setDecisionInfo] = useState('');
  const [reminderDate, setReminderDate] = useState(
    moment().format('YYYY-MM-DD'),
  );
  const [showValidationError, setShowValidationError] = useState({
    type: false,
    title: false,
  });

  const customFieldRules = {
    [CustomFieldType.STRING]: {
      validate: {
        maxLength: (value: string) =>
          value.length < 80 || translate.t('laErrorMaxTextLen1') + ' 80',
      },
    },
    [CustomFieldType.TEXT]: {
      validate: {
        maxLength: (value: string) =>
          value.length < 3000 || translate.t('laErrorMaxTextLen1') + ' 3000',
      },
    },
    [CustomFieldType.NUMBER]: {
      validate: {
        pattern: (value: string) =>
          /^-?\d*,?\d*$/.test(value) || translate.t('laCheckNumber'),
      },
    },
  };

  const loadOnEdit = (decisionToEdit?: Decision) => {
    if (decisionToEdit) {
      setType(decisionToEdit.type);
      setTitle(decisionToEdit.title);
      setDecisionInfo(decisionToEdit.info ? decisionToEdit.info : '');
      setSelectedFile(decisionToEdit.file);
      if (reminderDate === null) {
        return;
      }
      setReminderDate(
        decisionToEdit.reminderDate
          ? decisionToEdit.reminderDate
          : moment().format('YYYY-MM-DD'),
      );
    }
  };

  useEffect(() => {
    loadOnEdit(decision);
  }, [decision]);

  const clearState = () => {
    setType('');
    setTitle('');
    setDecisionInfo('');
    setSelectedFile(null);
    setReminderDate(moment().format('YYYY-MM-DD'));
    setShowValidationError({ type: false, title: false });
  };

  const handleSaveData = useMemo(() => {
    return handleSubmit((customFieldValues: any) => {
      const formData = new FormData();
      if (!validText(type)) {
        setShowValidationError({
          type: true,
          title: showValidationError.title,
        });
        return;
      }
      if (!validText(title)) {
        setShowValidationError({ type: showValidationError.type, title: true });
        return;
      }

      formData.append('type', type);
      formData.append('title', title);
      formData.append('maker', getEmployeeName(loggedInUserId, false, true));
      formData.append('info', decisionInfo);
      if (reminderDate !== null) {
        formData.append('reminderDate', reminderDate);
      }
      formData.append('isSystemGenerated', 'false');
      formData.append('file', selectedFile);

      const addURL = 'd/form/employee/' + employeeId + '/decisionregistry/add';
      let updateURL = '';
      let customForm = {};
      if (decision) {
        updateURL =
          'd/form/employee/' +
          employeeId +
          '/decisionregistry/' +
          decision.id +
          '/update';
        customForm = formatFieldsValue(
          customFieldValues,
          editDrAllCustomFields[decision.id]
            ? editDrAllCustomFields[decision.id]
            : addDrEmptyCustomFields,
        );
      } else {
        customForm = formatFieldsValue(
          customFieldValues,
          addDrEmptyCustomFields,
        );
      }

      if (inEditMode) {
        Service.put(updateURL, formData, () => {
          Service.put(
            `d/json/customdata/DECISION_REGISTRY/${decision.id}`,
            customForm,
            () => {
              fetchDecisionRegistryData();
              handleClose();
              clearState();
            },
          );
        });
      } else {
        Service.post(addURL, formData, (data: any) => {
          Service.put(
            `d/json/customdata/DECISION_REGISTRY/${data.id}`,
            customForm,
            () => {
              fetchDecisionRegistryData();
              handleClose();
              clearState();
            },
          );
        });
      }
    });
  }, [type, title, decisionInfo, loggedInUserId, reminderDate, selectedFile]);

  const handleReminderChange = (date: moment.Moment) => {
    setReminderDate(date.format('YYYY-MM-DD'));
  };

  const handleFileUpload = (e: any) => {
    setSelectedFile(e.target.files[0]);
  };
  return (
    <Dialog
      open={dialogOpen}
      onClose={() => {
        handleClose();
        clearState();
      }}
    >
      <DialogTitle id="form-dialog-title">
        {translate.t('laAddToDecisionRegistry')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {translate.t('laManualDecisionRegistry')}
        </DialogContentText>

        <TextField
          required
          autoFocus
          margin="dense"
          id="decisionType"
          label="Type"
          value={type}
          onChange={e => {
            setType(e.target.value);
            setShowValidationError({
              type: false,
              title: showValidationError.title,
            });
          }}
          disabled={inEditMode && decision.isSystemGenerated ? true : false}
          error={showValidationError.type ? true : false}
          helperText={
            showValidationError.type ? translate.t('laThisRequired') : ' '
          }
          fullWidth
        />
        <TextField
          required
          margin="dense"
          id="decisionTitle"
          label="Title"
          value={title}
          onChange={e => {
            setTitle(e.target.value);
            setShowValidationError({
              title: false,
              type: showValidationError.type,
            });
          }}
          disabled={inEditMode && decision.isSystemGenerated ? true : false}
          error={showValidationError.title ? true : false}
          helperText={
            showValidationError.title ? translate.t('laThisRequired') : ' '
          }
          fullWidth
        />
        <TextField
          margin="dense"
          id="decisionInfo"
          label="Info"
          value={decisionInfo}
          onChange={e => setDecisionInfo(e.target.value)}
          fullWidth
        />
        <form>
          {decision && decision.id && editDrAllCustomFields[decision.id]
            ? editDrAllCustomFields[decision.id].map((customField: any) => {
              return (
                <Grid key={customField.code} item={true}>
                  <CustomField
                    fieldDetails={customField}
                    isSaving={
                      inEditMode && decision.isSystemGenerated ? true : false
                    }
                    idEditMode={true}
                    formValues={getValues()}
                    setValue={setValue}
                    updateFormValues={updateCustomFields}
                    validationRules={customFieldRules}
                    errors={errors}
                    register={register}
                  />
                </Grid>
              );
            })
            : addDrEmptyCustomFields.map((customField: any) => {
              return (
                <Grid key={customField.code} item={true}>
                  <CustomField
                    fieldDetails={customField}
                    isSaving={
                      inEditMode && decision.isSystemGenerated ? true : false
                    }
                    idEditMode={true}
                    formValues={getValues()}
                    setValue={setValue}
                    errors={errors}
                    validationRules={customFieldRules}
                    updateFormValues={updateCustomFields}
                    register={register}
                  />
                </Grid>
              );
            })}
        </form>
        <br />
        <br />
        <div>
          <span>{translate.t('laAttachment')}: </span>
          <input
            accept="*"
            className={classes.inputFileUpload}
            id="raised-button-file"
            multiple
            type="file"
            onChange={e => handleFileUpload(e)}
          />
          <label htmlFor="raised-button-file">
            <Button variant="contained" component="span">
              {selectedFile || (decision && decision.attachmentName)
                ? translate.t('laChangeFile')
                : translate.t('laChooseFile')}
            </Button>
          </label>
          <span className={classes.fileButton}>
            {selectedFile
              ? selectedFile.name
              : decision && decision.attachmentName
                ? decision && decision.attachmentName
                : translate.t('laNoFile')}
          </span>
        </div>
        <br />
        <LocalizedDatePicker
          label={translate.t('laReminder')}
          value={reminderDate}
          minDate={moment()}
          onChange={(date: moment.Moment) => handleReminderChange(date)}
          autoOk={true}
        />
        <Button
          variant="contained"
          component="span"
          onClick={() => setReminderDate(null)}
          className={classes.reminderButton}
        >
          {translate.t('laNoReminder')}
        </Button>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
            clearState();
          }}
          color="primary"
        >
          {translate.t('laCancel')}
        </Button>
        <Button
          onClick={e => {
            e.preventDefault();
            handleSaveData();
          }}
          color="primary"
        >
          {translate.t('laSave')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const enhance = compose<Props, OwnProps>(
  withStyles(decisionRegistryFormStyles),
);

export default enhance(DecisionRegistryForm);

import translate from '@/app/utils/translate';
import { FormCompletionStatus } from '@/app/components/TemplateComponents/form/enums';
import { FormTemplate } from './types';

export const sortArr = (arr: any[], key: any, order: 'asc' | 'desc') => {
  return [...arr].sort(function(a: any, b: any) {
    if (order === 'asc') {
      return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
    } else if (order === 'desc') {
      return a[key] > b[key] ? -1 : a[key] < b[key] ? 1 : 0;
    }
    return 0;
  });
};

export const filterTemplatesByTags = (templates: FormTemplate[], tagCodes: string[]) => {
  if (!tagCodes || !tagCodes.length) {
    return templates;
  }

  return templates.filter((template) => {
    if (!template.tags || !template.tags.length) {
      return false;
    }

    const found = tagCodes.find((tagCode) => {
      return template.tags.find(tplTag => tplTag.tag === tagCode);
    });

    return !!found;
  });
};

export const translateCompletionStatus = (completionStatus: string): string => {
  if (completionStatus === FormCompletionStatus.IN_PROGRESS) {
    return translate.t('laInProgress');
  }

  if (completionStatus === FormCompletionStatus.COMPLETED) {
    return translate.t('laCompleted');
  }

  return completionStatus;
};

// Obsolete?
export const appraisalStatusTranslation = (value: string) => {
  if (value === 'ACTIVE') {
    return translate.t('laActive');
  }
  if (value === 'INACTIVE') {
    return translate.t('laInactive');
  }
  return value;
};

// Obsolete?
export const empAppraisalStatusTranslation = (value: string) => {
  if (value === FormCompletionStatus.IN_PROGRESS) {
    return translate.t('laInProgress');
  }
  if (value === FormCompletionStatus.COMPLETED) {
    return translate.t('laCompleted');
  }
  if (value === FormCompletionStatus.EMPLOYEE_APPROVED) {
    return translate.t('laEmployeeApproved');
  }
  return value;
};

export const orderElementsByIndex = <Type>(list: Type[]): Type[] => {
  let orderedList = [].concat(list);
  orderedList = orderedList.sort((a, b) => (a.index > b.index ? 1 : -1));
  return orderedList;
};

export const sortByDate = function(arrayOfObject: any[]) {
  arrayOfObject.sort(function compare(a: any, b: any) {
    var dateA: Date = new Date(a.createdTime);
    var dateB: Date = new Date(b.createdTime);
    return dateB.getTime() - dateA.getTime();
  });
  return arrayOfObject;
};

export const reIndexAndResetDefault = function(arrayOfObjects: any[]) {
  arrayOfObjects = arrayOfObjects.map((eachObject, index) => {
    eachObject.index = index;
    eachObject.default = index === 0;

    return eachObject;
  });
  return arrayOfObjects;
};

export const getLatestArchiveOfEvaluations = function(archives: any) {
  let usedTemplates: any[] = [];
  let latestArchives: any[] = [];
  let sortedArchives = sortByDate(archives);
  sortedArchives.map(archive => {
    if (!usedTemplates.includes(archive.templateId)) {
      latestArchives.push(archive);
      usedTemplates.push(archive.templateId);
    }
  });
  return latestArchives;
};

export const nameDuplicationCheck = function(arrayOfObjects: any[]) {
  const valueArr = arrayOfObjects.map(item => item.label);
  return valueArr.some((item, idx) => valueArr.indexOf(item) !== idx);
};

// Looks through an array of objects and returns the object with the default flag
export const getDefault = function(arrayOfObjects: any[]) {
  const def = arrayOfObjects.find( object => object.default === true );
  return def;
};

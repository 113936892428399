export enum ReportSettingsPrivacyType {
    PERSONAL = 'PERSONAL',
    COMMON = 'COMMON'
}

export interface Entity {
    id: string;
}

export interface UpdatableReportSettingsData {
    name: string;
    reportType: string;
    privacyType: ReportSettingsPrivacyType;
    activeColumns: string;
}

export interface ReportSettings extends Entity, UpdatableReportSettingsData {
    createdBy: number;
    createdAt: string;
    updatedBy: number;
    updatedAt: string;
}

export enum ReportSettingsTypes {
    GOALSV2 = 'GOALSV2',
    OPEN_WORKFLOW_REWARDING = 'OPEN_WORKFLOW_REWARDING',
    CLOSED_WORKFLOW_REWARDING = 'CLOSED_WORKFLOW_REWARDING',
    OPEN_WORKFLOW_INTPOS = 'OPEN_WORKFLOW_INTPOS',
    CLOSED_WORKFLOW_INTPOS = 'CLOSED_WORKFLOW_INTPOS',
    OPEN_WORKFLOW_EMPLDET = 'OPEN_WORKFLOW_EMPLDET',
    CLOSED_WORKFLOW_EMPLDET = 'CLOSED_WORKFLOW_EMPLDET',
    COMPENSATION_CURRENT = 'COMPENSATION_CURRENT',
    TRAINING = 'TRAINING',
    PERSONNEL_CUSTOM = 'PERSONNEL_CUSTOM',
    FULL_TIME_EQUIVALENT = 'FULL_TIME_EQUIVALENT'
}
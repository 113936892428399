import * as React from 'react';
import { compose } from 'recompose';
import {
  withStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  IconButton,
  WithStyles,
  Tooltip,
  Modal,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import TranslateIcon from '@material-ui/icons/Translate';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import translate from '@/app/utils/translate';
import AddSection from '../AddSection/AddSection';
import ComponentListing from '../ComponentListing/ComponentListing';
import TranslateSection from '../TranslateSection/TranslateSection';
import sectionListingStyle from './sectionListingStyle';

type State = {
  editModalOpen: boolean;
  translateModalOpen: boolean;
  expanded: boolean;
};

type Props = {
  template: any;
  section: any;
  updateSection: any;
  deleteSection: any;
  addSectionComponent: any;
  updateSectionComponent: any;
  orderSectionComponents: any;
  deleteSectionComponent: any;
  defaultExpanded: boolean;
} & WithStyles<typeof sectionListingStyle>;

class SectionListing extends React.Component<Props> {
  state: State = {
    editModalOpen: false,
    translateModalOpen: false,
    expanded: null,
  };

  openEditModal = (event: any) => {
    event.stopPropagation();
    this.setState({ editModalOpen: true });
  };

  closeEditModal = () => {
    this.setState({ editModalOpen: false });
  };

  openTranslateModal = (event: any) => {
    event.stopPropagation();
    this.setState({ translateModalOpen: true });
  };

  closeTranslateModal = () => {
    this.setState({ translateModalOpen: false });
  };

  toggleExpansionPanel = (_event: object, expanded: boolean) => {
    this.setState({ expanded });
  };

  handleSectionDelete = (event: any) => {
    event.stopPropagation();
    this.props.deleteSection(this.props.template.id, this.props.section.id);
  };

  render() {
    const { expanded, editModalOpen, translateModalOpen } = this.state;

    const {
      classes,
      template,
      section,
      updateSection,
      defaultExpanded,
      addSectionComponent,
      updateSectionComponent,
      deleteSectionComponent,
      orderSectionComponents,
    } = this.props;

    return (
      <div className={classes.root}>
        <ExpansionPanel
          defaultExpanded={defaultExpanded || expanded}
          onChange={this.toggleExpansionPanel}
        >
          <ExpansionPanelSummary>
            <Typography variant="h6">
              <IconButton className={classes.iconButton}>
                {expanded ? (
                  <ExpandLessIcon fontSize="small" />
                ) : (
                  <ExpandMoreIcon fontSize="small" />
                )}
              </IconButton>

              <label>{section.name}</label>

              <IconButton onClick={this.openEditModal}>
                <Tooltip title={translate.t('laEdit')}>
                  <EditIcon fontSize="small" />
                </Tooltip>
              </IconButton>

              <IconButton onClick={this.openTranslateModal}>
                <Tooltip title={translate.t('title_translate')}>
                  <TranslateIcon fontSize="small" />
                </Tooltip>
              </IconButton>

              <IconButton onClick={this.handleSectionDelete}>
                <Tooltip title={translate.t('laDelete')}>
                  <DeleteIcon fontSize="small" />
                </Tooltip>
              </IconButton>
            </Typography>
          </ExpansionPanelSummary>

          <ExpansionPanelDetails>
            <ComponentListing
              template={template}
              section={section}
              addComponent={addSectionComponent}
              updateComponent={updateSectionComponent}
              deleteComponent={deleteSectionComponent}
              orderComponents={orderSectionComponents}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <Modal open={editModalOpen} onClose={this.closeEditModal}>
          <AddSection
            template={template}
            sectionBeingEdited={section}
            updateSection={updateSection}
            handleModalClose={this.closeEditModal}
          />
        </Modal>

        <Modal open={translateModalOpen} onClose={this.closeTranslateModal}>
          <TranslateSection
            templateId={template.id}
            sectionId={section.id}
            handleModalClose={this.closeTranslateModal}
          />
        </Modal>
      </div>
    );
  }
}

const enhance = compose<any, any>(withStyles(sectionListingStyle));

export default enhance(SectionListing);

import moment from 'moment';
import { useEffect, useMemo, useRef } from 'react';

import {
  getLoggedUserCountry,
  getSelectedEmpId,
  getUserLegalEntityId,
} from '@/old/utils/helper';

import { WEEK_FORMAT } from './timeSheetConstants';
import { TimeSheetInnerProps } from './timeSheetModels';
import { useCurrentDate } from '@/app/hooks/useCurrentDate';

export const useTimeSheet = ({
  activeWeek,
  activeYears,
  loadingTimeSheet,
  loadingTimeCards,
  loadingHoursSettings,
  loadedTimeSheet,
  loadedTimeCards,
  loadedHoursSettings,
  failedToLoadTimeSheet,
  failedToLoadTimeCards,
  failedToLoadHoursSettings,
  loadingPublicHolidaysYears,
  loadedPublicHolidaysYears,
  failedToLoadPublicHolidaysYears,
  loadingAutoDeductionRule,
  loadedAutoDeductionRule,
  failedToLoadAutoDeductionRule,
  getTimeSheet,
  getTimeCards,
  getHoursSettings,
  getPublicHolidays,
  getAutoDeductionRule,
  clearState,
}: TimeSheetInnerProps) => {
  /**
   * Use a reference to prev activeWeek/activeYears to be able
   * to detect the change to be able to decide
   * when to load data.
   */
  const prevActiveWeekRef = useRef<string>();
  const prevActiveYearsRef = useRef<string[]>();

  const daysOfWeek: string[] = useMemo(() => {
    const startOfWeek = moment(activeWeek, WEEK_FORMAT).startOf('isoWeek');

    return Array.apply(null, Array(7)).map((_: undefined, index: number) => {
      return moment(startOfWeek)
        .add(index, 'days')
        .format('YYYY-MM-DD');
    });
  }, [activeWeek]);

  const currentDate = useCurrentDate();

  const loadedPublicHolidays = useMemo(
    () =>
      !(window as any)._.difference(activeYears, loadedPublicHolidaysYears)
        .length,
    [activeYears, loadedPublicHolidaysYears],
  );

  const loadingPublicHolidays = useMemo(
    () =>
      !!(window as any)._.intersection(activeYears, loadingPublicHolidaysYears)
        .length,
    [activeYears, loadingPublicHolidaysYears],
  );

  const failedToLoadPublicHolidays = useMemo(
    () =>
      !!(window as any)._.intersection(
        activeYears,
        failedToLoadPublicHolidaysYears,
      ).length,
    [activeYears, failedToLoadPublicHolidaysYears],
  );

  const loadingData =
    loadingTimeSheet ||
    loadingTimeCards ||
    loadingHoursSettings ||
    loadingAutoDeductionRule ||
    loadingPublicHolidays;
  const loadedData =
    loadedTimeSheet &&
    loadedTimeCards &&
    loadedHoursSettings &&
    loadedAutoDeductionRule &&
    loadedPublicHolidays;
  const failedToLoadData =
    failedToLoadTimeSheet ||
    failedToLoadTimeCards ||
    failedToLoadHoursSettings ||
    failedToLoadAutoDeductionRule ||
    failedToLoadPublicHolidays;

  useEffect(() => {
    if (!loadingTimeSheet && !loadedTimeSheet && !failedToLoadTimeSheet) {
      getTimeSheet(getSelectedEmpId());
    }
  }, [getTimeSheet, loadingTimeSheet, loadedTimeSheet, failedToLoadTimeSheet]);

  useEffect(() => {
    if (
      activeWeek !== prevActiveWeekRef.current &&
      !loadingTimeCards &&
      !loadedTimeCards
    ) {
      getTimeCards(getSelectedEmpId(), activeWeek);
    }
  }, [
    getTimeCards,
    activeWeek,
    prevActiveWeekRef,
    loadingTimeCards,
    loadedTimeCards,
  ]);

  useEffect(() => {
    if (
      !loadingHoursSettings &&
      !loadedHoursSettings &&
      !failedToLoadHoursSettings
    ) {
      getHoursSettings(getLoggedUserCountry());
    }
  }, [
    getHoursSettings,
    loadingHoursSettings,
    loadedHoursSettings,
    failedToLoadHoursSettings,
  ]);

  useEffect(() => {
    if (prevActiveYearsRef.current === activeYears) {
      return;
    }

    getPublicHolidays(
      getUserLegalEntityId(),
      (window as any)._.difference(
        activeYears,
        (window as any)._.union(
          loadingPublicHolidaysYears,
          loadedPublicHolidaysYears,
        ),
      ),
    );
  }, [
    prevActiveYearsRef,
    activeYears,
    getPublicHolidays,
    loadingPublicHolidaysYears,
    loadedPublicHolidaysYears,
  ]);

  useEffect(() => {
    if (
      !loadingAutoDeductionRule &&
      !loadedAutoDeductionRule &&
      !failedToLoadAutoDeductionRule
    ) {
      getAutoDeductionRule(getSelectedEmpId());
    }
  }, [
    getAutoDeductionRule,
    loadingAutoDeductionRule,
    loadedAutoDeductionRule,
    failedToLoadAutoDeductionRule,
  ]);

  useEffect(() => {
    prevActiveWeekRef.current = activeWeek;
  }, [prevActiveWeekRef, activeWeek]);

  useEffect(() => {
    prevActiveYearsRef.current = activeYears;
  }, [prevActiveYearsRef, activeYears]);

  useEffect(() => {
    return () => {
      clearState();
    };
  }, []);

  return {
    currentDate,
    daysOfWeek,
    loadingData,
    loadedData,
    failedToLoadData,
  };
};

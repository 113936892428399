import * as React from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText, TextField,
} from '@material-ui/core';
import translate from '@/app/utils/translate';

interface State {
  open: boolean;
  title: string;
  label: string;
  value: string;
  helpText: string;
  onOk: (value: string, evt: React.MouseEvent) => void;
}

let openDialogFn: Function;

class PromptNameDialog extends React.PureComponent {
  state: State = {
    open: false,
    title: null,
    label: null,
    value: '',
    helpText: null,
    onOk: null,
  };

  componentDidMount() {
    openDialogFn = this.openDialog;
  }

  openDialog = (
    title: string,
    label: string,
    defaultValue: string = '',
    onOk: React.MouseEventHandler,
    helpText: string = null
  ) => {
    this.setState({
      open: true,
      title,
      label,
      value: defaultValue,
      helpText,
      onOk,
    });
  }

  handleOk = (event: React.MouseEvent) => {
    const { value, onOk } = this.state;
    this.handleClose();
    onOk(value, event);
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
  }

  handleChange = (newValue: string) => {
    this.setState({ value: newValue });
  }

  render () {
    const { title, label, value, helpText, open } = this.state;

    return (
      <Dialog
        open={open}
        aria-labelledby="prompt-dialog-title"
        disableBackdropClick
        onEscapeKeyDown={this.handleClose}
        maxWidth="xs"
      >
        <DialogTitle id="prompt-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {helpText}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={label}
            type="text"
            value={value}
            onChange={(evt: any) => this.handleChange(evt.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            component="a"
            onClick={this.handleClose}
            color="primary"
          >
            {translate.t('laCancel')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleOk}
          >
            {translate.t('laSave')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
export const openDialog = (
  title: string,
  label: string,
  defaultValue: string,
  onOk: (value: string, evt: React.MouseEvent) => void,
  helpText: string = null
) => {
  openDialogFn(title, label, defaultValue, onOk, helpText);
};

export default PromptNameDialog;
import moment from 'moment';
import {
  FieldErrors,
  SchemaValidationResult,
  ValidationResolver,
} from 'react-hook-form';
import * as yup from 'yup';

import { parseHMMToDuration } from '@/app/utils/helper';
import translate from '@/app/utils/translate';

import {
  DailySummaryFormValues,
  TimeBlockFormValues,
} from './dailySummaryModels';
import { filterNonEmptyTimeBlocks } from './filterNonEmptyTimeBlocks';

const getBaseDataValidationSchema = () => {
  return yup.object().shape<DailySummaryFormValues>({
    defaultWorkingHours: yup
      .string()
      .required(translate.t('laThisRequired'))
      .test(
        'duration',
        translate.t('laErrorDefaultWorkingHoursDurationFormat'),
        (value: string) => {
          if (!value) {
            return true;
          }

          const duration = parseHMMToDuration(value);

          if (!duration) {
            return false;
          }

          const numberOfHours = duration.asHours();
          return numberOfHours >= 0 && numberOfHours <= 24;
        },
      ),
    comment: yup
      .string()
      .nullable()
      .max(80, `${translate.t('laErrorMaxTextLen1')} 80!`),
    timeBlocks: yup.object(),
  });
};

const getTimeBlockValidationSchema = (date: string) => {
  return yup.object().shape<TimeBlockFormValues>({
    reportingType: yup
      .string()
      .strict(true)
      .required(translate.t('laThisRequired')),
    start: yup.date().when('hours', {
      is: (value: string) => !value,
      then: yup.date().required(translate.t('laThisRequired')),
      otherwise: yup.date().nullable(),
    }),
    end: moment(date).isSame(new Date(), 'day')
      ? yup.date().nullable()
      : yup.date().when('start', (start: Date, schema: yup.DateSchema) => {
          return (
            start &&
            schema.min(
              moment(start)
                .add(1, 'minute')
                .toDate(),
              `${translate.t('laErrorGreaterThan')} ${translate.t('laStart')}!`,
            )
          );
        }),
    hours: moment(date).isSame(new Date(), 'day')
      ? yup.string().nullable()
      : yup.string().when('reportingType', {
          is: (value: string) => {
            const [reportingGroupType] = (value || '').split('-');
            return !!reportingGroupType;
          },
          then: yup
            .string()
            .required(translate.t('laThisRequired'))
            .test(
              'duration',
              translate.t('laErrorDurationFormat'),
              (value: string) => !value || !!parseHMMToDuration(value),
            ),
          otherwise: yup.string().nullable(),
        }),
    customData: yup.object(),
  });
};

const mapYupValidationError = (error: yup.ValidationError) => {
  return error.inner.reduce(
    (allErrors, currentError) => ({
      ...allErrors,
      [currentError.path]: {
        type: currentError.type || 'validation',
        message: currentError.message,
      },
    }),
    {},
  );
};

const intervalsAreIntersecting = (
  intervals: Array<{ start: string; end?: string }>,
) => {
  if (intervals.length === 1) {
    return false;
  }

  const sortedIntervalsByStart = [...intervals].sort((a, b) => {
    return a.start === b.start ? 0 : a.start < b.start ? -1 : 1;
  });

  for (let i = 0; i < sortedIntervalsByStart.length - 1; i++) {
    const currentEndTime = sortedIntervalsByStart[i].end;
    const nextStartTime = sortedIntervalsByStart[i + 1].start;

    if (!currentEndTime || currentEndTime > nextStartTime) {
      return true;
    }
  }

  return false;
};

export const dailySummaryValidationResolverFactory = (
  date: string,
): ValidationResolver<DailySummaryFormValues> => {
  return async values => {
    let errors: FieldErrors<DailySummaryFormValues> = {};

    try {
      await getBaseDataValidationSchema().validate(values, {
        abortEarly: false,
      });
    } catch (error) {
      errors = { ...errors, ...mapYupValidationError(error) };
    }

    const nonEmptyTimeBlocks = filterNonEmptyTimeBlocks(values.timeBlocks);
    for (let i = 0; i < nonEmptyTimeBlocks.length; i++) {
      try {
        await getTimeBlockValidationSchema(date).validate(
          values.timeBlocks[nonEmptyTimeBlocks[i]],
          { abortEarly: false },
        );
      } catch (error) {
        errors = {
          ...errors,
          timeBlocks: {
            ...errors.timeBlocks,
            [nonEmptyTimeBlocks[i]]: {
              ...(errors.timeBlocks &&
                errors.timeBlocks[nonEmptyTimeBlocks[i]]),
              ...mapYupValidationError(error),
            },
          },
        };
      }
    }

    if (
      intervalsAreIntersecting(
        nonEmptyTimeBlocks.reduce((accumulator, timeBlockId) => {
          const timeBlock = values.timeBlocks[timeBlockId];

          if (!timeBlock.start) {
            return accumulator;
          }

          return [
            ...accumulator,
            {
              start: moment(timeBlock.start).format('HH:mm'),
              end: timeBlock.end && moment(timeBlock.end).format('HH:mm'),
            },
          ];
        }, []),
      )
    ) {
      errors = {
        ...errors,
        form: {
          type: 'validation',
          message: translate.t('laErrorTransactionIntervalsAreIntersecting'),
        },
      };
    }

    return { values, errors } as SchemaValidationResult<DailySummaryFormValues>;
  };
};

import { getLoggedUserId, isHRAdmin } from '@/old/utils/helper';
import { CompanyTraining } from '../types';

export default function(training: CompanyTraining) {
  if (isHRAdmin()) {
    return true;
  }

  const userId = getLoggedUserId();
  if (training.responsiblePersons.findIndex(p => p.value === userId) > -1) {
    return true;
  }

  return false;
}

import * as React from 'react';
import { ResponsiveBar, BarExtendedDatum, BarDatum } from '@nivo/bar';
import translate from '@/app/utils/translate';
import BarTooltip from './joinersLeaversChart/BarTooltip';

export const COLORS = {
  JOINERS: 'green',
  LEAVERS: 'red',
  NET: '#05A4D8',
};

const getScale = (min: number, max: number): number => {
  if (max - min > 100000) {
    return 50000;
  } else if (max - min > 10000) {
    return 5000;
  } else if (max - min > 1000) {
    return 500;
  } else if (max - min > 100) {
    return 50;
  }
  return 5;
};

const getMinValue = (min: number, scale: number): number => {
  return min - scale;
};

const getMaxValue = (max: number, scale: number): number => {
  return max + scale;
};

const getColor = (datum: BarDatum) => {
  switch (datum.id) {
    case 'joiners': return COLORS.JOINERS;
    case 'leavers': return COLORS.LEAVERS;
    default: return COLORS.NET;
  }
};

type JoinersLeaversDataItem = {
  id: string;
  label: string;
  joiners: number;
  leavers: number;
  netChange: number;
};

type OwnProps = {
  data: JoinersLeaversDataItem[],
  onBarClick?: (
    datum: BarExtendedDatum,
    event: React.MouseEvent<SVGRectElement, MouseEvent>
  ) => void;
};
type Props = OwnProps;

const JoinersLeaversChart = ({
  data,
  onBarClick,
}: Props) => {
  const min = Math.min(...data.map(item => item.leavers));
  const max = Math.max(...data.map(item => item.joiners));
  const scale = getScale(min, max);

  return (
    <ResponsiveBar
      data={data}
      keys={['joiners', 'netChange', 'leavers']}
      indexBy="label"
      margin={{
        top: 50,
        right: 100,
        bottom: 50,
        left: 100,
      }}
      layout="vertical"
      groupMode="grouped"
      colors={(datum: BarDatum) => getColor(datum)}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      labelTextColor="#ffffff"
      labelSkipHeight={12}
      labelSkipWidth={16}
      legends={[]}
      axisBottom={{
        legend: translate.t('laObservationDates'),
        legendPosition: 'middle',
        legendOffset: 40,
      }}
      axisLeft={{
        legend: translate.t('laNumberOfEmp'),
        legendPosition: 'middle',
        legendOffset: -60,
      }}
      padding={0.4}
      tooltip={(datum: BarExtendedDatum) => <BarTooltip datum={datum} />}
      onClick={onBarClick}
      minValue={getMinValue(min, scale)}
      maxValue={getMaxValue(max, scale)}
      theme={{
        axis: {
          legend: {
            text: {
              letterSpacing: 1.1,
              fontWeight: 'bold',
            },
          },
        },
      }}
    />
  );
};

export default JoinersLeaversChart;

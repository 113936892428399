import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';

import { CustomField as LocalCustomField } from './CustomField';
import { CustomFieldInnerProps, CustomFieldOuterProps } from './customFieldModels';
import { customFieldStyles } from './customFieldStyles';

export const CustomField = compose<CustomFieldInnerProps, CustomFieldOuterProps>(
    withStyles(customFieldStyles),
)(LocalCustomField);

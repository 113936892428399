import * as React from 'react';
import { connect } from 'react-redux';
import { LanguagesType, fetchLanguages } from '@/app/redux/languages';
import { getStoredLanguage, setLanguage, getCompanyDefaultLanguage, setCompanyDefaultLanguage } from '@/app/utils/helper';
import translate from '@/app/utils/translate';
import { ReducerState } from '@/app/redux/store';

const FetchLanguagesHOC = (PassedComponent: React.ComponentType) => {
  class FetchLanguages extends React.Component<any> {
    componentDidMount() {
      translate.init();

      if (!this.props.languages.length && !this.props.fetching) {
        this.props.fetchLanguages();
      }
    }

    componentDidUpdate() {
      if (!this.props.languages.length || !this.props.companyCountry) {
        return;
      }

      const defaultLanguage = this.props.languages.find((language: LanguagesType) => language.isDefault);

      const getUserLanguageFromAvailable = (availableLangs: LanguagesType[], defaultLang: string): string => {
        const availableLangCodes = availableLangs.map((l) => l.code);
        const getShortLangCode = (langCode: string) => langCode.substr(0, 2);

        const langCodeMatch = (
          availableLangCodes.includes(getShortLangCode(navigator.language)) ? navigator.language : (
            navigator.languages && navigator.languages.filter(
              (l) => availableLangCodes.includes(getShortLangCode(l))
            )[0] || defaultLang
          )
        );

        return getShortLangCode(langCodeMatch);
      };

      /* Previous logic: const lang = `${this.props.companyCountry}`.toLowerCase() || defaultLanguage.code */
      const lang = getUserLanguageFromAvailable(this.props.languages, defaultLanguage.code || 'en');

      if (defaultLanguage) {
        if (!getStoredLanguage()) {
          setLanguage(lang);
          // translate needs to reload
          translate.init();
        }

        if (!getCompanyDefaultLanguage()) {
          setCompanyDefaultLanguage(defaultLanguage.code);
        }
      }
    }

    render () {
      if (!this.props.languages.length) {
        return null;
      }

      return <PassedComponent {...this.props} />;
    }
  }

  const mapStateToProps = (state: ReducerState) => ({
    companyCountry: state.companyInfo.get('country'),
    fetching: state.languages.get('isFetching'),
    languages: state.languages.get('allLanguages'),
  });

  return connect(
    mapStateToProps,
    {
      fetchLanguages,
    }
  )(FetchLanguages);
};

export default FetchLanguagesHOC;

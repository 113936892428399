import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import classNames from 'classnames';
import * as React from 'react';

import translate from '@/app/utils/translate';

import { ExtraHoursBalance } from './components/ExtraHoursBalance';
import { WeekSelector } from './components/WeekSelector';
import { DailySummary } from './components/DailySummary';
import { TimeSheetInnerProps } from './timeSheetModels';
import { useTimeSheet } from './useTimeSheet';

export const TimeSheet: React.FC<TimeSheetInnerProps> = props => {
  const {
    currentDate,
    daysOfWeek,
    loadingData,
    loadedData,
    failedToLoadData,
  } = useTimeSheet(props);

  const {
    activeWeek,
    calcHoursBalance,
    canManageBasicData,
    canManageEverything,
    changeWeek,
    classes,
    extraHoursBalance,
    loadedTimeSheet,
    maxCumulativeHours,
    minCumulativeHours,
  } = props;

  const showDailyCumulativeBalance = calcHoursBalance;
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <WeekSelector week={activeWeek} onWeekChange={changeWeek} />

        {calcHoursBalance && loadedTimeSheet && (
          <ExtraHoursBalance
            extraHoursBalance={extraHoursBalance}
            maxCumulativeHours={maxCumulativeHours}
            minCumulativeHours={minCumulativeHours}
          />
        )}
      </div>
      <div className={classes.body}>
        <div
          className={classNames(classes.header, {
            [classes.opaque]: loadingData || failedToLoadData,
          })}
        >
          <Grid container={true} spacing={8}>
            <Grid item={true} xs={showDailyCumulativeBalance ? 1 : 2}>
              <Typography variant="subtitle1">
                {translate.t('laDate')}
              </Typography>
            </Grid>
            <Grid item={true} xs={1}>
              <Typography variant="subtitle1">
                {translate.t('laStarted')}
              </Typography>
            </Grid>
            <Grid item={true} xs={1}>
              <Typography variant="subtitle1">
                {translate.t('laEnd')}
              </Typography>
            </Grid>
            <Grid item={true} xs={1}>
              <Typography variant="subtitle1">
                {translate.t('laWorktime')}
              </Typography>
            </Grid>
            <Grid item={true} xs={1}>
              <Typography variant="subtitle1">
                {translate.t('laNonWork')}
              </Typography>
            </Grid>
            <Grid item={true} xs={1}>
              <Typography variant="subtitle1">
                {translate.t('laExpectedWorktime')}
              </Typography>
            </Grid>
            <Grid item={true} xs={1}>
              <Typography variant="subtitle1">
                {translate.t('laHourBalanceChange')}
              </Typography>
            </Grid>
            {showDailyCumulativeBalance && (
              <Grid item={true} xs={1}>
                <Typography variant="subtitle1">
                  {translate.t('laHourBalanceInTotal')}
                </Typography>
              </Grid>
            )}
            <Grid item={true} xs={3}>
              <Typography variant="subtitle1">
                {translate.t('laComment')}
              </Typography>
            </Grid>
          </Grid>
        </div>

        <div
          className={classNames({
            [classes.opaque]: loadingData || failedToLoadData,
          })}
        >
          {daysOfWeek.map(date => (
            <DailySummary
              key={date}
              currentDate={currentDate}
              date={date}
              placeholder={!loadedData}
              canManageBasicData={canManageBasicData}
              canManageEverything={canManageEverything}
              showDailyCumulativeBalance={showDailyCumulativeBalance}
            />
          ))}
        </div>

        {loadingData && (
          <div className={classes.overlay}>
            <CircularProgress />
          </div>
        )}

        {!loadingData && failedToLoadData && (
          <div className={classNames(classes.overlay, classes.error)}>
            <ErrorOutline /> {translate.t('laFailedToLoadWorkTimeData')}
          </div>
        )}
      </div>
    </div>
  );
};

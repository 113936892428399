import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  WithStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styles from '@/app/components/Instructions/InstructionsStyle';
import Service from '@/app/utils/service';
import { getCurrentLanguage } from '@/app/utils/helper';
import translate from '@/app/utils/translate';
import { Close } from '@material-ui/icons';
import { sanitize } from 'dompurify';

type OwnProps = {
  type: string;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const Instructions = ({ classes, type }: Props) => {
  const [helperText, setHelperText] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    Service.get(
      `d/json/string/${type}/${getCurrentLanguage()}`,
      (data: any) => {
        setHelperText(data);
      },
      (error: any) => {
        console.error(error);
      },
    );
  }, []);

  if (helperText !== '') {
    return (
      <>
        <i onClick={() => setOpen(true)} className="fa fa-question-circle-o" />
        <Dialog
          className={classes.dialog}
          maxWidth="xl"
          hideBackdrop
          open={open}
        >
          <DialogTitle className={classes.titleDiv}>
            <div className={classes.title}>
              <span className={classes.titleText}>
                {translate.t('laDevTrainingInstr')}
              </span>
              <IconButton
                className={classes.closeButton}
                onClick={() => setOpen(false)}
              >
                <Close fontSize="small" />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <span dangerouslySetInnerHTML={{ __html: sanitize(helperText) }} />
          </DialogContent>
        </Dialog>
      </>
    );
  }
  return <></>;
};

export default withStyles(styles)(Instructions);

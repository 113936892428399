const normalizePhone = (value: string) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.match(/[0-9\+]/g);
  if (!onlyNums) {
    return '';
  }
  return onlyNums.join('').substr(0, 15);
};

const normalizeNumber = (value: string) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.match(/[0-9.,]/g);
  if (!onlyNums) {
    return '';
  }
  return onlyNums.join('').substr(0, 15);
};

const normalizeInteger = (value: string) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.match(/[0-9]/g);
  if (!onlyNums) {
    return '';
  }
  return onlyNums.join('').substr(0, 6);
};

export {
  normalizePhone,
  normalizeNumber,
  normalizeInteger
};

import { StyleRulesCallback } from '@material-ui/core';

export type CustomFieldStyleKeys =
    | 'textInput'
    | 'textarea'
    | 'clearableInputContainer'
    | 'clearButton'
    | 'fullWidth';

export const customFieldStyles: StyleRulesCallback<CustomFieldStyleKeys> = (theme) => ({
    textInput: {
        margin: 0,

        '& input': {
            padding: theme.spacing.unit,
        },
    },
    textarea: {
        overflow: 'auto',
        resize: 'vertical',
        minHeight: 32,
        maxHeight: 100,
    },
    clearableInputContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    clearButton: {
        marginLeft: theme.spacing.unit,

        '& svg': {
            fontSize: 20,
        },
    },
    fullWidth: {
        width: '100%',
    },
});

export type ChainGroup = {
  group: string;
  enum: string;
};

export type ScoreElement = {
  score: number;
  chain: ChainGroup[];
};

export type HeaderElement = {
  group: string;
  seq: number;
};

export type ScoreGroup = {
  id: string;
  name: string;
  headers: HeaderElement[];
  scores: ScoreElement[];
};

export type GroupEnumComponent = {
  name: string;
  code: string;
  isActive: boolean;
};

export type GroupEnums = {
  [key: string]: GroupEnumComponent[];
};

export type GroupValues = {
  [key: string]: string;
};

export type ComponentItem = {
  enumScoreGroupId?: string;
  id: string;
  index: number;
  language: string;
  name: string;
  type: string;
  enumScoreGroup?: ScoreGroup;
  defaultValue?: string;
};

export type JobReqEvalSection = {
  components: ComponentItem[];
  empAccess: boolean;
  id: string;
  index: number;
  language: string;
  name: string;
  onlyEmpAccess: boolean;
  templateId: string;
};

export type JobReqEvaluationResponse = {
  componentId: string;
  response: ScoreElement;
  type: string;
};

export type JobReqEvaluation = {
  tmpID?: string;
  responses: JobReqEvaluationResponse[];
};

export type EvaluationStage = {
  id: number;
  label: string;
  index: number;
  default: boolean;
  isDeleted: boolean;
};

export type UpdatedBy = {
  id: number;
  username: string;
  employeeId: number;
};

export type EvaluationTemplate = {
  id: string;
  subject: string;
  isTeam: boolean;
  stages: EvaluationStage[];
  status: string;
  deadline: string;
  language: string;
  defaultLang: string;
  sections: JobReqEvalSection[];
  updatedBy: UpdatedBy;
  updatedTime: string;
  createdTime: string;
};

export type ActiveTemplate = {
  id: string;
  currentEvaluationId: string;
  subject: string;
  deadline: string;
  sections: JobReqEvalSection[];
  stages: EvaluationStage[];
  isTeam: boolean;
  updatedBy: UpdatedBy;
  setStage: EvaluationStage;
  lastEdited: string;
  lastModifiedBy: string;
  completionStatus: string;
  approvedBy: string;
  archived: boolean;
  responses: JobReqEvaluationResponse[];
};

export type ArchivedEvaluation = {
  id: string;
  templateId: string;
  templateSubject: string;
  employeeId: string;
  createdTime: string;
  updatedBy: string;
  updatedTime: string;
};

export type TemplatesOrder = {
  subject: 'asc' | 'desc';
  status: 'asc' | 'desc';
  updatedTime: 'asc' | 'desc';
};

export enum EvaluationStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export type TemplateFieldSelection = {
  [sectionId: string]: { selectedFields: string[] };
};

export interface ITemplate {
  id: number;
  subject: string;
  status: string;
  deadline: Date;
  stages?: EvaluationStage[];
}

export interface ITemplateFull extends ITemplate {
  audience: {
    countryAudience: string[];
    roleAudience: string[];
    unitAudience: number[];
  };
  createdTime: string;
  defaultLang: string;
  language: string;
  sections: JobReqEvalSection[];
  updatedBy: {
    id: number;
    username: string;
    employeeId: number;
  };
  updatedTime: string;
}

type EntityDetails = {
  id: number;
  name?: string;
  code?: string;
  number?: string;
};

export type SummaryEmpData = {
  empId: number;
  firstName: string;
  lastName: string;
  empNumber: string;
  employmentId: number;
  startDate: number;
  orgUnitDetails: EntityDetails;
  managerId: number;
  managerEmpNumber?: string;
  managerFirstName?: string;
  managerLastName?: string;
  userDetails: { roles?: string[]; hasAccount: boolean };
};

export type GenericEvaluationItem = {
  id: string;
  templateId: string;
  employeeId: number;
  approvedBy: number[];
  status?: EvaluationStatus;
  isArchived?: boolean;
  archived?: boolean;
  updatedBy: number;
  updatedTime: string;
};

export type GenericEmployeeEvaluation = {
  employeeDetails: SummaryEmpData;
  evaluations: GenericEvaluationItem[];
};

export type EvaluationStatusDistribution = {
  inProgress: number[],
  completed: number[],
  notStarted: number[],
};

export interface IUnitGridData extends EvaluationStatusDistribution {
  unitId?: number;
  unitName?: string;
}

export interface IUnitGrid extends Omit<IUnitGridData, 'completed' | 'inProgress' | 'notStarted'> {
  completed?: number;
  inProgress?: number;
  notStarted?: number;
  subjectButton?: any;
}

export enum TemplateStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

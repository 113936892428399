import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { openNotifier } from '@/app/redux/notifier';
import { getErrorMessage, sendErrorlog } from '@/app/utils/error';
import { ReducerState } from '@/app/redux/store';
import { emptyError } from '@/app/redux/error';

const ErrorHandlerHOC = (PassedComponent: React.ComponentType) => {
  type PropsType = {
    error: any,
    openNotifier: (payload: any) => void,
    emptyError: () => void,
  };
  class WithErrorHandler extends React.Component<PropsType> {

    componentDidCatch(error: any) {
      sendErrorlog(error);
    }

    componentDidUpdate () {
      const { error } = this.props;
      if (error) {
        this.props.openNotifier({
          message: getErrorMessage(error.data, error.status),
          type: 'error'
        });
        this.props.emptyError();
        sendErrorlog(error);
      }
    }

    render () {
      return (
        <>
          <PassedComponent {...this.props} />
        </>
      );
    }
  }

  const mapStateToProps = (state: ReducerState) => ({
    error: state.error.get('error'),
  });

  const mapDispatchToProps = (dispatch: Dispatch) => ({
    openNotifier: (payload: any) => dispatch<any>(openNotifier(payload)),
    emptyError: () => dispatch<any>(emptyError())
  });

  return connect(mapStateToProps, mapDispatchToProps)(WithErrorHandler);
};

export default ErrorHandlerHOC;

import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const translateQuickLinkDialogStyle: StyleRulesCallback = (theme: Theme) => {
  return ({
    paperWidthMd: {
      width: 520,
    },
    grid: {
      marginTop: theme.spacing.unit,
    }
  });
};

export default translateQuickLinkDialogStyle;

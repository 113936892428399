import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Notifier, { NotifierTYpe } from '@/app/components/Notifier/Notifier';
import { closeNotifier } from '@/app/redux/notifier';

interface Props {
  type: NotifierTYpe;
  open: boolean;
  message: string;
  handleClose: any;
}

const NotifierHOC = (PassedComponent: React.ComponentType) => {
  const WithNotifier: React.FunctionComponent<Props> = ({type, open, message, handleClose, ...props}) => {
    return (
      <>
        <Notifier
          type={type}
          open={open}
          message={message}
          onClose={handleClose}
        />
        <PassedComponent {...props} />
      </>
    );
  };

  const mapStateToProps = (state: any) => ({
    type: state.notifier.get('type'),
    open: state.notifier.get('open'),
    message: state.notifier.get('message'),
  });

  const mapDispatchToProps = (dispatch: Dispatch) => ({
    handleClose: () => dispatch<any>(closeNotifier())
  });

  return connect(mapStateToProps, mapDispatchToProps)(WithNotifier);
};

export default NotifierHOC;

import * as React from 'react';
import Select from 'react-select';
import { compose } from 'recompose';
import * as moment from 'moment';
import { v4 as uuid4 } from 'uuid';
import { withStyles } from '@material-ui/core/styles';
import {
  WithStyles,
  Paper,
  TextField,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormGroup,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

import Control from '@/app/components/Formik2/components/formikSelectField/Control';
import LocalizedDatePicker from '@/app/components/Pickers/LocalizedDatePicker';
import { OptionType } from '@/app/components/Trainings/types';
import { Enum } from '@/app/redux/enums';
import { LanguagesType } from '@/app/redux/languages';
import { sortArr } from '@/app/utils/helper';
import Service from '@/app/utils/service';
import translate from '@/app/utils/translate';

import AddStage from './AddStages/AddStage';
import TemplateTags from './TemplateTags/TemplateTags';
import {
  getCompanyCountries,
  getCountryName,
  getRoleName,
  getRoles,
  getUnitName,
  getUnits,
  mapTemplateTags,
} from './helper';
import addNewTemplateStyle from './templateHeaderStyle';
import { UnitOrgTreeItem, findSubUnits } from '@/old/utils/helper';

type IncomingProps = {
  languages: LanguagesType[];
  handleModalClose: any;
  templateBeingEdited?: any;
  addTemplate?: any;
  updateTemplate?: any;
  editStyle?: boolean;
  showTags?: boolean;
  teamTemplate?: boolean;
  templateTags?: Enum[];
  accessFilter?: boolean;
};

type AudienceType = {
  unitAudience: OptionType[];
  roleAudience: OptionType[];
  countryAudience: OptionType[];
};

type IncomingAudience = {
  unitAudience: number[];
  roleAudience: string[];
  countryAudience: string[];
};

type PropsType = IncomingProps & WithStyles<typeof addNewTemplateStyle>;
type StateType = {
  subject: string;
  status: string;
  stages: [];
  stage: string;
  deadline: Date;
  language: string;
  showValidationError: boolean;
  disabledSave: boolean;
  stagesModal: boolean;
  audience: AudienceType;
  orgTree: UnitOrgTreeItem[];
  isTeam: boolean;
  includeSubUnitsCheck: boolean;
  tags: OptionType[];
  hrAccess: boolean;
  mgrAccess: boolean;
};

const mapAudience = (audience: IncomingAudience) => ({
  unitAudience:
    !audience || !audience.unitAudience || audience.unitAudience.length === 0
      ? null
      : audience.unitAudience.map((unitId: number) => ({
          value: unitId,
          label: getUnitName(unitId),
        })),
  roleAudience:
    !audience || !audience.roleAudience || audience.roleAudience.length === 0
      ? null
      : audience.roleAudience.map((role: string) => ({
          value: role,
          label: getRoleName(role),
        })),
  countryAudience:
    !audience ||
    !audience.countryAudience ||
    audience.countryAudience.length === 0
      ? null
      : audience.countryAudience.map((countryCode: string) => ({
          value: countryCode,
          label: getCountryName(countryCode),
        })),
});

class TemplateHeader extends React.PureComponent<PropsType> {
  state: StateType = {
    subject: '',
    status: 'INACTIVE',
    stage: '',
    stages: [],
    deadline: new Date(),
    language: 'en',
    showValidationError: false,
    disabledSave: true,
    stagesModal: false,
    audience: {
      countryAudience: [],
      roleAudience: [],
      unitAudience: [],
    },
    isTeam: false,
    orgTree: [],
    includeSubUnitsCheck: false,
    tags: [],
    hrAccess: false,
    mgrAccess: false,
  };

  constructor(props: any) {
    super(props);

    if (props.templateBeingEdited !== undefined) {
      const templateStages = props.templateBeingEdited.stages || [];
      const defaultTemplateStage = templateStages.find(
        (s: any) => s.default && !s.isDeleted,
      );

      this.state = {
        subject: props.templateBeingEdited.subject || '',
        status: props.templateBeingEdited.status || 'ACTIVE',
        stages: templateStages || [],
        stage: defaultTemplateStage
          ? defaultTemplateStage.label
          : templateStages[0]
          ? templateStages[0].label
          : '',
        deadline: props.templateBeingEdited.deadline || new Date(),
        language: props.templateBeingEdited.language || 'en',
        showValidationError: false,
        disabledSave: true,
        stagesModal: false,
        audience: mapAudience(props.templateBeingEdited.audience),
        isTeam: props.templateBeingEdited.isTeam || false,
        orgTree: [],
        includeSubUnitsCheck: false,
        tags: props.templateBeingEdited.tags
          ? mapTemplateTags(props.templateBeingEdited.tags, props.templateTags)
          : [],
        hrAccess: props.templateBeingEdited.defaultHrAccess,
        mgrAccess: props.templateBeingEdited.defaultMgrAccess,
      };
    }
  }

  changeSubject = (event: any) => {
    const target = event.target as HTMLTextAreaElement;
    this.setState({
      subject: target.value,
      ...(target.value !== ''
        ? {
            showValidationError: false,
            disabledSave: false,
          }
        : {}),
    });
  };

  changeStatus = (event: any) => {
    const target = event.target as HTMLSelectElement;
    this.setState({ status: target.value, disabledSave: false });
  };

  changeHRAccess = (event: any) => {
    const target = event.target;
    this.setState({ hrAccess: target.checked, disabledSave: false });
  };

  changeMGRAccess = (event: any) => {
    const target = event.target;
    this.setState({ mgrAccess: target.checked, disabledSave: false });
  };

  changeStage = (event: any) => {
    const target = event.target as HTMLSelectElement;

    this.setState({
      stage: target.value,
      stages: this.state.stages.map(
        (s: { label: string; index: number; default: boolean }) => {
          return { ...s, default: s.label === target.value };
        },
      ),
      disabledSave: false,
    });
  };

  changeStageList = (stageList: any) => {
    this.setState({
      stages: stageList,
      stage: stageList[0].label,
      disabledSave: false,
    });
  };

  handleDateRangeChange = (date: moment.Moment) => {
    this.setState({ deadline: date, disabledSave: false });
  };

  changeLanguage = (event: any) => {
    const target = event.target as HTMLTextAreaElement;
    this.setState({ language: target.value, disabledSave: false });
  };

  changeIsTeam = (_: any, checked: boolean) => {
    this.setState({ isTeam: checked, disabledSave: false });
  };

  handleSaveTemplate = (e: any) => {
    e.preventDefault();

    const {
      addTemplate,
      updateTemplate,
      templateBeingEdited,
      templateTags,
      showTags,
      accessFilter,
    } = this.props;
    const {
      subject,
      status,
      stages,
      language,
      audience,
      isTeam,
      tags,
      mgrAccess,
      hrAccess,
    } = this.state;

    if (subject === '') {
      this.setState({ showValidationError: true });
      return;
    }

    const deadline = moment(this.state.deadline).format('YYYY-MM-DD');
    const mappedAudience = Object.fromEntries(
      Object.entries(audience).map(([key, value]) => [
        key,
        value ? value.map((audiences: OptionType) => audiences.value) : [],
      ]),
    );

    const mappedTags =
      showTags && templateTags && templateTags[0]
        ? {
            tags: tags.map((tag) => ({
              tagGroup: templateTags[0].groupName,
              tag: tag.value,
            })),
          }
        : {};

    if (templateBeingEdited) {
      updateTemplate(templateBeingEdited.id, {
        subject,
        status,
        stages,
        deadline,
        audience: mappedAudience,
        ...mappedTags,
        isTeam,
        ...(accessFilter && {
          defaultMgrAccess: mgrAccess,
          defaultHrAccess: hrAccess,
        }),
      });
    } else {
      addTemplate({
        id: uuid4(),
        subject,
        status,
        stages,
        deadline,
        language,
        audience: mappedAudience,
        ...mappedTags,
        isTeam,
        ...(accessFilter && {
          defaultMgrAccess: mgrAccess,
          defaultHrAccess: hrAccess,
        }),
      });
    }

    this.props.handleModalClose();
  };

  openStagesModal = () => {
    this.setState({ stagesModal: true });
  };
  closeStagesModal = () => {
    this.setState({ stagesModal: false });
  };

  changeUnitAudience = (e: any) => {
    this.setState({
      audience: {
        ...this.state.audience,
        unitAudience: e,
      },
      disabledSave: false,
    });
  };

  changeRoleAudience = (e: any) => {
    this.setState({
      audience: {
        ...this.state.audience,
        roleAudience: e,
      },
      disabledSave: false,
    });
  };
  changeCountryAudience = (e: any) => {
    this.setState({
      audience: {
        ...this.state.audience,
        countryAudience: e,
      },
      disabledSave: false,
    });
  };
  changeTags = (e: any) => {
    this.setState({ tags: e, disabledSave: false });
  };
  handleIncludeSubUnitsToggle = () => {
    this.setState({ includeSubUnitsCheck: !this.state.includeSubUnitsCheck });
  };

  componentDidMount() {
    Service.get(
      '/d/json/org/tree/1/unit',
      (response: any) => {
        const orgTreeData = response.map((respItem: any) => ({
          id: parseInt(respItem.fTreeId, 10),
          parentId:
            respItem.fTreeParentUnitId === '#'
              ? '#'
              : parseInt(respItem.fTreeParentUnitId, 10),
          unitId: parseInt(respItem.fTreeUnitId, 10),
          name: respItem.fTreeUnitName,
          unitType: respItem.fTreeUnitType,
        }));
        this.setState({ orgTree: orgTreeData });
      },
      (_e: any) => {
        console.error(translate.t('laFailedToLoad'));
        // TODO: Change to snackbar if possible
      },
    );
  }

  render() {
    const {
      classes,
      editStyle,
      languages,
      handleModalClose,
      templateBeingEdited,
      templateTags,
      showTags,
      teamTemplate,
      accessFilter,
    } = this.props;
    const {
      subject,
      status,
      deadline,
      language,
      stage,
      stages,
      showValidationError,
      disabledSave,
      stagesModal,
      audience,
      orgTree,
      includeSubUnitsCheck,
      tags,
      isTeam,
      mgrAccess,
      hrAccess,
    } = this.state;

    const unitAudienceOptions = () =>
      getUnits().map((u: any) => ({
        value: u.id,
        label: u.name,
      })) as OptionType[];

    const getMatchingSubUnits = (
      unitAudience?: OptionType[],
      includeSubUnits = includeSubUnitsCheck,
    ) => {
      if (includeSubUnits || !unitAudience) {
        return findSubUnits(
          unitAudience,
          unitAudienceOptions(),
          orgTree,
          includeSubUnits,
        );
      }

      return unitAudience.map((unit) => ({ ...unit, isFixed: false }));
    };

    const getCanClearUnitAudience = (selectedUnitAudience?: OptionType[]) => {
      return (
        (selectedUnitAudience &&
          selectedUnitAudience.some((unit) => !unit.isFixed)) ||
        false
      );
    };

    return (
      <>
        <Paper className={editStyle ? classes.editStyle : classes.root}>
          <form onSubmit={this.handleSaveTemplate}>
            <h3 className={classes.topTitle}>
              {editStyle
                ? translate.t('laEditTemplate')
                : translate.t('laAddNewTemplate')}

              <span className={classes.topTitleRight}>
                <Button
                  color="primary"
                  variant="text"
                  onClick={handleModalClose}
                >
                  {editStyle ? translate.t('laDone') : translate.t('laCancel')}
                </Button>

                {templateBeingEdited !== undefined && stages.length > 0 && (
                  <Button
                    color="primary"
                    variant="text"
                    onClick={this.openStagesModal}
                  >
                    {translate.t('edit_stage_label')}
                  </Button>
                )}

                {templateBeingEdited === undefined && (
                  <Button color="primary" onClick={this.openStagesModal}>
                    {stages.length < 1
                      ? translate.t('add_stage_label')
                      : translate.t('edit_stage_label')}
                  </Button>
                )}

                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={disabledSave}
                >
                  {translate.t('button_save_close')}
                </Button>
              </span>
            </h3>

            <div className={classes.flexWrapper}>
              <TextField
                name="subject"
                value={subject}
                onChange={this.changeSubject}
                label={translate.t('laSubject')}
                className={classes.spacing}
                error={showValidationError}
                helperText={
                  showValidationError ? translate.t('laThisRequired') : ' '
                }
              />

              <TextField
                select
                name="status"
                value={status}
                onChange={this.changeStatus}
                label={translate.t('laStatus')}
                className={`${classes.spacing} ${classes.left}`}
              >
                <MenuItem key={'ACTIVE'} value={'ACTIVE'}>
                  {translate.t('laActive')}
                </MenuItem>

                <MenuItem key={'INACTIVE'} value={'INACTIVE'}>
                  {translate.t('label_inactive')}
                </MenuItem>
              </TextField>

              {stages && stages.length > 0 && (
                <TextField
                  select
                  name="stage"
                  value={stage}
                  onChange={this.changeStage}
                  label={translate.t('laStage')}
                  className={`${classes.spacing} ${classes.left}`}
                >
                  {sortArr(
                    stages.filter(({ isDeleted }: any) => !isDeleted),
                    'index',
                  ).map((s: { label: string }) => (
                    <MenuItem key={s.label} value={s.label}>
                      {s.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              <LocalizedDatePicker
                value={deadline}
                onChange={this.handleDateRangeChange}
                minDate={moment().add(-1, 'day')}
                label={translate.t('laDeadline')}
                className={classes.datePicker}
                helperText={
                  templateBeingEdited &&
                  moment(deadline).endOf('day').isBefore(new Date())
                    ? translate.t('laDeadlineReached')
                    : ''
                }
                autoOk={true}
              />
              <TextField
                select
                name="language"
                value={language}
                onChange={this.changeLanguage}
                disabled={!!templateBeingEdited}
                label={translate.t('laLanguage')}
                className={`${classes.spacing} ${classes.left}`}
              >
                {languages.map((option: LanguagesType) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              {teamTemplate && (
                <FormControlLabel
                  classes={{
                    root: classes.checkboxControlLabel,
                    label: classes.checkboxLabel,
                  }}
                  control={
                    <Checkbox
                      checked={isTeam}
                      onChange={this.changeIsTeam}
                      disabled={!!templateBeingEdited}
                    />
                  }
                  label={translate.t('laTeamTemplate')}
                />
              )}
            </div>
            <div>
              <Grid container={true} spacing={8}>
                <Grid item xs={10}>
                  <Select
                    classes={classes}
                    textFieldProps={{
                      label: translate.t('label_audience_for_units'),
                      InputLabelProps: {
                        shrink: true,
                      },
                    }}
                    options={unitAudienceOptions()}
                    components={{ Control }}
                    value={audience.unitAudience}
                    onChange={this.changeUnitAudience}
                    placeholder={translate.t('laUnits')}
                    isMulti
                    isClearable={getCanClearUnitAudience(audience.unitAudience)}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="includeSubUnits"
                        checked={includeSubUnitsCheck}
                        onChange={() => {
                          this.changeUnitAudience(
                            getMatchingSubUnits(
                              audience.unitAudience,
                              !includeSubUnitsCheck,
                            ),
                          );

                          this.handleIncludeSubUnitsToggle();
                        }}
                        color="primary"
                      />
                    }
                    label={translate.t('label_include_subunits')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Select
                    classes={classes}
                    textFieldProps={{
                      label: translate.t('label_audience_for_roles'),
                      InputLabelProps: {
                        shrink: true,
                      },
                    }}
                    options={getRoles().map((r: any) => ({
                      value: r.id,
                      label: r.name,
                    }))}
                    components={{ Control }}
                    value={audience.roleAudience}
                    onChange={this.changeRoleAudience}
                    placeholder={translate.t('table_col_roles')}
                    isMulti
                  />
                </Grid>
                <Grid item xs={12}>
                  <Select
                    classes={classes}
                    textFieldProps={{
                      label: translate.t('label_audience_for_countries'),
                      InputLabelProps: {
                        shrink: true,
                      },
                    }}
                    options={getCompanyCountries().map((c: any) => ({
                      value: c.code,
                      label: c.name,
                    }))}
                    components={{ Control }}
                    value={audience.countryAudience}
                    onChange={this.changeCountryAudience}
                    placeholder={translate.t('laCountries')}
                    isMulti
                  />
                </Grid>
                {accessFilter && (
                  <Grid item xs={12} container>
                    <FormControl>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={mgrAccess}
                              onChange={(e) => this.changeMGRAccess(e)}
                              color="primary"
                            />
                          }
                          label={translate.t('laManager')}
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={hrAccess}
                              onChange={(e) => this.changeHRAccess(e)}
                              color="primary"
                            />
                          }
                          label={translate.t('laHR')}
                        />
                      </FormGroup>
                    </FormControl>
                  </Grid>
                )}

                {showTags ? (
                  <Grid item xs={12}>
                    <TemplateTags
                      value={tags}
                      tagOptions={templateTags}
                      onChange={this.changeTags}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </div>
          </form>
        </Paper>

        <AddStage
          editedTemplate={templateBeingEdited}
          openedStageModal={stagesModal}
          closeStagesModal={this.closeStagesModal}
          changeStageList={this.changeStageList}
        />
      </>
    );
  }
}

const enhance = compose<PropsType, IncomingProps>(
  withStyles(addNewTemplateStyle),
);

export default enhance(TemplateHeader);

import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

export const styles: StyleRulesCallback = (theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end'
  },
  rightButton: {
    marginLeft: theme.spacing.unit,
  },
  inputFileUpload: {
    display: 'none'
  },
  deleteButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  noFileSpan: {
    marginLeft: theme.spacing.unit
  }
});

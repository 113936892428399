export enum ComponentType {
  TEXT = 'TEXT',
  EVALUATIONS = 'EVALUATIONS',
  COMPETENCY_LEVEL = 'COMPETENCY_LEVEL',
  CHECKBOXES = 'CHECKBOXES',
  DATE = 'DATE',
  LINK = 'LINK',
  SCALE = 'SCALE',
  SUBTITLE = 'SUBTITLE',
  FILE = 'FILE',
  DROPDOWN = 'DROPDOWN',
  MULTISELECT_DROPDOWN = 'MULTISELECT_DROPDOWN',
}

export const componentTypeTranslationKeys = {
  [ComponentType.TEXT]: 'label_TEXT',
  [ComponentType.EVALUATIONS]: 'laEvaluations',
  [ComponentType.COMPETENCY_LEVEL]: 'laSkillLevel',
  [ComponentType.CHECKBOXES]: 'label_MULTIPLE_CHOICE',
  [ComponentType.DATE]: 'label_DATE',
  [ComponentType.LINK]: 'label_LINK',
  [ComponentType.SCALE]: 'label_SCALE',
  [ComponentType.SUBTITLE]: 'label_SUBTITLE',
  [ComponentType.FILE]: 'label_FILE',
  [ComponentType.DROPDOWN]: 'label_DROPDOWN',
  [ComponentType.MULTISELECT_DROPDOWN]: 'label_MULTISELECT_DROPDOWN',
};

export enum TemplateType {
  Process = 'process',
  Appraisal = 'appraisal',
}

export const componentTypeExclusionsPerTemplateType: {
  [p: string]: ComponentType[];
} = {
  [TemplateType.Process]: [ComponentType.FILE, ComponentType.COMPETENCY_LEVEL],
};

import * as React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip, Icon, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { WithStyles } from '@material-ui/core';
import sectionTopToolbarStyle from './sectionTopToolbarStyle';
import Modal from '@material-ui/core/Modal';
import translate from '@/app/utils/translate';
import AddComponentForm from '../AddComponentForm/AddComponentForm';
import AddComponentPicker from '../AddComponentPicker/AddComponentPicker';

type PropsType = WithStyles<typeof sectionTopToolbarStyle>;

type ToolbarTypes = {
  partialShow: boolean;
  fullMenu: boolean;
  addComponentToSection: (
    templateId: string,
    sectionId: string,
    object: Object,
  ) => void;
  updateSectionComponent: (
    templateId: string,
    sectionId: string,
    object: Object,
  ) => void;
  readyToEditTemplate: any;
  section: any;
  handleEditComponentModal: (
    templateId: string,
    sectionId: string,
    selectedComponentIds: any[],
    component: any,
    sideMenuControl: boolean,
  ) => void;
  componentType: string;
  componentEditMode: boolean;
  deleteComponent: (
    templateId: string,
    sectionId: string,
    ids: string[],
  ) => void;
  sortComponents: () => void;
  selectedComponentIds: [];
  endEditMode: () => void;
  openAddComponentModal: boolean;
  handleAddComponent: () => void;
  handleModalClose: () => void;
};

class SectionTopToolbar extends React.PureComponent<PropsType & ToolbarTypes> {
  handleManageComponentDialogClose = () => {
    this.props.handleModalClose();

    if (this.props.componentEditMode) {
      setTimeout(() => {
        this.props.endEditMode();
      }, 750);
    }
  };

  handleComponentAdd = (c: any, readyToEditTemplate: any, section: any) => {
    this.props.addComponentToSection(readyToEditTemplate[0].id, section.id, {
      ...c,
      index: section.components ? section.components.length + 1 : 1,
    });
    this.handleManageComponentDialogClose();
  };

  handleUpdateComponent = (c: any, readyToEditTemplate: any, section: any) => {
    this.props.updateSectionComponent(readyToEditTemplate[0].id, section.id, c);
    this.handleManageComponentDialogClose();
  };

  render() {
    const {
      classes,
      partialShow,
      fullMenu,
      selectedComponentIds,
      readyToEditTemplate,
      section,
      componentType,
      componentEditMode,
      handleEditComponentModal,
      openAddComponentModal,
      deleteComponent,
      sortComponents,
    } = this.props;

    const [editedSection] = readyToEditTemplate[0].sections.filter(
      (sec: any) => sec.id === section.id,
    );
    const [editedComponentId] = selectedComponentIds.slice(0, 1);
    const [component] = editedComponentId
      ? editedSection.components.filter(
          (comp: any) => comp.id === editedComponentId,
        )
      : [{}];

    return (
      <>
        <span className={classes.root}>
          {partialShow && (
            <>
              <IconButton onClick={sortComponents}>
                <Tooltip title={translate.t('laSort')}>
                  <Icon
                    color={
                      (editedSection.components || {}).length < 2
                        ? 'disabled'
                        : 'primary'
                    }
                  >
                    sort
                  </Icon>
                </Tooltip>
              </IconButton>

              <AddComponentPicker
                onComponentAdd={this.props.handleAddComponent}
              />

              {fullMenu ? (
                <>
                  <IconButton
                    onClick={() =>
                      handleEditComponentModal(
                        readyToEditTemplate[0].id,
                        section.id,
                        selectedComponentIds,
                        component,
                        false,
                      )
                    }
                  >
                    <Tooltip title={translate.t('laEdit')}>
                      <EditIcon
                        color={
                          selectedComponentIds.length < 2
                            ? 'primary'
                            : 'disabled'
                        }
                      />
                    </Tooltip>
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      deleteComponent(
                        readyToEditTemplate[0].id,
                        section.id,
                        selectedComponentIds,
                      )
                    }
                  >
                    <Tooltip title={translate.t('laDelete')}>
                      <DeleteIcon color="primary" />
                    </Tooltip>
                  </IconButton>
                </>
              ) : (
                <>
                  <IconButton disabled>
                    <Tooltip title={translate.t('laEdit')}>
                      <EditIcon color="disabled" />
                    </Tooltip>
                  </IconButton>
                  <IconButton disabled>
                    <Tooltip title={translate.t('laDelete')}>
                      <DeleteIcon color="disabled" />
                    </Tooltip>
                  </IconButton>
                </>
              )}
            </>
          )}
        </span>
        <div>
          <Modal
            open={openAddComponentModal}
            onClose={this.handleManageComponentDialogClose}
          >
            <AddComponentForm
              component={(componentEditMode && component) || undefined}
              componentType={componentType}
              section={section}
              onCancel={this.handleManageComponentDialogClose}
              onComponentAdd={(c: any) =>
                this.handleComponentAdd(c, readyToEditTemplate, section)
              }
              onComponentUpdate={(c: any) =>
                this.handleUpdateComponent(c, readyToEditTemplate, section)
              }
            />
          </Modal>
        </div>
      </>
    );
  }
}

const enhance = compose<any, any>(withStyles(sectionTopToolbarStyle));

export default enhance(SectionTopToolbar);

import { process, State } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {
  GridDataStateChangeEvent,
  GridRowClickEvent,
} from '@progress/kendo-react-grid';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  SyntheticEvent,
} from 'react';

import { getSelectedEmpId } from '@/old/utils/helper';

import {
  RawCollaborationTask,
  RawTaskComment,
} from '../../collaborationModels';

import { getTasksListColumns } from './tasksListColumn';
import { filterCollaborationTasks } from './tasksListFilters';
import { mapRawCollaborationTasks } from './tasksListMappers';
import { ReportType } from './tasksListModels';

const defaultGridDataState: State = {
  skip: 0,
  take: 8,
  // It does not work correctly now
  // Uncomment this when filtering will be updated
  // filter: {
  //     logic: 'and',
  //     filters: [
  //         {
  //             field: 'status',
  //             operator: 'neq',
  //             value: 'Completed',
  //             ignoreCase: true,
  //         }
  //     ],
  // },
  sort: [
    {
      field: 'dueDate',
      dir: 'asc',
    },
  ],
};

export const useTasksList = (
  tasks: { [key: number]: RawCollaborationTask },
  fetchMyTasks: (empId: number) => void,
  openTaskDialog: (task: RawCollaborationTask) => void,
  syncTasks: (tasks: RawCollaborationTask[]) => void,
  syncDeletedTasks: (taskIds: number[]) => void,
  syncCreatedTaskComment: (comment: RawTaskComment) => void,
  syncDeletedTaskComment: (comment: RawTaskComment) => void,
) => {
  const [reportType, setReportType] = useState<ReportType>(
    ReportType.BY_ASSIGNEE,
  );
  const [gridDataState, setGridDataState] = useState(defaultGridDataState);
  const [showCommentsFor, setShowCommentsFor] = useState<number>();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const excelExportRef = useRef<ExcelExport>();

  const handleOpenCommentsDialog = useCallback(
    (taskId: number) => {
      setShowCommentsFor(taskId);
    },
    [setShowCommentsFor],
  );

  const handleCloseCommentsDialog = useCallback(() => {
    setShowCommentsFor(undefined);
  }, [setShowCommentsFor]);

  const [columns, setColumns] = useState(
    getTasksListColumns(handleOpenCommentsDialog),
  );

  const handleOpenQuickFilterMenu = useCallback(
    (e: SyntheticEvent) => {
      setMenuAnchorEl(e.target);
    },
    [setMenuAnchorEl],
  );

  const handleCloseQuickFilterMenu = useCallback(() => {
    setMenuAnchorEl(null);
  }, [setMenuAnchorEl]);

  const handleChangeReportTypeSettings = useCallback(
    (e: SyntheticEvent) => {
      setReportType(e.currentTarget.attributes['data-report-type'].value);
      setGridDataState((state) => ({ ...state, skip: 0 }));
      handleCloseQuickFilterMenu();
    },
    [setReportType, setGridDataState, handleCloseQuickFilterMenu],
  );

  const handleColumnsSubmit = useCallback(
    (nextColumns: typeof columns) => {
      setColumns(nextColumns);
    },
    [setColumns],
  );

  const handleGridDataStateChange = useCallback(
    (event: GridDataStateChangeEvent) => {
      setGridDataState(event.data);
    },
    [setGridDataState],
  );

  const handleRowClick = useCallback(
    (event: GridRowClickEvent) => {
      openTaskDialog(tasks[event.dataItem.id]);
    },
    [openTaskDialog, tasks],
  );

  const data = useMemo(() => {
    return mapRawCollaborationTasks(
      filterCollaborationTasks(Object.values(tasks), reportType),
    );
  }, [tasks, reportType]);

  const handleExportExcel = useCallback(() => {
    if (excelExportRef.current) {
      excelExportRef.current.save(
        process(data, {
          ...gridDataState,
          // It does not work to export grouped data
          group: undefined,
          // Export all the records
          skip: 0,
          take: undefined,
        }),
      );
    }
  }, [excelExportRef, data, gridDataState]);

  useEffect(() => {
    fetchMyTasks(getSelectedEmpId());
  }, [fetchMyTasks]);

  useEffect(() => {
    const handleSyncTasks = (
      event: CustomEvent<{ tasks: RawCollaborationTask[] }>,
    ) => {
      syncTasks(event.detail.tasks);
    };

    const handleSyncDeletedTasks = (
      event: CustomEvent<{ taskIds: number[] }>,
    ) => {
      syncDeletedTasks(event.detail.taskIds);
    };

    const handleSyncCreatedTaskComment = (
      event: CustomEvent<{ comment: RawTaskComment }>,
    ) => {
      syncCreatedTaskComment(event.detail.comment);
    };

    const handleSyncDeletedTaskComment = (
      event: CustomEvent<{ comment: RawTaskComment }>,
    ) => {
      syncDeletedTaskComment(event.detail.comment);
    };

    window.addEventListener('collaboration:sync-tasks', handleSyncTasks);
    window.addEventListener(
      'collaboration:sync-deleted-tasks',
      handleSyncDeletedTasks,
    );
    window.addEventListener(
      'collaboration:sync-created-task-comment',
      handleSyncCreatedTaskComment,
    );
    window.addEventListener(
      'collaboration:sync-deleted-task-comment',
      handleSyncDeletedTaskComment,
    );

    return () => {
      window.removeEventListener('collaboration:sync-tasks', handleSyncTasks);
      window.removeEventListener(
        'collaboration:sync-deleted-tasks',
        handleSyncDeletedTasks,
      );
      window.removeEventListener(
        'collaboration:sync-created-task-comment',
        handleSyncCreatedTaskComment,
      );
      window.removeEventListener(
        'collaboration:sync-deleted-task-comment',
        handleSyncDeletedTaskComment,
      );
    };
  }, [
    syncTasks,
    syncDeletedTasks,
    syncCreatedTaskComment,
    syncDeletedTaskComment,
  ]);

  return {
    data,
    menuAnchorEl,
    handleOpenQuickFilterMenu,
    handleCloseQuickFilterMenu,
    excelExportRef,
    handleExportExcel,
    reportType,
    handleChangeReportTypeSettings,
    gridDataState,
    handleGridDataStateChange,
    columns,
    handleColumnsSubmit,
    handleRowClick,
    showCommentsFor,
    handleCloseCommentsDialog,
  };
};

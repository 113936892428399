import { useCallback, useEffect, useState } from 'react';

import API from '@/app/api/internalAPIs';
import Service from '@/app/utils/service';
import {
  getLoggedUserId,
  isHR,
} from '@/old/utils/helper';

export type OrgUnit = {
  fTreeId: string;
  fTreeManagerId: string;
  fTreeManagerName: string;
  fTreeManagerPosition: string;
  fTreeManagerProfPictureId: string;
  fTreeManagerWorkCity: string;
  fTreeParentUnitId: string;
  fTreeResponsibleHrEmps: number;
  fTreeUnitId: string;
  fTreeUnitName: string;
  fTreeUnitNumber: string;
  fTreeUnitType: string;
};

const findUnitsWithParentRecursive = (
  parentId: string,
  allUnits: OrgUnit[],
): string[] => {
  if (!parentId || !allUnits || !allUnits.length) {
    return [];
  }

  const findChildUnits = (id: string): string[] =>
    allUnits.reduce((acc: string[], currentUnit) => {
      if (`${currentUnit.fTreeParentUnitId}` !== id) {
        return acc;
      }

      return [...acc, `${currentUnit.fTreeUnitId}`].concat(
        findChildUnits(`${currentUnit.fTreeUnitId}`),
      );
    }, []);

  return [parentId, ...findChildUnits(parentId)];
};

export const useUnits = () => {
  const userId = getLoggedUserId();
  const isUserHr = isHR();
  const [myUnits, setMyUnits] = useState<number[]>([]);
  const [allUnits, setAllUnits] = useState<OrgUnit[]>([]);
  const [includeSubunits, setIncludeSubUnits] = useState(false);
  const [selectedUnitId, setSelectedUnitId] = useState('');

  const getIncludedUnits = (
    unitId: string,
    includeSub: boolean
  ): string[] => includeSub
    ? findUnitsWithParentRecursive(`${unitId}`, allUnits)
    : [unitId];

  const getUnitDataById = useCallback(
    (unitId: number) => allUnits.find(unit => unit.fTreeUnitId === `${unitId}`),
    [allUnits]
  );

  useEffect(() => {
    Service.get(
      API.orgTree.primaryUnits(),
      (response: any) => {
        setAllUnits(response);
      },
      () => '',
    );
  
    Service.get(
      isUserHr
        ? API.orgTree.myHrUnits(userId)
        : API.orgTree.myManagedUnits(userId),
      (response: any) => {
        setMyUnits(response);
      },
      () => '',
    );
  }, [
    userId,
    isUserHr,
    setAllUnits,
  ]);

  return {
    myUnits,
    allUnits,
    includeSubunits,
    selectedUnitId,
    getUnitDataById,
    getIncludedUnits,
    setSelectedUnitId,
    setIncludeSubUnits
  };
};

import * as React from 'react';
import Modal from '@material-ui/core/Modal';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import modalWrapperStyle from './modalWrapperStyle';
import TemplateHeader from '@/app/components/TemplateComponents/admin/TemplateHeader/TemplateHeader';
import { connect } from 'react-redux';
import { selectActiveEnumsInGroup } from '@/app/redux/enums';
import { ReducerState } from '@/app/redux/store';

class ModalWrapper extends React.PureComponent<any> {
  render() {
    const {
      languages,
      modalOpen,
      addAppraisalTemplate,
      handleModalClose,
      templateTags,
    } = this.props;

    return (
      <div>
        <Modal open={modalOpen} onClose={() => handleModalClose()}>
          <TemplateHeader
            languages={languages}
            handleModalClose={handleModalClose}
            addTemplate={addAppraisalTemplate}
            showTags={true}
            templateTags={templateTags}
            teamTemplate={true}
          />
        </Modal>
      </div>
    );
  }
}

export const mapStateToProps = (state: ReducerState) => {
  return {
    templateTags: selectActiveEnumsInGroup(state, 'APPRAISAL_TEMPLATE_TAGS'),
  };
};

const enhance = compose<any, any>(
  connect(mapStateToProps, null),
  withStyles(modalWrapperStyle)
);

export default enhance(ModalWrapper);

export type ICalEvent = {
  title: string;
  description: string;
  startTime: string;
  endTime: string;
  location: string;
};

export const buildUrl = (
  event: ICalEvent,
  useDataURL: boolean = false
) => {
  const url = [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'BEGIN:VEVENT',
    'URL:' + document.URL,
    'DTSTART;VALUE=DATE:' + event.startTime,
    'DTEND;VALUE=DATE:' + event.endTime,
    // 'DTSTART:' + event.startTime.startOf('date').toISOString(),
    // 'DTEND:' + event.endTime.add(1, 'day').startOf('date').toISOString(),
    'SUMMARY:' + event.title,
    'DESCRIPTION:' + event.description,
    'LOCATION:' + event.location,
    'END:VEVENT',
    'END:VCALENDAR'
  ].join('\n');

  if (useDataURL) {
    return encodeURI(`data:text/calendar;charset=utf8,${url}`);
  } else {
    return url;
  }
};

export const downloadBlob = (
  blob: object,
  filename: string,
) => {
  const linkEl = document.createElement('a');
  linkEl.href = window.URL.createObjectURL(blob);
  linkEl.setAttribute('download', filename);
  document.body.appendChild(linkEl);
  linkEl.click();
  document.body.removeChild(linkEl);
};

export function isIOSSafari(): boolean {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);

  return iOS && webkit && !ua.match(/CriOS/i);
}

export function isIOSChrome(): boolean {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);

  return iOS && !!ua.match(/CriOS/i);
}

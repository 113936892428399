import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { CommentsList as InnerCommentsList, CommentsListInnerProps, CommentsListOuterProps } from './CommentsList';
import { mapDispatchToProps, mapStateToProps } from './commentsListRedux';
import { commentsListStyles } from './commentsListStyles';

export const CommentsList = compose<CommentsListInnerProps, CommentsListOuterProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(commentsListStyles),
)(InnerCommentsList);

import * as React from 'react';
import { compose } from 'recompose';
import { v4 as uuid4 } from 'uuid';
import { withStyles } from '@material-ui/core/styles';
import {
  WithStyles,
  Paper,
  Button,
  Checkbox,
  Typography,
} from '@material-ui/core';
import translate from '@/app/utils/translate';
import saveChangesStyles from './saveChangesStyles';
import {
  getSelectedEmpId,
  getLoggedUserId,
  getEmployeeManagerId,
  getEmployeeHRId,
} from '@/old/utils/helper';
import StatusSelect from '@/app/components/TemplateComponents/form/FormComponents/StatusSelect/StatusSelect';
import StageSelect from '@/app/components/TemplateComponents/form/FormComponents/StageSelect/StageSelect';

const GLOBAL: any = window;

type incomingProps = {
  completionStatus: string;
  activeTemplate: any;
  handleEvaluationsSave: any;
  handleModalClose: any;
  toggleEditMode: any;
  employeeId: number;
  archiveEvaluation: any;
  changeEvaluationStage: any;
  responses: any;
};

type PropsType = incomingProps & WithStyles;

type StateType = {
  status: string;
  approved: boolean;
  archived: boolean;
};

class SaveChanges extends React.PureComponent<PropsType> {
  state: StateType = {
    status: '',
    approved: false,
    archived: false,
  };

  constructor(props: any) {
    super(props);
    this.state = {
      status: props.completionStatus,
      approved: false,
      archived: false,
    };
  }

  addManagerVisibility = (activeTemplate: any, participants: number[]) => {
    if (activeTemplate.mgrAccess) {
      const getManagerEmpId = (empId: number) => {
        return getEmployeeManagerId(empId);
      };
      const DManager = GLOBAL.jsonEmpData.empDeputyManagers;
      const managerId: number = getManagerEmpId(getSelectedEmpId());
      if (managerId) {
        participants.push(managerId);
      }
      if (DManager && DManager.lenth > 0) {
        participants.push(...DManager);
      }
    }
  };

  addHrVisibility = (activeTemplate: any, participants: number[]) => {
    if (activeTemplate.hrAccess) {
      const getHrEmpId = (empId: number) => {
        return getEmployeeHRId(empId);
      };
      const HrId: number = getHrEmpId(getSelectedEmpId());
      if (HrId) {
        participants.push(HrId);
      }
    }
  };

  handleSaveEvaluation = (event: any) => {
    event.preventDefault();
    const { activeTemplate } = this.props;
    const templateId = activeTemplate.id;
    /* At evaluation creation the creator's, employee's and manager's
      or/and hr's ids are added to the list of participants
    */
    let participants = [getLoggedUserId(), getSelectedEmpId()];
    if (activeTemplate.participants) {
      participants = [...participants, ...activeTemplate.participants];
    }
    this.addManagerVisibility(activeTemplate, participants);
    this.addHrVisibility(activeTemplate, participants);

    let fullResponse: any = {};
    fullResponse.evaluationId = uuid4();
    fullResponse.processId = activeTemplate.processId;
    fullResponse.employeeId = getSelectedEmpId();
    fullResponse.responses = this.props.responses;
    fullResponse.mgrAccess = activeTemplate.mgrAccess;
    fullResponse.hrAccess = activeTemplate.hrAccess;
    fullResponse.participants = Array.from(new Set(participants));
    fullResponse.status = this.state.status;
    fullResponse.approved = this.state.approved;
    fullResponse.archived = this.state.archived;
    if (activeTemplate.setStage) {
      fullResponse.stageId = activeTemplate.setStage.id;
    }
    this.props.handleEvaluationsSave(templateId, fullResponse).then(() => {
      if (fullResponse.archived) {
        this.props.archiveEvaluation(fullResponse.evaluationId);
      }
    });
    this.props.toggleEditMode();
    this.props.handleModalClose();
  };

  onStatusChange = (value: string) => {
    this.setState({ status: value }, () => {
      if (this.state.status === 'IN_PROGRESS') {
        this.setState({
          approved: false,
          archived: false,
        });
      }
    });
  };

  handleMarkedAsApproved = () => {
    this.setState({ approved: !this.state.approved });
  };

  handleMarkedToArchive = () => {
    this.setState({ archived: !this.state.archived });
  };

  handleModalClose = () => {
    this.props.handleModalClose();
  };

  render() {
    const {
      classes,
      completionStatus,
      activeTemplate,
      employeeId,
    } = this.props;
    const ownPage = getLoggedUserId() === employeeId;

    return (
      <Paper className={classes.root}>
        <form autoComplete="off" className={classes.form}>
          <Typography variant="subtitle2" className={classes.topTitle}>
            {translate.t('laSaveChanges')}
          </Typography>
          {((!GLOBAL.iEmployee && !ownPage) ||
            GLOBAL.iHRAdmin ||
            (GLOBAL.iManager && !ownPage)) && (
            <>
              <Typography
                variant="subtitle2"
                className={this.props.classes.stageLabel}
              >
                {translate.t('laStatus')}
              </Typography>
              <StatusSelect
                completionStatus={completionStatus}
                onStatusChange={this.onStatusChange}
              />
              {activeTemplate.setStage !== undefined && (
                <>
                  <Typography
                    variant="subtitle2"
                    className={this.props.classes.stageLabel}
                  >
                    {translate.t('laStages')}
                  </Typography>
                  <StageSelect
                    activeTemplate={activeTemplate}
                    changeEvaluationStage={this.props.changeEvaluationStage}
                  />
                </>
              )}
              <div className={classes.checkboxText}>
                <Checkbox
                  color="primary"
                  onClick={() => this.handleMarkedAsApproved()}
                  checked={this.state.approved}
                  disabled={this.state.status === 'IN_PROGRESS'}
                />
                <span
                  className={
                    this.state.status === 'IN_PROGRESS'
                      ? classes.checkboxLabelInactive
                      : classes.checkboxLabelActive
                  }
                >
                  {translate.t('laMarkApproved')}
                </span>
              </div>
              <div className={classes.checkboxText}>
                <Checkbox
                  onClick={() => this.handleMarkedToArchive()}
                  color="primary"
                  checked={this.state.archived}
                  disabled={this.state.status === 'IN_PROGRESS'}
                />
                <span
                  className={
                    this.state.status === 'IN_PROGRESS'
                      ? classes.checkboxLabelInactive
                      : classes.checkboxLabelActive
                  }
                >
                  {translate.t('laArchiveCopy')}
                </span>
              </div>
            </>
          )}
          {GLOBAL.iEmployee && (
            <>
              <br />
              <br />
            </>
          )}
          <div className={classes.buttonsWrapper}>
            <Button
              color="primary"
              variant="text"
              className={classes.button}
              onClick={() => this.handleModalClose()}
            >
              {translate.t('laCancel')}
            </Button>
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={event => this.handleSaveEvaluation(event)}
            >
              {translate.t('laSave')}
            </Button>
          </div>
        </form>
      </Paper>
    );
  }
}

const enhance = compose<any, any>(withStyles(saveChangesStyles));

export default enhance(SaveChanges);

import * as React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import CssBaseline from '@material-ui/core/CssBaseline';

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      light: '#17b9ed',
      main: '#05a4d8',
      dark: '#068bb6',
      contrastText: grey[50],
    },
    secondary: {
      light: '#ffd03f',
      main: '#ffc000',
      dark: '#ca9b0a',
      contrastText: grey[50],
    },
  },
  overrides: {
    MuiButton: {
      root: {
        backgroundImage: 'none' // override the legacy style css from common.css for button
      }
    },
    MuiFormHelperText: {
      root: {
        fontSize: '12px',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '12px',
      },
    },
  },
  props: {
    MuiPaper: {
      tabIndex: 5
    }
  },
  zIndex: {
    mobileStepper: 10100,
    appBar: 10200,
    drawer: 10300,
    modal: 10400,
    snackbar: 10500,
    tooltip: 10600,
  },
});

function withRoot(Component: React.ComponentType) {
  function WithRoot(props: object) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <MuiThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;

import { useCountryAndUnitOptions } from '@/app/components/AutoDeduction/useCountryAndUnitOptions';
import { ReportingGroupType } from '@/app/components/TimeReporting/components/TimeSheet/data';
import { useReportingTypeOptions } from '@/app/components/AutoDeduction/useReportingTypeOptions';
import { SelectOptionProps } from '@/app/components/AutoDeduction/types';

export const getReportingOptions = (
  reportingGroup: ReportingGroupType,
  includeEmpty?: boolean,
  compoundValue?: boolean,
): SelectOptionProps[] => {
  const { reportingTypesOptions } = useReportingTypeOptions(
    [reportingGroup],
    null,
    compoundValue,
  );
  const options = reportingTypesOptions.length
    ? reportingTypesOptions[0].options
    : [];
  const empty = { label: '', value: '' };
  return includeEmpty ? [empty, ...options] : options;
};

export const useAutoDeductionOptions = () => {
  const { unitOptions, countryOptions } = useCountryAndUnitOptions();

  const correctionTypeOptions = getReportingOptions(
    ReportingGroupType.TIMESHEET_CORRECTION_TYPE,
    false,
    false,
  );
  const nonWorkTypeOptions = getReportingOptions(
    ReportingGroupType.TIMESHEET_NON_WORK_TYPE,
    false,
    false,
  );

  return {
    unitOptions,
    countryOptions,
    correctionTypeOptions,
    nonWorkTypeOptions,
  };
};

import moment from 'moment';

import { getActiveEnumNameByCode } from '@/app/utils/helper';
import { getEmployeeName } from '@/old/utils/helper';

import { RawCollaborationTask } from '../../collaborationModels';

import { CollaborationTask } from './tasksListModels';

export const mapRawCollaborationTasks = (tasks: RawCollaborationTask[]): CollaborationTask[] => {
    return tasks.map((task) => ({
        id: task.fTaskId,
        title: task.taskCore.fTaskTitle,
        type: getActiveEnumNameByCode('COLLAB_TASK_TYPE', task.taskCore.fTaskType),
        status: getActiveEnumNameByCode('COLLAB_TASK_STATUS', task.taskState.fTaskStatus),
        startDate: moment(task.taskState.fTaskStartDate, 'YYYY-MM-DD').toDate(),
        dueDate:
        (task.taskState.fTaskDueDate &&
          moment(task.taskState.fTaskDueDate, 'YYYY-MM-DD').toDate()) ||
          '',
        assigneeId: task.taskCore.fTaskAssigneeId,
        assigneeName: getEmployeeName(task.taskCore.fTaskAssigneeId, true),
        assignorId: task.taskCore.fTaskAssignorId,
        assignorName: getEmployeeName(task.taskCore.fTaskAssignorId, true),
        pertainingToId: task.taskCore.fTaskPertainingToId,
        pertainingToName: getEmployeeName(task.taskCore.fTaskPertainingToId, true),
        numberOfComments: task.numberOfComments,
        attachmentId: task.fTaskAttachmentId,
        attachment: task.fTaskAttachmentName,
    }));
};
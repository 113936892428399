import { Map as iMap } from 'immutable';
import { THROW_ERROR } from './error';
import API from '@/app/api/externalAPIs';
import Service from '@/app/utils/service';

export type CompanyInfoType = {
  logo: string;
  name: string;
  country: string;
  headerColor: string;
  configs: any;
};

const EMPTY_COMPANY_INFO: CompanyInfoType = {
  logo: '',
  name: '',
  country: '',
  headerColor: '',
  configs: {},
};

const FETCH_COMPANY_INFO_PENDING = '@@solaforce/companyInfo/FETCH_COMPANY_INFO_PENDING';
const FETCH_COMPANY_INFO_FULFILLED = '@@solaforce/companyInfo/FETCH_COMPANY_INFO_FULFILLED';
const FETCH_COMPANY_INFO_REJECTED = '@@solaforce/companyInfo/FETCH_COMPANY_INFO_REJECTED';

const initialState = iMap({
  isFetching: false,
  ...EMPTY_COMPANY_INFO,
});

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_COMPANY_INFO_PENDING:
      return state.set('isFetching', true);
    case FETCH_COMPANY_INFO_FULFILLED:
      const { fComName, fComCountry, fComHeaderColor,  fComConfig } = action.data;

      return state
        .set('name', fComName)
        .set('country', fComCountry)
        .set('headerColor', fComHeaderColor)
        .set('configs', fComConfig)
        .set('isFetching', false);
    case FETCH_COMPANY_INFO_REJECTED:
      return state.set('isFetching', true);
    default:
      return state;
  }
};

export const fetchCompanyInfo = () => {
  return (dispatch: any) => {
    dispatch({ type: FETCH_COMPANY_INFO_PENDING });

    Service.get(
      API.companyName,
      (data: any) => {
        dispatch({ type: FETCH_COMPANY_INFO_FULFILLED, data });
      },
      (error: any) => {
        dispatch({ type: FETCH_COMPANY_INFO_REJECTED });
        dispatch({ type: THROW_ERROR, error });
      },
    );
  };
};

export default reducer;

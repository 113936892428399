import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const scoreComponentStyles: StyleRulesCallback = (theme: Theme) => ({
  resultValue: {
    marginBottom: 2 * theme.spacing.unit,
    marginLeft: 50 * theme.spacing.unit,
    fontSize: 20,
  },
  select: {
    margin: 2 * theme.spacing.unit,
    minWidth: 150,
  },
  hide: {
    display: 'none',
  },
  noValue: {
    margin: 2 * theme.spacing.unit,
  },
  tooltipShow: {
    display: 'block',
  },
});

export default scoreComponentStyles;

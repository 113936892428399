import { Record } from 'immutable';
import { Dispatch } from 'redux';
import * as moment from 'moment';
import API from '@/app/api/internalAPIs';
import Service from '@/app/utils/service';
import { getEmployeeName, getSelectedEmpId } from '@/old/utils/helper';
import translate from '@/app/utils/translate';
import { THROW_ERROR } from './error';
import { OPEN_NOTIFIER } from './notifier';
import { getDefault } from '@/app/components/TemplateComponents/helpers';
import { ProcessFormTemplate } from '@/app/components/TemplateComponents/types';

const initialState = Record({
  allProcessTemplates: [],
  activeTemplate: {} as any,
  processEvaluation: { responses: [] } as any,
  archivedProcessEvaluations: [],
});

const FETCH_ALL_PROCESS_TEMPLATES =
  '@@solaforce/processEvaluation/FETCH_ALL_PROCESS_TEMPLATES';
const FETCH_ARCHIVED_PROCESS_EVAL =
  '@@solaforce/processEvaluation/FETCH_ARCHIVED_PROCESS_EVAL';
const CLEAR_ARCHIVED_PROCESS_EVAL =
  '@@solaforce/processEvaluation/CLEAR_ARCHIVED_PROCESS_EVAL';
const SET_INITIAL_TEMPLATE =
  '@@solaforce/processEvaluation/SET_INITIAL_TEMPLATE';
const SET_ACTIVE_TEMPLATE = '@@solaforce/processEvaluation/SET_ACTIVE_TEMPLATE';
const SAVE_EMPLOYEE_EVALUATION =
  '@@solaforce/processEvaluation/SAVE_EMPLOYEE_EVALUATION';
const CHANGE_TEMPLATE_APPROVEMENT = '@@solaforce/processEvaluation/APPROVE';
const CHANGE_EVALUATION_STAGE = '@@solaforce/processEvaluation/STAGE_CHANGE';
const UPDATE_ACTIVE_TEMPLATE =
  '@@solaforce/processEvaluation/UPDATE_ACTIVE_TEMPLATE';
const ARCHIVE_PROCESS_EVALUATION =
  '@@solaforce/processEvaluation/ARCHIVE_PROCESS_EVALUATION';
const UPDATE_PROCESS_EVALUATION =
  '@@solaforce/processEvaluation/UPDATE_PROCESS_EVALUATION';
const LOAD_PROCESS_EVALUATION =
  '@@solaforce/processEvaluation/LOAD_PROCESS_EVALUATION';
const CLEAR_EVALUATION = '@@solaforce/processEvaluation/CLEAR_EVALUATION';
const UPDATE_MANAGER_VISIBILITY =
  '@@solaforce/processEvaluation/UPDATE_MANAGER_VISIBILITY';

// Helper functions to format strings for process approval
const formatedListOnRemoval = (
  initialList: string,
  approvingUserName: string,
) => {
  if (initialList.indexOf(', ' + approvingUserName) !== -1) {
    return initialList.replace(', ' + approvingUserName, '');
  } else if (initialList.indexOf(approvingUserName + ', ') !== -1) {
    return initialList.replace(approvingUserName + ', ', '');
  } else {
    return initialList.replace(approvingUserName, '');
  }
};

const formatedAddition = (initialList: string, approvingUserName: string) => {
  if (initialList.length > 0) {
    return initialList + ', ' + approvingUserName;
  } else {
    return approvingUserName;
  }
};

const updateLastModifiedBy = (template: any) => {
  if (!template.updatedBy || !template.updatedBy.employeeId) {
    return template.lastModifiedBy || '';
  }

  return getEmployeeName(template.updatedBy.employeeId, true);
};

// Helper function to update components
const updateResponse = (listOfResponses: object[], answer: any) => {
  let newListOfResponses = Object.assign([], listOfResponses);
  let found = false;
  for (var i = 0; i < newListOfResponses.length; i++) {
    if (newListOfResponses[i].componentId === answer.componentId) {
      found = true;
      // Updates text components
      if (
        answer.type === 'TEXT' ||
        answer.type === 'DATE' ||
        answer.type === 'SCALE'
      ) {
        newListOfResponses[i] = answer;
      }
      // Updates checkbox components
      if (answer.type === 'CHECKBOXES') {
        if (newListOfResponses[i].response.includes(answer.response[0])) {
          newListOfResponses[i].response = newListOfResponses[
            i
          ].response.filter((value: number) => value !== answer.response[0]);
        } else {
          newListOfResponses[i].response.push(answer.response[0]);
        }
      }
      // Updates evaluations components
      if (answer.type === 'EVALUATIONS') {
        newListOfResponses[i].response.map(
          (evalQuestion: any, index: number) => {
            if (evalQuestion.optionId === answer.response[0].optionId) {
              newListOfResponses[i].response[index] = {
                ...evalQuestion,
                ...answer.response[0],
              };
            } else if (
              newListOfResponses[i].response.find(
                (o: any) => o.optionId === answer.response[0].optionId,
              ) === undefined
            ) {
              newListOfResponses[i].response.push(answer.response[0]);
            }
          },
        );
      }
      // Updates dropdown components
      if (
        answer.type === 'DROPDOWN' ||
        answer.type === 'MULTISELECT_DROPDOWN'
      ) {
        newListOfResponses[i].response = answer.response;
      }
    }
  }

  if (!found) {
    // Pushes a new component to the list of answers
    newListOfResponses.push(answer);
  }
  return newListOfResponses;
};

const reducer = (state = new initialState(), action: any) => {
  switch (action.type) {
    case FETCH_ALL_PROCESS_TEMPLATES:
      const { allProcessTemplates } = action.payload;
      return state.set('allProcessTemplates', allProcessTemplates);

    case FETCH_ARCHIVED_PROCESS_EVAL:
      const { archivedProcessEvaluations } = action.payload;
      return state.set(
        'archivedProcessEvaluations',
        archivedProcessEvaluations,
      );

    case CLEAR_ARCHIVED_PROCESS_EVAL:
      return state.set('archivedProcessEvaluations', []);

    case SET_INITIAL_TEMPLATE:
      const { activeEval } = action.payload;
      return state.set('activeTemplate', activeEval);

    case SET_ACTIVE_TEMPLATE:
      const { templateSelected } = action.payload;
      return state.set('activeTemplate', templateSelected);

    case SAVE_EMPLOYEE_EVALUATION:
      return state;

    case UPDATE_ACTIVE_TEMPLATE:
      const { approvingUser } = action.payload;
      const activeTemp = state.get('activeTemplate');
      const updatedActiveTemplate = {
        ...activeTemp,
        approvedBy: activeTemp.approvedBy.includes(approvingUser)
          ? formatedListOnRemoval(activeTemp.approvedBy, approvingUser)
          : formatedAddition(activeTemp.approvedBy, approvingUser),
        lastEdited: moment().format('YYYY-MM-DD'),
        lastModifiedBy: approvingUser,
      };
      return state.set('activeTemplate', updatedActiveTemplate);

    case LOAD_PROCESS_EVALUATION: {
      const { evaluation } = action;
      const tmpID = evaluation.templateId;
      const allAnswers = evaluation.responses;
      return state.set('processEvaluation', { tmpID, responses: allAnswers });
    }

    case UPDATE_PROCESS_EVALUATION:
      const { templateId, componentAnswer } = action.payload;
      const processEvaluationAnswers = state.get('processEvaluation').responses;
      const updatedAnswers = updateResponse(
        processEvaluationAnswers,
        componentAnswer,
      );

      return state.set('processEvaluation', {
        templateId,
        responses: updatedAnswers,
      });

    case CLEAR_EVALUATION:
      return state.set('processEvaluation', { responses: [] });

    case ARCHIVE_PROCESS_EVALUATION:
      const { templateToArhive } = action.payload;
      const allProcessUpdate = state
        .get('allProcessTemplates')
        .map((item: any) => {
          if (item.id === templateToArhive) {
            return {
              ...item,
              archived: true,
            };
          } else {
            return item;
          }
        });
      return state.set('allProcessTemplates', allProcessUpdate);

    case CHANGE_TEMPLATE_APPROVEMENT:
      const { evaluationToUpdate, newApproval } = action.payload;

      const updatedApprovedStatus = state
        .get('allProcessTemplates')
        .map((item: any) => {
          if (item.id === evaluationToUpdate) {
            if (item.approvedBy.includes(newApproval)) {
              return {
                ...item,
                approvedBy: formatedListOnRemoval(item.approvedBy, newApproval),
                lastEdited: moment().format('YYYY-MM-DD'),
                lastModifiedBy: newApproval,
              };
            } else {
              return {
                ...item,
                approvedBy: formatedAddition(item.approvedBy, newApproval),
                lastEdited: moment().format('YYYY-MM-DD'),
                lastModifiedBy: newApproval,
              };
            }
          }
          return item;
        });
      return state.set('allProcessTemplates', updatedApprovedStatus);
    default:
      return state;

    case CHANGE_EVALUATION_STAGE:
      const { setStage, userMakingTheChange } = action.payload;
      const activeTmp = state.get('activeTemplate');
      const updatedWStateChange = {
        ...activeTmp,
        setStage: setStage,
        lastEdited: moment().format('YYYY-MM-DD'),
        lastModifiedBy: userMakingTheChange,
      };
      return state.set('activeTemplate', updatedWStateChange);

    case UPDATE_MANAGER_VISIBILITY:
      const { role, visibility, userMakingChange } = action.payload;
      const activeTemplate = state.get('activeTemplate');
      const updatedWVisibilityChange = {
        ...activeTemplate,
        [role]: visibility,
        lastEdited: moment().format('YYYY-MM-DD'),
        lastModifiedBy: userMakingChange,
      };
      return state.set('activeTemplate', updatedWVisibilityChange);
  }
};

export const getEvaluationInformation = (
  template: ProcessFormTemplate,
  employeeId: number,
) => {
  return (dispatch: Dispatch) => {
    return Service.get(
      API.processEvaluations.getEvaluationDetails(template.id, employeeId),
      (response: any) => {
        if (response.data.length > 0) {
          response.data.map((evaluation: any) => {
            let payload = {
              activeEval: {
                id: evaluation.templateId,
                currentEvaluationId: evaluation.id,
                processId: evaluation.processId,
                subject: template.subject,
                deadline: template.deadline,
                sections: template.sections,
                stages: template.stages,
                setStage: evaluation.stageId
                  ? template.stages.filter(
                      (stage: any) => stage.id === evaluation.stageId,
                    )[0]
                  : getDefault(template.stages),
                lastEdited: moment(template.updatedTime).format('YYYY-MM-DD'),
                lastModifiedBy: evaluation.updatedBy
                  ? formatedAddition(
                      '',
                      getEmployeeName(evaluation.updatedBy, true),
                    )
                  : updateLastModifiedBy(template),
                completionStatus: evaluation.status,
                approvedBy: evaluation.approvedBy
                  .map((x: any) => getEmployeeName(x, true))
                  .reduce(formatedAddition, ''),
                responses: evaluation.responses,
                mgrAccess: evaluation.mgrAccess,
                hrAccess: evaluation.hrAccess,
                participants: evaluation.participants,
              },
            };
            dispatch({ type: SET_INITIAL_TEMPLATE, payload });
            dispatch({ type: LOAD_PROCESS_EVALUATION, evaluation });
          });
        } else {
          const noParticipants: number[] = [];
          const payload = {
            activeEval: {
              id: template.id,
              subject: template.subject,
              completionStatus: 'IN_PROGRESS',
              deadline: template.deadline,
              approvedBy: '',
              sections: template.sections,
              stages: template.stages,
              setStage: getDefault(template.stages),
              lastEdited: moment(template.updatedTime).format('YYYY-MM-DD'),
              lastModifiedBy: updateLastModifiedBy(template),
              mgrAccess: template.defaultMgrAccess || false,
              hrAccess: template.defaultHrAccess || false,
              participants: noParticipants,
              restricted: response.restricted,
            },
          };
          dispatch({ type: SET_INITIAL_TEMPLATE, payload });
          dispatch({ type: CLEAR_EVALUATION });
        }
      },
      (error: any) => {
        dispatch({ type: THROW_ERROR, error });
      },
    );
  };
};

export const fetchAllProcessTemplates = () => {
  return (dispatch: Dispatch) => {
    return Service.get(
      API.processEvaluations.getTemplateList(getSelectedEmpId()),
      (response: any) => {
        const payload = {
          allProcessTemplates: response.data,
        };
        dispatch({ type: FETCH_ALL_PROCESS_TEMPLATES, payload });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const fetchArchivedProcessEvaluations = (employeeId: number) => {
  return (dispatch: Dispatch) => {
    Service.get(
      API.processEvaluations.getArchivedEvaluations(employeeId),
      (response: any) => {
        const payload = { archivedProcessEvaluations: response.data };
        dispatch({ type: FETCH_ARCHIVED_PROCESS_EVAL, payload });
      },
      (_error: any) => dispatch({ type: CLEAR_ARCHIVED_PROCESS_EVAL }),
    );
  };
};

export const setActiveTemplate = (
  templateSelected: any,
  employeeId: number,
  fromArchive: boolean = false,
) => {
  return (dispatch: Dispatch) => {
    const url = fromArchive
      ? API.processEvaluations.getArchivedEvaluationDetails(
          templateSelected.id,
          employeeId,
        )
      : API.processEvaluations.getEvaluationDetails(
          templateSelected.id,
          employeeId,
        );

    return Service.get(
      url,
      (response: any) => {
        if (fromArchive) {
          dispatch({
            type: SET_ACTIVE_TEMPLATE,
            payload: {
              templateSelected: {
                id: response.templateId,
                currentEvaluationId: response.id,
                processId: response.processId,
                subject: templateSelected.subject,
                deadline: templateSelected.deadline,
                sections: templateSelected.sections,
                stages: templateSelected.stages,
                setStage: response.stageId
                  ? templateSelected.stages.filter(
                      (stage: any) => stage.id === response.stageId,
                    )[0]
                  : getDefault(templateSelected.stages),
                lastEdited: moment(templateSelected.updatedTime).format(
                  'YYYY-MM-DD',
                ),
                lastModifiedBy: response.updatedBy
                  ? formatedAddition(
                      '',
                      getEmployeeName(response.updatedBy, true),
                    )
                  : updateLastModifiedBy(templateSelected),
                completionStatus: response.status,
                approvedBy: formatedAddition(
                  '',
                  getEmployeeName(response.approvedBy, true),
                ),
                archived: fromArchive,
                responses: response.responses,
                mgrAccess: response.mgrAccess,
                hrAccess: response.hrAccess,
                participants: response.participants,
              },
            },
          });

          dispatch({ type: LOAD_PROCESS_EVALUATION, evaluation: response });

          return;
        }

        if (response.data.length > 0) {
          response.data.forEach((evaluation: any) => {
            dispatch({
              type: SET_ACTIVE_TEMPLATE,
              payload: {
                templateSelected: {
                  id: evaluation.templateId,
                  currentEvaluationId: evaluation.id,
                  processId: evaluation.processId,
                  subject: templateSelected.subject,
                  deadline: templateSelected.deadline,
                  sections: templateSelected.sections,
                  stages: templateSelected.stages,
                  setStage: evaluation.stageId
                    ? templateSelected.stages.find(
                        (stage: any) => stage.id === evaluation.stageId,
                      )
                    : getDefault(templateSelected.stages),
                  lastEdited: moment(templateSelected.updatedTime).format(
                    'YYYY-MM-DD',
                  ),
                  lastModifiedBy: evaluation.updatedBy
                    ? formatedAddition(
                        '',
                        getEmployeeName(evaluation.updatedBy, true),
                      )
                    : updateLastModifiedBy(templateSelected),
                  completionStatus: evaluation.status,
                  approvedBy: formatedAddition(
                    '',
                    getEmployeeName(evaluation.approvedBy, true),
                  ),
                  archived: fromArchive,
                  responses: evaluation.responses,
                  mgrAccess: evaluation.mgrAccess,
                  hrAccess: evaluation.hrAccess,
                  participants: evaluation.participants,
                },
              },
            });

            dispatch({ type: LOAD_PROCESS_EVALUATION, evaluation });
          });

          return;
        }

        dispatch({
          type: SET_ACTIVE_TEMPLATE,
          payload: {
            templateSelected: {
              id: templateSelected.id,
              subject: templateSelected.subject,
              completionStatus: 'IN_PROGRESS',
              deadline: templateSelected.deadline,
              approvedBy: '',
              sections: templateSelected.sections,
              stages: templateSelected.stages,
              setStage: getDefault(templateSelected.stages),
              lastEdited: moment(templateSelected.updatedTime).format(
                'YYYY-MM-DD',
              ),
              lastModifiedBy: updateLastModifiedBy(templateSelected),
              mgrAccess: templateSelected.mgrAccess || false,
              hrAccess: templateSelected.hrAccess || false,
              participants: [] as number[],
              restricted: response.restricted,
            },
          },
        });

        dispatch({ type: CLEAR_EVALUATION });
      },
      (error: any) => {
        dispatch({ type: THROW_ERROR, error });
      },
    );
  };
};

export const updateEvaluation = (templateId: string, componentAnswer: {}) => {
  return (dispatch: Dispatch) => {
    const payload = {
      templateId: templateId,
      componentAnswer: componentAnswer,
    };
    dispatch({ type: UPDATE_PROCESS_EVALUATION, payload });
  };
};

export const clearEvaluation = (_templateId: string) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: CLEAR_EVALUATION });
  };
};

export const saveEmployeeEvaluations = (
  templateId: string,
  fullResponse: {},
) => {
  return (dispatch: Dispatch) => {
    return Service.post(
      API.processEvaluations.saveEvaluation(templateId),
      fullResponse,
      (response: any) => {
        const payload = {
          fullResponse: fullResponse,
        };
        dispatch({ type: SAVE_EMPLOYEE_EVALUATION, payload });
        dispatch({
          type: OPEN_NOTIFIER,
          payload: {
            message: translate.t('change_saved'),
          },
        });
        return response;
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const archiveEvaluation = (processEvaluationId: string) => {
  return (dispatch: Dispatch) => {
    return Service.post(
      API.processEvaluations.archiveEvaluation(processEvaluationId),
      {},
      (response: any) => {
        const payload = {
          templateToArhive: processEvaluationId,
        };
        dispatch({ type: ARCHIVE_PROCESS_EVALUATION, payload });
        dispatch({
          type: OPEN_NOTIFIER,
          payload: {
            message: translate.t('evaluation_archived'),
          },
        });
        return response;
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const changeEvaluationApproval = (
  evaluationId: string,
  newApproval: string,
) => {
  return (dispatch: Dispatch) => {
    Service.post(
      API.processEvaluations.approveEvaluation(evaluationId),
      { newApproval: newApproval },
      (_response: any) => {
        const payload = {
          evaluationToUpdate: evaluationId,
          newApproval: newApproval,
          approvingUser: newApproval,
        };
        dispatch({ type: CHANGE_TEMPLATE_APPROVEMENT, payload });
        dispatch({ type: UPDATE_ACTIVE_TEMPLATE, payload });
        dispatch({
          type: OPEN_NOTIFIER,
          payload: { message: translate.t('change_saved') },
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const removeEvaluationApproval = (
  evaluationId: string,
  newApproval: string,
) => {
  return (dispatch: Dispatch) => {
    Service.post(
      API.processEvaluations.unapproveEvaluation(evaluationId),
      { newApproval: newApproval },
      (_response: any) => {
        const payload = {
          evaluationToUpdate: evaluationId,
          newApproval: newApproval,
          approvingUser: newApproval,
        };
        dispatch({ type: CHANGE_TEMPLATE_APPROVEMENT, payload });
        dispatch({ type: UPDATE_ACTIVE_TEMPLATE, payload });
        dispatch({
          type: OPEN_NOTIFIER,
          payload: { message: translate.t('change_saved') },
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const changeEvaluationStage = (
  setStage: any,
  userMakingTheChange: string,
) => {
  return (dispatch: Dispatch) => {
    const payload = {
      setStage: setStage,
      userMakingTheChange: userMakingTheChange,
    };
    dispatch({ type: CHANGE_EVALUATION_STAGE, payload });
  };
};

export const changeRoleVisibility = (
  role: string,
  visibility: boolean,
  userMakingChange: string,
) => {
  return (dispatch: Dispatch) => {
    const payload = {
      role: role,
      visibility: visibility,
      userMakingChange: userMakingChange,
    };
    dispatch({ type: UPDATE_MANAGER_VISIBILITY, payload });
  };
};

export default reducer;

import * as React from 'react';
import { v4 as uuid4 } from 'uuid';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import {
  WithStyles,
  Paper,
  TextField,
  Button,
  Checkbox,
} from '@material-ui/core';
import addSectionStyle from './addSectionStyle';
import translate from '@/app/utils/translate';
import {
  ActiveTemplate,
  JobReqEvalSection,
} from '@/app/components/JobEvaluation/types';
import { titleValidation } from '@/app/components/JobEvaluation/helpers';

type IncomingProps = {
  handleModalClose: () => void;
  addTemplateSection: (id: String, template: Object) => void;
  readyToEditTemplate: ActiveTemplate[];
  newSectionAdded: () => void;
  updateSection: (id: String, template: Object) => void;
  section?: JobReqEvalSection;
  editMode: boolean;
};

type StateType = {
  title: string;
  showValidationError: boolean;
  disabledSave: boolean;
  empAccess: boolean;
  onlyEmpAccess: boolean;
};

type PropsType = IncomingProps & WithStyles<typeof addSectionStyle>;

class AddSection extends React.PureComponent<PropsType> {
  state: StateType = {
    title: '',
    showValidationError: false,
    disabledSave: true,
    empAccess: true,
    onlyEmpAccess: false,
  };

  constructor(props: PropsType) {
    super(props);

    const { section, readyToEditTemplate } = this.props;
    const [templateBeingEdited] = readyToEditTemplate;

    this.state = {
      title: (section && section.name) || '',
      showValidationError: false,
      disabledSave: true,
      empAccess:
        !templateBeingEdited.isTeam && !!section && !!section.empAccess,
      onlyEmpAccess:
        !templateBeingEdited.isTeam && !!section && !!section.onlyEmpAccess,
    };
  }

  changeTitle = (event: React.ChangeEvent) => {
    const target = event.target as HTMLTextAreaElement;
    this.setState({ title: target.value });
    if (target.value !== '') {
      this.setState({ showValidationError: false, disabledSave: false });
    }
  };

  handleSaveSection = (handleModalClose: () => void) => {
    const {
      addTemplateSection,
      readyToEditTemplate,
      newSectionAdded,
    } = this.props;
    const { title, empAccess, onlyEmpAccess } = this.state;
    const [templateBeingEdited] = readyToEditTemplate;
    const isTitleValid = titleValidation(title);
    this.setState({ showValidationError: !isTitleValid });

    if (!isTitleValid) {
      return;
    }
    addTemplateSection(templateBeingEdited.id, {
      id: uuid4(),
      name: title,
      empAccess: empAccess,
      onlyEmpAccess: onlyEmpAccess,
      index: templateBeingEdited.sections
        ? templateBeingEdited.sections.length + 1
        : 0,
    });
    handleModalClose();
    newSectionAdded();
  };

  handleSectionUpdate = (handleModalClose: () => void) => {
    const { section, updateSection, readyToEditTemplate } = this.props;
    const { title, empAccess, onlyEmpAccess } = this.state;
    const isTitleValid = titleValidation(title);
    this.setState({ showValidationError: !isTitleValid });

    if (!isTitleValid) {
      return;
    }
    const updatedSection = {
      id: section.id,
      components: section.components,
      name: title,
      empAccess: empAccess,
      onlyEmpAccess: onlyEmpAccess,
      index: section.index,
    };

    updateSection(readyToEditTemplate[0].id, updatedSection);
    handleModalClose();
  };

  employeeCanEdit = (setTrue: boolean) => {
    if (setTrue) {
      this.setState({ empAccess: true });
    } else {
      this.setState({ empAccess: !this.state.empAccess });
    }
    this.setState({ disabledSave: false });
  };
  onlyEmployeeCanEdit = () => {
    const { onlyEmpAccess } = this.state;
    this.setState({ onlyEmpAccess: !onlyEmpAccess }, () => {
      this.employeeCanEdit(true);
      this.setState({ disabledSave: false });
    });
  };

  render() {
    const {
      classes,
      handleModalClose,
      editMode,
      readyToEditTemplate,
    } = this.props;
    const {
      title,
      empAccess,
      onlyEmpAccess,
      showValidationError,
      disabledSave,
    } = this.state;
    const [templateBeingEdited] = readyToEditTemplate;

    return (
      <Paper className={classes.root}>
        <form>
          <h3 className={classes.topTitle}>
            {editMode
              ? translate.t('edit_section')
              : translate.t('add_section')}
          </h3>
          <TextField
            label={translate.t('laTitle')}
            onChange={this.changeTitle}
            value={title}
            className={classes.spacing}
            required
            error={showValidationError}
            helperText={
              showValidationError ? translate.t('laThisRequired') : ' '
            }
          />
          <div className={classes.checkboxes}>
            <Checkbox
              onClick={() => this.employeeCanEdit(false)}
              checked={empAccess}
              disabled={templateBeingEdited.isTeam || onlyEmpAccess}
              color="primary"
            />
            {translate.t('employee_edit')}
          </div>
          <div className={classes.checkboxes}>
            <Checkbox
              onClick={() => this.onlyEmployeeCanEdit()}
              checked={onlyEmpAccess}
              disabled={templateBeingEdited.isTeam}
              color="primary"
            />
            {translate.t('only_employee_edit')}
          </div>
          <div className={classes.buttonsWrapper}>
            <Button color="primary" variant="text" onClick={handleModalClose}>
              {translate.t('laCancel')}
            </Button>
            {!editMode && (
              <Button
                color="primary"
                variant="contained"
                disabled={!!disabledSave}
                onClick={() => this.handleSaveSection(handleModalClose)}
              >
                {translate.t('laSave')}
              </Button>
            )}
            {editMode && (
              <Button
                color="primary"
                variant="contained"
                disabled={!!disabledSave}
                onClick={() => this.handleSectionUpdate(handleModalClose)}
              >
                {translate.t('laSaveChanges')}
              </Button>
            )}
          </div>
        </form>
      </Paper>
    );
  }
}

const enhance = compose<PropsType, IncomingProps>(withStyles(addSectionStyle));

export default enhance(AddSection);

import * as React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import {
  WithStyles,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import statusSelectStyle from './statusSelectStyles';
import { translateCompletionStatus } from '@/app/components/TemplateComponents/helpers';
import { FormCompletionStatus } from '@/app/components/TemplateComponents/form/enums';

type IncomingProps = {
  completionStatus: string;
  onStatusChange: (value: string) => void;
};

type PropsType = IncomingProps & WithStyles;

type StateType = {
  setStatus: { label: string; value: string };
};

class StatusSelect extends React.PureComponent<PropsType> {
  state: StateType = {
    setStatus: {
      label: translateCompletionStatus(this.props.completionStatus),
      value: this.props.completionStatus,
    },
  };

  constructor(props: PropsType) {
    super(props);
    const { completionStatus, onStatusChange } = props;
    this.state = {
      setStatus: {
        label: translateCompletionStatus(completionStatus),
        value: completionStatus,
      },
    };
    onStatusChange(completionStatus);
  }

  handleChange = (event: React.ChangeEvent<HTMLOptionElement>) => {
    const { value } = event.target;
    this.setState({
      setStatus: {
        label: translateCompletionStatus(value),
        value,
      },
    });
    this.props.onStatusChange(value);
  };

  render() {
    const { setStatus } = this.state;

    return (
      <FormControl className={this.props.classes.root}>
        <RadioGroup
          aria-label="position"
          name="position"
          value={setStatus.value}
          onChange={this.handleChange}
          row
        >
          <FormControlLabel
            key={'in_progress'}
            value={FormCompletionStatus.IN_PROGRESS}
            control={
              <Radio
                color="primary"
                checked={setStatus.value === FormCompletionStatus.IN_PROGRESS}
              />
            }
            label={translateCompletionStatus(FormCompletionStatus.IN_PROGRESS)}
            labelPlacement="top"
          />
          <FormControlLabel
            key={'completed'}
            value={FormCompletionStatus.COMPLETED}
            control={
              <Radio
                color="primary"
                checked={setStatus.value === FormCompletionStatus.COMPLETED}
              />
            }
            label={translateCompletionStatus(FormCompletionStatus.COMPLETED)}
            labelPlacement="top"
          />
        </RadioGroup>
      </FormControl>
    );
  }
}

const enhance = compose<PropsType, IncomingProps>(
  withStyles(statusSelectStyle),
);

export default enhance(StatusSelect);

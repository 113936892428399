import * as React from 'react';
import Modal from '@material-ui/core/Modal';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import modalWrapperStyle from './modalWrapperStyle';
import TemplateHeader from '@/app/components/TemplateComponents/admin/TemplateHeader/TemplateHeader';

class ModalWrapper extends React.PureComponent<any> {
  render() {
    const {
      languages,
      modalOpen,
      addProcessTemplate,
      handleModalClose,
    } = this.props;

    return (
      <div>
        <Modal open={modalOpen} onClose={() => handleModalClose()}>
          <TemplateHeader
            languages={languages}
            handleModalClose={handleModalClose}
            addTemplate={addProcessTemplate}
            accessFilter
          />
        </Modal>
      </div>
    );
  }
}

const enhance = compose<any, any>(withStyles(modalWrapperStyle));

export default enhance(ModalWrapper);

import { TimePicker } from 'material-ui-pickers';
import { TimePickerProps } from 'material-ui-pickers/TimePicker';
import React from 'react';
import LocalizationProvider from './LocalizationProvider/LocalizationProvider';

const LocalizedTimePicker = (props: TimePickerProps) => {
  return (
    <LocalizationProvider>
      <TimePicker {...props} />
    </LocalizationProvider>
  );
};

export default LocalizedTimePicker;

import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import translate from '@/app/utils/translate';
import Service from '@/app/utils/service';
import API from '@/app/api/internalAPIs';
import { INPUT_TYPE } from '@/app/utils/helper';
import FormFields, {
  InputType,
  ChildInputType,
} from '@/app/components/FormFields/FormFields';
import { throwError } from '@/app/redux/error';
import {
  getFormDialog,
  pushData,
} from '@/app/components/FormDialog/FormDialog';
import { withStyles } from '@material-ui/core/styles';
import { WithStyles } from '@material-ui/core';
import enumUpdateValueDialogStyle from './enumUpdateValueDialogStyle';
import { IEnumGroup } from '../EnumGroupList/enumGroup';
import { ValueListsEditableEnum, updateEnum } from '@/app/redux/enums';
import {
  UnitOrgTreeItem,
  findSubUnits,
  getCompanyCountries,
  getCompanyUnits,
  getCompanyUnitsByCodeArray,
} from '@/old/utils/helper';
import { selectCustomFieldInputTypes } from '@/app/redux/enums';
import { OptionType } from '../../Trainings/types';
import { FormDataType, changeFormData } from '@/app/redux/formData';

interface OwnProps {
  group: IEnumGroup;
  enum: ValueListsEditableEnum;
  title: string;
  open: boolean;
  onClose: () => void;
}

interface MapStateToProps {
  customDataTypes: ChildInputType[];
}

interface MapDispatchToProps {
  throwError: any;
  changeFormData: Function;
  updateEnum: (body: any, groupName: string, code: string) => void;
}

type PropsType = OwnProps &
  MapStateToProps &
  MapDispatchToProps &
  WithStyles<typeof enumUpdateValueDialogStyle>;

type StateType = {
  formData: ValueListsEditableEnum; // data for editing
  availableTags: Array<ChildInputType>;
  isDisableTagsSelect: boolean;
  orgTree: UnitOrgTreeItem[];
};

class EnumUpdateValueDialog extends React.Component<PropsType> {
  state: StateType = {
    formData: null,
    availableTags: [],
    isDisableTagsSelect: true,
    orgTree: [],
  };

  FORM_NAME: string;
  FormDialog: any;

  constructor(props: PropsType) {
    super(props);
    this.FORM_NAME = `enumUpdate`;
    this.FormDialog = getFormDialog(this.FORM_NAME, this.validate);
  }

  validate = (values: any) => {
    if (values.inputType && this.props.enum && this.props.enum.inputType) {
      if (
        (this.props.enum.inputType.value === 'ENUM' ||
          this.props.enum.inputType.value === 'MULTISELECT') &&
        this.props.enum.inputType.value !== values.inputType.value
      ) {
        return {
          inputType: translate.t(
            'Enumerated/Multiselect_type_cannot_be_modified_to_any_other_type',
          ),
        };
      }
    }
    if (values.inputType && this.props.enum && this.props.enum.inputType) {
      if (
        (values.inputType.value === 'ENUM' ||
          values.inputType.value === 'MULTISELECT') &&
        this.props.enum.inputType.value !== values.inputType.value
      ) {
        return {
          inputType: translate.t(
            'Cannot_be_modified_to_Enumerated_or_Multiselect_type',
          ),
        };
      }
    }

    let errors: { [k: string]: any } = {};
    const requiredFields = ['name'];

    requiredFields.forEach(field => {
      if (!values[field] || values[field] === '<p>&nbsp;</p>') {
        errors[field] = translate.t('laThisRequired');
      }
    });

    if (values.name && values.name.length > 254) {
      errors.name = `${translate.t('laErrorMaxTextLen1')} 254`;
    }

    if (values.extCode && values.extCode.length > 64) {
      errors.extCode = `${translate.t('laErrorMaxTextLen1')} 64`;
    }

    if (values.tags && values.tags.length > 10) {
      const showError = values.tags.find((tag: string) => tag.length > 132);

      errors.tags = showError
        ? translate.t('error_max_length_tag')
        : translate.t('error_max_length_tags');
    }

    return errors;
  };

  loadEnumValue = (e: ValueListsEditableEnum) => {
    this.setState({ formData: null });
    Service.get(
      API.enum.groupCode(e.groupName, e.code),
      (data: any) => {
        const tagsData = e.tags
          ? e.tags.map(tag => ({ label: tag, value: tag }))
          : null;

        const orgUnits = e.orgUnits
          ? e.orgUnits.map((u: any) => getCompanyUnitsByCodeArray(u))
          : [];

        const formData = {
          code: e.code,
          name: data.name,
          tags: tagsData,
          extCode: data.extCode || '',
          countries: e.countries || [],
          inputType: e.inputType,
          fCustomDataAttributeEditableByEmp: data.editableByEmp,
          orgUnits,
          includeSubUnits: false,
        };
        this.setState({ formData });
      },
      (error: any) => this.props.throwError(error),
    );
  };

  loadEnumAvailableTags = (groupName: string) => {
    Service.get(
      API.enum.groupTags(groupName),
      (data: any) => {
        this.setState({
          availableTags: data.map((d: any) => ({ label: d, value: d })),
          isDisableTagsSelect: false,
        });
      },
      (error: any) => this.props.throwError(error),
    );
  };

  componentDidMount() {
    Service.get(
      '/d/json/org/tree/1/unit',
      (response: any) => {
        const orgTreeData = response.map((respItem: any) => ({
          id: parseInt(respItem.fTreeId, 10),
          parentId:
            respItem.fTreeParentUnitId === '#'
              ? '#'
              : parseInt(respItem.fTreeParentUnitId, 10),
          unitId: parseInt(respItem.fTreeUnitId, 10),
          name: respItem.fTreeUnitName,
          unitType: respItem.fTreeUnitType,
        }));
        this.setState({ orgTree: orgTreeData });
      },
      (_e: any) => {
        console.error(translate.t('laFailedToLoad'));
        // TODO: Change to snackbar if possible
      },
    );
  }

  componentDidUpdate(prevProps: PropsType) {
    if (this.props.open !== prevProps.open && this.props.open === true) {
      this.loadEnumValue(this.props.enum);
      if (this.props.group.hasTags()) {
        this.loadEnumAvailableTags(this.props.enum.groupName);
      }
    }
  }

  addTagHandler = (inputValue: string) => {
    pushData({
      form: this.FORM_NAME,
      field: 'tags',
      value: { label: inputValue, value: inputValue },
    });
  };

  handleSubmit = (values: any) => {
    const payload = {
      code: values.code || '_',
      name: values.name,
      extCode: values.extCode || '',
      tags: values.tags ? values.tags.map((t: any) => t.value) : [],
      countries: values.countries
        ? values.countries.map((c: any) => c.value)
        : [],
      inputType: values.inputType ? values.inputType.value : '',
      fCustomDataAttributeEditableByEmp:
        values.fCustomDataAttributeEditableByEmp,
      orgUnits: values.orgUnits
        ? values.orgUnits.map((unit: any) => unit.value)
        : [],
    };

    const { group } = this.props;
    this.props.updateEnum(payload, group.name, payload.code);
    this.props.onClose();
  };

  changeOrgUnits = (e: OptionType[]) => {
    this.props.changeFormData({
      form: this.FORM_NAME,
      field: 'orgUnits',
      value: e,
    });
  };

  render() {
    const {
      group,
      title,
      open,
      onClose,
      classes,
      customDataTypes,
    } = this.props;
    const {
      formData,
      availableTags,
      isDisableTagsSelect,
      orgTree,
    } = this.state;
    const { FormDialog } = this;

    if (!group) {
      return null;
    }

    const unitOptions = getCompanyUnits().map((u: any) => ({
      value: u.id,
      label: u.name,
    })) as OptionType[];

    const getMatchingSubUnits = (
      units?: OptionType[],
      includeSubUnits = false,
    ) => {
      if (includeSubUnits || !units) {
        return findSubUnits(units, unitOptions, orgTree, includeSubUnits);
      }

      return units.map(unit => ({ ...unit, isFixed: false }));
    };

    let inputs: Array<InputType> = [];
    inputs.push({
      type: INPUT_TYPE.TEXT,
      code: 'name',
      name: 'name',
      label: translate.t('laTerm'),
      order: 1,
      required: true,
      disabled: !group.canEditName,
    });

    // enable external code, if needed
    if (group.hasExtCode) {
      inputs.push({
        type: INPUT_TYPE.TEXT,
        code: 'extCode',
        name: 'extCode',
        label: translate.t('laExternalCode'),
        order: 2,
        required: false,
      });
    }

    if (group.hasInputType && customDataTypes) {
      inputs.push({
        type: INPUT_TYPE.SELECT,
        code: 'inputType',
        name: 'inputType',
        label: translate.t('laType'),
        childrenInputs: customDataTypes,
        order: 2.1,
        required: true,
      });
    }

    if (group.hasTags()) {
      inputs.push({
        type: INPUT_TYPE.MULTISELECT,
        code: 'tags',
        name: 'tags',
        label: translate.t('label_tags'),
        childrenInputs: availableTags,
        order: 3,
        required: false,
        configs: {
          isSearchable: true,
          isDisabled: isDisableTagsSelect,
          isCreatable: true,
          onCreateOption: this.addTagHandler, // event for creating new option
        },
      });
    }

    if (group.isCountryBased()) {
      inputs.push({
        type: INPUT_TYPE.MULTISELECT,
        code: 'countries',
        name: 'countries',
        label: translate.t('laCountries'),
        childrenInputs: getCompanyCountries().map((c: any) => ({
          label: c.name,
          value: c.code,
        })),
        order: 4,
        required: false,
        configs: {
          isSearchable: true,
          isDisabled: false,
          isCreatable: false,
        },
      });
      if (!group.hasInputType) {
        inputs.push({
          type: INPUT_TYPE.MULTISELECT,
          code: 'orgUnits',
          name: 'orgUnits',
          label: translate.t('laOrgUnits'),
          childrenInputs: getCompanyUnits().map((u: any) => ({
            label: u.label,
            value: u.code,
          })),
          order: 5,
          configs: {
            isSearchable: true,
            isDisabled: false,
            isCreatable: false,
          },
        });
        inputs.push({
          type: INPUT_TYPE.CHECKBOX,
          code: 'includeSubUnits',
          name: 'includeSubUnits',
          label: translate.t('label_include_subunits'),
          order: 5.1,
          onChange: () => {
            this.changeOrgUnits(
              getMatchingSubUnits(formData.orgUnits, !formData.includeSubUnits),
            );
          },
        });
      }
    }
    if (
      group.hasInputType &&
      customDataTypes &&
      group.name === 'CUSTOM_ENTITY_EMPLOYEE'
    ) {
      inputs.push({
        type: INPUT_TYPE.CHECKBOX,
        code: 'fCustomDataAttributeEditableByEmp',
        name: 'fCustomDataAttributeEditableByEmp',
        label: translate.t('laCustomDataAttributeEditableByEmp'),
        order: 6,
        required: true,
      });
    }
    return (
      <FormDialog
        title={title}
        open={open}
        onClose={onClose}
        formData={formData}
        paperWidthMd={classes.paperWidthMd}
        onSubmit={this.handleSubmit}
        onChange={(values: any) => {
          this.setState({ formData: values });
        }}
      >
        <FormFields inputs={inputs} />
      </FormDialog>
    );
  }
}

const mapStateToProps = (state: any) => ({
  customDataTypes: selectCustomFieldInputTypes(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  throwError: (err: any) => dispatch(throwError(err)),
  changeFormData: (e: FormDataType) => changeFormData(dispatch, e),
  updateEnum: (body: any, groupName: string, code: string) =>
    dispatch<any>(updateEnum(body, groupName, code)),
});

const enhance = compose<any, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(enumUpdateValueDialogStyle, { withTheme: true }),
);

export default enhance(EnumUpdateValueDialog);

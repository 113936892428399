import { TextField, withStyles, WithStyles } from '@material-ui/core';
import React, { ChangeEvent, memo } from 'react';

import selectStyle from './selectStyle';

type OwnProps = {
  children: React.JSX.Element[];
  error?: unknown;
  disabled?: boolean;
  helperText?: string;
  label: string;
  name: string;
  onChange: (ev: ChangeEvent<HTMLSelectElement>) => void;
  required: boolean;
  value: string;
};
type PropsType = OwnProps & WithStyles<typeof selectStyle>;

const Select = (props: PropsType) => {
  const {
    classes,
    children,
    disabled = false,
    error,
    helperText,
    label,
    name,
    onChange,
    required,
    value,
  } = props;

  return (
    <TextField
      disabled={disabled}
      select
      label={label}
      onChange={onChange}
      value={value}
      name={name}
      className={classes.selectField}
      required={required}
      error={!!error}
      helperText={helperText}
    >
      {children}
    </TextField>
  );
};

export default memo(withStyles(selectStyle)(Select));

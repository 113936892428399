import { createStyles, Theme } from '@material-ui/core';

export const commentsListItemStyles = (theme: Theme) => createStyles({
    commentText: {
        wordBreak: 'break-all',
    },
    date: {
        marginRight: theme.spacing.unit,
    },
});

import React, { useContext, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  WithStyles,
  withStyles,
  Icon,
} from '@material-ui/core';

import translate from '@/app/utils/translate';

import Context from '../../../Trainings/context/trainingsContext';
import { formatTrainingDates } from '../../../Trainings/helpers';
import {
  CompanyTraining,
  CompanyTrainingParticipantRegistration,
} from '../../../Trainings/types';
import ParticipantsList from './ParticipantsList';
import { styles } from '../../../Trainings/components/participantsDialog/';
import { ParticipantsExportDialog } from '@/app/components/Trainings/components';

export type FormData = {
  participants: (CompanyTrainingParticipantRegistration & {
    addToDr?: boolean;
  })[];
};

type OwnProps = {
  open: boolean;
  training: CompanyTraining;
  onClose: () => void;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const ParticipantsDialog = ({ classes, open, training, onClose }: Props) => {
  const {
    state: {
      participants: { data: participants, loading },
    },
    getParticipants,
  } = useContext(Context);
  const [openExportDialog, setOpenExportDialog] = useState(false);

  useEffect(() => {
    getParticipants(training);
  }, []);

  const handleCancel = () => {
    onClose();
  };

  if (!open || loading || !participants) {
    return null;
  }

  const exportDialog = !openExportDialog ? null : (
    <ParticipantsExportDialog
      open={openExportDialog}
      training={training}
      participants={participants}
      onClose={() => setOpenExportDialog(false)}
    />
  );

  return (
    <>
      {exportDialog}
      <Dialog
        open={open}
        disableEscapeKeyDown={true}
        fullWidth={true}
        maxWidth="xl"
      >
        <DialogTitle>
          <div className={classes.flex}>
            <Typography className={classes.grow} variant="h6">
              {translate.t('label_participants')}:&nbsp;
              {training.name.label}
              {formatTrainingDates(training.startDate, training.endDate)}
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <ParticipantsList training={training} participants={participants} />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="text"
            disabled={!participants || participants.length === 0}
            onClick={() => setOpenExportDialog(true)}
          >
            <Icon className={classes.titleButtonIcon}>cloud_download</Icon>
            {translate.t('laExport')}
          </Button>
          <div className={classes.grow} />
          <Button
            data-testid="btn-training-participants-dialog-cancel"
            color="primary"
            onClick={handleCancel}
          >
            {translate.t('laClose')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(ParticipantsDialog);

const GLOBAL: any = window;

export default (unitId: number) => {
  if (!GLOBAL.jsonOrgUnitData) {
    return '';
  }

  const unit = GLOBAL.jsonOrgUnitData.find((item: any) => item.fOrgUnitId === unitId);
  if (!unit) {
    return undefined;
  }

  return unit.fOrgUnitName;
};

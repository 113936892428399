import { GridCellProps } from '@progress/kendo-react-grid';
import { IconButton } from '@material-ui/core';
import { AttachFile } from '@material-ui/icons';
import * as React from 'react';

import API from '@/app/api/internalAPIs';
import { downloadAttachment } from '@/old/utils/helper';

export const AttachmentCell: React.FC<GridCellProps> = ({
    rowType,
    dataItem,
}) => {
    const handleDownloadAttachment = React.useCallback(
        () => {
            downloadAttachment(
                API.collaboration.attachment(
                    dataItem.id,
                    dataItem.attachmentId,
                )
            );
        },
        [dataItem]
    );

    if (rowType !== 'data') {
        return null;
    }

    if (dataItem.attachmentId === -1) {
        return <td />;
    }

    return (
        <td style={{ textAlign: 'center' }}>
            <IconButton onClick={handleDownloadAttachment}>
                <AttachFile fontSize="small" />
            </IconButton>
        </td>
    );
};
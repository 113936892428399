import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const styles: StyleRulesCallback = (theme: Theme) => {
  return ({
    root: {
      padding: `${theme.spacing.unit * 2}px 0`,
      textAlign: 'center',
      minWidth: '100%',
      minHeight: '100%',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
    },
    onClickPointer: {
      cursor: 'pointer',
    },
    value: {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
    title: {
      padding: `0 ${theme.spacing.unit * 2}px`
    },
    button: {
      width: '100%',
      height: '100%',
      display: 'block',
    }
  });
};

export default styles;

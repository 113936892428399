import * as React from 'react';
import * as moment from 'moment';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import { compose } from 'recompose';

import LocalizedDatePicker from '@/app/components/Pickers/LocalizedDatePicker';
import translate from '@/app/utils/translate';
import styles from './styles';

type OutterProps = {
  sendTitle: string;
  printTitle: string;
  show: boolean;
  plan: any;
  formData: any;
  programs: any[];
  onFieldChange: (name: string, value: any) => void;
  onSend: (evt: React.SyntheticEvent) => void;
  onClose: (evt: React.SyntheticEvent) => void;
  onPrint: (evt: React.SyntheticEvent) => void;
  disabled: boolean;
};

type InnerProps = OutterProps & WithStyles<typeof styles>;

const BonusCalcSendToProcessDialog: React.FunctionComponent<InnerProps> = (props) => {
  const {
    classes,
    plan,
    programs,
    sendTitle,
    show,
    onSend,
    onClose,
    onPrint,
    printTitle,
    disabled,
  } = props;

  const handleChange = (name: string) => (ev: any) => {
    props.onFieldChange(name, ev.target.value);
  };

  const handleDateRangeChange = (value: moment.Moment) => {
    props.onFieldChange('paymentDate', value);
  };

  const renderForm = () => {
    const { paymentPeriod = '' } = props.formData;
    const minDate = '1910-01-01';

    return (
      <Paper
        id="sendToProcessFooter"
        className={classes.formPaper}
        elevation={1}
      >
        <Grid container spacing={24}>
          <Grid xs={12} sm={4} md={2} item>
            <LocalizedDatePicker
              className={classes.picker}
              clearable
              label={translate.t('laBonusDate')}
              value={props.formData.paymentDate}
              onChange={(date: moment.Moment) => handleDateRangeChange(date)}
              minDate={minDate}
              required
            />
          </Grid>
          <Grid xs={12} sm={8} md={4} item>
            <TextField
              margin="dense"
              id="paymentPeriod"
              label={translate.t('laPayPeriod')}
              type="text"
              value={paymentPeriod}
              fullWidth
              onChange={handleChange('paymentPeriod')}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  };

  const prepareSummaryData = () => {
    const selectedIds: number[] = plan.selectedIds;
    const summary: any = {
      rows: [],
      totals: {},
    };
    let currEmp: any = null;
    const usedProgIds: number[] = Array.from(new Set(plan.rows.map((row: any) => row.programId)));
    plan.rows
      .filter((r: any) => r.sum > 0)
      .sort((a: any, b: any) => a.id - b.id).forEach((row: any) => {
        if (selectedIds.indexOf(row.id) > -1) {
          // Push new employee, if changed
          if (!currEmp || currEmp.id !== row.employeeId) {
            currEmp = Object.assign({}, {
              id: row.employeeId,
              name: row.employee,
              total: 0,
              currency: row.currency,
            });
            summary.rows.push(currEmp);
          }
          // Make sure this total entry exists (currency/program)
          if (!summary.totals[currEmp.currency]) {
            summary.totals[currEmp.currency] = {
              total: 0,
            };
            usedProgIds.forEach((progId: number) => {
              summary.totals[currEmp.currency][`program_${progId}`] = 0;
            });
          }
          // Calculate row
          currEmp[`program_${row.programId}`] = row.sum;
          // Add totals
          currEmp.total += row.sum;
          summary.totals[currEmp.currency][`program_${row.programId}`] += row.sum;
          summary.totals[currEmp.currency].total += row.sum;
        }
      });

    return summary;
  };

  const renderSummaryTable = () => {
    const summaryData: any = prepareSummaryData();
    const progs = Object.values(summaryData.totals)[0];
    const progKeys = Object.keys(progs);

    const activePrograms = programs.filter(program => (
      progKeys.includes(`program_${program.id}`)
    ));

    return (
      <Table className={classes.table} id="processTable">
        <TableHead>
          <TableRow>
            <TableCell>{translate.t('laEmployee')}</TableCell>
            {activePrograms.map((program: any) => (
              <TableCell align="right" key={program.id}>{program.name}</TableCell>
            ))}
            <TableCell align="right">{translate.t('sum_to_paid')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {summaryData.rows.map((emp: any) => (
            <TableRow key={emp.id}>
              <TableCell className={classes.employeeCell}>{emp.name}</TableCell>
              {activePrograms.map((program: any) => (
                <TableCell align="right" key={`${emp.id}-${program.id}`}>
                  {!emp[`program_${program.id}`] ? '' : emp[`program_${program.id}`] + ` ${emp.currency}`}
                </TableCell>
              ))}
              <TableCell align="right">
                <Typography variant="subtitle2">
                  {emp.total === 0 ? '' : `${emp.total.toFixed(2)} ${emp.currency}`}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          {Object.keys(summaryData.totals).map((currencyKey: string) => (
            <TableRow className={classes.footerRow} key={currencyKey}>
              <TableCell align="right">
                <Typography variant="subtitle2">
                  {translate.t('laTotal')} {currencyKey}
                </Typography>
              </TableCell>
              {activePrograms.map((program: any) => (
                <TableCell align="right" key={`total_program_${program.id}`}>
                  <Typography variant="subtitle2">
                    {summaryData.totals[currencyKey][`program_${program.id}`] ?
                      summaryData.totals[currencyKey][`program_${program.id}`].toFixed(2) + ' ' + currencyKey : ''}
                  </Typography>
                </TableCell>
              ))}
              <TableCell align="right">
                <Typography variant="subtitle2">
                  {summaryData.totals[currencyKey].total.toFixed(2)} {currencyKey}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableFooter>
      </Table>
    );
  };

  return (
    <Dialog
      open={show}
      disableBackdropClick
      onEscapeKeyDown={onClose}
      fullScreen
      color="primary"
      scroll="body"
    >
      <DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6">
            {translate.t('button_send_to_process')}
          </Typography>
          <Button variant="contained" color="primary" onClick={onPrint}>{printTitle}</Button>
        </div>
      </DialogTitle>
      <DialogContent id="sendToProcessContent">
        {renderSummaryTable()}
      </DialogContent>
      <DialogActions>
        {renderForm()}
        <Button variant="text" component="a" color="primary" onClick={onClose}>{translate.t('laCancel')}</Button>
        <Button variant="contained" color="primary" disabled={disabled} onClick={onSend}>{sendTitle}</Button>
      </DialogActions>
    </Dialog>
  );
};

const enhance = compose<InnerProps, OutterProps>(
  withStyles(styles),
);

export default enhance(BonusCalcSendToProcessDialog);

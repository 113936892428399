import { withStyles } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { compose } from 'recompose';

import {
    OpenedReportSettingsControls as OpenedReportSettingsControlsComponent,
    OpenedReportControlsInnerProps,
    OpenedReportControlsOuterProps
} from './component';
import { styles } from './styles';

export const OpenedReportSettingsControls = compose<OpenedReportControlsInnerProps, OpenedReportControlsOuterProps>(
    withSnackbar,
    withStyles(styles)
)(OpenedReportSettingsControlsComponent);

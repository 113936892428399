import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const sortableComponentStyle: StyleRulesCallback = (theme: Theme) => {

  return ({
    root: {
      marginRight: 2 * theme.spacing.unit
    }
  });
};

export default sortableComponentStyle;

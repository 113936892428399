import {
  WithStyles,
  Paper,
  withStyles,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import * as React from 'react';

import ConfigureUiLabelButton from '@/app/components/ConfigurableLabels/ConfigureUiLabelButton/ConfigureUiLabelButton';
import translate from '@/app/utils/translate';

import { absenceCustomLabelsStyles } from './absenceCustomLabelsStyles';
import { fetchAllEnums } from '@/app/redux/enums';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

type MapDispatchToProps = {
  fetchAllEnums: () => void;
};

type PropsType = MapDispatchToProps &
  WithStyles<typeof absenceCustomLabelsStyles>;

const AbsenceCustomLabelsComponent: React.FC<PropsType> = ({
  classes,
  fetchAllEnums: handleUpdateEnum,
}) => {
  return (
    <Paper className={classes.root}>
      <List>
        <ListItem>
          <ListItemText
            primary={translate.t('laAbsenceHours')}
            secondary={
              <ConfigureUiLabelButton
                term={'laAbsenceHours'}
                fetchAllEnums={handleUpdateEnum}
              />
            }
          />
        </ListItem>

        <ListItem>
          <ListItemText
            primary={translate.t('laPlannedWorkingHours')}
            secondary={
              <ConfigureUiLabelButton
                term={'laPlannedWorkingHours'}
                fetchAllEnums={handleUpdateEnum}
              />
            }
          />
        </ListItem>
      </List>
    </Paper>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAllEnums: () => dispatch<any>(fetchAllEnums()),
});

export const AbsenceCustomLabels = compose<any, any>(
  connect(mapDispatchToProps),
  withStyles(absenceCustomLabelsStyles),
)(AbsenceCustomLabelsComponent);

import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const componentListingStyles: StyleRulesCallback = () => ({
  table: {
    '& th, & td': {
      paddingLeft: '5px',
      paddingRight: '5px',
    },
    '& tbody td': {
      borderWidth: 0,
    },
  },
  actionsContainer: {
    marginBottom: '10px',
    textAlign: 'right',
  },
  button: {
    minHeight: '35px',
    textAlign: 'left',
    fontSize: '0.9em !important'
  },
  delimiter: {
    borderBottom: '1px dashed #CCCCCC',
    marginTop: '20px',
    marginBottom: '20px',
  },
});

export default componentListingStyles;
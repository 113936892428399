import { getEmployeeInfoById, getEmployeeName } from '@/old/utils/helper';
import {
  ReportType,
  VacationBalanceReportResponseItem,
  VacationDetails,
} from './types';

export const mapReportForYearlyBalance = (
  responseItems: VacationBalanceReportResponseItem[],
) => {
  const reportEntry: ReportType[] = [];
  responseItems.map((item: VacationBalanceReportResponseItem) => {
    item.fEmpVacationDetails.map((ele: VacationDetails) => {
      reportEntry.push({
        empId: item.fEmpId,
        firstName: item.fEmpFirstName,
        lastName: item.fEmpLastName,
        empNumber: item.fEmpNum,
        unitName: item.fEmpUnit,
        unitCode: item.fEmpUnitNumber,
        costCenterName: item.fEmpCostCenterName,
        costCenterCode: item.fEmpCostCenterCode,
        legalEntityName: item.fEmpLegalEntityName,
        legalEntityCode: item.fEmpLegalEntityCode,
        manager: getEmployeeName(item.fEmpManagerId),
        managerNumber: getEmployeeInfoById(item.fEmpManagerId).fEmpPersonNumber,
        empHasAccount: item.fEmpHasAccount,
        vacationBalanceYear: ele.fEmpVacationBalanceYear,
        vacationBalance: ele.fEmpVacationBalance,
        vacationBalanceApproved: ele.fEmpVacationBalanceApproved,
        vacationBalanceInProgress: ele.fEmpVacationBalanceInProgress,
        vacationBalanceRemainingDays: ele.fEmpRemainingVacationBalance,
        extraBalance: ele.fEmpVacationBalanceExtra,
        extraBalanceApproved: ele.fEmpVacationBalanceExtraApproved,
        extraBalanceInProgress: ele.fEmpVacationBalanceExtraInProgress,
        supplement1: ele.fEmpVacationBalanceSuppl1,
        supplement1Spent: ele.fEmpVacationBalanceSuppl1Spent,
        supplement2: ele.fEmpVacationBalanceSuppl2,
        supplement2Spent: ele.fEmpVacationBalanceSuppl2Spent,
        vacationBalanceBank1: ele.fEmpVacationBalanceBank1,
        vacationBalanceBank1Approved: ele.fEmpVacationBalanceBank1Approved,
        vacationBalanceBank1InProgress: ele.fEmpVacationBalanceBank1InProgress,
        vacationBalanceBank2: ele.fEmpVacationBalanceBank2,
        vacationBalanceBank2Approved: ele.fEmpVacationBalanceBank2Approved,
        vacationBalanceBank2InProgress: ele.fEmpVacationBalanceBank2InProgress,
        vacationBalanceBank3: ele.fEmpVacationBalanceBank3,
        vacationBalanceBank3Approved: ele.fEmpVacationBalanceBank3Approved,
        vacationBalanceBank3InProgress: ele.fEmpVacationBalanceBank3InProgress,
      });
    });
  });
  return reportEntry;
};

export const mapReportForlatestYear = (
  responseItems: VacationBalanceReportResponseItem[],
) => {
  const reportEntry: ReportType[] = [];
  responseItems.forEach((item: VacationBalanceReportResponseItem) => {
    const itemVacationDetails = item.fEmpVacationDetails[0];
    reportEntry.push({
      empId: item.fEmpId,
      empNumber: item.fEmpNum,
      firstName: item.fEmpFirstName,
      lastName: item.fEmpLastName,
      unitName: item.fEmpUnit,
      unitCode: item.fEmpUnitNumber,
      costCenterName: item.fEmpCostCenterName,
      costCenterCode: item.fEmpCostCenterCode,
      legalEntityName: item.fEmpLegalEntityName,
      legalEntityCode: item.fEmpLegalEntityCode,
      manager: getEmployeeName(item.fEmpManagerId),
      managerNumber: getEmployeeInfoById(item.fEmpManagerId).fEmpPersonNumber,
      empHasAccount: item.fEmpHasAccount,
      vacationBalanceYear: itemVacationDetails.fEmpVacationBalanceYear,
      vacationBalance: itemVacationDetails.fEmpVacationBalance,
      vacationBalanceApproved: itemVacationDetails.fEmpVacationBalanceApproved,
      vacationBalanceInProgress:
        itemVacationDetails.fEmpVacationBalanceInProgress,
      vacationBalanceRemainingDays:
        itemVacationDetails.fEmpRemainingVacationBalance,
      extraBalance: itemVacationDetails.fEmpVacationBalanceExtra,
      extraBalanceApproved:
        itemVacationDetails.fEmpVacationBalanceExtraApproved,
      extraBalanceInProgress:
        itemVacationDetails.fEmpVacationBalanceExtraInProgress,
      supplement1: itemVacationDetails.fEmpVacationBalanceSuppl1,
      supplement1Spent: itemVacationDetails.fEmpVacationBalanceSuppl1Spent,
      supplement2: itemVacationDetails.fEmpVacationBalanceSuppl2,
      supplement2Spent: itemVacationDetails.fEmpVacationBalanceSuppl2Spent,
      vacationBalanceBank1: itemVacationDetails.fEmpVacationBalanceBank1,
      vacationBalanceBank1Approved:
        itemVacationDetails.fEmpVacationBalanceBank1Approved,
      vacationBalanceBank1InProgress:
        itemVacationDetails.fEmpVacationBalanceBank1InProgress,
      vacationBalanceBank2: itemVacationDetails.fEmpVacationBalanceBank2,
      vacationBalanceBank2Approved:
        itemVacationDetails.fEmpVacationBalanceBank2Approved,
      vacationBalanceBank2InProgress:
        itemVacationDetails.fEmpVacationBalanceBank2InProgress,
      vacationBalanceBank3: itemVacationDetails.fEmpVacationBalanceBank3,
      vacationBalanceBank3Approved:
        itemVacationDetails.fEmpVacationBalanceBank3Approved,
      vacationBalanceBank3InProgress:
        itemVacationDetails.fEmpVacationBalanceBank3InProgress,
    });
  });
  return reportEntry;
};

import { Typography } from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Dispatch } from 'redux';

import Loading from '@/app/components/Loading/Loading';
import {
  fetchAllCommonGoals,
  fetchAllTemplatesForUser,
  fetchAllEvaluations,
  setActiveTemplate,
} from '@/app/redux/goalEvaluations';
import { ReducerState } from '@/app/redux/store';
import translate from '@/app/utils/translate';

import TemplateSelection from './TemplateSelection/TemplateSelection';
import { getSelectedEmpId } from '@/old/utils/helper';

type IncomingProps = {
  employeeId: number;
};

type MapStateToProps = {
  allTemplates: any[];
  allEvaluations: any[];
};

type MapDispatchToProps = {
  fetchAllCommonGoals: () => Promise<void>;
  fetchAllTemplates: () => Promise<void>;
  fetchAllEvaluations: (employeeId: number) => Promise<void>;
  setActiveTemplate: (template: any) => Promise<void>;
};

type PropsType = IncomingProps & MapStateToProps & MapDispatchToProps;

type StateType = {
  isLoading: boolean;
};

class GoalEvaluationsContainer extends React.PureComponent<
  PropsType,
  StateType
> {
  state: StateType = {
    isLoading: false,
  };

  updateGoalEvaluationTemplate = () =>
    Promise.all([
      this.props.fetchAllCommonGoals(),
      this.props.fetchAllTemplates(),
      this.props.fetchAllEvaluations(this.props.employeeId),
    ])
      .then(() => {
        const { allEvaluations, allTemplates } = this.props;
        const activeTemplate = allTemplates.find(
          (template: any) => template.status === 'ACTIVE',
        );
        if (activeTemplate) {
          const activeEvaluation = allEvaluations.find(
            (evaluation: any) =>
              evaluation.templateId === activeTemplate.id &&
              evaluation.employeeId === this.props.employeeId,
          );
          this.props.setActiveTemplate({
            ...activeTemplate,
            approvedBy: (activeEvaluation && activeEvaluation.approvedBy) || [],
          });
        }
      })
      .then(() => {
        this.setState({ isLoading: false });
      });

  componentDidMount() {
    this.setState({ isLoading: true });
    this.updateGoalEvaluationTemplate();
  }

  componentDidUpdate(prevProps: PropsType) {
    if (this.props.employeeId !== prevProps.employeeId) {
      this.updateGoalEvaluationTemplate();
    }
  }

  render() {
    return (
      <>
        {(this.state.isLoading && <Loading key="loading" />) ||
          (this.props.allTemplates.length && (
            <div>
              <TemplateSelection
                employeeId={this.props.employeeId}
                templates={this.props.allTemplates}
                evaluations={this.props.allEvaluations}
              />
            </div>
          )) || (
            <Typography variant="h6">{translate.t('laNoResults')}</Typography>
          )}
      </>
    );
  }
}

const mapStateToProps = (state: ReducerState) => {
  return {
    allTemplates: state.goalEvaluations.get('allTemplates'),
    allEvaluations: state.goalEvaluations.get('allEvaluations'),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAllCommonGoals: () => dispatch<any>(fetchAllCommonGoals()),
  fetchAllTemplates: () => dispatch<any>(fetchAllTemplatesForUser(getSelectedEmpId())),
  fetchAllEvaluations: (employeeId: number) =>
    dispatch<any>(fetchAllEvaluations(employeeId)),
  setActiveTemplate: (template: any) =>
    dispatch<any>(setActiveTemplate(template)),
});

const enhance = compose<any, any>(connect(mapStateToProps, mapDispatchToProps));

export default enhance(GoalEvaluationsContainer);

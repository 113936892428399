type ReportIndividualEmployeeEvaluationResponse = {
  componentId: string;
  id: number;
  response: any;
  type: string;
};

type ReportIndividualEmployeeEvaluationData = {
  approvedBy: number[];
  archived: boolean;
  employeeId: number;
  id: string;
  respondentId: number;
  responses: ReportIndividualEmployeeEvaluationResponse[]
  status: string;
  templateId: string;
  updatedBy: number;
  updatedTime: string;
};

type ReportEvaluationEmployeeData = {
  employee: {
    employeeNumber: string;
    firstName: string;
    hasAccount: boolean;
    id: number;
    lastName: string;
  };
  evaluations: ReportIndividualEmployeeEvaluationData[];
};

export type ReportEvaluationType = {
  id: number;
  unitName: string;
  employees?: ReportEvaluationEmployeeData[];
};

export interface ITemplateReport {
  unitId: number;
  unitName: string;
  empId: string;
  empNumber: string;
  hasAccount: boolean;
  statusCode: string;
  templateId: string;
}

export interface ITemplateReportData extends ITemplateReport {
  name: string;
  position: string;
  evalItems?: any;
  selectedTemplateFields?: any;
  template?: any;
}

export interface ITemplateStatusReportData extends ITemplateReport {
  firstName: string;
  lastName: string;
  updatedTime: string;
  approvedBy: string;
  updatedBy: string;
  archived: string;
  status?: string;
  subject?: string;
}

export enum EvaluationStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export type TemplateFieldSelection = {
  [sectionId: string]: { selectedFields: string[] };
};

export type EvaluationStatusDistribution = {
  inProgress: number[],
  completed: number[],
  notStarted: number[],
};

export interface IUnitGridData extends EvaluationStatusDistribution {
  unitId?: number;
  unitName?: string;
}

export interface IUnitGrid extends Omit<IUnitGridData, 'completed' | 'inProgress' | 'notStarted'> {
  completed?: number;
  inProgress?: number;
  notStarted?: number;
  subjectButton?: any;
}

export interface ITemplate {
  id: number;
  subject: string;
  status: string;
  deadline: Date;
  stages?: any[];
}

export interface ITempGrid extends Partial<ITemplate> {
  subjectButton?: any;
}

export interface ITemplateFull extends ITemplate {
  audience: {
    countryAudience: string[];
    roleAudience: string[];
    unitAudience: number[];
  };
  createdTime: string;
  defaultLang: string;
  language: string;
  sections: any[];
  updatedBy: {
    id: number;
    username: string;
    employeeId: number;
  };
  updatedTime: string;
}

export enum TemplateStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

type GoalV2 = {
  actual: string;
  componentId: string;
  endDate: string;
  evaluationId: string;
  goal: string;
  id: string;
  info: string;
  rangeMax: string;
  rangeMin: string;
  respondentId: number;
  result: number;
  sectionId: string;
  startDate: string;
  status: string;
  successMeasure: string;
  target: string;
  weight: number;
  _type: string;
};

export type GoalsEvaluation = {
  approvedBy: number[];
  employeeId: number;
  goals: GoalV2[];
  id: string;
  isArchived: boolean;
  isLatest: boolean;
  templateId: string;
  createdBy: number;
  createdTime: string;
  updatedBy: number;
  updatedTime: string;
};

export type EmpUnitEval = {
  employeeDetails: EmpData;
  evaluations: GoalsEvaluation[];
};

type EntityDetails = {
  id: number;
  name?: string;
  code?: string;
  number?: string;
};

export type SummaryEmpData = {
  empId: number;
  firstName: string;
  lastName: string;
  empNumber: string;
  employmentId: number;
  startDate: number;
  orgUnitDetails: EntityDetails;
  managerId: number;
  managerEmpNumber?: string;
  managerFirstName?: string;
  managerLastName?: string;
  userDetails: { roles?: string[]; hasAccount: boolean };
};

export type EmpData = SummaryEmpData & {
  personnelGroups: string[];
  officeCountry: string;
  birthdate: number;
  legalEntityDetails: EntityDetails;
  costCenter: {
    id: number;
    code: string;
    name: string;
  };
  position: {
    code: string;
    group: string;
  };
  internalPositionId: number;
};

export type GenericEvaluationItem = {
  id: string;
  templateId: string;
  employeeId: number;
  approvedBy: number[];
  status?: EvaluationStatus;
  isArchived?: boolean;
  archived?: boolean;
  updatedBy: number;
  updatedTime: string;
};

export type GenericEmployeeEvaluation = {
  employeeDetails: SummaryEmpData;
  evaluations: GenericEvaluationItem[];
};

import * as React from 'react';
import moment from 'moment';
import { compose } from 'recompose';
import templateSelectionStyles from './templateSelectionStyles';
import { withStyles } from '@material-ui/core/styles';
import {
  WithStyles,
  Paper,
  TextField,
  Select,
  Tooltip,
  IconButton,
  Button,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Modal from '@material-ui/core/Modal';
import { getEmployeeName, getLoggedUserId } from '@/old/utils/helper';
import { printElements, saveElementsAsPDF } from '@/app/utils/helper';
import translate from '@/app/utils/translate';
import {
  getDefault,
  getLatestArchiveOfEvaluations,
  orderElementsByIndex,
  translateCompletionStatus,
} from '@/app/components/TemplateComponents/helpers';
import SaveChanges from './EditTemplate/SectionListing/ComponentListing/SaveChanges';
import Loading from '@/app/components/Loading/Loading';
import PrintIcon from '@material-ui/icons/Print';
import { ReducerState } from '@/app/redux/store';
import { connect } from 'react-redux';
import SectionListing from '@/app/components/TemplateComponents/form/FormComponents/SectionListing/SectionListing';
import { FormTemplate } from '@/app/components/TemplateComponents/types';

type MapStateToProps = {
  appraisalEvaluation: any;
};

type incomingProps = {
  templates: FormTemplate[];
  archivedEvaluations: [];
  progressStatus: string;
  deadline: Date;
  lastModified: Date;
  approved: string;
  activeTemplate: any;
  updateEvaluation: any;
  openConfirmDialog: any;
  clearEvaluation: any;
  handleEvaluationsSave: any;
  handleTemplateChange: any;
  changeEvaluationApproval: any;
  removeEvaluationApproval: any;
  archiveEvaluation: any;
  setActiveTemplate: any;
  employeeId: number;
  notStartedStatus: boolean;
  changeEvaluationStage: any;
};

type PropsType = MapStateToProps &
  incomingProps &
  WithStyles<typeof templateSelectionStyles>;

const GLOBAL: any = window;

class TemplateSelection extends React.PureComponent<PropsType> {
  state = {
    editMode: false,
    saveModalOpen: false,
    isLoading: false,
    open: false,
    selectedOptionValue: '',
    selectedOptionText: '',
  };

  toggleEditMode = () => {
    this.setState({ editMode: !this.state.editMode });
  };

  cancelEvaluation = () => {
    this.props.openConfirmDialog({
      text: translate.t('text_unsaved_changes'),
      onOk: () => {
        this.props.clearEvaluation();
        this.props.setActiveTemplate(
          this.props.activeTemplate,
          this.props.employeeId,
        );
        this.setState({ editMode: !this.state.editMode });
      },
    });
  };

  isEditDisabled = () => {
    const { activeTemplate } = this.props;

    if (
      !activeTemplate.sections.some((section: any) => section.components.length)
    ) {
      return true;
    }

    if (
      moment(activeTemplate.deadline)
        .endOf('day')
        .isBefore(new Date())
    ) {
      return true;
    }

    if (activeTemplate.archived) {
      return true;
    }

    if (!activeTemplate.isTeam) {
      return false;
    }

    if (!(GLOBAL.iHR || GLOBAL.iManager || GLOBAL.iDManager)) {
      return true;
    }

    if (
      activeTemplate.responses &&
      getLoggedUserId() !== activeTemplate.updatedBy
    ) {
      return true;
    }

    return false;
  };

  handleSaveModalClose = () => {
    this.setState({ saveModalOpen: false });
  };

  openSaveChangesModal = () => {
    this.setState({ saveModalOpen: true });
  };

  onTemplateChange = (evalId: string) => {
    this.setState({ isLoading: true });
    this.props.handleTemplateChange(evalId).then(() => {
      this.setState({ isLoading: false });
    });
  };

  printEvaluation = (elem: string) => {
    let node = document.getElementById(elem);
    printElements.print([node]);
  };

  saveAsPDF = (elem: string, docName: string) => {
    let node = document.getElementById(elem);
    saveElementsAsPDF.saveAsPDF([node], docName);
  };

  handleChangeApproval(activeTemplate: any) {
    const { changeEvaluationApproval, removeEvaluationApproval } = this.props;
    const currentUserName = getEmployeeName(getLoggedUserId(), true);
    if (
      activeTemplate.approvedBy &&
      activeTemplate.approvedBy.includes(currentUserName)
    ) {
      removeEvaluationApproval(
        activeTemplate.currentEvaluationId,
        currentUserName,
      );
    } else {
      changeEvaluationApproval(
        activeTemplate.currentEvaluationId,
        currentUserName,
      );
    }
  }

  componentDidMount() {
    const { activeTemplate } = this.props;
    const defaultOption = activeTemplate;
    const defaultValue = defaultOption.id;
    const defaultText = defaultOption.subject;
    this.setState({
      selectedOptionValue: defaultValue,
      selectedOptionText: defaultText,
    });
  }

  onChangeSelect = (event: any) => {
    const selectedOptionValue = event.target.value;
    const selectedOptionText =
      event.target.options[event.target.selectedIndex].text;
    this.setState({ selectedOptionValue, selectedOptionText });
    this.onTemplateChange(event.target.value);
  };

  render() {
    const {
      classes,
      templates,
      archivedEvaluations,
      activeTemplate,
      archiveEvaluation,
      updateEvaluation,
      clearEvaluation,
      employeeId,
      notStartedStatus,
      handleEvaluationsSave,
      changeEvaluationStage,
      appraisalEvaluation,
    } = this.props;

    const { editMode, saveModalOpen, isLoading } = this.state;
    const editIsDisabled = this.isEditDisabled();
    const orgUnitId = GLOBAL.jsonEmpData
      ? GLOBAL.jsonEmpData.hasOwnProperty('empPosition')
        ? GLOBAL.jsonEmpData.empPosition.fInternalPositionUnit
        : GLOBAL.jsonEmpData.empPositionPending.fInternalPositionUnit
      : -1;
    const currentUserName = getEmployeeName(getLoggedUserId(), true);
    const orderedSections = orderElementsByIndex(activeTemplate.sections);
    const latestArchives = getLatestArchiveOfEvaluations(archivedEvaluations);
    const activeStage = activeTemplate.setStage
      ? activeTemplate.stages.filter(
          (stage: any) => stage.id === activeTemplate.setStage.id,
        )[0]
      : getDefault(activeTemplate.stages);

    return (
      <Paper
        className={
          editMode ? [classes.root, 'cssEditMode'].join(' ') : classes.root
        }
        id="evaluationToPrint"
      >
        <form>
          <div>
            <Select
              native
              onChange={this.onChangeSelect}
              className={`${classes.select} ${
                editMode ? classes.selectDisabled : classes.selectEnabled
              }`}
              inputProps={{
                classes: {
                  icon: editMode ? classes.iconDisabled : classes.icon,
                  root: editMode ? classes.inputDisabled : classes.inputColor,
                },
              }}
              disabled={editMode}
            >
              <optgroup label={translate.t('laActive')}>
                {templates.map(
                  (templateOption: any) =>
                    templateOption.status === 'ACTIVE' &&
                    moment(templateOption.deadline)
                      .endOf('day')
                      .isAfter(new Date()) && (
                      <option
                        className={classes.option}
                        key={templateOption.id}
                        value={templateOption.id}
                      >
                        {templateOption.subject}
                      </option>
                    ),
                )}
              </optgroup>
              <optgroup label={translate.t('laLocked')}>
                {templates.map(
                  (templateOption: any) =>
                    templateOption.status === 'ACTIVE' &&
                    moment(templateOption.deadline)
                      .endOf('day')
                      .isBefore(new Date()) && (
                      <option
                        className={classes.option}
                        key={templateOption.id}
                        value={templateOption.id}
                      >
                        {templateOption.subject}
                      </option>
                    ),
                )}
              </optgroup>
              <optgroup
                label={`${translate.t('laArchived')} (${translate.t(
                  'laEvaluations',
                )})`}
              >
                {latestArchives.map((templateOption: any) => (
                  <option key={templateOption.id} value={templateOption.id}>
                    {templateOption.templateSubject}
                  </option>
                ))}
              </optgroup>
            </Select>
            {!editMode && (
              <>
                {GLOBAL.jsonAvailableTabsDevelopment.includes(
                  'DEV_COMPETENCIES',
                ) && (
                  <Button
                    id="opener"
                    color={'primary'}
                    className={classes.competenceDialog}
                    onClick={() => GLOBAL.jsCompetenciesPopup()}
                  >
                    {translate.t('laShowCompetencies')}
                  </Button>
                )}
                <Button
                  color={'primary'}
                  className={classes.pdfButton}
                  onClick={() =>
                    this.saveAsPDF(
                      'evaluationToPrint',
                      this.state.selectedOptionText,
                    )
                  }
                >
                  {'PDF'}
                </Button>
                <IconButton
                  disabled={editIsDisabled}
                  className={classes.iconButton}
                  onClick={() => this.toggleEditMode()}
                >
                  <Tooltip title={translate.t('laEdit')}>
                    <EditIcon color={editIsDisabled ? 'disabled' : 'primary'} />
                  </Tooltip>
                </IconButton>
                <IconButton
                  className={classes.iconButton}
                  onClick={() => this.printEvaluation('evaluationToPrint')}
                >
                  <Tooltip title={translate.t('laPrint')}>
                    <PrintIcon color="primary" />
                  </Tooltip>
                </IconButton>
              </>
            )}
          </div>
          {editMode && (
            <span className={classes.buttonWrapper}>
              <Button
                color="primary"
                variant="text"
                className={classes.button}
                onClick={() => this.cancelEvaluation()}
              >
                {translate.t('laCancel')}
              </Button>
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={() => this.openSaveChangesModal()}
              >
                {translate.t('laSave')}
              </Button>
            </span>
          )}
          <div className={classes.flexWrapper}>
            <TextField
              label={translate.t('laApprovalStatus')}
              inputProps={{ readOnly: true, disabled: true }}
              value={
                notStartedStatus
                  ? translate.t('label_notStarted')
                  : translateCompletionStatus(activeTemplate.completionStatus)
              }
              className={`${classes.spacing} ${classes.status}`}
            />
            {activeStage && (
              <TextField
                label={translate.t('laStage')}
                inputProps={{
                  readOnly: true,
                  disabled: true,
                }}
                value={`${activeStage ? activeStage.label : ''}`}
                className={`${classes.spacing} ${classes.textField}`}
              />
            )}
            <TextField
              label={translate.t('laDeadline')}
              inputProps={{ readOnly: true, disabled: true }}
              value={activeTemplate.deadline}
              className={`${classes.spacing} ${classes.deadline}`}
            />
            <TextField
              label={translate.t('laModifiedTime')}
              inputProps={{ readOnly: true, disabled: true }}
              InputLabelProps={{ shrink: true }}
              value={notStartedStatus ? '' : `${activeTemplate.lastEdited}, ${activeTemplate.lastModifiedBy}`}
              className={`${classes.spacing} ${classes.textField}`}
            />
            <TextField
              label={translate.t('laApproved')}
              inputProps={{ readOnly: true, disabled: true }}
              InputLabelProps={{ shrink: true }}
              value={activeTemplate.approvedBy}
              className={`${classes.spacing} ${classes.approved}`}
              title={activeTemplate.approvedBy}
            />
            <div className={classes.removeApprovalWrapper}>
              {activeTemplate.archived && (
                <Typography variant="h3">
                  {translate.t('laArchived')}
                </Typography>
              )}
              {!activeTemplate.archived && !editMode && (
                <>
                  <Tooltip title={translate.t('laEvaluationTooltipApprove')}>
                    <span>
                      <Button
                        className={classes.button}
                        color="primary"
                        variant="text"
                        onClick={() =>
                          this.handleChangeApproval(activeTemplate)
                        }
                        disabled={
                          activeTemplate.completionStatus === 'IN_PROGRESS' ||
                          !activeTemplate.currentEvaluationId ||
                          editIsDisabled
                        }
                      >
                        {activeTemplate.approvedBy.includes(currentUserName)
                          ? translate.t('laRemoveApproval')
                          : translate.t('laApprove')}
                      </Button>
                    </span>
                  </Tooltip>
                  <Tooltip title={translate.t('laEvaluationTooltipArchive')}>
                    <span>
                      <Button
                        className={classes.button}
                        color="primary"
                        variant="text"
                        onClick={() => {
                          archiveEvaluation(activeTemplate.currentEvaluationId);
                        }}
                        disabled={
                          activeTemplate.archived ||
                          activeTemplate.completionStatus === 'IN_PROGRESS' ||
                          !activeTemplate.currentEvaluationId ||
                          editIsDisabled
                        }
                      >
                        {translate.t('laArchive')}
                      </Button>
                    </span>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
        </form>
        {isLoading && <Loading key="loading" />}
        {!isLoading && orderedSections && orderedSections.length > 0
          ? orderedSections.map((section: any) => (
              <SectionListing
                key={section.id}
                template={activeTemplate}
                section={section}
                list={[
                  {
                    key: section.id,
                    label: section.name,
                    componentsList: section.components || [],
                  },
                ]}
                /* Section is editable by
              case 1: everyone,
              case 2: everyone but employees(unless HR/Manager are looking at their own profile) ,
              case 3: only Employees and HR
              case 4: is team template
            */
                editMode={
                  (editMode && !section.onlyEmpAccess && section.empAccess) ||
                  (editMode &&
                    !section.onlyEmpAccess &&
                    (!GLOBAL.iEmployee ||
                      GLOBAL.aMyManUnits.indexOf(orgUnitId) > -1) &&
                    getLoggedUserId() !== employeeId) ||
                  (editMode &&
                    section.onlyEmpAccess &&
                    ((GLOBAL.iEmployee && !GLOBAL.iITAdmin) ||
                      GLOBAL.iHR ||
                      getLoggedUserId() === employeeId)) ||
                  (editMode && activeTemplate.isTeam)
                }
                updateEvaluation={updateEvaluation}
                employeeId={this.props.employeeId}
                responses={appraisalEvaluation.responses}
              />
            ))
          : !isLoading && (
              <Typography className={classes.noSections}>
                {translate.t('no_sections')}
              </Typography>
            )}
        <Modal open={saveModalOpen} onClose={() => this.handleSaveModalClose()}>
          <SaveChanges
            employeeId={employeeId}
            completionStatus={activeTemplate.completionStatus}
            activeTemplate={activeTemplate}
            handleModalClose={this.handleSaveModalClose}
            handleEvaluationsSave={handleEvaluationsSave}
            clearEvaluation={clearEvaluation}
            toggleEditMode={this.toggleEditMode}
            archiveEvaluation={archiveEvaluation}
            changeEvaluationStage={changeEvaluationStage}
            responses={appraisalEvaluation.responses}
          />
        </Modal>
      </Paper>
    );
  }
}

const mapStateToProps = (state: ReducerState) => {
  const { appraisalEvaluation } = state.appraisalsEvaluations;

  return {
    appraisalEvaluation,
  };
};

const enhance = compose<any, any>(
  connect(mapStateToProps),
  withStyles(templateSelectionStyles),
);

export default enhance(TemplateSelection);

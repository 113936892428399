import { useMemo } from 'react';
import { ReportingGroupType } from '@/app/components/TimeReporting/components/TimeSheet/data';
import { getEnums } from '@/old/utils/helper';
import translate from '@/app/utils/translate';
import { ChildInputType } from '@/app/components/FormFields/FormFields';

const reportingGroupTypeLabels: { [key in ReportingGroupType]: string } = {
  [ReportingGroupType.TIMESHEET_WORK_TYPE]: 'laWorkReportingTypes',
  [ReportingGroupType.TIMESHEET_NON_WORK_TYPE]: 'laNonWorkReportingTypes',
  [ReportingGroupType.TIMESHEET_CORRECTION_TYPE]: 'laCorrectionReportingTypes',
};

export const useReportingTypeOptions = (
  reportingTypeGroups: ReportingGroupType[],
  inputReportingType?: string,
  useCompoundValue: boolean = true,
) => {
  const reportingTypesOptions = useMemo(() => {
    return reportingTypeGroups.reduce(
      (accumulator, group) => {
        const options = getEnums(group).filter(
          ({ isActive }) => isActive !== false,
        );

        if (!options.length) {
          return accumulator;
        }

        return [
          ...accumulator,
          {
            label: translate.t(reportingGroupTypeLabels[group]),
            options: options.map(option => ({
              label: option.name,
              value: reportingTypeGroups.length > 1 || useCompoundValue ? `${group}-${option.code}` : option.code,
            })),
          },
        ];
      },
      [] as Array<{
        label: string;
        options: ChildInputType[];
      }>,
    );
  }, [reportingTypeGroups]);

  const selectedReportingType = useMemo(() => {
    if (!inputReportingType) {
      return null;
    }

    return reportingTypesOptions
      .reduce((accumulator, { options }) => [...accumulator, ...options], [])
      .find(({ value }) => value === inputReportingType);
  }, [reportingTypesOptions, inputReportingType]);

  return {
    reportingTypesOptions,
    selectedReportingType,
  };
};

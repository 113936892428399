import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Dispatch } from 'redux';
import { clearFields, reset } from 'redux-form';
import { v4 as uuid4 } from 'uuid';

import { Grid } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';

import { getFormDialog } from '@/app/components/FormDialog/FormDialog';
import FormFields from '@/app/components/FormFields/FormFields';
import { useRoles } from '@/app/hooks/useRoles';
import {
  QuickLink,
  QuickLinkForm,
  QuickLinkInput,
} from '@/app/redux/quickLinks';
import { LanguagesType } from '@/app/redux/languages';
import { ReducerState } from '@/app/redux/store';
import { getCurrentLanguage, INPUT_TYPE } from '@/app/utils/helper';
import translate from '@/app/utils/translate';

import quickLinkFormDialogStyle from './quickLinkFormDialogStyle';
import { mapQuickLinkToForm, useModuleTabSelection } from './useModuleTabSelection';

const FORM_NAME = 'addEditQuickLinkForm';
type MapStateToProps = {
  modules: Record<string, boolean>;
  activePeopleTabs: string[];
  activePerformanceTabs: string[];
  activeDevelopmentTabs: string[];
  activeRewardingTabs: string[];
  allTabs: string[];
  quickLinks: QuickLink[];
};

type MapDispatchToProps = {
  clearFormFields: () => void;
  languages: LanguagesType[];
  resetForm: () => void;
  untouchField: (field: string) => void;
};

type OwnProps = {
  title: string;
  open: boolean;
  edit: boolean;
  existingItem?: QuickLink;
  locationLinksOnly?: boolean;
  onClose: () => void;
  onSubmit: (quickLinkInput: QuickLinkInput, isEdit?: boolean) => void;
};

type PropsType = OwnProps &
  WithStyles<typeof quickLinkFormDialogStyle> &
  MapDispatchToProps &
  MapStateToProps;

let FormDialog: ReturnType<typeof getFormDialog>;
const validate = (values: QuickLinkForm, _existingQuickLinks: QuickLink[]) => {
  const requiredFields = ['name', 'url', 'description'];

  const requiredErrors = requiredFields.reduce(
    (errAccumulator: unknown, field) => {
      if (!values[field] || (values[field] && values[field].length === 0)) {
        errAccumulator[field] = translate.t('laThisRequired');
      }

      return errAccumulator;
    },
    {},
  );

  const lengthErrors = {};
  const fieldName = 'name';
  if (
    !requiredErrors[fieldName] &&
    values[fieldName] &&
    values[fieldName].length < 2
  ) {
    lengthErrors[fieldName] = translate.t('laQuickLinksNameLength');
  }

  const specificErrors = {};
  const urlField = 'url';
  if (
    values[urlField] &&
    !(
      values[urlField].startsWith('/') ||
      values[urlField].startsWith('http://') ||
      values[urlField].startsWith('https://')
    )
  ) {
    specificErrors[urlField] = translate.t('laUrlFullOrRelative');
  }

  return {
    ...requiredErrors,
    ...lengthErrors,
    ...specificErrors,
  };
};

const QuickLinkFormDialog: React.FC<PropsType> = props => {
  const [formData, setFormData] = React.useState<Partial<QuickLinkForm>>({
    roles: [],
  });
  const currentLanguage: string = getCurrentLanguage();
  const { data: roles } = useRoles();
  const {
    activePeopleTabs,
    activePerformanceTabs,
    activeDevelopmentTabs,
    activeRewardingTabs,
    allTabs,
    classes,
    existingItem,
    locationLinksOnly,
    modules,
    open,
    onClose,
    onSubmit,
    quickLinks,
    resetForm,
    title,
  } = props;

  const {
    allTabsSelection,
    allNavModulesSelection,
    availableTabsSelection,
    moduleSelection,
    onSelectedModuleChange,
  } = useModuleTabSelection(
    existingItem && existingItem.navigation,
    modules,
    allTabs,
    activePeopleTabs,
    activePerformanceTabs,
    activeDevelopmentTabs,
    activeRewardingTabs,
  );

  const fields = [
    {
      type: INPUT_TYPE.TEXT,
      code: 'name',
      name: 'name',
      label: translate.t('table_col_link_name'),
      order: 1,
      required: true,
    },
    ...(locationLinksOnly ? [{
      type: INPUT_TYPE.SELECT,
      code: 'module',
      name: 'module',
      label: translate.t('laModule'),
      childrenInputs: moduleSelection,
      onChange: (selection: any) => { onSelectedModuleChange(selection); },
      order: 2,
      required: true,
    }, {
      type: INPUT_TYPE.SELECT,
      code: 'tab',
      name: 'tab',
      label: translate.t('laNavArea'),
      childrenInputs: availableTabsSelection,
      order: 3,
      required: true,
    }] : [{
      type: INPUT_TYPE.TEXT,
      code: 'url',
      name: 'url',
      label: translate.t('table_col_link_url'),
      order: 2,
      required: true,
    }]),
    {
      type: INPUT_TYPE.MULTISELECT,
      code: 'roles',
      name: 'roles',
      label: translate.t('table_col_roles'),
      childrenInputs: roles.map(role => ({
        label: role.name,
        value: role.code,
      })),
      order: 4,
      required: false,
    },
    {
      type: INPUT_TYPE.TEXTAREA,
      code: 'description',
      name: 'description',
      label: translate.t('table_col_description'),
      order: 5,
      required: true,
    },
  ];

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleSubmit = (values: QuickLinkForm) => {
    if (!existingItem) {
      return onSubmit({
        ...(locationLinksOnly ? {
          navigation: {
            module: values.module.value,
            tab: values.tab.value,
          },
        } : {
          url: values.url,
        }),
        location: locationLinksOnly ? 'menu_links' : 'quick_links_widget',
        name: values.name,
        description: values.description,
        language: getCurrentLanguage(),
        id: uuid4(),
        roles: values.roles ? values.roles.map(role => role.value) : [],
      });
    }

    const quickLinkInput: QuickLinkInput = {
      id: values.id,
      ...(locationLinksOnly ? {
        navigation: {
          module: values.module.value,
          tab: values.tab.value,
        },
      } : {
        url: values.url,
      }),
      location: existingItem.location,
      name: values.name,
      description: values.description,
      roles: values.roles.map(role => role.value),
      language: currentLanguage,
    };

    onSubmit(quickLinkInput, true);
  };

  const renderFields = () => {
    return fields.map(field => (
      <Grid item container xs={12} key={field.order} direction="column">
        <FormFields inputs={[field]} />
      </Grid>
    ));
  };

  const initForm = (existingQuickLinks: QuickLink[]) => {
    const validationCallback = (values: QuickLinkForm) => {
      return validate(values, existingQuickLinks);
    };

    return getFormDialog(FORM_NAME, validationCallback);
  };

  React.useEffect(() => {
    if (existingItem) {
      FormDialog = initForm(quickLinks);
      return setFormData(
        mapQuickLinkToForm(
          existingItem,
          currentLanguage,
          roles,
          allNavModulesSelection,
          allTabsSelection
        ),
      );
    }

    setFormData({ roles: [] });
    FormDialog = initForm(quickLinks);
    resetForm();
  }, [existingItem, quickLinks]);

  if (!FormDialog) {
    return <></>;
  }

  return (
    <FormDialog
      title={title}
      open={open}
      onClose={handleClose}
      formData={formData}
      paperWidthMd={classes.paperWidthMd}
      onSubmit={handleSubmit}
      preventConfirmDialog
    >
      <Grid container justify="space-between">
        {renderFields()}
      </Grid>
    </FormDialog>
  );
};

const mapStateToProps = (state: ReducerState) => ({
  languages: state.languages.get('allLanguages'),
  quickLinks: state.quickLinks.get('list'),
  modules: state.modules.get('allModules'),
  allTabs: state.adminSection.get('AllTabsOptions'),
  activePeopleTabs: state.adminSection.get('activePeopleTabs'),
  activePerformanceTabs: state.adminSection.get('activePerformanceTabs'),
  activeDevelopmentTabs: state.adminSection.get('activeDevelopmentTabs'),
  activeRewardingTabs: state.adminSection.get('activeRewardingTabs'),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearFormFields: () => dispatch(clearFields(FORM_NAME, false, false)),
  resetForm: () => dispatch(reset(FORM_NAME)),
});

const enhance = compose<any, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(quickLinkFormDialogStyle, { withTheme: true }),
);

export default enhance(QuickLinkFormDialog);

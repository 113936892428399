import { legacy_createStore, combineReducers, applyMiddleware, Store } from 'redux';
import thunk from 'redux-thunk';
import { reducer as reduxFormReducer } from 'redux-form';
import autoDeduction from '@/app/redux/autoDeduction';
import error from '@/app/redux/error';
import languages from '@/app/redux/languages';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import companyInfo from '@/app/redux/companyInfo';
import enums from '@/app/redux/enums';
import bonusCalc from '@/app/redux/bonusCalc';
import notifier from '@/app/redux/notifier';
import confirmDialog from '@/app/redux/confirmDialog';
import documentApproval from '@/app/redux/documentApproval';
import form from '@/app/middleware/form';
import enumsMiddleware from '@/app/middleware/enums';
import entityTranslations from '@/app/redux/entityTranslations';
import modules from '@/app/redux/modules';
import adminSection from '@/app/redux/adminSection';
import goals from '@/app/redux/goals';
import goalEvaluations from '@/app/redux/goalEvaluations';
import appraisals from '@/app/redux/appraisals';
import appraisalsEvaluations from '@/app/redux/appraisalsEvaluations';
import jobEvaluation from '@/app/redux/jobEvaluation';
import processTemplates from '@/app/redux/processTemplates';
import processEvaluations from '@/app/redux/processEvaluations';
import surveys from '@/app/redux/surveys';
import solaIntel from '@/app/redux/solaIntel';
import currentUser from '@/app/redux/currentUser';
import orgTree from '@/app/redux/orgTree';
import reporting from '@/app/redux/reporting';
import quickLinks from '@/app/redux/quickLinks';
import jobRequirementEvaluations from '@/app/redux/jobRequirementEvaluations';
import { collaborationReducer } from '@/app/components/Collaboration';
import { timeSheetReducer } from '@/app/components/TimeReporting';

const reducer = combineReducers({
  adminSection,
  autoDeduction,
  goals,
  goalEvaluations,
  appraisals,
  appraisalsEvaluations,
  jobEvaluation,
  bonusCalc,
  collaboration: collaborationReducer,
  companyInfo,
  confirmDialog,
  documentApproval,
  entityTranslations,
  enums,
  error,
  form: reduxFormReducer, // mounted under "form",
  languages,
  modules,
  notifier,
  solaIntel,
  surveys,
  currentUser,
  orgTree,
  processTemplates,
  processEvaluations,
  reporting,
  timeSheet: timeSheetReducer,
  quickLinks,
  jobRequirementEvaluations,
});

export function configureStore(): Store {
  const composeEnhancers = composeWithDevTools({});

  return legacy_createStore(
    reducer,
    composeEnhancers(applyMiddleware(thunk, form, enumsMiddleware)),
  );
}

const store = configureStore();

export default store;

import React, { useContext } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import translate from '@/app/utils/translate';
import Context from '../context/trainingsContext';
import {
  CompanyTraining,
  ParticipantSummary,
  TrainingParticipantSummary,
} from '../types';

type OwnProps = {
  trainingId: number;
  disabled?: boolean;
  className?: string;
  onOpenParticipants: () => void;
  participantsSummary?: TrainingParticipantSummary;
};
type InnerProps = {};
type Props = OwnProps & InnerProps;

const ParticipantsButton = ({
  className,
  disabled = false,
  trainingId,
  onOpenParticipants,
  participantsSummary,
}: Props) => {
  const {
    state: {
      companyTrainings: { data: trainings },
      participantSummaries: { data: summaries, loading },
      employeeTrainings: {
        calendar: { data: calendarTrainings },
      },
    },
  } = useContext(Context);

  let participantsText;
  if (loading || !summaries) {
    participantsText = <CircularProgress size={24} />;
  } else {
    // Get training settings through company training or training calendar
    let training = trainings.find((t: CompanyTraining) => t.id === trainingId);
    if (!training) {
      training = calendarTrainings.find(
        (t: CompanyTraining) => t.id === trainingId,
      );
    }
    const allowWaitingSeats = !training ? false : training.allowWaitingSeats;
    const trainingSeats = !training ? 0 : training.seats;

    const summary = summaries.find(
      (s: ParticipantSummary) => s.trainingId === trainingId,
    );
    const {
      totalRegistrants: participants,
      seatings: {
        guaranteedSeat: guaranteedSeaters,
        seat: seaters,
        waitingSeat: waitingSeaters,
      },
    } = summary || {
      totalRegistrants: 0,
      seatings: { guaranteedSeat: 0, seat: 0, waitingSeat: 0 },
    };

    if (participants === 0 || guaranteedSeaters + seaters === 0) {
      participantsText = translate.t('label_no_participants');
    }

    if (participantsSummary) {
      if (participantsSummary.participants === 0) {
        participantsText = translate.t('label_no_participants');
      } else {
        if (participantsSummary.seats > 0) {
          const waitingPartic =
            participantsSummary.participants - participantsSummary.seats;
          const waitingText =
            !participantsSummary.allowWaitingSeats || waitingPartic <= 0
              ? ''
              : ` (${translate.t('label_participants_waiting', {
                  count: waitingPartic,
                })})`;
          participantsText = `${participantsSummary.participants -
            waitingPartic}/${participantsSummary.seats}${waitingText}`;
        } else {
          participantsText = `${translate.t('label_participants_registered', {
            count: participantsSummary.participants,
          })}`;
        }
      }
    }

    if (!participantsText) {
      if (trainingSeats > 0) {
        const waitingText =
          !allowWaitingSeats || waitingSeaters === 0
            ? ''
            : ` (${translate.t('label_participants_waiting', {
                count: waitingSeaters,
              })})`;
        participantsText = `${guaranteedSeaters +
          seaters}/${trainingSeats}${waitingText}`;
      } else {
        participantsText = `${translate.t('label_participants_registered', {
          count: guaranteedSeaters + seaters,
        })}`;
      }
    }
  }

  return (
    <Button
      data-testid="btn-open-training-participants-dialog"
      className={className}
      size="small"
      variant="contained"
      color="default"
      disabled={disabled}
      onClick={onOpenParticipants}
    >
      {participantsText}
    </Button>
  );
};

export default ParticipantsButton;

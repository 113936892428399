import { createStyles } from '@material-ui/core';

export const taskListStyles = () => createStyles({
    errorContainer: {
        display: 'flex',
        margin: 50,
        justifyContent: 'center',
    },
    grid: {
        height: '100%',
    },
    gridToolbarContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
});
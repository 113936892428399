import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const quickLinkFormDialogStyle: StyleRulesCallback = () => {
  return {
    paperWidthMd: {
      width: '480px',
    },
  };
};

export default quickLinkFormDialogStyle;

import { Record } from 'immutable';
import { Dispatch } from 'redux';
import * as moment from 'moment';

import API from '@/app/api/internalAPIs';
import Service from '@/app/utils/service';
import translate from '@/app/utils/translate';
import { sortArr } from '@/app/utils/helper';

import { THROW_ERROR } from './error';
import { OPEN_NOTIFIER } from './notifier';

const initialState = Record({
  allCommonGoals: [] as any[],
  allTemplates: [] as any[],
  allEvaluations: [] as any[],
  activeTemplate: {} as any,
});

const FETCH_ALL_COMMON_GOALS =
  '@@solaforce/goalEvaluations/FETCH_ALL_COMMON_GOALS';
const FETCH_ALL_TEMPLATES = '@@solaforce/goalEvaluations/FETCH_ALL_TEMPLATES';
const FETCH_ALL_EVALUATIONS =
  '@@solaforce/goalEvaluations/FETCH_ALL_EVALUATIONS';
const SET_ACTIVE_TEMPLATE = '@@solaforce/goalEvaluations/SET_ACTIVE_TEMPLATE';
const UPDATE_EVALUATION_APPROVAL =
  '@@solaforce/goalEvaluations/UPDATE_EVALUATION_APPROVAL';
const ARCHIVE_EVALUATION = '@@solaforce/goalEvaluations/ARCHIVE_EVALUATION';
const SAVE_EVALUATION = '@@solaforce/goalEvaluations/SAVE_EVALUATION';
const UPDATE_ACTIVE_TEMPLATE =
  '@@solaforce/goalEvaluations/UPDATE_ACTIVE_TEMPLATE';

const formatedListOnRemoval = (
  initialList: number[],
  approvingUserId: number,
) => initialList.filter(el => el !== approvingUserId);

const formatedAddition = (initialList: number[], approvingUserId: number) => {

  return [...initialList, approvingUserId];
};

const reducer = (state = new initialState(), action: any) => {
  switch (action.type) {
    case FETCH_ALL_COMMON_GOALS: {
      const { allCommonGoals } = action.payload;
      return state.set(
        'allCommonGoals',
        allCommonGoals.reduce((commonGoalsHash: {}, commonGoal: any) => {
          return {
            ...commonGoalsHash,
            [commonGoal.subgroupName]: {
              ...commonGoal,
              subgroupValues: commonGoal.subgroupValues.reduce(
                (successMeasuresHash: {}, successMeasure: any) => {
                  return {
                    ...successMeasuresHash,
                    [successMeasure.code]: successMeasure,
                  };
                },
                {},
              ),
            },
          };
        }, {}),
      );
    }

    case FETCH_ALL_TEMPLATES: {
      const { allTemplates } = action.payload;

      return state.set(
        'allTemplates',
        sortArr(
          allTemplates.map((template: any) => {
            return {
              ...template,
              sections: sortArr(
                template.sections.map((section: any) => {
                  return {
                    ...section,
                    components: sortArr(section.components, 'index'),
                  };
                }),
                'index',
              ),
            };
          }),
          'deadline',
        ),
      );
    }

    case UPDATE_ACTIVE_TEMPLATE: {
      const { approvingUser } = action.payload;
      const activeTemp = state.get('activeTemplate');

      const updatedActiveTemplate = {
        ...activeTemp,
        approvedBy: activeTemp.approvedBy.includes(approvingUser)
          ? formatedListOnRemoval(activeTemp.approvedBy, approvingUser)
          : formatedAddition(activeTemp.approvedBy, approvingUser),
        lastEdited: moment().format('YYYY-MM-DD'),
        lastModifiedBy: approvingUser,
      };

      return state.set('activeTemplate', updatedActiveTemplate);
    }

    case FETCH_ALL_EVALUATIONS: {
      const { allEvaluations } = action.payload;

      return state.set(
        'allEvaluations',
        sortArr(allEvaluations, 'updatedTime', 'desc'),
      );
    }

    case SET_ACTIVE_TEMPLATE: {
      const { template } = action.payload;
      return state.set('activeTemplate', template);
    }

    case UPDATE_EVALUATION_APPROVAL: {
      const { evaluationToUpdate, newApproval } = action.payload;
      const updatedApprovedStatus = Object.values(
        state.get('allEvaluations'),
      ).map((item: any) => {
        if (item.id === evaluationToUpdate) {
          return {
            ...item,
            approvedBy: item.approvedBy.includes(newApproval)
              ? formatedListOnRemoval(item.approvedBy, newApproval)
              : formatedAddition(item.approvedBy, newApproval),
            lastEdited: moment().format('YYYY-MM-DD'),
            lastModifiedBy: newApproval,
          };
        }

        return item;
      });

      return state.set('allEvaluations', updatedApprovedStatus);
    }

    case ARCHIVE_EVALUATION: {
      return state.set('allEvaluations', [
        action.payload.evaluation,
        ...state.allEvaluations.filter(({ id }) => {
          return action.payload.evaluation.id !== id;
        }),
      ]);
    }

    case SAVE_EVALUATION: {
      return state.set('allEvaluations', [
        action.payload.evaluation,
        ...state.allEvaluations,
      ]);
    }

    default:
      return state;
  }
};

export const fetchAllCommonGoals = () => {
  return (dispatch: Dispatch) => {
    return Service.get(
      API.goalEvaluations.getAllCommonGoals(),
      (response: any) => {
        dispatch({
          type: FETCH_ALL_COMMON_GOALS,
          payload: {
            allCommonGoals: response,
          },
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const fetchAllTemplates = () => {
  return (dispatch: Dispatch) => {
    return Service.get(
      API.goalEvaluations.getTemplateList(),
      (response: any) => {
        dispatch({
          type: FETCH_ALL_TEMPLATES,
          payload: {
            allTemplates: response.data,
          },
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const fetchAllTemplatesForUser = (userId: string) => {
  return (dispatch: Dispatch) => {
    return Service.get(
      API.goalEvaluations.getAllFilteredTemplates(userId),
      (response: any) => {
        dispatch({
          type: FETCH_ALL_TEMPLATES,
          payload: {
            allTemplates: response.data,
          },
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const fetchAllEvaluations = (employeeId: number) => {
  return (dispatch: Dispatch) => {
    return Service.get(
      API.goalEvaluations.getEvaluations(employeeId),
      (response: any) => {
        dispatch({
          type: FETCH_ALL_EVALUATIONS,
          payload: {
            allEvaluations: response,
          },
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const setActiveTemplate = (template: any) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_ACTIVE_TEMPLATE,
      payload: {
        template: template,
      },
    });
  };
};

export const changeEvaluationApproval = (
  evaluationId: string,
  newApproval: string,
) => {
  return (dispatch: Dispatch) => {
    return Service.post(
      API.goalEvaluations.approveEvaluation(evaluationId),
      { newApproval: newApproval },
      (_response: any) => {
        const payload = {
          evaluationToUpdate: evaluationId,
          newApproval: newApproval,
          approvingUser: newApproval,
        };
        dispatch({ type: UPDATE_EVALUATION_APPROVAL, payload: payload });
        dispatch({ type: UPDATE_ACTIVE_TEMPLATE, payload });
        dispatch({ type: OPEN_NOTIFIER, payload: { message: translate.t('change_saved') },
      });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const removeEvaluationApproval = (
  evaluationId: string,
  newApproval: string,
) => {
  return (dispatch: Dispatch) => {
    return Service.post(
      API.goalEvaluations.unapproveEvaluation(evaluationId),
      { newApproval: newApproval },
      (_response: any) => {
        const payload = {
          evaluationToUpdate: evaluationId,
          newApproval: newApproval,
          approvingUser: newApproval,
        };
        dispatch({ type: UPDATE_EVALUATION_APPROVAL, payload: payload });
        dispatch({ type: UPDATE_ACTIVE_TEMPLATE, payload });
        dispatch({ type: OPEN_NOTIFIER, payload: { message: translate.t('change_saved') },
      });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const archiveEvaluation = (evaluationId: string) => {
  return (dispatch: Dispatch) => {
    return Service.post(
      API.goalEvaluations.archiveEvaluation(evaluationId),
      undefined,
      (response: any) => {
        dispatch({
          type: ARCHIVE_EVALUATION,
          payload: { evaluation: response },
        });
        dispatch({
          type: OPEN_NOTIFIER,
          payload: { message: translate.t('evaluation_archived') },
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const saveEvaluation = (templateId: string, evaluation: any) => {
  return (dispatch: Dispatch) => {
    return Service.post(
      API.goalEvaluations.saveEvaluation(templateId),
      evaluation,
      (response: any) => {
        dispatch({ type: SAVE_EVALUATION, payload: { evaluation: response } });
        dispatch({
          type: OPEN_NOTIFIER,
          payload: { message: translate.t('change_saved') },
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export default reducer;

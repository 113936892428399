import * as React from 'react';
import { v4 as uuid4 } from 'uuid';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import {
  WithStyles,
  Paper,
  TextField,
  Button,
  Checkbox,
} from '@material-ui/core';
import addSectionStyle from './addSectionStyle';
import translate from '@/app/utils/translate';
import {
  FormTemplate,
  FormTemplateSection,
  FormTemplateSectionUpdateCall
} from '@/app/components/TemplateComponents/types';

type incomingProps = {
  handleModalClose: () => void;
  addTemplateSection: FormTemplateSectionUpdateCall;
  readyToEditTemplate: FormTemplate;
  newSectionAdded?: () => void;
  updateSection: FormTemplateSectionUpdateCall;
  section?: FormTemplateSection;
  editMode?: boolean;
};

type StateType = {
  title: string;
  showValidationError: boolean;
  disabledSave: boolean;
  empAccess: boolean;
  onlyEmpAccess: boolean;
};

type PropsType = incomingProps & WithStyles<typeof addSectionStyle>;

class AddSection extends React.PureComponent<PropsType> {
  state: StateType = {
    title: '',
    showValidationError: false,
    disabledSave: true,
    empAccess: true,
    onlyEmpAccess: false,
  };

  constructor(props: any) {
    super(props);

    const { section, readyToEditTemplate } = this.props;

    // isTeam property is in Appraisal templates used as additional condition
    const handleIsTeamAccess = (access: boolean) => 'isTeam' in readyToEditTemplate ? !readyToEditTemplate.isTeam && access : access;

    this.state = {
      title: (section && section.name) || '',
      showValidationError: false,
      disabledSave: true,
      empAccess: handleIsTeamAccess(!!section && !!section.empAccess),
      onlyEmpAccess: handleIsTeamAccess(!!section && !!section.onlyEmpAccess),
    };
  }

  // To be moved to helper
  titleValidation = (title: string) => {
    if (title === '') {
      this.setState({ showValidationError: true });
      return false;
    } else {
      this.setState({ showValidationError: false });
      return true;
    }
  };

  changeTitle = (event: any) => {
    const target = event.target as HTMLTextAreaElement;
    this.setState({ title: target.value });
    if (target.value !== '') {
      this.setState({ showValidationError: false, disabledSave: false });
    }
  };

  handleSaveSection = () => {
    const {
      addTemplateSection,
      readyToEditTemplate,
      newSectionAdded,
      handleModalClose,
    } = this.props;
    const { title, empAccess, onlyEmpAccess } = this.state;
    const templateBeingEdited = readyToEditTemplate;

    if (!this.titleValidation(title)) {
      return;
    }
    addTemplateSection(templateBeingEdited.id, {
      id: uuid4(),
      name: title,
      templateId: readyToEditTemplate.id,
      empAccess: empAccess,
      onlyEmpAccess: onlyEmpAccess,
      index: templateBeingEdited.sections
        ? templateBeingEdited.sections.length + 1
        : 0,
    });
    handleModalClose();
    newSectionAdded();
  };

  handleSectionUpdate = () => {
    const { section, updateSection, readyToEditTemplate, handleModalClose } = this.props;
    const { title, empAccess, onlyEmpAccess } = this.state;

    if (!this.titleValidation(title)) {
      return;
    }
    let updatedSection = {
      id: section.id,
      components: section.components,
      name: title,
      templateId: readyToEditTemplate.id,
      empAccess: empAccess,
      onlyEmpAccess: onlyEmpAccess,
      index: section.index,
    };

    updateSection(readyToEditTemplate.id, updatedSection);
    handleModalClose();
  };

  employeeCanEdit = (setTrue: boolean) => {
    if (setTrue) {
      this.setState({ empAccess: true });
    } else {
      this.setState({ empAccess: !this.state.empAccess });
    }
    this.setState({ disabledSave: false });
  };
  onlyEmployeeCanEdit = () => {
    let { onlyEmpAccess } = this.state;
    this.setState({ onlyEmpAccess: !onlyEmpAccess }, () => {
      this.employeeCanEdit(true);
      this.setState({ disabledSave: false });
    });
  };

  render() {
    const {
      classes,
      handleModalClose,
      editMode,
      readyToEditTemplate,
    } = this.props;
    const {
      title,
      empAccess,
      onlyEmpAccess,
      showValidationError,
      disabledSave,
    } = this.state;
    const disabledOnIsTeamCondition = 'isTeam' in readyToEditTemplate ? readyToEditTemplate.isTeam : false;

    return (
      <Paper className={classes.root}>
        <form>
          <h3 className={classes.topTitle}>
            {editMode
              ? translate.t('edit_section')
              : translate.t('add_section')}
          </h3>
          <TextField
            label={translate.t('laTitle')}
            onChange={this.changeTitle}
            value={title}
            className={classes.spacing}
            required
            error={showValidationError}
            helperText={
              showValidationError ? translate.t('laThisRequired') : ' '
            }
          />
          <div className={classes.checkboxes}>
            <Checkbox
              onClick={() => this.employeeCanEdit(false)}
              checked={empAccess}
              disabled={disabledOnIsTeamCondition || onlyEmpAccess}
              color="primary"
            />
            {translate.t('employee_edit')}
          </div>
          <div className={classes.checkboxes}>
            <Checkbox
              onClick={() => this.onlyEmployeeCanEdit()}
              checked={onlyEmpAccess}
              disabled={disabledOnIsTeamCondition}
              color="primary"
            />
            {translate.t('only_employee_edit')}
          </div>
          <div className={classes.buttonsWrapper}>
            <Button color="primary" variant="text" onClick={handleModalClose}>
              {translate.t('laCancel')}
            </Button>
            {!editMode && (
              <Button
                color="primary"
                variant="contained"
                disabled={disabledSave}
                onClick={() => this.handleSaveSection()}
              >
                {translate.t('laSave')}
              </Button>
            )}
            {editMode && (
              <Button
                color="primary"
                variant="contained"
                disabled={disabledSave}
                onClick={() => this.handleSectionUpdate()}
              >
                {translate.t('laSaveChanges')}
              </Button>
            )}
          </div>
        </form>
      </Paper>
    );
  }
}

const enhance = compose<any, any>(withStyles(addSectionStyle));

export default enhance(AddSection);

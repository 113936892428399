import { GridCellProps } from '@progress/kendo-react-grid';
import { WithStyles, Avatar, } from '@material-ui/core';
import * as React from 'react';

import API from '@/app/api/internalAPIs';
import { getEmpPicId } from '@/old/utils/helper';

import { avatarCellStyles } from './avatarCellStyles';

export interface AvatarCellOuterProps extends GridCellProps {}

export interface AvatarCellInnerProps extends
    AvatarCellOuterProps,
    WithStyles<typeof avatarCellStyles> {}

export const AvatarCellFactory = (
    employeeIdField: string,
    employeeNameField: string
): React.FC<AvatarCellInnerProps> => {
    return ({
        classes,
        rowType,
        dataItem,
    }) => {
        if (rowType !== 'data') {
            return null;
        }

        if (!dataItem[employeeIdField]) {
            return <td />;
        }

        const avatarSrc = API.employeeImageUrl(
            getEmpPicId(dataItem[employeeIdField])
        );

        return (
            <td>
                <Avatar className={classes.avatar} src={avatarSrc} />
                <div className={classes.label}>{dataItem[employeeNameField]}</div>
            </td>
        );
    };
};
import React, { useContext, useState } from 'react';
import { getSelectedEmpId, getLoggedUserId } from '@/old/utils/helper';
import { REGISTRATION_STATUS } from '../../types';
import ReadOnlyRegistrationStatus from './ReadOnlyRegistrationStatus';
import {
  Button,
  CircularProgress,
  TextField,
  StyleRulesCallback,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import translate from '@/app/utils/translate';
import Context from '../../context/trainingsContext';
import moment from 'moment';

const styles: StyleRulesCallback = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  message: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    width: theme.spacing.unit * 40,
  },
});

type OwnProps = {
  trainingId: number;
  trainingStartDate: moment.Moment;
  useOnlineRegistration: boolean;
  lastDateForRegistration: moment.Moment;
};
type Props = OwnProps & WithStyles<typeof styles>;

const SelfRegisterButton = ({
  classes,
  trainingId,
  trainingStartDate,
  useOnlineRegistration,
  lastDateForRegistration,
}: Props) => {
  const {
    state: {
      employeeTrainings: {
        selfRegistering: { registering },
      },
    },
    selfRegister,
  } = useContext(Context);

  const [message, setMessage] = useState('');
  const handleSelfRegister = () => selfRegister(trainingId, message.trim());

  // We allow self registering if..
  // - online registering is used in training
  // - you are looking at your own training calendar
  // - training start date has not passed
  if (
    useOnlineRegistration &&
    getSelectedEmpId() === getLoggedUserId() &&
    trainingStartDate.isAfter(moment().endOf('day')) &&
    (!lastDateForRegistration ||
      (lastDateForRegistration &&
        moment(new Date()).isSameOrBefore(lastDateForRegistration, 'day')))
  ) {
    return (
      <div className={classes.container}>
        <TextField
          value={message}
          onChange={e => {
            setMessage(e.target.value);
          }}
          label={translate.t('laMessageToTrainer')}
          multiline
          rows={1}
          rowsMax={3}
          variant="outlined"
          inputProps={{ maxLength: 3000 }}
          className={classes.message}
        />

        <Button
          data-testid="btn-training-self-register"
          variant="contained"
          color="primary"
          disabled={registering}
          onClick={handleSelfRegister}
        >
          {registering ? (
            <CircularProgress size={24} />
          ) : (
            <>{translate.t('label_register_for_training')}</>
          )}
        </Button>
      </div>
    );
  }

  // Otherwise we just show the status as read only text
  return (
    <ReadOnlyRegistrationStatus
      registrationStatus={REGISTRATION_STATUS.NOT_REGISTERED}
    />
  );
};

export default withStyles(styles)(SelfRegisterButton);

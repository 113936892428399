import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const sortableComponentStyle: StyleRulesCallback = (theme: Theme) => {

  return ({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      marginTop: theme.spacing.unit,
      cursor: 'grab',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      '& :active': {
        cursor: 'grabbing',
      }
    },
  });
};

export default sortableComponentStyle;

import React from 'react';

import LocalizedDatePicker from '@/app/components/Pickers/LocalizedDatePicker';
import translate from '@/app/utils/translate';
import { DATE_FORMAT } from '@/app/utils/helper';
import { APPROVAL_STATUS, COMPLETION_STATUS } from '../../../types';
import {
  Button,
  StyleRulesCallback,
  Theme,
  WithStyles,
  withStyles,
  Icon,
  Typography,
} from '@material-ui/core';
import { FieldProps } from 'formik';
import moment from 'moment';
import { TextFieldProps } from '@material-ui/core/TextField';
const styles: StyleRulesCallback = (_theme: Theme) => ({
  button: {
    // backgroundColor: theme.palette.grey[300],
    // paddingTop: theme.spacing.unit / 2,
    // paddingBottom: theme.spacing.unit / 2,
  },
});

type OwnProps = {
  approvalStatus: APPROVAL_STATUS;
  completionStatus: COMPLETION_STATUS;
  className?: string;
  readOnly?: boolean;
};
type InnerProps = WithStyles<typeof styles> & FieldProps;
type Props = OwnProps & InnerProps;

const ExpiresDateField = ({
  classes,
  form,
  field,
  approvalStatus,
  completionStatus,
  className,
  readOnly = false,
  ...others
}: Props) => {
  const handleChange = (newDate: moment.Moment) => {
    form.setFieldTouched(field.name, true);
    form.setFieldValue(field.name, newDate);
  };

  if (
    approvalStatus === APPROVAL_STATUS.REJECTED ||
    approvalStatus === APPROVAL_STATUS.CANCELLED ||
    !(
      completionStatus === COMPLETION_STATUS.COMPLETED ||
      completionStatus === COMPLETION_STATUS.COMPLETED_PARTLY
    )
  ) {
    return null;
  }

  const expirationDate = !field.value
    ? `(${translate.t('label_not_set')})`
    : field.value.format(DATE_FORMAT);

  if (readOnly) {
    return <Typography variant="inherit">{expirationDate}</Typography>;
  }

  return (
    <LocalizedDatePicker
      {...field}
      autoOk={true}
      okLabel={translate.t('laOk')}
      cancelLabel={translate.t('laCancel')}
      clearLabel={translate.t('laClear')}
      label={translate.t('laExpires')}
      value={field.value || null}
      TextFieldComponent={(props: TextFieldProps) => (
        <Button
          className={[classes.button, className].join(' ')}
          color="default"
          variant="contained"
          size="small"
          onClick={props.onClick}
        >
          {expirationDate}
          <Icon>arrow_drop_down</Icon>
        </Button>
      )}
      onBlur={field.onBlur}
      onChange={(date) => handleChange(date)}
      {...others}
    />
  );
};

export default withStyles(styles)(ExpiresDateField);

import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    actionBar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      minHeight: '35px',
    },
    datePicker: {
      width: 100,
      margin: '0px 0px 0px 16px',
    },
    datePickerDimension: {
      width: 200,
    },
    externalSign: {
      fontSize: '10px',
      fontWeight: 'bold',
      color: '#05A4D8 !important',
      float: 'left',
    },
    dimensionSelect: {
      marginBottom: '8px',
    },
    '@keyframes pulse': {
      '0%': {
        boxShadow: '0 0 0 0 rgba(0, 0, 0, 0.7)',
      },
      '70%': {
        boxShadow: '0 0 0 5px rgba(0, 0, 0, 0)',
      },
      '100%': {
        boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
      },
    },
    setReportDimensions: {
      animationName: 'pulse',
      animationDuration: '3000ms',
      animationIterationCount: 'infinite',
    },
    unitSelect: {
      width: '300px',
    },
    summaryTitle: {
      color: '#6f6f6f',
      fontSize: '16px',
      fontWeight: 'bold',
    },
    summarySum: {
      color: '#6f6f6f',
      fontSize: '15px',
      fontWeight: 'bold',
      marginRight: '20px',
    },
    rightComponent: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  });

export default styles;

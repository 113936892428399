const GLOBAL: any = window;

export default () => {
  if (!GLOBAL.jsonCompanyCountries || !GLOBAL.jsonEnumALL || !GLOBAL.jsonEnumALL.COUNTRY) {
    return [];
  }

  return GLOBAL.jsonCompanyCountries.map((countryCode: string) => {
    const country = GLOBAL.jsonEnumALL.COUNTRY.find((c: any) => c.code === countryCode);
    return {
      code: countryCode,
      name: country.name || countryCode,
    };
  })
  .sort((a: any, b: any) => a.name.localeCompare(b.name));
};

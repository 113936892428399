import * as React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import evaluationOptionsStyles from './evaluationOptionsStyles';
import Scale1To5 from '@/app/components/TemplateComponents/form/InputComponents/common/Scale1To5/Scale1To5';

export type EvaluationOptionsChange = {
  componentId: string;
  response: {
    optionId: number;
    [p: string]: number;
  }[];
  type: string;
};

type PropsType = {
  label: string;
  evaluationVariable: string;
  componentId: string;
  evaluationType: string;
  showLabel: boolean;
  optionId: number;
  existingResponse: any;
  responseName: string;
  editMode: boolean;
  evaluationComponentChange: (props: EvaluationOptionsChange) => void;
  classes: any;
};

function EvaluationOptions(props: PropsType) {
  const {
    label,
    componentId,
    optionId,
    evaluationType,
    showLabel,
    editMode,
    evaluationComponentChange,
    existingResponse,
    evaluationVariable,
  } = props;
  const [selectedValue, setSelectedValue] = React.useState(0);

  function handleChange(value: number) {
    setSelectedValue(value);
    evaluationComponentChange({
      response: [
        {
          optionId: optionId,
          [evaluationVariable]: value,
        },
      ],
      componentId: componentId,
      type: evaluationType,
    });
  }

  const parseResponse = (response: any): number => {
    const potentialResponse = response ? parseInt(response, 10) : 0;
    return !isNaN(potentialResponse) ? potentialResponse : 0;
  };

  React.useEffect(() => {
    const evalResp = existingResponse.find((r: any) => r.optionId === optionId);
    const value =
      evalResp && evalResp[evaluationVariable]
        ? parseResponse(evalResp[evaluationVariable])
        : 0;

    if (selectedValue !== value) {
      setSelectedValue(value);
    }
  }, [existingResponse, evaluationVariable, optionId]);

  return (
    <Scale1To5
      label={label}
      showLabel={showLabel}
      editMode={editMode}
      value={selectedValue}
      onChange={handleChange}
    />
  );
}

const enhance = compose<any, any>(withStyles(evaluationOptionsStyles));

export default enhance(EvaluationOptions);

import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const scaleComponentStyles: StyleRulesCallback = () => ({
  typography: {
    whiteSpace: 'pre-wrap',
    paddingTop: '20px',
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontSize: '0.875em',
    fontWeight: 'bold',
  },
  controlContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
  },
  controlContainerLg: {
    maxWidth: '200px',
  },
});

export default scaleComponentStyles;

import { compose } from 'recompose';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { ReducerState } from '@/app/redux/store';
import tabsConfiguratorStyle from './tabsConfiguratorStyle';
import {
  fetchAllTabsOptions,
  getActiveTabs,
  setActiveTabs,
} from '@/app/redux/adminSection';
import {
  WithStyles,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Button,
} from '@material-ui/core';
import translate from '@/app/utils/translate';
import Loading from '@/app/components/Loading/Loading';
import {
  ENABLED_TABS_PEOPLE,
  ENABLED_TABS_PERFORMANCE,
  ENABLED_TABS_DEVELOPMENT,
  ENABLED_TABS_PLANNING,
  ENABLED_TABS_REWARDING,
  forTranslation,
  PEOP_BASIC,
} from '../../utils/constants';

type PropsType = MapStateToProps &
  MapDispatchToProps &
  WithStyles<typeof tabsConfiguratorStyle> & {
    area: string;
    disabledTabs?: string[];
  };

type MapStateToProps = {
  AllTabsOptions: string[];
  activePeopleTabs: string[];
  activePerformanceTabs: string[];
  activeDevelopmentTabs: string[];
  activePlanningTabs: string[];
  activeRewardingTabs: string[];
};

type MapDispatchToProps = {
  fetchAllTabsOptions: () => any;
  getActiveTabs: (module: string) => any;
  setActiveTabs: (area: string, tabs: string[]) => any;
};

type StateType = {
  activeTabs: string[];
  reload: boolean;
  disableSave: boolean;
};

class TabsConfigurator extends React.PureComponent<PropsType> {
  state: StateType = {
    activeTabs: ['empty'],
    disableSave: false,
    reload: false,
  };

  componentDidMount() {
    this.props.fetchAllTabsOptions();
    this.props.getActiveTabs(this.props.area);
  }

  componentDidUpdate = (prevProps: any) => {
    if (prevProps.area !== this.props.area) {
      this.setState({ reload: true });
      this.props.getActiveTabs(this.props.area);
      setTimeout(() => this.setState({ reload: false }), 750);
    }
    if (
      prevProps.activePeopleTabs !== this.props.activePeopleTabs ||
      prevProps.activePerformanceTabs !== this.props.activePerformanceTabs ||
      prevProps.activeDevelopmentTabs !== this.props.activeDevelopmentTabs ||
      prevProps.activePlanningTabs !== this.props.activePlanningTabs ||
      prevProps.activeRewardingTabs !== this.props.activeRewardingTabs
    ) {
      if (this.props.area === ENABLED_TABS_PEOPLE) {
        this.setState({ activeTabs: this.props.activePeopleTabs });
      }
      if (this.props.area === ENABLED_TABS_PERFORMANCE) {
        this.setState({ activeTabs: this.props.activePerformanceTabs });
      }
      if (this.props.area === ENABLED_TABS_DEVELOPMENT) {
        this.setState({ activeTabs: this.props.activeDevelopmentTabs });
      }
      if (this.props.area === ENABLED_TABS_PLANNING) {
        this.setState({ activeTabs: this.props.activePlanningTabs });
      }
      if (this.props.area === ENABLED_TABS_REWARDING) {
        this.setState({ activeTabs: this.props.activeRewardingTabs });
      }
    }
  };

  updateActiveTabs = () => {
    let tabs = this.state.activeTabs;
    this.props.setActiveTabs(this.props.area, tabs);
    this.setState({ disableSave: true });
    setTimeout(() => this.setState({ disableSave: false }), 1000);
  };

  handleChange = (tab: string) => {
    let currentTabs = this.state.activeTabs;
    if (tab === PEOP_BASIC) {
      return;
    }
    if (currentTabs.includes(tab)) {
      currentTabs = currentTabs.filter(eachTab => eachTab !== tab);
    } else {
      currentTabs = [...currentTabs, tab];
    }
    this.setState({ activeTabs: currentTabs });
  };

  render() {
    const { classes, AllTabsOptions, area, disabledTabs } = this.props;
    const { activeTabs, disableSave, reload } = this.state;

    let filterKind: string;
    switch (area) {
      case ENABLED_TABS_PEOPLE:
        filterKind = 'PEOP';
        break;
      case ENABLED_TABS_PERFORMANCE:
        filterKind = 'PERF';
        break;
      case ENABLED_TABS_DEVELOPMENT:
        filterKind = 'DEV';
        break;
      case ENABLED_TABS_PLANNING:
        filterKind = 'PLAN';
        break;
      case ENABLED_TABS_REWARDING:
        filterKind = 'REW';
        break;
      default:
        break;
    }

    return (
      <>
        <Paper className={classes.root}>
          {activeTabs && !reload ? (
            <Table>
              <TableBody>
                {AllTabsOptions.filter((option: string) => {
                  return (
                    option.slice(0, option.indexOf('_')) === filterKind &&
                    !(disabledTabs && disabledTabs.includes(option))
                  );
                }).map((tab, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      key={`${index}-row`}
                      selected={tab === PEOP_BASIC}
                    >
                      <TableCell
                        onClick={() => this.handleChange(tab)}
                        className={classes.tablecell}
                        key={`index-${tab}`}
                        padding="checkbox"
                      >
                        <Checkbox
                          disabled={tab === PEOP_BASIC}
                          checked={activeTabs.includes(tab)}
                        />
                        {translate.t(forTranslation[tab] || tab)}
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow>
                  <TableCell>
                    <Button
                      onClick={this.updateActiveTabs}
                      disabled={disableSave}
                    >
                      {translate.t('laSave')}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          ) : (
            <Loading />
          )}
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state: ReducerState) => {
  const {
    AllTabsOptions,
    activePeopleTabs,
    activePerformanceTabs,
    activeDevelopmentTabs,
    activeRewardingTabs,
    activePlanningTabs,
  } = state.adminSection;

  return {
    AllTabsOptions,
    activePeopleTabs,
    activePerformanceTabs,
    activeDevelopmentTabs,
    activeRewardingTabs,
    activePlanningTabs,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAllTabsOptions: () => dispatch<any>(fetchAllTabsOptions()),
  getActiveTabs: (area: string) => dispatch<any>(getActiveTabs(area)),
  setActiveTabs: (area: string, tabs: string[]) =>
    dispatch<any>(setActiveTabs(area, tabs)),
});

const enhance = compose<any, any>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(tabsConfiguratorStyle),
);

export default enhance(TabsConfigurator);

import { useCallback, useEffect, useMemo, useState } from 'react';
import { Role } from '@/app/hooks/useRoles';
import { QuickLink } from '@/app/redux/quickLinks';
import translate from '@/app/utils/translate';
import { forTranslation } from '@/app/utils/constants';
import { getTranslation } from '../utils';

const GLOBAL: any = window;
const showNewEmpForms = GLOBAL.jsIsNewDeploymentType
  && GLOBAL.jsEmpTemplatesActive
  && GLOBAL.jsIsNewDeploymentType()
  && GLOBAL.jsEmpTemplatesActive();

type DropdownSelectItem = {
  value: string;
  label: string;
};

enum NavigatableModule {
  PEOPLE = 'PEOPLE',
  REWARDING = 'REWARDING',
  PERFORMANCE = 'PERFORMANCE',
  DEVELOPMENT = 'DEVELOPMENT',
  ANALYTICS = 'ANALYTICS',
  COLLABORATION = 'COLLABORATION',
  ORGANIZATION = 'ORGANIZATION',
  RECRUITING = 'RECRUITING',
  PLANNING = 'PLANNING',
  SF2 = 'SF2',
}

const analyticsNavAreas = [
  'ANALYTICS_COMPANY_DASHBOARD',
  'ANALYTICS_TEAM_DASHBOARD',
  'ANALYTICS_HR_DASHBOARD',
  'ANALYTICS_EMP_DASHBOARD',
  'ANALYTICS_WORK_EXP_DASHBOARD',
  'ANALYTICS_PERSONNEL_LIST_CUSTOM',
  'ANALYTICS_ABSENCE_REPORT',
];

const recruitingTabs = [
  'REC_JOB_OPENINGS',
  'REC_NEW_POSITIONS',
  'REC_ARCHIVED_CANDIDATES',
  'REC_PROVISIONAL_EMPLOYEES',
];

const peopleNavAreas = [
  'PEOP_NEW_EMPLOYEE',
  ...(showNewEmpForms ? ['PEOP_NEW_EMPLOYEE_FORMS'] : []),
];

const collaborationNavAreas = [
  'COLLAB_TODO_TASKS',
  'COLLAB_MANAGE_SURVEYS',
];

const planningNavAreas = [
  'PLAN_SUCCESSION',
  'PLAN_TALENT_MANAGEMENT',
  'PLAN_BONUS_CALC',
];

const organizationNavAreas = [
  // 'ORG_LEGAL_ENTITIES',
  // 'ORG_OFFICES',
  // 'ORG_COST_CENTERS',
  // 'ORG_UNITS',
  // 'ORG_UNIT_POSITIONS',
  'ORG_TREES',
];

const sf2NavAreas = [
  'SF2_WORKSPACE',
  'SF2_HOMEPAGE',
];

const areaTranslationMapping = {
  ...forTranslation,
  ANALYTICS_COMPANY_DASHBOARD: 'laCompDB',
  ANALYTICS_TEAM_DASHBOARD: 'laTeamDB',
  ANALYTICS_HR_DASHBOARD: 'laHRDB',
  ANALYTICS_EMP_DASHBOARD: 'laEmpDB',
  ANALYTICS_WORK_EXP_DASHBOARD: 'laWEDB',
  ANALYTICS_PERSONNEL_LIST_CUSTOM: 'laCustomPersonelList',
  ANALYTICS_ABSENCE_REPORT: 'laAbsencesReport',
  COLLAB_TODO_TASKS: 'laTodoTasks',
  COLLAB_MANAGE_SURVEYS: 'laManageSurveys',
  // ORG_LEGAL_ENTITIES: 'laLegalEntities',
  // ORG_OFFICES: 'laOffices',
  // ORG_COST_CENTERS: 'laCCs',
  // ORG_UNITS: 'laOrgUnits',
  // ORG_UNIT_POSITIONS: 'laOrgPosition',
  ORG_TREES: 'laOrgTrees',
  PEOP_NEW_EMPLOYEE: 'laNewEmployee',
  PEOP_NEW_EMPLOYEE_FORMS: 'laNewEmployeeForms',
  PLAN_SUCCESSION: 'laSuccPlan',
  PLAN_TALENT_MANAGEMENT: 'laTalentManagement',
  PLAN_BONUS_CALC: 'title_bonus_calc_planning',
  REC_JOB_OPENINGS: 'laOpenPositions',
  REC_NEW_POSITIONS: 'laNewPositions',
  REC_ARCHIVED_CANDIDATES: 'laArchivedCandi',
  REC_PROVISIONAL_EMPLOYEES: 'laPendingEmp',
  SF2_WORKSPACE: 'laWorkspace',
  SF2_HOMEPAGE: 'laHome',
};

const getNavModuleTranslationKey = (moduleKey: string) => {
  switch (moduleKey) {
    case NavigatableModule.PEOPLE:
      return 'laPeople';
    case NavigatableModule.REWARDING:
      return 'laRewarding';
    case NavigatableModule.PERFORMANCE:
      return 'laPerformance';
    case NavigatableModule.DEVELOPMENT:
      return 'laDevelopment';
    case NavigatableModule.ANALYTICS:
      return 'laAnalytics';
    case NavigatableModule.RECRUITING:
      return 'laRecruiting';
    case NavigatableModule.COLLABORATION:
      return 'laCollaboration';
    case NavigatableModule.PLANNING:
      return 'laPlanning';
    case NavigatableModule.ORGANIZATION:
      return 'laOrganization';
    case NavigatableModule.SF2:
      return 'laSF2';
    default:
      return moduleKey;  
  }
};

const getTabsBasedOnMapping = (
  moduleKey: string,
  activePeopleTabs: string[],
  activePerformanceTabs: string[],
  activeDevelopmentTabs: string[],
  activeRewardingTabs: string[],
) => {
  switch (moduleKey) {
    case NavigatableModule.PEOPLE:
      return [...activePeopleTabs, ...peopleNavAreas].sort();
    case NavigatableModule.REWARDING:
      return [...activeRewardingTabs].sort();
    case NavigatableModule.PERFORMANCE:
      return [...activePerformanceTabs].sort();
    case NavigatableModule.DEVELOPMENT:
      return [...activeDevelopmentTabs].sort();
    case NavigatableModule.ANALYTICS:
      return [...analyticsNavAreas].sort();
    case NavigatableModule.RECRUITING:
      return [...recruitingTabs].sort();
    case NavigatableModule.COLLABORATION:
      return [...collaborationNavAreas].sort();
    case NavigatableModule.PLANNING:
      return [...planningNavAreas].sort();
    case NavigatableModule.ORGANIZATION:
      return [...organizationNavAreas].sort();
    case NavigatableModule.SF2:
      return [...sf2NavAreas].sort();
    default:
      return [];  
  }
};

export const mapQuickLinkToForm = (
  quickLink: QuickLink,
  languageCode: string,
  roles: Role[],
  allModules: DropdownSelectItem[],
  allTabs: DropdownSelectItem[],
) => {
  const currentTr = getTranslation(languageCode, quickLink.translations, true);
  const module = quickLink.navigation
    ? allModules.find(m => m.value === quickLink.navigation.module)
    : undefined;

  const tab = quickLink.navigation && module
    ? allTabs.find(t => t.value === quickLink.navigation.tab)
    : undefined;

  return {
    ...quickLink,
    name: currentTr ? currentTr.name : '',
    description: currentTr ? currentTr.description : '',
    ...(quickLink.navigation ? {
      module,
      tab,
    } : {}),
    roles: quickLink.roles.map(role => {
      const roleDetails = roles.find(roleItem => roleItem.code === role);

      return {
        label: roleDetails.name,
        value: roleDetails.code,
      };
    }),
  };
};

export const useModuleTabSelection = (
  existingNavConfig: undefined | { module: string, tab: string },
  moduleStatus: Record<string, boolean>,
  allTabs: string[],
  activePeopleTabs: string[],
  activePerformanceTabs: string[],
  activeDevelopmentTabs: string[],
  activeRewardingTabs: string[]
) => {
  const [
    availableTabsSelection,
    setAvailableTabsSelection
  ] = useState<DropdownSelectItem[]>([]);
  const allNavModulesSelection = Object.values(NavigatableModule)
    .map((navMod) => ({
      value: navMod,
      label: translate.t(getNavModuleTranslationKey(navMod))
    }));

  const moduleSelection = useMemo(() => {
    const enabledModules = Object.keys(moduleStatus)
      .filter(moduleKey => moduleStatus[moduleKey]);

    return Object.values(NavigatableModule).filter((navMod) => {
      if (navMod === NavigatableModule.RECRUITING) {
        return enabledModules.includes('RECRUITING_FULL')
          || enabledModules.includes('RECRUITING_MINI');
      }

      if (navMod === NavigatableModule.ORGANIZATION) {
        return enabledModules.includes('ORGANISATION');
      }

      if (navMod === NavigatableModule.SF2) {
        return enabledModules.includes('UI2');
      }

      return enabledModules.includes(navMod);
    }).map((navMod) => ({
      value: navMod,
      label: translate.t(getNavModuleTranslationKey(navMod))
    }));
  }, [moduleStatus]);

  const allTabsSelection = useMemo(() => [
    ...allTabs,
    ...peopleNavAreas,
    ...analyticsNavAreas,
    ...recruitingTabs,
    ...collaborationNavAreas,
    ...planningNavAreas,
    ...organizationNavAreas,
    ...sf2NavAreas,
  ].map((tab) => ({
    value: tab,
    label: translate.t(areaTranslationMapping[tab])
  })), [allTabs]);

  const getAvailableTabSelection = useCallback((moduleKey: string) => getTabsBasedOnMapping(
    moduleKey,
    activePeopleTabs,
    activePerformanceTabs,
    activeDevelopmentTabs,
    activeRewardingTabs
  ).map((tab) => ({
    value: tab,
    label: translate.t(areaTranslationMapping[tab])
  })), [
    activePeopleTabs,
    activePerformanceTabs,
    activeDevelopmentTabs,
    activeRewardingTabs,
  ]);

  const onSelectedModuleChange = (selectedItem: DropdownSelectItem) => {
    setAvailableTabsSelection(getAvailableTabSelection(selectedItem.value));
  };

  useEffect(() => {
    if (existingNavConfig && existingNavConfig.module) {
      setAvailableTabsSelection(getAvailableTabSelection(existingNavConfig.module));
    } else {
      setAvailableTabsSelection([]);
    }
  }, [existingNavConfig, getAvailableTabSelection, setAvailableTabsSelection]);

  return {
    allTabsSelection,
    allNavModulesSelection,
    availableTabsSelection,
    moduleSelection,
    onSelectedModuleChange,
  };
};

import React from 'react';
import { compose } from 'recompose';
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { WithStyles, Typography, Paper, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import translate from '@/app/utils/translate';
import { withStyles } from '@material-ui/core/styles';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import sortableHOCStyles from './sortableHOCStyles';

type IncomingProps = {
  open: boolean,
  itemsToSort: object[],
  getItemValue?: (item: object) => string | React.JSX.Element,
  saveNewOrder:  (items: object[]) => void,
  cancelNewOrder: () => void
};

interface OwnProps {
  showDragHandle?: boolean;
}

type PropsType = IncomingProps & OwnProps & WithStyles<typeof sortableHOCStyles>;

type StateType = { items: [] };

class SortableHOC extends React.PureComponent<PropsType> {
  state: StateType = {
    items: []
  };

  constructor(props: any) {
    super(props);
    this.state = {
      items: props.itemsToSort
    };
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.itemsToSort !== this.props.itemsToSort || !prevProps.open && this.props.open) {
      this.setState({ items: this.props.itemsToSort });
    }
  }

  onSortEnd = ({ oldIndex, newIndex }: any) => {
    this.setState(({ items }: any) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }));
  };

  DragHandle = SortableHandle(() => <DragHandleIcon />);

  render() {
    const { open, getItemValue, saveNewOrder, cancelNewOrder, classes } = this.props;

    const SortableList = SortableContainer(({children}: any) => {
      return <ul>{children}</ul>;
    });

    const SortableItem = SortableElement(({ value }: { value: any }) => {
      return (
        <Paper className={classes.listElements} style={{ zIndex: 50000 }} elevation={1}>
          <Typography variant="subtitle1">{value}</Typography>
          {this.props.showDragHandle ? <this.DragHandle /> : null}
        </Paper>
      );
    });

    return (
      <Dialog open={open}>
        <DialogTitle>{translate.t('laSort')}</DialogTitle>

        <DialogContentText className={classes.context}>{translate.t('laSortIns')}</DialogContentText>

        <DialogContent>
          <SortableList onSortEnd={this.onSortEnd}>
            {this.state.items.map((value: any, index) => {
                const v = (getItemValue && getItemValue(value)) || value.name || value.label;
                return <SortableItem key={`item-${v}-${index}`} index={index} value={v} />;
            })}
          </SortableList>
        </DialogContent>

        <DialogActions>
          <Button color="primary" variant="text" onClick={cancelNewOrder}>
            {translate.t('laCancel')}
          </Button>

          <Button color="primary" variant="contained" onClick={() => saveNewOrder(this.state.items)}>
            {translate.t('laSave')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const enhance = compose<any, any>(withStyles(sortableHOCStyles));

export default enhance(SortableHOC);

import { getEnums } from '@/old/utils/helper';
import {
  Company,
  LongAbsence,
  MappedData,
  OtherPosition,
  PreviousCompany,
  PreviousLongAbsence,
  WorkExperienceType,
} from './types';

export const workExperienceDataMapper = (data: WorkExperienceType) => {
  const positionEnums = getEnums('EMPLOYEE_POSITION');
  const absenceLongReasonEnums = getEnums('ABSENCE_LONG_REASON');
  const previousAbsenceLongReasonEnums = getEnums('ABSENCE_PREVIOUS_REASON');
  return {
    employment: data.fCurrentCompany.map((company: Company) => {
      return {
        position: positionEnums.find(
          position => position.code === company.internalPositionPosition,
        ).name,
        startDate: company.internalPositionStartDate
          ? company.internalPositionStartDate
          : '',
        endDate: company.internalPositionEndDate
          ? company.internalPositionEndDate
          : '',
      };
    }),

    longTermAbsences: data.fEmpCurrentAbsLong.map((absence: LongAbsence) => {
      return {
        absenceReason: absenceLongReasonEnums.find(
          absenceEnum => absenceEnum.code === absence.absenceLongReason,
        ).name,
        startDate: absence.absenceLongStartDate
          ? absence.absenceLongStartDate
          : '',
        endDate: absence.absencenceLongEndDate
          ? absence.absencenceLongEndDate
          : '',
        info: absence.absenceLongInfo,
      };
    }),

    previousCompany: data.fEmpPreviousCompany.map(
      (company: PreviousCompany) => {
        return {
          previousCompany: company.externalPositionCompany,
          position: company.externalPositionPosition,
          startDate: company.externalPositionStartDate
            ? company.externalPositionStartDate
            : '',
          endDate: company.externalPositionEndDate
            ? company.externalPositionEndDate
            : '',
          expInDays: company.externalPositionDays
           ? company.externalPositionDays : '',
          info: company.externalPositionInfo,
          experience: company.externalPositionType,
        };
      },
    ),

    previousAbsences: data.fEmpPreviousAbsLong.map(
      (prevAbs: PreviousLongAbsence) => {
        return {
          absenceReason: previousAbsenceLongReasonEnums.find(
            absenceEnum =>
              absenceEnum.code === prevAbs.absenceLongPreviousReason,
          ).name,
          startDate: prevAbs.absenceLongPreviousStartDate
            ? prevAbs.absenceLongPreviousStartDate
            : '',
          endDate: prevAbs.absenceLongPreviousEndDate
            ? prevAbs.absenceLongPreviousEndDate
            : '',
          days: prevAbs.absenceLongPreviousDays
            ? prevAbs.absenceLongPreviousDays
            : '',
          info: prevAbs.absenceLongPreviousInfo,
        };
      },
    ),

    otherExperience: data.fEmpOtherPosition.map((otherExp: OtherPosition) => {
      return {
        position: otherExp.otherPositionTitle,
        positionSubtitle: otherExp.otherPositionSubtitle
          ? otherExp.otherPositionSubtitle
          : '',
        startDate: otherExp.otherPositionStartDate
          ? otherExp.otherPositionStartDate
          : '',
        endDate: otherExp.otherPositionEndDate
          ? otherExp.otherPositionEndDate
          : '',
        info: otherExp.otherPositionInfo,
      };
    }),

    workExperience: {
      totalWorkExperience: data.fEmpTotalDays,
      convertYears: data.fEmpTotalYears,
      convertMonths: data.fEmpTotalMonthsRemaining,
      convertDays: data.fEmpTotalDaysRemaining,
    },
  } as MappedData;
};

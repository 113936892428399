import * as React from 'react';
import * as ReactDOM from 'react-dom';

import SendToApprovalFormDialog, { SendToApproval } from './SendToApprovalFormDialog';

interface PropsType {
  data: Array<{
    data: SendToApproval;
    containerId: string;
  }>;
}

export default class SendToApprovalFormDialogList extends React.Component<PropsType> {
  render () {
    return this.props.data.map(({ data, containerId }) => {
      return ReactDOM.createPortal(<SendToApprovalFormDialog data={data} />, document.getElementById(containerId));
    });
  }
}

import * as React from 'react';

export interface NewLineToBrProps {
    value: string;
}

export const NewLineToBr: React.FC<NewLineToBrProps> = ({ value }) => (
    <>
        {
            value.split('\n').map((line, index) => {
                return (
                    <React.Fragment key={index}>
                        {index > 0 && <br />}
                        {line}
                    </React.Fragment>
                );
            })
        }
    </>
);
import React, { useEffect, FC, useState } from 'react';
import { compose } from 'recompose';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import translate from '@/app/utils/translate';
import scoreComponentStyles from './scoreComponentStyles';
import {
  ChainGroup,
  ComponentItem,
  GroupEnumComponent,
  GroupEnums,
  GroupValues,
  HeaderElement,
  ScoreElement,
} from '@/app/components/JobEvaluation/types';
import Loading from '@/app/components/Loading/Loading';
import { TemplateInputComponentPropsType } from '@/app/components/TemplateComponents/form/InputComponents';
import { resolveComponentResponse } from '@/app/components/TemplateComponents/form/InputComponents/data';

type IncomingProps = TemplateInputComponentPropsType & {
  groupEnums: GroupEnums;
  component: ComponentItem;
  handleSectionScoreChange?: (componentId: string, newScore: number) => void;
};

type PropsType = IncomingProps & WithStyles<typeof scoreComponentStyles>;

const ScoreComponent: FC<PropsType> = (props: PropsType) => {
  const {
    templateId,
    component,
    editMode,
    handleSectionScoreChange,
    groupEnums,
    onUpdate,
    classes,
  } = props;
  const [groupValues, setGroupValues] = useState({});
  const [evaluationScore, setEvaluationScore] = useState(0);

  const handleComponentChange = (
    componentId: string,
    response: ScoreElement,
  ) => {
    if (!!onUpdate) {
      onUpdate(templateId, {
        componentId,
        response,
        type: 'SCORE',
      });
    }
  };

  const getEnumsByGroupName = (groupName: string): GroupEnumComponent[] => {
    return groupEnums && groupEnums[groupName] ? groupEnums[groupName] : [];
  };

  const resolveResponse = (properties: PropsType): ScoreElement => {
    const potentialResponse = resolveComponentResponse(
      properties.component,
      properties.responses,
      undefined,
    );

    return !potentialResponse || !potentialResponse.chain.length
      ? { chain: undefined, score: undefined }
      : potentialResponse;
  };

  const { score: responseScore, chain: responseChain } = resolveResponse(props);

  const findEnumCombination = (
    updatedGroupValues: GroupValues,
    chainGroups: ChainGroup[],
  ) => {
    let find = false;
    for (const group in updatedGroupValues) {
      if (updatedGroupValues.hasOwnProperty(group)) {
        find = chainGroups.some(
          (chainElement: ChainGroup) =>
            chainElement.group === group &&
            chainElement.enum === updatedGroupValues[group],
        );
        if (find === false) {
          break;
        }
      }
    }
    return find;
  };

  const createChainForNoCombination = (updatedGroupValues: GroupValues) =>
    Object.keys(updatedGroupValues).map(key => {
      return { group: key, enum: updatedGroupValues[key] };
    });

  const groupValuesChanged = (
    initial: boolean,
    updatedGroupValues: GroupValues,
  ) => {
    let getScoreValue = 0;
    let indexOfScore = -1;
    component.enumScoreGroup.scores.forEach(
      (score: ScoreElement, index: number) => {
        if (indexOfScore !== -1) {
          return;
        }
        if (findEnumCombination(updatedGroupValues, score.chain)) {
          getScoreValue = score.score;
          indexOfScore = index;
        }
      },
    );
    if (!initial) {
      handleComponentChange(
        component.id,
        indexOfScore === -1
          ? {
              score: 0,
              chain: createChainForNoCombination(updatedGroupValues),
            }
          : component.enumScoreGroup.scores[indexOfScore],
      );
    }
    if (evaluationScore !== getScoreValue) {
      if (!!handleSectionScoreChange) {
        handleSectionScoreChange(component.id, getScoreValue);
      }
      setEvaluationScore(getScoreValue);
    }
  };

  useEffect(() => {
    let stateValue = {};
    if (responseChain) {
      responseChain.forEach((response: ChainGroup) => {
        stateValue[response.group] = response.enum;
      });
    }
    component.enumScoreGroup.headers.forEach((header: HeaderElement) => {
      if (!stateValue[header.group]) {
        stateValue[header.group] = '';
      }
    });
    setGroupValues(stateValue);
    if (responseScore !== undefined) {
      if (!!handleSectionScoreChange) {
        handleSectionScoreChange(component.id, responseScore);
      }
      setEvaluationScore(responseScore);
    }
  }, [responseChain]);

  useEffect(() => {
    if (editMode) {
      groupValuesChanged(false, groupValues);
    }
  }, [editMode]);

  const handleChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    group: string,
  ) => {
    const groupValueChanged = {
      ...groupValues,
      [group]: event.target.value as string,
    };
    setGroupValues(groupValueChanged);
    groupValuesChanged(false, groupValueChanged);
  };

  const noValuesSelected =
    component.enumScoreGroup !== undefined &&
    component.enumScoreGroup.headers.every(
      (header: any) => !groupValues[header.group],
    );

  const selectGroup = component.enumScoreGroup.headers.map((header: any) => {
    const hideOrShow = groupValues[header.group]
      ? classes.select
      : classes.hide;
    const enumGroupElements = getEnumsByGroupName(header.group);
    const enumGroupElementsNotExist = enumGroupElements.length === 0;
    const returnValue = (
      <FormControl
        key={header.group}
        className={!editMode ? hideOrShow : classes.select}
      >
        <InputLabel htmlFor="criteria-select">
          {header.group.split('_')[5]}
        </InputLabel>
        <Tooltip
          disableHoverListener={!enumGroupElementsNotExist}
          disableFocusListener={!enumGroupElementsNotExist}
          disableTouchListener={!enumGroupElementsNotExist}
          title={translate.t('enumGroupDeleted')}
        >
          <Select
            disabled={!editMode || enumGroupElementsNotExist}
            inputProps={{
              id: 'criteria-select',
            }}
            value={groupValues[header.group] ? groupValues[header.group] : ''}
            onChange={event => handleChange(event, header.group)}
          >
            {enumGroupElements.map(
              (groupElement: GroupEnumComponent, groupIndex: number) => {
                if (!groupElement.isActive) {
                  return null; // Display nothing if isActive is false
                }

                return (
                  <MenuItem value={groupElement.code} key={groupIndex}>
                    {groupElement.name}
                  </MenuItem>
                );
              },
            )}
            {enumGroupElements.length === 0 && (
              <MenuItem key={'placeholder'} value={groupValues[header.group]}>
                {groupValues[header.group]}
              </MenuItem>
            )}
          </Select>
        </Tooltip>
      </FormControl>
    );

    return returnValue;
  });

  const selectGroupOrLoader =
    component.enumScoreGroup !== undefined ? (
      selectGroup
    ) : (
      <Loading size="small" />
    );
  return (
    <Grid container alignItems="center">
      <Grid item xs={8}>
        {noValuesSelected && !editMode ? (
          <Typography className={classes.noValue} variant="h6">
            {translate.t('noValuesSelected')}
          </Typography>
        ) : (
          selectGroupOrLoader
        )}
      </Grid>
      <Grid item xs={4}>
        <Typography variant="h6">
          {translate.t('laResult')} {evaluationScore}
        </Typography>
      </Grid>
    </Grid>
  );
};

const enhance = compose<PropsType, IncomingProps>(
  withStyles(scoreComponentStyles),
);

export default enhance(ScoreComponent);

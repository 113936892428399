import { StyleRulesCallback, Theme } from '@material-ui/core';

const styles: StyleRulesCallback = (theme: Theme) => ({
  button: {
    textTransform: 'none',
    fontWeight: 'normal',
    marginLeft: -1 * theme.spacing.unit,
  },
});

export default styles;

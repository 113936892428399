import * as React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/core';
import bonusCalculationRulesStyle from './bonusCalculationRulesStyle';
import { Dispatch } from 'redux';
import { reset } from 'redux-form';
import EnhancedTable from '../../EnhancedTable/EnhancedTable';
import { Tools } from '../../EnhancedTable/EnhancedTableToolbar/EnhancedTableToolbar';
import { HeadData } from '../../EnhancedTable/EnhancedTableHead/EnhancedTableHead';
import translate from '@/app/utils/translate';
import BonusCalculationRuleFormDialog from '../BonusCalculationRuleFormDialog/BonusCalculationRuleFormDialog';
import { throwError } from '@/app/redux/error';
import {
  getCurrency,
  fetchRules,
  activateRules,
  deactivateRules,
  deleteRules,
  editRule,
  addRule,
} from '@/app/redux/bonusCalc';
import {
  openConfirmDialog,
  ConfirmDialogType,
} from '@/app/redux/confirmDialog';

const TOOLS: Tools = {
  showAdd: true,
  showEdit: true,
  showActive: true,
  showDelete: true,
};

export type BonusRule = {
  id: number;
  active: boolean;
  fRuleId: number;
  fRuleName: string;
  fRuleFormula: string;
  fRuleActive: boolean;
  fBonusValue?: string;
  fMonthlySalary?: string;
  fScore?: string;
  fTargetEarning?: string;
  fAnnualSalary?: string;
};

interface MapStateToProps {
  rules: BonusRule[];
}

interface MapDispatchToProps {
  throwError: (err: any) => void;
  resetForm: (formName: string) => void;
  getCurrency: () => void;
  fetchRules: () => void;
  activateRules: (ruleIds: number[]) => void;
  deactivateRules: (ruleIds: number[]) => void;
  deleteRules: (ruleIds: number[]) => void;
  editRule: (rule: BonusRule) => void;
  addRule: (rule: BonusRule) => void;
  openConfirmDialog: (payload: ConfirmDialogType) => void;
}

type State = {
  formDialogOpen: boolean;
  formDialogTitle: string;
  rule: BonusRule;
};

type PropsType = MapStateToProps &
  MapDispatchToProps &
  WithStyles<typeof bonusCalculationRulesStyle>;

class BonusCalculationRules extends React.PureComponent<PropsType> {
  state: State = {
    formDialogOpen: false,
    formDialogTitle: '',
    rule: null,
  };

  edit: boolean = false;

  componentDidMount() {
    this.props.getCurrency();
    this.props.fetchRules();
  }

  handleAdd = (_event: React.MouseEvent) => {
    this.formDialogOpen(translate.t('title_add_bonus_calc_rule'));
  };

  formDialogOpen = (title: string) => {
    this.setState({ formDialogOpen: true, formDialogTitle: title });
  };

  handleFormDialogClose = () => {
    this.setState({
      rule: null,
      formDialogOpen: false,
    });
  };

  handleEdit = (selectedItems: Array<any>) => (_event: React.MouseEvent) => {
    const selectedIds = selectedItems.map((s: any) => s.id);
    const { rules } = this.props;
    const index = rules.findIndex((r) => r.fRuleId === selectedIds[0]);
    this.setState({ rule: rules[index] });
    this.edit = true;

    this.formDialogOpen(translate.t('title_edit_bonus_calc_rule'));
  };

  handleDelete = (selectedItems: Array<any>) => (_event: React.MouseEvent) => {
    const selectedIds = selectedItems.map((s: any) => s.id);
    this.props.openConfirmDialog({
      text: translate.t('confirm_delete_item_s'),
      onOk: () => this.props.deleteRules(selectedIds),
    });
  };

  handleActivate = (selectedItems: Array<any>) => (
    _event: React.MouseEvent,
  ) => {
    const selectedIds = selectedItems.map((s: any) => s.id);
    this.props.openConfirmDialog({
      text: translate.t('confirm_activate_item_s'),
      onOk: () => this.props.activateRules(selectedIds),
    });
  };

  handleDeactivate = (selectedItems: Array<any>) => (
    _event: React.MouseEvent,
  ) => {
    const selectedIds = selectedItems.map((s: any) => s.id);
    this.props.openConfirmDialog({
      text: translate.t('confirm_deactivate_item_s'),
      onOk: () => this.props.deactivateRules(selectedIds),
    });
  };

  handleSubmit = (values: BonusRule) => {
    if (this.edit) {
      this.props.editRule(values);
    } else {
      this.props.addRule(values);
    }

    this.handleFormDialogClose();
    this.edit = false;
  };

  render() {
    const headData: Array<HeadData> = [
      {
        id: 'fRuleName',
        type: 'string',
        disablePadding: false,
        label: translate.t('table_col_rule_name'),
      },
      {
        id: 'fRuleFormula',
        type: 'string',
        disablePadding: false,
        label: translate.t('table_col_formula'),
      },
    ];

    const { resetForm, rules } = this.props;
    const { formDialogOpen, formDialogTitle } = this.state;

    return (
      <>
        <BonusCalculationRuleFormDialog
          open={formDialogOpen}
          onClose={this.handleFormDialogClose}
          onSubmit={this.handleSubmit}
          title={formDialogTitle}
          data={this.state.rule}
          resetForm={resetForm}
        />
        <EnhancedTable
          title={translate.t('title_bonus_calc_rules')}
          tools={TOOLS}
          addHandler={this.handleAdd}
          editHandler={this.handleEdit}
          deleteHandler={this.handleDelete}
          deactivateHandler={this.handleDeactivate}
          activateHandler={this.handleActivate}
          headData={headData}
          data={rules}
          order={'asc'}
          orderBy={'fRuleName'}
        />
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { rules } = state.bonusCalc;

  return {
    rules,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  throwError: (err: any) => dispatch(throwError(err)),
  resetForm: (formName: string) => dispatch(reset(formName)),
  getCurrency: () => dispatch<any>(getCurrency()),
  fetchRules: () => dispatch<any>(fetchRules()),
  activateRules: (ruleIds: number[]) => dispatch<any>(activateRules(ruleIds)),
  deactivateRules: (ruleIds: number[]) =>
    dispatch<any>(deactivateRules(ruleIds)),
  deleteRules: (ruleIds: number[]) => dispatch<any>(deleteRules(ruleIds)),
  editRule: (rule: BonusRule) => dispatch<any>(editRule(rule)),
  addRule: (rule: BonusRule) => dispatch<any>(addRule(rule)),
  openConfirmDialog: (payload: ConfirmDialogType) =>
    dispatch(openConfirmDialog(payload)),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(bonusCalculationRulesStyle),
);

export default enhance(BonusCalculationRules);

import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Dispatch } from 'redux';
import { TextField } from '@material-ui/core';
import API from '@/app/api/internalAPIs';
import Service from '@/app/utils/service';
import translate from '@/app/utils/translate';
import { translateSection } from '@/app/redux/goals';
import TranslationDialog from '../../TranslationDialog/TranslationDialog';

type IncomingProps = {
  templateId: string;
  sectionId: string;
  handleModalClose: () => void;
};

type MapDispatchToProps = {
  translateSection: (language: string, templateId: string, sectionId: string, translation: any) => any;
};

type PropsType = IncomingProps & MapDispatchToProps;

type StateType = {
  localizedSection: any;
  title: string;
  showValidationError: boolean;
  disabledSave: boolean;
};

class TranslateSection extends React.PureComponent<PropsType> {
  state: StateType = {
    localizedSection: undefined,
    title: '',
    showValidationError: false,
    disabledSave: true,
  };

  loadLocalizedData = (lang: string) => {
    return new Promise((resolve, reject) => {
      Service.get(
        API.goalTemplates.getFullTemplate(this.props.templateId, lang),
        (localizedTemplate: any) => {
          const localizedSection = localizedTemplate.sections.find((section: any) => {
            return section.id === this.props.sectionId;
          });

          if (localizedSection) {
            this.setState({
              localizedSection,
              title: localizedSection.name,
            });

            resolve(localizedTemplate);
          } else {
            reject();
          }
        },
        reject,
      );
    });
  };

  handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      title: e.target.value,
      ...(
        e.target.value !== ''
          ? {
            showValidationError: false,
            disabledSave: false
          }
          : {}
      ),
    });
  };

  handleSubmit = (lang: string) => {
    const { localizedSection, title } = this.state;

    if (!localizedSection) {
      return;
    }

    if (title === '') {
      this.setState({ showValidationError: true });
      return;
    }

    this.props.translateSection(
      lang,
      this.props.templateId,
      localizedSection.id,
      { name: title },
    );

    this.props.handleModalClose();
  };

  render() {
    const { title, showValidationError } = this.state;

    return (
      <TranslationDialog
        title={translate.t('translate_section')}
        disabledSave={this.state.disabledSave}
        loadLocalizedData={this.loadLocalizedData}
        localizedDataForm={(
          <TextField
            name="title"
            value={title}
            onChange={this.handleTitleChange}
            label={translate.t('laTitle')}
            error={showValidationError}
            helperText={
              showValidationError
                ? translate.t('laThisRequired')
                : ''
            }
            fullWidth
          />
        )}
        onSubmit={this.handleSubmit}
        onCancel={this.props.handleModalClose}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  translateSection: (language: string, templateId: string, sectionId: string, translation: any) =>
    dispatch<any>(translateSection(language, templateId, sectionId, translation)),
});

const enhance = compose<any, any>(
  connect(undefined, mapDispatchToProps),
);

export default enhance(TranslateSection);
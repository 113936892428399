import { bindActionCreators, Dispatch } from 'redux';

import { openConfirmDialog } from '@/app/redux/confirmDialog';

import { deleteTimeCardAction } from '../../../../data';
import { TimeSheetStoreState } from '../../../../timeSheetReducer';

import { DailySummaryRowOuterProps, StateProps, DispatchProps } from './dailySummaryRowModels';

export const mapStateToProps = (state: any, props: DailySummaryRowOuterProps): StateProps => {
    const timeSheetStoreState: TimeSheetStoreState = state.timeSheet;

    return {
        isDeleting: timeSheetStoreState.timeCards.cardsWeAreDeleting.includes(props.date),
        failedToDelete: timeSheetStoreState.timeCards.cardsFailedToDelete.includes(props.date),
    };
};

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => bindActionCreators(
    {
        deleteTimeCard: deleteTimeCardAction,
        openConfirmDialog: openConfirmDialog,
    },
    dispatch
);

import * as React from 'react';
import moment from 'moment';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { ReducerState } from '@/app/redux/store';
import { Typography, WithStyles } from '@material-ui/core';
import { LanguagesType } from '@/app/redux/languages';
import {
  fetchAllProcessTemplates,
  fetchArchivedProcessEvaluations,
  getEvaluationInformation,
  changeEvaluationApproval,
  removeEvaluationApproval,
  setActiveTemplate,
  updateEvaluation,
  clearEvaluation,
  saveEmployeeEvaluations,
  archiveEvaluation,
  changeEvaluationStage,
  changeRoleVisibility
} from '@/app/redux/processEvaluations';
import Loading from '@/app/components/Loading/Loading';
import TemplateSelection from './TemplateSelection/TemplateSelection';
import { openConfirmDialog, ConfirmDialogType } from '@/app/redux/confirmDialog';
import translate from '@/app/utils/translate';
import processEvaluationsStyles from './processEvaluationsStyles';
import { ProcessFormTemplate } from '@/app/components/TemplateComponents/types';

type incomingProps = {
  employeeId: number;
};

type MapStateToProps = {
  allProcessTemplates: ProcessFormTemplate[];
  archivedProcessEvaluations: [];
  activeTemplate: any;
  languages: LanguagesType[];
};

type MapDispatchToProps = {
  fetchAllProcessTemplates: () => any;
  fetchArchivedProcessEvaluations: (employeeId: number) => void;
  getEvaluationInformation: (template: ProcessFormTemplate, employeeId: number) => any;
  setActiveTemplate: (template: ProcessFormTemplate, employeeId: number, fromArchive?: boolean) => any;
  updateEvaluation: (templateId: string, responses: any) => any;
  saveEmployeeEvaluations: (tempateId: string, component: any) => any;
  openConfirmDialog: (payload: ConfirmDialogType) => void;
  clearEvaluation: (templateId: string) => any;
  changeEvaluationApproval: () => any;
  removeEvaluationApproval: () => any;
  archiveEvaluation: (evaluationId: string) => any;
  changeEvaluationStage: (setStage: any, userMakingTheChange: string) => any;
  changeRoleVisibility: (role: string, visibility: boolean, userMakingChange: string) => any;
};

type PropsType = incomingProps & MapStateToProps & MapDispatchToProps & WithStyles<typeof processEvaluationsStyles>;

type StateType = {
  isLoading: boolean;
};

class ProcessEvaluationsContainer extends React.PureComponent<PropsType, StateType> {
  state: StateType = {
    isLoading: false,
  };

  componentDidMount() {
    this.setState({isLoading: true});
    this.props.fetchArchivedProcessEvaluations(this.props.employeeId);
    this.props.fetchAllProcessTemplates().then(() => {
      let firstActiveTemplate = this.props.allProcessTemplates.find((template: any) => {
        return template.status === 'ACTIVE' && moment(template.deadline).endOf('day').isAfter(new Date());
      });

      // If all templates have passed deadlines set the first active template a locked template
      if (firstActiveTemplate === undefined) {
        firstActiveTemplate = this.props.allProcessTemplates.find((template: any) => template.status === 'ACTIVE');
      }

      if (firstActiveTemplate) {
        this.props.getEvaluationInformation(firstActiveTemplate, this.props.employeeId).then(() => {
          this.setState({isLoading: false});
        });
      } else {
        this.setState({isLoading: false});
      }
    });
  }

  getTemplateDetails = (templateId: string, templates: ProcessFormTemplate[]): any => {
    return templates.find((template: any) => template.id === templateId);
  };

  handleTemplateChange = (selectedTemplateId: any) => {
    const newActiveTemplate = this.getTemplateDetails(selectedTemplateId, this.props.allProcessTemplates);
    const archivedEvaluation = this.getTemplateDetails(selectedTemplateId, this.props.archivedProcessEvaluations);

    if (newActiveTemplate) {
      return  this.props.setActiveTemplate(newActiveTemplate, this.props.employeeId);
    } else if (archivedEvaluation) {
      const activeTemplate = this.getTemplateDetails(archivedEvaluation.templateId, this.props.allProcessTemplates);
      const archivedTemplate = {
        ...activeTemplate,
        id: archivedEvaluation.id
      };

      return this.props.setActiveTemplate(archivedTemplate, this.props.employeeId, true);
    } else {
      return this.props.setActiveTemplate(newActiveTemplate, this.props.employeeId);
    }
  };

  archiveEvaluation = (evaluationId: string) => {
    return this.props.archiveEvaluation(evaluationId).then(() => {
      this.props.fetchArchivedProcessEvaluations(this.props.employeeId);
    });
  }

  handleEvaluationsSave = (evaluationId: string, component: any) => {
    return this.props.saveEmployeeEvaluations(evaluationId, component).then(() => {
      const { activeTemplate } = this.props;
      this.props.getEvaluationInformation(activeTemplate, this.props.employeeId);
    });
  }

  render () {
    const { languages, allProcessTemplates, activeTemplate, archivedProcessEvaluations } = this.props;
    const { isLoading } = this.state;
    const notStartedStatus = activeTemplate.currentEvaluationId === undefined;

    return (
      <>
        {isLoading && <Loading key="loading"/>}
        {!isLoading && allProcessTemplates.length > 0 && activeTemplate && activeTemplate.id && (
          <div>
            <TemplateSelection
              languages={languages}
              templates={allProcessTemplates}
              archivedProcessEvaluations={archivedProcessEvaluations}
              handleTemplateChange={this.handleTemplateChange}
              archiveEvaluation={this.archiveEvaluation}
              changeEvaluationApproval={this.props.changeEvaluationApproval}
              removeEvaluationApproval={this.props.removeEvaluationApproval}
              activeTemplate={activeTemplate}
              handleEvaluationsSave={this.handleEvaluationsSave}
              updateEvaluation={this.props.updateEvaluation}
              openConfirmDialog={this.props.openConfirmDialog}
              clearEvaluation={this.props.clearEvaluation}
              setActiveTemplate={this.props.setActiveTemplate}
              employeeId={this.props.employeeId}
              notStartedStatus={notStartedStatus}
              changeEvaluationStage={this.props.changeEvaluationStage}
              changeRoleVisibility={this.props.changeRoleVisibility}
            />
          </div>
        )}
        {!isLoading && !allProcessTemplates.length && !activeTemplate.id && (
          <Typography variant="h6">
            {translate.t('laNoResults')}
          </Typography>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: ReducerState) => {
  const {
    allProcessTemplates,
    activeTemplate,
    archivedProcessEvaluations
  } = state.processEvaluations;
  const { languages } = state;

  return {
    allProcessTemplates,
    activeTemplate,
    archivedProcessEvaluations,
    languages: languages.get('allLanguages')
   };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAllProcessTemplates: () => dispatch<any>(fetchAllProcessTemplates()),
  fetchArchivedProcessEvaluations: (employeeId: number) =>
    dispatch<any>(fetchArchivedProcessEvaluations(employeeId)),
  getEvaluationInformation: (template: ProcessFormTemplate, employeeId: number) =>
    dispatch<any>(getEvaluationInformation(template, employeeId)),
  changeEvaluationApproval: (evaluationId: string, newApprovedBy: string) =>
    dispatch<any>(changeEvaluationApproval(evaluationId, newApprovedBy)),
  removeEvaluationApproval: (evaluationId: string, newApprovedBy: string) =>
    dispatch<any>(removeEvaluationApproval(evaluationId, newApprovedBy)),
  setActiveTemplate: (template: ProcessFormTemplate, employeeId: number, fromArchive: boolean = false) =>
    dispatch<any>(setActiveTemplate(template, employeeId, fromArchive)),
  archiveEvaluation: (evaluationId: string) =>
    dispatch<any>(archiveEvaluation(evaluationId)),
  updateEvaluation: (templateId: string, responses: any) =>
    dispatch<any>(updateEvaluation(templateId, responses)),
  openConfirmDialog: (payload: ConfirmDialogType) =>
    dispatch(openConfirmDialog(payload)),
  clearEvaluation: (templateId: string) =>
    dispatch<any>(clearEvaluation(templateId)),
  saveEmployeeEvaluations: (templateId: string, component: any) =>
    dispatch<any>(saveEmployeeEvaluations(templateId, component)),
  changeEvaluationStage: (setStage: any, userMakingTheChange: string) =>
    dispatch<any>(changeEvaluationStage(setStage, userMakingTheChange)),
  changeRoleVisibility: (role: string, visibility: boolean, userMakingChange: string) => {
    dispatch<any>(changeRoleVisibility(role, visibility, userMakingChange));
  }
});

const enhance = compose<any, any>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(processEvaluationsStyles),
);

export default enhance(ProcessEvaluationsContainer);
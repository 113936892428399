import * as React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';

type PropsType = {
  label: string;
  isChecked: boolean;
  onCheck: (checked: boolean) => void;
};

const ToolbarFilterCheckbox: React.FunctionComponent<PropsType> = (props: PropsType) => {
  const { label, isChecked, onCheck } = props;

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isChecked || false}
          onChange={(event: React.ChangeEvent) => {
            const target = event.target as any;
            onCheck(target.checked);
          }}
          color="primary"
        />
      }
      label={label}
    />
  );
};

export default ToolbarFilterCheckbox;

import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const enumGroupListStyle: StyleRulesCallback = (theme: Theme) => ({
  root: {
    padding: theme.spacing.unit * 2,
  },
  progressContainer: {
    textAlign: 'center',
  },
});

export default enumGroupListStyle;

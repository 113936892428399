import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  gridMargin: {
    marginTop: theme.spacing.unit * 3,
  },
  gridMarginVertical: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 4,
  },
  noSections: {
    textAlign: 'left',
    paddingLeft: theme.spacing.unit * 3,
  },
  selectedButtonBackground: {
    backgroundColor: 'lightgrey'
  },
});

export default styles;

import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  Grid,
  GridColumn,
  GridColumnProps,
  GridToolbar,
  GridNoRecords,
} from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {
  Typography,
  StyleRulesCallback,
  Theme,
  withStyles,
  Paper,
  CircularProgress,
} from '@material-ui/core';

import FetchPrimaryUnitsHOC from '@/app/hocs/FetchPrimaryUnitsHOC';
import { withDataState } from './withDataState';
import { IReportSettings } from '../ReportSettings';

import { ReducerState } from '@/app/redux/store';
import { fetchMyManagedUnits, fetchMyHrUnits } from '@/app/redux/currentUser';
import { applySettings, applyDefaultSettings } from '@/app/redux/reporting';
import ReportTools from './ReportTools';
import { ICustomFilter, IReportConfig } from '../ReportConfig';
import translate from '@/app/utils/translate';
import ColumnMenu from './ColumnMenu';
import { currentUserHasTeam } from '@/old/utils/helper';
import { process } from '@progress/kendo-data-query';
// import { loadMessages, LocalizationProvider, IntlProvider } from '@progress/kendo-react-intl';

const StatefulGrid = withDataState(Grid);

const styles: StyleRulesCallback = (theme: Theme) => ({
  root: {
    padding: theme.spacing.unit * 2,
  },
  title: {
    marginBottom: theme.spacing.unit,
  },
});

type OwnProps = {
  moduleId: string;
  reportId: string;
  config: IReportConfig;
  defaultSettings: IReportSettings;
  kendoGrouping?: any;
  onDataMapping: (data: any) => any;
  onGetCustomFilters: (settings?: IReportSettings) => ICustomFilter[];
  sort?: any;
  exportFileName: string;
};

class ReportView extends React.Component<any, OwnProps> {
  private refExcelExport = React.createRef<ExcelExport>();
  private refStatefulGrid = React.createRef<any>();

  handleSettingsChanged = (newSettings: IReportSettings) => {
    const { onDataMapping } = this.props;
    this.props.applySettings(newSettings, onDataMapping);
  };

  handleExportExcel = () => {
    const { config } = this.props;
    const filteredData = process(this.props.data, {
      ...this.refStatefulGrid.current.state.dataState,
      // Export all the records
      skip: 0,
      take: undefined,
    });
    this.refExcelExport.current.save(filteredData, config.excelExportColumns);
  };

  getUiFilter = (settings: IReportSettings): any => {
    const { onGetCustomFilters = null } = this.props;
    if (!settings.custom || !onGetCustomFilters) {
      return null;
    }
    const filterConfig = onGetCustomFilters();
    const filters: Array<any> = [];
    Object.keys(settings.custom).forEach((key: string) => {
      if (settings.custom[key] !== '') {
        const filter = filterConfig.find(
          (item: ICustomFilter) => item.params.selectedSettingName === key,
        );
        if (typeof filter !== 'undefined') {
          filters.push({
            field: filter.params.fieldName,
            operator: filter.params.operator,
            value: settings.custom[key],
          });
        }
      }
    });
    if (filters.length > 0) {
      const retFilters = filters.map((filter: any) => ({
        field: filter.field,
        operator: filter.operator,
        value: filter.value,
        ignoreCase: filter.ignoreCase || null,
      }));

      return {
        logic: 'and',
        filters: retFilters,
      };
    }
    return null;
  };

  loadKendoIntlMessages() {
    // loadMessages(
    //   {
    //     'grid.pagerInfo': translate.t('label_grid_pager_info'),
    //   },
    //   'fi-FI'
    // );
  }

  componentDidMount() {
    this.loadKendoIntlMessages();
    const { primaryUnits } = this.props;
    if (primaryUnits.length > 0) {
      this.props.fetchMyManagedUnits(primaryUnits);
      this.props.fetchMyHrUnits(primaryUnits);
    }

    this.props.applyDefaultSettings(this.props.defaultSettings);
    this.handleSettingsChanged(this.props.defaultSettings);
  }

  componentDidUpdate(prevProps: any) {
    if (
      prevProps.primaryUnits.length === 0 &&
      this.props.primaryUnits.length > 0
    ) {
      this.props.fetchMyManagedUnits(this.props.primaryUnits);
      this.props.fetchMyHrUnits(this.props.primaryUnits);
    }
  }

  render() {
    const {
      classes,
      config,
      data,
      settings,
      isFetching,
      myHrUnits,
      myManagedUnits,
      kendoGrouping,
      sort,
      onGetCustomFilters = null,
      exportFileName,
    } = this.props;

    const noRecordsContent = !isFetching ? (
      settings.scope && settings.scope.type === '' ? (
        translate.t('laReportNotLoaded')
      ) : (
        translate.t('laNoData')
      )
    ) : (
      <CircularProgress color="secondary" title={translate.t('laLoading')} />
    );

    return (
      <Paper elevation={0} className={classes.root}>
        <Typography className={classes.title} variant="h6">
          {translate.t(config.titleLabel)}
        </Typography>
        {/* <LocalizationProvider language="fi-FI">
          <IntlProvider locale="fi"> */}
        <ExcelExport fileName={exportFileName} ref={this.refExcelExport}>
          <StatefulGrid
            ref={this.refStatefulGrid}
            data={data}
            pageable={true}
            filter={this.getUiFilter(settings)}
            group={kendoGrouping}
            groupable={!!kendoGrouping}
            reorderable
            sort={sort}
          >
            <GridNoRecords>
              {noRecordsContent}
              {/* {!isFetching ? translate.t('laNoData') : (
                    <CircularProgress color="secondary" title={translate.t('laLoading')} />
                  )} */}
            </GridNoRecords>
            <GridToolbar>
              <ReportTools
                config={config}
                settings={settings}
                hasTeam={currentUserHasTeam()}
                myHrUnits={myHrUnits}
                myManagedUnits={myManagedUnits}
                onGetCustomFilters={onGetCustomFilters}
                onSettingsChanged={this.handleSettingsChanged}
                onExportExcel={this.handleExportExcel}
              />
            </GridToolbar>
            {config.columns.map((col: GridColumnProps, ndx: number) => (
              <GridColumn key={ndx} {...col} columnMenu={ColumnMenu} />
            ))}
          </StatefulGrid>
        </ExcelExport>
        {/* </IntlProvider>
        </LocalizationProvider> */}
      </Paper>
    );
  }
}

const mapStateToProps = (state: ReducerState) => ({
  primaryUnits: state.orgTree.get('primaryUnits'),
  myManagedUnits: state.currentUser.get('myManagedUnits'),
  myHrUnits: state.currentUser.get('myHrUnits'),
  isFetching: state.reporting.get('isFetching'),
  settings: state.reporting.get('settings'),
  data: state.reporting.get('data'),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchMyManagedUnits: (fullUnitList: Array<object>) =>
    dispatch<any>(fetchMyManagedUnits(fullUnitList)),
  fetchMyHrUnits: (fullUnitList: Array<object>) =>
    dispatch<any>(fetchMyHrUnits(fullUnitList)),
  applySettings: (
    newSettings: IReportSettings,
    mapDataFn: (origData: any) => any,
  ) => dispatch<any>(applySettings(newSettings, mapDataFn)),
  applyDefaultSettings: (defaultSettings: IReportSettings) =>
    dispatch<any>(applyDefaultSettings(defaultSettings)),
});

const enhance = compose<any, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles, { withTheme: true }),
  FetchPrimaryUnitsHOC,
);

export default enhance(ReportView);

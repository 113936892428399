import React from 'react';
import { compose } from 'recompose';
import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import {
  WithStyles,
  Typography,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import translate from '@/app/utils/translate';
import { withStyles } from '@material-ui/core/styles';
import sortableHOCstyles from './sortableHOCstyles';
import DragHandleIcon from '@material-ui/icons/DragHandle';

type incomingProps = {
  open: boolean;
  itemsToSort: object[];
  saveNewOrder: (items: object[]) => void;
  cancelNewOrder: () => void;
};

type OwnProps = {
  showDragHandle?: boolean;
};

type StateType = { items: object[] };

type PropsType = incomingProps &
  OwnProps &
  WithStyles<typeof sortableHOCstyles>;

class SortableHOC extends React.PureComponent<PropsType> {
  state: StateType = {
    items: [],
  };

  constructor(props: PropsType) {
    super(props);
    this.state = {
      items: props.itemsToSort,
    };
  }

  componentDidUpdate(nextProps: PropsType) {
    const { itemsToSort } = this.props;
    if (nextProps.itemsToSort !== itemsToSort) {
      if (itemsToSort) {
        this.setState({ items: nextProps.itemsToSort });
      }
    }
  }

  onSortEnd = ({ oldIndex, newIndex }: any) => {
    this.setState(({ items }: any) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }));
  };

  DragHandle = SortableHandle(() => <DragHandleIcon />);

  render() {
    const { open, saveNewOrder, cancelNewOrder, classes } = this.props;
    const { items } = this.state;
    const SortableList = SortableContainer(({ children }: any) => {
      return <ul>{children}</ul>;
    });
    const SortableItem = SortableElement(({ value }: { value: any }) => {
      const { showDragHandle } = this.props;
      return (
        <Paper
          className={classes.listElements}
          style={{ zIndex: 50000 }}
          elevation={1}
        >
          <Typography variant="subtitle1">{value}</Typography>
          {showDragHandle ? <this.DragHandle /> : null}
        </Paper>
      );
    });

    return (
      <Dialog open={open}>
        <DialogTitle>{translate.t('laSort')}</DialogTitle>
        <DialogContentText className={classes.context}>
          {translate.t('laSortIns')}
        </DialogContentText>
        <DialogContent>
          <SortableList onSortEnd={this.onSortEnd}>
            {items.map((value: any, index) => {
              if (value.name) {
                return (
                  <SortableItem
                    key={`item-${value.name}-${index}`}
                    index={index}
                    value={value.name}
                  />
                );
              } else {
                return (
                  <SortableItem
                    key={`item-${value.label}-${index}`}
                    index={index}
                    value={value.label}
                  />
                );
              }
            })}
          </SortableList>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="text" onClick={cancelNewOrder}>
            {translate.t('laCancel')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => saveNewOrder(this.state.items)}
          >
            {translate.t('laSave')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const enhance = compose<any, any>(withStyles(sortableHOCstyles));

export default enhance(SortableHOC);

import { IntlProvider, InjectedIntl } from 'react-intl';
import { addLocaleData } from 'react-intl';
import { getCurrentLanguage } from '@/app/utils/helper';

/**
 * Translation class, use t() method to get the traslation string
 */
class Translate {
  private static instance: Translate;
  private intl: InjectedIntl = null;
  private intlFallback: InjectedIntl = null;

  /**
   * init method
   */
  public init () {
    const langData = this.getLangData();
    const fallbackLangData = this.getLangData('en');
    // add language config support
    addLocaleData([...langData.data, ...fallbackLangData.data]);
    this.intl = new IntlProvider({ locale: getCurrentLanguage(), messages: langData.message }).getChildContext().intl;
    this.intlFallback = new IntlProvider({ locale: 'en', messages: fallbackLangData.message }).getChildContext().intl;
  }

  /**
   * translation method
   * @param id key for the translation
   * @param values optional parameters
   */
  public t (id: string, values?: {}) {
    if (!this.intl) {
      return '';
    }
    return this.intl.formatMessage(
      {
        id,
        defaultMessage: this.intlFallback.formatMessage({ id, defaultMessage: '' }, values),
      },
      values,
    );
  }

  public getLangMessage(langCode: string, message: string) {
    const langData = this.getLangData(langCode);
    return langData.message[message] || '';
  }

  /**
   * load current language data
   * Notes: webpack currently cannot handle the dynamic referrence, have to import lib manually
   * @return {Object: {data, message}} config data and message data
   */
  private getLangData (languageCode: string = getCurrentLanguage()): { data: any, message: any } {

    const loadTranslations = (langCode: string) => {
      return Object.assign(
        {},
        require(`@/assets/lang/${langCode}.json`),
        require(`@/assets/lang-error/${langCode}.json`),
      );
    };

    const loadStandardLangData = (langCode: string): { data: any, message: any } => {
      return {
        data: require(`react-intl/locale-data/${langCode}`),
        message: loadTranslations(langCode),
      };
    };

    // Standard languages
    if ([
      'cs',
      'da',
      'de',
      'en',
      'et',
      'fi',
      'fr',
      'it',
      'lt',
      'lv',
      'nl',
      'no',
      'pl',
      'ru',
      'sv',
      'uk',
      'zh'
    ].includes(languageCode)) {
      return loadStandardLangData(languageCode);
    }

    // Custom languages
    // fm = Finnish (Modern / Gender neutral) - Custom variant
    if (['fm'].includes(languageCode)) {
      return {
        data: require(`@/assets/locale-data/${languageCode}`),  // Locale data for custom variant
        message: loadTranslations(languageCode)
      };
    }

    // Default language
    return loadStandardLangData('en');
  }

  /**
   * this method is used for instaniate only once
   */
  static getInstance() {
    if (!Translate.instance) {
      Translate.instance = new Translate();
    }
    return Translate.instance;
  }
}

export default Translate.getInstance();

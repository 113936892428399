import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { SendComment as InnerSendComment, SendCommentInnerPros, SendCommentOuterProps } from './SendComment';
import { mapDispatchToProps, mapStateToProps } from './sendCommentRedux';
import { sendCommentStyles } from './sendCommentStyles';

export const SendComment = compose<SendCommentInnerPros, SendCommentOuterProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(sendCommentStyles),
)(InnerSendComment);

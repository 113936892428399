import { Map as iMap, List as iList } from 'immutable';
import { THROW_ERROR } from './error';
import API from '@/app/api/externalAPIs';
import Service from '@/app/utils/service';

export type ModulesType = {
  CUSTOM_DATA: boolean,
  QUINYX: boolean,
  RECRUITING_MINI: boolean,
  MEPCO: boolean,
  CORE: boolean,
  RECRUITING_FULL: boolean,
  EXPERIMENTAL: boolean
};

const EMPTY_MODULES: ModulesType = {
  CUSTOM_DATA: false,
  QUINYX: false,
  RECRUITING_MINI: false,
  MEPCO: false,
  CORE: false,
  RECRUITING_FULL: false,
  EXPERIMENTAL: false
};

const FETCH_MODULES = '@@solaforce/modules/FETCH_MODULES';

const initalState = iMap({
  allModules: iList(),
});

const reducer = (state = initalState, action: any) => {
  switch (action.type) {
    case FETCH_MODULES:
      return state.set('allModules', action.modules);
    default:
      return state;
  }
};

export const fetchModules = () => {
  return (dispatch: any) => {
    Service.get(
      API.modules,
      (data: string[] = []) => {
        let modules = {...EMPTY_MODULES};
        data.forEach((m: string) => {
          modules[m] = true;
        });
        dispatch({ type: FETCH_MODULES, modules });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error })
    );
  };
};

export default reducer;

import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { IconButton, Toolbar, Typography } from '@material-ui/core';
import SolaIntelIcon from '@material-ui/icons/School';
import {
  updateIndicator,
  fetchIndicatorList,
  fetchIndicatorReportResults,
} from '@/app/redux/solaIntel';
import { ISolaIntelSettings } from '@/app/components/SolaIntel/SolaIntelConfig';
import { getMyTeams, isHRAdmin } from '@/old/utils/helper';
import DockablePopup from '../DockablePopup/DockablePopup';
import SolaIntelDashboard from './SolaIntelDashboard/SolaIntelDashboard';
import translate from '@/app/utils/translate';

interface MapStateToProps {
  indicators: [];
  uiMappedIndicatorResults: ISolaIntelSettings;
  fetchIndicatorList: (unitId: string, callback?: Function) => void;
  fetchIndicatorReportResults: (indicatorId: string, unitId: string) => void;
  updateIndicator: (indicator: any, unitId: string) => void;
}

interface IState {
  anchorEl: any;
  currentUnitId: string;
  popupToolbar?: React.ReactElement<typeof Toolbar>;
  allowClickAwayClose?: boolean;
}

class SolaIntelPopoverLaunchButton extends React.Component<MapStateToProps, IState> {
  state: IState = {
    anchorEl: null,
    currentUnitId: '',
    popupToolbar: null,
    allowClickAwayClose: true,
  };

  private teams: any[] = getMyTeams();
  private refLaunchButton = React.createRef<React.ReactNode>();

  openPopper = () => {
    if (!this.state.anchorEl) {
      this.setState({ anchorEl: this.refLaunchButton.current });
    }
  }

  onIndicatorUpdate = (indicator: any) => {
    const { currentUnitId } = this.state;
    this.props.updateIndicator(indicator, currentUnitId);
  }

  onUnitChange = (unitId: string, selectedIndicatorUid: string) => {
    this.setState({ currentUnitId: unitId });
    if (selectedIndicatorUid) {
      this.props.fetchIndicatorList(unitId, () => {
        this.props.fetchIndicatorReportResults(selectedIndicatorUid, unitId);
      });
    } else {
      this.props.fetchIndicatorList(unitId);
    }
  }

  onIndicatorSelect = (indicator: any) => {
    const { currentUnitId } = this.state;
    this.props.fetchIndicatorReportResults(indicator.uid, currentUnitId);
  }

  setPopupToolbar = (popupToolbar: React.ReactElement<typeof Toolbar>) => {
    this.setState({ popupToolbar });
  }

  setClickAwayClosing = (allowClickAwayClose: boolean) => {
    this.setState({ allowClickAwayClose });
  }

  componentDidMount() {
    if (this.teams && this.teams[0]) {
      this.props.fetchIndicatorList(this.teams[0].value);
      this.setState({ currentUnitId: this.teams[0].value });
    }
  }

  render() {
    const { uiMappedIndicatorResults } = this.props;
    const { anchorEl, popupToolbar, allowClickAwayClose, currentUnitId } = this.state;
    const currentUnit = this.teams.find(teamUnit => teamUnit.value === currentUnitId);
    const noDataStyle = { marginLeft: '8px' };

    return (
      <>
        <DockablePopup
          anchorEl={anchorEl}
          iconName="school"
          secondaryToolbar={popupToolbar}
          allowClickAwayClose={allowClickAwayClose}
          onClose={() => this.setState({ anchorEl: null })}
          title="SolaIntel"
        >
          {this.teams && this.teams.length > 0 ?
            (
              <SolaIntelDashboard
                indicators={uiMappedIndicatorResults}
                showConfig={isHRAdmin()}
                selectedUnitName={currentUnit && currentUnit.label}
                onUnitChange={this.onUnitChange}
                onIndicatorUpdate={this.onIndicatorUpdate}
                onIndicatorSelect={this.onIndicatorSelect}
                onSetExternalToolbar={this.setPopupToolbar}
                onSetClickAwayClosing={this.setClickAwayClosing}
              />
            ) : (
              <Typography variant="h6" style={noDataStyle}>{translate.t('laNoData')}</Typography>
            )
          }
        </DockablePopup>
        <IconButton buttonRef={this.refLaunchButton} onClick={this.openPopper}>
          <SolaIntelIcon color="primary" />
        </IconButton>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  indicators: state.solaIntel.get('indicators'),
  uiMappedIndicatorResults: state.solaIntel.get('uiMappedIndicatorResults'),
});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    fetchIndicatorReportResults,
    fetchIndicatorList,
    updateIndicator,
  },
  dispatch
);
const enhance = compose<MapStateToProps, any>(
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(SolaIntelPopoverLaunchButton);
import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const styles: StyleRulesCallback = (theme: Theme) => {
  return ({
    root: {
      padding: 0,
      paddingTop: theme.spacing.unit,
    },
    icon: {
      marginRight: theme.spacing.unit,
    },
    title: {
      paddingLeft: 0,
    },
    secondaryAction: {
    },
    secondaryActionIconButton: {
      maxWidth: 32,
      maxHeight: 32,
      padding: 6,
      marginTop: 6
    },
  });
};

export default styles;

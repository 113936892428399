import React from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core';
import translate from '@/app/utils/translate';

const AddToDrTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>{translate.t('laEmployee')}</TableCell>
        <TableCell>{translate.t('laType')}</TableCell>
        <TableCell>{translate.t('laTitle')}</TableCell>
        <TableCell>{translate.t('laDecisionInfo')}</TableCell>
        <TableCell>{translate.t('laAttachment')}</TableCell>
        <TableCell>{translate.t('laReminder')}</TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default AddToDrTableHead;

import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const sectionListingStyle: StyleRulesCallback = () => ({
  root: {
    padding: '20px',
  },
  button: {
    marginLeft: '20px',
    padding: '3px 5px'
  },
  iconButton: {
    marginLeft: -12
  },
  noMargin: {
    marginLeft: 0,
    marginRight: 0
  },
});

export default sectionListingStyle;
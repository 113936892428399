import getEnumName from './getEnumName';

export default (code: string, trainingGroup: string): string => {
  switch (trainingGroup) {
    case 'INTERNAL_TRAINING_TYPE':
      return getEnumName('INTERNAL_TRAINING_TYPE', code);
    case 'EXTERNAL_TRAINING_TYPE':
      return getEnumName('EXTERNAL_TRAINING_TYPE', code);
    default:
      return code;
  }
};

import { StyleRulesCallback, Theme } from '@material-ui/core';

const styles: StyleRulesCallback = (theme: Theme) => ({
  attButton: {
    fontSize: 'inherit', // theme.typography.body1.fontSize,
    fontWeight: 'inherit',
    padding: theme.spacing.unit / 2,
    textAlign: 'left',
    textTransform: 'none',
  },
  noData: {
    padding: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit,
  },
  table: {
    marginBottom: theme.spacing.unit * 3,
  },
});

export default styles;

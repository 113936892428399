import * as React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import ComponentListing from '@/app/components/TemplateComponents/form/FormComponents/ComponentListing/ComponentListing';

type Props = GridCellProps;

export const KendoTemplateResultsCell = ({ dataItem, rowType }: Props) => {
  if (
    rowType === 'groupHeader' ||
    rowType === 'groupFooter' ||
    rowType !== 'data'
  ) {
    return null;
  }

  return (
    <td>
      {Object.keys(dataItem.selectedTemplateFields).map(sectionId => {
        const section = dataItem.template.sections.find(
          (sectionItem: any) => sectionItem.id === sectionId,
        );
        const components = section.components.filter((component: any) =>
          dataItem.selectedTemplateFields[sectionId].selectedFields.includes(
            component.id,
          ),
        );

        return (
          <ComponentListing
            key={`compListing-${sectionId}`}
            template={dataItem.template}
            employeeId={dataItem.empId}
            responses={dataItem.evalItems.responses}
            section={section}
            components={components}
            expanded={true}
            editMode={false}
          />
        );
      })}
    </td>
  );
};

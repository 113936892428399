export enum HoursSettingsActionTypes {
    GET_COUNTRY_DEFAULT_HOURS_PENDING = '@@solaforce/workTimeSettings/GET_COUNTRY_DEFAULT_HOURS_PENDING',
    GET_COUNTRY_DEFAULT_HOURS_FULFILLED = '@@solaforce/workTimeSettings/GET_COUNTRY_DEFAULT_HOURS_FULFILLED',
    GET_COUNTRY_DEFAULT_HOURS_REJECTED = '@@solaforce/workTimeSettings/GET_COUNTRY_DEFAULT_HOURS_REJECTED',
    PUT_COUNTRY_DEFAULT_HOURS_PENDING = '@@solaforce/workTimeSettings/PUT_COUNTRY_DEFAULT_HOURS_PENDING',
    PUT_COUNTRY_DEFAULT_HOURS_FULFILLED = '@@solaforce/workTimeSettings/PUT_COUNTRY_DEFAULT_HOURS_FULFILLED',
    PUT_COUNTRY_DEFAULT_HOURS_REJECTED = '@@solaforce/workTimeSettings/PUT_COUNTRY_DEFAULT_HOURS_REJECTED',
    GET_COUNTRY_HOURS_CUSTOM_HOURS_PENDING = '@@solaforce/workTimeSettings/GET_COUNTRY_HOURS_CUSTOM_HOURS_PENDING',
    GET_COUNTRY_HOURS_CUSTOM_HOURS_FULFILLED = '@@solaforce/workTimeSettings/GET_COUNTRY_HOURS_CUSTOM_HOURS_FULFILLED',
    GET_COUNTRY_HOURS_CUSTOM_HOURS_REJECTED = '@@solaforce/workTimeSettings/GET_COUNTRY_HOURS_CUSTOM_HOURS_REJECTED',
    POST_COUNTRY_HOURS_CUSTOM_HOURS_PENDING = '@@solaforce/workTimeSettings/POST_COUNTRY_HOURS_CUSTOM_HOURS_PENDING',
    POST_COUNTRY_HOURS_CUSTOM_HOURS_FULFILLED = '@@solaforce/workTimeSettings/POST_COUNTRY_HOURS_CUSTOM_HOURS_FULFILLED',
    POST_COUNTRY_HOURS_CUSTOM_HOURS_REJECTED = '@@solaforce/workTimeSettings/POST_COUNTRY_HOURS_CUSTOM_HOURS_REJECTED',
    PUT_COUNTRY_CUSTOM_HOURS_PENDING = '@@solaforce/workTimeSettings/PUT_COUNTRY_CUSTOM_HOURS_PENDING',
    PUT_COUNTRY_CUSTOM_HOURS_FULFILLED = '@@solaforce/workTimeSettings/PUT_COUNTRY_CUSTOM_HOURS_FULFILLED',
    PUT_COUNTRY_CUSTOM_HOURS_REJECTED = '@@solaforce/workTimeSettings/PUT_COUNTRY_CUSTOM_HOURS_REJECTED',
}

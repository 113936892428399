import { Dispatch } from 'react';

import Service from '@/app/utils/service';
import API from '@/app/api/internalAPIs';

import { HoursSettingsActionTypes } from '../data';

export const getCustomHours = (
    countryCode: string,
    dispatch: Dispatch<any>
): void => {
    dispatch({type: HoursSettingsActionTypes.GET_COUNTRY_HOURS_CUSTOM_HOURS_PENDING});
    Service.get(
        API.workTimeSettings.getCountryHoursSettings(countryCode),
        (response: any) => {
            dispatch(
                {
                    type: HoursSettingsActionTypes.GET_COUNTRY_HOURS_CUSTOM_HOURS_FULFILLED,
                    payload: response
                }
            );
        },
        (error: any) => {
            dispatch(
                {
                    type: HoursSettingsActionTypes.GET_COUNTRY_HOURS_CUSTOM_HOURS_REJECTED,
                    error,
                    meta: countryCode
                }
            );
        }
    );
};

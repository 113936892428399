import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@material-ui/core';
import translate from '@/app/utils/translate';

type OwnProps = {
  open: boolean;
  defaultValue?: number;
  onClose: () => void;
  onSubmit: (newHours: number) => void;
};
type InnerProps = {};
type Props = OwnProps & InnerProps;

const EditHoursDialog = ({
  open,
  defaultValue,
  onClose,
  onSubmit
}: Props) => {
  const [hours, setHours] = useState(defaultValue);

  const handleOk = () => {
    onSubmit(hours);
    onClose();
  };

  if (!open) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Set hours</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus={true}
          type="number"
          fullWidth={true}
          value={hours}
          onChange={e => setHours(parseFloat(e.target.value))}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={onClose}>{translate.t('laCancel')}</Button>
        <Button variant="contained" color="primary" onClick={handleOk}>{translate.t('laOk')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditHoursDialog;

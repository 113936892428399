import * as React from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon,
  IconButton,
  LinearProgress,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  WithStyles,
  withStyles,
} from '@material-ui/core';

import {
  Field,
  FieldArray,
  FieldArrayRenderProps,
  Form,
  Formik,
  FormikProps,
} from 'formik';
import * as yup from 'yup';
import moment from 'moment';

import Service from '@/app/utils/service';
import API from '@/app/api/internalAPIs';

import {
  mapWorklistForUI,
  resolveInitialDueDate,
  getInitialDueDateAutomationSelection,
  getDueDateAutomationText,
  getInitialEmployeeAutomationSelection,
  getEmployeeAutomationText,
  resolveInitialEmployeeId,
  mapNewTaskForBE,
} from './service';
import {
  ICreateTasksFromWorklist,
  IWorklistTask,
  ITask,
  DueDateAutomationSelection,
  EmployeeAutomationSelection,
} from './types';

import { FieldDate } from '@/app/components/Formik';
import ErrorDialog from './createTasksFromWorklistDialog/ErrorDialog';
import FieldDueDateAutomation from './createTasksFromWorklistDialog/FieldDueDateAutomation';
import FieldEmployeeAutomation from './createTasksFromWorklistDialog/FieldEmployeeAutomation';
import styles from './createTasksFromWorklistDialog/styles';
import {
  getLoggedUserId,
  getSelectedEmpId,
  getEmployeeName,
  getEmployeeManagerId,
  getEmployeeHRId,
  downloadAttachment,
} from '@/old/utils/helper';
import translate from '@/app/utils/translate';

type OwnProps = {
  open: boolean;
  worklistId: number;
  onSuccess: (count: number) => void;
  onClose: () => void;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const CreateTasksFromWorklistDialog = (props: Props) => {
  const { classes, open, worklistId, onSuccess, onClose } = props;

  const mapWorklistTasksToCreateData = (
    completionDate: Date,
    tasks: IWorklistTask[],
  ): ITask[] => {
    const loggedInUserId = getLoggedUserId();
    const selectedEmpId = getSelectedEmpId();

    return tasks.map((task: IWorklistTask) => {
      const initialDueDate = resolveInitialDueDate(
        completionDate,
        task.dueDateAutomationType,
        task.dueDateAutomationDays,
      );
      const initialAssigneeId = resolveInitialEmployeeId(
        task.assigneeAutomation,
        loggedInUserId,
        selectedEmpId,
      );
      const initialPertainingToId = resolveInitialEmployeeId(
        task.pertainingToAutomation,
        loggedInUserId,
        selectedEmpId,
      );

      return {
        id: task.id,
        title: task.title,
        description: task.description,
        type: task.type,
        status: 'NEW',
        dueDate: initialDueDate,
        assigneeId: initialAssigneeId,
        pertainingToId: initialPertainingToId,
        visibleToPertainingTo: task.visibleToPertainingTo,
        attachmentId: task.attachmentId,
        attachmentName: task.attachmentName,
        automationSelection: {
          dueDate: getInitialDueDateAutomationSelection(
            task.dueDateAutomationType,
          ),
          dueDateAutomationType: task.dueDateAutomationType,
          dueDateAutomationDays: task.dueDateAutomationDays,
          assignee: getInitialEmployeeAutomationSelection(
            task.assigneeAutomation,
          ),
          assigneeAutomation: task.assigneeAutomation,
          pertainingTo: getInitialEmployeeAutomationSelection(
            task.pertainingToAutomation,
          ),
          pertainingToAutomation: task.pertainingToAutomation,
          copyAttachment: !!task.attachmentId && task.attachmentId > -1,
        },
      };
    });
  };

  const [selectedWorklistTask, setSelectedWorklistTask] = React.useState<any>();
  const [taskMenuAnchorEl, setTaskMenuAnchorEl] = React.useState<any>();

  const handleTaskMenuClose = () => {
    setSelectedWorklistTask(undefined);
    setTaskMenuAnchorEl(undefined);
  };

  const [createData, setCreateData] = React.useState<any>();
  React.useEffect(() => {
    Service.get(
      API.worklists.get(worklistId),
      (res: any) => {
        const worklist = mapWorklistForUI(res);
        const completionDate = new Date();
        const newCreateData = {
          worklistId,
          completionDate: moment(completionDate).format('YYYY-MM-DD'),
          title: worklist.title,
          tasks: mapWorklistTasksToCreateData(completionDate, worklist.tasks),
        };
        setCreateData(newCreateData);
      },
      (err: any) => {
        console.error(`ERROR: ${err.toString()}`);
      },
    );
  }, [worklistId]);

  const validationSchema = yup.object<ICreateTasksFromWorklist>().shape({
    tasks: yup.array(),
  });

  const [progressInfo, setProgressInfo] = React.useState<any>();
  const handleCreate = React.useCallback(
    async (
      values: ICreateTasksFromWorklist,
      _actions: FormikProps<ICreateTasksFromWorklist>,
    ) => {
      setProgressInfo({
        successCount: 0,
        errorCount: 0,
        errors: [],
        totalCount: values.tasks.length,
      });

      // const postPromises = new Array();
      values.tasks.forEach((task: ITask) => {
        const newTask = mapNewTaskForBE(task);

        // Push BE call to promise array
        Service.post(API.collaboration.addTask, newTask, (res: any) => {
          window.dispatchEvent(
            new CustomEvent('collaboration:sync-tasks', {
              detail: { tasks: [res] },
            }),
          );
        })
          .then((_res: any) => {
            setProgressInfo((prevProgressInfo: any) => ({
              ...prevProgressInfo,
              successCount: prevProgressInfo.successCount + 1,
            }));
          })
          .catch((err: any) => {
            setProgressInfo((prevProgressInfo: any) => ({
              ...prevProgressInfo,
              errorCount: prevProgressInfo.errorCount + 1,
              errors: [...prevProgressInfo.errors, err],
            }));
          });
      });
    },
    [setProgressInfo],
  );

  const [errorList, setErrorList] = React.useState([]);
  React.useEffect(() => {
    if (
      progressInfo &&
      progressInfo.totalCount > 0 &&
      progressInfo.successCount + progressInfo.errorCount >=
        progressInfo.totalCount
    ) {
      if (progressInfo.errorCount === 0) {
        onSuccess(progressInfo.successCount);
        setTimeout(() => onClose(), 500);
      } else {
        const errs = progressInfo.errors.map((error: any) => {
          const postData = JSON.parse(error.config.data);
          return {
            title: postData.taskCore.fTaskTitle,
            message: error.message,
            taskId: postData.fOriginalWorklistTaskId,
          };
        });
        setErrorList(errs);
      }
    }
  }, [progressInfo, setErrorList]);

  const renderDialog = ({
    errors,
    isSubmitting,
    isValid,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldValue,
    touched,
    values,
    setSubmitting,
  }: FormikProps<any>) => {
    const handleCancel = () => {
      resetForm();
      onClose();
    };

    const handleSubmitClick = () => {
      handleSubmit();
    };

    const renderTaskRows = (arrayHelpers: FieldArrayRenderProps) => {
      if (!values || !values.tasks) {
        return <></>;
      }

      return (
        <Table padding="dense">
          <TableHead>
            <TableRow>
              <TableCell>{translate.t('table_col_title')}</TableCell>
              <TableCell>{translate.t('fTaskDueDate')}</TableCell>
              <TableCell>{translate.t('table_col_assignee')}</TableCell>
              <TableCell>{translate.t('table_col_pertaining_to')}</TableCell>
              <TableCell>
                {translate.t('table_col_include_attachment')}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {values.tasks
              .sort((a: ITask, b: ITask) => a.title.localeCompare(b.title))
              .map((task: ITask, index: number) => {
                const handleTaskEditOpen = () => {
                  // TODO: Open task edit dialog here! Task: values.tasks[index].title
                  handleTaskMenuClose();
                };

                const handleTaskDeleteConfirm = () => {
                  arrayHelpers.remove(index);
                  handleTaskMenuClose();
                };

                const handleTaskMenuOpen = (e: React.MouseEvent) => {
                  setSelectedWorklistTask(task);
                  setTaskMenuAnchorEl(e.target);
                };

                const handleDueDateChange = (
                  value: DueDateAutomationSelection,
                  manualDate: Date,
                ) => {
                  switch (value) {
                    case 'AUTO':
                      setFieldValue(
                        `tasks.${index}.dueDate`,
                        resolveInitialDueDate(
                          values.completionDate,
                          task.automationSelection.dueDateAutomationType,
                          task.automationSelection.dueDateAutomationDays,
                        ),
                      );
                      break;
                    case 'COMPL_DATE':
                      setFieldValue(
                        `tasks.${index}.dueDate`,
                        moment(values.completionDate).toDate(),
                      );
                      break;
                    case 'MANUAL':
                      setFieldValue(`tasks.${index}.dueDate`, manualDate);
                      break;
                    default:
                      setFieldValue(`tasks.${index}.dueDate`, null);
                      break;
                  }
                };

                const handleEmployeeChange = (
                  fieldType: 'assignee' | 'pertainingTo',
                ) => (
                  value: EmployeeAutomationSelection,
                  _manualEmpId: number,
                ) => {
                  let empId;

                  switch (value) {
                    case 'AUTO':
                      empId = resolveInitialEmployeeId(
                        fieldType === 'assignee'
                          ? task.automationSelection.assigneeAutomation
                          : task.automationSelection.pertainingToAutomation,
                        getLoggedUserId(),
                        getSelectedEmpId(),
                      );
                      break;
                    case 'LOGGED_IN_USER':
                      empId = getLoggedUserId();
                      break;
                    case 'LOGGED_IN_USER_MANAGER':
                      empId = getEmployeeManagerId(getLoggedUserId());
                      break;
                    case 'LOGGED_IN_USER_HR_RESP':
                      empId = getEmployeeHRId(getLoggedUserId());
                      break;
                    case 'SELECTED_EMP':
                      empId = getSelectedEmpId();
                      break;
                    case 'SELECTED_EMP_MANAGER':
                      empId = getEmployeeManagerId(getSelectedEmpId());
                      break;
                    case 'SELECTED_EMP_HR_RESP':
                      empId = getEmployeeHRId(getSelectedEmpId());
                      break;
                    default:
                      empId = null;
                      break;
                  }

                  setFieldValue(`tasks.${index}.${fieldType}Id`, empId);
                };

                const taskMenu =
                  !Boolean(taskMenuAnchorEl) ||
                  !selectedWorklistTask ||
                  selectedWorklistTask.id !== task.id ? (
                    undefined
                  ) : (
                    <Menu
                      anchorEl={taskMenuAnchorEl}
                      open={Boolean(taskMenuAnchorEl)}
                      onClose={handleTaskMenuClose}
                    >
                      <MenuItem disabled={true} onClick={handleTaskEditOpen}>
                        <ListItemIcon>
                          <Icon fontSize="small">edit</Icon>
                        </ListItemIcon>
                        {translate.t('laEdit')}
                      </MenuItem>
                      <MenuItem onClick={handleTaskDeleteConfirm}>
                        <ListItemIcon>
                          <Icon fontSize="small">delete</Icon>
                        </ListItemIcon>
                        {translate.t('laDelete')}
                      </MenuItem>
                    </Menu>
                  );

                const attachment =
                  !task.attachmentId || task.attachmentName === '' ? (
                    undefined
                  ) : (
                    <>
                      <Field
                        id={`tasks.${index}.automationSelection.copyAttachment`}
                        name={`tasks.${index}.automationSelection.copyAttachment`}
                        checked={
                          !task.automationSelection.copyAttachment
                            ? ''
                            : 'checked'
                        }
                        value={task.automationSelection.copyAttachment}
                        component={Checkbox}
                        disabled={isSubmitting}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <IconButton
                        disabled={isSubmitting}
                        title={translate.t('label_open_attachment')}
                        onClick={() =>
                          downloadAttachment(
                            API.worklists.taskAttachment(
                              task.id,
                              task.attachmentId,
                            ),
                          )
                        }
                      >
                        <Icon fontSize="small">attach_file</Icon>
                      </IconButton>
                    </>
                  );

                return (
                  <TableRow key={task.id}>
                    <TableCell>{task.title}</TableCell>
                    <TableCell>
                      <Field
                        id={`tasks.${index}.automationSelection.dueDate`}
                        name={`tasks.${index}.automationSelection.dueDate`}
                        component={FieldDueDateAutomation}
                        displayValue={task.dueDate}
                        disabled={isSubmitting}
                        automationText={getDueDateAutomationText(
                          task.automationSelection.dueDateAutomationType,
                          task.automationSelection.dueDateAutomationDays,
                        )}
                        automationType={
                          task.automationSelection.dueDateAutomationType
                        }
                        onBlur={handleBlur}
                        onChange={handleDueDateChange}
                      />
                    </TableCell>
                    <TableCell>
                      <Field
                        id={`tasks.${index}.automationSelection.assignee`}
                        name={`tasks.${index}.automationSelection.assignee`}
                        component={FieldEmployeeAutomation}
                        displayValue={getEmployeeName(task.assigneeId)}
                        disabled={isSubmitting}
                        automationText={getEmployeeAutomationText(
                          task.automationSelection.assigneeAutomation,
                          getLoggedUserId(),
                          getSelectedEmpId(),
                        )}
                        automationType={
                          task.automationSelection.assigneeAutomation
                        }
                        loggedInEmpId={getLoggedUserId()}
                        selectedEmpId={getSelectedEmpId()}
                        onBlur={handleBlur}
                        onChange={handleEmployeeChange('assignee')}
                      />
                    </TableCell>
                    <TableCell>
                      <Field
                        id={`tasks.${index}.visibleToPertainingTo`}
                        name={`tasks.${index}.visibleToPertainingTo`}
                        checked={!task.visibleToPertainingTo ? '' : 'checked'}
                        value={task.visibleToPertainingTo}
                        component={Checkbox}
                        disabled={
                          task.automationSelection.pertainingTo === 'NONE' ||
                          isSubmitting
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <Field
                        id={`tasks.${index}.automationSelection.pertainingTo`}
                        name={`tasks.${index}.automationSelection.pertainingTo`}
                        component={FieldEmployeeAutomation}
                        displayValue={getEmployeeName(task.pertainingToId)}
                        disabled={isSubmitting}
                        automationText={getEmployeeAutomationText(
                          task.automationSelection.pertainingToAutomation,
                          getLoggedUserId(),
                          getSelectedEmpId(),
                        )}
                        automationType={
                          task.automationSelection.pertainingToAutomation
                        }
                        loggedInEmpId={getLoggedUserId()}
                        selectedEmpId={getSelectedEmpId()}
                        onBlur={handleBlur}
                        onChange={handleEmployeeChange('pertainingTo')}
                      />
                    </TableCell>
                    <TableCell>{attachment}</TableCell>
                    <TableCell>
                      {taskMenu}
                      <IconButton
                        disabled={isSubmitting}
                        onClick={handleTaskMenuOpen}
                      >
                        <Icon fontSize="small">more_vert</Icon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      );
    };

    const handleErrorDialogClose = () => {
      // Remove successfully created tasks from the task list
      const taskIds = errorList.map((el: any) => el.taskId);
      setFieldValue(
        'tasks',
        values.tasks.filter((task: ITask) => taskIds.indexOf(task.id) > -1),
      );

      // Empty error list for new try and stop submitting
      setErrorList([]);
      setSubmitting(false);
    };

    const handleCompletionDateChange = (value: any) => {
      // Resolve new due dates for tasks
      values.tasks.forEach((task: ITask, index: number) => {
        if (task.automationSelection.dueDate === 'AUTO') {
          setFieldValue(
            `tasks.${index}.dueDate`,
            resolveInitialDueDate(
              value,
              values.tasks[index].automationSelection.dueDateAutomationType,
              values.tasks[index].automationSelection.dueDateAutomationDays,
            ),
          );
        }
        if (task.automationSelection.dueDate === 'COMPL_DATE') {
          setFieldValue(`tasks.${index}.dueDate`, value);
        }
      });
    };

    const errorDialog =
      !errorList || errorList.length === 0 ? (
        undefined
      ) : (
        <ErrorDialog
          open={true}
          errors={errorList}
          errorCount={progressInfo.errorCount}
          successCount={progressInfo.successCount + 2}
          onClose={handleErrorDialogClose}
        />
      );

    const progressValue =
      !progressInfo || progressInfo.totalCount === 0
        ? 0
        : Math.round(
            ((progressInfo.successCount + progressInfo.errorCount) /
              progressInfo.totalCount) *
              100,
          );
    const dialogActions = isSubmitting ? (
      <DialogActions>
        <Paper className={classes.progressPaper} elevation={0}>
          {!progressInfo ? (
            undefined
          ) : (
            <LinearProgress
              className={classes.progress}
              color="primary"
              variant="determinate"
              value={progressValue}
            />
          )}
        </Paper>
      </DialogActions>
    ) : (
      <DialogActions>
        <Button color="primary" disabled={isSubmitting} onClick={handleCancel}>
          {translate.t('laCancel')}
        </Button>
        <Button
          color="primary"
          disabled={!isValid || isSubmitting}
          variant="contained"
          onClick={handleSubmitClick}
        >
          {translate.t('label_create_tasks')}
        </Button>
      </DialogActions>
    );

    if (!values || !values.tasks) {
      return <></>;
    }
    return (
      <Dialog
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        open={open}
        maxWidth="lg"
        onClose={handleCancel}
      >
        <DialogTitle>
          {translate.t('title_create_tasks_from_worklist', {
            worklistName: values.title,
          })}
        </DialogTitle>
        <DialogContent className={classes.content}>
          {errorDialog}
          <Form>
            <Paper className={classes.contentPaper} elevation={0}>
              <Field
                id="completionDate"
                name="completionDate"
                component={FieldDate}
                disabled={isSubmitting}
                error={Boolean(errors.completionDate && touched.completionDate)}
                helperText={
                  errors.completionDate &&
                  touched.completionDate &&
                  errors.completionDate
                }
                label={translate.t('table_col_completion_date')}
                margin="normal"
                InputLabelProps={{ shrink: true }}
                onBlur={handleBlur}
                onChange={handleCompletionDateChange}
                value={values.completionDate}
              />
            </Paper>
            <FieldArray
              name="tasks"
              render={(arrHelpers: FieldArrayRenderProps) =>
                renderTaskRows(arrHelpers)
              }
            />
          </Form>
        </DialogContent>
        {dialogActions}
      </Dialog>
    );
  };

  if (!open || !createData) {
    return null;
  }
  return (
    <Formik
      initialValues={createData}
      isInitialValid={true}
      validationSchema={validationSchema}
      onSubmit={handleCreate}
      render={renderDialog}
    />
  );
};

export default withStyles(styles)(CreateTasksFromWorklistDialog);

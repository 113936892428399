import { createStyles } from '@material-ui/core';

export const avatarCellStyles = () => createStyles({
    avatar: {
        display: 'inline-block',
        verticalAlign: 'middle',
        marginRight: '8px',
    },
    label: {
        display: 'inline-block',
        verticalAlign: 'middle',
    },
});
const GLOBAL: any = window;

export default () => {
  if (!GLOBAL.jsonOrgUnitData) {
    return [];
  }

  return GLOBAL.jsonOrgUnitData.map((item: any) => ({
    id: item.fOrgUnitId,
    code: item.fOrgUnitNumber,
    name: item.fOrgUnitName,
  }));
};

import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const evaluationOptionsStyles: StyleRulesCallback = () => ({
  evaluationOptions: {
    position: 'relative',
  },
  evaluationLabel: {
    position: 'absolute',
    width: '100%',
    marginTop: '-20px',
    textAlign: 'center',
  },
  formControlLabel: {
    marginLeft: 2,
    marginRight: -15,
  },
  formControlLabelFirst: {
    marginLeft: 10,
    marginRight: -15,
  },
  formControlLabelLast: {
    marginLeft: 2,
    marginRight: 10,
  },
});

export default evaluationOptionsStyles;

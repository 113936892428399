import Service from '@/app/utils/service';
import { Dispatch, ReducerAction, Reducer } from 'react';
import { v4 as uuid4 } from 'uuid';

import { ReportSettings, UpdatableReportSettingsData } from './models';

export enum ReportSettingsUtilsActionKeys {
  INITIALIZE_REPORT_SETTINGS = 'INITIALIZE_REPORT_SETTINGS',
  DELETE_REPORT_SETTINGS_PENDING = 'DELETE_REPORT_SETTINGS_PENDING',
  DELETE_REPORT_SETTINGS_FULFILLED = 'DELETE_REPORT_SETTINGS_FULFILLED',
  DELETE_REPORT_SETTINGS_REJECTED = 'DELETE_REPORT_SETTINGS_REJECTED',
  CHANGE_OPENED_REPORT_SETTINGS = 'CHANGE_OPENED_REPORT_SETTINGS',
  CREATE_REPORT_SETTINGS_PENDING = 'CREATE_REPORT_SETTINGS_PENDING',
  CREATE_REPORT_SETTINGS_FULFILLED = 'CREATE_REPORT_SETTINGS_FULFILLED',
  CREATE_REPORT_SETTINGS_REJECTED = 'CREATE_REPORT_SETTINGS_REJECTED',
  FETCH_REPORT_SETTINGS_PENDING = 'FETCH_REPORT_SETTINGS_PENDING',
  FETCH_REPORT_SETTINGS_FULFILLED = 'FETCH_REPORT_SETTINGS_FULFILLED',
  FETCH_REPORT_SETTINGS_REJECTED = 'FETCH_REPORT_SETTINGS_REJECTED',
  UPDATE_REPORT_SETTINGS_PENDING = 'UPDATE_REPORT_SETTINGS_PENDING',
  UPDATE_REPORT_SETTINGS_FULFILLED = 'UPDATE_REPORT_SETTINGS_FULFILLED',
  UPDATE_REPORT_SETTINGS_REJECTED = 'UPDATE_REPORT_SETTINGS_REJECTED',
}

type InitializeReportSettingsActionType = {
  type: ReportSettingsUtilsActionKeys.INITIALIZE_REPORT_SETTINGS;
  payload: {
    reportSettings: ReportSettings[];
    openedReportSettings?: ReportSettings;
  };
};

type DeleteReportSettingsPendingActionType = {
  type: ReportSettingsUtilsActionKeys.DELETE_REPORT_SETTINGS_PENDING;
  meta: string;
};

type DeleteReportSettingsFulfilledActionType = {
  type: ReportSettingsUtilsActionKeys.DELETE_REPORT_SETTINGS_FULFILLED;
  meta: string;
};

type DeleteReportSettingsRejectedActionType = {
  type: ReportSettingsUtilsActionKeys.DELETE_REPORT_SETTINGS_REJECTED;
  error: object;
  meta: string;
};

type ChangeOpenedReportSettingsActionType = {
  type: ReportSettingsUtilsActionKeys.CHANGE_OPENED_REPORT_SETTINGS;
  payload?: ReportSettings;
};

type CreateReportSettingsPendingActionType = {
  type: ReportSettingsUtilsActionKeys.CREATE_REPORT_SETTINGS_PENDING;
};

type CreateReportSettingsFulfilledActionType = {
  type: ReportSettingsUtilsActionKeys.CREATE_REPORT_SETTINGS_FULFILLED;
  payload: ReportSettings;
};

type CreateReportSettingsRejectedActionType = {
  type: ReportSettingsUtilsActionKeys.CREATE_REPORT_SETTINGS_REJECTED;
  error: object;
};

type FetchReportSettingsPendingActionType = {
  type: ReportSettingsUtilsActionKeys.FETCH_REPORT_SETTINGS_PENDING;
};

type FetchReportSettingsFulfilledActionType = {
  type: ReportSettingsUtilsActionKeys.FETCH_REPORT_SETTINGS_FULFILLED;
  payload: { reportSettings: ReportSettings[]; openedReportSettings?: ReportSettings};
};

type FetchReportSettingsRejectedActionType = {
  type: ReportSettingsUtilsActionKeys.FETCH_REPORT_SETTINGS_REJECTED;
  error: object;
};

type UpdateReportSettingsPendingActionType = {
  type: ReportSettingsUtilsActionKeys.UPDATE_REPORT_SETTINGS_PENDING;
  meta: string;
};

type UpdateReportSettingsFulfilledActionType = {
  type: ReportSettingsUtilsActionKeys.UPDATE_REPORT_SETTINGS_FULFILLED;
  payload: ReportSettings;
  meta: string;
};

type UpdateReportSettingsRejectedActionType = {
  type: ReportSettingsUtilsActionKeys.UPDATE_REPORT_SETTINGS_REJECTED;
  error: object;
  meta: string;
};

export type ReportSettingsUtilsActionTypes =
  | InitializeReportSettingsActionType
  | DeleteReportSettingsPendingActionType
  | DeleteReportSettingsFulfilledActionType
  | DeleteReportSettingsRejectedActionType
  | ChangeOpenedReportSettingsActionType
  | CreateReportSettingsPendingActionType
  | CreateReportSettingsFulfilledActionType
  | CreateReportSettingsRejectedActionType
  | FetchReportSettingsPendingActionType
  | FetchReportSettingsFulfilledActionType
  | FetchReportSettingsRejectedActionType
  | UpdateReportSettingsPendingActionType
  | UpdateReportSettingsFulfilledActionType
  | UpdateReportSettingsRejectedActionType;

export type ReportSettingsUtilsDispatch = Dispatch<
  ReducerAction<Reducer<unknown, ReportSettingsUtilsActionTypes>>
>;

export const initializeReportSettingsAction = (
  reportSettings: ReportSettings[],
  openedReportSettings?: ReportSettings,
): InitializeReportSettingsActionType => ({
  type: ReportSettingsUtilsActionKeys.INITIALIZE_REPORT_SETTINGS,
  payload: { reportSettings, openedReportSettings },
});

export const deleteReportSettingsAction = (
  dispatch: ReportSettingsUtilsDispatch,
  id: string,
) => {
  dispatch({
    type: ReportSettingsUtilsActionKeys.DELETE_REPORT_SETTINGS_PENDING,
    meta: id,
  });

  Service.delete(
    `/d/json/report/settings/${id}`,
    undefined,
    () => {
      dispatch({
        type: ReportSettingsUtilsActionKeys.DELETE_REPORT_SETTINGS_FULFILLED,
        meta: id,
      });
    },
    (error: object) => {
      dispatch({
        type: ReportSettingsUtilsActionKeys.DELETE_REPORT_SETTINGS_REJECTED,
        error,
        meta: id,
      });
    },
  );
};

export const changeOpenedReportSettingsAction = (
  nextOpenedReportSettings?: ReportSettings,
): ChangeOpenedReportSettingsActionType => ({
  type: ReportSettingsUtilsActionKeys.CHANGE_OPENED_REPORT_SETTINGS,
  payload: nextOpenedReportSettings,
});

export const createReportSettingsAction = (
  dispatch: ReportSettingsUtilsDispatch,
  data: UpdatableReportSettingsData,
) => {
  dispatch({
    type: ReportSettingsUtilsActionKeys.CREATE_REPORT_SETTINGS_PENDING,
  });

  Service.post(
    '/d/json/report/settings/create',
    { ...data, id: uuid4() },
    (rs: ReportSettings) => {
      dispatch({
        type: ReportSettingsUtilsActionKeys.CREATE_REPORT_SETTINGS_FULFILLED,
        payload: rs,
      });
    },
    (error: object) => {
      dispatch({
        type: ReportSettingsUtilsActionKeys.CREATE_REPORT_SETTINGS_REJECTED,
        error,
      });
    },
  );
};

export const fetchReportSettingsAction = (
  dispatch: ReportSettingsUtilsDispatch,
  rt: string,
  defaultOpenedReportSettingsId?: string,
) => {
  dispatch({
    type: ReportSettingsUtilsActionKeys.FETCH_REPORT_SETTINGS_PENDING,
  });

  Service.get(
    '/d/json/report/settings',
    (rs: ReportSettings[]) => {
      const filteredSettings = rs.filter(({ reportType }) => reportType === rt);
      dispatch({
        type: ReportSettingsUtilsActionKeys.FETCH_REPORT_SETTINGS_FULFILLED,
        payload: {
          reportSettings: filteredSettings,
          openedReportSettings: filteredSettings.find((setting) => {
            return setting.id === defaultOpenedReportSettingsId;
          })
        },
      });
    },
    (error: object) => {
      dispatch({
        type: ReportSettingsUtilsActionKeys.FETCH_REPORT_SETTINGS_REJECTED,
        error,
      });
    },
  );
};

export const updateReportSettingsAction = (
  dispatch: ReportSettingsUtilsDispatch,
  id: string,
  data: UpdatableReportSettingsData,
) => {
  dispatch({
    type: ReportSettingsUtilsActionKeys.UPDATE_REPORT_SETTINGS_PENDING,
    meta: id,
  });

  Service.put(
    `/d/json/report/settings/${id}`,
    data,
    (rs: ReportSettings) => {
      dispatch({
        type: ReportSettingsUtilsActionKeys.UPDATE_REPORT_SETTINGS_FULFILLED,
        payload: rs,
        meta: id,
      });
    },
    (error: object) => {
      dispatch({
        type: ReportSettingsUtilsActionKeys.UPDATE_REPORT_SETTINGS_REJECTED,
        error,
        meta: id,
      });
    },
  );
};

import { Record } from 'immutable';
import { Dispatch } from 'redux';
import { THROW_ERROR } from './error';
import Service from '@/app/utils/service';
import APIs from '../api/internalAPIs';
import { getLoggedUserId } from '@/old/utils/helper';
import { object } from 'prop-types';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const FETCHING = '@@solaforce/currentUser/FETCHING';
const NOT_FETCHING = '@@solaforce/currentuser/NOT_FETCHING';
const FETCH_MY_DETAILS = '@@solaforce/currentUser/FETCH_MY_DETAILS';
const FETCH_MY_MAN_UNITS = '@@solaforce/currentUser/FETCH_MY_MAN_UNITS';
const FETCH_MY_HR_UNITS = '@@solaforce/currentUser/FETCH_MY_HR_UNITS';
const FETCH_COMPANY_WORKFLOW = '@@solaforce/currentUser/FETCH_COMPANY_WORKFLOW';

export type UserDetails = {
  fLoginEmpId: number;
  fLoginProfileId: number;
  fLoginUsername: string;
  fLoginStatus: 'ACTIVE' | 'INACTIVE';
  fLoginIsExternal: boolean;
  fLoginLanguage: string;
  fLoginRoles: string[];
  fLoginEmpProfPictureId: string;
  fLoginNeedsPasswordChange: boolean;
  fLoginLastInfo: {
    fLoginLastTimestamp: string;
    fLoginLastIP: string;
  };
};

const initialState = Record({
  isFetching: false,
  myDetails: object,
  myManagedUnits: [],
  myHrUnits: [],
  companyWorkflow: object,
});

const reducer = (state = new initialState(), action: any) => {
  switch (action.type) {
    case FETCHING:
      return state.set('isFetching', true);
    case NOT_FETCHING:
      return state.set('isFetching', false);
    case FETCH_MY_DETAILS:
      return state.set('myDetails', action.myDetails);
    case FETCH_MY_MAN_UNITS:
      return state.set('myManagedUnits', action.myManUnits);
    case FETCH_MY_HR_UNITS:
      return state.set('myHrUnits', action.myHrUnits);
    case FETCH_COMPANY_WORKFLOW:
      return state.set('companyWorkflow', action.companyWorkflow);
    default:
      return state;
  }
};

export const fetchMyDetails = () => async (dispatch: Dispatch) => {
  await dispatch({ type: FETCHING });
  const { fLoginUsername } = cookies.get('sola-login-page');

  if (!fLoginUsername) {
    dispatch({ type: NOT_FETCHING });
    return;
  }

  Service.get(
    APIs.user.get(fLoginUsername),
    (data: UserDetails) => {
      dispatch({ type: FETCH_MY_DETAILS, myDetails: data });
      dispatch({ type: NOT_FETCHING });
    },
    (error: Error) => {
      dispatch({ type: NOT_FETCHING });
      dispatch({ type: THROW_ERROR, error });
    }
  );
};

export const fetchMyManagedUnits = (fullUnitList: Array<object>, idFieldName: string = 'fTreeUnitId') => {
  return async (dispatch: Dispatch) => {
    await dispatch({ type: FETCHING });

    Service.get(
      APIs.orgTree.myManagedUnits(getLoggedUserId()),
      (data: any) => {
        const myManUnits = fullUnitList.filter((unit: any) => data.indexOf(parseInt(unit[idFieldName], 10)) !== -1);
        dispatch({ type: FETCH_MY_MAN_UNITS, myManUnits });
        dispatch({ type: NOT_FETCHING });
      },
      (error: any) => {
        dispatch({ type: NOT_FETCHING });
        dispatch({ type: THROW_ERROR, error });
      }
    );
  };
};

export const fetchMyHrUnits = (fullUnitList: Array<object>, idFieldName: string = 'fTreeUnitId') => {
  return async (dispatch: Dispatch) => {
    await dispatch({ type: FETCHING });

    Service.get(
      APIs.orgTree.myHrUnits(getLoggedUserId()),
      (data: any) => {
        const myHrUnits = fullUnitList.filter((unit: any) => data.indexOf(parseInt(unit[idFieldName], 10)) !== -1);
        dispatch({ type: FETCH_MY_HR_UNITS, myHrUnits });
        dispatch({ type: NOT_FETCHING });
      },
      (error: any) => {
        dispatch({ type: NOT_FETCHING });
        dispatch({ type: THROW_ERROR, error });
      }
    );
  };
};

export const fetchCompanyWorkflow = () => {
  return async (dispatch: Dispatch) => {
    Service.get(
      APIs.company.configWorkflow,
      (companyWorkflow: any) => {
        dispatch({ type: FETCH_COMPANY_WORKFLOW, companyWorkflow });
      },
      (error: any) => {
        dispatch({ type: THROW_ERROR, error });
      }
    );
  };
};

export default reducer;
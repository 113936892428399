import * as React from 'react';
import Modal from '@material-ui/core/Modal';
import AddNewTemplate from '../AddNewTemplate/AddNewTemplate';
import { compose } from 'recompose';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import modalWrapperStyle from './modalWrapperStyle';
import { LanguagesType } from '@/app/redux/languages';

type IncomingProps = {
  modalOpen: boolean;
  languages: LanguagesType[];
  addJobEvaluationTemplate: any;
  handleModalClose: () => void;
  handleEditTemplate: (selectedTemplateIds: string[]) => void;
};

type PropsType = IncomingProps & WithStyles<typeof modalWrapperStyle>;
class ModalWrapper extends React.PureComponent<PropsType> {
  render() {
    const {
      languages,
      modalOpen,
      addJobEvaluationTemplate,
      handleModalClose,
      handleEditTemplate,
    } = this.props;

    return (
      <div>
        <Modal open={modalOpen} onClose={handleModalClose}>
          <AddNewTemplate
            languages={languages}
            handleModalClose={handleModalClose}
            addJobEvaluationTemplate={addJobEvaluationTemplate}
            handleEditTemplate={handleEditTemplate}
          />
        </Modal>
      </div>
    );
  }
}

const enhance = compose<PropsType, IncomingProps>(
  withStyles(modalWrapperStyle),
);

export default enhance(ModalWrapper);

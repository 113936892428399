import * as React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip, Icon, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { WithStyles } from '@material-ui/core';
import sectionTopToolbarStyle from './sectionTopToolbarStyle';
import Modal from '@material-ui/core/Modal';
import AddComponentForm from '@/app/components/TemplateComponents/AddComponentForm/AddComponentForm';
import AddComponentPicker from '@/app/components/TemplateComponents/AddComponentPicker/AddComponentPicker';
import translate from '@/app/utils/translate';
import {
  FormTemplate,
  FormTemplateComponentDeleteCall,
  FormTemplateComponentUpdateCall
} from '@/app/components/TemplateComponents/types';

type PropsType = WithStyles<typeof sectionTopToolbarStyle>;

type ToolbarTypes = {
  partialShow: boolean,
  fullMenu: boolean,
  addComponentToSection: FormTemplateComponentUpdateCall,
  updateSectionComponent: FormTemplateComponentUpdateCall,
  readyToEditTemplate: FormTemplate,
  section: any,
  handleEditComponentModal: any,
  componentType: string,
  componentEditMode: boolean,
  deleteComponent: FormTemplateComponentDeleteCall,
  sortComponents: any,
  selectedComponentIds: [],
  endEditMode: any,
  openAddComponentModal: boolean
  handleAddComponent: any,
  handleModalClose: any,
  templateType: string;
};

class SectionTopToolbar extends React.PureComponent<PropsType & ToolbarTypes> {
  handleSortComponents = () => {
    this.props.sortComponents();
  };

  handleComponentDeletion = (templateId: string, sectionId: string, selectedComponentIds: []) => {
    this.props.deleteComponent(templateId, sectionId, selectedComponentIds);
  };

  handleManageComponentDialogClose = () => {
    this.props.handleModalClose();

    if (this.props.componentEditMode) {
      setTimeout( () => { this.props.endEditMode(); }, 750);
    }
  };

  render() {
    const {
      classes,
      partialShow,
      fullMenu,
      selectedComponentIds,
      readyToEditTemplate,
      section,
      componentType,
      componentEditMode,
      handleEditComponentModal,
      openAddComponentModal,
      handleAddComponent,
      templateType,
   } = this.props;

    const [editedSection] = readyToEditTemplate.sections.filter((sec: any) => sec.id === section.id);
    const [editedComponentId] = selectedComponentIds.slice(0, 1);
    const [component] = editedComponentId ?
                        editedSection.components.filter((comp: any) => comp.id === editedComponentId)
                        : [{}];

    return (
      <>
        <span className={classes.root}>
          {partialShow &&
            <>
            <IconButton onClick={() => this.handleSortComponents()}>
              <Tooltip title={translate.t('laSort')}>
                  <Icon color={(editedSection.components || {}).length < 2 ? 'disabled' : 'primary'}>
                  sort
                  </Icon>
              </Tooltip>
            </IconButton>

            <AddComponentPicker onComponentAdd={handleAddComponent} templateType={templateType} />

            {fullMenu ? (
              <>
                <IconButton onClick={() => handleEditComponentModal(readyToEditTemplate.id, section.id, selectedComponentIds, component, false)}>
                  <Tooltip title={translate.t('laEdit')}>
                    <EditIcon color={selectedComponentIds.length < 2 ? 'primary' : 'disabled'} />
                  </Tooltip>
                </IconButton>
                <IconButton
                  onClick={() => this.handleComponentDeletion(readyToEditTemplate.id, section.id, selectedComponentIds)}
                >
                  <Tooltip title={translate.t('laDelete')}>
                    <DeleteIcon color="primary"/>
                  </Tooltip>
                </IconButton>
              </>
            ) : (
              <>
                <IconButton disabled>
                  <Tooltip title={translate.t('laEdit')}>
                    <EditIcon color="disabled"/>
                  </Tooltip>
                </IconButton>
                <IconButton disabled>
                  <Tooltip title={translate.t('laDelete')}>
                    <DeleteIcon color="disabled"/>
                  </Tooltip>
                </IconButton>
              </>
            )
            }
            </>
          }
        </span>
        <div>

        <Modal
          open={openAddComponentModal}
          onClose={this.handleManageComponentDialogClose}
        >
          <AddComponentForm
            component={componentEditMode && component || undefined}
            componentType={componentType}
            onCancel={this.handleManageComponentDialogClose}
            onComponentAdd={(c: any) => {
              this.props.addComponentToSection(readyToEditTemplate.id, section.id, {
                  ...c, index: section.components ? section.components.length + 1 : 1
              });
              this.handleManageComponentDialogClose();
            }}
            onComponentUpdate={(c: any) => {
              this.props.updateSectionComponent(readyToEditTemplate.id, section.id, c);
              this.handleManageComponentDialogClose();
            }}
          />
        </Modal>
      </div>
      </>
    );
  }
}

const enhance = compose<any, any>(withStyles(sectionTopToolbarStyle));

export default enhance(SectionTopToolbar);
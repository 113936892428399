import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const tooltipStyle: StyleRulesCallback = () => ({
  text: {
    maxWidth: '90%',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '& > *': {
      display: 'inline',
    },
  },
});

export default tooltipStyle;

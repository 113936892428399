import * as React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip, Icon, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { WithStyles } from '@material-ui/core';
import topMenuToolBarStyle from './topMenuToolBarStyle';
import ModalWrapper from './ModalWrapper';
import translate from '@/app/utils/translate';

type PropsType = OwnProps & WithStyles<typeof topMenuToolBarStyle>;

type OwnProps = {
  partialShow: boolean;
  fullMenu: boolean;
  deleteScoreGroup: (ids: string[]) => void;
  selectedScoreGroupIds: string[];
  handleEditScoreGroup: (selectedScoreGroupId: string) => void;
  selectedScoreGroupId: string;
  usedScoreGroups: string[][];
};

class TopMenuToolBar extends React.PureComponent<PropsType> {
  state = {
    modalOpen: false,
    openEditModal: false,
  };

  handleAddScoreGroup = () => {
    this.setState({ modalOpen: true });
  };

  handleScoreGroupDeletion = (selectedScoreGroupIds: string[]) => {
    this.props.deleteScoreGroup(selectedScoreGroupIds);
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false, openEditModal: false });
  };

  render() {
    const {
      classes,
      partialShow,
      fullMenu,
      selectedScoreGroupIds,
      handleEditScoreGroup,
      selectedScoreGroupId,
      usedScoreGroups,
    } = this.props;
    const { modalOpen } = this.state;

    return (
      <>
        <span className={classes.root}>
          <IconButton onClick={() => this.handleAddScoreGroup()}>
            <Tooltip title={translate.t('laAdd')}>
              <Icon color="primary">add_circle</Icon>
            </Tooltip>
          </IconButton>
          {fullMenu && (
            <>
              <IconButton
                onClick={() =>
                  handleEditScoreGroup(
                    selectedScoreGroupId || selectedScoreGroupIds[0],
                  )
                }
              >
                <Tooltip title={translate.t('laEdit')}>
                  <EditIcon color="primary" />
                </Tooltip>
              </IconButton>
            </>
          )}
          {(partialShow || fullMenu) && (
            <>
              <IconButton
                onClick={() =>
                  this.handleScoreGroupDeletion(selectedScoreGroupIds)
                }
              >
                <Tooltip title={translate.t('laDelete')}>
                  <DeleteIcon color="primary" />
                </Tooltip>
              </IconButton>
            </>
          )}
        </span>
        <ModalWrapper
          modalOpen={modalOpen}
          handleModalClose={this.handleModalClose}
          selectedScoreGroupId={selectedScoreGroupId}
          usedScoreGroups={usedScoreGroups}
        />
      </>
    );
  }
}

const enhance = compose<any, any>(withStyles(topMenuToolBarStyle));

export default enhance(TopMenuToolBar);

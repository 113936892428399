import * as React from 'react';
import { compose } from 'recompose';
import {
  withStyles,
  WithStyles,
  Paper,
  Button,
  IconButton,
  Tooltip,
  Input,
  InputAdornment,
  Modal,
  Typography,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SortIcon from '@material-ui/icons/Sort';
import translate from '@/app/utils/translate';
import { sortArr } from '@/app/utils/helper';
import addStageStyle from './addStageStyle';
import { nameDuplicationCheck, reIndexAndResetDefault } from '../helper';
import SortableHOC from '../../EditTemplateComponents/SortableHOC/SortableHOC';

type IncomingProps = {
  editedTemplate: any;
  openedStageModal: boolean;
  closeStagesModal: any;
  changeStageList: any;
};

type StateType = {
  showValidationError: boolean;
  disabledSave: boolean;
  inputOptions: any[];
  editMode: boolean;
  openSortingDialog: boolean;
  lastUsedIndex: number;
};

type PropsType = IncomingProps & WithStyles<typeof addStageStyle>;

class AddStage extends React.PureComponent<PropsType> {
  state: StateType = {
    showValidationError: false,
    disabledSave: true,
    inputOptions: [
      { label: '', index: 0, default: true },
      { label: '', index: 1 },
    ],
    editMode: false,
    openSortingDialog: false,
    lastUsedIndex: 1,
  };

  constructor(props: any) {
    super(props);

    if (props.editedTemplate) {
      const { stages } = this.props.editedTemplate;

      const orderedOptions = stages
        ? // Also removes stages that were previously deleted
          sortArr(
            stages.filter(({ isDeleted }: any) => !isDeleted),
            'index',
          )
        : [];

      this.state = {
        showValidationError: false,
        disabledSave: false,
        inputOptions: orderedOptions,
        openSortingDialog: false,
        editMode: true,
        lastUsedIndex:
          orderedOptions.length > 0
            ? orderedOptions[orderedOptions.length - 1].index
            : this.state.lastUsedIndex,
      };
    }
  }

  addInputOptions = () => {
    this.setState((prevState: any) => ({
      inputOptions: [
        ...prevState.inputOptions,
        { label: '', index: this.state.lastUsedIndex + 1 },
      ],
      disabledSave: true,
      lastUsedIndex: this.state.lastUsedIndex + 1,
    }));
  };

  removeInputOption = (indexToRemove: number) => {
    this.setState((prevState: any) => ({
      inputOptions: prevState.inputOptions.filter(
        ({ index }: any) => index !== indexToRemove,
      ),
      disabledSave: false,
      showValidationError: false,
    }));
  };

  handleInputChanges = (e: any, index: number) => {
    const nextOptions = this.state.inputOptions.map((option, i) => {
      if (i === index) {
        return { ...option, label: e.target.value };
      }

      return option;
    });

    this.setState({
      inputOptions: nextOptions,
      disabledSave: nextOptions.some(({ label }) => !label),
      showValidationError: false,
    });
  };

  componentSortOpen = () => {
    this.setState({ openSortingDialog: true });
  };

  closeSortingModal = () => {
    this.setState({ openSortingDialog: false });
  };

  saveNewOrder = (newOrder: any) => {
    this.setState({ inputOptions: newOrder }, () => {
      this.closeSortingModal();
      this.props.changeStageList(reIndexAndResetDefault(newOrder));
      this.props.closeStagesModal();
    });
  };

  handleSaveStages = (e: any) => {
    e.preventDefault();

    if (nameDuplicationCheck(this.state.inputOptions)) {
      this.setState({
        disabledSave: true,
        showValidationError: true,
      });

      return;
    }

    this.props.changeStageList(this.state.inputOptions);
    this.props.closeStagesModal();
  };

  render() {
    const { classes } = this.props;
    const {
      showValidationError,
      disabledSave,
      inputOptions,
      openSortingDialog,
    } = this.state;

    return (
      <>
        <Modal open={this.props.openedStageModal}>
          <Paper className={classes.root}>
            <form className={classes.form} onSubmit={this.handleSaveStages}>
              <div>
                <h3 className={classes.topTitle}>
                  {translate.t('add_stage_label')}

                  <span className={classes.topRightCtrButtons}>
                    <IconButton onClick={this.addInputOptions}>
                      <Tooltip title={translate.t('laStage')}>
                        <AddCircleIcon color="primary" />
                      </Tooltip>
                    </IconButton>
                  </span>

                  <span className={classes.topRightCtrButtons}>
                    <IconButton
                      disabled={disabledSave}
                      onClick={this.componentSortOpen}
                    >
                      <Tooltip title={translate.t('laSort')}>
                        <SortIcon
                          color={disabledSave ? 'disabled' : 'primary'}
                        />
                      </Tooltip>
                    </IconButton>
                  </span>
                </h3>

                {inputOptions.map((_input, index) => {
                  return (
                    <div key={`stage-${inputOptions[index].index}`}>
                      <Input
                        value={inputOptions[index].label}
                        onChange={e => this.handleInputChanges(e, index)}
                        placeholder={`${translate.t('add_stage_name')}`}
                        className={classes.input}
                        error={showValidationError}
                        multiline={true}
                        rows={1}
                        rowsMax={5}
                        endAdornment={
                          index > 0 && (
                            <InputAdornment position="end">
                              <IconButton
                                className={classes.button}
                                onClick={() =>
                                  this.removeInputOption(
                                    inputOptions[index].index,
                                  )
                                }
                                disableRipple
                              >
                                <HighlightOffIcon />
                              </IconButton>
                            </InputAdornment>
                          )
                        }
                      />

                      {showValidationError &&
                        inputOptions.length - 1 === index && (
                          <Typography
                            variant="h6"
                            color="error"
                            className={classes.validationError}
                          >
                            {translate.t('laNameDuplicateError')}
                          </Typography>
                        )}
                    </div>
                  );
                })}
              </div>

              <div className={classes.buttonsWrapper}>
                <Button
                  color="primary"
                  variant="text"
                  onClick={this.props.closeStagesModal}
                >
                  {translate.t('laCancel')}
                </Button>

                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={disabledSave}
                >
                  {translate.t('laSave')}
                </Button>
              </div>
            </form>
          </Paper>
        </Modal>

        <SortableHOC
          key={inputOptions.length}
          open={openSortingDialog}
          itemsToSort={inputOptions}
          saveNewOrder={this.saveNewOrder}
          cancelNewOrder={this.closeSortingModal}
        />
      </>
    );
  }
}

const enhance = compose<any, any>(withStyles(addStageStyle));

export default enhance(AddStage);

import * as React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  WithStyles,
} from '@material-ui/core';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { LanguagesType } from '@/app/redux/languages';
import { ReducerState } from '@/app/redux/store';
import {
  fetchAllProcessTemplates,
  addProcessTemplate,
  updateProcessTemplate,
  changeTemplateStatus,
  deleteTemplate,
  duplicateTemplate,
  addTemplateSection,
  deleteSection,
  addComponentToSection,
  updateSectionComponent,
  orderComponents,
  deleteComponent,
  updateSection,
} from '@/app/redux/processTemplates';
import {
  openConfirmDialog,
  ConfirmDialogType,
} from '@/app/redux/confirmDialog';
import Loading from '@/app/components/Loading/Loading';
import translate from '@/app/utils/translate';
import ConfigureUiLabelButton from '@/app/components/ConfigurableLabels/ConfigureUiLabelButton/ConfigureUiLabelButton';
import EditTemplate from './EditTemplate/EditTemplate';
import TopMenuToolBar from './TopMenuToolBar/TopMenuToolBar';
import SideRowMenu from '@/app/components/TemplateComponents/admin/SideMenuToolBar/SideRowMenu';
import { sortArr } from '@/app/components/TemplateComponents/helpers';
import processTemplatesStyle from './processTemplatesStyle';
import { FormTemplate } from '@/app/components/TemplateComponents/types';
import { fetchAllEnums } from '@/app/redux/enums';

type MapStateToProps = {
  allProcessTemplates: [];
  languages: LanguagesType[];
};

type MapDispatchToProps = {
  fetchAllProcessTemplates: () => any;
  addProcessTemplate: () => any;
  updateProcessTemplate: () => any;
  changeTemplateStatus: () => any;
  deleteTemplate: (templateIds: []) => any;
  openConfirmDialog: (payload: ConfirmDialogType) => void;
  duplicateTemplate: () => any;
  addTemplateSection: () => any;
  addComponentToSection: () => any;
  updateSectionComponent: () => any;
  updateSection: (section: any) => any;
  deleteSection: (templateId: string, sectionId: string) => any;
  orderComponents: (sectionId: string, components: any) => any;
  deleteComponent: (
    templateId: string,
    sectionId: string,
    componentIds: [],
  ) => any;
  fetchAllEnums: () => void;
};

type PropsType = MapStateToProps &
  MapDispatchToProps &
  WithStyles<typeof processTemplatesStyle>;

type StateType = {
  allCheckboxesSelected: boolean;
  selectedCheckboxes: any[];
  rowCount: number;
  openEditModal: boolean;
  readyToEditTemplate: FormTemplate | undefined;
  displayedOrder: any;
  orderBy: string;
  warningMessage: boolean;
  isLoading: boolean;
};

class ProcessTemplates extends React.PureComponent<PropsType> {
  state: StateType = {
    allCheckboxesSelected: false,
    selectedCheckboxes: [],
    rowCount: -1,
    openEditModal: false,
    readyToEditTemplate: undefined,
    displayedOrder: {
      subject: 'desc',
      status: 'desc',
      updatedTime: 'desc',
    },
    orderBy: 'updatedTime',
    warningMessage: false,
    isLoading: false,
  };

  componentDidMount() {
    this.setState({ isLoading: true });
    this.props
      .fetchAllProcessTemplates()
      .then(() => this.setState({ isLoading: false }));
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const rowCount = this.props.allProcessTemplates.length;
    if (prevProps.allProcessTemplates.length !== rowCount) {
      this.setState({ rowCount: rowCount });
    }
    if (rowCount !== prevState.rowCount && prevState.rowCount !== -1) {
      // On row deletion/new template add - set component's selected checkboxes state back to []
      this.setState({
        selectedCheckboxes: [],
        readyToEditTemplate: undefined,
        allCheckboxesSelected: false,
      });
    }
    const { readyToEditTemplate } = this.state;
    // Given the edit component relies on the local state the below is used to trigger re-render on section changes
    const beingEditedTemplate: any = this.props.allProcessTemplates.filter(
      (item: any) =>
        !!readyToEditTemplate && item.id === readyToEditTemplate.id,
    )[0];
    if (
      beingEditedTemplate !== undefined &&
      readyToEditTemplate !== undefined &&
      this.state.openEditModal
    ) {
      if (readyToEditTemplate.sections !== beingEditedTemplate.sections) {
        this.handleEditTemplate([readyToEditTemplate.id]);
      }
    }
  }

  handleChange = (id: string) => {
    let selectedRows = this.state.selectedCheckboxes;
    if (selectedRows.includes(id)) {
      selectedRows = selectedRows.filter(row => row !== id);
    } else {
      selectedRows = [...selectedRows, id];
    }
    this.setState({ selectedCheckboxes: selectedRows });

    if (selectedRows.length === this.state.rowCount) {
      this.setState({ allCheckboxesSelected: true });
    } else {
      this.setState({ allCheckboxesSelected: false });
    }
  };

  onSelectAllClick = (allProcessTemplates: []) => {
    let selectedRows = [];
    selectedRows = allProcessTemplates.map((template: any) => template.id);
    this.setState({
      allCheckboxesSelected: !this.state.allCheckboxesSelected,
      selectedCheckboxes: !this.state.allCheckboxesSelected ? selectedRows : [],
    });
  };

  handleEditTemplate = (selectedTemplateIds: string[]) => {
    const allProcessTemplates = this.props.allProcessTemplates;
    const [selectedTemplate] = selectedTemplateIds;
    const readyToEditTemplate: any = allProcessTemplates.filter(
      (template: any) => template.id === selectedTemplate,
    )[0];

    if (readyToEditTemplate.status === 'ACTIVE') {
      this.setState({ warningMessage: true });
    } else {
      this.setState({ warningMessage: false });
    }

    this.setState({
      openEditModal: true,
      readyToEditTemplate: readyToEditTemplate,
    });
  };

  handleDelete = (templateIds: []) => {
    this.props.openConfirmDialog({
      text: translate.t('template_delete_warning'),
      onOk: () => this.props.deleteTemplate(templateIds),
    });
  };

  handleDeleteSection = (templateId: string, sectionId: string) => {
    this.props.openConfirmDialog({
      text: translate.t('confirm_delete_item_s'),
      onOk: () => this.props.deleteSection(templateId, sectionId),
    });
  };

  handleDeleteComponent = (
    templateId: string,
    sectionId: string,
    componentIds: [],
  ) => {
    this.props.openConfirmDialog({
      text: translate.t('confirm_delete_item_s'),
      onOk: () =>
        this.props.deleteComponent(templateId, sectionId, componentIds),
    });
  };

  handleModalClose = () => {
    this.setState({ openEditModal: false });
  };

  sortHandle = (templates: any[], sortBy: string, order: string) => {
    // TODO - revise for sorting by date after lastEdited date format is received from real DB
    const reversedOrder = order === 'asc' ? 'desc' : 'asc';
    sortArr(templates, sortBy, reversedOrder);
    this.setState({
      displayedOrder: { ...this.state.displayedOrder, [sortBy]: reversedOrder },
    });
    this.setState({ orderBy: sortBy });
  };

  render() {
    const {
      classes,
      allProcessTemplates,
      languages,
      fetchAllEnums: handleUpdateEnum,
    } = this.props;
    const {
      allCheckboxesSelected,
      selectedCheckboxes,
      openEditModal,
      readyToEditTemplate,
      displayedOrder,
      orderBy,
      warningMessage,
      isLoading,
    } = this.state;

    const statusTranslation = {
      ACTIVE: 'label_ACTIVE',
      INACTIVE: 'label_inactive',
    };

    return (
      <>
        <Paper className={classes.root}>
          <Typography variant="h6" className={classes.sectionTitle}>
            <ConfigureUiLabelButton
              term={'laEarlyInvolvement'}
              fetchAllEnums={handleUpdateEnum}
            />
            {selectedCheckboxes.length > 0 && (
              <span className={classes.subHeader}>
                {translate.t('x_selected', {
                  count: selectedCheckboxes.length,
                })}
              </span>
            )}
            <TopMenuToolBar
              languages={languages}
              fullMenu={selectedCheckboxes.length === 1}
              partialShow={selectedCheckboxes.length > 1}
              selectedTemplateIds={selectedCheckboxes}
              addProcessTemplate={this.props.addProcessTemplate}
              changeTemplateStatus={this.props.changeTemplateStatus}
              deleteTemplate={this.handleDelete}
              duplicateTemplate={this.props.duplicateTemplate}
              handleEditTemplate={this.handleEditTemplate}
            />
          </Typography>
          {!isLoading && allProcessTemplates.length > 0 ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      indeterminate={
                        !allCheckboxesSelected && selectedCheckboxes.length > 0
                      }
                      checked={allCheckboxesSelected}
                      onChange={() =>
                        this.onSelectAllClick(allProcessTemplates)
                      }
                      className={classes.topCheckbox}
                    />
                    <TableSortLabel
                      classes={{
                        icon:
                          orderBy === 'subject'
                            ? classes.activeSortIcon
                            : classes.inactiveSortIcon,
                      }}
                      active={orderBy === 'subject'}
                      direction={displayedOrder.subject}
                      onClick={() =>
                        this.sortHandle(
                          allProcessTemplates,
                          'subject',
                          displayedOrder.subject,
                        )
                      }
                    >
                      {translate.t('laSubject')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      classes={{
                        icon:
                          orderBy === 'status'
                            ? classes.activeSortIcon
                            : classes.inactiveSortIcon,
                      }}
                      active={orderBy === 'status'}
                      direction={displayedOrder.status}
                      onClick={() =>
                        this.sortHandle(
                          allProcessTemplates,
                          'status',
                          displayedOrder.status,
                        )
                      }
                    >
                      {translate.t('laStatus')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      classes={{
                        icon:
                          orderBy === 'updatedTime'
                            ? classes.activeSortIcon
                            : classes.inactiveSortIcon,
                      }}
                      active={orderBy === 'updatedTime'}
                      direction={displayedOrder.updatedTime}
                      onClick={() =>
                        this.sortHandle(
                          allProcessTemplates,
                          'updatedTime',
                          displayedOrder.updatedTime,
                        )
                      }
                    >
                      {translate.t('laLastEdited')}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {allProcessTemplates.map((template: any, index) => {
                  return (
                    <TableRow hover role="checkbox" key={`${index}-row`}>
                      <TableCell
                        className={classes.tablecell}
                        key={`indexTC-${template.id}`}
                        padding="checkbox"
                      >
                        <Checkbox
                          onClick={() => this.handleChange(template.id)}
                          checked={selectedCheckboxes.includes(template.id)}
                          key={`index-${template.id}`}
                        />
                        {template.subject}
                      </TableCell>
                      <TableCell>
                        {translate
                          .t(statusTranslation[template.status])
                          .toUpperCase()}
                      </TableCell>
                      <TableCell className={classes.capitalize}>
                        {template.updatedTime.substring(0, 10)},
                        {' ' + template.updatedBy.username.replace('.', ' ')}
                      </TableCell>
                      <TableCell align="right">
                        <SideRowMenu
                          active={template.status === 'ACTIVE'}
                          tools={{
                            showActive: true,
                            showTranslate: false, // TODO: Enable when feature is supported
                            showDuplicate: true,
                          }}
                          templateId={template.id}
                          changeTemplateStatus={this.props.changeTemplateStatus}
                          deleteTemplate={this.handleDelete}
                          duplicateTemplate={this.props.duplicateTemplate}
                          translateHandler={() => {
                            /** TODO: implement translateHandler */
                          }}
                          templateToEdit={template}
                          handleEditTemplate={this.handleEditTemplate}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) : !isLoading ? (
            <Typography variant="h6">
              {translate.t('laNoTemplatesAvailable')}
            </Typography>
          ) : (
            <Loading key="loading" />
          )}
        </Paper>
        <EditTemplate
          readyToEditTemplate={readyToEditTemplate}
          openEditModal={openEditModal}
          handleModalClose={this.handleModalClose}
          updateProcessTemplate={this.props.updateProcessTemplate}
          addTemplateSection={this.props.addTemplateSection}
          deleteSection={this.handleDeleteSection}
          addComponentToSection={this.props.addComponentToSection}
          updateSectionComponent={this.props.updateSectionComponent}
          updateSection={this.props.updateSection}
          deleteComponent={this.handleDeleteComponent}
          orderComponents={this.props.orderComponents}
          languages={languages}
          selectedCheckboxes={selectedCheckboxes}
          warningMessage={warningMessage}
        />
      </>
    );
  }
}

const mapStateToProps = (state: ReducerState) => {
  const { allProcessTemplates } = state.processTemplates;
  const { languages } = state;

  return {
    allProcessTemplates,
    languages: languages.get('allLanguages'),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchAllProcessTemplates: () => dispatch<any>(fetchAllProcessTemplates()),
  addProcessTemplate: (template: any) =>
    dispatch<any>(addProcessTemplate(template)),
  changeTemplateStatus: (templateIds: [], newStatus: string) =>
    dispatch<any>(changeTemplateStatus(templateIds, newStatus)),
  deleteTemplate: (templateIds: []) =>
    dispatch<any>(deleteTemplate(templateIds)),
  duplicateTemplate: (templateId: string) =>
    dispatch<any>(duplicateTemplate(templateId)),
  addTemplateSection: (templateId: string, section: []) =>
    dispatch<any>(addTemplateSection(templateId, section)),
  deleteSection: (templateId: string, sectionId: string) =>
    dispatch<any>(deleteSection(templateId, sectionId)),
  updateProcessTemplate: (templateId: string, template: any) =>
    dispatch<any>(updateProcessTemplate(templateId, template)),
  addComponentToSection: (
    templateId: string,
    sectionId: string,
    component: {},
  ) => dispatch<any>(addComponentToSection(templateId, sectionId, component)),
  updateSection: (editedTmpId: string, section: any) =>
    dispatch<any>(updateSection(editedTmpId, section)),
  updateSectionComponent: (
    templateId: string,
    sectionId: string,
    component: {},
  ) => dispatch<any>(updateSectionComponent(templateId, sectionId, component)),
  orderComponents: (sectionId: string, components: any) =>
    dispatch<any>(orderComponents(sectionId, components)),
  deleteComponent: (templateId: string, sectionId: string, componentIds: []) =>
    dispatch<any>(deleteComponent(templateId, sectionId, componentIds)),
  openConfirmDialog: (payload: ConfirmDialogType) =>
    dispatch(openConfirmDialog(payload)),
  fetchAllEnums: () => dispatch<any>(fetchAllEnums()),
});

const enhance = compose<any, any>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(processTemplatesStyle),
);

export default enhance(ProcessTemplates);

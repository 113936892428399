import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const autoDeductionRulesDialogStyle: StyleRulesCallback = (theme: Theme) => {
  return {
    paperWidthMd: {
      width: '1100px',
    },
    gridRight: {
      [theme.breakpoints.up('md')]: {
        padding: `0 ${theme.spacing.unit}px`,

        '&:last-child': {
          padding: `0 0 0 ${theme.spacing.unit}px`,
        },
      },
    },
    gridRightContainer: {
      backgroundColor: '#eff0f1',
      width: '100%',
    },
    gridLeft: {
      paddingRight: theme.spacing.unit,
    },
    button: {
      margin: 0,
      padding: theme.spacing.unit,
      fontSize: '10px',
    },
    typography: {
      '&:not(:last-child)': {
        paddingBottom: `${theme.spacing.unit / 2}px`,
      },
      '&:first-of-type': {
        marginBottom: `${theme.spacing.unit / 2}px`,
      },
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    card: {
      maxWidth: '350px',
    },
    link: {
      color: '#2196f3',
    },
    mathjsDocsButton: {
      float: 'right',
      marginLeft: theme.spacing.unit,
    },
  };
};

export default autoDeductionRulesDialogStyle;

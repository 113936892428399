import * as React from 'react';
import { compose } from 'recompose';
import { LanguagesType } from '@/app/redux/languages';
import { withStyles } from '@material-ui/core/styles';
import { WithStyles, Paper, Icon, Button, Typography } from '@material-ui/core';
import { orderElementsByIndex } from '../../../helpers';
import editTemplateStyle from './editTemplateStyle';
import SectionListing from './SectionListing/SectionListing';
import Modal from '@material-ui/core/Modal';
import AddSection from './AddSection/AddSection';
import translate from '@/app/utils/translate';
import SortableHOC from './SortableHOC/SortableHOC';
import { ActiveTemplate } from '../../../types';
import TemplateHeader from '@/app/components/TemplateComponents/admin/TemplateHeader/TemplateHeader';
import { Enum, selectActiveEnumsInGroup } from '@/app/redux/enums';
import { ReducerState } from '@/app/redux/store';

type IncomingProps = {
  languages: LanguagesType[];
  handleModalClose: () => void;
  updateJobEvaluationTemplate: (id: string, template: Object) => void;
  addTemplateSection: (id: String, template: Object) => void;
  deleteSection: (templateId: string, sectionId: string) => void;
  addComponentToSection: (
    templateId: string,
    sectionId: string,
    list: Object[],
  ) => void;
  updateSection: (id: String, template: Object) => void;
  updateSectionComponent: (
    templateId: string,
    sectionId: string,
    object: Object,
  ) => void;
  deleteComponent: (
    templateId: string,
    sectionId: string,
    ids: string[],
  ) => void;
  orderComponents: (sectionId: string, object: Object[]) => void;
  openEditModal: boolean;
  readyToEditTemplate: ActiveTemplate[];
  warningMessage: boolean;
};

type MapStateToProps = {
  templateTags: Enum[];
};

type PropsType = IncomingProps &
  MapStateToProps &
  WithStyles<typeof editTemplateStyle>;

class EditTemplate extends React.PureComponent<PropsType> {
  state = {
    modalOpen: false,
    loadCompleted: false,
    newSection: false,
    openSectionSort: false,
  };

  handleAddSection = () => {
    this.setState({ modalOpen: true });
  };

  newSectionAdded = () => {
    this.setState({ newSection: true });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };

  componentDidUpdate = (prevProps: PropsType) => {
    if (
      !this.state.loadCompleted &&
      prevProps.readyToEditTemplate !== this.props.readyToEditTemplate
    ) {
      this.setState({ loadCompleted: true });
    }
  };

  saveNewOrder = (newOrder: []) => {
    const { readyToEditTemplate, updateSection } = this.props;
    const updatedSections = newOrder.map((section: any, index) => ({
      ...section,
      index: index + 1,
    }));
    updatedSections.forEach(section => {
      updateSection(readyToEditTemplate[0].id, section);
    });
    this.closeSortingModal();
  };

  sectionSortOpen = () => {
    this.setState({ openSectionSort: true });
  };

  closeSortingModal = () => {
    this.setState({ openSectionSort: false });
  };

  render() {
    const {
      classes,
      languages,
      addTemplateSection,
      addComponentToSection,
      updateSectionComponent,
      deleteSection,
      deleteComponent,
      orderComponents,
      readyToEditTemplate,
      updateJobEvaluationTemplate,
      openEditModal,
      handleModalClose,
      warningMessage,
      updateSection,
      templateTags,
    } = this.props;
    const {
      modalOpen,
      loadCompleted,
      newSection,
      openSectionSort,
    } = this.state;
    const readyToEditActiveTemplate = readyToEditTemplate;
    const [templateBeingEdited] = readyToEditActiveTemplate;

    let orderedSections = [];
    if (templateBeingEdited.sections) {
      orderedSections = orderElementsByIndex(templateBeingEdited.sections);
      readyToEditActiveTemplate[0].sections = orderedSections;
    }

    return (
      <Modal open={openEditModal}>
        <Paper className={classes.root}>
          <SortableHOC
            open={openSectionSort}
            itemsToSort={orderedSections}
            saveNewOrder={this.saveNewOrder}
            cancelNewOrder={this.closeSortingModal}
          />
          <TemplateHeader
            showTags={true}
            templateTags={templateTags}
            templateBeingEdited={templateBeingEdited}
            languages={languages}
            handleModalClose={handleModalClose}
            updateTemplate={updateJobEvaluationTemplate}
            editStyle={true}
            teamTemplate={true}
          />
          {warningMessage ? (
            <Typography className={classes.warning} color="error">
              {translate.t('warningTemplateEdit')}
            </Typography>
          ) : (
            ''
          )}
          {loadCompleted &&
          templateBeingEdited &&
          templateBeingEdited.sections &&
          templateBeingEdited.sections.length > 0 ? (
            orderedSections.map((section: any) => (
              <SectionListing
                key={section.id}
                customStyle={true}
                section={section}
                list={[
                  {
                    key: section.id,
                    label: section.name,
                    componentsList: section.components || [],
                  },
                ]}
                addComponentToSection={addComponentToSection}
                updateSectionComponent={updateSectionComponent}
                deleteSection={deleteSection}
                readyToEditTemplate={readyToEditActiveTemplate}
                orderComponents={orderComponents}
                deleteComponent={deleteComponent}
                newSection={newSection}
                updateSection={updateSection}
                addTemplateSection={addTemplateSection}
                newSectionAdded={this.newSectionAdded}
              />
            ))
          ) : (
            <h2 className={classes.noSections}>{translate.t('no_sections')}</h2>
          )}
          <div className={classes.buttonWrapper}>
            <Button
              onClick={() => this.handleAddSection()}
              variant="contained"
              color="primary"
              className={classes.addSectionButton}
            >
              <Icon
                onClick={() => this.handleAddSection()}
                className={classes.addIcon}
              >
                add_circle
              </Icon>
              {translate.t('add_section')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.sortButton}
              onClick={this.sectionSortOpen}
            >
              <Icon onClick={() => this.sectionSortOpen}>sort</Icon>
              {translate.t('laSort')}
            </Button>
          </div>
          <Modal open={modalOpen} onClose={() => this.handleModalClose()}>
            <AddSection
              readyToEditTemplate={readyToEditActiveTemplate}
              addTemplateSection={addTemplateSection}
              handleModalClose={this.handleModalClose}
              newSectionAdded={this.newSectionAdded}
              updateSection={updateSection}
              editMode={false}
            />
          </Modal>
        </Paper>
      </Modal>
    );
  }
}

export const mapStateToProps = (state: ReducerState) => {
  return {
    templateTags: selectActiveEnumsInGroup(state, 'JOB_REQ_TEMPLATE_TAGS'),
  };
};

const enhance = compose<PropsType, IncomingProps>(
  withStyles(editTemplateStyle),
);

export default enhance(EditTemplate);

import * as React from 'react';

import {
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
  withStyles,
  WithStyles,
  StyleRulesCallback,
  Icon,
  Theme,
} from '@material-ui/core';

const styles: StyleRulesCallback = (theme: Theme) => ({
  closeButton: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
  },
  dialogTitle: {
    padding: theme.spacing.unit * 2,
  },
});

type OwnProps = {
  onClose: () => void;
  title: string;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const DialogTitle = ({
  classes,
  title,
  onClose,
}: Props) => {
  return (
      <MuiDialogTitle disableTypography={true} className={classes.dialogTitle}>
        <Typography variant="h6">
          {title}
        </Typography>
        <IconButton
          className={classes.closeButton}
          onClick={onClose}
        >
          <Icon>close</Icon>
        </IconButton>
      </MuiDialogTitle>
  );
};

export default withStyles(styles)(DialogTitle);

import {
  CommonWorkflowReportScopeSettings,
  CommonWorkflowReportUnitData,
} from '@/app/components/WorkflowsReport/ReportTypes/Common';

export enum ReportToolbarSettingsChangeAction {
  TeamCheck = 'teamCheck',
  SubUnitCheck = 'subUnitCheck',
  SelectedUnit = 'selectedUnit',
  LoadSavedSettings = 'loadSavedSettings',
}

export type ReportToolbarSettingsChange = CommonWorkflowReportScopeSettings & {
  action: ReportToolbarSettingsChangeAction;
};

export type WorkflowsReportToolbarSettings = CommonWorkflowReportScopeSettings & {
  unitOptions: CommonWorkflowReportUnitData[];
  enabledActions: {
    unitSelector: boolean;
    teamCheckbox: boolean;
    subUnitsCheckbox: boolean;
  };
  onReportToolbarSettingsChange: (
    settings: ReportToolbarSettingsChange,
  ) => void;
};

import React, { useState, useCallback } from 'react';
import { APPROVAL_STATUS, REGISTRATION_STATUS, CompanyTrainingParticipantRegistration } from '../../types';
import { canAccessApprovalStatus, getApprovalStatusName } from '../../helpers';
import { getSelectedEmpId, getLoggedUserId, eventTargetAsHtmlElement } from '@/old/utils/helper';
import ReadOnlyRegistrationStatus from './ReadOnlyRegistrationStatus';
import {
  Menu,
  MenuItem,
  Button,
  Icon,
  StyleRulesCallback,
  WithStyles,
  withStyles,
  Theme
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';

const styles: StyleRulesCallback = (theme: Theme) => ({
  approvedIcon: {
    color: green[300],
    marginRight: theme.spacing.unit / 2,
  },
  rejectedIcon: {
    color: red[300],
    marginRight: theme.spacing.unit / 2,
  },
  approvalButton: {
    textTransform: 'none',
    width: 'auto !important',
    height: 'auto !important',
  },
});

type OwnProps = {
  registration: CompanyTrainingParticipantRegistration;
  registrationStatus: REGISTRATION_STATUS;
  onChangeApprovalStatus?: (updatedRegistration: CompanyTrainingParticipantRegistration) => void;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const ApprovalButton = ({
  classes,
  registration,
  registrationStatus,
  onChangeApprovalStatus,
}: Props) => {
  const [approvalMenuAnchorEl, setApprovalMenuAnchorEl] = useState<HTMLElement|undefined>();

  const handleApprovalStatusChange = useCallback(
    async (participant: CompanyTrainingParticipantRegistration, newApprovalStatus: APPROVAL_STATUS) => {
      const updatedParticipant = {
        ...participant,
        approvalStatus: {
          ...participant.approvalStatus,
          status: {
            value: newApprovalStatus,
            label: getApprovalStatusName(newApprovalStatus),
          },
        },
      };
      onChangeApprovalStatus(updatedParticipant);
      setApprovalMenuAnchorEl(undefined);
    },
    []
  );

  if (
    !!onChangeApprovalStatus &&
    registration.approvalStatus.status.value === APPROVAL_STATUS.WAITING &&
    canAccessApprovalStatus(getSelectedEmpId() || getLoggedUserId())
  ) {
    return (
      <>
        <Menu
          open={Boolean(approvalMenuAnchorEl)}
          anchorEl={approvalMenuAnchorEl}
          onClose={() => setApprovalMenuAnchorEl(undefined)}
        >
          <MenuItem onClick={() => handleApprovalStatusChange(registration, APPROVAL_STATUS.APPROVED)}>
            <Icon className={classes.approvedIcon}>check</Icon>
            {getApprovalStatusName(APPROVAL_STATUS.APPROVED)}
          </MenuItem>
          <MenuItem onClick={() => handleApprovalStatusChange(registration, APPROVAL_STATUS.REJECTED)}>
            <Icon className={classes.rejectedIcon}>clear</Icon>
            {getApprovalStatusName(APPROVAL_STATUS.REJECTED)}
          </MenuItem>
        </Menu>
        <Button
          size="small"
          className={classes.approvalButton}
          variant="contained"
          color="default"
          onClick={(e) => setApprovalMenuAnchorEl(eventTargetAsHtmlElement(e.target))}
        >
          {getApprovalStatusName(registration.approvalStatus.status.value)}
          <Icon>arrow_drop_down</Icon>
        </Button>
      </>
    );
  }

  return <ReadOnlyRegistrationStatus registrationStatus={registrationStatus} />;
};

export default withStyles(styles)(ApprovalButton);

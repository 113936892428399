import { StyleRulesCallback } from '@material-ui/core';
import { Theme } from '@material-ui/core';

const sortableHOCStyles: StyleRulesCallback = (theme: Theme) => ({
  listElements: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    marginTop: theme.spacing.unit,
    marginLeft: '-40px',
    marginBottom: '10px',
    padding: '10px 10px',
    cursor: 'grab',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& :active': {
      cursor: 'grabbing',
    }
  },
  context: {
    marginLeft: '25px',
    marginRight: '25px'
  }
});

export default sortableHOCStyles;

import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const evaluationVisibilityStyles: StyleRulesCallback = () => ({
  typography: {
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontSize: '0.875em',
    fontWeight: 'bold'
  },
  container: {
    height: '70px',
    marginLeft: '20px',
    marginRight: '20px',
    marginTop: '10px',
    paddingTop: '5px',
    paddingBottom: '5px',
    outline: 'none'
  },
  wrapper: {
    paddingLeft: '10px',
    paddingTop: '5px',
    paddingBottom: '5px'
  }
});

export default evaluationVisibilityStyles;

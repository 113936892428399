import { Record } from 'immutable';
import { Dispatch } from 'redux';

import API from '@/app/api/internalAPIs';
import Service from '@/app/utils/service';

import { THROW_ERROR } from './error';

export type TranslatableEntity = 'LEGAL_ENTITY' | 'OFFICE' | 'UNIT' | 'COST_CENTER';
export type TranslatableEntityProperty = 'name';
export type EntityTranslation = {
  locale: string;
  translation: string;
};

export type EntityFieldTranslation = {
  term: string;
  translations: {
    [key: string]: EntityTranslation
  }
};

export type EntityFieldTranslationSubmitData = {
  translations: EntityTranslation[];
};

const initialState = Record({
  entityFieldTranslations: {} as EntityFieldTranslation
});

const FETCH_ENTITY_FIELD_TRANSLATIONS = '@@solaforce/entityTranslations/FETCH_ENTITY_FIELD_TRANSLATIONS';
const FETCH_ENTITY_FIELD_TRANSLATIONS_SUCCESS = '@@solaforce/entityTranslations/FETCH_ENTITY_FIELD_TRANSLATIONS_SUCCESS';
const UPDATE_ENTITY_FIELD_TRANSLATIONS = '@@solaforce/entityTranslations/UPDATE_ENTITY_FIELD_TRANSLATIONS';
const UPDATE_ENTITY_FIELD_TRANSLATIONS_SUCCESS = '@@solaforce/entityTranslations/UPDATE_ENTITY_FIELD_TRANSLATIONS_SUCCESS';

const RESET_ENTITY_FIELD_TRANSLATIONS = '@@solaforce/entityTranslations/RESET_ENTITY_FIELD_TRANSLATIONS';

const reducer = (state = new initialState(), action: any) => {
  switch (action.type) {
    case FETCH_ENTITY_FIELD_TRANSLATIONS: {
      return state.set('entityFieldTranslations', {} as EntityFieldTranslation);
    }
    case FETCH_ENTITY_FIELD_TRANSLATIONS_SUCCESS: {
      const { entityFieldTranslations } = action.payload;
      return state.set('entityFieldTranslations', entityFieldTranslations);
    }
    case RESET_ENTITY_FIELD_TRANSLATIONS: {
      return state.set('entityFieldTranslations', {} as EntityFieldTranslation);
    }

    default: return state;
  }
};

export const fetchEntityFieldTranslations = (
  entity: TranslatableEntity,
  id: string,
  property: TranslatableEntityProperty
) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: FETCH_ENTITY_FIELD_TRANSLATIONS });

    return Service.get(
        API.entityFieldTranslations.get(entity, id, property),
        (response: any) => {
          dispatch({
            type: FETCH_ENTITY_FIELD_TRANSLATIONS_SUCCESS,
            payload: {
              entityFieldTranslations: response,
            }
          });
        },
        (error: any) => dispatch({ type: THROW_ERROR, error })
    );
  };
};

export const resetEntityFieldTranslations = () => (dispatch: Dispatch) => {
  dispatch({ type: RESET_ENTITY_FIELD_TRANSLATIONS });
};

export const updateEntityFieldTranslations = (
  entity: TranslatableEntity,
  id: string,
  property: TranslatableEntityProperty,
  translationSubmitData: EntityFieldTranslationSubmitData
) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: UPDATE_ENTITY_FIELD_TRANSLATIONS });

    return Service.put(
        API.entityFieldTranslations.update(entity, id, property),
        translationSubmitData,
        (response: any) => {
          dispatch({
            type: UPDATE_ENTITY_FIELD_TRANSLATIONS_SUCCESS,
            payload: {
              entityFieldTranslations: response,
            }
          });
        },
        (error: any) => dispatch({ type: THROW_ERROR, error })
    );
  };
};

export default reducer;

import * as React from 'react';
import { InjectedProps } from '@material-ui/core/withMobileDialog';
import FormFields, {
  InputType,
  ChildInputType,
} from '../../FormFields/FormFields';
import { INPUT_TYPE } from '@/app/utils/helper';
import {
  reduxForm,
  InjectedFormProps,
  getFormValues,
  FormProps,
} from 'redux-form';
import { compose } from 'recompose';
import {
  Country,
  UnitOrgTreeItem,
  findSubUnits,
  getCompanyCountries,
  getCompanyUnits,
} from '@/old/utils/helper';
import { Dispatch } from 'redux';
import { loadFormData, changeFormData } from '@/app/redux/formData';
import { connect } from 'react-redux';
import translate from '@/app/utils/translate';
import Service from '@/app/utils/service';
import API from '@/app/api/internalAPIs';
import { throwError } from '@/app/redux/error';
import { IEnumGroup } from '../EnumGroupList/enumGroup';
import { OPEN_NOTIFIER } from '@/app/redux/notifier';
import { selectCustomFieldInputTypes } from '@/app/redux/enums';
import { OptionType } from '../../Trainings/types';

const FORM_NAME = 'newEnum';

/**
 * form: redux form name
 * field: target field to change
 * value: field data
 */
type FormDataType = {
  form: string;
  field: string;
  value: any;
};

interface MapDispatchToProps {
  loadFormData(form: string, e: any): void;
  changeFormData: Function;
  throwError: Function;
}

type FormInputs = {
  name: string;
  extCode?: string;
  inputType?: ChildInputType;
  tags?: ChildInputType[];
  countries?: Country[];
  orgUnits?: OptionType[];
  includeSubUnits?: boolean;
  fCustomDataAttributeEditableByEmp?: boolean;
};

interface MapStateToProps extends FormProps<any, any> {
  currentValues: FormInputs;
  customDataTypes: ChildInputType[];
}

interface OwnProps extends InjectedProps {
  group: IEnumGroup;
  disableNext: (formName: string, errors?: any) => void;
  onSubmit: (values: any) => void;
}

type PropsType = OwnProps &
  InjectedFormProps &
  MapStateToProps &
  MapDispatchToProps;

type StateType = {
  availableTags: Array<ChildInputType>;
  orgTree: UnitOrgTreeItem[];
};

class EnumValueForm extends React.Component<PropsType> {
  mounted: boolean;
  state: StateType = {
    availableTags: [],
    orgTree: [],
  };

  componentDidMount() {
    this.mounted = true;
    if (this.props.group.hasTags() && this.props.group) {
      this.loadEnumAvailableTags(this.props.group);
    }
    if (this.props.group.hasInputType && this.props.customDataTypes) {
      this.props.changeFormData({
        form: FORM_NAME,
        field: 'inputType',
        value: this.props.customDataTypes[0],
      });
    }

    Service.get(
      '/d/json/org/tree/1/unit',
      (response: any) => {
        const orgTreeData = response.map((respItem: any) => ({
          id: parseInt(respItem.fTreeId, 10),
          parentId:
            respItem.fTreeParentUnitId === '#'
              ? '#'
              : parseInt(respItem.fTreeParentUnitId, 10),
          unitId: parseInt(respItem.fTreeUnitId, 10),
          name: respItem.fTreeUnitName,
          unitType: respItem.fTreeUnitType,
        }));
        this.setState({ orgTree: orgTreeData });
      },
      (_e: any) => {
        console.error(translate.t('laFailedToLoad'));
        // TODO: Change to snackbar if possible
      },
    );
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  shouldComponentUpdate(nextProps: OwnProps) {
    return nextProps.group !== null;
  }

  handleAddTag = (value: string) => {
    this.pushData({
      form: FORM_NAME,
      field: 'tags',
      value: { value, label: value },
    });

    const availableTags = [...this.state.availableTags];
    availableTags.push({ value, label: value });

    this.setState({
      availableTags,
    });
  };

  loadEnumAvailableTags = (group: IEnumGroup) => {
    Service.get(
      API.enum.groupTags(group.name),
      (data: any) => {
        if (this.mounted) {
          this.setState({
            availableTags: data.map((d: any) => ({ label: d, value: d })),
          });
        }
      },
      (error: any) => this.props.throwError(error),
    );
  };

  pushData = (data: FormDataType) => {
    let currentValue = this.props.currentValues[data.field];
    if (currentValue) {
      currentValue.push(data.value);
    } else {
      currentValue = [data.value];
    }

    this.props.changeFormData({
      form: data.form,
      field: data.field,
      value: currentValue,
    });
  };

  changeOrgUnits = (e: OptionType[]) => {
    this.props.changeFormData({
      form: FORM_NAME,
      field: 'orgUnits',
      value: e,
    });
  };

  render() {
    const { handleSubmit, group, customDataTypes, currentValues } = this.props;
    const { availableTags, orgTree } = this.state;
    const unitOptions = getCompanyUnits().map((u: any) => ({
      value: u.id,
      label: u.name,
    })) as OptionType[];

    const getMatchingSubUnits = (
      units?: OptionType[],
      includeSubUnits = currentValues.includeSubUnits,
    ) => {
      if (includeSubUnits || !units) {
        return findSubUnits(units, unitOptions, orgTree, includeSubUnits);
      }

      return units.map(unit => ({ ...unit, isFixed: false }));
    };

    const inputs: Array<InputType> = [
      {
        type: INPUT_TYPE.TEXT,
        code: 'name',
        name: 'name',
        label: translate.t('laTerm'),
        order: 1,
        required: true,
      },
    ];

    if (group.hasExtCode) {
      inputs.push({
        type: INPUT_TYPE.TEXT,
        code: 'extCode',
        name: 'extCode',
        label: translate.t('laExternalCode'),
        order: 2,
        required: false,
      });
    }

    if (group.hasInputType && customDataTypes) {
      inputs.push({
        type: INPUT_TYPE.SELECT,
        code: 'inputType',
        name: 'inputType',
        label: translate.t('laType'),
        childrenInputs: customDataTypes,
        order: 2.1,
        required: true,
      });
    }

    if (group.hasTags()) {
      inputs.push({
        type: INPUT_TYPE.MULTISELECT,
        code: 'tags',
        name: 'tags',
        label: translate.t('label_tags'),
        childrenInputs: availableTags,
        order: 3,
        required: false,
        configs: {
          isSearchable: true,
          isDisabled: false,
          isCreatable: true,
          onCreateOption: (value: string) => this.handleAddTag(value),
        },
      });
    }

    if (group.isCountryBased()) {
      inputs.push({
        type: INPUT_TYPE.MULTISELECT,
        code: 'countries',
        name: 'countries',
        label: translate.t('laCountries'),
        childrenInputs: getCompanyCountries().map((c: any) => ({
          label: c.name,
          value: c.code,
        })),
        order: 4,
        required: false,
        configs: {
          isSearchable: true,
          isDisabled: false,
          isCreatable: false,
        },
      });
      if (!group.hasInputType) {
        inputs.push({
          type: INPUT_TYPE.MULTISELECT,
          code: 'orgUnits',
          name: 'orgUnits',
          label: translate.t('laOrgUnits'),
          childrenInputs: getCompanyUnits().map((u: any) => ({
            label: u.label,
            value: u.code,
          })),
          order: 5,
          configs: {
            isSearchable: true,
            isDisabled: false,
            isCreatable: false,
          },
        });
        inputs.push({
          type: INPUT_TYPE.CHECKBOX,
          code: 'includeSubUnits',
          name: 'includeSubUnits',
          label: translate.t('label_include_subunits'),
          order: 5.1,
          onChange: () => {
            this.changeOrgUnits(
              getMatchingSubUnits(
                currentValues.orgUnits,
                !currentValues.includeSubUnits,
              ),
            );
          },
        });
      }
    }
    if (
      group.hasInputType &&
      customDataTypes &&
      group.name === 'CUSTOM_ENTITY_EMPLOYEE'
    ) {
      inputs.push({
        type: INPUT_TYPE.CHECKBOX,
        code: 'fCustomDataAttributeEditableByEmp',
        name: 'fCustomDataAttributeEditableByEmp',
        label: translate.t('laCustomDataAttributeEditableByEmp'),
        order: 6,
      });
    }

    return (
      <form onSubmit={handleSubmit}>
        <FormFields inputs={inputs} />
      </form>
    );
  }
}

const mapStateToProps = (state: any) => ({
  customDataTypes: selectCustomFieldInputTypes(state),
  currentValues: getFormValues(FORM_NAME)(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadFormData: (form: string, e: any) => dispatch<any>(loadFormData(form, e)),
  changeFormData: (e: FormDataType) => changeFormData(dispatch, e),
  throwError: (e: string) => throwError(e),
});

const validate = (values: any, props: PropsType) => {
  const errors: { [k: string]: any } = {};

  if (!values.name) {
    errors.name = translate.t('laThisRequired');
  } else if (values.name.length > 64) {
    errors.name = `${translate.t('laErrorMaxTextLen1')} 254`;
  }

  if (values.extCode && values.extCode.length > 64) {
    errors.extCode = `${translate.t('laErrorMaxTextLen1')} 64`;
  }

  if (values.tags && values.tags.length > 10) {
    const showError = values.tags.find((tag: string) => tag.length > 132);

    errors.tags = showError
      ? translate.t('error_max_length_tag')
      : translate.t('error_max_length_tags');
  }

  props.disableNext(FORM_NAME);

  return errors;
};

const asyncValidate = (values: any, dispatch: Dispatch, props: PropsType) => {
  const errKey = 'asyncErrors';
  let errors = props[errKey];

  return Service.get(
    API.enum.get(props.group.name, values.name),
    (data: any) => {
      if (data.hasOwnProperty('isActive') && data.isActive === false) {
        dispatch({
          type: OPEN_NOTIFIER,
          payload: {
            message: translate.t('warn_entity_deleted'),
            type: 'warning',
          },
        });
      } else if (data) {
        errors = Object.assign({}, errors, {
          name: translate.t('error_exists_name'),
        });
      }
    },
    (error: any) => props.throwError(error),
  ).then(() => {
    return new Promise<void>((resolve, reject) => {
      if (errors) {
        reject(errors);
      }

      resolve();
      props.disableNext(FORM_NAME, errors);
    });
  });
};

const enhance = compose<any, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
    asyncValidate,
    asyncBlurFields: ['name'],
  }),
);

export default enhance(EnumValueForm);

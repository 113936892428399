import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const templateSelectionStyles: StyleRulesCallback = () => ({
  root: {
    borderRadius: 0,
    border: 'solid 1px #D6D6D6 !important',
    boxShadow: 'none',
  },
  select: {
    minWidth: 220,
    margin: '20px 0 20px 20px',

    '&:before': {
      borderColor: '#05a4d8'
    },
    '&:after': {
      borderColor: '#05a4d8'
    },
  },
  selectDisabled : {
    '&:before': {
      borderColor: '#d3d3d3'
    },
    '&:after': {
      borderColor: '#d3d3d3'
    }
  },
  selectIcon: {
    fill: '#05a4d8'
  },
  selectIconDisabled: {
    fill: '#d3d3d3'
  },
  selectInput: {
    color: '#05a4d8',
  },
  selectInputDisabled: {
    color: '#d3d3d3',
  },
  highlightedOption: {
    color: 'red',
  },
  tooltipContainer: {
    display: 'inline-block',
    float: 'right',
  },
  iconButton: {
    minHeight: '50px',
    float: 'right'
  },
  buttonWrapper: {
    float: 'right',
    marginTop: '-20px',
    marginRight: '20px'
  },
  button: {
    minHeight: '35px',
    textAlign: 'left',
    fontSize: '0.9em !important'
  },
  flexWrapper: {
    marginLeft: '20px'
  },
  textField: {
    flexGrow: 1,
    marginRight: 20,
  },
  textFieldMinWidth: {
    minWidth: '175px'
  },
  textFieldApproved: {
    maxWidth: '145px'
  },
  evaluationActionsWrapper: {
    marginTop: '-20px',
    marginRight: '15px',
    marginLeft: '-20px',
    display: 'inline-block',
    maxWidth: '170px',
    float: 'right'
  },
  totals: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px 20px',
  },
  totalInput: {
    width: '100px',

    '&:not(:last-child)': {
      marginRight: '10px',
    },
  },
  noSections: {
    padding: '20px 20px'
  },
});

export default templateSelectionStyles;
import { sortByDate } from '../../helpers';
import { ParticipantSummary } from '../../types';
import { TrainingsContextState } from '../trainingsContext';
import {
  mapCompanyTrainingForUI,
  mapParticipantRegistrationForUI,
  mapEmployeeTrainingHistoryForUI,
} from '../trainingsContext/dataMappers';

export enum ACTIONS {
  // Company trainings
  START_LOADING_COMPANY_TRAININGS,
  START_LOADING_COMPANY_TRAINING,
  SET_COMPANY_TRAININGS_ERROR,
  UPDATE_COMPANY_TRAININGS,
  ADD_COMPANY_TRAINING,
  UPDATE_COMPANY_TRAINING,
  DELETE_COMPANY_TRAINING,
  UPDATE_PARTICIPANT_SUMMARIES,
  ADD_OR_UPDATE_PARTICIPANT_SUMMARY,
  GET_UNIT_ORG_TREE,
  GET_UNIT_ORG_TREE_DONE,
  GET_UNIT_ORG_TREE_ERROR,
  // Participants
  START_LOADING_PARTICIPANTS,
  START_LOADING_PARTICIPANT,
  SET_PARTICIPANTS_ERROR,
  UPDATE_PARTICIPANTS,
  UPDATE_PARTICIPANT,
  TOGGLE_PARTICIPANT_REGISTRATION,
  TOGGLE_ALL_PARTICIPANT_REGISTRATION,
  // Employee trainings
  START_LOADING_ONCOMING_TRAININGS,
  SET_ONCOMING_TRAININGS_ERROR,
  GET_ONCOMING_TRAININGS,
  START_LOADING_CALENDAR_TRAININGS,
  SET_CALENDAR_TRAININGS_ERROR,
  GET_CALENDAR_TRAININGS,
  START_LOADING_TRAINING_HISTORY,
  SET_TRAINING_HISTORY_ERROR,
  GET_TRAINING_HISTORY,
  ADD_EMPLOYEE_TRAINING,
  UPDATE_EMPLOYEE_TRAINING,
  DELETE_EMPLOYEE_TRAINING,
  START_LOADING_EMPLOYEE_TRAINING_REGISTRATIONS,
  SET_EMPLOYEE_TRAINING_REGISTRATIONS_ERROR,
  GET_EMPLOYEE_TRAINING_REGISTRATIONS,
  START_SELF_REGISTERING,
  SET_SELF_REGISTER_ERROR,
  SELF_REGISTER_DONE,
  SET_CANCELLATION_ERROR,
}

export default (state: TrainingsContextState, action: any) => {
  switch (action.type) {

    /**
     * Company trainings
     */
    case ACTIONS.START_LOADING_COMPANY_TRAININGS:
      return {
        ...state,
        companyTrainings: {
          ...state.companyTrainings,
          loading: true,
          error: undefined,
        },
      };
    case ACTIONS.START_LOADING_COMPANY_TRAINING:
      return {
        ...state,
        companyTrainings: {
          ...state.companyTrainings,
          loadingId: action.payload.id,
        },
      };
    case ACTIONS.SET_COMPANY_TRAININGS_ERROR:
      return {
        ...state,
        companyTrainings: {
          ...state.companyTrainings,
          error: action.payload.message,
          loadingId: undefined as undefined,
          loading: false,
        },
      };
    case ACTIONS.UPDATE_COMPANY_TRAININGS:
      return {
        ...state,
        companyTrainings: {
          data: action.payload,
          loading: false,
        },
      };
    case ACTIONS.ADD_COMPANY_TRAINING:
      return {
        ...state,
        companyTrainings: {
          data: [...state.companyTrainings.data, action.payload],
          loading: false,
        },
      };
    case ACTIONS.UPDATE_COMPANY_TRAINING:
      return {
        ...state,
        companyTrainings: {
          data: state.companyTrainings.data.map((t => {
            if (t.id === action.payload.id) {
              return action.payload;
            }
            return t;
          })),
          loadingId: undefined as undefined,
        }
      };
    case ACTIONS.DELETE_COMPANY_TRAINING:
      return {
        ...state,
        companyTrainings: {
          data: state.companyTrainings.data.filter(t => t.id !== action.payload.id),
          loadingId: undefined as undefined,
        },
      };

    case ACTIONS.GET_UNIT_ORG_TREE_DONE:
      return {
        ...state,
        companyTrainings: {
          ...state.companyTrainings,
          orgTree: action.payload
        }
      };
    case ACTIONS.UPDATE_PARTICIPANT_SUMMARIES:
      return {
        ...state,
        participantSummaries: {
          data: action.payload,
          loading: false,
        },
      };
    case ACTIONS.ADD_OR_UPDATE_PARTICIPANT_SUMMARY:
      const updatedSummaries = [...state.participantSummaries.data];
      const updateIndex = updatedSummaries.findIndex((s: ParticipantSummary) => s.trainingId === action.payload.trainingId);
      if (updateIndex === -1) {
        updatedSummaries.push(action.payload);
      } else {
        updatedSummaries[updateIndex] = action.payload;
      }
      return {
        ...state,
        participantSummaries: {
          data: updatedSummaries,
          loading: false,
        },
      };

    /**
     * Participants
     */
    case ACTIONS.START_LOADING_PARTICIPANTS:
      return {
        ...state,
        participants: {
          ...state.participants,
          loading: true,
          error: undefined,
        },
      };
    case ACTIONS.SET_PARTICIPANTS_ERROR:
      return {
        ...state,
        participants: {
          ...state.participants,
          error: action.payload.message,
          loading: false,
        },
      };
    case ACTIONS.UPDATE_PARTICIPANTS:
      return {
        ...state,
        participants: {
          data: action.payload,
          loading: false,
        },
      };
    case ACTIONS.UPDATE_PARTICIPANT:
      return {
        ...state,
        participants: {
          ...state.participants,
          data: state.participants.data.map(p => {
            if (p.id === action.payload.id) {
              return action.payload;
            }
            return p;
          }),
          loading: false,
        },
      };

    case ACTIONS.TOGGLE_PARTICIPANT_REGISTRATION:
      return {
        ...state,
        participants: {
          ...state.participants,
          data: state.participants.data.map(p => {
            if (p.id === action.payload) {
              return { ...p, selected: !p.selected };
            }

            return p;
          }),
        },
      };

    case ACTIONS.TOGGLE_ALL_PARTICIPANT_REGISTRATION: {
      const viableParticipants = state.participants.data.filter(({ participant }) => participant.id);
      const allParticipantsAreSelected = viableParticipants.every(({ selected }) => selected);

      return {
        ...state,
        participants: {
          ...state.participants,
          data: state.participants.data.map(p => {
            return { ...p, selected: !allParticipantsAreSelected };
          }),
        },
      };
    }

    /**
     * Employee trainings
     */
    case ACTIONS.START_LOADING_ONCOMING_TRAININGS:
      return {
        ...state,
        employeeTrainings: {
          ...state.employeeTrainings,
          oncoming: {
            ...state.employeeTrainings.oncoming,
            loading: true,
            error: undefined,
          }
        },
      };
    case ACTIONS.SET_ONCOMING_TRAININGS_ERROR:
      return {
        ...state,
        employeeTrainings: {
          ...state.employeeTrainings,
          oncoming: {
            ...state.employeeTrainings.oncoming,
            error: action.payload.message,
            loading: false,
          },
        },
      };
    case ACTIONS.GET_ONCOMING_TRAININGS:
      return {
        ...state,
        employeeTrainings: {
          ...state.employeeTrainings,
          oncoming: {
            ...state.employeeTrainings.oncoming,
            data: action.payload
              .map((item: any) => mapCompanyTrainingForUI(item))
              .sort((a: any, b: any) => sortByDate(a.startDate, b.startDate)),
            loading: false,
          }
        }
      };
    case ACTIONS.START_LOADING_CALENDAR_TRAININGS:
      return {
        ...state,
        employeeTrainings: {
          ...state.employeeTrainings,
          calendar: {
            ...state.employeeTrainings.calendar,
            loading: true,
            error: undefined,
          }
        },
      };
    case ACTIONS.SET_CALENDAR_TRAININGS_ERROR:
      return {
        ...state,
        employeeTrainings: {
          ...state.employeeTrainings,
          calendar: {
            ...state.employeeTrainings.calendar,
            error: action.payload.message,
            loading: false,
          },
        },
      };
    case ACTIONS.GET_CALENDAR_TRAININGS:
      return {
        ...state,
        employeeTrainings: {
          ...state.employeeTrainings,
          calendar: {
            ...state.employeeTrainings.calendar,
            data: action.payload
              .map((item: any) => mapCompanyTrainingForUI(item.training))
              .sort((a: any, b: any) => sortByDate(a.startDate, b.startDate)),
            loading: false,
          }
        }
      };
    case ACTIONS.START_LOADING_TRAINING_HISTORY:
      return {
        ...state,
        employeeTrainings: {
          ...state.employeeTrainings,
          history: {
            ...state.employeeTrainings.history,
            loading: true,
            error: undefined,
          }
        },
      };
    case ACTIONS.SET_TRAINING_HISTORY_ERROR:
      return {
        ...state,
        employeeTrainings: {
          ...state.employeeTrainings,
          history: {
            ...state.employeeTrainings.history,
            error: action.payload.message,
            loading: false,
          },
        },
      };
    case ACTIONS.GET_TRAINING_HISTORY:
      return {
        ...state,
        employeeTrainings: {
          ...state.employeeTrainings,
          history: {
            ...state.employeeTrainings.history,
            data: action.payload
              .map((item: any) => mapEmployeeTrainingHistoryForUI(item))
              .sort((a: any, b: any) => sortByDate(a.startDate, b.startDate)),
            loading: false,
          }
        }
      };
    case ACTIONS.ADD_EMPLOYEE_TRAINING:
      return {
        ...state,
        employeeTrainings: {
          ...state.employeeTrainings,
          history: {
            ...state.employeeTrainings.history,
            data: [...state.employeeTrainings.history.data, action.payload],
            loading: false,
          }
        },
      };
    case ACTIONS.UPDATE_EMPLOYEE_TRAINING:
      return {
        ...state,
        employeeTrainings: {
          ...state.employeeTrainings,
          history: {
            ...state.employeeTrainings.history,
            data: state.employeeTrainings.history.data.map(t => {
              if (t.trainingGroup.value === action.payload.trainingGroup.value && t.id === action.payload.id) {
                return action.payload;
              }
              return t;
            }),
            loading: false,
            error: undefined,
          },
        },
      };
    case ACTIONS.DELETE_EMPLOYEE_TRAINING:
      return {
        ...state,
        employeeTrainings: {
          ...state.employeeTrainings,
          history: {
            data: state.employeeTrainings.history.data.filter(t =>
              !(t.trainingGroup.value === action.payload.trainingGroup && t.id === action.payload.trainingId)),
            loading: false,
            error: undefined,
          },
        },
      };

    // Employee training registrations
    case ACTIONS.START_LOADING_EMPLOYEE_TRAINING_REGISTRATIONS:
      return {
        ...state,
        employeeTrainings: {
          ...state.employeeTrainings,
          registrations: {
            loading: true,
            error: undefined as undefined,
          },
        },
      };
    case ACTIONS.SET_EMPLOYEE_TRAINING_REGISTRATIONS_ERROR:
      return {
        ...state,
        employeeTrainings: {
          ...state.employeeTrainings,
          registrations: {
            loading: false,
            error: action.payload.message,
          },
        },
      };
    case ACTIONS.GET_EMPLOYEE_TRAINING_REGISTRATIONS:
      return {
        ...state,
        employeeTrainings: {
          ...state.employeeTrainings,
          registrations: {
            data: action.payload
              .map((item: any) => mapParticipantRegistrationForUI(item)),
            loading: false,
          },
        },
      };

    // Self registering
    case ACTIONS.START_SELF_REGISTERING:
      return {
        ...state,
        employeeTrainings: {
          ...state.employeeTrainings,
          selfRegistering: {
            registering: true,
            error: undefined,
          },
        },
      };
    case ACTIONS.SET_SELF_REGISTER_ERROR:
      return {
        ...state,
        employeeTrainings: {
          ...state.employeeTrainings,
          selfRegistering: {
            registering: false,
            error: action.payload.message,
          },
        },
      };
    case ACTIONS.SELF_REGISTER_DONE:
      return {
        ...state,
        employeeTrainings: {
          ...state.employeeTrainings,
          selfRegistering: {
            registering: false,
            error: undefined,
          },
        },
      };

    default:
      return state;
  }
};

import { useCallback } from 'react';
import { ValidationResolver } from 'react-hook-form';
import { ObjectSchema, Shape, ValidationError } from 'yup';

export const useYupValidationResolver = <
  FormValues extends object,
  ValidationContext extends object = {}
>(
  validationSchema: ObjectSchema<Shape<object, FormValues>>,
): ValidationResolver<FormValues, ValidationContext> => {
  return useCallback(
    async (data: FormValues) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: (errors as ValidationError).inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type || 'validation',
                message: currentError.message,
              },
            }),
            {},
          ),
        };
      }
    },
    [validationSchema],
  );
};

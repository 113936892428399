import * as React from 'react';

import {
    changeOpenedReportSettingsAction,
    deleteReportSettingsAction,
    createReportSettingsAction,
    updateReportSettingsAction,
    fetchReportSettingsAction
} from './actions';
import { OpenedReportSettingsControls, OpenReportSettingsMenu, SaveReportSettings } from './components';
import { ReportSettings, UpdatableReportSettingsData } from './models';
import { reducer, ReportSettingsUtilsReducer } from './reducer';

interface ReportSettingsUtilsProps {
    reportType: string;
    initialReportSettings: string;
    getCurrentReportSettings: () => object;
    applyReportSettings: (settings: object) => void;
}

const getLocalStorageKey = (reportType: string) => `kendo-opened-report-${reportType}`;

export const ReportSettingsUtils: React.FC<ReportSettingsUtilsProps> = ({
    reportType,
    initialReportSettings,
    getCurrentReportSettings,
    applyReportSettings
}) => {
    const [
        {
            reportSettings,
            openedReportSettings,
            deletingOpenedReportSettings,
            failedToDeleteOpenedReportSettings,
            savingReportSettings,
            failedToSaveReportSettings,
        },
        dispatch
    ] = React.useReducer<ReportSettingsUtilsReducer>(reducer, {});

    const handleDeleteOpenedReportSettings = React.useCallback(
        (openedReportSettingsId: string) => {
            deleteReportSettingsAction(dispatch, openedReportSettingsId);
        },
        [dispatch]
    );

    const handleOpenedReportSettingsChange = React.useCallback(
        (rs?: ReportSettings) => {
            dispatch(changeOpenedReportSettingsAction(rs));
        },
        [dispatch]
    );

    const handleCreateReportSettings = React.useCallback(
        (rs: UpdatableReportSettingsData) => {
            createReportSettingsAction(dispatch, rs);
        },
        [dispatch]
    );

    const handleUpdateReportSettings = React.useCallback(
        (id: string, rs: UpdatableReportSettingsData) => {
            updateReportSettingsAction(dispatch, id, rs);
        },
        [dispatch]
    );

    React.useEffect(
        () => {
            const defaultOpenedReportSettingsId = localStorage.getItem(getLocalStorageKey(reportType));
            fetchReportSettingsAction(dispatch, reportType, defaultOpenedReportSettingsId);
        },
        []
    );

    const isInitialMount = React.useRef(true);

    /**
     * Update the local storage and apply report settings on opened
     * report settings change.
     * Skip the first render as the report settings are not
     * initialized yet and it's useless to do any changes.
     */
    React.useEffect(
        () => {
            if (isInitialMount.current) {
                isInitialMount.current = false;
            } else {
                localStorage.setItem(getLocalStorageKey(reportType), openedReportSettings && openedReportSettings.id);
                applyReportSettings(openedReportSettings && JSON.parse(openedReportSettings.activeColumns));
            }
        },
        [openedReportSettings]
    );

    if (!reportSettings) {
        return null;
    }

    return (
        <>
            <OpenedReportSettingsControls
                openedReportSettings={openedReportSettings}
                onDelete={handleDeleteOpenedReportSettings}
                deleting={deletingOpenedReportSettings}
                failedToDelete={failedToDeleteOpenedReportSettings}
            />

            <OpenReportSettingsMenu
                reportSettings={reportSettings}
                openedReportSettings={openedReportSettings}
                initialReportSettings={initialReportSettings}
                onChange={handleOpenedReportSettingsChange}
                getCurrentReportSettings={getCurrentReportSettings}
                disabled={deletingOpenedReportSettings || savingReportSettings}
            />

            <SaveReportSettings
                reportType={reportType}
                openedReportSettings={openedReportSettings}
                getCurrentReportSettings={getCurrentReportSettings}
                onCreate={handleCreateReportSettings}
                onUpdate={handleUpdateReportSettings}
                saving={savingReportSettings}
                failedToSave={failedToSaveReportSettings}
            />
        </>
    );
};

import * as React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { showEmployee } from '@/old/utils/helper';
import { Button } from '@material-ui/core';

type OwnProps = {
  forceToPeopleModule?: boolean;
};

type Props = GridCellProps & OwnProps;

const LastNameCell = ({ dataItem, rowType, forceToPeopleModule }: Props) => {
  if (
    rowType === 'groupHeader' ||
    rowType === 'groupFooter' ||
    rowType !== 'data'
  ) {
    return null;
  }

  return (
    <td style={{ verticalAlign: 'top' }}>
      <Button
        onClick={() => showEmployee(dataItem.empId, forceToPeopleModule)}
      >
        <span>{dataItem.lastName}</span>
      </Button>
    </td>
  );
};

export default LastNameCell;

import { List, Typography, WithStyles } from '@material-ui/core';
import classNames from 'classnames';
import * as React from 'react';

import Loading from '@/app/components/Loading/Loading';
import translate from '@/app/utils/translate';

import { RawTaskComment } from '../../../../collaborationModels';

import { CommentsListItem } from './components/CommentsListItem';
import { commentsListStyles } from './commentsListStyles';

export interface StateProps {
    comments: RawTaskComment[];
    loadingComments: boolean;
    failedToLoadComments: boolean;
}

export interface DispatchProps {
    fetchTaskComments: (taskId: number) => void;
    clearTaskComments: (taskId: number) => void;
}

export interface CommentsListOuterProps {
    taskId: number;
}

export interface CommentsListInnerProps extends
    CommentsListOuterProps,
    WithStyles<typeof commentsListStyles>,
    StateProps,
    DispatchProps {}

export const CommentsList: React.FC<CommentsListInnerProps> = ({
    classes,
    taskId,
    comments,
    loadingComments,
    failedToLoadComments,
    fetchTaskComments,
    clearTaskComments,
}) => {
    React.useEffect(
        () => {
            fetchTaskComments(taskId);

            return () => {
                clearTaskComments(taskId);
            };
        },
        [taskId, fetchTaskComments, clearTaskComments]
    );

    if (loadingComments) {
        return (
            <div className={classes.root}>
                <Loading size="small" />
            </div>
        );
    }

    if (failedToLoadComments) {
        return (
            <div className={classNames(classes.root, classes.centerAlignedContainer)}>
                <Typography variant="h6" color="error">{translate.t('laFailedToLoad')}</Typography>
            </div>
        );
    }

    if (!comments.length) {
        return (
            <div className={classNames(classes.root, classes.centerAlignedContainer)}>
                <Typography variant="h6">{translate.t('laNoComments')}</Typography>
            </div>
        );
    }

    return (
        <List className={classes.root}>
            {comments.map((comment) => {
                return <CommentsListItem key={comment.fTaskCommentId} comment={comment} />;
            })}
        </List>
    );
};
import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const componentListingStyles: StyleRulesCallback = () => ({
  root: {
    '& textarea': {
      // needed because the old css overwrites textarea height in some sections
      minHeight: 20,
      fontFamily: 'Arial, Helvetica, sans-serif',
      fontSize: '0.875em',
    },
  },
  topCheckbox: {
    marginLeft: '-12px',
  },
  fullWidth: {
    width: '100%',
  },
  typography: {
    whiteSpace: 'pre-wrap',
    paddingTop: '20px',
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontSize: '0.875em',
    fontWeight: 'bold',
  },
  controlContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
  },
  controlContainerMd: {
    maxWidth: '150px',
  },
  controlContainerLg: {
    maxWidth: '200px',
  },
  componentListContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
  formLabel: {
    position: 'absolute',
    marginTop: '-20px',
  },
  sectionContainer: {
    position: 'relative',
  },
  checkboxes: {
    minHeight: '25px',
  },
  multiline: {
    whiteSpace: 'pre-wrap',
  },
});

export default componentListingStyles;

import { default as React } from 'react';

type InputComponentProps = {
  inputRef: React.RefObject<HTMLDivElement>,
};
const InputComponent = ({ inputRef, ...props }: InputComponentProps) => {
  return <div ref={inputRef} {...props} />;
};

export default InputComponent;

import React, { useState } from 'react';
import {
  Button,
  Menu,
  MenuItem,
  Icon,
  StyleRulesCallback,
  Theme,
  WithStyles,
  withStyles,
  Divider,
  Typography,
} from '@material-ui/core';
import {
  COMPLETION_STATUS,
  APPROVAL_STATUS,
} from '../../../types';
import translate from '@/app/utils/translate';
import { getCompletionStatusName } from '../../../helpers';
import { FieldProps } from 'formik';
import { eventTargetAsHtmlElement } from '@/old/utils/helper';

const styles: StyleRulesCallback = (theme: Theme) => ({
  checkbox: {
    marginRight: theme.spacing.unit,
    height: theme.spacing.unit * 3,
    width: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit * 3 / 2,
  }
});

type OwnProps = {
  approvalStatus: APPROVAL_STATUS;
  className?: string;
  readOnly?: boolean;
};
type InnerProps = WithStyles<typeof styles> & FieldProps;
type Props = OwnProps & InnerProps;

const CompletionStatusField = ({
  classes,
  className,
  form,
  field,
  approvalStatus,
  readOnly = false,
  ...others
}: Props) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement|undefined>();

  const handleSelect = (newCompletionStatus: COMPLETION_STATUS) => {
    setMenuAnchorEl(undefined);
    form.setFieldTouched(field.name, true);
    form.setFieldValue(field.name, {
      value: newCompletionStatus,
      label: getCompletionStatusName(newCompletionStatus),
    });
  };

  if (approvalStatus === APPROVAL_STATUS.REJECTED || approvalStatus === APPROVAL_STATUS.CANCELLED) {
    return null;
  }

  if (readOnly) {
    return <Typography variant="inherit">{field.value.label}</Typography>;
  }

  return (
    <>
      <Menu open={Boolean(menuAnchorEl)} anchorEl={menuAnchorEl} onClose={() => setMenuAnchorEl(undefined)}>
        <MenuItem onClick={() => handleSelect(COMPLETION_STATUS.COMPLETED)}>{translate.t('laCompleted')}</MenuItem>
        <MenuItem onClick={() => handleSelect(COMPLETION_STATUS.COMPLETED_PARTLY)}>{translate.t('label_completed_partly')}</MenuItem>
        <MenuItem onClick={() => handleSelect(COMPLETION_STATUS.DID_NOT_SHOW_UP)}>{translate.t('label_did_not_show_up')}</MenuItem>
        <MenuItem onClick={() => handleSelect(COMPLETION_STATUS.DID_NOT_COMPLETE)}>{translate.t('label_did_not_complete')}</MenuItem>
        <Divider />
        <MenuItem onClick={() => handleSelect(COMPLETION_STATUS.PENDING)}>{translate.t('label_PENDING')}</MenuItem>
      </Menu>
      <Button
        {...field}
        className={className}
        size="small"
        variant="contained"
        color="default"
        onClick={(e) => setMenuAnchorEl(eventTargetAsHtmlElement(e.target))}
        {...others}
      >
        {field.value.label}
        <Icon>arrow_drop_down</Icon>
      </Button>
    </>
  );
};

export default withStyles(styles)(CompletionStatusField);

import * as moment from 'moment';

import { getLoggedUserId } from '@/old/utils/helper';

export type FormatType = 'json' | 'xml' | 'csv';
export type ScopeNameType = '' | 'company' | 'unit' | 'manager' | 'hr' | 'dmgr' | 'amgr';
export type RlnType = 'direct' | 'indirect' | 'all';
export type ScopeType = {
  type: ScopeNameType,
  range?: DateRangeType,
  id?: string,
  rln?: RlnType,
};
export type DateRangeType = {
  maxRange?: number,
  maxRangeUnit?: 'month' | 'year' | 'day',
  startDate?: moment.Moment;
  endDate?: moment.Moment;
};
export type ChangedSinceType = moment.Moment;
export type CoveredDaysType = number;
export type EmpScopeType = 'ACTIVE' | 'CURRENT' | 'SYSTEM' | 'ENDED' | 'NEW' | 'PROVISIONAL';

export interface IReportSettings {
  report: string;
  format?: FormatType;
  scope?: ScopeType;
  allowCompanyScopeForAll?: boolean;
  dates?: DateRangeType | ChangedSinceType | CoveredDaysType;
  empScope?: EmpScopeType;
  custom?: any;
}

/* Report settings interface */
export interface IReportSettingsList {
  [id: string]: IReportSettings;
}

export function resolveUrl(settings: IReportSettings): string {
  const {
    report,
    format = 'json',
    scope,
  } = settings;

  // Construct scope part
  let scopeUrl: string;
  let urlParams: string[] = [];
  if (scope.type === 'company') {
    scopeUrl = 'company/-1/all';
  } else {
    if (scope.type === '') { return ''; }
    scopeUrl = `${scope.type}/${scope.id || getLoggedUserId()}/${scope.rln || 'direct'}`;
  }

  if (scope.range && scope.range.startDate && scope.range.endDate) {
    urlParams = urlParams.concat([
      `startDate=${scope.range.startDate.format('YYYY-MM-DD')}`,
      `endDate=${scope.range.endDate.format('YYYY-MM-DD')}`,
    ]);
  }

  // Return full url
  return `/d/${format}/report/${scopeUrl}/${report}?${urlParams.join('&')}`;
}

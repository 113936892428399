import { CompanyTrainingParticipantType, CompanyTrainingParticipant } from '../types';
import getEmployee from './getEmployee';

export default (participant: CompanyTrainingParticipant) => {
  if (participant.type === CompanyTrainingParticipantType.INTERNAL) {
    const emp = getEmployee(participant.employeeId);
    return `${emp.firstName} ${emp.lastName}`;
  }

  return `${participant.firstName} ${participant.lastName}`;
};
import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const answerSurveyBoxStyle: StyleRulesCallback = (theme: Theme) => {
  return ({
    iconButton: {
      color: '#025874',
      '&:hover': {
        color: 'white',
      },
    },
    listItem: {
      '&:not(:last-child)': {
        paddingBottom: theme.spacing.unit,
      },
    },
    listItemTitle: {
      maxWidth: '198px',
      overflow: 'hidden',
      paddingRight: theme.spacing.unit,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    pulsePaper: {
      textAlign: 'center',
    },
    pulseButton: {
      padding: theme.spacing.unit / 2,
    },
    pulseAnswered: {
      padding: theme.spacing.unit,
    },
    contentTitle: {
      backgroundColor: '#00ace6',
      color: 'white',
      fontSize: '14px',
      padding: theme.spacing.unit,
    },
    content: {
      padding: theme.spacing.unit,
      width: '300px',
    },
  });
};

export default answerSurveyBoxStyle;

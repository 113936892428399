import * as React from 'react';
import { withStyles, WithStyles } from '@material-ui/core';
import { InjectedProps } from '@material-ui/core/withMobileDialog';
import FormFields, { InputType } from '../../FormFields/FormFields';
import { INPUT_TYPE } from '@/app/utils/helper';
import { reduxForm, InjectedFormProps, getFormValues, FormProps } from 'redux-form';
import { compose } from 'recompose';
import { Dispatch } from 'redux';
import { loadFormData, changeFormData } from '@/app/redux/formData';
import { connect } from 'react-redux';
import translate from '@/app/utils/translate';
import { throwError } from '@/app/redux/error';
import enumTranslateFormStyle from './enumTranslateFormStyle';
import { LanguagesType } from '@/app/redux/languages';

const supportedLanguags = require('@/assets/languages.json');
const FORM_NAME = 'newEnum';

/**
 * form: redux form name
 * field: target field to change
 * value: field data
 */
type FormDataType = {
  form: string,
  field: string,
  value: any,
};

interface MapDispatchToProps {
  loadFormData(form: string, e: any): void;
  changeFormData: Function;
  throwError: Function;
}

interface MapStateToProps extends FormProps<any, any> {
  currentValues: Array<string>;
  languages: Array<LanguagesType>;
}

interface OwnProps extends InjectedProps {
  classes?: any;
  onSubmit: (values: any) => void;
}

type PropsType = OwnProps & InjectedFormProps & MapStateToProps & MapDispatchToProps & WithStyles<typeof enumTranslateFormStyle>;

class EnumValueForm extends React.Component<PropsType> {
  getTranslateLabel = (code: string) => {
    const result = supportedLanguags.find((l: LanguagesType) => l.code === code);
    if (!result) {
      return '';
    }

    return result.name;
  }

  render() {
    const { handleSubmit, classes, languages } = this.props;

    const inputs: InputType[] = languages.map((language: LanguagesType) => (
      {
        type: INPUT_TYPE.TEXT,
        code: language.code,
        name: language.code,
        label: this.getTranslateLabel(language.code),
        order: 1,
        required: false
      }
    ));

    return (
      <form
        className={classes.root}
        onSubmit={handleSubmit}
      >
        <FormFields inputs={inputs} />
      </form>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    languages: state.languages.get('allLanguages'),
    currentValues: getFormValues(FORM_NAME)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadFormData: (form: string, e: any) => dispatch<any>(loadFormData(form, e)),
  changeFormData: (e: FormDataType) => changeFormData(dispatch, e),
  throwError: (e: string) => dispatch(throwError(e)),
});

const validate = (values: any) => {
  const errors: {[k: string]: any} = {};
  const keys = Object.keys(values);

  if (keys) {
    keys.forEach(key => {
      if (values[key].length > 200) {
        errors[key] = `${translate.t('laErrorMaxTextLen1')} 200`;
      }
    });
  }

  return errors;
};

const enhance = compose<any, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
  }),
  withStyles(enumTranslateFormStyle, {withTheme: true})
);

export default enhance(EnumValueForm);

const GLOBAL: any = window;

export default (code: string): string => {
  if (!GLOBAL.jsonCountries) {
    return '';
  }

  const country = GLOBAL.jsonCountries.find((item: any) => item.code === code);
  if (!country) {
    return undefined;
  }

  return country.name;
};

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
} from '@material-ui/core';
import translate from '@/app/utils/translate';
import * as React from 'react';
import { ErrorMessage } from 'react-hook-form';

import { useEditWorkTimeSettingsDialog } from './useEditWorkTimeSettingsDialog';
import { CountryHoursSettings } from '../../hoursSettingsReducer';

export interface EditWorkTimeSettingsDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (data: Omit<CountryHoursSettings, 'countryCode'>) => void;
  hours: Omit<CountryHoursSettings, 'countryCode'>;
}

export const EditWorkTimeSettingsDialog: React.FC<EditWorkTimeSettingsDialogProps> = ({
  open,
  onClose,
  onSave,
  hours,
}) => {
  const {
    defaultFormValues,
    register,
    handleSave,
    errors,
  } = useEditWorkTimeSettingsDialog(onSave, hours);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <form onSubmit={handleSave}>
        <DialogTitle>{translate.t('workTimeSettings')}</DialogTitle>
        <DialogContent>
          <Grid container={true} spacing={8}>
            <Grid item={true} xs={6}>
              <FormControl fullWidth={true}>
                <TextField
                  inputRef={register}
                  type="text"
                  name="workDayHours"
                  defaultValue={defaultFormValues.workDayHours}
                  label={`${translate.t('lengthOfTheWorkDay')}`}
                  placeholder="H / H:MM"
                  error={!!errors.workDayHours}
                  helperText={
                    errors.workDayHours && (
                      <ErrorMessage errors={errors} name="workDayHours" />
                    )
                  }
                />
              </FormControl>
            </Grid>

            <Grid item={true} xs={6}>
              <FormControl fullWidth={true}>
                <TextField
                  inputRef={register}
                  type="text"
                  name="maxCumulativeHours"
                  defaultValue={defaultFormValues.maxCumulativeHours}
                  label={`${translate.t('maxCumulativeHours')}`}
                  placeholder="H / H:MM"
                  error={!!errors.maxCumulativeHours}
                  helperText={
                    errors.maxCumulativeHours && (
                      <ErrorMessage errors={errors} name="maxCumulativeHours" />
                    )
                  }
                />
              </FormControl>
            </Grid>

            <Grid item={true} xs={6}>
              <FormControl fullWidth={true}>
                <TextField
                  inputRef={register}
                  type="text"
                  name="minCumulativeHours"
                  defaultValue={defaultFormValues.minCumulativeHours}
                  label={`${translate.t('minCumulativeHours')}`}
                  placeholder="H / H:MM"
                  error={!!errors.minCumulativeHours}
                  helperText={
                    errors.minCumulativeHours && (
                      <ErrorMessage errors={errors} name="minCumulativeHours" />
                    )
                  }
                />
              </FormControl>
            </Grid>

            <Grid item={true} xs={6}>
              <FormControl fullWidth={true}>
                <TextField
                  inputRef={register}
                  type="number"
                  name="blockAfterDays"
                  defaultValue={defaultFormValues.blockAfterDays}
                  label={`${translate.t('blockTheHourEditingAfterDays')}`}
                  error={!!errors.blockAfterDays}
                  helperText={
                    errors.blockAfterDays && (
                      <ErrorMessage errors={errors} name="blockAfterDays" />
                    )
                  }
                  InputLabelProps={{
                    style: {
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      width: '100%',
                    },
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            {translate.t('laCancel')}
          </Button>
          <Button type="submit" variant="contained" color="primary">
            {translate.t('laSave')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

import React, { useEffect, useContext, useState, useRef, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Typography, Button } from '@material-ui/core';
import { CompanyTraining, CompanyTrainingParticipantRegistration, ExportedParticipant } from '../types';
import Context from '../context/trainingsContext';
import { getExportableParticipantRegistration } from '../helpers';
import translate from '@/app/utils/translate';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { GridColumn } from '@progress/kendo-react-grid';

type OwnProps = {
  training: CompanyTraining;
  participants?: CompanyTrainingParticipantRegistration[],
  open: boolean;
  onClose: () => void;
};
type Props = OwnProps;

const ParticipantsExportDialog = ({
  training,
  participants: paramParticipants,
  open,
  onClose,
}: Props) => {
  const {
    state: {
      participants: {
        data: fetchedParticipants,
        error,
      },
    },
    getParticipants,
  } = useContext(Context);
  const exportRef = useRef();
  const [fetchStarted, setFetchStarted] = useState(false);
  const [fetchDone, setFetchDone] = useState(false);
  const [readyToExport, setReadyToExport] = useState(false);
  const [exportableParticipants, setExportableParticipants] = useState<ExportedParticipant[]>([]);

  const fetchParticipants = useCallback(
    async () => {
      await getParticipants(training);
      setFetchDone(true);
    },
    [setFetchDone]
  );

  // Phase 1 - Get participants (only once in the beginning)
  useEffect(
    () => {
      if (!open || fetchStarted) {
        return;
      }
      setFetchStarted(true);
      if (!paramParticipants) {
        fetchParticipants();
      } else {
        setFetchDone(true);
      }
    },
    [open, fetchStarted, paramParticipants]
  );

  // Phase 2 - Turn participants into exportable participants
  useEffect(
    () => {
      if (!open || !fetchDone) {
        return;
      }
      if (!paramParticipants) {
        setExportableParticipants(
          fetchedParticipants.map((registration: CompanyTrainingParticipantRegistration) => {
            return getExportableParticipantRegistration(registration);
          })
        );
      } else {
        setExportableParticipants(
          paramParticipants.map((registration: CompanyTrainingParticipantRegistration) => {
            return getExportableParticipantRegistration(registration);
          })
        );
      }
      setReadyToExport(true);
    },
    [open, fetchDone, paramParticipants, fetchedParticipants, setExportableParticipants, setReadyToExport]
  );

  // Phase 3 - Use Kendo ExcelExport to save the file
  useEffect(
    () => {
      if (!open || !readyToExport || !exportRef || !exportRef.current) {
        return;
      }
      (exportRef.current as ExcelExport).save(exportableParticipants);
      onClose();
    },
    [open, readyToExport, exportRef, exportableParticipants]
  );

  if (!open) {
    return null;
  }

  const content = !error ? (
    <CircularProgress />
  ) : (
    <>
      <Typography color="error">{error}</Typography>
      <Button variant="contained" color="primary" onClick={onClose}>{translate.t('laClose')}</Button>
    </>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{translate.t('label_export_participants')}</DialogTitle>
      <DialogContent style={{textAlign: 'center'}}>
        <ExcelExport
          data={exportableParticipants}
          ref={exportRef}
          fileName={`${translate.t('label_participants')} ${translate.t('laList')}`}
        >
          <GridColumn field="employeeId" title={translate.t('laEmpId')} />
          <GridColumn field="firstName" title={translate.t('laFirstName')} />
          <GridColumn field="lastName" title={translate.t('laLastName')} />
          <GridColumn field="email" title={translate.t('laEmail')} />
          <GridColumn field="mobile" title={translate.t('laWorkMobile')} />
          <GridColumn field="phone" title={translate.t('laWorkPhone')} />
          <GridColumn field="employeePosition" title={translate.t('laPosition')} />
          <GridColumn field="unit" title={translate.t('laUnit')} />
          <GridColumn field="guaranteedSeatText" title={translate.t('label_seat_guaranteed', { seat: '' })} />
          <GridColumn field="position" title={translate.t('label_seat', { seat: '' })} />
          <GridColumn field="approvalStatus.status.label" title={translate.t('label_approval_status')} />
          <GridColumn field="hours" title={translate.t('laHours')} />
          <GridColumn field="completionStatus.label" title={translate.t('label_completion')} />
          <GridColumn field="expiresText" title={translate.t('laExpires')} />
          <GridColumn field="message" title={translate.t('laMessageToTrainer')} />
        </ExcelExport>
        {content}
      </DialogContent>
      <DialogActions />
    </Dialog>
  );
};

export default ParticipantsExportDialog;

import * as React from 'react';
import { FormControl, FormLabel, FormGroup, FormHelperText, Typography } from '@material-ui/core';

import { styles } from '@/app/components/FormFields/formFieldsStyle';
import { useState } from 'react';

const RenderUploadField = (props: any) => {
  const {
    input: {
      value,
      onChange,
      onFocus,
      onBlur,
      ...inputProps
    },
    label,
    required,
    meta: { touched, dirty, error },
    helpText,
    ...customProps
  } = props;

  const hasError = (touched || dirty) && !!error;
  
  const [filenames, setFilenames] = useState([]);

  // https://github.com/redux-form/redux-form/issues/3169
  const adaptFileEventToValue = (handler: (e: React.ChangeEvent<HTMLInputElement>) => void) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      // Converting to array first works, using FileList doesn't work on Firefox
      const files = Array.prototype.slice.call(e.target.files);

      setFilenames(files.map((file: any) => file.name));

      return handler(files);
    };
  };

  return (
    <FormControl
      style={styles.textField}
      fullWidth
      margin="normal"
    >
      <FormLabel required={required}>{label}</FormLabel>
      <FormGroup style={styles.uploadField}>
        <input
          type="file"
          required={required}
          onChange={adaptFileEventToValue(onChange)}
          onBlur={adaptFileEventToValue(onBlur)}
          onFocus={adaptFileEventToValue(onFocus)}
          {...inputProps}
          {...customProps}
        />
      </FormGroup>
      {filenames.map((filename, index) => {
        return <Typography key={index}>{filename}</Typography>;
      })}
      {helpText}
      {
        hasError && (
          <FormHelperText error={true}>
            {error}
          </FormHelperText>
        )
      }
    </FormControl>
  );
};

export default RenderUploadField;

import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  withStyles,
  WithStyles,
  DialogActions,
  Button,
  Switch,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  Grid,
} from '@material-ui/core';

import styles from './solaIntelConfigureModuleDialogStyle';

import { IModuleSettings, IIndicatorSettings } from '../SolaIntelConfig';
import translate from '@/app/utils/translate';

interface IEditedValue {
  active: boolean;
  value: string;
}

interface IEditedValues {
  [indicatorId: string]: IEditedValue;
}

interface IProps {
  moduleSettings: IModuleSettings;
  show: boolean;
  onClose: React.MouseEventHandler;
  onSave: (newModuleSettings: IModuleSettings) => void;
}

interface IState {
  editedValues: IEditedValues;
}

class SolaIntelConfigureModuleDialog extends React.Component<
  IProps & WithStyles<typeof styles>,
  IState
> {
  state = {
    editedValues: {},
  };

  componentDidMount() {
    const { moduleSettings } = this.props;
    this.resetEditedValues(moduleSettings);
  }

  componentDidUpdate(newProps: IProps) {
    if (newProps.moduleSettings.type !== this.props.moduleSettings.type) {
      this.resetEditedValues(newProps.moduleSettings);
    }
  }

  resetEditedValues = (moduleSettings: IModuleSettings) => {
    const editedValues: IEditedValues = {};
    Object.keys(moduleSettings.indicators).forEach((indicatorId: string) => {
      editedValues[indicatorId] = {
        value: moduleSettings.indicators[indicatorId].config.value.toString(),
        active: moduleSettings.indicators[indicatorId].active,
      };
    });
    this.setState({ editedValues });
  };

  handleActiveChange = (indicatorId: string, isActive: boolean): void => {
    this.setState((prevState: IState) => {
      return {
        ...prevState,
        editedValues: {
          ...prevState.editedValues,
          [indicatorId]: {
            ...prevState.editedValues[indicatorId],
            active: isActive,
          },
        },
      };
    });
  };

  handleValueChange = (indicatorId: string, newValue: string): void => {
    this.setState((prevState: IState) => {
      return {
        ...prevState,
        editedValues: {
          ...prevState.editedValues,
          [indicatorId]: {
            ...prevState.editedValues[indicatorId],
            value: newValue,
          },
        },
      };
    });
  };

  handleSave = () => {
    const { onSave, moduleSettings } = this.props;
    const { editedValues } = this.state;
    const updatedModuleSettings: IModuleSettings = Object.assign(
      {},
      moduleSettings,
    );
    Object.keys(editedValues).forEach((indicatorId: string) => {
      updatedModuleSettings.indicators[indicatorId].active =
        editedValues[indicatorId].active;
      updatedModuleSettings.indicators[indicatorId].config.value = parseFloat(
        editedValues[indicatorId].value,
      );
    });
    onSave(updatedModuleSettings);
  };

  render() {
    const { classes, show, onClose } = this.props;
    const moduleSettings = this.props.moduleSettings;
    const { editedValues } = this.state;
    if (!moduleSettings || !show) {
      return null;
    }

    const getIndicatorSettings = (indicatorId: string): IIndicatorSettings => {
      if (!moduleSettings || !moduleSettings.indicators[indicatorId]) {
        return null;
      }
      return moduleSettings.indicators[indicatorId];
    };

    const renderSingleNumberConfig = (
      indicator: IIndicatorSettings,
    ): React.ReactNode => {
      let endAdornment: React.ReactNode = null;
      if (indicator.config.unitTransLabel !== '') {
        endAdornment = (
          <InputAdornment position="end">
            {translate.t(indicator.config.unitTransLabel)}
          </InputAdornment>
        );
      }

      return (
        <Grid container key={indicator.id}>
          <Grid xs={3} item>
            <Switch
              color="primary"
              checked={
                !editedValues[indicator.id]
                  ? indicator.active
                  : editedValues[indicator.id].active
              }
              onChange={(_evt: React.ChangeEvent, checked: boolean) =>
                this.handleActiveChange(indicator.id, checked)
              }
            />
          </Grid>
          <Grid xs={9} item>
            <FormControl fullWidth>
              <InputLabel htmlFor="month-value" shrink>
                {translate.t(
                  moduleSettings.indicators[indicator.id].titleTransLabel,
                  {
                    value: moduleSettings.indicators[indicator.id].config.value,
                  },
                )}
              </InputLabel>
              <Input
                id="month-value"
                value={
                  (!editedValues[indicator.id]
                    ? null
                    : editedValues[indicator.id].value) || ''
                }
                onChange={(evt: any) =>
                  this.handleValueChange(indicator.id, evt.target.value)
                }
                endAdornment={endAdornment}
              />
            </FormControl>
          </Grid>
        </Grid>
      );
    };

    const renderIndicatorConfig = (
      indicator: IIndicatorSettings,
    ): React.ReactNode => {
      let nodeInstance: React.ReactNode = null;
      switch (indicator.type) {
        case 'SINGLE_NUMBER':
          nodeInstance = renderSingleNumberConfig(indicator);
          break;
        default:
          throw new Error(
            `Invalid indicator configuration type: ${indicator.type}`,
          );
      }

      return nodeInstance;
    };

    const renderModuleConfig = (): React.ReactNode[] => {
      return Object.keys(moduleSettings.indicators).map(
        (indicatorId: string) => {
          return renderIndicatorConfig(getIndicatorSettings(indicatorId));
        },
      );
    };

    return (
      <Dialog
        className={classes.root}
        open={show}
        onClose={onClose}
        disableBackdropClick={true}
        aria-labelledby="form-dialog-title"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>
          {translate.t('title_solaintel_module_configuration', {
            module: translate.t(moduleSettings.titleTransLabel),
          })}
        </DialogTitle>
        <DialogContent>{renderModuleConfig()}</DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {translate.t('laCancel')}
          </Button>
          <Button variant="contained" onClick={this.handleSave} color="primary">
            {translate.t('laSave')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  SolaIntelConfigureModuleDialog,
);

import translate from '@/app/utils/translate';
import {
  EndDateCell,
  StartDateCell,
  ProposalValueCell,
} from '@/app/components/WorkflowsReport/components/KendoCells';
import {
  commonEmployeeDetailColumnConfig,
  commonWorkflowDetailColumnConfig,
} from '@/app/components/WorkflowsReport/ReportTypes/Common';
import { ColumnConfig } from '@/old/utils/helper';

export const intPositionGridColumns = (): ColumnConfig[] => {
  const columnConfigs = [
    {
      field: 'position',
      title: translate.t('laPosition'),
      width: 150,
      show: true,
    },
    {
      field: 'unit',
      show: true,
      title: translate.t('laUnit'),
      width: 150,
    },
    {
      field: 'managerId',
      show: true,
      title: translate.t('laManager'),
      width: 125,
    },
    {
      field: 'legalEntity',
      title: translate.t('laLegalEntity'),
      width: 100,
      show: true,
    },
    {
      field: 'costCenter',
      title: translate.t('laCostCenters'),
      width: 150,
      show: true,
    },
    {
      field: 'costCenterPct',
      title: translate.t('laPercCostCenter'),
      width: 100,
      show: true,
    },
    {
      field: 'info',
      title: translate.t('laInfo'),
      width: 150,
    },
    {
      cell: StartDateCell,
      field: 'startDate',
      title: translate.t('laStartDate'),
      width: 125,
    },
    {
      cell: EndDateCell,
      field: 'endDate',
      title: translate.t('laEndDate'),
      width: 125,
    },
    {
      field: 'jobTitle',
      title: translate.t('laJobTitle'),
      width: 150,
    },
  ].map(cc =>
    cc.hasOwnProperty('cell')
      ? cc
      : {
          cell: ProposalValueCell,
          ...cc,
        },
  );

  return [
    ...commonEmployeeDetailColumnConfig(),
    ...columnConfigs,
    ...commonWorkflowDetailColumnConfig(),
  ];
};

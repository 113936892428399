import {
  Button,
  Checkbox,
  Paper,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { compose } from 'recompose';

import { Relation } from '@/app/api/internalAPIs';
import SectionListing from '@/app/components/TemplateComponents/admin/EditTemplateComponents/SectionListing/SectionListing';
import translate from '@/app/utils/translate';
import TemplateGrid from '../../components/TemplateGrid/TemplateGrid';
import UnitGrid from '../../components/UnitGrid/UnitGrid';
import { searchMatchingTemplates } from '../../helpers';
import EmployeeEvaluationGrid from '../EmployeeEvaluationGrid/EmployeeEvaluationGrid';
import EmployeeGrid from '../EmployeeGrid/EmployeeGrid';
import styles from './styles';
import { FormTemplateSection } from '@/app/components/TemplateComponents/types';
import { IUnitGrid, IUnitGridData, TemplateFieldSelection } from '@/app/components/JobEvaluation/types';

type OwnProps = {
  employeeJobEvaluationData: any[];
  exportFileName: string;
  templates: any[];
  selectedTemplateIds: string[];
  selectedUnitId: string;
  units: IUnitGridData[];
  onIncludeSubunitsClick: () => void;
  onTemplateClick: (templateId: string) => void;
  onTemplateFieldSelection: (
    sectionId: string,
    selection: string[],
  ) => void;
  onUnitClick: (unitId: string) => void;
  includeSubunits?: boolean;
  teamRelation?: Relation;
  templatesTitle?: string;
  selectedTemplateFields?: TemplateFieldSelection;
  isStatusReport?: boolean;
  showSearch?: boolean;
  onTeamRelationClick?: () => void;
  onBulkTemplateSelection?: (templateIds: string[]) => void;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const TemplateResponseReport = (props: Props) => {
  const {
    classes,
    employeeJobEvaluationData,
    exportFileName,
    includeSubunits,
    isStatusReport,
    selectedTemplateIds,
    selectedTemplateFields,
    units,
    selectedUnitId,
    showSearch,
    templates,
    templatesTitle,
    teamRelation,
    onTemplateClick,
    onUnitClick,
    onTeamRelationClick,
    onIncludeSubunitsClick,
    onTemplateFieldSelection,
    onBulkTemplateSelection,
  } = props;

  const [searchQuery, setSearchQuery] = useState('');
  const selectedTemplate: any = templates.find(
    (template: any) => selectedTemplateIds[0] === `${template.id}`,
  );

  const selectedUnit = units.find(
    (unit) => `${unit.unitId}` === selectedUnitId,
  );

  const getUnitGridElements = (): IUnitGrid[] => units.map((unitGridItem: IUnitGridData) => ({
    ...unitGridItem,
    completed: unitGridItem.completed.length,
    inProgress: unitGridItem.inProgress.length,
    notStarted: unitGridItem.notStarted.length,
    subjectButton: (
      <Button
        variant="outlined"
        onClick={() => onUnitClick(`${unitGridItem.unitId}`)}
        className={classNames({
          [classes.selectedButtonBackground]:
            `${unitGridItem.unitId}` === selectedUnitId,
        })}
      >
        {unitGridItem.unitName}
      </Button>
    ),
  }));

  const onSearch = (query: string) => {
    setSearchQuery(query);
    onBulkTemplateSelection([]);
  };

  const onDeselectAll = () => { onBulkTemplateSelection([]); };
  const onSelectAll = () => {
    onBulkTemplateSelection(
      searchMatchingTemplates(templates, searchQuery).map(template => `${template.id}`)
    );
  };

  const templateGridData = useMemo(() => searchMatchingTemplates(templates, searchQuery).map((template) => ({
    ...template,
    subjectButton: (
      <Button
        variant="outlined"
        onClick={() => onTemplateClick(`${template.id}`)}
        className={classNames({
          [classes.selectedButtonBackground]:
            selectedTemplateIds.includes(`${template.id}`)
        })}
      >
        {template.subject}
      </Button>
    ),
  })), [templates, selectedTemplateIds, searchQuery]);

  return (
    <div>
      <TemplateGrid
        title={templatesTitle}
        templateGridData={templateGridData}
        showSearch={showSearch}
        searchQuery={searchQuery}
        onSearch={onSearch}
        onSelectAll={isStatusReport ? onSelectAll : undefined}
        onDeselectAll={isStatusReport ? onDeselectAll : undefined}
      />
      {!!selectedTemplateIds.length && (
        <>
          {onTeamRelationClick && (
            <span>
              <Checkbox
                checked={teamRelation === Relation.DIRECT}
                onChange={onTeamRelationClick}
              />
              {translate.t('laTeam')}
            </span>
          )}

          <span>
            <Checkbox
              checked={includeSubunits}
              onChange={onIncludeSubunitsClick}
            />
            {translate.t('laIncludeSubunits')}
          </span>
          <UnitGrid
            showNotStarted={true}
            unitGrid={getUnitGridElements()}
            unitGridTitle={translate.t('laUnits')}
          />
        </>
      )}
      {!isStatusReport && selectedTemplateIds.length && selectedUnitId && employeeJobEvaluationData.length
        ? (
          <Paper>
            {selectedTemplate.sections.length ? (
              selectedTemplate.sections.map((section: FormTemplateSection) => (
                <SectionListing
                  readonly={true}
                  customStyle={true}
                  key={section.id}
                  section={section}
                  readyToEditTemplate={selectedTemplate}
                  onItemSelection={onTemplateFieldSelection}
                />
              ))
            ) : (
              <h2 className={classes.noSections}>{translate.t('no_sections')}</h2>
            )}
          </Paper>
        )
        : null
      }
      {!selectedTemplateIds.length || !employeeJobEvaluationData.length || (!selectedTemplateFields && !isStatusReport)
        ? null
        : (
          <div className={classes.gridMarginVertical}>
            {isStatusReport ? (
              <EmployeeGrid
                exportFileName={exportFileName}
                empData={employeeJobEvaluationData}
                empGridTitle={selectedUnit ? selectedUnit.unitName : ''}
              />
            ) : (
              <EmployeeEvaluationGrid
                exportFileName={exportFileName}
                empData={employeeJobEvaluationData}
                empGridTitle={selectedUnit ? selectedUnit.unitName : ''}
              />
            )}
          </div>
        )
      }
    </div>
  );
};

const enhance = compose<Props, OwnProps>(
  withStyles(styles),
);

export default enhance(TemplateResponseReport);

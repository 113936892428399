import { withStyles } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { DailySummary as LocalDailySummary } from './DailySummary';
import { DailySummaryInnerProps, DailySummaryOuterProps } from './dailySummaryModels';
import { dailySummaryStyles } from './dailySummaryStyles';
import { mapDispatchToProps, mapStateToProps } from './dailySummaryRedux';

export { getToggleOuterWidth } from './dailySummaryStyles';

export const DailySummary = compose<DailySummaryInnerProps, DailySummaryOuterProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withSnackbar,
    withStyles(dailySummaryStyles),
)(LocalDailySummary);

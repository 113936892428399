import {
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import { Delete, Event } from '@material-ui/icons';
import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';
import { ErrorMessage } from 'react-hook-form';

import { ErrorTooltip } from '@/app/components/ErrorTooltip';
import IntegrationReactSelect from '@/app/components/IntegrationReactSelect/IntegrationReactSelect';
import LocalizedTimePicker from '@/app/components/Pickers/LocalizedTimePicker';
import { formatDurationToHMM } from '@/app/utils/helper';

import { ReportingGroupType } from '../../../../../../data';

import { CustomField } from './components/CustomField';
import { TransactionRowInnerProps } from './transactionRowModels';
import { useTransactionRow } from './useTransactionRow';

export const TransactionRow: React.FC<TransactionRowInnerProps> = props => {
  const {
    reportingTypesOptions,
    selectedReportingType,
    formattedWorkingType,
    handleWorkingTypeChange,
    handleStartTimeChange,
    handleEndTimeChange,
    handleHoursChange,
    handleDeleteRow,
    updateCustomData,
  } = useTransactionRow(props);

  const {
    classes,
    currentDate,
    customData,
    customFields,
    date,
    end,
    formErrors,
    formValues,
    hours,
    id,
    inEditMode,
    isSaving,
    reportingType,
    start,
    inAutoDeductionEditMode,
    autoDeductionLock,
  } = props;

  const isCorrectionReportingType = inEditMode
    ? formValues &&
    formValues.reportingType &&
    formValues.reportingType.startsWith(
      ReportingGroupType.TIMESHEET_CORRECTION_TYPE,
    )
    : reportingType.startsWith(ReportingGroupType.TIMESHEET_CORRECTION_TYPE);

  return (
    <Grid
      container={true}
      spacing={8}
      wrap="nowrap"
      className={classNames(classes.root, {
        [classes.stale]:
          !inEditMode &&
          !end &&
          !hours &&
          !reportingType.startsWith(
            ReportingGroupType.TIMESHEET_CORRECTION_TYPE,
          ) &&
          moment(date).isBefore(currentDate, 'day'),
        [classes.nonWorkReportingType]: inEditMode
          ? formValues &&
          formValues.reportingType &&
          formValues.reportingType.startsWith(
            ReportingGroupType.TIMESHEET_NON_WORK_TYPE,
          )
          : reportingType.startsWith(
            ReportingGroupType.TIMESHEET_NON_WORK_TYPE,
          ),
        [classes.correctionReportingType]: isCorrectionReportingType,
      })}
    >
      <Grid item={true} xs={2}>
        {(inEditMode && (
          <FormControl variant="filled" fullWidth={true}>
            <ErrorTooltip
              placement="top"
              title={
                formErrors &&
                formErrors.reportingType && (
                  <ErrorMessage errors={formErrors} name="reportingType" />
                )
              }
            >
              <div
              // Wrap select into a div in order to be
              // able to see error tooltip correctly.
              >
                <IntegrationReactSelect
                  isMulti={false}
                  isSearchable={true}
                  isDisabled={isSaving}
                  label=""
                  required={false}
                  error=""
                  hasError={!!(formErrors && formErrors.reportingType)}
                  defaultValue={selectedReportingType || ''}
                  onChange={handleWorkingTypeChange}
                  options={reportingTypesOptions}
                  className={classes.fullWidth}
                  noWrapValue={true}
                />
              </div>
            </ErrorTooltip>
          </FormControl>
        )) || (
            <Typography color="textSecondary">
              {formattedWorkingType}
            </Typography>
          )}
      </Grid>
      <Grid item={true} xs={1}>
        {!isCorrectionReportingType &&
          ((inEditMode && (
            <ErrorTooltip
              placement="top"
              title={
                formErrors &&
                formErrors.start && (
                  <ErrorMessage errors={formErrors} name="start" />
                )
              }
            >
              <LocalizedTimePicker
                name={`start[${id}]`}
                ampm={false}
                clearable={true}
                keyboard={true}
                keyboardIcon={<Event />}
                placeholder="HH:MM"
                format="HH:mm"
                mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                value={(formValues && formValues.start) || null}
                onChange={handleStartTimeChange}
                className={classes.timePicker}
                invalidDateMessage=""
                invalidLabel=""
                error={!!(formErrors && formErrors.start)}
                disabled={isSaving}
                fullWidth={true}
              />
            </ErrorTooltip>
          )) || (
              <Typography color="textSecondary">
                {start && moment(start, 'HH:mm:ss').format('LT')}
              </Typography>
            ))}
      </Grid>
      <Grid item={true} xs={1}>
        {!isCorrectionReportingType &&
          ((inEditMode && (
            <ErrorTooltip
              placement="top"
              title={
                formErrors &&
                formErrors.end && (
                  <ErrorMessage errors={formErrors} name="end" />
                )
              }
            >
              <LocalizedTimePicker
                name={`end[${id}]`}
                ampm={false}
                clearable={true}
                keyboard={true}
                keyboardIcon={<Event />}
                placeholder="HH:MM"
                format="HH:mm"
                mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                value={(formValues && formValues.end) || null}
                onChange={handleEndTimeChange}
                className={classes.timePicker}
                invalidDateMessage=""
                invalidLabel=""
                error={!!(formErrors && formErrors.end)}
                disabled={isSaving}
                fullWidth={true}
              />
            </ErrorTooltip>
          )) || (
              <Typography color="textSecondary">
                {end && moment(end, 'HH:mm:ss').format('LT')}
              </Typography>
            ))}
      </Grid>
      <Grid item={true} xs={1}>
        {((inEditMode || inAutoDeductionEditMode) && (
          <ErrorTooltip
            placement="top"
            title={
              formErrors &&
              formErrors.hours && (
                <ErrorMessage errors={formErrors} name="hours" />
              )
            }
          >
            <TextField
              name={`hours[${id}]`}
              margin="normal"
              value={(formValues && formValues.hours) || ''}
              onChange={handleHoursChange}
              error={!!(formErrors && formErrors.hours)}
              disabled={isSaving}
              fullWidth={true}
              className={classes.textInput}
              placeholder="H / H:MM"
            />
          </ErrorTooltip>
        )) || (
            <Typography color="textSecondary">
              {hours && formatDurationToHMM(hours)}
            </Typography>
          )}
      </Grid>

      {customFields.map(customField => {
        return (
          <Grid key={customField.code} item={true} xs={2}>
            <CustomField
              fieldDetails={customField}
              isSaving={isSaving}
              idEditMode={inEditMode}
              customData={customData}
              formValues={formValues && formValues.customData}
              updateFormValues={updateCustomData}
            />
          </Grid>
        );
      })}

      <Grid item={true} xs={1}>
        {(inEditMode || inAutoDeductionEditMode) && (
          <IconButton
            className={classes.deleteButton}
            onClick={handleDeleteRow}
            disabled={autoDeductionLock}
          >
            <Delete />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

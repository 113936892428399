import * as React from 'react';
import { Button, Typography, Icon, StyleRulesCallback, withStyles, Theme, WithStyles } from '@material-ui/core';

const styles: StyleRulesCallback = (theme: Theme) => ({
  root: {
    marginLeft: theme.spacing.unit,
  },
  prompt: {
  },
  value: {
    textTransform: 'none',
  }
});

type PropsType = {
  prompt: string;
  value: string;
  value2?: string;
  onClick?: Function;
  buttonRef?: any;
};

const ToolbarFilterButton: React.FunctionComponent<PropsType> = (props: PropsType & WithStyles<typeof styles>) => {
  const { buttonRef = null, classes, prompt, value, value2, onClick } = props;
  return (
    <Button buttonRef={buttonRef} onClick={e => onClick(e)} disabled={!onClick} className={classes.root}>
      <div>
        <Typography className={classes.prompt}>{prompt}</Typography>
        <Typography variant="caption" className={classes.value}>{value}</Typography>
        {!value2 ? null : <Typography variant="caption" className={classes.value}>{value2}</Typography>}
      </div>
      {!onClick ? null : <Icon>arrow_drop_down</Icon>}
    </Button>
  );
};

export default withStyles(styles, {withTheme: true})(ToolbarFilterButton);
import { bindActionCreators, Dispatch } from 'redux';

import { createTaskCommentAction } from '../../../../data';

import { DispatchProps, StateProps, SendCommentOuterProps } from './SendComment';

export const mapStateToProps = (state: any, props: SendCommentOuterProps): StateProps => {
    const taskState = state.collaboration.tasks[props.taskId];
    const taskCommentsState = taskState && taskState.comments;

    return {
        creatingComment: taskCommentsState && taskCommentsState.creating || false,
        failedToCreateComment: taskCommentsState && taskCommentsState.failedToCreate || false,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => bindActionCreators(
    { createTaskComment: createTaskCommentAction },
    dispatch
);

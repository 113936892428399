import * as React from 'react';

import {
  GridColumnMenuSort,
  GridColumnMenuFilter,
  // GridColumnMenuItemGroup,
  // GridColumnMenuItem,
  // GridColumnMenuItemContent,
} from '@progress/kendo-react-grid';
import { GridColumnMenuProps } from '@progress/kendo-react-grid/dist/npm/interfaces/GridColumnMenuProps';

class ColumnMenu extends React.Component<GridColumnMenuProps, any> {
  // constructor(props: any) {
  //   super(props);

  //   this.state = {
  //     columns: this.props.columns,
  //     columnsExpanded: false,
  //     filterExpanded: false,
  //   };
  // }

  // componentDidMount() {
  //   const { columns } = this.props;
  //   this.setState({ columns });
  // }

  // handleReset(evt: any) {
  //   event.preventDefault();
  //   const allColumns = this.props.columns.map((col: any) => {
  //     return {
  //       ...col,
  //       show: true,
  //     };
  //   });
  //   this.setState({ columns: allColumns}, () => this.handleSubmit(null));
  // }

  // handleSubmit(evt: any) {
  //   if (evt) {
  //     event.preventDefault();
  //   }
  //   this.props.onColumnsSubmit(this.state.columns);
  //   if (this.props.onCloseMenu) {
  //     this.props.onCloseMenu();
  //   }
  // }

  // handleToggleColumn(id: number) {
  //   this.setState((prevState: any) => ({
  //     columns: prevState.columns.map((col: any, ndx: number) => {
  //       return ndx === id ? { ...col, show: !col.show } : col;
  //     }),
  //   }));
  // }

  // handleMenuItemClick = () => {
  //   const value = !this.state.columnsExpanded;
  //   this.setState({
  //       columnsExpanded: value,
  //       filterExpanded: value ? false : this.state.filterExpanded
  //   });
  // }

  render() {
    // if (!this.state.columns) { return null; }
    // const oneVisibleColumn = this.state.columns.filter((c: any) => c.show).length === 1;
    return (
      <>
        <GridColumnMenuSort column={this.props.column} {...this.props} />
        <GridColumnMenuFilter column={this.props.column} {...this.props} />
        {/* <GridColumnMenuItemGroup>
          <GridColumnMenuItem
            title={'Columns'}
            iconClass="k-i-columns"
            onClick={this.handleMenuItemClick}
          />
          <GridColumnMenuItemContent show={this.state.columnsExpanded}>
            <div className="k-column-list-wrapper">
              <form onSubmit={this.handleSubmit} onReset={this.handleReset}>
                <div className="k-column-list">
                  {this.state.columns.map((column: any, ndx: number) => (
                    <div key={ndx} className="k-column-list-item">
                      <span>
                        <input
                          id={`column-visibility-show-${ndx}`}
                          className="k-checkbox"
                          type="checkbox"
                          readOnly={true}
                          disabled={column.show && oneVisibleColumn}
                          checked={column.show}
                          onClick={() => this.handleToggleColumn(ndx)}
                        />
                        <label
                          htmlFor={`column-visibility-show-${ndx}`}
                          className="k-checkbox-label"
                          style={{ userSelect: 'none' }}
                        >
                          {column.title}
                        </label>
                      </span>
                    </div>
                  ))}
                </div>
                <div className="k-columnmenu-actions">
                    <button type="reset" className="k-button">Reset</button>
                    <button className="k-button k-primary">Save</button>
                </div>
              </form>
            </div>
          </GridColumnMenuItemContent>
        </GridColumnMenuItemGroup> */}
      </>
    );
  }
}

export default ColumnMenu;
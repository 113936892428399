import translate from '@/app/utils/translate';
import { GridCellProps } from '@progress/kendo-react-grid';
import React from 'react';
import {
  AmountCell,
  AvatarCell,
  EmpNoCell,
  EndDateCell,
  FirstNameCell,
  LastNameCell,
  OrgNoCell,
  OrgUnitCell,
  ScheduledDateCell,
  StartDateCell,
  StatusApprovedCell,
  TypeCell,
  UpdatedDateCell,
  WorkflowInfoCell,
  WorkflowStatusCell,
} from '../KendoCells';

export const gridColMapping = {
  empId: AvatarCell,
  firstName: FirstNameCell,
  lastName: LastNameCell,
  empNo: EmpNoCell,
  unitId: OrgUnitCell,
  unitNo: OrgNoCell,
  type: TypeCell,
  amount: AmountCell,
  status: WorkflowStatusCell,
  approvedBy: StatusApprovedCell,
  updatedDate: UpdatedDateCell,
  scheduledDate: ScheduledDateCell,
  info: WorkflowInfoCell,
};

export const gridColumns = () => [
  {
    cell: AvatarCell,
    field: 'empId',
    show: true,
    title: translate.t('laPicture'),
    groupable: false,
  },
  {
    cell: FirstNameCell,
    field: 'firstName',
    show: true,
    title: translate.t('laFirstName'),
  },
  {
    cell: LastNameCell,
    field: 'lastName',
    show: true,
    title: translate.t('laLastName'),
  },
  {
    cell: EmpNoCell,
    field: 'empNo',
    show: true,
    title: translate.t('laEmpId'),
  },
  {
    cell: OrgUnitCell,
    field: 'unitId',
    show: true,
    title: translate.t('laOrgUnit'),
  },
  {
    field: 'unit',
    show: true,
    title: translate.t('laUnit'),
  },
  {
    field: 'managerId',
    show: true,
    title: translate.t('laManager'),
  },
  {
    cell: OrgNoCell,
    field: 'unitNo',
    show: true,
    title: translate.t('laUnitNumber'),
  },
  {
    cell: TypeCell,
    field: 'type',
    show: true,
    title: translate.t('laType'),
  },
  {
    cell: AmountCell,
    field: 'amount',
    show: true,
    filter: 'numeric',
    title: translate.t('laAmount'),
  },
  {
    cell: WorkflowStatusCell,
    field: 'status',
    show: true,
    title: translate.t('laStatus'),
  },
  {
    cell: StatusApprovedCell,
    field: 'approvedBy',
    show: true,
    title: translate.t('laApproved'),
  },
  {
    cell: UpdatedDateCell,
    field: 'updatedDate',
    show: true,
    title: translate.t('laUpdated'),
  },
  {
    cell: ScheduledDateCell,
    field: 'scheduledDate',
    show: true,
    title: translate.t('laScheduledDate'),
  },
  {
    cell: WorkflowInfoCell,
    field: 'info',
    show: true,
    title: translate.t('laInfo'),
    groupable: false,
  },
];

export const intPositionGridColumns = () => [
  {
    cell: AvatarCell,
    field: 'img',
    title: translate.t('laPicture'),
    width: 75,
    groupable: false,
    sortable: false,
    show: true,
  },
  {
    cell: (props: React.PropsWithChildren<GridCellProps>) => (
      <FirstNameCell {...props} forceToPeopleModule={true} />
    ),
    field: 'firstName',
    title: translate.t('laFirstName'),
    width: 100,
    show: true,
  },
  {
    cell: (props: React.PropsWithChildren<GridCellProps>) => (
      <LastNameCell {...props} forceToPeopleModule={true} />
    ),
    field: 'lastName',
    title: translate.t('laLastName'),
    width: 125,
    show: true,
  },
  {
    cell: EmpNoCell,
    field: 'empNo',
    show: true,
    title: translate.t('laEmpId'),
    width: 120,
  },
  {
    field: 'position',
    title: translate.t('laPosition'),
    width: 150,
    show: true,
  },
  {
    field: 'unit',
    show: true,
    title: translate.t('laUnit'),
    width: 150,
  },
  {
    field: 'managerId',
    show: true,
    title: translate.t('laManager'),
    width: 125,
  },
  {
    field: 'legalEntity',
    title: translate.t('laLegalEntity'),
    width: 100,
    show: true,
  },
  {
    field: 'costCenter',
    title: translate.t('laCostCenters'),
    width: 150,
    show: true,
  },
  {
    field: 'costCenterPct',
    title: translate.t('laPercCostCenter'),
    width: 100,
    show: true,
  },
  {
    cell: ScheduledDateCell,
    field: 'scheduledDate',
    show: true,
    title: translate.t('laScheduledDate'),
    width: 125,
  },
  {
    cell: UpdatedDateCell,
    field: 'updatedDate',
    show: true,
    title: translate.t('laUpdated'),
    width: 125,
  },
  {
    cell: WorkflowStatusCell,
    field: 'status',
    show: true,
    title: translate.t('laStatus'),
    width: 100,
  },
  {
    cell: StatusApprovedCell,
    field: 'approvedBy',
    show: true,
    title: translate.t('laApproved'),
    width: 175,
  },
  {
    field: 'info',
    title: translate.t('laInfo'),
    width: 150,
  },
  {
    cell: StartDateCell,
    field: 'startDate',
    title: translate.t('laStartDate'),
    width: 125,
  },
  {
    cell: EndDateCell,
    field: 'endDate',
    title: translate.t('laEndDate'),
    width: 125,
  },
  {
    field: 'jobTitle',
    title: translate.t('laJobTitle'),
    width: 150,
  },
];

export const empDetailsGridColumns = () => [
  {
    cell: AvatarCell,
    field: 'empId',
    show: true,
    title: translate.t('laPicture'),
    groupable: false,
    width: 150,
  },
  {
    cell: (props: React.PropsWithChildren<GridCellProps>) => (
      <FirstNameCell {...props} forceToPeopleModule={true} />
    ),
    field: 'firstName',
    show: true,
    title: translate.t('laFirstName'),
    width: 150,
  },
  {
    cell: (props: React.PropsWithChildren<GridCellProps>) => (
      <LastNameCell {...props} forceToPeopleModule={true} />
    ),
    field: 'lastName',
    show: true,
    title: translate.t('laLastName'),
    width: 150,
  },
  {
    cell: EmpNoCell,
    field: 'empNo',
    show: true,
    title: translate.t('laEmpId'),
    width: 120,
  },
  {
    field: 'empStatus',
    show: true,
    title: translate.t('laEmploymentStatus'),
    width: 120,
  },
  {
    field: 'pg1',
    show: true,
    title: translate.t('fPersonnelGroup1'),
    width: 150,
  },
  {
    field: 'pg2',
    show: true,
    title: translate.t('fPersonnelGroup2'),
    width: 150,
  },
  {
    field: 'pg3',
    show: true,
    title: translate.t('fPersonnelGroup3'),
    width: 150,
  },
  {
    field: 'pg4',
    show: true,
    title: translate.t('fPersonnelGroup4'),
    width: 150,
  },
  {
    field: 'pg5',
    show: true,
    title: translate.t('fPersonnelGroup5'),
    width: 150,
  },
  {
    cell: ScheduledDateCell,
    field: 'scheduledDate',
    show: true,
    title: translate.t('laScheduledDate'),
    width: 120,
  },
  {
    cell: UpdatedDateCell,
    field: 'updatedDate',
    show: true,
    title: translate.t('laUpdated'),
    width: 120,
  },
  {
    cell: WorkflowStatusCell,
    field: 'status',
    show: true,
    title: translate.t('laStatus'),
    width: 120,
  },
  {
    cell: StatusApprovedCell,
    field: 'approvedBy',
    show: true,
    title: translate.t('laApproved'),
    width: 150,
  },
  {
    field: 'startDate',
    show: false,
    title: translate.t('laStartDate'),
    width: 120,
  },
  {
    field: 'endDate',
    show: false,
    title: translate.t('laEndDate'),
    width: 120,
  },
  {
    cell: WorkflowInfoCell,
    field: 'info',
    show: false,
    title: translate.t('laInfo'),
    groupable: false,
    width: 150,
  },
  {
    field: 'jobGrade',
    show: false,
    title: translate.t('laJobGrade'),
    groupable: false,
    width: 120,
  },
  {
    field: 'taxNumber',
    show: false,
    title: translate.t('laTaxNumber'),
    groupable: false,
    width: 150,
  },
  {
    field: 'vacBalCalcUseSixDays',
    show: false,
    title: translate.t('laVacBalCal'),
    groupable: false,
    width: 100,
  },
  {
    field: 'calcHoursBalance',
    show: false,
    title: translate.t('laCalcHoursBalance'),
    groupable: false,
    width: 100,
  },
];

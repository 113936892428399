import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const trainingFormDialogStyle: StyleRulesCallback = (theme: Theme) => {

  return ({
    paperWidthMd: {
      width: '1100px'
    },
    gridRight: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: '24px'
      },
      '& .cke_contents': {
        minHeight: '400px'
      }
    }
  });
};

export default trainingFormDialogStyle;

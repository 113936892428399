import * as React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import {
  WithStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import componentListingStyles from './componentListingStyles';
import DeleteIcon from '@material-ui/icons/Delete';
import { componentTypes, ComponentType } from '../../../../constants';
import SectionTopToolbar from '../SectionTopToolbar/SectionTopToolbar';
import EditIcon from '@material-ui/icons/Edit';
import translate from '@/app/utils/translate';
import { orderElementsByIndex } from '../../../../helpers';
import SortableHOC from '../SortableHOC/SortableHOC';

type PropsType = IncomingProps & WithStyles<typeof componentListingStyles>;

type IncomingProps = {
  components: Object[];
  expanded: boolean;
  addComponentToSection: (
    templateId: string,
    sectionId: string,
    object: Object,
  ) => void;
  updateSectionComponent: (
    templateId: string,
    sectionId: string,
    object: Object,
  ) => void;
  section: any;
  readyToEditTemplate: any;
  orderComponents: (sectionId: string, object: Object[]) => void;
  deleteComponent: (
    templateId: string,
    sectionId: string,
    ids: string[],
  ) => void;
  readonly?: boolean;
  onItemSelection?: (sectionId: string, selectedItemIds: string[]) => void;
};

type StateType = {
  allCheckboxesSelected: boolean;
  selectedCheckboxes: any[];
  rowCount: number;
  componentType: string;
  componentEditMode: boolean;
  openAddComponentModal: boolean;
  openComponentSort: boolean;
};

class ComponentListing extends React.PureComponent<PropsType> {
  state: StateType = {
    allCheckboxesSelected: false,
    selectedCheckboxes: [],
    rowCount: -1,
    componentType: '',
    componentEditMode: false,
    openAddComponentModal: false,
    openComponentSort: false,
  };

  componentDidUpdate(prevProps: PropsType, prevState: StateType) {
    if (prevProps.components.length !== -1) {
      const newRowCount = this.props.components.length;
      this.setState({ rowCount: newRowCount });
    }
    if (
      this.state.rowCount !== prevState.rowCount &&
      prevState.rowCount !== -1
    ) {
      // On row deletion/new template add - set component's selected checkboxes state back to []
      this.setState({ selectedCheckboxes: [], allCheckboxesSelected: false });
    }
  }

  onSelectAllClick = (allComponents: []) => {
    const selectedRows = allComponents.map((component: any) => component.id);
    this.setState({
      allCheckboxesSelected: !this.state.allCheckboxesSelected,
      selectedCheckboxes: !this.state.allCheckboxesSelected ? selectedRows : [],
    });
  };

  handleChange = (id: string) => {
    const selectedCheckboxes = [...this.state.selectedCheckboxes];
    const selectedRows = selectedCheckboxes.includes(id)
      ? selectedCheckboxes.filter(row => row !== id)
      : [...selectedCheckboxes, id];
    this.setState({
      selectedCheckboxes: selectedRows,
      allCheckboxesSelected: selectedRows.length === this.state.rowCount,
    });

    if (this.props.onItemSelection) {
      this.props.onItemSelection(this.props.section.id, selectedRows);
    }
  };

  handleAddComponent = (componentType: ComponentType) => {
    this.setState({
      openAddComponentModal: true,
      componentType: componentType,
    });
  };

  handleModalClose = () => {
    this.setState({ openAddComponentModal: false });
  };

  handleEditComponentModal = (
    _templateId: string,
    _sectionId: string,
    _selectedComponentIds: any[],
    component: any,
    sideMenuControl: boolean,
  ) => {
    if (sideMenuControl) {
      this.setState({ selectedCheckboxes: [component.id] });
    }

    this.setState({
      componentType: component.type,
      componentEditMode: true,
      openAddComponentModal: true,
    });
  };

  endEditMode = () => {
    this.setState({ componentEditMode: false });
  };

  saveNewOrder = (newOrder: []) => {
    const updatedComponents = newOrder.map((component: any, index) => {
      component.index = index + 1;
      return component;
    });

    const allComponentsToUpdate = updatedComponents.map(component => ({
      componentId: component.id,
      index: component.index,
    }));
    this.props.orderComponents(this.props.section.id, allComponentsToUpdate);
    this.closeSortingModal();
  };

  componentSortOpen = () => {
    this.setState({ openComponentSort: true });
  };

  closeSortingModal = () => {
    this.setState({ openComponentSort: false });
  };

  render() {
    const {
      classes,
      components,
      expanded = true,
      addComponentToSection,
      updateSectionComponent,
      readyToEditTemplate,
      section,
      deleteComponent,
      readonly,
    } = this.props;

    const {
      allCheckboxesSelected,
      selectedCheckboxes,
      openAddComponentModal,
      openComponentSort,
      componentType,
      componentEditMode,
    } = this.state;

    let orederedComponents: any = [];
    if (components) {
      orederedComponents = orderElementsByIndex(components);
    }

    const componentsListed =
      orederedComponents.length === 0 ? (
        !readonly && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography>{translate.t('no_components')}.</Typography>
                </TableCell>
                <TableCell />
                <TableCell>
                  <SectionTopToolbar
                    sortComponents={this.componentSortOpen}
                    partialShow={expanded}
                    fullMenu={expanded && selectedCheckboxes.length > 0}
                    selectedComponentIds={selectedCheckboxes}
                    addComponentToSection={addComponentToSection}
                    updateSectionComponent={updateSectionComponent}
                    readyToEditTemplate={readyToEditTemplate}
                    section={section}
                    deleteComponent={deleteComponent}
                    handleEditComponentModal={this.handleEditComponentModal}
                    componentEditMode={componentEditMode}
                    endEditMode={this.endEditMode}
                    openAddComponentModal={openAddComponentModal}
                    handleAddComponent={this.handleAddComponent}
                    componentType={componentType}
                    handleModalClose={this.handleModalClose}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        )
      ) : (
        <>
          <Table>
            {!readonly && (
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      indeterminate={
                        !allCheckboxesSelected && selectedCheckboxes.length > 0
                      }
                      checked={allCheckboxesSelected}
                      onChange={() => this.onSelectAllClick(orederedComponents)}
                      className={classes.topCheckbox}
                    />
                    {translate.t('item_type')}
                  </TableCell>
                  <TableCell align="left">
                    {translate.t('laDescription')}
                  </TableCell>
                  <TableCell>
                    <SectionTopToolbar
                      sortComponents={this.componentSortOpen}
                      partialShow={!!expanded}
                      fullMenu={expanded && selectedCheckboxes.length > 0}
                      selectedComponentIds={selectedCheckboxes}
                      addComponentToSection={addComponentToSection}
                      updateSectionComponent={updateSectionComponent}
                      readyToEditTemplate={readyToEditTemplate}
                      section={section}
                      componentEditMode={componentEditMode}
                      deleteComponent={deleteComponent}
                      handleEditComponentModal={this.handleEditComponentModal}
                      endEditMode={this.endEditMode}
                      handleAddComponent={this.handleAddComponent}
                      openAddComponentModal={openAddComponentModal}
                      componentType={componentType}
                      handleModalClose={this.handleModalClose}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {orederedComponents.map((component: any, index: number) => {
                return (
                  <TableRow hover role="checkbox" key={`${index}-row`}>
                    <TableCell
                      key={`indexTC-${component.id}`}
                      padding="checkbox"
                    >
                      <Checkbox
                        onClick={() => this.handleChange(component.id)}
                        checked={selectedCheckboxes.includes(component.id)}
                        key={`index-${component.id}`}
                      />
                      {componentTypes[component.type]}
                    </TableCell>
                    <TableCell
                      className={`${classes.multiline} ${classes.longText}`}
                    >
                      {component.name}
                    </TableCell>
                    {!readonly && (
                      <TableCell align="right">
                        <IconButton
                          onClick={() =>
                            this.handleEditComponentModal(
                              readyToEditTemplate[0].id,
                              section.id,
                              selectedCheckboxes,
                              component,
                              true,
                            )
                          }
                        >
                          <Tooltip title={translate.t('laEdit')}>
                            <EditIcon color="primary" />
                          </Tooltip>
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            this.props.deleteComponent(
                              readyToEditTemplate[0].id,
                              section.id,
                              [component.id],
                            )
                          }
                        >
                          <Tooltip title={translate.t('laDelete')}>
                            <DeleteIcon color="primary" />
                          </Tooltip>
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <SortableHOC
            open={openComponentSort}
            itemsToSort={orederedComponents}
            saveNewOrder={this.saveNewOrder}
            cancelNewOrder={this.closeSortingModal}
          />
        </>
      );

    return componentsListed;
  }
}

const enhance = compose<PropsType, IncomingProps>(
  withStyles(componentListingStyles),
);

export default enhance(ComponentListing);

import {
  SubstituteManagerReportResponseItem,
  SubstituteManagerReportEntry,
} from './types';

export const mapReportForUI = (
  responseItems: SubstituteManagerReportResponseItem[],
): SubstituteManagerReportEntry[] => {
  const reportItemsWithSubstitutes: SubstituteManagerReportResponseItem[] = responseItems.filter(
    responseItem =>
      responseItem.fOrgUnitSubstitute &&
      responseItem.fOrgUnitSubstitute.length > 0,
  );

  return reportItemsWithSubstitutes.reduce(
    (reportEntriesAcc: SubstituteManagerReportEntry[], currentResponseItem) => [
      ...reportEntriesAcc,
      ...currentResponseItem.fOrgUnitSubstitute.map(substitute => {
        const mgrEntry = {
          unitName: currentResponseItem.fOrgUnitName,
          unitNumber: currentResponseItem.fOrgUnitNumber,
          mgrId: currentResponseItem.fOrgUnitCurMgrId,
          mgrNumber: currentResponseItem.fOrgUnitCurMgrNumber,
          mgrFirstName: currentResponseItem.fOrgUnitCurMgrFirstName,
          mgrLastName: currentResponseItem.fOrgUnitCurMgrLastName,
        };

        const subEntry = {
          subRespUnitId: substitute.fSubRespUnitId,
          subMgrId: substitute.fEmpId,
          subMgrNumber: substitute.fEmpNumber,
          subMgrFirstName: substitute.fEmpFirstName,
          subMgrLastName: substitute.fEmpLastName,
          subMgrUnitName: substitute.fEmpUnitName,
          subMgrUnitCode: substitute.fEmpUnitCode,
          subStartDate: substitute.fSubEmpStartDate,
          subEndDate: substitute.fSubEmpEndDate,
        };

        const reportEntry: SubstituteManagerReportEntry = {
          ...mgrEntry,
          ...subEntry,
        };

        return reportEntry;
      }),
    ],
    [],
  );
};

import * as React from 'react';
import { compose } from 'recompose';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  IconButton,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import sectionListingStyles from './sectionListingStyles';
import ComponentListing from '@/app/components/TemplateComponents/form/FormComponents/ComponentListing/ComponentListing';

type State = {
  expanded: string;
};

export interface DataType {
  key: string;
  label: string;
  componentsList: any;
}

type Props = {
  template: object;
  section: object;
  list: Array<DataType>;
  editMode: boolean;
  updateEvaluation: any;
  employeeId: string;
  responses?: any[];
} & WithStyles;

class SectionListing extends React.Component<Props> {
  state: State = {
    expanded: null,
  };

  handleChange = (panel: string) => (_event: object, expanded: boolean) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  render() {
    const { expanded } = this.state;
    const {
      classes,
      template,
      section,
      list,
      editMode,
      updateEvaluation,
      responses,
      employeeId,
    } = this.props;

    return (
      <div className={classes.container}>
        {list.map((l: DataType) => (
          <ExpansionPanel
            key={l.key}
            expanded={expanded === l.key}
            onChange={this.handleChange(l.key)}
          >
            <ExpansionPanelSummary
              classes={{
                root: classes.expansionPanel,
                content: classes.expansionPanelContent,
                expanded: classes.expanded,
              }}
            >
              <Typography
                variant="h6"
                style={{ cursor: expanded ? 'pointer' : 'auto' }}
              >
                {expanded ? (
                  <IconButton className={classes.iconButton}>
                    <ExpandMoreIcon
                      fontSize="small"
                      className={classes.iconTransform}
                    />
                  </IconButton>
                ) : (
                  <IconButton className={classes.iconButton}>
                    <ExpandMoreIcon fontSize="small" />
                  </IconButton>
                )}
                {l.label}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              classes={{ root: classes.expansionPanelDetails }}
            >
              <ComponentListing
                key={`compListing-${l.key}`}
                template={template}
                section={section}
                components={l.componentsList}
                expanded={!!expanded}
                editMode={editMode}
                updateEvaluation={updateEvaluation}
                employeeId={employeeId}
                responses={responses}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      </div>
    );
  }
}

const enhance = compose<any, any>(withStyles(sectionListingStyles));

export default enhance(SectionListing);

import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles, WithStyles } from '@material-ui/core';

import EnhancedTable from '@/app/components/EnhancedTable/EnhancedTable';
import { HeadData } from '@/app/components/EnhancedTable/EnhancedTableHead/EnhancedTableHead';
import { Tools } from '@/app/components/EnhancedTable/EnhancedTableToolbar/EnhancedTableToolbar';
import FetchModulesHOC from '@/app/hocs/FetchModulesHOC';
import { useRoles } from '@/app/hooks/useRoles';
import {
  createQuickLink,
  duplicateQuickLink,
  editQuickLink,
  fetchQuickLinks,
  removeMultipleQuickLinks,
  updateQuickLinkTranslations,
  QuickLink,
  QuickLinkForm,
  QuickLinkInput,
} from '@/app/redux/quickLinks';
import {
  openConfirmDialog,
  ConfirmDialogType,
} from '@/app/redux/confirmDialog';
import { ReducerState } from '@/app/redux/store';
import {
  ENABLED_TABS_PEOPLE,
  ENABLED_TABS_REWARDING,
  ENABLED_TABS_PERFORMANCE,
  ENABLED_TABS_DEVELOPMENT,
} from '@/app/utils/constants';
import { getCurrentLanguage } from '@/app/utils/helper';
import translate from '@/app/utils/translate';
import QuickLinkFormDialog from '../QuickLinkFormDialog/QuickLinkFormDialog';
import TranslateQuickLinkDialog from '../TranslateQuickLinkDialog/TranslateQuickLinkDialog';
import { findQuickLink, getTranslation } from '../utils';
import manageQuickLinksStyle from './manageQuickLinksStyle';
import { getActiveTabs } from '@/app/redux/adminSection';
import SortQuickLinksDialog from '../SortQuickLinksDialog/SortQuickLinkDialog';

const TOOLS: Tools = {
  showAdd: true,
  showEdit: true,
  showDelete: true,
  showTranslate: true,
  showDuplicate: true,
  showSort: true,
};

type OwnProps = {
  locationLinksOnly?: boolean;
};

type MapStateToProps = {
  allTabs: string[];
  quickLinks: QuickLink[];
};

type MapDispatchToProps = {
  addQuickLink: (quickLinkInput: QuickLinkInput) => Promise<{ id: string }>;
  dupeQuickLink: (quickLink: QuickLink) => Promise<{ id: string }>;
  fetchAllQuickLinks: () => void;
  fetchActiveTabs: (module: string) => void;
  openConfirmationDialog: (props: ConfirmDialogType) => void;
  removeSelectedQuickLinks: (selected: string[]) => Promise<{}>;
  updateQuickLink: (quickLinkInput: QuickLinkInput) => Promise<{}>;
  updateTranslations: (quickLink: QuickLink) => Promise<{}>;
};

type PropsType = OwnProps & 
  MapStateToProps &
  MapDispatchToProps &
  WithStyles<typeof manageQuickLinksStyle>;

const ManageQuickLinks: React.FC<PropsType> = (props) => {
  const {
    addQuickLink,
    classes,
    dupeQuickLink,
    fetchAllQuickLinks,
    fetchActiveTabs,
    locationLinksOnly,
    openConfirmationDialog,
    quickLinks,
    removeSelectedQuickLinks,
    updateQuickLink,
    updateTranslations,
  } = props;
  const availableLinks = quickLinks.filter(link => locationLinksOnly
    ? link.location === 'menu_links'
    : link.location === 'quick_links_widget'
  );

  const [open, setOpen] = React.useState(false);
  const [sortOpen, setSortOpen] = React.useState(false);
  const [editingItem, setEditingItem] = React.useState<QuickLink>(undefined);
  const [translateOpen, setTranslateOpen] = React.useState(false);
  const currentLanguage = getCurrentLanguage();
  const { data: roles } = useRoles();
  const headData: Array<HeadData> = [
    {
      id: 'name',
      type: 'string',
      disablePadding: false,
      label: translate.t('table_col_link_name'),
    },
    {
      id: 'description',
      type: 'string',
      disablePadding: false,
      label: translate.t('table_col_description'),
    },
    {
      id: 'roles',
      type: 'string',
      disablePadding: false,
      label: translate.t('table_col_roles'),
    },
  ];

  const tableData = availableLinks.map((quickLink) => {
    const currentTr = getTranslation(
      currentLanguage,
      quickLink.translations,
      true,
    );
    const currentRoles = quickLink.roles
      .map((role) => {
        const roleDetails = roles.find((roleItem) => roleItem.code === role);
        if (!roleDetails) {
          return '';
        }

        return roleDetails.name;
      })
      .join(', ');

    return {
      ...quickLink,
      name: currentTr ? currentTr.name : '',
      description: currentTr ? currentTr.description : '',
      roles: currentRoles,
    };
  });

  const addHandler = () => {
    setEditingItem(undefined);
    setOpen(true);
  };

  const editHandler = (selectedItems: QuickLinkForm[]) => (
    _event: React.MouseEvent,
  ) => {
    const found = findQuickLink(selectedItems[0].id, availableLinks);
    if (!found) {
      return;
    }

    setEditingItem(found);
    setOpen(true);
  };

  const deleteHandler = (selectedItems: QuickLinkForm[]) => (
    _event: React.MouseEvent,
  ) => {
    openConfirmationDialog({
      text: translate.t('confirm_delete_item_s'),
      onOk: () => {
        removeSelectedQuickLinks(selectedItems.map((item) => item.id));
      },
    });
  };

  const duplicateHandler = (selectedItems: QuickLinkForm[]) => (
    _event: React.MouseEvent,
  ) => {
    const found = availableLinks.find(
      (quickLink) => quickLink.id === selectedItems[0].id,
    );
    if (!found) {
      return;
    }

    dupeQuickLink(found);
  };

  const translateHandler = (selectedItems: QuickLinkForm[]) => (
    _event: React.MouseEvent,
  ) => {
    const found = findQuickLink(selectedItems[0].id, availableLinks);
    if (!found) {
      return;
    }

    setEditingItem(found);
    setTranslateOpen(true);
  };

  const onDialogClose = () => {
    setOpen(false);
    setEditingItem(undefined);
  };

  const onTranslationClose = () => {
    setEditingItem(undefined);
    setTranslateOpen(false);
  };

  const onTranslationSubmit = (updatedQuickLink: QuickLink) => {
    setEditingItem(undefined);
    setTranslateOpen(false);
    updateTranslations(updatedQuickLink);
  };

  const onSubmit = (quickLinkInput: QuickLinkInput, isEdit?: boolean) => {
    if (!isEdit) {
      addQuickLink(quickLinkInput).then(() => {
        setOpen(false);
      });

      return;
    }

    updateQuickLink(quickLinkInput).then(() => {
      setEditingItem(undefined);
      setOpen(false);
    });
  };

  const sortHandler = () => {
    setSortOpen(true);
  };

  const onSortClose = () => {
    setSortOpen(false);
  };

  React.useEffect(() => {
    fetchAllQuickLinks();
  }, [fetchAllQuickLinks]);

  React.useEffect(() => {
    if (locationLinksOnly) {
      fetchActiveTabs(ENABLED_TABS_PEOPLE);
      fetchActiveTabs(ENABLED_TABS_REWARDING);
      fetchActiveTabs(ENABLED_TABS_PERFORMANCE);
      fetchActiveTabs(ENABLED_TABS_DEVELOPMENT);
    }
  }, [fetchActiveTabs, locationLinksOnly]);

  return (
    <>
      <QuickLinkFormDialog
        open={open}
        onClose={onDialogClose}
        onSubmit={onSubmit}
        locationLinksOnly={locationLinksOnly}
        title={locationLinksOnly
          ? translate.t('laSidebarMenuSettings')
          : translate.t('title_manage_quick_links')
        }
        existingItem={editingItem}
        edit={true}
      />
      <TranslateQuickLinkDialog
        open={translateOpen}
        onClose={onTranslationClose}
        onSubmit={onTranslationSubmit}
        selectedItem={editingItem}
        title={translate.t('title_translate')}
        edit={true}
      />
      <SortQuickLinksDialog
        quickLinks={availableLinks}
        open={sortOpen}
        onClose={onSortClose}
      />
      <div className={classes.root}>
        <EnhancedTable
          title={locationLinksOnly
            ? translate.t('laSidebarMenuSettings')
            : translate.t('title_manage_quick_links')
          }
          hideTools={{
            hideDelete: false,
            hideTranslate: false,
          }}
          tools={TOOLS}
          addHandler={addHandler}
          editHandler={editHandler}
          deleteHandler={deleteHandler}
          duplicateHandler={duplicateHandler}
          translateHandler={translateHandler}
          sortHandler={sortHandler}
          headData={headData}
          data={tableData}
          order={'asc'}
          orderBy={'name'}
        />
      </div>
    </>
  );
};

const mapDispatchToProps = {
  addQuickLink: createQuickLink,
  dupeQuickLink: duplicateQuickLink,
  updateQuickLink: editQuickLink,
  fetchAllQuickLinks: fetchQuickLinks,
  openConfirmationDialog: openConfirmDialog,
  removeSelectedQuickLinks: removeMultipleQuickLinks,
  updateTranslations: updateQuickLinkTranslations,
  fetchActiveTabs: getActiveTabs,
};

const mapStateToProps = (state: ReducerState) => ({
  quickLinks: state.quickLinks.get('list'),
});

const enhance = compose<PropsType, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(manageQuickLinksStyle),
  FetchModulesHOC,
);

export default enhance(ManageQuickLinks);

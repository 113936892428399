import * as React from 'react';
import {
  Paper,
  withStyles,
  WithStyles,
  Toolbar,
  Popper,
  Fade,
  ClickAwayListener,
} from '@material-ui/core';

import styles from './dockablePopupStyle';
import Titlebar from './DockablePopupTitlebar/DockablePopupTitlebar';

interface IProps {
  anchorEl: any;
  title: string;
  iconName?: string;
  secondaryToolbar?: React.ReactElement<typeof Toolbar>;
  allowClickAwayClose?: boolean;
  onClose: Function;
}

class DockablePopup extends React.Component<IProps & WithStyles<typeof styles>, any> {
  render() {
    const { children, classes, anchorEl, iconName, title, secondaryToolbar, allowClickAwayClose = true, onClose } = this.props;
    if (!anchorEl) { return null; }
    return (
      <Popper
        className={classes.root}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="top-start"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <ClickAwayListener
              onClickAway={() => onClose()}
              mouseEvent={allowClickAwayClose ? 'onMouseUp' : false}
              touchEvent={allowClickAwayClose ? 'onTouchEnd' : false}
            >
              <Paper className={classes.rootPaper}>
                <Titlebar
                  title={title}
                  iconName={iconName}
                  secondaryToolbar={secondaryToolbar}
                  onClose={() => onClose()}
                />
                <Paper className={classes.content}>
                  {children}
                </Paper>
              </Paper>
            </ClickAwayListener>
          </Fade>
        )}
      </Popper>
    );
  }
}

export default withStyles(styles, {withTheme: true})(DockablePopup);
import * as React from 'react';

import {
  CompanyTrainingParticipant,
  CompanyTrainingParticipantType,
} from '../../../types';
import EmployeeAvatar from '../../EmployeeAvatar';
import ExternalParticipantAvatar from './ExternalParticipantAvatar';

type Props = {
  participant: CompanyTrainingParticipant;
};

const ParticipantAvatar = ({ participant }: Props) => {
  if (participant.type === CompanyTrainingParticipantType.INTERNAL) {
    return <EmployeeAvatar employeeId={participant.employeeId} />;
  }

  return <ExternalParticipantAvatar participant={participant} />;
};

export default ParticipantAvatar;
import { createStyles } from '@material-ui/core';

export const commentsListStyles = () => createStyles({
    root: {
        minHeight: 150,
    },
    centerAlignedContainer: {
        display: 'flex',
        padding: 50,
        justifyContent: 'center',
    },
});
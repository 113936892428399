export enum ComponentType {
  GOAL = 'GOAL',
  LINK = 'LINK',
  SUBTITLE = 'SUBTITLE',
}

export const componentTypeTranslationKeys = {
  [ComponentType.GOAL]: 'laGoal',
  [ComponentType.LINK]: 'label_LINK',
  [ComponentType.SUBTITLE]: 'label_SUBTITLE',
};

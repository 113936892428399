import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const wizardDialogActionsStyle: StyleRulesCallback = (theme: Theme) => {

  return ({
    button: {
      marginLeft: theme.spacing.unit,
    },
  });
};

export default wizardDialogActionsStyle;

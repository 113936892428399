import { Record } from 'immutable';
import { Dispatch } from 'redux';
import { THROW_ERROR } from './error';
import { OPEN_NOTIFIER } from '@/app/redux/notifier';
import translate from '@/app/utils/translate';
import API from '@/app/api/internalAPIs';
import Service from '@/app/utils/service';

const initialState = Record({
  allProcessTemplates: [],
});

const FETCH_ALL_PROCESS_TEMPLATES =
  '@@solaforce/process-templates/FETCH_ALL_PROCESS_TEMPLATES';
const ADD_PROCESS_TEMPLATE =
  '@@solaforce/process-templates/ADD_PROCESS_TEMPLATE';
const UPDATE_PROCESS_TEMPLATE =
  '@@solaforce/process-templates/UPDATE_PROCESS_TEMPLATE';
const CHANGE_TEMPLATE_ACTIVE_STATUS =
  '@@solaforce/process-templates/CHANGE_TEMPLATE_ACTIVE_STATUS';
const DELETE_TEMPLATES = '@@solaforce/process-templates/DELETE_TEMPLATES';
const DUPLICATE_TEMPLATE = '@@solaforce/process-templates/DUPLICATE_TEMPLATE';
const ADD_TEMPLATE_SECTION =
  '@@solaforce/process-templates/ADD_TEMPLATE_SECTION';
const UPDATE_SECTION = '@@solaforce/appraisal/UPDATE_SECTION';
const ADD_SECTION_COMPONENT =
  '@@solaforce/process-templates/ADD_SECTION_COMPONENT';
const UPDATE_SECTION_COMPONENT =
  '@@solaforce/process-templates/UPDATE_SECTION_COMPONENT';
const DELET_COMPONENT = '@@solaforce/process-templates/DELET_COMPONENT';
const DELETE_SECTION = '@@solaforce/process-templates/DELETE_SECTION';

const reducer = (state = new initialState(), action: any) => {
  switch (action.type) {
    case FETCH_ALL_PROCESS_TEMPLATES:
      const { allProcessTemplates } = action.payload;
      return state.set('allProcessTemplates', allProcessTemplates);

    case ADD_PROCESS_TEMPLATE:
      const { newTemplate } = action.payload;
      return state.set('allProcessTemplates', [
        ...state.allProcessTemplates,
        newTemplate,
      ]);

    case UPDATE_PROCESS_TEMPLATE:
      const { updatedTemplate } = action.payload;
      const updatedStateWTemplate = state
        .get('allProcessTemplates')
        .map((item: any) => {
          if (updatedTemplate.id === item.id) {
            return {
              ...updatedTemplate,
              sections: [...item.sections],
            };
          }
          return item;
        });
      return state.set('allProcessTemplates', updatedStateWTemplate);

    case CHANGE_TEMPLATE_ACTIVE_STATUS:
      const { templatesToUpdate, newStatus } = action.payload;
      const updatedState = state.get('allProcessTemplates').map((item: any) => {
        if (templatesToUpdate.includes(item.id)) {
          return {
            ...item,
            status: newStatus,
          };
        }
        return item;
      });
      return state.set('allProcessTemplates', updatedState);

    case DELETE_TEMPLATES:
      const { templatesToDelete } = action.payload;
      const updatedStateAfterDelete = state
        .get('allProcessTemplates')
        .filter((item: any) => {
          return !templatesToDelete.includes(item.id);
        });
      return state.set('allProcessTemplates', updatedStateAfterDelete);

    case DUPLICATE_TEMPLATE:
      const { templateResponse } = action.payload;
      return state.set('allProcessTemplates', [
        ...state.allProcessTemplates,
        templateResponse,
      ]);

    case ADD_TEMPLATE_SECTION:
      const { templateId, section } = action.payload;
      const updatedStateWSection = state
        .get('allProcessTemplates')
        .map((item: any) => {
          if (templateId === item.id) {
            return {
              ...item,
              sections: [...item.sections, section],
            };
          }
          return item;
        });
      return state.set('allProcessTemplates', updatedStateWSection);

    case DELETE_SECTION:
      const { tempId, sectionToDeleteId } = action.payload;
      const updatedStateAfterSectionDelete = state
        .get('allProcessTemplates')
        .map((template: any) => {
          if (tempId === template.id) {
            return {
              ...template,
              sections: template.sections.filter(
                (sec: any) => sec.id !== sectionToDeleteId,
              ),
            };
          } else {
            return template;
          }
        });
      return state.set('allProcessTemplates', updatedStateAfterSectionDelete);

    case ADD_SECTION_COMPONENT:
      const { templateIdUsed, sectionId, component } = action.payload;
      const updatedStateWComponent = state
        .get('allProcessTemplates')
        .map((item: any) => {
          if (templateIdUsed === item.id) {
            item.sections.map((templateSection: any, index: any) => {
              if (templateSection.id === sectionId) {
                if (item.sections[index].components) {
                  item.sections[index].components = [
                    ...item.sections[index].components,
                    ...component,
                  ];
                } else {
                  item.sections[index].components = [...component];
                }
                return {
                  ...item,
                  sections: [...item.sections],
                };
              }
            });
            return {
              ...item,
              sections: [...item.sections],
            };
          }
          return item;
        });
      return state.set('allProcessTemplates', updatedStateWComponent);

    case UPDATE_SECTION_COMPONENT:
      const { tmpId, secId, cmp } = action.payload;
      const updatedComponent = state
        .get('allProcessTemplates')
        .map((item: any) => {
          if (tmpId === item.id) {
            item.sections.map((templateSection: any, index: any) => {
              if (templateSection.id === secId) {
                templateSection.components.map((comp: any, idx: any) => {
                  if (comp.id === cmp[0].id) {
                    return {
                      ...item,
                      sections: [...item.sections],
                      components: [
                        ...item.sections[index].components,
                        (item.sections[index].components[idx] = cmp[0]),
                      ],
                    };
                  }
                });
              }
            });
          }
          return item;
        });
      return state.set('allProcessTemplates', updatedComponent);

    case DELET_COMPONENT:
      const {
        editedTemplateId,
        editedSectionId,
        componentsToDelete,
      } = action.payload;
      const updatedSectionStateAfterDelete = state
        .get('allProcessTemplates')
        .map((template: any) => {
          if (editedTemplateId === template.id) {
            template.sections.map((templateSection: any, index: any) => {
              if (editedSectionId === templateSection.id) {
                if (template.sections[index].components) {
                  template.sections[index].components = template.sections[
                    index
                  ].components.filter((sectionComponent: any) => {
                    return !componentsToDelete.includes(sectionComponent.id);
                  });
                }
              }
            });
          }
          return {
            ...template,
            sections: [...template.sections],
          };
        });

      return state.set('allProcessTemplates', updatedSectionStateAfterDelete);
    default:
      return state;
  }
};

// >>>>>>>>>>>>>>>>>>>>>>>>> TEMPLATES  <<<<<<<<<<<<<<<<<<<<<<<<<
export const fetchAllProcessTemplates = () => {
  return (dispatch: Dispatch) => {
    return Service.get(
      API.processTemplates.getAllTemplates(),
      (response: any) => {
        const payload = {
          allProcessTemplates: response.data,
        };
        dispatch({ type: FETCH_ALL_PROCESS_TEMPLATES, payload });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const addProcessTemplate = (template: any[]) => {
  return (dispatch: Dispatch) => {
    Service.post(
      API.processTemplates.addProcessTemplate(),
      template,
      (response: any) => {
        response.sections = [];
        const payload = {
          newTemplate: response,
        };
        dispatch({ type: ADD_PROCESS_TEMPLATE, payload });
        dispatch({
          type: OPEN_NOTIFIER,
          payload: { message: translate.t('new_template_added') },
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const updateProcessTemplate = (templateId: string, template: any[]) => {
  return (dispatch: Dispatch) => {
    Service.post(
      API.processTemplates.updateTemplate(templateId),
      template,
      (response: any) => {
        const payload = {
          updatedTemplate: response,
        };
        dispatch({ type: UPDATE_PROCESS_TEMPLATE, payload });
        dispatch({ type: OPEN_NOTIFIER, payload: {} });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const changeTemplateStatus = (templateIds: [], newStatus: string) => {
  return (dispatch: Dispatch) => {
    Service.post(
      newStatus === 'ACTIVE'
        ? API.processTemplates.activateTemplate()
        : API.processTemplates.deactivateTemplate(),
      { ids: templateIds },
      (response: any) => {
        const payload = {
          templatesToUpdate: response.data.map((template: any) => template.id),
          newStatus: newStatus,
        };
        dispatch({ type: CHANGE_TEMPLATE_ACTIVE_STATUS, payload });
        dispatch({
          type: OPEN_NOTIFIER,
          payload: { message: translate.t('template_status_updated') },
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const deleteTemplate = (templateIds: []) => {
  return (dispatch: Dispatch) => {
    Service.post(
      API.processTemplates.removeTemplates(),
      { ids: templateIds },
      (_response: any) => {
        const payload = {
          templatesToDelete: templateIds,
        };
        dispatch({ type: DELETE_TEMPLATES, payload });
        dispatch({
          type: OPEN_NOTIFIER,
          payload: { message: translate.t('template_deleted') },
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const duplicateTemplate = (templateId: string) => {
  return (dispatch: Dispatch) => {
    Service.post(
      API.processTemplates.duplicateTemplate(templateId),
      { id: templateId },
      (response: any) => {
        dispatch({
          type: DUPLICATE_TEMPLATE,
          payload: { templateResponse: response },
        });
        dispatch({
          type: OPEN_NOTIFIER,
          payload: { message: translate.t('template_duplicated') },
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

// >>>>>>>>>>>>>>>>>>>>>>>>> SECTIONS  <<<<<<<<<<<<<<<<<<<<<<<<<
export const addTemplateSection = (templateId: string, section: {}) => {
  return (dispatch: Dispatch) => {
    Service.post(
      API.processTemplates.addSection(templateId),
      section,
      (_response: any) => {
        const payload = {
          templateId: templateId,
          section: section,
        };
        dispatch({ type: ADD_TEMPLATE_SECTION, payload });
        dispatch({
          type: OPEN_NOTIFIER,
          payload: { message: translate.t('new_section_added') },
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const updateSection = (editedTmpId: any, section: any) => {
  return (dispatch: Dispatch) => {
    Service.post(
      API.processTemplates.updateSection(section.id),
      {
        name: section.name,
        empAccess: section.empAccess,
        onlyEmpAccess: section.onlyEmpAccess,
        components: section.components,
        index: section.index,
      },
      (_response: any) => {
        dispatch({
          type: UPDATE_SECTION,
          payload: { updatedSection: section, editedTmpId: editedTmpId },
        });
        dispatch({
          type: OPEN_NOTIFIER,
          payload: { message: translate.t('sectionUpdated') },
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const deleteSection = (templateId: string, sectionId: string) => {
  return (dispatch: Dispatch) => {
    Service.delete(
      API.processTemplates.deleteSection(sectionId),
      { id: sectionId },
      (_response: any) => {
        const payload = {
          tempId: templateId,
          sectionToDeleteId: sectionId,
        };
        dispatch({ type: DELETE_SECTION, payload });
        dispatch({
          type: OPEN_NOTIFIER,
          payload: { message: translate.t('section_deleted') },
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

// >>>>>>>>>>>>>>>>>>>>>>>>> Components  <<<<<<<<<<<<<<<<<<<<<<<<<
export const addComponentToSection = (
  templateId: string,
  sectionId: string,
  component: {},
) => {
  return (dispatch: Dispatch) => {
    Service.post(
      API.processTemplates.addComponent(sectionId),
      component,
      (response: any) => {
        const payload = {
          templateIdUsed: templateId,
          sectionId: sectionId,
          component: [response],
        };
        dispatch({ type: ADD_SECTION_COMPONENT, payload });
        dispatch({
          type: OPEN_NOTIFIER,
          payload: { message: translate.t('new_component_added') },
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const updateSectionComponent = (
  templateId: string,
  sectionId: string,
  component: any,
) => {
  return (dispatch: Dispatch) => {
    const { componentId, ...componentProps } = component;

    Service.post(
      API.processTemplates.updateComponent(componentId),
      componentProps,
      (response: any) => {
        const payload = {
          tmpId: templateId,
          secId: sectionId,
          cmp: [response],
        };
        dispatch({ type: UPDATE_SECTION_COMPONENT, payload });
        dispatch({
          type: OPEN_NOTIFIER,
          payload: { message: translate.t('componentUpdated') },
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const orderComponents = (sectionId: string, components: any) => {
  return (dispatch: Dispatch) => {
    Service.post(
      API.processTemplates.orderComponents(sectionId),
      { components },
      (_response: any) => {
        dispatch({
          type: OPEN_NOTIFIER,
          payload: { message: translate.t('componentUpdated') },
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const deleteComponent = (
  templateId: string,
  sectionId: string,
  componentIds: [],
) => {
  return (dispatch: Dispatch) => {
    Service.post(
      API.processTemplates.deleteComponent(),
      { ids: componentIds },
      (_response: any) => {
        const payload = {
          editedTemplateId: templateId,
          editedSectionId: sectionId,
          componentsToDelete: componentIds,
        };
        dispatch({ type: DELET_COMPONENT, payload });
        dispatch({
          type: OPEN_NOTIFIER,
          payload: { message: translate.t('component_deleted') },
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};
export default reducer;

import * as React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Grid,
  Typography,
  ExpansionPanelDetails,
  IconButton,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ReducerState } from '@/app/redux/store';
import translate from '@/app/utils/translate';
import sectionListingStyles from './sectionListingStyles';
import ComponentListing from './ComponentListing/ComponentListing';
import {
  ActiveTemplate,
  ComponentItem,
  JobReqEvalSection,
  JobReqEvaluation,
  JobReqEvaluationResponse,
} from '@/app/components/JobEvaluation/types';

type State = {
  expanded: string;
  sectionScore: { [componentId: string]: number };
};

export interface DataType {
  key: string;
  label: string;
  componentsList: ComponentItem[];
}

type OwnProps = {
  template: ActiveTemplate;
  section: JobReqEvalSection;
  list: DataType[];
  editMode: boolean;
  updateEvaluation: (
    templateId: string,
    responses: JobReqEvaluationResponse,
  ) => Promise<void>;
};

type MapStateToProps = {
  jobRequirementEvaluation: JobReqEvaluation;
};

type Props = OwnProps & MapStateToProps & WithStyles;

const getScore = (
  sectionScore: State['sectionScore'],
  section: JobReqEvalSection,
) => {
  const sectionComponentIds = section.components.map(comp => comp.id);
  return Object.keys(sectionScore)
    .filter(componentId => sectionComponentIds.indexOf(componentId) > -1)
    .map(componentId => sectionScore[componentId])
    .reduce((sum, score) => sum + score, 0);
};

class SectionListing extends React.Component<Props> {
  state: State = {
    expanded: null,
    sectionScore: {},
  };

  /*componentDidUpdate(prevProps: Props) {
    if (
      prevProps.jobRequirementEvaluation.tmpID !==
      this.props.jobRequirementEvaluation.tmpID
    ) {
      const initialScores = this.props.jobRequirementEvaluation.responses.reduce(
        (acc, respItem) => {
          return { ...acc, [respItem.componentId]: respItem.response.score };
        },
        {},
      );

      this.setState({
        sectionScore: { ...this.state.sectionScore, ...initialScores },
      });
    }
  }*/

  handleChange = (panel: string) => (_event: object, expanded: boolean) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  handleSectionScoreChange = (componentId: string, newScore: number) => {
    this.setState((prevState: State) => {
      const updatedScore = {
        ...prevState.sectionScore,
        [componentId]: newScore,
      };

      return { sectionScore: updatedScore };
    });
  };

  render() {
    const { expanded, sectionScore } = this.state;
    const {
      classes,
      template,
      section,
      list,
      editMode,
      updateEvaluation,
    } = this.props;

    return (
      <div className={classes.container}>
        {list.map((l: DataType) => (
          <ExpansionPanel
            key={l.key}
            expanded={expanded === l.key}
            onChange={this.handleChange(l.key)}
          >
            <ExpansionPanelSummary
              classes={{
                root: classes.expansionPanel,
                content: classes.expansionPanelContent,
                expanded: classes.expanded,
              }}
            >
              <Grid container alignItems="center">
                <Grid item xs={8}>
                  <Typography
                    variant="h6"
                    style={{ cursor: expanded ? 'pointer' : 'auto' }}
                  >
                    {expanded ? (
                      <IconButton className={classes.iconButton}>
                        <ExpandMoreIcon
                          fontSize="small"
                          className={classes.iconTransform}
                        />
                      </IconButton>
                    ) : (
                      <IconButton className={classes.iconButton}>
                        <ExpandMoreIcon fontSize="small" />
                      </IconButton>
                    )}
                    {l.label}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  {!expanded && (
                    <Typography
                      variant="h6"
                      style={{ cursor: expanded ? 'pointer' : 'auto' }}
                    >
                      {translate.t('sum')} {getScore(sectionScore, section)}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              classes={{ root: classes.expansionPanelDetails }}
            >
              <Grid wrap="nowrap" container direction="column">
                <Grid item>
                  <ComponentListing
                    template={template}
                    components={l.componentsList}
                    expanded={expanded}
                    editMode={editMode}
                    updateEvaluation={updateEvaluation}
                    handleSectionScoreChange={this.handleSectionScoreChange}
                  />
                </Grid>
                <Grid container item>
                  <Grid item xs={8} />
                  <Grid item xs={4}>
                    {l.componentsList.length ? (
                      <Typography variant="h6">
                        <strong>
                          {translate.t('sum')} {getScore(sectionScore, section)}
                        </strong>
                      </Typography>
                    ) : (
                      undefined
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state: ReducerState) => {
  const { jobRequirementEvaluations } = state;

  return {
    jobRequirementEvaluation: jobRequirementEvaluations.get(
      'jobRequirementEvaluation',
    ),
  };
};

const enhance = compose<Props, OwnProps>(
  connect(mapStateToProps),
  withStyles(sectionListingStyles),
);

export default enhance(SectionListing);

const GLOBAL: any = window;

type EmployeeStatus = 'NEW' | 'PENDING' | 'PASSIVE' | 'ACTIVE' | 'ENDED';

export default (
  searchTerm: string = '',
  statuses: EmployeeStatus[] = []
) => {
  if (!GLOBAL.jsonAllEmp) {
    return [];
  }

  const searchTermLC = searchTerm.toLocaleLowerCase();
  return Object.keys(GLOBAL.jsonAllEmp)
  .map((key: string) => GLOBAL.jsonAllEmp[key])
  .filter((item: any) => {
    return (
      (statuses.length === 0 || statuses.indexOf(item.fEmpStatus.toLocaleUpperCase()) > -1) &&
      (searchTerm === '' || (
        item.fEmpFirstName.toLocaleLowerCase().toLowerCase().includes(searchTermLC) ||
        item.fEmpLastName.toLocaleLowerCase().includes(searchTermLC) ||
        item.fEmpPosition.toLocaleLowerCase().includes(searchTermLC) ||
        item.fEmpPersonNumber.toLocaleLowerCase().includes(searchTermLC)
      ))
    );
  })
  .map((item: any) => ({
    id: item.fEmpId,
    code: item.fEmpPersonNumber,
    firstName: item.fEmpFirstName,
    lastName: item.fEmpLastName,
    position: item.fEmpPosition,
    status: item.fEmpStatus,
  }));
};

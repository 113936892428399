import React from 'react';
import { Menu, MenuItem, ListItemIcon, Icon, ListItemText, Divider, Typography } from '@material-ui/core';
import { CompanyTraining } from '../../types';
import translate from '@/app/utils/translate';

type OwnProps = {
  training: CompanyTraining;
  anchorEl?: HTMLElement;
  onClose: () => void;
  onEdit: () => void;
  onPublish: () => void;
  onUnpublish: () => void;
  onDuplicate: () => void;
  onDelete?: () => void;
  onOpenParticipants: () => void;
  onExportParticipants: () => void;
  onImportParticipants: () => void;
};
type Props = OwnProps;

const RowMenu = ({
  training,
  anchorEl,
  onClose,
  onEdit,
  onPublish,
  onUnpublish,
  onDuplicate,
  onDelete,
  onOpenParticipants,
  onExportParticipants,
  onImportParticipants,
}: Props) => {
  if (!anchorEl) {
    return null;
  }

  const onCloseWithAction = (onAction: () => void) => {
    onClose();
    onAction();
  };

  const publishMenuItem = !training.active ? (
    <MenuItem onClick={() => onCloseWithAction(onPublish)} data-testid="btn-training-table-publish">
      <ListItemIcon><Icon>check_circle</Icon></ListItemIcon>
      <ListItemText>{translate.t('label_publish')}</ListItemText>
    </MenuItem>
  ) : (
    <MenuItem onClick={() => onCloseWithAction(onUnpublish)}>
      <ListItemIcon><Icon>block</Icon></ListItemIcon>
      <ListItemText>{translate.t('label_unpublish')}</ListItemText>
    </MenuItem>
  );

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      <MenuItem onClick={() => onCloseWithAction(onEdit)}>
        <ListItemIcon><Icon>edit</Icon></ListItemIcon>
        <ListItemText>{translate.t('laEdit')}</ListItemText>
      </MenuItem>
      {publishMenuItem}
      <MenuItem onClick={() => onCloseWithAction(onDuplicate)}>
        <ListItemIcon><Icon>file_copy</Icon></ListItemIcon>
        <ListItemText>{translate.t('label_duplicate')}</ListItemText>
      </MenuItem>
      <MenuItem
        disabled={!onDelete}
        onClick={!onDelete ? undefined : () => onCloseWithAction(onDelete)}
        data-testid="btn-training-table-delete"  
      >
        <ListItemIcon><Icon>delete</Icon></ListItemIcon>
        <ListItemText>
          {translate.t('laDelete')}
          {!onDelete ? <Typography variant="caption">{translate.t('text_cannot_delete_when_participants')}</Typography> : null}
        </ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => onCloseWithAction(onOpenParticipants)}>
        <ListItemIcon><Icon>list</Icon></ListItemIcon>
        <ListItemText>{translate.t('label_show_participants')}</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => onCloseWithAction(onExportParticipants)}>
        <ListItemIcon><Icon>cloud_download</Icon></ListItemIcon>
        <ListItemText>{translate.t('label_export_participants')}</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => onCloseWithAction(onImportParticipants)}>
        <ListItemIcon><Icon>cloud_upload</Icon></ListItemIcon>
        <ListItemText>{translate.t('label_import_participants')}</ListItemText>
      </MenuItem>
    </Menu>
  );
};

export default RowMenu;

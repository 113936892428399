import { LanguagesType } from '@/app/redux/languages';
import { QuickLink, QuickLinkTranslation } from '@/app/redux/quickLinks';

export const findQuickLink = (
  id: string,
  quickLinks: QuickLink[]
): QuickLink | undefined => quickLinks.find(quickLink => quickLink.id === id);

export const getTranslation = (
  lang: string,
  translations: QuickLinkTranslation[],
  allowFallback?: boolean,
): QuickLinkTranslation | undefined => {
  const found = translations.find(tr => tr.language === lang);
  if (found) {
    return found;
  }

  if (allowFallback) {
    return translations[0];
  }

  return undefined;
};

export const mapTranslationToQuickLinkField = (
  quickLink: QuickLink,
  formVals: object,
  langs: LanguagesType[],
  fieldNameDivider: string,
): QuickLinkTranslation[] => {
  return langs.reduce(
    (acc: QuickLinkTranslation[], lang) => {
      const nameFieldName = fieldNameDivider ? `${lang.code}${fieldNameDivider}name` : 'name';
      const descriptionFieldName = fieldNameDivider ? `${lang.code}${fieldNameDivider}description` : 'description';
      const found = quickLink.translations.find((tr: QuickLinkTranslation) => tr.language === lang.code);
      const nameFieldVal = formVals[nameFieldName];
      const descriptionFieldVal = formVals[descriptionFieldName];

      if (found && nameFieldVal) {
        acc.push({
          ...found,
          name: nameFieldVal,
          description: descriptionFieldVal || quickLink.translations[0].description,
        });
      } else {
        if (nameFieldVal) {
          acc.push({
            ...quickLink.translations[0],
            language: lang.code,
            name: nameFieldVal,
            description: descriptionFieldVal || quickLink.translations[0].description,
          });
        }
      }

      return acc;
    },
    []
  );
};

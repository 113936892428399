import {
  Checkbox,
  FormControlLabel,
  Typography,
  withStyles,
} from '@material-ui/core';
import * as React from 'react';
import { compose } from 'recompose';
import {
  TemplateBaseComponentOptionType,
  TemplateBaseComponentType,
  TemplateComponentTypeKey,
  TemplateInputComponentPropsType,
} from '@/app/components/TemplateComponents/form/InputComponents';
import multipleChoiceComponentStyles from '@/app/components/TemplateComponents/form/InputComponents/MultipleChoiceComponent/multipleChoiceComponentStyles';
import { resolveComponentResponse } from '@/app/components/TemplateComponents/form/InputComponents/data';

export type MultipleChoiceComponentProps = TemplateInputComponentPropsType & {
  component: TemplateBaseComponentType & {
    type: TemplateComponentTypeKey.MULTIPLE_CHOICE;
    options: TemplateBaseComponentOptionType[];
  };
};

const MultipleChoiceComponent = (props: MultipleChoiceComponentProps) => {
  const {
    templateId,
    component,
    responses,
    editMode,
    onUpdate,
    classes,
  } = props;
  const existingResponse = resolveComponentResponse(component, responses, '');

  const handleChange = (event: any) => {
    if (!!onUpdate) {
      onUpdate(templateId, {
        componentId: component.id,
        response: [parseInt(event.target.value, 10)],
        type: component.type,
      });
    }
  };

  return (
    component.type === TemplateComponentTypeKey.MULTIPLE_CHOICE && (
      <div key={component.id + '_parent' + '_' + editMode}>
        <Typography variant="subtitle2" className={classes.typography}>
          {component.name}
        </Typography>
        {component.options.map((option: any, idx: number) => {
          return (
            <div
              className={classes.checkboxes}
              key={option.label + '-' + option.id}
              style={
                idx === component.options.length - 1
                  ? { paddingBottom: '40px' }
                  : {}
              }
            >
              <FormControlLabel
                control={
                  <Checkbox
                    id={`${component.id}-${option.id}`}
                    value={option.id.toString()}
                    color="primary"
                    disabled={!editMode}
                    onChange={handleChange}
                    checked={existingResponse.includes(option.id)}
                  />
                }
                label={option.label}
                className={classes.multiline}
              />
            </div>
          );
        })}
      </div>
    )
  );
};

const enhance = compose<any, any>(withStyles(multipleChoiceComponentStyles));

export default enhance(MultipleChoiceComponent);

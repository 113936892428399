import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const sectionListingStyles: StyleRulesCallback = () => ({
  container: {
    padding: '10px 20px'
  },
  expansionPanel: {
    '&$expanded': {
      margin: 0,
      minHeight: 48,
    },
  },
  expansionPanelContent: {
    '&$expanded': {
      margin: 0,
    },

    margin: 0,
  },
  expanded: {},
  iconButton: {
    height: '44px !important',
    marginLeft: -12,
  },
  expansionPanelDetails: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
  },
});

export default sectionListingStyles;

import { StyleRulesCallback } from '@material-ui/core';

export type TransactionsListStyleKeys = 'root';

export const transactionsListStyles: StyleRulesCallback<TransactionsListStyleKeys> = (theme) => ({
    root: {
        padding: theme.spacing.unit / 2,
        overflow: 'auto',

        /**
         * Make sure the columns will not get
         * resized when they will not fit.
         */
        '&>div>div': {
            flexShrink: 0,
        },
    },
});

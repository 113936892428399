import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const style: StyleRulesCallback = (theme: Theme) => ({
  toolbar: {
    paddingRight: theme.spacing.unit,
  },
  actions: {
    flex: '0 0 auto',
    textAlign: 'right',
  },
  title: {
    flex: '1 1 100%',
    display: 'flex',
  },
  root: {
    textAlign: 'center',
    width: '97%',
    marginTop: '40px',
    marginLeft: '15px',
  },
  select: {
    width: '100px',
  },
  button: {
    float: 'right',
    marginTop: '24px',
  },
  paper: {
    textAlign: 'center',
    width: '1250px',
    marginTop: '40px',
    marginLeft: '15px',
  },
  tableHead: {
    borderBottom: '2px solid',
  },
  bold: {
    fontWeight: 600,
  },
  runRetentionBtn: {
    textTransform: 'none',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
});

export default style;

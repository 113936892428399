import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const editTemplateStyle: StyleRulesCallback = () => ({
  root: {
    textAlign: 'center',
    width: '60%',
    marginTop: '40px',
    marginLeft: '22%',
    maxHeight: '90%',
    overflow: 'auto',
  },
  warning: {
    paddingTop: '20px',
  },
  noSections: {
    textAlign: 'left',
    paddingLeft: '25px',
  },
  buttonWrapper: {
    textAlign: 'right',
    marginRight: '20px',
  },
  addSectionButton: {
    marginTop: '25px',
    marginBottom: '25px',
    paddingRight: '25px',
  },
  buttonIcon: {
    marginRight: '10px',
  },
  sortButton: {
    marginTop: '25px',
    marginBottom: '25px',
    paddingRight: '25px',
    marginLeft: '5px',
  },
});

export default editTemplateStyle;

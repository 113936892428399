import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Grid, Typography, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { orderElementsByIndex } from '../../../../../helpers';
import { ReducerState } from '@/app/redux/store';
import translate from '@/app/utils/translate';
import componentListingStyles from './componentListingStyles';
import {
  TemplateStaticComponentProps,
  TemplateInputComponentPropsType,
  LinkComponent,
  SubtitleComponent,
  TemplateComponentTypeKey,
  TextComponent,
} from '@/app/components/TemplateComponents/form/InputComponents';
import { SCORE } from '@/app/components/JobEvaluation/constants';
import Loading from '@/app/components/Loading/Loading';
import { Enum } from '@/app/redux/enums';
import {
  ActiveTemplate,
  ComponentItem,
  GroupEnums,
  JobReqEvaluationResponse,
  ScoreGroup,
} from '@/app/components/JobEvaluation/types';
import ScoreComponent from './ScoreComponent/ScoreComponent';

type MapStateToProps = {
  jobRequirementEvaluation: any;
  scoreGroups: ScoreGroup[];
  groupEnums: GroupEnums;
};

type IncomingProps = {
  components: ComponentItem[];
  expanded: string;
  template: ActiveTemplate;
  editMode: boolean;
  updateEvaluation: (
    templateId: string,
    responses: JobReqEvaluationResponse,
  ) => Promise<void>;
  handleSectionScoreChange: (componentId: string, newScore: number) => void;
};

type PropsType = MapStateToProps &
  IncomingProps &
  WithStyles<typeof componentListingStyles>;

class ComponentListing extends React.PureComponent<PropsType> {
  generateComponentKey = (component: any, editMode: boolean) =>
    component.type + '-' + component.id + '-' + editMode;

  render() {
    const {
      classes,
      components,
      editMode,
      jobRequirementEvaluation,
      handleSectionScoreChange,
      scoreGroups,
    } = this.props;

    const hasComponentsOfTypes = (types: string[]): boolean =>
      !!components.filter(c => types.includes(c.type)).length;
    const isScoreDependenciesLoading = (): boolean =>
      hasComponentsOfTypes([SCORE]) ? !scoreGroups.length : false;
    if (isScoreDependenciesLoading() || !jobRequirementEvaluation.responses) {
      return <Loading key="loading" />;
    }
    const orderedComponents: ComponentItem[] = orderElementsByIndex(
      components,
    ).map((component: any) => {
      if (component.type === SCORE) {
        const enumScoreGroup = scoreGroups.find(
          scoreGroup => scoreGroup.id === component.enumScoreGroupId,
        );
        const updatedComp = { ...component, enumScoreGroup };

        if (!component.options) {
          return updatedComp;
        }

        return {
          ...updatedComp,
          options: orderElementsByIndex(component.options),
        };
      } else {
        return component;
      }
    });

    if (!orderedComponents.length) {
      return <Typography>{translate.t('no_components')}</Typography>;
    }

    return (
      <Grid container direction="column" className={classes.fullWidth}>
        {orderedComponents.map((component, index) => {
          const staticComponentProps: TemplateStaticComponentProps = {
            key: this.generateComponentKey(component, editMode),
            component: component,
          };
          const inputComponentProps: TemplateInputComponentPropsType = {
            ...staticComponentProps,
            templateId: this.props.template.id,
            responses: jobRequirementEvaluation.responses,
            onUpdate: this.props.updateEvaluation,
            editMode: editMode,
          };

          switch (component.type) {
            case SCORE:
              return (
                <ScoreComponent
                  groupEnums={this.props.groupEnums}
                  handleSectionScoreChange={handleSectionScoreChange}
                  {...inputComponentProps}
                />
              );
            case TemplateComponentTypeKey.LINK:
              return <LinkComponent {...inputComponentProps} />;
            case TemplateComponentTypeKey.SUBTITLE:
              return <SubtitleComponent {...inputComponentProps} />;
            case TemplateComponentTypeKey.TEXT:
              return <TextComponent {...inputComponentProps} />;
            default:
              return <h2 key={index}>{translate.t('laComponentError')}</h2>;
          }
        })}
      </Grid>
    );
  }
}

const mapStateToProps = (state: ReducerState) => {
  const { jobRequirementEvaluations, enums } = state;
  const enumData = enums.get('allEnums').toJS();
  const groupEnums = Object.keys(enumData)
    .filter(enumItemKey => enumItemKey.indexOf('__USER__DEV_') === 0)
    .map(enumItemKey => enumData[enumItemKey])
    .reduce((acc: { [enumGroup: string]: string[] }, enumGroup: Enum[]) => {
      if (!enumGroup.length) {
        return acc;
      }

      const enumCodes = enumGroup.map(enumItem => {
        return {
          isActive: enumItem.isActive,
          code: enumItem.code,
          name: enumItem.name,
        };
      });
      return {
        ...acc,
        [enumGroup[0].groupName]: enumCodes,
      };
    }, {});

  return {
    groupEnums,
    jobRequirementEvaluation: jobRequirementEvaluations.get(
      'jobRequirementEvaluation',
    ),
    scoreGroups: jobRequirementEvaluations.get('scoreGroups'),
  };
};

const enhance = compose<PropsType, IncomingProps>(
  connect(mapStateToProps),
  withStyles(componentListingStyles),
);

export default enhance(ComponentListing);

import * as React from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import translate from '@/app/utils/translate';
import { Tools } from '@/app/components/EnhancedTable/EnhancedTableToolbar/EnhancedTableToolbar';
import { compose, withState } from 'recompose';

type OwnProps = {
  id: string;
  tools: Tools;
  deleteScoreGroup: (id: string) => void;
  handleEditScoreGroup: (id: string) => void;
};

type PropsType = OwnProps & {
  anchorEl: HTMLElement;
  setAnchorEl: (target: EventTarget) => void;
};

const SideRowMenu: React.FunctionComponent<PropsType> = (props) => {
  const handleOpenMenu = (event: React.MouseEvent) => {
    event.stopPropagation(); // stop trigger the row checkbox selection
    event.preventDefault();
    props.setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (event: React.MouseEvent) => {
    event.stopPropagation(); // stop trigger the row checkbox selection
    event.preventDefault();
    props.setAnchorEl(null);
  };

  const handleScoreGroupDeletion = (event: React.MouseEvent) => {
    props.deleteScoreGroup(props.id);
    handleCloseMenu(event);
  };

  const { tools, anchorEl } = props;

  const { showEdit = true, showDelete = true } = tools;

  const openMenu = Boolean(anchorEl);

  const editButton = showEdit ? (
    <MenuItem
      onClick={(event) => {
        props.handleEditScoreGroup(props.id);
        handleCloseMenu(event);
      }}
    >
      <ListItemIcon>
        <EditIcon color="primary" />
      </ListItemIcon>
      <ListItemText inset primary={translate.t('laEdit')} />
    </MenuItem>
  ) : null;
  const deleteButton = showDelete ? (
    <MenuItem onClick={handleScoreGroupDeletion}>
      <ListItemIcon>
        <DeleteIcon />
      </ListItemIcon>
      <ListItemText inset primary={translate.t('laDelete')} />
    </MenuItem>
  ) : null;

  if (!editButton && !deleteButton) {
    return null;
  }

  return (
    <>
      <IconButton
        aria-label={translate.t('laMore')}
        aria-owns={openMenu ? 'long-menu' : null}
        aria-haspopup="true"
        onClick={handleOpenMenu}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
      >
        {editButton}
        {deleteButton}
      </Menu>
    </>
  );
};

const enhance = compose<PropsType, OwnProps>(
  withState('anchorEl', 'setAnchorEl', null),
);

export default enhance(SideRowMenu);

import React, { useState, useContext, useEffect } from 'react';

import { Typography, Toolbar, Button, WithStyles, withStyles, Icon } from '@material-ui/core';

import RegistrationsTable from './trainingsView/RegistrationsTable';
import HistoryTable from './trainingsView/HistoryTable';
import translate from '@/app/utils/translate';

import styles from './trainingsView/styles';
import moment from 'moment';
import EmployeeTrainingUpdateDialog from './trainingsView/EmployeeTrainingUpdateDialog';
import { getTrainingGroupName } from '../helpers';
import { EmployeeTraining, TrainingType } from '../types';
import { getSelectedEmpId, getLoggedUserId, isBnTrainingCalendar, getSelectedEmpContractsData } from '@/old/utils/helper';
import Context from '../context/trainingsContext';
import { TrainingCalendarPanelDialog } from './';
import Instructions from '../../Instructions/Instructions';

type OwnProps = {};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const TrainingsView = ({ classes }: Props) => {
  const employeeId = getSelectedEmpId() || getLoggedUserId();

  const checkIfOneOfLoggedUserEmployments = (empId: string) => {
    const loggedUserId = getLoggedUserId();
    if (empId === loggedUserId) {
      return true;
    }

    const selectedEmpOverlappingIds = getSelectedEmpContractsData().contracts.map((c: any) => c.employeeId);

    return selectedEmpOverlappingIds.includes(loggedUserId);
  };

  const isLoggedUserData = checkIfOneOfLoggedUserEmployments(employeeId);

  const {
    getOncomingEmployeeTrainings,
    getEmployeeTrainingHistory,
  } = useContext(Context);

  const [trainingCalendarDialogOpen, setTrainingCalendarDialogOpen] = useState(false);

  useEffect(
    () => {
      getOncomingEmployeeTrainings(employeeId);
      getEmployeeTrainingHistory(employeeId);
    },
    [employeeId]
  );

  const emptyTrainingValues: EmployeeTraining = {
    employeeId,
    trainingGroup: null,
    name: null,
    trainingClass: null,
    provider: '',
    startDate: moment(),
    endDate: null,
    hours: null,
    expires: null,
    info: '',
    attachmentId: -1,
    attachmentName: '',
  };

  const [addTrainingGroup, setAddTrainingGroup] = useState<TrainingType>();
  const [selectedTraining, setSelectedTraining] = useState();

  const getNewTrainingValues = () => {
    return ({
      ...emptyTrainingValues,
      trainingGroup: { value: addTrainingGroup, label: getTrainingGroupName(addTrainingGroup) },
      includeInTrainingCompensation: false,
    });
  };

  const employeeTrainingDialog = !addTrainingGroup && !selectedTraining ? null : (
    <EmployeeTrainingUpdateDialog
      open={true}
      initialValues={!selectedTraining ? getNewTrainingValues() : selectedTraining}
      onClose={() => {
        setAddTrainingGroup(undefined);
        setSelectedTraining(undefined);
      }}
      isLoggedUserData={isLoggedUserData}
    />
  );

  const trainingCalendarDialog = !trainingCalendarDialogOpen ? null : (
    <TrainingCalendarPanelDialog
      open={trainingCalendarDialogOpen}
      onClose={() => setTrainingCalendarDialogOpen(false)}
    />
  );

  return (
    <>
      {employeeTrainingDialog}
      {trainingCalendarDialog}

      <Toolbar
        disableGutters={true}
        variant="dense"
        className={classes.tableToolbar}
      >
        <Typography variant="h6" className={classes.grow}>
          {translate.t('title_oncoming_trainings')}
          <Instructions type={'INSTRUCTIONS_EDU_TRAINING'} />
        </Typography>
        {isBnTrainingCalendar() ? (
          <Button
            data-testid="btn-training-calendar"
            color="primary"
            size="small"
            onClick={() => setTrainingCalendarDialogOpen(true)}
          >
            <Icon className={classes.toolbarIcon} fontSize="small">
              calendar_today
            </Icon>
            {translate.t('training_calendar')}
          </Button>
        ) : (
          <></>
        )}
      </Toolbar>
      <RegistrationsTable />

      <Toolbar disableGutters={true} variant="dense" className={classes.tableToolbar}>
        <Typography variant="h6" className={classes.grow}>{translate.t('title_training_history')}</Typography>
        <Button
          data-testid="btn-add-training"
          color="primary"
          size="small"
          onClick={() => setAddTrainingGroup(isLoggedUserData ? TrainingType.EXTERNAL : TrainingType.INTERNAL)}
        >
          <Icon className={classes.toolbarIcon}>add_circle_outline</Icon>
          {translate.t('laAdd')}
        </Button>
      </Toolbar>
      <HistoryTable isLoggedUserData={isLoggedUserData} />
    </>
  );
};

export default withStyles(styles)(TrainingsView);

import React, { useEffect } from 'react';
import APIs from '@/app/api/commonAPIs';
import Service from '@/app/utils/service';
import { ActivityLogging } from './logging';

const { dispatchTabChangeEvent, initLogger } = ActivityLogging;
const UserActivityLogging = () => {
  useEffect(() => {
    Service.get(APIs.companyName, (resp: any) => {
      if (!resp.fComConfig || !resp.fComConfig.miscellaneousConfig) {
        return;
      }

      initLogger(resp.fComConfig.miscellaneousConfig.fComEnableLogginActivity);
    }, () => '');
  }, []);
  
  return <></>;
};

export { dispatchTabChangeEvent, initLogger };
export default UserActivityLogging;

import { useEffect, useState } from 'react';

export type Role = {
  code: string;
  name: string;
};

const GLOBAL: any = window;
const translations = GLOBAL.jsonLa;

export const useRoles = () => {
  const [data, setData] = useState([] as Role[]);
  const [loading, setLoading] = useState(false);
  const error = false; // should never error

  useEffect(() => {
    setLoading(true);
    setData([
      { code: 'EMPLOYEE', name: GLOBAL.jsonLa.laEmployee },
      { code: 'HR_STAFF', name: GLOBAL.jsonLa.laHR },
      { code: 'HR_ADMIN', name: GLOBAL.jsonLa.laHRAdmin },
      { code: 'HR_ADMIN_LIMITED', name: GLOBAL.jsonLa.laHRAdminLimited },
      { code: 'HR_DEV', name: GLOBAL.jsonLa.laHRDev },
      { code: 'HR_PEOP', name: GLOBAL.jsonLa.laHRPeople },
      { code: 'HR_PEOP_DEV', name: GLOBAL.jsonLa.laHRPeopleDev },
      { code: 'MANAGER', name: GLOBAL.jsonLa.laManager },
      { code: 'ALT_MANAGER', name: GLOBAL.jsonLa.laAltManager },
      { code: 'DEPUTY_MANAGER', name: GLOBAL.jsonLa.laDeputyManager },
      { code: 'IT_ADMIN', name: GLOBAL.jsonLa.laITAdmin },
    ]);
    setLoading(false);
  }, [translations]);

  return { data, loading, error };
};

export default useRoles;

import React from 'react';
import { Avatar } from '@material-ui/core';
import API from '@/app/api/internalAPIs';
import { getEmpPicId } from '@/old/utils/helper';

type OwnProps = {
  employeeId: number;
};
type InnerProps = {};
type Props = OwnProps & InnerProps;

const EmployeeAvatar = ({
  employeeId
}: Props) => {
  const imageUrl = API.employeeImageUrl(getEmpPicId(employeeId));

  return (
    <Avatar src={imageUrl} />
  );
};

export default EmployeeAvatar;

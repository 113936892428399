import { Grid, Typography, withStyles } from '@material-ui/core';
import * as React from 'react';
import { compose } from 'recompose';
import {
  TemplateBaseComponentType,
  TemplateComponentTypeKey,
  TemplateInputComponentPropsType,
} from '@/app/components/TemplateComponents/form/InputComponents';
import dateComponentStyles from '@/app/components/TemplateComponents/form/InputComponents/DateComponent/dateComponentStyles';
import * as moment from 'moment';
import LocalizedDatePicker from '@/app/components/Pickers/LocalizedDatePicker';
import { resolveComponentResponse } from '@/app/components/TemplateComponents/form/InputComponents/data';

export type DateComponentProps = TemplateInputComponentPropsType & {
  component: TemplateBaseComponentType & {
    type: TemplateComponentTypeKey.DATE;
  };
};

const DateComponent = (props: DateComponentProps) => {
  const {
    templateId,
    component,
    responses,
    editMode,
    onUpdate,
    classes,
  } = props;
  const existingResponse = resolveComponentResponse(component, responses, null);

  const handleChange = (value: string) => {
    if (!!onUpdate) {
      onUpdate(templateId, {
        componentId: component.id,
        response: value,
        type: component.type,
      });
    }
  };

  return (
    component.type === TemplateComponentTypeKey.DATE && (
      <Grid
        container={true}
        alignItems="flex-end"
        key={component.type + '-' + component.id + '-' + editMode}
      >
        <Grid
          item={true}
          className={`${classes.controlContainer} ${classes.controlContainerMd}`}
        >
          <Typography variant="subtitle2" className={classes.typography}>
            {component.name}
          </Typography>
        </Grid>
        <Grid
          item={true}
          className={`${classes.controlContainer} ${classes.controlContainerLg} ${classes.constrolContainerSpacing}`}
        >
          <LocalizedDatePicker
            value={existingResponse}
            allowKeyboardControl={true}
            onChange={(date: moment.Moment) =>
              handleChange(date.format('YYYY-MM-DD'))
            }
            autoOk={true}
            disabled={!editMode}
          />
        </Grid>
      </Grid>
    )
  );
};

const enhance = compose<any, any>(withStyles(dateComponentStyles));

export default enhance(DateComponent);

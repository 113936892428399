import * as React from 'react';
import { connect } from 'react-redux';
import { fetchCompanyInfo } from '@/app/redux/companyInfo';
import { ReducerState } from '@/app/redux/store';

const FetchCompanyInfoHOC = (PassedComponent: React.ComponentType) => {
  class FetchCompanyInfo extends React.Component<any> {
    componentDidMount() {
      if (!this.props.companyName && !this.props.fetching) {
        this.props.fetchCompanyInfo();
      }
    }

    render () {
      if (!this.props.companyName) {
        return null;
      }

      return <PassedComponent {...this.props} />;
    }
  }

  const mapStateToProps = (state: ReducerState) => ({
    fetching: state.companyInfo.get('isFetching'),
    companyName: state.companyInfo.get('name'),
  });

  return connect(
    mapStateToProps,
    {
      fetchCompanyInfo,
    }
  )(FetchCompanyInfo);
};

export default FetchCompanyInfoHOC;

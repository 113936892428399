import React, { createContext, useReducer } from 'react';

export default (reducer: any, actions: any, initialState: any) => {
  const Context = createContext(undefined);

  const Provider = ({ children }: any) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const boundActions = {};
    Object.keys(actions).forEach(key => {
      boundActions[key] = actions[key](dispatch);
    });

    return (
      <Context.Provider value={{ state, ...boundActions }}>
        {children}
      </Context.Provider>
    );
  };

  return { Context, Provider };
};

import { StyleRulesCallback, Theme } from '@material-ui/core';

const styles: StyleRulesCallback = (theme: Theme) => ({
  paper: {
    padding: theme.spacing.unit,
    paddingTop: 0,
    minWidth: 280,
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  textButton: {
    textTransform: 'none',
  },
});

export default styles;

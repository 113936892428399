import { StyleRulesCallback, Theme } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles/colorManipulator';

export type DailySummaryStyleKeys =
    | 'root'
    | 'header'
    | 'toggle'
    | 'body'
    | 'content'
    | 'formErrors'
    | 'actions'
    | 'button'
    | 'buttonIcon'
    | 'expanded'
    | 'publicHoliday'
    | 'approvedVacation';

const toggleInnerWidth = 48;
export const getToggleOuterWidth = (theme: Theme) => {
    return toggleInnerWidth + 2 * theme.spacing.unit;
};

export const dailySummaryStyles: StyleRulesCallback<DailySummaryStyleKeys> = (theme) => ({
    root: {
        '&:not(:last-child)': {
            marginBottom: 2 * theme.spacing.unit,
        },
    },
    header: {
        display: 'flex',
        margin: 0,
        paddingRight: theme.spacing.unit,
        transition: theme.transitions.create('padding'),
    },
    toggle: {
        width: toggleInnerWidth,
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,

        '& svg': {
            transition: theme.transitions.create(
                'transform',
                { duration: theme.transitions.duration.shortest }
            ),
        },
    },
    body: {
        maxHeight: 0,
        overflow: 'hidden',
        transition: theme.transitions.create('max-height'),
    },
    content: {
        maxHeight: 500,
        overflow: 'auto',
        paddingTop: theme.spacing.unit,
        paddingBottom: 3 * theme.spacing.unit,
        paddingLeft: getToggleOuterWidth(theme),
        paddingRight: theme.spacing.unit,
    },
    formErrors: {
        marginTop: 2 * theme.spacing.unit,
    },
    actions: {
        marginTop: 2 * theme.spacing.unit,

        '&>button': {
            marginRight: theme.spacing.unit,
        },
    },
    button: {
        // Override UI Legacy styles
        fontSize: '0.8125rem !important',
    },
    buttonIcon: {
        marginRight: theme.spacing.unit,
        fontSize: 20,
    },
    expanded: {
        '& $header': {
            paddingTop: theme.spacing.unit,
            paddingBottom: theme.spacing.unit,
        },
        '& $toggle svg': {
            transform: 'rotate(180deg)',
        },
        '& $body': {
            maxHeight: 500,
        },
    },
    publicHoliday: {
      backgroundColor: lighten('#FF7F50', 0.9),
      border: '1px dashed'
    },
    approvedVacation: {
      backgroundColor: lighten('#006400', 0.9),
      border: '1px dashed'
    },
});

import moment from 'moment';

export type OptionType = {
  value: string | number;
  label: string;
  isFixed?: boolean;
};

type ParticipantApprovalsSummary = {
  waiting: number;
  approved: number;
  rejected: number;
  cancelled: number;
};

type ParticipantSeatingsSummary = {
  guaranteedSeat: number;
  seat: number;  // Does not includes guaranteed seats!!
  waitingSeat: number;
  noSeat: number;
};

type ParticipantCompletionsSummary = {
  completed: number;
  completedPartly: number;
  didNotShowUp: number;
  didNotComplete: number;
  pending: number;
};

export type ParticipantSummary = {
  trainingId: number;
  totalRegistrants: number; // All registered
  approvals: ParticipantApprovalsSummary;
  seatings: ParticipantSeatingsSummary;
  completions: ParticipantCompletionsSummary;
};

export type CompanyTraining = {
  id?: number,
  name: OptionType,
  description: string,
  trainingGroup: OptionType,
  trainingClass: OptionType,
  provider: string,
  responsiblePersons?: OptionType[],
  language: OptionType,
  startDate: moment.Moment,
  endDate?: moment.Moment,
  lastDateOfCancellation?: moment.Moment,
  lastDateForRegistration?: moment.Moment,
  startTime?: moment.Moment,
  endTime?: moment.Moment,
  timeZone?: OptionType,
  hours?: number,
  unitAudience?: OptionType[],
  roleAudience?: OptionType[],
  countryAudience?: OptionType[],
  seats?: number,
  fee?: number,
  currency?: OptionType,
  city?: string;
  room?: string;
  contactPerson?: string;
  teacherName?: string;
  allowWaitingSeats?: boolean,
  useOnlineRegistration?: boolean,
  requireManagerApproval?: boolean,
  isPublic?: boolean,
  includeInTrainingCompensation?: boolean,
  expires?: moment.Moment,
  active?: boolean,
  summary?: TrainingParticipantSummary,
};

export type IntCompanyTraining = CompanyTraining & {
  int: {
    description: {
      [lng: string]: string;
    };
  };
};

export enum TrainingType {
  INTERNAL = 'INTERNAL_TRAINING_TYPE',
  EXTERNAL = 'EXTERNAL_TRAINING_TYPE',
  OTHER = 'OTHER_TRAINING_TYPE',
}

export enum APPROVAL_STATUS {
  WAITING = 'WAITING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
}
export type ApprovalStatus = {
  status: {
    value: APPROVAL_STATUS;
    label: string;
  },
  statusBy?: number;
  statusOn?: moment.Moment;
};

export enum COMPLETION_STATUS {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  COMPLETED_PARTLY = 'PARTICIPATED_PARTLY',
  DID_NOT_SHOW_UP = 'DID_NOT_SHOW_UP',
  DID_NOT_COMPLETE = 'DID_NOT_COMPLETE',
  // DID_NOT_GET_SEAT = 'DID_NOT_GET_SEAT',
}

export type CompletionStatus = {
  value: COMPLETION_STATUS,
  label: string,
};

export enum SEAT_STATUS {
  HAS_SEAT = 'HAS_SEAT',
  WAITING_SEAT = 'WAITING_SEAT',
  NO_SEAT = 'NO_SEAT',
}

export enum REGISTRATION_STATUS {
  NOT_REGISTERED = 'NOT_REGISTERED',
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
  CANCELLED = 'CANCELLED',
  REJECTED = 'REJECTED',
  WAITING_FOR_SEAT = 'WAITING_FOR_SEAT',
  NO_SEAT = 'NO_SEAT',
  ACCEPTED = 'ACCEPTED',
}

export enum CompanyTrainingParticipantType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export type CompanyTrainingInternalParticipant = {
  id: number;
  employeeId: number;
  updateTrainingHistory?: boolean;
  includeInTrainingCompensation?: boolean;
  employeeTrainingId?: number;
  lastEmailSubject?: string;
  costCenter: number;
  type: CompanyTrainingParticipantType.INTERNAL;
};

export type CompanyTrainingExternalParticipant = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  givenNames: string;
  phone: string;
  lastEmailSubject?: string;
  type: CompanyTrainingParticipantType.EXTERNAL;
};

export type CompanyTrainingParticipant =
  | CompanyTrainingInternalParticipant
  | CompanyTrainingExternalParticipant;

export type CompanyTrainingParticipantRegistration = {
  id: string;
  trainingId: number;
  participant: CompanyTrainingParticipant;
  seated?: boolean;
  seatStatus?: SEAT_STATUS;
  position?: number;
  approvalStatus?: ApprovalStatus;
  hours?: number;
  completionStatus?: CompletionStatus;
  expires?: moment.Moment;
  message?: string;
  registeredTime: moment.Moment;
  selected?: boolean;
};

export type ApprovalAction =
  'CHANGE_ALL_SEATERS_AS_APPROVED' |
  'CHANGE_ALL_AS_APPROVED';

export type HoursAction =
  'CHANGE_ALL_HOURS_TO_DEFAULT' |
  'CHANGE_ALL_HOURS_TO';

export type CompletionAction =
  'CHANGE_ALL_SEATERS_TO_COMPLETED' |
  'CHANGE_ALL_TO_DID_NOT_COMPLETE';

export type ExpiresAction =
  'CHANGE_ALL_COMPLETED_TO_DEFAULT' |
  'CHANGE_ALL_COMPLETED_TO';

export type UpdateTrainingHistoryAction =
  'MARK_ALL_COMPLETED_TO_UPDATE_HISTORY' |
  'UNMARK_ALL_COMPLETED_FROM_UPDATING_HISTORY';

export type EmployeeTraining = {
  id?: number;
  trainingGroup: OptionType;
  employeeId: number;
  name: OptionType;
  provider?: string;
  trainingClass?: OptionType;
  startDate: moment.Moment;
  endDate?: moment.Moment;
  hours?: number;
  info?: string;
  attachmentId: number;
  attachmentName: string;
  attachmentFile?: File;
  expires?: moment.Moment;
  includeInTrainingCompensation?: boolean;
  companyTrainingFK?: number
};

export type ExportedParticipant = CompanyTrainingParticipantRegistration & {
  employeeId?: number;
  firstName: string;
  lastName: string;
  email: string;
  mobile?: string;
  phone: string;
  employeePosition?: string;
  unit?: string;
  guaranteedSeatText: string;
  expiresText: string;
};

export type ExportedParticipantData = ExportedParticipant[];

export type TrainingParticipantSummary = {
  participants: number;
  waiting: number;
  allowWaitingSeats: boolean;
  seats?: number;
};

import { getActiveEnumNameByCode } from '@/app/utils/helper';
import moment from 'moment';
import { getCountryName } from '../Trainings/helpers';

import {
  WorktimeReportResponseItem,
  WorktimeReportEntry,
  CustomDataItem,
  Dimensions,
} from './types';
import { CustomField, CustomFieldType } from './worktimeReportRedux';

export const mapReportForUI = (
  worktimeReportResponseItems: WorktimeReportResponseItem[],
  allEnums: any,
  customEntities: CustomField[],
  timesheetReportingTypes: CustomField[],
  employeePositions: CustomField[],
): WorktimeReportEntry[] => {
  const reportItemsWithTimeBlocks: WorktimeReportResponseItem[] = worktimeReportResponseItems.filter(
    worktimeReportResponseItem =>
      worktimeReportResponseItem.fEmpTimeBlocks &&
      worktimeReportResponseItem.fEmpTimeBlocks.length > 0,
  );

  return reportItemsWithTimeBlocks.reduce(
    (
      worktimeReportEntriesAcc: WorktimeReportEntry[],
      currentWorktimeReportResponseItem,
    ) => [
      ...worktimeReportEntriesAcc,
      ...currentWorktimeReportResponseItem.fEmpTimeBlocks.map(timeBlock => {
        const employeePositionEnumValue = employeePositions.find(
          e => e.code === currentWorktimeReportResponseItem.fEmpPosition,
        );
        const reportingTypeEnumValue = timesheetReportingTypes.find(
          e => e.code === timeBlock.reportingType,
        );

        const entries = {
          empId: currentWorktimeReportResponseItem.fEmpId,
          empFirstName: currentWorktimeReportResponseItem.fEmpFirstName,
          empLastName: currentWorktimeReportResponseItem.fEmpLastName,
          empNumber: currentWorktimeReportResponseItem.fEmpNumber,
          empPosition: employeePositionEnumValue
            ? employeePositionEnumValue.name
            : '',
          empUnitName: currentWorktimeReportResponseItem.fEmpUnitName,
          empUnitNumber: currentWorktimeReportResponseItem.fEmpUnitNumber,
          country: getCountryName(
            currentWorktimeReportResponseItem.fEmpOfficeCountry,
          ),
          legalEntityName:
            currentWorktimeReportResponseItem.fEmpLegalEntityName,
          legalEntityCode:
            currentWorktimeReportResponseItem.fEmpLegalEntityCode,
          empPersonnelGroup1:
            currentWorktimeReportResponseItem.fEmpPersonnelGroups &&
            getActiveEnumNameByCode(
              'PERSONNEL_GROUP_1',
              currentWorktimeReportResponseItem.fEmpPersonnelGroups[0],
            ),
          empPersonnelGroup2:
            currentWorktimeReportResponseItem.fEmpPersonnelGroups &&
            getActiveEnumNameByCode(
              'PERSONNEL_GROUP_2',
              currentWorktimeReportResponseItem.fEmpPersonnelGroups[1],
            ),
          empPersonnelGroup3:
            currentWorktimeReportResponseItem.fEmpPersonnelGroups &&
            getActiveEnumNameByCode(
              'PERSONNEL_GROUP_3',
              currentWorktimeReportResponseItem.fEmpPersonnelGroups[2],
            ),
          empPersonnelGroup4:
            currentWorktimeReportResponseItem.fEmpPersonnelGroups &&
            getActiveEnumNameByCode(
              'PERSONNEL_GROUP_4',
              currentWorktimeReportResponseItem.fEmpPersonnelGroups[3],
            ),
          empPersonnelGroup5:
            currentWorktimeReportResponseItem.fEmpPersonnelGroups &&
            getActiveEnumNameByCode(
              'PERSONNEL_GROUP_5',
              currentWorktimeReportResponseItem.fEmpPersonnelGroups[4],
            ),
          date: timeBlock.entryDate,
          reportingType: reportingTypeEnumValue
            ? reportingTypeEnumValue.name
            : '',
          start: timeBlock.timeFrom,
          end: timeBlock.timeTo,
          time: timeBlock.hours,
          fEmpHasAccount: currentWorktimeReportResponseItem.fEmpHasAccount,
        };

        timeBlock.customData.forEach(customField => {
          const customFieldEnum = customEntities.find(
            c => c.code === customField.fCustomDataFieldName,
          );

          entries[customField.fCustomDataFieldName] = getCustomFieldValue(
            customField,
            customFieldEnum,
            allEnums,
          );
        });

        return entries;
      }),
    ],
    [],
  );
};

const getCustomFieldValue = (
  customField: CustomDataItem,
  customDataEnum: CustomField,
  allEnums: any,
): string => {
  switch (customDataEnum.inputType.value) {
    case CustomFieldType.BOOLEAN: {
      return customField.fCustomDataFieldValue[0];
    }
    case CustomFieldType.STRING: {
      return customField.fCustomDataFieldValue[0];
    }
    case CustomFieldType.NUMBER: {
      return customField.fCustomDataFieldValue[0];
    }
    case CustomFieldType.TEXT: {
      return customField.fCustomDataFieldValue[0];
    }
    case CustomFieldType.DATE: {
      return moment(customField.fCustomDataFieldValue[0]).format('L');
    }
    case CustomFieldType.ENUM: {
      const customDataSubItemEnum: CustomField[] = allEnums.get(
        `__CUSTOMDATA__TIMESHEET__${customField.fCustomDataFieldName}`,
      );

      return customDataSubItemEnum && !!customDataSubItemEnum.length
        ? customField.fCustomDataFieldValue
            .map(c => {
              const customDataSubItem = customDataSubItemEnum.find(
                s => s.code === c,
              );

              if (customDataSubItem) {
                return customDataSubItem.name;
              }

              return c;
            })
            .join()
        : customField.fCustomDataFieldValue.join();
    }
    case CustomFieldType.MULTISELECT: {
      const customDataSubItemEnum: CustomField[] = allEnums.get(
        `__CUSTOMDATA__TIMESHEET__${customField.fCustomDataFieldName}`,
      );

      return customDataSubItemEnum && !!customDataSubItemEnum.length
        ? customField.fCustomDataFieldValue
            .map(c => {
              const customDataSubItem = customDataSubItemEnum.find(
                s => s.code === c,
              );

              if (customDataSubItem) {
                return customDataSubItem.name;
              }

              return c;
            })
            .join()
        : customField.fCustomDataFieldValue.join();
    }
    default: {
      return customField.fCustomDataFieldValue[0];
    }
  }
};

export const getCustomDataColumns = (
  timeSheetReportingTypeEnums: CustomField[],
  dimensions: Dimensions,
): any[] => {
  return timeSheetReportingTypeEnums.map(t => ({
    field: t.code,
    show:
      !dimensions.customEntities.length ||
      dimensions.customEntities.find(
        customEntity => customEntity.value === t.code,
      ),
    title: t.name,
  }));
};

export const sumConverter = (sumInH: number) =>
  `${sumInH / 60 < 10 && sumInH / 60 > -10 ? '0' : ''}${
    (sumInH / 60).toString().split('.')[0]
  }:${sumInH % 60 < 10 && sumInH % 60 > -10 ? '0' : ''}${sumInH % 60}`;

export const convertToM = (h: string) =>
  parseInt(h.split(':')[0], 10) * 60 + parseInt(h.split(':')[1], 10);

export const mapReportForUIDimensions = (
  worktimeReportResponseItems: WorktimeReportResponseItem[],
  allEnums: any,
  customEntities: CustomField[],
  timesheetReportingTypes: CustomField[],
  dimensions: Dimensions,
): WorktimeReportEntry[] => {
  const reportItemsWithTimeBlocks: WorktimeReportResponseItem[] = worktimeReportResponseItems.filter(
    worktimeReportResponseItem =>
      worktimeReportResponseItem.fEmpTimeBlocks &&
      worktimeReportResponseItem.fEmpTimeBlocks.length > 0,
  );

  let checked: number[];

  if (
    !dimensions.date &&
    !dimensions.reportingType &&
    !dimensions.customEntities.length
  ) {
    return [];
  }

  return reportItemsWithTimeBlocks.reduce(
    (
      worktimeReportEntriesAcc: WorktimeReportEntry[],
      currentWorktimeReportResponseItem,
    ) => [
      ...worktimeReportEntriesAcc,
      ...currentWorktimeReportResponseItem.fEmpTimeBlocks.map(
        (timeBlock, i) => {
          if (i === 0) {
            checked = [];
          }
          const reportingTypeEnumValue = timesheetReportingTypes.find(
            e => e.code === timeBlock.reportingType,
          );

          if (
            !checked.includes(i) &&
            (!dimensions.reportingType ||
              timeBlock.reportingType ===
                dimensions.reportingType.value.split('-')[1]) &&
            (!dimensions.date ||
              timeBlock.entryDate ===
                moment(dimensions.date).format('YYYY-MM-DD'))
          ) {
            const compare = currentWorktimeReportResponseItem.fEmpTimeBlocks.filter(
              (timeBlock2, i2) => {
                // checks if the dimension exists that they are matching, in case of custom entities checks only the selected ones
                const test: boolean =
                  i < i2 &&
                  (!dimensions.date ||
                    timeBlock.entryDate === timeBlock2.entryDate) &&
                  (!dimensions.reportingType ||
                    (timeBlock.reportingGroupType ===
                      timeBlock2.reportingGroupType &&
                      timeBlock.reportingType === timeBlock2.reportingType)) &&
                  (!dimensions.customEntities.length ||
                    dimensions.customEntities.every(dimension => {
                      const timeBlock1Dim = timeBlock.customData.find(
                        cd => cd.fCustomDataFieldName === dimension.value,
                      );
                      const timeBlock2Dim = timeBlock2.customData.find(
                        cd => cd.fCustomDataFieldName === dimension.value,
                      );
                      if (timeBlock1Dim && timeBlock2Dim) {
                        return (
                          JSON.stringify(
                            timeBlock1Dim.fCustomDataFieldValue,
                          ) ===
                          JSON.stringify(timeBlock2Dim.fCustomDataFieldValue)
                        );
                      }
                      return !timeBlock1Dim && !timeBlock2Dim;
                    }));
                if (test) {
                  checked.push(i2);
                }
                return test;
              },
            );
            const calculate: number = compare.reduce(
              (pre, current) => pre + convertToM(current.hours),
              convertToM(timeBlock.hours),
            );
            const entries = {
              empId: currentWorktimeReportResponseItem.fEmpId,
              empFirstName: currentWorktimeReportResponseItem.fEmpFirstName,
              empLastName: currentWorktimeReportResponseItem.fEmpLastName,
              empNumber: currentWorktimeReportResponseItem.fEmpNumber,
              empUnitName: currentWorktimeReportResponseItem.fEmpUnitName,
              empUnitNumber: currentWorktimeReportResponseItem.fEmpUnitNumber,
              legalEntityName:
                currentWorktimeReportResponseItem.fEmpLegalEntityName,
              legalEntityCode:
                currentWorktimeReportResponseItem.fEmpLegalEntityCode,
              fEmpCostCenterCode:
                currentWorktimeReportResponseItem.fEmpCostCenterCode,
              fEmpCostCenterName:
                currentWorktimeReportResponseItem.fEmpCostCenterName,
              sum: sumConverter(calculate),
              fEmpHasAccount: currentWorktimeReportResponseItem.fEmpHasAccount,
              ...(dimensions.date && { date: timeBlock.entryDate }),
              ...(dimensions.reportingType && {
                reportingType: reportingTypeEnumValue
                  ? reportingTypeEnumValue.name
                  : '',
              }),
            };

            timeBlock.customData.forEach(customField => {
              const customFieldEnum = customEntities.find(
                c => c.code === customField.fCustomDataFieldName,
              );
              if (
                dimensions.customEntities.find(
                  centity => centity.value === customField.fCustomDataFieldName,
                )
              ) {
                entries[customField.fCustomDataFieldName] = getCustomFieldValue(
                  customField,
                  customFieldEnum,
                  allEnums,
                );
              }
            });

            return entries;
          }
          return {} as WorktimeReportEntry;
        },
      ),
    ],
    [],
  );
};

export const mapEntriesForDimensionSum = (entries: WorktimeReportEntry[]) => {
  let checked: string[] = [];
  return entries.reduce(
    (entriesAcc: WorktimeReportEntry[], currentEntryItem, index) => {
      if (!checked.includes(currentEntryItem.empNumber)) {
        const getAllSums = entries.filter(
          (entry, i) =>
            i !== index && entry.empNumber === currentEntryItem.empNumber,
        );
        const calculate: number = getAllSums.reduce(
          (acc, current) => acc + convertToM(current.sum),
          convertToM(currentEntryItem.sum),
        );
        checked.push(currentEntryItem.empNumber);
        return [
          ...entriesAcc,
          { ...currentEntryItem, sum: sumConverter(calculate) },
        ];
      }
      return [...entriesAcc];
    },
    [],
  );
};

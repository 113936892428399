import { useEffect, useRef } from 'react';

export const useWhenFlagBecomesTrue = (flag: boolean, fn: () => void) => {
    const prevFlagRef = useRef<boolean>();

    useEffect(
        () => {
            if (flag && prevFlagRef.current === false) {
                fn();
            }

            prevFlagRef.current = flag;
        },
        [flag, prevFlagRef, fn],
    );
};

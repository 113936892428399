import * as React from 'react';
import { compose } from 'recompose';
import { Typography, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import translate from '@/app/utils/translate';
import componentListingStyles from './componentListingStyles';
import {
  TemplateStaticComponentProps,
  TemplateInputComponentPropsType,
  TextComponent,
  EvaluationsComponent,
  MultipleChoiceComponent,
  ScaleComponent,
  DateComponent,
  LinkComponent,
  SubtitleComponent,
  TemplateComponentTypeKey,
  FileComponent,
  UpdateTemplateInputComponentCallbackType,
} from '@/app/components/TemplateComponents/form/InputComponents';
import { orderElementsByIndex } from '@/app/components/TemplateComponents/helpers';
import DropdownComponent from '../../InputComponents/DropdownComponent/DropdownComponent';
import MultiSelectDropdownComponent from '../../InputComponents/MultiSelectDropdownComponent/MultiSelectDropdownComponent';
import ScoreComponent from '@/app/components/JobEvaluation/users/TemplateSelection/EditTemplate/SectionListing/ComponentListing/ScoreComponent/ScoreComponent';
import { connect } from 'react-redux';
import { Enum } from '@/app/redux/enums';
import { ReducerState } from '@/app/redux/store';
import {
  ComponentItem,
  GroupEnums,
  ScoreGroup,
} from '@/app/components/JobEvaluation/types';
import { SCORE } from '@/app/components/JobEvaluation/constants';

type MapStateToProps = {
  jobRequirementEvaluation: any;
  scoreGroups: ScoreGroup[];
  groupEnums: GroupEnums;
};

type IncomingProps = {
  components: [];
  expanded: boolean;
  section: any;
  template: any;
  editMode: boolean;
  updateEvaluation?: UpdateTemplateInputComponentCallbackType;
  employeeId: string;
  responses: any[];
};

type PropsType = MapStateToProps &
  IncomingProps &
  WithStyles<typeof componentListingStyles>;

class ComponentListing extends React.PureComponent<PropsType> {
  generateComponentKey = (component: any, editMode: boolean) =>
    `${component.type}-${component.id}-${editMode}`;

  render() {
    const {
      classes,
      components,
      editMode,
      responses,
      scoreGroups,
    } = this.props;

    const orderComponentOptionsByIndex = (component: any) => {
      return !!component.options
        ? { ...component, options: orderElementsByIndex(component.options) }
        : component;
    };

    const includeScoreGroupsToComponent = (component: any) => {
      const enumScoreGroup = scoreGroups.find(
        scoreGroup => scoreGroup.id === component.enumScoreGroupId,
      );

      return { ...component, enumScoreGroup };
    };

    const orderedComponents: ComponentItem[] = orderElementsByIndex(
      components,
    ).map((component: any) => {
      return orderComponentOptionsByIndex(
        component.type === SCORE
          ? includeScoreGroupsToComponent(component)
          : component,
      );
    });

    return orderedComponents.length === 0 ? (
      <Typography>{translate.t('no_components')}</Typography>
    ) : (
      <div className={classes.fullWidth}>
        {orderedComponents.map((component: any, index: number) => {
          const staticComponentProps: TemplateStaticComponentProps = {
            key: this.generateComponentKey(component, editMode),
            component: component,
          };
          const inputComponentProps: TemplateInputComponentPropsType = {
            ...staticComponentProps,
            templateId: this.props.template.id,
            responses: responses,
            onUpdate: this.props.updateEvaluation,
            editMode: editMode,
          };

          switch (component.type) {
            case TemplateComponentTypeKey.TEXT:
              return <TextComponent {...inputComponentProps} />;
            case TemplateComponentTypeKey.EVALUATIONS:
            case TemplateComponentTypeKey.COMPETENCY_LEVEL:
              return <EvaluationsComponent {...inputComponentProps} />;
            case TemplateComponentTypeKey.MULTIPLE_CHOICE:
              return <MultipleChoiceComponent {...inputComponentProps} />;
            case TemplateComponentTypeKey.DROPDOWN:
              return <DropdownComponent {...inputComponentProps} />;
            case TemplateComponentTypeKey.MULTISELECT_DROPDOWN:
              return <MultiSelectDropdownComponent {...inputComponentProps} />;
            case TemplateComponentTypeKey.SCALE:
              return <ScaleComponent {...inputComponentProps} />;
            case TemplateComponentTypeKey.DATE:
              return <DateComponent {...inputComponentProps} />;
            case TemplateComponentTypeKey.FILE:
              return (
                <FileComponent
                  {...inputComponentProps}
                  employeeId={this.props.employeeId}
                />
              );
            case TemplateComponentTypeKey.LINK:
              return <LinkComponent {...staticComponentProps} />;
            case TemplateComponentTypeKey.SUBTITLE:
              return <SubtitleComponent {...staticComponentProps} />;
            case TemplateComponentTypeKey.SCORE:
              return (
                <ScoreComponent
                  groupEnums={this.props.groupEnums}
                  handleSectionScoreChange={undefined}
                  {...inputComponentProps}
                />
              );
            default:
              return <h2 key={index}>{translate.t('laComponentError')}</h2>;
          }
        })}
      </div>
    );
  }
}

const mapStateToProps = (state: ReducerState) => {
  const { jobRequirementEvaluations, enums } = state;
  const enumData = enums.get('allEnums').toJS();
  const groupEnums = Object.keys(enumData)
    .filter(enumItemKey => enumItemKey.indexOf('__USER__DEV_') === 0)
    .map(enumItemKey => enumData[enumItemKey])
    .reduce((acc: { [enumGroup: string]: string[] }, enumGroup: Enum[]) => {
      if (!enumGroup.length) {
        return acc;
      }

      const enumCodes = enumGroup.map(enumItem => {
        return {
          isActive: enumItem.isActive,
          code: enumItem.code,
          name: enumItem.name,
        };
      });
      return {
        ...acc,
        [enumGroup[0].groupName]: enumCodes,
      };
    }, {});

  return {
    groupEnums,
    jobRequirementEvaluation: jobRequirementEvaluations.get(
      'jobRequirementEvaluation',
    ),
    scoreGroups: jobRequirementEvaluations.get('scoreGroups'),
  };
};

const enhance = compose<PropsType, IncomingProps>(
  connect(mapStateToProps),
  withStyles(componentListingStyles),
);

export default enhance(ComponentListing);

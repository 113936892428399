import translate from '@/app/utils/translate';
import { getEnums } from '@/old/utils/helper';
import { ReportingGroupType } from '../../TimeReporting/components/TimeSheet/data';
import { ChildInputType } from '../types';

const reportingGroupTypeLabels: { [key in ReportingGroupType]: string } = {
  [ReportingGroupType.TIMESHEET_WORK_TYPE]: 'laWorkReportingTypes',
  [ReportingGroupType.TIMESHEET_NON_WORK_TYPE]: 'laNonWorkReportingTypes',
  [ReportingGroupType.TIMESHEET_CORRECTION_TYPE]: 'laCorrectionReportingTypes',
};

const GLOBAL: any = window;

export const reportingTypesOptions = () => {
  return [
    ReportingGroupType.TIMESHEET_WORK_TYPE,
    ReportingGroupType.TIMESHEET_NON_WORK_TYPE,
    ...(GLOBAL.iHRAdmin ||
    (GLOBAL.iEmpId !== GLOBAL.iLoginId && (GLOBAL.iHR || GLOBAL.iManager))
      ? [ReportingGroupType.TIMESHEET_CORRECTION_TYPE]
      : []),
  ].reduce(
    (accumulator, group) => {
      const options = getEnums(group).filter(
        ({ isActive }) => isActive !== false,
      );

      if (!options.length) {
        return accumulator;
      }

      return [
        ...accumulator,
        {
          label: translate.t(reportingGroupTypeLabels[group]),
          options: options.map(option => ({
            label: option.name,
            value: `${group}-${option.code}`,
          })),
        },
      ];
    },
    [] as Array<{
      label: string;
      options: ChildInputType[];
    }>,
  );
};

import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const sectionListingStyle: StyleRulesCallback = () => ({
  button: {
    marginLeft: '20px',
    padding: '3px 5px',
  },
  icon: {
    transform: 'rotate(180deg)',
  },
  iconButton: {
    marginLeft: -12,
  },
  noMargin: {
    marginLeft: 0,
    marginRight: 0,
  },
  // Will be used
  // limitChars: {
  //   whiteSpace: 'nowrap',
  //   overflow: 'hidden',
  //   textOverflow: 'ellipsis',
  //   maxWidth: '450px'
  // }
});

export default sectionListingStyle;

import { EvaluationOptionsChange as EOC } from './EvaluationsComponent/components/EvaluationOptions/EvaluationOptions';
export type EvaluationOptionsChange = EOC;

import SubtitleComponent from './SubtitleComponent/SubtitleComponent';
import LinkComponent from './LinkComponent/LinkComponent';
import DateComponent from './DateComponent/DateComponent';
import ScaleComponent from './ScaleComponent/ScaleComponent';
import MultipleChoiceComponent from './MultipleChoiceComponent/MultipleChoiceComponent';
import TextComponent from './TextComponent/TextComponent';
import EvaluationsComponent from './EvaluationsComponent/EvaluationsComponent';
import FileComponent from './FileComponent/FileComponent';
export {
  SubtitleComponent,
  LinkComponent,
  DateComponent,
  ScaleComponent,
  MultipleChoiceComponent,
  TextComponent,
  EvaluationsComponent,
  FileComponent,
};

export enum TemplateComponentTypeKey {
  SUBTITLE = 'SUBTITLE',
  LINK = 'LINK',
  DATE = 'DATE',
  SCALE = 'SCALE',
  MULTIPLE_CHOICE = 'CHECKBOXES',
  EVALUATIONS = 'EVALUATIONS',
  COMPETENCY_LEVEL = 'COMPETENCY_LEVEL',
  TEXT = 'TEXT',
  FILE = 'FILE',
  DROPDOWN = 'DROPDOWN',
  MULTISELECT_DROPDOWN = 'MULTISELECT_DROPDOWN',
  SCORE = 'SCORE',
}

export type TemplateBaseComponentType = {
  id: string;
  index: number;
  type: TemplateComponentTypeKey | string;
  language: string;
  name: string;
};

export type TemplateBaseComponentOptionType = {
  label: string;
  id: number;
  index: number;
};

export type TemplateStaticComponentProps = {
  component: any;
  classes?: any;
  key?: string;
};

export type UpdateTemplateInputComponentCallbackType = (
  templateId: string,
  props: any,
) => void;

export type TemplateInputComponentPropsType = {
  templateId: string;
  component: any;
  responses: any;
  editMode: boolean;
  onUpdate?: UpdateTemplateInputComponentCallbackType;
  classes?: any;
  key?: string;
};

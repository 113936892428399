import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const componentListingStyles: StyleRulesCallback = () => ({
  root: {
    width: '100%',
  },
  header: {
    textAlign: 'right',
  },
  topCheckbox: {
    marginLeft: '-12px'
  },
  multiline: {
    whiteSpace: 'pre-wrap',
  },
  longText: {
    maxWidth: 520,
  },
});

export default componentListingStyles;
import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const topMenuToolBarStyle: StyleRulesCallback = () => ({
  root: {
    marginTop: '-95px',
    marginRight: '-20px',
    position: 'relative',
    minWidth: '150px',
    float: 'right',
  },
});

export default topMenuToolBarStyle;

import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import {
  Grid,
  Checkbox,
  FormControlLabel,
  Tabs,
  Tab,
  Typography,
  FormHelperText,
} from '@material-ui/core';
import {
  FormikDialog,
  FormikSelectField,
  FormikTextField,
  FormikDateField,
  FormikCheckboxField,
  FormikRichTextField,
} from '@/app/components/Formik2';
import { CompanyTraining, IntCompanyTraining, OptionType } from '../../types';
import { Field, FormikProps, FormikValues } from 'formik';
import * as yup from 'yup';
import {
  getLoginLanguage,
  getInternalTrainingNames,
  getExternalTrainingNames,
  getTrainingGroupName,
  getLanguageName,
  getEmployees,
  getTrainingClasses,
  getCompanyCountries,
  getRoles,
  getUnits,
} from '../../helpers';
import translate from '@/app/utils/translate';
import moment from 'moment';
import Context from '../../context/trainingsContext';
import { FormikTimeField } from '@/app/components/Formik2/components/FormikTimeField';
import 'moment-timezone';
import { ReducerState } from '@/app/redux/store';
import { connect } from 'react-redux';
import { LanguagesType } from '@/app/redux/languages';
import { throwError } from '@/app/redux/error';
import Service from '@/app/utils/service';
import API from '@/app/api/internalAPIs';
import { findSubUnits } from '@/old/utils/helper';

type OwnProps = {
  open: boolean;
  initialValues?: CompanyTraining;
  toDuplicate?: boolean;
  onClose: () => void;
};

type MapStateToProps = {
  languages: Array<LanguagesType>;
};

type MapDispatchToProps = {
  throwError: typeof throwError;
};

type Props = OwnProps & MapStateToProps & MapDispatchToProps;

type FormData = Omit<IntCompanyTraining, 'description'>;

const GLOBAL: any = window;

const getCanClearUnitAudience = (selectedUnitAudience?: OptionType[]) => {
  return (
    (selectedUnitAudience &&
      selectedUnitAudience.some((unit) => !unit.isFixed)) ||
    false
  );
};

const TrainingUpdateDialog = ({
  languages,
  open,
  initialValues,
  toDuplicate = false,
  onClose,
  ...props
}: Props) => {
  const {
    state: {
      companyTrainings: { orgTree: unitOrgTree },
    },
    addCompanyTraining,
    getUnitOrgTree,
    updateCompanyTraining,
  } = useContext(Context);
  const [supportedCurrencies, setSupportedCurrencies] = useState([]);

  useEffect(() => {
    getUnitOrgTree();
    Service.get(
      API.getCurrency(),
      (data: any) => {
        if (data) {
          setSupportedCurrencies(
            data.fComSupportedCurrencies.map((c: string) => ({
              value: c,
              label: c,
            })),
          );
        }
      },
      (error: any) => console.log(error),
    );
  }, []);

  const [descriptionTranslations, setDescriptionTranslations] = useState({});
  const [loadingTranslations, setLoadingTranslations] = useState(true);

  const trainingId = initialValues && initialValues.id;

  useEffect(() => {
    if (!GLOBAL.jsIsNewDeploymentType() || !trainingId) {
      setLoadingTranslations(false);
      return;
    }

    Service.get(
      API.entityFieldTranslations.get(
        'COMPANY_TRAINING',
        trainingId.toString(),
        'description',
      ),
      (response: any) => {
        setDescriptionTranslations(response.translations);
        setLoadingTranslations(false);
      },
      (error: any) => {
        setLoadingTranslations(false);
        props.throwError(error);
      },
    );
  }, [trainingId, props.throwError]);

  const defaultLanguageCode = useMemo(() => {
    const defaultLanguage = languages.find(({ isDefault }) => isDefault);
    return (defaultLanguage && defaultLanguage.code) || 'en';
  }, [languages]);

  const [includeSubUnitsCheck, setIncludeSubUnitsCheck] = useState(false);
  const handleIncludeSubUnitsToggle = () =>
    setIncludeSubUnitsCheck(!includeSubUnitsCheck);

  const [activeDescriptionLanguage, setActiveDescriptionLanguage] =
    useState(defaultLanguageCode);
  const handleActiveDescriptionLanguageChange = (_: unknown, lng: string) =>
    setActiveDescriptionLanguage(lng);

  const unitAudienceOptions: OptionType[] = useMemo(
    () => getUnits().map((u: any) => ({ value: u.id, label: u.name })),
    [],
  );

  const internalNameOptions = useMemo(() => {
    return getInternalTrainingNames().map((item: any) => {
      return { value: item.code, label: item.name };
    });
  }, []);

  const externalNameOptions = useMemo(() => {
    return getExternalTrainingNames().map((item: any) => {
      return { value: item.code, label: item.name };
    });
  }, []);

  const trainingGroupOptions = useMemo(
    () => [
      {
        value: 'INTERNAL_TRAINING_TYPE',
        label: getTrainingGroupName('INTERNAL_TRAINING_TYPE'),
      },
      {
        value: 'EXTERNAL_TRAINING_TYPE',
        label: getTrainingGroupName('EXTERNAL_TRAINING_TYPE'),
      },
    ],
    [],
  );

  const trainingClassOptions = useMemo(
    () =>
      getTrainingClasses().map((item: any) => ({
        value: item.code,
        label: item.name,
      })),
    [],
  );

  const languageOptions = useMemo(
    () => languages.map((item) => ({ value: item.code, label: item.name })),
    [languages],
  );

  const timeZoneOptions = useMemo(
    () =>
      moment.tz.names().map((timeZone: string) => ({
        value: timeZone,
        label: `${timeZone} (GMT ${moment.tz(timeZone).format('Z')})`,
      })),
    [],
  );

  const roleAudienceOptions = useMemo(
    () => getRoles().map((r: any) => ({ value: r.id, label: r.name })),
    [],
  );

  const countryAudienceOptions = useMemo(
    () =>
      getCompanyCountries().map((c: any) => ({ value: c.code, label: c.name })),
    [],
  );

  const emptyFormData: FormData = useMemo(() => {
    return {
      name: null,
      trainingGroup: {
        value: 'INTERNAL_TRAINING_TYPE',
        label: getTrainingGroupName('INTERNAL_TRAINING_TYPE'),
      },
      trainingClass: null,
      provider: '',
      responsiblePersons: null,
      language: {
        value: getLoginLanguage() || defaultLanguageCode,
        label: getLanguageName(getLoginLanguage() || defaultLanguageCode),
      },
      startDate: moment().startOf('day'),
      endDate: null,
      startTime: null,
      endTime: null,
      timeZone: null,
      expires: null,
      allowWaitingSeats: false,
      useOnlineRegistration: false,
      lastDateOfCancellation: null,
      city: '',
      room: '',
      contactPerson: '',
      teacherName: '',
      requireManagerApproval: false,
      isPublic: false,
      includeInTrainingCompensation: false,
      int: {
        description: languages.reduce((acc, { code }) => {
          return { ...acc, [code]: '' };
        }, {}),
      },
    };
  }, [defaultLanguageCode, languages]);

  const initialFormData: FormData = useMemo(() => {
    return (
      initialValues && {
        ...initialValues,
        int: {
          description: languages.reduce(
            (acc, { code }) => {
              const translation =
                descriptionTranslations[code] &&
                descriptionTranslations[code].translation;
              return { ...acc, [code]: translation || acc[code] || '' };
            },
            { [defaultLanguageCode]: initialValues.description },
          ),
        },
      }
    );
  }, [defaultLanguageCode, languages, descriptionTranslations]);

  const formData = initialFormData || emptyFormData;

  const validationSchema = useMemo(() => {
    return yup.object<FormData>().shape(
      {
        name: yup
          .object<OptionType>()
          .nullable()
          .required(translate.t('laThisRequired')),
        trainingGroup: yup
          .object<OptionType>()
          .required(translate.t('laThisRequired')),
        trainingClass: yup.object<OptionType>().nullable(),
        currency: yup.object<OptionType>().nullable(),
        provider: yup.string().required(translate.t('laThisRequired')),
        responsiblePersons: yup
          .array()
          .of(yup.object<OptionType>())
          .nullable()
          .required(translate.t('laThisRequired')),
        language: yup
          .object<OptionType>()
          .required(translate.t('laThisRequired')),
        startDate: yup.date().required(translate.t('laThisRequired')),
        endDate: yup
          .date()
          .nullable()
          .when(
            ['startDate', 'endTime'],
            (
              startDate: Date | undefined,
              endTime: Date | undefined,
              schema: yup.DateSchema<Date>,
            ) => {
              if (startDate) {
                schema = schema.min(startDate, translate.t('laCheckEndDate'));
              }

              if (endTime) {
                schema = schema.required(translate.t('laCheckEndDate'));
              }

              return schema;
            },
          ),
        startTime: yup
          .date()
          .nullable()
          .when(
            'endTime',
            (endTime: Date | undefined, schema: yup.DateSchema<Date>) => {
              return endTime
                ? schema.required(translate.t('laCheckEndDate'))
                : schema;
            },
          ),
        endTime: yup
          .date()
          .nullable()
          .when(
            ['endDate', 'startDate', 'startTime'],
            (
              endDate: Date | undefined,
              startDate: Date | undefined,
              startTime: Date | undefined,
              schema: yup.DateSchema<Date>,
            ) => {
              return endDate &&
                startDate &&
                startTime &&
                endDate.valueOf() === startDate.valueOf()
                ? schema.min(startTime, translate.t('laCheckEndTimeStartTime'))
                : schema;
            },
          ),
        timeZone: yup
          .object<OptionType>()
          .nullable()
          .when(
            ['startTime', 'endTime'],
            (
              startTime: Date | undefined,
              endTime: Date | undefined,
              schema: yup.DateSchema<Date>,
            ) => {
              return startTime || endTime
                ? schema.required(translate.t('laThisRequired'))
                : schema;
            },
          ),
        hours: yup.number().nullable(),
        unitAudience: yup.array().of(yup.object<OptionType>()).nullable(),
        roleAudience: yup.array().of(yup.object<OptionType>()).nullable(),
        countryAudience: yup.array().of(yup.object<OptionType>()).nullable(),
        seats: yup
          .number()
          .nullable()
          .integer(translate.t('text_validate_integer')),
        allowWaitingSeats: yup
          .boolean()
          .required(translate.t('laThisRequired')),
        useOnlineRegistration: yup.boolean().nullable(),
        requireManagerApproval: yup.boolean().nullable(),
        isPublic: yup.boolean().nullable(),
        includeInTrainingCompensation: yup.boolean().nullable(),
        expires: yup
          .date()
          .nullable()
          .when(
            'startDate',
            (startDate: Date | undefined, schema: yup.DateSchema<Date>) => {
              return startDate
                ? schema.min(startDate, translate.t('laCheckExpirationDate'))
                : schema;
            },
          ),
        int: yup.object().shape({
          description: yup.object().shape({
            [defaultLanguageCode]: yup
              .string()
              .required(translate.t('laDefaultLanguageIsRequired')),
          }),
        }),
        contactPerson: yup
          .string()
          .test(
            'contactPerson-test',
            translate.t('text_validate_max_length', { max: 80 }),
            (value) => !value || value.length <= 80,
          ),
        teacherName: yup
          .string()
          .test(
            'contactPerson-test',
            translate.t('text_validate_max_length', { max: 80 }),
            (value) => !value || value.length <= 80,
          ),
      },
      [
        ['startTime', 'endTime'],
        ['endDate', 'endTime'],
      ],
    );
  }, [languages]);

  const handleTrainingGroupChange = (formikBag: FormikProps<FormikValues>) => {
    if (formikBag.values.name) {
      formikBag.setFieldTouched('name', true);
      formikBag.setFieldValue('name', null);
    }
  };

  const getMatchingSubUnits = (
    unitAudience?: OptionType[],
    includeSubUnits = includeSubUnitsCheck,
  ) => {
    if (includeSubUnits || !unitAudience) {
      return findSubUnits(
        unitAudience,
        unitAudienceOptions,
        unitOrgTree,
        includeSubUnits,
      );
    }

    return unitAudience.map((unit) => ({ ...unit, isFixed: false }));
  };

  const handleUnitAudienceChange = (
    formikBag: FormikProps<FormikValues>,
    values: OptionType[],
  ) => {
    formikBag.setFieldValue('unitAudience', getMatchingSubUnits(values));
  };

  const handleSubmit = useCallback(
    (values: FormData, actions: FormikProps<FormData>) => {
      try {
        const trainingData = {
          ...values,
          description: values.int.description[defaultLanguageCode],
        };

        if (toDuplicate || !trainingData.id) {
          addCompanyTraining(trainingData);
        } else {
          updateCompanyTraining(trainingData);
        }

        actions.resetForm({ values: formData });
      } catch (e) {
        props.throwError(e);
      }
    },
    [formData],
  );

  if (loadingTranslations || !open) {
    return null;
  }

  const renderContent = ({
    values,
    setFieldValue,
    errors,
    touched,
  }: FormikProps<FormData>) => {
    // TODO: Look for another solution (mutating state on render is no-op)
    // When duplicating training, this will make form dirty and also makes
    // sure that duplicated training is inactive by default
    if (toDuplicate && values.active) {
      setFieldValue('active', false);
    }

    return (
      <Grid container={true} spacing={24}>
        <Grid item={true} xs={12} md={6} lg={4} xl={3}>
          <Grid container spacing={8}>
            <Grid item xs={12} data-testid="autocomplete-training-type">
              <Field
                name="trainingGroup"
                label={translate.t('label_training_type')}
                component={FormikSelectField}
                options={trainingGroupOptions}
                onChange={handleTrainingGroupChange}
                required
              />
            </Grid>
            <Grid item xs={12} data-testid="autocomplete-training-name">
              <Field
                name="name"
                label={translate.t('laTrainingName')}
                component={FormikSelectField}
                options={
                  values.trainingGroup.value === 'INTERNAL_TRAINING_TYPE'
                    ? internalNameOptions
                    : externalNameOptions
                }
                required
              />
            </Grid>
            <Grid item xs={12} data-testid="autocomplete-training-class">
              <Field
                name="trainingClass"
                label={translate.t('training_class')}
                component={FormikSelectField}
                options={trainingClassOptions}
                clearable
              />
            </Grid>
            <Grid container spacing={24}>
              <Grid item xs={5}>
                <Field
                  name="fee"
                  type="number"
                  label={translate.t('laFee')}
                  component={FormikTextField}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="currency"
                  label={translate.t('laCurrency')}
                  component={FormikSelectField}
                  options={supportedCurrencies}
                />
              </Grid>
            </Grid>
            <Grid container spacing={24}>
              <Grid item xs={5}>
                <Field
                  name="city"
                  label={translate.t('fEmpCity')}
                  component={FormikTextField}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="room"
                  label={`${translate.t('fEmpOfficeRoom')}/${translate.t(
                    'laVenue',
                  )}`}
                  component={FormikTextField}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} xs={12} md={6} lg={4} xl={3}>
          <Grid container={true} spacing={8}>
            <Grid item xs={12} data-testid="text-training-provider">
              <Field
                name="provider"
                label={translate.t('laProvider')}
                component={FormikTextField}
                fullWidth
                required
              />
            </Grid>
            <Grid
              item
              xs={12}
              data-testid="autocomplete-training-responsible-persons"
            >
              <Field
                name="responsiblePersons"
                label={translate.t('label_responsible_persons')}
                component={FormikSelectField}
                isMulti={true}
                onLoadOptions={(searchTerm: string): Promise<any> => {
                  if (searchTerm.length < 3) {
                    return Promise.resolve([]);
                  }

                  return Promise.resolve(
                    getEmployees(searchTerm, ['ACTIVE']).map((item: any) => ({
                      value: item.id,
                      label: `${item.firstName} ${item.lastName} (${item.position}) - ${item.code}`,
                    })),
                  );
                }}
                options={[]}
                noOptionsMessage={() =>
                  translate.t('label_type_at_least_n_chars', { count: 3 })
                }
                required={true}
              />
            </Grid>
            <Grid item xs={12} data-testid="autocomplete-training-language">
              <Field
                name="language"
                label={translate.t('laLanguage')}
                component={FormikSelectField}
                options={languageOptions}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="contactPerson"
                label={translate.t('laContactPerson')}
                component={FormikTextField}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="teacherName"
                label={translate.t('laTeacherName')}
                component={FormikTextField}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} xs={12} md={6} lg={4} xl={3}>
          <Grid container spacing={8}>
            <Grid item xs={6} data-testid="date-training-start-date">
              <Field
                name="startDate"
                label={translate.t('laStartDate')}
                component={FormikDateField}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6} data-testid="date-training-end-date">
              <Field
                name="endDate"
                label={translate.t('laEndDate')}
                component={FormikDateField}
                clearable
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="startTime"
                label={translate.t('laStartTime')}
                component={FormikTimeField}
                clearable
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="endTime"
                label={translate.t('laEndTime')}
                component={FormikTimeField}
                clearable
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="timeZone"
                label={translate.t('laTimeZone')}
                component={FormikSelectField}
                options={timeZoneOptions}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="hours"
                type="number"
                label={translate.t('laHours')}
                component={FormikTextField}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="expires"
                label={translate.t('laExpires')}
                component={FormikDateField}
                clearable
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="seats"
                type="number"
                label={translate.t('label_seats')}
                component={FormikTextField}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="allowWaitingSeats"
                label={translate.t('label_allow_waiting_seats')}
                component={FormikCheckboxField}
                color="primary"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="lastDateOfCancellation"
                label={translate.t('laLastCancellationDate')}
                component={FormikDateField}
                clearable
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="lastDateForRegistration"
                label={translate.t('lastDateForRegistration')}
                component={FormikDateField}
                clearable
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} xs={12} md={6} lg={4} xl={3}>
          <Grid container={true} spacing={8}>
            <Grid item xs={12}>
              <Field
                name="unitAudience"
                label={translate.t('label_audience_for_units')}
                component={FormikSelectField}
                onChange={handleUnitAudienceChange}
                options={unitAudienceOptions}
                isClearable={getCanClearUnitAudience(values.unitAudience)}
                isMulti
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="includeSubUnits"
                    checked={includeSubUnitsCheck}
                    onChange={() => {
                      setFieldValue(
                        'unitAudience',
                        getMatchingSubUnits(
                          values.unitAudience,
                          !includeSubUnitsCheck,
                        ),
                      );

                      handleIncludeSubUnitsToggle();
                    }}
                    color="primary"
                  />
                }
                label={translate.t('label_include_subunits')}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="roleAudience"
                label={translate.t('label_audience_for_roles')}
                component={FormikSelectField}
                options={roleAudienceOptions}
                isMulti
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="countryAudience"
                label={translate.t('label_audience_for_countries')}
                component={FormikSelectField}
                options={countryAudienceOptions}
                isMulti
              />
            </Grid>
            <Grid item xs={6} data-testid="checkbox-use-online-registration">
              <Field
                name="useOnlineRegistration"
                label={translate.t('label_use_online_registration')}
                component={FormikCheckboxField}
                color="primary"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="requireManagerApproval"
                label={translate.t('label_require_manager_approval')}
                component={FormikCheckboxField}
                color="primary"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="isPublic"
                label={translate.t('label_is_public')}
                component={FormikCheckboxField}
                color="primary"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="includeInTrainingCompensation"
                label={translate.t('label_include_in_training_compensation')}
                component={FormikCheckboxField}
                color="primary"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} xs={12} md={12} lg={8} xl={6}>
          <Grid container={true} spacing={8}>
            <Grid item xs={12} data-testid="text-training-description">
              <Typography variant="subtitle1">
                {translate.t('laDescription')}
                {GLOBAL.jsIsNewDeploymentType() ? '' : '*'}
              </Typography>

              {(GLOBAL.jsIsNewDeploymentType() && (
                <>
                  <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    value={activeDescriptionLanguage}
                    onChange={handleActiveDescriptionLanguageChange}
                  >
                    {languages.map((language) => {
                      const label = `${language.name}${
                        language.code === defaultLanguageCode ? '*' : ''
                      }`;
                      return (
                        <Tab
                          key={language.code}
                          label={label}
                          value={language.code}
                        />
                      );
                    })}
                  </Tabs>

                  {languages.map((language) => (
                    <div
                      key={language.code}
                      style={{
                        display:
                          language.code === activeDescriptionLanguage
                            ? 'block'
                            : 'none',
                      }}
                    >
                      <Field
                        name={`int.description[${language.code}]`}
                        component={FormikRichTextField}
                        fullWidth
                      />
                    </div>
                  ))}
                </>
              )) || (
                <Field
                  name={`int.description[${defaultLanguageCode}]`}
                  component={FormikRichTextField}
                  fullWidth
                />
              )}

              {GLOBAL._.get(errors, `int.description.${defaultLanguageCode}`) &&
                GLOBAL._.get(
                  touched,
                  `int.description.${defaultLanguageCode}`,
                ) && (
                  <FormHelperText error>
                    {GLOBAL._.get(
                      errors,
                      `int.description.${defaultLanguageCode}`,
                    )}
                  </FormHelperText>
                )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <FormikDialog
      fullWidth={true}
      initialValues={formData}
      maxWidth="xl"
      open={open}
      title={
        formData.id && toDuplicate
          ? translate.t('label_duplicate_training')
          : formData.id
          ? translate.t('edit_training')
          : translate.t('laAddTraining')
      }
      validationSchema={validationSchema}
      // This is needed for CKEditor to work on dialog
      disableEnforceFocus={true}
      onClose={onClose}
      onSubmit={handleSubmit}
      needsToBeValidatedBeforeSave={false}
    >
      {renderContent}
    </FormikDialog>
  );
};

const mapStateToProps = (state: ReducerState) => ({
  languages: state.languages.get('allLanguages'),
});

export default connect(mapStateToProps, { throwError })(TrainingUpdateDialog);

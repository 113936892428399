import { Map as iMap } from 'immutable';
import { Dispatch } from 'redux';
import * as moment from 'moment';
import API from '@/app/api/internalAPIs';
import { SendToApproval } from '@/app/components/DocumentApproval/SendToApprovalFormDialog/SendToApprovalFormDialog';
import Service from '@/app/utils/service';
import { getEmployeeName, updateEmpDocumentData } from '@/old/utils/helper';
import { DTH_FORMAT, getStored } from '../utils/helper';
import translate from '../utils/translate';
import { THROW_ERROR } from './error';
import { OPEN_NOTIFIER } from './notifier';

export const OPEN_AD_DIALOG = '@@solaforce/documentApproval/OPEN_AD_DIALOG';
export const CLOSE_AD_DIALOG = '@@solaforce/documentApproval/CLOSE_AD_DIALOG';
export const SEND_APPROVER_DATA = '@@solaforce/documentApproval/SEND_APPROVER_DATA';
export const GET_ACCESS_CODE = '@@solaforce/documentApproval/GET_ACCESS_CODE';
export const VIEW_DOCUMENT = '@@solaforce/documentApproval/VIEW_DOCUMENT';
export const UPDATE_APPROVAL_STATUS = '@@solaforce/documentApproval/UPDATE_APPROVAL_STATUS';
export const CREATE_DOCUMENT_LINK = '@@solaforce/documentApproval/CREATE_DOCUMENT_LINK';
export const SET_ENTITY_ID = '@@solaforce/documentApproval/SET_ENTITY_ID';
export const SET_ACCESS_TOKEN = '@@solaforce/documentApproval/SET_ACCESS_TOKEN';
export const CHANGE_ACTIVE_PAGE = '@@solaforce/documentApproval/CHANGE_ACTIVE_PAGE';
export const GET_DOCUMENT_HISTORY = '@@solaforce/documentApproval/GET_DOCUMENT_HISTORY';
export const SET_DOCUMENT_NAME = '@@solaforce/documentApproval/SET_DOCUMENT_NAME';
export const RESET_APPROVER_DATA = '@@solaforce/documentApproval/RESET_APPROVER_DATA';
export const FETCH_NEW_DOCUMENT_SERVICE = '@@solaforce/documentApproval/FETCH_NEW_DOCUMENT_SERVICE';

type Response = {
  fId: number;
  fRequestId: string;
  fDocumentId: number;
  fEmployeeId: number;
  fEmployeeEmail: string;
  fEmployeePhone: number | string;
  fApprovalStatus: string;
  fCreatedUserId: number;
  fUpdatedUserId: number;
  fCreated: string;
  fUpdated: string;
  fApproverUserId?: number;
};

export enum ApprovalMethod {
  EMAIL = 'email',
  SIGNSPACE = 'signSpace',
  SIGNICAT = 'signicat',
}

type ApprovalRequestData = {
  fDocumentId: number;
  fEmployeeId: number;
  fEmployeeEmail: string;
  fEmployeePhone?: string;
  fSendSmsAccessCode?: string;
  language: string;
  method?: ApprovalMethod;
  multipleSignerEmails?: string[];
};

type DocumentV2Signers = {
  email: string;
  phone: string;
  useSmsAccessCode: boolean;
};

type EmployeeDocumentApprover = {
  employeeId: number;
  firstName: string;
  lastName: string;
  userId: number;
  username: string;
};

type DocumentHistoryV2 = {
  approvedBy: EmployeeDocumentApprover | null;
  createdAt: string;
  createdBy: EmployeeDocumentApprover;
  document: {
    contentHash: string;
    createdAt: string;
    description: string | null;
    employeeId: number;
    expireAt: string | null;
    id: number;
    name: string;
    path: string;
    size: number;
    type: {
      code: string;
      group: string;
    }
    updatedAt: string;
  },
  documentId: number;
  employeeId: number;
  id: string;
  info: string | null;
  signers: DocumentV2Signers[];
  status: APPROVAL_STATUS;
  type: ApprovalMethod;
  updatedAt: string;
  updatedBy: EmployeeDocumentApprover;
};

type DocumentHistoryV2Response = {
  data: DocumentHistoryV2[];
  total: number;
};

export enum APPROVAL_STATUS {
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
}

export enum APPROVAL_PAGES {
  REQUEST_CODE = 'requestCode',
  APPROVE_DOC = 'approveDocument',
  ACCESS_CODE = 'accessCode',
  SUCCESS = 'success',
  ERROR = 'error',
}

export type DocumentApprovalConfig = {
  approval: {
    defaultMethod: ApprovalMethod;
    availableMethods: ApprovalMethod[];
  };
};

const getStatus = (doc: any) => {
  switch (doc.fApprovalStatus) {
    case APPROVAL_STATUS.APPROVED:
      return `${translate.t(`label_${doc.fApprovalStatus}`)} ${moment(doc.fUpdated).format(DTH_FORMAT)}`;
    case APPROVAL_STATUS.REJECTED:
      return `${translate.t(`label_${doc.fApprovalStatus}`)} ${moment(doc.fUpdated).format(DTH_FORMAT)}: ${doc.fInfo}`;
    case APPROVAL_STATUS.PENDING:
      return translate.t(`label_${doc.fApprovalStatus}`);
    default:
      return '';
  }
};

const generateRows = (data?: any) => {
  const sortedData = data.sort((left: any, right: any) => (
    moment.utc(right.fDocumentApproval.fCreated).diff(moment.utc(left.fDocumentApproval.fCreated))
  ));

  const rows = sortedData.map((dt: any, id: number) => ({
    id,
    started: `${moment(dt.fDocumentApproval.fCreated).format('DD.MM.YYYY HH:mm')} ${dt.fUpdatedUser.fName}`,
    status: getStatus(dt.fDocumentApproval),
    statusType: dt.fDocumentApproval.fApprovalStatus,
  }));

  return rows;
};

const generateHistoryRowsV2Response = (resp: DocumentHistoryV2Response) => {
  const sortedData = resp.data.sort((left, right) => (
    moment.utc(right.createdAt).diff(moment.utc(left.createdAt))
  ));

  const rows = sortedData.map((dt, id) => ({
    id,
    started: `${moment(dt.createdAt).format('DD.MM.YYYY HH:mm')} ${getEmployeeName(dt.updatedBy.employeeId, true)}`,
    status: getStatus({
      fApprovalStatus: dt.status,
      fUpdated: dt.updatedAt,
      fInfo: dt.info || '',
    }),
    statusType: dt.status,
  }));

  return rows;
};

const initialState = iMap({
  openAD: false,
  accessToken: '',
  config: null,
  documentURL: null,
  documentName: null,
  empId: null,
  empEmail: null,
  empPhone: null,
  docId: null,
  entityId: null,
  docApprovalHistory: [],
  activePage: APPROVAL_PAGES.REQUEST_CODE,
});

const initializeState = () => {
  const docAppData = getStored('documentApprovalData');

  if (docAppData && docAppData.entityId) {
    return iMap({
      openAD: false,
      accessToken: docAppData.accessToken || '',
      config: null,
      documentURL: null,
      documentName: docAppData.documentName || null,
      empId: docAppData.empId,
      empEmail: null,
      empPhone: null,
      docId: null,
      entityId: docAppData.entityId,
      docApprovalHistory: [],
      activePage: APPROVAL_PAGES.REQUEST_CODE,
    });
  }

  return initialState;
};

const reducer = (state = initializeState(), action: any) => {
  switch (action.type) {
    case OPEN_AD_DIALOG:
      return state.set('openAD', true);
    case CLOSE_AD_DIALOG:
      return state.set('openAD', false);
    case CREATE_DOCUMENT_LINK: {
      const { empId, docName, docId } = action.payload;
      const documentURL = `/d/bin/employee/${empId}/file/${docId}`;

      return state
        .set('documentURL', documentURL)
        .set('documentName', docName)
        .set('empId', empId)
        .set('docId', docId);
    }
    case CHANGE_ACTIVE_PAGE:
      return state.set('activePage', action.activePage);
    case SET_ENTITY_ID:
      return state.set('entityId', action.entityId);
    case SEND_APPROVER_DATA: {
      const { fId, fRequestId, fEmployeeEmail, fEmployeePhone } = action.payload;
      return state
        .set('entityId', fId)
        .set('rid', fRequestId)
        .set('empEmail', fEmployeeEmail)
        .set('empPhone', fEmployeePhone);
    }
    case SET_ACCESS_TOKEN:
      return state.set('accessToken', action.accessToken);
    case GET_DOCUMENT_HISTORY: {
      const { docApprovalHistory, entityId } = action.payload;
      return state
        .set('docApprovalHistory', docApprovalHistory)
        .set('entityId', entityId);
    }
    case SET_DOCUMENT_NAME:
      return state.set('documentName', action.payload);
    case RESET_APPROVER_DATA:
      return state
        .set('empEmail', '')
        .set('empPhone', '');
    case FETCH_NEW_DOCUMENT_SERVICE:
      return state.set('config', action.payload);
    default:
      return state;
  }
};

export const isAdvancedApprovalMethod = (method: ApprovalMethod) => [
  ApprovalMethod.SIGNSPACE,
  ApprovalMethod.SIGNICAT,
].includes(method);

const mapApprovalRequestBody = (approvalReqData: ApprovalRequestData) => {
  const {
    method,
    language,
    multipleSignerEmails,
    ...legacyReqFields
  } = approvalReqData;

  if (!window.jsIsNewDeploymentType()) {
    return legacyReqFields;
  }

  const empName = getEmployeeName(approvalReqData.fEmployeeId, true);
  if (method === ApprovalMethod.EMAIL) {
    return {
      type: method,
      signers: [{
        email: approvalReqData.fEmployeeEmail,
        phone: approvalReqData.fEmployeePhone,
        fullName: empName
      }],
      useSmsAccessCode: approvalReqData.fSendSmsAccessCode
    }; 
  }

  if (isAdvancedApprovalMethod(method)) {
    return {
      type: method,
      signers: [{
        email: approvalReqData.fEmployeeEmail,
        language: language || 'en'
      }].concat(multipleSignerEmails.map((additionalSignerEmail) => ({
        email: additionalSignerEmail,
        language: language || 'en'
      })))
    };
  }

  return approvalReqData;
};

const handleSendApprovalRequest = async (
  approvalReqData: ApprovalRequestData,
  successCallback: (payload: Response) => void,
  errCallback: (error: Error) => void,
) => {
  const sendData = mapApprovalRequestBody(approvalReqData);
  const approveDocumentUrl = !window.jsIsNewDeploymentType()
    ? API.approveDocument.send
    : API.v2ApproveDocument.send(approvalReqData.fDocumentId);

  await Service.post(
    approveDocumentUrl,
    sendData,
    successCallback,
    errCallback
  );
};

const handleDocumentHistoryRetrieval = async (
  empId: number,
  docId: number,
  successCallback: (payload: {
    docApprovalHistory: any[];
    entityId: string;
  }) => void,
  errCallback: (error: Error) => void,
) => {
  const docHistoryUrl = !window.jsIsNewDeploymentType()
    ? API.approveDocument.docHistory(empId, docId)
    : API.v2ApproveDocument.docHistory(docId);

  await Service.get(
    docHistoryUrl,
    (resp: Response | DocumentHistoryV2Response) => {
      const v2Response = resp as DocumentHistoryV2Response;
      const legacyResp = resp as Response;

      const docApprovalHistory = window.jsIsNewDeploymentType()
        ? generateHistoryRowsV2Response(v2Response)
        : generateRows(legacyResp);

      const entityIdV2 = v2Response.data && v2Response.data[0]
        ? v2Response.data[0].id
        : null;

      const entityId = legacyResp && legacyResp[0]
        ? legacyResp[0].fDocumentApproval.fId
        : null;

      successCallback({
        docApprovalHistory,
        entityId: window.jsIsNewDeploymentType() ? entityIdV2 : entityId,
      });
    },
    errCallback
  );
};

const handleDocumentApprovalResponse = async (
  approvalResp: {
    docId: number;
    entityId: number;
    fInfo: string;
    approved: boolean;
    fAccessToken: string;
  },
  successCallback: (payload: Response) => void,
  errCallback: (error: Error) => void,
  type?: ApprovalMethod,
) => {
  const { docId, entityId, fInfo, approved, fAccessToken } = approvalResp;
  const approveUrl = window.jsIsNewDeploymentType()
    ? API.v2ApproveDocument.approve(docId)
    : API.approveDocument.approve(entityId);

  const rejectUrl = window.jsIsNewDeploymentType()
    ? API.v2ApproveDocument.cancel(docId)
    : API.approveDocument.reject(entityId);

  const APIurl = approved ? approveUrl : rejectUrl;
  const reason = approved ? {} : { reason: fInfo };
  const body = window.jsIsNewDeploymentType()
    ? { type, ...reason}
    : { fInfo, fAccessToken };

  await Service.post(
    APIurl,
    body,
    successCallback,
    errCallback
  );
};

export const openADDialog = (payload?: any) => ({ type: OPEN_AD_DIALOG, payload });
export const closeADDialog = () => ({ type: CLOSE_AD_DIALOG });
export const resetApproverData = () => ({ type: RESET_APPROVER_DATA });
export const createDocumentLink = (payload: SendToApproval) => ({ type: CREATE_DOCUMENT_LINK, payload });
export const changeActivePage = (activePage: string) => ({ type: CHANGE_ACTIVE_PAGE, activePage });

export const sendApproverData = (empData: any) => {
  return async (dispatch: Dispatch) => {
    const { fDocumentId, fEmployeeId } = empData;

    await handleSendApprovalRequest(
      empData,
      (payload: Response) => {
        dispatch({ type: SEND_APPROVER_DATA, payload });
        dispatch({ type: OPEN_NOTIFIER, payload: {} });

        updateEmpDocumentData(fEmployeeId);
      },
      (error: any) => dispatch({ type: THROW_ERROR, error })
    );

    await handleDocumentHistoryRetrieval(
      fEmployeeId,
      fDocumentId,
      (payload: any) => {
        dispatch({ type: GET_DOCUMENT_HISTORY, payload });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error })
    );
  };
};

export const getDocumentHistory = (empId: number, docId: number) => {
  return (dispatch: Dispatch) => {
    handleDocumentHistoryRetrieval(
      empId,
      docId,
      (resp: any) => {
        dispatch({ type: GET_DOCUMENT_HISTORY, payload: resp });
      },
      (error: any) => { console.error(error); }
    );
  };
};

export const requestAccessCode = (ids: any) => {
  return (dispatch: Dispatch) => {
    const { rid, entityId } = ids;

    Service.post(
      API.approveDocument.code(entityId),
      { rid },
      (data: any) => {
        if (data) {
          localStorage.setItem('documentApprovalData', JSON.stringify({ rid, entityId }));
          dispatch({ type: SET_ENTITY_ID, entityId });
          dispatch({ type: OPEN_NOTIFIER, payload: {} });

          dispatch({ type: CHANGE_ACTIVE_PAGE, activePage: APPROVAL_PAGES.ACCESS_CODE });
        }
      },
      (error: any) => dispatch({ type: THROW_ERROR, error })
    );
  };
};

export const viewDocument = (data: any) => {
  return (dispatch: Dispatch) => {
    const { entityId, accessCode } = data;

    Service.post(
      API.approveDocument.token(entityId),
      { access_code: accessCode },
      (response: any) => {
        const { fDocument, fDocumentApproval } = response;
        const docAppData = JSON.parse(localStorage.getItem('documentApprovalData'));
        localStorage.setItem('documentApprovalData', JSON.stringify({
          ...docAppData,
          accessCode,
          accessToken: fDocumentApproval.fAccessToken,
          accessTokenExpireTime: fDocumentApproval.fExpireTime,
          documentName: fDocument.fName,
        }));

        dispatch({ type: SET_ACCESS_TOKEN, accessToken: fDocumentApproval.fAccessToken });
        dispatch({ type: SET_DOCUMENT_NAME, payload: fDocument.fName });
        dispatch({ type: OPEN_NOTIFIER, payload: {} });

        dispatch({ type: CHANGE_ACTIVE_PAGE, activePage: APPROVAL_PAGES.APPROVE_DOC });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error })
    );
  };
};

export const sendResponse = (response: any) => {
  return async (dispatch: Dispatch, getState: Function) => {
    const empId = getState().documentApproval.get('empId');
    const docId = getState().documentApproval.get('docId');
    const config = getState().documentApproval.get('config');

    const { entityId, fInfo, approved, fAccessToken } = response;

    await handleDocumentApprovalResponse(
      { docId, entityId, fInfo, approved, fAccessToken },
      (payload: Response) => {
        dispatch({ type: UPDATE_APPROVAL_STATUS, payload });
        dispatch({ type: OPEN_NOTIFIER, payload: {} });

        updateEmpDocumentData(empId);
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
      config && config.approval.defaultMethod,
    );

    await handleDocumentHistoryRetrieval(
      empId,
      docId,
      (payload: any) => {
        dispatch({ type: GET_DOCUMENT_HISTORY, payload });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error })
    );
  };
};

export const sendUnauthorizedResponse = (response: any) => {
  return (dispatch: Dispatch) => {
    const { entityId, fInfo, approved, accessToken } = response;
    const APIurl = approved ?
      API.approveDocument.approve(entityId) :
      API.approveDocument.reject(entityId);
    const body = approved ? {} : { fInfo };

    Service.post(
      APIurl,
      body,
      (data: Response) => {
        dispatch({ type: UPDATE_APPROVAL_STATUS, data });
        dispatch({ type: OPEN_NOTIFIER, payload: {} });

        dispatch({ type: CHANGE_ACTIVE_PAGE, activePage: APPROVAL_PAGES.SUCCESS });

        localStorage.removeItem('documentApprovalData');
      },
      (error: any) => {
        dispatch({ type: THROW_ERROR, error });
        // TODO: - this probably should also be put in token request when unauthenticated
        if (error.status === 403) {
          dispatch({ type: CHANGE_ACTIVE_PAGE, activePage: APPROVAL_PAGES.ERROR });
        }
      },
      { Authorization: `AccessToken ${accessToken}` }
    );
  };
};

export const fetchNewDocumentServiceConfig = () => {
  return (dispatch: Dispatch) => {
    if (!window.jsIsNewDeploymentType()) {
      return;
    }

    Service.get(
      API.v2ApproveDocument.config,
      (config: DocumentApprovalConfig) => {
        dispatch({ type: FETCH_NEW_DOCUMENT_SERVICE, payload: config });
      },
      (error: any) => { console.error(error); }
    );
  };
};

export default reducer;

import { Record } from 'immutable';
import { Dispatch } from 'redux';
import { THROW_ERROR } from './error';
import Service from '@/app/utils/service';
import APIs from '../api/internalAPIs';

const FETCHING = '@@solaforce/orgTree/FETCHING';
const FETCH_PRIMARY_UNITS = '@@solaforce/orgTree/FETCH_PRIMARY_UNITS';

const initialState = Record({
  isFetching: false,
  primaryUnits: [],
});

const reducer = (state = new initialState(), action: any) => {
  switch (action.type) {
    case FETCHING:
      return state.set('isFetching', true);
    case FETCH_PRIMARY_UNITS:
      return state.set('primaryUnits', action.payload);
    default:
      return state;
  }
};

export const fetchPrimaryUnits = () => {
  return async (dispatch: Dispatch, getState: any) => {
    if (getState().orgTree.primaryUnits.length > 0) { return; }
    await dispatch({ type: FETCHING });

    Service.get(
      APIs.orgTree.primaryUnits(),
      (data: any) => {
        dispatch({ type: FETCH_PRIMARY_UNITS, payload: data });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error })
    );
  };
};

export default reducer;
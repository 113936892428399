import { Checkbox, IconButton, TextField, Typography } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import * as React from 'react';

import IntegrationReactSelect from '@/app/components/IntegrationReactSelect/IntegrationReactSelect';
import LocalizedDatePicker from '@/app/components/Pickers/LocalizedDatePicker';

import { CustomFieldType } from '../../../../transactionsListModels';

import { CustomFieldInnerProps } from './customFieldModels';
import { useCustomField } from './useCustomField';

export const CustomField: React.FC<CustomFieldInnerProps> = (props) => {
  const {
    formattedValue,
    options,
    inputValue,
    selectedOption,
    handleChange,
    handleClearValue,
  } = useCustomField(props);

  if (props.idEditMode) {
    switch (props.fieldDetails.inputType.value) {
      case CustomFieldType.BOOLEAN: {
        return (
          <Checkbox
            disabled={props.isSaving}
            checked={inputValue === 'true'}
            onChange={handleChange}
          />
        );
      }
      case CustomFieldType.STRING: {
        return (
          <TextField
            type="text"
            variant="outlined"
            disabled={props.isSaving}
            className={props.classes.textInput}
            value={inputValue || ''}
            onChange={handleChange}
          />
        );
      }
      case CustomFieldType.NUMBER: {
        return (
          <TextField
            type="number"
            variant="outlined"
            inputProps={{ step: '0.01' }}
            disabled={props.isSaving}
            className={props.classes.textInput}
            value={inputValue === undefined ? '' : inputValue}
            onChange={handleChange}
          />
        );
      }
      case CustomFieldType.TEXT: {
        return (
          <textarea
            maxLength={3000}
            disabled={props.isSaving}
            className={props.classes.textarea}
            value={inputValue || ''}
            onChange={handleChange}
          />
        );
      }
      case CustomFieldType.DATE: {
        return (
          <div className={props.classes.clearableInputContainer}>
            <LocalizedDatePicker
              format="L"
              margin="normal"
              variant="outlined"
              value={inputValue || ''}
              onChange={handleChange}
              className={props.classes.textInput}
              invalidDateMessage=""
              invalidLabel=""
              disabled={props.isSaving}
              fullWidth={true}
            />
            <IconButton
              className={props.classes.clearButton}
              disabled={!inputValue || props.isSaving}
              onClick={handleClearValue}
            >
              <Clear />
            </IconButton>
          </div>
        );
      }
      case CustomFieldType.ENUM: {
        return (
          <IntegrationReactSelect
            isMulti={false}
            isClearable={true}
            isSearchable={true}
            isDisabled={props.isSaving}
            label=""
            required={false}
            error=""
            defaultValue={selectedOption || ''}
            onChange={handleChange}
            options={options}
            className={props.classes.fullWidth}
            noWrapValue={true}
          />
        );
      }
      case CustomFieldType.MULTISELECT: {
        return (
          <IntegrationReactSelect
            isMulti={true}
            isClearable={true}
            isSearchable={true}
            isDisabled={props.isSaving}
            label=""
            required={false}
            error=""
            defaultValue={selectedOption || []}
            onChange={handleChange}
            options={options}
            className={props.classes.fullWidth}
            noWrapValue={true}
          />
        );
      }
      default: {
        return null;
      }
    }
  }

  return (
    <Typography color="textSecondary">
      {formattedValue}
    </Typography>
  );
};

import translate from '@/app/utils/translate';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { CompanyTraining } from '../types';
import { styles } from './addToDRDialog/addToDRList/';
import Context from '../context/trainingsContext';
import API from '@/app/api/internalAPIs';
import Service from '@/app/utils/service';
import { useSnackbar } from 'notistack';
import {
  hasValidCSVMimeType,
  hasValidExtension,
} from '@/app/utils/fileValidation';
import Instructions from '@/app/components/Instructions/Instructions';

type OwnProps = {
  training: CompanyTraining;
  open: boolean;
  onClose: () => void;
};

type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const ParticipantsImportDialog = ({
  training,
  open,
  onClose,
  classes,
}: Props) => {
  const { getParticipants } = useContext(Context);
  const [file, setFile] = useState<File>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files[0]) {
      const uploadedFile = event.target.files[0];
      const allowedExtensions = ['.csv'];
      if (
        !hasValidExtension(uploadedFile.name, allowedExtensions) ||
        !hasValidCSVMimeType(uploadedFile.type)
      ) {
        setErrorMessage(translate.t('extensionNotAcceptable'));
        setFile(undefined);
        return;
      }
      setErrorMessage('');
      setFile(event.target.files[0]);
    }
  };

  const importParticipants = async () => {
    const formData = new FormData();
    formData.append('file', file);
    Service.post(
      API.training.participants(training.id),
      formData,
      () => {
        getParticipants(training);
        enqueueSnackbar(translate.t('importSuccessful'), {
          variant: 'success',
        });
      },
      (e: any) => {
        setErrorMessage(e.data.error.detail);
        setFile(undefined);
      },
    );
  };

  const onSave = async () => {
    if (errorMessage === '' && file) {
      await importParticipants();
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>
        {translate.t('label_import_participants')}
        <Instructions type={'INSTRUCTIONS_TRAINING_CALENDAR_PARTICIPANT_UPLOAD'} />
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Grid item>
            <input
              accept=".csv"
              className={classes.inputFileUpload}
              id={'upload-participants'}
              type="file"
              onChange={handleFileUpload}
            />
            <label htmlFor={'upload-participants'}>
              <Button variant="contained" component="span">
                {file
                  ? translate.t('laChangeFile')
                  : translate.t('laChooseFile')}
              </Button>
            </label>
            <span>
              {file ? `  ${file.name}` : `  ${translate.t('laNoFile')}`}
            </span>
          </Grid>
          <Grid item>
            <span className={classes.error}>{errorMessage}</span>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          href={API.training.downloadTemplate}
          size="small"
          variant="contained"
        >
          {translate.t('downloadTemplate')}
        </Button>
        <Button color="primary" onClick={onClose}>
          {translate.t('laCancel')}
        </Button>
        <Button
          disabled={!file}
          color="primary"
          variant="contained"
          onClick={onSave}
        >
          {translate.t('laSave')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(ParticipantsImportDialog);

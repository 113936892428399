import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: theme.spacing.unit,
    flex: 1,
  },
  iconButton: {
    padding: theme.spacing.unit,
  },
  fullFlex: {
    flex: 1,
  }
});

export default styles;

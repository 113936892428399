import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { WithStyles, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import translate from '@/app/utils/translate';
import { INPUT_TYPE } from '@/app/utils/helper';
import FormFields from '@/app/components/FormFields/FormFields';
import changeCloseDateFormDialogStyle from './changeCloseDateFormDialogStyle';
import { getFormDialog } from '@/app/components/FormDialog/FormDialog';
import { getFormValues, reset } from 'redux-form';
import { ListSurvey, SurveyCloseDate } from '@/app/redux/surveys';
import { ConfirmDialogType, openConfirmDialog } from '@/app/redux/confirmDialog';
import * as moment from 'moment';
import { ReducerState } from '@/app/redux/store';

const FORM_NAME = 'changeCloseDateForm';

type MapStateToProps = {
  currentValues: SurveyCloseDate;
};

type MapDispatchToProps = {
  resetForm: () => void;
  openConfirmDialog: (payload: ConfirmDialogType) => void;
};

type OwnProps = {
  title: string;
  open: boolean;
  survey: ListSurvey;
  onClose: () => void;
  onSubmit: (surveyData: SurveyCloseDate) => void;
};

type PropsType = OwnProps & WithStyles<typeof changeCloseDateFormDialogStyle> & MapDispatchToProps & MapStateToProps;

const emptyForm = () => {
  return {
    id: '',
    startDate: '',
    closeDate: '',
  };
};

type StateType = {
  formData: SurveyCloseDate;
};

class ChangeCloseDateFormDialog extends React.Component<PropsType> {
  state: StateType = {
    formData: emptyForm(),
  };

  validate = (values: SurveyCloseDate) => {
    const errors: {[k: string]: any} = {};
    const requiredFields = [
      'closeDate',
    ];

    requiredFields.forEach(field => {
      if (!values[field] || values[field] === '<p>&nbsp;</p>') {
        errors[field] = translate.t('laThisRequired');
      }
    });

    const { startDate, closeDate } = values;
    if (startDate && closeDate && moment(closeDate).isSameOrBefore(startDate)) {
      errors.closeDate = translate.t('error_close_date_before_start_date');
    } else if (closeDate && moment(closeDate).diff(moment(), 'days') <= 0 && moment(closeDate).diff(moment(), 'hours') < 0) {
      errors.closeDate = translate.t('error_close_date_from_tomorrow');
    }

    return errors;
  };

  componentDidUpdate(prevProps: PropsType) {
    const { open, survey } = this.props;
    const { open: prevOpen, survey: prevSurvey } = prevProps;

    if (open && open !== prevOpen && JSON.stringify(survey) !== JSON.stringify(prevSurvey)) {
      this.loadData(survey);
    }
  }

  loadData = (survey: ListSurvey) => {
    const { id, closeDate, startDate } = survey;
    const formData = {
      id,
      closeDate,
      startDate,
    };

    this.setState({
      formData,
    });
  };

  setEmptyState = () => {
    this.setState({
      formData: emptyForm(),
    });
  };

  handleClose = () => {
    const { onClose, resetForm } = this.props;

    this.setEmptyState();
    onClose();
    resetForm();
  };

  handleSubmit = (values: SurveyCloseDate) => {
    this.props.openConfirmDialog({
      // TODO: change text? -> reschedule?
      text: translate.t('confirm_schedule_survey'),
      onOk: () => submit()
    });

    const submit = () => {
      const { onSubmit, resetForm } = this.props;

      this.setEmptyState();
      onSubmit(values);
      resetForm();
    };
  };

  renderTopInputs = () => {
    const topInputs = [
      {
        type: INPUT_TYPE.DATE,
        code: 'startDate',
        name: 'startDate',
        label: translate.t('label_start_date'),
        order: 1,
        disabled: true,
        required: true,
      }, {
        type: INPUT_TYPE.DATE,
        code: 'closeDate',
        name: 'closeDate',
        label: translate.t('label_close_date'),
        order: 2,
        required: true,
      },
    ];

    return topInputs.map(inpt => (
      <Grid item xs={12} sm={5} key={inpt.order}>
        <FormFields inputs={[inpt]} />
      </Grid>
    ));
  };

  FormDialog = getFormDialog(FORM_NAME, this.validate);

  render() {
    const { title, open, classes } = this.props;
    const { formData } = this.state;
    const { FormDialog } = this;

    return (
      <FormDialog
        title={title}
        open={open}
        onClose={this.handleClose}
        formData={formData}
        paperWidthMd={classes.paperWidthMd}
        onSubmit={this.handleSubmit}
        customSubmitLabel={translate.t('laChangeDate')}
        customCloseLabel={translate.t('laCancel')}
      >
        <Grid container className={classes.root} justify="space-between">
          {this.renderTopInputs()}
        </Grid>
      </FormDialog>
    );
  }
}

const mapStateToProps = (state: ReducerState) => {
  return {
    currentValues: getFormValues(FORM_NAME)(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetForm: () => dispatch(reset(FORM_NAME)),
  openConfirmDialog: (payload: ConfirmDialogType) => dispatch(openConfirmDialog(payload)),
});

const enhance = compose<any, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(changeCloseDateFormDialogStyle, {withTheme: true}),
);

export default enhance(ChangeCloseDateFormDialog);
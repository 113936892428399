import API from '@/app/api/internalAPIs';
import { getIncentiveShortTypes, getTeamMembers } from '@/old/utils/helper';

class Service {
  getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }

  getIncentiveTypes() {
    const incentiveTypes = [...getIncentiveShortTypes()];
    return incentiveTypes.map((d: any, index: number) =>
      Object.assign({}, d, { id: index }),
    );
  }

  getCurrentPlan(path: string) {
    const teamId = path.split('/')[path.split('/').length - 1];
    const teamMembers = getTeamMembers(teamId);
    const incentiveTypes = this.getIncentiveTypes();
    const data: any = {
      id: null,
      description: null,
      rows: teamMembers.map((d: any): any => {
        const program =
          incentiveTypes[this.getRandomInt(0, incentiveTypes.length - 1)];
        return {
          id: d.fEmpId,
          firstName: d.fEmpFirstName,
          lastName: d.fEmpGivenNames,
          personNumber: d.fEmpPersonNumber,
          position: d.fEmpPosition,
          currency: 'EUR',
          annualSalary: 40000,
          monthlySalary: 3500,
          score: 0.8,
          incentives: [
            {
              id: d.fEmpId,
              programId: program.id,
              programName: program.name,
              ruleId: null,
              ruleName: null,
              bonusValue: 0.2,
              targetEarning: 3700,
              sum: 0,
            },
          ],
        };
      }),
    };
    return data;
  }

  get(path: string, callback: any, _errorHandler: any) {
    if (path.indexOf('bonus/PLAN/') === 0) {
      callback(this.getCurrentPlan(path));
    } else if (path === API.bonus.programs) {
      callback(this.getIncentiveTypes());
    } else {
      console.error('API: ' + path + ' is not defined');
    }
  }

  patch(path: string, payload: any) {
    const oldValue = JSON.parse(localStorage.getItem(path));

    if (!!oldValue) {
      const value = Object.assign({}, payload, {
        id: oldValue.length + 1,
      });

      const newValue = [value, ...oldValue];
      localStorage.setItem(path, JSON.stringify(newValue));

      return value;
    }

    localStorage.setItem(path, JSON.stringify(payload));

    return payload;
  }

  put(path: string, payload: any, callback: any, _errorHandler: any) {
    const oldValue = JSON.parse(localStorage.getItem(path));

    if (!!oldValue) {
      const value = Object.assign({}, payload, {
        id: oldValue.length + 1,
      });

      const newValue = [value, ...oldValue];
      localStorage.setItem(path, JSON.stringify(newValue));

      callback(value);
      return;
    }

    localStorage.setItem(path, JSON.stringify(payload));

    callback(payload);
  }
}

export default new Service();

import * as React from 'react';
import classNames from 'classnames';
import Select from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';
import { withStyles } from '@material-ui/core/styles';
import { WithStyles, Typography, TextField, Chip, MenuItem } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import styles from '@/app/components/IntegrationReactSelect/IntegrationReactSelectStyle';
import { ChildInputType } from '@/app/components/FormFields/FormFields';
import translate from '@/app/utils/translate';

type OptionsType =
    | Array<ChildInputType>
    | Array<{
      label: string;
      options: ChildInputType[];
    }>;

interface PropsType extends WithStyles<typeof styles, true> {
  isMulti: boolean;
  value?: any;
  defaultValue?: any;
  isClearable?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  onChange?: any;
  options?: OptionsType;
  customComponents?: any;
  isCreatable?: boolean;
  label: string;
  required: boolean;
  error: string;
  hasError?: boolean;
  className?: string;
  noWrapValue?: boolean;
  name?: string;
}

class IntegrationReactSelect extends React.Component<PropsType> {
  formatCreateLabel = (inputValue: string) => `${translate.t('laAdd')} "${inputValue}"`;

  NoOptionsMessage = (props: any) => {
    return (
      <Typography
        color="textSecondary"
        className={props.selectProps.classes.noOptionsMessage}
      >
        {translate.t('laNoData')}
      </Typography>
    );
  }

  inputComponent = ({ inputRef, ...props }: { inputRef: any, props: any }) => {
    return <div ref={inputRef} {...props} />;
  }

  Control = (props: any) => {
    const {
      placeholder,
      required,
      error,
      hasError,
    } = this.props;

    return (
      <TextField
        fullWidth
        label={placeholder}
        required={required}
        error={hasError || !!error}
        helperText={error ? error : null}
        InputLabelProps={
          {
            shrink: props.isFocused || props.hasValue,
            style: !props.isFocused && !props.hasValue ? {
              width: 'calc(100% - 40px)',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            } : {}
          }
        }
          // user typing or got value, label should shrink
        InputProps={{
          inputComponent: this.inputComponent,
          inputProps: {
            className: props.selectProps.classes.input,
            inputRef: props.innerRef,
            children: props.children,
            ...props.innerProps,
          },
        }}
        {...props.selectProps.textFieldProps}
      />
    );
  }

  Option = (props: any) => {
    return (
      <MenuItem
        buttonRef={props.innerRef}
        selected={props.isFocused}
        component="div"
        style={{
          fontWeight: props.isSelected ? 500 : 400,
        }}
        {...props.innerProps}
      >
        {props.children}
      </MenuItem>
    );
  }

  Placeholder = () => {
    return '';
  }

  SingleValue = (props: any) => {
    return (
      <Typography
          className={classNames(props.selectProps.classes.singleValue, {
            [props.selectProps.classes.noWrapSingleValue]: this.props.noWrapValue,
          })}
          {...props.innerProps}
      >
        {props.children}
      </Typography>
    );
  }

  ValueContainer = (props: any) => {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
  }

  MultiValue = (props: any) => {
    return (
      <Chip
        tabIndex={-1}
        label={props.children}
        classes={{
          root: classNames(props.selectProps.classes.chip, {
            [props.selectProps.classes.chipFocused]: props.isFocused,
            [props.selectProps.classes.noWrapChip]: this.props.noWrapValue,
          }),
          label: classNames({
            [props.selectProps.classes.noWrapChipLabel]: this.props.noWrapValue,
          }),
        }}
        onDelete={props.removeProps.onClick}
        deleteIcon={<Cancel {...props.removeProps} />}
      />
    );
  }

  render() {
    const {
      theme,
      isSearchable = false,
      customComponents = {},
      isCreatable = false,
      ...props
    } = this.props;

    const selectStyles = {
      input: (base: any) => ({
        ...base,
        color: theme.palette.text.primary,
      }),
      menu: (base: any) => ({
        ...base,
        borderRadius: 0,
        boxShadow: theme.shadows[1],
      }),
      menuPortal: (base: any) => ({
        ...base,
        zIndex: theme.zIndex.modal + 1,
      }),
    };

    const components = {
      Option: this.Option,
      Control: this.Control,
      NoOptionsMessage: this.NoOptionsMessage,
      Placeholder: this.Placeholder,
      SingleValue: this.SingleValue,
      MultiValue: this.MultiValue,
      ValueContainer: this.ValueContainer,
    };

    if (isCreatable) {
      return (
        <CreatableSelect
          formatCreateLabel={this.formatCreateLabel}
          styles={selectStyles}
          components={{...components, ...customComponents}}
          isSearchable={isSearchable}
          menuPortalTarget={document.body}
          menuShouldBlockScroll={true}
          menuPlacement="auto"
          {...props}
        />
      );
    }

    return (
      <Select
        styles={selectStyles}
        components={{...components, ...customComponents}}
        isSearchable={isSearchable}
        menuPortalTarget={document.body}
        menuShouldBlockScroll={true}
        menuPlacement="auto"
        {...props}
      />
    );
  }
}

export default withStyles(styles, { withTheme: true })(IntegrationReactSelect);

import * as React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { Grid, WithStyles, withStyles, Card, CardHeader, CardContent } from '@material-ui/core';
import { compose } from 'recompose';
import donutChartStyle from './donutChartStyle';
import { Optional } from '@/app/utils/helper';

export type DonutData = {
  id: string;
  label?: string;
  value: number;
  color: string;
  percentageLabel?: Optional<string>;
};

type OwnProps = {
  data: DonutData[];
  title: string;
  total: number;
  percentageLabel?: boolean;
};

type PropsType = OwnProps & WithStyles<typeof donutChartStyle>;

const DonutChart: React.FunctionComponent<PropsType> = (props) => {
  const { data, classes, percentageLabel = false, title, total } = props;

  return (
    <Card className={classes.donutChartCard}>
      <CardHeader
        className={classes.donutChartHeader}
        title={title}
        classes={{
          title: classes.donutChartCardTitle,
        }}
      />
      <CardContent className={classes.donutChartCardContent}>
        <Grid container className={classes.donutChartContainer}>
          <ResponsivePie
            data={data}
            margin={{
              top: 20,
              right: 0,
              bottom: 20,
              left: 96,
            }}
            innerRadius={0.75}
            padAngle={1}
            colors={(e) => `${e.color}`}
            borderColor="inherit:darker(0.2)"
            radialLabel={(e) => percentageLabel ? `${e.percentageLabel}` : `${e.value}`}
            radialLabelsSkipAngle={0}
            radialLabelsTextXOffset={0}
            radialLabelsTextColor="#000"
            radialLabelsLinkOffset={-10}
            radialLabelsLinkDiagonalLength={15}
            radialLabelsLinkHorizontalLength={0}
            radialLabelsLinkStrokeWidth={0}
            radialLabelsLinkColor="inherit"
            enableSlicesLabels={false}
            slicesLabelsSkipAngle={0}
            slicesLabelsTextColor="#222"
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            legends={[{
              anchor: 'bottom-left',
              direction: 'column',
              translateX: -96,
              translateY: 20,
              itemWidth: 125,
              itemHeight: 18,
              itemsSpacing: 2,
              itemDirection: 'left-to-right',
              itemTextColor: '#000',
              symbolSize: 18,
              symbolShape: 'square',
            }]}
          />
          <div className={classes.total}>{total}</div>
        </Grid>
      </CardContent>
    </Card>
  );
};

const enhance = compose<PropsType, OwnProps>(
  withStyles(donutChartStyle),
);

export default enhance(DonutChart);

import { Record } from 'immutable';
import { Dispatch } from 'redux';
import { THROW_ERROR } from './error';
import { OPEN_NOTIFIER } from '@/app/redux/notifier';
import translate from '@/app/utils/translate';
import API from '@/app/api/internalAPIs';
import Service from '@/app/utils/service';
import { EvaluationStatus } from '../components/Reporting/Performance/types';
import { CustomFieldType } from '../components/CustomField/types';

type Stage = {
  default: boolean;
  id: number;
  index: number;
  isDeleted: boolean;
  label: string;
};

export type AppraisalReportEmployee = {
  id: number;
  firstName: string;
  lastName: string;
  employeeNumber: string;
  hasAccount: boolean;
  roles: string[];
};

type AppraisalReportEvaluationResponse = {
  componentId: string;
  response: string;
  id: number;
  type: CustomFieldType;
};

type AppraisalReportEvaluation = {
  id: string;
  templateId: string;
  employeeId: number;
  responses: AppraisalReportEvaluationResponse[];
  respondentId: number;
  approvedBy: number[];
  status: EvaluationStatus;
  archived: boolean;
  updatedBy: number;
  updatedTime: string;
};

export type AppraisalReportData = {
  employee: AppraisalReportEmployee;
  evaluations: AppraisalReportEvaluation[];
};

export type AppraisalReportUnit = {
  employees: AppraisalReportData[];
  unit: {
    id: number;
    unitName: string;
  };
};

const getUniqueStages = function(stages: Stage[]) {
  if (!stages || !stages.length) {
    return stages;
  }

  return stages.reduce((acc, stage) => {
    const isIdDuplicated = acc.find(
      existingStage => existingStage.id === stage.id,
    );
    const isNameDuplicated = acc.find(
      existingStage => existingStage.label === stage.label,
    );
    if (stage.isDeleted || isIdDuplicated || isNameDuplicated) {
      return acc;
    }

    return acc.concat(stage);
  }, []);
};

const initialState = Record({
  allAppraisalTemplates: [],
});

const FETCH_ALL_APPRAISAL_TEMPLATES =
  '@@solaforce/appraisal/FETCH_ALL_APPRAISAL_TEMPLATES';
const ADD_APPRAISAL_TEMPLATE = '@@solaforce/appraisal/ADD_APPRAISAL_TEMPLATE';
const UPDATE_APPRAISAL_TEMPLATE =
  '@@solaforce/appraisal/UPDATE_APPRAISAL_TEMPLATE';
const CHANGE_TEMPLATE_ACTIVE_STATUS =
  '@@solaforce/appraisal/CHANGE_TEMPLATE_ACTIVE_STATUS';
const DELETE_TEMPLATES = '@@solaforce/appraisal/DELETE_TEMPLATES';
const DUPLICATE_TEMPLATE = '@@solaforce/appraisal/DUPLICATE_TEMPLATE';
const ADD_TEMPLATE_SECTION = '@@solaforce/appraisal/ADD_TEMPLATE_SECTION';
const UPDATE_SECTION = '@@solaforce/appraisal/UPDATE_SECTION';
const ADD_SECTION_COMPONENT = '@@solaforce/appraisal/ADD_SECTION_COMPONENT';
const UPDATE_SECTION_COMPONENT =
  '@@solaforce/appraisal/UPDATE_SECTION_COMPONENT';
const UPDATE_SECTION_COMPONENTS_ORDER =
  '@@solafroce/apprailsal/UPDATE_SECTION_COMPONENTS_ORDER';
const DELET_COMPONENT = '@@solaforce/appraisal/DELET_COMPONENT';
const DELETE_SECTION = '@@solaforce/appraisal/DELETE_SECTION';

const reducer = (state = new initialState(), action: any) => {
  switch (action.type) {
    case FETCH_ALL_APPRAISAL_TEMPLATES:
      const { allAppraisalTemplates } = action.payload;
      const processedAppraisalTemplates = allAppraisalTemplates.map(
        (appraisalTemplate: any) => {
          return {
            ...appraisalTemplate,
            stages: getUniqueStages(appraisalTemplate.stages),
          };
        },
      );

      return state.set('allAppraisalTemplates', processedAppraisalTemplates);

    case ADD_APPRAISAL_TEMPLATE:
      const { newTemplate } = action.payload;
      return state.set('allAppraisalTemplates', [
        ...state.allAppraisalTemplates,
        newTemplate,
      ]);

    case UPDATE_SECTION:
      const { editedTmpId, updatedSection } = action.payload;
      const updatedStateAfterSectionUpdate = state
        .get('allAppraisalTemplates')
        .map((template: any) => {
          if (template.id === editedTmpId) {
            for (let i = 0; i < template.sections.length; i++) {
              if (template.sections[i].id === updatedSection.id) {
                template.sections[i] = updatedSection;
              }
            }
            return {
              ...template,
              sections: [...template.sections],
            };
          } else {
            return template;
          }
        });
      return state.set('allAppraisalTemplates', updatedStateAfterSectionUpdate);

    case UPDATE_SECTION_COMPONENTS_ORDER:
      const {
        sectionId: updateSectionId,
        components: orderedUpdateComponents = [],
      } = action.payload;
      const foundTemplate = state
        .get('allAppraisalTemplates')
        .find((template: any) => {
          return template.sections.find(
            (tSection: any) => tSection.id === updateSectionId,
          );
        });
      const updatedAllAppraisalTemplates = state
        .get('allAppraisalTemplates')
        .map((template: any) => {
          if (template.id === foundTemplate.id) {
            const updatedSections = template.sections.map((tSection: any) => {
              if (tSection.id === updateSectionId) {
                return {
                  ...tSection,
                  components: orderedUpdateComponents,
                };
              }
              return tSection;
            });
            return {
              ...template,
              sections: updatedSections,
            };
          }
          return template;
        });
      return state.set('allAppraisalTemplates', updatedAllAppraisalTemplates);

    case UPDATE_APPRAISAL_TEMPLATE:
      const { updatedTemplate } = action.payload;
      const updatedStateWTemplate = state
        .get('allAppraisalTemplates')
        .map((item: any) => {
          if (updatedTemplate.id === item.id) {
            return {
              ...updatedTemplate,
              sections: [...item.sections],
            };
          }
          return item;
        });
      return state.set('allAppraisalTemplates', updatedStateWTemplate);

    case CHANGE_TEMPLATE_ACTIVE_STATUS:
      const { templatesToUpdate, newStatus } = action.payload;
      const updatedState = state
        .get('allAppraisalTemplates')
        .map((item: any) => {
          if (templatesToUpdate.includes(item.id)) {
            return {
              ...item,
              status: newStatus,
            };
          }
          return item;
        });
      return state.set('allAppraisalTemplates', updatedState);

    case DELETE_TEMPLATES:
      const { templatesToDelete } = action.payload;
      const updatedStateAfterDelete = state
        .get('allAppraisalTemplates')
        .filter((item: any) => {
          return !templatesToDelete.includes(item.id);
        });
      return state.set('allAppraisalTemplates', updatedStateAfterDelete);

    case DUPLICATE_TEMPLATE:
      const { templateResponse } = action.payload;
      return state.set('allAppraisalTemplates', [
        ...state.allAppraisalTemplates,
        templateResponse,
      ]);

    case ADD_TEMPLATE_SECTION:
      const { templateId, section } = action.payload;
      const updatedStateWSection = state
        .get('allAppraisalTemplates')
        .map((item: any) => {
          if (templateId === item.id) {
            return {
              ...item,
              sections: [...item.sections, section],
            };
          }
          return item;
        });
      return state.set('allAppraisalTemplates', updatedStateWSection);

    case DELETE_SECTION:
      const { tempId, sectionToDeleteId } = action.payload;
      const updatedStateAfterSectionDelete = state
        .get('allAppraisalTemplates')
        .map((template: any) => {
          if (tempId === template.id) {
            return {
              ...template,
              sections: template.sections.filter(
                (sec: any) => sec.id !== sectionToDeleteId,
              ),
            };
          } else {
            return template;
          }
        });
      return state.set('allAppraisalTemplates', updatedStateAfterSectionDelete);

    case ADD_SECTION_COMPONENT:
      const { templateIdUsed, sectionId, component } = action.payload;
      const updatedStateWComponent = state
        .get('allAppraisalTemplates')
        .map((item: any) => {
          if (templateIdUsed === item.id) {
            item.sections.map((templateSection: any, index: any) => {
              if (templateSection.id === sectionId) {
                if (item.sections[index].components) {
                  item.sections[index].components = [
                    ...item.sections[index].components,
                    ...component,
                  ];
                } else {
                  item.sections[index].components = [...component];
                }
                return {
                  ...item,
                  sections: [...item.sections],
                };
              }
            });
            return {
              ...item,
              sections: [...item.sections],
            };
          }
          return item;
        });
      return state.set('allAppraisalTemplates', updatedStateWComponent);

    case UPDATE_SECTION_COMPONENT:
      const { tmpId, secId, cmp } = action.payload;
      const updatedComponent = state
        .get('allAppraisalTemplates')
        .map((item: any) => {
          if (tmpId === item.id) {
            item.sections.map((templateSection: any, index: any) => {
              if (templateSection.id === secId) {
                templateSection.components.map((comp: any, idx: any) => {
                  if (comp.id === cmp[0].id) {
                    return {
                      ...item,
                      sections: [...item.sections],
                      components: [
                        ...item.sections[index].components,
                        (item.sections[index].components[idx] = cmp[0]),
                      ],
                    };
                  }
                });
              }
            });
          }
          return item;
        });
      return state.set('allAppraisalTemplates', updatedComponent);

    case DELET_COMPONENT:
      const {
        editedTemplateId,
        editedSectionId,
        componentsToDelete,
      } = action.payload;
      const updatedSectionStateAfterDelete = state
        .get('allAppraisalTemplates')
        .map((template: any) => {
          if (editedTemplateId === template.id) {
            template.sections.map((templateSection: any, index: any) => {
              if (editedSectionId === templateSection.id) {
                if (template.sections[index].components) {
                  template.sections[index].components = template.sections[
                    index
                  ].components.filter((sectionComponent: any) => {
                    return !componentsToDelete.includes(sectionComponent.id);
                  });
                }
              }
            });
          }
          return {
            ...template,
            sections: [...template.sections],
          };
        });

      return state.set('allAppraisalTemplates', updatedSectionStateAfterDelete);
    default:
      return state;
  }
};

// >>>>>>>>>>>>>>>>>>>>>>>>> TEMPLATES  <<<<<<<<<<<<<<<<<<<<<<<<<
export const fetchAllAppraisals = () => {
  return (dispatch: Dispatch) => {
    return Service.get(
      API.appraisalsTemplates.getAllTemplates(),
      (response: any) => {
        const payload = {
          allAppraisalTemplates: response.data,
        };
        dispatch({ type: FETCH_ALL_APPRAISAL_TEMPLATES, payload });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const addAppraisalTemplate = (template: any[]) => {
  return (dispatch: Dispatch) => {
    Service.post(
      API.appraisalsTemplates.addAppraisalTemplate(),
      template,
      (response: any) => {
        response.sections = [];
        const payload = {
          newTemplate: response,
        };
        dispatch({ type: ADD_APPRAISAL_TEMPLATE, payload });
        dispatch({
          type: OPEN_NOTIFIER,
          payload: { message: translate.t('new_template_added') },
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const updateAppraisalTemplate = (
  templateId: string,
  template: any[],
) => {
  return (dispatch: Dispatch) => {
    Service.post(
      API.appraisalsTemplates.updateTemplate(templateId),
      template,
      (response: any) => {
        const payload = {
          updatedTemplate: response,
        };
        dispatch({ type: UPDATE_APPRAISAL_TEMPLATE, payload });
        dispatch({ type: OPEN_NOTIFIER, payload: {} });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const changeTemplateStatus = (templateIds: [], newStatus: string) => {
  return (dispatch: Dispatch) => {
    Service.post(
      newStatus === 'ACTIVE'
        ? API.appraisalsTemplates.activateTemplate()
        : API.appraisalsTemplates.deactivateTemplate(),
      { ids: templateIds },
      (response: any) => {
        const payload = {
          templatesToUpdate: response.data.map((template: any) => template.id),
          newStatus: newStatus,
        };
        dispatch({ type: CHANGE_TEMPLATE_ACTIVE_STATUS, payload });
        dispatch({
          type: OPEN_NOTIFIER,
          payload: { message: translate.t('template_status_updated') },
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const deleteTemplate = (templateIds: []) => {
  return (dispatch: Dispatch) => {
    Service.post(
      API.appraisalsTemplates.removeTemplates(),
      { ids: templateIds },
      (_response: any) => {
        const payload = {
          templatesToDelete: templateIds,
        };
        dispatch({ type: DELETE_TEMPLATES, payload });
        dispatch({
          type: OPEN_NOTIFIER,
          payload: { message: translate.t('template_deleted') },
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const duplicateTemplate = (templateId: string) => {
  return (dispatch: Dispatch) => {
    Service.post(
      API.appraisalsTemplates.duplicateTemplate(templateId),
      { id: templateId },
      (response: any) => {
        dispatch({
          type: DUPLICATE_TEMPLATE,
          payload: { templateResponse: response },
        });
        dispatch({
          type: OPEN_NOTIFIER,
          payload: { message: translate.t('template_duplicated') },
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

// >>>>>>>>>>>>>>>>>>>>>>>>> SECTIONS  <<<<<<<<<<<<<<<<<<<<<<<<<
export const addTemplateSection = (templateId: string, section: {}) => {
  return (dispatch: Dispatch) => {
    Service.post(
      API.appraisalsTemplates.addSection(templateId),
      section,
      (_response: any) => {
        const payload = {
          templateId: templateId,
          section: section,
        };
        dispatch({ type: ADD_TEMPLATE_SECTION, payload });
        dispatch({
          type: OPEN_NOTIFIER,
          payload: { message: translate.t('new_section_added') },
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const updateSection = (editedTmpId: any, section: any) => {
  return (dispatch: Dispatch) => {
    Service.post(
      API.appraisalsTemplates.updateSection(section.id),
      {
        name: section.name,
        empAccess: section.empAccess,
        onlyEmpAccess: section.onlyEmpAccess,
        components: section.components,
        index: section.index,
      },
      (_response: any) => {
        dispatch({
          type: UPDATE_SECTION,
          payload: { updatedSection: section, editedTmpId: editedTmpId },
        });
        dispatch({
          type: OPEN_NOTIFIER,
          payload: { message: translate.t('sectionUpdated') },
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const deleteSection = (templateId: string, sectionId: string) => {
  return (dispatch: Dispatch) => {
    Service.delete(
      API.appraisalsTemplates.deleteSection(sectionId),
      { id: sectionId },
      (_response: any) => {
        const payload = {
          tempId: templateId,
          sectionToDeleteId: sectionId,
        };
        dispatch({ type: DELETE_SECTION, payload });
        dispatch({
          type: OPEN_NOTIFIER,
          payload: { message: translate.t('section_deleted') },
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

// >>>>>>>>>>>>>>>>>>>>>>>>> Components  <<<<<<<<<<<<<<<<<<<<<<<<<
export const addComponentToSection = (
  templateId: string,
  sectionId: string,
  component: {},
) => {
  return (dispatch: Dispatch) => {
    Service.post(
      API.appraisalsTemplates.addComponent(sectionId),
      component,
      (response: any) => {
        const payload = {
          templateIdUsed: templateId,
          sectionId: sectionId,
          component: [response],
        };
        dispatch({ type: ADD_SECTION_COMPONENT, payload });
        dispatch({
          type: OPEN_NOTIFIER,
          payload: { message: translate.t('new_component_added') },
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const updateSectionComponent = (
  templateId: string,
  sectionId: string,
  component: any,
) => {
  return (dispatch: Dispatch) => {
    const { componentId, ...componentProps } = component;

    Service.post(
      API.appraisalsTemplates.updateComponent(componentId),
      componentProps,
      (response: any) => {
        const payload = {
          tmpId: templateId,
          secId: sectionId,
          cmp: [response],
        };
        dispatch({ type: UPDATE_SECTION_COMPONENT, payload });
        dispatch({
          type: OPEN_NOTIFIER,
          payload: { message: translate.t('componentUpdated') },
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const orderComponents = (sectionId: string, components: any) => {
  return (dispatch: Dispatch) => {
    const sortedComponents = components.map((comp: any) => ({
      componentId: comp.id,
      index: comp.index,
    }));
    Service.post(
      API.appraisalsTemplates.orderComponents(sectionId),
      { components: sortedComponents },
      (_response: any) => {
        dispatch({
          type: UPDATE_SECTION_COMPONENTS_ORDER,
          payload: { sectionId, components },
        });
        dispatch({
          type: OPEN_NOTIFIER,
          payload: { message: translate.t('componentUpdated') },
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const deleteComponent = (
  templateId: string,
  sectionId: string,
  componentIds: [],
) => {
  return (dispatch: Dispatch) => {
    Service.post(
      API.appraisalsTemplates.deleteComponent(),
      { ids: componentIds },
      (_response: any) => {
        const payload = {
          editedTemplateId: templateId,
          editedSectionId: sectionId,
          componentsToDelete: componentIds,
        };
        dispatch({ type: DELET_COMPONENT, payload });
        dispatch({
          type: OPEN_NOTIFIER,
          payload: { message: translate.t('component_deleted') },
        });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};
export default reducer;

import translate from '@/app/utils/translate';
import { JobEvaluationStatus, Status } from './enums';

export const sortArr = (arr, key, order) =>
  arr.sort((a, b) => {
    if (order === 'asc') {
      return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
    }
    if (order === 'desc') {
      return a[key] > b[key] ? -1 : a[key] < b[key] ? 1 : 0;
    }
    return;
  });

export const translateCompletionStatus = (completionStatus) => {
  if (completionStatus === JobEvaluationStatus.IN_PROGRESS) {
    return translate.t('laInProgress');
  }

  if (completionStatus === JobEvaluationStatus.COMPLETED) {
    return translate.t('laCompleted');
  }

  return completionStatus;
}

export const jobEvaluationStatusTranslation = (value) => {
  if (value === Status.ACTIVE) {
    return translate.t('laActive');
  }
  if (value === Status.INACTIVE) {
    return translate.t('laInactive');
  }
  return value;
};

export const empJobEvaluationStatusTranslation = (value) => {
  if (value === JobEvaluationStatus.IN_PROGRESS) {
    return translate.t('laInProgress');
  }
  if (value === JobEvaluationStatus.COMPLETED) {
    return translate.t('laCompleted');
  }
  if (value === JobEvaluationStatus.EMPLOYEE_APPROVED) {
    return translate.t('laEmployeeApproved');
  }
  return value;
};

export const orderElementsByIndex = function(list) {
  return list.slice().sort((a, b) => (a.index > b.index) ? 1 : -1);
};


export const sortByDate = (arrayOfObject) => {
  arrayOfObject.sort(function compare(a, b) {
    const dateA = new Date(a.createdTime);
    const dateB = new Date(b.createdTime);
    return dateB - dateA;
  });
  return arrayOfObject;
}

export const reIndexAndResetDefault = (arrayOfObjects) =>
  arrayOfObjects.map((eachObject, index) =>(
    {...eachObject, index, default: index === 0}
  ));

export const getLatestArchiveOfEvaluations = (archives) => {
  let usedTemplates = [];
  let latestArchives = [];
  let sortedArchives = sortByDate(archives);
  sortedArchives.forEach(archive => {
      if (!usedTemplates.includes(archive.templateId)) {
        latestArchives.push(archive);
        usedTemplates.push(archive.templateId);
      }
    });

  return latestArchives;
}

export const nameDuplicationCheck = (arrayOfObjects) => {
  const valueArr = arrayOfObjects.map(item => item.label);
  const isDuplicate = valueArr.some((item, idx) => valueArr.indexOf(item) != idx);
  return isDuplicate;
}

// Looks through the stages and returns the stage with the default flag
export const getDefault = (arrayOfObjects) => {
  const def = arrayOfObjects.find( object => object.default === true );
  return def;
}

export const titleValidation = (title) => {
  if (title === '') {
    return false;
  } else {
    return true;
  }
}

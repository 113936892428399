import { Dispatch } from 'redux';

import API from '@/app/api/internalAPIs';
import Service from '@/app/utils/service';

import { RawCollaborationTask, RawTaskComment } from '../collaborationModels';

export enum CollaborationActionKeys {
    FETCH_MY_TASKS_PENDING = '@/app/components/Collaboration/FETCH_MY_TASKS_PENDING',
    FETCH_MY_TASKS_FULFILLED = '@/app/components/Collaboration/FETCH_MY_TASKS_FULFILLED',
    FETCH_MY_TASKS_REJECTED = '@/app/components/Collaboration/FETCH_MY_TASKS_REJECTED',
    FETCH_TASK_COMMENTS_PENDING = '@/app/components/Collaboration/FETCH_TASK_COMMENTS_PENDING',
    FETCH_TASK_COMMENTS_FULFILLED = '@/app/components/Collaboration/FETCH_TASK_COMMENTS_FULFILLED',
    FETCH_TASK_COMMENTS_REJECTED = '@/app/components/Collaboration/FETCH_TASK_COMMENTS_REJECTED',
    CREATE_TASK_COMMENT_PENDING = '@/app/components/Collaboration/CREATE_TASK_COMMENT_PENDING',
    CREATE_TASK_COMMENT_FULFILLED = '@/app/components/Collaboration/CREATE_TASK_COMMENT_FULFILLED',
    CREATE_TASK_COMMENT_REJECTED = '@/app/components/Collaboration/CREATE_TASK_COMMENT_REJECTED',
    CLEAR_TASK_COMMENTS = '@/app/components/Collaboration/CLEAR_TASK_COMMENTS',
    SYNC_TASKS = '@/app/components/Collaboration/SYNC_TASKS',
    SYNC_DELETED_TASKS = '@/app/components/Collaboration/DELETE_TASKS',
    SYNC_CREATED_TASK_COMMENT = '@/app/components/Collaboration/CREATE_TASK_COMMENT',
    SYNC_DELETED_TASK_COMMENT = '@/app/components/Collaboration/DELETE_TASK_COMMENT',
}

type FetchMyTasksPendingActionType = {
    type: CollaborationActionKeys.FETCH_MY_TASKS_PENDING;
};

type FetchMyTasksFulfilledActionType = {
    type: CollaborationActionKeys.FETCH_MY_TASKS_FULFILLED;
    payload: RawCollaborationTask[];
};

type FetchMyTasksRejectedActionType = {
    type: CollaborationActionKeys.FETCH_MY_TASKS_REJECTED;
};

type FetchTaskCommentsPendingActionType = {
    type: CollaborationActionKeys.FETCH_TASK_COMMENTS_PENDING;
    meta: number;
};

type FetchTaskCommentsFulfilledActionType = {
    type: CollaborationActionKeys.FETCH_TASK_COMMENTS_FULFILLED;
    payload: RawTaskComment[];
    meta: number;
};

type FetchTaskCommentsRejectedActionType = {
    type: CollaborationActionKeys.FETCH_TASK_COMMENTS_REJECTED;
    meta: number;
};

type CreateTaskCommentPendingActionType = {
    type: CollaborationActionKeys.CREATE_TASK_COMMENT_PENDING;
    meta: number;
};

type CreateTaskCommentFulfilledActionType = {
    type: CollaborationActionKeys.CREATE_TASK_COMMENT_FULFILLED;
    payload: RawTaskComment;
    meta: number;
};

type CreateTaskCommentRejectedActionType = {
    type: CollaborationActionKeys.CREATE_TASK_COMMENT_REJECTED;
    meta: number;
};

type ClearTaskCommentsActionType = {
    type: CollaborationActionKeys.CLEAR_TASK_COMMENTS;
    meta: number;
};

type SyncTasksActionType = {
    type: CollaborationActionKeys.SYNC_TASKS;
    payload: RawCollaborationTask[];
};

type SyncDeletedTasksActionType = {
    type: CollaborationActionKeys.SYNC_DELETED_TASKS;
    payload: number[];
};

type SyncCreatedTaskCommentActionType = {
    type: CollaborationActionKeys.SYNC_CREATED_TASK_COMMENT;
    payload: RawTaskComment;
};

type SyncDeletedTaskCommentActionType = {
    type: CollaborationActionKeys.SYNC_DELETED_TASK_COMMENT;
    payload: RawTaskComment;
};

export type CollaborationActionTypes =
    | FetchMyTasksPendingActionType
    | FetchMyTasksFulfilledActionType
    | FetchMyTasksRejectedActionType
    | FetchTaskCommentsPendingActionType
    | FetchTaskCommentsFulfilledActionType
    | FetchTaskCommentsRejectedActionType
    | CreateTaskCommentPendingActionType
    | CreateTaskCommentFulfilledActionType
    | CreateTaskCommentRejectedActionType
    | ClearTaskCommentsActionType
    | SyncTasksActionType
    | SyncDeletedTasksActionType
    | SyncCreatedTaskCommentActionType
    | SyncDeletedTaskCommentActionType;

export const fetchMyTasksAction = (empId: number) => {
    return (dispatch: Dispatch) => {
        dispatch({ type: CollaborationActionKeys.FETCH_MY_TASKS_PENDING });

        Promise.all<RawCollaborationTask[]>([
            new Promise((resolve, reject) => {
                Service.get(API.collaboration.tasksByAssignee(empId), resolve, reject);
            }),
            new Promise((resolve, reject) => {
                Service.get(API.collaboration.tasksByAssignor(empId), resolve, reject);
            }),
            new Promise((resolve, reject) => {
                Service.get(API.collaboration.tasksByPertains(empId), resolve, reject);
            }),
        ]).then(([byAssignee, byAssignor, byPertains]) => {
            dispatch({
                type: CollaborationActionKeys.FETCH_MY_TASKS_FULFILLED,
                payload: [...byAssignee, ...byAssignor, ...byPertains].reduce<RawCollaborationTask[]>(
                    (accumulator, task) => {
                        if (accumulator.some(({ fTaskId }) => fTaskId === task.fTaskId)) {
                            return accumulator;
                        }

                        return [...accumulator, task];
                    },
                    []
                ),
            });
        }).catch(() => {
            dispatch({ type: CollaborationActionKeys.FETCH_MY_TASKS_REJECTED });
        });
    };
};

export const fetchTaskCommentsAction = (taskId: number) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: CollaborationActionKeys.FETCH_TASK_COMMENTS_PENDING,
            meta: taskId,
        });

        Service.get(
            API.collaboration.comments(taskId),
            (data: RawTaskComment[]) => {
                dispatch({
                    type: CollaborationActionKeys.FETCH_TASK_COMMENTS_FULFILLED,
                    payload: data,
                    meta: taskId,
                });
            },
            () => {
                dispatch({
                    type: CollaborationActionKeys.FETCH_TASK_COMMENTS_REJECTED,
                    meta: taskId,
                });
            }
        );
    };
};

export const createTaskCommentAction = (taskId: number, commentText: string) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: CollaborationActionKeys.CREATE_TASK_COMMENT_PENDING,
            meta: taskId,
        });

        Service.post(
            API.collaboration.comments(taskId),
            { fTaskCommentText: commentText },
            (data: RawTaskComment) => {
                dispatch({
                    type: CollaborationActionKeys.CREATE_TASK_COMMENT_FULFILLED,
                    payload: data,
                    meta: taskId,
                });
            },
            () => {
                dispatch({
                    type: CollaborationActionKeys.CREATE_TASK_COMMENT_REJECTED,
                    meta: taskId,
                });
            }
        );
    };
};

export const clearTaskComments = (taskId: number) => ({
    type: CollaborationActionKeys.CLEAR_TASK_COMMENTS,
    meta: taskId,
});

export const syncTasksAction = (tasks: RawCollaborationTask[]) => ({
    type: CollaborationActionKeys.SYNC_TASKS,
    payload: tasks,
});

export const syncDeletedTasksAction = (taskIds: number[]) => ({
    type: CollaborationActionKeys.SYNC_DELETED_TASKS,
    payload: taskIds,
});

export const syncCreatedTaskCommentAction = (comment: RawTaskComment) => ({
    type: CollaborationActionKeys.SYNC_CREATED_TASK_COMMENT,
    payload: comment,
});

export const syncDeletedTaskCommentAction = (comment: RawTaskComment) => ({
    type: CollaborationActionKeys.SYNC_DELETED_TASK_COMMENT,
    payload: comment,
});

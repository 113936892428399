import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, WithStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import * as React from 'react';

import translate from '@/app/utils/translate';

import { commentsDialogStyles } from './commentsDialogStyles';

export interface CommentsDialogOuterProps {
    open: boolean;
    onClose: () => void;
    taskTitle?: string;
    content?: React.JSX.Element;
    footer?: React.JSX.Element;
}

export interface CommentsDialogInnerProps extends
    CommentsDialogOuterProps,
    WithStyles<typeof commentsDialogStyles> {}

export const CommentsDialog: React.FC<CommentsDialogInnerProps> = ({
    classes,
    open,
    onClose,
    taskTitle,
    content,
    footer,
}) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth={true} disableBackdropClick={true}>
            <DialogTitle>
                <span>{translate.t('laCommentsFor')} "{taskTitle}"</span>
                <IconButton className={classes.closeButton} onClick={onClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>{content}</DialogContent>
            <DialogActions>{footer}</DialogActions>
        </Dialog>
    );
};
import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const wizardDialogStyle: StyleRulesCallback = (theme: Theme) => {

  return ({
    root: {
    },
    dialogActions: {
      padding: theme.spacing.unit,
    },
    paperWidthMd: {
      width: '500px'
    },
  });
};

export default wizardDialogStyle;

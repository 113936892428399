import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const multipleChoiceComponentStyles: StyleRulesCallback = () => ({
  typography: {
    whiteSpace: 'pre-wrap',
    paddingTop: '20px',
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontSize: '0.875em',
    fontWeight: 'bold',
  },
  checkboxes: {
    minHeight: '25px',
  },
  multiline: {
    whiteSpace: 'pre-wrap',
  },
});

export default multipleChoiceComponentStyles;

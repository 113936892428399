import * as React from 'react';
import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BlockIcon from '@material-ui/icons/Block';
import EditIcon from '@material-ui/icons/Edit';
import DuplicateIcon from '@material-ui/icons/FileCopy';
import translate from '@/app/utils/translate';
import { Tools } from '@/app/components/EnhancedTable/EnhancedTableToolbar/EnhancedTableToolbar';
import TranslateIcon from '@material-ui/icons/Translate';
import DetailsIcon from '@material-ui/icons/Description';
import { compose, withState } from 'recompose';

export type CustomRowButton = {
  label: string;
  iconButton: React.ReactNode;
  onClick: Function;
  hidden?: boolean;
};

interface PropsType {
  active: boolean;
  tools: Tools;
  editHandler: React.MouseEventHandler;
  deleteHandler: React.MouseEventHandler;
  deactivateHandler: React.MouseEventHandler;
  activateHandler: React.MouseEventHandler;
  translateHandler: React.MouseEventHandler;
  detailsHandler: React.MouseEventHandler;
  duplicateHandler: React.MouseEventHandler;
  customButtons?: CustomRowButton[] | undefined;
  data: any;
  hideTranslate?: boolean;
}

interface InnerProps extends PropsType {
  anchorEl: HTMLElement;
  setAnchorEl: (target: EventTarget) => void;
}

const RowMenu: React.FunctionComponent<InnerProps> = (props) => {
  const handleOpenMenu = (event: React.MouseEvent) => {
    event.stopPropagation(); // stop trigger the row checkbox selection
    event.preventDefault();
    props.setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (event: React.MouseEvent) => {
    event.stopPropagation(); // stop trigger the row checkbox selection
    event.preventDefault();
    props.setAnchorEl(null);
  };

  const editHandler = (event: React.MouseEvent) => {
    handleCloseMenu(event);
    props.editHandler(event);
  };

  const deleteHandler = (event: React.MouseEvent) => {
    handleCloseMenu(event);
    props.deleteHandler(event);
  };

  const deactivateHandler = (event: React.MouseEvent) => {
    handleCloseMenu(event);
    props.deactivateHandler(event);
  };

  const activateHandler = (event: React.MouseEvent) => {
    handleCloseMenu(event);
    props.activateHandler(event);
  };

  const translateHandler = (event: React.MouseEvent) => {
    handleCloseMenu(event);
    props.translateHandler(event);
  };

  const detailsHandler = (event: React.MouseEvent) => {
    handleCloseMenu(event);
    props.detailsHandler(event);
  };

  const duplicateHandler = (event: React.MouseEvent) => {
    handleCloseMenu(event);
    props.duplicateHandler(event);
  };

  const customHandler = (event: React.MouseEvent, fn?: any) => {
    handleCloseMenu(event);
    fn(props.data);
  };

  const { active, tools, anchorEl, customButtons, hideTranslate } = props;

  const {
    showEdit = true,
    showDelete = true,
    showActive = false,
    showTranslate = false,
    showDetails = false,
    showDuplicate = false,
  } = tools;

  const openMenu = Boolean(anchorEl);

  let activateButtons = null;
  if (showActive) {
    activateButtons = !active ?
      (
        <MenuItem onClick={activateHandler}>
          <ListItemIcon>
            <CheckCircleIcon />
          </ListItemIcon>
          <ListItemText
            inset
            primary={translate.t('laActivate')}
          />
        </MenuItem>
      ) : (
        <MenuItem onClick={deactivateHandler}>
          <ListItemIcon>
            <BlockIcon />
          </ListItemIcon>
          <ListItemText
            inset
            primary={translate.t('button_deactivate')}
          />
        </MenuItem>
      );
  }
  const editButton = showEdit ? (
    <MenuItem onClick={editHandler}>
      <ListItemIcon>
        <EditIcon />
      </ListItemIcon>
      <ListItemText
        inset
        primary={translate.t('laEdit')}
      />
    </MenuItem>
  ) : null;
  const translateButton = showTranslate && !hideTranslate ? (
    <MenuItem onClick={translateHandler}>
      <ListItemIcon>
        <TranslateIcon />
      </ListItemIcon>
      <ListItemText
        inset
        primary={translate.t('button_translate')}
      />
    </MenuItem>
  ) : null;
  const detailsButton = showDetails ? (
    <MenuItem onClick={detailsHandler}>
      <ListItemIcon>
        <DetailsIcon />
      </ListItemIcon>
      <ListItemText
        inset
        primary={translate.t('button_details')}
      />
    </MenuItem>
  ) : null;
  const deleteButton = showDelete ? (
    <MenuItem onClick={deleteHandler}>
      <ListItemIcon>
        <DeleteIcon />
      </ListItemIcon>
      <ListItemText
        inset
        primary={translate.t('laDelete')}
      />
    </MenuItem>
  ) : null;
  const duplicateButton = showDuplicate ? (
    <MenuItem onClick={duplicateHandler}>
      <ListItemIcon>
        <DuplicateIcon />
      </ListItemIcon>
      <ListItemText
        inset
        primary={translate.t('label_duplicate')}
      />
    </MenuItem>
  ) : null;

  if (
    !activateButtons && !editButton && !translateButton && !deleteButton && !duplicateButton &&
    (!customButtons || customButtons.length === 0)
  ) {
    return null;
  }

  const customBtns = customButtons ?
    customButtons.map((btn: any) => (
      <MenuItem onClick={(event: React.MouseEvent) => customHandler(event, btn.onClick)} key={btn.label}>
        <ListItemIcon>
          {btn.iconButton}
        </ListItemIcon>
        <ListItemText
          inset
          primary={btn.label}
        />
      </MenuItem>
    )) : null;

  return (
    <>
      <IconButton
        aria-label="More"
        aria-owns={openMenu ? 'long-menu' : null}
        aria-haspopup="true"
        onClick={handleOpenMenu}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
      >
        {customBtns}
        {editButton}
        {activateButtons}
        {translateButton}
        {detailsButton}
        {duplicateButton}
        {deleteButton}
      </Menu>
    </>
  );
};

const enhance = compose<InnerProps, PropsType>(
  withState('anchorEl', 'setAnchorEl', null)
);

export default enhance(RowMenu);

import axios from 'axios';
import Cookies from 'universal-cookie';
import { getCurrentLanguage } from '@/app/utils/helper';

const cookies = new Cookies();
const token = cookies.get('SOLAFORCE1');

export default axios.create({
  baseURL: '/d/json',
  headers: {
    Language: getCurrentLanguage(),
    'X-Solaforce-Token': token,
  },
});

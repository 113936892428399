const GLOBAL: any = window;

export default (group: string, code: string) => {
  try {
    const groupValues: any[] = GLOBAL.jsonEnumALL[group];
    const value = groupValues.find(item => item.code === code);
    return value.name;
  } catch (e) {
    return '';
  }
};

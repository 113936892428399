import * as React from 'react';
import {
  List,
  ListItem,
  Checkbox,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  ListSubheader,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import translate from '@/app/utils/translate';

interface IProps {
  indicators: any[];
  disableIndicators?: boolean;
  allIndicators?: any[];
  listTitle?: string;
  selectedIndicatorIds?: string[];
  onEdit?: (indicator: any) => void;
  onDelete?: (indicatorId: string) => void;
  onIndicatorInclude?: (uuid: string, checked: boolean) => void;
}

interface IState {
  open: boolean;
  anchorEl: any;
  selectedIndicator: any;
}

class SolaIntelGroupedIndicatorList extends React.Component<IProps, IState> {
  state = {
    open: false,
    anchorEl: null as any,
    selectedIndicator: null as any,
  };

  getChecked(indicator: any) {
    const { selectedIndicatorIds } = this.props;
    if (indicator.group) {
      return indicator.active;
    }

    if (selectedIndicatorIds) {
      return !!selectedIndicatorIds.find(
        (indicatorId) => indicator.id === indicatorId,
      );
    }

    return false;
  }

  translateIndicatorName(indicator: any): string {
    return translate.t(indicator.titleTransLabel, {
      value: indicator.config.value,
    });
  }

  getGroupedIndicatorDescription(indicator: any, indicators: any[]): string {
    const names = indicator.group.indicators.map((indGroup: any) => {
      const found = indicators.find((ind) => ind.id === indGroup.id);
      if (found) {
        return this.translateIndicatorName(found);
      }

      return '';
    });

    return names
      .filter((name: string) => name !== '')
      .join(` ${translate.t(`la${indicator.group.condition}`)} `);
  }

  handleMenuOpen = (event: any, selectedIndicator: any) => {
    const { anchorEl } = this.state;

    if (anchorEl) {
      this.setState({
        anchorEl: null,
        selectedIndicator: null,
      });
    } else {
      this.setState({
        anchorEl: event.currentTarget,
        selectedIndicator,
      });
    }
  };

  handleDelete = (selectedIndicator: any) => {
    this.setState({
      open: true,
      selectedIndicator,
    });
  };

  handleCancel = () => {
    this.setState({ open: false });
  };

  render() {
    const { anchorEl, open, selectedIndicator } = this.state;
    const {
      allIndicators,
      indicators,
      disableIndicators,
      listTitle,
      onIndicatorInclude,
      onEdit,
      onDelete,
    } = this.props;

    return (
      <>
        <List disablePadding>
          {listTitle ? <ListSubheader>{listTitle}</ListSubheader> : null}
          {indicators.map((indicator) => (
            <ListItem disableGutters key={indicator.id}>
              <Checkbox
                checked={this.getChecked(indicator)}
                disabled={onEdit || disableIndicators ? true : false}
                tabIndex={-1}
                disableRipple
                style={{ padding: '0 0 0 12px' }}
                onChange={(_event: any, checked: boolean) =>
                  onIndicatorInclude(indicator.id, checked)
                }
              />
              <ListItemText
                primary={`${
                  indicator.titleTransLabel
                    ? this.translateIndicatorName(indicator)
                    : indicator.name
                }`}
                secondary={
                  indicator.group
                    ? this.getGroupedIndicatorDescription(
                        indicator,
                        allIndicators,
                      )
                    : null
                }
              />
              {onEdit ? (
                <ListItemSecondaryAction>
                  <IconButton
                    aria-label="More"
                    aria-owns={open ? 'options-menu' : undefined}
                    aria-haspopup="true"
                    onClick={(event) => this.handleMenuOpen(event, indicator)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              ) : null}
            </ListItem>
          ))}
        </List>
        <Menu
          id="options-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => this.handleMenuOpen(null, null)}
        >
          <MenuItem
            onClick={() => {
              this.handleMenuOpen(null, null);
              onEdit(selectedIndicator);
            }}
          >
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText inset primary={translate.t('laEdit')} />
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.handleMenuOpen(null, null);
              this.handleDelete(selectedIndicator);
            }}
          >
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText inset primary={translate.t('laDelete')} />
          </MenuItem>
        </Menu>

        <Dialog
          open={open}
          onClose={this.handleCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {selectedIndicator && `${selectedIndicator.name}`}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {translate.t('confirm_delete_item_s')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel}>{translate.t('laNo')}</Button>
            <Button
              autoFocus
              color="primary"
              onClick={() => {
                onDelete(selectedIndicator.id);
                this.handleCancel();
              }}
            >
              {translate.t('laYes')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default SolaIntelGroupedIndicatorList;

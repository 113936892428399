import * as React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import {
  WithStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import componentListingStyles from './componentListingStyles';
import DeleteIcon from '@material-ui/icons/Delete';
import SortIcon from '@material-ui/icons/Sort';
import SectionTopToolbar from '@/app/components/TemplateComponents/admin/EditTemplateComponents/SectionTopToolbar/SectionTopToolbar';
import EditIcon from '@material-ui/icons/Edit';
import translate from '@/app/utils/translate';
import { orderElementsByIndex } from '@/app/components/TemplateComponents/helpers';
import SortableHOC from '@/app/components/TemplateComponents/admin/EditTemplateComponents/SortableHOC/SortableHOC';
import {
  FormTemplate,
  FormTemplateComponentDeleteCall,
  FormTemplateComponentOrderCall,
  FormTemplateComponentUpdateCall,
  FormTemplateSection,
} from '@/app/components/TemplateComponents/types';
import {
  ComponentType,
  componentTypeTranslationKeys,
} from '@/app/components/TemplateComponents/constants';
import { capitalizeFirstLetter } from '@/app/utils/helper';

type PropsType = incomingProps & WithStyles<typeof componentListingStyles>;

type incomingProps = {
  handleToolbarChange: any;
  expanded: boolean;
  addComponentToSection: FormTemplateComponentUpdateCall;
  updateSectionComponent: FormTemplateComponentUpdateCall;
  section: FormTemplateSection;
  readyToEditTemplate: FormTemplate;
  orderComponents: FormTemplateComponentOrderCall;
  deleteComponent: FormTemplateComponentDeleteCall;
  readonly?: boolean;
  onItemSelection?: (sectionId: string, selectedItemIds: string[]) => void;
  templateType: string;
};

type StateType = {
  allCheckboxesSelected: boolean;
  selectedCheckboxes: any[];
  toSort: {
    id: string;
    items: any[];
  };
  rowCount: number;
  componentType: string;
  componentEditMode: boolean;
  openAddComponentModal: boolean;
  openComponentSort: boolean;
};

class ComponentListing extends React.PureComponent<PropsType> {
  state: StateType = {
    allCheckboxesSelected: false,
    selectedCheckboxes: [],
    toSort: {
      id: '',
      items: [],
    },
    rowCount: -1,
    componentType: '',
    componentEditMode: false,
    openAddComponentModal: false,
    openComponentSort: false,
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    const prevPropsHasComponentsDefined =
      !!prevProps && !!prevProps.section && !!prevProps.section.components;

    if (
      prevPropsHasComponentsDefined &&
      prevProps.section.components.length !== -1
    ) {
      const newRowCount = this.props.section.components.length;
      this.setState({ rowCount: newRowCount });
    }
    if (
      this.state.rowCount !== prevState.rowCount &&
      prevState.rowCount !== -1
    ) {
      // On row deletion/new template add - set component's selected checkboxes state back to []
      this.setState({ selectedCheckboxes: [], allCheckboxesSelected: false });
    }
  }

  onSelectAllClick = (allComponents: []) => {
    let selectedRows = [];
    selectedRows = allComponents.map((component: any) => component.id);
    this.setState({
      allCheckboxesSelected: !this.state.allCheckboxesSelected,
      selectedCheckboxes: !this.state.allCheckboxesSelected ? selectedRows : [],
    });
  };

  handleChange = (id: string) => {
    let selectedRows = this.state.selectedCheckboxes;
    if (selectedRows.includes(id)) {
      selectedRows = selectedRows.filter(row => row !== id);
    } else {
      selectedRows = [...selectedRows, id];
    }
    this.setState({ selectedCheckboxes: selectedRows });

    if (selectedRows.length === this.state.rowCount) {
      this.setState({ allCheckboxesSelected: true });
    } else {
      this.setState({ allCheckboxesSelected: false });
    }

    if (this.props.onItemSelection) {
      this.props.onItemSelection(this.props.section.id, selectedRows);
    }
  };

  handleAddComponent = (componentType: string) => {
    this.setState({
      openAddComponentModal: true,
      componentType: componentType,
    });
  };

  handleModalClose = () => {
    this.setState({ openAddComponentModal: false });
  };

  handleEditComponentModal = (
    _templateId: string,
    _sectionId: string,
    _selectedComponentIds: any[],
    component: any,
    sideMenuControl: boolean,
  ) => {
    if (sideMenuControl) {
      this.setState({ selectedCheckboxes: [component.id] });
    }

    this.setState({
      componentType: component.type,
      componentEditMode: true,
      openAddComponentModal: true,
    });
  };

  endEditMode = () => {
    this.setState({ componentEditMode: false });
  };

  saveNewOrder = (newOrder: []) => {
    const sortedComponents = newOrder.map((component: any, index) => ({
      ...component,
      index,
    }));

    if (!this.state.toSort.id) {
      this.props.orderComponents(this.props.section.id, sortedComponents);
      return this.closeSortingModal();
    }

    const currentComp = this.props.section.components.find(
      c => c.id === this.state.toSort.id,
    );

    if (!currentComp) {
      return this.closeSortingModal();
    }

    const updatedComp = {
      ...currentComp,
      componentId: currentComp.id,
      options: sortedComponents,
    };

    this.props.updateSectionComponent(
      this.props.section.templateId,
      this.props.section.id,
      updatedComp,
    );

    return this.closeSortingModal();
  };

  handleSort = (items: any[], id: string) => () => {
    this.setState({
      toSort: {
        id,
        items,
      },
    });
  };

  closeSortingModal = () => {
    this.setState({ toSort: { id: undefined, items: [] } });
  };

  render() {
    const {
      classes,
      expanded,
      addComponentToSection,
      updateSectionComponent,
      readyToEditTemplate,
      section,
      deleteComponent,
      readonly,
      templateType,
    } = this.props;

    const {
      allCheckboxesSelected,
      selectedCheckboxes,
      openAddComponentModal,
      componentType,
      componentEditMode,
      toSort,
    } = this.state;

    const getTranslatedComponentType = (type: string) => {
      if (Object.keys(ComponentType).includes(type)) {
        return translate.t(componentTypeTranslationKeys[type]);
      }
      // TODO: Handle translation for custom component types
      return capitalizeFirstLetter(type.toLowerCase());
    };

    const orderedComponents: any = !!section.components
      ? orderElementsByIndex(section.components)
      : [];

    return orderedComponents.length === 0 ? (
      !readonly && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography>{translate.t('no_components')}.</Typography>
              </TableCell>
              <TableCell />
              <TableCell>
                <SectionTopToolbar
                  sortComponents={this.handleSort(orderedComponents, undefined)}
                  partialShow={expanded}
                  fullMenu={expanded && selectedCheckboxes.length > 0}
                  selectedComponentIds={selectedCheckboxes}
                  addComponentToSection={addComponentToSection}
                  updateSectionComponent={updateSectionComponent}
                  readyToEditTemplate={readyToEditTemplate}
                  section={section}
                  deleteComponent={deleteComponent}
                  handleEditComponentModal={this.handleEditComponentModal}
                  componentEditMode={componentEditMode}
                  endEditMode={this.endEditMode}
                  openAddComponentModal={openAddComponentModal}
                  handleAddComponent={this.handleAddComponent}
                  componentType={componentType}
                  handleModalClose={this.handleModalClose}
                  templateType={templateType}
                />
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      )
    ) : (
      <>
        <Table>
          {!readonly && (
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    indeterminate={
                      !allCheckboxesSelected && selectedCheckboxes.length > 0
                    }
                    checked={allCheckboxesSelected}
                    onChange={() => this.onSelectAllClick(orderedComponents)}
                    className={classes.topCheckbox}
                  />
                  {translate.t('item_type')}
                </TableCell>
                <TableCell align="left">
                  {translate.t('laDescription')}
                </TableCell>
                <TableCell>
                  <SectionTopToolbar
                    sortComponents={this.handleSort(
                      orderedComponents,
                      undefined,
                    )}
                    partialShow={expanded || expanded === null}
                    fullMenu={expanded && selectedCheckboxes.length > 0}
                    selectedComponentIds={selectedCheckboxes}
                    addComponentToSection={addComponentToSection}
                    updateSectionComponent={updateSectionComponent}
                    readyToEditTemplate={readyToEditTemplate}
                    section={section}
                    componentEditMode={componentEditMode}
                    deleteComponent={deleteComponent}
                    handleEditComponentModal={this.handleEditComponentModal}
                    endEditMode={this.endEditMode}
                    handleAddComponent={this.handleAddComponent}
                    openAddComponentModal={openAddComponentModal}
                    componentType={componentType}
                    handleModalClose={this.handleModalClose}
                    templateType={templateType}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {orderedComponents.map((component: any, index: number) => {
              return (
                <TableRow hover role="checkbox" key={`${index}-row`}>
                  <TableCell
                    className={classes.tablecell}
                    key={`indexTC-${component.id}`}
                    padding="checkbox"
                  >
                    <Checkbox
                      onClick={() => this.handleChange(component.id)}
                      checked={selectedCheckboxes.includes(component.id)}
                      key={`index-${component.id}`}
                    />
                    {getTranslatedComponentType(component.type)}
                  </TableCell>
                  <TableCell className={classes.multiline}>
                    {component.name}
                  </TableCell>
                  {!readonly && (
                    <TableCell align="right">
                      {[
                        ComponentType.CHECKBOXES,
                        ComponentType.DROPDOWN,
                        ComponentType.MULTISELECT_DROPDOWN,
                      ].includes(component.type) && (
                        <IconButton
                          onClick={this.handleSort(
                            component.options,
                            component.id,
                          )}
                        >
                          <Tooltip title={translate.t('laSort')}>
                            <SortIcon color="primary" />
                          </Tooltip>
                        </IconButton>
                      )}
                      <IconButton
                        onClick={() =>
                          this.handleEditComponentModal(
                            readyToEditTemplate.id,
                            section.id,
                            selectedCheckboxes,
                            component,
                            true,
                          )
                        }
                      >
                        <Tooltip title={translate.t('laEdit')}>
                          <EditIcon color="primary" />
                        </Tooltip>
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          this.props.deleteComponent(
                            readyToEditTemplate.id,
                            section.id,
                            [component.id],
                          )
                        }
                      >
                        <Tooltip title={translate.t('laDelete')}>
                          <DeleteIcon color="primary" />
                        </Tooltip>
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <SortableHOC
          open={!!toSort.items.length}
          itemsToSort={toSort.items}
          saveNewOrder={this.saveNewOrder}
          cancelNewOrder={this.closeSortingModal}
        />
      </>
    );
  }
}

const enhance = compose<any, any>(withStyles(componentListingStyles));

export default enhance(ComponentListing);

import * as React from 'react';
import classNames from 'classnames';
import {
  Toolbar,
  Tooltip,
  Typography,
  IconButton,
  WithStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BlockIcon from '@material-ui/icons/Block';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core/styles';
import enhancedTableToolbarStyle from './enhancedTableToolbarStyle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import TranslateIcon from '@material-ui/icons/Translate';
import DetailsIcon from '@material-ui/icons/Description';
import translate from '@/app/utils/translate';
import SortIcon from '@material-ui/icons/Sort';
import DuplicateIcon from '@material-ui/icons/FileCopy';
import { generateUniqueId } from '@/app/utils/helper';

/**
 *  Toolbar's buttons configs
 */
export type Tools = {
  showAdd?: boolean;
  showEdit?: boolean;
  showActive?: boolean;
  showDelete?: boolean;
  showTranslate?: boolean;
  showDetails?: boolean;
  showSort?: boolean;
  showDeleteItemIcon?: boolean;
  showDuplicate?: boolean;
};

export type HideTools = {
  hideTranslate?: boolean;
  hideDelete?: boolean;
};

export type CustomToolbarButton = {
  iconButton: React.ReactNode;
  onClick: Function;
  tooltip?: string;
  type?: string;
};

interface PropsType extends WithStyles<typeof enhancedTableToolbarStyle, true> {
  numSelected: number; // selected items count
  title: string; // table title, optional in table props
  customTitle: React.ReactNode | null; // custom component to use beside or in place of title, optional
  tools: Tools;
  customTools?: React.ReactNode | null; // custom component for tools to left of standard tools, optional
  customButtons?: CustomToolbarButton[] | undefined;
  foldable: boolean;
  expanded: boolean;
  titleClickHandler: React.MouseEventHandler;
  addHandler: React.MouseEventHandler;
  editHandler: React.MouseEventHandler;
  deleteHandler: React.MouseEventHandler;
  deleteCurrentItemHandler: React.MouseEventHandler;
  deactivateHandler: React.MouseEventHandler;
  activateHandler: React.MouseEventHandler;
  translateHandler: React.MouseEventHandler;
  detailsHandler: React.MouseEventHandler;
  sortHandler: React.MouseEventHandler;
  duplicateHandler: React.MouseEventHandler;
  hideTools: HideTools;
  data: any;
}

const EnhancedTableToolbar: React.FunctionComponent<PropsType> = props => {
  const {
    numSelected,
    classes,
    title,
    customTitle,
    tools,
    foldable,
    expanded,
    customTools = null,
    customButtons = undefined,
    hideTools = {
      hideDelete: false,
      hideTranslate: false,
    },
    data,
  } = props;
  const {
    showAdd = false,
    showEdit = false,
    showActive = false,
    showDelete = false,
    showTranslate = false,
    showDetails = false,
    showSort = false,
    showDuplicate = false,
    showDeleteItemIcon = false,
  } = tools;

  const { hideDelete, hideTranslate } = hideTools;

  // disable buttons when 0 selected
  // Currently disabled button will be hidden
  const disableButton = numSelected === 0;

  const renderedCustomButtons = customButtons
    ? customButtons.map((btn: any) => (
        <Tooltip title={btn.tooltip || ''} key={generateUniqueId()}>
          <IconButton
            color="primary"
            component="span"
            onClick={(_event: React.MouseEvent) => btn.onClick(data)}
          >
            {btn.iconButton}
          </IconButton>
        </Tooltip>
      ))
    : null;

  const itemSelectedTools = (
    <>
      {customTools}
      {showSort ? (
        <Tooltip title={translate.t('laSort')}>
          <IconButton
            color="primary"
            component="span"
            onClick={props.sortHandler}
          >
            <SortIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      {showAdd ? (
        <Tooltip title={translate.t('laAdd')}>
          <IconButton
            color="primary"
            component="span"
            onClick={props.addHandler}
          >
            <AddCircleIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      {renderedCustomButtons}
      {showEdit && numSelected === 1 ? (
        <Tooltip title={translate.t('laEdit')}>
          <IconButton
            color="primary"
            component="span"
            onClick={props.editHandler}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      {showTranslate && numSelected === 1 && !hideTranslate ? (
        <Tooltip title={translate.t('button_translate')}>
          <IconButton
            color="primary"
            component="span"
            onClick={props.translateHandler}
          >
            <TranslateIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      {showDetails && numSelected === 1 ? (
        <Tooltip title={translate.t('button_details')}>
          <IconButton
            color="primary"
            component="span"
            onClick={props.detailsHandler}
          >
            <DetailsIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      {showActive && !disableButton ? (
        <>
          <Tooltip title={translate.t('laActivateSelected')}>
            <IconButton
              color="primary"
              component="span"
              onClick={props.activateHandler}
            >
              <CheckCircleIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={translate.t('laDeactivateSelected')}>
            <IconButton
              color="primary"
              component="span"
              onClick={props.deactivateHandler}
            >
              <BlockIcon />
            </IconButton>
          </Tooltip>
        </>
      ) : null}
      {showDuplicate && numSelected === 1 ? (
        <Tooltip title={translate.t('label_duplicate')}>
          <IconButton
            color="primary"
            component="span"
            onClick={props.duplicateHandler}
          >
            <DuplicateIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      {showDelete && !disableButton && !hideDelete ? (
        <Tooltip title={translate.t('laDelete')}>
          <IconButton
            color="primary"
            component="span"
            disabled={disableButton}
            onClick={props.deleteHandler}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      {showDeleteItemIcon && !(showDelete && !disableButton && !hideDelete) ? (
        <Tooltip title={translate.t('laDelete')}>
          <IconButton
            color="primary"
            component="span"
            onClick={props.deleteCurrentItemHandler}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </>
  );

  const ExpandIcon = expanded ? ExpandLessIcon : ExpandMoreIcon;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title} onClick={props.titleClickHandler}>
        <Typography
          variant="h6"
          id="tableTitle"
          style={{ cursor: foldable ? 'pointer' : 'auto' }}
        >
          {foldable ? (
            <IconButton style={{ marginLeft: -12 }}>
              <ExpandIcon fontSize="small" />
            </IconButton>
          ) : null}
          {title}
          {customTitle}
          {numSelected > 0 ? (
            <span className={classes.subHeader}>
              {translate.t('x_selected', { count: numSelected })}
            </span>
          ) : null}
        </Typography>
      </div>
      <div className={classes.actions}>{itemSelectedTools}</div>
    </Toolbar>
  );
};

export default withStyles(enhancedTableToolbarStyle, { withTheme: true })(
  EnhancedTableToolbar,
);

import * as React from 'react';
import {
  Icon,
  Typography,
  withStyles,
  WithStyles,
  AppBar,
  Toolbar,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import styles from '../dockablePopupStyle';

interface IProps {
  title: string;
  iconName?: string;
  customTools?: React.ReactNodeArray;
  secondaryToolbar?: React.ReactElement<typeof Toolbar>;
  onClose?: Function;
}

const DockablePopupTitlebar: React.FunctionComponent<IProps & WithStyles<typeof styles>> = (props) => {
  const { classes, iconName, title, secondaryToolbar, onClose } = props;

  const icon: React.ReactElement<typeof Icon> = !iconName ? null : (
    <Icon
      classes={{
        root: classes.toolbarIcon
      }}
      color="inherit"
    >
      {iconName}
    </Icon>
  );

  return (
    <AppBar position="sticky" component="div" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        {icon}
        <Typography
          className={[classes.toolbarTitle, classes.grow].join(' ')}
          variant="subtitle2"
          color="inherit"
        >
          {title}
        </Typography>
        <IconButton
           className={classes.toolbarIconButton}
           color="inherit"
           aria-label="Close"
           onClick={() => onClose()}
        >
          <CloseIcon className={classes.toolbarIcon} />
        </IconButton>
      </Toolbar>
      {secondaryToolbar}
    </AppBar>
  );
};

export default withStyles(styles, {withTheme: true})(DockablePopupTitlebar);
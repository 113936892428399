import * as React from 'react';
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Divider,
  ListSubheader,
} from '@material-ui/core';
import DropdownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import translate from '@/app/utils/translate';
import BonusCalcRuleMenu, {
  openMenu as openRuleMenu,
} from '../BonusCalcRuleMenu/BonusCalcRuleMenu';

type PropsType = {
  label: string;
  rules: any[];
  programs: any[];
  onClick: Function;
};

interface State {
  menuAnchorEl: any;
  submenuAnchorEl: any;
  selectedSubMenu: string;
}

class BonusCalcApplyRuleColumnHeader extends React.Component<PropsType> {
  state: State = {
    menuAnchorEl: null,
    submenuAnchorEl: null,
    selectedSubMenu: '',
  };

  handleButtonClick = (evt: any) => {
    this.setState({ menuAnchorEl: evt.currentTarget });
  };

  handleProgramClick = (program: any, evt: any) => {
    openRuleMenu(
      evt.currentTarget,
      this.props.rules,
      (newRule: any, clickEvt: React.MouseEvent) =>
        this.handleRuleClick(program, newRule, clickEvt),
    );
  };

  handleClose = () => {
    this.setState({ menuAnchorEl: null, submenuAnchorEl: null });
  };

  handleRuleClick = (program: any, newRule: any, evt: any) => {
    this.props.onClick(program, newRule, evt);
    this.handleClose();
  };

  render() {
    const { label, programs } = this.props;
    const { menuAnchorEl } = this.state;
    return (
      <React.Fragment>
        <BonusCalcRuleMenu />
        <Button
          variant="text"
          color="primary"
          aria-owns={menuAnchorEl ? 'apply-rules-menu' : undefined}
          aria-haspopup={true}
          onClick={this.handleButtonClick}
        >
          {label}
          <DropdownIcon />
        </Button>
        <Menu
          id="apply-rules-menu"
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={evt => this.handleProgramClick(null, evt)}>
            <ListItemText>
              {translate.t('apply_to_all_incentives')}
            </ListItemText>
            <ListItemIcon>
              <ArrowRightIcon />
            </ListItemIcon>
          </MenuItem>
          <Divider />
          <ListSubheader>
            {translate.t('apply_only_to_incentive')}
          </ListSubheader>
          {programs.map((program: any) => (
            <MenuItem
              onClick={evt => this.handleProgramClick(program, evt)}
              key={program.id}
            >
              <ListItemText>{program.name}</ListItemText>
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
            </MenuItem>
          ))}
        </Menu>
      </React.Fragment>
    );
  }
}

export default BonusCalcApplyRuleColumnHeader;

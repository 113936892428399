import {
  Button,
  Icon,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { DatePicker } from 'material-ui-pickers';
import moment from 'moment';
import React, { useState, useCallback, useRef } from 'react';

import { LocalizationProvider } from '@/app/components/Pickers/LocalizationProvider/LocalizationProvider';
import translate from '@/app/utils/translate';
import { DATE_FORMAT } from '@/app/utils/helper';
import { ExpiresAction, COMPLETION_STATUS } from '../../../../types';
import { getCompletionStatusName } from '../../../../helpers';

type OwnProps = {
  className?: string;
  readOnly?: boolean;
  onChangeExpires: (action: ExpiresAction, expires?: moment.Moment) => void;
};
type InnerProps = {};
type Props = OwnProps & InnerProps;

const ExpiresHeadButton = ({
  className,
  readOnly = false,
  onChangeExpires,
}: Props) => {
  const pickerRef = useRef(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(undefined);

  // const handleChangeExpires = (action: ExpiresAction) => {
  //   setMenuAnchorEl(undefined);
  //   onChangeExpires(action);
  // };

  const openPicker = useCallback(
    e => {
      if (pickerRef.current) {
        pickerRef.current.open(e);
      }
    },
    [pickerRef.current]
  );

  // const closePicker = useCallback(
  //   () => {
  //     if (pickerRef.current) {
  //       pickerRef.current.close();
  //     }
  //   },
  //   [pickerRef.current]
  // );

  const handleDateChangeToDefault = () => {
    onChangeExpires('CHANGE_ALL_COMPLETED_TO_DEFAULT');
    setMenuAnchorEl(undefined);
  };

  const handleDateChange = (newDate: moment.Moment) => {
    onChangeExpires('CHANGE_ALL_COMPLETED_TO', newDate);
    // closePicker();
    setMenuAnchorEl(undefined);
  };

  const handleClose = () => {
    // closePicker();
    setMenuAnchorEl(undefined);
  };

  if (readOnly) {
    return <Typography variant="inherit">{translate.t('laExpires')}</Typography>;
  }

  return (
    <>
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(undefined)}
      >
        <MenuItem onClick={handleDateChangeToDefault}>
          <Typography variant="inherit">
            {translate.t(
              'label_set_all_statuses_to_training_expiration_date',
              { status: getCompletionStatusName(COMPLETION_STATUS.COMPLETED).toLocaleUpperCase() }
            )}
          </Typography>
        </MenuItem>
        <MenuItem onClick={e => openPicker(e)}>
          <Typography variant="inherit">
            {translate.t(
              'label_set_all_statuses_to_date',
              { status: getCompletionStatusName(COMPLETION_STATUS.COMPLETED).toLocaleUpperCase() }
            )}
          </Typography>
          <LocalizationProvider>
            <DatePicker
              autoOk={true}
              // disabled={disabled}
              okLabel={translate.t('laOk')}
              cancelLabel={translate.t('laCancel')}
              clearLabel={translate.t('laClear')}
              format={DATE_FORMAT}
              label={translate.t('laExpires')}
              value={null}
              ref={pickerRef}
              TextFieldComponent={(): any => null}
              // TextFieldComponent={() => (
              //   <Button
              //     // className={className}
              //     color="default"
              //     variant="contained"
              //     size="small"
              //     onClick={openPicker}
              //   >
              //     {!date ? '' : date.format(DATE_FORMAT)}
              //     <Icon>arrow_drop_down</Icon>
              //   </Button>
              // )}
              // onBlur={field.onBlur}
              onClose={handleClose}
              onChange={newDate => handleDateChange(newDate)}
            />
          </LocalizationProvider>
        </MenuItem>
      </Menu>
      <Button className={className} color="inherit" onClick={e => setMenuAnchorEl(e.target)}>
        {translate.t('laExpires')}
        <Icon>arrow_drop_down</Icon>
      </Button>
    </>
  );
};

export default ExpiresHeadButton;

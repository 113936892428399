import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import moment from 'moment';
import * as React from 'react';

import { getEnums } from '@/old/utils/helper';

import { CustomFieldInnerProps } from './customFieldModels';
import { CustomFieldType } from './types';
import { DATE_FORMAT } from '@/app/utils/helper';

const getEnumOptionValue = (group: string, code: string) => {
  const option = getEnums(group).find(o => o.code === code);
  return option ? option.name : code;
};

const inputIsEnumerable = (customFieldType: CustomFieldType) => {
  return (
    customFieldType === CustomFieldType.ENUM ||
    customFieldType === CustomFieldType.MULTISELECT
  );
};

export const useCustomField = ({
  formValues,
  fieldDetails,
  updateFormValues,
  setValue,
}: CustomFieldInnerProps) => {
  const value = fieldDetails.value;
  const formattedValue: React.ReactNode = React.useMemo(() => {
    if (value === undefined || value === '') {
      return value;
    }

    switch (fieldDetails.inputType.value) {
      case CustomFieldType.BOOLEAN: {
        return value === 'true' ? <CheckBox /> : <CheckBoxOutlineBlank />;
      }
      case CustomFieldType.DATE: {
        return moment(value).format('L');
      }
      case CustomFieldType.ENUM: {
        return getEnumOptionValue(fieldDetails.groupName, `${value}`);
      }
      case CustomFieldType.MULTISELECT: {
        if (Array.isArray(value)) {
          return (value as string[])
            .map(code => {
              return getEnumOptionValue(fieldDetails.groupName, code);
            })
            .join(', ');
        } else {
          return getEnumOptionValue(fieldDetails.groupName, `${value}`);
        }
      }
      default: {
        return value;
      }
    }
  }, [fieldDetails, value]);

  const options = React.useMemo(() => {
    if (!inputIsEnumerable(fieldDetails.inputType.value)) {
      return [];
    }

    return getEnums(fieldDetails.groupName)
      .filter(({ isActive }) => isActive !== false)
      .map(({ name, code }) => ({ label: name, value: code }));
  }, [fieldDetails]);
  const inputValue = formValues && formValues[fieldDetails.code];

  const selectedOption = React.useMemo(() => {
    if (!inputIsEnumerable(fieldDetails.inputType.value)) {
      return null;
    }

    return Array.isArray(inputValue)
      ? options.filter(o => inputValue.includes(o.value))
      : options.find(o => o.value === inputValue);
  }, [fieldDetails, inputValue, options]);

  const handleChange = React.useCallback(
    (event: unknown, ...otherArgs: unknown[]) => {
      switch (fieldDetails.inputType.value) {
        case CustomFieldType.BOOLEAN: {
          const [checked] = otherArgs as [boolean];

          updateFormValues({
            [fieldDetails.code]: checked ? 'true' : undefined,
          });

          return;
        }
        case CustomFieldType.DATE: {
          setValue(
            fieldDetails.code,
            moment(event && (event as moment.Moment).format('L')).format(
              DATE_FORMAT,
            ) || undefined,
          );

          return;
        }
        case CustomFieldType.ENUM: {
          setValue(
            fieldDetails.code,
            (event && (event as { value: string }).value) || undefined,
          );

          return;
        }
        case CustomFieldType.MULTISELECT: {
          setValue(
            fieldDetails.code,
            (event && (event as Array<{ value: string }>).map(o => o.value)) ||
              [],
          );

          return;
        }
        default: {
          updateFormValues({
            [fieldDetails.code]: (event as React.ChangeEvent<HTMLInputElement>)
              .target.value,
          });
        }
      }
    },
    [fieldDetails, updateFormValues],
  );

  const handleClearValue = React.useCallback(() => {
    setValue(fieldDetails.code, '');
  }, [fieldDetails, setValue]);

  return {
    formattedValue,
    options,
    inputValue,
    selectedOption,
    handleChange,
    handleClearValue,
  };
};

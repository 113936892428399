export const hasValidExtension = (
  fileName: string,
  exts: string[],
): boolean => {
  const beginsWithDotRgx = /^(\.).*/i;
  const extensions = exts.map(e => (beginsWithDotRgx.test(e) ? e : `.${e}`));
  const validExtensionsRgx = new RegExp(
    '(' + extensions.join('|').replace(/\./g, '\\.') + ')$',
    'i',
  );
  return validExtensionsRgx.test(fileName);
};

export const hasValidCSVMimeType = (mimeType: string): boolean => {
  /**
   * This list may need periodical updates as mime type information
   * provided by browser may differ depending on applications installed on user OS
   */
  const possibleCSVMimeTypes: string[] = [
    'text/plain',
    'text/x-csv',
    'application/vnd.ms-excel',
    'application/csv',
    'application/x-csv',
    'text/csv',
    'text/comma-separated-values',
    'text/x-comma-separated-values',
    'text/tab-separated-values',
  ];
  return possibleCSVMimeTypes.includes(mimeType);
};

export enum FileExtension {
  XLSX = 'xlsx',
  XLS = 'xls',
  CSV = 'csv',
  PDF = 'pdf',
}

export class ExportFilename {
  fileName: string;

  constructor(filename: string) {
    // TODO: Convert provided filename string to be more web-safe string
    this.fileName = filename;
  }

  private getWithExtension(ext: string | FileExtension): string {
    return hasValidExtension(this.fileName, [ext])
      ? this.fileName
      : `${this.fileName}.${ext.replace(/^\./g, '')}`;
  }

  extension(extension: string | FileExtension) {
    return this.getWithExtension(extension);
  }

  XLSX() {
    return this.getWithExtension(FileExtension.XLSX);
  }

  XLS() {
    return this.getWithExtension(FileExtension.XLS);
  }

  CSV() {
    return this.getWithExtension(FileExtension.CSV);
  }

  PDF() {
    return this.getWithExtension(FileExtension.PDF);
  }
}

import createDataContext from './createDataContext';
import solaforce from '../api/solaforce';
import {
  CompanyTraining,
  IntCompanyTraining,
  CompanyTrainingParticipantRegistration,
  ApprovalAction,
  APPROVAL_STATUS,
  SEAT_STATUS,
  HoursAction,
  CompletionAction,
  ExpiresAction,
  UpdateTrainingHistoryAction,
  COMPLETION_STATUS,
  EmployeeTraining,
  ParticipantSummary,
} from '../types';
import reducer, { ACTIONS } from './trainingsContext/reducer';
import {
  mapCompanyTrainingForBE,
  mapCompanyTrainingForUI,
  mapParticipantRegistrationForBE,
  mapEmployeeTrainingHistoryForBE,
  mapParticipantRegistrationForUI,
  mapEmployeeTrainingHistoryForUI,
} from './trainingsContext/dataMappers';
import Service from '@/app/utils/service';
import {
  UnitOrgTreeItem,
  downloadAttachment,
  getLoggedUserId,
  getSelectedEmpId,
} from '@/old/utils/helper';
import {
  calculateParticipantSummary,
  getApprovalStatusName,
  getCompletionStatusName,
  sortByDate,
  sortAndPositionParticipants,
  canAccessParticipantList,
} from '../helpers';
import moment from 'moment';
import { getFormData } from '@/app/utils/helper';

const GLOBAL: any = window;

const ENDPOINTS = {
  companyTrainings: () => '/dev/edu/company/trainings',
  companyTraining: (trainingId: number) =>
    `/dev/edu/company/trainings/${trainingId}`,
  publish: (trainingId: number) =>
    `/dev/edu/company/trainings/${trainingId}/publish`,
  unpublish: (trainingId: number) =>
    `/dev/edu/company/trainings/${trainingId}/unpublish`,
  participants: (trainingId: number) =>
    `/dev/edu/company/trainings/${trainingId}/participants`,
  participant: (trainingId: number, reqId: string) =>
    `/dev/edu/company/trainings/${trainingId}/participant/${reqId}`,
  saveParticipants: (trainingId: number) =>
    `/dev/edu/company/trainings/${trainingId}/save-list`,
  employeeAttachment: (empId: number, fileId: number) =>
    `/employee/${empId}/file/${fileId}`,
  oncomingEmployeeTrainings: (empId: number) =>
    `/dev/edu/company/trainings/employee/${empId}/oncoming`,
  trainingCalendarForEmployee: (empId: number, showAll: boolean = false) => {
    const showAllParam = !showAll ? '' : '?showAll=true';
    return `/dev/edu/company/trainings/employee/${empId}/available${showAllParam}`;
  },
  trainingHistoryForEmployee: (
    empId: number,
    type: 'internal' | 'external' | 'other',
  ) => `/employee/${empId}/dev/edu/training/${type}`,
  selfRegisterForTraining: (trainingId: number) =>
    `/dev/edu/company/trainings/${trainingId}/register`,
  employeeTraining: (
    empId: number,
    trainingId: number,
    type: 'internal' | 'external' | 'other',
  ) => `/employee/${empId}/dev/edu/training/${type}/${trainingId}`,
  employeeTrainings: (empId: number, type: 'internal' | 'external' | 'other') =>
    `/employee/${empId}/dev/edu/training/${type}`,
  unitOrgTree: () => '/org/tree/1/unit',
  trainingCancellation: (trainingId: number, regId: string) =>
    `/dev/edu/company/trainings/${trainingId}/oncoming/${regId}`,
};

type CompanyTrainingsState = {
  data: CompanyTraining[];
  orgTree: UnitOrgTreeItem[];
  loading: boolean;
  loadingId?: number;
  error: string;
};

type CompanyTrainingParticipantsState = {
  data: CompanyTrainingParticipantRegistration[];
  loading: boolean;
  error: string;
};

type ParticipantSummariesState = {
  data: ParticipantSummary[];
  loading: boolean;
  error: string;
};

type OncomingTrainingsState = {
  data: CompanyTraining[];
  loading: boolean;
  error: string;
};

type EmployeeTrainingCalendarState = {
  data: CompanyTraining[];
  loading: boolean;
  error: string;
};

type EmployeeTrainingHistoryState = {
  data: EmployeeTraining[];
  loading: boolean;
  error: string;
};

type SelfRegisteringState = {
  registering: boolean;
  error: string;
};

type EmployeeTrainingRegistrationState = {
  data: CompanyTrainingParticipantRegistration[];
  loading: boolean;
  error: string;
};

type EmployeeTrainingsState = {
  oncoming: OncomingTrainingsState;
  calendar: EmployeeTrainingCalendarState;
  history: EmployeeTrainingHistoryState;
  selfRegistering: SelfRegisteringState;
  registrations: EmployeeTrainingRegistrationState;
};

export type TrainingsContextState = {
  companyTrainings: CompanyTrainingsState;
  participants: CompanyTrainingParticipantsState;
  participantSummaries: ParticipantSummariesState;
  employeeTrainings: EmployeeTrainingsState;
};

const initialState: TrainingsContextState = {
  companyTrainings: {
    data: [],
    orgTree: [],
    loading: false,
    error: undefined,
  },
  participants: {
    data: [],
    loading: false,
    error: undefined,
  },
  participantSummaries: {
    data: [],
    loading: false,
    error: undefined,
  },
  employeeTrainings: {
    oncoming: {
      data: [],
      loading: false,
      error: undefined,
    },
    calendar: {
      data: [],
      loading: false,
      error: undefined,
    },
    history: {
      data: [],
      loading: false,
      error: undefined,
    },
    selfRegistering: {
      registering: false,
      error: undefined,
    },
    registrations: {
      data: [],
      loading: false,
      error: undefined,
    },
  },
};

const getCompanyTrainings = (dispatch: any) => {
  return async () => {
    try {
      dispatch({ type: ACTIONS.START_LOADING_COMPANY_TRAININGS });
      const response = await solaforce.get(ENDPOINTS.companyTrainings());
      dispatch({
        type: ACTIONS.UPDATE_COMPANY_TRAININGS,
        payload: response.data
          .map((item: any) => mapCompanyTrainingForUI(item.training))
          .sort((a: any, b: any) => sortByDate(b.startDate, a.startDate)),
      });

      // Get participants for each training to collect summary info
      // TODO! THIS SHOULD BE REPLACED BY SERVER SIDE SUMMARY COLLECTION
      response.data.map((item: any) => {
        const training = mapCompanyTrainingForUI(item.training);
        solaforce
          .get(ENDPOINTS.participants(training.id))
          .then(participantsRes => {
            dispatch({
              type: ACTIONS.ADD_OR_UPDATE_PARTICIPANT_SUMMARY,
              payload: calculateParticipantSummary(
                training,
                sortAndPositionParticipants(
                  training,
                  participantsRes.data.map((p: any[]) =>
                    mapParticipantRegistrationForUI(p),
                  ),
                ),
              ),
            });
          });
      });
    } catch (e) {
      dispatch({ type: ACTIONS.SET_COMPANY_TRAININGS_ERROR, payload: e });
    }
  };
};

const updateCompanyTrainingInt = async (
  trainingId: string,
  int: IntCompanyTraining['int'],
) => {
  if (!GLOBAL.jsIsNewDeploymentType()) {
    return;
  }

  await solaforce.put(
    `/translations/entity/COMPANY_TRAINING/${trainingId}/description`,
    Object.keys(int.description).reduce(
      (acc, lang) => {
        if (int.description[lang]) {
          acc.translations.push({
            locale: lang,
            translation: int.description[lang],
          });
        }

        return acc;
      },
      { translations: [] },
    ),
  );
};

const addCompanyTraining = (dispatch: any) => {
  return async ({ int, ...newTraining }: IntCompanyTraining) => {
    try {
      dispatch({ type: ACTIONS.START_LOADING_COMPANY_TRAININGS });

      const response = await new Promise((resolve, reject) => {
        Service.post(
          `/d/json${ENDPOINTS.companyTrainings()}`,
          mapCompanyTrainingForBE(newTraining, true),
          resolve,
          reject,
        );
      });

      const training = mapCompanyTrainingForUI(response);
      await updateCompanyTrainingInt(training.id.toString(), int);

      dispatch({ type: ACTIONS.ADD_COMPANY_TRAINING, payload: training });

      dispatch({
        type: ACTIONS.ADD_OR_UPDATE_PARTICIPANT_SUMMARY,
        payload: {
          trainingId: training.id,
          totalRegistrants: 0,
          approvals: { waiting: 0, approved: 0, rejected: 0, cancelled: 0 },
          seatings: { guaranteedSeat: 0, seat: 0, waitingSeat: 0, noSeat: 0 },
          completions: {
            completed: 0,
            completedPartly: 0,
            didNotShowUp: 0,
            didNotComplete: 0,
            pending: 0,
          },
        } as ParticipantSummary,
      });
    } catch (e) {
      dispatch({ type: ACTIONS.SET_COMPANY_TRAININGS_ERROR, payload: e });
    }
  };
};

const updateCompanyTraining = (dispatch: any) => {
  return async ({ int, ...updatedTraining }: IntCompanyTraining) => {
    try {
      dispatch({
        type: ACTIONS.START_LOADING_COMPANY_TRAINING,
        payload: { id: updatedTraining.id },
      });
      const response = await solaforce.put(
        ENDPOINTS.companyTraining(updatedTraining.id),
        mapCompanyTrainingForBE(updatedTraining),
      );
      const training = mapCompanyTrainingForUI(response.data);
      await updateCompanyTrainingInt(training.id.toString(), int);
      dispatch({ type: ACTIONS.UPDATE_COMPANY_TRAINING, payload: training });
      getParticipants(dispatch)(training);
    } catch (e) {
      dispatch({ type: ACTIONS.SET_COMPANY_TRAININGS_ERROR, payload: e });
    }
  };
};

const deleteCompanyTraining = (dispatch: any) => {
  return async (id: number) => {
    try {
      dispatch({
        type: ACTIONS.START_LOADING_COMPANY_TRAINING,
        payload: { id },
      });
      await solaforce.delete(ENDPOINTS.companyTraining(id));
      dispatch({ type: ACTIONS.DELETE_COMPANY_TRAINING, payload: { id } });
    } catch (e) {
      dispatch({ type: ACTIONS.SET_COMPANY_TRAININGS_ERROR, payload: e });
    }
  };
};

const publishCompanyTraining = (dispatch: any) => {
  return async (id: number) => {
    dispatch({ type: ACTIONS.START_LOADING_COMPANY_TRAINING, payload: { id } });
    Service.post(
      `/d/json${ENDPOINTS.publish(id)}`,
      {},
      (response: any) => {
        dispatch({
          type: ACTIONS.UPDATE_COMPANY_TRAINING,
          payload: mapCompanyTrainingForUI(response),
        });
      },
      (e: any) => {
        dispatch({ type: ACTIONS.SET_COMPANY_TRAININGS_ERROR, payload: e });
      },
    );
  };
};

const unpublishCompanyTraining = (dispatch: any) => {
  return async (id: number) => {
    dispatch({ type: ACTIONS.START_LOADING_COMPANY_TRAINING, payload: { id } });
    Service.post(
      `/d/json${ENDPOINTS.unpublish(id)}`,
      {},
      (response: any) => {
        dispatch({
          type: ACTIONS.UPDATE_COMPANY_TRAINING,
          payload: mapCompanyTrainingForUI(response),
        });
      },
      (e: any) => {
        dispatch({ type: ACTIONS.SET_COMPANY_TRAININGS_ERROR, payload: e });
      },
    );
  };
};

/**
 * Participants for company trainings
 */
const getParticipants = (dispatch: any) => {
  return async (training: CompanyTraining) => {
    try {
      dispatch({ type: ACTIONS.START_LOADING_PARTICIPANTS });
      const response = await solaforce.get(ENDPOINTS.participants(training.id));
      const participants = sortAndPositionParticipants(
        training,
        response.data.map((item: any) => mapParticipantRegistrationForUI(item)),
      );
      dispatch({ type: ACTIONS.UPDATE_PARTICIPANTS, payload: participants });
      dispatch({
        type: ACTIONS.ADD_OR_UPDATE_PARTICIPANT_SUMMARY,
        payload: calculateParticipantSummary(training, participants),
      });
    } catch (e) {
      dispatch({ type: ACTIONS.SET_PARTICIPANTS_ERROR, payload: e });
    }
  };
};

const saveParticipants = (dispatch: any) => {
  return async (
    training: CompanyTraining,
    newRegistrations: CompanyTrainingParticipantRegistration[],
  ) => {
    dispatch({ type: ACTIONS.START_LOADING_PARTICIPANTS });
    const data = {
      registrations: newRegistrations.map((p) =>
        mapParticipantRegistrationForBE(p),
      ),
    };
    Service.post(
      `/d/json${ENDPOINTS.saveParticipants(training.id)}`,
      data,
      (response: any) => {
        const registrations = sortAndPositionParticipants(
          training,
          response.map((item: any) => mapParticipantRegistrationForUI(item)),
        );
        dispatch({ type: ACTIONS.UPDATE_PARTICIPANTS, payload: registrations });
        dispatch({
          type: ACTIONS.ADD_OR_UPDATE_PARTICIPANT_SUMMARY,
          payload: calculateParticipantSummary(training, registrations),
        });
        getOncomingEmployeeTrainings(dispatch)(
          getSelectedEmpId() || getLoggedUserId(),
        );
      },
      (e: any) => {
        dispatch({ type: ACTIONS.SET_PARTICIPANTS_ERROR, payload: e });
      },
    );
  };
};

const saveParticipant = (dispatch: any) => {
  return async (
    training: CompanyTraining,
    updatedParticipant: CompanyTrainingParticipantRegistration,
  ) => {
    try {
      dispatch({ type: ACTIONS.START_LOADING_PARTICIPANTS });
      const response = await solaforce.put(
        ENDPOINTS.participant(
          updatedParticipant.trainingId,
          updatedParticipant.id,
        ),
        { registration: mapParticipantRegistrationForBE(updatedParticipant) },
      );
      dispatch({
        type: ACTIONS.UPDATE_PARTICIPANT,
        payload: mapParticipantRegistrationForUI(response.data),
      });
      getParticipants(dispatch)(training);
    } catch (e) {
      dispatch({ type: ACTIONS.SET_PARTICIPANTS_ERROR, payload: e });
    }
  };
};

const reorderParticipants = (dispatch: any) => {
  return async (
    training: CompanyTraining,
    newParticipants: CompanyTrainingParticipantRegistration[],
  ) => {
    dispatch({ type: ACTIONS.START_LOADING_PARTICIPANTS });
    const participants = sortAndPositionParticipants(training, newParticipants);
    dispatch({ type: ACTIONS.UPDATE_PARTICIPANTS, payload: participants });
    dispatch({
      type: ACTIONS.ADD_OR_UPDATE_PARTICIPANT_SUMMARY,
      payload: calculateParticipantSummary(training, participants),
    });
  };
};

const changeApprovalStatuses = (dispatch: any) => {
  return async (
    training: CompanyTraining,
    currentParticipants: CompanyTrainingParticipantRegistration[],
    action: ApprovalAction,
  ) => {
    dispatch({ type: ACTIONS.START_LOADING_PARTICIPANTS });
    let newParticipants;
    switch (action) {
      case 'CHANGE_ALL_AS_APPROVED':
        newParticipants = currentParticipants.map((p) => ({
          ...p,
          approvalStatus: {
            ...p.approvalStatus,
            status: {
              value: APPROVAL_STATUS.APPROVED,
              label: getApprovalStatusName(APPROVAL_STATUS.APPROVED),
            },
          },
        }));
        break;
      case 'CHANGE_ALL_SEATERS_AS_APPROVED':
        newParticipants = currentParticipants.map((p) => {
          if (p.seatStatus === SEAT_STATUS.HAS_SEAT) {
            return {
              ...p,
              approvalStatus: {
                ...p.approvalStatus,
                status: {
                  value: APPROVAL_STATUS.APPROVED,
                  label: getApprovalStatusName(APPROVAL_STATUS.APPROVED),
                },
              },
            };
          }
          return p;
        });
        break;
      default:
        newParticipants = [...currentParticipants];
    }
    const participants = sortAndPositionParticipants(training, newParticipants);
    dispatch({ type: ACTIONS.UPDATE_PARTICIPANTS, payload: participants });
    dispatch({
      type: ACTIONS.ADD_OR_UPDATE_PARTICIPANT_SUMMARY,
      payload: calculateParticipantSummary(training, participants),
    });
  };
};

const changeHours = (dispatch: any) => {
  return async (
    training: CompanyTraining,
    currentParticipants: CompanyTrainingParticipantRegistration[],
    action: HoursAction,
    hours?: number,
  ) => {
    dispatch({ type: ACTIONS.START_LOADING_PARTICIPANTS });
    let newParticipants;
    switch (action) {
      case 'CHANGE_ALL_HOURS_TO_DEFAULT':
        newParticipants = currentParticipants.map((p) => ({
          ...p,
          hours: training.hours,
        }));
        break;
      case 'CHANGE_ALL_HOURS_TO':
        newParticipants = currentParticipants.map((p) => ({
          ...p,
          hours,
        }));
        break;
      default:
        newParticipants = [...currentParticipants];
    }
    const participants = sortAndPositionParticipants(training, newParticipants);
    dispatch({ type: ACTIONS.UPDATE_PARTICIPANTS, payload: participants });
    dispatch({
      type: ACTIONS.ADD_OR_UPDATE_PARTICIPANT_SUMMARY,
      payload: calculateParticipantSummary(training, participants),
    });
  };
};

const changeCompletionStatuses = (dispatch: any) => {
  return async (
    training: CompanyTraining,
    currentParticipants: CompanyTrainingParticipantRegistration[],
    action: CompletionAction,
  ) => {
    dispatch({ type: ACTIONS.START_LOADING_PARTICIPANTS });
    let newParticipants;
    switch (action) {
      case 'CHANGE_ALL_SEATERS_TO_COMPLETED':
        newParticipants = currentParticipants.map((p) => {
          if (p.seatStatus === SEAT_STATUS.HAS_SEAT) {
            return {
              ...p,
              completionStatus: {
                value: COMPLETION_STATUS.COMPLETED,
                label: getCompletionStatusName(COMPLETION_STATUS.COMPLETED),
              },
            };
          }
          return p;
        });
        break;
      case 'CHANGE_ALL_TO_DID_NOT_COMPLETE':
        newParticipants = currentParticipants.map((p) => ({
          ...p,
          completionStatus: {
            value: COMPLETION_STATUS.DID_NOT_COMPLETE,
            label: getCompletionStatusName(COMPLETION_STATUS.DID_NOT_COMPLETE),
          },
        }));
        break;
      default:
        newParticipants = [...currentParticipants];
    }
    const participants = sortAndPositionParticipants(training, newParticipants);
    dispatch({ type: ACTIONS.UPDATE_PARTICIPANTS, payload: participants });
    dispatch({
      type: ACTIONS.ADD_OR_UPDATE_PARTICIPANT_SUMMARY,
      payload: calculateParticipantSummary(training, participants),
    });
  };
};

const changeExpirationDates = (dispatch: any) => {
  return async (
    training: CompanyTraining,
    currentParticipants: CompanyTrainingParticipantRegistration[],
    action: ExpiresAction,
    expires?: moment.Moment,
  ) => {
    dispatch({ type: ACTIONS.START_LOADING_PARTICIPANTS });
    let newParticipants;
    switch (action) {
      case 'CHANGE_ALL_COMPLETED_TO_DEFAULT':
        newParticipants = currentParticipants.map((p) => {
          if (p.completionStatus.value === COMPLETION_STATUS.COMPLETED) {
            return {
              ...p,
              expires: training.expires,
            };
          }
          return p;
        });
        break;
      case 'CHANGE_ALL_COMPLETED_TO':
        newParticipants = currentParticipants.map((p) => {
          if (p.completionStatus.value === COMPLETION_STATUS.COMPLETED) {
            return {
              ...p,
              expires,
            };
          }
          return p;
        });
        break;
      default:
        newParticipants = [...currentParticipants];
    }
    const participants = sortAndPositionParticipants(training, newParticipants);
    dispatch({ type: ACTIONS.UPDATE_PARTICIPANTS, payload: participants });
    dispatch({
      type: ACTIONS.ADD_OR_UPDATE_PARTICIPANT_SUMMARY,
      payload: calculateParticipantSummary(training, participants),
    });
  };
};

const changeUpdateTrainingHistoryMarks = (dispatch: any) => {
  return async (
    training: CompanyTraining,
    currentParticipants: CompanyTrainingParticipantRegistration[],
    action: UpdateTrainingHistoryAction,
  ) => {
    dispatch({ type: ACTIONS.START_LOADING_PARTICIPANTS });
    let newParticipants;
    switch (action) {
      case 'MARK_ALL_COMPLETED_TO_UPDATE_HISTORY':
        newParticipants = currentParticipants.map((p) => {
          if (p.completionStatus.value === COMPLETION_STATUS.COMPLETED) {
            return {
              ...p,
              participant: { ...p.participant, updateTrainingHistory: true },
            };
          }
          return p;
        });
        break;
      case 'UNMARK_ALL_COMPLETED_FROM_UPDATING_HISTORY':
        newParticipants = currentParticipants.map((p) => {
          if (p.completionStatus.value === COMPLETION_STATUS.COMPLETED) {
            return {
              ...p,
              participant: { ...p.participant, updateTrainingHistory: false },
            };
          }
          return p;
        });
        break;
      default:
        newParticipants = [...currentParticipants];
    }
    const participants = sortAndPositionParticipants(training, newParticipants);
    dispatch({ type: ACTIONS.UPDATE_PARTICIPANTS, payload: participants });
    dispatch({
      type: ACTIONS.ADD_OR_UPDATE_PARTICIPANT_SUMMARY,
      payload: calculateParticipantSummary(training, participants),
    });
  };
};

const changeUpdateTrainingHistoryMarkForOneUser = (dispatch: any) => {
  return async (
    training: CompanyTraining,
    currentParticipants: CompanyTrainingParticipantRegistration[],
    actualParticipantId: number,
    checkBoxValue: boolean,
  ) => {
    dispatch({ type: ACTIONS.START_LOADING_PARTICIPANTS });
    const newParticipants = currentParticipants.map((p) => {
      if (
        p.participant.id === actualParticipantId &&
        p.completionStatus.value === COMPLETION_STATUS.COMPLETED
      ) {
        return {
          ...p,
          participant: {
            ...p.participant,
            updateTrainingHistory: checkBoxValue,
          },
        };
      }

      return p;
    });
    const participants = sortAndPositionParticipants(training, newParticipants);
    dispatch({ type: ACTIONS.UPDATE_PARTICIPANTS, payload: participants });
    dispatch({
      type: ACTIONS.ADD_OR_UPDATE_PARTICIPANT_SUMMARY,
      payload: calculateParticipantSummary(training, participants),
    });
  };
};

const toggleParticipantRegistration = (dispatch: any) => {
  return (registrationId: string) => {
    dispatch({
      type: ACTIONS.TOGGLE_PARTICIPANT_REGISTRATION,
      payload: registrationId,
    });
  };
};

const toggleAllParticipantRegistrations = (dispatch: any) => {
  return () => {
    dispatch({ type: ACTIONS.TOGGLE_ALL_PARTICIPANT_REGISTRATION });
  };
};

/**
 * Employee trainings
 */

const downloadEmployeeTrainingAttachment = (_dispatch: any) => {
  return (employeeId: number, attachmentId: number) => {
    // We add random querystring parameter to force server request (avoid cache when replacing attahcment)
    const url = `/d/bin${ENDPOINTS.employeeAttachment(
      employeeId,
      attachmentId,
    )}?nocache=${Math.floor(Math.random() * 100000000)}`;
    downloadAttachment(url);
  };
};

// Oncoming
const getOncomingEmployeeTrainings = (dispatch: any) => {
  return async (empId: number) => {
    try {
      dispatch({ type: ACTIONS.START_LOADING_ONCOMING_TRAININGS });
      const response = await solaforce.get(
        ENDPOINTS.oncomingEmployeeTrainings(empId),
      );
      const trainings = response.data.map((item: any) => item.training);
      const registrations = response.data.map((item: any) => item.registration);
      dispatch({ type: ACTIONS.GET_ONCOMING_TRAININGS, payload: trainings });
      dispatch({
        type: ACTIONS.GET_EMPLOYEE_TRAINING_REGISTRATIONS,
        payload: registrations,
      });
    } catch (e) {
      dispatch({ type: ACTIONS.SET_ONCOMING_TRAININGS_ERROR, payload: e });
    }
  };
};

// Training calendar for employee
const getEmployeeTrainingCalendar = (dispatch: any) => {
  return async (empId: number, showAll: boolean = false) => {
    try {
      dispatch({ type: ACTIONS.START_LOADING_CALENDAR_TRAININGS });
      const response = await solaforce.get(
        ENDPOINTS.trainingCalendarForEmployee(empId, showAll),
      );
      dispatch({
        type: ACTIONS.GET_CALENDAR_TRAININGS,
        payload: response.data,
      });

      // Get participants for each training to collect summary info
      // TODO! THIS SHOULD PROBABLY BE REPLACED BY SERVER SIDE SUMMARY COLLECTION
      response.data.map((item: any) => {
        const training = mapCompanyTrainingForUI(item.training);

        // Only collect participant summaries for trainings that user has access to
        if (canAccessParticipantList(training)) {
          solaforce
            .get(ENDPOINTS.participants(training.id))
            .then((participantsRes) => {
              dispatch({
                type: ACTIONS.ADD_OR_UPDATE_PARTICIPANT_SUMMARY,
                payload: calculateParticipantSummary(
                  training,
                  sortAndPositionParticipants(
                    training,
                    participantsRes.data.map((p: any[]) =>
                      mapParticipantRegistrationForUI(p),
                    ),
                  ),
                ),
              });
            });
        }
      });
    } catch (e) {
      dispatch({ type: ACTIONS.SET_CALENDAR_TRAININGS_ERROR, payload: e });
    }
  };
};

// Trainings history for employee
const getEmployeeTrainingHistory = (dispatch: any) => {
  return async (empId: number) => {
    try {
      dispatch({ type: ACTIONS.START_LOADING_TRAINING_HISTORY });
      const resInternal = solaforce.get(
        ENDPOINTS.trainingHistoryForEmployee(empId, 'internal'),
      );
      const resExternal = solaforce.get(
        ENDPOINTS.trainingHistoryForEmployee(empId, 'external'),
      );
      const resOther = solaforce.get(
        ENDPOINTS.trainingHistoryForEmployee(empId, 'other'),
      );
      const results = await Promise.all([
        resInternal,
        resExternal,
        resOther,
      ]).then((res: any[]) => [...res[0].data, ...res[1].data, ...res[2].data]);
      dispatch({ type: ACTIONS.GET_TRAINING_HISTORY, payload: results });
    } catch (e) {
      dispatch({ type: ACTIONS.SET_TRAINING_HISTORY_ERROR, payload: e });
    }
  };
};

const getHistoryTrainingType = (
  trainingGroup:
    | 'INTERNAL_TRAINING_TYPE'
    | 'EXTERNAL_TRAINING_TYPE'
    | 'OTHER_TRAINING_TYPE',
) => {
  switch (trainingGroup) {
    case 'INTERNAL_TRAINING_TYPE':
      return 'internal';
    case 'EXTERNAL_TRAINING_TYPE':
      return 'external';
    case 'OTHER_TRAINING_TYPE':
      return 'other';
    default:
      return undefined;
  }
};

const addEmployeeTraining = (dispatch: any) => {
  return async (newTraining: EmployeeTraining) => {
    dispatch({ type: ACTIONS.START_LOADING_TRAINING_HISTORY });
    const { formData } = getFormData(
      mapEmployeeTrainingHistoryForBE(newTraining),
    );
    await Service.postFormData(
      `/d/form${ENDPOINTS.employeeTrainings(
        newTraining.employeeId,
        getHistoryTrainingType(newTraining.trainingGroup.value as any),
      )}`,
      formData,
      (res: any) => {
        dispatch({
          type: ACTIONS.ADD_EMPLOYEE_TRAINING,
          payload: mapEmployeeTrainingHistoryForUI(res),
        });
      },
      (err: any) => {
        dispatch({ type: ACTIONS.SET_TRAINING_HISTORY_ERROR, payload: err });
      },
    );
  };
};

const updateEmployeeTraining = (dispatch: any) => {
  return async (updatedTraining: EmployeeTraining) => {
    dispatch({ type: ACTIONS.START_LOADING_TRAINING_HISTORY });
    const type = getHistoryTrainingType(
      updatedTraining.trainingGroup.value as any,
    );
    const { formData } = getFormData(
      mapEmployeeTrainingHistoryForBE(updatedTraining),
    );
    await Service.putFormData(
      `/d/form${ENDPOINTS.employeeTraining(
        updatedTraining.employeeId,
        updatedTraining.id,
        type as any,
      )}`,
      formData,
      (res: any) => {
        dispatch({
          type: ACTIONS.UPDATE_EMPLOYEE_TRAINING,
          payload: mapEmployeeTrainingHistoryForUI(res),
        });
      },
      (err: any) => {
        dispatch({ type: ACTIONS.SET_TRAINING_HISTORY_ERROR, payload: err });
      },
    );
  };
};

const deleteEmployeeTraining = (dispatch: any) => {
  return async (deletedTraining: EmployeeTraining) => {
    try {
      dispatch({ type: ACTIONS.START_LOADING_TRAINING_HISTORY });
      const type = getHistoryTrainingType(
        deletedTraining.trainingGroup.value as any,
      );
      await solaforce.delete(
        ENDPOINTS.employeeTraining(
          deletedTraining.employeeId,
          deletedTraining.id,
          type,
        ),
      );
      dispatch({
        type: ACTIONS.DELETE_EMPLOYEE_TRAINING,
        payload: {
          trainingGroup: deletedTraining.trainingGroup.value,
          trainingId: deletedTraining.id,
        },
      });
    } catch (e) {
      dispatch({ type: ACTIONS.SET_TRAINING_HISTORY_ERROR, payload: e });
    }
  };
};

// Self registration for employee
const selfRegister = (dispatch: any) => {
  return async (trainingId: number, message?: string) => {
    dispatch({ type: ACTIONS.START_SELF_REGISTERING });
    Service.post(
      `/d/json${ENDPOINTS.selfRegisterForTraining(trainingId)}`,
      { message },
      (_response: any) => {
        dispatch({ type: ACTIONS.SELF_REGISTER_DONE, payload: trainingId });
        getOncomingEmployeeTrainings(dispatch)(getLoggedUserId()); // We update employee training calendar
      },
      (e: any) => {
        dispatch({ type: ACTIONS.SET_SELF_REGISTER_ERROR, payload: e });
      },
    );
  };
};

const getUnitOrgTree = (dispatch: any) => {
  return async () => {
    dispatch({ type: ACTIONS.GET_UNIT_ORG_TREE });
    Service.get(
      `/d/json${ENDPOINTS.unitOrgTree()}`,
      (response: any) => {
        const orgTreeData = response.map((respItem: any) => ({
          id: parseInt(respItem.fTreeId, 10),
          parentId:
            respItem.fTreeParentUnitId === '#'
              ? '#'
              : parseInt(respItem.fTreeParentUnitId, 10),
          unitId: parseInt(respItem.fTreeUnitId, 10),
          name: respItem.fTreeUnitName,
          unitType: respItem.fTreeUnitType,
        }));
        dispatch({
          type: ACTIONS.GET_UNIT_ORG_TREE_DONE,
          payload: orgTreeData,
        });
      },
      (e: any) => {
        dispatch({ type: ACTIONS.GET_UNIT_ORG_TREE_ERROR, payload: e });
      },
    );
  };
};

const cancelTaining = (dispatch: any) => {
  return async (trainingId: number, regId: string) => {
    Service.delete(
      `/d/json${ENDPOINTS.trainingCancellation(trainingId, regId)}`,
      undefined,
      (_response: any) => {
        getOncomingEmployeeTrainings(dispatch)(getLoggedUserId());
      },
      (e: any) => {
        dispatch({ type: ACTIONS.SET_CANCELLATION_ERROR, payload: e });
      },
    );
  };
};

export const { Context, Provider } = createDataContext(
  reducer,
  {
    // Company trainings
    getCompanyTrainings,
    addCompanyTraining,
    updateCompanyTraining,
    deleteCompanyTraining,
    publishCompanyTraining,
    unpublishCompanyTraining,
    getUnitOrgTree,
    // Participants
    getParticipants,
    saveParticipants,
    saveParticipant,
    reorderParticipants,
    changeApprovalStatuses,
    changeHours,
    changeCompletionStatuses,
    changeExpirationDates,
    changeUpdateTrainingHistoryMarks,
    changeUpdateTrainingHistoryMarkForOneUser,
    toggleParticipantRegistration,
    toggleAllParticipantRegistrations,
    cancelTaining,
    // Employee trainings
    downloadEmployeeTrainingAttachment,
    getOncomingEmployeeTrainings,
    getEmployeeTrainingCalendar,
    getEmployeeTrainingHistory,
    addEmployeeTraining,
    updateEmployeeTraining,
    deleteEmployeeTraining,
    selfRegister,
  },
  initialState,
);

export default Context;

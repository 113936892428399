import { Map as iMap } from 'immutable';

export const OPEN_NOTIFIER = '@@solaforce/notifier/OPEN_NOTIFIER';
export const CLOSE_NOTIFIER = '@@solaforce/notifier/CLOSE_NOTIFIER';

const initialState = iMap({
  type: 'success',
  open: false,
  message: '',
});

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case OPEN_NOTIFIER:
      return state
        .set('type', action.payload.type)
        .set('open', true)
        .set('message', action.payload.message);
      case CLOSE_NOTIFIER:
        return state
          .set('open', false);
    default:
      return state;
  }
};

export const openNotifier = (payload: any = {}) => ({ type: OPEN_NOTIFIER, payload });
export const closeNotifier = (payload: any = {}) => ({ type: CLOSE_NOTIFIER, payload });

export default reducer;

import translate from '@/app/utils/translate';
import {
  Button,
  Icon,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import { process } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {
  Grid,
  GridColumn,
  GridDataStateChangeEvent,
  GridToolbar,
} from '@progress/kendo-react-grid';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { convertToM, mapEntriesForDimensionSum, sumConverter } from './service';
import styles from './styles';
import { WorktimeReportEntry } from './types';

import { mapStateToProps, StateProps } from './worktimeReportRedux';

type InnerProps = WithStyles<typeof styles>;
type OwnProps = {
  columns: any;
  entries: WorktimeReportEntry[];
};

type Props = OwnProps & InnerProps & StateProps;

const DimensionsSumTable = (props: Props) => {
  const { columns, entries, classes } = props;
  const summedUpDimensions = mapEntriesForDimensionSum(entries);
  const [dataState = { skip: 0, take: 9 }, setDataState] = useState();
  const processedData = process(summedUpDimensions, dataState);
  let _export: any;
  const saveWorkTimeDimensionsSum = () => {
    _export.save(
      process(summedUpDimensions, {
        ...dataState, // Export all the records
        skip: 0,
        take: undefined,
      }),
    );
  };

  const createDataState = (dataStateProp: any) => {
    return {
      dataState: setDataState(dataStateProp),
    };
  };

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    return createDataState(event.data);
  };

  const calculate: number = processedData.data.reduce(
    (acc, current) => acc + convertToM(current.sum),
    0,
  );

  const ToolbarManagerAndHr = (
    <div className={classes.actionBar}>
      <Typography className={classes.summaryTitle}>
        {translate.t('laSummary')}
      </Typography>
      <div className={classes.rightComponent}>
        <Typography className={classes.summarySum}>
          {translate.t('laTotal')} {sumConverter(calculate)}
        </Typography>
        <Button onClick={saveWorkTimeDimensionsSum}>
          {translate.t('laDownload')} &nbsp;{' '}
          <Icon fontSize="small">cloud_download</Icon>
        </Button>
      </div>
    </div>
  );

  return (
    <div style={{ marginTop: '20px' }}>
      <ExcelExport
        fileName={`${translate.t('laWorktimeReportDimensions')} ${translate
          .t('laSummary')
          .toLowerCase()}`}
        ref={excelExport => (_export = excelExport)}
      >
        <Grid
          data={processedData}
          onDataStateChange={dataStateChange}
          {...dataState}
          sortable={true}
          resizable={true}
          pageable={true}
          groupable={true}
        >
          <GridToolbar>{ToolbarManagerAndHr}</GridToolbar>
          {columns.map(
            (column: any, idx: number) =>
              column.show && <GridColumn {...column} key={idx} />,
          )}
        </Grid>
      </ExcelExport>
    </div>
  );
};

const enhance = compose<Props, OwnProps>(
  connect(mapStateToProps),
  withStyles(styles),
);

export default enhance(DimensionsSumTable);

import { getSelectedEmpId } from '@/old/utils/helper';

import { RawCollaborationTask } from '../../collaborationModels';

import { ReportType } from './tasksListModels';

const empIdFieldsMap = {
    [ReportType.BY_ASSIGNOR]: 'fTaskAssignorId',
    [ReportType.BY_PERTAINS]: 'fTaskPertainingToId',
    default: 'fTaskAssigneeId',
};

export const filterCollaborationTasks = (tasks: RawCollaborationTask[], reportType: ReportType) => {
    return tasks.filter((task) => {
        return task.taskCore[empIdFieldsMap[reportType] || empIdFieldsMap.default] === getSelectedEmpId();
    });
};
import * as React from 'react';
import { Dispatch } from 'redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { v4 as uuid4 } from 'uuid';
import { ReducerState } from '@/app/redux/store';
import { withStyles } from '@material-ui/core/styles';
import {
  WithStyles,
  Paper,
  Button,
  Checkbox,
  Typography,
} from '@material-ui/core';
import { getSelectedEmpId } from '@/old/utils/helper';
import translate from '@/app/utils/translate';
import saveChangesStyles from './saveChangesStyles';
import { getLoggedUserId } from '@/old/utils/helper';
import {
  ConfirmDialogType,
  openConfirmDialog,
} from '@/app/redux/confirmDialog';
import IntegrationReactSelect from '@/app/components/IntegrationReactSelect/IntegrationReactSelect';
import {
  getMyTeams,
  getAllActiveEmployees,
  lookForSubUnits,
} from '@/old/utils/helper';
import StageSelect from './StageSelect';
import {
  ActiveTemplate,
  ComponentItem,
  EvaluationStage,
  JobReqEvaluation,
} from '@/app/components/JobEvaluation/types';
import {
  JobEvaluationStatus,
  TargetType,
} from '@/app/components/JobEvaluation/enums';
import StatusSelect from '@/app/components/TemplateComponents/form/FormComponents/StatusSelect/StatusSelect';

type IncomingProps = {
  completionStatus: string;
  activeTemplate: ActiveTemplate;
  handleEvaluationsSave: (
    evaluationId: string,
    component: ComponentItem,
  ) => Promise<void>;
  handleModalClose: () => void;
  toggleEditMode: () => void;
  employeeId: number;
  archiveEvaluation: (evaluationId: string) => Promise<void>;
  changeEvaluationStage: (
    setStage: EvaluationStage,
    userMakingTheChange: string,
  ) => Promise<void>;
};

type MapStateToProps = {
  jobRequirementEvaluation: JobReqEvaluation;
};

type MapDispatchToProps = {
  openConfirmDialog: (payload: ConfirmDialogType) => void;
};

type PropsType = MapStateToProps &
  MapDispatchToProps &
  IncomingProps &
  WithStyles;

type TargetOption = {
  label: string;
  value: string;
  type: string;
};

type TargetEmployeeOption = TargetOption & {
  type: TargetType.EMPLOYEE;
};

type TargetUnitOption = TargetOption & {
  type: TargetType.UNIT;
  unitEmployees: Array<unknown>;
};

type StateType = {
  status: string;
  approved: boolean;
  archived: boolean;
  targets: TargetOption[];
  targetEmployeeOptions: TargetEmployeeOption[];
};

const getTargetEmployeeIds = (targets: TargetOption[]) => {
  return Object.values(targets).reduce<string[]>((accumulator, option) => {
    if (option.type === TargetType.UNIT) {
      const { unitEmployees } = option as TargetUnitOption;
      return [...accumulator, ...unitEmployees.map(({ value }) => value)];
    }

    if (option.type === TargetType.EMPLOYEE) {
      return [...accumulator, option.value];
    }

    return accumulator;
  }, []);
};

class SaveChanges extends React.PureComponent<PropsType> {
  state: StateType = {
    status: '',
    approved: false,
    archived: false,
    targets: [],
    targetEmployeeOptions: [],
  };

  targetUnitOptions = getMyTeams(true) as TargetUnitOption[];

  constructor(props: PropsType) {
    super(props);
    this.state = {
      status: props.completionStatus,
      approved: false,
      archived: false,
      targets: [],
      targetEmployeeOptions: getAllActiveEmployees(true),
    };
  }

  handleSaveEvaluation = (event: any) => {
    event.preventDefault();
    const templateId = this.props.activeTemplate.id;
    let fullResponse: any = {};
    fullResponse.evaluationId = uuid4();
    fullResponse.employeeId = getSelectedEmpId();
    fullResponse.responses = this.props.jobRequirementEvaluation.responses;
    fullResponse.status = this.state.status;
    fullResponse.approved = this.state.approved;
    fullResponse.archived = this.state.archived;
    if (this.props.activeTemplate.isTeam) {
      fullResponse.target = {
        unitIds: this.state.targets
          .filter(({ type }) => type === TargetType.UNIT)
          .map(({ value }) => value),
        employeeIds: this.state.targets
          .filter(({ type }) => type === TargetType.EMPLOYEE)
          .map(({ value }) => value),
      };
    }
    if (this.props.activeTemplate.setStage) {
      fullResponse.stageId = this.props.activeTemplate.setStage.id;
    }
    this.props
      .handleEvaluationsSave(templateId, fullResponse)
      .then((response: any) => {
        if (response && fullResponse.archived) {
          this.props.archiveEvaluation(response.id);
        }
      });
    this.props.toggleEditMode();
    this.props.handleModalClose();
  };

  onStatusChange = (value: string) => {
    this.setState({ status: value }, () => {
      if (this.state.status === JobEvaluationStatus.IN_PROGRESS) {
        this.setState({
          approved: false,
          archived: false,
        });
      }
    });
  };

  handleMarkedAsApproved = () => {
    this.setState({ approved: !this.state.approved });
  };

  handleMarkedToArchive = () => {
    this.setState({ archived: !this.state.archived });
  };

  handleModalClose = () => {
    this.props.handleModalClose();
  };

  updateTargetsList = (selection: TargetOption[]) => {
    const indirectSelectedEmployeeIds = getTargetEmployeeIds(
      selection.filter(({ type }) => type === TargetType.UNIT),
    );

    // Make sure to exclude employees if the unit they belong to was already selected
    const resolvedSelection = selection.filter(option => {
      return (
        option.type !== TargetType.EMPLOYEE ||
        !indirectSelectedEmployeeIds.includes(option.value)
      );
    });

    const selectedEmployeeIds = getTargetEmployeeIds(resolvedSelection);

    this.setState({
      targets: resolvedSelection,
      targetEmployeeOptions: getAllActiveEmployees(true).filter(option => {
        return !selectedEmployeeIds.includes(option.value);
      }),
    });
  };

  handleTargetsChange = (selection: TargetOption[]) => {
    const { targets } = this.state;

    // Update targets directly when some option was deselected
    if (targets.length > selection.length) {
      this.updateTargetsList(selection);
      return;
    }

    const notIncludedSubUnitIds = lookForSubUnits(selection).filter(
      (subUnit: any) => {
        return !selection.some(option => {
          return option.type === TargetType.UNIT && option.value === subUnit;
        });
      },
    );

    if (notIncludedSubUnitIds && notIncludedSubUnitIds.length) {
      this.props.openConfirmDialog({
        text: translate.t('confirm_add_subunits'),
        onOk: () => {
          this.updateTargetsList([
            ...selection,
            ...notIncludedSubUnitIds.map((subUnitId: any) => {
              return this.targetUnitOptions.find(option => {
                return option.value === subUnitId;
              });
            }),
          ]);
        },
        onClose: () => {
          this.updateTargetsList(selection);
        },
        customOk: translate.t('laYes'),
        customClose: translate.t('laNo'),
      });
    } else {
      this.updateTargetsList(selection);
    }
  };

  getTargetOptions = () => {
    return [...this.targetUnitOptions, ...this.state.targetEmployeeOptions];
  };

  render() {
    const GLOBAL: any = window;
    const {
      classes,
      completionStatus,
      activeTemplate,
      employeeId,
    } = this.props;

    const { status, approved, archived, targets } = this.state;

    const ownPage = getLoggedUserId() === employeeId;

    return (
      <Paper className={classes.root}>
        <form className={classes.form}>
          <Typography variant="subtitle2" className={classes.topTitle}>
            {translate.t('laSaveChanges')}
          </Typography>
          {((!GLOBAL.iEmployee && !ownPage) ||
            GLOBAL.iHRAdmin ||
            (GLOBAL.iManager && !ownPage) ||
            activeTemplate.isTeam) && (
            <>
              <Typography
                variant="subtitle2"
                className={this.props.classes.stageLabel}
              >
                {translate.t('laStatus')}
              </Typography>
              <StatusSelect
                completionStatus={completionStatus}
                onStatusChange={this.onStatusChange}
              />
              {activeTemplate.setStage !== undefined && (
                <>
                  <Typography
                    variant="subtitle2"
                    className={this.props.classes.stageLabel}
                  >
                    {translate.t('laStages')}
                  </Typography>
                  <StageSelect
                    activeTemplate={activeTemplate}
                    changeEvaluationStage={this.props.changeEvaluationStage}
                  />
                </>
              )}
              <div className={classes.checkboxText}>
                <Checkbox
                  color="primary"
                  onClick={() => this.handleMarkedAsApproved()}
                  checked={approved}
                  disabled={status === JobEvaluationStatus.IN_PROGRESS}
                />
                <span
                  className={
                    status === JobEvaluationStatus.IN_PROGRESS
                      ? classes.checkboxLabelInactive
                      : classes.checkboxLabelActive
                  }
                >
                  {translate.t('laMarkApproved')}
                </span>
              </div>
              <div className={classes.checkboxText}>
                <Checkbox
                  onClick={() => this.handleMarkedToArchive()}
                  color="primary"
                  checked={archived}
                  disabled={status === JobEvaluationStatus.IN_PROGRESS}
                />
                <span
                  className={
                    status === JobEvaluationStatus.IN_PROGRESS
                      ? classes.checkboxLabelInactive
                      : classes.checkboxLabelActive
                  }
                >
                  {translate.t('laArchiveCopy')}
                </span>
              </div>
              {activeTemplate.isTeam && (
                <IntegrationReactSelect
                  isMulti={true}
                  isSearchable={true}
                  label={translate.t('laCopyDiscussionTo')}
                  placeholder={translate.t('laCopyDiscussionTo')}
                  required={false}
                  error=""
                  value={targets}
                  onChange={this.handleTargetsChange}
                  options={this.getTargetOptions()}
                  className={classes.multiSelect}
                />
              )}
            </>
          )}
          {GLOBAL.iEmployee && (
            <>
              <br />
              <br />
            </>
          )}
          <div className={classes.buttonsWrapper}>
            <Button
              color="primary"
              variant="text"
              className={classes.button}
              onClick={() => this.handleModalClose()}
            >
              {translate.t('laCancel')}
            </Button>
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={this.handleSaveEvaluation}
            >
              {translate.t('laSave')}
            </Button>
          </div>
        </form>
      </Paper>
    );
  }
}

const mapStateToProps = (state: ReducerState) => {
  const { jobRequirementEvaluation } = state.jobRequirementEvaluations;

  return {
    jobRequirementEvaluation,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openConfirmDialog: (payload: ConfirmDialogType) =>
    dispatch(openConfirmDialog(payload)),
});

const enhance = compose<PropsType, IncomingProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(saveChangesStyles),
);

export default enhance(SaveChanges);

import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const notifierForOldStyle: StyleRulesCallback = () => ({
  close: {
    fontSize: '18px'
  },
  info: {
    backgroundColor: '#04a5d6',
  },
  infoIcon: {
    fontSize: '18px', marginBottom: '-5px'
  },
  messageSpan: {
    fontSize: '13px', paddingLeft: '5px'
  }
});

export default notifierForOldStyle;
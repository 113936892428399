import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const addAudienceFormDialogStyle: StyleRulesCallback = (theme: Theme) => {
  return ({
    paperWidthMd: {
      width: '620px',
    },
    card: {
      backgroundColor: '#fff7b0',
      marginRight: `${theme.spacing.unit * 2}px`,
    },
    cardContent: {
      '&:last-child': {
        paddingBottom: theme.spacing.unit * 2,
      },
    },
    multiSelectContainer: {
      overflow: 'auto',
      maxHeight: '220px',
    },
    cardContainer: {
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      marginTop: theme.spacing.unit,
    },
  });
};

export default addAudienceFormDialogStyle;

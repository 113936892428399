import { withStyles, IconButton, Popover, WithStyles } from '@material-ui/core';
import MoodIcon from '@material-ui/icons/Mood';
import MoodBadIcon from '@material-ui/icons/MoodBad';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import * as React from 'react';
import { compose } from 'recompose';

import moodPickerStyles from './moodPickerStyles';

enum Moods {
  HAPPY = '1',
  SATISFIED = '2',
  UNHAPPY = '3',
  DEFAULT = '0',
}

type IncomingProps = {
  value: string;
  onChange: (v: string) => void;
  disabled?: boolean;
};

type PropsType = IncomingProps & WithStyles<typeof moodPickerStyles>;

type StateType = {
  anchorEl: any;
};

class MoodPicker extends React.PureComponent<PropsType, StateType> {
  state: StateType = { anchorEl: null };

  handleOpenMenu = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ anchorEl: null });
  };

  handleChange = (value: Moods) => {
    this.props.onChange(value);
    this.handleCloseMenu();
  };

  render () {
    const { classes, value, disabled } = this.props;
    const { anchorEl } = this.state;

    const id = anchorEl ? 'mood-picker-popover' : undefined;

    return (
      <>
        <IconButton
          aria-describedby={id}
          disabled={disabled}
          onClick={this.handleOpenMenu}
          className={classes.button}
        >
          {(() => {
            switch (value) {
              case Moods.HAPPY: return <MoodIcon className={classes.happy} />;
              case Moods.SATISFIED: return <SentimentSatisfiedIcon className={classes.satisfied} />;
              case Moods.UNHAPPY: return <MoodBadIcon className={classes.unhappy} />;
              default: return <RadioButtonUncheckedIcon className={classes.default} />;
            }
          })()}
        </IconButton>

        <Popover
          id={id}
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={this.handleCloseMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
             vertical: 'top',
             horizontal: 'center',
          }}
          classes={{ paper: classes.popover }}
        >
          <IconButton onClick={() => this.handleChange(Moods.HAPPY)} className={classes.button}>
            <MoodIcon className={classes.happy} />
          </IconButton>

          <IconButton onClick={() => this.handleChange(Moods.SATISFIED)} className={classes.button}>
            <SentimentSatisfiedIcon className={classes.satisfied} />
          </IconButton>

          <IconButton onClick={() => this.handleChange(Moods.UNHAPPY)} className={classes.button}>
            <MoodBadIcon className={classes.unhappy} />
          </IconButton>

          <IconButton onClick={() => this.handleChange(Moods.DEFAULT)} className={classes.button}>
            <RadioButtonUncheckedIcon className={classes.default} />
          </IconButton>
        </Popover>
      </>
    );
  }
}

const enhance = compose<any, any>(
    withStyles(moodPickerStyles),
);

export default enhance(MoodPicker);

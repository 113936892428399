import * as actionType from '@/app/redux/formData';
import {  } from '@/app/redux/formData';
import { setOpenConfirmDialog } from '@/app/redux/confirmDialog';

export default (store: any) => (next: any) => (action: any) => {
  next(action);

  switch (action.type) {
    case actionType.FORM_CHANGE: {
      store.dispatch(setOpenConfirmDialog());
      break;
    }
    case actionType.FORM_DESTROY:
    case actionType.FORM_INITIALIZE: {
      store.dispatch(setOpenConfirmDialog(false));
      break;
    }
    default:
      break;
  }
};

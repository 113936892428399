import * as React from 'react';
import { compose } from 'recompose';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stepper,
  Step,
  StepLabel,
  withStyles,
  WithStyles,
} from '@material-ui/core';

import wizardDialogStyle from './wizardDialogStyle';

interface IProps {
  activePage: number;
  title: string;
  open: boolean;
  pageTitles: Array<string>;
  onRenderPage(page: number): React.ReactNode;
  onRenderActions(page: number): React.ReactNode;
  onEsc: () => void;
}

class WizardDialog extends React.Component<
  IProps & WithStyles<typeof wizardDialogStyle>,
  any
> {
  render() {
    const {
      classes,
      activePage,
      title,
      open,
      pageTitles,
      onRenderPage,
      onRenderActions,
      onEsc,
    } = this.props;

    return (
      <Dialog
        disableBackdropClick
        onEscapeKeyDown={onEsc}
        open={open}
        maxWidth="md"
        className={classes.root}
        classes={{ paperWidthMd: classes.paperWidthMd }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Stepper activeStep={activePage}>
            {pageTitles.map((label: string) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {onRenderPage(activePage)}
        </DialogContent>
        <DialogActions
          classes={{
            root: classes.dialogActions,
          }}
        >
          {onRenderActions(activePage)}
        </DialogActions>
      </Dialog>
    );
  }
}

const enhance = compose<any, IProps>(
  withStyles(wizardDialogStyle, { withTheme: true }),
);

export default enhance(WizardDialog);

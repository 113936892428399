import * as React from 'react';

import { WithStyles, withStyles, createStyles } from '@material-ui/core';
import { GridCellProps } from '@progress/kendo-react-grid';

const styles = () =>
  createStyles({
    historyStatus: {
      paddingBottom: '1px',
    },
  });

type Props = WithStyles<typeof styles> & GridCellProps;

const UpdateddateCell = ({ classes, dataItem, rowType }: Props) => {
  if (
    rowType === 'groupHeader' ||
    rowType === 'groupFooter' ||
    rowType !== 'data'
  ) {
    return null;
  }
  const { updatedDate, updatedDateHistory, showHistory } = dataItem;

  const statusHistory = !showHistory
    ? updatedDate
    : updatedDateHistory.map((comp: any, index: number) => (
        <div key={index} className={classes.historyStatus}>
          {comp}
        </div>
      ));

  return <td style={{ verticalAlign: 'top' }}>{statusHistory}</td>;
};

export default withStyles(styles)(UpdateddateCell);

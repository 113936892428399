import * as React from 'react';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import { Training, trainingFactory, TRAINING_TYPE } from '../TrainingList/TrainingList';
import Service from '@/app/utils/service';
import API from '@/app/api/internalAPIs';
import TrainingCalendarDialog from '../TrainingCalendarDialog/TrainingCalendarDialog';
import ResizableIconButton from '@/app/components/ResizableIconButton/ResizableIconButton';
import translate from '@/app/utils/translate';
import { connect } from 'react-redux';
import { throwError } from '@/app/redux/error';
import compose from 'recompose/compose';
import { getEnumName, selectAllEnums } from '@/app/redux/enums';

interface MapStateToProps {
  allEnums: object;
}

type PropsType = {
  groupType: TRAINING_TYPE,
  throwError: Function,
};

type StateType = {
  trainings: Array<Training>,
  open: boolean;
};

class TrainingCalendar extends React.Component<PropsType & MapStateToProps> {
  state: StateType = {
    trainings: [],
    open: false,
  };

  handleClick = () => {
    Service.get(
      API.training.listByType(this.props.groupType),
      (data: any) => {
        if (data instanceof Array) {
          let trainings: Array<Training> = data.map((d: any) => trainingFactory({
            ...d,
            fEduCompanyTrainingClass: getEnumName('TRAINING_CLASS', d.fEduCompanyTrainingClassFK, this.props.allEnums),
          }));
          trainings.sort((a: any, b: any) =>
            (a.start ? a.start.localeCompare(b.start) : 1)
          );
          this.setState({
            trainings,
            open: true,
          });
        }
      },
      (error: any) => this.props.throwError(error)
    );
  };

  handleClose = () => {
    this.setState({ open: false });
  }

  render () {
    const { trainings, open } = this.state;
    return (
      <>
        <ResizableIconButton
          title={translate.t('training_calendar')}
          color="primary"
          aria-label=""
          onClick={this.handleClick}
          disableRipple={true}
          size="small"
        >
          <CalendarIcon />
        </ResizableIconButton>
        <TrainingCalendarDialog
          open={open}
          trainings={trainings}
          onClose={this.handleClose}
        />
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  allEnums: selectAllEnums(state),
});

const enhance = compose<any, any>(
  connect(mapStateToProps, { throwError }),
);

export default enhance(TrainingCalendar);

import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getFormValues, isPristine, isInvalid } from 'redux-form';
import compose from 'recompose/compose';
import {
  WithStyles,
  Grid,
  Typography,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import translate from '@/app/utils/translate';
import { INPUT_TYPE, getCurrentLocaleForDateUtils } from '@/app/utils/helper';
import FormFields from '@/app/components/FormFields/FormFields';
import sendToApprovalFormDialogStyle from './sendToApprovalFormDialogStyle';
import { throwError } from '@/app/redux/error';
import {
  getFormDialog,
  changeData,
} from '@/app/components/FormDialog/FormDialog';
import {
  openADDialog,
  createDocumentLink,
  sendApproverData,
  getDocumentHistory,
  APPROVAL_STATUS,
  resetApproverData,
  fetchNewDocumentServiceConfig,
  DocumentApprovalConfig,
  isAdvancedApprovalMethod,
} from '@/app/redux/documentApproval';
import { getCompanyLanguages, getEmployeeInfoById, isEmployee, isHR } from '@/old/utils/helper';
import TextFormFieldDynamicList from './TextFormFieldDynamicList/TextFormFieldDynamicList';
import ApproveDocumentFormDialog from '../ApproveDocumentFormDialog/ApproveDocumentFormDialog';

interface MapStateToProps {
  config: DocumentApprovalConfig | null;
  currentValues: ApprovalForm;
  docAppHistory: any;
  invalid: Function;
  pristine: Function;
  empEmail: string | null;
  empPhone: string | null;
  openAD: boolean;
}

interface MapDispatchToProps {
  throwError: Function;
  openADDialog: Function;
  createDocumentLink: Function;
  sendApproverData: Function;
  getDocumentHistory: Function;
  resetApproverData: Function;
  fetchNewDocumentServiceConfig: Function;
}

export type SendToApproval = {
  empId: number;
  docId: number;
  docName: string;
};

interface OwnProps {
  data: SendToApproval;
}

type PropsType = OwnProps &
  WithStyles<typeof sendToApprovalFormDialogStyle> &
  MapDispatchToProps &
  MapStateToProps;

type AdvancedApprovalForm = {
  [signerEmails: string]: string;
} & {
  email: string;
  language: {
    label: string;
    value: string;
  };
};

type EmailApprovalForm = {
  email: string;
  mobilePhone: string;
};

type ApprovalForm = AdvancedApprovalForm | EmailApprovalForm;
type StateType = {
  formData: ApprovalForm;
  disabledSend: boolean;
  open: boolean;
  notifyBySms: boolean;
  signerInputs: any[];
};

const isAdvancedApprovalForm = (formValues: ApprovalForm): formValues is AdvancedApprovalForm => {
  return (formValues as AdvancedApprovalForm).language !== undefined;
};

const mapMultipleSignerEmails = (formValues: ApprovalForm) => Object.keys(formValues)
  .filter(key => key.indexOf(MULTIPLE_SIGNER_FIELD_PREFIX) > -1)
  .map(key => formValues[key]);

const validate = (values: ApprovalForm) => {
  let errors: { [k: string]: any } = {};
  const requiredFields = ['email', 'mobilePhone'];
  const additionalSignerEmails = Object.keys(values)
    .filter(formKey => formKey.indexOf(MULTIPLE_SIGNER_FIELD_PREFIX) > -1);

  requiredFields.forEach((field) => {
    if (!values[field] || values[field] === '<p>&nbsp;</p>') {
      errors[field] = translate.t('laThisRequired');
    }
  });

  additionalSignerEmails.forEach((field) => {
    if (values[field] && values[field].length > 80) {
      errors[field] = `${translate.t('laErrorMaxTextLen1')} 80`;
    }
  });

  if (values.email && values.email.length > 80) {
    errors.email = `${translate.t('laErrorMaxTextLen1')} 80`;
  }

  if (values.mobilePhone && values.mobilePhone.length > 16) {
    errors.mobilePhone = `${translate.t('laErrorMaxTextLen1')} 16`;
  }

  return errors;
};

const NO_PHONE_NUMBER = 'noPhone';
const MULTIPLE_SIGNER_FIELD_PREFIX = 'signer-email-';
const FORM_NAME = 'sendToApprovalForm';

class SendToApprovalFormDialog extends React.Component<PropsType> {
  state: StateType = {
    formData: {
      email: '',
      mobilePhone: '',
    },
    disabledSend: true,
    open: false,
    notifyBySms: false,
    signerInputs: [
      {
        type: INPUT_TYPE.TEXT,
        code: 'email',
        name: 'email',
        label: translate.t('label_approver_email'),
        order: 1,
        required: true,
        onBlur: () => this.updateDisableSendState(FORM_NAME),
      }
    ]
  };

  language = [{
    type: INPUT_TYPE.SELECT,
    code: 'language',
    name: 'language',
    label: translate.t('laLanguage'),
    childrenInputs: getCompanyLanguages().map((lang: {
      fComLanguageCode: string;
      fComLanguageIsDefault: boolean;
      fComLanguageName: string;
    }) => ({
      label: lang.fComLanguageName,
      value: lang.fComLanguageCode
    })),
    order: 1,
    required: true,
    onBlur: () => this.updateDisableSendState(FORM_NAME),
  }];

  phoneInput = [
    {
      type: INPUT_TYPE.TEXT,
      code: 'mobilePhone',
      name: 'mobilePhone',
      label: translate.t('label_approver_mobile'),
      order: 2,
      required: true,
      onBlur: () => this.updateDisableSendState(FORM_NAME),
    },
  ];

  FormDialog = getFormDialog(FORM_NAME, validate, (values: ApprovalForm) => {
    if (!values.email && !values.mobilePhone) {
      return;
    }

    this.updateDisableSendState(FORM_NAME);
  });

  loadData = (empId: number) => {
    const { fEmpPhoneMob, fEmpEmailWork } = getEmployeeInfoById(empId);
    const mobilePhone = fEmpPhoneMob ? fEmpPhoneMob : NO_PHONE_NUMBER;
    const defaultLanguage = getCompanyLanguages().find((lang: {
      fComLanguageCode: string;
      fComLanguageIsDefault: boolean;
      fComLanguageName: string;
    }) => lang.fComLanguageIsDefault);

    this.setState({
      formData: {
        email: fEmpEmailWork,
        language: {
          label: defaultLanguage.fComLanguageName,
          value: defaultLanguage.fComLanguageCode
        },
        mobilePhone,
      },
    });
  };

  loadConfig = () => {
    this.props.fetchNewDocumentServiceConfig();
  }

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(_prevProps: PropsType, prevState: StateType) {
    if (this.state.open && !prevState.open) {
      this.setFormValues(this.props.data.empId);
      this.updateDisableSendState(FORM_NAME);

      this.initialize();
    }
  }

  initialize = () => {
    const { config, data } = this.props;
    const approvalMethod = config ? config.approval.defaultMethod : undefined;

    this.loadData(data.empId);
    this.loadConfig();

    this.props.createDocumentLink(data);
    if (this.state.open) {
      this.props.getDocumentHistory(data.empId, data.docId);
    }

    if (isAdvancedApprovalMethod(approvalMethod)) {
      this.setState({
        signerInputs: this.state.signerInputs.map(input => ({
          ...input,
          type: INPUT_TYPE.TEXT,
        }))
      });
    }
  };

  setFormValues = (empId?: any) => {
    const {
      fEmpEmailWork: email,
      fEmpPhoneMob: mobilePhone,
    } = getEmployeeInfoById(empId);
    const defaultLanguage = getCompanyLanguages().find((lang: {
      fComLanguageCode: string;
      fComLanguageIsDefault: boolean;
      fComLanguageName: string;
    }) => lang.fComLanguageIsDefault);

    changeData({ form: FORM_NAME, field: 'email', value: email });
    changeData({ form: FORM_NAME, field: 'mobilePhone', value: mobilePhone });
    changeData({ form: FORM_NAME, field: 'language', value: {
      label: defaultLanguage.fComLanguageName,
      value: defaultLanguage.fComLanguageCode
    }});
  };

  updateDisableSendState = (formName: string) => {
    this.setState({
      disabledSend:
        this.props.invalid(formName) || this.props.pristine(formName),
    });
  };

  openDialog = () => {
    this.setState({ open: true });
  };

  closeDialog = () => {
    this.setState({ open: false });
    this.props.resetApproverData();
  };

  handleSend = () => {
    const { config, currentValues, data } = this.props;
    const sendData = {
      fDocumentId: data.docId,
      fEmployeeId: data.empId,
      fEmployeeEmail: currentValues.email,
      fEmployeePhone: currentValues.mobilePhone || NO_PHONE_NUMBER,
      fSendSmsAccessCode: this.state.notifyBySms,
      method: config ? config.approval.defaultMethod : undefined,
      language: isAdvancedApprovalForm(currentValues)
        ? getCurrentLocaleForDateUtils(currentValues.language.value)
        : '',
      multipleSignerEmails: mapMultipleSignerEmails(currentValues)
    };

    this.props.sendApproverData(sendData).then(() => {
      window.jsReadEmpDocumentLatest();      
    });

    this.setState({ open: false });
  };

  handleComplete = () => {
    this.props.openADDialog();
  };

  handleSendBySmsChange = () => {
    this.setState({ notifyBySms: !this.state.notifyBySms });
  };

  handleOnRemoveSignerField = (field: string) => {
    changeData({ form: FORM_NAME, field, value: '' });
    this.setState({
      signerInputs: this.state.signerInputs.filter(input => input.name !== field)
    });
  }

  handleOnAddSignerField = () => {
    this.setState({
      signerInputs: [
        ...this.state.signerInputs,
        {
          type: INPUT_TYPE.TEXT,
          code: `${MULTIPLE_SIGNER_FIELD_PREFIX}${this.state.signerInputs.length}`,
          name: `${MULTIPLE_SIGNER_FIELD_PREFIX}${this.state.signerInputs.length}`,
          label: translate.t('label_approver_email'),
          order: this.state.signerInputs.length,
          required: false,
          onBlur: () => this.updateDisableSendState(FORM_NAME),
        },
      ]
    });
  }

  render() {
    const { classes, config, docAppHistory, data, openAD } = this.props;
    const { formData, open, notifyBySms, disabledSend } = this.state;
    const approvalMethod = config ? config.approval.defaultMethod : undefined;
    const { FormDialog } = this;

    return (
      <>
        <button className="approval" onClick={this.openDialog}>
          {translate.t('label_send_to_approval')}
        </button>

        {open && (
          <FormDialog
            title={`${translate.t('text_send_approve')}: ${data.docName}`}
            open={true}
            onClose={this.closeDialog}
            formData={formData}
            paperWidthMd={classes.paperWidthMd}
            customCloseLabel={translate.t('laClose')}
            preventConfirmDialog
          >
            { !isEmployee() && (
              <>
                <Typography component="p">
                  {translate.t('text_approval_process')}
                </Typography>
                {this.state.formData.mobilePhone !== NO_PHONE_NUMBER && !isAdvancedApprovalMethod(approvalMethod) && (
                  <>
                    <br />
                    <Grid item xs={12} sm={12} md={6} className={classes.gridLeft}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() => this.handleSendBySmsChange()}
                            checked={notifyBySms}
                          />
                        }
                        label={translate.t('laSendCodeSMS')}
                      />
                    </Grid>
                  </>
                )}
                <Grid container style={{ flexFlow: 'row' }}>
                  <Grid item xs={12} sm={12} md={6} className={classes.gridLeft}>
                    {!isAdvancedApprovalMethod(approvalMethod) ? (
                      <>
                        <FormFields inputs={this.state.signerInputs} />
                        {notifyBySms && <FormFields inputs={this.phoneInput} />}
                      </>
                    ) : (
                      <>
                        <TextFormFieldDynamicList
                          fields={this.state.signerInputs}
                          onAddField={this.handleOnAddSignerField}
                          onRemoveField={this.handleOnRemoveSignerField}
                        />
                        <FormFields inputs={this.language} />
                      </>
                    )}
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    className={classes.gridRight}
                  >
                    <Button
                      className={classes.sendButton}
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={this.handleSend}
                      disabled={disabledSend}
                    >
                      {translate.t('laSend')}
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
            <Typography component="h6" className={classes.listTitle}>
              {translate.t('text_doc_approval_history')}
            </Typography>

            <Table className={classes.table}>
              <TableHead>
                <TableRow className={classes.tableHead}>
                  <TableCell className={classes.tableHeadCell}>
                    {translate.t('label_started')}
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    {translate.t('label_approval_status')}
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {!docAppHistory.length && (
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.tableCell}
                    >
                      {translate.t('message_no_history')}
                    </TableCell>
                    <TableCell className={classes.tableCell} />
                    <TableCell align="right" className={classes.tableCell} />
                  </TableRow>
                )}

                {docAppHistory.map((row: any) => {
                  return (
                    <TableRow key={row.id}>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.tableCell}
                      >
                        {row.started}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {row.status}
                      </TableCell>
                      <TableCell align="right" className={classes.tableCell}>
                        {row.statusType === APPROVAL_STATUS.PENDING && isHR() && (
                          <Button
                            variant="text"
                            component="a"
                            onClick={this.handleComplete}
                            color="primary"
                            size="small"
                          >
                            {translate.t('label_complete')}
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </FormDialog>
        )}

        {openAD && <ApproveDocumentFormDialog />}
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { documentApproval: docApp } = state;

  return {
    config: docApp.get('config'),
    currentValues: getFormValues(FORM_NAME)(state),
    docAppHistory: docApp.get('docApprovalHistory'),
    invalid: (formName: string) => isInvalid(formName)(state),
    pristine: (formName: string) => isPristine(formName)(state),
    empEmail: docApp.get('empEmail'),
    empPhone: docApp.get('empPhone'),
    openAD: docApp.get('openAD'),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  throwError: (err: any) => dispatch(throwError(err)),
  openADDialog: (payload?: any) => dispatch(openADDialog(payload)),
  createDocumentLink: (data: SendToApproval) =>
    dispatch(createDocumentLink(data)),
  sendApproverData: (data: any) => dispatch<any>(sendApproverData(data)),
  getDocumentHistory: (empId: number, docId: number) =>
    dispatch<any>(getDocumentHistory(empId, docId)),
  resetApproverData: () => dispatch(resetApproverData()),
  fetchNewDocumentServiceConfig: () => dispatch<any>(fetchNewDocumentServiceConfig()),
});

const enhance = compose<any, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(sendToApprovalFormDialogStyle),
);

export default enhance(SendToApprovalFormDialog);

import {
  Button,
  Paper,
  TextField,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import * as React from 'react';
import { compose } from 'recompose';
import { v4 as uuid4 } from 'uuid';

import translate from '@/app/utils/translate';

import { ComponentType, componentTypeTranslationKeys } from '../constants';

import addComponentFormStyle from './AddComponentFormStyle';

type IncomingProps = {
  component: any;
  componentType: ComponentType;
  section: any;
  onCancel: () => void;
  onComponentAdd: (component: any) => void;
  onComponentUpdate: (component: any) => void;
};

type PropsType = IncomingProps & WithStyles<typeof addComponentFormStyle>;

const AddOtherComponent: React.FC<PropsType> = ({
  classes,
  component,
  componentType,
  section,
  onCancel,
  onComponentAdd,
  onComponentUpdate,
}) => {
  const [labelValue, setLabelValue] = React.useState('');
  const [labelError, setLabelError] = React.useState('');

  const [defaultValue, setDefaultValue] = React.useState('');
  const [defaultValueError, setDefaultValueError] = React.useState('');

  const [showValidationError, setShowValidationError] = React.useState(false);
  const [disabledSave, setDisabledSave] = React.useState(true);

  React.useEffect(() => {
    if (component) {
      setLabelValue(component.name);
      setDefaultValue(component.defaultValue || '');
    }
  }, []);

  const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLabelValue(e.target.value);
    setDisabledSave(!e.target.value);
    setShowValidationError(false);
  };

  const handleDefaultValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDefaultValue(e.target.value);
    setDisabledSave(!e.target.value);
    setShowValidationError(false);
  };

  const handleSave = () => {
    let hasErrors = false;

    if (labelValue) {
      setLabelError('');
    } else {
      setLabelError(translate.t('laThisRequired'));
      hasErrors = true;
    }

    if (componentType === ComponentType.LINK) {
      if (defaultValue) {
        setDefaultValueError('');
      } else {
        setDefaultValueError(translate.t('laThisRequired'));
        hasErrors = true;
      }
    }

    if (hasErrors) {
      setShowValidationError(true);
      return;
    }

    if (component) {
      onComponentUpdate({
        componentId: component.id,
        type: componentType,
        name: labelValue,
        index: component.index,
        commonGoal: undefined,
        successMeasure: undefined,
        defaultValue:
          componentType === ComponentType.LINK ? defaultValue : undefined,
      });
    } else {
      onComponentAdd({
        componentId: uuid4(),
        type: componentType,
        name: labelValue,
        commonGoal: undefined,
        successMeasure: undefined,
        defaultValue:
          componentType === ComponentType.LINK ? defaultValue : undefined,
        index: section.components ? section.components.length + 1 : 1,
      });
    }
  };
  return (
    <Paper className={classes.root}>
      <form className={classes.form}>
        <Typography variant="h6" className={classes.topTitle}>
          {translate.t(componentTypeTranslationKeys[componentType])}
        </Typography>

        <TextField
          label={translate.t('laLabel')}
          value={labelValue}
          onChange={handleLabelChange}
          error={showValidationError && !!labelError}
          helperText={(showValidationError && labelError) || ''}
          fullWidth={true}
          margin="dense"
          multiline={true}
          rows={1}
          rowsMax={5}
        />

        {componentType === ComponentType.LINK && (
          <TextField
            label={translate.t('laUrl')}
            value={defaultValue}
            onChange={handleDefaultValueChange}
            error={showValidationError && !!defaultValueError}
            helperText={(showValidationError && defaultValueError) || ''}
            fullWidth={true}
            margin="dense"
          />
        )}

        <div className={classes.buttonsWrapper}>
          <Button color="primary" variant="text" onClick={onCancel}>
            {translate.t('laCancel')}
          </Button>

          <Button
            color="primary"
            variant="contained"
            disabled={disabledSave}
            onClick={handleSave}
          >
            {translate.t('laSave')}
          </Button>
        </div>
      </form>
    </Paper>
  );
};

export default compose<any, any>(withStyles(addComponentFormStyle))(
  AddOtherComponent,
);

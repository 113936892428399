import { DATE_FORMAT } from '@/app/utils/helper';
import moment from 'moment';
import { CustomField, CustomFieldType } from './types';

export const formatFieldsValue = (
  formValues: object,
  customFields: CustomField[],
) => {
  return Object.keys(formValues).reduce((acc, code) => {
    const value = formValues[code];
    if (value === undefined || value === '') {
      return acc;
    }
    const field = customFields.find(f => f.code === code);
    switch (field.inputType.value) {
      case CustomFieldType.BOOLEAN: {
        acc[code] = value === true ? 'true' : 'false';
        return acc;
      }
      case CustomFieldType.NUMBER: {
        acc[code] = value.replace(',', '.');
        return acc;
      }
      case CustomFieldType.DATE: {
        acc[code] = moment(value, DATE_FORMAT).format('YYYY-MM-DD');
        return acc;
      }

      default: {
        acc[code] = value;
        return acc;
      }
    }
  }, {});
};

import React from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { FieldProps } from 'formik';
import { hasError, getHelperText } from '../utils';

type OwnProps = {
  value?: string;
  onChange?: (e: React.ChangeEvent<any>) => void;
};
type FormikTextFieldProps = OwnProps & FieldProps & TextFieldProps;

const defaultProps = {
  margin: 'dense' as 'dense',
};

export function FormikTextField({
  field,
  form,
  error,
  helperText,
  value,
  onChange,
  ...others
}: FormikTextFieldProps) {
  return (
    <TextField
      {...defaultProps}
      {...field}
      error={hasError(field.name, form, error)}
      helperText={getHelperText(field.name, form, helperText)}
      value={typeof value === 'string' ? value : field.value || ''}
      onChange={onChange || field.onChange}
      {...others}
    />
  );
}

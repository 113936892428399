import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import EnhancedTable, {
  resetSelection,
  BaseDataType,
} from '@/app/components/EnhancedTable/EnhancedTable';
import { HeadData } from '@/app/components/EnhancedTable/EnhancedTableHead/EnhancedTableHead';
import translate from '@/app/utils/translate';
import trainingListStyle from './trainingListStyle';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { Tools } from '@/app/components/EnhancedTable/EnhancedTableToolbar/EnhancedTableToolbar';
import Service from '@/app/utils/service';
import API from '@/app/api/internalAPIs';
import TrainingFormDialog, {
  loadTraining,
} from '../TrainingFormDialog/TrainingFormDialog';
import { getJsonData, formatStartAndEndDate } from '@/app/utils/helper';
import { openNotifier } from '@/app/redux/notifier';
import update from 'immutability-helper';
import { throwError } from '@/app/redux/error';
import { getEnumName, selectAllEnums } from '@/app/redux/enums';
import {
  openConfirmDialog,
  ConfirmDialogType,
} from '@/app/redux/confirmDialog';

export type TRAINING_TYPE = 'INTERNAL_TRAINING_TYPE' | 'EXTERNAL_TRAINING_TYPE';

export const getTrainingGroupLabel = (type: string): string => {
  if (type === 'INTERNAL_TRAINING_TYPE') {
    return translate.t('laInternalTraining');
  } else if (type === 'EXTERNAL_TRAINING_TYPE') {
    return translate.t('laExternalTraining');
  }
  return '';
};

export const trainingFactory = (d: any): Training => {
  return {
    id: d.fEduCompanyTrainingId,
    name: d.fEduCompanyTrainingName,
    provider: d.fEduCompanyTrainingProvider,
    trainingClass: d.fEduCompanyTrainingClass,
    type: getTrainingGroupLabel(d.fEduCompanyTrainingGroup),
    start: d.fEduCompanyTrainingStartDate,
    end: d.fEduCompanyTrainingEndDate,
    date: formatStartAndEndDate(
      d.fEduCompanyTrainingStartDate,
      d.fEduCompanyTrainingEndDate,
    ),
    active: d.fEduCompanyTrainingActive || false,
    hours: d.fEduCompanyTrainingHours || 0,
    description: d.fEduCompanyTrainingDesc,
    rawData: d,
  };
};

export interface Training extends BaseDataType {
  name: string;
  provider: string;
  trainingClass: string;
  start: string;
  end: string;
  date: string;
  hours: number;
  description: string;
  rawData: any; // copied api data
}

interface StateType {
  trainings: Array<Training>;
  formDialogOpen: boolean;
  confirmDialogOpen: boolean;
  confirmDialogText: string;
  confirmDialogAction: React.MouseEventHandler;
  formDialogTitle: string;
}

const TOOLS: Tools = {
  showAdd: true,
  showEdit: true,
  showActive: true,
  showDelete: true,
};

interface MapStateToProps {
  allEnums: object;
}

interface MapDispatchToProps {
  throwError: Function;
  openNotifier: Function;
  openConfirmDialog: (payload: ConfirmDialogType) => void;
}

type PropsType = MapDispatchToProps & MapStateToProps;

class TrainingList extends React.Component<PropsType> {
  state: StateType = {
    trainings: [],
    formDialogOpen: false,
    confirmDialogOpen: false,
    confirmDialogText: '',
    confirmDialogAction: void 0,
    formDialogTitle: '',
  };
  _asyncRequest: any;

  handleAdd = (_event: React.MouseEvent) => {
    this.formDialogOpen(translate.t('laAddTraining'));
  };

  /**
   * Open add or update training dialog
   */
  formDialogOpen = (title: string) => {
    this.setState({ formDialogOpen: true, formDialogTitle: title });
  };

  /**
   * Close training form dialog
   */
  handleFormDialogClose = () => {
    // reset current training data in form
    loadTraining(null);
    this.setState({ formDialogOpen: false });
  };

  showSuccessMessage = () => {
    this.props.openNotifier();
  };

  delete = (selectedItems: Array<any>) => {
    const selectedIds = selectedItems.map((s: any) => s.id);
    Service.delete(
      API.training.delete,
      { fEduCompanyTrainingIds: selectedIds },
      (data: any) => {
        if (data > 0) {
          this.showSuccessMessage();
          this.setState({
            trainings: this.state.trainings.filter(
              (training) => !selectedIds.includes(training.id),
            ),
          });
          if (selectedIds.length > 1) {
            resetSelection();
          }
        }
      },
      (error: any) => this.props.throwError(error),
    );
  };

  activate = (selectedItems: Array<any>) => {
    const selectedIds = selectedItems.map((s: any) => s.id);
    const payload = {
      fEduCompanyTrainingIds: selectedIds,
      fEduCompanyTrainingAction: 'activate',
    };
    Service.put(
      API.training.updateActive,
      payload,
      (_data: any) => {
        this.showSuccessMessage();
        this.setState((prevSate: StateType) => {
          let copyTrainings = [...prevSate.trainings];
          copyTrainings.map((training) => {
            if (selectedIds.includes(training.id)) {
              training.active = true;
              training.rawData.fEduCompanyTrainingActive = true;
            }
            return training;
          });
          return { training: copyTrainings };
        });
        if (selectedIds.length > 1) {
          resetSelection();
        }
      },
      (error: any) => this.props.throwError(error),
    );
  };

  deactivate = (selectedItems: Array<any>) => {
    const selectedIds = selectedItems.map((s: any) => s.id);
    const payload = {
      fEduCompanyTrainingIds: selectedIds,
      fEduCompanyTrainingAction: 'inactivate',
    };
    Service.put(
      API.training.updateActive,
      payload,
      (_data: any) => {
        this.showSuccessMessage();
        this.setState((prevSate: StateType) => {
          let copyTrainings = [...prevSate.trainings];
          copyTrainings.map((training) => {
            if (selectedIds.includes(training.id)) {
              training.active = false;
              training.rawData.fEduCompanyTrainingActive = false;
            }
            return training;
          });
          return { training: copyTrainings };
        });
        if (selectedIds.length > 1) {
          resetSelection();
        }
      },
      (error: any) => this.props.throwError(error),
    );
  };

  handleEdit = (selectedItems: Array<any>) => (_event: React.MouseEvent) => {
    const selectedIds = selectedItems.map((s: any) => s.id);
    this.formDialogOpen(translate.t('edit_training'));
    loadTraining(
      this.state.trainings.find((training) => training.id === selectedIds[0]),
    );
  };

  handleDelete = (selectedItems: Array<any>) => (_event: React.MouseEvent) => {
    this.props.openConfirmDialog({
      text: translate.t('confirm_delete_item_s'),
      onOk: () => this.delete(selectedItems),
    });
  };

  handleActivate = (selectedItems: Array<any>) => (
    _event: React.MouseEvent,
  ) => {
    this.props.openConfirmDialog({
      text: translate.t('confirm_activate_item_s'),
      onOk: () => this.activate(selectedItems),
    });
  };

  handleDeactivate = (selectedItems: Array<any>) => (
    _event: React.MouseEvent,
  ) => {
    this.props.openConfirmDialog({
      text: translate.t('confirm_deactivate_item_s'),
      onOk: () => this.deactivate(selectedItems),
    });
  };

  componentDidMount() {
    this._asyncRequest = Service.get(
      API.training.list,
      (data: any) => {
        this._asyncRequest = null;
        let trainings: Array<Training> = [];
        if (data instanceof Array) {
          trainings = data.map((d: any) => {
            const className = getEnumName(
              'TRAINING_CLASS',
              d.fEduCompanyTrainingClassFK,
              this.props.allEnums,
            );
            return trainingFactory({
              ...d,
              fEduCompanyTrainingClass: className,
            });
          });
          this.setState({
            trainings,
          });
        }
      },
      (error: any) => this.props.throwError(error),
    );
  }

  // prevent memory leak
  componentWillUnmount() {
    if (this._asyncRequest) {
      this._asyncRequest.abort();
    }
  }

  handleSubmit = (values: any) => {
    // fEduCompanyTrainingName is required in backend
    if (values.fEduCompanyTrainingType) {
      values.fEduCompanyTrainingName = values.fEduCompanyTrainingType.label;
    }

    if (values.fEduCompanyTrainingHours) {
      values.fEduCompanyTrainingHours = parseFloat(
        `${values.fEduCompanyTrainingHours}`.replace(',', '.'),
      );
    }

    const { jsonData } = getJsonData(values);
    // if there is no id, we add
    const trainingId = values.fEduCompanyTrainingId;
    if (!trainingId) {
      return Service.post(
        API.training.add,
        jsonData,
        (data: any) => {
          const className = getEnumName(
            'TRAINING_CLASS',
            data.fEduCompanyTrainingClassFK,
            this.props.allEnums,
          );
          const training = trainingFactory({
            ...data,
            fEduCompanyTrainingClass: className,
          });
          this.setState({
            trainings: [training, ...this.state.trainings],
          });
          this.showSuccessMessage();
          this.handleFormDialogClose();
        },
        (error: any) => this.props.throwError(error),
      );
    }
    return Service.put(
      API.training.update(values.fEduCompanyTrainingId),
      jsonData,
      (data: any) => {
        const className = getEnumName(
          'TRAINING_CLASS',
          data.fEduCompanyTrainingClassFK,
          this.props.allEnums,
        );
        const training = trainingFactory({
          ...data,
          fEduCompanyTrainingClass: className,
        });
        this.setState((prevState: StateType) => {
          const index = prevState.trainings.findIndex(
            (t) => t.id === trainingId,
          );
          const trainings = update(prevState.trainings, {
            [index]: { $set: training },
          });
          return { trainings };
        });
        this.showSuccessMessage();
        this.handleFormDialogClose();
      },
      (error: any) => this.props.throwError(error),
    );
  };

  render() {
    const headData: Array<HeadData> = [
      {
        id: 'name',
        type: 'string',
        disablePadding: false,
        label: translate.t('laTrainingName'),
      },
      {
        id: 'provider',
        type: 'string',
        disablePadding: false,
        label: translate.t('laProvider'),
      },
      {
        id: 'trainingClass',
        type: 'string',
        disablePadding: false,
        label: translate.t('training_class'),
      },
      {
        id: 'type',
        type: 'string',
        disablePadding: false,
        label: translate.t('laType'),
      },
      {
        id: 'date',
        type: 'string',
        disablePadding: false,
        label: translate.t('laDate'),
      },
      {
        id: 'hours',
        type: 'number',
        disablePadding: false,
        label: translate.t('laHours'),
      },
    ];

    const { trainings, formDialogOpen, formDialogTitle } = this.state;

    return (
      <>
        <TrainingFormDialog
          open={formDialogOpen}
          onClose={this.handleFormDialogClose}
          onSubmit={this.handleSubmit}
          title={formDialogTitle}
        />
        <EnhancedTable
          title={translate.t('training_calendar')}
          tools={TOOLS}
          addHandler={this.handleAdd}
          editHandler={this.handleEdit}
          deleteHandler={this.handleDelete}
          deactivateHandler={this.handleDeactivate}
          activateHandler={this.handleActivate}
          headData={headData}
          data={trainings}
          order={'asc'}
          orderBy={'date'}
        />
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  allEnums: selectAllEnums(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  throwError: (err: any) => dispatch(throwError(err)),
  openNotifier: () => dispatch<any>(openNotifier()),
  openConfirmDialog: (payload: ConfirmDialogType) =>
    dispatch(openConfirmDialog(payload)),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(trainingListStyle),
);

export default enhance(TrainingList);

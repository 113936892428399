import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { TasksList as InnerTasksList, TasksListInnerProps, TasksListOuterProps } from './TasksList';
import { mapDispatchToProps, mapStateToProps } from './tasksListRedux';
import { taskListStyles } from './tasksListStyles';

export const TasksList = compose<TasksListInnerProps, TasksListOuterProps>(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(taskListStyles),
)(InnerTasksList);

import * as React from 'react';
import { FormControl, FormControlLabel, FormGroup, Switch } from '@material-ui/core';

const RenderSwitchField = (props: any) => {
  const {
    input,
    label,
    helpText,
    ...custom
  } = props;

  return (
    <FormControl
      fullWidth
      margin="normal"
    >
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={input.value ? true : false}
              onChange={input.onChange}
              {...custom}
            />
          }
          label={label}
        />
      </FormGroup>
      {helpText}
    </FormControl>
  );
};

export default RenderSwitchField;

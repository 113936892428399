import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const styles: StyleRulesCallback = (theme: Theme) => {
  return ({
    dialog: {
      padding: theme.spacing.unit * 3,
    },
    formPaper: {
      backgroundColor: theme.palette.background.default,
      flex: 1,
      padding: theme.spacing.unit * 2,
    },
    actionButtons: {
      padding: theme.spacing.unit * 2,
    },
    footerRow: {
      height: '24px',
    },
    picker: {
      marginTop: '5px',
    },
  });
};

export default styles;

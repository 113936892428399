import { StyleRulesCallback, Theme } from '@material-ui/core';

const styles: StyleRulesCallback = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  grow: {
    flex: 1,
  },
  publicLink: {
    marginRight: theme.spacing.unit,
  },
  cellButton: {
    textTransform: 'none',
  },
  searchField: {
    marginLeft: theme.spacing.unit * 3,
  },
});

export default styles;

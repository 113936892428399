import API from '@/app/api/internalAPIs';
import Service from '@/app/utils/service';
import translate from '@/app/utils/translate';
import {
  Button,
  Checkbox,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { compose } from 'recompose';
import Loading from '../Loading/Loading';
import style from './dataRetentionStyle';
import { mapDataRetention, mapSubmitData } from './mapDataRetention';
import { DataRetentionSetting } from './types';

export type FormData = {
  PEOPLE_AND_REWARDING_checkbox: boolean;
  PEOPLE_AND_REWARDING_select: number;
  PERFORMANCE_checkbox: boolean;
  PERFORMANCE_select: number;
  DEVELOPMENT_checkbox: boolean;
  DEVELOPMENT_select: number;
  RECRUITING_checkbox: boolean;
  RECRUITING_select: number;
  TRAINING_CALENDAR_checkbox: boolean;
  TRAINING_CALENDAR_select: number;
};

type OwnProps = {};
type InnerProps = WithStyles<typeof style>;
type Props = OwnProps & InnerProps;

const DataRetention = ({ classes }: Props) => {
  const [editMode, setEditMode] = useState(false);
  const [dataRetentionSettings, setDataRetentionSettings] = useState([]);
  const [loading, setLoading] = useState(true);
  const { control, handleSubmit, reset, errors, watch } = useForm({
    reValidateMode: 'onChange',
  });
  const peopleValue = watch('PEOPLE_AND_REWARDING_select');
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    Service.get(
      API.dataRetention(),
      (resp: DataRetentionSetting[]) => {
        setDataRetentionSettings(resp);
        reset(mapDataRetention(resp));
        setLoading(false);
      },
      () => {
        enqueueSnackbar(translate.t('laGeneralError'), {
          variant: 'error',
        });
      },
    );
  }, []);

  const timelineOptions = useMemo(() => {
    return [
      {
        label: `6 ${translate.t('laMonths')}`,
        value: 6,
        disabled: peopleValue < 6,
      },
      {
        label: `1 ${translate.t('laYearLK')}`,
        value: 12,
        disabled: peopleValue < 12,
      },
      {
        label: `2 ${translate.t('laYearsLK')}`,
        value: 24,
        disabled: peopleValue < 24,
      },
      {
        label: `3 ${translate.t('laYearsLK')}`,
        value: 36,
        disabled: peopleValue < 36,
      },
      {
        label: `4 ${translate.t('laYearsLK')}`,
        value: 48,
        disabled: peopleValue < 48,
      },
      {
        label: `5 ${translate.t('laYearsLK')}`,
        value: 60,
        disabled: peopleValue < 60,
      },
      {
        label: `10 ${translate.t('laYearsLK')}`,
        value: 120,
        disabled: peopleValue < 120,
      },
      {
        label: `15 ${translate.t('laYearsLK')}`,
        value: 180,
        disabled: peopleValue < 180,
      },
    ];
  }, [peopleValue]);

  const moduleOptions = [
    {
      title: `${translate.t('laPeople')} & ${translate.t('laRewarding')}`,
      name: 'PEOPLE_AND_REWARDING',
    },
    {
      title: translate.t('laPerformance'),
      name: 'PERFORMANCE',
    },
    {
      title: translate.t('laDevelopment'),
      name: 'DEVELOPMENT',
    },
    {
      title: translate.t('laRecruiting'),
      name: 'RECRUITING',
    },
    {
      title: translate.t('training_calendar'),
      name: 'TRAINING_CALENDAR',
    },
  ];

  const handleSaveData = useMemo(() => {
    return handleSubmit((data: any) => {
      Service.put(
        API.dataRetention(),
        {
          fComDataRetentionSettings: mapSubmitData(data, dataRetentionSettings),
        },
        (_data: any) => {
          enqueueSnackbar(translate.t('message_success_approval'), {
            variant: 'success',
          });
          setEditMode(false);
        },
        (_err: any) => {
          enqueueSnackbar(translate.t('laGeneralError'), {
            variant: 'error',
          });
        },
      );
    });
  }, [dataRetentionSettings]);

  return (
    <Paper className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.title}>
          <Typography variant="h6">
            {translate.t('laDataRetentionPolicies')}
          </Typography>
        </div>
        <div className={classes.actions}>
          {!editMode && (
            <Tooltip title={translate.t('laAdd')}>
              <IconButton
                color="primary"
                component="span"
                onClick={() => setEditMode(true)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </Toolbar>
      {loading ? (
        <Loading />
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">{translate.t('laModule')}</TableCell>
              <TableCell align="left">
                {translate.t('laAutoDataRetentionSelect')}
              </TableCell>
              <TableCell align="left">
                {translate.t('laDataRetentionTimeline')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {moduleOptions.map(module => (
              <TableRow key={module.title}>
                <TableCell>{module.title}</TableCell>
                <TableCell>
                  <Controller
                    control={control}
                    name={`${module.name}_checkbox`}
                    defaultValue={true}
                    as={<Checkbox disabled={!editMode} />}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    control={control}
                    name={`${module.name}_select`}
                    as={
                      <Select
                        disabled={!editMode}
                        className={classes.select}
                        error={!!errors[`${module.name}_select`]}
                      >
                        <MenuItem key="none" value={-1} />
                        {timelineOptions.map(timeline => (
                          <MenuItem
                            disabled={
                              module.name !== 'PEOPLE_AND_REWARDING' &&
                              timeline.disabled
                            }
                            key={timeline.label}
                            value={timeline.value}
                          >
                            {timeline.label}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                  />
                  {errors[`${module.name}_select`] && (
                    <p style={{ color: 'red', width: '300px' }}>
                      {errors[`${module.name}_select`].message}
                    </p>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {editMode && (
        <>
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={handleSaveData}
          >
            {translate.t('laSave')}
          </Button>
          <Button
            color="primary"
            className={classes.button}
            onClick={() => {
              reset();
              setEditMode(false);
            }}
          >
            {translate.t('laCancel')}
          </Button>
        </>
      )}
    </Paper>
  );
};

const enhance = compose<Props, OwnProps>(
  withStyles(style, { withTheme: true }),
);

export default enhance(DataRetention);

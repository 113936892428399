export type CustomFieldValues = {
  [key: string]: string | string[];
};

export type FieldAttributes = {
  fCustomDataEntity: string;
  fCustomDataAttributeName: string;
  fCustomDataAttributeLabel: string;
  fCustomDataAttributeDataType: string;
  fCustomDataCreatedTime: string;
  fCustomDataCreatedUserId: number;
  fCustomDataUpdatedTime: string;
  fCustomDataUpdatedUserId?: number;
  fCustomDataAttributeCountry?: string[];
  fCustomDataAttributeDataSubtype?: string;
};

export type FieldLang = {
  code: string;
  description: string;
  name: string;
  seq: number;
};

export enum CustomFieldType {
  BOOLEAN = 'BOOLEAN',
  STRING = 'STRING',
  DATE = 'DATE',
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
  ENUM = 'ENUM',
  MULTISELECT = 'MULTISELECT',
}

export interface CustomField {
  groupName: string;
  code: string;
  description: string;
  name: string;
  label: string;
  inputType: {
    label: string;
    value: CustomFieldType;
  };
  countries?: Array<{
    label: string;
    value: string;
  }>;
  value: string | string[];
}

export interface StateProps {
  customFields: CustomField[];
}

export type AnswerPreProcess = {
  [key: string]: string | string[];
};

export type FieldAttributesHash = {
  [key: string]: FieldAttributes;
};

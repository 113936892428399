import * as React from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BlockIcon from '@material-ui/icons/Block';
import EditIcon from '@material-ui/icons/Edit';
import DuplicateIcon from '@material-ui/icons/FileCopy';
import translate from '@/app/utils/translate';
import { Tools } from '@/app/components/EnhancedTable/EnhancedTableToolbar/EnhancedTableToolbar';
import TranslateIcon from '@material-ui/icons/Translate';
import { compose, withState } from 'recompose';
import { Status } from '@/app/components/JobEvaluation/enums';

export type CustomRowButton = {
  label: string;
  iconButton: React.ReactNode;
  onClick: Function;
  hidden?: boolean;
};

type OwnProps = {
  templateId: string;
  active: boolean;
  tools: Tools;
  changeTemplateStatus: (list: string[], status: string) => void;
  deleteTemplate: (list: string[]) => void;
  duplicateTemplate: (id: string) => void;
  customButtons?: CustomRowButton[];
  hideTranslate?: boolean;
  handleEditTemplate: (ids: string[]) => void;
};

type PropsType = OwnProps & {
  anchorEl: HTMLElement;
  setAnchorEl: (target: EventTarget) => void;
};

const SideRowMenu: React.FunctionComponent<PropsType> = (props) => {
  const handleOpenMenu = (event: React.MouseEvent) => {
    event.stopPropagation(); // stop trigger the row checkbox selection
    event.preventDefault();
    props.setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (event: React.MouseEvent) => {
    event.stopPropagation(); // stop trigger the row checkbox selection
    event.preventDefault();
    props.setAnchorEl(null);
  };

  const handleTemplateStatusChange = (
    event: React.MouseEvent,
    newStatus: string,
  ) => {
    props.changeTemplateStatus([props.templateId], newStatus);
    handleCloseMenu(event);
  };

  const handleTemplateDeletion = (event: React.MouseEvent) => {
    props.deleteTemplate([props.templateId]);
    handleCloseMenu(event);
  };

  const handleTemplateDuplication = (event: React.MouseEvent) => {
    props.duplicateTemplate(props.templateId);
    // TODO: Auto editing disabled. Changes required after BE wiring
    // setTimeout(function () {handleTemplateEdit([newId]); }, 750);
    handleCloseMenu(event);
  };

  const handleEdit = (event: React.MouseEvent) => {
    props.handleEditTemplate([props.templateId]);
    handleCloseMenu(event);
  };

  const translateHandler = (event: React.MouseEvent) => {
    handleCloseMenu(event);
  };

  const { active, tools, anchorEl, customButtons, hideTranslate } = props;

  const {
    showEdit = true,
    showDelete = true,
    showActive = false,
    showTranslate = false,
    showDuplicate = false,
  } = tools;

  const openMenu = Boolean(anchorEl);

  let activateButtons = null;
  if (showActive) {
    activateButtons = !active ? (
      <MenuItem
        onClick={(event) => handleTemplateStatusChange(event, Status.ACTIVE)}
      >
        <ListItemIcon>
          <CheckCircleIcon />
        </ListItemIcon>
        <ListItemText inset primary={translate.t('laActivate')} />
      </MenuItem>
    ) : (
      <MenuItem
        onClick={(event) => handleTemplateStatusChange(event, Status.INACTIVE)}
      >
        <ListItemIcon>
          <BlockIcon />
        </ListItemIcon>
        <ListItemText inset primary={translate.t('button_deactivate')} />
      </MenuItem>
    );
  }
  const editButton = showEdit ? (
    <MenuItem onClick={handleEdit}>
      <ListItemIcon>
        <EditIcon color="primary" />
      </ListItemIcon>
      <ListItemText inset primary={translate.t('laEdit')} />
    </MenuItem>
  ) : null;
  const translateButton =
    showTranslate && !hideTranslate ? (
      <MenuItem onClick={translateHandler}>
        <ListItemIcon>
          <TranslateIcon color="disabled" />
        </ListItemIcon>
        <ListItemText inset primary={translate.t('button_translate')} />
      </MenuItem>
    ) : null;
  const deleteButton = showDelete ? (
    <MenuItem onClick={handleTemplateDeletion}>
      <ListItemIcon>
        <DeleteIcon />
      </ListItemIcon>
      <ListItemText inset primary={translate.t('laDelete')} />
    </MenuItem>
  ) : null;
  const duplicateButton = showDuplicate ? (
    <MenuItem onClick={handleTemplateDuplication}>
      <ListItemIcon>
        <DuplicateIcon />
      </ListItemIcon>
      <ListItemText inset primary={translate.t('label_duplicate')} />
    </MenuItem>
  ) : null;

  if (
    !activateButtons &&
    !editButton &&
    !translateButton &&
    !deleteButton &&
    !duplicateButton &&
    (!customButtons || customButtons.length === 0)
  ) {
    return null;
  }

  return (
    <>
      <IconButton
        aria-label={translate.t('laMore')}
        aria-owns={openMenu ? 'long-menu' : null}
        aria-haspopup="true"
        onClick={handleOpenMenu}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
      >
        {editButton}
        {deleteButton}
        {translateButton}
        {activateButtons}
        {duplicateButton}
      </Menu>
    </>
  );
};

const enhance = compose<PropsType, OwnProps>(
  withState('anchorEl', 'setAnchorEl', null),
);

export default enhance(SideRowMenu);

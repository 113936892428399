import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const fileComponentStyles: StyleRulesCallback = () => ({
  root: {
    '& textarea': {
      // needed because the old css overwrites textarea height in some sections
      minHeight: 20,
      fontFamily: 'Arial, Helvetica, sans-serif',
      fontSize: '0.875em',
    },
  },
  typography: {
    whiteSpace: 'pre-wrap',
    paddingTop: '20px',
    fontFamily: 'Arial, Helvetica, sans-serif',
    fontSize: '0.875em',
    fontWeight: 'bold',
  },
});

export default fileComponentStyles;

import React from 'react';

import {
  CompanyTrainingParticipant,
  CompanyTrainingParticipantType,
} from '../../../types';

import EmployeeDetailsPopover from './EmployeeDetailsPopover';
import ExternalParticipantDetailsPopover from './ExternalParticipantDetailsPopover';

type Props = {
  participant: CompanyTrainingParticipant;
  anchorEl: any;
  onClose: () => void;
};

const ParticipantDetailsPopover = ({ participant, anchorEl, onClose }: Props) => {
  if (participant.type === CompanyTrainingParticipantType.INTERNAL) {
    return (
      <EmployeeDetailsPopover
        employeeId={participant.employeeId}
        anchorEl={anchorEl}
        onClose={onClose}
      />
    );
  }

  return (
    <ExternalParticipantDetailsPopover
      participant={participant}
      anchorEl={anchorEl}
      onClose={onClose}
    />
  );
};

export default ParticipantDetailsPopover;

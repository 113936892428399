import Service from './service';
import translate from '@/app/utils/translate';

export const sendErrorlog = (message: any) => {
  // make ajax call to api to save error on server
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    console.info(message);

    return true;
  }
  const data = {
    platform: require('platform'),
    message,
    url: window.location.href,
  };
  Service.post(
    '/d/json/aux/clienterror',
    JSON.stringify(data),
    () => '',
    () => '',
  );

  return true;
};

export const getErrorMessage = (error: any, status: any): string => {
  let errorType = '';
  let errorDetail = '';
  let errorMessage = '';
  if (error && error.error && error.error.type) {
    errorType = error.error.type;
  }
  if (error && error.error && error.error.detail) {
    errorDetail = error.error.detail;
  }
  if (error && !error.error) {
    errorDetail = error;
  }

  if (error && error.error && error.error.code) {
    /** ErrorCode */
    errorMessage = translate.t(error.error.code);
  } else if (status === 400) {
    /** 400 / BAD REQUEST */
    if (errorType === 'UNSPECIFIED FAILURE') {
      switch (errorDetail) {
        case 'no file uploaded':
          errorMessage = translate.t('laNoFileUploaded');
          break;
        case 'cannot create multiple proposals':
          errorMessage = translate.t('laNoMultipleProps');
          break;
        case 'Email already registered for this training':
          errorMessage = translate.t('laAlreadyRegisteredToTraining');
          break;
        default:
          errorMessage = translate.t('laError');
      }
    } else if (errorType === 'INVALID JSON') {
      errorMessage = translate.t('laGeneralError');
    } else if (errorType === 'INVALID HEADER') {
      errorMessage = translate.t('laGeneralError');
    } else if (errorType === 'INVALID FORM') {
      errorMessage += translate.t('laGeneralError');
    }
  } else if (status === 401) {
    /**  401 / UNAUTHORIZED OR UNAUTHENTICATED: Redirect to signin page */
    window.location.href = '/signin.html';
  } else if (status === 402) {
    /** 402 / MODULE DISABLED: Module supporting given operation is not enabled for this deployment */
    errorMessage = translate.t('laErrorModuleDisabled');
  } else if (status === 403) {
    /*
     * 403 / UNAUTHORIZED: User is logged in, but they do not have access
     * rights to the requested content or to perform the requested modification
     */
    switch (errorDetail) {
      case 'cannot edit self':
        errorMessage = translate.t('laErrorCannotEditSelf');
        break;
      // When the system is configured to use only AD (Active Directory) for signin.
      case 'internal password maintenance disabled':
        errorMessage = translate.t('laErrorInterPwdMaintDisab');
        break;
      default:
        errorMessage = translate.t('laNoAuthorization');
        break;
    }
  } else if (status === 404) {
    /** 404 / NOT FOUND: Object with requested ID not found for PUT (update) requests it will come as a 404 / NOT FOUND */
    errorMessage = translate.t('laGeneralError');
  } else if (status === 409) {
    /** 409 / CONFLICT: Errors are returned for uniqueness violations on data insert/update */
    if (errorType === 'DUPLICATE') {
      errorMessage += translate.t('laGeneralError');
    }
  } else if (status === 415) {
    /** 415 / ILLEGAL CONTENT */
    if (errorType === 'ILLEGAL CONTENT') {
      errorMessage += translate.t('laGeneralError');
    }
  } else if (status === 422) {
    /** 422 / HAS LINK: 422 errors indicate attempt to delete or remove data that is linked to by other data still in the system */
    if (errorType === 'HAS LINKS') {
      errorMessage += translate.t('laErrorHasLinks');
    }
  } else if (status === 424) {
    /** 424 / DANGLING REFERENCE */
    if (errorDetail === 'currency_fk') {
      errorMessage = translate.t('laErrorCurrencyDel');
    } else {
      errorMessage = translate.t('laGeneralError');
    }
  } else if (status === 500) {
    /** 500 / INTERNAL */
    errorMessage = translate.t('laGeneralError');
  } else if (status === 502) {
    /** 502 / BAD GATEWAY ERROR */
    window.location.href = 'error502.html';
  }

  if (!errorMessage) {
    errorMessage = translate.t('laGeneralError');
  }
  if (errorDetail) {
    console.error(errorDetail);
  }
  return errorMessage;
};

import * as React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import {
  WithStyles,
  Paper,
  Button,
  IconButton,
  Icon,
  Tooltip,
  Input,
  InputAdornment,
  Modal,
  Typography,
} from '@material-ui/core';
import translate from '@/app/utils/translate';
import {
  orderElementsByIndex,
  reIndexAndResetDefault,
  nameDuplicationCheck,
} from '../../../../helpers';
import HighlightOff from '@material-ui/icons/HighlightOff';
import addStageStyle from './addStageStyle';
import SortableHOC from '../../EditTemplate/SortableHOC/SortableHOC';

type incomingProps = {
  editedTemplate: any;
  openedStageModal: boolean;
  closeStagesModal: () => void;
  changeStageList: any;
};

type StateType = {
  showValidationError: boolean;
  disabledSave: boolean;
  inputOptions: any[];
  editMode: boolean;
  openSortingDialog: boolean;
  lastUsedIndex: number;
};

type PropsType = incomingProps & WithStyles<typeof addStageStyle>;

class AddStage extends React.PureComponent<PropsType> {
  state: StateType = {
    showValidationError: false,
    disabledSave: true,
    inputOptions: [
      { label: '', index: 0, default: true },
      { label: '', index: 1 },
    ],
    editMode: false,
    openSortingDialog: false,
    lastUsedIndex: 1,
  };

  constructor(props: any) {
    super(props);
    let orderedOptions = [];
    if (props.editedTemplate) {
      const { stages } = this.props.editedTemplate;
      if (stages) {
        orderedOptions = orderElementsByIndex(stages);
        // TEMP - removes stages that were previously deleted
        orderedOptions = stages.filter((stage: any) => !stage.isDeleted);
      }
      this.state = {
        showValidationError: false,
        disabledSave: false,
        inputOptions: orderedOptions,
        openSortingDialog: false,
        editMode: true,
        lastUsedIndex:
          orderedOptions.length > 0
            ? orderedOptions[orderedOptions.length - 1].index
            : this.state.lastUsedIndex,
      };
    }
  }

  addInputOptions = () => {
    this.setState((prevState: any) => ({
      inputOptions: [
        ...prevState.inputOptions,
        { label: '', index: this.state.lastUsedIndex + 1 },
      ],
      disabledSave: true,
      lastUsedIndex: this.state.lastUsedIndex + 1,
    }));
  };

  removeInputOption = (indexToRemove: number) => {
    this.setState((prevState: any) => ({
      inputOptions: prevState.inputOptions.filter(
        (option: any) => option.index !== indexToRemove,
      ),
      disabledSave: false,
      showValidationError: false,
    }));
  };

  handleInputChanges = (e: any, index: number) => {
    let inputs = [...this.state.inputOptions];
    inputs[index].label = e.target.value;
    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].label === '') {
        this.setState({ disabledSave: true });
        return;
      } else {
        this.setState({ disabledSave: false, showValidationError: false });
      }
    }
    this.setState({ inputs });
  };

  componentSortOpen = () => {
    this.setState({ openSortingDialog: true });
  };

  closeSortingModal = () => {
    this.setState({ openSortingDialog: false });
  };

  saveNewOrder = (newOrder: any) => {
    this.setState({ inputOptions: newOrder }, () => {
      this.closeSortingModal();
      newOrder = reIndexAndResetDefault(newOrder);
      this.props.changeStageList(newOrder);
      this.props.closeStagesModal();
    });
  };

  handleSaveStages = (closeStagesModal: () => void) => {
    const nameDuplicated = nameDuplicationCheck(this.state.inputOptions);
    if (nameDuplicated) {
      this.setState({
        disabledSave: true,
        showValidationError: true,
      });
      return;
    }
    this.props.changeStageList(this.state.inputOptions);
    closeStagesModal();
  };

  render() {
    const { classes, closeStagesModal } = this.props;
    const {
      showValidationError,
      disabledSave,
      inputOptions,
      openSortingDialog,
    } = this.state;

    return (
      <div>
        <Modal open={this.props.openedStageModal}>
          <Paper className={classes.root}>
            <form className={classes.form}>
              <div>
                <h3 className={classes.topTitle}>
                  {translate.t('add_stage_label')}
                  <span className={classes.topRightCtrButtons}>
                    <Tooltip title={translate.t('laStage')}>
                      <IconButton onClick={() => this.addInputOptions()}>
                        <Icon color="primary">add_circle</Icon>
                      </IconButton>
                    </Tooltip>
                  </span>
                  <span className={classes.topRightCtrButtons}>
                    <Tooltip title={translate.t('laSort')}>
                      <IconButton
                        disabled={disabledSave}
                        onClick={() => this.componentSortOpen()}
                      >
                        <Icon color={disabledSave ? 'disabled' : 'primary'}>
                          sort
                        </Icon>
                      </IconButton>
                    </Tooltip>
                  </span>
                </h3>
                {inputOptions.map((_input, index) => (
                  <div key={`stage-${inputOptions[index].index}`}>
                    <Input
                      onChange={(e) => {
                        this.handleInputChanges(e, index);
                      }}
                      className={classes.input}
                      defaultValue={inputOptions[index].label}
                      error={showValidationError}
                      multiline={true}
                      rows={1}
                      rowsMax={5}
                      required
                      placeholder={`${translate.t('add_stage_name')}`}
                      endAdornment={
                        index > 0 && (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Delete option"
                              className={classes.button}
                              onClick={() => {
                                this.removeInputOption(
                                  inputOptions[index].index,
                                );
                              }}
                              disableRipple
                            >
                              <HighlightOff />
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    />
                    {showValidationError && inputOptions.length - 1 === index && (
                      <Typography
                        variant="h6"
                        id={`err-label${inputOptions[index].index}`}
                        color="error"
                        className={classes.validationError}
                      >
                        {translate.t('laNameDuplicateError')}
                      </Typography>
                    )}
                  </div>
                ))}
              </div>
              <div className={classes.buttonsWrapper}>
                <Button
                  color="primary"
                  variant="text"
                  onClick={closeStagesModal}
                >
                  {translate.t('laCancel')}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={!!disabledSave}
                  onClick={() => this.handleSaveStages(closeStagesModal)}
                >
                  {translate.t('laSave')}
                </Button>
              </div>
            </form>
          </Paper>
        </Modal>
        <SortableHOC
          key={inputOptions.length}
          open={openSortingDialog}
          itemsToSort={inputOptions}
          saveNewOrder={this.saveNewOrder}
          cancelNewOrder={this.closeSortingModal}
        />
      </div>
    );
  }
}

const enhance = compose<any, any>(withStyles(addStageStyle));

export default enhance(AddStage);

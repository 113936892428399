import { Record } from 'immutable';
import { Dispatch } from 'redux';
import { v4 as uuid4 } from 'uuid';
import { THROW_ERROR } from './error';
import { OPEN_NOTIFIER } from './notifier';
import API from '@/app/api/internalAPIs';
import Service from '@/app/utils/service';
import translate from '../utils/translate';
import {
  getMyTeams,
  getUnitMembers,
  getAllActiveEmployees,
} from '@/old/utils/helper';
import * as moment from 'moment';
import { ChildInputType } from '@/app/components/FormFields/FormFields';
import {
  getPercentage,
  capitalizeFirstLetter,
  Optional,
  SERVER_DATE_FORMAT,
  getCurrentLanguage,
} from '@/app/utils/helper';
import { BarData } from '@/app/components/Charts/BarChart/BarChart';
import { DonutData } from '@/app/components/Charts/DonutChart/DonutChart';
import {
  LikertData,
  LikertLegendData,
} from '@/app/components/Charts/LikertChart/LikertChart';

export enum SURVEY_STATUS {
  DRAFT = 'DRAFT',
  SCHEDULED = 'SCHEDULED',
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  PUBLISHED = 'PUBLISHED',
}

export enum QuestionType {
  YES_NO = 'YESNO',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  SCALE = 'SCALE',
  FREE_TEXT = 'TEXT',
}

export enum RESULTS_SUMMARY {
  ACTIVITY = 'ACTIVITY',
  GENDER = 'GENDER',
  AGE = 'AGE',
}

export type SurveyNotification = {
  id: string;
  language: string;
  name: string;
  status: SURVEY_STATUS;
};

export type SurveySummaryData = {
  type: RESULTS_SUMMARY;
  title: string;
  data: DonutData[];
  total?: Optional<number>;
};

interface ServerSummaryData {
  type: RESULTS_SUMMARY;
  didAnswer?: number;
  didNotAnswer?: number;
  male?: number;
  female?: number;
  none?: number;
  other?: number;
  '< 25'?: number;
  '25-35'?: number;
  '36-45'?: number;
  '46-55'?: number;
  '> 56'?: number;
}

export type ServerScaleData = {
  '1': number;
  '2': number;
  '3': number;
  '4': number;
  '5': number;
};

export type ServerYesNoData = {
  yes: number;
  no: number;
};

export type ServerChoiceData = {
  optionText: string;
  count: number;
  optionId: number;
};

type ServerAnswerData =
  | ServerScaleData
  | ServerYesNoData
  | ServerChoiceData[]
  | string[];
type ServerResultsData = {
  questionId: number;
  questionType: QuestionType;
  questionText: string;
  results: ServerAnswerData;
  index: number;
};

type DonutBarLikertString = DonutData | BarData | LikertData | string;
export type SurveyResultsData = {
  id: number;
  type: QuestionType;
  title: string;
  index: number;
  answers: DonutBarLikertString[];
  total?: Optional<number>;
  legend?: Optional<LikertLegendData[]>;
};
// TODO: FIX these types. Because of the double development (POC and connect with API)
// resulted in almost doubled types, with mostly the same keys inside...
export type ServerSurveyResults = {
  surveyId: string;
  name: string;
  summary: ServerSummaryData[];
  results: ServerResultsData[];
};

type SurveyResultsObject = {
  id: string;
  name: string;
  summary: SurveySummaryData[];
  results: SurveyResultsData[];
  showResults: boolean;
};

export type TranslateSurvey = {
  language: string;
  terms: Object;
};

export type ServerSurveyTranslation = {
  language: string;
  name: string;
  questions: SurveyQuestion[];
};

export type UnitType = {
  label: string;
  value: string;
  id?: number;
  name?: string;
  type: string;
  unitEmployees: [];
};

export interface PartialSurvey {
  id: string;
  audience: UnitType[];
  reviewers: UnitType[];
  startDate: string;
  closeDate: string;
  publishOnClose: boolean;
}

export interface NewSurvey {
  id: string;
  name: string;
  language: string;
  isPrivate: boolean;
  questions: SurveyQuestion[];
  status?: SURVEY_STATUS;
  lang?: ChildInputType;
}

export interface ListSurvey {
  id: string;
  name: string;
  language: string;
  isPrivate: boolean;
  owner: SurveyUser;
  status: SURVEY_STATUS;
  audienceAnswered: number;
  audienceTotal: number;
  surveyAnswers: string;
  audience?: UnitType[];
  surveyAudience?: Optional<string>;
  reviewers?: UnitType[];
  surveyReviewers?: Optional<string>;
  startDate?: string;
  closeDate?: string;
}

type ServerQuestionOption = {
  id: number;
  index: number;
  text: string;
  value?: any;
};

type QuestionOption = {
  index?: number;
  text?: string;
  id?: number;
  value: any;
  order: number;
};

export type ServerQuestion = {
  id: number;
  index: number;
  text: string;
  type: QuestionType;
  language: string;
  yesLabel?: string;
  noLabel?: string;
  options?: ServerQuestionOption[];
  order?: number;
  typeLabel?: string;
};

export type SurveyQuestion = {
  id?: number | string;
  index: number;
  text: string;
  type: QuestionType;
  order: number;
  language?: string;
  yesLabel?: string;
  noLabel?: string;
  options?: QuestionOption[];
  typeLabel?: string;
};

type SurveyUser = {
  id: number;
  username: string;
  employeeId: number;
};

export type ServerSurvey = {
  id: string;
  name: string;
  language: string;
  defaultLanguage: string;
  isPrivate: boolean;
  owner: SurveyUser;
  questions: ServerQuestion[];
  status: SURVEY_STATUS;
  publishOnClose: boolean;
  audienceAnswered: number;
  audienceTotal: number;
  audience: UnitType[];
  surveyAudience?: Optional<string>;
  reviewers: UnitType[];
  startDate: string;
  closeDate: string;
  updatedBy: SurveyUser;
  updatedTime: string;
  createdTime: string;
};

type ServerUnitType = {
  id: number;
  name: string;
  type: string;
};

type ServerListSurvey = {
  id: string;
  name: string;
  language: string;
  isPrivate: boolean;
  owner: SurveyUser;
  status: SURVEY_STATUS;
  audienceAnswered: number;
  audienceTotal: number;
  audience?: ServerUnitType[];
  reviewers?: ServerUnitType[];
  startDate?: string;
  closeDate?: string;
};

export type SurveyCloseDate = {
  id: string;
  startDate: string;
  closeDate: string;
};

export type SurveyAddAudience = {
  id: string;
  oldAudience: UnitType[];
  audience: UnitType[];
};

export type SurveyChangeReviewers = {
  id: string;
  audience: UnitType[];
  reviewers: UnitType[];
};

const FETCHING = '@@solaforce/surveys/FETCHING';
const FETCH_MY_SURVEYS = '@@solaforce/surveys/FETCH_MY_SURVEYS';
const FETCH_ALL_SURVEYS = '@@solaforce/surveys/FETCH_ALL_SURVEYS';
const CREATE_SURVEY = '@@solaforce/surveys/CREATE_SURVEY';
const SCHEDULE_SURVEY = '@@solaforce/surveys/SCHEDULE_SURVEY';
const START_SURVEY = '@@solaforce/surveys/START_SURVEY';
const PUBLISH_SURVEY = '@@solaforce/surveys/PUBLISH_SURVEY';
const UNPUBLISH_SURVEY = '@@solaforce/surveys/UNPUBLISH_SURVEY';
const CLOSE_SURVEY = '@@solaforce/surveys/CLOSE_SURVEY';
const EDIT_SURVEY = '@@solaforce/surveys/EDIT_SURVEY';
const DELETE_SURVEYS = '@@solaforce/surveys/DELETE_SURVEYS';
const SET_AUDIENCE_COUNT = '@@solaforce/surveys/SET_AUDIENCE_COUNT';
const RESET_COUNT = '@@solaforce/surveys/RESET_COUNT';
const GET_NOTIFICATIONS = '@@solaforce/surveys/GET_NOTIFICATIONS';
const SEND_SURVEY_RESPONSE = '@@solaforce/surveys/SEND_SURVEY_RESPONSE';
const OPEN_SURVEY_RESULTS_DIALOG =
  '@@solaforce/surveys/OPEN_SURVEY_RESULTS_DIALOG';
const CLOSE_SURVEY_RESULTS_DIALOG =
  '@@solaforce/surveys/CLOSE_SURVEY_RESULTS_DIALOG';
const OPEN_ANSWER_SURVEY_DIALOG =
  '@@solaforce/surveys/OPEN_ANSWER_SURVEY_DIALOG';
const CLOSE_ANSWER_SURVEY_DIALOG =
  '@@solaforce/surveys/CLOSE_ANSWER_SURVEY_DIALOG';
const GET_FILTERED_RESULTS = '@@solaforce/surveys/GET_FILTERED_RESULTS';
const GET_TRANSLATION = '@@solaforce/surveys/GET_TRANSLATION';
const RESET_TRANSLATIONS = '@@solaforce/surveys/RESET_TRANSLATIONS';
const RESET_ALL_TRANSLATIONS = '@@solaforce/surveys/RESET_ALL_TRANSLATIONS';
const SAVE_SURVEY_TRANSLATION = '@@solaforce/surveys/SAVE_SURVEY_TRANSLATION';
const FETCH_EDIT_SURVEY = '@@solaforce/surveys/FETCH_EDIT_SURVEY';
const FETCH_REVIEWERS = '@@solaforce/surveys/FETCH_REVIEWERS';
const FETCH_QUESTIONS = '@@solaforce/surveys/FETCH_QUESTIONS';
const EMPTY_EDIT = '@@solaforce/surveys/EMPTY_EDIT';
const CHANGE_CLOSE_DATE = '@@solaforce/surveys/CHANGE_CLOSE_DATE';
const ADD_AUDIENCE = '@@solaforce/surveys/ADD_AUDIENCE';
const UPDATE_UNITS_EMPLOYEES_AVAILABLE =
  '@@solaforce/surveys/UPDATE_UNITS_EMPLOYEES_AVAILABLE';
const CHANGE_REVIEWERS = '@@solaforce/surveys/CHANGE_REVIEWERS';
const GET_ALL_ACTIVE_EMPLOYEES = '@@solaforce/surveys/GET_ALL_ACTIVE_EMPLOYEES';

const initialState = Record({
  unitList: [],
  allActiveEmployees: [],
  unitsEmployeesList: [],
  audienceCount: 0,
  surveyAudience: [],
  allSurveys: [],
  isFetching: false,
  notifications: [],
  surveyRawResults: undefined,
  surveyResults: {} as SurveyResultsObject,
  surveyResultsOpen: false,
  answerSurvey: undefined,
  answerSurveyOpen: false,
  allTranslations: undefined,
  translations: undefined,
  refTrData: undefined,
  trData: undefined,
  serverSurvey: undefined as ServerSurvey,
  onlyOwned: true,
  editSurvey: undefined,
  surveyReviewers: [],
  surveyQuestions: [],
});

const generateSurvey = (survey: ServerSurvey) => {
  const {
    audience,
    reviewers,
    status,
    isPrivate,
    audienceAnswered = 0,
    audienceTotal = 0,
    questions: sQuestions,
  } = survey;

  let questions = [] as SurveyQuestion[];
  if (sQuestions && sQuestions.length > 0) {
    const getOptions = (opts: ServerQuestionOption[]): QuestionOption[] =>
      opts
        .map(opt =>
          Object.assign({}, opt, { order: opt.index, value: opt.text }),
        )
        .sort((a, b) => a.index - b.index);

    questions = sQuestions
      .map(
        (question: ServerQuestion): SurveyQuestion =>
          Object.assign(
            {},
            question,
            question.options ? { options: getOptions(question.options) } : {},
            {
              typeLabel: translate.t(`label_${question.type}`),
              order: question.index,
              value: question.text,
            },
          ),
      )
      .sort((a, b) => a.index - b.index);
  }

  let surveyAudience = translate.t('label_none');
  if (audience && audience.length !== 0) {
    surveyAudience =
      audience.length === 1
        ? audience[0].name
        : translate.t('label_units', { count: audience.length });
  }

  let surveyReviewers = translate.t('label_none');
  if (reviewers && reviewers.length !== 0) {
    surveyReviewers =
      reviewers.length === 1
        ? reviewers[0].name
        : translate.t('label_units', { count: reviewers.length });
  }

  let surveyStatus = '';
  if (status === SURVEY_STATUS.ACTIVE) {
    surveyStatus = survey.closeDate
      ? translate.t('label_active_closes', {
          date: moment(survey.closeDate).format('DD.MM.YYYY'),
        })
      : translate.t('label_active_close_not_set');
  } else {
    surveyStatus = translate.t(`label_${status}`);
  }

  const surveyAnswers = `${audienceAnswered}/${audienceTotal} (${getPercentage(
    audienceAnswered,
    audienceTotal,
  )})`;

  return {
    ...survey,
    surveyStatus,
    questions,
    surveyAudience,
    surveyReviewers,
    isPrivate,
    surveyAnswers,
  };
};

const generateSurveys = (surveys: ServerListSurvey[]) =>
  surveys.map((survey: ServerListSurvey) => {
    const {
      audience,
      reviewers,
      status,
      isPrivate,
      audienceAnswered = 0,
      audienceTotal = 0,
    } = survey;

    let surveyAudience = translate.t('label_none');
    if (audience && audience.length !== 0) {
      surveyAudience =
        audience.length === 1
          ? audience[0].name
          : translate.t('label_units', { count: audience.length });
    }

    let surveyReviewers = translate.t('label_none');
    if (reviewers && reviewers.length !== 0) {
      surveyReviewers =
        reviewers.length === 1
          ? reviewers[0].name
          : translate.t('label_units', { count: reviewers.length });
    }

    let surveyStatus = '';
    if (status === SURVEY_STATUS.ACTIVE) {
      surveyStatus = survey.closeDate
        ? translate.t('label_active_closes', {
            date: moment(survey.closeDate).format('DD.MM.YYYY'),
          })
        : translate.t('label_active_close_not_set');
    } else {
      surveyStatus = translate.t(`label_${status}`);
    }

    const surveyAnswers = `${audienceAnswered}/${audienceTotal} (${getPercentage(
      audienceAnswered,
      audienceTotal,
    )})`;

    return {
      ...survey,
      surveyStatus,
      audience: audience
        ? audience.map(a => ({
            value: a.id,
            label: a.name,
            type: a.type,
          }))
        : [],
      reviewers: reviewers
        ? reviewers.map(r => ({
            value: r.id,
            label: r.name,
            type: r.type,
          }))
        : [],
      surveyAudience,
      surveyReviewers,
      isPrivate,
      surveyAnswers,
    };
  });

const generateResults = (r: ServerSurveyResults) => {
  const resObj: SurveyResultsObject = {
    id: r.surveyId,
    name: r.name,
    summary: [] as SurveySummaryData[],
    results: [] as SurveyResultsData[],
    showResults: false,
  };

  const getChartColor = (
    idx: number,
    type?: RESULTS_SUMMARY.AGE | QuestionType,
  ) => {
    let colors: string[];

    switch (type) {
      case RESULTS_SUMMARY.AGE:
        colors = [
          'hsl(195, 96%, 43%)',
          'hsl(94, 73%, 84%)',
          'hsl(195, 93%, 88%)',
          'hsl(49, 100%, 50%)',
          'hsl(216, 22%, 86%)',
        ];
        break;
      case QuestionType.SCALE:
        colors = [
          'hsl(49, 100%, 50%)',
          'hsl(45, 100%, 80%)',
          'hsl(216, 22%, 87%)',
          'hsl(195, 96%, 89%)',
          'hsl(195, 95%, 43%)',
        ];
        break;
      case QuestionType.MULTIPLE_CHOICE:
      case QuestionType.SINGLE_CHOICE:
        colors = ['hsl(195, 96%, 89%)'];
        break;
      default:
        colors = ['hsl(195, 96%, 43%)', 'hsl(49, 100%, 50%)', 'hsl(94, 73%, 84%)', 'hsl(216, 22%, 86%)'];
        break;
    }

    return colors[idx];
  };

  const getDonutData = (
    k: string,
    v: number,
    i: number,
    total: number,
    age?: boolean,
  ): DonutData => {
    let label = '';
    if (k === 'yes' || k === 'no') {
      label = translate.t(`la${capitalizeFirstLetter(k)}`);
    } else if (!age) {
      label = translate.t(`label_question_${k}`);
    } else {
      label = k;
    }

    return Object.assign(
      !age
        ? {
            percentageLabel: `${v} ${((v * 100) / total)
              .toFixed(1)
              .toString()}%`,
          }
        : {},
      {
        id: label,
        label,
        value: v,
        color: getChartColor(i, age ? RESULTS_SUMMARY.AGE : undefined),
      },
    );
  };

  let totalAnswered = 0;
  if (r.hasOwnProperty('summary')) {
    r.summary.forEach((rs: ServerSummaryData) => {
      const rsO: SurveySummaryData = {
        type: rs.type,
        title: translate.t(`title_chart_${rs.type}`),
        data: [],
        total: undefined,
      };
      let total = 0;

      if (rs.type === RESULTS_SUMMARY.ACTIVITY) {
        total = rs.didAnswer + rs.didNotAnswer;
        totalAnswered = rs.didAnswer;
        rsO.title = `${translate.t(`title_chart_${rs.type}`)} ${Math.round(
          (totalAnswered * 100) / total,
        )}%`;
        resObj.showResults = rs.didAnswer > 0;
      }

      if (rs.type === RESULTS_SUMMARY.GENDER) {
        total = totalAnswered;
      }

      Object.keys(rs).forEach((key, i) => {
        if (key !== 'type') {
          rsO.data.push(
            getDonutData(
              key,
              rs[key],
              i,
              total,
              rs.type === RESULTS_SUMMARY.AGE,
            ),
          );
          rsO.total = rs.type !== RESULTS_SUMMARY.AGE ? total : undefined;
        }
      });

      resObj.summary.push(rsO);
    });
  }

  if (r.hasOwnProperty('results')) {
    const getBarData = (
      arr: ServerChoiceData[],
      type: QuestionType,
    ): BarData[] => {
      const barDataArr = arr.map(bd => {
        return {
          label: bd.optionText,
          value: bd.count,
          color: getChartColor(0, type),
          id: bd.optionId,
        };
      });

      return barDataArr.sort((a, b) => b.value - a.value);
    };

    const getScaleData = (
      scO: ServerScaleData,
      question: string,
    ): LikertData => {
      const so = { question };

      Object.keys(scO).forEach((key, i) => {
        const kId = `c${key}`;
        so[kId] = scO[key];
        so[`${kId}Label`] = translate.t(`label_likert_scale_${key}`);
        so[`${kId}Color`] = getChartColor(i, QuestionType.SCALE);
      });

      return <LikertData> so;
    };

    const getScaleLegend = (
      ans: ServerScaleData,
      sData: LikertData,
    ): LikertLegendData[] => {
      const sLegend = [] as LikertLegendData[];

      Object.keys(ans).forEach(k => {
        if (parseInt(k, 10) % 2 !== 0) {
          sLegend.push({
            color: sData[`c${k}Color`],
            label: sData[`c${k}Label`],
          });
        }
      });

      return sLegend;
    };

    r.results.forEach((rr: ServerResultsData) => {
      const { questionId, questionType, questionText, results, index } = rr;

      const rrO: SurveyResultsData = {
        id: questionId,
        type: questionType,
        title: questionText,
        answers: [],
        legend: undefined,
        total: totalAnswered,
        index,
      };

      if (questionType === QuestionType.FREE_TEXT) {
        rrO.answers = [...(<string[]> results)];
      }

      if (
        questionType === QuestionType.MULTIPLE_CHOICE ||
        questionType === QuestionType.SINGLE_CHOICE
      ) {
        rrO.answers = getBarData(<ServerChoiceData[]> results, questionType);
      }

      if (questionType === QuestionType.YES_NO) {
        Object.keys(rr.results).forEach((key, i) => {
          rrO.answers.push(getDonutData(key, results[key], i, totalAnswered));
        });
      }

      if (questionType === QuestionType.SCALE) {
        const scaleData = getScaleData(
          <ServerScaleData> rr.results,
          rr.questionText,
        );
        rrO.answers.push(scaleData);
        rrO.legend = getScaleLegend(<ServerScaleData> rr.results, scaleData);
      }

      resObj.results.push(rrO);
    });

    const res = [...resObj.results]
      .filter(q => q.type !== QuestionType.FREE_TEXT)
      .sort((a, b) => a.index - b.index);
    const comm = [...resObj.results]
      .filter(q => q.type === QuestionType.FREE_TEXT)
      .sort((a, b) => a.index - b.index);
    resObj.results = res.concat(comm);
  }

  return resObj;
};

const getQuestionByType = (q: ServerQuestion) => {
  switch (q.type) {
    case QuestionType.YES_NO:
      return {
        text: q.text,
        yesLabel: q.yesLabel,
        noLabel: q.noLabel,
      };
    case QuestionType.SINGLE_CHOICE:
    case QuestionType.MULTIPLE_CHOICE:
      return {
        text: q.text,
        options: q.options.map(o => (o.text ? o.text : o.value)),
      };
    case QuestionType.SCALE:
    case QuestionType.FREE_TEXT:
    default:
      return {
        text: q.text,
      };
  }
};

const generateTranslation = (srv: ServerSurvey) => {
  let surv = {
    language: srv.language || 'en',
    terms: { [`${translate.t('label_survey_name')}`]: { name: srv.name } },
  } as TranslateSurvey;
  Object.keys(srv).forEach((k: string) => {
    if (k === 'language') {
      surv.language = srv[k];
    } else if (k === 'questions' && srv[k].length > 0) {
      srv[k].forEach((q, i) => {
        surv.terms[
          `${translate.t('label_question')} ${i + 1}`
        ] = getQuestionByType(q);
      });
    }
  });

  return surv;
};

const generateTranslations = (srv: ServerSurvey) => {
  return { [`${srv.name}`]: [generateTranslation(srv)] };
};

const getEmptyGroup = (group: object) => {
  let obj = {};
  Object.keys(group).forEach(key => {
    if (typeof group[key] !== 'string') {
      obj[key] = getEmptyGroup(group[key]);
    } else {
      obj[key] = '';
    }
  });

  return obj;
};

const getTranslationData = (trArr: TranslateSurvey[], language: string) => {
  const trData = [...trArr].find(
    (ref: TranslateSurvey) => ref.language === language,
  );

  if (trData) {
    return trData;
  }

  return Object.assign({}, getEmptyGroup(trArr[0]), {
    language,
  });
};

const reducer = (state = new initialState(), action: any) => {
  switch (action.type) {
    case FETCHING:
      return state.set('isFetching', true);
    case FETCH_MY_SURVEYS:
    case FETCH_ALL_SURVEYS: {
      const { data, onlyOwned } = action.payload;
      const surveys = generateSurveys(data);
      const unitList = getMyTeams();

      return state
        .set('onlyOwned', !!onlyOwned)
        .set('allSurveys', surveys)
        .set('unitList', unitList);
    }
    case GET_ALL_ACTIVE_EMPLOYEES: {
      const allActiveEmployees = getAllActiveEmployees();
      return state.set('allActiveEmployees', allActiveEmployees);
    }
    case FETCH_EDIT_SURVEY: {
      const editSurvey = generateSurvey(action.survey);

      return state.set('editSurvey', editSurvey);
    }
    case EDIT_SURVEY:
    case EMPTY_EDIT: {
      return state.set('editSurvey', undefined);
    }
    case RESET_COUNT:
      return state.set('audienceCount', 0);
    case SET_AUDIENCE_COUNT: {
      let audienceCount: UnitType[] = [];
      action.units.forEach((unit: UnitType) => {
        if (unit.type === 'EMPLOYEE') {
          audienceCount = audienceCount.concat(unit);
        } else {
          audienceCount = audienceCount.concat(
            getUnitMembers(parseInt(unit.value, 10)),
          );
        }
      });

      return state.set('audienceCount', audienceCount.length || 0);
    }
    case UPDATE_UNITS_EMPLOYEES_AVAILABLE: {
      const listOfAddedEmployeeIds = action.alreadyAddedEmployees.map(
        (e: { value: any }) => e.value,
      );
      const updatedListOfEmployees = action.allEmployees.filter(
        (employee: { value: any }) =>
          !listOfAddedEmployeeIds.includes(employee.value),
      );
      return state.set('unitsEmployeesList', updatedListOfEmployees);
    }
    case GET_NOTIFICATIONS: {
      const notifications = action.payload.sort(
        (a: SurveyNotification, b: SurveyNotification) => {
          if (a.status === b.status) {
            return a.name > b.name ? 1 : -1;
          }

          return a.status > b.status ? 1 : -1;
        },
      );

      return state.set('notifications', notifications);
    }
    case FETCH_REVIEWERS:
      return state.set('surveyReviewers', action.reviewers);
    case FETCH_QUESTIONS:
      return state.set('surveyQuestions', action.questions);
    case OPEN_SURVEY_RESULTS_DIALOG: {
      const surveyResults = generateResults(action.results);

      return state
        .set('surveyRawResults', action.results)
        .set('surveyResults', surveyResults)
        .set('surveyResultsOpen', true);
    }
    case CLOSE_SURVEY_RESULTS_DIALOG:
      return state
        .set('surveyRawResults', undefined)
        .set('surveyResults', undefined)
        .set('surveyReviewers', [])
        .set('surveyQuestions', [])
        .set('surveyResultsOpen', false);
    case OPEN_ANSWER_SURVEY_DIALOG: {
      const answerSurvey = generateSurvey(action.answerSurvey);

      return state
        .set('answerSurvey', answerSurvey)
        .set('answerSurveyOpen', true);
    }
    case CLOSE_ANSWER_SURVEY_DIALOG:
      return state
        .set('answerSurvey', undefined)
        .set('answerSurveyOpen', false);
    case GET_FILTERED_RESULTS: {
      const filteredSurveyResults = generateResults(action.results);

      return state
        .set('surveyRawResults', action.results)
        .set('surveyResults', filteredSurveyResults);
    }
    case GET_TRANSLATION: {
      const { allTranslations, serverSurvey } = state;
      const { survey: newSurvey, lang, ref } = action.payload;

      let survey;
      if (serverSurvey) {
        survey = { ...serverSurvey };
      } else {
        survey = { ...newSurvey };
      }

      const { name } = survey;

      let translations;
      if (!allTranslations) {
        translations = generateTranslations(survey);
      } else {
        translations = { ...allTranslations };
      }

      const aSurvey = generateTranslation(newSurvey);
      const foundIdx = translations[name].findIndex(
        (s: TranslateSurvey) => s.language === aSurvey.language,
      );

      if (foundIdx === -1) {
        translations[name].push(aSurvey);
      } else {
        translations[name][foundIdx] = aSurvey;
      }

      const aTrData = getTranslationData(
        translations[name],
        ref ? aSurvey.language : lang,
      );

      return state
        .set('allTranslations', { ...translations })
        .set('translations', translations)
        .set(ref ? 'refTrData' : 'trData', aTrData)
        .set('serverSurvey', survey);
    }
    case SAVE_SURVEY_TRANSLATION:
    case RESET_ALL_TRANSLATIONS:
      return state
        .set('allTranslations', undefined)
        .set('translations', undefined)
        .set('refTrData', undefined)
        .set('trData', undefined)
        .set('serverSurvey', undefined);
    case RESET_TRANSLATIONS:
      return state.set('translations', undefined);
    case ADD_AUDIENCE:
    case CHANGE_REVIEWERS:
    case CHANGE_CLOSE_DATE:
    case SEND_SURVEY_RESPONSE:
    case CREATE_SURVEY:
    case SCHEDULE_SURVEY:
    case DELETE_SURVEYS:
    case START_SURVEY:
    case CLOSE_SURVEY:
    case PUBLISH_SURVEY:
    case UNPUBLISH_SURVEY:
    default:
      return state;
  }
};

export const getTranslations = (
  surveyId: string,
  refLang: string,
  trLang: string,
) => {
  return async (dispatch: Dispatch) => {
    const language = refLang || getCurrentLanguage();

    await Service.get(
      API.solaPulse.get(surveyId, language),
      (survey: ServerSurvey) => {
        const payload = {
          survey,
          lang: refLang,
          ref: true,
        };

        dispatch({ type: GET_TRANSLATION, payload });
      },
      (err: any) => dispatch({ type: THROW_ERROR, err }),
    );

    await dispatch({ type: RESET_TRANSLATIONS });
    await dispatch<any>(getTranslation(surveyId, trLang));
  };
};

export const resetAllTranslations = () => ({ type: RESET_ALL_TRANSLATIONS });

export const getRefTranslation = (surveyId: string, lang: string) => {
  return (dispatch: Dispatch) => {
    const language = lang || getCurrentLanguage();
    Service.get(
      API.solaPulse.get(surveyId, language),
      (survey: ServerSurvey) => {
        const payload = {
          survey,
          lang,
          ref: true,
        };

        dispatch({ type: GET_TRANSLATION, payload });
      },
      (err: any) => dispatch({ type: THROW_ERROR, err }),
    );
  };
};

export const getTranslation = (surveyId: string, lang: string) => {
  return (dispatch: Dispatch) => {
    Service.get(
      API.solaPulse.get(surveyId, lang),
      (survey: ServerSurvey) => {
        const payload = {
          survey,
          lang,
          ref: false,
        };

        dispatch({ type: GET_TRANSLATION, payload });
      },
      (err: any) => dispatch({ type: THROW_ERROR, err }),
    );
  };
};

export const saveTranslation = (
  surveyId: string,
  translation: ServerSurveyTranslation,
) => {
  return (dispatch: Dispatch) => {
    Service.post(
      API.solaPulse.translate(surveyId),
      translation,
      () => {
        dispatch({ type: SAVE_SURVEY_TRANSLATION });
        dispatch({ type: OPEN_NOTIFIER, payload: {} });
      },
      (err: any) => dispatch({ type: THROW_ERROR, err }),
    );
  };
};

export const fetchSurveys = (onlyOwned?: boolean) => {
  return async (dispatch: Dispatch) => {
    await dispatch({ type: FETCHING });

    Service.get(
      API.solaPulse.getAll(!!onlyOwned),
      (response: any) => {
        const payload = {
          data: response.data,
          onlyOwned,
        };

        dispatch({
          type: onlyOwned ? FETCH_MY_SURVEYS : FETCH_ALL_SURVEYS,
          payload,
        });

        dispatch<any>(getNotifications());
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const fetchEditSurvey = (surveyId: string) => {
  return (dispatch: Dispatch) => {
    Service.get(
      API.solaPulse.get(surveyId),
      (survey: ServerSurvey) => {
        dispatch({ type: FETCH_EDIT_SURVEY, survey });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const emptyEdit = () => ({ type: EMPTY_EDIT });

const getSurveyQuestion4Server = (q: SurveyQuestion, language: string) => {
  const { text, type, order, yesLabel, noLabel, options, index } = q;

  switch (q.type) {
    case QuestionType.FREE_TEXT:
    case QuestionType.SCALE:
      return {
        language,
        text,
        type,
        index: index || order,
      };
    case QuestionType.YES_NO:
      return {
        language,
        text,
        type,
        index: index || order,
        yesLabel,
        noLabel,
      };
    case QuestionType.SINGLE_CHOICE:
    case QuestionType.MULTIPLE_CHOICE:
      return {
        language,
        text,
        type,
        index: index || order,
        options: options.map(o => ({
          text: o.value,
          index: o.order,
        })),
      };
    default:
      return q;
  }
};

const getDuplicatedQuestions = (q: ServerQuestion) => {
  const duplicatedQ = Object.assign(
    {},
    q,
    q.hasOwnProperty('options')
      ? {
          options: q.options.map(o => ({
            text: o.text,
            index: o.index,
          })),
        }
      : {},
  );

  delete duplicatedQ.id;

  return duplicatedQ;
};

const getNewSurvey = (srv: any) => {
  // TODO: fix type
  const language = srv.lang.value;

  const newSrv = {
    ...srv,
    language,
    questions: srv.questions.map((q: any) =>
      getSurveyQuestion4Server(q, language),
    ),
  };

  delete newSrv.status;
  delete newSrv.lang;

  return newSrv;
};

export const createSurvey = (newSurvey: NewSurvey, copy?: boolean) => {
  return (dispatch: Dispatch, getState: Function) => {
    const survey = !copy ? getNewSurvey(newSurvey) : { ...newSurvey };

    Service.post(
      API.solaPulse.create,
      survey,
      () => {
        const { onlyOwned } = getState().surveys;

        dispatch({ type: CREATE_SURVEY });
        dispatch({ type: OPEN_NOTIFIER, payload: {} });

        dispatch<any>(fetchSurveys(onlyOwned));
      },
      (err: any) => dispatch({ type: THROW_ERROR, err }),
    );
  };
};

export const scheduleSurvey = (partSurvey: PartialSurvey) => {
  const {
    startDate,
    closeDate,
    publishOnClose,
    audience,
    reviewers,
  } = partSurvey;
  const survey = {
    publishOnClose,
    startDate: moment(startDate).format(SERVER_DATE_FORMAT),
    closeDate: moment(closeDate).format(SERVER_DATE_FORMAT),
    audience: audience.map(a => ({ id: a.value, type: a.type })),
    reviewers: reviewers.map(r => ({ id: r.value, type: r.type })),
  };

  return (dispatch: Dispatch, getState: Function) => {
    Service.post(
      API.solaPulse.schedule(partSurvey.id),
      survey,
      (response: any) => {
        const { onlyOwned } = getState().surveys;

        dispatch({ type: SCHEDULE_SURVEY, payload: response });
        dispatch({ type: OPEN_NOTIFIER, payload: {} });

        dispatch<any>(fetchSurveys(onlyOwned));
      },
      (err: any) => dispatch({ type: THROW_ERROR, err }),
    );
  };
};

const getDuplicatedSurvey = (srv: ServerSurvey) => {
  const { name, language, isPrivate, questions } = srv;

  return {
    id: uuid4(),
    name,
    language,
    isPrivate,
    questions: questions.map(q => getDuplicatedQuestions(q)),
  };
};

export const duplicateSurvey = (surveyId: string) => {
  return async (dispatch: Dispatch, getState: Function) => {
    let duplicatedSurvey;

    await Service.get(
      API.solaPulse.get(surveyId),
      (response: ServerSurvey) => {
        const { allSurveys } = getState().surveys;

        const sameNameSurveys = allSurveys.filter(
          (s: ListSurvey) =>
            s.name.includes(`${response.name} (`) ||
            s.name.includes(response.name),
        );
        duplicatedSurvey = getDuplicatedSurvey({ ...response });
        duplicatedSurvey.name = `${response.name} (${translate.t('laCopy')} ${
          sameNameSurveys ? sameNameSurveys.length : 1
        })`;
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );

    await dispatch<any>(createSurvey(duplicatedSurvey, true));
  };
};

export const updateSurvey = (survey: NewSurvey) => {
  const { name, isPrivate, questions: qs, id: surveyId } = survey;

  const questions = qs.map(q => {
    const { id, type, language, text, options, yesLabel, noLabel, order } = q;
    const notEdit = id && typeof id === 'number';

    return Object.assign(
      {},
      notEdit ? { id } : {},
      options
        ? {
            options: options.map(o =>
              notEdit
                ? {
                    id: o.id,
                    text: o.value,
                    index: o.order,
                  }
                : {
                    text: o.value,
                    index: o.order,
                  },
            ),
          }
        : {},
      yesLabel ? { yesLabel } : {},
      noLabel ? { noLabel } : {},
      language ? { language } : {},
      {
        type,
        text,
      },
      { index: order },
    );
  });

  const updatedSurvey = {
    name,
    isPrivate,
    questions,
  };

  return (dispatch: Dispatch, getState: Function) => {
    Service.post(
      API.solaPulse.update(surveyId),
      updatedSurvey,
      () => {
        const { onlyOwned } = getState().surveys;

        dispatch({ type: EDIT_SURVEY });
        dispatch({ type: OPEN_NOTIFIER, payload: {} });

        dispatch<any>(fetchSurveys(onlyOwned));
      },
      (err: any) => dispatch({ type: THROW_ERROR, err }),
    );
  };
};

export const deleteSurveys = (surveyIds: string[]) => {
  return (dispatch: Dispatch, getState: Function) => {
    Service.post(
      API.solaPulse.bulkDelete,
      { surveyIds },
      () => {
        const { onlyOwned } = getState().surveys;

        dispatch({ type: DELETE_SURVEYS });
        dispatch({ type: OPEN_NOTIFIER, payload: {} });

        dispatch<any>(fetchSurveys(onlyOwned));
      },
      (err: any) => dispatch({ type: THROW_ERROR, err }),
    );
  };
};

export const setAudienceCount = (units: UnitType[]) => ({
  type: SET_AUDIENCE_COUNT,
  units,
});

export const getAllActiveEmployeesForSurveys = () => ({
  type: GET_ALL_ACTIVE_EMPLOYEES,
});

export const updateEmployeesList = (
  allEmployees: any[],
  alreadyAddedEmployees: any[],
) => ({
  type: UPDATE_UNITS_EMPLOYEES_AVAILABLE,
  allEmployees,
  alreadyAddedEmployees,
});

export const resetCount = () => ({ type: RESET_COUNT });

export const startSurvey = (surveyId: string) => {
  return (dispatch: Dispatch, getState: Function) => {
    Service.post(
      API.solaPulse.start(surveyId),
      {},
      () => {
        const { onlyOwned } = getState().surveys;

        dispatch({ type: START_SURVEY });
        dispatch({ type: OPEN_NOTIFIER, payload: {} });

        dispatch<any>(fetchSurveys(onlyOwned));
      },
      (err: any) => dispatch({ type: THROW_ERROR, err }),
    );
  };
};

export const closeSurvey = (surveyId: string) => {
  return (dispatch: Dispatch, getState: Function) => {
    Service.post(
      API.solaPulse.close(surveyId),
      {},
      () => {
        const { onlyOwned } = getState().surveys;

        dispatch({ type: CLOSE_SURVEY });
        dispatch({ type: OPEN_NOTIFIER, payload: {} });

        dispatch<any>(fetchSurveys(onlyOwned));
      },
      (err: any) => dispatch({ type: THROW_ERROR, err }),
    );
  };
};

export const publishSurvey = (surveyId: string) => {
  return (dispatch: Dispatch, getState: Function) => {
    Service.post(
      API.solaPulse.publish(surveyId),
      {},
      () => {
        const { onlyOwned } = getState().surveys;

        dispatch({ type: PUBLISH_SURVEY });
        dispatch({ type: OPEN_NOTIFIER, payload: {} });

        dispatch<any>(fetchSurveys(onlyOwned));
      },
      (err: any) => dispatch({ type: THROW_ERROR, err }),
    );
  };
};

export const unpublishSurvey = (surveyId: string) => {
  return (dispatch: Dispatch, getState: Function) => {
    Service.post(
      API.solaPulse.unpublish(surveyId),
      {},
      () => {
        const { onlyOwned } = getState().surveys;

        dispatch({ type: UNPUBLISH_SURVEY });
        dispatch({ type: OPEN_NOTIFIER, payload: {} });

        dispatch<any>(fetchSurveys(onlyOwned));
      },
      (err: any) => dispatch({ type: THROW_ERROR, err }),
    );
  };
};

export const getNotifications = () => {
  return (dispatch: Dispatch) => {
    Service.get(
      API.solaPulse.getNotifications,
      (response: any) => {
        dispatch({ type: GET_NOTIFICATIONS, payload: response.data });
      },
      (err: any) => dispatch({ type: THROW_ERROR, err }),
    );
  };
};

export const sendSurveyResponse = (surveyId: string, response: any) => {
  // TODO: type?
  return (dispatch: Dispatch) => {
    Service.post(
      API.solaPulse.answer(surveyId),
      response,
      (data: any) => {
        const payload = {
          surveyId,
          data,
          response,
        };

        dispatch({ type: SEND_SURVEY_RESPONSE, payload });
        dispatch({ type: OPEN_NOTIFIER, payload: {} });
        dispatch<any>(getNotifications());
      },
      (err: any) => {
        dispatch({ type: THROW_ERROR, err });
        // TODO: AFTER BE FIXED, NEEDS TO BE REMOVED FROM HERE
        if (err.data.error.type === 'NOT AUTHORIZED') {
          dispatch({
            type: OPEN_NOTIFIER,
            payload: {
              type: 'error',
              message: translate.t('youNotInSurveyAudience'),
            },
          });
        }
      },
    );
  };
};

export const getAndOpenAnswerDialog = (surveyId: string) => {
  return (dispatch: Dispatch) => {
    Service.get(
      API.solaPulse.view(surveyId),
      (answerSurvey: ServerSurvey) => {
        dispatch({ type: OPEN_ANSWER_SURVEY_DIALOG, answerSurvey });
      },
      (err: any) => dispatch({ type: THROW_ERROR, err }),
    );
  };
};

export const closeAnswerDialog = () => ({ type: CLOSE_ANSWER_SURVEY_DIALOG });

export const getAndOpenResultsDialog = (surveyId: string) => {
  return async (dispatch: Dispatch) => {
    await Service.get(
      API.solaPulse.view(surveyId),
      (survey: ServerSurvey) => {
        dispatch({ type: FETCH_REVIEWERS, reviewers: survey.reviewers });
        dispatch({ type: FETCH_QUESTIONS, questions: survey.questions });
      },
      (err: any) => dispatch({ type: THROW_ERROR, err }),
    );

    await Service.get(
      API.solaPulse.surveyReport(surveyId),
      (results: ServerSurveyResults) => {
        dispatch({ type: OPEN_SURVEY_RESULTS_DIALOG, results });
      },
      (err: any) => dispatch({ type: THROW_ERROR, err }),
    );
  };
};

export const closeResultsDialog = () => ({ type: CLOSE_SURVEY_RESULTS_DIALOG });

export const getFilteredResults = (surveyId: string, unitId?: number) => {
  return (dispatch: Dispatch) => {
    Service.get(
      API.solaPulse.surveyReport(surveyId, unitId),
      (results: ServerSurveyResults) => {
        dispatch({ type: GET_FILTERED_RESULTS, results });
      },
      (err: any) => dispatch({ type: THROW_ERROR, err }),
    );
  };
};

export const changeCloseDate = (surveyData: SurveyCloseDate) => {
  return (dispatch: Dispatch, getState: Function) => {
    const { id: surveyId, closeDate } = surveyData;

    Service.post(
      API.solaPulse.changeCloseDate(surveyId),
      { closeDate: moment(closeDate).format(SERVER_DATE_FORMAT) },
      () => {
        const { onlyOwned } = getState().surveys;

        dispatch({ type: CHANGE_CLOSE_DATE });
        dispatch<any>(fetchSurveys(onlyOwned));
      },
      (err: any) => dispatch({ type: THROW_ERROR, err }),
    );
  };
};

export const addAudience = (surveyData: SurveyAddAudience) => {
  return (dispatch: Dispatch, getState: Function) => {
    const { id: surveyId, audience: newAudience } = surveyData;
    const audience = newAudience.map(a => ({ id: a.value, type: a.type }));

    Service.post(
      API.solaPulse.addAudience(surveyId),
      { audience },
      () => {
        const { onlyOwned } = getState().surveys;

        dispatch({ type: ADD_AUDIENCE });
        dispatch<any>(fetchSurveys(onlyOwned));
      },
      (err: any) => dispatch({ type: THROW_ERROR, err }),
    );
  };
};

export const changeReviewers = (surveyData: SurveyChangeReviewers) => {
  return (dispatch: Dispatch, getState: Function) => {
    const { id: surveyId, reviewers: newReviewers } = surveyData;
    const reviewers = newReviewers.map(r => ({ id: r.value, type: r.type }));

    Service.post(
      API.solaPulse.changeReviewers(surveyId),
      { reviewers },
      () => {
        const { onlyOwned } = getState().surveys;

        dispatch({ type: CHANGE_REVIEWERS });
        dispatch<any>(fetchSurveys(onlyOwned));
      },
      (err: any) => dispatch({ type: THROW_ERROR, err }),
    );
  };
};

export default reducer;

import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { WithStyles, Grid, Card, CardContent, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import translate from '@/app/utils/translate';
import { INPUT_TYPE, getUniqValues } from '@/app/utils/helper';
import FormFields from '@/app/components/FormFields/FormFields';
import addAudienceFormDialogStyle from './addAudienceFormDialogStyle';
import { getFormDialog } from '@/app/components/FormDialog/FormDialog';
import { getFormValues, reset, untouch } from 'redux-form';
import { setAudienceCount, resetCount, UnitType, ListSurvey, SurveyAddAudience, updateEmployeesList } from '@/app/redux/surveys';
import { changeFormData, FormDataType } from '@/app/redux/formData';
import { ConfirmDialogType, openConfirmDialog } from '@/app/redux/confirmDialog';
import { getUnitMembers, lookForSubUnits } from '@/old/utils/helper';
import { ReducerState } from '@/app/redux/store';

const FORM_NAME = 'addAudienceForm';

type MapStateToProps = {
  currentValues: SurveyAddAudience;
  unitList: UnitType[];
  unitsEmployeesList: any;
  allActiveEmployees: [];
  audienceCount: number;
};

type MapDispatchToProps = {
  resetForm: () => void;
  changeFormData: (payload: FormDataType) => void;
  setAudienceCount: (units: UnitType[]) => void;
  updateEmployeesList: (allActiveEmployees: any[], employeeList: any[]) => void;
  resetCount: () => void;
  openConfirmDialog: (payload: ConfirmDialogType) => void;
  untouch: (field: string) => void;
};

type OwnProps = {
  title: string;
  open: boolean;
  survey: ListSurvey;
  onClose: () => void;
  onSubmit: (survey: SurveyAddAudience) => void;
};

type PropsType = OwnProps & WithStyles<typeof addAudienceFormDialogStyle> & MapDispatchToProps & MapStateToProps;

const emptyForm = () => {
  return {
    id: '',
    oldAudience: undefined as UnitType[],
    audience: undefined as UnitType[],
  };
};

type StateType = {
  formData: SurveyAddAudience;
};

class AddAudienceFormDialog extends React.Component<PropsType> {
  state: StateType = {
    formData: emptyForm(),
  };

  validate = (values: SurveyAddAudience) => {
    const errors: {[k: string]: any} = {};
    const requiredFields = [
      'audience',
    ];

    requiredFields.forEach(field => {
      if (!values[field] || values[field] === '<p>&nbsp;</p>' || values[field] && values[field].length === 0) {
        errors[field] = translate.t('laThisRequired');
      }
    });

    return errors;
  };

  componentDidUpdate(prevProps: PropsType) {
    const { open, survey } = this.props;
    const { open: prevOpen, survey: prevSurvey } = prevProps;

    if (open && open !== prevOpen && JSON.stringify(survey) !== JSON.stringify(prevSurvey)) {
      this.loadData(survey);
    }
  }

  loadData = (survey: ListSurvey) => {
    const { id, audience: oldAudience } = survey;
    const formData = {
      id,
      audience: undefined as UnitType[],
      oldAudience,
    };

    this.setState(
      { formData },
      () => this.props.untouch('audience')
    );
  };

  handleChangeAudience = (event: any) => {
    const { currentValues, allActiveEmployees } = this.props;
    const alreadyAddedAudienceByUnits: any = [];
    let subUnits: number[] = [];
    const audience: UnitType[] = [];
    Object.values(event).forEach((item: UnitType) => {
      if (typeof item === 'object') {
        if (item.type === 'UNIT' && item.unitEmployees) {
          alreadyAddedAudienceByUnits.push(...item.unitEmployees);
        }
        if (item.type === 'EMPLOYEE') {
          alreadyAddedAudienceByUnits.push(...[item]);
        }
        audience.push(item);
      }
    });

    if (currentValues.audience && currentValues.audience.length > audience.length) {
      this.props.setAudienceCount(audience);
    } else {
      subUnits = lookForSubUnits(audience);

      if (subUnits && subUnits.length > 0) {
        this.props.openConfirmDialog({
          text: translate.t('confirm_add_subunits'),
          onOk: () =>  {
            subUnits.forEach((subUnit: any) => {
              alreadyAddedAudienceByUnits.push(...getUnitMembers(subUnit, true));
            });
            this.props.updateEmployeesList(allActiveEmployees, alreadyAddedAudienceByUnits);
            this.setDataField(audience, subUnits);
          },
          onClose: () => this.setFormAudience(audience),
          customOk: translate.t('laYes'),
          customClose: translate.t('laNo'),
        });
      } else {
        this.props.setAudienceCount(audience);
      }
    }
    this.props.updateEmployeesList(allActiveEmployees, alreadyAddedAudienceByUnits);
  }

  getSubUnitsValuesFromIds = (ids: number[]) => {
    const { unitList } = this.props;
    const values = ids.map((id: number) => (
      unitList.find((unit: UnitType) => parseInt(unit.value, 10) === id)
    ));

    return values;
  };

  setDataField = (unitList: UnitType[], subUnits: number[]) => {
    const subUnitValues = this.getSubUnitsValuesFromIds(subUnits);
    const newUnitList = [...unitList].concat(subUnitValues);
    const units: UnitType[] = getUniqValues(newUnitList);

    this.setFormAudience(units);
  };

  setFormAudience = (unitList: UnitType[]) => {
    const { currentValues } = this.props;
    const audience = getUniqValues(unitList);

    this.setState({
      formData: { ...currentValues, audience },
    });

    this.props.setAudienceCount(audience);
    this.props.changeFormData({ form: FORM_NAME, field: 'audience', value: audience });
  };

  setEmptyState = () => {
    this.setState({
      formData: emptyForm(),
    });
  };

  handleClose = () => {
    const { onClose, resetForm } = this.props;

    this.setEmptyState();
    onClose();
    resetForm();
  };

  handleSubmit = (values: SurveyAddAudience) => {
    this.props.openConfirmDialog({
      text: translate.t('confirm_add_audience'),
      onOk: () => submit()
    });

    const submit = () => {
      const { onSubmit, resetForm } = this.props;

      this.setEmptyState();
      onSubmit(values);
      resetForm();
    };
  };

  renderOldAudience = () => (
    <Grid item xs={12}>
      <FormFields
        inputs={[{
          type: INPUT_TYPE.MULTISELECT,
          code: 'oldAudience',
          name: 'oldAudience',
          label: translate.t('label_audience'),
          childrenInputs: this.props.unitList
            .concat(this.props.unitsEmployeesList.length > 0 ? this.props.unitsEmployeesList : this.props.allActiveEmployees),
          order: 1,
          required: false,
          configs: {
            isSearchable: false,
            isDisabled: true,
            isCreatable: false,
          },
        }]}
      />
    </Grid>
  );

  renderAudience = () => {
    const { survey, unitList, allActiveEmployees } = this.props;
    let addedUnits: any[] = [];
    let addedEmployees: any[] = [];
    if (survey) {
      survey.audience.forEach(entry => {
        if (entry.type === 'UNIT') {
          addedUnits.push(entry);
        }
        if (entry.type === 'EMPLOYEE') {
          addedEmployees.push(entry);
        }
      });
    }
    let addedUnitsIds = addedUnits.map(u => u.value);
    let addedEmployeesIds = addedEmployees.map(e => e.value);
    let remainingUnits = unitList.filter(u => !addedUnitsIds.includes(u.value));
    let remainingEmployees = allActiveEmployees.filter((e: any) => !addedEmployeesIds.includes(e.value));
    const completeAudieceList = remainingUnits.concat(remainingEmployees);

    return (
      <Grid item xs={12}>
        <FormFields
          inputs={[{
            type: INPUT_TYPE.MULTISELECT,
            code: 'audience',
            name: 'audience',
            label: translate.t('label_audience_new'),
            childrenInputs: this.state.formData.audience === undefined
              ? completeAudieceList : remainingUnits.concat(this.props.unitsEmployeesList),
            order: 2,
            required: true,
            configs: {
              isSearchable: true,
              isDisabled: false,
              isCreatable: false,
            },
            onChange: (value: any) => this.handleChangeAudience(value),
          }]}
        />
      </Grid>
    );
  };

  FormDialog = getFormDialog(FORM_NAME, this.validate);

  render() {
    const { title, open, classes, audienceCount } = this.props;
    const { formData } = this.state;
    const { FormDialog } = this;

    return (
      <FormDialog
        title={title}
        open={open}
        onClose={this.handleClose}
        formData={formData}
        paperWidthMd={classes.paperWidthMd}
        onSubmit={this.handleSubmit}
        customSubmitLabel={translate.t('laAdd')}
        customCloseLabel={translate.t('laCancel')}
      >
        <Grid container className={classes.root} justify="space-between">
          <Grid container item xs={12} className={classes.multiSelectContainer}>
            {this.renderOldAudience()}
          </Grid>

          <Grid container item xs={12} className={classes.multiSelectContainer}>
            {this.renderAudience()}
          </Grid>

          <Grid container item xs={12} justify="center" alignItems="center">
            {audienceCount > 0 ?
              (
                <Card className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    <Typography variant="body2">
                      <b>{translate.t('text_note')}</b> {translate.t('text_invitations_sent_more', {count: audienceCount})}
                    </Typography>
                  </CardContent>
                </Card>
              ) : null
            }
          </Grid>
        </Grid>
      </FormDialog>
    );
  }
}

const mapStateToProps = (state: ReducerState) => {
  const { unitList, unitsEmployeesList, audienceCount, allActiveEmployees } = state.surveys;

  return {
    currentValues: getFormValues(FORM_NAME)(state),
    unitList,
    unitsEmployeesList,
    audienceCount,
    allActiveEmployees
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  resetForm: () => dispatch(reset(FORM_NAME)),
  updateEmployeesList: (allActiveEmployees: any[], employeeList: any[]) => dispatch(updateEmployeesList(allActiveEmployees, employeeList)),
  changeFormData: (payload: FormDataType) => changeFormData(dispatch, payload),
  setAudienceCount: (units: UnitType[]) => dispatch(setAudienceCount(units)),
  resetCount: () => dispatch(resetCount()),
  openConfirmDialog: (payload: ConfirmDialogType) => dispatch(openConfirmDialog(payload)),
  untouch: (field: string) => dispatch(untouch(FORM_NAME, field)),
});

const enhance = compose<any, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(addAudienceFormDialogStyle, {withTheme: true}),
);

export default enhance(AddAudienceFormDialog);

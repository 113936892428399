import * as React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { getCurrentLanguage, getStringAfterChar } from '@/app/utils/helper';
import { ReducerState } from '@/app/redux/store';
import { LanguagesType } from '@/app/redux/languages';
import { Dispatch } from 'redux';
import {
  getTranslation,
  TranslateSurvey,
  SurveyQuestion,
  ServerSurveyTranslation,
  ServerSurvey,
  getRefTranslation,
  getTranslations,
} from '@/app/redux/surveys';
import translate from '@/app/utils/translate';
import TranslateTermsDialog from '../../TranslateTermsDialog/TranslateTermsDialog';
import { untouch } from 'redux-form';

type MapStateToProps = {
  languages: LanguagesType[];
  survey: ServerSurvey;
  translations: TranslateSurvey;
  refTrData: any;
  trData: any;
};

type MapDispatchToProps = {
  getTranslation: (surveyId: string, lang: string) => void;
  getRefTranslation: (surveyId: string, lang: string) => void;
  getTranslations: (surveyId: string, refLang: string, trLang: string) => void;
  untouch: (field: string) => void;
};

type OwnProps = {
  open: boolean;
  surveyId: string;
  onSave: (surveyId: string, data: ServerSurveyTranslation) => void;
  onClose: () => void;
};

type PropsType = MapDispatchToProps & MapStateToProps & OwnProps;

type StateType = {
  selectedRefLang: string;
  selectedLanguage: string;
};

class TranslateSurveyDialog extends React.Component<PropsType> {
  state: StateType = {
    selectedRefLang: getCurrentLanguage(),
    selectedLanguage: getCurrentLanguage(),
  };

  avLanguages: LanguagesType[];

  componentDidUpdate(prevProps: PropsType) {
    const { open, surveyId, languages, survey } = this.props;
    const { survey: prevSurvey } = prevProps;

    if (open && open !== prevProps.open) {
      const avLangs = [...languages].filter(lang => !lang.isDefault);
      const defaultLang = languages.find(l => l.isDefault).code;
      this.props.getTranslations(surveyId, defaultLang, avLangs[0].code);
    }

    if (open && JSON.stringify(survey) !== JSON.stringify(prevSurvey)) {
      this.handleTermSet(survey);
    }
  }

  handleTermSet = (survey: ServerSurvey) => {
    const { languages, getRefTranslation: getReference } = this.props;
    const { id, defaultLanguage } = survey;
    getReference(id, defaultLanguage);
    const transLang = languages[0] && languages[0].code === defaultLanguage ?
      languages[1].code : languages[0].code;
    this.setState({
      selectedRefLang: defaultLanguage,
      selectedLanguage: transLang,
    });
  }

  getTranslatedSurvey = (srvData: ServerSurvey, trSrvData: TranslateSurvey) => {
    const nameKey = translate.t('label_survey_name');
    const transformedSurvey = {
      name: trSrvData.terms[nameKey].name,
      language: trSrvData.language,
      questions: [] as SurveyQuestion[],
    };

    const getOptions = (opts: Object, oldOpts: Object[]) => (
      oldOpts.map((opt, ix) => (
        Object.assign({}, opt, { text: opts[ix], value: opts[ix] })
      ))
    );

    Object.keys(trSrvData.terms).forEach((key) => {
      if (key !== nameKey) {
        const i = parseInt(getStringAfterChar(key, ' '), 10) - 1;

        const newQ = Object.assign(
          {},
          trSrvData.terms[key].yesLabel ? { yesLabel: trSrvData.terms[key].yesLabel } : {},
          trSrvData.terms[key].noLabel ? { noLabel: trSrvData.terms[key].noLabel } : {},
          trSrvData.terms[key].options ?
            { options: getOptions(trSrvData.terms[key].options, srvData.questions[i].options) } :
            {},
          { text: trSrvData.terms[key].text },
        );

        transformedSurvey.questions.push(
          Object.assign(
            {},
            srvData.questions[i],
            newQ,
          )
        );
      }
    });

    return transformedSurvey;
  };

  handleSave = (saveData: TranslateSurvey) => {
    const { survey } = this.props;
    const transformedData = this.getTranslatedSurvey(survey, saveData);

    this.props.onSave(survey.id, transformedData);

    this.setState({
      selectedRefLang: getCurrentLanguage(),
      selectedLanguage: getCurrentLanguage(),
    });
  }

  handleClose = () => {
    this.props.onClose();
    this.setState({
      selectedRefLang: getCurrentLanguage(),
      selectedLanguage: getCurrentLanguage(),
    });
  };

  handleRefChange = (language: string) => {
    const { id } = this.props.survey;
    this.props.getRefTranslation(id, language);

    this.setState({
      selectedRefLang: language,
    });
  }

  handleTrChange = (language: string) => {
    const { id } = this.props.survey;
    this.props.getTranslation(id, language);

    this.setState({
      selectedLanguage: language,
    });
  }

  render() {
    const { selectedLanguage, selectedRefLang } = this.state;
    const { languages, open, survey, refTrData = { terms: {} }, trData = { terms: {} } } = this.props;
    const refLanguages = selectedRefLang !== selectedLanguage ?
      [...languages].filter(l => l.code !== selectedLanguage) : [...languages];
    const translationLanguages = selectedRefLang !== selectedLanguage ?
      [...languages].filter(l => l.code !== selectedRefLang) : [...languages];

    if (!survey && selectedLanguage === selectedRefLang) {
      return null;
    }

    return (
      <TranslateTermsDialog
        open={open}
        title={`${translate.t('title_translate')} - ${survey ? survey.name : ''}`}
        references={refLanguages}
        languages={translationLanguages}
        referenceData={refTrData}
        translationData={trData}
        selectedRefLang={selectedRefLang}
        selectedTransLang={selectedLanguage}
        onRefChange={change => this.handleRefChange(change)}
        onTrChange={change => this.handleTrChange(change)}
        onClose={this.handleClose}
        onSave={this.handleSave}
        untouch={this.props.untouch}
      />
    );
  }
}

const mapStateToProps = (state: ReducerState) => {
  const { languages, surveys } = state;
  const {
    serverSurvey: survey, translations, refTrData, trData,
  } = surveys;

  return {
    languages: languages.get('allLanguages'),
    survey,
    translations,
    refTrData,
    trData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getTranslation: (surveyId: string, lang: string) => dispatch<any>(getTranslation(surveyId, lang)),
  getRefTranslation: (surveyId: string, lang: string) => dispatch<any>(getRefTranslation(surveyId, lang)),
  getTranslations: (surveyId: string, refLang: string, trLang: string) => (
    dispatch<any>(getTranslations(surveyId, refLang, trLang))
  ),
  untouch: (field: string) => dispatch(untouch('translateTermsForm', field)),
});

const enhance = compose<any, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(TranslateSurveyDialog);

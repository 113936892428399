import { StyleRulesCallback } from '@material-ui/core';

export type ExtraHoursBalanceStyleKeys =
    'hoursLimitExceededContainer'
    | 'hoursLimitExceededMessageContainer'
    | 'hoursLimitDeceededContainer';

export const extraHoursBalanceStyles: StyleRulesCallback<ExtraHoursBalanceStyleKeys> = (theme) => ({
    hoursLimitExceededContainer: {
        display: 'flex',
        border: '2px dotted red',
        padding: 2 * theme.spacing.unit
    },
    hoursLimitExceededMessageContainer: {
        marginRight: 3 * theme.spacing.unit
    },
    hoursLimitDeceededContainer: {
        padding: 2 * theme.spacing.unit
    },
});

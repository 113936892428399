import * as React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import {
  WithStyles,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';

import { getEmployeeName, getLoggedUserId } from '@/old/utils/helper';
import stageSelectStyle from './stageSelectStyle';
import { getDefault } from '@/app/components/TemplateComponents/helpers';

type incomingProps = {
  activeTemplate: any;
  changeEvaluationStage: any;
};

type PropsType = incomingProps & WithStyles;

type StateType = {
  setStage: { id: number; label: string; default: boolean };
};

class StageSelect extends React.PureComponent<PropsType> {
  state: StateType = {
    setStage: getDefault(this.props.activeTemplate.stages),
  };

  constructor(props: any) {
    super(props);
    const { stages, setStage } = props.activeTemplate;
    const removeDeletedStaged = stages.filter((stage: any) => !stage.isDeleted);
    const defStageFromTemplate = getDefault(removeDeletedStaged);
    this.state = {
      // Stage exists - was not soft deleted - id of set stage > template default
      setStage:
        setStage &&
        !setStage.isDeleted &&
        setStage.id > (defStageFromTemplate || { id: '' }).id
          ? setStage
          : getDefault(stages),
    };
  }

  handleChange = (event: any) => {
    const { stages } = this.props.activeTemplate;
    const currentUserName = getEmployeeName(getLoggedUserId(), true);
    const newStage = stages.find((stage: any) => stage.id === Number(event.target.value));
    this.setState({ setStage: newStage });
    this.props.changeEvaluationStage(newStage, currentUserName);
  };

  render() {
    const { stages } = this.props.activeTemplate;
    const { setStage } = this.state;
    const activeUniqueStages =
      stages && stages.length
        ? stages.reduce((acc: Array<any>, stage: any) => {
            const isFound = acc.find(
              existingStage => existingStage.id === stage.id,
            );
            if (stage.isDeleted || isFound) {
              return acc;
            }

            return acc.concat(stage);
          }, [])
        : [];

    return (
      <FormControl className={this.props.classes.root}>
        <RadioGroup
          aria-label="position"
          name="position"
          value={this.state.setStage.label}
          onChange={this.handleChange}
          row
        >
          {activeUniqueStages.map((stage: any) => {
            return (
              <FormControlLabel
                key={stage.id}
                value={stage.id.toString()}
                control={
                  <Radio color="primary" checked={stage.id === setStage.id} />
                }
                label={stage.label}
                labelPlacement="top"
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  }
}

const enhance = compose<any, any>(withStyles(stageSelectStyle));

export default enhance(StageSelect);

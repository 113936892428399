import { utils, writeFile } from 'xlsx';
import {
    ServerSurveyResults,
    ServerYesNoData,
    ServerScaleData,
    ServerChoiceData,
    ServerQuestion,
    QuestionType,
} from '@/app/redux/surveys';
import translate from '@/app/utils/translate';

export const exportSurveyResultsToExcel = (rawQuestions: ServerQuestion[], resultsRawObj: ServerSurveyResults) => {
    const data = [[translate.t('label_survey_question'), translate.t('label_answer')]];

    resultsRawObj.results.sort(({ index: a }, { index: b }) => a === b ? 0 : a > b ? 1 : -1).forEach(result => {
        const question = `${result.index}. ${result.questionText}`;

        switch (result.questionType) {
            case QuestionType.YES_NO: {
                const q = rawQuestions.find(({ id }) => id === result.questionId);
                const results = result.results as ServerYesNoData;

                data.push(
                    ['', q ? q.yesLabel : 'Yes', q ? q.noLabel : 'No'],
                    [question, results.yes.toString(), results.no.toString()],
                    [],
                );

                break;
            }
            case QuestionType.SCALE: {
                const results = result.results as ServerScaleData;

                data.push(
                    ['', translate.t('strongly_disagree'), translate.t('disagree'), translate.t('neutral'), translate.t('agree'), translate.t('strongly_agree')],
                    [
                        question,
                        results[1].toString(),
                        results[2].toString(),
                        results[3].toString(),
                        results[4].toString(),
                        results[5].toString(),
                    ],
                    [],
                );

                break;
            }
            case QuestionType.SINGLE_CHOICE:
            case QuestionType.MULTIPLE_CHOICE: {
                const results = result.results as ServerChoiceData[];

                data.push(
                    ['', ...results.map(r => r.optionText)],
                    [question, ...results.map(r => r.count.toString())],
                    [],
                );

                break;
            }
            case QuestionType.FREE_TEXT: {
                const results = (result.results as string[]).filter(res => res !== '');

                data.push(
                    ['', translate.t('free_text')],
                    [question, ...results],
                    [],
                );

                break;
            }
            default: break;
        }
    });

    const worksheet = utils.aoa_to_sheet(data);
    const workbook = utils.book_new();

    worksheet['!cols'] = [{ wpx: 200 }];

    utils.book_append_sheet(workbook, worksheet, translate.t('label_results'));
    writeFile(workbook, `${resultsRawObj.name.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.xlsx`);
};

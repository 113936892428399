import React from 'react';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import FormControlLabel, { FormControlLabelProps } from '@material-ui/core/FormControlLabel';
import { FieldProps } from 'formik';

type OwnProps = {};
type FormikCheckboxFieldProps = OwnProps & FieldProps & FormControlLabelProps & CheckboxProps;

const defaultProps = {
  margin: 'dense' as 'dense',
};

export function FormikCheckboxField({
  label,
  field,
  form,
  ...others
}: FormikCheckboxFieldProps) {
  return (
    <>
      <FormControlLabel
        label={label}
        control={
          <Checkbox
            {...defaultProps}
            {...field}
            checked={field.value}
            value={!field.value ? false.toString() : field.value.toString()}
            {...others}
          />
        }
      />
    </>
  );
}

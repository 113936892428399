import { StyleRulesCallback, Theme } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

const styles: StyleRulesCallback = (theme: Theme) => ({
  expansionPanelDetails: {
    padding: 0,
    display: 'block',
  },
  expansionPanelSummaryContent: {
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  flexBreak: {
    flexBasis: '100%',
    height: 0,
  },
  inactiveChip: {
    height: 24,
    marginLeft: theme.spacing.unit,
  },
  loading: {
    margin: theme.spacing.unit * 3,
  },
  noExpandedTools: {
    flexGrow: 1,
  },
  root: {
    padding: theme.spacing.unit * 2,
  },
  successSnackbar: {
    backgroundColor: green[600],
  },
  worklist: {
    paddingTop: theme.spacing.unit * 2,
  },
  worklistDesc: {
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    width: '100%',
  },
  worklistHeading: {
    display: 'inline',
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
  },
  worklistMenuButtonContainer: {
    display: 'inline',
    flexGrow: 1,
    paddingLeft: theme.spacing.unit,
  },
});

export default styles;

import { getEmployeeName, getEmployeePosition, getLegalEntity } from '@/old/utils/helper';
import moment from 'moment';
import {
  EmpClosedWorkflow,
  IIntPositionClosedWorkflows,
  IWorkflows,
  IEmpDetailsWorkflow,
  IntPositionClosedWorkflow,
  EmpDetailsClosedWorkflow,
} from './types';

export const mapClosedWorkflowsForUI = (data: any[]): IWorkflows[] => {
  return data.map(item => mapClosedWorkflowForUI(item));
};

export const mapClosedWorkflowForUI = (data: any): IWorkflows => {
  return {
    empId: data.fEmpId,
    firstName: data.fEmpFirstName,
    lastName: data.fEmpLastName,
    empNo: data.fEmpEmployeeNumber,
    unitId: data.fEmpUnitId,
    unit: data.fEmpUnit,
    unitNo: data.fEmpUnitNumber,
    managerId: getEmployeeName(data.fEmpManagerId),
    workflowId: data.fOpenWorkflowUid,
    status: data.fOpenWorkflowStatus,
    amount: data.fOpenWorkflowAmount,
    group: data.fOpenWorkflowGroup,
    type: data.fOpenWorkflowType,
    scheduledDate: moment(data.fOpenWorkflowScheduledDate, 'YYYY-MM-DD').format('DD.MM.YYYY'),
    updatedDate: moment(data.fOpenWorkflowLastUpdated, 'YYYY-MM-DD').format('DD.MM.YYYY'),
    approvedBy: getEmployeeName(data.fOpenWorkflowUpdatedByEmpId),
    info: data.fOpenWorkflowInfo,
    ext: data.fEmpHasAccount,
  };
};

export const mapIntPositionClosedWorkflowsForUI = (data: IntPositionClosedWorkflow[]): IIntPositionClosedWorkflows[] => {
  const flatData: IIntPositionClosedWorkflows[] = [];
  data.forEach((item: IntPositionClosedWorkflow) => {
    item.fEmpClosedWorkflows.forEach((closedWorkflow: EmpClosedWorkflow) => {
      const subItem = closedWorkflow.fClosedWorkflowEntry;
      const proposal =
        closedWorkflow.fInternalPositionProposal.translate;
      const legalEntity = getLegalEntity(proposal.fLegalEntity);

      flatData.push({
        empId: item.fEmpDetails.fEmpId,
        firstName: item.fEmpDetails.fEmpFirstName,
        lastName: item.fEmpDetails.fEmpLastName,
        empNo: item.fEmpDetails.fEmpPersonNumber,
        type: subItem.fOpenWorkflowType,
        unitId: item.fEmpDetails.fEmpUnitDetails.fOrgUnitId,
        unit: item.fEmpDetails.fEmpUnitDetails.fOrgUnitName,
        unitNo: item.fEmpDetails.fEmpUnitDetails.fOrgUnitNumber,
        workflowId: subItem.fOpenWorkflowUid,
        group: subItem.fOpenWorkflowGroup,
        scheduledDate: moment(subItem.fOpenWorkflowScheduledDate, 'YYYY-MM-DD').format('DD.MM.YYYY'),
        updatedDate: moment(subItem.fOpenWorkflowLastUpdated, 'YYYY-MM-DD').format('DD.MM.YYYY'),
        startDate: moment(proposal.fStartDate, 'YYYY-MM-DD').format('DD.MM.YYYY'),
        endDate: !!proposal.fEndDate ? moment(proposal.fEndDate, 'YYYY-MM-DD').format('DD.MM.YYYY') : '',
        approvedBy: getEmployeeName(subItem.fOpenWorkflowUpdatedByEmpId),
        info: proposal.fInfo,
        position: getEmployeePosition(item.fEmpDetails.fEmpId),
        costCenter:
          item.fEmpDetails.fEmpCostCenterDetails.fEmpCostCenterName,
        costCenterPct: proposal.fCostCenterPct,
        managerId: getEmployeeName(+proposal.fManagerFK),
        status: subItem.fOpenWorkflowStatus,
        jobTitle: proposal.fJobTitle,
        legalEntity: legalEntity.fLegalEntityName,
      });
    });
  });

  return flatData;
};

export const mapEmpDetailsClosedWorkflowsForUI = (data: EmpDetailsClosedWorkflow[]): IEmpDetailsWorkflow[] => {
  const flatData: IEmpDetailsWorkflow[] = [];
  data.forEach((item: EmpDetailsClosedWorkflow) => {
    item.fEmpClosedWorkflow.forEach((closedWorkflow: EmpClosedWorkflow) => {
      const empDetails = item.fEmpDetails;
      const subItem = closedWorkflow.fClosedWorkflowEntry;
      const proposal =
        closedWorkflow.fEmploymentDetailsProposal.translate;

      flatData.push({
        empId: empDetails.fEmpId,
        firstName: empDetails.fEmpFirstName,
        lastName: empDetails.fEmpLastName,
        empNo: empDetails.fEmpPersonNumber,
        empStatus: proposal.fEmploymentStatusFK,
        pg1: proposal.fPersonnelGroup1FK,
        pg2: proposal.fPersonnelGroup2FK,
        pg3: proposal.fPersonnelGroup3FK,
        pg4: proposal.fPersonnelGroup4FK,
        pg5: proposal.fPersonnelGroup5FK,
        unitId: empDetails.fEmpUnitDetails.fOrgUnitId,
        unit: empDetails.fEmpUnitDetails.fOrgUnitName,
        unitNo: empDetails.fEmpUnitDetails.fOrgUnitNumber,
        workflowId: subItem.fOpenWorkflowUid,
        status: subItem.fOpenWorkflowStatus,
        approvedBy: getEmployeeName(subItem.fOpenWorkflowUpdatedByEmpId),
        scheduledDate: moment(subItem.fOpenWorkflowScheduledDate, 'YYYY-MM-DD').format('DD.MM.YYYY'),
        updatedDate: moment(subItem.fOpenWorkflowLastUpdated, 'YYYY-MM-DD').format('DD.MM.YYYY'),
        startDate: moment(proposal.fStartDate, 'YYYY-MM-DD').format('DD.MM.YYYY'),
        endDate: !!proposal.fEndDate ? moment(proposal.fEndDate, 'YYYY-MM-DD').format('DD.MM.YYYY') : '',
        info: subItem.fOpenWorkflowInfo,
        jobGrade: proposal.fJobGradeFK,
        taxNumber: proposal.fTaxNumber,
        vacBalCalcUseSixDays: proposal.fVacBalCalcUseSixDays,
        calcHoursBalance: proposal.fCalcHoursBalance,
      });
    });
  });

  return flatData;
};

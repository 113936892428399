import { Theme } from '@material-ui/core';
import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const templateHeaderStyle: StyleRulesCallback = (theme: Theme) => ({
  root: {
    textAlign: 'center',
    padding: '25px 25px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) !important',
    minWidth: 700,
  },
  editStyle: {
    paddingTop: '25px',
    paddingLeft: '25px',
    paddingRight: '25px',
    backgroundColor: 'lightyellow',
  },
  topTitle: {
    textAlign: 'left',
  },
  topTitleRight: {
    float: 'right',
    marginTop: '-8px',
  },
  flexWrapper: {
    display: 'flex',
  },
  spacing: {
    flexGrow: 1,
    marginRight: 20,
  },
  left: {
    textAlign: 'left',
  },
  datePicker: {
    marginRight: '20px',
    flexGrow: 1,
  },
  input: {
    display: 'flex',
    height: 'auto',
    padding: 0,
  },
  checkboxControlLabel: {
    position: 'relative',
  },
  checkboxLabel: {
    transform: 'translate(0, 0) scale(0.75)',
    transformOrigin: 'top left',
    color: theme.palette.text.secondary,
    fontSize: '1rem',
    lineHeight: 1,
    position: 'absolute',
    top: 0,
    left: 0,
  },
});

export default templateHeaderStyle;

import * as React from 'react';
import translate from '@/app/utils/translate';
import { getEnums } from '@/old/utils/helper';

export const getConfiguredText = (term: string): string => {
  const custItem: any = getEnums('UI_LABEL').find(
    (item: any) => item.code.toUpperCase() === term.toUpperCase(),
  );
  if (typeof custItem !== 'undefined' && custItem.name !== '') {
    return custItem.name;
  } else {
    return translate.t(term);
  }
};

interface Props {
  term: string;
  overrideValue?: string;
}

const uiLabel = (props: Props) => {
  const { term, overrideValue } = props;
  let text: string = '';
  if (!overrideValue) {
    text = getConfiguredText(term);
  } else {
    text = overrideValue;
  }

  return <>{text}</>;
};

export default uiLabel;

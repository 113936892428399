import { FormControl, FormHelperText } from '@material-ui/core';
import * as moment from 'moment';
import * as React from 'react';

import 'moment/locale/da';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/et';
import 'moment/locale/fi';
import 'moment/locale/it';
import 'moment/locale/lv';
import 'moment/locale/nl';
import 'moment/locale/nb';
import 'moment/locale/pl';
import 'moment/locale/ru';
import 'moment/locale/sv';
import 'moment/locale/uk';
import 'moment/locale/zh-cn';

import LocalizedDatePicker from '@/app/components/Pickers/LocalizedDatePicker';
import { DATE_FORMAT, getCurrentLanguage, getCurrentLocaleForDateUtils } from '@/app/utils/helper';
import { styles } from '@/app/components/FormFields/formFieldsStyle';

const localeMap = {
  'da': 'da',
  'de': 'de',
  'en': 'en-gb',
  'et': 'et',
  'fi': 'fi',
  'fm': 'fm',
  'it': 'it',
  'lv': 'lv',
  'nl': 'nl',
  'no': 'nb',
  'pl': 'pl',
  'ru': 'ru',
  'sv': 'sv',
  'uk': 'uk',
  'zh': 'zh-cn',
};

const currentLanguage = getCurrentLanguage();
const currentLocale = localeMap[currentLanguage];
moment.locale(getCurrentLocaleForDateUtils(currentLocale));

const RenderDateField = ({
  disabled,
  input,
  label,
  required,
  meta: { touched, error },
  helpText,
  ...custom
}: any) => {
  const hasError = touched && !!error;

  return (
    <FormControl
      key={input.code}
      style={styles.textField}
      margin="normal"
      error={hasError}
      fullWidth
    >
      <LocalizedDatePicker
        {...input}
        label={label}
        value={input.value || null}
        onChange={(date?: moment.Moment) => {
          const value = date && date.isValid() ? date : null;

          input.onBlur(value);
          input.onChange(value);
        }}
        onBlur={undefined}
        placeholder={DATE_FORMAT}
        required={required}
        {...custom}
        clearable={true}
        keyboard={true}
        invalidDateMessage=""
        invalidLabel=""
        disabled={!!disabled}
        error={hasError}
        fullWidth
        autoOk
      />

      {hasError && (
        <FormHelperText id={`${input.name}-error-text`}>
          {error}
        </FormHelperText>
      )}

      {helpText}
    </FormControl>
  );
};

export default RenderDateField;

import * as React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Theme, withStyles, StyleRulesCallback } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import ConfigureUiLabelDialog from '../ConfigureUiLabelDialog/ConfigureUiLabelDialog';
import UiLabel from '../UiLabel/UiLabel';
import translate from '@/app/utils/translate';
import { WithStyles } from '@material-ui/core/styles';
import { isHRAdmin } from '@/old/utils/helper';
import { getCurrentLanguage } from '@/app/utils/helper';

const styles: StyleRulesCallback = (theme: Theme) => ({
  editButton: {
    marginLeft: theme.spacing.unit,
  },
});

interface Props extends WithStyles<typeof styles> {
  term: string;
  fetchAllEnums: () => void;
}

interface State {
  showDialog: boolean;
  overrideValue: string | null; // TODO: Optional<string>
}

class ConfigureUiLabelButton extends React.Component<Props, State> {
  state: State = {
    overrideValue: null,
    showDialog: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (this.props.term !== prevProps.term) {
      this.setState({ overrideValue: null });
    }
  }

  handleSave = (newValues: any[]) => {
    this.props.fetchAllEnums();
    const currLangItem = newValues.find(
      v => v.langCode === getCurrentLanguage(),
    );
    let overrideValue = null;
    if (currLangItem) {
      overrideValue = currLangItem.value;
    }
    this.setState({ showDialog: false, overrideValue });
  };

  render() {
    const { term, classes } = this.props;
    const { showDialog, overrideValue } = this.state;
    const dialog = showDialog ? (
      <ConfigureUiLabelDialog
        show={showDialog}
        term={term}
        onSave={this.handleSave}
        onClose={() => this.setState({ showDialog: false })}
      />
    ) : null;
    const configureButton = isHRAdmin() ? (
      <IconButton
        className={classes.editButton}
        component="span"
        title={translate.t('laCustomize')}
        onClick={evt => {
          this.setState({ showDialog: true });
          evt.stopPropagation();
        }}
      >
        <EditIcon fontSize="small" />
      </IconButton>
    ) : null;
    return (
      <>
        {dialog}
        <UiLabel term={term} overrideValue={overrideValue} />
        {configureButton}
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ConfigureUiLabelButton);

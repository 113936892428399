import { Record } from 'immutable';
import { THROW_ERROR } from './error';
import { Dispatch } from 'redux';
import Service from '@/app/utils/service';
import BonusCalcPlanningService from '@/app/components/BonusCalc/BonusCalcPlanning/mock/service';
import * as math from 'mathjs';
import API from '@/app/api/internalAPIs';
import { BonusRule } from '../components/BonusCalc/BonusCalculationRules/BonusCalculationRules';
import { formatNumber, SERVER_DATE_FORMAT } from '../utils/helper';
import { OPEN_NOTIFIER } from './notifier';
import {
  getEmployeeInfoById,
  getLoggedUserData,
  getLoggedUserId,
  hasHrRole,
  isMyEmp,
  getWorkflowPermission,
  findFirstHROfUnit,
  getBonusWorkflowStatus,
} from '@/old/utils/helper';
import * as moment from 'moment';

type BonusPlanningRowType = {
  id: number;
  employeeId: number;
  employee: string;
  position: string;
  fAnnualSalary: number;
  fMonthlySalary: number;
  fScore: number;
  fTargetEarning: number;
  fBonusValue: number;
  programId: number;
  programName: string;
  ruleId: number;
  ruleName: string;
  currency: string;
  sum: number;
  rawFormula: string;
  valuesFormula: string;
};

const RESET_DEMO = '@@solaforce/bonusCalc/RESET_DEMO';
const CREATE_NEW_PLAN = '@@solaforce/bonusCalc/CREATE_NEW_PLAN';
const UPDATE_CURRENT_PLAN = '@@solaforce/bonusCalc/UPDATE_CURRENT_PLAN';
const FETCH_RULES = '@@solaforce/bonusCalc/FETCH_RULES';
const FETCH_PROGRAMS = '@@solaforce/bonusCalc/FETCH_PROGRAMS';
const FETCH_PLANS = '@@solaforce/bonusCalc/FETCH_PLANS';
const FETCHING = '@@solaforce/bonusCalc/FETCHING';
const STOP_FETCHING = '@@solaforce/bonusCalc/STOP_FETCHING';
const FETCH_CURRENCY = '@@solaforce/bonusCalc/FETCH_CURRENCY';
const ACTIVATE_RULES = '@@solaforce/bonusCalc/ACTIVATE_RULES';
const DEACTIVATE_RULES = '@@solaforce/bonusCalc/DEACTIVATE_RULES';
const DELETE_RULES = '@@solaforce/bonusCalc/DELETE_RULES';
const EDIT_RULE = '@@solaforce/bonusCalc/EDIT_RULE';
const ADD_RULE = '@@solaforce/bonusCalc/ADD_RULE';
const GENERATE_CALCULATE_FORMULA =
  '@@solaforce/bonusCalc/GENERATE_CALCULATE_FORMULA';
const RESET_FORMULA = '@@solaforce/bonusCalc/RESET_FORMULA';
const PROPOSAL_SENT = '@@solaforce/bonusCalc/PROPOSAL_SENT';

// TODO: change [] with iList
const initialState = Record({
  plans: [],
  currentPlan: {
    id: null,
    desc: '',
    status: 'DRAFT',
    updatedOn: null,
    rows: [],
    selectedIds: [],
    totals: {
      rowCount: 0,
    },
  },
  rules: [],
  programs: [],
  isFetching: false,
  currency: '',
  supportedCurrencies: [],
  testResult: '0',
  formula: '',
  shouldConfirm: false,
});

const getTokensWithValues = (row: any) => ({
  ANNUAL_SALARY: row.fAnnualSalary,
  BONUS_VALUE: row.fBonusValue,
  MONTHLY_SALARY: row.fMonthlySalary,
  SCORE: row.fScore,
  TARGET_EARNING: row.fTargetEarning,
});

const parseFormula = (
  formula: string,
  params: any,
  format?: boolean,
  comma?: boolean,
) => {
  if (formula) {
    let parsedFormula = formula;

    Object.keys(params).forEach((key: string) => {
      const regEx = new RegExp(`{${key}}`, 'g');

      parsedFormula = parsedFormula.replace(
        regEx,
        format ? formatNumber(params[key] || 0) : params[key] || 0,
      );
    });

    if (comma) {
      return parsedFormula.replace(/\./g, ',');
    }

    return parsedFormula.replace(/,/g, '.');
  }

  return '';
};

const calculateFormula = (formula: string): number => {
  try {
    return math.eval(formula);
  } catch (err) {
    return 0;
  }
};

const calculateBonuses = (
  plan: any,
  rules: BonusRule[],
  affectedRowIds: number[] = null,
): any => {
  return {
    ...plan,
    rows: plan.rows.map((row: any) => {
      if (!affectedRowIds || affectedRowIds.indexOf(row.id) > -1) {
        let sum = 0;
        if (row.ruleId) {
          const formula = parseFormula(
            rules.find((rule: BonusRule) => rule.fRuleId === row.ruleId)
              .fRuleFormula,
            getTokensWithValues(row),
          );
          sum = Math.round(calculateFormula(formula) * 100) / 100;
        }

        return {
          ...row,
          sum,
        };
      }

      return row;
    }),
  };
};

const calculateTotals = (rows: BonusPlanningRowType[]): any => {
  const selRows = rows.filter((row: any) => row.ruleId);
  const usedProgIds: number[] = Array.from(
    new Set(selRows.map((row: any) => row.programId)),
  ).sort((a: any, b: any) => a - b);
  const usedCurrencies: string[] = Array.from(
    new Set(selRows.map((row: any) => row.currency)),
  );

  // Prepare totals object
  const totals: any = {
    rowCount: 0,
  };
  usedCurrencies.forEach((curr: string) => {
    totals[curr] = {
      total: 0,
    };
    usedProgIds.forEach((progId: number) => {
      totals[curr][`program_${progId}`] = 0;
    });
  });

  // Loop through selected rows and calculate totals
  selRows.forEach((row: any) => {
    totals.rowCount += 1;
    totals[row.currency].total =
      Math.round(totals[row.currency].total * 100) / 100 +
      Math.round(row.sum * 100) / 100;
    totals[row.currency][`program_${row.programId}`] =
      Math.round(totals[row.currency][`program_${row.programId}`] * 100) / 100 +
      Math.round(row.sum * 100) / 100;
  });

  return totals;
};

const getRawFormula = (ruleId: number, rules: BonusRule[]) => {
  const rule = rules.find(r => r.fRuleId === ruleId);

  return rule ? rule.fRuleFormula : '';
};

const getValuesFormula = (
  ruleId: number,
  rules: BonusRule[],
  row: any = null,
) => {
  const rule = rules.find(r => r.fRuleId === ruleId);

  if (rule) {
    if (row) {
      return parseFormula(rule.fRuleFormula, getTokensWithValues(row));
    }

    return parseFormula(rule.fRuleFormula, getTokensWithValues(rule));
  }

  return '';
};

const createRule = (rule: BonusRule) => {
  return {
    ...rule,
    id: rule.fRuleId,
    active: rule.fRuleActive,
    fBonusValue: '0.15',
    fMonthlySalary: '3000',
    fScore: '0.75',
    fTargetEarning: '3000',
    fAnnualSalary: '36000',
  };
};

const getValueFromObj = (obj: object, key: string, defaultValue: any = 0) =>
  obj && obj.hasOwnProperty(key) ? obj[key] : defaultValue;

const reducer = (state = new initialState(), action: any) => {
  switch (action.type) {
    case FETCHING:
      return state.set('isFetching', true);
    case STOP_FETCHING:
      return state.set('isFetching', false);
    case RESET_DEMO:
      return new initialState();
    case CREATE_NEW_PLAN: {
      if (localStorage.getItem('currentBonusPlan')) {
        localStorage.removeItem('currentBonusPlan');
      }

      const { payload, enumsState } = action;
      const {
        EMPLOYEE_POSITION: empPos,
        INCENTIVE_SHORT_TYPE: incentiveList,
      } = enumsState;
      const rows: BonusPlanningRowType[] = [];

      payload.forEach((emp: any) => {
        const {
          empBasicData,
          empPosition,
          emplAnnualIncome,
          emplBasic,
          emplIncentiveShorts,
          emplSalary,
          performanceSummary,
        } = emp;

        emplIncentiveShorts.forEach((incent: any) => {
          const position = empPos.find(
            (pos: any) => pos.code === empPosition.fInternalPositionPosition,
          ).name;
          const {
            fIncentiveShortTarget = 0,
            fEmplId,
            fIncentiveShortType,
            fEmplBonusValue = 0,
          } = incent;

          const incentive = Object.assign(
            {},
            {
              fEmplId,
              fIncentiveShortTarget,
              fEmplBonusValue,
              ruleId: null,
              ruleName: '',
              sum: 0,
              fIncentiveShortType,
            },
          );

          const incentiveIndex = incentiveList.findIndex(
            (inc: any) => inc.code === incentive.fIncentiveShortType,
          );
          const program = {
            id: incentiveIndex,
            name: incentiveList[incentiveIndex].name,
          };

          rows.push({
            id: parseInt(`${empBasicData.fEmpId}${program.id}`, 10),
            employeeId: empBasicData.fEmpId,
            employee: `${empBasicData.fEmpLastName}, ${empBasicData.fEmpFirstName} (${empBasicData.fEmpPersonNumber})`,
            position,
            fAnnualSalary: getValueFromObj(
              emplAnnualIncome,
              'fAnnualIncomeAmount',
            ),
            fMonthlySalary: getValueFromObj(emplSalary, 'fSalaryBaseSalary'),
            fScore: getValueFromObj(performanceSummary, 'fPerfTotalScore'),
            programId: program.id,
            programName: program.name,
            fTargetEarning: incentive.fIncentiveShortTarget,
            fBonusValue: incentive.fEmplBonusValue || 0,
            ruleId: incentive.ruleId || null,
            ruleName: incentive.ruleName || '',
            currency: getValueFromObj(emplBasic, 'fEmplCurrency', 'EUR'),
            sum: incentive.sum || 0,
            rawFormula: '',
            valuesFormula: '',
          });
        });
      });

      const currentPlan: any = {
        id: null,
        desc: '',
        status: 'DRAFT',
        updatedOn: null,
        rows,
        selectedIds: [],
        totals: {
          rowCount: 0,
        },
      };

      return state
        .set('currentPlan', currentPlan)
        .set('isFetching', false)
        .set('shouldConfirm', false);
    }
    case UPDATE_CURRENT_PLAN: {
      const { currentPlan: oldPlan } = state;
      const { rule, rowIds, rules } = action.payload;

      const updatedRows = oldPlan.rows.map((row: BonusPlanningRowType) => {
        if (rowIds.indexOf(row.id) > -1) {
          return {
            ...row,
            ruleId: rule.fRuleId,
            ruleName: rule.fRuleName,
            rawFormula: getRawFormula(rule.fRuleId, rules),
            valuesFormula: getValuesFormula(rule.fRuleId, rules, row),
          };
        }

        return row;
      });

      const affectedRowIds = rowIds;
      const selectedIds = updatedRows
        .filter((row: any) => row.ruleId)
        .map((row: any) => row.id);

      const updatedPlan = calculateBonuses(
        {
          ...oldPlan,
          selectedIds,
          rows: [...updatedRows],
        },
        rules,
        affectedRowIds,
      );

      const currentPlan = {
        ...updatedPlan,
        totals: calculateTotals(updatedPlan.rows),
      };

      if (localStorage.getItem('currentBonusPlan')) {
        localStorage.removeItem('currentBonusPlan');
      }
      localStorage.setItem('currentBonusPlan', JSON.stringify(currentPlan));

      return state.set('currentPlan', currentPlan).set('shouldConfirm', true);
    }
    case FETCH_RULES: {
      const rules = action.rules.map((rule: BonusRule) => createRule(rule));

      return state.set('rules', rules);
    }
    case FETCH_PROGRAMS:
      return state.set('programs', action.programs);
    case FETCH_PLANS:
      return state.set('plans', action.plans);
    case FETCH_CURRENCY: {
      const { currency, supportedCurrencies } = action.payload;

      return state
        .set('currency', currency)
        .set('supportedCurrencies', supportedCurrencies);
    }
    case ADD_RULE: {
      const { rules: oldRules } = state;
      const rules = [createRule(action.rule), ...oldRules];

      return state.set('rules', rules);
    }
    case EDIT_RULE: {
      const { rules: oldRules } = state;

      const rule = createRule(action.rule);
      const index = oldRules.findIndex(
        (r: BonusRule) => r.fRuleId === rule.fRuleId,
      );
      const rules = [...oldRules];
      rules[index] = rule;

      return state.set('rules', rules);
    }
    case ACTIVATE_RULES: {
      const { rules: oldRules } = state;
      const { ids } = action;
      const rules: BonusRule[] = [...oldRules];

      ids.forEach((id: number) => {
        const index = rules.findIndex(c => c.fRuleId === id);
        rules[index].fRuleActive = true;
        rules[index].active = true;
      });

      return state.set('rules', rules);
    }
    case DEACTIVATE_RULES: {
      const { rules: oldRules } = state;
      const { ids } = action;
      const rules: BonusRule[] = [...oldRules];

      ids.forEach((id: number) => {
        const index = rules.findIndex(c => c.fRuleId === id);
        rules[index].fRuleActive = false;
        rules[index].active = false;
      });

      return state.set('rules', rules);
    }
    case DELETE_RULES: {
      const { rules: oldRules } = state;
      const { ids } = action;
      const rules: BonusRule[] = [...oldRules];

      ids.forEach((id: number) => {
        const index = rules.findIndex((c: BonusRule) => c.fRuleId === id);
        rules.splice(index, 1);
      });

      return state.set('rules', rules);
    }
    case GENERATE_CALCULATE_FORMULA: {
      const { rule } = action;
      const formula = parseFormula(
        rule.fRuleFormula,
        getTokensWithValues(rule),
        true,
        true,
      );
      const parsedFormula = parseFormula(
        rule.fRuleFormula,
        getTokensWithValues(rule),
      );
      const testResult = formatNumber(
        Math.round(calculateFormula(parsedFormula) * 100) / 100,
      );

      return state.set('formula', formula).set('testResult', testResult);
    }
    case RESET_FORMULA:
      return state.set('formula', '').set('testResult', '0');
    case PROPOSAL_SENT:
      return state
        .set('currentPlan', {
          id: null,
          desc: '',
          status: 'DRAFT',
          updatedOn: null,
          rows: [],
          selectedIds: [],
          totals: {
            rowCount: 0,
          },
        })
        .set('isFetching', false);
    default:
      return state;
  }
};

export const createNewPlan = (teamId: any) => {
  return async (dispatch: Dispatch, getState: Function) => {
    const enumsState = getState()
      .enums.get('allEnums')
      .toJS();

    await dispatch({ type: FETCHING });

    Service.get(
      API.bonus.plan(teamId),
      (data: any) => {
        dispatch({ type: CREATE_NEW_PLAN, payload: data, enumsState });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const fetchActiveRules = () => {
  return (dispatch: Dispatch) => {
    Service.get(
      API.bonus.activeRules,
      (rules: BonusRule[]) => {
        dispatch({ type: FETCH_RULES, rules });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const fetchRules = () => {
  return (dispatch: Dispatch) => {
    Service.get(
      API.bonus.rules,
      (data: any) => {
        const rules = data;
        dispatch({ type: FETCH_RULES, rules });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const fetchPrograms = () => {
  // TODO: change it? get data from store...
  return (dispatch: Dispatch) => {
    BonusCalcPlanningService.get(
      API.bonus.programs,
      (data: any) => {
        const programs = data;
        dispatch({ type: FETCH_PROGRAMS, programs });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const setRuleForCurrentPlanRows = (
  rowIds: number[],
  rule: BonusRule,
) => {
  return (dispatch: Dispatch, getState: Function) => {
    const rules = getState().bonusCalc.get('rules');
    const payload = {
      rowIds,
      rule,
      rules,
    };

    dispatch({ type: UPDATE_CURRENT_PLAN, payload });
  };
};

export const getCurrency = () => {
  return (dispatch: Dispatch) => {
    Service.get(
      API.getCurrency(),
      (data: any) => {
        if (data) {
          const payload = {
            currency: data.fComDefaultCurrency,
            supportedCurrencies: data.fComSupportedCurrencies,
          };

          dispatch({
            type: FETCH_CURRENCY,
            payload,
          });
        }
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const addRule = (rule: BonusRule) => {
  return (dispatch: Dispatch) => {
    Service.post(
      API.bonus.rules,
      rule,
      (data: BonusRule) => {
        dispatch({ type: ADD_RULE, rule: data });
        dispatch({ type: OPEN_NOTIFIER, payload: {} });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const editRule = (rule: BonusRule) => {
  return (dispatch: Dispatch) => {
    Service.put(
      API.bonus.rule(rule.fRuleId),
      rule,
      (data: BonusRule) => {
        dispatch({ type: EDIT_RULE, rule: data });
        dispatch({ type: OPEN_NOTIFIER, payload: {} });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const activateRules = (ids: number[]) => {
  return (dispatch: Dispatch) => {
    Service.put(
      API.bonus.toggleRules,
      {
        ids,
        active: true,
      },
      (_response: number) => {
        dispatch({ type: ACTIVATE_RULES, ids });
        dispatch({ type: OPEN_NOTIFIER, payload: {} });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const deactivateRules = (ids: number[]) => {
  return (dispatch: Dispatch) => {
    Service.put(
      API.bonus.toggleRules,
      {
        ids,
        active: false,
      },
      (_response: number) => {
        dispatch({ type: DEACTIVATE_RULES, ids });
        dispatch({ type: OPEN_NOTIFIER, payload: {} });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const deleteRules = (ids: number[]) => {
  return (dispatch: Dispatch) => {
    Service.delete(
      API.bonus.rules,
      ids,
      (_response: number) => {
        dispatch({ type: DELETE_RULES, ids });
        dispatch({ type: OPEN_NOTIFIER, payload: {} });
      },
      (error: any) => dispatch({ type: THROW_ERROR, error }),
    );
  };
};

export const genCalcFormula = (rule: BonusRule) => {
  return { type: GENERATE_CALCULATE_FORMULA, rule };
};

export const resetFormula = () => {
  return { type: RESET_FORMULA };
};

const getEmployeeList = (payload: any, workflow: any) => {
  const { employees, programs, payment } = payload;
  const { paymentDate, paymentPeriod } = payment;
  const { fLoginRoles } = getLoggedUserData();
  const loggedUserId = getLoggedUserId();

  const list = employees
    .filter((l: any) => l.sum > 0)
    .map((e: any) => {
      const { employeeId, sum: fIncentiveShortProposalAmount, programId } = e;
      const {
        fEmpId,
        fEmpEmplId: fEmplId,
        fEmpManagerId,
        fEmpUnit,
      } = getEmployeeInfoById(employeeId);

      let fIncentiveShortProposalHrId = -1;
      let fIncentiveShortProposalManagerId = fEmpManagerId;
      let fIncentiveShortProposalHigherMgrId = -1;
      const loggedHR = hasHrRole(fLoginRoles);

      if (loggedUserId === fEmpId) {
        fIncentiveShortProposalHigherMgrId = -1;
      } else if (!(loggedHR && isMyEmp(fEmpId))) {
        const bonusWkflw = workflow.find(
          (wk: any) => wk.fWorkflowName === 'BONUS_PROPOSAL',
        );
        if (
          bonusWkflw !== undefined &&
          bonusWkflw.fWorkflowHigherMgrCapability === '0_NONE'
        ) {
          fIncentiveShortProposalHrId = findFirstHROfUnit(fEmpUnit);
        } else {
          if (fEmpManagerId !== -1) {
            fIncentiveShortProposalHigherMgrId = getEmployeeInfoById(
              fEmpManagerId,
            ).fEmpManagerId;
          }
        }
      }

      const aBonusPropConf = getWorkflowPermission(
        'BONUS_PROPOSAL',
        workflow,
        fLoginRoles,
        fEmpId,
        loggedUserId,
      );
      if (
        (aBonusPropConf[0] === 'HR' &&
          (fIncentiveShortProposalHrId === -1 ||
            (aBonusPropConf[1] === '4_ADMINISTER' &&
              fIncentiveShortProposalHrId !== -1))) ||
        loggedHR
      ) {
        fIncentiveShortProposalHrId = loggedUserId;
      }

      const fIncentiveShortProposalStatus = getBonusWorkflowStatus(
        aBonusPropConf[0],
        aBonusPropConf[1],
        loggedHR,
      );
      if (
        fIncentiveShortProposalStatus === 'MANAGER_APPROVED' &&
        fIncentiveShortProposalHrId === -1
      ) {
        fIncentiveShortProposalHrId = -1;
      }

      return {
        fEmplId,
        fEmplSubEnteredTime: paymentDate,
        fIncentiveShortProposalAmount,
        fIncentiveShortProposalHigherMgrId,
        fIncentiveShortProposalHrId,
        fIncentiveShortProposalManagerId,
        fIncentiveShortProposalPaymentDate: moment(paymentDate).format(
          SERVER_DATE_FORMAT,
        ),
        fIncentiveShortProposalPaymentPeriod: paymentPeriod,
        fIncentiveShortProposalStatus,
        fIncentiveShortProposalType: programs[programId].code,
      };
    });

  return list;
};

export const bulkSendToProcess = (payload: any) => {
  return (dispatch: Dispatch, getState: Function) => {
    dispatch({ type: FETCHING });
    const { companyWorkflow } = getState().currentUser;
    const employeeList = getEmployeeList(payload, companyWorkflow);

    Service.post(
      API.bonus.bulkProposal,
      employeeList,
      () => {
        dispatch({ type: OPEN_NOTIFIER, payload: {} });
        dispatch({ type: PROPOSAL_SENT });
      },
      (error: any) => {
        dispatch({ type: THROW_ERROR, error });
        dispatch({ type: STOP_FETCHING });
      },
    );
  };
};

export default reducer;

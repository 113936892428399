import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const cardHeight = 192;
const cardWidth = 340;

const barChartStyle: StyleRulesCallback = (theme: Theme) => {
  return ({
    barChartCard: {
      height: '100%',
      minHeight: `${cardHeight}px`,
      padding: 0,
      width: `${cardWidth}px`,
    },
    barChartHeader: {
      padding: `${theme.spacing.unit}px ${theme.spacing.unit}px 0`,
    },
    barChartCardTitle: {
      fontSize: '1.3rem',
    },
    barChartCardContent: {
      padding: 0,
      '&:last-child': {
        paddingBottom: 0,
      },
      '&:not(:last-child)': {
        paddingBottom: 0,
      },
    },
    barChartContainer: {
      height: '160px',
      padding: `0 ${theme.spacing.unit}px ${theme.spacing.unit}px`,
      position: 'relative',
      width: `${cardWidth}px`,

      '& g ~ line': {
        '@media print': {
          display: 'none',
        }
      },
    },
    total: {
      fontSize: '0.9rem',
      position: 'absolute',
      right: '109px',
      top: '43%',
    },
  });
};

export default barChartStyle;

import { Record } from 'immutable';
import { Dispatch } from 'redux';
import { THROW_ERROR } from './error';
import { OPEN_NOTIFIER } from '@/app/redux/notifier';
import translate from '@/app/utils/translate';
import API from '@/app/api/internalAPIs';
import Service from '@/app/utils/service';
import { sortArr } from '@/app/utils/helper';
import {
  EvaluationStatus,
  EvaluationTemplate,
  ScoreGroup,
} from '../components/JobEvaluation/types';
import { CustomFieldType } from '../components/CustomField/types';

type Stage = {
  default: boolean;
  id: number;
  index: number;
  isDeleted: boolean;
  label: string;
};

export type JobEvaluationReportEmployee = {
  id: number;
  firstName: string;
  lastName: string;
  employeeNumber: string;
  hasAccount: boolean;
  roles: string[];
};

type JobEvaluationReportEvaluationResponse = {
  componentId: string;
  response: string;
  id: number;
  type: CustomFieldType;
};

type JobEvaluationReportEvaluation = {
  id: string;
  templateId: string;
  employeeId: number;
  responses: JobEvaluationReportEvaluationResponse[];
  respondentId: number;
  approvedBy: number[];
  status: EvaluationStatus;
  archived: boolean;
  updatedBy: number;
  updatedTime: string;
};

export type JobEvaluationReportData = {
  employee: JobEvaluationReportEmployee;
  evaluations: JobEvaluationReportEvaluation[];
};

export type JobEvaluationReportUnit = {
  employees: JobEvaluationReportData[];
  unit: {
    id: number;
    unitName: string;
  };
};

const getUniqueStages = function (stages: Stage[]) {
  if (!stages || !stages.length) {
    return stages;
  }

  return stages.reduce((acc, stage) => {
    const isIdDuplicated = acc.find(
      (existingStage) => existingStage.id === stage.id,
    );
    const isNameDuplicated = acc.find(
      (existingStage) => existingStage.label === stage.label,
    );
    if (stage.isDeleted || isIdDuplicated || isNameDuplicated) {
      return acc;
    }

    return acc.concat(stage);
  }, []);
};

const initialState = Record({
  allJobEvaluationTemplates: [],
  scoreGroups: [],
});

const FETCH_ALL_JOB_EVALUATION_TEMPLATES =
  '@@solaforce/jobEvaluation/FETCH_ALL_JOB_EVALUATION_TEMPLATES';
const ADD_JOB_EVALUATION_TEMPLATE =
  '@@solaforce/jobEvaluation/ADD_JOB_EVALUATION_TEMPLATE';
const UPDATE_JOB_EVALUATION_TEMPLATE =
  '@@solaforce/jobEvaluation/UPDATE_JOB_EVALUATION_TEMPLATE';
const CHANGE_TEMPLATE_ACTIVE_STATUS =
  '@@solaforce/jobEvaluation/CHANGE_TEMPLATE_ACTIVE_STATUS';
const DELETE_TEMPLATES = '@@solaforce/jobEvaluation/DELETE_TEMPLATES';
const DELETE_SCORE_GROUP = '@@solaforce/jobEvaluation/DELETE_SCORE_GROUP';
const DELETE_SCORE_GROUPS = '@@solaforce/jobEvaluation/DELETE_SCORE_GROUPS';
const DUPLICATE_TEMPLATE = '@@solaforce/jobEvaluation/DUPLICATE_TEMPLATE';
const ADD_TEMPLATE_SECTION = '@@solaforce/jobEvaluation/ADD_TEMPLATE_SECTION';
const UPDATE_SECTION = '@@solaforce/jobEvaluation/UPDATE_SECTION';
const ADD_SECTION_COMPONENT = '@@solaforce/jobEvaluation/ADD_SECTION_COMPONENT';
const UPDATE_SECTION_COMPONENT =
  '@@solaforce/jobEvaluation/UPDATE_SECTION_COMPONENT';
const DELETE_COMPONENT = '@@solaforce/jobEvaluation/DELETE_COMPONENT';
const DELETE_SECTION = '@@solaforce/jobEvaluation/DELETE_SECTION';
const FETCH_ALL_SCORE_GROUPS =
  '@@solaforce/jobEvaluation/FETCH_ALL_SCORE_GROUPS';
const CREATE_SCORE_GROUP = '@@solaforce/jobEvaluation/CREATE_SCORE_GROUP';
const UPDATE_SCORE_GROUP = '@@solaforce/jobEvaluation/UPDATE_SCORE_GROUP';

const reducer = (state = new initialState(), action: any) => {
  switch (action.type) {
    case FETCH_ALL_JOB_EVALUATION_TEMPLATES:
      const { allJobEvaluationTemplates } = action.payload;
      const processedJobEvaluationTemplates = allJobEvaluationTemplates.map(
        (jobEvaluationTemplate: EvaluationTemplate) => {
          return {
            ...jobEvaluationTemplate,
            sections: sortArr(
              jobEvaluationTemplate.sections.map((jobSection: any) => {
                  const allComponents = jobSection.components.concat(jobSection.appraisalComponents);
                  return { ...jobSection, components: sortArr(allComponents, 'index') };
              }),
              'index'
            ),
            stages: getUniqueStages(jobEvaluationTemplate.stages),
          };
        },
      );

      return state.set(
        'allJobEvaluationTemplates',
        processedJobEvaluationTemplates,
      );

    case ADD_JOB_EVALUATION_TEMPLATE:
      const { newTemplate } = action.payload;
      return state.set('allJobEvaluationTemplates', [
        ...state.allJobEvaluationTemplates,
        newTemplate,
      ]);

    case UPDATE_SECTION:
      const { editedTmpId, updatedSection } = action.payload;
      const updatedStateAfterSectionUpdate = state
        .get('allJobEvaluationTemplates')
        .map((template: any) => {
          if (template.id === editedTmpId) {
            template.sections.forEach((templateSection: any, index: any) => {
              if (templateSection.id === updatedSection.id) {
                template.sections[index] = updatedSection;
              }
            });
            return {
              ...template,
              sections: [...template.sections],
            };
          }

          return template;
        });

      return state.set(
        'allJobEvaluationTemplates',
        updatedStateAfterSectionUpdate,
      );

    case UPDATE_JOB_EVALUATION_TEMPLATE:
      const { updatedTemplate } = action.payload;
      const updatedStateWTemplate = state
        .get('allJobEvaluationTemplates')
        .map((item: any) => {
          if (updatedTemplate.id === item.id) {
            return {
              ...updatedTemplate,
              sections: [...item.sections],
            };
          }
          return item;
        });
      return state.set('allJobEvaluationTemplates', updatedStateWTemplate);

    case CHANGE_TEMPLATE_ACTIVE_STATUS:
      const { templatesToUpdate, newStatus } = action.payload;
      const updatedState = state
        .get('allJobEvaluationTemplates')
        .map((item: any) => {
          if (templatesToUpdate.includes(item.id)) {
            return {
              ...item,
              status: newStatus,
            };
          }
          return item;
        });
      return state.set('allJobEvaluationTemplates', updatedState);

    case DELETE_TEMPLATES:
      const { templatesToDelete } = action.payload;
      const updatedStateAfterDelete = state
        .get('allJobEvaluationTemplates')
        .filter((item: any) => {
          return !templatesToDelete.includes(item.id);
        });
      return state.set('allJobEvaluationTemplates', updatedStateAfterDelete);

    case DUPLICATE_TEMPLATE:
      const { templateIdToDuplicate, newId } = action.payload;
      let duplicate = { id: '', subject: '' };
      const currentState = state
        .get('allJobEvaluationTemplates')
        .map((item: any) => {
          if (item.id === templateIdToDuplicate) {
            duplicate = JSON.parse(JSON.stringify(item));
            duplicate.id = newId;
            duplicate.subject = `${item.subject} - Duplicate`;
          }
          return item;
        });
      return state.set('allJobEvaluationTemplates', [
        ...currentState,
        duplicate,
      ]);

    case ADD_TEMPLATE_SECTION:
      const { templateId, section } = action.payload;
      const updatedStateWSection = state
        .get('allJobEvaluationTemplates')
        .map((item: any) => {
          if (templateId === item.id) {
            return {
              ...item,
              sections: [...item.sections, section],
            };
          }
          return item;
        });
      return state.set('allJobEvaluationTemplates', updatedStateWSection);

    case DELETE_SECTION:
      const { tempId, sectionToDeleteId } = action.payload;
      const updatedStateAfterSectionDelete = state
        .get('allJobEvaluationTemplates')
        .map((template: any) => {
          if (tempId === template.id) {
            return {
              ...template,
              sections: template.sections.filter(
                (sec: any) => sec.id !== sectionToDeleteId,
              ),
            };
          }

          return template;
        });
      return state.set(
        'allJobEvaluationTemplates',
        updatedStateAfterSectionDelete,
      );

    case ADD_SECTION_COMPONENT:
      const { templateIdUsed, sectionId, component } = action.payload;
      const updatedStateWComponent = state
        .get('allJobEvaluationTemplates')
        .map((item: any) => {
          if (templateIdUsed === item.id) {
            item.sections.forEach((templateSection: any, index: any) => {
              if (templateSection.id === sectionId) {
                if (item.sections[index].components) {
                  item.sections[index].components = [
                    ...item.sections[index].components,
                    ...component,
                  ];
                } else {
                  item.sections[index].components = [...component];
                }
                return {
                  ...item,
                  sections: [...item.sections],
                };
              }
            });
            return {
              ...item,
              sections: [...item.sections],
            };
          }
          return item;
        });
      return state.set('allJobEvaluationTemplates', updatedStateWComponent);

    case UPDATE_SECTION_COMPONENT:
      const { tmpId, secId, cmp } = action.payload;
      const updatedComponent = state
        .get('allJobEvaluationTemplates')
        .map((item: any) => {
          if (tmpId === item.id) {
            item.sections.forEach((templateSection: any, index: any) => {
              if (templateSection.id === secId) {
                templateSection.components.map((comp: any, idx: any) => {
                  if (comp.id === cmp[0].id) {
                    return {
                      ...item,
                      sections: [...item.sections],
                      components: [
                        ...item.sections[index].components,
                        (item.sections[index].components[idx] = cmp[0]),
                      ],
                    };
                  }
                });
              }
            });
          }
          return item;
        });
      return state.set('allJobEvaluationTemplates', updatedComponent);

    case DELETE_COMPONENT:
      const {
        editedTemplateId,
        editedSectionId,
        componentsToDelete,
      } = action.payload;
      const updatedSectionStateAfterDelete = state
        .get('allJobEvaluationTemplates')
        .map((template: any) => {
          if (editedTemplateId === template.id) {
            template.sections.forEach((templateSection: any, index: any) => {
              if (editedSectionId === templateSection.id) {
                if (template.sections[index].components) {
                  template.sections[index].components = template.sections[
                    index
                  ].components.filter((sectionComponent: any) => {
                    return !componentsToDelete.includes(sectionComponent.id);
                  });
                }
              }
            });
          }
          return {
            ...template,
            sections: [...template.sections],
          };
        });

      return state.set(
        'allJobEvaluationTemplates',
        updatedSectionStateAfterDelete,
      );

    case FETCH_ALL_SCORE_GROUPS: {
      const { scoreGroups } = action.payload;

      return state.set('scoreGroups', scoreGroups);
    }

    case CREATE_SCORE_GROUP: {
      const scoreGroup = action.payload;
      const oldScoreGroups = state.get('scoreGroups');
      const scoreGroups = [...oldScoreGroups, scoreGroup];

      return state.set('scoreGroups', scoreGroups);
    }

    case UPDATE_SCORE_GROUP: {
      const scoreGroup = action.payload;
      const oldScoreGroups = state.get('scoreGroups');

      const scoreGroups = oldScoreGroups.map((scg) => {
        if (scg.id === scoreGroup.id) {
          scg = { ...scg, ...scoreGroup };
        }

        return scg;
      });

      return state.set('scoreGroups', scoreGroups);
    }

    case DELETE_SCORE_GROUP:
      const { id } = action.payload;
      const scoreGroupsAfterDelete = state
        .get('scoreGroups')
        .filter((item: any) => item.id !== id);
      return state.set('scoreGroups', scoreGroupsAfterDelete);

    case DELETE_SCORE_GROUPS:
      const { scoreGroupsToDelete } = action.payload;
      const scoreGroupsUpdatedStateAfterDelete = state
        .get('scoreGroups')
        .filter((item: any) => !scoreGroupsToDelete.includes(item.id));
      return state.set('scoreGroups', scoreGroupsUpdatedStateAfterDelete);

    default:
      return state;
  }
};

// >>>>>>>>>>>>>>>>>>>>>>>>> TEMPLATES  <<<<<<<<<<<<<<<<<<<<<<<<<
export const fetchAllJobEvaluationTemplates = () => (dispatch: Dispatch) =>
  Service.get(
    API.jobEvaluationTemplates.getAllTemplates(),
    (response: any) => {
      const payload = {
        allJobEvaluationTemplates: response.data,
      };
      dispatch({ type: FETCH_ALL_JOB_EVALUATION_TEMPLATES, payload });
    },
    (error: any) => dispatch({ type: THROW_ERROR, error }),
  );

export const addJobEvaluationTemplate = (template: any[]) => (
  dispatch: Dispatch,
) =>
  Service.post(
    API.jobEvaluationTemplates.addJobEvaluationTemplate(),
    template,
    (response: any) => {
      response.sections = [];
      const payload = {
        newTemplate: response,
      };
      dispatch({ type: ADD_JOB_EVALUATION_TEMPLATE, payload });
      dispatch({
        type: OPEN_NOTIFIER,
        payload: { message: translate.t('new_template_added') },
      });
    },
    (error: any) => dispatch({ type: THROW_ERROR, error }),
  );

export const updateJobEvaluationTemplate = (
  templateId: string,
  template: any[],
) => (dispatch: Dispatch) =>
  Service.post(
    API.jobEvaluationTemplates.updateTemplate(templateId),
    template,
    (response: any) => {
      const payload = {
        updatedTemplate: response,
      };
      dispatch({ type: UPDATE_JOB_EVALUATION_TEMPLATE, payload });
      dispatch({ type: OPEN_NOTIFIER, payload: {} });
    },
    (error: any) => dispatch({ type: THROW_ERROR, error }),
  );

export const changeTemplateStatus = (templateIds: [], newStatus: string) => (
  dispatch: Dispatch,
) =>
  Service.post(
    newStatus === 'ACTIVE'
      ? API.jobEvaluationTemplates.activateTemplate()
      : API.jobEvaluationTemplates.deactivateTemplate(),
    { ids: templateIds },
    (response: any) => {
      const payload = {
        templatesToUpdate: response.data.map((template: any) => template.id),
        newStatus: newStatus,
      };
      dispatch({ type: CHANGE_TEMPLATE_ACTIVE_STATUS, payload });
      dispatch({
        type: OPEN_NOTIFIER,
        payload: { message: translate.t('template_status_updated') },
      });
    },
    (error: any) => dispatch({ type: THROW_ERROR, error }),
  );

export const deleteTemplate = (templateIds: []) => (dispatch: Dispatch) =>
  Service.post(
    API.jobEvaluationTemplates.removeTemplates(),
    { ids: templateIds },
    () => {
      const payload = {
        templatesToDelete: templateIds,
      };
      dispatch({ type: DELETE_TEMPLATES, payload });
      dispatch({
        type: OPEN_NOTIFIER,
        payload: { message: translate.t('template_deleted') },
      });
    },
    (error: any) => dispatch({ type: THROW_ERROR, error }),
  );

export const deleteScoreGroup = (id: string) => (dispatch: Dispatch) =>
  Service.delete(
    API.jobEvaluationTemplates.deleteScoreGroup(id),
    undefined,
    () => {
      const payload = {
        id,
      };
      dispatch({ type: DELETE_SCORE_GROUP, payload });
      dispatch({
        type: OPEN_NOTIFIER,
        payload: { message: translate.t('score_group_deleted') },
      });
    },
    (error: any) => dispatch({ type: THROW_ERROR, error }),
  );

export const deleteScoreGroups = (scoreGroupIds: []) => (dispatch: Dispatch) =>
  Service.post(
    API.jobEvaluationTemplates.deleteScoreGroups(),
    { ids: scoreGroupIds },
    () => {
      const payload = {
        scoreGroupsToDelete: scoreGroupIds,
      };
      dispatch({ type: DELETE_SCORE_GROUPS, payload });
      dispatch({
        type: OPEN_NOTIFIER,
        payload: { message: translate.t('score_groups_deleted') },
      });
    },
    (error: any) => dispatch({ type: THROW_ERROR, error }),
  );

export const duplicateTemplate = (templateId: string) => (dispatch: Dispatch) =>
  Service.post(
    API.jobEvaluationTemplates.duplicateTemplate(templateId),
    { id: templateId },
    (response: any) => {
      const payload = {
        templateIdToDuplicate: templateId,
        newId: response.id,
      };
      dispatch({ type: DUPLICATE_TEMPLATE, payload });
      dispatch({
        type: OPEN_NOTIFIER,
        payload: { message: translate.t('template_duplicated') },
      });
    },
    (error: any) => dispatch({ type: THROW_ERROR, error }),
  );

// >>>>>>>>>>>>>>>>>>>>>>>>> SECTIONS  <<<<<<<<<<<<<<<<<<<<<<<<<
export const addTemplateSection = (templateId: string, section: {}) => (
  dispatch: Dispatch,
) =>
  Service.post(
    API.jobEvaluationTemplates.addSection(templateId),
    section,
    () => {
      const payload = {
        templateId: templateId,
        section: section,
      };
      dispatch({ type: ADD_TEMPLATE_SECTION, payload });
      dispatch({
        type: OPEN_NOTIFIER,
        payload: { message: translate.t('new_section_added') },
      });
    },
    (error: any) => dispatch({ type: THROW_ERROR, error }),
  );

export const updateSection = (editedTmpId: any, section: any) => (
  dispatch: Dispatch,
) =>
  Service.post(
    API.jobEvaluationTemplates.updateSection(section.id),
    {
      name: section.name,
      empAccess: section.empAccess,
      onlyEmpAccess: section.onlyEmpAccess,
      components: section.components,
      index: section.index,
    },
    () => {
      dispatch({
        type: UPDATE_SECTION,
        payload: { updatedSection: section, editedTmpId: editedTmpId },
      });
      dispatch({
        type: OPEN_NOTIFIER,
        payload: { message: translate.t('sectionUpdated') },
      });
    },
    (error: any) => dispatch({ type: THROW_ERROR, error }),
  );

export const deleteSection = (templateId: string, sectionId: string) => (
  dispatch: Dispatch,
) =>
  Service.delete(
    API.jobEvaluationTemplates.deleteSection(sectionId),
    { id: sectionId },
    () => {
      const payload = {
        tempId: templateId,
        sectionToDeleteId: sectionId,
      };
      dispatch({ type: DELETE_SECTION, payload });
      dispatch({
        type: OPEN_NOTIFIER,
        payload: { message: translate.t('section_deleted') },
      });
    },
    (error: any) => dispatch({ type: THROW_ERROR, error }),
  );

// >>>>>>>>>>>>>>>>>>>>>>>>> Components  <<<<<<<<<<<<<<<<<<<<<<<<<
export const addComponentToSection = (
  templateId: string,
  sectionId: string,
  component: {},
) => (dispatch: Dispatch) =>
  Service.post(
    API.jobEvaluationTemplates.addComponent(sectionId),
    component,
    (response: any) => {
      const payload = {
        templateIdUsed: templateId,
        sectionId: sectionId,
        component: [response],
      };
      dispatch({ type: ADD_SECTION_COMPONENT, payload });
      dispatch({
        type: OPEN_NOTIFIER,
        payload: { message: translate.t('new_component_added') },
      });
    },
    (error: any) => dispatch({ type: THROW_ERROR, error }),
  );

export const updateSectionComponent = (
  templateId: string,
  sectionId: string,
  component: any,
) => (dispatch: Dispatch) => {
  const { componentId, ...componentProps } = component;

  return Service.post(
    API.jobEvaluationTemplates.updateComponent(componentId),
    componentProps,
    (response: any) => {
      const payload = {
        tmpId: templateId,
        secId: sectionId,
        cmp: [response],
      };
      dispatch({ type: UPDATE_SECTION_COMPONENT, payload });
      dispatch({
        type: OPEN_NOTIFIER,
        payload: { message: translate.t('componentUpdated') },
      });
    },
    (error: any) => dispatch({ type: THROW_ERROR, error }),
  );
};

export const orderComponents = (sectionId: string, components: any) => (
  dispatch: Dispatch,
) =>
  Service.post(
    API.jobEvaluationTemplates.orderComponents(sectionId),
    { components },
    () => {
      dispatch({
        type: OPEN_NOTIFIER,
        payload: { message: translate.t('componentUpdated') },
      });
    },
    (error: any) => dispatch({ type: THROW_ERROR, error }),
  );

export const deleteComponent = (
  templateId: string,
  sectionId: string,
  componentIds: [],
) => (dispatch: Dispatch) =>
  Service.post(
    API.jobEvaluationTemplates.deleteComponent(),
    { ids: componentIds },
    () => {
      const payload = {
        editedTemplateId: templateId,
        editedSectionId: sectionId,
        componentsToDelete: componentIds,
      };
      dispatch({ type: DELETE_COMPONENT, payload });
      dispatch({
        type: OPEN_NOTIFIER,
        payload: { message: translate.t('component_deleted') },
      });
    },
    (error: any) => dispatch({ type: THROW_ERROR, error }),
  );

export const getEnumScoreGroups = () => (dispatch: Dispatch) =>
  Service.get(
    API.jobEvaluationTemplates.getScoreGroups(),
    (scoreGroups: ScoreGroup[]) => {
      dispatch({ type: FETCH_ALL_SCORE_GROUPS, payload: { scoreGroups } });
    },
    (error: any) => dispatch({ type: THROW_ERROR, error }),
  );

export const createEnumScoreGroup = (scoreGroup: ScoreGroup) => (
  dispatch: Dispatch,
) =>
  Service.post(
    API.jobEvaluationTemplates.createScoreGroup(),
    scoreGroup,
    (response: ScoreGroup) => {
      dispatch({ type: CREATE_SCORE_GROUP, payload: { ...response } });
    },
    (error: any) => dispatch({ type: THROW_ERROR, error }),
  );

export const updateEnumScoreGroup = (scoreGroup: ScoreGroup) => (
  dispatch: Dispatch,
) => {
  const { id, ...group } = scoreGroup;

  return Service.put(
    API.jobEvaluationTemplates.updateScoreGroup(id),
    { ...group },
    (response: ScoreGroup) => {
      dispatch({ type: UPDATE_SCORE_GROUP, payload: { ...response } });
    },
    (error: any) => dispatch({ type: THROW_ERROR, error }),
  );
};

export default reducer;

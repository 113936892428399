import {
  IconButton,
  InputLabel,
  FormControl,
  Select,
  TableRow,
  TableCell,
  TextField,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import * as math from 'mathjs';
import * as moment from 'moment';
import * as React from 'react';

import { ErrorTooltip } from '@/app/components/ErrorTooltip';
import LocalizedDatePicker from '@/app/components/Pickers/LocalizedDatePicker';
import { isNumber, isNumberWithBothCommaAndDot } from '@/app/utils/helper';
import translate from '@/app/utils/translate';
import Service from '@/app/utils/service';
import API from '@/app/api/internalAPIs';

import MoodPicker from './MoodPicker';

type PropsType = {
  data: any;
  submitErrors?: any;
  onChange: (data: any) => void;
  onDelete: (data: any) => void;
  inEditMode: boolean;
  allCommonGoals: any;
};

type StateType = {
  loading: boolean;
  successMeasureSettings?: any;
  errors: any;
};

const getDaysDifference = (date1: moment.Moment, date2: moment.Moment) => {
  const days = date1.diff(date2, 'days');
  date2.add(days, 'days');

  return days;
};

const getDayValueFromDate = (dateValue: string) => {
  var theDate = moment(dateValue, 'DD.MM.YYYY').isValid()
    ? moment(dateValue, 'DD.MM.YYYY')
    : moment(dateValue, 'YYYY-MM-DD');
  var today = moment();

  return getDaysDifference(today, theDate);
};

const calculateResultFromFormula = (formula: string, data: any) => {
  try {
    const { rangeMax, rangeMin, actual, target, isDate } = data;

    let actualValue = isNumberWithBothCommaAndDot(actual) ? actual : '0';
    let rangeMaxValue = isNumberWithBothCommaAndDot(rangeMax) ? rangeMax : '0';
    let rangeMinValue = isNumberWithBothCommaAndDot(rangeMin) ? rangeMin : '0';
    let targetValue = isNumberWithBothCommaAndDot(target) ? target : '0';

    if (isDate) {
      actualValue = `(${getDayValueFromDate(actual)})`;
      rangeMaxValue = `(${getDayValueFromDate(rangeMax)})`;
      rangeMinValue = `(${getDayValueFromDate(rangeMin)})`;
      targetValue = `(${getDayValueFromDate(target)})`;
    }

    const formulaToEvaluate = formula
      .replace(new RegExp('{RANGE_MAX}', 'g'), rangeMaxValue)
      .replace(new RegExp('{RANGE_MIN}', 'g'), rangeMinValue)
      .replace(new RegExp('{TARGET}', 'g'), targetValue)
      .replace(new RegExp('{ACTUAL}', 'g'), actualValue)
      .replace(new RegExp(',', 'g'), '.');

    const result = math.eval(formulaToEvaluate);

    return isNumber(result.toString()) ? result : 0;
  } catch (error) {
    return 0;
  }
};

class CommonGoal extends React.PureComponent<PropsType, StateType> {
  state: StateType = {
    loading: false,
    errors: {},
  };

  componentDidMount() {
    this.setState({ errors: this.props.submitErrors || {} });
    this.loadSuccessMeasureSettings(true);
  }

  componentDidUpdate(prevProps: Readonly<PropsType>) {
    if (this.props.submitErrors !== prevProps.submitErrors) {
      this.setState({ errors: this.props.submitErrors || {} });
    }

    if (this.props.data.successMeasure !== prevProps.data.successMeasure) {
      this.loadSuccessMeasureSettings(false);
    }
  }

  loadSuccessMeasureSettings = (updateOnlyIfTemplate = false) => {
    const { data } = this.props;

    if (!data.goal || !data.successMeasure) {
      return;
    }

    this.setState({ loading: true });

    Service.get(
      API.goalEvaluations.getSuccessMeasureSettings(data.goal),
      (goalSettings: any[]) => {
        const successMeasureSettings = goalSettings.find(
          gs => gs.fPerfConfGoalCommonSuccessMeasure === data.successMeasure,
        );

        this.setState({
          successMeasureSettings,
          loading: false,
        });

        if (
          successMeasureSettings &&
          !(updateOnlyIfTemplate && !data.template)
        ) {
          const preloadedData = {
            startDate:
              successMeasureSettings.fPerfConfGoalCommonStartDate || '',
            endDate: successMeasureSettings.fPerfConfGoalCommonEndDate || '',
            weight: successMeasureSettings.fPerfConfGoalCommonWeight || '',
            rangeMin: successMeasureSettings.fPerfConfGoalCommonRangeMin || '',
            rangeMax: successMeasureSettings.fPerfConfGoalCommonRangeMax || '',
            target: successMeasureSettings.fPerfConfGoalCommonTarget || '',
            total: successMeasureSettings.fPerfConfGoalCommonTotal || '',
            actual: successMeasureSettings.fPerfConfGoalCommonActual || '',
            result: successMeasureSettings.fPerfConfGoalCommonResult || '',
            info: successMeasureSettings.fPerfConfGoalCommonInfo || '',
            isDate: successMeasureSettings.fPerfConfGoalCommonIsDate || false,
          };

          if (successMeasureSettings.fPerfConfGoalCommonRuleFormula) {
            const result = calculateResultFromFormula(
              successMeasureSettings.fPerfConfGoalCommonRuleFormula,
              preloadedData,
            );
            preloadedData.result = Math.trunc(result * 1000) / 1000;
          }

          this.props.onChange({
            ...this.props.data,
            ...preloadedData,
            template: undefined,
          });
        }
      },
      () => {
        this.setState({
          successMeasureSettings: undefined,
          loading: false,
        });
      },
    );
  };

  handleStatusChange = (status: string) => {
    this.props.onChange({ ...this.props.data, status });
  };

  handleChangeGoal = (event: any) => {
    this.props.onChange({
      ...this.props.data,
      goal: event.target.value,
      successMeasure: '',
      startDate: '',
      endDate: '',
      weight: '',
      rangeMin: '',
      rangeMax: '',
      target: '',
      total: '',
      actual: '',
      result: '',
      info: '',
    });

    this.setState({
      errors: {},
    });
  };

  handleChangeSuccessMeasure = (event: any) => {
    this.props.onChange({
      ...this.props.data,
      successMeasure: event.target.value,
      startDate: '',
      endDate: '',
      weight: '',
      rangeMin: '',
      rangeMax: '',
      target: '',
      total: '',
      actual: '',
      result: '',
      info: '',
    });

    this.setState({
      errors: {},
    });
  };

  handleChangeText = (property: string) => (event: any) => {
    const nextData = {
      ...this.props.data,
      [property]: event.target.value,
    };

    if (
      this.state.successMeasureSettings &&
      this.state.successMeasureSettings.fPerfConfGoalCommonRuleFormula
    ) {
      if (['target', 'rangeMin', 'rangeMax', 'actual'].includes(property)) {
        const result = calculateResultFromFormula(
          this.state.successMeasureSettings.fPerfConfGoalCommonRuleFormula,
          nextData,
        );
        nextData.result = Math.trunc(result * 1000) / 1000;
      }
    }

    this.props.onChange(nextData);

    this.setState({
      errors: {
        ...this.state.errors,
        [property]: undefined,
      },
    });
  };

  handleChangeDate = (property: string) => (date: moment.Moment) => {
    const nextData = {
      ...this.props.data,
      [property]: date.format('YYYY-MM-DD'),
    };

    if (
      this.state.successMeasureSettings &&
      this.state.successMeasureSettings.fPerfConfGoalCommonRuleFormula
    ) {
      if (['target', 'rangeMin', 'rangeMax', 'actual'].includes(property)) {
        const result = calculateResultFromFormula(
          this.state.successMeasureSettings.fPerfConfGoalCommonRuleFormula,
          nextData,
        );
        nextData.result = Math.trunc(result * 1000) / 1000;
      }
    }

    this.props.onChange(nextData);
  };

  handleDelete = () => {
    this.props.onDelete(this.props.data);
  };

  getTotal = () => {
    const { data } = this.props;
    const total = (data.result * (data.weight / 100)).toFixed(3);
    return isNumber(data.result) && isNumber(data.weight)
      ? Math.trunc(parseFloat(total) * 1000) / 1000
      : '';
  };

  inputIsDisabled = (field: string) => {
    const { data, inEditMode } = this.props;
    const { loading, successMeasureSettings } = this.state;

    if (['goal', 'successMeasure'].includes(field) && !!data.componentId) {
      return true;
    }

    if (
      field === 'result' &&
      successMeasureSettings &&
      successMeasureSettings.fPerfConfGoalCommonRuleFormula
    ) {
      return true;
    }

    return (
      loading ||
      !inEditMode ||
      (successMeasureSettings &&
        successMeasureSettings.fPerfConfGoalCommonLocks[
        `fPerfConfGoalCommon${field.charAt(0).toUpperCase() +
        field.slice(1)}Locked`
        ])
    );
  };

  getField = (fieldName: string, label: string, data: any, errors: any) =>
    data.isDate ? (
      <LocalizedDatePicker
        label={label}
        value={data[fieldName] || null}
        onChange={this.handleChangeDate(fieldName)}
        disabled={this.inputIsDisabled(fieldName)}
        autoOk={true}
      />
    ) : (
      <ErrorTooltip placement="top" title={errors[fieldName]}>
        <TextField
          label={label}
          value={data[fieldName] || ''}
          onChange={this.handleChangeText(fieldName)}
          error={!!errors[fieldName]}
          disabled={this.inputIsDisabled(fieldName)}
          fullWidth
        />
      </ErrorTooltip>
    );

  render() {
    const { data, inEditMode, allCommonGoals } = this.props;
    const { errors } = this.state;
    const result = data.result;
    return (
      <>
        <TableRow>
          <TableCell>
            <MoodPicker
              value={data.status}
              onChange={this.handleStatusChange}
              disabled={this.inputIsDisabled('status')}
            />
          </TableCell>
          <TableCell colSpan={2}>
            <ErrorTooltip placement="top" title={errors.goal}>
              <FormControl fullWidth>
                <InputLabel htmlFor={`common-goals-${data.goalId}`}>
                  {translate.t('laCommonGoals')}
                </InputLabel>
                <Select
                  native
                  title={
                    allCommonGoals[data.goal]
                      ? allCommonGoals[data.goal].name
                      : ''
                  }
                  name={`common-goals-${data.goalId}`}
                  value={data.goal || ''}
                  onChange={this.handleChangeGoal}
                  error={!!errors.goal}
                  disabled={this.inputIsDisabled('goal')}
                  fullWidth
                >
                  <option value="" />

                  {Object.values(allCommonGoals).map((goal: any) => (
                    <option key={goal.subgroupName} value={goal.subgroupName}>
                      {goal.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </ErrorTooltip>
          </TableCell>
          <TableCell colSpan={2}>
            <ErrorTooltip placement="top" title={errors.successMeasure}>
              <FormControl fullWidth>
                <InputLabel htmlFor={`success-measure-${data.goalId}`}>
                  {translate.t('laSuccessMeasure')}
                </InputLabel>
                <Select
                  native
                  title={
                    allCommonGoals[data.goal] &&
                      allCommonGoals[data.goal].subgroupValues[
                      data.successMeasure
                      ]
                      ? allCommonGoals[data.goal].subgroupValues[
                        data.successMeasure
                      ].name
                      : ''
                  }
                  name={`success-measure-${data.goalId}`}
                  value={data.successMeasure || ''}
                  onChange={this.handleChangeSuccessMeasure}
                  error={!!errors.successMeasure}
                  disabled={this.inputIsDisabled('successMeasure')}
                  fullWidth
                >
                  <option value="" />

                  {allCommonGoals[data.goal] &&
                    Object.values(allCommonGoals[data.goal].subgroupValues).map(
                      (successMeasure: any) => (
                        <option
                          key={successMeasure.code}
                          value={successMeasure.code}
                        >
                          {successMeasure.name}
                        </option>
                      ),
                    )}
                </Select>
              </FormControl>
            </ErrorTooltip>
          </TableCell>
          <TableCell>
            <LocalizedDatePicker
              label={translate.t('laStarted')}
              value={data.startDate || null}
              onChange={this.handleChangeDate('startDate')}
              disabled={this.inputIsDisabled('startDate')}
              autoOk={true}
            />
          </TableCell>
          <TableCell>
            <LocalizedDatePicker
              label={translate.t('laEnd')}
              value={data.endDate || null}
              onChange={this.handleChangeDate('endDate')}
              disabled={this.inputIsDisabled('endDate')}
              autoOk={true}
            />
          </TableCell>
          <TableCell>
            {inEditMode && !data.componentId && (
              <IconButton
                style={{ height: 'auto' }}
                onClick={this.handleDelete}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell />
          <TableCell>
            {this.getField('target', translate.t('laTarget'), data, errors)}
          </TableCell>
          <TableCell>
            {this.getField('rangeMin', translate.t('laRangeMin'), data, errors)}
          </TableCell>
          <TableCell>
            {this.getField('rangeMax', translate.t('laRangeMax'), data, errors)}
          </TableCell>
          <TableCell>
            {this.getField('actual', translate.t('laActual'), data, errors)}
          </TableCell>
          <TableCell>
            <ErrorTooltip placement="top" title={errors.result}>
              <TextField
                label={translate.t('laResult')}
                value={result || ''}
                onChange={this.handleChangeText('result')}
                error={!!errors.result}
                disabled={this.inputIsDisabled('result')}
                fullWidth
              />
            </ErrorTooltip>
          </TableCell>
          <TableCell>
            <ErrorTooltip placement="top" title={errors.weight}>
              <TextField
                label={translate.t('laWeight')}
                value={data.weight || ''}
                onChange={this.handleChangeText('weight')}
                error={!!errors.weight}
                disabled={this.inputIsDisabled('weight')}
                fullWidth
              />
            </ErrorTooltip>
          </TableCell>
          <TableCell>
            <TextField
              label={translate.t('laTotal')}
              value={this.getTotal()}
              disabled={true}
              fullWidth
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell />
          <TableCell colSpan={7}>
            <TextField
              multiline
              label={translate.t('laInfo')}
              variant="outlined"
              value={data.info || ''}
              onChange={this.handleChangeText('info')}
              disabled={this.inputIsDisabled('info')}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </TableCell>
        </TableRow>
      </>
    );
  }
}

export default CommonGoal;

import { APPROVAL_STATUS } from '../types';
import translate from '@/app/utils/translate';

export default (
  approvalStatus: APPROVAL_STATUS,
  seated: boolean = false,
  position: number,
  trainingSeats: number = 0,
  allowWaitingSeats: boolean = false,
) => {
  if (
    approvalStatus === APPROVAL_STATUS.REJECTED ||
    approvalStatus === APPROVAL_STATUS.CANCELLED ||
    (trainingSeats > 0 && !allowWaitingSeats && position > trainingSeats)
  ) {
    return translate.t('label_no_seat');
  } else {
    if (!trainingSeats) {
      return !seated
        ? translate.t('label_seat', { seat: position })
        : translate.t('label_seat_guaranteed', { seat: position });
    } else {
      if (position > trainingSeats) {
        return translate.t('label_seat_waiting', { seat: `${position - trainingSeats}` });
      } else {
        return !seated
          ? translate.t('label_seat', { seat: `${position}/${trainingSeats}` })
          : translate.t('label_seat_guaranteed', { seat: `${position}/${trainingSeats}` });
      }
    }
  }
};

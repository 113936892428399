const GLOBAL: any = window;

export default (code: string): string => {
  try {
    const lang = GLOBAL.jsonLanguages.find((item: any) => item.code.toLocaleLowerCase() === code.toLocaleLowerCase());
    return lang.name;
  } catch (e) {
    return `No translation for language "${code}"`;
  }
};

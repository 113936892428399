import { Typography } from '@material-ui/core';
import { process, State } from '@progress/kendo-data-query';
import {
  GridDataStateChangeEvent,
  Grid,
  GridColumn,
  GridToolbar,
} from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';

import React, { LegacyRef, useRef, useState } from 'react';

import ColumnMenu from '@/app/components/Kendo/ColumnMenu';
import translate from '@/app/utils/translate';

import KendoAvatarCell from '../../../components/KendoAvatarCell';
import KendoReportDownloadButton from '../../../components/KendoReportDownloadButton';
import KendoSelectFilter from '../../../components/KendoSelectFilter';

export const empGridColumns = () => [
  {
    cell: KendoAvatarCell,
    field: 'empId',
    show: true,
    title: translate.t('laPicture'),
    width: 88,
  },
  {
    field: 'firstName',
    show: true,
    title: translate.t('laFirstName'),
  },
  {
    field: 'lastName',
    show: true,
    title: translate.t('laLastName'),
  },
  {
    field: 'empNumber',
    show: true,
    title: translate.t('laEmpNumber'),
  },
  {
    field: 'subject',
    show: true,
    title: translate.t('laSubject'),
  },
  {
    field: 'status',
    show: true,
    title: translate.t('laStatus'),
  },
  {
    field: 'approvedBy',
    show: true,
    title: translate.t('laApprovedBy'),
  },
  {
    field: 'updatedBy',
    show: true,
    title: translate.t('laUpdatedBy'),
  },
  {
    field: 'updatedTime',
    show: true,
    title: translate.t('laUpdatedDate'),
  },
  {
    field: 'archived',
    show: true,
    title: translate.t('laArchived')
  }
];

type Props = {
  exportFileName: string;
  empData: any;
  empGridTitle: string;
};

const EmployeeGrid = (props: Props) => {
  const { exportFileName, empData, empGridTitle } = props;

  const exportRef: LegacyRef<ExcelExport> = useRef();
  const [columns, setColumns] = useState(empGridColumns());
  const [dataState, setDataState] = useState<State>({ skip: 0, take: 9 });

  const onColumnsSubmit = (columnsState: any) => {
    setColumns(columnsState);
  };

  const dataStateChange = (event: GridDataStateChangeEvent) => ({
    dataState: setDataState(event.data),
  });

  const empStatusOptions = Array.from(
    new Set(empData.map((field: any) => field.status)),
  );
  const empStatusFilterCell = KendoSelectFilter(empStatusOptions, 'Select status');
  const saveGridData = () => {
    exportRef.current.save();
  };

  return (
    <div>
      <ExcelExport
        data={
          process(empData, {
            ...dataState,
            // Export all the records
            skip: 0,
            take: undefined,
          }).data
        }
        fileName={exportFileName}
        ref={exportRef}
      >
        <Grid
          data={process(empData, dataState)}
          onDataStateChange={dataStateChange}
          {...dataState}
          sortable={true}
          resizable={true}
          pageable={true}
          groupable={true}
          filterable={false}
        >
          <GridToolbar>
            <Typography variant="h6" gutterBottom color="primary">
              {empGridTitle}
            </Typography>
            <KendoReportDownloadButton onClick={saveGridData}/>
          </GridToolbar>
          {columns.map(
            (column: any, idx: number) =>
              column.show && (
                <GridColumn
                  {...column}
                  key={idx}
                  columnMenu={(otherProps: any) => (
                    <ColumnMenu
                      {...otherProps}
                      columns={columns}
                      onColumnsSubmit={onColumnsSubmit}
                      filterUI={
                        column.field === 'status'
                          ? empStatusFilterCell
                          : undefined
                      }
                    />
                  )}
                />
              ),
          )}
        </Grid>
      </ExcelExport>
    </div>
  );
};

export default EmployeeGrid;

export enum ReportType {
    BY_ASSIGNEE = 'BY_ASSIGNEE',
    BY_ASSIGNOR = 'BY_ASSIGNOR',
    BY_PERTAINS = 'BY_PERTAINS',
}

export interface CollaborationTask {
    id: number;
    title: string;
    type: string;
    status: string;
    startDate: Date;
    dueDate: Date | string;
    assigneeId?: number;
    assigneeName: string;
    assignorId?: number;
    assignorName: string;
    pertainingToId?: number;
    pertainingToName: string;
    numberOfComments: number;
    attachmentId: number;
    attachment: string;
}

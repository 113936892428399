import React from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  StyleRulesCallback,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import {
  ApprovalStatusHeadButton,
  CompletionStatusHeadButton,
  ExpiresHeadButton,
  HoursHeadButton,
  UpdateHistoryHeadButton,
} from './participantTableHead/';
import translate from '@/app/utils/translate';
import {
  ApprovalAction,
  HoursAction,
  CompletionAction,
  ExpiresAction,
  UpdateTrainingHistoryAction,
  CompanyTrainingParticipantRegistration,
  CompanyTraining,
} from '../../../types';
import moment from 'moment';

const styles: StyleRulesCallback = () => ({
  headButton: {
    textTransform: 'none',
    fontSize: '0.75rem',
  },
  selectionCell: {
    width: 0,
  },
});

type OwnProps = {
  training: CompanyTraining;
  isReordering?: boolean;
  participants?: CompanyTrainingParticipantRegistration[];
  onChangeApproval: (action: ApprovalAction) => void;
  onChangeHours: (action: HoursAction, hours?: number) => void;
  onChangeCompletionStatus: (action: CompletionAction) => void;
  onChangeExpires: (action: ExpiresAction, expires?: moment.Moment) => void;
  onChangeUpdateHistory: (action: UpdateTrainingHistoryAction) => void;
  onToggleAllParticipantRegistration: () => void;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const ParticipantTableHead = ({
  classes,
  training,
  participants = [],
  onChangeApproval,
  onChangeHours,
  onChangeCompletionStatus,
  onChangeExpires,
  onChangeUpdateHistory,
  onToggleAllParticipantRegistration,
}: Props) => {
  const GLOBAL: any = window;

  const selectionStatus = React.useMemo(() => {
    const viableParticipants = participants.filter(
      ({ participant }) => participant.id,
    );
    const allParticipantsAreSelected = viableParticipants.every(
      ({ selected }) => selected,
    );
    const onlySomeParticipantsAreSelected =
      !allParticipantsAreSelected &&
      viableParticipants.some(({ selected }) => selected);
    return { allParticipantsAreSelected, onlySomeParticipantsAreSelected };
  }, [participants]);

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.selectionCell}>
          <Checkbox
            checked={selectionStatus.allParticipantsAreSelected}
            indeterminate={selectionStatus.onlySomeParticipantsAreSelected}
            onChange={onToggleAllParticipantRegistration}
            color="primary"
          />
        </TableCell>
        <TableCell>{translate.t('laEmployee')}</TableCell>
        <TableCell>{translate.t('label_seat_status')}</TableCell>
        <TableCell>
          <ApprovalStatusHeadButton
            className={classes.headButton}
            onChangeApproval={onChangeApproval}
            readOnly={participants.length === 0}
          />
        </TableCell>
        <TableCell>
          <HoursHeadButton
            className={classes.headButton}
            onChangeHours={onChangeHours}
            readOnly={participants.length === 0}
          />
        </TableCell>
        <TableCell>
          <CompletionStatusHeadButton
            className={classes.headButton}
            onChangeCompletionStatus={onChangeCompletionStatus}
            readOnly={participants.length === 0}
          />
        </TableCell>
        <TableCell>
          <ExpiresHeadButton
            className={classes.headButton}
            onChangeExpires={onChangeExpires}
            readOnly={participants.length === 0}
          />
        </TableCell>
        <TableCell>
          <UpdateHistoryHeadButton
            className={classes.headButton}
            onChangeUpdateHistory={onChangeUpdateHistory}
            readOnly={participants.length === 0}
          />
        </TableCell>
        {GLOBAL.bnEnableDecisionRegistry && (
          <TableCell>{translate.t('laAddToDR')}</TableCell>
        )}
        {training.includeInTrainingCompensation && (
          <TableCell>
            {translate.t('label_include_in_training_compensation')}
          </TableCell>
        )}
        <TableCell>{translate.t('laLastEmailSubject')}</TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  );
};

export default withStyles(styles)(ParticipantTableHead);

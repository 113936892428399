import { AvatarCell, FirstNameCell, LastNameCell } from '../KendoCells';
import { Dimensions } from '../types';

export const gridColumns = () => [
  {
    cell: AvatarCell,
    field: 'empId',
    show: true,
    title: 'laPicture',
    groupable: false,
  },
  {
    cell: FirstNameCell,
    field: 'empFirstName',
    show: true,
    title: 'laFirstName',
  },
  {
    cell: LastNameCell,
    field: 'empLastName',
    show: true,
    title: 'laLastName',
  },
  {
    field: 'empNumber',
    show: true,
    title: 'laEmpId',
  },
  {
    field: 'empPosition',
    show: true,
    title: 'laPosition',
  },
  {
    field: 'empUnitName',
    show: true,
    title: 'laOrgUnit',
  },
  {
    field: 'empUnitNumber',
    show: true,
    title: 'laUnitNumber',
  },
  {
    field: 'country',
    show: true,
    title: 'laCountry',
  },
  {
    field: 'legalEntityCode',
    show: true,
    title: 'laLegalEntityCode',
  },
  {
    field: 'legalEntityName',
    show: true,
    title: 'laLegalEntityName',
  },
  {
    field: 'empPersonnelGroup1',
    show: true,
    title: 'fPersonnelGroup1',
  },
  {
    field: 'empPersonnelGroup2',
    show: true,
    title: 'fPersonnelGroup2',
  },
  {
    field: 'empPersonnelGroup3',
    show: true,
    title: 'fPersonnelGroup3',
  },
  {
    field: 'empPersonnelGroup4',
    show: true,
    title: 'fPersonnelGroup4',
  },
  {
    field: 'empPersonnelGroup5',
    show: true,
    title: 'fPersonnelGroup5',
  },
  {
    field: 'date',
    show: true,
    title: 'laDate',
  },
  {
    field: 'reportingType',
    show: true,
    title: 'laReportingType',
  },
  {
    field: 'start',
    show: true,
    title: 'laStart',
  },
  {
    field: 'end',
    show: true,
    title: 'laEnd',
  },
  {
    field: 'time',
    show: true,
    title: 'laTime',
  },
];

export const gridColumnsDimensions = (dimensions: Dimensions) => [
  {
    cell: AvatarCell,
    field: 'empId',
    show: true,
    title: 'laPicture',
    groupable: false,
  },
  {
    cell: FirstNameCell,
    field: 'empFirstName',
    show: true,
    title: 'laFirstName',
  },
  {
    cell: LastNameCell,
    field: 'empLastName',
    show: true,
    title: 'laLastName',
  },
  {
    field: 'empNumber',
    show: false,
    title: 'laEmpId',
  },
  {
    field: 'empUnitName',
    show: false,
    title: 'laOrgUnit',
  },
  {
    field: 'empUnitNumber',
    show: false,
    title: 'laUnitNumber',
  },
  {
    field: 'legalEntityCode',
    show: false,
    title: 'laLegalEntityCode',
  },
  {
    field: 'legalEntityName',
    show: false,
    title: 'laLegalEntityName',
  },
  {
    field: 'fEmpCostCenterName',
    show: false,
    title: 'laCostCenterCode',
  },
  {
    field: 'fEmpCostCenterCode',
    show: false,
    title: 'laCostCenter',
  },
  {
    field: 'date',
    show: dimensions.date,
    title: 'laDate',
  },
  {
    field: 'reportingType',
    show: dimensions.reportingType,
    title: 'laReportingType',
  },
  {
    field: 'sum',
    show: true,
    title: 'sum',
  },
];

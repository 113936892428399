import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Dispatch } from 'redux';
import { clearFields, reset, reduxForm, getFormError } from 'redux-form';

import { Grid, MenuItem, Select } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';

import { getFormDialog } from '@/app/components/FormDialog/FormDialog';
import FormFields from '@/app/components/FormFields/FormFields';
import { LanguagesType } from '@/app/redux/languages';
import { ReducerState } from '@/app/redux/store';
import { QuickLink } from '@/app/redux/quickLinks';
import { getCurrentLanguage, INPUT_TYPE } from '@/app/utils/helper';
import translate from '@/app/utils/translate';

import { mapTranslationToQuickLinkField } from '../utils';
import translateQuickLinkDialogStyle from './translateQuickLinkDialogStyle';

const FORM_NAME = 'translateQuickLinkForm';
const FORM_FIELD_NAME_DIVIDER = '-';

const mapQuickLinkToTranslationForm = (
  quickLink: QuickLink,
  languages: LanguagesType[],
  fieldNameDivider: string,
) => {
  if (!quickLink) {
    return {};
  }

  return languages.reduce(
    (acc: object, lang) => {
      const foundTr = quickLink.translations.find(
        (tr) => tr.language === lang.code,
      );

      return {
        ...acc,
        [`${lang.code}${fieldNameDivider}name`]: (foundTr && foundTr.name) || '',
        [`${lang.code}${fieldNameDivider}description`]: (foundTr && foundTr.description) || '',
      };
    },
    {}
  );
};

type MapStateToProps = {
  languages: LanguagesType[];
};

type MapDispatchToProps = {
  clearFormFields: () => void;
  resetForm: () => void;
  untouchField: (field: string) => void;
};

type OwnProps = {
  title: string;
  open: boolean;
  edit: boolean;
  selectedItem: QuickLink;
  onClose: () => void;
  onSubmit: (updatedQuickLink: QuickLink) => void;
};

type PropsType = OwnProps &
  WithStyles<typeof translateQuickLinkDialogStyle> &
  MapDispatchToProps &
  MapStateToProps;

let FormDialog: ReturnType<typeof getFormDialog>;

const validate = (values: object, props: any) => {
  const errors = {};
  const translatedLanguages = props.languages.filter((lang: any) => {
    const nameFieldName = `${lang.code}${FORM_FIELD_NAME_DIVIDER}name`;
    const descriptionFieldName = `${lang.code}${FORM_FIELD_NAME_DIVIDER}description`;
    return values[nameFieldName] || values[descriptionFieldName];
  });
  if (translatedLanguages.length === 0) {
    props.languages.forEach((lang: any) => {
      const nameFieldName = `${lang.code}${FORM_FIELD_NAME_DIVIDER}name`;
      errors[nameFieldName] = translate.t('laRequired');
    });
  }
  translatedLanguages.forEach((lang: any) => {
    const nameFieldName = `${lang.code}${FORM_FIELD_NAME_DIVIDER}name`;
    const nameFieldVal = values[nameFieldName];
    if (!nameFieldVal) {
      errors[nameFieldName] = translate.t('laRequired');
    } else if (nameFieldVal.length < 2) {
      errors[nameFieldName] = translate.t('laQuickLinksNameLength');
    }
  });

  return errors;
};

const TranslateQuickLinkDialog: React.FC<PropsType> = (props) => {
  const {
    classes,
    open,
    onClose,
    onSubmit,
    resetForm,
    languages,
    selectedItem,
    title,
  } = props;

  const [
    visibleTranslationFormIndex,
    setVisibleTranslationFormIndex,
  ] = React.useState(0);
  const [formData, setFormData] = React.useState<Partial<QuickLink>>({});
  const currentLanguage = getCurrentLanguage();
  const handleActiveTranslationChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setVisibleTranslationFormIndex(parseInt(event.target.value, 10));
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleSubmit = (formValues: object) => {
    const updatedQuickLink = {
      ...selectedItem,
      translations: mapTranslationToQuickLinkField(
        selectedItem,
        formValues,
        languages,
        FORM_FIELD_NAME_DIVIDER,
      ),
    };

    onSubmit(updatedQuickLink);
  };

  const renderFields = (lang: string) => {
    const fields = [
      {
        type: INPUT_TYPE.TEXT,
        code: `${lang}${FORM_FIELD_NAME_DIVIDER}name`,
        name: `${lang}${FORM_FIELD_NAME_DIVIDER}name`,
        label: translate.t('table_col_link_name'),
        order: 1,
      },
      {
        type: INPUT_TYPE.TEXTAREA,
        code: `${lang}${FORM_FIELD_NAME_DIVIDER}description`,
        name: `${lang}${FORM_FIELD_NAME_DIVIDER}description`,
        label: translate.t('table_col_description'),
        multiline: true,
        order: 2,
      },
    ];

    return fields.map((field) => (
      <FormFields inputs={[field]} key={`${lang}-${field.name}`} />
    ));
  };

  const renderLangSections = () => (
    <>
      <Select
        id="demo-simple-select"
        value={visibleTranslationFormIndex}
        onChange={handleActiveTranslationChange}
      >
        {languages.map((lang, index) => (
          <MenuItem value={index} key={`${lang.name}-${index}`}>
            {lang.name}
          </MenuItem>
        ))}
      </Select>
      {languages.map((lang, index) => (
        <Grid
          hidden={visibleTranslationFormIndex !== index}
          className={classes.grid}
          key={`tab-content-${lang.code}`}
        >
          {renderFields(lang.code)}
        </Grid>
      ))}
    </>
  );

  React.useEffect(
    () => {
      const data = mapQuickLinkToTranslationForm(
        selectedItem,
        languages,
        FORM_FIELD_NAME_DIVIDER,
      );

      FormDialog = getFormDialog(FORM_NAME);
      setFormData(data);

      const activeIndex = languages.findIndex(
        (lang) => lang.code === currentLanguage,
      );
      setVisibleTranslationFormIndex(activeIndex);
    },
    [selectedItem, languages, FORM_FIELD_NAME_DIVIDER]
  );

  if (!FormDialog) {
    return <></>;
  }

  return (
    <FormDialog
      title={title}
      open={open}
      onClose={handleClose}
      formData={formData}
      paperWidthMd={classes.paperWidthMd}
      onSubmit={handleSubmit}
      preventConfirmDialog
    >
      <Grid container justify="space-between" direction="column">
        {renderLangSections()}
      </Grid>
    </FormDialog>
  );
};

const mapStateToProps = (state: ReducerState) => ({
  languages: state.languages.get('allLanguages'),
  errors: getFormError(FORM_NAME)(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearFormFields: () => dispatch(clearFields(FORM_NAME, false, false)),
  resetForm: () => dispatch(reset(FORM_NAME)),
});

const enhance = compose<any, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME,
    validate,
  }),
  withStyles(translateQuickLinkDialogStyle, { withTheme: true }),
);

export default enhance(TranslateQuickLinkDialog);

import {
  WorkflowReportTypes,
  WorkflowsReportDataRow,
  WorkflowsReportDataRowWithHistory,
} from '@/app/components/WorkflowsReport/ReportTypes';
import { useMemo } from 'react';
import { CommonWorkflowReportHistoryData } from '@/app/components/WorkflowsReport/ReportTypes/Common';
import { WorkflowsReportSettingsConfigShowDetails } from '@/app/components/WorkflowsReport/useWorkflowsReportSettings';
import translate from '@/app/utils/translate';
import moment from 'moment';

type PropsType = {
  reportType: WorkflowReportTypes;
  workflowsData: WorkflowsReportDataRow[];
  showDetails: WorkflowsReportSettingsConfigShowDetails;
};

export type UseWorkflowsReportExcelExportOutput = {
  gridData: WorkflowsReportDataRowWithHistory[];
  excelData: {
    fileName: string;
    history: WorkflowsReportDataRow[];
    current: WorkflowsReportDataRow[];
  };
};

export const useWorkflowsReportExcelAndGridData = (
  props: PropsType,
): UseWorkflowsReportExcelExportOutput => {
  const { workflowsData, showDetails, reportType } = props;

  // Unique workflows on report data
  const workflowIds: string[] = useMemo(() => {
    return workflowsData
      .map(dataRow => dataRow.workflowId)
      .filter((id, index, array) => array.indexOf(id) === index);
  }, [workflowsData]);

  // Convert all report entries into latest entry with status history
  const latestEntriesWithHistory: WorkflowsReportDataRowWithHistory[] = useMemo(() => {
    return workflowIds.map(wfId => {
      // Workflow related entries, latest first
      const workflowEntries = workflowsData
        .filter(dataRow => dataRow.workflowId === wfId)
        .sort((a, b) =>
          a.updatedDate === b.updatedDate
            ? 0
            : a.updatedDate < b.updatedDate
            ? 1
            : -1,
        );
      const latestEntry = workflowEntries[0];

      // Workflow entry status history
      const entryHistory: CommonWorkflowReportHistoryData = workflowEntries.reduce(
        (history, entry) => {
          return {
            statusHistory: [...history.statusHistory, entry.status],
            updatedDateHistory: [
              ...history.updatedDateHistory,
              entry.updatedDate,
            ],
            approvedByHistory: [...history.approvedByHistory, entry.approvedBy],
          };
        },
        {
          statusHistory: [],
          updatedDateHistory: [],
          approvedByHistory: [],
        },
      );

      // Return latest entry info
      return {
        ...latestEntry,
        ...entryHistory,
      };
    });
  }, [workflowIds, workflowsData]);

  // Remove history props for latest entries for Excel export
  const latestEntriesForExcel = useMemo(
    () =>
      latestEntriesWithHistory.map(entry => {
        const {
          statusHistory,
          updatedDateHistory,
          approvedByHistory,
          ...rest
        } = entry;
        return rest;
      }),
    [latestEntriesWithHistory],
  );

  const excelFileName = useMemo(() => {
    const date = moment().format('YYYYMMDD');

    const state = {
      open: translate.t('laOpenWorkflowsReport'),
      closed: translate.t('laClosedWorkflowsReport'),
    };

    const generateFilename = (type: string, typeState: string) => {
      return `${type}_${typeState}_${moment().format('YYYYMMDD')}`
        .replace(/[^a-zA-Z0-9_-]/gi, '_')
        .replace(/_{2,}/g, '_');
    };

    switch (reportType) {
      case WorkflowReportTypes.EmployeeDetailsClosed:
        return generateFilename(
          translate.t('laOpenWorkflowsReportEmpDetails'),
          state.closed,
        );
      case WorkflowReportTypes.EmployeeDetailsOpen:
        return generateFilename(
          translate.t('laOpenWorkflowsReportEmpDetails'),
          state.open,
        );
      case WorkflowReportTypes.InternalPositionClosed:
        return generateFilename(
          translate.t('laOpenWorkflowsReportIntPosition'),
          state.closed,
        );
      case WorkflowReportTypes.InternalPositionOpen:
        return generateFilename(
          translate.t('laOpenWorkflowsReportIntPosition'),
          state.open,
        );
      case WorkflowReportTypes.RewardingClosed:
        return generateFilename(translate.t('laRewarding'), state.closed);
      case WorkflowReportTypes.RewardingOpen:
        return generateFilename(translate.t('laRewarding'), state.open);
      default:
        return `unknown_report_${date}`;
    }
  }, [reportType, translate]);

  // Include showHistory status for grid data
  const gridData = latestEntriesWithHistory.map(entry => {
    return {
      ...entry,
      showHistory: showDetails.history,
      showChanges: showDetails.changes,
    };
  });

  return {
    gridData,
    excelData: {
      fileName: excelFileName,
      history: workflowsData,
      current: latestEntriesForExcel,
    },
  };
};

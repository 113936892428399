import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    actionBar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    downloadButton: {
      position: 'absolute',
      right: '0px',
    },
  });

export default styles;

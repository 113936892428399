import { HoursSettingsActionTypes } from './data';

export interface CountryHoursSettings {
    countryCode: string;
    workDayHours: string;
    maxCumulativeHours: string;
    minCumulativeHours: string;
    blockAfterDays: number;
}

export interface HoursSettingsStoreState {
    loading: boolean;
    error: boolean;
    hasCustomHours: boolean;
    companyDefaultHours?: Omit<CountryHoursSettings, 'countryCode'>;
    selectedCountryCustomHours?: CountryHoursSettings;
}

export const hoursSettingsReducer = (
    state: HoursSettingsStoreState,
    action: any,
): HoursSettingsStoreState => {
    switch (action.type) {
        case HoursSettingsActionTypes.PUT_COUNTRY_DEFAULT_HOURS_PENDING:
            return {
                ...state,
                loading: true,
            };
        case HoursSettingsActionTypes.PUT_COUNTRY_DEFAULT_HOURS_FULFILLED:
            return {
                ...state,
                loading: false,
                error: false,
                companyDefaultHours: {
                    workDayHours: action.payload.fComTmWorkdayLengthHours,
                    maxCumulativeHours: action.payload.fComTmMaxCumulativeHours,
                    minCumulativeHours: action.payload.fComTmMinCumulativeHours,
                    blockAfterDays: action.payload.fComTmPossibleEditingDays,
                },
                selectedCountryCustomHours: state.hasCustomHours ? state.selectedCountryCustomHours : {
                    ...state.selectedCountryCustomHours,
                    workDayHours: action.payload.fComTmWorkdayLengthHours,
                    maxCumulativeHours: action.payload.fComTmMaxCumulativeHours,
                    minCumulativeHours: action.payload.fComTmMinCumulativeHours,
                    blockAfterDays: action.payload.fComTmPossibleEditingDays,
                }
            };
        case HoursSettingsActionTypes.PUT_COUNTRY_DEFAULT_HOURS_REJECTED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case HoursSettingsActionTypes.GET_COUNTRY_HOURS_CUSTOM_HOURS_PENDING:
            return {
                ...state,
                loading: true,
            };
        case HoursSettingsActionTypes.GET_COUNTRY_HOURS_CUSTOM_HOURS_FULFILLED:
            return {
                ...state,
                loading: false,
                error: false,
                hasCustomHours: true,
                selectedCountryCustomHours: action.payload,
            };
        case HoursSettingsActionTypes.GET_COUNTRY_HOURS_CUSTOM_HOURS_REJECTED:
            if (action.error.status === 404) {
                return {
                    ...state,
                    loading: false,
                    hasCustomHours: false,
                    selectedCountryCustomHours: {
                        countryCode: action.meta,
                        ...state.companyDefaultHours
                    }
                };
            }

            return {
                ...state,
                loading: false,
                error: true,
            };
        case HoursSettingsActionTypes.POST_COUNTRY_HOURS_CUSTOM_HOURS_PENDING:
            return {
                ...state,
                loading: true,
            };
        case HoursSettingsActionTypes.POST_COUNTRY_HOURS_CUSTOM_HOURS_FULFILLED:
            return {
                ...state,
                loading: false,
                error: false,
                hasCustomHours: true,
                selectedCountryCustomHours: {
                    ...state.selectedCountryCustomHours,
                    ...action.payload
                },
            };
        case HoursSettingsActionTypes.POST_COUNTRY_HOURS_CUSTOM_HOURS_REJECTED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case HoursSettingsActionTypes.PUT_COUNTRY_CUSTOM_HOURS_PENDING:
            return {
                ...state,
                loading: true,
            };
        case HoursSettingsActionTypes.PUT_COUNTRY_CUSTOM_HOURS_FULFILLED:
            return {
                ...state,
                loading: false,
                error: false,
                selectedCountryCustomHours: {
                    ...state.selectedCountryCustomHours,
                    ...action.payload
                },
            };
        case HoursSettingsActionTypes.PUT_COUNTRY_CUSTOM_HOURS_REJECTED:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};

import React from 'react';
import { Icon, Theme, StyleRulesCallback, WithStyles, withStyles } from '@material-ui/core';
import { green, red, orange } from '@material-ui/core/colors';

import { REGISTRATION_STATUS } from '../../../types';

const styles: StyleRulesCallback = (theme: Theme) => ({
  accepted: {
    color: green[300],
    marginRight: theme.spacing.unit / 2,
  },
  rejected: {
    color: red[300],
    marginRight: theme.spacing.unit / 2,
  },
  waiting: {
    color: orange[300],
    marginRight: theme.spacing.unit / 2,
  },
  notRegistered: {
    color: theme.palette.grey[300],
    marginRight: theme.spacing.unit / 2,
  },
});

type OwnProps = {
  registrationStatus: REGISTRATION_STATUS;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const RegistrationStatusIcon = ({
  classes,
  registrationStatus,
}: Props) => {
  let icon, className;

  switch (registrationStatus) {
    case REGISTRATION_STATUS.NOT_REGISTERED:
      icon = 'not_interested';
      className = classes.notRegistered;
      break;
    case REGISTRATION_STATUS.CANCELLED:
    case REGISTRATION_STATUS.REJECTED:
    case REGISTRATION_STATUS.NO_SEAT:
      icon = 'cancel';
      className = classes.rejected;
      break;
    case REGISTRATION_STATUS.WAITING_FOR_APPROVAL:
    case REGISTRATION_STATUS.WAITING_FOR_SEAT:
      icon = 'how_to_reg';
      className = classes.waiting;
      break;
    default:  // Accepted by default
      icon = 'check';
      className = classes.accepted;
  }

  return <Icon className={className}>{icon}</Icon>;
};

export default withStyles(styles)(RegistrationStatusIcon);

import { CompanyTrainingParticipantRegistration, APPROVAL_STATUS, CompanyTraining } from '../types';
import sortByDate from './sortByDate';
import getTrainingSeatStatus from './getTrainingSeatStatus';

const sortParticipant = (a: CompanyTrainingParticipantRegistration, b: CompanyTrainingParticipantRegistration) => {
  // Handle case where both are rejected or cancelled and sort them only by registration date
  if (
    (a.approvalStatus.status.value === APPROVAL_STATUS.REJECTED || a.approvalStatus.status.value === APPROVAL_STATUS.CANCELLED) &&
    (b.approvalStatus.status.value === APPROVAL_STATUS.REJECTED || b.approvalStatus.status.value === APPROVAL_STATUS.CANCELLED)
  ) {
    return sortByDate(a.registeredTime, b.registeredTime);
  }

  // Handle simple cases where other item is rejected or cancelled
  if (a.approvalStatus.status.value === APPROVAL_STATUS.REJECTED || a.approvalStatus.status.value === APPROVAL_STATUS.CANCELLED) {
    return 1;
  }
  if (b.approvalStatus.status.value === APPROVAL_STATUS.REJECTED || b.approvalStatus.status.value === APPROVAL_STATUS.CANCELLED) {
    return -1;
  }

  // Handle guaranteed seat before non-guaranteed seat
  if (!!a.seated && !b.seated) {
    return -1;
  }
  if (!!b.seated && !a.seated) {
    return 1;
  }

  // Rest cases go just by registration date
  return sortByDate(a.registeredTime, b.registeredTime);
};

export default (training: CompanyTraining, participants: CompanyTrainingParticipantRegistration[]): CompanyTrainingParticipantRegistration[] => {
  return participants.sort(sortParticipant)
    .map((item: CompanyTrainingParticipantRegistration, ndx: number) => ({
      ...item,
      position: ndx + 1,
      seatStatus: getTrainingSeatStatus(item.approvalStatus.status.value, ndx + 1, training.seats, training.allowWaitingSeats),
    }));
};

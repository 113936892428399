import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  withStyles,
  WithStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  IconButton,
  Tooltip,
  Typography,
  Modal,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import translate from '@/app/utils/translate';
import { ReducerState } from '@/app/redux/store';
import SortableHOC from '../../SortableHOC/SortableHOC';
import SectionTopToolbar from '../SectionTopToolbar/SectionTopToolbar';
import componentListingStyles from './componentListingStyles';
import AddOtherComponent from '../AddComponent/AddOtherComponent/AddOtherComponent';
import AddGoalComponent from '../AddComponent/AddGoalComponent';
import {
  ComponentType,
  componentTypeTranslationKeys,
} from '../AddComponent/constants';

type IncomingProps = {
  template: any;
  section: any;
  addComponent: any;
  updateComponent: any;
  deleteComponent: any;
  orderComponents: any;
};

type MapStateToProps = {
  allCommonGoals: any;
};

type PropsType = IncomingProps &
  MapStateToProps &
  WithStyles<typeof componentListingStyles>;

type StateType = {
  allCheckboxesSelected: boolean;
  selectedCheckboxes: any[];
  rowCount: number;
  openAddModal: boolean;
  componentBeingEdited: any;
  openComponentSort: boolean;
  componentType: string;
};

class ComponentListing extends React.PureComponent<PropsType> {
  state: StateType = {
    allCheckboxesSelected: false,
    selectedCheckboxes: [],
    rowCount: -1,
    openAddModal: false,
    componentBeingEdited: false,
    openComponentSort: false,
    componentType: '',
  };

  constructor(props: PropsType) {
    super(props);

    this.state = {
      allCheckboxesSelected: false,
      selectedCheckboxes: [],
      rowCount: props.section.components.length,
      openAddModal: false,
      componentBeingEdited: false,
      openComponentSort: false,
      componentType: '',
    };
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // Update rows count
    if (
      prevProps.section.components.length !==
      this.props.section.components.length
    ) {
      this.setState({ rowCount: this.props.section.components.length });
    }

    // Reset selection on component delete/create
    if (
      prevProps.section.components.length !==
        this.props.section.components.length &&
      prevState.rowCount !== -1
    ) {
      this.setState({
        selectedCheckboxes: [],
        componentBeingEdited: false,
        allCheckboxesSelected: false,
      });
    }
  }

  onSelectComponentClick = (id: string) => {
    const nextSelectedRows = this.state.selectedCheckboxes.includes(id)
      ? this.state.selectedCheckboxes.filter(row => row !== id)
      : [...this.state.selectedCheckboxes, id];

    this.setState({
      selectedCheckboxes: nextSelectedRows,
      allCheckboxesSelected: nextSelectedRows.length === this.state.rowCount,
    });
  };

  onSelectAllComponentsClick = () => {
    this.setState({
      allCheckboxesSelected: !this.state.allCheckboxesSelected,
      selectedCheckboxes: !this.state.allCheckboxesSelected
        ? this.props.section.components.map(({ id }: any) => id)
        : [],
    });
  };

  openAddComponentModal = (componentType: string) => {
    this.setState({
      openAddModal: true,
      componentType,
    });
  };

  closeAddComponentModal = () => {
    this.setState({ openAddModal: false, componentBeingEdited: false });
  };

  handleEditComponent = (selectedComponentIds?: any[]) => {
    const [firstSelectedComponentId] =
      selectedComponentIds || this.state.selectedCheckboxes;
    const componentBeingEdited: any = this.props.section.components.find(
      (component: any) => {
        return component.id === firstSelectedComponentId;
      },
    );
    this.setState({
      componentBeingEdited,
      openAddModal: true,
      componentType: componentBeingEdited.type,
    });
  };

  openSortingModal = () => {
    this.setState({ openComponentSort: true });
  };

  closeSortingModal = () => {
    this.setState({ openComponentSort: false });
  };

  saveNewOrder = (newOrder: []) => {
    const { template, section, orderComponents } = this.props;

    orderComponents(
      template.id,
      section.id,
      newOrder.map((component: any, i: number) => {
        return { ...component, index: i + 1 };
      }),
    );

    this.closeSortingModal();
  };

  handleDeleteSelectedComponents = () => {
    this.props.deleteComponent(
      this.props.template.id,
      this.props.section.id,
      this.state.selectedCheckboxes,
    );
  };

  getComponentLabel = (component: any) => {
    try {
      if (component.type === ComponentType.GOAL || !component.type) {
        const commonGoal = this.props.allCommonGoals[component.enum.groupFK];
        const successMeasure = commonGoal.subgroupValues[component.enum.code];

        return `${commonGoal.name}, ${successMeasure.name}`;
      } else {
        return `${component.name}`;
      }
    } catch (e) {
      return component.enum.description;
    }
  };

  render() {
    const {
      classes,
      template,
      section,
      addComponent,
      updateComponent,
      deleteComponent,
    } = this.props;

    const {
      allCheckboxesSelected,
      selectedCheckboxes,
      openAddModal,
      componentBeingEdited,
      openComponentSort,
      componentType,
    } = this.state;
    return (
      <>
        <div className={classes.root}>
          <div className={classes.header}>
            <SectionTopToolbar
              section={section}
              selectedComponentIds={selectedCheckboxes}
              sortComponents={this.openSortingModal}
              addComponent={this.openAddComponentModal}
              editComponent={this.handleEditComponent}
              deleteComponent={this.handleDeleteSelectedComponents}
            />
          </div>

          {section.components.length > 0 ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      indeterminate={
                        !allCheckboxesSelected && selectedCheckboxes.length > 0
                      }
                      checked={allCheckboxesSelected}
                      onChange={this.onSelectAllComponentsClick}
                      className={classes.topCheckbox}
                    />
                    {translate.t('item_type')}
                  </TableCell>
                  <TableCell align="left">
                    {translate.t('laDescription')}
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {section.components.map((component: any) => {
                  return (
                    <TableRow key={component.id} hover role="checkbox">
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={() =>
                            this.onSelectComponentClick(component.id)
                          }
                          checked={selectedCheckboxes.includes(component.id)}
                        />
                        {component.type
                          ? translate.t(
                              componentTypeTranslationKeys[component.type],
                            )
                          : translate.t('laGoal')}
                      </TableCell>

                      <TableCell
                        className={`${classes.multiline} ${classes.longText}`}
                      >
                        {this.getComponentLabel(component)}
                      </TableCell>

                      <TableCell align="right">
                        <Tooltip title={translate.t('laEdit')}>
                          <IconButton
                            onClick={() =>
                              this.handleEditComponent([component.id])
                            }
                          >
                            <EditIcon color="primary" />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title={translate.t('laDelete')}>
                          <IconButton
                            onClick={() =>
                              deleteComponent(template.id, section.id, [
                                component.id,
                              ])
                            }
                          >
                            <DeleteIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          ) : (
            <Typography variant="h6">{translate.t('no_components')}</Typography>
          )}
        </div>
        <Modal open={openAddModal} onClose={this.closeAddComponentModal}>
          {componentType === ComponentType.GOAL ||
          (componentBeingEdited && !componentBeingEdited.type) ? (
            <AddGoalComponent
              template={template}
              section={section}
              addComponent={addComponent}
              componentBeingEdited={componentBeingEdited}
              updateComponent={updateComponent}
              componentType={componentType}
              handleModalClose={this.closeAddComponentModal}
            />
          ) : (
            <AddOtherComponent
              template={template}
              section={section}
              component={componentBeingEdited ? componentBeingEdited : false}
              componentType={componentType}
              onComponentAdd={(c: any) => {
                addComponent(template.id, section.id, {
                  ...c,
                  index: section.components ? section.components.length + 1 : 1,
                });
                this.closeAddComponentModal();
              }}
              onComponentUpdate={(c: any) => {
                updateComponent(template.id, section.id, c);
                this.closeAddComponentModal();
              }}
              onCancel={this.closeAddComponentModal}
            />
          )}
        </Modal>

        <SortableHOC
          open={openComponentSort}
          getItemValue={this.getComponentLabel}
          itemsToSort={section.components}
          saveNewOrder={this.saveNewOrder}
          cancelNewOrder={this.closeSortingModal}
        />
      </>
    );
  }
}

const mapStateToProps = (state: ReducerState) => ({
  allCommonGoals: state.goals.allCommonGoals,
});

const enhance = compose<any, any>(
  connect(mapStateToProps),
  withStyles(componentListingStyles),
);

export default enhance(ComponentListing);

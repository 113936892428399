import React, { useState, useEffect } from 'react';
import { Button, Icon, StyleRulesCallback, Theme, WithStyles, withStyles } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { ICalEvent, buildUrl, isIOSSafari, downloadBlob } from './iCalButton/utils';
import { IconProps } from '@material-ui/core/Icon';
import translate from '@/app/utils/translate';

const styles: StyleRulesCallback = (theme: Theme) => ({
  leftIcon: {
    marginRight: theme.spacing.unit / 2,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit / 2,
  },
});

type OwnProps = {
  event: ICalEvent;
  label: string;
  filename?: string;
  iconName?: string;
  iconPosition?: 'left' | 'right',
  iconProps?: IconProps;
};
type InnerProps = ButtonProps & WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const ICalButton = ({
  classes,
  label,
  event,
  filename = `${translate.t('label_ical_filename')}.ics`,
  iconName = '',
  iconPosition = 'left',
  iconProps: {
    className: iconClassName,
    ...iconPropsRest
  },
  ...rest
}: Props) => {
  const [isCrappyIE, setIsCrappyIE] = useState<boolean>();

  useEffect(
    () => {
      setIsCrappyIE(!!(
        typeof window !== 'undefined' &&
        window.navigator.msSaveOrOpenBlob &&
        window.Blob
      ));
    },
    []
  );

  const handleDownloadEventFile = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const url: string = buildUrl(event, isIOSSafari());
    const blob: object = new Blob([url], {
      type: 'text/calendar;charset=utf-8',
    });

    // IE
    if (isCrappyIE) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    }

    // Safari
    if (isIOSSafari()) {
      window.open(url, '_blank');
    }

    // Desktop
    downloadBlob(blob, filename);
  };

  const icon = iconName === '' ? null : (
    <Icon
      className={[iconPosition === 'left' ? classes.leftIcon : classes.rightIcon, iconClassName].join(' ')}
      {...iconPropsRest}
    >
      {iconName}
    </Icon>
  );

  return (
    <Button
      onClick={handleDownloadEventFile}
      {...rest}
    >
      {iconPosition === 'left' && icon}
      {label}
      {iconPosition === 'right' && icon}
    </Button>
  );
};

export default withStyles(styles)(ICalButton);

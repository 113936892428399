import expressions from 'angular-expressions';
import DOCXTemplater from 'docxtemplater';
import { saveAs } from 'file-saver';
import FileType from 'file-type';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils';

expressions.filters.lower = (input: string) => {
  if (!input) {
    return input;
  }

  return input.toLowerCase();
};

expressions.filters.sumby = function(input: unknown, field: string) {
  if (!(input instanceof Array)) {
    return input;
  }

  return input.reduce(
    (sum: number, object: object) => sum + (+object[field] || 0),
    0
  );
};

const angularParser = (tag: string) => {
  if (tag === '.') {
    return {
      get: (s: any) => s,
    };
  }

  const expr = expressions.compile(
    tag.replace(/(’|‘)/g, '\'').replace(/(“|”)/g, '"')
  );

  return {
    get: function(scope: any, context: any) {
      let obj = {};
      for (let i = 0, len = context.num + 1; i < len; i++) {
        obj = (window as any)._.assign(obj, context.scopeList[i]);
      }
      return expr(scope, obj);
    }
  };
};

export const replacePlaceholdersIntoDoc = async (url: string, placeholders: {}, outputFileName: string) => {
  return new Promise((resolve, reject) => {
    PizZipUtils.getBinaryContent(url, async (error: any, content: any) => {
      if (error) {
        return reject(new Error('Failed to read file.'));
      }

      try {
        if ((await FileType.fromBuffer(content) || {}).ext !== 'docx') {
          return reject(new Error('File is not of a valid type.'));
        }

        const zip = new PizZip(content);
        const doc = new DOCXTemplater(zip, {
          parser: angularParser,
          nullGetter: () => '',
          paragraphLoop: true,
        });

        doc.setData(placeholders);
        doc.render();

        const out = doc.getZip().generate({
          type: 'blob',
          mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        });

        saveAs(out, `${outputFileName}.docx`);

        return resolve();
      } catch (error) {
        return reject(new Error('Failed to parse file.'));
      }
    });
  });
};

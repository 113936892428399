import * as React from 'react';
import CKEditor from 'react-ckeditor-component';
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormHelperText
} from '@material-ui/core';
import { DEFAULT_LANG, getCurrentLanguage } from '@/app/utils/helper';

const RenderRichTextField = (props: any) => {
  const {
    input,
    label,
    required,
    meta: { touched, error },
    helpText,
    ...custom
  } = props;

  const currentLanguage = getCurrentLanguage();

  const hasError = touched && !!error;
  return (
    <FormControl
      margin="normal"
      fullWidth
      error={hasError}
      aria-describedby={`${input.name}-error-text`}
    >
      <FormLabel
        required={required}
      >
        {label}
      </FormLabel>
      <FormGroup>
        <CKEditor
          {...input}
          {...custom}
          activeClass="p10"
          content={input.value}
          events={{
            blur: (event: any) => input.onBlur(event.editor.getData()),
            change: (event: any) => input.onChange(event.editor.getData())
          }}
          config={{
            toolbar: [
              ['Format', 'FontSize', 'RemoveFormat'],
              ['Cut', 'Copy', 'Paste'],
              ['Bold', 'Italic', 'Underline'],
              ['TextColor', 'BGColor'],
              ['NumberedList', 'BulletedList', 'Outdent', 'Indent'],
              ['Link', 'Unlink'],
              ['Image', 'Table', 'HorizontalRule']
            ],
            defaultLanguage: DEFAULT_LANG,
            language: currentLanguage,
            baseFloatZIndex: 10401
          }}
        />
        {hasError ? <FormHelperText id={`${input.name}-error-text`}>{error}</FormHelperText> : null}
        {helpText}
      </FormGroup>
    </FormControl>

  );
};

export default RenderRichTextField;

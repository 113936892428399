import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const enhancedTableStyle: StyleRulesCallback = (theme: Theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 200,
  },
  tablecell: {
    fontSize: '0.9rem',
  },
  scrollableCell: {
    maxHeight: '77px',
    minHeight: '48px',
    display: 'block',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  srollableArrayCell: {
    marginBottom: '1px',
    marginTop: '1px',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  paginationCaption: {
    fontSize: '0.9rem',
  },
  paginationSelect: {
    fontSize: '0.9rem',
    paddingRight: '20px',
  },
  paginationSelectIcon: {
    paddingBottom: '5px',
  },
  inactive: {
    background: '#DDDDDD',
  },
});

export default enhancedTableStyle;

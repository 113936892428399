import {
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import * as React from 'react';

import translate from '@/app/utils/translate';

import {
  ComponentType,
  componentTypeTranslationKeys,
} from '@/app/components/JobEvaluation/constants';

interface PropsType {
  onComponentAdd: (componentType: ComponentType) => void;
  templateType?: string;
}

const AddComponentPicker: React.FC<PropsType> = ({ onComponentAdd }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleComponentAdd = (componentType: ComponentType) => {
    onComponentAdd(componentType);
    handleCloseMenu();
  };

  return (
    <>
      <Tooltip title={translate.t('laAdd')}>
        <IconButton onClick={handleOpenMenu}>
          <AddCircleIcon color="primary" />
        </IconButton>
      </Tooltip>

      <Menu
        id="component-type-picker-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleCloseMenu}
      >
        {Object.keys(ComponentType).map((componentType: ComponentType) => {
          return (
            <MenuItem
              key={componentType}
              onClick={() => handleComponentAdd(componentType)}
            >
              <ListItemText
                inset={false}
                primary={translate.t(
                  componentTypeTranslationKeys[componentType],
                )}
              />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default AddComponentPicker;

import { getActiveEnumNameByCode } from '@/app/utils/helper';
import { getCountryName } from '../Trainings/helpers';
import {
  WorkingHoursSummaryReportResponseItem,
  WorkingHoursSummaryReportEntry,
} from './types';

export const mapReportForUI = (
  workingHoursSummaryReportResponseItems: WorkingHoursSummaryReportResponseItem[],
): WorkingHoursSummaryReportEntry[] => {
  const reportItemsWithTimeBlocks: WorkingHoursSummaryReportResponseItem[] = workingHoursSummaryReportResponseItems.filter(
    workingHoursSummaryReportResponseItem =>
      workingHoursSummaryReportResponseItem.fEmpTimeCards &&
      workingHoursSummaryReportResponseItem.fEmpTimeCards.length > 0,
  );

  return reportItemsWithTimeBlocks.reduce(
    (
      workingHoursSummaryReportEntriesAcc: WorkingHoursSummaryReportEntry[],
      currentWorkingHoursSummaryReportResponseItem,
    ) => [
      ...workingHoursSummaryReportEntriesAcc,
      ...currentWorkingHoursSummaryReportResponseItem.fEmpTimeCards.map(
        timeCard => {
          const entries = {
            empId: currentWorkingHoursSummaryReportResponseItem.fEmpId,
            empFirstName:
              currentWorkingHoursSummaryReportResponseItem.fEmpFirstName,
            empLastName:
              currentWorkingHoursSummaryReportResponseItem.fEmpLastName,
            empNumber: currentWorkingHoursSummaryReportResponseItem.fEmpNumber,
            empUnitName:
              currentWorkingHoursSummaryReportResponseItem.fEmpUnitName,
            empUnitNumber:
              currentWorkingHoursSummaryReportResponseItem.fEmpUnitNumber,
            country: getCountryName(
              currentWorkingHoursSummaryReportResponseItem.fEmpOfficeCountry,
            ),
            legalEntityName:
              currentWorkingHoursSummaryReportResponseItem.fEmpLegalEntityName,
            legalEntityCode:
              currentWorkingHoursSummaryReportResponseItem.fEmpLegalEntityCode,
            empPersonnelGroup1:
              currentWorkingHoursSummaryReportResponseItem.fEmpPersonnelGroups &&
              getActiveEnumNameByCode(
                'PERSONNEL_GROUP_1',
                currentWorkingHoursSummaryReportResponseItem
                  .fEmpPersonnelGroups[0],
              ),
            empPersonnelGroup2:
              currentWorkingHoursSummaryReportResponseItem.fEmpPersonnelGroups &&
              getActiveEnumNameByCode(
                'PERSONNEL_GROUP_2',
                currentWorkingHoursSummaryReportResponseItem
                  .fEmpPersonnelGroups[1],
              ),
            empPersonnelGroup3:
              currentWorkingHoursSummaryReportResponseItem.fEmpPersonnelGroups &&
              getActiveEnumNameByCode(
                'PERSONNEL_GROUP_3',
                currentWorkingHoursSummaryReportResponseItem
                  .fEmpPersonnelGroups[2],
              ),
            empPersonnelGroup4:
              currentWorkingHoursSummaryReportResponseItem.fEmpPersonnelGroups &&
              getActiveEnumNameByCode(
                'PERSONNEL_GROUP_4',
                currentWorkingHoursSummaryReportResponseItem
                  .fEmpPersonnelGroups[3],
              ),
            empPersonnelGroup5:
              currentWorkingHoursSummaryReportResponseItem.fEmpPersonnelGroups &&
              getActiveEnumNameByCode(
                'PERSONNEL_GROUP_5',
                currentWorkingHoursSummaryReportResponseItem
                  .fEmpPersonnelGroups[4],
              ),
            date: timeCard.entryDate,
            dailyBalance: timeCard.dailyBalance,
            dailyCumulativeBalance: timeCard.cumulativeBalance,
            defaultHours: timeCard.defaultHours,
            correctionTypeHours: timeCard.correctionTypeHours,
            nonWorkingTypeHours: timeCard.nonWorkingTypeHours,
            workingTypeHours: timeCard.workingTypeHours,
            fEmpHasAccount: currentWorkingHoursSummaryReportResponseItem.fEmpHasAccount
          };

          return entries;
        },
      ),
    ],
    [],
  );
};

import {
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import { Delete, Edit, Timer, TimerOff } from '@material-ui/icons';
import moment from 'moment';
import * as React from 'react';
import { ErrorMessage } from 'react-hook-form';

import { ErrorTooltip } from '@/app/components/ErrorTooltip';
import { NullableTooltip } from '@/app/components/NullableTooltip';
import { formatDurationToHMM } from '@/app/utils/helper';
import translate from '@/app/utils/translate';

import { DailySummaryRowInnerProps } from './dailySummaryRowModels';
import { useDailySummaryRow } from './useDailySummaryRow';

export const DailySummaryRow: React.FC<DailySummaryRowInnerProps> = props => {
  const {
    isToday,
    startDate,
    endDate,
    workingHours,
    nonWorkingHours,
    dailyBalance,
    momentIsFilled,
    timerIsRunning,
    timerJustStarted,
    inputDefaultWorkingHours,
    inputComment,
    handleDefaultWorkingHoursChange,
    handleCommentChange,
    handleDeleteTimeCard,
  } = useDailySummaryRow(props);

  const {
    allowEditMode,
    canManageEverything,
    classes,
    date,
    formErrors,
    inEditMode,
    isDeleting,
    isLoadingAdditionalData,
    isSaving,
    onEditModeEnter,
    onStartTimer,
    onStopTimer,
    showDailyCumulativeBalance,
    timeCard,
    timerStartIsConfigured,
  } = props;

  return (
    <Grid container={true} spacing={8} className={classes.root}>
      <Grid item={true} xs={showDailyCumulativeBalance ? 1 : 2}>
        <Typography variant="subtitle1">
          {moment(date).format('ddd D.M')}
        </Typography>
      </Grid>
      <Grid item={true} xs={1}>
        <Typography color="textSecondary">{startDate}</Typography>
      </Grid>
      <Grid item={true} xs={1}>
        <Typography color="textSecondary">{endDate}</Typography>
      </Grid>
      <Grid item={true} xs={1}>
        <Typography color="textSecondary">
          {workingHours && formatDurationToHMM(workingHours)}
        </Typography>
      </Grid>
      <Grid item={true} xs={1}>
        <Typography color="textSecondary">
          {nonWorkingHours && formatDurationToHMM(nonWorkingHours)}
        </Typography>
      </Grid>
      <Grid item={true} xs={1}>
        {(inEditMode && canManageEverything && (
          <ErrorTooltip
            placement="top"
            title={
              formErrors.defaultWorkingHours && (
                <ErrorMessage errors={formErrors} name="defaultWorkingHours" />
              )
            }
          >
            <TextField
              name="defaultWorkingHours"
              margin="normal"
              variant="outlined"
              value={inputDefaultWorkingHours || ''}
              onChange={handleDefaultWorkingHoursChange}
              error={!!formErrors.defaultWorkingHours}
              disabled={isSaving}
              fullWidth={true}
              className={classes.textInput}
              placeholder="H / H:MM"
            />
          </ErrorTooltip>
        )) || (
          <Typography color="textSecondary">
            {timeCard && formatDurationToHMM(timeCard.requiredWorkingHours)}
          </Typography>
        )}
      </Grid>
      <Grid item={true} xs={1}>
        <Typography color="textSecondary">
          {dailyBalance && formatDurationToHMM(dailyBalance)}
        </Typography>
      </Grid>
      {showDailyCumulativeBalance && (
        <Grid item={true} xs={1}>
          <Typography color="textSecondary">
            {timeCard ? formatDurationToHMM(timeCard.totalBalanceOnDate) : ''}
          </Typography>
        </Grid>
      )}
      <Grid item={true} xs={4} className={classes.actionButtonsContainer}>
        {(inEditMode && (
          <ErrorTooltip
            placement="top"
            title={
              formErrors.comment && (
                <ErrorMessage errors={formErrors} name="comment" />
              )
            }
          >
            <TextField
              name="comment"
              margin="normal"
              variant="outlined"
              value={inputComment || ''}
              onChange={handleCommentChange}
              error={!!formErrors.comment}
              disabled={isSaving}
              fullWidth={true}
              className={classes.textInput}
            />
          </ErrorTooltip>
        )) || (
          <>
            <Typography color="textSecondary">
              {timeCard && timeCard.comment}
            </Typography>

            <span>
              {allowEditMode &&
                isToday &&
                (timerIsRunning ? (
                  <IconButton
                    onClick={onStopTimer}
                    className={classes.actionButton}
                    disabled={timerJustStarted || isSaving || isDeleting}
                  >
                    {isSaving ? (
                      <CircularProgress size={20} />
                    ) : (
                      <TimerOff className={classes.actionButtonIcon} />
                    )}
                  </IconButton>
                ) : (
                  <NullableTooltip
                    title={
                      !timerStartIsConfigured
                        ? translate.t('laTimerStartIsNotConfigured')
                        : momentIsFilled
                        ? translate.t('laMomentIsFilled')
                        : ''
                    }
                  >
                    <div
                      className={classes.tooltipContainer}
                      // Wrapper is required in order to have a tooltip
                      // when Button is disabled.
                    >
                      <IconButton
                        onClick={onStartTimer}
                        className={classes.actionButton}
                        disabled={
                          !timerStartIsConfigured ||
                          momentIsFilled ||
                          !!isLoadingAdditionalData ||
                          isDeleting
                        }
                      >
                        {isLoadingAdditionalData === 'TIMER' ? (
                          <CircularProgress size={20} />
                        ) : (
                          <Timer className={classes.actionButtonIcon} />
                        )}
                      </IconButton>
                    </div>
                  </NullableTooltip>
                ))}

              {allowEditMode && (
                <IconButton
                  onClick={onEditModeEnter}
                  className={classes.actionButton}
                  disabled={!!isLoadingAdditionalData || isDeleting}
                >
                  {isLoadingAdditionalData === 'EDIT' ? (
                    <CircularProgress size={20} />
                  ) : (
                    <Edit className={classes.actionButtonIcon} />
                  )}
                </IconButton>
              )}

              {canManageEverything && (
                <IconButton
                  onClick={handleDeleteTimeCard}
                  className={classes.actionButton}
                  disabled={!timeCard || isDeleting}
                >
                  <Delete className={classes.actionButtonIcon} />
                </IconButton>
              )}
            </span>
          </>
        )}
      </Grid>
    </Grid>
  );
};

import { v4 as uuid4 } from 'uuid';
import * as React from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { initialize, reset } from 'redux-form';
import {
  Paper,
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  WithStyles,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import translate from '@/app/utils/translate';
import {
  createGroupedIndicator,
  fetchIndicators,
  removeGroupedIndicator,
  updateGroupedIndicator,
} from '@/app/redux/solaIntel';
import {
  changeData,
  getFormDialog,
} from '@/app/components/FormDialog/FormDialog';
import { IGroupedIndicatorCreate } from '@/app/components/SolaIntel/SolaIntelConfig';
import SolaIntelGroupedIndicatorDetails from './SolaIntelGroupedIndicatorDetails/solaIntelGroupedIndicatorDetails';
import SolaIntelGroupedIndicatorList from './SolaIntelGroupedIndicatorList/solaIntelGroupedIndicatorList';
import style from './solaIntelGroupConfigStyle';

const validate = (values: any) => {
  let errors: { [k: string]: any } = {};
  if (!values.name || values.name === '') {
    errors = Object.assign({}, errors, {
      name: translate.t('laThisRequired'),
    });
  }

  if (!values.condition || !values.condition.value) {
    errors = Object.assign({}, errors, {
      condition: translate.t('laThisRequired'),
    });
  }

  if (!values.indicatorIds || values.indicatorIds.length < 2) {
    errors = Object.assign({}, errors, {
      indicatorIds: translate.t('laThisRequired'),
    });
  }

  return errors;
};

const FORM_NAME = 'solaIntelGroupedIndicatorsDialog';
const FormDialog = getFormDialog(FORM_NAME, validate);

interface IProps extends WithStyles<typeof style, true> {
  indicators: [];
  indicator: any;
  createGroupedIndicatorAction: (
    groupedIndicator: IGroupedIndicatorCreate,
  ) => void;
  fetchIndicatorsAction: () => void;
  removeGroupedIndicatorAction: (id: string) => void;
  updateGroupedIndicatorAction: (id: string, data: any) => void;
  resetForm: (formName: string) => void;
  initializeField: (formName: string, data: any) => void;
}

interface IState {
  currentIndicator: IGroupedIndicatorCreate;
  open: boolean;
  formData: any;
}

class SolaIntelGroupConfig extends React.Component<IProps, IState> {
  isEditing: boolean = false;
  state: IState = {
    open: false,
    formData: {
      indicatorIds: null,
      name: '',
      active: false,
      condition: null,
    },
    currentIndicator: {
      id: uuid4(),
      name: '',
      indicatorIds: [],
      condition: '',
    },
  };

  onAdd = () => {
    this.setState({ open: true });
    this.props.resetForm(FORM_NAME);
  };

  onDelete = (indicatorId: string) => {
    this.props.removeGroupedIndicatorAction(indicatorId);
  };

  onEdit = (indicator: any) => {
    this.isEditing = true;
    const data = {
      indicatorIds: indicator.group.indicators.map((ind: any) => ind.id),
      name: indicator.name,
      active: indicator.active,
      condition: {
        label: translate.t(`la${indicator.group.condition}`),
        value: indicator.group.condition,
      },
    };

    this.setState({
      open: true,
      formData: data,
      currentIndicator: Object.assign({}, this.state.currentIndicator, {
        id: indicator.id,
        name: indicator.name,
        indicatorIds: data.indicatorIds.slice(),
        condition: Object.assign({}, data.condition),
      }),
    });

    this.props.resetForm(FORM_NAME);
  };

  onClose = () => {
    this.isEditing = false;
    const data = {
      indicatorIds: [] as string[],
      name: '',
      active: false,
      condition: '',
    };

    this.setState({
      open: false,
      formData: data,
      currentIndicator: {
        id: uuid4(),
        name: '',
        indicatorIds: [],
        condition: '',
      },
    });

    this.props.resetForm(FORM_NAME);
  };

  onIndicatorInclude = (uuid: string, checked: boolean) => {
    let updatedIndicator;
    const { currentIndicator } = this.state;
    if (checked) {
      updatedIndicator = Object.assign({}, currentIndicator, {
        indicatorIds: currentIndicator.indicatorIds.concat([uuid]),
      });
    } else {
      updatedIndicator = Object.assign({}, currentIndicator, {
        indicatorIds: currentIndicator.indicatorIds.filter(
          currentUid => currentUid !== uuid,
        ),
      });
    }

    changeData({
      form: FORM_NAME,
      field: 'indicatorIds',
      value: updatedIndicator.indicatorIds.slice(),
    });

    this.setState({
      currentIndicator: Object.assign({}, updatedIndicator),
    });
  };

  onSubmit = (formData: any) => {
    const data = Object.assign({}, this.state.currentIndicator, {
      active: formData.active ? true : false,
      condition: formData.condition.value,
      name: formData.name,
    });

    if (!this.isEditing) {
      this.props.createGroupedIndicatorAction(data);
    } else {
      this.props.updateGroupedIndicatorAction(
        this.state.currentIndicator.id,
        data,
      );
    }

    this.onClose();
    this.isEditing = false;
  };

  componentDidMount() {
    const { fetchIndicatorsAction } = this.props;
    fetchIndicatorsAction();
  }

  render() {
    const { classes, indicators } = this.props;
    const { open, formData, currentIndicator } = this.state;
    const groupedIndicators = indicators
      .filter((indicator: any) => !!indicator.group)
      .sort((firstInd: any, secondInd: any) => {
        return `${firstInd.name}`.localeCompare(secondInd.name);
      });

    const title = this.isEditing
      ? translate.t('laGroupedIndicatorEdit')
      : translate.t('laGroupedIndicatorAdd');

    return (
      <>
        <FormDialog
          title={title}
          open={open}
          onClose={this.onClose}
          formData={formData}
          paperWidthMd={classes.paperWidthMd}
          onSubmit={this.onSubmit}
        >
          <SolaIntelGroupedIndicatorDetails
            indicators={indicators}
            disableIndicators={false}
            selectedIndicatorIds={currentIndicator.indicatorIds.slice()}
            onIndicatorInclude={this.onIndicatorInclude}
          />
        </FormDialog>
        <Paper>
          <Toolbar className={classes.root}>
            <div className={classes.title}>
              <Typography variant="h6">
                {translate.t('laGroupedIndicators')}
              </Typography>
            </div>
            <div className={classes.actions}>
              <Tooltip title={translate.t('laAdd')}>
                <IconButton
                  color="primary"
                  component="span"
                  onClick={this.onAdd}
                >
                  <AddCircleIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Toolbar>
          <SolaIntelGroupedIndicatorList
            indicators={groupedIndicators}
            allIndicators={indicators}
            onEdit={this.onEdit}
            onDelete={this.onDelete}
          />
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  indicators: state.solaIntel.get('uiMappedIndicators'),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      fetchIndicatorsAction: fetchIndicators,
      createGroupedIndicatorAction: createGroupedIndicator,
      removeGroupedIndicatorAction: removeGroupedIndicator,
      updateGroupedIndicatorAction: updateGroupedIndicator,
      resetForm: reset,
      initializeField: initialize,
    },
    dispatch,
  );

const enhance = compose<IProps, any>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(style, { withTheme: true }),
);

export default enhance(SolaIntelGroupConfig);

import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';

const loadingStyle: StyleRulesCallback = () => ({
  root: {
    textAlign: 'center',
  },
  progress: {
    margin: '50px 0px',
  },
});

export default loadingStyle;

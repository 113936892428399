import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const cardHeight = 192;
const cardWidth = 340;

const likertChartStyle: StyleRulesCallback = (theme: Theme) => {
  return ({
    likertChartCard: {
      height: '100%',
      minHeight: `${cardHeight}px`,
      padding: 0,
      width: `${cardWidth}px`,
    },
    likertChartHeader: {
      padding: `${theme.spacing.unit}px ${theme.spacing.unit}px 0`,
    },
    likertChartCardTitle: {
      fontSize: '1.3rem',
    },
    likertChartCardContent: {
      padding: 0,
      '&:last-child': {
        paddingBottom: 0,
      },
      '&:not(:last-child)': {
        paddingBottom: 0,
      },
    },
    likertChartContainer: {
      height: '160px',
      padding: `0 ${theme.spacing.unit}px ${theme.spacing.unit}px`,
      position: 'relative',
      width: `${cardWidth}px`,

      '& text': {
        transform: 'translate(0, 30px)',
      },
    },
    total: {
      fontSize: '0.9rem',
      position: 'absolute',
      right: '109px',
      top: '43%',
    },
    likertLegend: {
      bottom: theme.spacing.unit,
      position: 'absolute',
      maxHeight: '50px',
      maxWidth: `${cardWidth - (theme.spacing.unit * 2)}px`,
    },
  });
};

export default likertChartStyle;

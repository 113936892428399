import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const POPUP_Z_INDEX: number = 10090;
const POPUP_HEIGHT: number = 600;
const POPUP_MAX_HEIGHT: string = '70vh';
const POPUP_WIDTH: number = 320;
const TOOLBAR_HEIGHT: number = 34;

const styles: StyleRulesCallback = (theme: Theme) => ({
  root: {
    height: POPUP_HEIGHT,
    maxHeight: POPUP_MAX_HEIGHT,
    width: POPUP_WIDTH,
    zIndex: POPUP_Z_INDEX,
  },
  rootPaper: {
    display: 'flex',
    flexBasis: 'auto',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 1,
    height: POPUP_HEIGHT,
    maxHeight: POPUP_MAX_HEIGHT,
    position: 'relative',
    width: POPUP_WIDTH,
  },
  appBar: {
    boxSizing: 'border-box',
    display: 'block',
    flexBasis: 'auto',
    flexGrow: 0,
    flexShrink: 0,
    height: TOOLBAR_HEIGHT,
    width: POPUP_WIDTH,
  },
  toolbar: {
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    minHeight: TOOLBAR_HEIGHT,
    height: TOOLBAR_HEIGHT,
    width: POPUP_WIDTH,
  },
  toolbarIconButton: {
    maxHeight: TOOLBAR_HEIGHT - 4,
    maxWidth: TOOLBAR_HEIGHT - 4,
    padding: 6,
  },
  toolbarIcon: {
    fontSize: theme.typography.h6.fontSize,
  },
  toolbarTitle: {
    flexGrow: 1,
    textAlign: 'left',
    fontSize: '14px',
    marginLeft: theme.spacing.unit,
  },
  content: {
    backgroundColor: theme.palette.background.default,
    marginTop: TOOLBAR_HEIGHT,
    boxSizing: 'border-box',
    display: 'block',
    flexBasis: 'auto',
    flexGrow: 1,
    flexShrink: 1,
    overflowY: 'auto',
    width: POPUP_WIDTH,
  },
});

export default styles;
import { StyleRulesCallback } from '@material-ui/core';

import { getToggleOuterWidth } from './components/DailySummary';

export type TimeSheetStyleKeys = 'root' | 'header' | 'body' | 'opaque' | 'overlay' | 'error';

export const timeSheetStyles: StyleRulesCallback<TimeSheetStyleKeys> = (theme) => ({
    root: {},
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 2 * theme.spacing.unit,
    },
    body: {
        position: 'relative',

        '&>$header': {
            marginLeft: getToggleOuterWidth(theme),
            marginRight: theme.spacing.unit,
        },
    },
    opaque: {
        opacity: 0.35,
    },
    overlay: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    error: {
        '&>svg': {
            color: theme.palette.error.main,
            marginRight: theme.spacing.unit,
        }
    },
});

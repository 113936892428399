import * as React from 'react';
import {
  Theme,
  WithStyles,
  withStyles,
  ListItem,
  ListItemIcon,
  IconButton,
  Icon,
  ListItemText,
  Typography,
  Button,
  Grid,
  Toolbar,
  Popover,
} from '@material-ui/core';
import { compose } from 'recompose';
import { LineDatum } from '@nivo/line';
import moment from 'moment';

import { ReducerState } from '@/app/redux/store';
import { fetchMyManagedUnits, fetchMyHrUnits } from '@/app/redux/currentUser';

import EmpCountLineChart from './headCountReport/EmpCountLineChart';
import { IReportConfig } from '../ReportConfig';
import translate from '@/app/utils/translate';
import JoinersLeaversChart from './headCountReport/JoinersLeaversChart';
import { DATE_FORMAT } from '@/app/utils/helper';
import Loading from '../../Loading/Loading';
import ToolbarFilterButton from '../components/ToolbarFilterButton';
import ScopeSelector from '../components/ScopeSelector';
import { hasRole } from '@/app/utils/security';
import { ScopeType, IReportSettings } from '../ReportSettings';
import ToolbarFilterButtonGroup from '../components/ToolbarFilterButtonGroup';
import { currentUserHasTeam, eventTargetAsHtmlElement } from '@/old/utils/helper';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import FetchPrimaryUnitsHOC from '@/app/hocs/FetchPrimaryUnitsHOC';
import { applyDefaultSettings, applySettings } from '@/app/redux/reporting';

const styles = (theme: Theme) => ({
  root: {
    padding: theme.spacing.unit * 2,
    height: 'calc(100vh - 360px)',
    width: 1100,
  },
  title: {
    marginBottom: theme.spacing.unit,
  },
  toolsContainer: {
    backgroundColor: theme.palette.background.default,
    border: 'solid 1px rgba(0, 0, 0, 0.08)',
    padding: theme.spacing.unit,
  },
  leftToolbarButtonMargin: {
    marginLeft: theme.spacing.unit * 2,
  },
  grow: {
    flexGrow: 1,
  },
  noDataOrScope: {
    padding: theme.spacing.unit * 2,
  },
});

const sortByDate = (a: any, b: any) => {
  if (moment(a.date).isBefore(moment(b.date))) {
    return -1;
  }
  if (moment(b.date).isBefore(moment(a.date))) {
    return 1;
  }

  return 0;
};

const defaultSettings: IReportSettings = {
  report: 'headcountsummary',
  scope: {
    type: '',
    range: {
      startDate: moment().subtract(3, 'year'),
      endDate: moment(),
    },
  },
  custom: {
    viewMode: 'monthly_emp_count',
  },
};

type ViewMode =
  | 'yearly_emp_count'
  | 'monthly_emp_count'
  | 'monthly_joiners_leavers';

type DispatchProps = {
  settings: IReportSettings;
  data: Array<any>;
  isFetching: boolean;
  primaryUnits: Array<object>;
  myManagedUnits: Array<object>;
  myHrUnits: Array<object>;
  fetchManagedUnits: (fullUnitList: Array<object>) => any;
  fetchHrUnits: (fullUnitList: Array<object>) => any;
  saveSettings: (
    newSettings: IReportSettings,
    mapDataFn: (origData: any) => any,
  ) => void;
  saveDefaultSettings: (defaultSettings: IReportSettings) => void;
};
type OwnProps = {};
type InnerProps = WithStyles<typeof styles> & DispatchProps;
type Props = InnerProps & OwnProps;

const HeadCountReport = ({
  classes,
  settings,
  data,
  isFetching,
  primaryUnits,
  myManagedUnits,
  myHrUnits,
  fetchManagedUnits,
  fetchHrUnits,
  saveSettings,
  saveDefaultSettings,
}: Props) => {
  const [viewMode, setViewMode] = React.useState<ViewMode>('yearly_emp_count');
  const [viewDate, setViewDate] = React.useState<string>();
  const [scopeSelectorAnchorEl, setScopeSelectorAnchorEl] = React.useState<HTMLElement|undefined>();
  const [hasTeam, setHasTeam] = React.useState<boolean>();
  const [isLocked, setIsLocked] = React.useState(false);

  React.useEffect(() => {
    saveDefaultSettings(defaultSettings);
    handleSettingsChanged(defaultSettings);
  }, [defaultSettings]);

  React.useEffect(() => {
    setHasTeam(currentUserHasTeam());
  }, [setHasTeam]);

  React.useEffect(() => {
    if (!primaryUnits || primaryUnits.length === 0) {
      return;
    }
    fetchManagedUnits(primaryUnits);
  }, [primaryUnits, fetchManagedUnits]);

  React.useEffect(() => {
    if (!primaryUnits || primaryUnits.length === 0) {
      return;
    }
    fetchHrUnits(primaryUnits);
  }, [primaryUnits, fetchHrUnits]);

  const handleMapData = (origData: Array<object>) => {
    const mappedData = origData.map((year: any) => ({
      date: year.fEhdDate,
      totalCount: year.fEhdTotalCount,
      monthly: year.fEhdMonthlyCounts.map((month: any) => ({
        date: month.fEheDate,
        leavers: month.fEheLeavers,
        joiners: month.fEheJoiners,
        netChange: month.fEheJoiners - month.fEheLeavers,
        totalCount: month.fEheCount,
      })),
    }));
    return mappedData;
  };

  const handleSettingsChanged = React.useCallback(
    (newSettings: IReportSettings) => {
      saveSettings(newSettings, handleMapData);
    },
    [],
  );

  const config: IReportConfig = {
    id: 'headcountsummary',
    report: 'headcountsummary',
    titleLabel: 'laHeadCountReport',
    access: 'standard',
    columns: [],
    filters: {
      scopeFilter: {
        enabled: true,
        accessRights: {
          company: ['HR_ADMIN'],
        },
      },
      yearsAndEndDateFilter: {
        enabled: true,
      },
      customFilters: [
        {
          type: 'BUTTONGROUP',
          params: {
            buttonLabel: '',
            fieldName: '',
            operator: '',
            selectedSettingName: 'viewMode',
            buttons: [
              { label: translate.t('laEmpCount'), value: 'monthly_emp_count' },
              {
                label: translate.t('laJoinersAndLeavers'),
                value: 'monthly_joiners_leavers',
              },
            ],
          },
        },
      ],
    },
  };

  const handleScopeSelectorClose = () => {
    if (isLocked) {
      return;
    }

    setScopeSelectorAnchorEl(undefined);
  };

  const handleApplyScope = (newScope: ScopeType) => {
    setViewMode('yearly_emp_count');
    const newSettings = Object.assign({}, settings);
    newSettings.scope = newScope;
    handleSettingsChanged(newSettings);
    setScopeSelectorAnchorEl(undefined);
  };

  const handleYearClick = (datum: LineDatum) => {
    setViewMode('monthly_emp_count');
    setViewDate(moment(datum.data.x, DATE_FORMAT).format('YYYY-MM-DD'));
  };

  const handleYearlyViewMode = () => setViewMode('yearly_emp_count');

  const handlePreviousYear = () => {
    const ndx = data.findIndex(year => year.date === viewDate);
    setViewDate(data[ndx - 1].date);
  };

  const handleNextYear = () => {
    const ndx = data.findIndex(year => year.date === viewDate);
    setViewDate(data[ndx + 1].date);
  };

  const getUnitName = (unitId: string): string => {
    let unit: any = myManagedUnits.find(
      (item: any) => item.fTreeUnitId === unitId,
    );
    if (!unit) {
      unit = myHrUnits.find((item: any) => item.fTreeUnitId === unitId);
      if (!unit) {
        return '';
      }
    }
    return unit.fTreeUnitName;
  };

  const getScopeSelectorDescription = (): string => {
    if (!settings.scope || settings.scope.type === '') {
      return translate.t('label_none');
    }

    switch (settings.scope.type) {
      case 'manager':
        return translate.t('label_myteam');
      case 'unit':
        if (settings.scope.rln === 'all') {
          return translate.t('label_unit_with_subunits', {
            unitName: getUnitName(settings.scope.id),
          });
        } else {
          return getUnitName(settings.scope.id);
        }
      default:
        return translate.t('label_whole_company');
    }
  };

  const getScopeDateRangeDescription = (): string => {
    if (!settings.scope) {
      return null;
    }
    if (settings.scope.range) {
      const years = settings.scope.range.endDate.diff(
        settings.scope.range.startDate,
        'years',
      );
      if (settings.scope.range.startDate && settings.scope.range.endDate) {
        return `${settings.scope.range.endDate.format(
          DATE_FORMAT,
        )} (${years} ${translate.t('laYears').toLocaleLowerCase()})`;
      }
    }
    return null;
  };

  const naviButtons =
    viewMode === 'yearly_emp_count' ? (
      undefined
    ) : (
      <Toolbar>
        <Button
          color="primary"
          disabled={viewDate === data[0].date}
          variant="text"
          onClick={handlePreviousYear}
        >
          <Icon color="inherit">arrow_back</Icon>
          {translate.t('laPrev')}
        </Button>
        <div className={classes.grow} />
        <Button
          color="primary"
          disabled={viewDate === data[data.length - 1].date}
          variant="text"
          onClick={handleNextYear}
        >
          {translate.t('laNext')}
          <Icon color="inherit">arrow_forward</Icon>
        </Button>
      </Toolbar>
    );

  let chart, viewModeTitle;
  if (isFetching) {
    chart = <Loading />;
  } else if (!data || data.length === 0) {
    chart = (
      <div className={classes.noDataOrScope}>
        {settings.scope && settings.scope.type === ''
          ? translate.t('laReportNotLoaded')
          : translate.t('laNoData')}
      </div>
    );
  } else {
    switch (viewMode) {
      case 'monthly_emp_count':
        const mecData = [
          {
            id: 'emp_count',
            data: data
              .filter(year => year.date === viewDate)[0]
              .monthly.sort(sortByDate)
              .map((month: LineDatum) => ({
                x: moment(month.date, 'YYYY-MM-DD').format(DATE_FORMAT),
                y: month.totalCount,
              })),
          },
        ];
        chart = <EmpCountLineChart data={mecData} />;
        viewModeTitle = (
          <ListItem>
            <ListItemIcon>
              <IconButton onClick={handleYearlyViewMode}>
                <Icon>arrow_back</Icon>
              </IconButton>
            </ListItemIcon>
            <ListItemText>
              {translate.t('label_emp_count_in_month', {
                year:
                  moment(viewDate, 'YYYY-MM-DD')
                    .clone()
                    .subtract(1, 'year')
                    .format(DATE_FORMAT) +
                  ' - ' +
                  moment(viewDate, 'YYYY-MM-DD').format(DATE_FORMAT),
              })}
            </ListItemText>
          </ListItem>
        );
        break;
      case 'monthly_joiners_leavers':
        chart = (
          <JoinersLeaversChart
            data={data
              .filter(year => year.date === viewDate)[0]
              .monthly.sort(sortByDate)
              .map((month: LineDatum) => ({
                id: month.date,
                label: moment(month.date, 'YYYY-MM-DD').format(DATE_FORMAT),
                leavers: -1 * month.leavers,
                joiners: month.joiners,
                netChange: month.joiners - month.leavers,
              }))}
            onBarClick={datum => console.info(datum)}
          />
        );
        viewModeTitle = (
          <ListItem>
            <ListItemIcon>
              <IconButton onClick={handleYearlyViewMode}>
                <Icon>arrow_back</Icon>
              </IconButton>
            </ListItemIcon>
            <ListItemText>
              {translate.t('label_emp_joiners_and_leavers_in_month', {
                year:
                  // moment(viewDate, 'YYYY-MM-DD').format(DATE_FORMAT)
                  moment(viewDate, 'YYYY-MM-DD')
                    .clone()
                    .subtract(1, 'year')
                    .format(DATE_FORMAT) +
                  ' - ' +
                  moment(viewDate, 'YYYY-MM-DD').format(DATE_FORMAT),
              })}
            </ListItemText>
          </ListItem>
        );
        break;
      default:
        const chartData = [
          {
            id: 'emp_count',
            data: data.sort(sortByDate).map(year => ({
              x: moment(year.date, 'YYYY-MM-DD').format(DATE_FORMAT),
              y: year.totalCount,
            })),
          },
        ];
        chart = (
          <EmpCountLineChart data={chartData} onPointClick={handleYearClick} />
        );
        viewModeTitle = (
          <ListItem>
            <ListItemText>
              {translate.t('label_emp_count_in_year')}
            </ListItemText>
          </ListItem>
        );
        break;
    }
  }

  const monthViewModeSelection =
    viewMode === 'yearly_emp_count' ? (
      undefined
    ) : (
      <ToolbarFilterButtonGroup
        className={classes.leftToolbarButtonMargin}
        buttons={[
          {
            label: translate.t('laNumberOfEmp'),
            value: 'monthly_emp_count',
          },
          {
            label: translate.t('laJoinersAndLeavers'),
            value: 'monthly_joiners_leavers',
          },
        ]}
        selectedButton={viewMode}
        onSelect={mode => setViewMode(mode as ViewMode)}
      />
    );

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h6">
        {translate.t(config.titleLabel)}
      </Typography>
      <Grid
        className={classes.toolsContainer}
        container={true}
        justify="space-between"
        alignItems="center"
      >
        <Grid item={true}>
          <Popover
            anchorEl={scopeSelectorAnchorEl}
            open={Boolean(scopeSelectorAnchorEl)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            elevation={4}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handleScopeSelectorClose}
          >
            <ScopeSelector
              scope={settings.scope}
              allowCompanyScope={
                settings.allowCompanyScopeForAll === true || hasRole('HR_ADMIN')
              }
              hasTeam={hasTeam}
              myHrUnits={myHrUnits}
              myManagedUnits={myManagedUnits}
              dateRangeVariant="years-and-end-date"
              onApply={handleApplyScope}
              onCancel={handleScopeSelectorClose}
              onPopupLock={shouldLock => setIsLocked(shouldLock)}
            />
          </Popover>
          <ToolbarFilterButton
            prompt={translate.t('label_scope')}
            value={getScopeSelectorDescription()}
            value2={getScopeDateRangeDescription()}
            onClick={(e: React.SyntheticEvent<HTMLButtonElement>) =>
              setScopeSelectorAnchorEl(eventTargetAsHtmlElement(e.currentTarget))
            }
          />
          {monthViewModeSelection}
        </Grid>
        {/* <Grid item={true}>
          <Button>
            Export
          </Button>
        </Grid> */}
      </Grid>
      {viewModeTitle}
      {chart}
      {naviButtons}
    </div>
  );
};

const mapStateToProps = (state: ReducerState) => ({
  primaryUnits: state.orgTree.get('primaryUnits'),
  myManagedUnits: state.currentUser.get('myManagedUnits'),
  myHrUnits: state.currentUser.get('myHrUnits'),
  isFetching: state.reporting.get('isFetching'),
  settings: state.reporting.get('settings'),
  data: state.reporting.get('data'),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchManagedUnits: (fullUnitList: Array<object>) =>
    dispatch<any>(fetchMyManagedUnits(fullUnitList)),
  fetchHrUnits: (fullUnitList: Array<object>) =>
    dispatch<any>(fetchMyHrUnits(fullUnitList)),
  saveSettings: (
    newSettings: IReportSettings,
    mapDataFn: (origData: any) => any,
  ) => dispatch<any>(applySettings(newSettings, mapDataFn)),
  saveDefaultSettings: (newDefaultSettings: IReportSettings) =>
    dispatch<any>(applyDefaultSettings(newDefaultSettings)),
});

const enhance = compose<Props, OwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles, { withTheme: true }),
  FetchPrimaryUnitsHOC,
);

export default enhance(HeadCountReport);

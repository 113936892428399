import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemIcon,
  Icon,
  ListItemText,
  StyleRulesCallback,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import translate from '@/app/utils/translate';

const styles: StyleRulesCallback = (theme: Theme) => ({
  removalNote: {
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
});

type OwnProps = {
  open: boolean;
  successCount: number;
  errorCount: number;
  errors: any[];
  onClose: () => void;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const ErrorDialog = (props: Props) => {
  const { classes, open, errors, successCount, errorCount, onClose } = props;

  const errorStatus = translate.t('text_worklist_task_creation_error_status', { errorCount });
  let title, removalNote;
  if (successCount === 0) {
    title = translate.t('text_worklist_task_creation_no_tasks_created');
  } else {
    title = translate.t('text_worklist_task_creation_tasks_were_created', { successCount });
    removalNote = (
      <Typography className={classes.removalNote} variant="body1">
        {translate.t('text_worklist_task_creation_removal_note', { successCount })}
      </Typography>
    );
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography color="error" variant="h5">{errorStatus}</Typography>
        <List>
          {errors.map((error: any, index: number) => (
            <ListItem key={index}>
              <ListItemIcon>
                <Icon color="error">error</Icon>
              </ListItemIcon>
              <ListItemText>
                <Typography variant="caption" color="error">{error.message}</Typography>
                <Typography>{error.title}</Typography>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        {removalNote}
        <Button
          variant="contained"
          color="primary"
          onClick={onClose}
        >
          {translate.t('laClose')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(ErrorDialog);

import { FormikProps } from 'formik';

export function hasError(
  name: string,
  form: FormikProps<any>,
  error: boolean | undefined
) {
  return (
    (form.errors && form.touched[name] === true && !!form.errors[name]) ||
    error
  );
}

export function getHelperText(
  name: string,
  form: FormikProps<any>,
  helperText: React.ReactNode,
) {
  return (
    (form.errors && form.touched[name] === true && form.errors[name]) ||
    helperText
  );
}

import * as React from 'react';
import { compose } from 'recompose';
import { LanguagesType } from '@/app/redux/languages';
import {
  withStyles,
  WithStyles,
  Modal,
  Paper,
  Typography,
  Button,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SortIcon from '@material-ui/icons/Sort';
import translate from '@/app/utils/translate';
import SortableHOC from '../SortableHOC/SortableHOC';
import SectionListing from './SectionListing/SectionListing';
import AddSection from './AddSection/AddSection';
import editTemplateStyle from './editTemplateStyle';
import TemplateHeader from '@/app/components/TemplateComponents/admin/TemplateHeader/TemplateHeader';
import { connect } from 'react-redux';
import { Enum, selectActiveEnumsInGroup } from '@/app/redux/enums';
import { ReducerState } from '@/app/redux/store';

type IncomingProps = {
  template: any;
  openEditModal: any;
  handleModalClose: any;
  updateTemplate: any;
  addTemplateSection: any;
  updateTemplateSection: any;
  updateTemplateSections: any;
  deleteTemplateSection: any;
  addSectionComponent: any;
  updateSectionComponent: any;
  deleteSectionComponent: any;
  orderSectionComponents: any;
  warningMessage: boolean;
  languages: LanguagesType[];
};

type MapStateToProps = {
  templateTags: Enum[];
};

type PropsType = IncomingProps & MapStateToProps & WithStyles<typeof editTemplateStyle>;

class EditTemplate extends React.PureComponent<PropsType> {
  state = {
    openSectionAdd: false,
    openSectionSort: false,
  };

  openAddSectionModal = () => {
    this.setState({ openSectionAdd: true });
  };

  closeAddSectionModal = () => {
    this.setState({ openSectionAdd: false });
  };

  openSortingModal = () => {
    this.setState({ openSectionSort: true });
  };

  closeSortingModal = () => {
    this.setState({ openSectionSort: false });
  };

  saveNewOrder = (newOrder: []) => {
    const { template, updateTemplateSections } = this.props;

    updateTemplateSections(
      template.id,
      newOrder.map((section: any, i: number) => {
        return { ...section, index: i + 1 };
      }),
    );

    this.closeSortingModal();
  };

  render() {
    const {
      classes,
      openEditModal,
      template,
      handleModalClose,
      updateTemplate,
      addTemplateSection,
      updateTemplateSection,
      deleteTemplateSection,
      addSectionComponent,
      updateSectionComponent,
      orderSectionComponents,
      deleteSectionComponent,
      warningMessage,
      languages,
      templateTags,
    } = this.props;

    const { openSectionAdd, openSectionSort } = this.state;

    return (
      <>
        <Modal open={openEditModal}>
          <Paper className={classes.root}>
            <TemplateHeader
              templateBeingEdited={template}
              languages={languages}
              handleModalClose={handleModalClose}
              updateTemplate={updateTemplate}
              editStyle={true}
              showTags={true}
              templateTags={templateTags}
            />

            {warningMessage && (
              <Typography className={classes.warning} color="error">
                {translate.t('warningTemplateEdit')}
              </Typography>
            )}

            {template.sections && template.sections.length ? (
              template.sections.map((section: any, index: number) => {
                return (
                  <SectionListing
                    key={section.id}
                    template={template}
                    section={section}
                    updateSection={updateTemplateSection}
                    deleteSection={deleteTemplateSection}
                    addSectionComponent={addSectionComponent}
                    updateSectionComponent={updateSectionComponent}
                    deleteSectionComponent={deleteSectionComponent}
                    orderSectionComponents={orderSectionComponents}
                    defaultExpanded={index + 1 === template.sections.length}
                  />
                );
              })
            ) : (
              <h2 className={classes.noSections}>
                {translate.t('no_sections')}
              </h2>
            )}

            <div className={classes.buttonWrapper}>
              <Button
                variant="contained"
                color="primary"
                onClick={this.openAddSectionModal}
                className={classes.addSectionButton}
              >
                <AddCircleIcon className={classes.buttonIcon} />
                {translate.t('add_section')}
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={this.openSortingModal}
                className={classes.sortButton}
                disabled={(template.sections || []).length < 2}
              >
                <SortIcon className={classes.buttonIcon} />
                {translate.t('laSort')}
              </Button>
            </div>
          </Paper>
        </Modal>

        <Modal open={openSectionAdd} onClose={this.closeAddSectionModal}>
          <AddSection
            template={template}
            addSection={addTemplateSection}
            handleModalClose={this.closeAddSectionModal}
          />
        </Modal>

        <SortableHOC
          open={openSectionSort}
          itemsToSort={template.sections || []}
          saveNewOrder={this.saveNewOrder}
          cancelNewOrder={this.closeSortingModal}
        />
      </>
    );
  }
}

export const mapStateToProps = (state: ReducerState) => {
  return {
    templateTags: selectActiveEnumsInGroup(state, 'GOALS_TEMPLATE_TAGS'),
  };
};

const enhance = compose<any, any>(
  connect(mapStateToProps, null),
  withStyles(editTemplateStyle)
);

export default enhance(EditTemplate);

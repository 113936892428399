import * as React from 'react';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
  SortableHandle,
} from 'react-sortable-hoc';
import { withStyles } from '@material-ui/core/styles';
import {
  WithStyles,
  Paper,
  TextField,
  Typography,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import sortableInputsComponentStyle from './sortableInputsComponentStyle';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import translate from '@/app/utils/translate';

// TODO: create a component which accepts a list of React.Node(component) and make it sortable
export interface SortableInputItem {
  value: any;
  order: number;
}

interface SortableElementProps {
  item: SortableInputItem;
  itemIndex: number;
  label: string;
  className?: any;
}

interface OwnProps {
  list: SortableInputItem[];
  updatedList: (list: SortableInputItem[]) => void;
  showDragHandle?: boolean;
  title: string;
  label: string;
}

type SortStateType = {
  items: SortableInputItem[];
};

type Props = OwnProps & WithStyles<typeof sortableInputsComponentStyle>;

class SortableInputsComponent extends React.Component<Props> {
  state: SortStateType = {
    items: [],
  };

  constructor(props: Props) {
    super(props);

    let items = [];
    if (props.list.length === 0) {
      items.push({ value: '', order: 0 });
    } else {
      items = [...props.list];
    }

    this.state = {
      items,
    };
  }

  addChoice = () => {
    const { items } = this.state;
    const list = [...items];
    list.push({ value: '', order: items.length });

    this.setState({
      items: list,
    });
  };

  removeChoice = (itemIndex: number) => {
    const { items: oldItems } = this.state;
    const items: SortableInputItem[] = [...oldItems];
    items.splice(itemIndex, 1);

    this.setState(
      {
        items,
      },
      () => this.props.updatedList(items),
    );
  };

  handleChange = (index: number) => (event: any) => {
    const { items: allItems } = this.state;
    const items = this.getOrdered(allItems);
    items[index] = { ...items[index], value: event.target.value };

    this.setState(
      {
        items,
      },
      () => this.props.updatedList(items),
    );
  };

  getOrdered = (list: SortableInputItem[]) =>
    list.map((item: SortableInputItem, idx: number) => ({
      ...item,
      order: idx,
    }));

  DragHandle = SortableHandle(() => <DragHandleIcon />);

  SortableItem = SortableElement(
    ({ item, itemIndex, label }: SortableElementProps) => {
      const { classes, showDragHandle = true } = this.props;

      return (
        <Paper
          className={`${classes.root} ${classes.sortableItem}`}
          elevation={1}
        >
          <TextField
            id="sortable-value"
            label={`${label} ${itemIndex + 1}`}
            className={classes.textField}
            value={item.value}
            onChange={this.handleChange(itemIndex)}
            fullWidth
            margin="dense"
          />
          {showDragHandle ? <this.DragHandle /> : null}
        </Paper>
      );
    },
  );

  SortableList = SortableContainer(
    ({ items, label }: { items: SortableInputItem[]; label: string }) => {
      const { classes } = this.props;

      return (
        <div className={classes.sortableListContainer}>
          <div className={classes.sortableItems}>
            {items.map((item: SortableInputItem, index: number) => (
              <this.SortableItem
                key={`item-${index}`}
                index={index}
                item={item}
                itemIndex={index}
                label={label}
              />
            ))}
          </div>
          <div className={classes.deleteButtonsContainer}>
            {items.map((_item: SortableInputItem, index: number) => (
              <Tooltip title={translate.t('laDelete')} key={`item-${index}`}>
                <IconButton
                  color="primary"
                  className={classes.deleteButton}
                  onClick={(event: any) => {
                    this.removeChoice(index);
                    event.stopPropagation();
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            ))}
          </div>
        </div>
      );
    },
  );

  onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    const items = this.getOrdered(
      arrayMove(this.state.items, oldIndex, newIndex),
    );

    this.setState({ items }, () => this.props.updatedList(items));
  };

  render() {
    const { classes, label } = this.props;

    return (
      <>
        <div className={classes.titleContainer}>
          <Typography component="h1" variant="h6">
            {this.props.title}
          </Typography>
          <Tooltip title={translate.t('laAdd')}>
            <IconButton color="primary" onClick={this.addChoice}>
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div>
          <this.SortableList
            items={this.state.items}
            onSortEnd={this.onSortEnd}
            label={label}
          />
        </div>
      </>
    );
  }
}

export default withStyles(sortableInputsComponentStyle, { withTheme: true })(
  SortableInputsComponent,
);

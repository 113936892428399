import * as React from 'react';
import { Avatar, Button, createStyles } from '@material-ui/core';
import { GridCellProps } from '@progress/kendo-react-grid';
import { getEmpPicId, showEmployee } from '@/old/utils/helper';
import { withStyles, WithStyles } from '@material-ui/core';
import API from '@/app/api/internalAPIs';
import translate from '@/app/utils/translate';

const styles = () =>
  createStyles({
    buttonShowExt: {
      position: 'relative',
      '&:after': {
        content: 'attr(data-ext)',
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        fontSize: '10px',
        color: '#05A4D8 !important',
        textTransform: 'capitalize',
      },
    },
  });

type Props = WithStyles<typeof styles> & GridCellProps;

const AvatarCell = ({ dataItem, rowType, classes }: Props) => {
  if (
    rowType === 'groupHeader' ||
    rowType === 'groupFooter' ||
    rowType !== 'data'
  ) {
    return null;
  }

  const getEmployeeImageUrl = (employeeId: number): string => {
    return API.employeeImageUrl(getEmpPicId(employeeId));
  };

  const visitEmpProfile = (id: number) => {
    showEmployee(id);
  };
  return (
    <td style={{ verticalAlign: 'top' }}>
      <Button
        className={classes.buttonShowExt}
        data-ext={dataItem.ext ? translate.t('ext') : ''}
      >
        <Avatar
          src={getEmployeeImageUrl(dataItem.empId)}
          onClick={() => visitEmpProfile(dataItem.empId)}
        />
      </Button>
    </td>
  );
};

export default withStyles(styles)(AvatarCell);

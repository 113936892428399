import * as React from 'react';
import {
  Button,
  Menu,
  MenuItem,
  Icon,
  StyleRulesCallback,
  WithStyles,
  withStyles,
  Snackbar,
  SnackbarContent,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';

import API from '@/app/api/internalAPIs';
import Service from '@/app/utils/service';

import { IWorklist } from './types';
import CreateTasksFromWorklistDialog from './CreateTasksFromWorklistDialog';
import { mapWorklistsForUI } from './service';
import translate from '@/app/utils/translate';

const styles: StyleRulesCallback = () => ({
  button: {
    backgroundColor: '#eeeeee',
    borderRadius: 2,
    color: '#336699',
    maxHeight: 24,
    // padding: theme.spacing.unit / 2,
    fontFamily: 'arial, helvetica, sans-serif',
    fontSize: '13px',
    padding: '0 4px',
    textTransform: 'none',
  },
  successSnackbar: {
    backgroundColor: green[600],
  },
});

type OwnProps = {
  onSuccess: () => void;
};
type Props = OwnProps & WithStyles<typeof styles>;

const CreateTasksFromWorklistButton = (props: Props) => {
  const { classes, onSuccess } = props;
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const [worklists, setWorklists] = React.useState(new Array<IWorklist>());
  const [selectedWorklist, setSelectedWorklist] = React.useState(null);
  const [openSuccessCount, setOpenSuccessCount] = React.useState(0);

  React.useEffect(() => {
    Service.get(
      API.worklists.all,
      (res: any) => {
        setWorklists(mapWorklistsForUI(res).filter(wl => wl.active));
      },
      (err: any) => {
        console.error(`ERROR: ${err.toString()}`);
      },
    );
  }, [setWorklists]);

  const handleOpenMenu = (e: React.SyntheticEvent) => setMenuAnchorEl(e.target);

  const handleDialogClose = () => setSelectedWorklist(null);

  const handleSuccess = (count: number) => {
    setOpenSuccessCount(count);
    onSuccess();
  };

  const createDialog = !selectedWorklist ? (
    undefined
  ) : (
    <CreateTasksFromWorklistDialog
      open={true}
      worklistId={selectedWorklist.id}
      onSuccess={handleSuccess}
      onClose={handleDialogClose}
    />
  );

  return (
    <>
      {createDialog}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openSuccessCount > 0}
        autoHideDuration={4000}
        onClose={() => setOpenSuccessCount(0)}
      >
        <SnackbarContent
          className={classes.successSnackbar}
          message={translate.t(
            'text_worklist_task_creation_tasks_were_created',
            { successCount: openSuccessCount },
          )}
        />
      </Snackbar>
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
      >
        {worklists
          .filter((worklist: IWorklist) => !!worklist.active)
          .sort((a: IWorklist, b: IWorklist) => a.title.localeCompare(b.title))
          .map((worklist: IWorklist) => {
            const handleWorklistClick = () => {
              setMenuAnchorEl(null);
              setSelectedWorklist(worklist);
            };

            return (
              <MenuItem key={worklist.id} onClick={handleWorklistClick}>
                {worklist.title}
              </MenuItem>
            );
          })}
      </Menu>
      <Button
        className={classes.button}
        size="small"
        variant="outlined"
        onClick={handleOpenMenu}
      >
        {translate.t('label_create_tasks_from_worklist')}{' '}
        <Icon fontSize="small">arrow_drop_down</Icon>
      </Button>
    </>
  );
};

export default withStyles(styles)(CreateTasksFromWorklistButton);

import { Enum } from '@/app/redux/enums';

import {
  CustomFieldValues,
  FieldAttributes,
  FieldAttributesHash,
  FieldLang,
  CustomField,
  CustomFieldType,
} from './types';

const GLOBAL: any = window;

export const jsEditCustomFieldData = async (
  empStatus: string,
  empPosition: number,
  customFieldAttributesHash: FieldAttributesHash,
  customFieldLang: FieldLang[],
  customFields: CustomFieldValues,
  empPositionPending?: any,
): Promise<CustomField[]> => {
  const empPositionData =
    (GLOBAL.jsIsEmpStatus(empStatus, 'NEW') ||
      GLOBAL.jsIsEmpStatus(empStatus, 'PENDING')) &&
    empPositionPending
      ? empPositionPending
      : empPosition;
  const legalEntityCountry = GLOBAL.getCountryByLegalEntity(
    empPositionData.fEmpLegalEntity,
  );
  const labels = GLOBAL.filterEnumsByCountry(
    GLOBAL.jsonEnumALL[`CUSTOM_ENTITY_DECISION_REGISTRY`],
    '',
    legalEntityCountry,
  );
  let sortedLabels = labels.sort(GLOBAL.sortEnumLables);
  return sortedLabels.map((label: Enum) => {
    const field: FieldAttributes = customFieldAttributesHash[label.code];
    return {
      ...jsCustomFieldEditMode(
        field,
        customFields,
        customFieldLang,
        label.name,
      ),
    };
  });
};

function jsCustomFieldEditMode(
  customField: FieldAttributes,
  customFieldValues: CustomFieldValues,
  customFieldLang: FieldLang[],
  labelText: string,
): CustomField {
  const strName = customField.fCustomDataAttributeName;
  const strType = customField.fCustomDataAttributeDataType;
  const strValue = customFieldValues.hasOwnProperty(strName)
    ? customFieldValues[strName]
    : '';
  const fieldLang = customFieldLang.find(
    (e: FieldLang) => customField.fCustomDataAttributeName === e.code,
  );
  return {
    groupName: customField.fCustomDataAttributeDataSubtype,
    code: fieldLang.code,
    description: fieldLang.description,
    label: labelText,
    name: strName,
    inputType: {
      label: customField.fCustomDataAttributeLabel,
      value: CustomFieldType[strType],
    },
    value: strValue,
  };
}

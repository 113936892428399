import * as React from 'react';
import Modal from '@material-ui/core/Modal';
import { compose } from 'recompose';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import modalWrapperStyle from './modalWrapperStyle';
import EnumScore from '../../EnumScore/EnumScore';

type IncomingProps = {
  modalOpen: boolean;
  selectedScoreGroupId: string;
  handleModalClose: () => void;
  usedScoreGroups: string[][];
};

type PropsType = IncomingProps & WithStyles<typeof modalWrapperStyle>;
class ModalWrapper extends React.PureComponent<PropsType> {
  render() {
    const {
      modalOpen,
      selectedScoreGroupId,
      handleModalClose,
      usedScoreGroups,
    } = this.props;

    return (
      <div>
        <Modal open={modalOpen} onClose={handleModalClose}>
          <EnumScore
            handleModalClose={handleModalClose}
            selectedScoreGroupId={selectedScoreGroupId}
            open={modalOpen}
            usedScoreGroups={usedScoreGroups}
          />
        </Modal>
      </div>
    );
  }
}

const enhance = compose<PropsType, IncomingProps>(
  withStyles(modalWrapperStyle),
);

export default enhance(ModalWrapper);

import React from 'react';
import { Checkbox, WithStyles, withStyles, StyleRulesCallback, Theme, Grid } from '@material-ui/core';
import { APPROVAL_STATUS } from '../../../types';
import { FieldProps } from 'formik';
import { getTrainingSeatStatusText } from '../../../helpers';

const styles: StyleRulesCallback = (theme: Theme) => ({
  checkbox: {
    height: theme.spacing.unit * 3,
    width: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit,
  },
  grow: {
    flex: 1,
  },
});

type OwnProps = {
  approvalStatus: APPROVAL_STATUS;
  position: number;
  trainingSeats?: number;
  allowWaitingSeats?: boolean;
  readOnly?: boolean;
  onChange: () => void;
};
type InnerProps = WithStyles<typeof styles> & FieldProps;
type Props = OwnProps & InnerProps;

const GuaranteedSeatField = ({
  classes,
  field,
  form,
  trainingSeats,
  allowWaitingSeats = false,
  approvalStatus,
  position,
  readOnly = false,
  onChange,
  ...others
}: Props) => {
  const handleChange = (e: React.ChangeEvent<any>) => {
    field.onChange(e);
    onChange();
  };

  let seatText = getTrainingSeatStatusText(approvalStatus, field.value, position, trainingSeats, allowWaitingSeats);

  if (readOnly) {
    return (
      <Grid container={true}>
        <Grid item={true} className={classes.grow}>{seatText}</Grid>
      </Grid>
    );
  }

  return (
    <Grid container={true} alignItems="center">
      <Grid item={true}>
        <Checkbox
          {...field}
          className={classes.checkbox}
          color="default"
          checked={field.value}
          value={!field.value ? '' : field.value.toString()}
          disabled={approvalStatus === APPROVAL_STATUS.REJECTED || approvalStatus === APPROVAL_STATUS.CANCELLED}
          onChange={handleChange}
          {...others}
        />
      </Grid>
      <Grid item={true} className={classes.grow}>
        {seatText}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(GuaranteedSeatField);

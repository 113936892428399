import { bindActionCreators, Dispatch } from 'redux';

import { updateTimeCardAction } from '../../data';
import { TimeSheetStoreState } from '../../timeSheetReducer';

import { DailySummaryOuterProps, StateProps, DispatchProps } from './dailySummaryModels';

export const mapStateToProps = (state: any, props: DailySummaryOuterProps): StateProps => {
    const timeSheetStoreState: TimeSheetStoreState = state.timeSheet;
    const hoursSettings = timeSheetStoreState.hoursSettings.data;

    return {
        defaultWorkingHours: hoursSettings && hoursSettings.workDayHours || 'PT8H',
        blockAfterDays: hoursSettings && hoursSettings.blockAfterDays,
        orderedTimeCardsData: timeSheetStoreState.timeCards.data,
        isSaving: timeSheetStoreState.timeCards.cardsWeAreSaving.includes(props.date),
        failedToSave: timeSheetStoreState.timeCards.cardsFailedToSave.includes(props.date),
        isPublicHoliday: timeSheetStoreState.publicHolidays.data.includes(props.date),
        autoDeductionRule: timeSheetStoreState.autoDeductionRule.data,
    };
};

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => bindActionCreators(
    { updateTimeCard: updateTimeCardAction },
    dispatch
);

import { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const addNewTemplateStyle: StyleRulesCallback = (theme: Theme) => ({
  root: {
    textAlign: 'center',
    padding: '25px 25px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) !important',
    minWidth: 700,
  },
  topTitle: {
    textAlign: 'left',
  },
  topTitleRight: {
    float: 'right',
    marginTop: '-8px'
  },
  spacing: {
    flexGrow: 1,
    marginRight: 20,
  },
  left: {
    textAlign: 'left',
  },
  flexWrapper: {
    display: 'flex',
  },
  editStyle: {
    padding: '25px 25px 8px 25px',
    backgroundColor: 'lightyellow'
  },
  datePicker: {
    marginRight: '20px',
    flexGrow: 1
  },
  stagesButton: {
    marginLeft: '-15px'
  },
  checkboxControlLabel: {
    position: 'relative',
  },
  checkboxLabel: {
    transform: 'translate(0, 0) scale(0.75)',
    transformOrigin: 'top left',
    color: theme.palette.text.secondary,
    fontSize: '1rem',
    lineHeight: 1,
    position: 'absolute',
    top: 0,
    left: 0,
  },
});

export default addNewTemplateStyle;

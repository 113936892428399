import React, { useContext, useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  StyleRulesCallback,
  Theme,
  WithStyles,
  withStyles,
  IconButton,
  Icon,
  ExpansionPanelDetails,
  Checkbox,
  FormControlLabel,
  TablePagination,
  OutlinedInput,
  InputAdornment,
  Tooltip,
} from '@material-ui/core';
import translate from '@/app/utils/translate';
import Context from '../context/trainingsContext';
import {
  CompanyTraining,
  CompanyTrainingParticipantRegistration,
} from '../types';
import { getLoggedUserId, getSelectedEmpId } from '@/old/utils/helper';
import Loading from '../../Loading/Loading';
import {
  formatTrainingDates,
  getEmployee,
  sortByDate,
  sortByDateDesc,
} from '../helpers';
import TrainingInfoView from './trainingCalendarPanelDialog/TrainingInfoView';
import { EmployeeAvatar } from './';
import { Search, Delete } from '@material-ui/icons';
import moment from 'moment';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';

const styles: StyleRulesCallback = (theme: Theme) => ({
  dialogTitle: {
    display: 'flex',
  },
  grow: {
    flex: 1,
  },
  selectedEmployeeTitle: {
    display: 'flex',
    backgroundColor: theme.palette.grey[200],
    paddingTop: theme.spacing.unit / 2,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit / 2,
    marginLeft: theme.spacing.unit * 2,
    alignItems: 'center',
  },
  selectedEmployeeTitleTexts: {
    paddingLeft: theme.spacing.unit,
  },
  searchField: {
    marginBottom: theme.spacing.unit,
  },
  searchInput: {
    padding: theme.spacing.unit,
  },
  location: {
    width: theme.spacing.unit * 70,
  },
  deleteButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

type OwnProps = {
  open: boolean;
  onClose: () => void;
};
type InnerProps = WithStyles<typeof styles>;
type Props = OwnProps & InnerProps;

const TrainingCalendarPanelDialog = ({ classes, open, onClose }: Props) => {
  const {
    state: {
      employeeTrainings: {
        calendar: { data, loading, error },
        registrations: { data: currentRegistrations },
      },
    },
    cancelTaining,
    getEmployeeTrainingCalendar,
    getOncomingEmployeeTrainings,
  } = useContext(Context);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [expandedTraining, setExpandedTraining] = useState<
    CompanyTraining | undefined
  >();
  const [showAll, setShowAll] = useState(false);
  const employeeId = getSelectedEmpId() || getLoggedUserId();
  const [searchField, setSearchField] = useState('');
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedTrainingId, setSelectedTrainingId] = useState(undefined);
  const [selectedRegistraionId, setSelectedRegistraionId] = useState(undefined);

  useEffect(() => {
    if (employeeId) {
      getEmployeeTrainingCalendar(employeeId, showAll);
    }
  }, [employeeId, showAll]);

  useEffect(() => {
    if (employeeId) {
      getOncomingEmployeeTrainings(employeeId);
    }
  }, [employeeId]);

  const handlePageChange = (_e: any, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (e: any) => {
    setRowsPerPage(e.target.value);
  };

  const searchFilter = (training: CompanyTraining) => {
    const smallSearchFilter = searchField.toLowerCase();
    if (
      training.name.label.toLowerCase().includes(smallSearchFilter) ||
      (training.city && training.city.toLowerCase().includes(smallSearchFilter))
    ) {
      return true;
    }
    return false;
  };

  const handleTrainingCancellation = () => {
    cancelTaining(selectedTrainingId, selectedRegistraionId);
    setSelectedTrainingId(undefined);
    setSelectedRegistraionId(undefined);
  };

  const handleChange = (
    training: CompanyTraining,
    _event: React.ChangeEvent<any>,
    expanded: boolean,
  ) => {
    expanded ? setExpandedTraining(training) : setExpandedTraining(undefined);
  };

  let content;
  if (error) {
    content = (
      <Typography variant="h6" color="error">
        {error}
      </Typography>
    );
  } else {
    if (loading) {
      content = <Loading />;
    } else {
      if (data.length === 0) {
        content = (
          <Typography variant="subtitle1" color="default">
            {translate.t('laNoData')}
          </Typography>
        );
      } else {
        const filteredData = data.filter(
          (t: CompanyTraining) => t.active && searchFilter(t),
        );
        content = (
          <>
            {filteredData
              .sort((a: CompanyTraining, b: CompanyTraining) =>
                !showAll
                  ? sortByDate(a.startDate, b.startDate)
                  : sortByDateDesc(a.startDate, b.startDate),
              )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((t: CompanyTraining, index: number) => {
                const currentRegistration: CompanyTrainingParticipantRegistration = currentRegistrations.find(
                  (r: CompanyTrainingParticipantRegistration) =>
                    r.trainingId === t.id,
                );
                return (
                  <div key={t.id}>
                    <ExpansionPanel
                      key={t.id}
                      expanded={
                        !!expandedTraining && expandedTraining.id === t.id
                      }
                      onChange={(e, expanded) => handleChange(t, e, expanded)}
                    >
                      <ExpansionPanelSummary
                        expandIcon={<Icon>expand_more</Icon>}
                        data-testid={`btn-training-calendar-panel-expand-${index}`}
                      >
                        <Typography
                          className={classes.location}
                          variant="button"
                        >
                          {t.name.label}(
                          {formatTrainingDates(t.startDate, t.endDate)})
                        </Typography>
                        <Typography className={classes.grow} variant="button">
                          {t.city}
                        </Typography>
                        {t.lastDateOfCancellation &&
                          moment(new Date()).isSameOrBefore(
                            t.lastDateOfCancellation,
                            'day',
                          ) &&
                          currentRegistration &&
                          getLoggedUserId() === getSelectedEmpId() && (
                            <Tooltip title={translate.t('laDelete')}>
                              <IconButton
                                color="primary"
                                component="span"
                                className={classes.deleteButton}
                                onClick={e => {
                                  e.stopPropagation();
                                  setConfirmDialogOpen(true);
                                  setSelectedTrainingId(t.id);
                                  setSelectedRegistraionId(
                                    currentRegistration.id,
                                  );
                                }}
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          )}
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <TrainingInfoView training={t} />
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ConfirmDialog
                      open={confirmDialogOpen}
                      text={translate.t('laTrainingCancellation')}
                      onOk={handleTrainingCancellation}
                      onClose={() => setConfirmDialogOpen(false)}
                    />
                  </div>
                );
              })}
            <TablePagination
              component="div"
              count={filteredData.length}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[10, 25, 50, 100]}
              backIconButtonProps={{
                'aria-label': translate.t('label_previous_page'),
              }}
              nextIconButtonProps={{
                'aria-label': translate.t('label_next_page'),
              }}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
            />
          </>
        );
      }
    }
  }

  const emp = getEmployee(employeeId);

  const selectedEmployeeTitle =
    employeeId === getLoggedUserId() ? null : (
      <div className={classes.selectedEmployeeTitle}>
        <EmployeeAvatar employeeId={employeeId} />
        <div className={classes.selectedEmployeeTitleTexts}>
          <Typography variant="caption">
            {translate.t('title_showing_training_calendar_for')}
          </Typography>
          <Typography variant="subtitle2">
            {emp.firstName} {emp.lastName}
          </Typography>
        </div>
      </div>
    );

  const TrainingFilters = (
    <div>
      <OutlinedInput
        className={classes.searchField}
        value={searchField}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setSearchField(e.target.value);
          setPage(0);
        }}
        margin="dense"
        inputProps={{ className: classes.searchInput }}
        labelWidth={0}
        placeholder={translate.t('laSearch')}
        startAdornment={
          <InputAdornment position="start">
            <Search fontSize="small" />
          </InputAdornment>
        }
      />
    </div>
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="lg">
      <DialogTitle className={classes.dialogTitle} disableTypography={true}>
        <Typography variant="h6">{translate.t('training_calendar')}</Typography>
        <div className={classes.grow} />
        {selectedEmployeeTitle}
        <div className={classes.grow} />
        <div>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                value={showAll.toString()}
                onChange={e => setShowAll(e.target.checked)}
              />
            }
            label={translate.t('label_show_all')}
          />
          <IconButton
            onClick={onClose}
            data-testid="btn-training-calendar-dialog-close"
          >
            <Icon>close</Icon>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        {TrainingFilters}
        {content}
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(TrainingCalendarPanelDialog);

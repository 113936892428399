import { bindActionCreators, Dispatch } from 'redux';

import {
    fetchMyTasksAction,
    syncTasksAction,
    syncDeletedTasksAction,
    syncCreatedTaskCommentAction,
    syncDeletedTaskCommentAction,
} from '../../data';

import { DispatchProps, StateProps } from './TasksList';

export const mapStateToProps = (state: any): StateProps => ({
    tasks: state.collaboration.module.tasks,
    loadingTasks: state.collaboration.module.loadingTasks,
    failedToLoadTasks: state.collaboration.module.failedToLoadTasks,
});

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => bindActionCreators(
    {
        fetchMyTasks: fetchMyTasksAction,
        syncTasks: syncTasksAction,
        syncDeletedTasks: syncDeletedTasksAction,
        syncCreatedTaskComment: syncCreatedTaskCommentAction,
        syncDeletedTaskComment: syncDeletedTaskCommentAction,
    },
    dispatch
);
